import React, { memo } from 'react';
import PropTypes from 'prop-types';

const AUDIT_CHECKLIST_CARD_BLOCK = 'audit-checklist-card';

const AuditChecklistCard = ({ children }) => {
  return <div className={AUDIT_CHECKLIST_CARD_BLOCK}>{children}</div>;
};
AuditChecklistCard.propTypes = {
  children: PropTypes.node,
};
export default memo(AuditChecklistCard);
