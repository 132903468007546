import React from 'react';
import PropTypes from 'prop-types';
import AuditChecklistQuestionElement from 'components/feature/toolkit/toolkit-audit-panel/toolkit-audit-checklist-question-element';
import ConditionalRender from 'components/util/conditional-render-component';
import AuditQuestionsChecklist from 'models/api/audit-questions-checklist-api-model';

const AuditChecklistPanelContent = ({
  auditQuestionpaperList,
  buttonStatus,
}) => {
  return (
    <ConditionalRender dependencies={[auditQuestionpaperList]}>
      {auditQuestionpaperList.data.questionsChecklist.map((questionElement) => (
        <AuditChecklistQuestionElement
          key={questionElement.id}
          questionId={questionElement.id}
          question={questionElement.question}
          auditAnswer={questionElement.auditAnswer}
          toggleButton={buttonStatus}
        />
      ))}
    </ConditionalRender>
  );
};

AuditChecklistPanelContent.propTypes = {
  /** object of question papers to display on the toolkit */
  auditQuestionpaperList: PropTypes.instanceOf(AuditQuestionsChecklist)
    .isRequired,
  /** To disable buttons after confirmation on the modal */
  buttonStatus: PropTypes.bool,
};

export default AuditChecklistPanelContent;
