import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { RadioOption } from 'models/utils/common/radio-options-model';

export const getProjectNameMenuOptions = (projectList) => {
  return projectList.map(
    (project) =>
      new MenuOption({
        id: project.id,
        title: project.name,
        value: project,
        isIntl: false,
      }),
  );
};

export const getStatementNameMenuOptions = (statementList) => {
  return statementList.map(
    (statement) =>
      new RadioOption({
        id: statement.id,
        label: `${statement.statementName}${getTagForStatementName(statement)}`,
        value: statement.id,
        isNotInternationalized: true,
      }),
  );
};

export const getTagForStatementName = (statement) => {
  if (statement.isSoftDeleted()) return ' (Deleted)';
  else if (statement.isWrappedUp()) return ' (Wrapped up)';
  else if (statement.isPendingApproval()) return ' (Approval pending)';
  else if (statement.hasApprovalInitiated()) return ' (Wrap-up in progress)';
  else return EMPTY_STRING;
};

export const dropdownOptionsForRevision = [
  {
    label: 'side-by-side-view.compare.dropdown.workpaper.label',
    name: 'side-by-side-view.compare.dropdown.workpaper.name',
    checked: true,
    id: 'wp',
  },
  {
    label: 'side-by-side-view.compare.dropdown.tickmarks.label',
    name: 'side-by-side-view.compare.dropdown.tickmarks.name',
    checked: true,
    id: 'tm',
  },
  {
    label: 'side-by-side-view.compare.dropdown.verification-status.label',
    name: 'side-by-side-view.compare.dropdown.verification-status.name',
    checked: true,
    id: 'vs',
  },
];

export const dropdownOptionsForStatements = [
  {
    label: 'side-by-side-view.compare.dropdown.workpaper.label',
    name: 'side-by-side-view.compare.dropdown.workpaper.name',
    checked: true,
    id: 'wp',
  },
  {
    label: 'side-by-side-view.compare.dropdown.tickmarks.label',
    name: 'side-by-side-view.compare.dropdown.tickmarks.name',
    checked: true,
    id: 'tm',
  },
  {
    label: 'side-by-side-view.compare.dropdown.py.label',
    name: 'side-by-side-view.compare.dropdown.py.label',
    checked: true,
    id: 'py',
  },
  {
    label: 'side-by-side-view.compare.dropdown.pq.label',
    name: 'side-by-side-view.compare.dropdown.pq.label',
    checked: true,
    id: 'pq',
  },
];

export const TAB_NAME = {
  LEFT: 'left',
  RIGHT: 'right',
};
