import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import Note from 'models/data/note-model';

export default class NotesBySectionMap extends ApiModel({
  data: {
    notes: {
      /*
        {
          [noteId]: Note,
          ...
        }
      */
    },
    elementNotes: {
      /*
        [elementId]: Note
      */
    },
    pseudoNotes: {
      /*
        [pseudoElementId]: Note
      */
    },
    sectionId: null,
  },
}) {
  get notes() {
    if (!isNullOrUndefined(this.data) && this.data.notes) {
      return this.data.notes;
    }
    return {};
  }

  get elementNotes() {
    if (!isNullOrUndefined(this.data) && this.data.elementNotes) {
      return this.data.elementNotes;
    }
    return {};
  }

  get pseudoNotes() {
    if (!isNullOrUndefined(this.data) && this.data.pseudoNotes) {
      return this.data.pseudoNotes;
    }
    return {};
  }

  get sectionId() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.sectionId;
    }
    return null;
  }

  processResponse({ response, sectionId }) {
    let notes = {};
    let elementNotes = {};
    let pseudoNotes = {};
    response.data.result.forEach((noteData) => {
      const { elementId, noteId, pseudoElementId } = noteData;
      const noteModel = new Note(noteData);
      if (!isNullOrUndefined(pseudoElementId) && pseudoElementId !== -1) {
        pseudoNotes[pseudoElementId] = noteModel;
      } else {
        elementNotes[elementId] = noteModel;
        notes[noteId] = noteModel;
      }
    });
    return {
      data: {
        notes,
        elementNotes,
        pseudoNotes,
        sectionId,
      },
    };
  }

  getNote(noteId) {
    return this.notes[noteId];
  }

  getPseudoNote(pseudoElementId) {
    return this.pseudoNotes[pseudoElementId];
  }

  hasNote(noteId) {
    return !isNullOrUndefined(this.notes[noteId]);
  }

  hasElementNote(elementId) {
    return !isNullOrUndefined(this.elementNotes[elementId]);
  }

  hasPseudoNote(pseudoElementId) {
    return !isNullOrUndefined(this.pseudoNotes[pseudoElementId]);
  }
}
