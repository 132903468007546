import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CurrentUser } from 'models/api/current-user-model';
import { AuthUser } from 'models/api/auth-user-model';

import ConditionalRender from 'components/util/conditional-render-component';
import Unauthorized from 'components/app/unauthorized-component';

/**
 * This is a middleware component used to handle authentication and the associated rendering of components based on
 * the status of the current user.
 */
class Authorized extends Component {
  async authenticateCurrentUser() {
    const { fetchUserOPMProfile, authenticateUser } = this.props;
    await fetchUserOPMProfile();

    const { authUser } = this.props;

    !authUser.isAuthStatus401UnAuthorized() && (await authenticateUser());
  }

  componentDidMount() {
    this.authenticateCurrentUser();
  }

  componentDidUpdate(prevProps) {
    const { fetchCurrentUser, authUser } = this.props;
    if (
      prevProps.authUser.isLoaded !== authUser.isLoaded &&
      authUser.isUserAuthorized()
    ) {
      fetchCurrentUser();
    }
  }

  render() {
    const { currentUser, authUser, component: Component } = this.props;

    // Redirect disabled users to unauthorized page
    if (!authUser.isUserAuthorized()) {
      return (
        <ConditionalRender dependencies={[authUser]}>
          <Unauthorized />
        </ConditionalRender>
      );
    }

    return (
      <ConditionalRender dependencies={[currentUser]}>
        <Component {...this.props} />
      </ConditionalRender>
    );
  }
}

Authorized.propTypes = {
  /** Object representing the current user in TieOut */
  currentUser: PropTypes.instanceOf(CurrentUser).isRequired,
  /** Object representing the current user in TieOut */
  authUser: PropTypes.instanceOf(AuthUser).isRequired,
  /** Function call used to fetch the current user from the Tieout back-end */
  fetchCurrentUser: PropTypes.func,
  /** The component to be wrapped in this authorized middleware component */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** Function call used to fetch the user OPM profile from the Tieout back-end */
  fetchUserOPMProfile: PropTypes.func,
  /** Function call used to authenticate the user from the Tieout back-end */
  authenticateUser: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default Authorized;
