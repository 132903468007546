import { createAction } from 'redux-actions';

export const incrementZoom = createAction('INCREMENT_ZOOM');
export const decrementZoom = createAction('DECREMENT_ZOOM');
export const resetZoom = createAction('RESET_ZOOM');

export const leftIncrementZoom = createAction('INCREMENT_LEFT_ZOOM');
export const leftDecrementZoom = createAction('DECREMENT_LEFT_ZOOM');
export const leftResetZoom = createAction('RESET_LEFT_ZOOM');

export const rightIncrementZoom = createAction('INCREMENT_RIGHT_ZOOM');
export const rightDecrementZoom = createAction('DECREMENT_RIGHT_ZOOM');
export const rightResetZoom = createAction('RESET_RIGHT_ZOOM');
