import React, { useState } from 'react';
import ExpandableRow from 'components/common/expandable-row-component';
import SectionDetailsHistoryElement from 'components/feature/section-details-panel/_section-details-history-element-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConditionalRender from 'components/util/conditional-render-component';
import { SIGN_OFF_HISTORY_OPTIONS } from 'constants/feature/section-details-constants';
const SECTION_DETAILS_HISTORY = 'section-details-history';

export const SectionDetailsHistoryRow = ({ sectionHistory }) => {
  const [showHistory, setShowHistory] = useState(true);

  return (
    <ExpandableRow
      title="section-panel.history.title"
      showExpandableRow={showHistory}
      minimizeExpandableRow={() => setShowHistory(false)}
      expandExpandableRow={() => setShowHistory(true)}
      tooltipId={`${SECTION_DETAILS_HISTORY}-history__tooltip`}
    >
      <ConditionalRender dependencies={[sectionHistory]}>
        {sectionHistory.data.sectionReviewHistory &&
          sectionHistory.data.sectionReviewHistory.result &&
          sectionHistory.data.sectionReviewHistory.result
            .filter(
              (review) =>
                review.sectionAction !== SIGN_OFF_HISTORY_OPTIONS.ASSIGN_BY &&
                review.sectionAction !== SIGN_OFF_HISTORY_OPTIONS.UNASSIGN_BY,
            )
            .map((history) => (
              <div key={history.id}>
                <SectionDetailsHistoryElement history={history} />
              </div>
            ))}
      </ConditionalRender>
    </ExpandableRow>
  );
};

const mapStateToProps = ({
  data: {
    sectionPanel: { sectionReviewHistory },
  },
}) => ({
  sectionHistory: sectionReviewHistory,
});

SectionDetailsHistoryRow.propTypes = {
  /** history object from the section detail store */
  sectionHistory: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SectionDetailsHistoryRow);
