import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataGrid, {
  DataGridDataApi,
} from 'components/common/data-grid/data-grid-component';
import { getEngagementListColumns } from 'constants/feature/omnia-engagements-constants';
import { connect } from 'react-redux';
import {
  handleProjectClick,
  handleOmniaEngagementLinking,
  handleOmniaEngagementUnlinking,
} from 'store/actions/omnia-engagement-actions';
import { fetchProjectList } from 'store/actions/project-list-actions';
import OmniaEngagement from 'models/api/omnia-engagement-model';
import OmniaLinkingConfirmModal from './omnia-linking-confirmation-modal';
import OmniaUnlinkingConfirmModal from './omnia-unlinking-confirmation-modal';
import OmniaEngagementEntry from 'models/data/omnia-engagement-entry-model';
import { useCallback } from 'react';
import { isNullOrUndefined } from 'utils/object-utils';

export const OMNIA_ENGAGEMENT_LIST_BLOCK = 'omnia-engagement-list';
export const OMNIA_ENGAGEMENT_LIST_ID_BLOCK = 'omnia-engagement-list-id';

const OmniaEngagementList = ({
  omniaEngagement,
  handleProjectClick,
  fetchProjectList,
  handleOmniaEngagementLinking,
  handleOmniaEngagementUnlinking,
}) => {
  useEffect(() => {
    fetchProjectList();
  }, [fetchProjectList]);

  const [showLinkConfirmModal, setShowLinkConfirmModal] = useState(false);
  const [showUnlinkConfirmModal, setShowUnlinkConfirmModal] = useState(false);
  const [engagementToLink, setEngagementToLink] = useState(
    new OmniaEngagementEntry(),
  );

  const handleOnClickLink = useCallback((engagement) => {
    setShowLinkConfirmModal(true);
    setEngagementToLink(engagement);
  }, []);

  const handleOnClickUnlink = useCallback((engagement) => {
    setShowUnlinkConfirmModal(true);
    setEngagementToLink(engagement);
  }, []);

  return (
    <div className={OMNIA_ENGAGEMENT_LIST_BLOCK}>
      <DataGrid
        tableId={`${OMNIA_ENGAGEMENT_LIST_ID_BLOCK}-list`}
        className={`${OMNIA_ENGAGEMENT_LIST_BLOCK}__list`}
        columns={getEngagementListColumns({
          onProjectClick: handleProjectClick,
          handleOnClickLink,
          handleOnClickUnlink,
          omniaEngagement,
        })}
        dataModel={
          new DataGridDataApi({
            apiModel: omniaEngagement,
            rowItems: omniaEngagement.omniaTieoutEngagementEntityList,
          })
        }
      />
      {showLinkConfirmModal && (
        <OmniaLinkingConfirmModal
          omniaEngagement={omniaEngagement}
          engagementToLink={engagementToLink}
          onClickCancel={() => setShowLinkConfirmModal(false)}
          onClickLink={() => {
            if (!isNullOrUndefined(omniaEngagement.getGeoName())) {
              setShowLinkConfirmModal(false);
              handleOmniaEngagementLinking({
                engagement: engagementToLink,
                omEngagementId: omniaEngagement.getEngagementId(),
                geoCode: omniaEngagement.getGeoName(),
              });
            }
          }}
        />
      )}
      {showUnlinkConfirmModal && (
        <OmniaUnlinkingConfirmModal
          omniaEngagement={omniaEngagement}
          engagementToUnlink={engagementToLink}
          onClickCancel={() => setShowUnlinkConfirmModal(false)}
          onClickUnlink={(engagementToUnlink) => {
            if (!isNullOrUndefined(omniaEngagement.getGeoName())) {
              setShowUnlinkConfirmModal(false);
              handleOmniaEngagementUnlinking({
                engagementToUnlink,
                geoCode: omniaEngagement.getGeoName(),
              });
            }
          }}
        />
      )}
    </div>
  );
};

OmniaEngagementList.propTypes = {
  /** Omnia engagement object  */
  omniaEngagement: PropTypes.instanceOf(OmniaEngagement).isRequired,
  /** Action to handle when a project is clicked in the engagment project list */
  handleProjectClick: PropTypes.func.isRequired,
  /** Action for linking omnia engagements */
  handleOmniaEngagementLinking: PropTypes.func.isRequired,
  /** Action for unlinking omnia engagements */
  handleOmniaEngagementUnlinking: PropTypes.func.isRequired,
  /** Action to fetch the list of projects */
  fetchProjectList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  handleProjectClick,
  fetchProjectList,
  handleOmniaEngagementLinking,
  handleOmniaEngagementUnlinking,
};

export { OmniaEngagementList };

export default connect(null, mapDispatchToProps)(OmniaEngagementList);
