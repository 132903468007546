import React from 'react';
import PropTypes from 'prop-types';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';

import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';

export const PROJECT_CREATION_PAGE_ID_BLOCK = 'project-creation-id';

const ProjectCreationCancelModal = ({
  toggleCancelModal,
  onCancelAndLeavePage,
}) => {
  return (
    <Modal
      id={`${PROJECT_CREATION_PAGE_ID_BLOCK}-cancel-modal`}
      title={'common.cancel-modal.title'}
      onClose={toggleCancelModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: onCancelAndLeavePage,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: toggleCancelModal,
        })
      }
    >
      <Banner
        id={`${PROJECT_CREATION_PAGE_ID_BLOCK}-cancel-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'project-creation.cancel-modal.warning'}
        isInternationalized={true}
        isInteractive={false}
      />
    </Modal>
  );
};

ProjectCreationCancelModal.propTypes = {
  /** Method on the project creation component that closes project creation cancel modal */
  toggleCancelModal: PropTypes.func.isRequired,
  /** Method on the project creation component that let user leave project creation page */
  onCancelAndLeavePage: PropTypes.func.isRequired,
};

export default ProjectCreationCancelModal;
