import * as signalr from '@microsoft/signalr';
import { getStore } from 'store/store';
import { setSocketDisconnectAction } from 'store/actions/statement-socket-actions';
import { setProjectSocketDisconnectAction } from 'store/actions/project-socket-actions';
import { isInvalidUrl } from 'api/api-request-validator';
import { checkIfExistingTokenIsValidAndFetchNewIfExpired } from 'api/api-authentication';

const retryInterval = {
  0: 0, // 0 seconds
  1: 2000, // 2 seconds
  2: 10000, // 10 seconds
  3: 30000, // 30 seconds
  4: 60000, // 60 seconds
  5: 120000, // 120 seconds
};

const globalClientConfig = {};

export default globalClientConfig;

export class SignalrClient {
  accessToken = null;
  autoConnect = false;
  callbackQueue = [];
  connection = null;
  isConnected = false;
  url = null;
  isProjectLevelConnection = false;
  geoCode = null;

  constructor(config) {
    this.connectWithRetry = this.connectWithRetry.bind(this);
    Object.assign(this, globalClientConfig, config);
    if (this.autoConnect) {
      this.connectWithRetry();
    }
  }

  async connect() {
    if (!this.accessToken || !this.url || !this.geoCode) {
      return false;
    }
    const { dispatch } = getStore();
    let connectionBuilder = new signalr.HubConnectionBuilder()
      .withUrl(
        `${window.TIEOUT.ENV.GEOS[this.geoCode].BASE_API_URL}${this.url}`,
        {
          accessTokenFactory: async () => {
            this.accessToken = await checkIfExistingTokenIsValidAndFetchNewIfExpired(
              this.geoCode,
            );
            return this.accessToken;
          },
          withCredentials: false,
        },
      )
      .configureLogging(signalr.LogLevel.Information);

    if (this.autoConnect) {
      connectionBuilder = connectionBuilder.withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 120000) {
            // If we've been reconnecting for less than 120 seconds so far,
            const retryNumber = retryContext.previousRetryCount;
            return retryInterval[retryNumber];
          } else {
            // If we've been reconnecting for more than 240 seconds so far, stop reconnecting
            // and revert back to the original way of receiving data
            this.isProjectLevelConnection
              ? dispatch(setProjectSocketDisconnectAction(true))
              : dispatch(setSocketDisconnectAction(true));
            return null;
          }
        },
      });
    }
    // We do not want to build and start connection if url is inavlid for some reason
    // Although we will retry after few elapsed seconds again (if this.autoConnect is set to true)
    if (!isInvalidUrl(connectionBuilder.url)) {
      this.connection = connectionBuilder.build();
      this.connection.serverTimeoutInMilliseconds = 60000;
      this.connection.keepAliveIntervalInMilliseconds = 30000;
      this.connection.onreconnecting((error) => {
        if (error) console.warn(error);
        this.isProjectLevelConnection
          ? dispatch(setProjectSocketDisconnectAction(true))
          : dispatch(setSocketDisconnectAction(true));
      });
      this.connection.onreconnected((connectionId) => {
        this._setSocketConnectionState(connectionId);
      });
      try {
        await this.connection.start();
        this.isConnected = true;
        this.executeCallbacks();
        //store connectionId in local storage. Will need when we want to disconnect
        const connectionId = this.connection.connection.connectionId;
        this._setSocketConnectionState(connectionId);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  }

  _setSocketConnectionState = (connectionId) => {
    const { dispatch } = getStore();
    if (this.isProjectLevelConnection) {
      localStorage.setItem('projectConnectionId', connectionId);
      dispatch(setProjectSocketDisconnectAction(false));
    } else {
      localStorage.setItem('connectionId', connectionId);
      dispatch(setSocketDisconnectAction(false));
    }
  };
  async connectWithRetry() {
    if (this.isConnected) {
      return;
    }
    if (!this.accessToken && this.geoCode) {
      this.accessToken = await checkIfExistingTokenIsValidAndFetchNewIfExpired(
        this.geoCode,
      );
      this.autoConnect = true;
    }
    if (!(await this.connect())) {
      setTimeout(this.connectWithRetry, 30000);
    }
  }

  async disconnect() {
    if (!this.connection) {
      return false;
    }
    this.connection.close();
    return true;
  }

  queueCallback(callback) {
    if (this.isConnected) {
      callback();
    } else {
      this.callbackQueue.push(callback);
    }
  }

  executeCallbacks() {
    if (this.isConnected) {
      while (this.callbackQueue.length) {
        this.callbackQueue.shift()();
      }
    }
  }

  on(event, callback) {
    this.queueCallback(() => this.connection.on(event, callback));
  }
}
