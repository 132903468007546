import { handleActions } from 'redux-actions';
import {
  setInternalReferenceLoading,
  setInternalReferenceLoaded,
  setInternalReferenceError,
  clearInternalReferenceAction,
  updateInternalReferenceElementsFromSocketAction,
  updateInternalReferenceFromSocketAction,
  setInternalReferenceLoadedWithoutPayload,
} from 'store/actions/internal-reference-actions';

import InternalReference from 'models/api/internal-reference-model';

export default handleActions(
  {
    [setInternalReferenceLoading]: (internalReference) => {
      return internalReference.setLoading();
    },
    [setInternalReferenceLoaded]: (internalReference, { payload }) => {
      return internalReference.setLoaded(payload);
    },
    [setInternalReferenceLoadedWithoutPayload]: (internalReference) => {
      return internalReference.setLoadedWithoutResponse();
    },
    [setInternalReferenceError]: (internalReference, { payload }) => {
      return internalReference.setError(payload);
    },
    [updateInternalReferenceElementsFromSocketAction]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.updateInternalReferenceElementsFromSocket(
        payload,
      );
    },
    [updateInternalReferenceFromSocketAction]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.updateInternalReferenceFromSocket(payload);
    },
    [clearInternalReferenceAction]: (internalReference, { payload }) => {
      return new InternalReference();
    },
  },
  new InternalReference(),
);
