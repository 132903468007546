import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class StatementSummaryElements extends ApiModel({
  data: {
    totalElements: 0,
    verified: 0,
    notVerified: 0,
    flagged: 0,
    notFlagged: 0,
    reviewed: 0,
    notReviewed: 0,
  },
}) {
  get totalElements() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.totalElements;
  }
  get verified() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.verified;
  }

  get unverified() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.notVerified;
  }

  get reviewed() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.reviewed;
  }

  get flagged() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.flagged;
  }

  get unverifiedPercentage() {
    return this.totalElements === 0
      ? 0
      : ((this.unverified * 100) / this.totalElements).toFixed(0);
  }

  get verifiedPercentage() {
    return this.totalElements === 0
      ? 0
      : Math.round((this.verified * 100) / this.totalElements);
  }

  get reviewedPercentage() {
    return this.totalElements === 0
      ? 0
      : ((this.reviewed * 100) / this.totalElements).toFixed(0);
  }

  processResponse({ response }) {
    return { data: response.data.result };
  }

  updateElementsSummaryFromSocketPayload(payload) {
    let {
      elementsVerified,
      elementsReviewed,
      elementsCount,
      elementsFlagged,
      totalElementsFlagged,
    } = payload;
    !isNullOrUndefined(totalElementsFlagged) &&
      totalElementsFlagged !== -1 &&
      (elementsFlagged = totalElementsFlagged - this.data.flagged);
    return this.mergeData({
      totalElements: this.data.totalElements + elementsCount,
      verified: this.data.verified + elementsVerified,
      notVerified: this.data.notVerified - elementsVerified + elementsCount,
      reviewed: this.data.reviewed + elementsReviewed,
      notReviewed: this.data.notReviewed - elementsReviewed + elementsCount,
      flagged: this.data.flagged + elementsFlagged,
      notFlagged: this.data.notFlagged - elementsFlagged + elementsCount,
    });
  }
}
