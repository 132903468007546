import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ComfortLetterEntry,
  COMFORT_LETTER_ENTRY_BLOCK,
} from './comfort-letter-entry-component';
import { ReactComponent as PlusCircle } from 'icons/plus-circle-blue.svg';
import ComfortLetter from 'models/data/comfort-letter-model';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const ATTACH_ICON_SIZE = '28px';

const COMFORT_LETTER_ATTACH_TOOLTIP = new TooltipOptions({
  text: 'common.attach',
  id: 'comfort-letter-attach-button-tool-tip',
  position: 'left',
});

const ComfortLetterEntryWithAttach = ({
  comfortLetter,
  onAddComfortLetter,
  disabled,
}) => {
  return (
    <ComfortLetterEntry
      comfortLetter={comfortLetter}
      rightComponent={
        <IconButton
          id={`attach-comfort-letter-${comfortLetter.comfortLetterId}`}
          className={`${COMFORT_LETTER_ENTRY_BLOCK}__attach`}
          tooltip={COMFORT_LETTER_ATTACH_TOOLTIP}
          onClick={() => onAddComfortLetter(comfortLetter)}
          Icon={PlusCircle}
          iconSize={ATTACH_ICON_SIZE}
          disabled={disabled}
        />
      }
    />
  );
};

ComfortLetterEntryWithAttach.propTypes = {
  /** comfort letter object from the comfort letter list endpoint */
  comfortLetter: PropTypes.instanceOf(ComfortLetter).isRequired,
  /** action fired to attach this comfort letter to an element */
  onAddComfortLetter: PropTypes.func.isRequired,
  /** boolean to disable the button */
  disabled: PropTypes.bool.isRequired,
};

export default memo(ComfortLetterEntryWithAttach);
