import React from 'react';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from 'store/store';
import 'react-dates/initialize';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

import IntlProvider from 'containers/util/intl-provider-container';
import HomePage from 'containers/feature/homepage-container';
import ProjectCreationPage from 'containers/feature/project-creation/project-creation-container';
import ProjectEditPage from 'containers/feature/project-edit/project-edit-container';
import StatementCreationPage from 'containers/feature/statement-creation/statement-creation-container';
import StatementEditPage from 'containers/feature/statement-edit/statement-edit-container';
import StatementContentPage from 'containers/feature/statement-content/statement-content-container';
import OmniaEngagementPage from 'components/feature/omnia/omnia-engagement-page-component';
import SessionTimeoutPage from 'pages/session-timeout-page';
import TieoutPage from 'pages/tieout-page';
import LogoutPage from 'pages/logout-page';

import Unauthorized from 'containers/unauthorized-container';
import PublicRoute from 'components/routes/public-route-component';
import ProtectedRoute from 'components/routes/protected-route-component';
import RouteNotFound from 'components/routes/404-route-not-found-component';
import AuthSuccess from 'components/app/auth-success-component';
import 'styles/app-styles.scss';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionTimeout from 'components/session-timeout/session-timeout-component';
import AdminModulePage from 'pages/admin-module/admin-module-page';
import SideBySideViewPage from 'pages/side-by-side-compare/side-by-side-view';
import AdobeTracker from 'pages/adobe-tracker';

function App() {
  return (
    <Provider store={configureStore({})}>
      <IntlProvider>
        <ConnectedRouter history={history}>
          <span className="cookielink" style={{ display: 'none' }}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie-setting button - not for display
            </button>
          </span>
          <AdobeTracker />
          <Switch>
            <PublicRoute exact path="/authsuccess" component={AuthSuccess} />
            <PublicRoute exact path="/unauthorized" component={Unauthorized} />
            <ProtectedRoute exact path={'/omnia'} component={HomePage} />
            <PublicRoute
              exact
              path={ROUTE_CONSTANTS.LOGOUT_PAGE}
              component={LogoutPage}
            />
            <PublicRoute
              exact
              path={ROUTE_CONSTANTS.SESSION_TIMEOUT}
              component={SessionTimeoutPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.HOME_PAGE}
              component={HomePage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.TIEOUT_PAGE}
              component={TieoutPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.PROJECT_CREATION_PAGE}
              component={ProjectCreationPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.PROJECT_EDIT_PAGE}
              component={ProjectEditPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.STATEMENT_CREATION}
              component={StatementCreationPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.STATEMENT_EDIT_PAGE}
              component={StatementEditPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE}
              component={StatementContentPage}
            />
            {/**TODO: Once the split page is properly set up, do not map
             * this route to the statement content page anymore, but to a separate
             * statement split page.
             */}
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.STATEMENT_SPLIT_PAGE}
              component={StatementContentPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.OMNIA_ENGAGEMENT_PAGE}
              component={OmniaEngagementPage}
            />
            <ProtectedRoute
              exact
              path={ROUTE_CONSTANTS.ADMIN_MODULE_PAGE}
              component={AdminModulePage}
            />
            <ProtectedRoute
              exact
              path={`${ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE}${ROUTE_CONSTANTS.SIDE_BY_SIDE}`}
              component={SideBySideViewPage}
            />

            <PublicRoute path="*" component={RouteNotFound} />
          </Switch>
          <ToastContainer
            className="Toast-notification"
            transition={Slide}
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            closeButton={false}
          />
          <SessionTimeout />
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
}

export default App;
