import { handleActions } from 'redux-actions';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import {
  setProjectUsersListLoaded,
  setProjectUsersListLoading,
  setProjectUsersListError,
} from 'store/actions/project-users-list-actions';

export default handleActions(
  {
    [setProjectUsersListLoaded]: (user, { payload }) => {
      return user.setLoaded(payload);
    },
    [setProjectUsersListLoading]: (user, { payload }) => {
      return user.setLoading(payload);
    },

    [setProjectUsersListError]: (user, { payload }) => {
      return user.setError(payload);
    },
  },
  new ProjectUsersList(),
);
