import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

export const WPR_NUMBER_CHAR_LIMIT = 15;
export const WPR_NAME_CHAR_LIMIT = 150;
export const WPR_NUMBER_REGEX = /["*:<>?|\\]/;

export const WORKPAPER_DETACH_TOOLTIP = new TooltipOptions({
  text: 'common.unlink',
  id: 'workpaper-unlink-button-tool-tip',
  position: 'left',
});

export const WORKPAPER_TOOLKIT_PANEL_DELETE_TOOLTIP = new TooltipOptions({
  text: 'common.delete',
  id: 'workpaper-delete-button-tool-tip',
  position: 'top',
});

export const WORKPAPER_TOOLKIT_PANEL_EDIT_TOOLTIP = new TooltipOptions({
  text: 'common.edit',
  id: 'workpaper-edit-button-tool-tip',
  position: 'top',
});
export const EXPAND_WORKPAPER_LIST_TOOLTIP = new TooltipOptions({
  text: 'common.expand',
  id: 'workpaper-expand-button-tool-tip',
});

export const COLLAPSE_WORKPAPER_LIST_TOOLTIP = new TooltipOptions({
  text: 'common.collapse',
  id: 'workpaper-collapse-button-tool-tip',
});

export const UNLINK_ICON_TOOLTIP = new TooltipOptions({
  text: 'common.unlink',
  id: 'workpaper-element-icon-button-tool-tip',
});
export const PERMISSION_DENIED_ICON_TOOLTIP = new TooltipOptions({
  text: 'permissions.edit.statement',
  id: 'workpaper-element-icon-button-tool-tip',
});
