import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TickmarkEntry, {
  TICKMARK_ENTRY_BLOCK,
} from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-component';
import { ReactComponent as PlusCircle } from 'icons/plus-circle-blue.svg';
import Tickmark from 'models/data/tickmark-model';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const ATTACH_ICON_SIZE = '28px';

const TICKMARK_ATTACH_TOOLTIP = new TooltipOptions({
  text: 'common.attach',
  id: 'tickmark-attach-button-tool-tip',
  position: 'left',
});

const TickmarkEntryWithAttach = ({ tickmark, onAddTickmark, disabled }) => {
  return (
    <TickmarkEntry
      tickmark={tickmark}
      rightComponent={
        <IconButton
          id={`attach-tickmark-${tickmark.ordinal}`}
          className={`${TICKMARK_ENTRY_BLOCK}__attach`}
          tooltip={TICKMARK_ATTACH_TOOLTIP}
          onClick={() => onAddTickmark(tickmark)}
          Icon={PlusCircle}
          iconSize={ATTACH_ICON_SIZE}
          disabled={disabled}
        />
      }
    />
  );
};

TickmarkEntryWithAttach.propTypes = {
  /** Tickmark object from the tickmark list endpoint */
  tickmark: PropTypes.instanceOf(Tickmark).isRequired,
  /** action fired to attach this tickmark to an element */
  onAddTickmark: PropTypes.func.isRequired,
  /** boolean to disable the button */
  disabled: PropTypes.bool.isRequired,
};

export default memo(TickmarkEntryWithAttach);
