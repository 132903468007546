import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import DeletedHistoryModal from 'components/feature/statement-list/_deleted-revision-history-modal';
import ConfirmDeleteRevisionModal from 'components/feature/statement-list/permanently-delete-single-revision-modal';
import SelectedStatement from 'models/api/selected-statement-model';
import RestoreRevisionErrorModal from './restore-deleted-revision-error-modal';
import { restoreDeletedRevisionRequest } from 'api/statement-list-api';
import { BAD_REQUEST } from 'http-status-codes';
import ProjectApi from 'models/api/project-api-model';

/**
 * This wrapper encapsulates the state and logic for actions that can be committed from the Deleted Revision History Modal (DRHM)
 * instead of cluttering the statement-list-component with state variables that are only scoped for the DRHM.
 *
 * The overall display of this wrapper is controlled like a normal modal in the statement-list component, this allows actions
 * in higher scopes to cancel this flow (by changing the boolean variable that controls this wrappers display in the parent).
 *
 * The display of the DRHM is also controlled locally with `showHistoryModalLocally`, since some actions require separate
 * confirmation modals to appear, we need a way to hide the DRHM but store some state about it
 */
class DeletedRevisionHistoryStateWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRevisionId: null,
      isLastRevisionInHistory: false,
      showHistoryModalLocally: true, // handles the showing and hiding of the history modal within this scope
      showDeleteConfirmationModal: false,
      showRestoreRevisionErrorModal: false,
    };
  }

  _onHistoryDeleteSelected = ({ revisionId, lastRevision }) => {
    this.setState({
      selectedRevisionId: revisionId,
      isLastRevisionInHistory: lastRevision,
      showHistoryModalLocally: false,
      showDeleteConfirmationModal: true,
    });
  };

  _onHistoryRestoreSelected = async ({ revisionId }) => {
    const {
      refreshStatementList,
      closeWholeFlowFromParent,
      selectedStatement,
    } = this.props;
    try {
      await restoreDeletedRevisionRequest(revisionId, selectedStatement.id);

      this.setState({ showHistoryModalLocally: false });
      refreshStatementList();
      closeWholeFlowFromParent();
    } catch (error) {
      const statusCode = error.response.status;
      /** Failed response returns with 2 errors for now: server error (500) and bad request; action not allowed(400) We need to deferenciate these so we can display appropriate error message. We need to handle error status 400*/
      if (statusCode === BAD_REQUEST) {
        this.setState({
          showHistoryModalLocally: false,
          showRestoreRevisionErrorModal: true,
        });
      }
    }
  };

  _onDeleteConfirmation = async () => {
    const {
      closeWholeFlowFromParent,
      refreshStatementList,
      permanentlyDeleteRevision,
      selectedStatement,
    } = this.props;
    const { isLastRevisionInHistory, selectedRevisionId } = this.state;
    // TODO error handling for failed request
    await permanentlyDeleteRevision(selectedRevisionId, selectedStatement.id);

    if (isLastRevisionInHistory) {
      // if the last revision was deleted, we need to close the modal completely (from the parent)
      this.setState({ showDeleteConfirmationModal: false });
      refreshStatementList();
      closeWholeFlowFromParent(); // closes from parent, thus clearing all react state
    } else {
      this.setState({
        showDeleteConfirmationModal: false,
        showHistoryModalLocally: true,
      });
    }
  };

  _onCancelDelete = () => {
    this.setState({
      showDeleteConfirmationModal: false,
      showHistoryModalLocally: true,
    });
  };

  _onCloseRestoreModal = () => {
    this.setState({
      showRestoreRevisionErrorModal: false,
      showHistoryModalLocally: false,
    });
  };

  _onOkayConfirm = () => {
    this.setState({
      showRestoreRevisionErrorModal: false,
      showHistoryModalLocally: true,
    });
  };

  render() {
    const { project, selectedStatement, closeWholeFlowFromParent } = this.props;
    const {
      showHistoryModalLocally,
      showDeleteConfirmationModal,
      showRestoreRevisionErrorModal,
    } = this.state;

    return (
      <>
        {showHistoryModalLocally && (
          <DeletedHistoryModal
            project={project}
            toggleStatementRevisonsModal={closeWholeFlowFromParent}
            selectedStatement={selectedStatement}
            onRestore={this._onHistoryRestoreSelected}
            onDelete={this._onHistoryDeleteSelected}
          />
        )}
        {showDeleteConfirmationModal && (
          <ConfirmDeleteRevisionModal
            statementToDelete={selectedStatement}
            onClose={this._onCancelDelete}
            onDelete={this._onDeleteConfirmation}
          />
        )}
        {showRestoreRevisionErrorModal && (
          <RestoreRevisionErrorModal
            toggleRestoreModal={this._onCloseRestoreModal}
            onOkay={this._onOkayConfirm}
          />
        )}
      </>
    );
  }
}

DeletedRevisionHistoryStateWrapper.propTypes = {
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Selected statement viewing deleted revisions of */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** function for controlling the display of the wrapper from the parent*/
  closeWholeFlowFromParent: PropTypes.func.isRequired,
  /** function fired to request the api to delete a selected revision*/
  permanentlyDeleteRevision: PropTypes.func.isRequired,
  /** function for initiating a call to update the deleted statements tab of the statement list, usefull if all revisions were deleted from a given statement */
  refreshStatementList: PropTypes.func.isRequired,
};

export default memo(DeletedRevisionHistoryStateWrapper);
