/* eslint-disable eqeqeq */
import React, { memo } from 'react';
import shortid from 'shortid';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import User from 'models/data/user-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import {
  focusNextMenuItem,
  focusPrevMenuItem,
} from 'utils/menu-accessibility-utils';
import DataGrid from 'components/common/data-grid/data-grid-component';
import DataGridDataApi from 'models/utils/common/data-grid/data-grid-data-model';
import DataGridColumn from 'models/utils/common/data-grid/data-grid-column-model';

import { ReactComponent as CheckMark } from 'icons/checkmarkDropdown.svg';
import ConditionalRender from '../util/conditional-render-component';
import MenuApiData from 'models/utils/common/menu/menu-api-data-model';
import Tooltip from 'components/common/tool-tip-component';
import {
  SPACEBAR_KEY_CODE,
  TAB_KEY_CODE,
  ARROW_KEY_DOWN_CODE,
  ARROW_KEY_UP_CODE,
} from 'constants/util/key-code-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import { extractPropFromFunctionOrValue } from 'utils/object-utils';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { getNumVisibleRows } from 'utils/menu-utility';
import Checkbox from 'components/common/checkbox-component';

const MENU_BLOCK = 'menu';
const CHECKMARK_SIZE = '18px';

const NUM_VISIBLE_ROWS_WHILE_LOADING = 4;
const MIN_NUM_VISIBLE_ROWS_FOR_SCROLL = 4;
const MIN_VALUE_LEFT_FOR_BOTTOM_VISIBILITY = 5;
const MENU_OPTION_HEIGHT = 45; // px
const DEFAULT_POP_UP_VIEWPORT_BOTTOM_THRESHOLD =
  NUM_VISIBLE_ROWS_WHILE_LOADING * MENU_OPTION_HEIGHT; // px, used in instance where no options are available to count

const MenuTypeDecider = (props) =>
  props.usePortal ? (
    <PortalMenu {...props}>{props.children}</PortalMenu>
  ) : (
    <NormalMenu {...props}>{props.children}</NormalMenu>
  );

MenuTypeDecider.propTypes = {
  /** indicates if menu should use a react portal or normal child element implementation */
  usePortal: PropTypes.bool,
};

/**
 * Provides convenience methods necessary for page overflow logic shared by NormalMenu's and PortalMenus
 * Extends React.Component for the convenience of the class that extend it (since ES6 classes cannot extend mutliple classes)
 * but the render method _must_ be overwritten in any component that extends it
 */
class MenuHelper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      left: 'auto',
      bottom: 'auto',
      maxHeight: 'auto',
      right: 'auto',
    };
    this.scrollParent = null;
  }

  _getOverflowData = () => {
    const { wrapperRef, options, numberOfVisibleRows } = this.props;
    const defaultOverflow = {
      top: false,
      bottom: false,
    };
    if (isNullOrUndefined(this.scrollParent)) {
      // called from render method before scroll parent has been established
      return {
        window: defaultOverflow,
        scrollParent: defaultOverflow,
      };
    }
    let windowOverflow = defaultOverflow;
    let scrollParentOverflow = defaultOverflow;
    const boundingRect = wrapperRef.getBoundingClientRect();
    const menuHeight = isNullOrUndefined(numberOfVisibleRows)
      ? options.length * MENU_OPTION_HEIGHT
      : numberOfVisibleRows * MENU_OPTION_HEIGHT;
    const bottomThreshold = isNullOrUndefined(options)
      ? DEFAULT_POP_UP_VIEWPORT_BOTTOM_THRESHOLD
      : menuHeight;
    // window overflow detection
    const overflowTopOfPage = menuHeight > window.innerHeight;
    const overflowBottomOfPage =
      boundingRect.bottom + bottomThreshold + window.pageYOffset >
      document.documentElement.offsetHeight; //offsetHeight is the full page height (all scrollable area) NOT viewport height
    windowOverflow = {
      top: overflowTopOfPage,
      bottom: overflowBottomOfPage,
    };

    // TODO implement scrollParent overflow logic if necessary

    return {
      window: windowOverflow,
      scrollParent: scrollParentOverflow,
    };
  };

  /**
   * Copied from  https://stackoverflow.com/a/42543908/9904038 which is a modified implementation
   * of jQueryUI's `scrollParent` function
   */
  getScrollParent = (element, includeHidden) => {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = includeHidden
      ? /(auto|scroll|hidden)/
      : /(auto|scroll)/;

    if (style.position === 'fixed') return document.body;
    for (let parent = element; (parent = parent.parentElement); ) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === 'static') {
        continue;
      }
      if (
        overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
      )
        return parent;
    }

    return window;
  };

  // eslint-disable-next-line react/require-render-return
  render() {
    throw Error(
      "Menu Provider's render method needs to be overwritten in the inheriting class",
    );
  }
}

MenuHelper.propTypes = {
  /** Reference to drawer triggering element (needed to calculate menu position since implemented with portals) */
  wrapperRef: PropTypes.instanceOf(Element),
  /** Array of menu items  */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(User)),
    PropTypes.arrayOf(PropTypes.instanceOf(MenuOption)),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  /** Optional number representing how many visible rows should be displayed */
  numberOfVisibleRows: PropTypes.number,
};

/**
 * Implements a menu using React.CreatePortal which places the html generated for the menu in the #menu-root block (search for #menu-root in the project to find where the menu is inserted)
 * Portals allow components to exist as direct _React_ children (receiving props from parent components) but be physically located elsewhere
 * This solves the problem of needing menus inside independent scroll containers (like a data grid that scrolls but has a kebab menu for each row)
 * but allows those menu's to be visible OUTSIDE the scroll container
 * In order to create a scroll container, some parent component needs to set it's overflow property to hidden, which does not allow any html element
 * that is a child to overflow it, regardless of positioning
 */
class PortalMenu extends MenuHelper {
  componentDidMount() {
    const { wrapperRef } = this.props;
    if (wrapperRef && !this.scrollParent) {
      // scroll parent may not be window, so set it on whatever controls scrolling of the wrapperRef
      this.scrollParent = this.getScrollParent(wrapperRef);

      this.scrollParent.addEventListener('scroll', this._trackPortalPosition);
      this._trackPortalPosition();
    }
  }

  componentWillUnmount() {
    this.scrollParent.removeEventListener('scroll', this._trackPortalPosition);
  }

  /**
   * IMPORTANT: these calculations only work because #menu-root in menu-component.scss has position relative which allows
   * us to do all calculations based on menu root instead of sometimes on the scroll parent and some times on the window
   * Since menu get's position relative, it orients itself after all of the content, this is why the top values need to be negative the distance between the trigger and the end of the page
   * it pulls them back up into the page view
   * boundingRect.bottom = the page y position of the bottom of the wrapperRef (drawer triggering component) relative to the viewPort (not page height)
   * currentPageScroll = distance the page has been scrolled (absolute positioning is relative to the page height, not the viewport, which is why boundingRect.top is not enough as it changes due to scrolling)
   */
  _trackPortalPosition = () => {
    const { wrapperRef, numberOfVisibleRows } = this.props;
    const boundingRect = wrapperRef.getBoundingClientRect();
    const currentPageScroll = window.pageYOffset;
    const heightBetweenWrapperAndBottomOfPage =
      document.documentElement.offsetHeight -
      currentPageScroll -
      boundingRect.bottom;
    const menuOverflowMap = this._getOverflowData();
    if (menuOverflowMap.window.bottom) {
      this.setState({
        top: -heightBetweenWrapperAndBottomOfPage,
        left: boundingRect.left,
        // calculates distance between bottom of control and scrollable height so it fits exactly between
        maxHeight: heightBetweenWrapperAndBottomOfPage,
        bottom: 'auto',
      });
    } else {
      // menu scrolls normally
      this.setState({
        top: -heightBetweenWrapperAndBottomOfPage,
        maxHeight: numberOfVisibleRows
          ? numberOfVisibleRows * MENU_OPTION_HEIGHT
          : 'auto',
        left: boundingRect.left,
        bottom: 'auto',
      });
    }
  };

  render() {
    const {
      children,
      wrapperRef,
      className,
      centerMenuDisplay,
      numberOfVisibleRows,
      options,
    } = this.props;
    const { top, bottom, left, maxHeight } = this.state;
    const menuOverflowMap = this._getOverflowData();
    return ReactDOM.createPortal(
      <ul
        className={classnames(
          MENU_BLOCK,
          className,
          menuOverflowMap.window.bottom ||
            menuOverflowMap.scrollParent.bottom ||
            // In this case we have specified we only want to see a certain number of elements, but we should only show
            // a scrollbar if they extend past the minimum we want to see on the page.
            (maxHeight !== 'auto' && options.length > numberOfVisibleRows)
            ? `${MENU_BLOCK}--overflow-scroll`
            : null,
          centerMenuDisplay ? `${MENU_BLOCK}--centered` : null,
        )}
        style={{
          top,
          left,
          bottom,
          width: wrapperRef.offsetWidth,
          maxHeight,
        }}
      >
        {children}
      </ul>,
      document.getElementById('menu-root'),
    );
  }
}

class NormalMenu extends MenuHelper {
  componentDidMount() {
    const { wrapperRef } = this.props;
    if (wrapperRef && !this.scrollParent) {
      // scroll parent may not be window, so set it on whatever controls scrolling of the wrapperRef
      this.scrollParent = this.getScrollParent(wrapperRef);
      this._calculateMenuDimensions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options && this.props.wrapperRef) {
      this._calculateMenuDimensions();
    }
  }

  _calculateMenuDimensions = () => {
    const {
      wrapperRef,
      numberOfVisibleRows,
      options,
      className,
      containerRef,
    } = this.props;
    const boundingRect = wrapperRef.getBoundingClientRect();
    const currentPageScroll = window.pageYOffset;
    const heightBetweenWrapperAndBottomOfPage =
      document.documentElement.offsetHeight -
      currentPageScroll -
      boundingRect.bottom -
      MIN_VALUE_LEFT_FOR_BOTTOM_VISIBILITY; //minimum value so that dropdown option border should be properly visible from bottom
    const menuOverflowMap = this._getOverflowData();
    /**
     * We need to compute the maxHeight of the number of menu items that we want to display.
     * By default we will only be manipulating the height of the menu if there is an overflow, triggered
     * by checking the heightBetweenWrapperAndBottomOfPage, which indicates that the menu has gone past the end of the page.
     * There is also a check against the numberOfVisibleRows prop however, in the case that the menu has NOT overflown the page
     * but it has a set height that we want to utilize based on passed-in props.
     */

    let maxHeight = 'auto';
    let top = boundingRect.height;
    let right = 'auto';

    //statement list kebab-menu
    if (className == 'statement-list__kebab-menu') {
      const menuHeight = isNullOrUndefined(numberOfVisibleRows)
        ? options.length * MENU_OPTION_HEIGHT
        : numberOfVisibleRows * MENU_OPTION_HEIGHT;
      const footerHeight = 90; //apporximate, need to find a way for accurate value

      //case we are using virtualized list
      if (containerRef) {
        const fatherProperties = containerRef.current.getBoundingClientRect();
        const shouldAdjustTop =
          boundingRect.bottom + menuHeight > fatherProperties.bottom;
        top = shouldAdjustTop ? 0 - menuHeight : 0;
        right = boundingRect.width - 15;

        if (numberOfVisibleRows) {
          maxHeight = numberOfVisibleRows * MENU_OPTION_HEIGHT;
        }
        this.setState({
          top: top, // absolute positioned RELATIVE TO wrapperRef
          width: wrapperRef.offsetWidth,
          maxHeight: maxHeight,
          right: right,
        });
      } else {
        let availableSpace =
          window.pageYOffset +
          window.innerHeight -
          (window.pageYOffset + boundingRect.bottom + footerHeight);

        if (availableSpace < menuHeight) {
          top = availableSpace - menuHeight;
          right = boundingRect.width - 15;
        }

        if (numberOfVisibleRows) {
          maxHeight = numberOfVisibleRows * MENU_OPTION_HEIGHT;
        }
        this.setState({
          top: top, // absolute positioned RELATIVE TO wrapperRef
          width: wrapperRef.offsetWidth,
          maxHeight: maxHeight,
          right: right,
        });
      }
    } else {
      //all other components
      if (menuOverflowMap.window.bottom) {
        maxHeight = heightBetweenWrapperAndBottomOfPage;
      } else if (numberOfVisibleRows) {
        maxHeight = numberOfVisibleRows * MENU_OPTION_HEIGHT;
      }

      this.setState({
        top: top, // absolute positioned RELATIVE TO wrapperRef
        width: wrapperRef.offsetWidth,
        maxHeight: maxHeight,
      });
    }
  };

  render() {
    const {
      children,
      className,
      numberOfVisibleRows = MIN_NUM_VISIBLE_ROWS_FOR_SCROLL,
      options,
    } = this.props;
    const { top, maxHeight, right } = this.state;
    const menuOverflowMap = this._getOverflowData();
    const isDropdownHasCheckbox = options.find(
      (element) => element.isCheckboxRequired,
    );
    /* Checks if menu is at bottom of screen or will touch the bottom of screen;
       maxHeight has changed from the default auto and length of options is greater than num of visible rows with default set to 4
       OR check if options length is greater than num of rows and maxHeight not auto
    */
    const shouldDisplayOverflowScroll =
      ((menuOverflowMap.window.bottom || menuOverflowMap.scrollParent.bottom) &&
        maxHeight !== 'auto' &&
        options.length > numberOfVisibleRows) ||
      (options.length > numberOfVisibleRows && maxHeight !== 'auto');

    return (
      <ul
        className={classnames(
          MENU_BLOCK,
          className,
          shouldDisplayOverflowScroll ? `${MENU_BLOCK}--overflow-scroll` : null,
          isDropdownHasCheckbox
            ? `${MENU_BLOCK}__dropdown-menu-withcheckbox`
            : null,
        )}
        // normal menu's inherit much of their position properties from their parent
        // left, width, and bottom should all be targeted with css on a case by case basis for normal (non portal) menus
        style={{
          top,
          maxHeight,
          right:
            className == 'statement-list__kebab-menu' && right != 'auto'
              ? right
              : null,
        }}
      >
        {children}
      </ul>
    );
  }
}

const DefaultMenuItems = ({
  options,
  onSelectOption,
  toggleDrawer,
  isNotIntl,
  selection,
  intl,
  hideCheckmarks,
  centerMenuDisplay,
}) => {
  const _isOptionSelected = (option) => {
    // kebab menu options don't have values, so we want to ensure that a value is present before checking selection
    return (
      !isNullOrUndefined(option.value) &&
      !isNullOrUndefined(selection) &&
      option.value === selection.value
    );
  };

  const _onSelectOption = (item) => {
    if (item.onSelectOption) {
      // for kebabs where items have different actions
      item.onSelectOption();
    }
    if (onSelectOption) {
      // kebabs may not pass an onSelectOption for the whole dropdown
      onSelectOption(item);
    }
    toggleDrawer();
  };

  const keyEventHandler = (e, _isDisabled, item) => {
    if (e.keyCode === SPACEBAR_KEY_CODE) {
      e.preventDefault();
      e.stopPropagation();
      if (!_isDisabled) {
        _onSelectOption(item);
      }
    } else if (e.keyCode === ARROW_KEY_DOWN_CODE) {
      focusNextMenuItem(e);
    } else if (e.keyCode === ARROW_KEY_UP_CODE) {
      focusPrevMenuItem(e);
    } else if (e.keyCode === TAB_KEY_CODE) {
      toggleDrawer();
    }
  };

  const _renderOption = (item, index) => {
    const tooltip = extractPropFromFunctionOrValue(item.tooltip);
    const _isDisabled = extractPropFromFunctionOrValue(item.disabled);
    const _isSelected = item.isSelected
      ? extractPropFromFunctionOrValue(item.isSelected)
      : false;
    if (item.isLabel) {
      return (
        <span className={`${MENU_BLOCK}__optionlabel`}>
          {isNotIntl || !item.isIntl
            ? item.title
            : intl.formatMessage({ id: item.title })}
        </span>
      );
    } else if (item.isCheckboxRequired) {
      return (
        <li
          className={classnames(`${MENU_BLOCK}__checkboxoption`, {
            [`${MENU_BLOCK}__option--selected`]: _isOptionSelected(item),
            [`${MENU_BLOCK}__option--centered`]: centerMenuDisplay,
          })}
          key={shortid.generate()}
          disabled={_isDisabled}
          onClick={(e) => {
            if (!_isDisabled) {
              _onSelectOption(item);
            }
          }}
          tabIndex={-1}
          onKeyDown={(e) => keyEventHandler(e, _isDisabled, item)}
        >
          <Checkbox
            checked={_isSelected}
            onChange={(e) => {
              if (!_isDisabled) {
                _onSelectOption(item);
              }
            }}
            label={
              isNotIntl || !item.isIntl
                ? item.title
                : intl.formatMessage({ id: item.title })
            }
            id={`checkbox-dropdown-${item.id}`}
            width={'100%'}
            isValid={true}
            disabled={_isDisabled}
          />
        </li>
      );
    } else {
      return (
        <li
          className={classnames(
            `${MENU_BLOCK}__option`,
            _isOptionSelected(item) ? `${MENU_BLOCK}__option--selected` : null,
            centerMenuDisplay ? `${MENU_BLOCK}__option--centered` : null,
          )}
          key={shortid.generate()}
          disabled={_isDisabled}
          onClick={(e) => {
            if (!_isDisabled) {
              _onSelectOption(item);
            }
          }}
          tabIndex={-1}
          onKeyDown={(e) => keyEventHandler(e, _isDisabled, item)}
        >
          <Tooltip {...tooltip} active={!isNullOrUndefined(tooltip)}>
            <span disabled={_isDisabled}>
              {isNotIntl || !item.isIntl
                ? item.title
                : intl.formatMessage({ id: item.title })}
              {!hideCheckmarks && _isOptionSelected(item) ? (
                <CheckMark
                  name={'checkmarkDropdown'}
                  className={`${MENU_BLOCK}__checkmark`}
                  width={CHECKMARK_SIZE}
                  height={CHECKMARK_SIZE}
                />
              ) : null}
            </span>
          </Tooltip>
        </li>
      );
    }
  };

  return (
    <React.Fragment>
      {options.map((item) => _renderOption(item))}
    </React.Fragment>
  );
};

const DefaultApiMenu = (props) => {
  const {
    dataModel,
    onSelectOption,
    selection,
    toggleDrawer,
    hideCheckmarks,
    NoResultsComponent,
  } = props;

  return (
    <MenuTypeDecider {...props} options={dataModel.options}>
      <ConditionalRender dependencies={[dataModel.apiModel]}>
        {dataModel.options.length > 0 ? (
          <DefaultMenuItems
            options={dataModel.options}
            onSelectOption={onSelectOption}
            toggleDrawer={toggleDrawer}
            selection={selection}
            isNotIntl={true}
            hideCheckmarks={hideCheckmarks}
          />
        ) : (
          <NoResultsComponent />
        )}
      </ConditionalRender>
    </MenuTypeDecider>
  );
};

const DefaultApiMenuWithCreate = (props) => {
  const {
    dataModel,
    onSelectOption,
    selection,
    toggleDrawer,
    onCreateClick,
    NoResultsComponent,
    visibleRowsForScroll = MIN_NUM_VISIBLE_ROWS_FOR_SCROLL,
  } = props;
  const _numVisibleRows = getNumVisibleRows({
    dataModel: dataModel.apiModel,
    options: dataModel.options,
    visibleRowsWhileLoading: NUM_VISIBLE_ROWS_WHILE_LOADING,
    visibleRowsForScroll,
  });
  const _numRowsBeyondScrollableThreshold =
    dataModel.apiModel.isLoaded &&
    dataModel.options.length > MIN_NUM_VISIBLE_ROWS_FOR_SCROLL;
  return (
    <MenuTypeDecider
      {...props}
      options={dataModel.options}
      numberOfVisibleRows={_numVisibleRows}
    >
      <ConditionalRender dependencies={[dataModel.apiModel]}>
        {dataModel.options.length > 0 ? (
          <DefaultMenuItems
            options={dataModel.options}
            onSelectOption={onSelectOption}
            toggleDrawer={toggleDrawer}
            selection={selection}
            isNotIntl={true}
            scrollable={_numRowsBeyondScrollableThreshold}
            numberOfVisibleRows={_numVisibleRows}
          />
        ) : NoResultsComponent ? (
          <div className={`${MENU_BLOCK}__no-results`}>
            <NoResultsComponent />
          </div>
        ) : null}
        <Button.IconButton
          className={`${MENU_BLOCK}__option--create-button`}
          types={BUTTON_TYPES.icon}
          Icon={PlusIcon}
          onClick={onCreateClick}
          disabled={dataModel.options.length > 0}
        >
          <FormattedMessage id="common.create" />
        </Button.IconButton>
      </ConditionalRender>
    </MenuTypeDecider>
  );
};

DefaultApiMenuWithCreate.propTypes = {
  /** Function that fires when the create button is clicked */
  onCreateClick: PropTypes.func.isRequired,
  /** Custom component to display under the column headers when there the rowItems property of the dataModel is an empty [] */
  NoResultsComponent: PropTypes.element,
  /** Min number of rows to display for scroll */
  visibleRowsForScroll: PropTypes.number,
};

DefaultMenuItems.propTypes = {
  /** Sometimes the individual options have their own individual function handlers */
  /** Array of menu items  */
  options: PropTypes.arrayOf(PropTypes.instanceOf(MenuOption)).isRequired,
  /** Function fired when menu item is selected passing the value of that item */
  onSelectOption: PropTypes.func,
  /** HOC Function fired to toggle drop down menu */
  toggleDrawer: PropTypes.func.isRequired,
  /** Value of current selection, needs to be one of the values defined in the options.value constructor in whatever constants file originally defines the options */
  selection: PropTypes.oneOfType([
    PropTypes.instanceOf(MenuOption),
    PropTypes.string,
  ]),
  /** custom className applied to the <ul> */
  className: PropTypes.string,
  /** Reference to drawer triggering element (needed to calculate menu position since implemented with portals) */
  wrapperRef: PropTypes.instanceOf(Element),
};

DefaultApiMenu.propTypes = {
  /** Sometimes the individual options have their own individual function handlers */
  /** Array of menu items  */
  dataModel: PropTypes.instanceOf(MenuApiData).isRequired,
  /** Function fired when menu item is selected passing the value of that item */
  onSelectOption: PropTypes.func,
  /** HOC Function fired to toggle drop down menu */
  toggleDrawer: PropTypes.func.isRequired,
  /** Flag for non internationalized menu options */
  isNotIntl: PropTypes.bool,
  /** Value of current selection, needs to be one of the values defined in the options.value constructor */
  selection: PropTypes.string,
  /** custom className applied to the <ul> */
  className: PropTypes.string,
  /** Reference to drawer triggering element (needed to calculate menu position since implemented with portals) */
  wrapperRef: PropTypes.instanceOf(Element),
  /** indicates if menu should use a react portal or normal child element implementation */
  usePortal: PropTypes.bool,
  /** indicates if the menu should hide the checkmark next to selected values. */
  hideCheckmarks: PropTypes.bool,
  /** indicates if the menu should be centered */
  centerMenuDisplay: PropTypes.bool,
};

const DefaultTableApiMenu = (props) => {
  const {
    columns,
    dataModel,
    onSelectOption,
    tableId,
    toggleDrawer,
    NoResultsComponent,
    isOptionSelected,
    shouldDisableMenuOption,
  } = props;
  const _rowClassnames = (rowData) =>
    isOptionSelected && isOptionSelected(rowData)
      ? `${MENU_BLOCK}__option--selected`
      : null;

  const _numVisibleRows = getNumVisibleRows({
    dataModel: dataModel.apiModel,
    options: dataModel.rowItems,
    visibleRowsWhileLoading: NUM_VISIBLE_ROWS_WHILE_LOADING,
    visibleRowsForScroll: MIN_NUM_VISIBLE_ROWS_FOR_SCROLL,
  });
  return (
    <MenuTypeDecider
      {...props}
      options={dataModel.rowItems}
      className={`${MENU_BLOCK}__default-table-api-menu`}
      numberOfVisibleRows={_numVisibleRows + 1} // used for height calculation, +1 indicates the table column header row
    >
      <DataGrid
        columns={columns}
        dataModel={dataModel}
        className={`${MENU_BLOCK}__table-drawer`}
        rowClassnames={_rowClassnames}
        shouldDisableRow={shouldDisableMenuOption}
        onRowClick={(rowData) => {
          onSelectOption(rowData);
          toggleDrawer();
        }}
        tableId={tableId}
        numberOfVisibleRows={_numVisibleRows}
        NoResultsComponent={NoResultsComponent}
      />
    </MenuTypeDecider>
  );
};

DefaultTableApiMenu.propTypes = {
  /** An array of column objects */
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.arrayOf(PropTypes.instanceOf(DataGridColumn)),
  ]),
  /** Contains the rowItems array and api model for loading */
  dataModel: PropTypes.instanceOf(DataGridDataApi),
  /** Function, triggered when clicking on a row, passes the current clicked row object */
  onSelectOption: PropTypes.func.isRequired,
  /** Unique key string for each table */
  tableId: PropTypes.string.isRequired,
  /* Number of visual data rows, controls scrolling */
  numberOfVisibleRows: PropTypes.number,
  /** HOC Function fired to toggle drop down menu */
  toggleDrawer: PropTypes.func.isRequired,
  /** Custom component to display under the column headers when there the rowItems property of the dataModel is an empty [] */
  NoResultsComponent: PropTypes.element,
  /** custom className applied to the <ul> */
  className: PropTypes.string,
  /** Reference to drawer triggering element (needed to calculate menu position since implemented with portals) */
  wrapperRef: PropTypes.instanceOf(Element),
  /** Function to determine if a specific option is already selected, func should look like (rowData) => someFunction(rowData)
   * And evaluate to a boolean
   */
  isOptionSelected: PropTypes.func,
  /** indicates if menu should use a react portal or normal child element implementation */
  usePortal: PropTypes.bool,
  /** Function that determines if a particular row should be disabled based on if the user has already been selected */
  shouldDisableMenuOption: PropTypes.func,
};

const Menu = (props) => (
  <MenuTypeDecider {...props}>
    <DefaultMenuItems {...props} />
  </MenuTypeDecider>
);

const ApiMenu = memo(injectIntl(DefaultApiMenu));

const ApiMenuWithCreate = memo(injectIntl(DefaultApiMenuWithCreate));

const TableApiMenu = memo(DefaultTableApiMenu);

export default memo(injectIntl(Menu));
export { ApiMenu, TableApiMenu, ApiMenuWithCreate };
