import React from 'react';
import moment from 'moment';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';

import { DataGridColumn } from 'components/common/data-grid/data-grid-component';
import { isNullOrUndefined } from 'utils/object-utils';

import { FormattedMessage } from 'react-intl';

import {
  OMNIA_ENGAGEMENT_LIST_BLOCK,
  OMNIA_ENGAGEMENT_LIST_ID_BLOCK,
} from 'components/feature/omnia/_omnia-engagement-list-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import Permissions from 'permissions/permissions';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';

export const getEngagementListColumns = ({
  onProjectClick,
  handleOnClickLink,
  handleOnClickUnlink,
  omniaEngagement,
}) => {
  return [
    new DataGridColumn({
      sortable: false,
      header: 'omnia.engagement-list.column-header.project-name',
      width: '20%',
      formatter: (engagement, { rowKey }) => {
        const colId = `engagement-list-project-name-${rowKey}`;
        return (
          <div
            className={`${OMNIA_ENGAGEMENT_LIST_BLOCK}__project-name`}
            onClick={() =>
              onProjectClick({ projectId: engagement.toutProjectId })
            }
          >
            <MultiLineEllipsis
              toolTipID={colId}
              text={engagement.toutProjectName}
              isNotInternationalized
              maxLines={2}
            />
          </div>
        );
      },
    }),
    new DataGridColumn({
      sortable: false,
      header: 'omnia.engagement-list.column-header.engagement-details',
      width: '20%',
      formatter: (engagement, { rowKey }) => {
        return (
          <>
            <div className={`${OMNIA_ENGAGEMENT_LIST_BLOCK}__engagement-name`}>
              <MultiLineEllipsis
                toolTipID={`engagement-list-engagement-name-${rowKey}`}
                text={engagement.toutEngagementName}
                isNotInternationalized
              />
            </div>
            <MultiLineEllipsis
              toolTipID={`engagement-list-client-name-${rowKey}`}
              text={engagement.toutClientName}
              isNotInternationalized
            />
          </>
        );
      },
    }),
    new DataGridColumn({
      sortable: false,
      header: 'omnia.engagement-list.column-header.linked-omnia-egagement',
      width: '25%',
      Icon: OmniaIcon,
      formatter: (engagement, { rowKey }) => {
        return (
          <>
            <div
              className={`${OMNIA_ENGAGEMENT_LIST_BLOCK}__om-engagement-name`}
            >
              <MultiLineEllipsis
                toolTipID={`engagement-list-omnia-engagement-name-${rowKey}`}
                text={engagement.omEngagementName}
                isNotInternationalized
              />
            </div>
            <MultiLineEllipsis
              toolTipID={`engagement-list-omnia-client-name-${rowKey}`}
              text={engagement.omClientName}
              isNotInternationalized
            />
          </>
        );
      },
    }),
    new DataGridColumn({
      sortable: false,
      header: 'omnia.engagement-list.column-header.linked-details',
      width: '25%',
      formatter: (engagement) => {
        if (engagement.linkedStatus === 1) {
          return (
            <FormattedMessage
              id={'omnia.engagement-list.linked.details'}
              values={{
                userName: engagement.linkedUserName,
                date: moment(engagement.linkedDate).format(
                  DATE_FORMATS.MONTH_DAY_YEAR,
                ),
              }}
            />
          );
        } else if (engagement.linkedStatus === 0) {
          return (
            <FormattedMessage
              id={'omnia.engagement-list.unlinked.details'}
              values={{
                userName: engagement.linkedUserName,
                date: moment(engagement.linkedDate).format(
                  DATE_FORMATS.MONTH_DAY_YEAR,
                ),
              }}
            />
          );
        } else {
          return (
            <div className={`${OMNIA_ENGAGEMENT_LIST_BLOCK}__not-linked`}>
              <FormattedMessage
                id={'omnia.engagement-list.no-engagements-linked'}
              />
            </div>
          );
        }
      },
    }),
    new DataGridColumn({
      sortable: false,
      header: 'omnia.engagement-list.column-header.action',
      width: '10%',
      formatter: (engagement) => {
        const _permissionToLinkOmnia = Permissions.Omnia.canEditOmniaEngagements(
          engagement.toutProjectId,
        );
        return isNullOrUndefined(engagement.linkedStatus) ||
          engagement.linkedStatus === 0 ? (
          <Button
            id={`${engagement.omEngagementId}-link-button`}
            type={BUTTON_TYPES.icon}
            onClick={() => handleOnClickLink(engagement)}
            disabled={
              !_permissionToLinkOmnia ||
              isNullOrUndefined(omniaEngagement.omniaEngagementEntity)
            }
          >
            {!_permissionToLinkOmnia ||
            isNullOrUndefined(omniaEngagement.omniaEngagementEntity) ? (
              <Tooltip
                isNotInternationalized={false}
                {...new TooltipOptions({
                  text: 'omnia.engagement-list.button.disabled-tooltip',
                  id: `${OMNIA_ENGAGEMENT_LIST_ID_BLOCK}-link-${engagement.toutProjectId}`,
                  position: 'bottom',
                })}
              >
                <div>
                  <FormattedMessage id={'omnia.engagement-list.button.link'} />
                </div>
              </Tooltip>
            ) : (
              <FormattedMessage id={'omnia.engagement-list.button.link'} />
            )}
          </Button>
        ) : (
          <Button
            id={`${engagement.omEngagementId}-unlink-button`}
            type={BUTTON_TYPES.icon}
            onClick={() => handleOnClickUnlink(engagement)}
            disabled={!_permissionToLinkOmnia}
          >
            {!_permissionToLinkOmnia ? (
              <Tooltip
                isNotInternationalized={false}
                {...new TooltipOptions({
                  text: 'omnia.engagement-list.button.disabled-tooltip',
                  id: `${OMNIA_ENGAGEMENT_LIST_ID_BLOCK}-link-${engagement.toutProjectId}`,
                  position: 'bottom',
                })}
              >
                <div>
                  <FormattedMessage
                    id={'omnia.engagement-list.button.unlink'}
                  />
                </div>
              </Tooltip>
            ) : (
              <FormattedMessage id={'omnia.engagement-list.button.unlink'} />
            )}
          </Button>
        );
      },
    }),
  ];
};
