import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import Search from 'components/common/search-component';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { getRevisionsSectionsRequest } from 'api/sections-api';

export const NOTES_STATEMENT_SEGMENT_FILTERS_ID_BLOCK =
  'notes-statement-segment-id';

export const NotesStatementSegmentFilter = ({
  className,
  revisionId,
  selectedSegments,
  updateSelectedSegmentsObject,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [sections, setSections] = React.useState(
    new SectionTreeList({ isLoading: true }),
  );

  let validSearchTerm = '';
  if (searchTerm.length > 1) {
    validSearchTerm = searchTerm;
  }

  React.useEffect(() => {
    const fetchSectionList = async () => {
      try {
        const response = await getRevisionsSectionsRequest({
          revisionId: revisionId,
        });
        console.log(sections.filteredSectionsHierarchy(validSearchTerm));
        setSections(sections.setLoaded({ response }));
      } catch (error) {
        setSections(sections.setError(error));
      }
    };
    if (!sections.hasBeenFetched) {
      fetchSectionList();
    }
  });

  return (
    <div className={`${className}__statement-segment`}>
      <Search
        className={`${className}__statement-segment-search`}
        id={`${className}-search`}
        onChange={(val) => setSearchTerm(val)}
        onClear={() => {
          setSearchTerm('');
        }}
        placeholder="notes-panel.advanced-filter.statement-segment.search.placeholder"
        showSearchButton
        value={searchTerm}
        isValid
        disableAutoComplete
      />
      <ConditionalRender dependencies={[sections]}>
        {sections.filteredSectionsHierarchy(validSearchTerm).map((section) => (
          <Checkbox
            key={section.id}
            id={`${NOTES_STATEMENT_SEGMENT_FILTERS_ID_BLOCK}-${section.id}`}
            isNotIntl
            label={section.name}
            value={section}
            checked={Boolean(selectedSegments[section.id])}
            onChange={(section) => updateSelectedSegmentsObject(section)}
          />
        ))}
      </ConditionalRender>
    </div>
  );
};

const mapStateToProps = ({
  data: {
    revision,
    statementContent: {
      sectionsCache: {
        contentSectionMap: { data: map },
      },
    },
  },
}) => {
  return {
    contentSectionMap: map,
    revisionId: revision.id,
  };
};

NotesStatementSegmentFilter.propTypes = {
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  // id of current revision
  revisionId: PropTypes.number.isRequired,
  /** object of all the selected segments */
  selectedSegments: PropTypes.object.isRequired,
  /** method to updated the object of selected segments */
  updateSelectedSegmentsObject: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(NotesStatementSegmentFilter);
