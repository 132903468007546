import ApiModel from 'models/api-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { isNullOrUndefined } from 'utils/object-utils';

// N/A is always the default value provided at time of creation of entity
export const DEFAULT_ENTITY_STRING = 'N/A';

export class EntityList extends ApiModel({
  data: {
    entities: [],
    entitiesDropdownMap: {},
  },
  status: null,
}) {
  get entities() {
    if (!isNullOrUndefined(this.data) && Array.isArray(this.data.entities)) {
      return this.data.entities;
    }
    return [];
  }
  get entitiesDropdownMap() {
    if (this.data && this.data.entitiesDropdownMap) {
      return this.data.entitiesDropdownMap;
    }
    return null;
  }
  processResponse({ response }) {
    let entityMenuOptions = {};
    /** The response looks something like
     *
     * entityDetails = [
     *  {
     *    cliendId: 1,
     *    entity: N/A,
     *    priority: 1,
     *    lastModifiedDate: 152343423423
     *  }
     * ]
     */

    if (
      !isNullOrUndefined(response.data) &&
      !isNullOrUndefined(response.data.result)
    ) {
      let index = 1;
      response.data.result.forEach((entity) => {
        if (!isNullOrUndefined(entity)) {
          entityMenuOptions[entity.entity] = new MenuOption({
            id: index,
            title: entity.entity,
            value: entity.entity,
            isIntl: false,
          });
          index++;
        }
      });
    }

    // If we dont get default value('N/A') from server, we need to add it manually at FE side
    if (entityMenuOptions && !entityMenuOptions[DEFAULT_ENTITY_STRING]) {
      entityMenuOptions[DEFAULT_ENTITY_STRING] = new MenuOption({
        id: Object.keys(entityMenuOptions).length + 1,
        title: DEFAULT_ENTITY_STRING,
        value: DEFAULT_ENTITY_STRING,
        isIntl: false,
      });
      response.data.result.push({
        clientId:
          response &&
          response.data &&
          response.data.result[0] &&
          response.data.result[0].clientId
            ? response.data.result[0].clientId
            : 0,
        entity: DEFAULT_ENTITY_STRING,
        lastModifiedDate: Date.now(),
        manual: false,
      });
    }
    return {
      data: {
        entities: response.data.result,
        entitiesDropdownMap: entityMenuOptions,
      },
      status: response.status,
    };
  }

  getEntityDropdownValues(searchTerm) {
    return Object.values(this.data.entitiesDropdownMap).filter(
      (entity) =>
        entity.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
    );
  }
}
