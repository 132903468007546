import { handleActions } from 'redux-actions';
import {
  statementSummaryNotesLoading,
  statementSummaryNotesLoaded,
  statementSummaryNotesError,
  updateSummaryNotesFromWebsocket,
} from 'store/actions/statement-summary/notes-summary-actions';
import StatementSummaryNotes from 'models/api/statement-summary-notes-model';

export default handleActions(
  {
    [statementSummaryNotesLoading]: (noteSummary) => {
      return noteSummary.setLoading();
    },
    [statementSummaryNotesError]: (noteSummary, { payload }) => {
      return noteSummary.setError(payload);
    },
    [statementSummaryNotesLoaded]: (noteSummary, { payload }) => {
      return noteSummary.setLoaded(payload);
    },
    [updateSummaryNotesFromWebsocket]: (noteSummary, { payload }) => {
      return noteSummary.updateSummaryFromWebsocket(payload);
    },
  },
  new StatementSummaryNotes(),
);
