import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';

import Statement from 'models/data/statement-model';

const PERMANENTLY_DELETE_REVISION_ID_BLOCK =
  'id-permanently-delete-revision-modal';

const PermanentlyDeleteSingleRevisionModal = ({
  statementToDelete,
  onClose,
  onDelete,
}) => {
  return (
    <Modal
      id={PERMANENTLY_DELETE_REVISION_ID_BLOCK}
      title={'statement-list.permanent-delete-revision-modal.single.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.delete',
          onClick: onDelete,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
    >
      <Banner
        id={`${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={
          'statement-list.delete-statement-modal.banner-copy-permanent'
        }
        isInternationalized={true}
        isInteractive={false}
      />
    </Modal>
  );
};

PermanentlyDeleteSingleRevisionModal.propTypes = {
  /** Statement model holding the revisions that can be deleted */
  statementToDelete: PropTypes.instanceOf(Statement).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when deletion is confirmed */
  onDelete: PropTypes.func.isRequired,
};

export default memo(PermanentlyDeleteSingleRevisionModal);
