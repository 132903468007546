import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatManualInputFormulaRowNumber } from 'utils/number-formatter-util';

import { FORMULA_MANUAL_NUMBER_REGEX } from 'constants/feature/formula-constants';
import FormulaRowNumber from 'models/data/formula/formula-row-number-model';
import TextInput from 'components/common/text-input-component';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import shortid from 'shortid';

export const FORMULA_ROW_MANUAL_BLOCK = 'formula-row-manual-input';
export const FORMULA_ROW_MANUAL_ID_BLOCK = 'formula-row-manual-input-id';

class FormulaRowManualInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      numberInput: '',
    };
    this.textInput = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isFocused && !prevState.isFocused) {
      this.textInput.current.focus();
    }
  }

  handleOnFocus = () => {
    const {
      formulaRow,
      checkEmptyInput,
      setSelectedRowNumber,
      formulaRowIndex,
    } = this.props;
    // call checkEmptyInput if input value is 0
    if (formulaRow.value.toString() === '0') checkEmptyInput('');
    this.setState((state) => ({
      isFocused: true,
      numberInput: formulaRow.value.toString() === '0' ? '' : formulaRow.value, // clear 0 value when user focuses else display current value
    }));
    setSelectedRowNumber(formulaRowIndex);
  };

  handleOnBlur = () => {
    const { setManualNumber, formulaRowIndex } = this.props;
    const { numberInput } = this.state;
    this.setState((state) => ({
      isFocused: false,
      numberInput: formatManualInputFormulaRowNumber(state.numberInput),
    }));
    setManualNumber({ index: formulaRowIndex, number: numberInput });
  };

  handleOnChange = (number) => {
    const { checkEmptyInput } = this.props;
    this.setState({
      numberInput: number,
    });
    checkEmptyInput(number);
  };

  render() {
    const { formulaRow } = this.props;
    const { isFocused, numberInput } = this.state;
    const _isNotValid = formulaRow.hasRowError();
    return (
      <div className={`${FORMULA_ROW_MANUAL_BLOCK}`}>
        {!isFocused ? (
          <div
            className={classNames(
              `${FORMULA_ROW_MANUAL_BLOCK}__readonly`,
              _isNotValid
                ? `${FORMULA_ROW_MANUAL_BLOCK}__readonly--invalid`
                : null,
            )}
            onClick={this.handleOnFocus}
          >
            <MultiLineEllipsis
              toolTipID={`${FORMULA_ROW_MANUAL_ID_BLOCK}-${shortid.generate()}`}
              text={formatManualInputFormulaRowNumber(formulaRow.value)}
              maxLines={1}
              isNotInternationalized={true}
            >
              {formulaRow.display}
            </MultiLineEllipsis>
          </div>
        ) : (
          <TextInput
            key={`${FORMULA_ROW_MANUAL_ID_BLOCK}-input-${formulaRow.key}`}
            id={formulaRow.key}
            isValid={!_isNotValid}
            className={classNames(`${FORMULA_ROW_MANUAL_BLOCK}__input`)}
            type="text"
            onPaste={(event) => event.preventDefault()} //We cannot paste text into these input boxes.
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            value={numberInput}
            pattern={FORMULA_MANUAL_NUMBER_REGEX}
            ref={this.textInput}
          />
        )}
      </div>
    );
  }
}

FormulaRowManualInput.propTypes = {
  /** The formula row for this row. */
  formulaRow: PropTypes.instanceOf(FormulaRowNumber),
  /** The index from the formula rows array on the formula form of this specific formula row */
  formulaRowIndex: PropTypes.number.isRequired,
  /** The action to be fired when the formula row needs to be set and validated. */
  setManualNumber: PropTypes.func.isRequired,
  /** Event handler to check for empty input field */
  checkEmptyInput: PropTypes.func.isRequired,
  /* action to save the selected row number */
  setSelectedRowNumber: PropTypes.func.isRequired,
};

export default FormulaRowManualInput;
