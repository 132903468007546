import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ProjectApi from 'models/api/project-api-model';

import Statement from 'models/data/statement-model';
import {
  submitStatementForWrapUpApprovalRequest,
  getStatementSubmitForWrapUpApprovalStatsRequest,
} from 'api/statement-list-api';
import StatementWrapUpStatsModel from 'models/api/statement-wrap-up/statement-wrap-up-stats-api-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import StatementWrapUpStats from './_statement-wrap-up-stats-component';

const STATEMENT_WRAP_UP_SUBMISSION_ID_BLOCK =
  'statement-wrap-up-submission-modal-id';

export const STATEMENT_WRAP_UP_SUBMISSION_BLOCK =
  'statement-wrap-up-submission-modal';

const SubmitStatementWrapUpApprovalModal = ({
  statement,
  project,
  onClose,
  onSubmit,
}) => {
  const [submitStats, setSubmitStats] = useState(
    new StatementWrapUpStatsModel(),
  );
  let notAllElementsVerified = null;
  if (submitStats.isLoaded) {
    notAllElementsVerified =
      submitStats.totalElements !== submitStats.verifiedElements;
  }

  useEffect(() => {
    const loadStatementDetails = async () => {
      setSubmitStats((s) => s.setLoading());
      try {
        const response = await getStatementSubmitForWrapUpApprovalStatsRequest({
          statementId: statement.id,
        });
        setSubmitStats((s) => s.setLoaded({ response }));
      } catch (error) {
        setSubmitStats((s) => s.setError(error));
      }
    };
    loadStatementDetails();
  }, [statement]);

  const handleSubmit = async () => {
    setSubmitStats((s) => s.setLoading());
    try {
      await submitStatementForWrapUpApprovalRequest({
        statementId: statement.id,
      });
      onSubmit();
    } catch (error) {
      setSubmitStats((s) => s.setError(error));
    }
  };
  return (
    <Modal
      id={STATEMENT_WRAP_UP_SUBMISSION_ID_BLOCK}
      className={STATEMENT_WRAP_UP_SUBMISSION_BLOCK}
      title={'statement-list.submit-wrap-up-approval.modal.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'statement-list.submit-wrap-up-approval.modal.submit',
          onClick: handleSubmit,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'statement-list.submit-wrap-up-approval.modal.cancel',
          onClick: onClose,
        })
      }
    >
      <ConditionalRender dependencies={[project, submitStats]}>
        {notAllElementsVerified && (
          <Banner
            id={`${STATEMENT_WRAP_UP_SUBMISSION_ID_BLOCK}-warning-banner`}
            type={BANNER_TYPES.ERROR}
            bannerCopy={{
              id:
                'statement-list.submit-wrap-up-approval.modal.warning.notVerified',
              values: {
                b: (...chunks) => <strong>{chunks}</strong>,
              },
            }}
            isInternationalized={true}
            isInteractive={false}
            className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__banner`}
          />
        )}
        <Banner
          id={`${STATEMENT_WRAP_UP_SUBMISSION_ID_BLOCK}-information-banner`}
          type={BANNER_TYPES.SECONDARY_INFORMATION}
          bannerCopy={'statement-list.submit-wrap-up-approval.modal.warning'}
          isInternationalized={true}
          isInteractive={false}
          className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__banner`}
        />
        <div className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__row`}>
          <div className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__col`}>
            <span className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__label`}>
              {project.name}
            </span>
            <span className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__value`}>
              {statement.statementName}
            </span>
          </div>
        </div>
        <StatementWrapUpStats
          stats={submitStats}
          className={`${STATEMENT_WRAP_UP_SUBMISSION_BLOCK}__row`}
          notAllElementsVerified={notAllElementsVerified}
        />
      </ConditionalRender>
    </Modal>
  );
};

SubmitStatementWrapUpApprovalModal.propTypes = {
  /** Statement model to be wrapped up */
  statement: PropTypes.instanceOf(Statement).isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when submission is confirmed */
  onSubmit: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  data: {
    selectedProject: { project },
  },
}) => ({
  project,
});

export { SubmitStatementWrapUpApprovalModal };
export default connect(
  mapStateToProps,
  null,
)(SubmitStatementWrapUpApprovalModal);
