import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  smoothScrollIntoCenterById,
  smoothScrollWithOffset,
} from 'utils/scrolling-utils';
import { FormattedMessage } from 'react-intl';

export const PROJECT_EDIT_SECTION_MENU_BLOCK = 'project-edit-section-menu';
export const PROJECT_EDIT_SECTION_MENU_ID_BLOCK =
  'project-edit-section-menu-id';
const BUTTON_CONTAINER_HEIGHT = 72;

const ProjectEditSectionMenu = ({
  sectionList,
  activeSectionId,
  onSectionClick,
}) => {
  const onClick = (element) => {
    if (element.offsetHeight > window.innerHeight - BUTTON_CONTAINER_HEIGHT) {
      const top = element.getBoundingClientRect().top;
      smoothScrollWithOffset(BUTTON_CONTAINER_HEIGHT, top); // 72 is the height of the buttons div container
    } else {
      smoothScrollIntoCenterById(element.id);
    }

    onSectionClick(element.id);
  };

  return (
    <div className={classNames(`${PROJECT_EDIT_SECTION_MENU_BLOCK}`)}>
      {sectionList.map((section, index) => (
        <button
          className={classNames(
            `${PROJECT_EDIT_SECTION_MENU_BLOCK}__section`,
            section.element.id === activeSectionId &&
              `${PROJECT_EDIT_SECTION_MENU_BLOCK}__section--active`,
          )}
          onClick={() => onClick(section.element)}
          key={`${section.element.id}-${PROJECT_EDIT_SECTION_MENU_ID_BLOCK}`}
          id={`${section.element.id}-${PROJECT_EDIT_SECTION_MENU_ID_BLOCK}-${index}`}
        >
          <FormattedMessage id={section.text} />
        </button>
      ))}
    </div>
  );
};
ProjectEditSectionMenu.propTypes = {
  //list of sections for the menu
  sectionList: PropTypes.array.isRequired,
  // id of the active section on the component
  activeSectionId: PropTypes.string.isRequired,
  // function to set a section as active
  onSectionClick: PropTypes.func.isRequired,
};
export default ProjectEditSectionMenu;
