import { createAction } from 'redux-actions';
import { addUserToGroup } from 'api/statement-socket-client-api';
import { getSelectedProjectRequest } from 'api/selected-project-api';
import { selectedProjectLoaded } from 'store/actions/selected-project-actions';
import { getAllUsers } from './project-users-list-actions';
// import { removeUserFromRoom } from 'api/statement-socket-client-api';
export const setActiveUsersLoading = createAction('ACTIVE_USERS_LOADING');
export const setActiveUsersLoaded = createAction('ACTIVE_USERS_LOADED');
export const setActiveUsersError = createAction('ACTIVE_USERS_ERROR');
export const addUserAction = createAction('ADD_USER_ACTION');
export const removeUserAction = createAction('REMOVE_USER_ACTION');
export const clearActiveUsersAction = createAction(
  'CLEAR_ACTIVER_USERS_ACTION',
);

export const addUserAfterValidatingIfIdExists =
  (webSocketPayload) => async (dispatch, getState) => {
    const existingUserIds =
      getState().data &&
      getState().data.projectUsersList &&
      getState().data.projectUsersList.data &&
      getState().data.projectUsersList.data.users &&
      getState().data.projectUsersList.data.users.map((user) => user.id);
    const projectId =
      getState().data &&
      getState().data.selectedProject &&
      getState().data.selectedProject.id;

    let isIdExist =
      webSocketPayload &&
      webSocketPayload.payload &&
      webSocketPayload.payload.every((e) => existingUserIds.includes(e));
    if (!isIdExist) {
      const response = await getSelectedProjectRequest(projectId);
      await dispatch(selectedProjectLoaded({ response }));
      await dispatch(getAllUsers());
    }
    await dispatch(addUserAction({ payload: webSocketPayload.payload }));
  };

export const addUserToRevision =
  (revisionIdParam, projectIdParam) => async (dispatch, getState) => {
    const { projectUsersList, revision, selectedProject } = getState().data;
    const revisionId = revision.id ? revision.id : revisionIdParam;
    const selectedProjectId = selectedProject && selectedProject.id;

    // try to get project id from store, if it is not there then switch to url param
    const projectId = selectedProjectId ? selectedProjectId : projectIdParam;

    try {
      const response = await addUserToGroup({
        revisionId: revisionId,
        projectUserIds: Object.keys(projectUsersList.data.userIdToUserNameMap),
        projectId,
      });
      dispatch(setActiveUsersLoaded(response));
    } catch (error) {
      dispatch(setActiveUsersError(error));
    }
  };

// TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
// export const removeUserFromRoomAction =
//   ({ userId, projectId, revisionId }) =>
//   async (dispatch, getState) => {
//     try {
//       const user = await removeUserFromRoom({
//         revisionId,
//         projectUserIds: [userId],
//         projectId,
//       });
//       const { result } = user.data;
//       const { data: { activeUsers } = {} } = getState();
//       const { data: { users } = {} } = activeUsers || {};
//       const payload =
//         users.length && users.filter((user) => user !== result[0]);
//       dispatch(removeUserAction({ payload }));
//     } catch (error) {
//       console.log(error);
//     }
//   };
