import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';

export const getWebSocketPayload = (webSocketEvent) => {
  if (isSignalRForSocketConnectionEnabled) {
    return webSocketEvent.payload;
  }
  return (
    webSocketEvent &&
    webSocketEvent.data &&
    webSocketEvent.data.arguments &&
    webSocketEvent.data.arguments[0] &&
    webSocketEvent.data.arguments[0].payload
  );
};

export const getWebSocketNotificationType = (webSocketEvent) => {
  if (isSignalRForSocketConnectionEnabled) {
    return webSocketEvent.notificationEventType;
  }
  return (
    webSocketEvent &&
    webSocketEvent.data &&
    webSocketEvent.data.arguments &&
    webSocketEvent.data.arguments[0] &&
    webSocketEvent.data.arguments[0].notificationEventType
  );
};
