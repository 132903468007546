import { withDefaultContextAsSelectedProject } from './api-default-context';

export const mapElementsRequest = ({
  targetId,
  sourceId,
  targetStatementId,
  sourceStatementId,
  elementMapDetails,
}) => {
  return withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/source/${sourceStatementId}/target/${targetStatementId}/revisions/source/${sourceId}/target/${targetId}/map`,
      elementMapDetails,
    )
    .then((response) => {
      return response;
    });
};
