import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from 'components/common/data-grid/data-grid-component';
import {
  getDeletedRevisionHistoryListColumns,
  NUM_VISIBLE_ROWS_REVISIONS_MODAL,
} from 'constants/feature/revision-history-constants';
import { DataGridDataApi } from 'models/utils/common/data-grid/data-grid-data-model';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import { RevisionList } from 'models/api/revision-list-api-model';
import revisionApi from 'api/revision-api.js';
import ProjectApi from 'models/api/project-api-model';
import { history } from 'store/store';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import SelectedStatement from 'models/api/selected-statement-model';

const REVISION_HISTORY_BLOCK = 'revision-history-modal';
const REVISION_HISTORY_ID_BLOCK = 'revision-history-modal-id';

class DeletedRevisionHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revisionList: new RevisionList(),
    };
  }

  componentDidMount() {
    this._fetchRevisions();
  }

  _fetchRevisions = async () => {
    const { selectedStatement } = this.props;

    this.setState(({ revisionList }) => ({
      revisionList: revisionList.setLoading(),
    }));
    try {
      const response = await revisionApi.getDeletedRevisionsForStatement(
        selectedStatement.id
      );
      this.setState(({ revisionList }) => ({
        revisionList: revisionList.setLoaded({ response }),
      }));
    } catch (e) {
      this.setState(({ revisionList }) => ({
        revisionList: revisionList.setError(e),
      }));
    }
  };

  render() {
    const {
      project,
      toggleStatementRevisonsModal,
      selectedStatement,
      onDelete,
      onRestore,
    } = this.props;
    const { revisionList } = this.state;

    return (
      <Modal
        id={`${REVISION_HISTORY_ID_BLOCK}`}
        title={'statement-list.deleted-revisions-modal.title'}
        onClose={toggleStatementRevisonsModal}
        primaryModalButton={
          new ModalButton({
            text: 'common.close',
            onClick: toggleStatementRevisonsModal,
          })
        }
      >
        <DataGrid
          className={`${REVISION_HISTORY_BLOCK}__revisions-list`}
          columns={getDeletedRevisionHistoryListColumns({
            project: project,
            selectedStatement: selectedStatement,
            onView: (revision) =>
              history.push(
                parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
                  params: {
                    projectId: project.id,
                    revisionId: revision.id,
                    statementId: revision.statementId,
                    readOnly: true,
                  },
                })
              ),
            onRestore: (revision) =>
              onRestore({
                revisionId: revision.id,
                lastRevision: revisionList.length === 1,
              }),
            onPermanentlyDelete: (revision) =>
              onDelete({
                revisionId: revision.id,
                lastRevision: revisionList.length === 1,
              }),
          })}
          tableId={`${REVISION_HISTORY_ID_BLOCK}-revision-modal-table`}
          dataModel={
            new DataGridDataApi({
              apiModel: revisionList,
              rowItems: revisionList.revisions,
            })
          }
          scrollable
          numberOfVisibleRows={NUM_VISIBLE_ROWS_REVISIONS_MODAL}
        />
      </Modal>
    );
  }
}

DeletedRevisionHistoryModal.propTypes = {
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Method pn the statement list component that opens the show revision modal */
  toggleStatementRevisonsModal: PropTypes.func.isRequired,
  /** selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** action to permanently delete a specific revision */
  onDelete: PropTypes.func.isRequired,
  /** Method to restore a revision */
  onRestore: PropTypes.func.isRequired,
};

export default DeletedRevisionHistoryModal;
