import { createAction } from 'redux-actions';

import {
  getNotesByRevisionRequest,
  getNoteByRevisionAndPseudoElementIdRequest,
  getNoteByRevisionAndElementIdRequest,
  updateNoteRequest,
  deleteNoteRequest,
  updateNoteReplyRequest,
  EditNoteRequest,
  sendEmailToTaggedUsersRequest,
} from 'api/notes-api';
import { fetchAllSectionRenderingData } from './statement-content-actions';
import { fetchNotesBySection } from './notes-cache-actions';
import { showNotesPanelAction } from './panel-controller-actions';
import { fetchStatementSummaryNotes } from './statement-summary/notes-summary-actions';
import Note from 'models/data/note-model';

export const saveNotesLoading = createAction('SAVE_NOTES_LOADING');
export const saveNotesError = createAction('SAVE_NOTES_ERROR');
export const initNotePanelFromSelectionAction = createAction(
  'INIT_NOTES_PANEL_WITH_SELECTION',
);
export const clearNoteSelectionAction = createAction('NOTES_SELECTION_CLEAR');
export const notesListLoading = createAction('NOTES_LIST_LOADING');
export const notesListLoaded = createAction('NOTES_LIST_LOADED');
export const notesListError = createAction('NOTES_LIST_ERROR');
export const notesListLoadedWithoutReponse = createAction(
  'NOTES_LIST_LOADED_WITHOUT_RESPONSE',
);

export const selectedNoteLoadingAction = createAction('SELECTED_NOTE_LOADING');
export const selectedNoteLoadedAction = createAction('SELECTED_NOTE_LOADED');
export const selectedNoteErrorAction = createAction('SELECTED_NOTE_ERROR');

export const selectNoteFromNoteListAction = createAction(
  'SELECTED_NOTE_FROM_NOTE_LIST',
);

export const updateNoteStatusAction = createAction('NOTE_STATUS_UPDATE');
export const updateNotePriorityAction = createAction('NOTE_PRIORITY_UPDATE');

export const removeNoteFilterAction = createAction('REMOVE_NOTE_FILTER_ACTION');

export const updateNoteFilterAction = createAction('UPDATE_NOTE_FILTER');

export const clearAllNoteFiltersAction = createAction(
  'CLEAR_ALL_NOTE_FILTERS_ACTION',
);
export const applyQuickFilterAction = createAction('APPLY_QUICK_FILTER_ACTION');
export const applyNoteFilterAction = createAction('APPLY_NOTE_FILTER_ACTION');

export const deleteNoteWithWebsocketPayload = createAction(
  'DELETE_NOTE_WITH_WEBSOCKET_PAYLOAD_ACTION',
);
export const addNoteWithWebsocketPayload = createAction(
  'ADD_NOTE_WITH_WEBSOCKET_PAYLOAD_ACTION',
);
export const updateNoteWithWebsocketPayload = createAction(
  'UPDATE_NOTE_WITH_WEBSOCKET_PAYLOAD',
);
export const changeIsNewPropertyFromSelectedNote = createAction(
  'CHANGE_IS_NEW_PROPERTY_FROM_SELECTED_NOTE',
);
export const setPreviousNoteBody = createAction('SET_PREVIOUS_NOTE_BODY');
export const setAssignedToMe = createAction('SET_ASSIGNED_TO_ME');

export const addAdditionalDetailsToNoteList = createAction(
  'ADD_ADDITIONAL_DETAILS_TO_NOTES_LIST_ACTION',
);

export const applyNoteFilter = (
  updatedFilters,
  userFilterCreated,
  userFilterReplied,
  selectedSegments,
  selectedUsersCreatedAndRepliedUsers,
  userFilterMentionedUsers,
) => (dispatch) => {
  dispatch(
    applyNoteFilterAction({
      updatedFilters,
      userFilterCreated,
      userFilterReplied,
      selectedSegments,
      selectedUsersCreatedAndRepliedUsers,
      userFilterMentionedUsers,
    }),
  );
};

// We need to modify the note list to include additional details like parentId and isTechnical for particular section.
// We expected to get these details from BE directly, but they are facing some technical issues (inner join to fetch these
// details causes ghost notes to dissappear from the list), which enforces us to fetch these details from sections cache.
export const putAdditionalDetailsToNoteList = () => async (
  dispatch,
  getState,
) => {
  const { contentSectionMap } = getState().data.statementContent.sectionsCache;
  const sectionCacheData =
    contentSectionMap && contentSectionMap.data && contentSectionMap.data.map;
  await dispatch(addAdditionalDetailsToNoteList({ sectionCacheData }));
};

export const applyQuickFilter = (filterVal, filterType) => (dispatch) => {
  dispatch(applyQuickFilterAction({ filterVal, filterType }));
};

export const removeNoteFilter = (filterType, createdOrRepliedUsers) => (
  dispatch,
) => {
  dispatch(
    removeNoteFilterAction({
      filterType,
      createdOrRepliedUsers,
    }),
  );
};

export const clearAllNoteFilters = () => (dispatch) => {
  dispatch(clearAllNoteFiltersAction());
};

export const updateNoteFilterList = (filterVal, filterType) => (dispatch) => {
  dispatch(updateNoteFilterAction({ filterVal, filterType }));
};

export const initCreateNoteFromSelection = ({ data }) => (
  dispatch,
  getState,
) => {
  dispatch(initNotePanelFromSelectionAction(data));
  dispatch(showNotesPanelAction());
};

export const fetchTheListOfNotes = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  dispatch(notesListLoading());
  await dispatch(fetchNoteListWithoutLoading({ revisionId }));
};

export const fetchNoteListWithoutLoading = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  try {
    const response = await getNotesByRevisionRequest({ revisionId });
    await dispatch(notesListLoaded({ response }));
    await dispatch(putAdditionalDetailsToNoteList());
  } catch (error) {
    dispatch(notesListError(error));
  }
};

export const fetchNotesListAndApplyFilter = ({
  revisionId,
  updatedFilters = {},
  userFilterCreated = {},
  userFilterReplied = {},
  selectedSegments = {},
  selectedUsersCreatedAndRepliedUsers = {},
  userFilterMentionedUsers = {},
}) => async (dispatch, getState) => {
  dispatch(showNotesPanelAction());
  dispatch(
    applyNoteFilter(
      updatedFilters,
      userFilterCreated,
      userFilterReplied,
      selectedSegments,
      selectedUsersCreatedAndRepliedUsers,
      userFilterMentionedUsers,
    ),
  );
};

export const selectNoteFromContentPanel = ({
  pseudoElementId = -1,
  elementId = -1,
}) => (dispatch, getState) => {
  const { revision } = getState().data;
  dispatch(showNotesPanelAction());
  const hasElementId = elementId !== -1;
  const getRequestMethod = hasElementId
    ? getNoteByRevisionAndElementIdRequest
    : getNoteByRevisionAndPseudoElementIdRequest;
  const getRequestBody = {
    revisionId: revision.id,
    ...(hasElementId ? { elementId } : { pseudoElementId }),
  };
  getRequestMethod(getRequestBody)
    .then((response) => {
      dispatch(selectedNoteLoadedAction({ response }));
    })
    .catch((error) => {
      dispatch(selectedNoteErrorAction(error));
    });
};

export const handleNoteCreationSuccess = ({ noteId }) => async (
  dispatch,
  getState,
) => {
  const {
    data: {
      revision,
      notesPanel: { addNoteSelection },
    },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  } = getState();
  //clear the selectedNote to avoid highlight
  dispatch(notesListLoading());

  dispatch(
    selectNoteFromNoteListAction({
      note: new Note(),
    }),
  );
  if (socketHasBeenDisconnected) {
    dispatch(
      fetchAllSectionRenderingData({ sectionId: addNoteSelection.sectionId }),
    );
  } else {
    setTimeout(() => {
      dispatch(notesListLoadedWithoutReponse());
    }, 5000);
  }

  dispatch(clearNoteSelectionAction());

  if (socketHasBeenDisconnected) {
    await Promise.all([
      dispatch(fetchNoteListWithoutLoading({ revisionId: revision.id })),
      dispatch(fetchStatementSummaryNotes({ revisionId: revision.id })),
    ]);
  }

  // set selected note after successfully fetching notes list
  if (socketHasBeenDisconnected) {
    const {
      data: {
        notesPanel: { notesList },
      },
    } = getState();
    const note = notesList.notes.find((note) => note.noteId === noteId);
    //set a parameter in the new note to highlight it as a new note on content panel
    note.isNew = true;
    dispatch(
      selectNoteFromNoteListAction({
        note: note,
      }),
    );
  }
};

export const updateNoteAttribute = ({ newNoteModel }) => async (
  dispatch,
  getState,
) => {
  const {
    data: { revision },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  } = getState();
  try {
    await updateNoteRequest({
      revisionId: revision.id,
      noteModel: newNoteModel,
    });
    if (socketHasBeenDisconnected) {
      dispatch(fetchNoteListWithoutLoading({ revisionId: revision.id }));
      dispatch(fetchNotesBySection({ sectionId: newNoteModel.sectionId }));
    }
  } catch (error) {
    dispatch(notesListError(error));
  }
};

export const editNote = ({ newNoteModel }) => async (dispatch, getState) => {
  const {
    data: { revision },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  } = getState();
  try {
    await EditNoteRequest({ revisionId: revision.id, noteModel: newNoteModel });
    if (socketHasBeenDisconnected) {
      dispatch(fetchNoteListWithoutLoading({ revisionId: revision.id }));
      dispatch(fetchNotesBySection({ sectionId: newNoteModel.sectionId }));
    }
    if (
      newNoteModel.includeEmailListAfterNoteEdit().sendEmailTo &&
      newNoteModel.includeEmailListAfterNoteEdit().sendEmailTo.length > 0
    ) {
      await sendEmailToTaggedUsersRequest({
        revisionId: revision.id,
        emailRequestBody: newNoteModel.includeEmailListAfterNoteEdit(),
      });
    }
    dispatch(setPreviousNoteBody(''));
  } catch (error) {
    dispatch(notesListError(error));
  }
};

export const createOrDeleteNoteReply = ({ newNoteModel }) => async (
  dispatch,
  getState,
) => {
  const {
    data: { revision },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  } = getState();
  try {
    await updateNoteReplyRequest({
      revisionId: revision.id,
      noteModel: newNoteModel,
    });
    if (socketHasBeenDisconnected) {
      dispatch(fetchNoteListWithoutLoading({ revisionId: revision.id }));
      dispatch(fetchNotesBySection({ sectionId: newNoteModel.sectionId }));
    }
    if (
      newNoteModel.includeEmailListAfterNoteReply().sendEmailTo &&
      newNoteModel.includeEmailListAfterNoteReply().sendEmailTo.length > 0
    ) {
      await sendEmailToTaggedUsersRequest({
        revisionId: revision.id,
        emailRequestBody: newNoteModel.includeEmailListAfterNoteReply(),
      });
    }
  } catch (error) {
    dispatch(notesListError(error));
  }
};

export const deleteNote = ({ note }) => async (dispatch, getState) => {
  const {
    data: { revision },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  } = getState();

  dispatch(notesListLoading());
  try {
    await deleteNoteRequest({
      revisionId: revision.id,
      note,
    });
    if (socketHasBeenDisconnected) {
      dispatch(fetchNoteListWithoutLoading({ revisionId: revision.id }));
      dispatch(fetchAllSectionRenderingData({ sectionId: note.sectionId }));
      dispatch(fetchStatementSummaryNotes({ revisionId: revision.id }));
    } else {
      setTimeout(() => {
        dispatch(notesListLoadedWithoutReponse());
      }, 5000);
    }
  } catch (error) {
    dispatch(notesListError(error));
  }
};
