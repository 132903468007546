import ApiModel from 'models/api-model';
import ToolkitDownloadData from './toolkit-export-download-model';
import { isNullOrUndefined } from '../../utils/object-utils';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import cloneDeep from 'lodash.clonedeep';
import { getExportReportType } from 'utils/statement-content-page-utils';

export const REPORT_DOWNLOAD_PROCESSING = 'PROCESSING';
export const REPORT_DOWNLOAD_PROCESSED = 'PROCESSED';
export const REPORT_DOWNLOAD_FAILED = 'FAILED';

export default class ToolkitDownloadListData extends ApiModel({
  // structure
  data: new Map(),
  //[downloadId-1] : {instance of ToolkitDownloadData},
  //[downloadId-2] : {instance of ToolkitDownloadData},
}) {
  /**
   * @param {Object} data :  { downloadId: null, name: null,  dateTime: null,  downloadStatus: false }
   * @returns {ThisType}
   */
  setDownloadData(data) {
    if (
      data &&
      !isNullOrUndefined(data.reportId) &&
      !isNullOrUndefined(data.processedTime)
    ) {
      let downloadData = new ToolkitDownloadData({
        downloadId: data.reportId,
        name: data.fileName,
        dateTime: moment
          .utc(data.processedTime)
          .local()
          .format(DATE_FORMATS.MONTH_DAY_YEAR_TIME),
        downloadStatus: data.status,
        // This might be a bit confusing. For BE, the field downloadedStatus is what we call userDownloadedReportStatus
        // and the field status is what we call downloadStatus. We need to interpret it correctly at our end.
        userDownloadedReportStatus: data.downloadedStatus,
        downloadResponse: data.byteDocument,
        storageGuid: data.storageGuid,
        reportType: getExportReportType(data.reportType),
        // these values ensure this method acts as setLoaded function for single report when called to set data
        isLoading: false,
        isLoaded: true,
        error: null,
      });
      return this.mergeLoadedData(this.data.set(data.reportId, downloadData));
    }
    return this;
  }

  setReportIdLoading({ id }) {
    !isNullOrUndefined(id) &&
      this.data.has(id) &&
      this.data.set(
        id,
        new ToolkitDownloadData({
          ...this.data.get(id),
          isLoading: true,
          isLoaded: false,
        }),
      );
    return this.merge({ data: this.data });
  }

  setReportIdLoaded({ id }) {
    !isNullOrUndefined(id) &&
      this.data.has(id) &&
      this.data.set(
        id,
        new ToolkitDownloadData({
          ...this.data.get(id),
          isLoading: false,
          isLoaded: true,
        }),
      );
    return this.merge({ data: this.data });
  }

  setReportIdError({ id, error }) {
    !isNullOrUndefined(id) &&
      this.data.has(id) &&
      this.data.set(
        id,
        new ToolkitDownloadData({
          ...this.data.get(id),
          isLoading: false,
          isLoaded: false,
          error: error,
        }),
      );
    return this.merge({ data: this.data });
  }

  setLoaded() {
    return this.merge({
      data: this.data,
      isLoaded: true,
      isLoading: false,
    });
  }

  hasReports() {
    return this && this.data && this.data.size;
  }
  /**
   *
   * @param {Array} listData : array of object
   * @returns
   */
  setDownloadListData(listData) {
    let reportList = cloneDeep(this);
    for (let index = 0; index < listData.length; index++) {
      // if reportId is already processed then dont alter it
      !(
        reportList &&
        reportList.hasReportId(listData[index].reportId) &&
        reportList.getReportById(listData[index].reportId).downloadStatus ===
          REPORT_DOWNLOAD_PROCESSED
      ) && (reportList = reportList.setDownloadData(listData[index]));
    }
    return this.mergeLoadedData(reportList.data);
  }

  updateDownloadStatus({
    id,
    downloadStatus,
    downloadResponse,
    storageGuid,
    userDownloadedReportStatus = undefined,
  }) {
    !isNullOrUndefined(id) &&
      this.data.has(id) &&
      this.data.set(
        id,
        new ToolkitDownloadData({
          ...this.data.get(id),
          downloadStatus: downloadStatus,
          userDownloadedReportStatus: userDownloadedReportStatus,
          downloadResponse: downloadResponse,
          storageGuid: storageGuid,
          isLoading: false,
          isLoaded: true,
          error: null,
        }),
      );
    return this.mergeLoadedData(this.data);
  }

  updateFlagUserDownloadedReportStatus({ id, userDownloadedReportStatus }) {
    !isNullOrUndefined(id) &&
      this.data.has(id) &&
      this.data.set(
        id,
        new ToolkitDownloadData({
          ...this.data.get(id),
          userDownloadedReportStatus: userDownloadedReportStatus,
          isLoading: false,
          isLoaded: true,
          error: null,
        }),
      );
    return this.mergeLoadedData(this.data);
  }

  mergeLoadedData(reportData) {
    return this.merge({
      data: reportData,
      isLoaded: true,
      isLoading: false,
      error: null,
    });
  }

  getReportById(id) {
    return !isNullOrUndefined(id) && this && this.data && this.data.get(id);
  }

  hasReportTypeDownloadProcessing(reportType) {
    return this &&
      this.data &&
      this.data.size > 0 &&
      [...this.data.values()].findIndex(
        (downloadData) =>
          downloadData.reportType === reportType &&
          downloadData.downloadStatus === REPORT_DOWNLOAD_PROCESSING,
      ) !== -1
      ? true
      : false;
  }

  hasReportId(id) {
    return !isNullOrUndefined(id) && this && this.data && this.data.has(id);
  }

  clear() {
    return this.merge({
      data: new Map(),
    });
  }

  sortByTime() {
    return (
      this &&
      this.data &&
      this.data.size > 0 &&
      [...this.data.values()].sort((a, b) =>
        b.dateTime.localeCompare(a.dateTime),
      )
    );
  }
}
