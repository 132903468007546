import { createAction } from 'redux-actions';
import { getInternalReferenceSuggestionsByRevisionAndElementId } from 'api/internal-reference-api';

export const internalReferenceSuggestedListLoading = createAction(
  'INTERNAL_REFERENCE_SUGGESTED_LIST_LOADING',
);
export const internalReferenceSuggestedListLoaded = createAction(
  'INTERNAL_REFERENCE_SUGGESTED_LIST_LOADED',
);
export const internalReferenceSuggestedListError = createAction(
  'INTERNAL_REFERENCE_SUGGESTED_LIST_ERROR',
);

export const updateSuggestedIRElementsFromListFromSocketPayloadAction = createAction(
  'UPDATE_SUGGESTED_LIST_ELEMENTS_FROM_SOCKET_PAYLOAD_ACTION',
);

export const updateSuggestedIRListFromSocketPayloadAction = createAction(
  'UPDATE_SUGGESTED_LIST_FROM_SOCKET_PAYLOAD_ACTION',
);

export const removeSuggestedIRFromSocketPayloadAction = createAction(
  'REMOVE_SUGGESTED_IR_FROM_SOCKET_PAYLOAD_ACTION',
);

export const fetchSuggestedListed = ({ revisionId, elementId }) => async (
  dispatch,
) => {
  dispatch(internalReferenceSuggestedListLoading());
  await dispatch(fetchSuggestedListedWithoutLoading({ revisionId, elementId }));
};

export const fetchSuggestedListedWithoutLoading = ({
  revisionId,
  elementId,
}) => async (dispatch) => {
  try {
    const response = await getInternalReferenceSuggestionsByRevisionAndElementId(
      { revisionId, elementId },
    );
    dispatch(internalReferenceSuggestedListLoaded(response));
  } catch (error) {
    dispatch(internalReferenceSuggestedListError(error));
  }
};
