import React, { memo, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/button-component';
import { ReactComponent as Plus } from 'icons/plus.svg';
import Search from 'components/common/search-component';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import TickmarkList from 'models/api/tickmark-list-api-model';
import SearchResults from 'components/feature/element-panel/tickmark-panel/_element-tickmark-panel-search-results-component';

const ATTACH_TICKMARK_BLOCK = 'attach-tickmark';
const ATTACH_TICKMARK_ID_BLOCK = 'attach-tickmark-id';

const MIN_CHAR_LENGTH_TO_SEARCH = 1;

const AttachTickmark = ({
  disabled,
  hideTitle,
  onCreate,
  onAttach,
  onSearch,
}) => {
  const [search, setSearch] = useState('');
  const [tickmarkList, setTickmarkListModel] = useState(new TickmarkList());
  const wrapperRef = useRef();

  const _searchTickmarks = (val) => {
    setSearch(val);
    const _shouldClearResults =
      !isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH) &&
      tickmarkList.isLoaded &&
      tickmarkList.hasTickmarks();
    if (isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH)) {
      debouncedSearch({ search: val });
    } else if (_shouldClearResults) {
      setTickmarkListModel(new TickmarkList());
    }
  };

  const _fetchTickmarks = async ({ search }) => {
    setTickmarkListModel(tickmarkList.setLoading());

    try {
      const response = await onSearch({ search });
      setTickmarkListModel(tickmarkList.setLoaded({ response }));
    } catch (error) {
      setTickmarkListModel(tickmarkList.setError(error));
    }
  };

  const _clearSearch = () => {
    setTickmarkListModel(new TickmarkList());
    setSearch('');
  };

  const _handleAttachTickmark = async (tickmark) => {
    try {
      await onAttach({ tickmark });
    } catch (error) {
      setTickmarkListModel(tickmarkList.setError(error));
    }
    _clearSearch();
  };

  const debouncedSearch = useCallback(debounceSearch(_fetchTickmarks), []);
  const _shouldOpenMenu = isSearchCharLengthValid(
    search,
    MIN_CHAR_LENGTH_TO_SEARCH,
  );

  return (
    <div className={ATTACH_TICKMARK_BLOCK} ref={wrapperRef}>
      {!hideTitle && (
        <h4 className={`${ATTACH_TICKMARK_BLOCK}__title`}>
          <FormattedMessage id="element-tickmark-panel.attach-section.title" />
        </h4>
      )}
      <Button.IconButton
        id={`${ATTACH_TICKMARK_ID_BLOCK}__create-button`}
        className={`${ATTACH_TICKMARK_BLOCK}__create-button`}
        onClick={onCreate}
        Icon={Plus}
      >
        <FormattedMessage id="common.create" />
      </Button.IconButton>
      <Search
        id={`${ATTACH_TICKMARK_ID_BLOCK}-search`}
        className={`${ATTACH_TICKMARK_BLOCK}__search`}
        placeholder="element-tickmark-panel.attach-section.search.placeholder"
        label="element-tickmark-panel.attach-section.search.label"
        onChange={_searchTickmarks}
        value={search}
        isValid={true}
        disableAutoComplete
        onClear={_clearSearch}
        minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
        showSearchButton
      />
      <SearchResults
        tickmarkList={tickmarkList}
        search={search}
        onAddTickmark={_handleAttachTickmark}
        disabled={disabled}
        wrapperRef={wrapperRef.current}
        isOpen={_shouldOpenMenu}
        onClickOutside={_clearSearch}
      />
    </div>
  );
};

AttachTickmark.defaultProps = {
  hideTitle: false,
};

AttachTickmark.propTypes = {
  /** Function fired when user clicks Create */
  onCreate: PropTypes.func.isRequired,
  /** Function fired when user attaches tickmark from search results */
  onAttach: PropTypes.func.isRequired,
  /** boolean or func to disable the button */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  /** boolean to hide title */
  hideTitle: PropTypes.bool,
};

export default memo(AttachTickmark);
