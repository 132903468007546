export default class DataGridData {
  rowItems = [];
  constructor({ rowItems }) {
    if (!Array.isArray(rowItems)) {
      throw Error('rowItems prop for data grid needs to be of type Array');
    }
    this.rowItems = rowItems;
  }
}

export class DataGridDataApi extends DataGridData {
  /* Api model representing the response object, used for loading animations on the table */
  apiModel = null;
  /* Used for pagination: Number of ALL records in the database for this query, not just how many are returned for current pagination */
  totalResults = 0;
  /* Used for pagination: Offset for database query to begin looking for values */
  currentOffset;
  /* Used for pagination: number of records to fetch */
  pageSize;

  constructor({ apiModel, rowItems, totalResults, currentOffset, pageSize }) {
    super({ rowItems });
    // TODO would like to add this instanceof check here
    // if (!(apiModel instanceof ApiModel)) {
    //   throw Error('apiModel prop for data grid needs to be of type ApiModel');
    // }
    this.apiModel = apiModel;
    this.totalResults = totalResults || 0;
    this.currentOffset = currentOffset;
    this.pageSize = pageSize;
  }
}
