import { getElementDetailsRequest } from 'api/element-api';
import { ElementSelectionLimit } from 'higher-order-components/tieout-element-hoc-component';
import ElementDetails from 'models/api/element-details-api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { getMultipleElementsFromCache } from './element-cache-actions';

export const addElementsToSourceListInSideBySideMode =
  ({ elements }) =>
  async (dispatch, getState) => {
    const {
      ui: {
        sideBySideView: {
          sideBySideElementMap: { sourceStatementElementMap },
        },
      },
    } = getState();
    const sourceSideBySideMapping = [...sourceStatementElementMap];
    const totalElements = elements.length + sourceSideBySideMapping.length;

    // Check if total elements are within the limit
    if (totalElements <= ElementSelectionLimit) {
      try {
        // Fetch elements from cache
        const selectedElements = await dispatch(
          getSourceSideBySideMultipleElementsFromCache(elements),
        );

        return selectedElements;
      } catch (error) {
        console.error('Error fetching elements from cache:', error);
      }
    }
    return [];
  };

export const addElementsToTargetListInSideBySideMode =
  ({ elements }) =>
  async (dispatch, getState) => {
    const {
      ui: {
        sideBySideView: {
          sideBySideElementMap: { targetStatementElementMap },
        },
      },
    } = getState();
    const targetSideBySideMapping = [...targetStatementElementMap];
    const totalElements = elements.length + targetSideBySideMapping.length;

    if (totalElements <= ElementSelectionLimit) {
      const selectedElements = await dispatch(
        getMultipleElementsFromCache({
          elements,
          isSideBySideView: true,
        }),
      );
      return selectedElements;
    }
    return [];
  };

export const getSourceSideBySideMultipleElementsFromCache =
  (elements) => async (dispatch, getState) => {
    const dataState = getState().data;
    const uiState = getState().ui;
    const { leftElementCache } = dataState.leftStatementContent;
    const { revisionId } = uiState.sourceStatementParams;
    let selectedElements = [];
    let initiatedRequest = false;
    await Promise.all(
      elements.map(async ({ elementId, sectionId }) => {
        try {
          if (!isNullOrUndefined(elementId)) {
            let elementData = leftElementCache.getElement({
              elementId,
              sectionId,
            });
            if (isNullOrUndefined(elementData)) {
              if (!initiatedRequest) {
                initiatedRequest = true;
              }

              const response = await getElementDetailsRequest({
                elementId,
                revisionId: revisionId,
              });
              elementData = new ElementDetails().setLoaded({ response });
            }
            selectedElements.push(elementData);
          }
        } catch (e) {
          console.error(
            'Failed to load elements while making batch selection in side by side view',
            e,
          );
        }
      }),
    );
    return selectedElements;
  };
