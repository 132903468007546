import React, { useEffect, useRef, useState } from 'react';
import { getCurrentMaintenanceBanner } from 'api/admin-module-api';
import { ReactComponent as Information } from 'icons/info.svg';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import PropTypes from 'prop-types';
import disclosureAnalyticsCSSVariable from '../../../../src/styles/variables/feature/disclosure-analytics-header-variables.scss';
import homePageCSSVariable from '../../../../src/styles/variables/pages/home-page-variables.scss';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Tooltip from 'components/common/tool-tip-component';
import { FormattedMessage } from 'react-intl';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const MAINTENANCE_BANNER_BLOCK = 'maintenance-banner';
const MAINTENANCE_BANNER_BLOCK_ID = 'maintenance-banner-id';
export const MAINTENANCE_BANNER_SESSION_DATA_NAME =
  'maintenance-banner-session-data';
export const MAINTENANCE_BANNER_SESSION_DATA_VALUE = 'tieout-maintenance-info';
const BANNER_CLOSE_ICON_SIZE = '28px';

const MAINTENANCE_BANNER_ICON_SIZE = '18px';
const DISCLOSURE_ANALYTICS_BANNER_HEIGHT = parseInt(
  disclosureAnalyticsCSSVariable.disclosureAnalyticsHeaderHeight,
);
const HOME_PAGE_FOOTER_HEIGHT = parseInt(homePageCSSVariable.footerHeight);

const ResponsiveLinesEllipsis = responsiveHOC()(HTMLEllipsis);

export const MaintenanceBanner = ({ projectListRef, homePageRightRef }) => {
  const bannerText = useRef('');
  const bannerDivRef = useRef();

  // This state is set to true when we have success while fetching banner text from BE
  // And we are ready to display it.
  const [showBanner, setShowBanner] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const isSessionStoreConsistsBanner = useRef(
    sessionStorage.getItem(MAINTENANCE_BANNER_SESSION_DATA_NAME) ===
      MAINTENANCE_BANNER_SESSION_DATA_VALUE,
  );

  useEffect(() => {
    const fetchData = async () => await getCurrentMaintenanceBanner();
    !isSessionStoreConsistsBanner.current &&
      fetchData().then((response) => {
        bannerText.current = response;
        if (bannerText.current && typeof bannerText.current === 'string') {
          setShowBanner(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      showBanner &&
      projectListRef &&
      homePageRightRef &&
      !isSessionStoreConsistsBanner.current
    ) {
      //adjusting project list top property
      projectListRef.style.top = `${
        DISCLOSURE_ANALYTICS_BANNER_HEIGHT +
        parseInt(bannerDivRef.current.clientHeight)
      }px`;
      //adjusting project list height property
      projectListRef.style.height = `calc(
        100vh - ${
          DISCLOSURE_ANALYTICS_BANNER_HEIGHT +
          HOME_PAGE_FOOTER_HEIGHT +
          parseInt(bannerDivRef.current.clientHeight)
        }px
      )`;

      //adjusting home page right component- containing project details
      homePageRightRef.style.top = `${parseInt(
        bannerDivRef.current.clientHeight,
      )}px`;
    }
  }, [homePageRightRef, projectListRef, showBanner]);

  const handleBannerClose = () => {
    sessionStorage.setItem(
      MAINTENANCE_BANNER_SESSION_DATA_NAME,
      MAINTENANCE_BANNER_SESSION_DATA_VALUE,
    );
    setShowBanner(false);
    //adjusting project list top property
    projectListRef &&
      (projectListRef.style.top = `${DISCLOSURE_ANALYTICS_BANNER_HEIGHT}px`);
    //adjusting project list height property
    projectListRef &&
      (projectListRef.style.height = `calc(
        100vh - ${
          DISCLOSURE_ANALYTICS_BANNER_HEIGHT + HOME_PAGE_FOOTER_HEIGHT
        }px
      )`);

    //adjusting home page right component- containing project details
    homePageRightRef && (homePageRightRef.style.top = '0px');
  };
  return (
    !isSessionStoreConsistsBanner.current &&
    showBanner && (
      <div className={MAINTENANCE_BANNER_BLOCK} ref={bannerDivRef}>
        <div className={`${MAINTENANCE_BANNER_BLOCK}__header`}>
          <Information
            name={'information'}
            className={`${MAINTENANCE_BANNER_BLOCK}__icon`}
            width={MAINTENANCE_BANNER_ICON_SIZE}
            height={MAINTENANCE_BANNER_ICON_SIZE}
          />
        </div>
        <div className={`${MAINTENANCE_BANNER_BLOCK}__body`}>
          <Tooltip
            id={`${MAINTENANCE_BANNER_BLOCK_ID}-maintenance-banner-tooltip`}
            text={
              <FormattedMessage
                id={'maintenance-banner-formatted-msg'}
                values={{
                  span: (...chunks) => (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: bannerText.current,
                      }}
                      className={`${MAINTENANCE_BANNER_BLOCK}-tooltip-message`}
                    />
                  ),
                }}
              />
            }
            isNotInternationalized={true}
            position={'bottom'}
            active={showTooltip}
          >
            <ResponsiveLinesEllipsis
              unsafeHTML={bannerText.current}
              maxLine="2"
              ellipsis="..."
              basedOn="letters"
              onReflow={({ clamped }) => {
                setShowTooltip(clamped);
              }}
            />
          </Tooltip>
        </div>
        <CloseButton
          name={'close-button'}
          role={'button'}
          className={`${MAINTENANCE_BANNER_BLOCK}__close`}
          width={BANNER_CLOSE_ICON_SIZE}
          height={BANNER_CLOSE_ICON_SIZE}
          onClick={() => handleBannerClose()}
        />
      </div>
    )
  );
};

MaintenanceBanner.propTypes = {
  // ref for project list in home page. It is used here to set style dynamically
  projectListRef: PropTypes.object,
  // ref for right part of home page. It is used here to set style dynamically
  homePageRightRef: PropTypes.object,
};

export default MaintenanceBanner;
