import { handleActions } from 'redux-actions';
import {
  statementWorkflowsLoading,
  statementWorkflowsError,
  statementWorkflowsLoaded,
  permanentlyDeleteWorkflowRevisionError,
  retryFailedDocumentUploadError,
  clearStatementWorkflows,
} from 'store/actions/statement-workflows-actions';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';

export default handleActions(
  {
    [clearStatementWorkflows]: (workflowsMap) => {
      return new WorkflowsMap();
    },
    [statementWorkflowsLoading]: (workflowsMap) => {
      return workflowsMap.setLoading();
    },
    [statementWorkflowsError]: (workflowsMap, { payload }) => {
      return workflowsMap.setError(payload);
    },
    [statementWorkflowsLoaded]: (workflowsMap, { payload }) => {
      return workflowsMap.setLoaded(payload);
    },
    [permanentlyDeleteWorkflowRevisionError]: (workflowsMap, { payload }) => {
      return workflowsMap.setError(payload);
    },
    [retryFailedDocumentUploadError]: (workflowsMap, { payload }) => {
      return workflowsMap.setError(payload);
    },
  },
  new WorkflowsMap()
);
