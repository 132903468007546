import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

export const NO_PROJECTS_BLOCK = 'no-projects';

const NoProjects = () => {
  return (
    <div className={NO_PROJECTS_BLOCK}>
      <div className={`${NO_PROJECTS_BLOCK}__title`}>
        <FormattedMessage id={'project-details.no-projects.title'} />
      </div>
      <div className={`${NO_PROJECTS_BLOCK}__body`}>
        <FormattedMessage id={'project-details.no-projects.body'} />
      </div>
    </div>
  );
};

export default memo(NoProjects);
