import TabModel from 'models/utils/common/tab-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import Permissions from 'permissions/permissions';
import RoleUtil from 'permissions/utils/roles';
export const CONTENT_MAX_SEARCH_RESULTS = 500;

export const STATEMENT_NAV_HEADING_ACTION_DROPDOWN_EDIT_OPTION = 'edit';
export const STATEMENT_NAV_HEADING_ACTION_DROPDOWN_BULK_ASSIGN_OPTION =
  'bulk-assign';

export const STATEMENT_NAV_TABS = {
  headings: new TabModel({
    id: 'statement-nav-headings-tab',
    intlTitle: 'statement-nav-panel.tab.headings.title',
  }),
  elements: new TabModel({
    id: 'statement-nav-elements-tab',
    intlTitle: 'statement-nav-panel.tab.elements.title',
  }),
  content: new TabModel({
    id: 'statement-nav-content-tab',
    intlTitle: 'statement-nav-panel.tab.content.title',
  }),
};

export const getStatementNavHeadingActionDropdown = (
  selectedStatement,
  selectedProjectId,
) => ({
  edit: new MenuOption({
    id: 'statement-nav-heading-dropdown-option-1',
    title: 'common.edit',
    value: STATEMENT_NAV_HEADING_ACTION_DROPDOWN_EDIT_OPTION,
    isIntl: true,
    disabled:
      RoleUtil.doesUserHaveObserverRoleForProject() ||
      RoleUtil.doesUserHaveCoeRoleForProject() ||
      RoleUtil.doesUserHaveAdminSupportAccess(),
  }),
  bulkAssign: new MenuOption({
    id: 'statement-nav-heading-dropdown-option-2',
    title: 'statement-nav-heading-action-dropdown.bulk.assign',
    value: STATEMENT_NAV_HEADING_ACTION_DROPDOWN_BULK_ASSIGN_OPTION,
    isIntl: true,
    disabled:
      (selectedStatement && selectedStatement.isReadOnly()) ||
      (selectedProjectId &&
        !Permissions.Section.canAssignSection(selectedProjectId)),
  }),
});

export const getStatementNavHeadingActionDropdownValues = (
  selectedStatement,
  selectedProjectId,
) =>
  Object.values(
    getStatementNavHeadingActionDropdown(selectedStatement, selectedProjectId),
  );

export const STATEMENT_NAV_TABS_ARRAY = Object.values(STATEMENT_NAV_TABS);
