import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from 'icons/down-arrow.svg';
import Checkbox from 'components/common/checkbox-component';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import { FormattedMessage } from 'react-intl';
import ToolTip from 'components/common/tool-tip-component';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { getInitials } from 'utils/text-format-utils';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';

export const BULK_SELECT_SECTIONS_BLOCK = 'bulk-select-sections';
export const BULK_SELECT_SECTIONS_ID = 'bulk-select-sections-id';

const StatementNavBulkSelectSections = ({
  expanded,
  children,
  toggle,
  id,
  label,
  checked,
  setChecked,
  sectionAssignments,
  usersList,
}) => {
  const isUserActive = (userId) => {
    let selectedUser = usersList.find(
      (user) => user.id === userId && user.active === true,
    );
    return selectedUser ? selectedUser.active : false;
  };

  return (
    <div
      className={`${BULK_SELECT_SECTIONS_BLOCK}`}
      id={`${BULK_SELECT_SECTIONS_ID}-${id}`}
    >
      <div
        className={classNames(
          `${BULK_SELECT_SECTIONS_BLOCK}__item`,
          expanded && `${BULK_SELECT_SECTIONS_BLOCK}__item--expanded`,
        )}
      >
        <div className={`${BULK_SELECT_SECTIONS_BLOCK}__item--col1`}>
          <div
            className={classNames(
              `${BULK_SELECT_SECTIONS_BLOCK}__arrow`,
              !expanded && `${BULK_SELECT_SECTIONS_BLOCK}__arrow--collapsed`,
            )}
            onClick={(e) => {
              toggle(id);
              e.stopPropagation();
            }}
          >
            {children && <ArrowDown />}
          </div>

          <Checkbox
            checked={checked}
            className={`${BULK_SELECT_SECTIONS_BLOCK}__item--col1__checkbox`}
            disabled={false}
            id={`${BULK_SELECT_SECTIONS_ID}-checkbox-${id}`}
            name={`${BULK_SELECT_SECTIONS_BLOCK}-checkbox-sectionId-${id}`}
            onChange={() => {
              setChecked(id, !checked);
            }}
            label={label}
            isNotIntl={true}
          />
        </div>

        <div className={`${BULK_SELECT_SECTIONS_BLOCK}__section-assignments`}>
          {sectionAssignments.sectionAssignments
            .filter((assignments) => assignments.sectionId === id)
            .map(
              (assignment, index) =>
                isUserActive(assignment.userId) && (
                  <ToolTip
                    key={`${assignment.sectionId}-user-${assignment.userId}-assignment-tooltip-id`}
                    id={`${BULK_SELECT_SECTIONS_BLOCK}__section-review-tooltip-${assignment.sectionId}-${assignment.userId}`}
                    className={`${BULK_SELECT_SECTIONS_BLOCK}__section-review-tooltip`}
                    text={
                      <FormattedMessage
                        id={'section-details.bulkassigned-user.tooltip'}
                        values={{
                          userName: getUserNameByUserId(assignment.userId),
                          date: moment(assignment.date).format(
                            DATE_FORMATS.MONTH_DAY_YEAR,
                          ),
                          time: moment(assignment.date).format(
                            DATE_FORMATS.HOURS_MINUTES,
                          ),
                        }}
                      />
                    }
                    isNotInternationalized
                  >
                    <div className={`${BULK_SELECT_SECTIONS_BLOCK}__assigned`}>
                      {getInitials(assignment.userName)}
                    </div>
                  </ToolTip>
                ),
            )}
        </div>
      </div>
      {children && (
        <div
          className={classNames(
            `${BULK_SELECT_SECTIONS_BLOCK}__children`,
            !expanded && `${BULK_SELECT_SECTIONS_BLOCK}__children--collapsed`,
          )}
        >
          {expanded && children}
        </div>
      )}
    </div>
  );
};

StatementNavBulkSelectSections.propTypes = {
  /**boolean value which indicates collapsed/expanded sections */
  expanded: PropTypes.bool,
  /**children of component */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**function to expand/collapse sections */
  toggle: PropTypes.func.isRequired,
  /**section id */
  id: PropTypes.number.isRequired,
  /**section label */
  label: PropTypes.string.isRequired,
  /**value which indicates selection/unselection of section */
  checked: PropTypes.bool.isRequired,
  /**function to set the state of checkbox(checked/unchecked) for a particular section */
  setChecked: PropTypes.func.isRequired,
  /** A reference to the assignments of the selected section */
  sectionAssignments: PropTypes.instanceOf(SectionAssignmentsList).isRequired,
  /** an array of all the users*/
  usersList: PropTypes.array.isRequired,
};

export default StatementNavBulkSelectSections;
