import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/common/card-component';
import CheckboxList from 'components/common/checkbox-list-component';
import { FormattedMessage } from 'react-intl';
import { LEGAL_HOLD_TOOLTIP } from 'constants/feature/project-edit-constants';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import RolesUtil from 'permissions/utils/roles';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
export const EDIT_MANAGE_LEGAL_HOLD_BLOCK = 'edit-manage-legal-hold';
const EDIT_MANAGE_LEGAL_HOLD_ID_BLOCK = 'edit-manage-legal-hold-id';

const INFORMATION_ICON_SIZE = '18px';
const ACTIVE_CHECKBOX = 'active';
const ARCHIVED_CHECKBOX = 'archived';
const DELETED_CHECKBOX = 'deleted';

const EditManageLegalHold = ({
  projectId,
  activeStatements,
  wrappedUpStatements,
  deletedStatements,
  setActiveStatements,
  setWrappedUpStatements,
  setDeletedStatements,
}) => {
  const [masterCheckboxActive, setMasterCheckboxActive] = useState(false);
  const [masterCheckboxWrapped, setMasterCheckboxWrapped] = useState(false);
  const [masterCheckboxDeleted, setMasterCheckboxDeleted] = useState(false);
  const hasPermissionToDisableLegalHold = RolesUtil.doesUserHavePPMDRoleForProject(
    projectId,
  );
  //this states are created to keep the original value of the props array
  // to be able to keep checkboxes enabled until the user saves the changes
  //and avoid disabling the checkboxes after the user clicks on them
  // and they don't have permission to disable legal hold
  const [copyActiveStatements] = useState(() => {
    return JSON.parse(JSON.stringify(activeStatements));
  });
  const [copyDeletedStatements] = useState(() => {
    return JSON.parse(JSON.stringify(deletedStatements));
  });
  const [copyWrappedUpStatements] = useState(() => {
    return JSON.parse(JSON.stringify(wrappedUpStatements));
  });
  const getCopyArray = (checkboxType) => {
    switch (checkboxType) {
      case ACTIVE_CHECKBOX: {
        return copyActiveStatements;
      }
      case ARCHIVED_CHECKBOX: {
        return copyWrappedUpStatements;
      }
      case DELETED_CHECKBOX: {
        return copyDeletedStatements;
      }
      default:
    }
  };
  const hasPermissionToEnableLegalHold =
    RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId);
  const ENABLE_LEGAL_HOLD_TOOLTIP = new TooltipOptions({
    text: 'project-edit.manage-legal-hold-enable-tooltip.text',
    id: 'project-edit-manage-legal-hold-enable-tool-tip',
    position: 'right',
  });
  const DISABLE_LEGAL_HOLD_TOOLTIP = new TooltipOptions({
    text: 'project-edit.manage-legal-hold-disable-tooltip.text',
    id: 'project-edit-manage-legal-hold-disable-tool-tip',
    position: 'right',
  });

  const statementToCheckboxInfo = (statementsArray, checkboxType) => {
    let checkboxArray = [];
    for (let i = 0; i < statementsArray.length; i++) {
      checkboxArray.push({
        label: statementsArray[i].statementName,
        checked: statementsArray[i].legalHold,
        tooltip: getCopyArray(checkboxType)[i].legalHold
          ? DISABLE_LEGAL_HOLD_TOOLTIP
          : ENABLE_LEGAL_HOLD_TOOLTIP,
        disabled: getCopyArray(checkboxType)[i].legalHold
          ? !hasPermissionToDisableLegalHold
          : !hasPermissionToEnableLegalHold,
      });
    }
    return checkboxArray;
  };
  const checkLegalHoldActive = () => {
    if (activeStatements && wrappedUpStatements && deletedStatements) {
      return (
        activeStatements.some((statement) => statement.isLegalHoldSet()) ||
        wrappedUpStatements.some((statement) => statement.isLegalHoldSet()) ||
        deletedStatements.some((statement) => statement.isLegalHoldSet())
      );
    }
    return false;
  };
  const setLegalHold = (index, statements) => {
    if (statements[index]) {
      statements[index].legalHold = statements[index].legalHold ? 0 : 1;
    }
    return statements;
  };
  const setChecked = (index, checkboxType) => {
    switch (checkboxType) {
      case ACTIVE_CHECKBOX: {
        setActiveStatements([...setLegalHold(index, activeStatements)]);
        break;
      }
      case ARCHIVED_CHECKBOX: {
        setWrappedUpStatements([...setLegalHold(index, wrappedUpStatements)]);
        break;
      }
      case DELETED_CHECKBOX: {
        setDeletedStatements([...setLegalHold(index, deletedStatements)]);
        break;
      }

      default:
    }
  };
  const checkCanChangeLegalHold = (
    masterCheckbox,
    statement,
    index,
    copyArray,
  ) => {
    if (masterCheckbox) {
      if (
        !hasPermissionToDisableLegalHold &&
        copyArray[index].legalHold !== 1
      ) {
        return masterCheckbox ? 0 : 1;
      } else if (hasPermissionToDisableLegalHold) {
        return masterCheckbox ? 0 : 1;
      }
    } else if (!masterCheckbox && hasPermissionToEnableLegalHold) {
      return masterCheckbox ? 0 : 1;
    }
    return statement.legalHold;
  };

  const masterCheckboxToggle = (checkboxType, newValue) => {
    //case checkbox list returns a value on setMasterCheckbox function
    // to set the checkbox as checked if the user selected all the checkboxes manually
    if (newValue !== null && newValue !== undefined) {
      switch (checkboxType) {
        case ACTIVE_CHECKBOX:
          setMasterCheckboxActive(newValue);
          break;
        case DELETED_CHECKBOX:
          setMasterCheckboxDeleted(newValue);
          break;
        case ARCHIVED_CHECKBOX:
          setMasterCheckboxWrapped(newValue);
          break;
        default:
      }
    } else {
      //case checkbox list returns a null value on setMasterCheckbox function
      // so this means user selected clicked on the master checkbox
      switch (checkboxType) {
        case ACTIVE_CHECKBOX: {
          activeStatements.forEach((element, index) => {
            element.legalHold = checkCanChangeLegalHold(
              masterCheckboxActive,
              element,
              index,
              copyActiveStatements,
            );
          });
          setActiveStatements([...activeStatements]);
          setMasterCheckboxActive((prevState) => !prevState);

          break;
        }
        case ARCHIVED_CHECKBOX: {
          wrappedUpStatements.forEach((element, index) => {
            element.legalHold = checkCanChangeLegalHold(
              masterCheckboxWrapped,
              element,
              index,
              copyWrappedUpStatements,
            );
          });
          setWrappedUpStatements([...wrappedUpStatements]);
          setMasterCheckboxWrapped((prevState) => !prevState);
          break;
        }
        case DELETED_CHECKBOX: {
          deletedStatements.forEach((element, index) => {
            element.legalHold = checkCanChangeLegalHold(
              masterCheckboxDeleted,
              element,
              index,
              copyDeletedStatements,
            );
          });
          setDeletedStatements([...deletedStatements]);
          setMasterCheckboxDeleted((prevState) => !prevState);
          break;
        }

        default:
      }
    }
  };
  return (
    <div className={EDIT_MANAGE_LEGAL_HOLD_BLOCK}>
      <Card>
        <h3 className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__title`}>
          <FormattedMessage id={'project-edit.manage-legal-hold.title'} />
          <Tooltip
            {...LEGAL_HOLD_TOOLTIP}
            className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__info-tooltip`}
          >
            <Information
              name={'information-legal-hold'}
              className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__information-icon`}
              width={INFORMATION_ICON_SIZE}
              height={INFORMATION_ICON_SIZE}
            />
          </Tooltip>
        </h3>
        <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__info`}>
          <FormattedMessage id={'project-edit.manage-legal-hold.info'} />
        </div>
        {checkLegalHoldActive() && (
          <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__banner`}>
            <Banner
              id={`${EDIT_MANAGE_LEGAL_HOLD_ID_BLOCK}-legal-hold-banner`}
              type={BANNER_TYPES.WARNING}
              bannerCopy={'statement-edit.statement-info.legal-hold.banner'}
              isInternationalized={true}
              isInteractive={false}
              width={'75%'}
            />
          </div>
        )}
        <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__statements`}>
          <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__statements-card`}>
            <Card>
              <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-title`}>
                <FormattedMessage
                  id={'project-edit.manage-legal-hold-active-card.title'}
                />
              </div>
              <CheckboxList
                className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-checkbox-list`}
                itemsList={statementToCheckboxInfo(
                  activeStatements,
                  ACTIVE_CHECKBOX,
                )}
                setCheckbox={(index) => setChecked(index, ACTIVE_CHECKBOX)}
                masterCheckboxChecked={masterCheckboxActive}
                setMasterCheckbox={(value) =>
                  masterCheckboxToggle(ACTIVE_CHECKBOX, value)
                }
                id={`${projectId}-active`}
              />
            </Card>
          </div>
          <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__statements-card`}>
            <Card>
              <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-title`}>
                <FormattedMessage
                  id={'project-edit.manage-legal-hold-wrapped-card.title'}
                />
              </div>
              <CheckboxList
                className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-checkbox-list`}
                itemsList={statementToCheckboxInfo(
                  wrappedUpStatements,
                  ARCHIVED_CHECKBOX,
                )}
                setCheckbox={(index) => setChecked(index, ARCHIVED_CHECKBOX)}
                masterCheckboxChecked={masterCheckboxWrapped}
                setMasterCheckbox={(value) =>
                  masterCheckboxToggle(ARCHIVED_CHECKBOX, value)
                }
                id={`${projectId}-archived`}
              />
            </Card>
          </div>
          <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__statements-card`}>
            <Card>
              <div className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-title`}>
                <FormattedMessage
                  id={'project-edit.manage-legal-hold-deleted-card.title'}
                />
              </div>
              <CheckboxList
                className={`${EDIT_MANAGE_LEGAL_HOLD_BLOCK}__card-checkbox-list`}
                itemsList={statementToCheckboxInfo(
                  deletedStatements,
                  DELETED_CHECKBOX,
                )}
                setCheckbox={(index) => setChecked(index, DELETED_CHECKBOX)}
                masterCheckboxChecked={masterCheckboxDeleted}
                setMasterCheckbox={(value) =>
                  masterCheckboxToggle(DELETED_CHECKBOX, value)
                }
                id={`${projectId}-deleted`}
              />
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
};
EditManageLegalHold.propTypes = {
  /* id of the current selected project */
  projectId: PropTypes.string.isRequired,
  /* array of active statements */
  activeStatements: PropTypes.array.isRequired,
  /* array of wrapped up statements */
  wrappedUpStatements: PropTypes.array.isRequired,
  /* array of statements */
  deletedStatements: PropTypes.array.isRequired,
  /* function to set active statements data on a project */
  setActiveStatements: PropTypes.func.isRequired,
  /* function to set wrapped up statements data on a project */
  setWrappedUpStatements: PropTypes.func.isRequired,
  /* function to set deleted statements data on a project */
  setDeletedStatements: PropTypes.func.isRequired,
};
export default EditManageLegalHold;
