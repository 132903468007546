import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';

import Card from 'components/common/card-component';
import { SearchTableDropdownApi } from 'components/common/search-component';
import Checkbox from 'components/common/checkbox-component';
import DataGrid, {
  DataGridData,
  DataGridDataApi,
} from 'components/common/data-grid/data-grid-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import {
  USER_SEARCH_COLUMNS,
  getAddedUserColumns,
} from 'constants/feature/project-creation-constants';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import ProgressBarStep from 'components/common/progress-bar-step-component';
import { UserList } from 'models/api/users-list-model';
import ProjectForm from 'models/form/project-form-model';
import { LearnMoreAboutRolesModal } from '../project-info/learn-more-about-roles-modal';
import { ViewDisclaimerModal } from 'components/feature/project-creation/manage-users/support-team-access-view-disclaimer-modal';
import { getUserListRequest } from 'api/project-creation-edit-api';
import { AcknowledgeDisclaimerModal } from 'components/feature/project-creation/manage-users/support-team-access-acknowledge-disclaimer-modal';

export const MANAGE_USERS_BLOCK = 'manage-users';
const MANAGE_USERS_ID_BLOCK = 'manage-users-id';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      showLearnMoreModal: false,
      showViewDisclaimerModal: false,
      showAcknowledgeDisclaimerModal: false,
      hasBeenOpened: false,
      userListModel: new UserList(),
    };
    // do not call this function directly, call _searchUser, which uses the debounced version of this function
    this.debouncedSearch = debounceSearch(this._fetchSearchUserList);
  }

  componentDidUpdate() {
    const { projectInProgress } = this.props;
    const { hasBeenOpened } = this.state;
    if (!hasBeenOpened && projectInProgress.isStepOneValid()) {
      this.setState({
        hasBeenOpened: true,
      });
    }
  }

  _fetchSearchUserList = async ({ search }) => {
    const { projectInProgress } = this.props;
    this.setState((state) => ({
      userListModel: state.userListModel.setLoading(),
    }));
    try {
      const response = await getUserListRequest({
        /**
         * We need to pass a clientId of 0 to the back-end, as there is different search permissions logic for create than for edit.
         * In create, all users are considered to be engagement owners until they hit the create button, so this id of 0 allows us to
         * distinguish that flow.
         *  */

        clientId: !isNullOrUndefined(projectInProgress.id)
          ? projectInProgress.id
          : -1,
        searchString: search,
        numberOfRows: 50,
        pageNumber: 1,
      });
      this.setState((state) => ({
        userListModel: state.userListModel.setLoaded({ response }),
      }));
    } catch (err) {
      this.setState((state) => ({
        userListModel: state.userListModel.setError(err),
      }));
    }
  };

  _searchUser = (val) => {
    this.setState({ search: val }, () => {
      if (isSearchCharLengthValid(this.state.search)) {
        this.debouncedSearch({ search: this.state.search });
      }
    });
  };

  // always display at least one extra row so you don't just have a floating table header
  _getNumAddedUserRows = () =>
    this.props.projectInProgress.userEntities.length || 1;

  _toggleLearnMoreModal = () => {
    this.setState((state) => ({
      showLearnMoreModal: !state.showLearnMoreModal,
    }));
  };

  _toggleViewDisclaimerModal = () => {
    this.setState((state) => ({
      showViewDisclaimerModal: !state.showViewDisclaimerModal,
    }));
  };

  _toggleAcknowledgementDisclaimerModal = () => {
    this.setState((state) => ({
      showAcknowledgeDisclaimerModal: !state.showAcknowledgeDisclaimerModal,
    }));
  };

  _onSupportTeamAccessChange = () => {
    const { projectInProgress, toggleHasAdminGroup } = this.props;
    if (projectInProgress.hasAdminGroup) {
      toggleHasAdminGroup();
    } else {
      this._toggleAcknowledgementDisclaimerModal();
    }
  };

  render() {
    const {
      projectInProgress,
      addUserToProject,
      removeUserFromProject,
      assignUserProjectRole,
      toggleHasAdminGroup,
    } = this.props;
    const {
      search,
      showLearnMoreModal,
      showViewDisclaimerModal,
      showAcknowledgeDisclaimerModal,
      hasBeenOpened,
      userListModel,
    } = this.state;
    return (
      <div className={MANAGE_USERS_BLOCK}>
        <div className={`${MANAGE_USERS_BLOCK}__step`}>
          <ProgressBarStep
            id={`${MANAGE_USERS_ID_BLOCK}-step`}
            active={
              projectInProgress.isStepOneValid() &&
              !projectInProgress.isStepTwoValid()
            }
            isValid={projectInProgress.isStepTwoValid()}
            stepNum={'2'}
          />
        </div>
        <div className={`${MANAGE_USERS_BLOCK}__card`}>
          <Card>
            <h3 className={`${MANAGE_USERS_BLOCK}__title`}>
              <FormattedMessage id={'project-creation.manage-users.title'} />
            </h3>
            {hasBeenOpened ? (
              <React.Fragment>
                <div className={`${MANAGE_USERS_BLOCK}__info`}>
                  <FormattedMessage id={'project-creation.manage-users.info'} />
                  <button
                    id={`${MANAGE_USERS_ID_BLOCK}-learn-more`}
                    className={`${MANAGE_USERS_BLOCK}__learn-more-button`}
                    onClick={() => this._toggleLearnMoreModal()}
                  >
                    <FormattedMessage
                      id={'project-creation.manage-users.learn-more'}
                    />
                  </button>
                </div>
                <div className={`${MANAGE_USERS_BLOCK}__search`}>
                  <SearchTableDropdownApi
                    columns={USER_SEARCH_COLUMNS}
                    id={`${MANAGE_USERS_ID_BLOCK}-user-search`}
                    label={'project-creation.manage-users.search'}
                    placeholder={
                      'project-creation.manage-users.search.placeholder'
                    }
                    onChange={this._searchUser}
                    onClear={() => this._searchUser('')}
                    value={search}
                    isOptionSelected={(user) =>
                      projectInProgress.containsUser(user)
                    }
                    onSelectResult={(id) => {
                      addUserToProject(id);
                      this.setState({
                        search: '',
                      });
                    }}
                    shouldDisableMenuOption={(user) =>
                      projectInProgress.containsUser(user)
                    }
                    showSearchButton
                    isValid
                    resultsApiModel={
                      new DataGridDataApi({
                        apiModel: userListModel,
                        rowItems: userListModel.getUsers(),
                      })
                    }
                  />
                </div>
                {!projectInProgress.isPpmdUserAdded() && (
                  <Banner
                    id={`${MANAGE_USERS_ID_BLOCK}-ppmd-banner`}
                    className={`${MANAGE_USERS_BLOCK}__banner`}
                    type={BANNER_TYPES.INFORMATION}
                    bannerCopy={'project-creation.manage-users.ppmd-banner'}
                    isInternationalized={true}
                    width={'75%'}
                  />
                )}
                {!projectInProgress.isEngagementOwnerUserAdded() && (
                  <Banner
                    id={`${MANAGE_USERS_ID_BLOCK}-ppmd-banner`}
                    className={`${MANAGE_USERS_BLOCK}__banner`}
                    type={BANNER_TYPES.INFORMATION}
                    bannerCopy={
                      'project-creation.manage-users.engagement-owner-banner'
                    }
                    isInternationalized={true}
                    width={'75%'}
                  />
                )}
                <DataGrid
                  columns={getAddedUserColumns({
                    onRemoveClick: removeUserFromProject,
                  })}
                  className={`${MANAGE_USERS_BLOCK}__added-users`}
                  tableId={`${MANAGE_USERS_ID_BLOCK}-added-users`}
                  dataModel={
                    new DataGridData({
                      rowItems: projectInProgress.userEntities,
                    })
                  }
                  numberOfVisibleRows={this._getNumAddedUserRows()}
                  onDropdownColumnSelect={(role, rowId) => {
                    assignUserProjectRole({
                      userId: rowId,
                      role,
                    });
                  }}
                />
                <div className={`${MANAGE_USERS_BLOCK}__checkbox`}>
                  <Checkbox
                    checked={projectInProgress.hasAdminGroup}
                    value={'support-team-access'}
                    onChange={() => this._onSupportTeamAccessChange()}
                    label={'project-creation.manage-users.support-team-access'}
                    id={`${MANAGE_USERS_ID_BLOCK}-legal-hold-checkbox`}
                    width={'75%'}
                    isValid={true}
                  />
                </div>
                <div className={`${MANAGE_USERS_BLOCK}__view-disclaimer`}>
                  <button
                    id={`${MANAGE_USERS_ID_BLOCK}-disclaimer`}
                    className={`${MANAGE_USERS_BLOCK}__disclaimer-button`}
                    onClick={() => this._toggleViewDisclaimerModal()}
                  >
                    <FormattedMessage
                      id={
                        'project-creation.manage-users.support-team-access.disclaimer'
                      }
                    />
                  </button>
                </div>
                {projectInProgress.hasAdminGroup && (
                  <Banner
                    width={'75%'}
                    id={`${MANAGE_USERS_ID_BLOCK}-support-team-banner`}
                    className={`${MANAGE_USERS_BLOCK}__banner`}
                    type={BANNER_TYPES.INFORMATION}
                    bannerCopy={
                      'project-creation.manage-users.support-team-access.banner'
                    }
                    isInternationalized
                  />
                )}
              </React.Fragment>
            ) : null}
          </Card>
        </div>
        {showLearnMoreModal && (
          <LearnMoreAboutRolesModal onClose={this._toggleLearnMoreModal} />
        )}
        {showViewDisclaimerModal && (
          <ViewDisclaimerModal onClose={this._toggleViewDisclaimerModal} />
        )}
        {showAcknowledgeDisclaimerModal && (
          <AcknowledgeDisclaimerModal
            onClose={() => this._toggleAcknowledgementDisclaimerModal()}
            onSubmit={() => {
              toggleHasAdminGroup();
              this._toggleAcknowledgementDisclaimerModal();
            }}
          />
        )}
      </div>
    );
  }
}

ManageUsers.propTypes = {
  /** Project object to create and be submitted */
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Function fired for adding a user to this project */
  addUserToProject: PropTypes.func.isRequired,
  /** Function fired for removing a user from this project */
  removeUserFromProject: PropTypes.func.isRequired,
  /** Function fired for assigning a role to a user on this project */
  assignUserProjectRole: PropTypes.func.isRequired,
  /** Function fired for adding suport team access on this project */
  toggleHasAdminGroup: PropTypes.func.isRequired,
};

export default ManageUsers;
