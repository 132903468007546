import ElementComfortLetterList from 'models/api/element-comfort-letter-list-model';
import { handleActions } from 'redux-actions';
import {
  elementComfortLetterListLoading,
  elementComfortLetterListLoaded,
  elementComfortLetterListLoadedWithoutResponse,
  elementComfortLetterListError,
} from 'store/actions/element-panel-actions';

export default handleActions(
  {
    [elementComfortLetterListLoading]: (comfortLetterList) => {
      return comfortLetterList.setLoading();
    },
    [elementComfortLetterListLoaded]: (comfortLetterList, { payload }) => {
      return comfortLetterList.setLoaded(payload);
    },
    [elementComfortLetterListLoadedWithoutResponse]: (comfortLetterList) => {
      return comfortLetterList.setLoadedWithoutResponse();
    },
    [elementComfortLetterListError]: (comfortLetterList, { payload }) => {
      return comfortLetterList.setError(payload);
    },
  },
  new ElementComfortLetterList(),
);
