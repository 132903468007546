import { createAction } from 'redux-actions';
import { getUserOPMProfile } from 'api/current-user-api';
import { currentUserLoading, currentUserError } from './current-user-actions';
import { userAuth401Error } from './user-auth-actions';

export const setUserHomeGeo = createAction('SET_USER_HOME_GEO');

export const fetchUserOPMProfile = () => async (dispatch, getState) => {
  try {
    dispatch(currentUserLoading());
    const opmResponse = await getUserOPMProfile();

    const { response } = opmResponse;
    if (response && response.status === 401)
      await dispatch(userAuth401Error(response));
    else dispatch(setUserHomeGeo(opmResponse));
  } catch (error) {
    dispatch(currentUserError(error));
  }
};
