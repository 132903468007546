import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from 'components/common/data-grid/data-grid-component';
import {
  getRevisionHistoryListColumns,
  NUM_VISIBLE_ROWS_REVISIONS_MODAL,
} from 'constants/feature/revision-history-constants';
import { DataGridDataApi } from 'models/utils/common/data-grid/data-grid-data-model';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import { RevisionList } from 'models/api/revision-list-api-model';
import revisionApi from 'api/revision-api.js';
import { REVISION_STATUSES } from 'constants/feature/revision-status-constants';
import ProjectApi from 'models/api/project-api-model';
import { history } from 'store/store';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';

const REVISION_HISTORY_BLOCK = 'revision-history-modal';
const REVISION_HISTORY_ID_BLOCK = 'revision-history-modal-id';

class RevisionHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revisionList: new RevisionList(),
    };
  }

  componentDidMount() {
    this._fetchRevisions();
  }

  _fetchRevisions = async () => {
    const { selectedStatementId } = this.props;

    this.setState(({ revisionList }) => ({
      revisionList: revisionList.setLoading(),
    }));
    try {
      const response = await revisionApi.getAllRevisionsForStatement(
        selectedStatementId,
      );
      this.setState(({ revisionList }) => ({
        revisionList: revisionList.setLoaded({ response }),
      }));
    } catch (e) {
      this.setState(({ revisionList }) => ({
        revisionList: revisionList.setError(e),
      }));
    }
  };

  render() {
    const { project, toggleStatementRevisonsModal } = this.props;
    const { revisionList } = this.state;

    return (
      <Modal
        id={`${REVISION_HISTORY_ID_BLOCK}`}
        title={'statement-list.revisions-modal.title'}
        onClose={toggleStatementRevisonsModal}
        primaryModalButton={
          new ModalButton({
            text: 'common.close',
            onClick: toggleStatementRevisonsModal,
          })
        }
      >
        <DataGrid
          className={`${REVISION_HISTORY_BLOCK}__revisions-list`}
          columns={getRevisionHistoryListColumns({
            onRevisionLinkIconClick: (revision) =>
              history.push(
                parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
                  params: {
                    projectId: project.id,
                    revisionId: revision.id,
                    statementId: revision.statementId,
                    readOnly: true,
                  },
                }),
              ),
          })}
          tableId={`${REVISION_HISTORY_ID_BLOCK}-revision-modal-table`}
          rowClassnames={(revision) => [
            `${REVISION_HISTORY_BLOCK}__revisions-list-item`,
            revision.status === REVISION_STATUSES.DELETED.id
              ? `${REVISION_HISTORY_BLOCK}__revisions-list-item--deleted`
              : null,
          ]}
          dataModel={
            new DataGridDataApi({
              apiModel: revisionList,
              rowItems: revisionList.revisions,
            })
          }
          scrollable
          numberOfVisibleRows={NUM_VISIBLE_ROWS_REVISIONS_MODAL}
        />
      </Modal>
    );
  }
}

RevisionHistoryModal.propTypes = {
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Method pn the statement list component that opens the show revision modal */
  toggleStatementRevisonsModal: PropTypes.func.isRequired,
  /** Statement id to fetch revisions for */
  selectedStatementId: PropTypes.number.isRequired,
};

export default RevisionHistoryModal;
