import { getStatementIdUtil } from 'utils/statement-export-utils';
import { withDefaultContextAsProject } from 'api/api-default-context';

export const createProjectEntityRequest = ({
  projectId,
  revisionId,
  entity,
  contextKey,
}) => {
  return withDefaultContextAsProject({ contextKey }, projectId)
    .post(
      `/tieout/client/${parseInt(
        projectId,
      )}/statements/${getStatementIdUtil()}/revision/${revisionId}/entity/${entity}`,
    )
    .then((response) => {
      return response;
    });
};
