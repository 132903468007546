import { createAction } from 'redux-actions';
import {
  closeSocketClientRequest,
  //   removeUserFromRoom,
} from 'api/project-socket-client-api';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';

export const setProjectSocketClient = createAction('SET_PROJECT_SOCKET_CLIENT');

export const initProjectSocketIOSocketClient = createAction(
  'INIT_PROJECT_SOCKETIO_SOCKET_CLIENT',
);

export const initProjectSignalrSocketClient = createAction(
  'INIT_PROJECT_SIGNALR_SOCKET_CLIENT',
);

export const clearProjectSocketClientInStore = createAction(
  'CLEAR_PROJECT_SOCKET_CLIENT',
);

export const stopProjectSocketConnection = createAction(
  'STOP_PROJECT_SOCKET_CONNECTION',
);

export const setProjectSocketDisconnectAction = createAction(
  'SET_PROJECT_SOCKET_DISCONNECT_ACTION',
);

export const initProjectSocketClient = (projectId) => async (
  dispatch,
  getState,
) => {
  const { data: { currentUser } = {} } = getState();
  const { data: { projectToGeoMap } = {} } = currentUser || {};
  const geoCode = projectId
    ? (projectToGeoMap.get(`${projectId}`) || {}).geoCode
    : null;
  if (isSignalRForSocketConnectionEnabled) {
    await dispatch(initProjectSignalrSocketClient({ projectId, geoCode }));
  } else {
    await dispatch(initProjectSocketIOSocketClient({ projectId, geoCode }));
  }
};

export const closeProjectSocketClient = (projectId) => async (
  dispatch,
  getState,
) => {
  await dispatch(stopProjectSocketConnection());
  await dispatch(clearProjectSocketClientInStore());
  try {
    if (isSignalRForSocketConnectionEnabled) {
      const connectionId = localStorage.getItem('projectConnectionId');
      await closeSocketClientRequest({
        projectId,
        connectionId,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
