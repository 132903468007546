import { createAction } from 'redux-actions';
import {
  getSectionIdListRequest,
  getSectionSegmentsRequest,
  getRevisionPeriodListRequest,
  getClientEntityListRequest,
  getBlacklineViewStatusRequest,
} from 'api/statement-content-api';
import { getElementsBySectionRequest } from 'api/element-api';
import {
  clearStatementPageRevisionStoreAction,
  fetchRevision,
} from 'store/actions/revision-actions';
import { fetchLeftRevision } from 'store/actions/left-revision-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { updateSelectedProjectFromID } from 'store/actions/selected-project-actions';
import {
  createSectionCacheTracker,
  createLeftSectionCacheTracker,
  updateSectionViewedTimestamp,
  checkCacheToPurge,
  calculateSizeAndAddSectionToCache,
  getContentSectionMapRequest,
  getLeftContentSectionMapRequest,
} from 'store/actions/section-cache-actions';
import { checkLeftCacheToPurge } from 'store/actions/left-statement-section-content-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import { batchAddElementFormulaRows } from 'store/actions/formula-actions';
import {
  searchElements,
  deselectSelectedElement,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';
import { scrollSectionIntoView } from 'utils/scrolling-utils';
import { batchAddElementsToBatch } from './batch-panel-actions';
import {
  fetchNotesBySection,
  fetchLeftNotesBySection,
} from './notes-cache-actions';
import {
  hideSectionDetailsPanelAction,
  showSectionDetailsPanelAction,
  showStatementNavPanelAction,
} from 'store/actions/panel-controller-actions';
import {
  fetchSectionReviews,
  fetchSectionReviewsWithLoading,
} from 'store/actions/sections-actions';
import {
  fetchLeftSectionReviews,
  setSourceStatementBatchElementsMapAction,
  setTargetStatementBatchElementsMapAction,
} from './side-by-side-statement/side-by-side-statement-actions';
import {
  setStatementSocketClient,
  initStatementSocketClientAction,
} from 'store/actions/statement-socket-actions';
import { addUserToRevision } from 'store/actions/active-users-actions';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { selectNoteFromNoteListAction } from 'store/actions/notes-panel-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { fetchSectionAssignmentsList } from 'store/actions/section-assignment-actions';
import { addElementForCopyFormula } from 'store/actions/copy-formula-actions';
import { getAllUsers } from 'store/actions/project-users-list-actions';

import { getUpdatedSectionElementsNotesSummaryCount } from '../../utils/heading-data-utils';
import { fetchSectionTreeAction } from './section-tree-list-actions';
import { blacklineTogleNotification } from 'utils/statement-content-page-utils';
import { BLACKLINE_VIEW_STATUS } from 'constants/feature/statement-content-constants';
import { fetchLeftSectionTreeAction } from './side-by-side-statement/left-statement-section-tree-list-actions';
import { mapElementsRequest } from 'api/side-by-side-view-api';
import {
  successElementMapNotification,
  failureElementMapNotification,
  seggregateSourceTargetElements,
  highlightMappedElementsByResponse,
  handleElementMappingFailure,
} from 'constants/feature/tieout-element-utils';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { updateCacheWithMostRecentSideBySideElementChanges } from 'store/actions/element-changes-since-tracker-actions';
import { CONTEXT_KEY } from 'api/api-client';
import {
  fetchSelectedStatement,
  selectedStatementError,
} from './selected-statement-actions';
import {
  addElementsToSourceListInSideBySideMode,
  addElementsToTargetListInSideBySideMode,
} from './left-side-by-side-batch-actions';

let isIntervalAlreadySet = null;
export const mapElementsSuccessResult = 'success';
export const mapElementsFailureResult = 'failure';

export const sectionIdListStatementContentLoading = createAction(
  'SECTION_ID_LIST_STATEMENT_CONTENT_LOADING',
);
export const sectionIdListStatementContentLoaded = createAction(
  'SECTION_ID_LIST_STATEMENT_CONTENT_LOADED',
);
export const sectionIdListStatementContentError = createAction(
  'SECTION_ID_LIST_STATEMENT_CONTENT_ERROR',
);
export const leftSectionIdListStatementContentLoading = createAction(
  'LEFT_SECTION_ID_LIST_STATEMENT_CONTENT_LOADING',
);
export const leftSectionIdListStatementContentLoaded = createAction(
  'LEFT_SECTION_ID_LIST_STATEMENT_CONTENT_LOADED',
);
export const leftSectionIdListStatementContentError = createAction(
  'LEFT_SECTION_ID_LIST_STATEMENT_CONTENT_ERROR',
);
export const sectionHTMLSegmentsStatementContentLoading = createAction(
  'SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADING',
);
export const sectionHTMLSegmentsStatementContentLoaded = createAction(
  'SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADED',
);
export const sectionHTMLSegmentsStatementContentLoadedWithoutResponse =
  createAction(
    'SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADED_WITHOUT_RESPONSE',
  );
export const sectionHTMLSegmentsStatementContentError = createAction(
  'SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_ERROR',
);
export const leftSectionHTMLSegmentsStatementContentLoading = createAction(
  'LEFT_SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADING',
);
export const leftSectionHTMLSegmentsStatementContentLoaded = createAction(
  'LEFT_SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADED',
);
export const leftSectionHTMLSegmentsStatementContentLoadedWithoutResponse =
  createAction(
    'LEFT_SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_LOADED_WITHOUT_RESPONSE',
  );
export const leftSectionHTMLSegmentsStatementContentError = createAction(
  'LEFT_SECTION_HTML_SEGMENTS_STATEMENT_CONTENT_ERROR',
);
export const setSectionInViewStatementContent = createAction(
  'SET_SECTION_IN_VIEW_STATEMENT_CONTENT',
);
export const clearSectionInViewStatementContent = createAction(
  'CLEAR_SECTION_IN_VIEW_STATEMENT_CONTENT',
);
export const clearSectionContentWithIdsFromCache = createAction(
  'CLEAR_CONTENT_WITH_IDS_FROM_CACHE',
);
export const sectionElementsLoading = createAction('SECTION_ELEMENTS_LOADING');
export const sectionElementsLoaded = createAction('SECTION_ELEMENTS_LOADED');
export const sectionElementsError = createAction('SECTION_ELEMENTS_ERROR');

export const leftSectionElementsLoading = createAction(
  'LEFT_SECTION_ELEMENTS_LOADING',
);
export const leftSectionElementsLoaded = createAction(
  'LEFT_SECTION_ELEMENTS_LOADED',
);
export const leftSectionElementsError = createAction(
  'LEFT_SECTION_ELEMENTS_ERROR',
);

export const setStatementContentTab = createAction('SET_STATEMENT_CONTENT_TAB');

export const selectElementContentPanel = createAction(
  'SELECT_ELEMENT_CONTENT_PANEL',
);
export const deselectElementContentPanel = createAction(
  'DESELECT_ELEMENT_CONTENT_PANEL',
);
export const deselectElementsFromArrayContentPanel = createAction(
  'DESELECT_ELEMENT_FROM_ARRAY_CONTENT_PANEL',
);
export const clearSelectedElementsContentPanel = createAction(
  'CLEAR_SELECTED_ELEMENTS_CONTENT_PANEL',
);
export const clearLeftSelectedElementsContentPanel = createAction(
  'CLEAR_LEFT_SELECTED_ELEMENTS_CONTENT_PANEL',
);
export const selectMultipleElementsContentPanel = createAction(
  'SELECT_MULTIPLE_ELEMENTS_CONTENT_PANEL',
);
export const replaceSelectedElementsContentPanel = createAction(
  'REPLACE_SELECTED_ELEMENTS_CONTENT_PANEL',
);
export const replaceSelectedElementWithoutRemovingOld = createAction(
  'REPLACE_SELECTED_ELEMENTS_WITHOUT_REMOVING_OLD',
);
export const replaceLeftSelectedElementWithoutRemovingOld = createAction(
  'REPLACE_LEFT_SELECTED_ELEMENTS_WITHOUT_REMOVING_OLD',
);
export const deallocateSelectedElementsContentPanel = createAction(
  'DEALLOCATE_SELECTED_ELEMENTS_CONTENT_PANEL',
);

export const setHighlightedElementIdContentPanel = createAction(
  'SET_HIGHLIGHTED_ELEMENT_ID_CONTENT_PANEL',
);

export const selectSectionContentPanel = createAction(
  'SELECT_SECTION_CONTENT_PANEL',
);
export const deselectSectionContentPanel = createAction(
  'DESELECT_SECTION_CONTENT_PANEL',
);
export const clearSelectedSectionsContentPanel = createAction(
  'CLEAR_SELECTED_SECTIONS_CONTENT_PANEL',
);
export const selectMultipleSectionsContentPanel = createAction(
  'SELECT_MULTIPLE_SECTIONS_CONTENT_PANEL',
);
export const replaceSelectedSectionsContentPanel = createAction(
  'REPLACE_SELECTED_SECTIONS_CONTENT_PANEL',
);
export const deallocateSelectedSectionsContentPanel = createAction(
  'DEALLOCATE_SELECTED_SECTIONS_CONTENT_PANEL',
);
export const periodListStatementContentLoading = createAction(
  'PERIOD_LIST_STATEMENT_CONTENT_LOADING',
);
export const periodListStatementContentLoaded = createAction(
  'PERIOD_LIST_STATEMENT_CONTENT_LOADED',
);
export const periodListStatementContentError = createAction(
  'PERIOD_LIST_STATEMENT_CONTENT_ERROR',
);

export const entityListStatementContentLoading = createAction(
  'ENTITY_LIST_STATEMENT_CONTENT_LOADING',
);

export const entityListStatementContentLoaded = createAction(
  'ENTITY_LIST_STATEMENT_CONTENT_LOADED',
);

export const entityListStatementContentError = createAction(
  'ENTITY_LIST_STATEMENT_CONTENT_ERROR',
);

export const setContentSearchAction = createAction('SET_CONTENT_SEARCH');
export const clearContentSearchAction = createAction('CLEAR_CONTENT_SEARCH');

export const setContentHighlightSelector = createAction(
  'SET_CONTENT_HIGHLIGHT_SELECTOR',
);

export const clearContentHighlightSelector = createAction(
  'CLEAR_CONTENT_HIGHLIGHT_SELECTOR',
);
export const clearStatementPageStoreAction = createAction(
  'CLEAR_STATEMENT_PAGE_STORE',
);

export const elementDatapartStoreAction = createAction(
  'UPDATE_ELEMENT_DATAPART_STORE',
);

export const leftElementDatapartStoreAction = createAction(
  'UPDATE_LEFT_ELEMENT_DATAPART_STORE',
);

export const sectionDetailsLoaded = createAction('SECTION_DETAILS_LOADED');
export const sectionDetailsLoading = createAction('SECTION_DETAILS_LOADING');
export const clearSectionDetails = createAction('CLEAR_SECTION_DETAILS');

export const fetchSectionIdListStatementContent =
  (revisionId) => async (dispatch) => {
    dispatch(sectionIdListStatementContentLoading());
    try {
      const response = await getSectionIdListRequest(revisionId);
      dispatch(
        sectionIdListStatementContentLoaded({
          response,
        }),
      );
    } catch (error) {
      dispatch(sectionIdListStatementContentError(error));
    }
  };

export const fetchLeftSectionIdListStatementContent =
  (revisionId, statementId, projectId) => async (dispatch) => {
    dispatch(leftSectionIdListStatementContentLoading());
    try {
      const response = await getSectionIdListRequest(revisionId, statementId, {
        contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
        contextValue: projectId,
      });
      dispatch(
        leftSectionIdListStatementContentLoaded({
          response,
        }),
      );
    } catch (error) {
      dispatch(leftSectionIdListStatementContentError(error));
    }
  };

export const fetchRevisionPeriodList = (revisionId) => async (dispatch) => {
  dispatch(periodListStatementContentLoading());
  try {
    const response = await getRevisionPeriodListRequest(revisionId);
    dispatch(
      periodListStatementContentLoaded({
        response,
      }),
    );
  } catch (error) {
    dispatch(periodListStatementContentError(error));
  }
};

export const fetchClientEntityList = (clientId) => async (dispatch) => {
  dispatch(entityListStatementContentLoading());
  try {
    const response = await getClientEntityListRequest(clientId);
    dispatch(
      entityListStatementContentLoaded({
        response,
      }),
    );
  } catch (error) {
    dispatch(entityListStatementContentError(error));
  }
};

export const fetchSectionHTMLSegmentsStatementContentWithoutLoading =
  ({ sectionIdList, segmentType }) =>
  async (dispatch, getStore) => {
    const {
      data: { revision },
    } = getStore();
    getSectionSegmentsRequest({
      revisionId: revision.id,
      sectionIdList,
      segmentType,
    })
      .then((response) => {
        const {
          ui: {
            statementPage: { contentSearch },
          },
        } = getStore();
        dispatch(
          sectionHTMLSegmentsStatementContentLoaded({
            response,
            searchTerm: contentSearch,
          }),
        );
        dispatch(
          calculateSizeAndAddSectionToCache({ sectionId: sectionIdList[0] }),
        );
        // after set in cache, evaluate cache
        dispatch(checkCacheToPurge());
        dispatch(
          elementDatapartStoreAction({
            response,
          }),
        );
      })
      .catch((error) => {
        dispatch(sectionHTMLSegmentsStatementContentError(error));
      });
  };

export const fetchSectionHTMLSegmentsStatementContent =
  ({ sectionIdList, segmentType, scrollToSection }) =>
  async (dispatch, getStore) => {
    const {
      data: { revision },
    } = getStore();
    dispatch(sectionHTMLSegmentsStatementContentLoading(sectionIdList));
    getSectionSegmentsRequest({
      revisionId: revision.id,
      sectionIdList,
      segmentType,
    })
      .then((response) => {
        const {
          ui: {
            statementPage: { contentSearch },
          },
        } = getStore();
        dispatch(
          sectionHTMLSegmentsStatementContentLoaded({
            response,
            searchTerm: contentSearch,
          }),
        );
        const sectionId = sectionIdList[0];
        if (scrollToSection && !isNullOrUndefined(sectionId)) {
          scrollSectionIntoView(sectionId);
        }
        dispatch(
          calculateSizeAndAddSectionToCache({ sectionId: sectionIdList[0] }),
        );
        // after set in cache, evaluate cache
        dispatch(checkCacheToPurge());
        dispatch(
          elementDatapartStoreAction({
            response,
          }),
        );
      })
      .catch((error) => {
        dispatch(sectionHTMLSegmentsStatementContentError(error));
      });
  };

export const fetchLeftSectionHTMLSegmentsStatementContent =
  ({ statementId, sectionIdList, segmentType, scrollToSection }) =>
  async (dispatch, getStore) => {
    const {
      data: { leftRevision },
      ui: {
        sourceStatementParams: { projectId },
      },
    } = getStore();
    dispatch(leftSectionHTMLSegmentsStatementContentLoading(sectionIdList));
    getSectionSegmentsRequest({
      statementId,
      revisionId: leftRevision.id,
      sectionIdList,
      segmentType,
      contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
      contextValue: projectId,
    })
      .then((response) => {
        const {
          ui: {
            statementPage: { contentSearch },
          },
        } = getStore();
        dispatch(
          leftSectionHTMLSegmentsStatementContentLoaded({
            response,
            searchTerm: contentSearch,
          }),
        );
        const sectionId = sectionIdList[0];
        if (scrollToSection && !isNullOrUndefined(sectionId)) {
          scrollSectionIntoView(sectionId);
        }
        // after set in cache, evaluate cache for left statement of side by side view page
        dispatch(checkLeftCacheToPurge());
        dispatch(
          leftElementDatapartStoreAction({
            response,
          }),
        );
      })
      .catch((error) => {
        dispatch(leftSectionHTMLSegmentsStatementContentError(error));
      });
  };

export const fetchElementsBySection =
  ({ sectionId }) =>
  (dispatch, getStore) => {
    const { revision } = getStore().data;
    dispatch(sectionElementsLoading({ sectionId }));
    getElementsBySectionRequest({
      sectionId,
      revisionId: revision.id,
    })
      .then((response) => {
        dispatch(
          sectionElementsLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(sectionElementsError({ sectionId, error }));
      });
  };

export const fetchLeftElementsBySection =
  ({ sectionId, statementId }) =>
  (dispatch, getStore) => {
    const { leftRevision } = getStore().data;
    const { sourceStatementParams } = getStore().ui;
    dispatch(leftSectionElementsLoading({ sectionId }));
    getElementsBySectionRequest({
      statementId,
      sectionId,
      revisionId: leftRevision.id,
      contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
      contextValue: sourceStatementParams.projectId,
    })
      .then((response) => {
        dispatch(
          leftSectionElementsLoaded({
            response,
            sectionId,
          }),
        );
      })
      .catch((error) => {
        dispatch(leftSectionElementsError({ sectionId, error }));
      });
  };

/**
 * Fetches all data necessary for rendering section content and element annotations
 * @param {number} param.sectionId sectionId to fetch info for
 */
export const fetchAllSectionRenderingData =
  ({ sectionId, scrollToSection }) =>
  (dispatch, getStore) => {
    const { blacklineViewMode } = getStore().ui.statementPage.modes;

    dispatch(
      fetchSectionHTMLSegmentsStatementContent({
        sectionIdList: [sectionId],
        segmentType: blacklineViewMode ? 'C' : 'P',
        scrollToSection,
      }),
    );
    dispatch(fetchElementsBySection({ sectionId }));
    dispatch(fetchNotesBySection({ sectionId }));
  };

/**
 * Fetches all data necessary for rendering section content and element annotations
 * @param {number} param.sectionId sectionId to fetch info for
 */
export const fetchAllLeftSectionRenderingData =
  ({ sectionId, scrollToSection }) =>
  (dispatch, getStore) => {
    const { sourceStatementParams } = getStore().ui;

    dispatch(
      fetchLeftSectionHTMLSegmentsStatementContent({
        statementId: sourceStatementParams.statementId,
        sectionIdList: [sectionId],
        segmentType: 'P',
        scrollToSection,
      }),
    );
    dispatch(
      fetchLeftElementsBySection({
        sectionId,
        statementId: sourceStatementParams.statementId,
      }),
    );
    dispatch(
      fetchLeftNotesBySection({
        sectionId,
        statementId: sourceStatementParams.statementId,
        projectId: sourceStatementParams.projectId,
      }),
    );
  };

export const fetchAllSectionRenderingDataWithoutLoading =
  ({ sectionId }) =>
  (dispatch, getStore) => {
    const { blacklineViewMode } = getStore().ui.statementPage.modes;

    dispatch(
      fetchSectionHTMLSegmentsStatementContentWithoutLoading({
        sectionIdList: [sectionId],
        segmentType: blacklineViewMode ? 'C' : 'P',
      }),
    );
    dispatch(fetchElementsBySection({ sectionId }));
    dispatch(fetchNotesBySection({ sectionId }));
  };

export const setCurrentStatementContentTab =
  (tab) => async (dispatch, getState) => {
    //First set the new tab that is passed in.
    await dispatch(setStatementContentTab(tab));
    /**
     * TODO: If we want to support multiple routes for the different tabs, we will have to investigate the logic for it
     * here with some type of react router/redux combination approach. TBD.
     */
    // /**
    //  * Next we set the url parameters for the new page based on the tab and
    //  * parameters in the store, namely the currently rendered revision and the currently
    //  * selected project.
    //  */
    // const currentState = getState().data;
    // const selectedProject = currentState.selectedProject.project;
    // const revision = currentState.revision;
    // if (!selectedProject.isInitialized() || !revision.isInitialized()) {
    //   const errorMessage =
    //     'Cannot select a tab, user does not have a selected project, or a revision loaded.';
    //   console.error(errorMessage);
    //   throw new Error(errorMessage);
    // }
    // let route;
    // if (tab === STATEMENT_CONTENT_TABS.STATEMENT) {
    //   route = ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE;
    // } else if (tab === STATEMENT_CONTENT_TABS.SPLIT) {
    //   route = ROUTE_CONSTANTS.STATEMENT_SPLIT_PAGE;
    // }
    // // TODO: Once we have better readOnly support, refactor its logic to be store-based somehow.
    // await dispatch(
    //   push(
    //     parseRoute(route, {
    //       params: {
    //         projectId: selectedProject.id,
    //         statementId: revision.statementId,
    //         revisionId: revision.id,
    //         readOnly: false,
    //       },
    //     }),
    //   ),
    // );
  };

/**
 * @param {} urlParams
 */
export const initStatementContent =
  (urlParams) => async (dispatch, getState) => {
    const projectId = urlParams.projectId;
    // const statementId = parseInt(urlParams.statementId);
    const revisionId = parseInt(urlParams.revisionId);
    dispatch(
      createSectionCacheTracker({
        revisionId,
      }),
    );
    // Once it has been fetched, we need to use our urlParams to ensure the proper project is the selected one in the store.
    const { data: currentState } = getState();

    const currentUserId =
      currentState && currentState.currentUser && currentState.currentUser.id;
    const selectedProject = currentState.selectedProject.project;

    // const { socketHasBeenDisconnected } = sockets.statementSocket;

    /**
     * If the selected project in the store is not the same as the one in the url params, update it.
     * This can happen if the user refreshes the page, or if they navigate to this page directly from a
     * bookmarked url for instance.
     *  */

    if (selectedProject.id !== projectId) {
      // Verify if projectid in url is present in projectToGeoMap
      const { projectToGeoMap } =
        currentState.currentUser && currentState.currentUser.data;
      const desiredProject = projectToGeoMap.get(`${projectId}`);
      /**
       * If the user puts in a project id in the url that is not valid, meaning it is not found in the projectToGeoMap,
       * we display an error.
       * TODO: Refactor this when we fix up overall error handling in the application.
       */
      if (isNullOrUndefined(desiredProject)) {
        const errorMessage = `Project with id: ${projectId} does not exist`;
        dispatch(selectedStatementError(errorMessage));
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
      // We always fire off an update to the selected project to ensure the proper project is marked as selected in the store.
      await dispatch(
        updateSelectedProjectFromID({
          projectId,
        }),
      );
    }
    dispatch(fetchSelectedStatement(urlParams));

    // We need to update the revision in the store to ensure we have the proper information to display in the UI.
    // all content requests for the statement require that the revision number be known
    await dispatch(fetchRevision(revisionId));
    // Next, get the section id map and the associated content so we can display the statement properly.
    // MUST await as this determines the order sections are rendered in
    await dispatch(fetchSectionIdListStatementContent(revisionId));
    // Finally fetch the period list for the current statement to populate the element details dropdown.
    dispatch(fetchRevisionPeriodList(revisionId));
    dispatch(fetchClientEntityList(projectId));
    // fetch contentSectionMap for relationships between content and bookmark sections
    dispatch(getContentSectionMapRequest({ revisionId }));
    //TODO: Uncomment out this dispatch for socket subscription once that is setup on the back-end.
    // subscribe({ projectId, statementId, revisionId });

    // It will fetch section tree list used for rendering statement-nav panel
    dispatch(fetchSectionTreeAction({ revisionId }));

    // init statement nav panel as open by default when loading statement page
    dispatch(showStatementNavPanelAction());
    // Set statment nav tab to default
    dispatch(setSelectedTabAction(STATEMENT_NAV_TABS.headings));
    dispatch(fetchSectionReviews(revisionId));

    await dispatch(
      initStatementSocketClientAction({ revisionId, projectId, currentUserId }),
    );

    dispatch(setStatementSocketClient());
    dispatch(fetchSectionAssignmentsList({ revisionId }));
    await dispatch(getAllUsers());

    //Before loading statement content for the first time, load the details of active users from BE.
    await dispatch(addUserToRevision(revisionId, projectId));
    // Don't set interval if there is already one in place;
    if (!isIntervalAlreadySet) {
      // We need to refresh the users in the room every 1hour
      isIntervalAlreadySet = setInterval(
        () => dispatch(addUserToRevision(revisionId, projectId)),
        3600000,
      );
    }
    // TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
    // if (socketHasBeenDisconnected) {
    //   dispatch(addUserToRevision(revisionId, projectId));
    // }
  };

export const initLeftStatementContent =
  (sourceStatementParams) => async (dispatch, getState) => {
    // const statementId = parseInt(sourceStatementUrlParams.statementId);
    const revisionId = parseInt(sourceStatementParams.revisionId);
    const statementId = parseInt(sourceStatementParams.statementId);
    const projectId = sourceStatementParams.projectId;
    //creating the section cache tracker for left statement in side by side view page
    dispatch(
      createLeftSectionCacheTracker({
        revisionId,
      }),
    );
    // We need to update the revision in the store to ensure we have the proper information to display in the UI.
    // all content requests for the statement require that the revision number be known
    await dispatch(fetchLeftRevision(revisionId, statementId, projectId));
    // Next, get the section id map and the associated content so we can display the statement properly.
    // MUST await as this determines the order sections are rendered in
    await dispatch(
      fetchLeftSectionIdListStatementContent(
        revisionId,
        statementId,
        projectId,
      ),
    );
    // fetch contentSectionMap for relationships between content and bookmark sections
    dispatch(
      getLeftContentSectionMapRequest({ statementId, revisionId, projectId }),
    );
    // fetch source Statements section reviews on particular revisions
    dispatch(fetchLeftSectionReviews(statementId));

    // It will fetch section tree list used for rendering statement-nav panel
    dispatch(
      fetchLeftSectionTreeAction({
        statementId,
        revisionId,
        projectId: sourceStatementParams.projectId,
      }),
    );
  };

/**
 * Adds section to SectionInViewMap and updates it's viewed timestamp in the SectionCacheTracker
 * @param {int} param.sectionId
 */
export const setSectionInView =
  ({ sectionId }) =>
  (dispatch) => {
    dispatch(setSectionInViewStatementContent({ sectionId }));
    dispatch(updateSectionViewedTimestamp({ sectionId }));
  };

/**
 * Removes a section from the SectionInViewMap
 *
 * Sometimes the cache will still be too large if the transition between 2 large sections is within the viewport threshold
 * thus every time we clear a seciton in view, we should also check the cache and see if we can purge a section that is no longer
 * in view so we can get below the threshold
 * @param {int} param.sectionId
 */
export const clearSectionInView =
  ({ sectionId }) =>
  async (dispatch, getStore) => {
    dispatch(clearSectionInViewStatementContent({ sectionId }));
    const { tracker } = getStore().data.statementContent.sectionsCache;
    const _cacheOverThreshold = tracker.cacheOverThreshold();
    if (_cacheOverThreshold) {
      dispatch(checkCacheToPurge());
    }
  };

export const clearStatementPageStore = () => (dispatch) => {
  dispatch(clearStatementPageStoreAction());

  // We dont want to clear revision id when navigating to side by side page,
  // since, we will need it for fetching data for target statement
  !(
    window.location &&
    window.location.pathname &&
    window.location.pathname.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
  ) && dispatch(clearStatementPageRevisionStoreAction());
  //TODO: Restore this code once we have back-end support for sockets.
  //unsubscribe();
};

export const batchSelectElements =
  ({ elements }) =>
  (dispatch, getState) => {
    const { selectMode } = getState().ui.statementPage.modes;
    // then dispatch the mode specific action
    switch (selectMode) {
      case ELEMENT_SELECT_MODES.FORMULA: {
        // always select all the elements in the content panel
        dispatch(
          selectMultipleElementsContentPanel({
            elementIds: elements.map((el) => el.elementId),
          }),
        );
        dispatch(
          batchAddElementFormulaRows({
            elements,
          }),
        );
        break;
      }
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER:
      case ELEMENT_SELECT_MODES.BATCH: {
        dispatch(batchAddElementsToBatch({ elements }));
        break;
      }
      case ELEMENT_SELECT_MODES.COPY_FORMULA: {
        elements.forEach((ele) =>
          dispatch(addElementForCopyFormula({ elementId: ele.elementId })),
        );
        break;
      }
      default:
        // should never happen
        console.log('BATCH SELECTED', elements);
    }
  };

export const batchSelectElementsInSideBySideMode =
  ({ elements, isLeftSideView }) =>
  async (dispatch, getState) => {
    // Filter out elements with valid elementId
    const selectedElements = elements.filter((ele) => !isNaN(ele.elementId));
    if (isLeftSideView) {
      // Dispatch action to add elements to source list in side by side view mode
      const selectedElementsfromCache = await dispatch(
        addElementsToSourceListInSideBySideMode({ elements: selectedElements }),
      );
      // Get the array of elements data from the elements map object
      const sourceSideBySideElements =
        getElementsDataArryaFromElementsMapObject(selectedElementsfromCache);

      // Dispatch action to set the source statement batch elements map
      dispatch(
        setSourceStatementBatchElementsMapAction(sourceSideBySideElements),
      );
    } else {
      // Dispatch action to add elements to target list in side by side view mode
      const selectedTargetElementsfromcache = await dispatch(
        addElementsToTargetListInSideBySideMode({ elements: selectedElements }),
      );

      const targetSideBySideElements =
        getElementsDataArryaFromElementsMapObject(
          selectedTargetElementsfromcache,
        );

      dispatch(
        setTargetStatementBatchElementsMapAction(targetSideBySideElements),
      );
    }
  };

const getElementsDataArryaFromElementsMapObject = (selectedElementsMap) =>
  Object.values(selectedElementsMap).map((item) => item.data);

export const onStatementNavSectionSelect =
  ({ sectionId, color }) =>
  async (dispatch, getState) => {
    dispatch(
      replaceSelectedSectionsContentPanel({ sectionIds: [sectionId], color }),
    );
    scrollSectionIntoView(sectionId);
  };

export const onSectionClick =
  ({ sectionId, color, sectionClick }) =>
  async (dispatch, getState) => {
    const { elementFilters } =
      getState().ui.statementPage.statementNavigatorPanel;
    const { contentSectionMap } =
      getState().data.statementContent.sectionsCache;
    const { selectMode } = getState().ui.statementPage.modes;
    const { right } = getState().ui.statementPage.panels;
    const {
      data: { revision },
    } = getState();
    const { INTERNAL_REFERENCE, FORMULA, BATCH, COPY_FORMULA } =
      ELEMENT_SELECT_MODES;
    const shouldDisableSectionClick =
      selectMode === INTERNAL_REFERENCE ||
      selectMode === FORMULA ||
      selectMode === COPY_FORMULA ||
      right === BATCH.title;
    let section = contentSectionMap.get(sectionId);
    dispatch(onStatementNavSectionSelect({ sectionId, color }));
    dispatch(showStatementNavPanelAction());
    dispatch(showSectionDetailsPanelAction());
    await dispatch(fetchSectionReviewsWithLoading());

    if (!shouldDisableSectionClick) {
      dispatch(sectionDetailsLoading());

      let updatedSection = await getUpdatedSectionElementsNotesSummaryCount(
        revision,
        section,
      );

      dispatch(sectionDetailsLoaded(updatedSection));
      dispatch(
        fetchSectionHistoryDetails({
          revisionId: revision.id,
          sectionId: sectionId,
        }),
      );
    }
    // this case is added because if the filter has just only one section selected and the user clicks on the section again
    // it is going to remove completely the section filter, so we need to remove the selected element
    if (
      elementFilters &&
      elementFilters.isSectionSelected(sectionId) &&
      elementFilters.selectedSectionsLength() === 1
    ) {
      dispatch(deselectSelectedElement());
    }
    dispatch(
      searchElements({
        filters: elementFilters.selectSection(section),
        searchTerm: '',
        scrollIntoView: false,
        sectionClick,
      }),
    );
  };

export const updateSectionDetailsStore =
  (props) => async (dispatch, getState) => {
    const { section, sectionId, isDelete } = props;
    const { selectedSection } = getState().data.sectionPanel;
    const {
      data: { revision },
    } = getState();
    // Added falsy value check - before evaluating selectedSection.id
    if (isDelete && selectedSection && sectionId === selectedSection.id) {
      dispatch(hideSectionDetailsPanelAction());
      dispatch(clearSectionDetails());
    }
    // Added check for falsy value - before evaluating section.id
    else if (section && selectedSection && selectedSection.id === section.id) {
      let updatedSection = await getUpdatedSectionElementsNotesSummaryCount(
        revision,
        section,
      );
      dispatch(sectionDetailsLoaded(updatedSection));
    }
  };

export const selectElementFromElementSearch =
  ({ elementId, firstSearch }) =>
  (dispatch, getState) => {
    const { elementDetails } = getState().data.elementPanel;
    const { selectMode } = getState().ui.statementPage.modes;
    if (
      selectMode !== ELEMENT_SELECT_MODES.RANGE &&
      (selectMode === ELEMENT_SELECT_MODES.BATCH_WITH_BANNER ||
        selectMode !== ELEMENT_SELECT_MODES.BATCH)
    ) {
      // skip when in range or batch select mode
      dispatch(
        replaceSelectedElementsContentPanel({
          elementIds: [elementId],
          color: firstSearch
            ? ELEMENT_HIGHLIGHT_STATES.DEFAULT
            : ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
        }),
      );
      if (elementDetails.id) {
        dispatch(
          selectElementContentPanel({
            elementIds: elementDetails.id,
            color: ELEMENT_HIGHLIGHT_STATES.DEFAULT,
          }),
        );
      }
    }
  };

export const searchForTermInContent =
  ({ searchTerm }) =>
  async (dispatch, getState) => {
    const {
      ui: {
        statementPage: { sectionsInView },
      },
    } = getState();
    // ensure search term gets updated in redux
    await dispatch(setContentSearchAction(searchTerm));
    sectionsInView.sectionIds.forEach((id) => {
      // refetch all section data in order to iterate through and highlight content
      // search term should be updated already at this point
      dispatch(fetchAllSectionRenderingData({ sectionId: id }));
    });
  };

export const clearSearchFromContent = () => async (dispatch, getState) => {
  const {
    ui: {
      statementPage: { sectionsInView },
    },
  } = getState();
  dispatch(clearContentHighlightSelector());
  // clear selected element from searched element tab
  dispatch(deselectSelectedElement());
  // ensure search term gets updated in redux
  await dispatch(clearContentSearchAction());
  sectionsInView.sectionIds.forEach((id) => {
    // refetch all section data in order to iterate through and highlight content
    // search term should be updated already at this point
    dispatch(fetchAllSectionRenderingData({ sectionId: id }));
  });
};

export const refetchSectionsInView = () => async (dispatch, getState) => {
  const {
    ui: {
      statementPage: { sectionsInView },
    },
  } = getState();

  sectionsInView.sectionIds.forEach((id) => {
    // refetch all section data in order to iterate through and highlight content
    // search term should be updated already at this point
    dispatch(fetchAllSectionRenderingData({ sectionId: id }));
  });
};

export const deallocateItems = () => (dispatch, getState) => {
  const {
    data: {
      notesPanel: { selectedNote },
    },
    ui: {
      statementPage: {
        panels: { right },
      },
    },
  } = getState();
  dispatch(deallocateSelectedSectionsContentPanel());
  dispatch(deallocateSelectedElementsContentPanel());
  dispatch(clearContentHighlightSelector());

  if (right === RIGHT_PANELS.NOTES) {
    dispatch(
      selectNoteFromNoteListAction({
        note: selectedNote,
        color: 'default',
      }),
    );
  }
};

export const refetchBlacklineView = () => async (dispatch, getState) => {
  try {
    const { revision } = getState().data;
    const response = await getBlacklineViewStatusRequest({
      revisionId: revision.id,
    });

    if (response && response.data) {
      switch (response.data.result) {
        case BLACKLINE_VIEW_STATUS.BLK_REQ_RECEIVED:
          blacklineTogleNotification(BLACKLINE_VIEW_STATUS.BLK_REQ_RECEIVED);
          break;
        case BLACKLINE_VIEW_STATUS.GENERATING:
          blacklineTogleNotification(BLACKLINE_VIEW_STATUS.GENERATING);
          break;
        case BLACKLINE_VIEW_STATUS.FAILED:
          blacklineTogleNotification(BLACKLINE_VIEW_STATUS.FAILED);
          break;
        default:
          break;
      }
    }
  } catch (error) {
    blacklineTogleNotification(BLACKLINE_VIEW_STATUS.FAILED);
    console.error(error);
  }
};

export const mapSideBySideElementsRequest =
  (statementId, revisionId) => async (dispatch, getState) => {
    const { sourceStatementParams, sideBySideView } = getState().ui;
    const { currentUser } = getState().data;
    const sectionIdList =
      getState() &&
      getState().data &&
      getState().data.statementContent &&
      getState().data.statementContent.sectionIdList;
    const targetId = revisionId;
    const targetStatementId = statementId;
    const sourceId = sourceStatementParams && sourceStatementParams.revisionId;
    const sourceStatementId =
      sourceStatementParams && sourceStatementParams.statementId;
    let elementMapDetails = { ...sideBySideView.sideBySideViewAnnotations };
    elementMapDetails.userId = currentUser && currentUser.id;
    elementMapDetails.elementsforMapping =
      sideBySideView &&
      sideBySideView.sideBySideElementMap &&
      sideBySideView.sideBySideElementMap.toApiFormat();

    const sourceElements =
      sideBySideView &&
      sideBySideView.sideBySideElementMap &&
      sideBySideView.sideBySideElementMap.sideBySideSourceElementsId();
    const targetElements =
      sideBySideView &&
      sideBySideView.sideBySideElementMap &&
      sideBySideView.sideBySideElementMap.sideBySideTargetElementsId();

    try {
      // api request to mapping element based on the user selection in side by side view page.
      const response = await mapElementsRequest({
        targetId,
        sourceId,
        targetStatementId,
        sourceStatementId,
        elementMapDetails,
      });

      if (
        response &&
        response.data &&
        response.data.elementsforMapping.length !== 0
      ) {
        const {
          successSourceElements,
          failedSourceElements,
          successTargetElements,
          failedTargetElements,
        } = seggregateSourceTargetElements(response.data.elementsforMapping);
        dispatch(
          fetchSectionHTMLSegmentsStatementContentWithoutLoading({
            sectionIdList: sectionIdList.sectionIds,
            segmentType: 'P',
          }),
        );
        dispatch(updateCacheWithMostRecentSideBySideElementChanges());
        dispatch(
          highlightMappedElementsByResponse({
            result: mapElementsSuccessResult,
            sourceElements: successSourceElements,
            targetElements: successTargetElements,
          }),
        );
        dispatch(
          highlightMappedElementsByResponse({
            result: mapElementsFailureResult,
            sourceElements: failedSourceElements,
            targetElements: failedTargetElements,
          }),
        );
        dispatch(clearSideBySideElementsMap());

        // successfully mapped elements notification
        successTargetElements.length > 0 &&
          successElementMapNotification({
            targetElements: successTargetElements,
          });
        // failed mapped elements notification
        failedTargetElements.length > 0 &&
          failureElementMapNotification({
            targetElements: failedTargetElements,
          });
      } else {
        dispatch(
          handleElementMappingFailure(
            mapElementsFailureResult,
            sourceElements,
            targetElements,
          ),
        );
      }
    } catch (error) {
      dispatch(
        handleElementMappingFailure(
          mapElementsFailureResult,
          sourceElements,
          targetElements,
        ),
      );
    }
    //on mouse click it will reset the successfully or failed mapped elements hightlight to the default
    document.addEventListener(
      'click',
      (e) => {
        if (!e.ctrlKey) {
          dispatch(clearSelectedElementsContentPanel());
          dispatch(clearLeftSelectedElementsContentPanel());
        }
      },
      {
        once: true,
      },
    );
  };

export const getStatementIdAction = () => (dispatch, getState) => {
  const { selectedStatement } = getState().data;
  let statementId =
    selectedStatement &&
    selectedStatement.statement &&
    parseInt(selectedStatement.statement.id);
  // if we are unsuccessful in getting selected statement id from redux then try getting it from url params.
  const locationPath = window.location.pathname.split('/');
  if (!statementId && locationPath.includes('statement')) {
    statementId = locationPath[locationPath.indexOf('statement') + 1];
  }
  return statementId;
};
