import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import SessionTimeoutModal from 'components/session-timeout/_session-timeout-modal';
import moment from 'moment';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { userLogOut } from 'api/api-authentication';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import { stopSocketConnection } from '../../store/actions/statement-socket-actions';
import { stopProjectSocketConnection } from '../../store/actions/project-socket-actions';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';

//this 3 const variables is used to calculate time of 30 minutes interval to trigger Session timeout API call
const INTERVAL = 1000;
const WARNING_TIME_MS = INTERVAL * 60 * 2;
const TIMEOUT_DURATION_BEFORE_WARNING_MS = INTERVAL * 60 * 28;

const SessionTimeout = ({
  push,
  location,
  revisionId,
  projectUsersList,
  stopSocketConnection,
  stopProjectSocketConnection,
  isStatementSocketDisconnected,
  isProjectSocketDisconnected,
  selectedProjectId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [warningCountdown, setWarningCountdown] = useState('');
  const [warningIsShown, setWarningIsShown] = useState(false);

  const warningCheckInterval = useRef(null);
  let countdowndownStartTime;

  const handleOnIdle = () => {
    countdowndownStartTime = Date.now();
    if (
      warningIsShown ||
      location.pathname === ROUTE_CONSTANTS.SESSION_TIMEOUT
    ) {
      return;
    }
    setShowModal(true);
    updateCountdown();
    warningCheckInterval.current = setInterval(updateCountdown, INTERVAL);
  };

  const updateCountdown = async () => {
    const warningTimeLeft =
      countdowndownStartTime + WARNING_TIME_MS - Date.now();

    if (warningTimeLeft <= 0) {
      clearInterval(warningCheckInterval.current);
      setShowModal(false);
      setWarningIsShown(false);
      await userLogOut(
        revisionId,
        projectUsersList.data.userIdToUserNameMap,
        selectedProjectId,
      ).catch((e) => console.error(e));

      // disconnect statement socket, if connected
      if (
        !isSignalRForSocketConnectionEnabled &&
        !isStatementSocketDisconnected
      ) {
        stopSocketConnection();
      }

      //disconnect project socket, if connected
      if (
        !isSignalRForSocketConnectionEnabled &&
        !isProjectSocketDisconnected
      ) {
        stopProjectSocketConnection();
      }

      push(ROUTE_CONSTANTS.SESSION_TIMEOUT);
    } else {
      const countdownDisplay = moment()
        .startOf('day')
        .add(warningTimeLeft, 'ms')
        .format('m:ss');
      setWarningCountdown(countdownDisplay);
      setWarningIsShown(true);
    }
  };

  const onStayActive = () => {
    setShowModal(false);
    setWarningIsShown(false);
    clearInterval(warningCheckInterval.current);
  };

  //idleTimer hook
  useIdleTimer({
    timeout: TIMEOUT_DURATION_BEFORE_WARNING_MS,
    onIdle: handleOnIdle,
    debounce: INTERVAL,
  });

  return (
    showModal && (
      <SessionTimeoutModal
        onClickCancel={onStayActive}
        timer={warningCountdown}
      />
    )
  );
};

SessionTimeout.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** Currently selected revision */
  revisionId: PropTypes.number,
  /** The model of all the users on the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
  // boolean to indicate whether statement socket is disconnected
  isStatementSocketDisconnected: PropTypes.bool,
  // boolean to indicate whether project socket is disconnected
  isProjectSocketDisconnected: PropTypes.bool,
  // action method to exit room for statement socket
  stopSocketConnection: PropTypes.func.isRequired,
  // action method to exit room for project socket
  stopProjectSocketConnection: PropTypes.func.isRequired,
  // project id for the currently selected project
  selectedProjectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = ({
  router,
  data: { projectUsersList, revision, selectedProject },
  sockets: {
    statementSocket: {
      socketHasBeenDisconnected: isStatementSocketDisconnected,
    },
    projectSocket: { socketHasBeenDisconnected: isProjectSocketDisconnected },
  },
}) => ({
  location: router.location,
  projectUsersList,
  revisionId: revision.id,
  isStatementSocketDisconnected,
  isProjectSocketDisconnected,
  selectedProjectId: selectedProject && selectedProject.id,
});

const mapDispatchToProps = {
  push,
  stopSocketConnection,
  stopProjectSocketConnection,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeout);
