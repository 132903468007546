import {
  getElementsCountBySectionID,
  getNotesCountBySectionID,
} from '../api/sections-api';

/**This method accepts the section details, fetches the section wise summary data from the backend
 * (consisting of the count of total elements, verified,flagged,reviewed elements,,notes) & returns
 * the updated section
 */
export const getUpdatedSectionElementsNotesSummaryCount = async (
  revision,
  section,
) => {
  try {
    let sectionElementCountSummary = await getElementsCountBySectionID({
      revisionId: revision.id,
      sectionId: section.id,
    });

    let sectionNotesCountSummary = await getNotesCountBySectionID({
      revisionId: revision.id,
      sectionId: section.id,
    });

    if (
      sectionElementCountSummary &&
      sectionElementCountSummary.data &&
      sectionElementCountSummary.data.result
    ) {
      let apiSummaryElementCountResponse =
        sectionElementCountSummary.data.result;
      section = {
        ...section,
        totalElements: apiSummaryElementCountResponse.totalElements
          ? apiSummaryElementCountResponse.totalElements
          : 0,
        verifiedElements: apiSummaryElementCountResponse.verified
          ? apiSummaryElementCountResponse.verified
          : 0,
        reviewedElements: apiSummaryElementCountResponse.reviewed
          ? apiSummaryElementCountResponse.reviewed
          : 0,
        flaggedElements: apiSummaryElementCountResponse.elementFlagCount
          ? apiSummaryElementCountResponse.elementFlagCount
          : 0,
      };
    }
    if (sectionNotesCountSummary && sectionNotesCountSummary.data) {
      let apiSummaryNoteCountResponse = sectionNotesCountSummary.data.result;
      section = {
        ...section,
        totalNotes: apiSummaryNoteCountResponse
          ? apiSummaryNoteCountResponse
          : 0,
      };
    }
  } catch (error) {
    section = {
      ...section,
      error: error,
    };
  }
  return section;
};
