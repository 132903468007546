import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import WorkPaper from 'models/data/workpaper-model';
import clonedeep from 'lodash.clonedeep';

export default class WorkpaperToolkitListPanel extends ApiModel({
  data: {
    workpapers: [],
    elementsMap: {},
    deletedWorkpapers: [],
  },
}) {
  get workpapers() {
    if (this.hasWorkpapers()) {
      return this.data.workpapers;
    }
    return [];
  }
  get elementsMap() {
    if (this.hasElementsMap()) {
      return this.data.elementsMap;
    }
    return {};
  }

  processResponse({ response }) {
    return {
      data: {
        workpapers: response.data.result.map(
          (workpaper) => new WorkPaper(workpaper),
        ),
        elementsMap: this.data.elementsMap,
        deletedWorkpapers: this.data.deletedWorkpapers,
      },
    };
  }

  hasWorkpapers() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.workpapers) &&
      this.data.workpapers.length > 0
    );
  }
  hasElementsMap() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.elementsMap) &&
      Object.keys(this.data.elementsMap).length > 0
    );
  }

  setElementList({ response, wpRefId }) {
    let updatedElementsMap = clonedeep(this.data.elementsMap);

    updatedElementsMap = {
      ...updatedElementsMap,
      [wpRefId]: response.data,
    };

    return this.mergeData({ elementsMap: updatedElementsMap });
  }

  clearElementList() {
    return this.mergeData({ elementsMap: {} });
  }

  removeWorkpaperFromElementsMap(wpRefId) {
    let updatedElementsMap = clonedeep(this.data.elementsMap);
    if (wpRefId in updatedElementsMap) {
      delete updatedElementsMap[wpRefId];
    }
    return this.mergeData({ elementsMap: updatedElementsMap });
  }

  updateWorkpaperElementsMap(payload) {
    const updatedElement = payload;
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const wpRefIdArray = Object.keys(clonedElementsMap);

    for (let index = 0; index < wpRefIdArray.length; index++) {
      const wpRefId = wpRefIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[wpRefId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId === updatedElement.id) {
          clonedElementsMap[wpRefId][j] = updatedElement;
          break;
        }
      }
    }

    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  updateWorkpaperElementsMapFromArray(payload) {
    let updatedElementsMapObject = {};
    for (let i = 0; i < payload.length; i++) {
      updatedElementsMapObject = {
        ...updatedElementsMapObject,
        [payload[i].id]: payload[i],
      };
    }
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const workpaperIdArray = Object.keys(clonedElementsMap);
    for (let index = 0; index < workpaperIdArray.length; index++) {
      const workpaperId = workpaperIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[workpaperId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId in updatedElementsMapObject) {
          const element = updatedElementsMapObject[elementId];
          const elementToUpdate = clonedElementsMap[workpaperId][j];
          clonedElementsMap[workpaperId][j] = {
            ...elementToUpdate,
            ...element,
          };
        }
      }
    }
    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  unlinkElementFromElementsMap({ elementId, wpRefId }) {
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    if (wpRefId in clonedElementsMap) {
      const elementIndex = clonedElementsMap[wpRefId].find(
        (element) => element.id === elementId,
      );
      clonedElementsMap[wpRefId].splice(
        clonedElementsMap[wpRefId].indexOf(elementIndex),
        1,
      );
    }
    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  removeElementFromElementsMap(payload) {
    const elementIdToRemove = payload;
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const wpRefIdArray = Object.keys(clonedElementsMap);

    for (let index = 0; index < wpRefIdArray.length; index++) {
      const wpRefId = wpRefIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[wpRefId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId === elementIdToRemove) {
          clonedElementsMap[wpRefId].splice(j, 1);
        }
      }
    }

    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  removeElementsFromElementsMapWithSocketPayload(payload) {
    let elementsToRemoveSet = new Set();
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      elementsToRemoveSet.add(element.id);
    }

    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const wpRefIdArray = Object.keys(clonedElementsMap);

    // this part updates the elements map
    for (let index = 0; index < wpRefIdArray.length; index++) {
      const wpRefId = wpRefIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[wpRefId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementsToRemoveSet.has(elementId)) {
          clonedElementsMap[wpRefId].splice(j, 1);
        }
      }
    }

    return this.mergeData({
      elementsMap: clonedElementsMap,
    });
  }
  addWorkpaperToDeletedArray(workpaperRefId) {
    const deletedWorkpapersCopy = this.data.deletedWorkpapers;
    deletedWorkpapersCopy.push(workpaperRefId);
    return this.mergeData({ deletedWorkpapers: deletedWorkpapersCopy });
  }

  addWorkpaperToList(payload) {
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const workpapersMap = this.workpapers.reduce((elementMap, elementData) => {
      elementMap[elementData.workpaperRefId] = elementData;
      return elementMap;
    }, {});
    const elements = payload.elementEntities.reduce(
      (elementMap, elementData) => {
        elementMap[elementData.id] = elementData;
        return elementMap;
      },
      {},
    );
    let modifiedWP = {};
    payload.attachedWorkpapers.forEach((workPaper) => {
      const workpaperId = workPaper.workpaperRefId;
      if (!(workpaperId in workpapersMap)) {
        workpapersMap[workpaperId] = workPaper;
      }
      const element = elements[workPaper.elementId];
      if (workpaperId in clonedElementsMap) {
        if (element) {
          modifiedWP[workpaperId] = true;
          clonedElementsMap[workpaperId].push(element);
          workpapersMap[workpaperId].elementCount++;
        }
      } else {
        if (element) {
          clonedElementsMap[workpaperId] = [element];
          workpapersMap[workpaperId].elementCount++;
        }
      }
    });
    const modifiedWpKeys = Object.keys(modifiedWP);
    for (let i = 0; i < modifiedWpKeys.length; i++) {
      //sort the element list by id to put the new element in the correct position
      clonedElementsMap[modifiedWpKeys[i]] = clonedElementsMap[
        modifiedWpKeys[i]
      ].sort((a, b) => a.id - b.id);
    }
    // sort the workpapers list by name
    const workpapers = Object.values(workpapersMap).sort((a, b) =>
      a.referenceNumber.localeCompare(b.referenceNumber),
    );
    return this.mergeData({
      workpapers: workpapers,
      elementsMap: clonedElementsMap,
    });
  }

  unlinkWorkpaperFromElementWithWebsocket(payload) {
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    let clonedWorkpapers = clonedeep(this.data.workpapers);

    let workPaperToElementsMap = {};
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      if (element.workpaperRefId in workPaperToElementsMap) {
        workPaperToElementsMap[element.workpaperRefId] = {
          ...workPaperToElementsMap[element.workpaperRefId],
          [element.elementId]: true,
        };
      } else {
        workPaperToElementsMap[element.workpaperRefId] = {
          [element.elementId]: true,
        };
      }
    }

    for (let i = 0; i < clonedWorkpapers.length; i++) {
      let workpaper = clonedWorkpapers[i];
      const workpaperId = workpaper.workpaperRefId;
      if (workpaperId in workPaperToElementsMap) {
        const elementsObject = workPaperToElementsMap[workpaperId];

        // subtract elementCount based on how many affected elements
        // are mapped to a specific wpRefId
        const elementsToSubtract = Object.keys(elementsObject).length;
        workpaper.elementCount = workpaper.elementCount - elementsToSubtract;

        if (Object.keys(clonedElementsMap).length > 0) {
          let elements = clonedElementsMap[workpaperId];
          const filteredElements = elements.filter(
            (element) => !(element.id in workPaperToElementsMap[workpaperId]),
          );
          clonedElementsMap[workpaperId] = filteredElements;
        }
      }
    }

    return this.mergeData({
      workpapers: clonedWorkpapers,
      elementsMap: clonedElementsMap,
    });
  }

  deleteWorkpaperWithSocketPayload(payload) {
    let workpapersCopy = [...this.data.workpapers];

    workpapersCopy = workpapersCopy.filter(
      (workpaper) => workpaper.workpaperRefId !== payload.wpRefId,
    );

    let updatedElementsMap = clonedeep(this.data.elementsMap);
    if (payload.wpRefId in updatedElementsMap) {
      delete updatedElementsMap[payload.wpRefId];
    }

    return this.mergeData({
      workpapers: workpapersCopy,
      elementsMap: updatedElementsMap,
    });
  }

  updateWorkpaperWithSocketPayload(payload) {
    let workpapersCopy = [...this.data.workpapers];

    for (let i = 0; i < workpapersCopy.length; i++) {
      if (payload.wpRefId === workpapersCopy[i].workpaperRefId) {
        workpapersCopy[i].referenceNumber = payload.referenceNumber;
        workpapersCopy[i].referenceName = payload.referenceName;
        break;
      }
    }

    return this.mergeData({
      workpapers: workpapersCopy,
    });
  }
}
