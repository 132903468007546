import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import revisionApi from 'api/revision-api';
import { isNullOrUndefined } from 'utils/object-utils';
import { getStatementRequest } from 'api/statement-api';
import Radio from 'components/common/radio-component';
import { RadioOption } from 'models/utils/common/radio-options-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { setSideBySideViewParams } from 'utils/statement-content-page-utils';

const SELECT_REVISION_TAB_BLOCK = 'left-side-select-revision';
const SELECT_REVISION_TAB_BLOCK_ID = 'left-side-select-revision-id';

const SelectRevisionTab = ({
  isButtonDisabled,
  setSourceStatementParamsValues,
  updateSelectedProjectFromIDIfDoesNotExists,
}) => {
  const { projectId, statementId } = useParams();
  const [prevRevision, setPreviousRevision] = useState('');
  const [selectedStatement, setSelectedStatement] = useState('');
  const [statementName, setStatementName] = useState('');

  const urlParams = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  const isImmediateRevisionExist = useCallback(() => {
    return isLoaded && selectedStatement && statementName && prevRevision;
  }, [isLoaded, prevRevision, selectedStatement, statementName]);

  useEffect(() => {
    let isMounted = true;
    const getAllRevisionsAndStatementData = async () => {
      try {
        setDataLoading();
        await updateSelectedProjectFromIDIfDoesNotExists({
          projectId: urlParams.projectId,
        });
        const { data: revisionStatements } =
          await revisionApi.getActiveRevisionsForStatement(statementId);
        const { data: selectedStatement } = await getStatementRequest(
          statementId,
        );
        if (isMounted) {
          if (
            !isNullOrUndefined(revisionStatements) &&
            !isNullOrUndefined(selectedStatement) &&
            Array.isArray(revisionStatements) &&
            revisionStatements.length > 1
          ) {
            const statementName = selectedStatement.statementName;
            const { revisionNumber } = revisionStatements[1];
            const statementNameWithVersion = `${statementName} V${revisionNumber}`;
            setStatementName(statementNameWithVersion);
            setPreviousRevision(revisionNumber);
            setSelectedStatement(revisionNumber);

            setSourceStatementParamsValues(
              setSideBySideViewParams(
                projectId,
                Number(statementId),
                Number(revisionStatements[1].id),
              ),
            );
          }
          setDataLoaded();
        }
      } catch (e) {
        setError(e);
      }
    };

    getAllRevisionsAndStatementData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevRevision, statementId]);

  useEffect(() => {
    prevRevision ? isButtonDisabled(false) : isButtonDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevRevision]);
  return (
    <ConditionalRender dependencies={[{ error, isLoading, isLoaded }]}>
      {isImmediateRevisionExist() ? (
        <Radio
          id={`${SELECT_REVISION_TAB_BLOCK_ID}-statements`}
          className={`${SELECT_REVISION_TAB_BLOCK}__radio_wrap`}
          radioWrapperClass={`${SELECT_REVISION_TAB_BLOCK}__radio`}
          options={[
            new RadioOption({
              id: prevRevision,
              label: statementName,
              value: prevRevision,
              isNotInternationalized: true,
            }),
          ]}
          width="auto"
          isValid
          selectedOption={selectedStatement}
          onChange={() => {
            return;
          }}
        />
      ) : (
        <div className={`${SELECT_REVISION_TAB_BLOCK}__no-data-msg`}>
          <FormattedMessage id="side-by-side-view.compare.left-panel.revision-not-found" />
        </div>
      )}
    </ConditionalRender>
  );
};

export default SelectRevisionTab;

SelectRevisionTab.propTypes = {
  /** func to set disable the confirm button */
  isButtonDisabled: PropTypes.func.isRequired,
  /** function to set the selected source statement details(projectId, statementId & revisionId) */
  setSourceStatementParamsValues: PropTypes.func.isRequired,
};
