import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { TICKMARK_TEXT_CHAR_LIMIT } from 'constants/feature/tickmark-constants';
import htmlToText from 'html-to-text';
export default class TickmarkForm extends ApiModel({
  data: {
    text: '',
    richText: '',
    tickmarkId: null,
    revisionId: null,
    elementId: null,
    noteOrdinal: null,
  },
  errorDuplicateTickmarkflag: null, //stores the tickmark id to determine if it is replicated
}) {
  get text() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.text;
    }
    return '';
  }

  get richText() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.richText;
    }
    return '';
  }

  get tickmarkId() {
    if (this.data) {
      return this.data.tickmarkId;
    }
    return null;
  }

  get revisionId() {
    if (this.data) {
      return this.data.revisionId;
    }
    return null;
  }
  get elementId() {
    if (this.data) {
      return this.data.elementId;
    }
    return null;
  }

  /**
   * Initializes a tickmark form from a normal tickmark model
   * @param {Tickmark} tickmark
   */
  fromTickmark(tickmark) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      error: null,
      data: { ...tickmark },
    });
  }

  toApiFormat() {
    return {
      ...this.data,
      richText: this.data.richText,
      text: this.data.text,
    };
  }

  toEditApiFormat() {
    return {
      richText: this.data.richText,
      text: this.data.text,
      tickmarkId: this.data.tickmarkId,
      revisionId: this.data.revisionId,
    };
  }

  initFromElement({ elementDetails }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      data: {
        ...this.data,
        revisionId: elementDetails.revisionId,
        elementId: elementDetails.id,
      },
    });
  }

  setText(richText) {
    const text = htmlToText.fromString(richText, {
      wordwrap: false, // by default it adds new lines after 80 chars, need to disable to ensure no new characters are added
    });
    // we need to remove ascii codes from strings
    // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-

    //We need to remove new line characters
    //https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
    // text = text.replace(/(\r\n|\n|\r)/gm, '');
    return this.merge({
      data: {
        ...this.data,
        // eslint-disable-next-line no-control-regex
        text: text.trim().replace(/[^\x00-\x7F]/g, '').replace(/(\r\n|\n|\r)/gm, ''),
        richText,
      },
    });
  }

  setDuplicateErrorLoaded({ duplicate }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      errorDuplicateTickmarkflag: duplicate,
    });
  }

  isTickmarkIdDuplicate() {
    return (
      !isNullOrUndefined(this.errorDuplicateTickmarkflag) &&
      this.errorDuplicateTickmarkflag === true
    );
  }

  isTextPresent() {
    return this.data && this.data.text && this.data.text.length > 0;
  }

  isTextTooLong() {
    return this.data.text.length > TICKMARK_TEXT_CHAR_LIMIT;
  }

  isTickmarkValid() {
    return (
      this.isTextPresent() &&
      !this.isTextTooLong() &&
      !this.isTickmarkIdDuplicate()
    );
  }

  updateTickmark(tickmark) {
    return this.merge({
      data: {
        ...this.data,
        text: tickmark.text,
        richText: tickmark.richText,
        tickmarkId: tickmark.tickmarkId,
        noteOrdinal: tickmark.noteOrdinal,
      },
    });
  }
}
