import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConditionalRender from 'components/util/conditional-render-component';
import { ProjectList } from 'models/api/project-list-model';

import ProjectListCard from './_project-list-card-component';
import ProjectListHeader from './_project-list-header-component';
import { PROJECTS_AMOUNT } from 'constants/feature/project-list-constants';
import Loading from 'components/common/loading-component';
import VirtualizedList from 'components/common/virtualized-list-component';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { GEO_NAMES } from 'constants/feature/project-list-header-constants';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
const PROJECT_LIST_BLOCK = 'project-list';
export const PROJECT_LIST_SCROLL_CONTAINER_ID =
  'project-list-scroll-container-id';
const PROJECT_CARD_SIZE = 198;
const SELECTED_PROJECT_CLASS_NAME = 'project-card--selected';

class ProjectListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: PROJECTS_AMOUNT,
      searchTerm: '',
      projects: [],
      calculatedScrollPosition: null,
    };
  }
  componentDidMount() {
    const { selectedProjectID, projectList, fetchProjectList } = this.props;
    fetchProjectList();

    //This code will execute only when user navigate to home page from statement page
    if (projectList.getProjects().length) {
      const projects = this.getFilteredProjectList();
      const calculatedScrollPosition = this.getCalculatedScrollPosition(
        projects,
        selectedProjectID,
      );
      const endIndexInVirtualList =
        this.getEndIndexOfProjectToBeRenderedInVirtualList(
          projects,
          selectedProjectID,
        );
      this.setState({
        projects,
        index: endIndexInVirtualList,
        calculatedScrollPosition,
      });
    }
  }

  componentWillUnmount() {
    //clearing the appliedSearchTerm from redux store to default value.
    this.props.setAppliedSearchTerm(EMPTY_STRING);
  }

  getFilteredProjectList = () => {
    const { projectList } = this.props;
    const { data: { selectedGeo, appliedSearchTerm } = {} } = projectList;
    const searchTerm = appliedSearchTerm.toLowerCase();
    let filteredProjectList = projectList.getProjects().filter((project) => {
      if (selectedGeo.value === GEO_NAMES.ALL) {
        return project;
      }
      return project.geoCode === selectedGeo.value;
    });

    filteredProjectList = filteredProjectList.filter(
      (project) =>
        project.name.toLowerCase().indexOf(searchTerm) !== -1 ||
        project.engagementEntity.engagementName
          .toLowerCase()
          .indexOf(searchTerm) !== -1,
    );
    return filteredProjectList;
  };

  //Calaculate the index value based on selected project.
  //we are calculating the index, so that we can decide how much data will be passed to the virtualize list.
  //Index will alway be the project length or in the multiple of 'PROJECTS_AMOUNT'.
  getEndIndexOfProjectToBeRenderedInVirtualList = (
    projects,
    selectedProjectID,
  ) => {
    const indexOfSelectedProject = projects.findIndex(
      (item) => item.id === selectedProjectID,
    );
    const projectSerialNumber =
      indexOfSelectedProject === -1 ? 1 : indexOfSelectedProject + 1;
    const indexValue =
      PROJECTS_AMOUNT * Math.ceil(projectSerialNumber / PROJECTS_AMOUNT);
    return indexValue > projects.length ? projects.length : indexValue;
  };

  //Calculate the scroll position using selected project in project list and height of project card.
  getCalculatedScrollPosition = (projects, selectedProjectID) => {
    const indexOfSelectedProject = projects.findIndex(
      (item) => item.id === selectedProjectID,
    );
    return indexOfSelectedProject === -1
      ? 0
      : PROJECT_CARD_SIZE * indexOfSelectedProject;
  };

  updateFilteredProjectAndEndIndexOfVirtualList() {
    const { selectedProjectID } = this.props;
    const projects = this.getFilteredProjectList();

    const endIndexInVirtualList =
      this.getEndIndexOfProjectToBeRenderedInVirtualList(
        projects,
        selectedProjectID,
      );
    this.setState({ projects, index: endIndexInVirtualList });
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectList, setFirstProjectAsSelectedFromProjectList } =
      this.props;
    const { projectList: prevProjectList } = prevProps;
    const {
      data: {
        selectedGeo: prevSelectedGeo,
        appliedSearchTerm: prevAppliedSearchTerm,
      } = {},
    } = prevProjectList;
    const { data: { selectedGeo, appliedSearchTerm } = {} } = projectList;
    const allProjects = projectList.getProjects();

    if (prevProps.projectList !== this.props.projectList) {
      const projects = this.getFilteredProjectList();
      this.setState({ projects });
    }

    if (
      prevSelectedGeo &&
      selectedGeo &&
      prevSelectedGeo.value !== selectedGeo.value
    ) {
      let filteredProjectList = allProjects.filter((project) => {
        if (selectedGeo.value === GEO_NAMES.ALL) {
          return project;
        }
        return project.geoCode === selectedGeo.value;
      });

      if (appliedSearchTerm) {
        const searchTerm = appliedSearchTerm.toLowerCase();
        filteredProjectList = filteredProjectList.filter(
          (project) =>
            project.name.toLowerCase().indexOf(searchTerm) !== -1 ||
            project.engagementEntity.engagementName
              .toLowerCase()
              .indexOf(searchTerm) !== -1,
        );
      }

      setFirstProjectAsSelectedFromProjectList({
        projectList: filteredProjectList || [],
      });
      this.setState({
        projects: filteredProjectList,
      });
    }

    if (prevAppliedSearchTerm !== appliedSearchTerm) {
      let filteredProjectList = allProjects.filter((project) => {
        if (selectedGeo.value === GEO_NAMES.ALL) {
          return project;
        }
        return project.geoCode === selectedGeo.value;
      });

      const searchTerm = appliedSearchTerm.toLowerCase();
      filteredProjectList = filteredProjectList.filter(
        (project) =>
          project.name.toLowerCase().indexOf(searchTerm) !== -1 ||
          project.engagementEntity.engagementName
            .toLowerCase()
            .indexOf(searchTerm) !== -1,
      );

      setFirstProjectAsSelectedFromProjectList({
        projectList: filteredProjectList || [],
      });
      this.setState({
        projects: filteredProjectList,
      });
    }
    //This code will execute only when there is change in project list length i.e when new project is created and when this component is initially loaded.
    if (
      prevProps.projectList.getProjects().length !==
      this.props.projectList.getProjects().length
    ) {
      const projects = this.getFilteredProjectList();
      const calculatedScrollPosition = this.getCalculatedScrollPosition(
        projects,
        this.props.selectedProjectID,
      );
      const endIndexInVirtualList =
        this.getEndIndexOfProjectToBeRenderedInVirtualList(
          projects,
          this.props.selectedProjectID,
        );
      this.setState({ calculatedScrollPosition, index: endIndexInVirtualList });
    }
  }

  listScroll = () => {
    const { index } = this.state;
    const { projectList } = this.props;
    const projectsAmount = projectList.getProjects().length;
    if (index !== projectsAmount) {
      if (index + PROJECTS_AMOUNT <= projectsAmount) {
        this.setState({
          index: index + PROJECTS_AMOUNT,
        });
      } else {
        this.setState({
          index: projectsAmount,
        });
      }
    }
  };

  setSearchTerm(val) {
    this.setState({
      searchTerm: val,
    });
  }

  setGeo(val) {
    this.props.setProjectFilterGeo(val);
    this.props.clearAppliedSearchTerm();
    this.setState({ searchTerm: '' });
  }

  _executeSearch() {
    this.props.setAppliedSearchTerm(this.state.searchTerm);
  }

  render() {
    const {
      projectList,
      setSelectedProjectFromProjectList,
      selectedProjectID,
      softDeleteProject,
      restoreDeletedProject,
      permanentlyDeleteProject,
      push,
      currentUser,
    } = this.props;
    const { index, searchTerm, projects, calculatedScrollPosition } =
      this.state;
    const { data: { appliedSearchTerm } = {} } = projectList;
    const disableScrollCallback = index === projectList.getProjects().length;
    const renderFooter = disableScrollCallback ? () => null : () => <Loading />;
    const useVirtualizedList = projects.length > PROJECTS_AMOUNT;
    const projectListLength = projects.length;
    return (
      <ConditionalRender dependencies={[projectList]}>
        <div className={`${PROJECT_LIST_BLOCK}`}>
          <ProjectListHeader
            projectList={projectList}
            push={push}
            searchTerm={searchTerm}
            setSearchTerm={(value) => this.setSearchTerm(value)}
            clearSearchTerm={() => {
              this.setState({ searchTerm: '' });
              this.props.clearAppliedSearchTerm();
            }}
            setGeo={(value) => this.setGeo(value)}
            _executeSearch={() => this._executeSearch()}
            currentUser={currentUser}
            projects={projects}
            appliedSearchTerm={appliedSearchTerm}
          />
          <div
            id={PROJECT_LIST_SCROLL_CONTAINER_ID}
            className={classnames(
              `${PROJECT_LIST_BLOCK}__list`,
              useVirtualizedList
                ? `${PROJECT_LIST_BLOCK}__list--virtualized`
                : `${PROJECT_LIST_BLOCK}__list--scroll`,
            )}
          >
            {appliedSearchTerm.length > 0 && projects.length === 0 && (
              <p className={`${PROJECT_LIST_BLOCK}__no-matching-results`}>
                <FormattedMessage
                  id={'project-list.no-matching-search-results.message'}
                />
              </p>
            )}
            {useVirtualizedList ? (
              <VirtualizedList
                id={`${PROJECT_LIST_BLOCK}-list`}
                itemHeight={PROJECT_CARD_SIZE} //approximate size of the project card
                itemList={projects.slice(0, index)}
                onScrollEnd={this.listScroll}
                rowsAmount={
                  projectListLength <= PROJECTS_AMOUNT
                    ? projectListLength
                    : PROJECTS_AMOUNT - 1
                }
                calculatedScrollPosition={calculatedScrollPosition}
                selectedItemClassName={SELECTED_PROJECT_CLASS_NAME}
                renderFooter={renderFooter}
                disableScrollCallback={disableScrollCallback}
                renderItem={(project, index) => (
                  <ProjectListCard
                    key={`project-list-key-${index}`}
                    project={project}
                    onClick={() =>
                      setSelectedProjectFromProjectList({
                        projectListEntry: project,
                      })
                    }
                    softDeleteAction={softDeleteProject}
                    updateFilteredProjectList={this.updateFilteredProjectAndEndIndexOfVirtualList.bind(
                      this,
                    )}
                    restoreAction={restoreDeletedProject}
                    permanentDeleteAction={permanentlyDeleteProject}
                    selectedProjectID={selectedProjectID}
                    push={push}
                    index={index}
                  />
                )}
              />
            ) : (
              projects.map((project, index) => {
                return (
                  <ProjectListCard
                    key={`project-list-key-${index}`}
                    project={project}
                    onClick={() =>
                      setSelectedProjectFromProjectList({
                        projectListEntry: project,
                      })
                    }
                    softDeleteAction={softDeleteProject}
                    updateFilteredProjectList={this.updateFilteredProjectAndEndIndexOfVirtualList.bind(
                      this,
                    )}
                    restoreAction={restoreDeletedProject}
                    permanentDeleteAction={permanentlyDeleteProject}
                    selectedProjectID={selectedProjectID}
                    push={push}
                    index={index}
                  />
                );
              })
            )}
          </div>
        </div>
      </ConditionalRender>
    );
  }
}

ProjectListComponent.propTypes = {
  /** function used to fetch the projects through an api call to be displayed.*/
  fetchProjectList: PropTypes.func.isRequired,
  /** project[] The list of retrieved projects to be displayed.*/
  projectList: PropTypes.instanceOf(ProjectList).isRequired,
  /** function that is used to set the selected project in the store.*/
  setSelectedProjectFromProjectList: PropTypes.func.isRequired,
  /** number representing the id of the currently selected project in the store*/
  selectedProjectID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** function that calls an action to soft delete a project */
  softDeleteProject: PropTypes.func.isRequired,
  /** function that calls an action to restore a soft deleted project */
  restoreDeletedProject: PropTypes.func.isRequired,
  /**function that calls an action to permanently delete a project */
  permanentlyDeleteProject: PropTypes.func.isRequired,
  /** function for changing route */
  push: PropTypes.func.isRequired,
  /** Current user data */
  currentUser: PropTypes.object.isRequired,
};

export default ProjectListComponent;
export { PROJECT_LIST_BLOCK };
