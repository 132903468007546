import React, { useState, useEffect } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import Dropdown from 'components/common/dropdown-component';
import { getUserMenuOptions } from 'constants/feature/section-details-constants';
import Chip from 'components/common/chip-component';
import { createRevisionSectionAssignment } from 'api/sections-api';
import SectionAssignments from 'models/api/section-assignments-api-model';

const ASSIGN_SECTION_MODAL_ID_BLOCK = 'assign-section-modal-id';
export const ASSIGN_SECTION_BLOCK = 'assign-section-modal';

export const AssignSectionModal = ({
  onClose,
  sectionAssignments,
  usersList,
  onSave,
  revisionId,
  sectionId,
}) => {
  const [assignedUsers, setAssignedUsers] = useState({
    ...sectionAssignments.assignedUsersMap,
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableDeleteAllButton, setDisableDeleteAllButton] = useState(false);
  useEffect(() => {
    const assignedUsersArray = Object.keys(assignedUsers).sort((a, b) => a - b);
    const sectionAssignmentsArray = Object.keys({
      ...sectionAssignments.assignedUsersMap,
    }).sort((a, b) => a - b);
    setDisableSaveButton(
      JSON.stringify(assignedUsersArray) ===
        JSON.stringify(sectionAssignmentsArray),
    );
  }, [assignedUsers, sectionAssignments.assignedUsersMap]);

  const handleUserSelect = (user) => {
    setAssignedUsers({
      ...assignedUsers,
      [user.id]: {
        name: user.title,
      },
    });
  };

  const handleChipRemove = (key) => {
    let assignedUsersMap = { ...assignedUsers };
    if (key in assignedUsers) {
      delete assignedUsersMap[key];
    }
    setAssignedUsers(assignedUsersMap);
  };

  const handleSave = async () => {
    setDisableSaveButton(true);
    const userIds = Object.keys(assignedUsers);
    await createRevisionSectionAssignment({ revisionId, sectionId, userIds });
    onSave();
  };

  const handleDeleteAll = async () => {
    setDisableDeleteAllButton(true);
    await createRevisionSectionAssignment({
      revisionId,
      sectionId,
      userIds: [],
    });
    onSave();
  };
  return (
    <Modal
      id={ASSIGN_SECTION_MODAL_ID_BLOCK}
      title={'section-details.assign-modal.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          onClick: handleSave,
          disabled: !Object.keys(assignedUsers).length > 0 || disableSaveButton,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'section-details.assign-modal.unassign-all',
          onClick: handleDeleteAll,
          disabled: disableDeleteAllButton,
        })
      }
    >
      <div className={`${ASSIGN_SECTION_BLOCK}__chip-container`}>
        {Object.keys(assignedUsers).map((key) => {
          return (
            <Chip
              key={`assigned-user-key-${key}`}
              id={`${ASSIGN_SECTION_MODAL_ID_BLOCK}-key`}
              onRemove={() => handleChipRemove(key)}
            >
              {assignedUsers[key].name}
            </Chip>
          );
        })}
      </div>
      <Dropdown
        id={`${ASSIGN_SECTION_MODAL_ID_BLOCK}-assign-modal`}
        placeholder={'section-details.assign-modal.placeholder'}
        width={'100%'}
        onSelectOption={(user) => handleUserSelect(user)}
        isValid={true}
        options={getUserMenuOptions(usersList, assignedUsers)}
        value={null}
      />
    </Modal>
  );
};

AssignSectionModal.propTypes = {
  /** Action fired when user clicks Cancel or the 'x' button*/
  onClose: PropTypes.func.isRequired,
  /** Action fired when the save or delete all button is clicked*/
  onSave: PropTypes.func.isRequired,
  /** the id of the current revision*/
  revisionId: PropTypes.number.isRequired,
  /** the id of the section*/
  sectionId: PropTypes.number.isRequired,
  /** an array of all the usres on the revision*/
  usersList: PropTypes.array.isRequired,
  /** A reference to the assignments of the selected section */
  sectionAssignments: PropTypes.instanceOf(SectionAssignments).isRequired,
};

export default AssignSectionModal;
