import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { isNullOrUndefined } from 'utils/object-utils';

const CHIP_BLOCK = 'chip';

const Chip = ({ children, id, onRemove, onClick, className }) => {
  return (
    <div
      tabIndex={!isNullOrUndefined(onClick) ? 0 : null}
      id={id}
      onClick={onClick}
      className={classnames(CHIP_BLOCK, className)}
    >
      <div className={`${CHIP_BLOCK}__content`}>{children}</div>
      {!isNullOrUndefined(onRemove) && (
        <IconButton
          id={`${id}-remove`}
          onClick={onRemove}
          className={`${CHIP_BLOCK}__remove`}
          Icon={CloseButton}
        />
      )}
    </div>
  );
};

Chip.propTypes = {
  /** custom string class */
  className: PropTypes.string,
  /** unigue string id */
  id: PropTypes.string.isRequired,
  /** Function for determing the behavior of button when clicked */
  onClick: PropTypes.func,
  /** Function fired when clicking the x button */
  onRemove: PropTypes.func,
};

export default Chip;
