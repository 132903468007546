import { handleActions } from 'redux-actions';
import {
  clearElementHistory,
  setElementHistoryLoading,
  setElementHistoryLoaded,
  setElementHistoryError,
} from 'store/actions/element-panel-actions';
import ElementHistory from 'models/api/element-history-api-model';

export default handleActions(
  {
    [setElementHistoryLoading]: (elementHistory, { payload }) => {
      return elementHistory.setLoading(payload);
    },
    [setElementHistoryLoaded]: (elementHistory, { payload }) => {
      return elementHistory.setLoaded(payload);
    },
    [setElementHistoryError]: (elementHistory, { payload }) => {
      return elementHistory.setError(payload);
    },
    [clearElementHistory]: (elementHistory, { payload }) => {
      return new ElementHistory();
    },
  },
  new ElementHistory(),
);
