import BaseModel from 'models/base-model';
import isEqual from 'lodash/isEqual';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  NOTE_STATUS_TYPES,
  NOTE_PRIORITY_TYPES,
} from 'constants/feature/notes-panel-constants';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { getEmailsForTaggedUsers } from 'utils/note-email-util';
import { getStore } from 'store/store';

import { toApiNoteFormat } from 'utils/note-filter-util';
export default class Note extends BaseModel({
  body: { note: null, replies: {}, taggedUsers: {} },
  closedBy: null,
  closedByName: null,
  closedDate: null,
  elementDisplay: null,
  elementId: null,
  insideSibling: null,
  length: null,
  noteDate: null,
  noteId: null,
  noteNumber: null,
  noteOrdinal: null,
  notePriorityType: null,
  noteTaker: null,
  noteTakerName: null,
  noteType: null,
  offset: null,
  pseudoElementId: null,
  replyFlag: null,
  revisionId: null,
  revisionNumber: null,
  sectionId: null,
  selectedText: null,
  selector: null,
  siblingIndex: null,
  workflowStatus: null,
  noteTime: null,
  noteTimestamp: null,
  formattedNoteNumber: null,
  ghost: 0,
  reply: false,
}) {
  constructor(props) {
    super(props);
    if (!isNullOrUndefined(props)) {
      const { body, noteDate } = props;
      if (typeof props.body === 'string') {
        this.body = JSON.parse(body);
      }
      this.noteDate = moment(noteDate).format();
      this.noteTimestamp = noteDate;
    }
  }

  setOpen() {
    return this.merge({
      workflowStatus: NOTE_STATUS_TYPES.OPEN,
    });
  }

  setClosed() {
    return this.merge({
      workflowStatus: NOTE_STATUS_TYPES.CLOSED,
    });
  }

  isClosed() {
    return this.workflowStatus === NOTE_STATUS_TYPES.CLOSED;
  }

  isGhostNote() {
    return this.ghost === 1;
  }

  setHighPriority() {
    return this.merge({
      notePriorityType: NOTE_PRIORITY_TYPES.HIGH,
    });
  }

  setNormalPriority() {
    return this.merge({
      notePriorityType: NOTE_PRIORITY_TYPES.NORMAL,
    });
  }

  setNoteEdit({ newNote, type, revision }) {
    return this.merge({
      noteType: type,
      revisionNumber: revision,
      body: { ...this.body, note: newNote },
    });
  }

  setNoteReply({ data }) {
    const regex = /cfto_user_id="\s*(.*?)\s*"/g;
    let match;
    let usersObject = {};
    const reply = toApiNoteFormat(data.reply);

    do {
      match = regex.exec(data.reply);
      if (match) {
        usersObject[match[1]] = true;
      }
    } while (match);

    data.taggedUsers = usersObject;
    data.reply = reply;
    const noteRepliesArray = [
      ...Object.values(this.body.replies),
      { ...data, reply: this.removeXMLTags(data.reply) },
    ];

    return this.merge({
      body: { ...this.body, replies: { ...noteRepliesArray } },
    });
  }

  setNoteReplyDelete(reply) {
    const noteRepliesArray = Object.values(this.body.replies).filter(
      (entry) => !isEqual(entry, reply),
    );
    return this.merge({
      body: { ...this.body, replies: { ...noteRepliesArray } },
    });
  }

  isHighPriority() {
    return this.notePriorityType === NOTE_PRIORITY_TYPES.HIGH;
  }

  toApiFormat() {
    return {
      revisionId: this.revisionId,
      sectionId: this.sectionId,
      noteType: this.noteType,
      notePriorityType: this.notePriorityType,
      revisionNumber: this.revisionNumber,
      selectedText: this.selectedText,
      offset: this.offset,
      length: this.length,
      hasElement: this.hasElement,
      workflowStatus: this.workflowStatus,
      noteId: this.noteId,
    };
  }

  toNoteReplyApiFormat() {
    return {
      noteId: this.noteId,
      body: JSON.stringify(this.body),
      noteType: this.noteType,
      notePriorityType: this.notePriorityType,
      revisionId: this.revisionId,
      sectionId: this.sectionId,
      workflowStatus: this.workflowStatus,
      reply: true,
    };
  }

  includeEmailListAfterNoteReply() {
    const { projectUsersList, revision } = getStore().getState().data;
    const emailList = getEmailsForTaggedUsers({
      noteBody: '',
      replies: this.body.replies,
      projectUsersList: projectUsersList.users,
      previousNoteBody: '',
    });

    const replyKeys = Object.keys(this.body.replies);
    const latestReplyIndex = replyKeys.length - 1;
    const lastReply = this.body.replies[latestReplyIndex];

    return {
      revisionId: revision.id,
      noteContent: lastReply && lastReply.reply ? lastReply.reply : '',
      sendEmailTo: emailList,
    };
  }

  includeEmailListAfterNoteEdit() {
    const {
      projectUsersList,
      notesPanel: { notesList },
      revision,
    } = getStore().getState().data;
    const emailList = getEmailsForTaggedUsers({
      noteBody: this.body.note,
      replies: {},
      projectUsersList: projectUsersList.users,
      previousNoteBody: notesList.data.previousNoteBody,
    });

    return {
      revisionId: revision.id,
      noteContent: this.body.note,
      sendEmailTo: emailList,
    };
  }

  toEditNoteApiFormat() {
    let noteBody = { ...this.body };
    let note = this.getNote();
    noteBody.note = toApiNoteFormat(note);
    const regex = /cfto_user_id="\s*(.*?)\s*"/g;
    let match;
    let usersObject = {};

    do {
      match = regex.exec(noteBody.note);
      if (match) {
        usersObject[match[1]] = true;
      }
    } while (match);

    noteBody.taggedUsers = usersObject;

    return {
      noteId: this.noteId,
      noteType: this.noteType,
      body: JSON.stringify(noteBody),
      notePriorityType: this.notePriorityType,
      revisionId: this.revisionId,
      sectionId: this.sectionId,
      revisionNumber: this.revisionNumber,
      workflowStatus: this.workflowStatus,
      edit: true,
    };
  }

  toDeleteApiFormat() {
    return {
      revisionId: this.revisionId,
      sectionId: this.sectionId,
      noteId: this.noteId,
      pseudoElementId: this.pseudoElementId,
    };
  }

  hasNoteBeenEdited(text, noteType) {
    return this.body.note === text && this.noteType === noteType;
  }

  getNoteReplies() {
    if (this.body && this.body.replies) {
      return Object.values(this.body.replies).map((entry) => ({
        ...entry,
        reply: this.removeXMLTags(entry.reply),
      }));
    }
    return [];
  }

  getSortedNoteRepliesByLastModified() {
    return this.getNoteReplies().sort((r1, r2) =>
      r1.lastModified > r2.lastModified ? -1 : 1,
    );
  }

  getNoteReplyCount() {
    return this.getNoteReplies().length;
  }

  getNote() {
    if (isNullOrUndefined(this.body) || isNullOrUndefined(this.body.note)) {
      return null;
    }
    return this.removeXMLTags(this.body.note);
  }

  removeXMLTags(note) {
    let cleanHTML = DOMPurify.sanitize(note, {
      ALLOWED_TAGS: ['span'],
      ALLOWED_ATTR: ['cfto_user_id'],
    });

    return cleanHTML;
  }
}
