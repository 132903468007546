import { handleActions } from 'redux-actions';
import {
  addElementToBatchSelectionAction,
  removeElementFromBatchSelectionAction,
  setBatchSelectedElementsLoading,
  appendBatchSelectedElementsLoaded,
  setBatchSelectedElementsError,
  clearBatchSelectedElementsAction,
  updateSelectedBatchElementsFromSocketAction,
  removeBatchElementsFromSocketPayloadAction,
} from 'store/actions/batch-panel-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import BatchUpdateElementList from 'models/data/batch-update-element-list-model';

export default handleActions(
  {
    [addElementToBatchSelectionAction]: (elements, { payload }) => {
      return elements.appendElement(payload);
    },
    [removeElementFromBatchSelectionAction]: (elements, { payload }) => {
      return elements.removeElement(payload);
    },
    [setBatchSelectedElementsLoading]: (elements, { payload }) => {
      return elements.setLoading();
    },
    [appendBatchSelectedElementsLoaded]: (elements, { payload }) => {
      return elements.appendMultipleElementsLoaded(payload);
    },
    [setBatchSelectedElementsError]: (elements, { payload }) => {
      return elements.setError(payload);
    },
    [clearBatchSelectedElementsAction]: () => {
      return new BatchUpdateElementList();
    },
    [updateSelectedBatchElementsFromSocketAction]: (elements, { payload }) => {
      return elements.updateSelectedElementsMapFromSocket(payload);
    },
    [removeBatchElementsFromSocketPayloadAction]: (elements, { payload }) => {
      return elements.removeBatchElementsFromSocketPayload(payload);
    },
    [clearStatementPageStoreAction]: () => {
      return new BatchUpdateElementList();
    },
  },
  new BatchUpdateElementList(),
);
