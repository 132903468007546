import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

export const EDIT_PROJECT_TOOLTIP = new TooltipOptions({
  text: 'common.edit',
  id: 'project-details-id-edit-project-button',
  position: 'left',
});

export const SUPPORT_TEAM_ACCESS_TOOLTIP = new TooltipOptions({
  text: 'project-details.support-team-access.tooltip',
  id: 'project-details-id-support-team-access',
  position: 'right',
});

export const COE_TEAM_ACCESS_TOOLTIP = new TooltipOptions({
  text: 'project-details.coe-team-access.tooltip',
  id: 'project-details-id-coe-team-access',
  position: 'right',
});
