import ProjectSocketModel from 'models/sockets/project-socket-model';
import { handleActions } from 'redux-actions';
import {
  initProjectSocketIOSocketClient,
  initProjectSignalrSocketClient,
  setProjectSocketClient,
  clearProjectSocketClientInStore,
  stopProjectSocketConnection,
  setProjectSocketDisconnectAction,
} from 'store/actions/project-socket-actions';
import { initialProjectSocketClientUrl } from 'api/project-socket-client-api';

export default handleActions(
  {
    [initProjectSocketIOSocketClient]: (projectSocket, { payload }) => {
      const { projectId, geoCode } = payload;
      return projectSocket.init({ projectId, geoCode });
    },
    [initProjectSignalrSocketClient]: (projectSocket, { payload }) => {
      const { projectId, geoCode } = payload;
      const url = initialProjectSocketClientUrl(projectId);
      return projectSocket.init({ url, geoCode });
    },
    [setProjectSocketClient]: (projectSocket, { payload }) => {
      return projectSocket.setProjectSocketClient(payload);
    },
    [setProjectSocketDisconnectAction]: (projectSocket, { payload }) => {
      return projectSocket.setProjectSocketDisconnect(payload);
    },
    [stopProjectSocketConnection]: (projectSocket) => {
      return projectSocket.stopProjectSocketConnection();
    },
    [clearProjectSocketClientInStore]: () => {
      return new ProjectSocketModel();
    },
  },
  new ProjectSocketModel(),
);
