import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ConditionalRender from 'components/util/conditional-render-component';
import classnames from 'classnames';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ProgressBarStep from 'components/common/progress-bar-step-component';
import Card from 'components/common/card-component';
import Radio from 'components/common/radio-component';
import { RadioOption } from 'models/utils/common/radio-options-model';
import Dropdown from 'components/common/dropdown-component';
import DataGrid from 'components/common/data-grid/data-grid-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import NoCarryForwardStatements from 'components/feature/statement-creation/statement-creation-no-carry-forward-statements-component';
import CarryForwardInfo from 'components/feature/statement-creation/statement-creation-carryforward-info-component';
import Checkbox from 'components/common/checkbox-component';
import shortid from 'shortid';
import {
  getProjectNameMenuOptions,
  defaultSelectedProject,
  CARRYFORWARD_COLUMNS,
} from 'constants/feature/statement-creation-constants';
import { ProjectList } from 'models/api/project-list-model';
import { StatementList } from 'models/api/statement-list-model';
import StatementForm from 'models/form/statement-form-model';
import { DataGridDataApi } from 'components/common/data-grid/data-grid-component';
import { getProjectListRequest } from 'api/project-list-api';
import { getStatementListForProjectRequest } from 'api/statement-list-api';
import ProjectApi from '../../../models/api/project-api-model';
import { isProjectHasReservedClientName } from 'utils/project-list-utils';

export const CARRY_FORWARD_BLOCK = 'carry-forward';
const CARRY_FORWARD_ID_BLOCK = 'carry-forward-id';

class Carryforward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenOpened: false,
      projectList: new ProjectList(),
      statementList: new StatementList(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { statementInProgress } = this.props;
    const { hasBeenOpened } = this.state;
    if (
      statementInProgress.hasNewFileBeenUploaded(prevProps.statementInProgress)
    ) {
      this.setState({
        hasBeenOpened: false,
      });
    } else if (
      !hasBeenOpened &&
      statementInProgress.isStepOneValid() &&
      statementInProgress.isStepTwoValid()
    ) {
      this.setState({
        hasBeenOpened: true,
      });
    }
  }

  _changeCarryForwordProject = (project) => {
    const { changeCarryForwordProject } = this.props;

    changeCarryForwordProject(project);

    this._getActiveProjectStatements(project.id);
  };

  _initCarryForwardStatementCreation = async () => {
    const { selectedProject } = this.props;
    this._fetchProjectList();
    this._getActiveProjectStatements(selectedProject.id);
  };

  _fetchProjectList = async () => {
    this.setState((state) => ({
      projectList: state.projectList.setLoading(),
    }));
    try {
      const response = await getProjectListRequest();
      this.setState((state) => ({
        projectList: state.projectList.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setError(error),
      }));
    }
  };

  _getActiveProjectStatements = async (projectId) => {
    this.setState((state) => ({
      statementList: state.statementList.setLoading(),
    }));
    try {
      const response = await getStatementListForProjectRequest({
        projectId,
        selectedProject: this.props.selectedProject,
      });
      this.setState((state) => ({
        statementList: state.statementList.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        statementList: state.statementList.setError(error),
      }));
    }
  };

  _setRadioOptionForCarryForward = (val) => {
    const {
      setCarryForwardStatementCreation,
      changeCarryForwordProject,
      setCarryForwardStatementIds,
    } = this.props;
    if (val) {
      this._initCarryForwardStatementCreation();
    } else {
      changeCarryForwordProject(null);
      setCarryForwardStatementIds({});
    }

    setCarryForwardStatementCreation(val);
  };

  _clearCarryForwardStatementIds = () => {
    this.props.setCarryForwardStatementIds({});
  };

  render() {
    const { hasBeenOpened, projectList, statementList } = this.state;
    const {
      statementInProgress,
      setCarryForwardStatementIds,
      selectedProject,
      setCarryForwardDetails,
    } = this.props;
    return (
      <div className={`${CARRY_FORWARD_BLOCK}`}>
        <div className={`${CARRY_FORWARD_BLOCK}__step`}>
          <ProgressBarStep
            id={`${CARRY_FORWARD_ID_BLOCK}-step`}
            active={
              (statementInProgress.isStepOneValid() &&
                statementInProgress.isStepTwoValid() &&
                !statementInProgress.isStepThreeValid()) ||
              hasBeenOpened
            }
            isValid={statementInProgress.isStepThreeValid()}
            stepNum={'3'}
          />
        </div>
        <div className={`${CARRY_FORWARD_BLOCK}__card`}>
          <Card>
            <h3 className={`${CARRY_FORWARD_BLOCK}__title`}>
              <FormattedMessage id={'statement-create.carry-forward.title'} />
            </h3>
            {hasBeenOpened ? (
              <React.Fragment>
                <div className={`${CARRY_FORWARD_BLOCK}__annotations-copy`}>
                  <FormattedMessage
                    id={'statement-create.carry-forward.annotations-copy'}
                  />
                </div>
                <div>
                  <Radio
                    selectedOption={statementInProgress.carryForward}
                    options={[
                      new RadioOption({
                        id: `${CARRY_FORWARD_ID_BLOCK}-radio-option-latest`,
                        label:
                          'statement-create.carryforward.radio-label.no-carryforward',
                        value: false,
                      }),
                    ]}
                    onChange={this._setRadioOptionForCarryForward}
                    id={`${CARRY_FORWARD_BLOCK}-radio-no-carryforward`}
                    width="100%"
                    isValid={true}
                    name={`${CARRY_FORWARD_BLOCK}-radio-no-carryforward`}
                    disabled={false}
                  />
                </div>
                <div className={`${CARRY_FORWARD_BLOCK}__modal--radio`}>
                  <Radio
                    selectedOption={statementInProgress.carryForward}
                    options={[
                      new RadioOption({
                        id: `${CARRY_FORWARD_ID_BLOCK}-radio-option-all`,
                        label:
                          'statement-create.carryforward.radio-label.yes-carryforward',
                        value: true,
                      }),
                    ]}
                    onChange={this._setRadioOptionForCarryForward}
                    id={`${CARRY_FORWARD_BLOCK}-radio-yes-carryforward`}
                    width="100%"
                    isValid={true}
                    name={`${CARRY_FORWARD_ID_BLOCK}-radio-no-carryforward`}
                    disabled={false}
                  />
                </div>
                {statementInProgress.carryForward ? (
                  <ConditionalRender dependencies={[projectList]}>
                    <FeatureFlagComponent
                      name={FEATURE_NAMES.CARRY_FORWARD_META_DATA_DISABLED}
                    >
                      {(window.TIEOUT.ENV.ENV_PARAM !== 'PROD' ||
                        isProjectHasReservedClientName(selectedProject)) && (
                        <div>
                          <h4
                            className={`${CARRY_FORWARD_BLOCK}__betaversion-title`}
                          >
                            <FormattedMessage
                              id={
                                'statement-create.carry-forward.betaversion.title'
                              }
                            />
                          </h4>
                          <Checkbox
                            id={`${CARRY_FORWARD_BLOCK}-betaversion-checkbox`}
                            className={`${CARRY_FORWARD_BLOCK}__betaversion-checkbox`}
                            checked={
                              statementInProgress.carryForwardDetails
                                .comparemetadata
                            }
                            value={'comparemetadata'}
                            onChange={() => {
                              setCarryForwardDetails({
                                comparemetadata:
                                  !statementInProgress.carryForwardDetails
                                    .comparemetadata,
                              });
                            }}
                            label={
                              'statement-create.carry-forward.betaversion.label'
                            }
                            width={'auto'}
                            isValid={true}
                          />
                        </div>
                      )}
                    </FeatureFlagComponent>

                    <h4 className={`${CARRY_FORWARD_BLOCK}__autotieout-title`}>
                      <FormattedMessage
                        id={'statement-create.carry-forward.autotieout-title'}
                      />
                    </h4>
                    <div
                      className={`${CARRY_FORWARD_BLOCK}__autotieout-subtitle`}
                    >
                      <FormattedMessage
                        id={
                          'statement-create.carry-forward.autotieout-subtitle'
                        }
                      />
                    </div>
                    <Checkbox
                      id={`${CARRY_FORWARD_BLOCK}-autotieout-checkbox`}
                      className={`${CARRY_FORWARD_BLOCK}__autotieout-checkbox`}
                      checked={
                        statementInProgress.carryForwardDetails.autotieout
                      }
                      value={'autotieout'}
                      onChange={() => {
                        setCarryForwardDetails({
                          autotieout:
                            !statementInProgress.carryForwardDetails.autotieout,
                        });
                      }}
                      label={'statement-create.carry-forward.autotieout.label'}
                      width={'auto'}
                      isValid={true}
                    />
                    <h4 className={`${CARRY_FORWARD_BLOCK}__annotations-title`}>
                      <FormattedMessage
                        id={'statement-create.carry-forward.annotations-title'}
                      />
                    </h4>
                    <div
                      className={`${CARRY_FORWARD_BLOCK}__annotations-subtitle`}
                    >
                      <FormattedMessage
                        id={
                          'statement-create.carry-forward.annotations-subtitle'
                        }
                      />
                    </div>
                    <Checkbox
                      id={`${CARRY_FORWARD_BLOCK}-annotations-workpaper-reference-checkbox`}
                      className={`${CARRY_FORWARD_BLOCK}__workpaper-reference-checkbox`}
                      checked={
                        statementInProgress.carryForwardDetails.workpapers
                      }
                      value={'workapapers'}
                      onChange={() => {
                        setCarryForwardDetails({
                          workpapers:
                            !statementInProgress.carryForwardDetails.workpapers,
                        });
                      }}
                      label={
                        'statement-create.carry-forward.annotations.workpaper-reference.label'
                      }
                      width={'auto'}
                      isValid={true}
                    />
                    <Checkbox
                      id={`${CARRY_FORWARD_BLOCK}-annotations-tickmarks-checkbox`}
                      className={`${CARRY_FORWARD_BLOCK}__tickmarks-checkbox`}
                      checked={
                        statementInProgress.carryForwardDetails.tickmarks
                      }
                      value={'tickmarks'}
                      onChange={() => {
                        setCarryForwardDetails({
                          tickmarks:
                            !statementInProgress.carryForwardDetails.tickmarks,
                        });
                      }}
                      label={
                        'statement-create.carry-forward.annotations.tickmarks.label'
                      }
                      width={'auto'}
                      isValid={true}
                    />
                    <Checkbox
                      id={`${CARRY_FORWARD_BLOCK}-annotations-formulas-checkbox`}
                      className={`${CARRY_FORWARD_BLOCK}__formulas-checkbox`}
                      checked={statementInProgress.carryForwardDetails.formulas}
                      value={'formulas'}
                      onChange={() => {
                        setCarryForwardDetails({
                          formulas:
                            !statementInProgress.carryForwardDetails.formulas,
                        });
                      }}
                      label={
                        'statement-create.carry-forward.annotations.formulas.label'
                      }
                      width={'auto'}
                      isValid={true}
                    />
                    <Checkbox
                      id={`${CARRY_FORWARD_BLOCK}-annotations-internalreferences-checkbox`}
                      className={`${CARRY_FORWARD_BLOCK}__internalreferences-checkbox`}
                      checked={
                        statementInProgress.carryForwardDetails
                          .internalreferences
                      }
                      value={'internalreferences'}
                      onChange={() => {
                        setCarryForwardDetails({
                          internalreferences:
                            !statementInProgress.carryForwardDetails
                              .internalreferences,
                        });
                      }}
                      label={
                        'statement-create.carry-forward.annotations.internalreferences.label'
                      }
                      width={'auto'}
                      isValid={true}
                    />
                    <div className={`${CARRY_FORWARD_BLOCK}__banner`}>
                      <Banner
                        id={`${CARRY_FORWARD_ID_BLOCK}-carry-forward-info`}
                        type={BANNER_TYPES.INFORMATION}
                        bannerCopy={{
                          id: 'statement-create.carry-forward.info-banner',
                          values: {
                            b: (...chunks) => <strong>{chunks}</strong>,
                            lineBreak: (
                              <br
                                key={`${CARRY_FORWARD_ID_BLOCK}-brTag-${shortid.generate()}}`}
                              />
                            ),
                          },
                        }}
                        isInternationalized={true}
                        isInteractive={false}
                        width={'100%'}
                      />
                    </div>
                    <div className={`${CARRY_FORWARD_BLOCK}__drop-down`}>
                      <Dropdown
                        id={`${CARRY_FORWARD_ID_BLOCK}-project-name-dropdown`}
                        label={
                          'statement-create.carry-forward.project-name-drop-down'
                        }
                        placeholder={'common.placeholder.select'}
                        width={'25%'}
                        onSelectOption={this._changeCarryForwordProject}
                        required={true}
                        isValid={true}
                        options={getProjectNameMenuOptions(
                          projectList.getActiveProjects(),
                        )}
                        value={
                          statementInProgress.carryForwardProject
                            ? statementInProgress.carryForwardProject
                            : defaultSelectedProject(selectedProject)
                        }
                      />
                    </div>
                    {statementInProgress.carryForwardStatementIdsArray
                      .length ? (
                      <div
                        className={`${CARRY_FORWARD_BLOCK}__selected-statement`}
                      >
                        {statementList
                          .getSelectedCarryForwardStatements(
                            statementInProgress.carryForwardStatementIdsArray,
                          )
                          .map((statement) => (
                            <div
                              className={`${CARRY_FORWARD_BLOCK}__selected-statement--row`}
                              key={statement.id}
                            >
                              <div
                                className={`${CARRY_FORWARD_BLOCK}__selected-statement--label`}
                              >
                                <FormattedMessage
                                  id={
                                    'statement-create.carry-forward.selected-statements'
                                  }
                                />
                              </div>
                              <div
                                className={`${CARRY_FORWARD_BLOCK}__selected-statement--name`}
                              >
                                {statement.statementName}
                              </div>
                              <Button
                                id={`${CARRY_FORWARD_ID_BLOCK}-statement-remove-button`}
                                className={`${CARRY_FORWARD_BLOCK}__remove-button`}
                                type={BUTTON_TYPES.tertiary}
                                onClick={this._clearCarryForwardStatementIds}
                              >
                                <FormattedMessage id={'common.remove'} />
                              </Button>
                            </div>
                          ))}
                      </div>
                    ) : null}
                    <div className={`${CARRY_FORWARD_BLOCK}__statement-list`}>
                      <DataGrid
                        className={classnames(`${CARRY_FORWARD_BLOCK}__list`)}
                        columns={CARRYFORWARD_COLUMNS}
                        tableId={`${CARRY_FORWARD_ID_BLOCK}-list`}
                        dataModel={
                          new DataGridDataApi({
                            apiModel: statementList,
                            rowItems: statementList.getStatements(),
                          })
                        }
                        onRowCheckboxSelect={setCarryForwardStatementIds}
                        selectedRows={
                          statementInProgress.carryForwardStatementIds
                            ? statementInProgress.carryForwardStatementIds
                            : {}
                        }
                        canSelectMoreThenOneRow={false}
                        NoResultsComponent={<NoCarryForwardStatements />}
                      />
                    </div>
                    {!statementList.getStatements().length ? (
                      <div className={`${CARRY_FORWARD_BLOCK}__info`}>
                        <CarryForwardInfo />
                      </div>
                    ) : null}
                  </ConditionalRender>
                ) : null}
              </React.Fragment>
            ) : null}
          </Card>
        </div>
      </div>
    );
  }
}

Carryforward.propTypes = {
  /** Api model representing the statement being created comming from statement creation as state */
  statementInProgress: PropTypes.instanceOf(StatementForm).isRequired,
  /** Api model that represents currently selected project */
  selectedProject: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Func that sets all statements you want to carry forward */
  setCarryForwardStatementIds: PropTypes.func.isRequired,
  /** Method that changes carryforward project */
  changeCarryForwordProject: PropTypes.func.isRequired,
};

export default Carryforward;
