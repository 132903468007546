import { handleActions } from 'redux-actions';
import {
  fetchSectionReviewsHistoryLoaded,
  fetchSectionReviewsHistoryLoading,
  fetchSectionReviewsHistoryError,
  appendSectionReviewHistory,
} from 'store/actions/section-review-history-actions';
import { SectionReviewHistory } from 'models/api/section-review-history-model';

export default handleActions(
  {
    [fetchSectionReviewsHistoryLoaded]: (sectionHistory, { payload }) => {
      return sectionHistory.setLoaded(payload);
    },
    [appendSectionReviewHistory]: (sectionHistory, { payload }) => {
      return sectionHistory.appendSectionReviewHistory(payload);
    },
    [fetchSectionReviewsHistoryLoading]: (sectionHistory, { payload }) => {
      return sectionHistory.setLoading(payload);
    },
    [fetchSectionReviewsHistoryError]: (sectionHistory, { payload }) => {
      return sectionHistory.setError(payload);
    },
  },
  new SectionReviewHistory(),
);
