import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormItem from 'components/common/form-item-component';
import classNames from 'classnames';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isNullOrUndefined } from 'utils/object-utils';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';

export const CHECKBOX_BLOCK = 'checkbox';

const Checkbox = ({
  className,
  checkboxClassName,
  checkboxInputClassName,
  disabled,
  errorText,
  id,
  checked,
  isValid,
  isNotIntl,
  label,
  value,
  name,
  onChange,
  tooltip,
  width,
  alt,
  intl,
  masterChecked,
  needsMultilineLabel,
}) => {
  const renderCheckbox = () => (
    <div
      className={classNames(
        CHECKBOX_BLOCK,
        checked ? `${CHECKBOX_BLOCK}--checked` : null,
        disabled ? `${CHECKBOX_BLOCK}--disabled` : null,
        !isNullOrUndefined(isValid) && !isValid
          ? `${CHECKBOX_BLOCK}--invalid`
          : null,
        needsMultilineLabel && `${CHECKBOX_BLOCK}--multiLineLabel`,
        checkboxClassName,
      )}
      onClick={() => {
        // need an onclick on the wrapper so the whole styled wrapping box, with border is clickable to select the checkbox
        !disabled && onChange(value);
      }}
    >
      <input
        checked={checked}
        className={classNames(
          `${CHECKBOX_BLOCK}__input`,
          alt ? `${CHECKBOX_BLOCK}__input--alt` : null,
          masterChecked && `${CHECKBOX_BLOCK}__input--master`,
          checkboxInputClassName,
        )}
        disabled={disabled}
        id={id}
        name={name}
        onChange={() => {}} // had to use onClick so we could stop propagation of the click event to the wrapper component.
        onClick={(e) => {
          e.stopPropagation();
          onChange(value);
        }}
        type="checkbox"
        value={value}
      />
      <label
        htmlFor={id}
        disabled={disabled}
        className={classNames(
          `${CHECKBOX_BLOCK}__label`,
          needsMultilineLabel && `${CHECKBOX_BLOCK}--multiLineLabel`,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {needsMultilineLabel ? (
          <MultiLineEllipsis
            toolTipID={`${CHECKBOX_BLOCK}-${id}`}
            text={label}
            isNotInternationalized={true}
          >
            {label}
          </MultiLineEllipsis>
        ) : isNotIntl ? (
          label
        ) : (
          <FormattedMessage id={label} />
        )}
      </label>
    </div>
  );

  return (
    <FormItem
      className={classNames(className)}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      width={width}
    >
      {tooltip ? (
        <Tooltip {...tooltip} active={tooltip ? true : false}>
          {renderCheckbox()}
        </Tooltip>
      ) : (
        renderCheckbox()
      )}
    </FormItem>
  );
};

Checkbox.propTypes = {
  /* Use alternative checked state */
  alt: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  /** optional prop to indicate if checkbox label will be an intl id or not */
  isNotIntl: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.instanceOf(TooltipOptions),
  width: PropTypes.string,
  masterChecked: PropTypes.bool,
  needsMultilineLabel: PropTypes.bool,
};

export default memo(injectIntl(Checkbox));
