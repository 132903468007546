import {
  withDefaultContextAsProject,
  withDefaultContextAsHomeGeo,
} from 'api/api-default-context';
import { isNullOrUndefined } from 'utils/object-utils';
import { CONTEXT_KEY } from './api-client';

export const getEngagementEntitiesRequest = (searchTerm) =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/searchengagements', {
      params: {
        searchTerm,
      },
    })
    .then((response) => {
      return response;
    });

export const getUserListRequest = ({
  clientId,
  searchString,
  pageNumber,
  numberOfRows,
  memberFirmCode,
}) => {
  // for admin page client id is going to be 0 and for create project page it is 0.
  // Hence for those cases we want this request to be fired in users Home Geo
  let contextKey =
    isNullOrUndefined(clientId) || clientId === -1 || clientId === 0
      ? CONTEXT_KEY.HOME_GEO_CONTEXT
      : null;
  return withDefaultContextAsProject({ contextKey }, clientId)
    .post(
      '/tieout/user/search',
      {
        searchString,
        pageNumber,
        numberOfRows,
        memberFirmCode,
      },
      {
        params: {
          clientId: parseInt(clientId),
        },
      },
    )
    .then((response) => {
      return response;
    });
};

export const createNewProjectRequest = (project) =>
  withDefaultContextAsHomeGeo()
    .post('/tieout/clients', project.toApiFormat())
    .then((response) => {
      return response;
    });

export const editProjectRequest = (project, contextKey) => {
  return withDefaultContextAsProject({ contextKey }, project.data.id)
    .post('/tieout/client/update', project.toApiFormat())
    .then((response) => {
      response.data.id = project.id;
      return response;
    });
};
