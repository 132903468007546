import { handleActions } from 'redux-actions';
import { setCurrentAdminModuleTab } from 'store/actions/admin-module-actions';
import AdminModuleTabModel from 'models/data/admin-module-tab-model';

export default handleActions(
  {
    [setCurrentAdminModuleTab]: (adminModuleCurrentTab, { payload }) => {
      return adminModuleCurrentTab.setSelectedTab(payload);
    },
  },
  new AdminModuleTabModel(),
);
