import { Component } from 'react';
import { getAPIClient } from 'api/api-client';
import { authenticateSuccess } from 'api/api-authentication';

class AuthSuccess extends Component {
  componentDidMount() {
    if (!window.AuthenticationContext) {
      Object.keys(window.TIEOUT.ENV.GEOS).forEach((geoKey) =>
        getAPIClient(geoKey),
      );
    }
    authenticateSuccess();
  }

  render() {
    return null;
  }
}
export default AuthSuccess;
