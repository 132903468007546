import { handleActions } from 'redux-actions';

import {
  selectedProjectLoading,
  selectedProjectLoaded,
  clearSelectedProject,
  selectedProjectError,
  setUpdatedLegalHoldForSelectedProject,
} from 'store/actions/selected-project-actions';

import ProjectApi from 'models/api/project-api-model';

export default handleActions(
  {
    [selectedProjectLoading]: (selectedProject) => {
      return selectedProject.setLoading();
    },
    [selectedProjectLoaded]: (selectedProject, { payload: { response } }) => {
      return selectedProject.setLoaded(response);
    },
    [selectedProjectError]: (projectList, { payload }) => {
      return projectList.setError(payload);
    },
    [clearSelectedProject]: (selectedProject) => {
      return new ProjectApi();
    },
    [setUpdatedLegalHoldForSelectedProject]: (projectList, { payload }) => {
      return projectList.updateLegalHoldForSelectedProject(payload);
    },
  },
  new ProjectApi(),
);
