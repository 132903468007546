import React from 'react';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import DataGridColumn from 'models/utils/common/data-grid/data-grid-column-model';
import { DataGridCheckboxColumn } from 'components/common/data-grid/data-grid-component';
import MultilineEllipsis from '../../components/common/multi-line-ellipsis-text-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { FormattedMessage } from 'react-intl';

export const MAX_STATEMENT_FILE_SIZE = 209715200;
export const MAX_STATEMENT_NAME_LENGTH = 100;
export const SUPPORTED_STATEMENT_FILE_TYPES =
  'text/htm, text/html, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const LEGAL_HOLD_TOOLTIP = new TooltipOptions({
  text: 'project-creation.project-info.legal-hold-verbiage',
  id: 'project-creation-id-tooltip-legal-hold',
  position: 'right',
});

export const ENABLE_COMFORT_LETTER_TOOLTIP = new TooltipOptions({
  text: 'project-creation.project-info.enable-comfort-letter-verbiage.tooltip',
  id: 'project-creation-id-enable-comfort-letter',
  position: 'right',
});

export const AUTO_IR_TOOLTIP = new TooltipOptions({
  text: 'statement-create.statement-info.auto-ir-verbiage',
  id: 'statement-create-statement-info-auto-ir',
  position: 'right',
});

export const STATEMENT_TYPE_ID_MAP = {
  TEN_K: 1, // 10-K
  TEN_Q: 2, // 10-Q
  SIX_K: 5, // 6-K
  PRESS_RELEASE: 6, // 8-K
  TWENTY_F: 7, // 20-F
  FORTY_F: 8, // 40-F
  NCSR: 9, // N-CSR
  PRIVATE_ANNUAL: 11, // Private Annual
  PRIVATE_QUARTERLY: 12, // Private Quarterly
  PROXY_STATEMENT: 13,
  COMFORT_LETTER: 14,
  REGISTRATION_STATEMENT: 15,
  INSURANCE_STATEMENT: 16,
  IM_FUND_REPORT: 17,
  AGENCY_REPORT: 18,
  OTHER: 999, // Other
  GLOBAL_ANNUAL: 19, // Global Annual
  GLOBAL_QUARTERLY: 20, // Global Quarterly
};

export const STATEMENT_TYPE_OPTIONS_MAP = new Map();
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.TEN_K,
  new MenuOption({
    id: 'statement-type-option-10-K',
    title: 'statement-create.statement-info.statement-type.option.10-K',
    value: STATEMENT_TYPE_ID_MAP.TEN_K,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.TEN_Q,
  new MenuOption({
    id: 'statement-type-option-10-Q',
    title: 'statement-create.statement-info.statement-type.option.10-Q',
    value: STATEMENT_TYPE_ID_MAP.TEN_Q,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.PRIVATE_ANNUAL,
  new MenuOption({
    id: 'statement-type-option-private-annual',
    title:
      'statement-create.statement-info.statement-type.option.private-annual',
    value: STATEMENT_TYPE_ID_MAP.PRIVATE_ANNUAL,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.PRIVATE_QUARTERLY,
  new MenuOption({
    id: 'statement-type-option-private-quarterly',
    title:
      'statement-create.statement-info.statement-type.option.private-quarterly',
    value: STATEMENT_TYPE_ID_MAP.PRIVATE_QUARTERLY,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.GLOBAL_ANNUAL,
  new MenuOption({
    id: 'statement-type-option-global-annual',
    title:
      'statement-create.statement-info.statement-type.option.global-annual',
    value: STATEMENT_TYPE_ID_MAP.GLOBAL_ANNUAL,
  }),
);

STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.GLOBAL_QUARTERLY,
  new MenuOption({
    id: 'statement-type-option-global-quarterly',
    title:
      'statement-create.statement-info.statement-type.option.global-quarterly',
    value: STATEMENT_TYPE_ID_MAP.GLOBAL_QUARTERLY,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.IM_FUND_REPORT,
  new MenuOption({
    id: 'statement-type-option-im-fund-report',
    title:
      'statement-create.statement-info.statement-type.option.im-fund-report',
    value: STATEMENT_TYPE_ID_MAP.IM_FUND_REPORT,
  }),
);

STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.PRESS_RELEASE,
  new MenuOption({
    id: 'statement-type-option-press-release',
    title:
      'statement-create.statement-info.statement-type.option.press-release',
    value: STATEMENT_TYPE_ID_MAP.PRESS_RELEASE,
  }),
);

STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.AGENCY_REPORT,
  new MenuOption({
    id: 'statement-type-option-agency-report',
    title:
      'statement-create.statement-info.statement-type.option.agency-report',
    value: STATEMENT_TYPE_ID_MAP.AGENCY_REPORT,
  }),
);

STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.PROXY_STATEMENT,
  new MenuOption({
    id: 'statement-type-option-proxy-statement',
    title:
      'statement-create.statement-info.statement-type.option.proxy-statement',
    value: STATEMENT_TYPE_ID_MAP.PROXY_STATEMENT,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.COMFORT_LETTER,
  new MenuOption({
    id: 'statement-type-option-comfort-letter',
    title:
      'statement-create.statement-info.statement-type.option.comfort-letter',
    value: STATEMENT_TYPE_ID_MAP.COMFORT_LETTER,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.REGISTRATION_STATEMENT,
  new MenuOption({
    id: 'statement-type-option-registration-statement',
    title:
      'statement-create.statement-info.statement-type.option.registration-statement',
    value: STATEMENT_TYPE_ID_MAP.REGISTRATION_STATEMENT,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.INSURANCE_STATEMENT,
  new MenuOption({
    id: 'statement-type-option-stat-audit-report',
    title:
      'statement-create.statement-info.statement-type.option.insurance-statement',
    value: STATEMENT_TYPE_ID_MAP.INSURANCE_STATEMENT,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.SIX_K,
  new MenuOption({
    id: 'statement-type-option-6-K',
    title: 'statement-create.statement-info.statement-type.option.6-K',
    value: STATEMENT_TYPE_ID_MAP.SIX_K,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.TWENTY_F,
  new MenuOption({
    id: 'statement-type-option-20-F',
    title: 'statement-create.statement-info.statement-type.option.20-F',
    value: STATEMENT_TYPE_ID_MAP.TWENTY_F,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.FORTY_F,
  new MenuOption({
    id: 'statement-type-option-40-F',
    title: 'statement-create.statement-info.statement-type.option.40-F',
    value: STATEMENT_TYPE_ID_MAP.FORTY_F,
  }),
);
STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.NCSR,
  new MenuOption({
    id: 'statement-type-option-N-CSR',
    title: 'statement-create.statement-info.statement-type.option.N-CSR',
    value: STATEMENT_TYPE_ID_MAP.NCSR,
  }),
);

STATEMENT_TYPE_OPTIONS_MAP.set(
  STATEMENT_TYPE_ID_MAP.OTHER,
  new MenuOption({
    id: 'statement-type-option-other',
    title: 'statement-create.statement-info.statement-type.option.other',
    value: STATEMENT_TYPE_ID_MAP.OTHER,
  }),
);

export const STATEMENT_TYPE_OPTIONS = Array.from(
  STATEMENT_TYPE_OPTIONS_MAP.values(),
);

export const QUARTER_OPTIONS_MAP = new Map();
QUARTER_OPTIONS_MAP.set(
  'Q1',
  new MenuOption({
    id: 'quarter-option-q1',
    title: 'statement-create.statement-info.quarter.option.q1',
    value: 1,
  }),
);
QUARTER_OPTIONS_MAP.set(
  'Q2',
  new MenuOption({
    id: 'quarter-option-q2',
    title: 'statement-create.statement-info.quarter.option.q2',
    value: 2,
  }),
);
QUARTER_OPTIONS_MAP.set(
  'Q3',
  new MenuOption({
    id: 'quarter-option-q3',
    title: 'statement-create.statement-info.quarter.option.q3',
    value: 3,
  }),
);

export const QUARTER_OPTIONS = Array.from(QUARTER_OPTIONS_MAP.values());

const currentYear = moment().format(DATE_FORMATS.YEAR);
const minus5Years = moment().subtract(5, 'y').format(DATE_FORMATS.YEAR);
const minus4Years = moment().subtract(4, 'y').format(DATE_FORMATS.YEAR);
const minus3Years = moment().subtract(3, 'y').format(DATE_FORMATS.YEAR);
const minus2Years = moment().subtract(2, 'y').format(DATE_FORMATS.YEAR);
const minus1Years = moment().subtract(1, 'y').format(DATE_FORMATS.YEAR);
const plus1Years = moment().add(1, 'y').format(DATE_FORMATS.YEAR);
const plus2Years = moment().add(2, 'y').format(DATE_FORMATS.YEAR);

export const FISCAL_YEAR_OPTIONS_MAP = new Map();
FISCAL_YEAR_OPTIONS_MAP.set(
  minus5Years,
  new MenuOption({
    id: 'fiscal-year-option-1',
    title: minus5Years,
    value: minus5Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  minus4Years,
  new MenuOption({
    id: 'fiscal-year-option-2',
    title: minus4Years,
    value: minus4Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  minus3Years,
  new MenuOption({
    id: 'fiscal-year-option-3',
    title: minus3Years,
    value: minus3Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  minus2Years,
  new MenuOption({
    id: 'fiscal-year-option-4',
    title: minus2Years,
    value: minus2Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  minus1Years,
  new MenuOption({
    id: 'fiscal-year-option-5',
    title: minus1Years,
    value: minus1Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  currentYear,
  new MenuOption({
    id: 'fiscal-year-option-6',
    title: currentYear,
    value: currentYear,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  plus1Years,
  new MenuOption({
    id: 'fiscal-year-option-7',
    title: plus1Years,
    value: plus1Years,
    isIntl: false,
  }),
);
FISCAL_YEAR_OPTIONS_MAP.set(
  plus2Years,
  new MenuOption({
    id: 'fiscal-year-option-8',
    title: plus2Years,
    value: plus2Years,
    isIntl: false,
  }),
);

export const FISCAL_YEAR_OPTIONS = Array.from(FISCAL_YEAR_OPTIONS_MAP.values());

const ACCOUNTING_BASIS_TYPES = {
  GAAP: 'GAAP',
  IFRS: 'IFRS',
  STATUTORY: 'Statutory',
  NOT_APPLICABLE: 'Not applicable',
};

export const ACCOUNTING_BASIS_OPTIONS_MAP = new Map(
  Object.entries({
    [ACCOUNTING_BASIS_TYPES.GAAP]: new MenuOption({
      id: 'accounting-basis-option-GAAP',
      title: 'statement-create.statement-info.accounting-basis.option.GAAP',
      value: ACCOUNTING_BASIS_TYPES.GAAP,
    }),
    [ACCOUNTING_BASIS_TYPES.IFRS]: new MenuOption({
      id: 'accounting-basis-option-IFRS',
      title: 'statement-create.statement-info.accounting-basis.option.IFRS',
      value: ACCOUNTING_BASIS_TYPES.IFRS,
    }),
    [ACCOUNTING_BASIS_TYPES.STATUTORY]: new MenuOption({
      id: 'accounting-basis-option-STATUTORY',
      title:
        'statement-create.statement-info.accounting-basis.option.STATUTORY',
      value: ACCOUNTING_BASIS_TYPES.STATUTORY,
    }),
    [ACCOUNTING_BASIS_TYPES.NOT_APPLICABLE]: new MenuOption({
      id: 'accounting-basis-option-other',
      title: 'statement-create.statement-info.accounting-basis.option.NA',
      value: ACCOUNTING_BASIS_TYPES.NOT_APPLICABLE,
    }),
  }),
);

export const ACCOUNTING_BASIS_OPTIONS = Array.from(
  ACCOUNTING_BASIS_OPTIONS_MAP.values(),
);

const REGISTRANT_TYPES = {
  SEC: 'SEC',
  NAIC: 'NAIC',
  OTHER: 'Other',
};

export const REGISTRANT_OPTIONS_MAP = new Map();
REGISTRANT_OPTIONS_MAP.set(
  REGISTRANT_TYPES.SEC,
  new MenuOption({
    id: 'registrant-option-SEC',
    title: 'statement-create.statement-info.registrant.option.SEC',
    value: REGISTRANT_TYPES.SEC,
  }),
);
REGISTRANT_OPTIONS_MAP.set(
  REGISTRANT_TYPES.NAIC,
  new MenuOption({
    id: 'registrant-option-NAIC',
    title: 'statement-create.statement-info.registrant.option.NAIC',
    value: REGISTRANT_TYPES.NAIC,
  }),
);
REGISTRANT_OPTIONS_MAP.set(
  REGISTRANT_TYPES.OTHER,
  new MenuOption({
    id: 'registrant-option-other',
    title: 'statement-create.statement-info.registrant.option.other',
    value: REGISTRANT_TYPES.OTHER,
  }),
);

export const REGISTRANT_OPTIONS = Array.from(REGISTRANT_OPTIONS_MAP.values());

export const getProjectNameMenuOptions = (projectList) => {
  return projectList.map(
    (project) =>
      new MenuOption({
        id: project.id,
        title: project.name,
        value: project,
        isIntl: false,
      }),
  );
};

export const getLanguagesMenuOptions = (languages) => {
  return languages.map(
    (lang) =>
      new MenuOption({
        id: lang.languageCode,
        title: lang.languageName,
        value: lang.languageCode,
        isIntl: false,
      }),
  );
};

export const defaultSelectedProject = (selectedProject) => {
  return new MenuOption({
    id: selectedProject.id,
    title: selectedProject.name,
    value: selectedProject,
    isIntl: false,
  });
};

export const CARRYFORWARD_COLUMNS = [
  new DataGridCheckboxColumn({
    id: 'carryforward-col-checkbox-id',
  }),
  new DataGridColumn({
    key: 'statementName',
    header: 'statement-list.column.statement-name',
    width: '40%',
    formatter: (statement, { rowKey }) => {
      const getStatementStatusSuffix = () => {
        if (statement.isPendingApproval()) {
          return (
            <FormattedMessage id="statement-list.column.statement-name.suffix.status.pending-approval" />
          );
        } else if (statement.hasApprovalInitiated()) {
          return (
            <FormattedMessage id="statement-list.column.statement-name.suffix.status.approval-initiated" />
          );
        } else if (statement.isWrappedUp()) {
          return (
            <FormattedMessage id="statement-list.column.statement-name.suffix.status.wrapped-up" />
          );
        }
      };
      const colId = `statement-name-col-${rowKey}`;
      return (
        <>
          <MultilineEllipsis
            toolTipID={colId}
            text={statement.statementName}
            isNotInternationalized
          />
          {getStatementStatusSuffix()}
        </>
      );
    },
  }),
  new DataGridColumn({
    key: 'type',
    header: 'statement-list.column.type',
    width: '15%',
    formatter: (statement, { rowKey }) => {
      const colId = `statement-type-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={statement.type}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'fiscalYear',
    header: 'statement-list.column.fiscal-year',
    width: '15%',
  }),
  new DataGridColumn({
    key: 'periodEnding',
    header: 'statement-list.column.period-ending',
    width: '15%',
    formatter: (statement) =>
      moment(statement.periodEnding).format(DATE_FORMATS.MONTH_DAY_YEAR),
  }),
  new DataGridColumn({
    key: 'status',
    header: 'statement-list.column.status',
    width: '15%',
    formatter: (statement) => {
      return `${Math.round(
        (statement.percentageCompleted * 100 * 100) / 100,
      )}%`;
    },
  }),
];
export const ENABLE_LEGAL_HOLD_TOOLTIP = new TooltipOptions({
  text: 'project-edit.manage-legal-hold-enable-tooltip.text',
  id: 'project-edit-manage-legal-hold-enable-tool-tip',
  position: 'right',
});
export const DISABLE_LEGAL_HOLD_TOOLTIP = new TooltipOptions({
  text: 'project-edit.manage-legal-hold-disable-tooltip.text',
  id: 'project-edit-manage-legal-hold-disable-tool-tip',
  position: 'right',
});
export const ENABLE_AUTO_IR_TOOLTIP = new TooltipOptions({
  text: 'statement-create.statement.info-auto-ir-enable-tooltip.text',
  id: 'statement-create-statement-info-auto-ir-enable-tool-tip',
  position: 'right',
});

export const DISABLED_COMFORT_LETTER_TOOLTIP = new TooltipOptions({
  text: 'project-edit.disabled-comfort-letter-tooltip.text',
  id: 'project-edit-disabled-comfort-letter-tooltip-id',
  position: 'right',
});

export const getDateFormatMenuOptions = (dateFormats) => {
  return dateFormats.map(
    (date) =>
      new MenuOption({
        id: date.id,
        title: date.formatType,
        value: date.id,
        isIntl: false,
      }),
  );
};

export const getNumberFormatMenuOptions = (numberFormats) => {
  return numberFormats.map(
    (numb) =>
      new MenuOption({
        id: numb.id,
        title: numb.formatType,
        value: numb.id,
        isIntl: false,
      }),
  );
};
