import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Success } from 'icons/success.svg';
import { ReactComponent as Information } from 'icons/info.svg';
import { ReactComponent as InformationSecondary } from 'icons/icon-information-tooltip.svg';
import { ReactComponent as Guidance } from 'icons/guidance.svg';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { ReactComponent as Error } from 'icons/error.svg';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';

export const BANNER_TYPES = {
  SUCCESS: 'success',
  INFORMATION: 'information',
  SECONDARY_INFORMATION: 'secondary-information',
  GUIDANCE: 'guidance',
  WARNING: 'warning',
  ERROR: 'error',
};

const BANNER_BLOCK = 'banner';
const BANNER_ICON_SIZE = '18px';
const BANNER_CLOSE_ICON_SIZE = '28px';

class Banner extends Component {
  /** returns icon component based on type string
   * @param {string} type - One of strings success, information, guidance, warning, error (use constants)
   */
  getBannerTypeIcon = (type) => {
    let icon;
    switch (type) {
      case BANNER_TYPES.SUCCESS:
        icon = (
          <Success
            name={'success-tick'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
        break;
      case BANNER_TYPES.INFORMATION:
        icon = (
          <Information
            name={'information'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
        break;
      case BANNER_TYPES.GUIDANCE:
        icon = (
          <Guidance
            name={'guidance'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
        break;
      case BANNER_TYPES.WARNING:
        icon = (
          <Warning
            name={'warning'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
        break;
      case BANNER_TYPES.SECONDARY_INFORMATION:
        icon = (
          <InformationSecondary
            name={'information-secondary'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
        break;
      default:
        icon = (
          <Error
            name={'error'}
            className={`${BANNER_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
        );
    }
    return icon;
  };

  render() {
    const {
      id,
      type,
      bannerCopy,
      className,
      onClose,
      isInternationalized,
      isInteractive,
      width,
    } = this.props;
    let _bannerIntlData = bannerCopy;
    if (typeof bannerCopy === 'string') {
      _bannerIntlData = { id: bannerCopy };
    }
    return (
      <div
        className={classnames(
          `${BANNER_BLOCK}__${type}`,
          !isInteractive ? `${BANNER_BLOCK}__${type}--no-border` : null,
          className,
        )}
        id={id}
        style={{ width: width ? width : '100%' }}
      >
        <div className={`${BANNER_BLOCK}__header`}>
          {this.getBannerTypeIcon(type)}
          <div className={`${BANNER_BLOCK}__body`}>
            {isInternationalized ? (
              <FormattedMessage {..._bannerIntlData} />
            ) : (
              bannerCopy
            )}
          </div>
          {isInteractive ? (
            <CloseButton
              name={'close-button'}
              role={'button'}
              className={`${BANNER_BLOCK}__close`}
              width={BANNER_CLOSE_ICON_SIZE}
              height={BANNER_CLOSE_ICON_SIZE}
              onClick={() => onClose()}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  /** Unique string id for banner */
  id: PropTypes.string.isRequired,
  /** Custom extra classname */
  className: PropTypes.string,
  /** Function fired when close button is pressed */
  onClose: PropTypes.func,
  /** String copy for body of banner */
  bannerCopy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.object,
    }),
  ]).isRequired,
  /** One of strings success, information, guidance, warning, error (use BANNER_TYPES constants) */
  type: PropTypes.string.isRequired,
  /** Boolean representing  whether the component takes internationalized id or string*/
  isInternationalized: PropTypes.bool.isRequired,
  /** Boolean representing if close button is shown */
  isInteractive: PropTypes.bool,
  /** string for the width dimension, should include the % */
  width: PropTypes.string,
};

export default Banner;
