import React from 'react';
import useOutsideClick from 'components/hooks/useOutsideClick';
import { ReactComponent as FilterIcon } from 'icons/filter-icon.svg';
import { ReactComponent as CrossIcon } from 'icons/close-button.svg';
import SearchComponent from 'components/common/search-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export const DATA_GRID_FILTER_BLOCK = 'data-grid-filter';
const ICON_SIZE = '16px';

const DataGridFilter = ({
  className,
  columnKey,
  filterInput,
  showFilterPopup,
  isFilterActive,
  onFilterInputChange,
  onFilterInputClear,
  onFilterInputApply,
  onFilterClick,
  onCloseFilterPopup,
}) => {
  const inputValue = filterInput[columnKey] || '';
  const [popUpContainerRef] = useOutsideClick(
    () => showFilterPopup && onCloseFilterPopup(),
  );

  const onChange = (value) => {
    onFilterInputChange(columnKey, value);
  };

  return (
    <div ref={popUpContainerRef}>
      <div className={DATA_GRID_FILTER_BLOCK}>
        <div
          className={`${DATA_GRID_FILTER_BLOCK}__filter-icon-container`}
          onClick={onFilterClick}
        >
          <div
            className={classNames({
              [`${DATA_GRID_FILTER_BLOCK}__filter-active`]:
                isFilterActive && !showFilterPopup,
            })}
          ></div>
          <div
            className={classNames(`${DATA_GRID_FILTER_BLOCK}__filter-icon`, {
              [`${DATA_GRID_FILTER_BLOCK}__filter-icon-active`]:
                showFilterPopup,
            })}
          >
            <FilterIcon
              name={'filter-icon'}
              className={`${DATA_GRID_FILTER_BLOCK}__icon`}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          </div>
        </div>
      </div>
      {showFilterPopup && (
        <div
          className={classNames(`${DATA_GRID_FILTER_BLOCK}__filter-popup`, {
            [className]: !!className,
          })}
        >
          <div className={`${DATA_GRID_FILTER_BLOCK}__header`}>
            <div className={`${DATA_GRID_FILTER_BLOCK}__header-title`}>
              Filter
            </div>
            <div
              className={`${DATA_GRID_FILTER_BLOCK}__cross-icon`}
              onClick={onCloseFilterPopup}
            >
              <CrossIcon />
            </div>
          </div>
          <SearchComponent
            id={`${DATA_GRID_FILTER_BLOCK}-search`}
            className={`${DATA_GRID_FILTER_BLOCK}__search`}
            placeholder="common.search"
            onChange={onChange}
            value={inputValue}
            isValid={true}
            disableAutoComplete
            showSearchButton
          />
          <div className={`${DATA_GRID_FILTER_BLOCK}__filter-btn`}>
            <Button
              id={`${DATA_GRID_FILTER_BLOCK}-reset-button`}
              className={`${DATA_GRID_FILTER_BLOCK}__reset-button`}
              type={BUTTON_TYPES.tertiary}
              onClick={() => onFilterInputClear(columnKey)}
              disabled={!inputValue}
            >
              <FormattedMessage id={'common.reset'} />
            </Button>
            <Button
              id={`${DATA_GRID_FILTER_BLOCK}-apply-button`}
              className={`${DATA_GRID_FILTER_BLOCK}__apply-button`}
              type={BUTTON_TYPES.primary}
              onClick={onFilterInputApply}
              disabled={!inputValue}
            >
              <FormattedMessage id={'common.apply'} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

DataGridFilter.propTypes = {
  /**css classname for popup placement */
  className: PropTypes.string,
  /**It is used to determine the column on which filter is applied */
  columnKey: PropTypes.string,
  /**It containes the value of input field */
  filterInput: PropTypes.object,
  /**It will show/hide the filter popup */
  showFilterPopup: PropTypes.bool.isRequired,
  /**It will check whether filter is active or not to show orange icon */
  isFilterActive: PropTypes.bool.isRequired,
  /**It is the change handler for filter input */
  onFilterInputChange: PropTypes.func.isRequired,
  /**It will clear/reset the filter input */
  onFilterInputClear: PropTypes.func.isRequired,
  /**It will execute when use click on apply button */
  onFilterInputApply: PropTypes.func.isRequired,
  /**It will execute when user will click on filter icon */
  onFilterClick: PropTypes.func.isRequired,
  /**It will close the filter popup */
  onCloseFilterPopup: PropTypes.func.isRequired,
};

export default DataGridFilter;
