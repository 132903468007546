export const TICKMARK_TEXT_CHAR_LIMIT = 4000;
export const NEW_LINE_REGEX = /\n/g;

export const shouldShowMoreButton = ({ textRef }) => {
  return (
    textRef &&
    textRef.scrollHeight &&
    textRef.clientHeight &&
    textRef.scrollHeight > textRef.clientHeight
  );
};
