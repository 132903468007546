import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const TOOLKIT_PANEL_WORKPAPER_DELETE_MODAL = 'workpaper-toolkit-delete-modal';

const ToolkitDeleteWorkpaperModal = ({ onClose, onDelete }) => (
  <Modal
    id={TOOLKIT_PANEL_WORKPAPER_DELETE_MODAL}
    title="toolkit.workpaper-panel.delete-modal.title"
    onClose={onClose}
    primaryModalButton={
      new ModalButton({ text: 'common.confirm', onClick: onDelete })
    }
    secondaryModalButton={
      new ModalButton({ text: 'common.cancel', onClick: onClose })
    }
  >
    <div>
      <FormattedMessage id="toolkit.workpaper-panel.delete-modal.message" />
    </div>
  </Modal>
);

ToolkitDeleteWorkpaperModal.propTypes = {
  /** Action fired when user clicks close */
  onClose: PropTypes.func.isRequired,
  /** Action fired when user clicks delete */
  onDelete: PropTypes.func.isRequired,
};

export default ToolkitDeleteWorkpaperModal;
