import { isNullOrUndefined } from 'utils/object-utils';
import { formatTieoutSelectorForApi } from 'constants/feature/notes-panel-constants';

export default class NoteSelection {
  revisionId;
  sectionId;
  revisionNumber;
  selectedText;
  selector;
  endSelector;
  offset;
  endOffset;
  length;
  hasElement;
  constructor(data) {
    if (!isNullOrUndefined(data)) {
      this.revisionId = data.revisionId;
      this.sectionId = data.sectionId;
      this.revisionNumber = data.revisionNumber;
      this.selectedText = data.selectedText;
      this.selector = data.selector;
      this.endSelector = data.endSelector;
      this.offset = data.offset;
      this.endOffset = data.endOffset;
      this.length = data.length;
      this.hasElement = data.hasElement;
    }
  }

  hasSelection() {
    return !isNullOrUndefined(this.selectedText);
  }

  toApiFormat() {
    return {
      ...this,
      selector: formatTieoutSelectorForApi({ selector: this.selector }),
      endSelector: formatTieoutSelectorForApi({ selector: this.endSelector }),
    };
  }
}
