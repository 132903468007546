import { push } from 'connected-react-router';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { fetchCurrentUser } from 'store/actions/current-user-actions';
import { updateSelectedProjectFromID } from 'store/actions/selected-project-actions';
import { CONTEXT_KEY } from '../../api/api-client';

export const finishProjectCreation =
  (projectInProgress) => async (dispatch) => {
    const callbackToNavigateToHomePage = () =>
      dispatch(push(ROUTE_CONSTANTS.HOME_PAGE));

    //Set the project just created as the currently selected project
    dispatch(
      updateSelectedProjectFromID({
        projectId: projectInProgress.data.result.id,
        contextKey: CONTEXT_KEY.HOME_GEO_CONTEXT,
      }),
    );

    /**
     * Update the current user as the user's project roles are stored there,
     * and navigate user to homepage once selected project is decided.
     *  */
    await dispatch(fetchCurrentUser(callbackToNavigateToHomePage));
  };
