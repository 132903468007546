import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Radio from 'components/common/radio-component';
import { RadioOption } from 'models/utils/common/radio-options-model';

import { DELETE_STATEMENT_OPTIONS } from 'constants/feature/statement-list-constants';
import Statement from 'models/data/statement-model';
import { isNullOrUndefined } from 'utils/object-utils';

const PERMANENTLY_DELETE_REVISION_ID_BLOCK =
  'id-permanently-delete-revision-modal';
const PERMANENTLY_DELETE_REVISION_BLOCK = 'permanently-delete-revision-modal';

const PermanentlyDeleteRevisionsModal = ({
  statementToDelete,
  onClose,
  onDeleteTop,
  onDeleteAll,
}) => {
  const [revisionDeleteSelection, setRevisionDeleteSelection] = useState(null);
  return (
    <Modal
      id={PERMANENTLY_DELETE_REVISION_ID_BLOCK}
      title={'statement-list.permanent-delete-revision-modal.multiple.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.delete',
          disabled: isNullOrUndefined(revisionDeleteSelection),
          onClick: () => {
            if (revisionDeleteSelection === DELETE_STATEMENT_OPTIONS.CURRENT) {
              onDeleteTop();
            } else if (
              revisionDeleteSelection === DELETE_STATEMENT_OPTIONS.ALL
            ) {
              onDeleteAll();
            }
            // do nothing if no option selected
          },
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
    >
      <Radio
        className={`${PERMANENTLY_DELETE_REVISION_BLOCK}__radio`}
        selectedOption={revisionDeleteSelection}
        options={[
          new RadioOption({
            id: `${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-option-latest`,
            label: 'statement-list.delete-statement-modal.latest-title',
            value: DELETE_STATEMENT_OPTIONS.CURRENT,
          }),
        ]}
        onChange={setRevisionDeleteSelection}
        id={`${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-latest`}
        width="100%"
        isValid={true}
        name={`${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-latest`}
        disabled={false}
      />
      <div className={`${PERMANENTLY_DELETE_REVISION_BLOCK}__copy`}>
        <FormattedMessage
          id={'statement-list.delete-statement-modal.latest-copy-permanent'}
        />
      </div>
      <Radio
        className={`${PERMANENTLY_DELETE_REVISION_BLOCK}__radio`}
        selectedOption={revisionDeleteSelection}
        options={[
          new RadioOption({
            id: `${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-option-all`,
            label: 'statement-list.delete-statement-modal.all-revisions-title',
            value: DELETE_STATEMENT_OPTIONS.ALL,
          }),
        ]}
        onChange={setRevisionDeleteSelection}
        id={`${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-all`}
        width="100%"
        isValid={true}
        name={`${PERMANENTLY_DELETE_REVISION_ID_BLOCK}-radio-all`}
        disabled={false}
      />
      <div className={`${PERMANENTLY_DELETE_REVISION_BLOCK}__copy`}>
        <FormattedMessage
          id={
            'statement-list.delete-statement-modal.all-revisions-copy-permanent'
          }
        />
      </div>
    </Modal>
  );
};

PermanentlyDeleteRevisionsModal.propTypes = {
  /** Statement model holding the revisions that can be deleted */
  statementToDelete: PropTypes.instanceOf(Statement).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** Function fired when user chooses to delete most recent */
  onDeleteTop: PropTypes.func.isRequired,
  /** Function fired when user chooses to delete all */
  onDeleteAll: PropTypes.func.isRequired,
};

export default memo(PermanentlyDeleteRevisionsModal);
