export default class RevisionStatusModel {
  id;
  status;
  statusDisplay;
  intlIdentifier;
  constructor({ id, status, statusDisplay, intlIdentifier }) {
    this.id = id;
    this.status = status;
    this.statusDisplay = statusDisplay;
    this.intlIdentifier = intlIdentifier;
  }
}
