import React from 'react';
import { connect } from 'react-redux';
import {
  fetchAllSectionRenderingData,
  fetchAllLeftSectionRenderingData,
  setSectionInView,
  clearSectionInView,
} from 'store/actions/statement-content-actions';
import {
  addToCurrentSectionIds,
  removeFromCurrentSectionIds,
  addToLeftCurrentSectionIds,
  removeFromLeftCurrentSectionIds,
} from 'store/actions/current-section-actions';
import SectionContent from 'components/feature/statement-content-panel/section-content-component';
import {
  setLeftSectionInView,
  clearLeftSectionInView,
} from 'store/actions/left-statement-section-content-actions';
import {
  REACT_LEFT_STATEMENT_SECTION_ID_PREFIX,
  REACT_SECTION_ID_PREFIX,
} from 'constants/feature/statement-content-constants';

export const SectionContentHOC = (props) => {
  const {
    fetchAllLeftSectionRenderingData,
    fetchAllSectionRenderingData,
    leftSideBySideView,
    setSectionInView,
    setLeftSectionInView,
    clearSectionInView,
    clearLeftSectionInView,
    addToLeftCurrentSectionIds,
    addToCurrentSectionIds,
    removeFromLeftCurrentSectionIds,
    removeFromCurrentSectionIds,
  } = props;
  return (
    <SectionContent
      {...props}
      fetchAllSectionRenderingData={
        leftSideBySideView
          ? fetchAllLeftSectionRenderingData
          : fetchAllSectionRenderingData
      }
      setSectionInView={
        leftSideBySideView ? setLeftSectionInView : setSectionInView
      }
      clearSectionInView={
        leftSideBySideView ? clearLeftSectionInView : clearSectionInView
      }
      addToCurrentSectionIds={
        leftSideBySideView ? addToLeftCurrentSectionIds : addToCurrentSectionIds
      }
      removeFromCurrentSectionIds={
        leftSideBySideView
          ? removeFromLeftCurrentSectionIds
          : removeFromCurrentSectionIds
      }
      reactSectionPrefix={
        leftSideBySideView
          ? REACT_LEFT_STATEMENT_SECTION_ID_PREFIX
          : REACT_SECTION_ID_PREFIX
      }
    />
  );
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
  },
  ui: {
    sourceStatementParams,
    statementPage: {
      modes: { blacklineViewMode },
    },
  },
}) => ({
  sourceStatementParams,
  blacklineViewMode,
  selectedProjectId: id,
});

const mapDispatchToProps = {
  fetchAllSectionRenderingData,
  fetchAllLeftSectionRenderingData,
  setSectionInView,
  clearSectionInView,
  setLeftSectionInView,
  clearLeftSectionInView,
  addToCurrentSectionIds,
  removeFromCurrentSectionIds,
  addToLeftCurrentSectionIds,
  removeFromLeftCurrentSectionIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionContentHOC);
