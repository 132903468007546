import React, { memo, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import Tooltip from 'components/common/tool-tip-component';
import { isNullOrUndefined } from 'utils/object-utils';
import { shouldBreakWord } from 'constants/common/multi-line-ellipsis-constants';

const ResponsiveLinesEllipsis = responsiveHOC()(LinesEllipsis);

const ELLIPSIS_BLOCK = 'multi-line-ellipsis';
// this style ensures that long single line single word values still get ellipsis (like a long e-mail in a data-grid column)
const BREAK_LINE_AT_FIRST_OVERFLOW_LETTER = { wordBreak: 'break-all' };

/**
 * Handles wrapping multiple lines of text with overflow to have an ellipsis based
 * on the `maxLines` prop. If wrapping occurs, a tooltip is shown with that data
 */
const MultiLineEllipsis = ({
  text,
  maxLines,
  toolTipID,
  tooltipPosition,
  isNotInternationalized,
  spaceThresholdToBreakWord,
  intl,
  tooltipClassName,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  if (isNullOrUndefined(text)) {
    // if text isn't a string we get type errors in ResponsiveLinesEllipsis
    return null;
  }
  const _displayText = isNotInternationalized
    ? text
    : intl.formattMessage({ id: text });
  return (
    <Tooltip
      id={toolTipID}
      text={text}
      isNotInternationalized={isNotInternationalized}
      position={tooltipPosition}
      active={showTooltip}
      className={tooltipClassName}
    >
      <div className={`${ELLIPSIS_BLOCK}`}>
        <ResponsiveLinesEllipsis
          text={_displayText}
          maxLine={maxLines}
          onReflow={({ clamped }) => {
            setShowTooltip(clamped);
          }}
          style={
            shouldBreakWord(_displayText, spaceThresholdToBreakWord)
              ? BREAK_LINE_AT_FIRST_OVERFLOW_LETTER
              : null
          }
          basedOn="letters"
        />
      </div>
    </Tooltip>
  );
};

MultiLineEllipsis.defaultProps = {
  maxLines: 1,
  isNotInternationalized: false,
};

MultiLineEllipsis.propTypes = {
  /** Text to be evaluated for clamping, if `isInternationalized` is true, this should be an intl ID */
  text: PropTypes.string,
  /** number of lines visible before clamping, defaults to 1 */
  maxLines: PropTypes.number,
  /** classname for tooltip component */
  tooltipClassName: PropTypes.string,
  /** unique ID for tooltip */
  toolTipID: PropTypes.string.isRequired,
  /** indicates position of tooltip, one of `top`, `bottom`, `left`, `right` */
  tooltipPosition: PropTypes.string,
  /** flag for whether text is internationalized */
  isNotInternationalized: PropTypes.bool,
  /** Number passed as 2nd paramter to `shouldBreakWord`, conditionally applies the { word-break: break-all } style
   * for wraping text at first letter overflow based on number of spaces in the word specified by this threshold
   */
  spaceThresholdToBreakWord: PropTypes.number,
};

export default injectIntl(memo(MultiLineEllipsis));
