import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';

import FormItem from 'components/common/form-item-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

const TEXT_AREA_BLOCK = 'text-area';

const TextArea = ({
  className,
  disabled,
  errorText,
  id,
  isValid,
  label,
  maxLength,
  onChange,
  onBlur,
  placeholder,
  tooltip,
  value,
  width,
  intl,
  rows = 10,
  readOnly,
}) => {
  return (
    <FormItem
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      label={label}
      tooltip={tooltip}
      width={width}
    >
      <textarea
        className={
          isValid
            ? classNames(TEXT_AREA_BLOCK, className)
            : classNames(`${TEXT_AREA_BLOCK}__invalid`, className)
        }
        disabled={disabled}
        id={id}
        label={label}
        maxLength={maxLength}
        name={id}
        onChange={(e) => onChange(e.target.value)}
        placeholder={intl.formatMessage({ id: placeholder })}
        rows={rows}
        value={value}
        width={width}
        onBlur={onBlur}
        readOnly={readOnly}
      />
    </FormItem>
  );
};

TextArea.propTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** Unique string id for text area */
  id: PropTypes.string.isRequired,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Maximum text area length */
  maxLength: PropTypes.number,
  /** Function fired on user input return value*/
  onChange: PropTypes.func.isRequired,
  /** Function fired when focus is lost from text area*/
  onBlur: PropTypes.func,
  /** Internationalized sting id */
  placeholder: PropTypes.string,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** Current value of the text area*/
  value: PropTypes.string,
  /** String percentage representing width of component passed to form item*/
  width: PropTypes.string,
  /** Read only boolean */
  readOnly: PropTypes.bool,
};

export default memo(injectIntl(TextArea));
