import { handleActions } from 'redux-actions';
import {
  selectSectionContentPanel,
  deselectSectionContentPanel,
  clearSelectedSectionsContentPanel,
  selectMultipleSectionsContentPanel,
  replaceSelectedSectionsContentPanel,
  clearStatementPageStoreAction,
  deallocateSelectedSectionsContentPanel,
} from 'store/actions/statement-content-actions';
import SelectedSectionMap from 'models/data/selected-section-map-model';

export default handleActions(
  {
    [selectSectionContentPanel]: (selectedSectionMap, { payload }) => {
      return selectedSectionMap.appendSelectedSection(payload);
    },
    [deselectSectionContentPanel]: (selectedSectionMap, { payload }) => {
      return selectedSectionMap.deselectSection(payload);
    },
    [clearSelectedSectionsContentPanel]: (selectedSectionMap) => {
      return selectedSectionMap.clearMap();
    },
    [selectMultipleSectionsContentPanel]: (selectedSectionMap, { payload }) => {
      return selectedSectionMap.appendSelectedSections(payload);
    },
    [replaceSelectedSectionsContentPanel]: (
      selectedSectionMap,
      { payload },
    ) => {
      return selectedSectionMap.replaceWithSections(payload);
    },
    [deallocateSelectedSectionsContentPanel]: (selectedSectionMap) => {
      return selectedSectionMap.deallocateSection();
    },
    [clearStatementPageStoreAction]: (selectedSectionMap, { payload }) => {
      return new SelectedSectionMap();
    },
  },
  new SelectedSectionMap(),
);
