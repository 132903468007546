import { MenuOption } from 'models/utils/common/menu/menu-option-model';

// Backend ENUM ids
export const SCALING_TYPES = {
  ONES: 1,
  THOUSANDS: 2,
  MILLIONS: 3,
  BILLIONS: 4,
  TRILLIONS: 5,
  OTHER: 6,
};

export const SCALING_OPTIONS_MAP = new Map();
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.ONES,
  new MenuOption({
    id: SCALING_TYPES.ONES,
    title: 'element-panel.amount-attribute.scaling.option.ONES',
    value: SCALING_TYPES.ONES,
  })
);
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.THOUSANDS,
  new MenuOption({
    id: SCALING_TYPES.THOUSANDS,
    title: 'element-panel.amount-attribute.scaling.option.THOUSANDS',
    value: SCALING_TYPES.THOUSANDS,
  })
);
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.MILLIONS,
  new MenuOption({
    id: SCALING_TYPES.MILLIONS,
    title: 'element-panel.amount-attribute.scaling.option.MILLIONS',
    value: SCALING_TYPES.MILLIONS,
  })
);
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.BILLIONS,
  new MenuOption({
    id: SCALING_TYPES.BILLIONS,
    title: 'element-panel.amount-attribute.scaling.option.BILLIONS',
    value: SCALING_TYPES.BILLIONS,
  })
);
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.TRILLIONS,
  new MenuOption({
    id: SCALING_TYPES.TRILLIONS,
    title: 'element-panel.amount-attribute.scaling.option.TRILLIONS',
    value: SCALING_TYPES.TRILLIONS,
  })
);
SCALING_OPTIONS_MAP.set(
  SCALING_TYPES.OTHER,
  new MenuOption({
    id: SCALING_TYPES.OTHER,
    title: 'element-panel.amount-attribute.scaling.option.OTHER',
    value: SCALING_TYPES.OTHER,
  })
);

export const SCALING_OPTIONS = Array.from(SCALING_OPTIONS_MAP.values());

// Backend ENUM ids
export const UNIT_TYPES = {
  CURRENCY: 1,
  PERCENTAGE: 2,
  SHARES: 3,
  TEXT: 4,
  OTHER: 5,
};

export const UNIT_OPTIONS_MAP = new Map();
UNIT_OPTIONS_MAP.set(
  UNIT_TYPES.CURRENCY,
  new MenuOption({
    id: UNIT_TYPES.CURRENCY,
    title: 'element-panel.amount-attribute.unit.option.CURRENCY',
    value: UNIT_TYPES.CURRENCY,
  })
);
UNIT_OPTIONS_MAP.set(
  UNIT_TYPES.PERCENTAGE,
  new MenuOption({
    id: UNIT_TYPES.PERCENTAGE,
    title: 'element-panel.amount-attribute.unit.option.PERCENTAGE',
    value: UNIT_TYPES.PERCENTAGE,
  })
);
UNIT_OPTIONS_MAP.set(
  UNIT_TYPES.SHARES,
  new MenuOption({
    id: UNIT_TYPES.SHARES,
    title: 'element-panel.amount-attribute.unit.option.SHARES',
    value: UNIT_TYPES.SHARES,
  })
);
UNIT_OPTIONS_MAP.set(
  UNIT_TYPES.TEXT,
  new MenuOption({
    id: UNIT_TYPES.TEXT,
    title: 'element-panel.amount-attribute.unit.option.TEXT',
    value: UNIT_TYPES.TEXT,
  })
);
UNIT_OPTIONS_MAP.set(
  UNIT_TYPES.OTHER,
  new MenuOption({
    id: UNIT_TYPES.OTHER,
    title: 'element-panel.amount-attribute.unit.option.OTHER',
    value: UNIT_TYPES.OTHER,
  })
);

export const UNIT_OPTIONS = Array.from(UNIT_OPTIONS_MAP.values());
