import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import ComfortLetterEntryWithAttach from '../annotations/comfort-letter-entry/comfort-letter-entry-with-attach-component';
import ComfortLetterList from 'models/api/comfort-letter-list-api-model';
import Button from 'components/common/button-component';
import { ReactComponent as Plus } from 'icons/plus.svg';
import { MODAL_CLOSE_ICON_ID } from 'components/common/modal-component';
import { hasComfortLetterExistInBatchUpdate } from 'utils/statement-content-page-utils';
export const COMFORT_LETTER_SEARCH_BLOCK = 'comfort-letter-search-results';

export const ComfortLetterSearchResults = ({
  comfortLetterList,
  onAddComfortLetter,
  search,
  disabled,
  isOpen,
  onClickOutside,
  onCreate,
  showCreateButton = false,
  selectedComfortLetterList,
}) => {
  const resultRef = useRef();

  useEffect(() => {
    const onMousedownEvent = (event) => {
      if (
        resultRef.current &&
        !resultRef.current.contains(event.target) &&
        event.target.id !== MODAL_CLOSE_ICON_ID
      ) {
        //pass event to callback so that if someone has to restrict the invoke based on event target they can implement it inside callback.
        onClickOutside(event);
      }
    };
    document.addEventListener('mousedown', onMousedownEvent, false);
    return () => {
      document.removeEventListener('mousedown', onMousedownEvent, false);
    };
  }, [onClickOutside, resultRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={COMFORT_LETTER_SEARCH_BLOCK} ref={resultRef}>
      <ConditionalRender dependencies={[comfortLetterList]}>
        {comfortLetterList.hasComfortLetters() ? (
          comfortLetterList.comfortLetters.map((comfortLetter) => (
            <ComfortLetterEntryWithAttach
              key={comfortLetter.comfortLetterId}
              comfortLetter={comfortLetter}
              onAddComfortLetter={() => onAddComfortLetter(comfortLetter)}
              disabled={
                selectedComfortLetterList &&
                hasComfortLetterExistInBatchUpdate(
                  comfortLetter.comfortLetterId,
                  selectedComfortLetterList,
                )
                  ? true
                  : isFunction(disabled)
                  ? disabled(comfortLetter)
                  : disabled
              }
            />
          ))
        ) : (
          <div id={`${COMFORT_LETTER_SEARCH_BLOCK}-no-search-results-id`}>
            <FormattedMessage
              id="element-comfort-letter-panel.attach-section.search.no-results"
              values={{ search }}
            />
          </div>
        )}
        {showCreateButton && (
          <Button.IconButton
            id={`${COMFORT_LETTER_SEARCH_BLOCK}__create-button`}
            className={`${COMFORT_LETTER_SEARCH_BLOCK}__create-button`}
            onClick={onCreate}
            Icon={Plus}
            disabled={comfortLetterList.hasComfortLetters()}
          >
            <FormattedMessage id="common.create" />
          </Button.IconButton>
        )}
      </ConditionalRender>
    </div>
  );
};

ComfortLetterSearchResults.propTypes = {
  /** List of comfort letter search results */
  comfortLetterList: PropTypes.instanceOf(ComfortLetterList).isRequired,
  /** Action fired when the add button is clicked */
  onAddComfortLetter: PropTypes.func.isRequired,
  /** Search  text */
  search: PropTypes.string.isRequired,
  /** boolean or func to disable the button */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  /** Ref to wrapper component */
  wrapperRef: PropTypes.object,
  /** If search results should be rendered */
  isOpen: PropTypes.bool.isRequired,
  /** Function fired when user clicks outside wrapper */
  onClickOutside: PropTypes.func.isRequired,
  /**If create button should be rendered just below search result */
  showCreateButton: PropTypes.bool,
};

export default memo(ComfortLetterSearchResults);
