import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ConditionalRender from 'components/util/conditional-render-component';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import RichTextEditor from 'components/common/rich-text-editor-component';
import TickmarkForm from 'models/form/tickmark-form-model';
import { TICKMARK_TEXT_CHAR_LIMIT } from 'constants/feature/tickmark-constants';
import Tickmark from 'models/data/tickmark-model';
import { connect } from 'react-redux';
import { updateTickmarkRequest } from 'api/tickmark-api';
import { FormattedMessage } from 'react-intl';

const EDIT_TICKMARK_BLOCK = 'edit-tickmark-modal';
const EDIT_TICKMARK_ID_BLOCK = 'edit-tickmark-modal-id';
export const CREATE_TICKMARK_DUPLICATE_ERROR =
  'ValidationException: This tickmark already exists, try attaching it from existing tickmarks';

const EditTickmarkModal = ({
  tickmarkToEdit,
  onCancel,
  onSave,
  revisionId,
}) => {
  const [tickmarkInProgress, setTickmarkInProgress] = useState(
    new TickmarkForm().fromTickmark(tickmarkToEdit),
  );

  const _handleSave = async () => {
    setTickmarkInProgress((t) => t.setLoading());
    try {
      await updateTickmarkRequest({ revisionId, tickmark: tickmarkInProgress });
      onSave();
    } catch (error) {
      if (_isRequestErrorForDuplicate(error)) {
        setTickmarkInProgress((t) =>
          t.setDuplicateErrorLoaded({ duplicate: true }),
        );
      } else {
        setTickmarkInProgress((t) => t.setError(error));
      }
    }
  };

  const _isRequestErrorForDuplicate = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause.includes(
      CREATE_TICKMARK_DUPLICATE_ERROR, // error message from API
    );

  return (
    <Modal
      id={EDIT_TICKMARK_ID_BLOCK}
      title={'edit-tickmark-modal.title'}
      onClose={onCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          onClick: _handleSave,
          disabled:
            tickmarkToEdit.richText === tickmarkInProgress.richText ||
            !tickmarkInProgress.isTickmarkValid(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onCancel,
        })
      }
    >
      <ConditionalRender dependencies={[tickmarkInProgress]}>
        <RichTextEditor
          className={`${EDIT_TICKMARK_BLOCK}__text`}
          errorText={{
            id: 'tickmark-modal.form.text.error.char-count',
            values: { limit: TICKMARK_TEXT_CHAR_LIMIT },
          }}
          id={`${EDIT_TICKMARK_ID_BLOCK}-text`}
          isValid={!tickmarkInProgress.isTextTooLong()}
          onChange={(val) => {
            setTickmarkInProgress((t) => t.setText(val));
            tickmarkInProgress.richText !== val &&
              setTickmarkInProgress((t) =>
                t.setDuplicateErrorLoaded({ duplicate: false }),
              );
          }}
          placeholder={{
            id: 'tickmark-modal.form.text.placeholder',
            values: { limit: TICKMARK_TEXT_CHAR_LIMIT },
          }}
          width={'100%'}
          initContents={
            tickmarkInProgress.richText
              ? tickmarkInProgress.richText
              : tickmarkToEdit.richText
          }
        />
        {tickmarkInProgress.isTickmarkIdDuplicate() && (
          <FormattedMessage
            id="create-tickmark-modal.form.rich-text.error.duplicate"
            values={{
              errorTxt: (...chunks) => (
                <span className="error-text-validation-msg">{chunks}</span>
              ),
            }}
          />
        )}
      </ConditionalRender>
    </Modal>
  );
};
EditTickmarkModal.propTypes = {
  tickmarkToEdit: PropTypes.instanceOf(Tickmark).isRequired,
  /** Current revision id */
  revisionId: PropTypes.number,
  /** func fired to close modal */
  onCancel: PropTypes.func.isRequired,
  /** func fired on save click */
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { EditTickmarkModal };
export default connect(mapStateToProps, null)(EditTickmarkModal);
