import { handleActions } from 'redux-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  setElementSelectModeDefaultAction,
  setElementSelectModeBatchAction,
  setElementSelectModeInternalReferenceAction,
  setElementSelectModeFormulaAction,
  setElementSelectModeRangeAction,
  setElementSelectModeCopyFormulaAction,
  setElementSelectModeBatchWithBannerAction,
  setElementSelectModeSideBySideAction,
} from 'store/actions/modes-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
export default handleActions(
  {
    [setElementSelectModeDefaultAction]: () => {
      return ELEMENT_SELECT_MODES.DEFAULT;
    },
    [setElementSelectModeBatchAction]: () => {
      return ELEMENT_SELECT_MODES.BATCH;
    },
    [setElementSelectModeBatchWithBannerAction]: () => {
      return ELEMENT_SELECT_MODES.BATCH_WITH_BANNER;
    },
    [setElementSelectModeRangeAction]: () => {
      return ELEMENT_SELECT_MODES.RANGE;
    },
    [setElementSelectModeInternalReferenceAction]: () => {
      return ELEMENT_SELECT_MODES.INTERNAL_REFERENCE;
    },
    [setElementSelectModeFormulaAction]: () => {
      return ELEMENT_SELECT_MODES.FORMULA;
    },
    [clearStatementPageStoreAction]: () => {
      return ELEMENT_SELECT_MODES.DEFAULT;
    },
    [setElementSelectModeCopyFormulaAction]: () => {
      return ELEMENT_SELECT_MODES.COPY_FORMULA;
    },
    [setElementSelectModeSideBySideAction]: () => {
      return ELEMENT_SELECT_MODES.SIDE_BY_SIDE;
    },
  },
  ELEMENT_SELECT_MODES.DEFAULT,
);
