import { createAction } from 'redux-actions';

export const setLeftSectionInViewStatementContent = createAction(
  'SET_LEFT_SECTION_IN_VIEW_STATEMENT_CONTENT',
);
export const clearLeftSectionInViewStatementContent = createAction(
  'CLEAR_LEFT_SECTION_IN_VIEW_STATEMENT_CONTENT',
);
export const clearLeftSectionContentWithIdsFromCache = createAction(
  'CLEAR_LEFT_CONTENT_WITH_IDS_FROM_CACHE',
);
export const updateLeftSectionViewedTimestamp = createAction(
  'CACHE_UPDATE_LEFT_SECTION_VIEWED_TIMESTAMP',
);
export const updateLeftPurgedCacheTracker = createAction(
  'UPDATE_LEFT_PURGED_CACHE_TRACKER',
);

/**
 * Adds section to SectionInViewMap and updates it's viewed timestamp in the SectionCacheTracker for left statement of side by side view page
 * @param {int} param.sectionId
 */
export const setLeftSectionInView = ({ sectionId }) => (dispatch) => {
  dispatch(setLeftSectionInViewStatementContent({ sectionId }));
  dispatch(updateLeftSectionViewedTimestamp({ sectionId }));
};

/**
 * Removes a section from the SectionInViewMap for left statement of side by side view page
 *
 * Sometimes the cache will still be too large if the transition between 2 large sections is within the viewport threshold
 * thus every time we clear a seciton in view, we should also check the cache and see if we can purge a section that is no longer
 * in view so we can get below the threshold
 * @param {int} param.sectionId
 */
export const clearLeftSectionInView = ({ sectionId }) => async (
  dispatch,
  getStore,
) => {
  dispatch(clearLeftSectionInViewStatementContent({ sectionId }));
  const {
    leftTracker,
  } = getStore().data.leftStatementContent.leftSectionsCache;
  const _cacheOverThreshold = leftTracker.cacheOverThreshold();
  if (_cacheOverThreshold) {
    dispatch(checkLeftCacheToPurge());
  }
};

//action used for left statement of side by side view page
export const checkLeftCacheToPurge = () => async (dispatch, getStore) => {
  const store = getStore();
  const { leftTracker } = store.data.leftStatementContent.leftSectionsCache;
  const { sectionsInView } = store.ui.statementPage;

  if (leftTracker.cacheOverThreshold()) {
    const {
      newViewedSectionMetricsMap,
      newViewedSectionQueue,
      reducedTotalSize,
      removedSectionIds,
    } = leftTracker.getPurgedCache({ sectionsInView });
    dispatch(
      updateLeftPurgedCacheTracker({
        viewedSectionMetricsMap: newViewedSectionMetricsMap,
        viewedSectionQueue: newViewedSectionQueue,
        totalCacheMemorySize: reducedTotalSize,
      }),
    );
    dispatch(clearLeftSectionContentWithIdsFromCache({ removedSectionIds }));
  }
};
