import { handleActions } from 'redux-actions';
import {
  entityListStatementContentLoading,
  entityListStatementContentLoaded,
  entityListStatementContentError,
} from 'store/actions/statement-content-actions';
import { EntityList } from 'models/api/entity-list-model';

export default handleActions(
  {
    [entityListStatementContentLoading]: (entityList) => {
      return entityList.setLoading();
    },
    [entityListStatementContentLoaded]: (entityList, { payload }) => {
      return entityList.setLoaded(payload);
    },
    [entityListStatementContentError]: (entityList, { payload }) => {
      return entityList.setError(payload);
    },
  },
  new EntityList()
);
