import { Component } from 'react';
import { connect } from 'react-redux';
import ProjectApi from 'models/api/project-api-model';
import Revision from 'models/api/revision-api-model';
import Statement from 'models/data/statement-model';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { SET_ADOBE_NEW_PROJECT_DATA_ACTION } from 'store/middlewares/adobe-analytics-middleware';
import { setDataLayer } from 'utils/adobe-analytics-utils';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

class AdobeTrackerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { intervalId: 0 };
  }

  _isHomePage = () =>
    matchPath(window.location.pathname, {
      path: ROUTE_CONSTANTS.HOME_PAGE,
      strict: true,
      exact: true,
    });

  componentDidUpdate(prevProps, prevState) {
    const { project, statement, revision, SET_ADOBE_NEW_PROJECT_DATA_ACTION } =
      this.props;

    // set data layer for adobe tracking
    setDataLayer({
      pageInfo: {
        // statement data
        statementName: statement ? statement.statementName : '',
        statementID: statement ? statement.id : '',
        statementType: statement ? statement.type : '',
        statementRevision:
          revision && revision.isLoaded ? revision.revisionNumber : '',

        // project data
        projectName: project && project.isLoaded ? project.name : '',
        projectID: project && project.isLoaded ? project.id : '',
      },
    });

    // we want to send tracking request only if project details really changed
    if (
      // (project && !prevProps.project) || -- dont send tracking request if have just landed to home page.
      // Since default project-id is already tracked with newPageView event when home page is loaded.

      this._isHomePage() && // user have ability to switch projects only in home page
      project &&
      prevProps.project &&
      prevProps.project.id &&
      project.id !== prevProps.project.id
    )
      // send data to adobe for tracking
      SET_ADOBE_NEW_PROJECT_DATA_ACTION();
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({
  data: {
    selectedProject: { project },
    selectedStatement: { statement },
    revision,
  },
}) => ({
  project,
  statement,
  revision,
});

const mapDispatchToProps = {
  SET_ADOBE_NEW_PROJECT_DATA_ACTION,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdobeTrackerComponent);

AdobeTrackerComponent.propTypes = {
  // project model for getting project details
  project: PropTypes.instanceOf(ProjectApi),
  // statement model to get statement details
  statement: PropTypes.instanceOf(Statement),
  // revision model to get revision details
  revision: PropTypes.instanceOf(Revision),
  // function to set adobe event for tracking data
  SET_ADOBE_NEW_PROJECT_DATA_ACTION: PropTypes.func.isRequired,
};
