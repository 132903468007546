import BaseModel from 'models/base-model';
import shortid from 'shortid';

import { FORMULA_ROW_TYPES } from 'constants/feature/formula-constants';
/**
 * Abstract class for different FormulaRow types (Number, Element, Operator).
 *
 * Should not be instantied by itself, it is meant to be extended by the above models.
 */
export default class FormulaRow extends BaseModel({
  key: null,
  value: '',
  type: null, //FormulaRowType
  rowError: false,
}) {
  constructor(props) {
    super(props);
    this.key = shortid.generate();
    if (props) {
      this.value = props.value;
    }
  }
  hasRowError() {
    return this.rowError;
  }

  isManual() {
    return this.type === FORMULA_ROW_TYPES.NUMBER;
  }

  isElement() {
    return this.type === FORMULA_ROW_TYPES.ELEMENT;
  }

  isOperator() {
    return this.type === FORMULA_ROW_TYPES.OPERATOR;
  }
}
