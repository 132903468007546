import React from 'react';
import IconButton from 'components/common/icon-button-component';
import {
  scrollElementIntoView,
  scrollSelectorIntoView,
  scrollIntoViewForVirtualizedList,
} from 'utils/scrolling-utils';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { CONTENT_SEARCH_ID_BLOCK } from './statement-nav-content-search-results-component';
import { ReactComponent as ArrowUp } from 'icons/arrow-thick-up-alt.svg';
import { ReactComponent as ArrowDown } from 'icons/arrow-thick-down-alt.svg';
import {
  ELEMENTS_SEARCH_BLOCK,
  ELEMENTS_SEARCH_RESULT_HEIGHT,
} from './elements/statement-nav-elements-search-results-component';

const SEARCH_NAVIGATION_CONTROLS =
  'statement-navigator-search-navigation-controls';

const SEARCH_NAV_ICON_SIZE = 14;

const NEXT_TOOLTIP_PROPS = new TooltipOptions({
  id: `${SEARCH_NAVIGATION_CONTROLS}__next-tooltip`,
  text: 'common.next',
});
const PREV_TOOLTIP_PROPS = new TooltipOptions({
  id: `${SEARCH_NAVIGATION_CONTROLS}__prev-tooltip`,
  text: 'common.previous',
});

const ELEMENTS_VLIST_BLOCK = `${ELEMENTS_SEARCH_BLOCK}-list`;

const StatementNavigatorResultsControls = ({
  onSelectContentResult,
  onSelectElementResult,
  searchResultsContent,
  searchResultsElement,
  selectedTab,
  setContentHighlightSelector,
  selectElementFromElementSearch,
}) => {
  if (selectedTab === STATEMENT_NAV_TABS.content) {
    if (!searchResultsContent.isInitiated()) {
      return null;
    }
    const { occurrences, selectedIndex } = searchResultsContent;
    const hasPrev = selectedIndex > 0;
    const hasNext = occurrences.length > selectedIndex + 1;
    const isContentTab = selectedTab === STATEMENT_NAV_TABS.content;
    const onClickPrev = async () => {
      const prevResult = occurrences[selectedIndex - 1];
      if (prevResult) {
        onSelectContentResult(selectedIndex - 1);
        if (isContentTab) {
          const nodeId = `${CONTENT_SEARCH_ID_BLOCK}-${prevResult.id}`;
          document.getElementById(nodeId).scrollIntoView(true);
        }
        const { sectionId, selector } = prevResult;
        setContentHighlightSelector({ sectionId, selector });
        scrollSelectorIntoView(prevResult);
      }
    };
    const onClickNext = async () => {
      const nextResult = occurrences[selectedIndex + 1];
      if (nextResult) {
        onSelectContentResult(selectedIndex + 1);
        if (isContentTab) {
          const nodeId = `${CONTENT_SEARCH_ID_BLOCK}-${nextResult.id}`;
          document.getElementById(nodeId).scrollIntoView(true);
        }
        const { sectionId, selector } = nextResult;
        setContentHighlightSelector({ sectionId, selector });
        scrollSelectorIntoView(nextResult);
      }
    };
    return (
      <>
        <IconButton
          disabled={!hasPrev}
          iconSize={SEARCH_NAV_ICON_SIZE}
          id={PREV_TOOLTIP_PROPS.id}
          tooltip={hasPrev ? PREV_TOOLTIP_PROPS : undefined}
          Icon={ArrowUp}
          onClick={onClickPrev}
        />
        <IconButton
          disabled={!hasNext}
          iconSize={SEARCH_NAV_ICON_SIZE}
          id={NEXT_TOOLTIP_PROPS.id}
          tooltip={hasNext ? NEXT_TOOLTIP_PROPS : undefined}
          Icon={ArrowDown}
          onClick={onClickNext}
        />
      </>
    );
  }

  if (selectedTab === STATEMENT_NAV_TABS.elements) {
    if (!searchResultsElement.isInitiated()) {
      return null;
    }
    const { elements, selectedIndex } = searchResultsElement;
    const hasPrev = selectedIndex > 0;
    const hasNext = elements.length > selectedIndex + 1;
    const onClickPrev = async () => {
      const prevResult = elements[selectedIndex - 1];
      if (prevResult) {
        onSelectElementResult(selectedIndex - 1);
        scrollIntoViewForVirtualizedList(
          ELEMENTS_VLIST_BLOCK,
          ELEMENTS_SEARCH_RESULT_HEIGHT,
          selectedIndex - 1,
        );
        selectElementFromElementSearch({
          elementId: prevResult.id,
        });
        scrollElementIntoView({
          elementId: prevResult.id,
          sectionId: prevResult.sectionId,
        });
      }
    };
    const onClickNext = async () => {
      const nextResult = elements[selectedIndex + 1];
      if (nextResult) {
        onSelectElementResult(selectedIndex + 1);
        scrollIntoViewForVirtualizedList(
          ELEMENTS_VLIST_BLOCK,
          ELEMENTS_SEARCH_RESULT_HEIGHT,
          selectedIndex + 1,
        );
        selectElementFromElementSearch({
          elementId: nextResult.id,
        });
        scrollElementIntoView({
          elementId: nextResult.id,
          sectionId: nextResult.sectionId,
        });
      }
    };
    return (
      <>
        <IconButton
          disabled={!hasPrev}
          iconSize={SEARCH_NAV_ICON_SIZE}
          id={PREV_TOOLTIP_PROPS.id}
          tooltip={hasPrev ? PREV_TOOLTIP_PROPS : undefined}
          Icon={ArrowUp}
          onClick={onClickPrev}
        />
        <IconButton
          disabled={!hasNext}
          iconSize={SEARCH_NAV_ICON_SIZE}
          id={NEXT_TOOLTIP_PROPS.id}
          tooltip={hasNext ? NEXT_TOOLTIP_PROPS : undefined}
          Icon={ArrowDown}
          onClick={onClickNext}
        />
      </>
    );
  }

  return null;
};

export default StatementNavigatorResultsControls;
