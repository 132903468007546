import BaseModel from 'models/base-model';
import SectionCacheMetrics from 'models/data/section-cache-metrics-model';

const CACHE_SIZE_THRESHOLD = 500000; // ~500MB
/**
 * A tracker object for evaluating the cache and ensuring it stays below a reasonable memory threshold
 * @param {int} param.revisionId revision id this cache tracker is valid for
 * @param {int} param.totalCacheMemorySize total memory size of items in cache
 * @param {int[]} param.viewedSectionQueue queue of sections that are present in the cache in the order they've been viewed
 * @param {object} param.viewedSectionMetricsMap map of SectionCacheMetrics by sectionId for any section present in the cache
 */
class SectionCacheTracker extends BaseModel({
  revisionId: null,
  totalCacheMemorySize: 0,
  viewedSectionQueue: [],
  viewedSectionMetricsMap: {}, // { [sectionId]: SectionCacheMetric, ...}
}) {
  /**
   * Reducer function for initializing the cache
   * @param {int} param.revisionId
   */
  init({ revisionId }) {
    return this.merge({
      revisionId,
      viewedSectionQueue: [],
      viewedSectionMetricsMap: {},
    });
  }

  /**
   * Reducer function for initializing a section in the cache with it's tracking metrics
   * @param {int} param.revisionId
   * @param {int} param.sectionMemorySize
   */
  initSection({ sectionId, sectionMemorySize }) {
    const viewedSectionQueue = this._getUpdatedQueue({ sectionId });
    const viewedSectionMetricsMap = this._getMergedMap({
      [sectionId]: new SectionCacheMetrics({ sectionId, sectionMemorySize }),
    });
    return this.merge({
      viewedSectionMetricsMap,
      viewedSectionQueue,
      totalCacheMemorySize: this.totalCacheMemorySize + sectionMemorySize,
    });
  }

  /**
   * Reducer function for updating all values in cache tracker
   * @param {int[]} param.viewedSectionQueue new queue
   * @param {object} param.viewedSectionMetricsMap new map
   * @param {int} param.totalCacheMemorySize new total size
   */
  updateCache({
    viewedSectionQueue,
    viewedSectionMetricsMap,
    totalCacheMemorySize,
  }) {
    return this.merge({
      viewedSectionQueue,
      viewedSectionMetricsMap,
      totalCacheMemorySize,
    });
  }

  /**
   * Returns a queue with the given section id as the most recently viewed (e.g. back of the queue)
   * if the section already had a value in the queue, that old value is sliced out
   * @param {int} param.sectionId
   */
  _getUpdatedQueue({ sectionId }) {
    const sectionExists = this.get(sectionId);
    let newViewedSectionQueue = this.viewedSectionQueue;
    if (sectionExists) {
      for (let index = 0; index < this.viewedSectionQueue.length; index++) {
        // find current section index, remove it, and put it at the back of queue
        if (this.viewedSectionQueue[index] === sectionId) {
          newViewedSectionQueue = [
            ...this.viewedSectionQueue.slice(0, index),
            ...this.viewedSectionQueue.slice(index + 1),
            sectionId,
          ];
          break;
        }
      }
    } else {
      // section doesn't exist in queue, add to back
      newViewedSectionQueue = [...this.viewedSectionQueue, sectionId];
    }
    return newViewedSectionQueue;
  }

  /**
   * Private convenience method for merging the map portion of the model in a reducer
   * @param {object} values [sectionIds] : SectionCacheMetrics models
   */
  _getMergedMap(values) {
    return {
      ...this.viewedSectionMetricsMap,
      ...values,
    };
  }

  /**
   * Returns the SectionCacheMetrics model for provided sectionId
   * @param {int} sectionId
   */
  get(sectionId) {
    return this.viewedSectionMetricsMap[sectionId];
  }

  /**
   * Reducer function for indicating an existing section has just been viewed
   * updates the queue and map
   * @param {int} sectionId
   */
  setSectionTimestamp(sectionId) {
    const viewedSectionQueue = this._getUpdatedQueue({ sectionId });
    const existingSection = this.get(sectionId);
    const viewedSectionMetricsMap = this._getMergedMap({
      [sectionId]: existingSection
        ? existingSection.setTimestamp()
        : new SectionCacheMetrics(),
    });
    return this.merge({
      viewedSectionMetricsMap,
      viewedSectionQueue,
    });
  }

  /**
   * Handles determining which sections should be purged from cache and returns
   * updated values for the queue, map, memory size and array of ids that were removed
   * @param {SectionInViewMap} param.sectionsInView SectionInViewMap from current redux store
   */
  getPurgedCache({ sectionsInView }) {
    let newViewedSectionQueue = [];
    let newViewedSectionMetricsMap = {};
    let removedSectionIds = [];
    let reducedTotalSize = this.totalCacheMemorySize;
    let cacheStillTooLarge = true;

    for (let index = 0; index < this.viewedSectionQueue.length; index++) {
      const sectionId = this.viewedSectionQueue[index];

      const _sectionCurrentlyInView = sectionsInView.has(sectionId);

      if (cacheStillTooLarge && !_sectionCurrentlyInView) {
        // remove section from cache if we need to and caclulate new size
        reducedTotalSize =
          reducedTotalSize - this.get(sectionId).sectionMemorySize;
        removedSectionIds.push(sectionId);
        if (this.wouldCacheBeUnderThreshold(reducedTotalSize)) {
          // indicate if cache would now be below threshold
          cacheStillTooLarge = false;
        }
      } else {
        // otherwise keep section in queue and map
        newViewedSectionQueue.push(sectionId);
        newViewedSectionMetricsMap[sectionId] = this.get(sectionId);
      }
    }
    return {
      newViewedSectionMetricsMap,
      newViewedSectionQueue,
      reducedTotalSize,
      removedSectionIds,
    };
  }

  /**
   * indicates if cache is currently over it's memory threshold
   */
  cacheOverThreshold() {
    return this.totalCacheMemorySize > CACHE_SIZE_THRESHOLD;
  }

  /**
   * indicates if a new total size would be under the cache size threshold
   */
  wouldCacheBeUnderThreshold(newSize) {
    return newSize < CACHE_SIZE_THRESHOLD;
  }
}

export default SectionCacheTracker;
