import BaseModel from 'models/base-model';

export default class AdminModuleMaintenance extends BaseModel({
  apiMaintenanceId: null,
  apiStartTime: null,
  apiEndTime: null,
  apiStartDate: null,
  apEndDate: null,
  apiBannerText: null,
  isActive: false,
  isLoading: true,
  isLoaded: false,
}) {}
