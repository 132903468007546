import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export class TieoutBuildInfo extends ApiModel({
  data: {
    omniaEnabled: null,
    springProfiles: null,
    vstsBuild: null,
    projectVersion: null,
  },
}) {
  get vstsBuild() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.vstsBuild;
    }
    return null;
  }
  get projectVersion() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.projectVersion;
    }
    return null;
  }

  processResponse({ response }) {
    return {
      data: response.data,
    };
  }
}
