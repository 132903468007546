import RolesUtil from 'permissions/utils/roles';
const NotesPermissions = {
  canManageNotes: (projectID) =>
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectID),

  canManageCoeNotesType: (projectID) =>
    RolesUtil.doesUserHaveCoeRoleForProject(projectID) ||
    RolesUtil.doesUserHaveObserverRoleForProject(projectID) ||
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectID),
};

export default NotesPermissions;
