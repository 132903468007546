export const ELEMENT_SELECT_MODES = {
  DEFAULT: {
    id: 0,
    title: 'DEFAULT',
  },
  BATCH: {
    id: 1,
    title: 'BATCH',
  },
  INTERNAL_REFERENCE: {
    id: 2,
    title: 'INTERNAL_REFERENCE',
  },
  FORMULA: {
    id: 3,
    title: 'FORMULA',
  },
  RANGE: {
    id: 4,
    title: 'RANGE',
  },
  COPY_FORMULA: {
    id: 5,
    title: 'COPY_FORMULA',
  },
  BATCH_WITH_BANNER: {
    id: 6,
    title: 'BATCH_BANNER',
  },
  SIDE_BY_SIDE: {
    id: 7,
    title: 'SIDE_BY_SIDE',
  },
};

// useful in PropTypes.oneOf(ELEMENT_SELECT_MODES_ARRAY)
export const ELEMENT_SELECT_MODES_ARRAY = Object.values(ELEMENT_SELECT_MODES);

export const CONTENT_HIGHLIGHT_MODES = {
  DEFAULT: {
    id: 0,
    title: 'DEFAULT',
  },
  NOTES: {
    id: 1,
    title: 'NOTES',
  },
  ADD_ELEMENT: {
    id: 2,
    title: 'ADD_ELEMENT',
  },
  ADD_NAVIGATION: {
    id: 3,
    title: 'ADD_NAVIGATION',
  },
};

export const CONTENT_HIGHLIGHT_MODES_ARRAY = Object.values(
  CONTENT_HIGHLIGHT_MODES,
);
