import BaseModel from 'models/base-model';

export default class AdminModuleTabModel extends BaseModel({
  selectedTab: null,
}) {
  setSelectedTab(selectedTab) {
    return this.merge({
      selectedTab: selectedTab,
    });
  }
}
