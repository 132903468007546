import merge from 'lodash/merge';
import { pathToRegexp } from 'path-to-regexp';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { matchPath } from 'react-router-dom';
import { isNullOrUndefined } from './object-utils';

const ADOBE_APP_NAME = 'Tie-Out';

// Adobe direct call rule queue
let _ADOBE_DCR_QUEUE = [];
// Adobe satellite ready
let _ADOBE_SAT_READY = false;

// Analytics data layer is a window-bound object
window.analyticsDataLayer = {
  pageInfo: {
    appName: ADOBE_APP_NAME,
    pageName: '',
    userID: null,
    projectName: null,
    projectID: null,
    statementName: null,
    statementID: null,
    statementType: null,
    statementRevision: null,
  },
};

const LIST_OF_PAGES_WITH_STATEMENT_DATA = [
  `${ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE}${ROUTE_CONSTANTS.SIDE_BY_SIDE}`,
  ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE,
];

const LIST_OF_PAGES_WITH_PROJECT_DATA = [
  ROUTE_CONSTANTS.HOME_PAGE,
  ROUTE_CONSTANTS.PROJECT_EDIT_PAGE,
  ROUTE_CONSTANTS.SIDE_BY_SIDE,
  ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE,
  ROUTE_CONSTANTS.STATEMENT_EDIT_PAGE,
];

const doesAnalyticsDataObjectContainsProjectInfo = () =>
  window.analyticsDataLayer &&
  window.analyticsDataLayer.pageInfo &&
  window.analyticsDataLayer.pageInfo.projectID &&
  window.analyticsDataLayer.pageInfo.projectName;

const doesAnalyticsDataObjectContainsStatementInfo = () =>
  window.analyticsDataLayer &&
  window.analyticsDataLayer.pageInfo &&
  window.analyticsDataLayer.pageInfo.statementName &&
  window.analyticsDataLayer.pageInfo.statementID &&
  window.analyticsDataLayer.pageInfo.statementType &&
  window.analyticsDataLayer.pageInfo.statementRevision;

const doesPageRequiresProjectInfo = () =>
  LIST_OF_PAGES_WITH_PROJECT_DATA.some((routePath) =>
    matchPath(window.location.pathname, {
      path: routePath,
      strict: true,
      exact: true,
    }),
  );

const doesPageRequiresStatementInfo = () =>
  LIST_OF_PAGES_WITH_STATEMENT_DATA.some((routePath) =>
    matchPath(window.location.pathname, {
      path: routePath,
      strict: true,
      exact: true,
    }),
  );

// Check if Adobe satellite is ready
export function isSatelliteReady() {
  if (_ADOBE_SAT_READY) {
    return true;
  }
  if (window._satellite) {
    if (window._satellite.domReady) {
      window._satellite.domReady(() => {
        _ADOBE_SAT_READY = true;
      });
    } else {
      _ADOBE_SAT_READY = true;
    }
  }
  return _ADOBE_SAT_READY;
}

// Check if required variables are set in analytics data layer
export function isDataLayerReady() {
  const dataLayer = getDataLayer();

  // null user id indicates that data layer is not yet ready
  if (isNullOrUndefined(dataLayer.pageInfo.userID)) return false;

  //project-info related pages not containing project data indicates that data layer is not yet ready
  if (
    doesPageRequiresProjectInfo() &&
    !doesAnalyticsDataObjectContainsProjectInfo()
  )
    return false;

  //statement-info related pages not containing project or statement data indicates that data layer is not yet ready
  if (
    doesPageRequiresStatementInfo() &&
    (!doesAnalyticsDataObjectContainsProjectInfo() ||
      !doesAnalyticsDataObjectContainsStatementInfo())
  )
    return false;
  // else return true
  return true;
}

// Get analytics data layer object
export function getDataLayer() {
  return window.analyticsDataLayer;
}

// Set analytics data layer (deep merge)
export function setDataLayer(dataLayer) {
  merge(window.analyticsDataLayer, dataLayer);
}

// Append direct call rule to end of queue
export function appendDCRQueue(dcr) {
  _ADOBE_DCR_QUEUE.push(dcr);
}

// Get direct call rule queue
export function getDCRQueue() {
  return _ADOBE_DCR_QUEUE;
}

// Set direct call rule queue
export function setDCRQueue(queue) {
  _ADOBE_DCR_QUEUE = queue;
}

// Track direct call rule and add to queue if not ready
export function trackDCR(dcr, addToQueue = true) {
  if (isDataLayerReady() && isSatelliteReady()) {
    dcr.dataLayer && setDataLayer(dcr.dataLayer);
    // console.log('analytics_data:', JSON.stringify(window.analyticsDataLayer));
    window._satellite.track(dcr.ruleName);
  } else if (addToQueue) {
    appendDCRQueue(dcr);
  }
}

// Get user id from email
export function getUserIDFromEmail(email) {
  const matches = email.match(/^([^@]*)@/);
  return matches.length > 1 ? matches[1] : '';
}

// Get page name from route pathname
export function getRoutePageName(pathName) {
  let pageName = '';
  Object.keys(ROUTE_CONSTANTS).some((routeName) => {
    // Check if the route is a relative path (ignores URLs)
    if (ROUTE_CONSTANTS[routeName].startsWith('/')) {
      try {
        // This is somewhat special case to determine side-by-side page.
        // Since route constant for side-by-side is a combination of route constant for STATEMENT_CONTENT_PAGE and SIDE_BY_SIDE.
        // Hence regex for route SIDE_BY_SIDE might not match with pathname.
        if (
          pathName &&
          pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE) &&
          ROUTE_CONSTANTS[routeName] === ROUTE_CONSTANTS.SIDE_BY_SIDE
        ) {
          pageName = routeName;
          return true;
        }

        // Generate regex for route constant. Then use that regex to check if path is valid.
        const re = pathToRegexp(ROUTE_CONSTANTS[routeName]);
        if (re.exec(pathName)) {
          pageName = routeName;
          return true;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return false;
  });
  return pageName;
}
