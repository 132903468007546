import { handleActions } from 'redux-actions';
import {
  createSectionCacheTracker,
  updateSectionViewedTimestamp,
  addSectionToCache,
  updatePurgedCacheTracker,
} from 'store/actions/section-cache-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import SectionCacheTracker from 'models/data/section-cache-tracker-model';

export default handleActions(
  {
    [createSectionCacheTracker]: (tracker, { payload }) => {
      return tracker.init(payload);
    },
    [updateSectionViewedTimestamp]: (tracker, { payload: { sectionId } }) => {
      return tracker.setSectionTimestamp(sectionId);
    },
    [addSectionToCache]: (tracker, { payload }) => {
      return tracker.initSection(payload);
    },
    [updatePurgedCacheTracker]: (tracker, { payload }) => {
      return tracker.updateCache(payload);
    },
    [clearStatementPageStoreAction]: (tracker, { payload }) => {
      return new SectionCacheTracker();
    },
  },
  new SectionCacheTracker(),
);
