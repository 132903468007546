import FileSaver from 'file-saver';
import { getStatementIdAction } from 'store/actions/statement-content-actions';
import { getStore } from 'store/store';

export const downloadFile = (response) => {
  const fileName = response.headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim();
  const contentType = response.headers['content-type'];
  let blob = new Blob([response.data], { type: contentType });
  FileSaver.saveAs(blob, fileName);
};

export const getStatementIdUtil = (statementId) => {
  if (statementId) {
    return statementId;
  }
  const { dispatch } = getStore();
  return dispatch(getStatementIdAction());
};
