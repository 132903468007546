import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ElementDetails from 'models/api/element-details-api-model';
import ConditionalRender from 'components/util/conditional-render-component';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import RichTextEditor from 'components/common/rich-text-editor-component';
import { FormattedMessage } from 'react-intl';

import TickmarkForm from 'models/form/tickmark-form-model';
import { TICKMARK_TEXT_CHAR_LIMIT } from 'constants/feature/tickmark-constants';
const CREATE_TICKMARK_BLOCK = 'create-tickmark-modal';
const CREATE_TICKMARK_ID_BLOCK = 'create-tickmark-modal-id';
const CREATE_TICKMARK_DUPLICATE_ERROR =
  'ValidationException: This tickmark already exists, try attaching it from existing tickmarks';

class CreateTickmarkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickmarkInProgress: new TickmarkForm(),
    };
  }

  componentDidMount() {
    const { elementDetails } = this.props;
    this.setState((state) => ({
      tickmarkInProgress: state.tickmarkInProgress.initFromElement({
        elementDetails,
      }),
    }));
  }

  _handleCreateClick = async () => {
    const { onCreate } = this.props;
    const { tickmarkInProgress } = this.state;
    try {
      this.setState((state) => ({
        tickmarkInProgress: state.tickmarkInProgress.setLoading(),
      }));
      await onCreate({ tickmarkModel: tickmarkInProgress });
    } catch (error) {
      if (this._isRequestErrorForDuplicate(error)) {
        this.setDuplicateTickmarkFlag(true);
      } else {
        this.setState((state) => ({
          tickmarkInProgress: state.tickmarkInProgress.setError(error),
        }));
      }
    }
  };
  _isRequestErrorForDuplicate = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause.includes(
      CREATE_TICKMARK_DUPLICATE_ERROR, // error message from API
    );

  setDuplicateTickmarkFlag = (flag) => {
    this.setState((state) => ({
      tickmarkInProgress: state.tickmarkInProgress.setDuplicateErrorLoaded({
        duplicate: flag,
      }),
    }));
  };

  render() {
    const { onClose } = this.props;
    const { tickmarkInProgress } = this.state;
    return (
      <Modal
        id={CREATE_TICKMARK_ID_BLOCK}
        title={'create-tickmark-modal.title'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.create',
            onClick: this._handleCreateClick,
            disabled: !tickmarkInProgress.isTickmarkValid(),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
      >
        <ConditionalRender dependencies={[tickmarkInProgress]}>
          <RichTextEditor
            className={`${CREATE_TICKMARK_BLOCK}__text`}
            errorText={{
              id: 'tickmark-modal.form.text.error.char-count',
              values: { limit: TICKMARK_TEXT_CHAR_LIMIT },
            }}
            id={`${CREATE_TICKMARK_ID_BLOCK}-text`}
            isValid={!tickmarkInProgress.isTextTooLong()}
            onChange={(val) => {
              this.setState((state) => ({
                tickmarkInProgress: state.tickmarkInProgress.setText(val),
              }));
              this.setDuplicateTickmarkFlag(false);
            }}
            placeholder={{
              id: 'tickmark-modal.form.text.placeholder',
              values: { limit: TICKMARK_TEXT_CHAR_LIMIT },
            }}
            width={'100%'}
          />
        </ConditionalRender>
        {this.state.tickmarkInProgress.isTickmarkIdDuplicate() && (
          <FormattedMessage
            id="create-tickmark-modal.form.rich-text.error.duplicate"
            values={{
              errorTxt: (...chunks) => (
                <span className="error-text-validation-msg">{chunks}</span>
              ),
            }}
          />
        )}
      </Modal>
    );
  }
}
CreateTickmarkModal.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** func fired to close modal */
  onClose: PropTypes.func.isRequired,
  /** func fired on create click */
  onCreate: PropTypes.func.isRequired,
};
export default CreateTickmarkModal;
