export const WITH_DUPLICATES = 1;
export const WITHOUT_DUPLICATES = 2;

export const getRelatedWorkPapers = (internalRef, duplicateFlag) => {
  let workPapers = [];
  internalRef &&
    internalRef.data &&
    internalRef.data.internalReferenceElements &&
    internalRef.data.internalReferenceElements.length > 0 &&
    internalRef.data.internalReferenceElements.forEach(
      (irs) =>
        irs.elementEntity &&
        irs.elementEntity.elementAnnotations &&
        irs.elementEntity.elementAnnotations.workpaperReferenceDto &&
        irs.elementEntity.elementAnnotations.workpaperReferenceDto.length > 0 &&
        irs.elementEntity.elementAnnotations.workpaperReferenceDto.forEach(
          (wps) => {
            if (duplicateFlag === WITH_DUPLICATES) {
              workPapers.push({
                ...wps,
                checked: false,
              });
            } else {
              if (
                workPapers.findIndex(
                  (wp) => wp.referenceNumber === wps.referenceNumber,
                ) === -1
              ) {
                workPapers.push({
                  ...wps,
                  checked: false,
                });
              }
            }
          },
        ),
    );
  return workPapers;
};

export const getRelatedTickmarks = (internalRef, duplicateFlag) => {
  let tickmarks = [];
  internalRef &&
    internalRef.data &&
    internalRef.data.internalReferenceElements &&
    internalRef.data.internalReferenceElements.length > 0 &&
    internalRef.data.internalReferenceElements.forEach(
      (irs) =>
        irs.elementEntity &&
        irs.elementEntity.elementAnnotations &&
        irs.elementEntity.elementAnnotations.tickmarkDtoList &&
        irs.elementEntity.elementAnnotations.tickmarkDtoList.length > 0 &&
        irs.elementEntity.elementAnnotations.tickmarkDtoList.forEach(
          (ticks) => {
            if (duplicateFlag === WITH_DUPLICATES) {
              tickmarks.push({
                ...ticks,
                checked: false,
              });
            } else {
              if (
                tickmarks.findIndex(
                  (tick) => tick.tickmarkId === ticks.tickmarkId,
                ) === -1
              ) {
                tickmarks.push({
                  ...ticks,
                  checked: false,
                });
              }
            }
          },
        ),
    );
  return tickmarks;
};
