import React from 'react';
import ReactDOM from 'react-dom';
import './one-trust-cookies';
import './polyfills';
import './disclosure-analytics';
import './walkme-bootstrap';
import './adobe-analytics-bootstrap';
import 'styles/base-styles.scss';

import App from 'components/app-component';

ReactDOM.render(<App />, document.getElementById('root'));
