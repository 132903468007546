import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const createElementTickmarkRequest = ({ tickmarkModel }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${
      tickmarkModel.revisionId
    }/elements/${tickmarkModel.elementId}/tickmarks`,
    tickmarkModel.toApiFormat(),
  );

export const getElementTickmarksRequest = ({ revisionId, elementId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/tickmarks`,
    /*
    // ordinal asc  - returns the list sorted by ordinal order {a}, {b}, {c}
    // default is created_date desc  - returns the list sorted by elements attached order
    {
      params: {
        sortDirection: 'asc',
        sortBy: 'ordinal',
      },
    },
    */
  );

export const searchTickmarksByRevisionAndElementRequest = ({
  revisionId,
  elementId,
  search,
}) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/tickmark/suggestions`,
    {
      text: search,
    },
  );

export const attachTickmarkToElementRequest = ({
  tickmarkId,
  elementId,
  revisionId,
}) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/tickmarks`,
    {
      tickmarkId,
      elementId,
      revisionId,
    },
  );

export const detachTickmarkFromElementRequest = ({
  tickmarkId,
  elementId,
  revisionId,
}) =>
  withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/tickmarks/${tickmarkId}`,
  );

export const TICKMARK_SORT_BY = {
  ordinal: 'ordinal',
  createdDate: 'created_date',
};
export const getTickmarksForRevisionRequest = ({
  revisionId,
  sortBy = TICKMARK_SORT_BY.ordinal,
  sortDirection = 'asc',
}) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/tickmarks`,
    {
      params: {
        sortBy,
        sortDirection,
      },
    },
  );

export const updateTickmarkRequest = ({ revisionId, tickmark }) =>
  withDefaultContextAsSelectedProject().put(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/tickmarks/${
      tickmark.tickmarkId
    }`,
    tickmark.toEditApiFormat(),
  );

export const createTickmarkRequest = ({ tickmark }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${
      tickmark.revisionId
    }/tickmarks`,
    tickmark.toEditApiFormat(),
  );

export const searchTickmarkByKeywords = ({ search, revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/tickmark/suggestions`,
    {
      params: { searchText: encodeURIComponent(search) },
    },
  );

export const deleteTickmarkRequest = ({ tickmark }) => {
  const { revisionId, tickmarkId } = tickmark;
  return withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/tickmarks/${tickmarkId}`,
  );
};

export const getElementsByTickmark = ({ revisionId, tickmarkId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/tickmark/${tickmarkId}`,
  );
};
