import { handleActions } from 'redux-actions';
import ElementCache from 'models/data/element-cache-model';
import {
  sectionElementsLoading,
  sectionElementsLoaded,
  sectionElementsError,
  clearSectionContentWithIdsFromCache,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import {
  updateMultipleElementsInCacheAction,
  updateElementInCacheAction,
  updateElementCacheFromSocketPayloadAction,
} from 'store/actions/element-cache-actions';

import { elementDetailsLoaded } from 'store/actions/element-panel-actions';
export default handleActions(
  {
    [sectionElementsLoading]: (elementCache, { payload }) => {
      return elementCache.setSectionLoading(payload);
    },
    [sectionElementsLoaded]: (elementCache, { payload }) => {
      return elementCache.setSectionLoaded(payload);
    },
    [sectionElementsError]: (elementCache, { payload }) => {
      return elementCache.setSectionError(payload);
    },
    [clearSectionContentWithIdsFromCache]: (elementCache, { payload }) => {
      return elementCache.clearSections(payload);
    },
    [updateElementInCacheAction]: (elementCache, { payload }) => {
      const { element } = payload;
      return elementCache.updateElement({
        element,
        sectionId: element.sectionId,
      });
    },
    [updateElementCacheFromSocketPayloadAction]: (
      elementCache,
      { payload },
    ) => {
      const elements = payload;
      return elementCache.updateMultipleElements({ elements });
    },
    [updateMultipleElementsInCacheAction]: (elementCache, { payload }) => {
      const { elements } = payload;
      return elementCache.updateMultipleElements({
        elements,
      });
    },
    /** External listeners */
    [clearStatementPageStoreAction]: (elementCache, { payload }) => {
      return new ElementCache();
    },
    [elementDetailsLoaded]: (elementCache, { payload }) => {
      const { response } = payload;
      const {
        data: {
          result: { id, sectionId },
        },
      } = response;
      return elementCache.setElementFromElementDetailsLoaded({
        response,
        elementId: id,
        sectionId,
      });
    },
  },
  new ElementCache(),
);
