import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class Note extends ApiModel({
  data: {
    body: {},
    closedBy: null,
    closedByName: null,
    closedDate: null,
    elementDisplay: null,
    elementId: null,
    insideSibling: null,
    length: null,
    noteDate: null,
    noteId: null,
    noteNumber: null,
    noteOrdinal: null,
    notePriorityType: null,
    noteTaker: null,
    noteTakerName: null,
    noteType: null,
    offset: null,
    pseudoElementId: null,
    replyFlag: null,
    revisionId: null,
    revisionNumber: null,
    sectionId: null,
    selectedText: null,
    selector: null,
    siblingIndex: null,
    workflowStatus: null,
    isNew: null,
    color: null,
  },
}) {
  get noteId() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.noteId;
    }
    return null;
  }

  processResponse({ response }) {
    return {
      data: {
        ...response.data.result,
      },
    };
  }

  setFromNoteListEntry({ note, color }) {
    note.color = color;
    return this.merge({
      data: {
        ...note,
      },
    });
  }

  changeIsNewPropertyFromSelectedNote(payload) {
    return this.mergeData({
      isNew: payload,
    });
  }
}
