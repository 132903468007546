import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
const PANEL_CONTENT_BLOCK = 'panelContent';

/**
 * Body content to be used within a Flyout.
 */
class FlyoutPanelContent extends PureComponent {
  render() {
    const { children, className } = this.props;

    return (
      <div className={classnames([PANEL_CONTENT_BLOCK, className])}>
        {children}
      </div>
    );
  }
}

FlyoutPanelContent.propTypes = {
  /** string classname to append to the className list of the panel content */
  className: PropTypes.string,
};

export default FlyoutPanelContent;
