import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

export const PROJECT_DELETED_OVERLAY_BLOCK = 'project-deleted-overlay';

const ProjectDeletedOverlay = () => {
  return (
    <div className={`${PROJECT_DELETED_OVERLAY_BLOCK}`}>
      <div className={`${PROJECT_DELETED_OVERLAY_BLOCK}__message-container`}>
        <div className={`${PROJECT_DELETED_OVERLAY_BLOCK}__title`}>
          <FormattedMessage id={'project-details.deleted-project.title'} />
        </div>
        <div className={`${PROJECT_DELETED_OVERLAY_BLOCK}__body`}>
          <FormattedMessage id={'project-details.deleted-project.body1'} />
        </div>
        <div className={`${PROJECT_DELETED_OVERLAY_BLOCK}__body`}>
          <FormattedMessage id={'project-details.deleted-project.body2'} />
        </div>
      </div>
    </div>
  );
};

export default memo(ProjectDeletedOverlay);
