import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import {
  UNSELECTED_FILTER,
  IS_MENTIONED_FILTER,
} from 'constants/feature/notes/notes-panel-filter-constants';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';

export const USER_LIST_CHECKMARK_BLOCK = 'user-list-checkmark-block';

export const NotesPanelUserFilter = ({
  showCreatedByUsers,
  className,
  updateUserFilter,
  userFilterCreated,
  userFilterReplied,
  selectedAllUsers,
  notes,
  userFilterMentionedUsers,
  userIdToUserNameMap,
}) => {
  const getInitialUserFilterData = useCallback(() => {
    let filters = {
      usersListNotesCreatedByObj: {},
      usersListNotesRepliedByObj: {},
      usersCreatedAndRepliedAndMentionsObj: {},
      usersTaggedUsers: {},
    };

    for (let index = 0; index < notes.length; index++) {
      const idOfNoteCreator = notes[index].noteTaker;
      const note = notes[index];
      if (!(idOfNoteCreator in filters.usersListNotesCreatedByObj)) {
        filters.usersListNotesCreatedByObj[
          idOfNoteCreator
        ] = getUserNameByUserId(idOfNoteCreator);
      }

      if (!(idOfNoteCreator in filters.usersCreatedAndRepliedAndMentionsObj)) {
        filters.usersCreatedAndRepliedAndMentionsObj[
          idOfNoteCreator
        ] = getUserNameByUserId(idOfNoteCreator);
      }

      if (note.body.taggedUsers) {
        const taggedUsersInNoteBody = note.body.taggedUsers;
        filters.usersTaggedUsers = {
          ...filters.usersTaggedUsers,
          ...taggedUsersInNoteBody,
        };
        filters.usersCreatedAndRepliedAndMentionsObj = {
          ...filters.usersCreatedAndRepliedAndMentionsObj,
          ...taggedUsersInNoteBody,
        };
      }

      const replies = notes[index].body.replies;
      const repliesArray = Object.values(replies);
      for (let j = 0; j < repliesArray.length; j++) {
        const replierId = repliesArray[j].userId;
        const reply = repliesArray[j];

        if (!(replierId in filters.usersListNotesRepliedByObj)) {
          filters.usersListNotesRepliedByObj[replierId] = getUserNameByUserId(
            replierId,
          );
        }

        if (!(replierId in filters.usersCreatedAndRepliedAndMentionsObj)) {
          filters.usersCreatedAndRepliedAndMentionsObj[
            replierId
          ] = getUserNameByUserId(replierId);
        }

        if (reply.taggedUsers) {
          const taggedUsersInReply = reply.taggedUsers;
          filters.usersTaggedUsers = {
            ...filters.usersTaggedUsers,
            ...taggedUsersInReply,
          };
          filters.usersCreatedAndRepliedAndMentionsObj = {
            ...filters.usersCreatedAndRepliedAndMentionsObj,
            ...taggedUsersInReply,
          };
        }
      }
    }

    for (const userId in filters.usersTaggedUsers) {
      if (userId in userIdToUserNameMap) {
        filters.usersTaggedUsers[userId] = userIdToUserNameMap[userId];
      }
    }

    for (const userId in filters.usersCreatedAndRepliedAndMentionsObj) {
      if (userId in userIdToUserNameMap) {
        filters.usersCreatedAndRepliedAndMentionsObj[userId] =
          userIdToUserNameMap[userId];
      }
    }
    return filters;
  }, [notes, userIdToUserNameMap]);

  const createdUsers = useMemo(
    () => getInitialUserFilterData().usersListNotesCreatedByObj,
    [getInitialUserFilterData],
  );

  const repliedUsers = useMemo(
    () => getInitialUserFilterData().usersListNotesRepliedByObj,
    [getInitialUserFilterData],
  );

  const createdAndRepliedUsers = useMemo(
    () => getInitialUserFilterData().usersCreatedAndRepliedAndMentionsObj,
    [getInitialUserFilterData],
  );

  const taggedUsers = useMemo(
    () => getInitialUserFilterData().usersTaggedUsers,
    [getInitialUserFilterData],
  );

  const showUsersByNotesCreated = () => {
    return (
      <>
        {Object.keys(createdUsers).map((noteTaker) => (
          <div
            className={`${USER_LIST_CHECKMARK_BLOCK}__checkbox-container`}
            key={`${noteTaker}-created`}
          >
            <Checkbox
              className={`${USER_LIST_CHECKMARK_BLOCK}__tickmark-checkbox`}
              id={`${USER_LIST_CHECKMARK_BLOCK}-${noteTaker}`}
              isNotIntl={true}
              value={createdUsers[noteTaker]}
              checked={Boolean(userFilterCreated[noteTaker])}
              label={createdUsers[noteTaker].split(' ').join(' ')}
              onChange={(val) =>
                updateUserFilter(val, noteTaker, showCreatedByUsers)
              }
            />
          </div>
        ))}
      </>
    );
  };

  const showUsersByReplied = () => {
    return (
      <>
        {Object.keys(repliedUsers).map((userId) => (
          <div
            className={`${USER_LIST_CHECKMARK_BLOCK}__checkbox-container`}
            key={`${userId}-replied`}
          >
            <Checkbox
              className={`${USER_LIST_CHECKMARK_BLOCK}__tickmark-checkbox`}
              id={`${USER_LIST_CHECKMARK_BLOCK}-${userId}`}
              isNotIntl={true}
              value={repliedUsers[userId]}
              checked={Boolean(userFilterReplied[userId])}
              label={repliedUsers[userId].split(' ').join(' ')}
              onChange={(val) =>
                updateUserFilter(val, userId, showCreatedByUsers)
              }
            />
          </div>
        ))}
      </>
    );
  };

  const showerUsersByCreatedAndReplied = () => {
    return (
      <>
        {Object.keys(createdAndRepliedUsers).map((userId) => (
          <div
            className={`${USER_LIST_CHECKMARK_BLOCK}__checkbox-container`}
            key={`${userId}-created-or-replied`}
          >
            <Checkbox
              className={`${USER_LIST_CHECKMARK_BLOCK}__tickmark-checkbox`}
              id={`${USER_LIST_CHECKMARK_BLOCK}-${userId}`}
              isNotIntl={true}
              value={userId}
              checked={Boolean(selectedAllUsers[userId])}
              label={createdAndRepliedUsers[userId].split(' ').join(' ')}
              onChange={() =>
                updateUserFilter(
                  createdAndRepliedUsers[userId],
                  userId,
                  showCreatedByUsers,
                )
              }
            />
          </div>
        ))}
      </>
    );
  };

  const showAllUsers = () => {
    return (
      <>
        {Object.keys(taggedUsers).map((userId) => (
          <div
            className={`${USER_LIST_CHECKMARK_BLOCK}__checkbox-container`}
            key={`${userId}-tagged-users`}
          >
            <Checkbox
              className={`${USER_LIST_CHECKMARK_BLOCK}__tickmark-checkbox`}
              id={`${USER_LIST_CHECKMARK_BLOCK}-${userId}`}
              isNotIntl={true}
              value={taggedUsers[userId]}
              checked={Boolean(userFilterMentionedUsers[userId])}
              label={taggedUsers[userId].split(' ').join(' ')}
              onChange={(val) =>
                updateUserFilter(val, userId, showCreatedByUsers)
              }
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={className}>
      {showCreatedByUsers === true && showUsersByNotesCreated()}
      {showCreatedByUsers === false && showUsersByReplied()}
      {showCreatedByUsers === IS_MENTIONED_FILTER && showAllUsers()}
      {showCreatedByUsers === UNSELECTED_FILTER &&
        showerUsersByCreatedAndReplied()}
    </div>
  );
};

NotesPanelUserFilter.propTypes = {
  /** boolean value to determine which set of users should be rendered */
  showCreatedByUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Object containing all the users who have created a note */
  userFilterCreated: PropTypes.object,
  /** Object containing all the users who have created a note */
  userFilterReplied: PropTypes.object,
  /** function to update checkbox  */
  updateUserNameCheckBox: PropTypes.func,
  /** classname string for containing the list */
  className: PropTypes.string.isRequired,
  /** array of notes needed to get the list of user names */
  notes: PropTypes.array,
  /** object containing all the users who have tagged in a note */
  userFilterMentionedUsers: PropTypes.object,
  /** object containing a map of the user ids to the user names */
  userIdToUserNameMap: PropTypes.object,
};

export default NotesPanelUserFilter;
