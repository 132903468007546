import { withDefaultContextAsSelectedProject } from './api-default-context';

export const getChecklistDataRequest = ({
  revisionId,
  statementId,
  projectId,
}) => {
  return withDefaultContextAsSelectedProject()
    .get(
      `/tieout/project/${parseInt(
        projectId,
      )}/statement/${statementId}/revisions/${revisionId}/auditCheckList`,
    )
    .then((response) => {
      return response;
    });
};

export const postAuditAnswerRequest = (
  answer,
  revisionId,
  statementId,
  projectId,
) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/project/${parseInt(
      projectId,
    )}/statement/${statementId}/revisions/${revisionId}/auditCheckList`,
    answer,
  );
};

export const postFinalChecklistConfirmation = (
  confirmationRequestBody,
  revisionId,
  statementId,
  projectId,
) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/project/${parseInt(
      projectId,
    )}/statement/${statementId}/revisions/${revisionId}/finalAuditCheckList`,
    confirmationRequestBody,
  );
};
