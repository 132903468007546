import React from 'react';
import ReactDOM from 'react-dom';
import parser from 'html-dom-parser';
import camelCase from 'lodash.camelcase';
import { isNullOrUndefined } from './object-utils';
import ReactTooltip from 'react-tooltip';
import { getStore } from 'store/store';
import { messages } from 'containers/util/intl-provider-container';
import SectionAnchorHOC from 'higher-order-components/section-anchor-hoc-component';
import TieoutElementHOC from 'higher-order-components/tieout-element-hoc-component';
import NoteAnchorHOC from 'higher-order-components/note-anchor-hoc-component';
import shortid from 'shortid'; // used to generate unique react keys for sibling elements in map functions
import {
  STATEMENT_ELEMENT_ID_PREFIX,
  STATEMENT_SECTION_ID_PREFIX,
  STATEMENT_PSEUDO_ELEMENT_ID_PREFIX,
  CONTENT_SEARCH_HIGHLIGHT_CLASS,
} from 'constants/feature/statement-content-constants';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import { ReactComponent as FullWidthIcon } from 'icons/fullscreen-increase.svg';
import { ReactComponent as FixedWidthIcon } from 'icons/fullscreen-decrease.svg';

const ACCEPTED_HTML_TO_REACT_ATTRIBUTES = {
  colspan: 'colSpan',
  rowspan: 'rowSpan',
  style: 'style',
  class: 'className',
  id: 'id',
  start: 'start',
  type: 'type',
  defaultChecked: 'defaultChecked',
  src: 'src',
  // name: 'name' // TODO explore usefulness of name attribute in application
};

/**
 * Converts an html string into a react DOM tree
 * @param {string} param.html
 */
let renderedAnchors = {};

export const getParsedReactSegmentFromHTML = ({
  sectionId,
  html,
  searchTerm,
  leftSideView,
}) => {
  const segmentJsonTree = parser(html);
  renderedAnchors = {};
  return (
    <React.Fragment>
      {_recursivePreProcessSegment({
        sectionId,
        children: segmentJsonTree,
        searchTerm,
        leftSideView,
      })}
    </React.Fragment>
  );
};

// TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
// /**
//  * Converts a json representation of an html DOM into a react DOM tree
//  * @param {string} param.segmentJsonTree
//  */
// export const getParsedReactSegmentJSON = ({ segmentJsonTree }) => {
//   return (
//     <React.Fragment>
//       {_recursivePreProcessSegment({
//         children: segmentJsonTree,
//       })}
//     </React.Fragment>
//   );
// };

/**
 * Turns a JSON object representing an html DOM tree into a react DOM tree
 * @param {Array} param.children array of children node objects to recursively morph to react elements
 */
const _recursivePreProcessSegment = ({
  sectionId,
  children,
  searchTerm,
  leftSideView,
}) => {
  if (isNullOrUndefined(children) || children.length === 0) {
    return [];
  }
  return children.map((node) => {
    if (node.type === 'text') {
      if (!isNullOrUndefined(searchTerm) && typeof searchTerm === 'string') {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const lowerCaseNodeData = node.data.toLowerCase();
        const _containsSearchTerm =
          searchTerm.length > 0 &&
          lowerCaseNodeData.includes(lowerCaseSearchTerm);
        if (_containsSearchTerm) {
          const escapedSearchTerm = searchTerm.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&',
          );
          const updatedHtmlString = node.data.replace(
            new RegExp(escapedSearchTerm, 'gi'), // i as 2nd arg indicates case ignore
            (match) =>
              `<mark class='${CONTENT_SEARCH_HIGHLIGHT_CLASS}'>${match}</mark>`, // we only want to wrap the matched content
          );
          const highlightedHtml = parser(updatedHtmlString);
          return (
            <>
              {_recursivePreProcessSegment({
                sectionId,
                children: highlightedHtml,
                searchTerm,
                leftSideView,
              })}
            </>
          );
        }
        return node.data;
      }
      return node.data;
    }
    if (node.type === 'style') {
      /** Some html we get from the BE comes with their own stylesheet and we want to append this to the head tag so they are displayed as expected */
      const styleSheet = node.children[0].data;
      const reg = '#ato-section-data';
      const styleSheetWithoutId = styleSheet.replaceAll(
        reg,
        '#statement-content-panel-id',
      );

      const style = document.createElement('style');
      style.type = 'text/css';

      document.head.appendChild(style);
      style.appendChild(document.createTextNode(styleSheetWithoutId));
      return EMPTY_STRING;
    } else if (node.type === 'tag') {
      switch (node.name) {
        // skipped elements
        case 'html':
        case 'head':
        case 'meta':
        case 'body': {
          return _recursivePreProcessSegment({
            sectionId,
            children: node.children,
            searchTerm,
            leftSideView,
          });
        }
        // void elements
        case 'hr':
        case 'br':
        case 'img': {
          return _childlessElement({ sectionId, node, searchTerm });
        }
        // elements with text node restrictions
        case 'tr':
        case 'table':
        case 'tbody': {
          return _elementWithOnlyTagChildren({
            node,
            sectionId,
            searchTerm,
            leftSideView,
          });
        }
        // elements that may need to be replaced with react components
        case 'a': {
          if (!('id' in node.attribs)) {
            return (
              <>
                {_recursivePreProcessSegment({
                  sectionId,
                  children: node.children,
                  searchTerm,
                  leftSideView,
                })}
              </>
            );
          }
          const anchorId = node.attribs.id;
          if (anchorId.includes(STATEMENT_SECTION_ID_PREFIX)) {
            const shouldRenderIcon = !renderedAnchors[anchorId];
            renderedAnchors[anchorId] = true;
            return (
              <SectionAnchorHOC
                {..._attributesToProps(node.attribs)}
                sectionId={sectionId}
                key={`${sectionId}-section-anchor-${anchorId}`}
                shouldRenderIcon={shouldRenderIcon}
                leftSideView={leftSideView}
              >
                {_recursivePreProcessSegment({
                  sectionId,
                  children: node.children,
                  searchTerm,
                  leftSideView,
                })}
              </SectionAnchorHOC>
            );
          } else if (anchorId.includes(STATEMENT_ELEMENT_ID_PREFIX)) {
            return (
              <TieoutElementHOC
                {..._attributesToProps(node.attribs)}
                sectionId={sectionId}
                key={`${sectionId}-tieout-element-${anchorId}`}
                leftSideView={leftSideView}
              >
                {_recursivePreProcessSegment({
                  sectionId,
                  children: node.children,
                  searchTerm,
                  leftSideView,
                })}
              </TieoutElementHOC>
            );
          } else {
            /**
             * If there are other types of HTML anchor elements that we need to handle based
             * on their ID from the back-end beyond tieout elements and section anchors, this
             * switch statement needs to be expanded further.
             */
            return _createNormalElement({
              node,
              sectionId,
              searchTerm,
              leftSideView,
            });
          }
        }
        case 'span': {
          const spanId = (node.attribs && node.attribs.id) || null;
          const _isPseudoNoteSpan =
            !isNullOrUndefined(spanId) &&
            spanId.includes(STATEMENT_PSEUDO_ELEMENT_ID_PREFIX);

          if (_isPseudoNoteSpan) {
            const incrementClass = String(node.attribs.class).split([' '])[1]; // 2nd class always includes incremement part at end

            const attribs = _attributesToProps(node.attribs);

            const noteIncrementParts = String(incrementClass).split('_'); // CFTO_PSEUDO_ELEMENT_<id>_<increment>
            const noteIncrement = parseInt(
              noteIncrementParts[noteIncrementParts.length - 1],
            );
            const _isPseudoNoteIncrementSpan =
              _isPseudoNoteSpan && noteIncrement > 0;
            /**
             * Current BE implementation of pseudo notes that span multiple html elements,
             * each of those elements ends up with a pseudo note
             * span.CFTO_PSEUDO_ELEMENT_<pseudoElementId>.CFTO_PSEUDO_ELEMENT_<pseudoElementId>_<spanIncrement>
             * Pseudo note increment spans should not have custom components, only the first one for rendering the icon
             */
            if (!_isPseudoNoteIncrementSpan) {
              return (
                <NoteAnchorHOC
                  {...attribs}
                  sectionId={sectionId}
                  key={`${sectionId}-tieout-note-${spanId}`}
                >
                  {_recursivePreProcessSegment({
                    sectionId,
                    children: node.children,
                    searchTerm,
                    leftSideView,
                  })}
                </NoteAnchorHOC>
              );
            }
          }
          // excluded elements need to be changed to an anchor tag
          if (
            String(node.attribs.class).includes(
              'tieout-element--excluded-element',
            )
          ) {
            node.name = 'a';
          }

          //Inherit font-family style for span from parent p if it does not already have one. defect #1353485.
          if (node.parent && node.parent.name === 'p') {
            if (node.attribs) {
              if ('style' in node.attribs) {
                if (!String(node.attribs.style).includes('font-family'))
                  node.attribs.style = `${node.attribs.style}; font-family: inherit;`;
              } else {
                node.attribs['style'] = 'font-family: inherit;';
              }
            } else {
              node['attribs'] = { style: 'font-family: inherit;' };
            }
          }
          return _createNormalElement({
            node,
            sectionId,
            searchTerm,
            leftSideView,
          });
        }
        case 'mark': {
          const markClass = node.attribs.class;
          const markClasses = !isNullOrUndefined(markClass)
            ? node.attribs.class.split(' ')
            : [];
          const contentSearchClass = markClasses[0];
          if (contentSearchClass === CONTENT_SEARCH_HIGHLIGHT_CLASS) {
            return React.createElement(
              node.name,
              {
                ..._attributesToProps(node.attribs),
                key: shortid.generate(),
              },
              node.children[0].data, // mark tags generated during search will only have one text node child
            );
          }
          return _createNormalElement({
            node,
            sectionId,
            searchTerm,
            leftSideView,
          });
        }
        case 'ul': {
          //if attribs exist we are going to check if it comes with styles
          if (node.attribs) {
            // if style exist in attribs, we concatenate a new style to add the bullets to the ul tag
            if ('style' in node.attribs) {
              node.attribs.style = `${node.attribs.style}; list-style-type: initial; `;
            } else {
              // if styles does not exist, we create it and add the list style
              node.attribs['style'] = 'list-style-type: initial;';
            }
          } else {
            // if attribs does not exist we create and also the style object
            node['attribs'] = { style: 'list-style-type: initial;' };
          }
          return _createNormalElement({
            node,
            sectionId,
            searchTerm,
            leftSideView,
          });
        }
        default: {
          return _createNormalElement({
            node,
            sectionId,
            searchTerm,
            leftSideView,
          });
        }
      }
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log('UNCAUGHT', node);
      }
      return [];
    }
  });
};

const _nodeHasChildren = (node) => {
  return !isNullOrUndefined(node.children) && node.children.length > 0;
};

/**
 * Some elements are supposed to have no children like <img />, <hr />, <br /> etc.
 * This function returns void elements with no children
 * @param {Node} param.node node to render
 */
const _childlessElement = ({ sectionId, node }) => {
  return React.createElement(node.name, {
    key: `${sectionId}-${shortid.generate()}`,
    ..._attributesToPropsEx(node.attribs),
  });
};

/**
 * Some elements like <table>, <tbody> etc. are not meant to have any text nodes between them
 * this function only renders tag children
 * @param {Node} param.node node to render
 */
const _elementWithOnlyTagChildren = ({
  node,
  sectionId,
  searchTerm,
  leftSideView,
}) => {
  let children = null;
  if (_nodeHasChildren(node)) {
    children = [
      ..._recursivePreProcessSegment({
        children: node.children.filter((childNode) => childNode.type === 'tag'),
        sectionId,
        searchTerm,
        leftSideView,
      }),
    ];
  }
  return React.createElement(
    node.name,
    {
      ..._attributesToProps(node.attribs),
      key: `${sectionId}-${shortid.generate()}`,
    },
    children,
  );
};

const _createNormalElement = ({
  node,
  sectionId,
  searchTerm,
  leftSideView,
}) => {
  const elementId = node.attribs.id;
  let children = null;
  if (_nodeHasChildren(node)) {
    children = [
      ..._recursivePreProcessSegment({
        sectionId,
        children: node.children,
        searchTerm,
        leftSideView,
      }),
    ];
  }
  return React.createElement(
    node.name,
    {
      ..._attributesToProps(node.attribs),
      key: elementId
        ? `${sectionId}-element-${elementId}`
        : `${sectionId}-${shortid.generate()}`,
    },
    children,
  );
};

/**
 * Converts prop style string to a react camel cased style object
 * @param {string} plainStyleString
 */
const _getReactInlineStyleObject = (plainStyleString) => {
  if (isNullOrUndefined(plainStyleString) || plainStyleString === '') {
    return undefined;
  }
  const styles = plainStyleString.split(';');
  const inlineStyleMap = {};
  styles.forEach((indvStyle) => {
    const style = indvStyle.split(':');
    inlineStyleMap[camelCase(style[0])] = style[1];
  });
  return inlineStyleMap;
};

/**
 * turns a node.attribs object into the proper camel cased react props values
 * filters unnecessary attributes out of the nodes
 *
 * NOTE: may need to devise a way to convert data-<specific-attribute> attributes to get converted to props or passed through by default
 * reference https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html for more info
 * @param {object} attributes node.attribs object
 */
const _attributesToProps = (attributes) => {
  let props = {};
  // TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
  // if (Array.isArray(attributes)) {
  //   // JSON FROM BACK END
  //   attributes.forEach(attribute => {
  //     Object.keys(attribute).forEach(attKey => {
  //       if (ACCEPTED_HTML_TO_REACT_ATTRIBUTES[attKey]) {
  //         if (attKey === ACCEPTED_HTML_TO_REACT_ATTRIBUTES.style) {
  //           props.style = _getReactInlineStyleObject(attribute[attKey]);
  //         } else {
  //           props[ACCEPTED_HTML_TO_REACT_ATTRIBUTES[attKey]] =
  //             attribute[attKey];
  //         }
  //       } else {
  //         if (process.env.NODE_ENV === 'development') {
  //           // DEV TODO may want to export these to a file so we can consume it more easily
  //           console.log('UNCAUGHT PROPS', attribute);
  //         }
  //       }
  //     });
  //   });
  // } else {
  // JSON FROM HTML DOM PARSER
  props = {
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.colspan]:
      attributes.colspan || undefined,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.rowspan]:
      attributes.rowspan || undefined,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.style]: _getReactInlineStyleObject(
      attributes.style,
    ),
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.class]: attributes.class,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.id]: attributes.id,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.start]: attributes.start || undefined,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.type]: attributes.type || undefined,
    [ACCEPTED_HTML_TO_REACT_ATTRIBUTES.defaultChecked]:
      'checked' in attributes
        ? ACCEPTED_HTML_TO_REACT_ATTRIBUTES.defaultChecked
        : undefined,
  };
  // }

  return props;
};

const _renderTooltip = (target, tooltipOptions, children) => {
  const { id, text } = tooltipOptions;
  const _id = `${id}-tooltip`;
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { 'data-for': _id, 'data-tip': 'data-tip' }),
  );
  const store = getStore();
  const { locale } = store.getState().ui;
  const intlMessages = messages[locale];
  ReactDOM.render(
    <>
      <ReactTooltip
        id={_id}
        key={text}
        place="right"
        effect="solid"
        multiline
        html={false}
      >
        {intlMessages ? intlMessages[text] || text : text}
      </ReactTooltip>
      {childrenWithProps}
    </>,
    target,
  );
};

/**
 * We listen for a "mouseover" event for tables in the statement content panel.
 * We update the position and callback of our table controls for node that most
 * recently fired a "mouseover" event.
 */
export const initTableControls = () => {
  const tableControls = document.getElementById(
    'statement-content-page-id__table-controls',
  );
  if (!tableControls) return;
  const tableSelector = '.statement-content-segment table';
  const nodeList = document.querySelectorAll(tableSelector);
  if (nodeList.length === 0) return;
  const renderControls = (node) => {
    const { top, left } = document
      .getElementById('statement-content-panel-zoom-id')
      .getBoundingClientRect();

    const childTopPosition = node.getBoundingClientRect().top;
    const parentTopPosition = top;
    const topPosition = childTopPosition - parentTopPosition;

    const childLeftPosition = left;
    const parentLeftPosition = document
      .getElementById('statement-content-panel-id')
      .getBoundingClientRect().left;
    const leftPosition = childLeftPosition - parentLeftPosition - 5;

    tableControls.style.cssText = `display: flex; left: ${leftPosition}px; top: ${topPosition}px;`;
    const fixedWidthAttr = node.getAttribute('data-fixed-width');
    const isFullWidthMode =
      fixedWidthAttr === null || fixedWidthAttr === 'false';
    _renderTooltip(
      tableControls,
      {
        id: 'content-table-controls-id',
        text: isFullWidthMode
          ? 'content-table-controls.restore'
          : 'content-table-controls.expand',
      },
      isFullWidthMode ? <FixedWidthIcon /> : <FullWidthIcon />,
    );
  };
  const onMouseEnter = (node) => () => {
    renderControls(node);
    tableControls.onclick = () => {
      const fixedWidthAttr = node.getAttribute('data-fixed-width');
      const isFullWidthMode =
        fixedWidthAttr === null || fixedWidthAttr === 'false';
      if (isFullWidthMode) {
        node.setAttribute('data-fixed-width', 'true');
      } else {
        node.setAttribute('data-fixed-width', 'false');
      }
      renderControls(node);
    };
  };
  for (let i = 0; i < nodeList.length; i++) {
    nodeList[i].onmouseover = onMouseEnter(nodeList[i]);
  }
};

/**
 * This is a workaround for the time being to add src attribute to image and other childless elements.
 * @param {object} attributes node.attribs object
 */
const _attributesToPropsEx = (attributes) => {
  let props = _attributesToProps(attributes);
  if (attributes.src)
    props[ACCEPTED_HTML_TO_REACT_ATTRIBUTES.src] = attributes.src;

  return props;
};
