const hostname = window.TIEOUT.ENV.DA_BASE_URL;

function addStylesheet(href) {
  const element = document.createElement('link');
  Object.assign(element, { rel: 'stylesheet', type: 'text/css', href });
  document.head.appendChild(element);
}

addStylesheet(hostname + '/public/CommonStyle/style.css');
addStylesheet(hostname + '/public/HeaderPlugin/styles.bundle.css');

function addScript(attrs = {}, props = {}) {
  const element = document.createElement('script');
  Object.assign(element, props);
  Object.keys(attrs).forEach((key) => {
    element.setAttribute(key, attrs[key]);
  });
  element.setAttribute('type', 'text/javascript');
  document.body.appendChild(element);
}

const loadMainBundle = () => {
  const src = hostname + '/public/HeaderPlugin/main.bundle.js';
  addScript({ 'data-auth-type': 'Azure', 'data-name': 'HeaderPlugin', src });
};

const loadPolyfillBundle = () => {
  const src = hostname + '/public/HeaderPlugin/polyfills.bundle.js';
  addScript({ src }, { onload: loadMainBundle });
};

addScript(
  { src: hostname + '/public/HeaderPlugin/inline.bundle.js' },
  { onload: loadPolyfillBundle },
);
