import { withDefaultContextAsSelectedProject } from './api-default-context';

export const createNewStatementRequest = ({ project, statement }) => {
  const carryforwardStatement = statement.carryForwardStatementIdsArray.length
    ? statement.carryForwardStatementIdsArray[0]
    : '-1';
  const isPeriodToPeriod = statement.carryForwardStatementIdsArray.length
    ? true
    : false;
  return withDefaultContextAsSelectedProject().post(
    `/tieout/documentupload/${parseInt(
      project.id,
    )}/statements/${carryforwardStatement}`,
    statement.toCreationApiFormat(),
    {
      params: {
        isPeriodToPeriod,
      },
    },
  );
};

export const uploadRevisionRequest = ({ statement, revision }) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/documentupload/${parseInt(statement.clientId)}/statements/${
      statement.id
    }`,
    revision.revisionToApiFormat(statement),
    {
      params: {
        isPeriodToPeriod: false,
      },
    },
  );
};

export const getSelectedStatementRequest = (statementId, projectIdWithGeo) =>
  withDefaultContextAsSelectedProject()
    .get(`/tieout/statements/${statementId}`, {})
    .then((response) => {
      response.data.clientId = projectIdWithGeo;
      return response;
    });

export const editStatementRequest = (statement, project) =>
  withDefaultContextAsSelectedProject()
    .post('/tieout/statements/update', statement.toEditApiFormat())
    .then((response) => {
      response.data.result.clientId = project.data.id;
      return response;
    });

export const getBetaStmtGuide = () =>
  withDefaultContextAsSelectedProject()
    .get('/tieout/statement/betastmtguide', { responseType: 'blob' })
    .then((response) => {
      return response;
    });

export default {
  createNewStatementRequest,
  uploadRevisionRequest,
  getSelectedStatementRequest,
  editStatementRequest,
  getBetaStmtGuide,
};
