import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

const REACT_TOASTIFY_BLOCK = 'Toastify__toast';

const ToastNotification = ({
  children,
  icon,
  intl,
  message,
  title,
  truncateMessage,
}) => {
  const formatText = (text) => {
    if (!text) return '';
    if (typeof text === 'string') return text;
    if (
      typeof text === 'object' &&
      Object.prototype.hasOwnProperty.call(text, 'id')
    )
      return Object.prototype.hasOwnProperty.call(text, 'values')
        ? intl.formatMessage({ id: text.id }, { ...text.values })
        : intl.formatMessage(text);
    return '';
  };
  const formattedMessage = formatText(message);
  const formatteTitle = formatText(title);
  return (
    <>
      <div className={`${REACT_TOASTIFY_BLOCK}-title`}>
        {icon}
        {formatteTitle}
      </div>
      {formattedMessage && (
        <div
          className={classNames(
            `${REACT_TOASTIFY_BLOCK}-message`,
            truncateMessage && `${REACT_TOASTIFY_BLOCK}-text--truncated`,
          )}
        >
          {formattedMessage}
        </div>
      )}
      {children && (
        <div className={`${REACT_TOASTIFY_BLOCK}-children`}>{children}</div>
      )}
    </>
  );
};

ToastNotification.propTypes = {
  /** Additional nodes to render */
  children: PropTypes.node,
  /** Icon to render */
  icon: PropTypes.node,
  /** Intl message or string for the toast message */
  message: PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.string.isRequired }),
    PropTypes.string,
  ]),
  /** Intl message or string for the toast title */
  title: PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.string.isRequired }),
    PropTypes.string,
  ]),
  /** Boolean to truncate message */
  truncateMessage: PropTypes.bool,
};

export default injectIntl(ToastNotification);
