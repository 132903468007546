import { createAction } from 'redux-actions';
import { requestStatementListForNewProject } from 'store/actions/statement-list/statement-list-actions';
import { getSelectedProjectRequest } from 'api/selected-project-api';
import { CURRENT_SELECTED_PROJECT_ID_SESSION_KEY } from 'api/api-client';
import { undeterminedGeoKey } from 'api/api-client';
import { setMergeSelectedProjectWithProjectList } from 'store/actions/project-list-actions';

export const setSelectedProjectId = createAction('SET_SELECTED_PROJECT');

export const selectedProjectLoading = createAction('SELECTED_PROJECT_LOADING');
export const selectedProjectLoaded = createAction('SELECTED_PROJECT_LOADED');
export const selectedProjectError = createAction('SELECTED_PROJECT_ERROR');

export const setSelectedProjectError = createAction(
  'SET_SELECTED_PROJECT_ERROR',
);
export const clearSelectedProject = createAction('CLEAR_SELECTED_PROJECT');
export const setUpdatedLegalHoldForSelectedProject = createAction(
  'UPDATE_LEGAL_HOLD_FOR_SELECTED_PROJECT',
);

/* This function has been created so that when user successfully creates a statement with legal hold option
we update the ProjectDetailsContainer along with ProjectListContainer So that we have the latest details
about the project selected. */
export const updatedSelectedProjectFromIDWithoutLoading =
  ({ projectId, contextKey }) =>
  async (dispatch, getState) => {
    const response = await getSelectedProjectRequest(projectId, contextKey);
    await dispatch(selectedProjectLoaded({ response }));
    const { id, legalHold } = response.data;
    dispatch(
      setMergeSelectedProjectWithProjectList({
        selectedProjectId: id,
        projectDetails: { legalHold },
      }),
    );
  };

export const updateSelectedProject =
  ({ projectId, clientLegalHold }) =>
  async (dispatch, getState) => {
    const { socketHasBeenDisconnected } = getState().sockets.projectSocket;
    try {
      // Check if the socket connection is active.
      if (!socketHasBeenDisconnected) {
        dispatch(
          setUpdatedLegalHoldForSelectedProject({
            projectDetails: { legalHold: clientLegalHold },
          }),
        );
        dispatch(
          setMergeSelectedProjectWithProjectList({
            selectedProjectId: projectId,
            projectDetails: { legalHold: clientLegalHold },
          }),
        );
      } else {
        dispatch(updatedSelectedProjectFromIDWithoutLoading({ projectId }));
      }
    } catch (error) {
      dispatch(selectedProjectError(error));
    }
  };

export const updateSelectedProjectFromID =
  ({ projectId, contextKey }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setSelectedProjectId({ projectId }));
      dispatch(selectedProjectLoading());
      const response = await getSelectedProjectRequest(projectId, contextKey);
      await dispatch(selectedProjectLoaded({ response }));
      const selectedProject = getState().data.selectedProject.project;
      const location = getState().router.location;
      // If on the homepage and the currently selected project is initialized, we get its statements and the associated document processing workflows.
      if (location.pathname === '/' && selectedProject.isInitialized()) {
        dispatch(initNewProjectDetailsActions());
      }
    } catch (error) {
      dispatch(selectedProjectError(error));
    }
  };

export const updateSelectedProjectFromIDIfDoesNotExists =
  ({ projectId, contextKey }) =>
  async (dispatch, getState) => {
    const storeData = getState().data;
    const isSelectedProjectInitialized =
      storeData &&
      storeData.selectedProject &&
      storeData.selectedProject.project &&
      storeData.selectedProject.project.isInitialized();

    try {
      if (!isSelectedProjectInitialized) {
        dispatch(setSelectedProjectId({ projectId }));
        dispatch(selectedProjectLoading());
        const response = await getSelectedProjectRequest(projectId, contextKey);
        await dispatch(selectedProjectLoaded({ response }));
      }
    } catch (error) {
      dispatch(selectedProjectError(error));
    }
  };

export const initNewProjectDetailsActions = () => (dispatch) => {
  dispatch(requestStatementListForNewProject());
};

export const setSelectedProjectFromProjectList =
  ({ projectListEntry }) =>
  (dispatch, getState) => {
    dispatch(setSelectedProjectId({ projectId: projectListEntry.id }));
    dispatch(
      selectedProjectLoaded({
        response: projectListEntry.toProjectApiModel(),
      }),
    );
    // If on the homepage and the currently selected project is initialized, we get its statements and the associated document processing workflows.
    dispatch(initNewProjectDetailsActions());
  };

export const getGeoForSelectedProjectId = () => (dispatch, getState) => {
  const storeData = getState().data;

  let projectGeo;

  // first, try getting selected project from redux store
  let selectedProjectId =
    storeData && storeData.selectedProject && storeData.selectedProject.id;

  // if we are unsuccessful in getting selected project id from redux then try getting it from session storage.
  if (!selectedProjectId)
    selectedProjectId = sessionStorage.getItem(
      CURRENT_SELECTED_PROJECT_ID_SESSION_KEY,
    );

  // Still if we are if we are unsuccessful in getting selected project id from session and redux store, then try getting it from url
  if (
    !selectedProjectId &&
    window.location.pathname.split('/').includes('project')
  )
    selectedProjectId = window.location.pathname.split('/')[2];

  projectGeo = dispatch(getGeoForProjectId(selectedProjectId));

  return projectGeo;
};

export const getGeoForProjectId = (projectID) => (dispatch, getState) => {
  const storeData = getState().data;
  const { currentUser: { data } = {} } = storeData;
  const { projectToGeoMap } = data || {};

  // first try to get project geo from store.
  let projectGeo =
    projectID &&
    projectToGeoMap &&
    (projectToGeoMap.get(`${projectID}`) || {}).geoCode;

  // if we don't have project geo info in store, then try to fetch it from session
  if (!projectGeo) {
    const projectGeoMapFromSession = JSON.parse(
      sessionStorage.getItem('projectToGeoMap'),
    );
    projectGeo =
      projectID &&
      projectGeoMapFromSession &&
      (projectGeoMapFromSession[`${projectID}`] || {}).geoCode;
  }

  if (!projectGeo) {
    console.log('failed to determine geo for project id:' + projectID);
    console.log('projectToGeoMap:', projectToGeoMap);
    console.trace();
    return undeterminedGeoKey;
  }

  return projectGeo;
};
