import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Page from 'components/util/page-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';

import StatementForm from 'models/form/statement-form-model';
import ConditionalRender from 'components/util/conditional-render-component';
import SelectStatementComponent from 'components/feature/statement-creation/select-statement/statement-creation-select-statement-component';
import StatementInfoComponent from 'components/feature/statement-creation/statement-creation-statement-info-component';
import StatementCarryforwardComponent from 'components/feature/statement-creation/statement-creation-carryforward-component';
import ProjectApi from 'models/api/project-api-model';
import { history } from 'store/store';
import StatementCreationCancelModal from 'components/feature/statement-creation/statement-creation-cancel-modal';
import { getSelectedProjectRequest } from 'api/selected-project-api';
import { createNewStatementRequest } from 'api/statement-creation-edit-api';
import { AdditionalConsiderationModal } from 'components/feature/statement-creation/statement-creation-additional-consideration-modal';

const STATEMENT_CREATION_PAGE_BLOCK = 'statement-creation';
export const STATEMENT_CREATION_PAGE_ID_BLOCK = 'statement-creation-id';

class StatementCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelModal: false,
      showAdditionalConsiderationModal: false,
      statementInProgress: new StatementForm({
        isLoaded: true, // init as loaded so user can fill out form
      }),

      project: new ProjectApi(),
    };
    this.unBlockNavigation = null;
    this.isAdditionalConsiderationModalEnabled =
      window.TIEOUT.ENV.FEATURE.ENABLE_CREATE_STATEMENT_ADDITIONAL_CONSIDERATION_MODAL;
    this.shouldBlockNavigation = true; // instance variable for indicating if navigation away from page should be blocked
  }

  componentDidMount() {
    const { projectId } = this.props;

    this._fetchSelectedProjectDetails({ projectId });
    /** Handles logic for blocking navigation with modal prompt and redux clean up */
    this.unBlockNavigation = history.block((toLocation) => {
      if (
        this.shouldBlockNavigation &&
        toLocation &&
        toLocation.pathname !== ROUTE_CONSTANTS.SESSION_TIMEOUT
      ) {
        this.setState({ showCancelModal: true });
        return false;
      }
      return true;
    });
    window.addEventListener('beforeunload', this._blockUrlNavigation);
  }
  componentWillUnmount() {
    this.unBlockNavigation();
    window.removeEventListener('beforeunload', this._blockUrlNavigation);
  }
  _blockUrlNavigation = (e) => {
    if (this.shouldBlockNavigation) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  _toggleAdditionalConsiderationModal = () => {
    this.setState({
      showAdditionalConsiderationModal:
        !this.state.showAdditionalConsiderationModal,
    });
  };

  _onCreateStatement = () => {
    const { statementInProgress, project } = this.state;
    if (this.isAdditionalConsiderationModalEnabled)
      this._toggleAdditionalConsiderationModal();
    else
      this._createNewStatement({
        project: project.getProjectData(),
        statement: statementInProgress,
      });
  };

  _fetchSelectedProjectDetails = async ({ projectId }) => {
    const { selectedProjectLoaded } = this.props;
    this.setState((state) => ({
      project: state.project.setLoading(),
    }));
    try {
      const response = await getSelectedProjectRequest(projectId);
      selectedProjectLoaded({ response });
      this.setState((state) => ({
        project: state.project.setLoaded(response),
      }));
    } catch (error) {
      this.setState((state) => ({
        project: state.project.setError(error),
      }));
    }
  };

  _toggleCancelModal = () => {
    this.setState((state, props) => {
      return {
        showCancelModal: !state.showCancelModal,
      };
    });
  };

  _onCancelAndLeavePage = () => {
    const { push } = this.props;
    this.shouldBlockNavigation = false;
    push(ROUTE_CONSTANTS.HOME_PAGE);
  };

  _setFiles = (files) => {
    this.setState((state) => ({
      statementInProgress: state.statementInProgress.setFile(files),
    }));
  };

  _setFilesError = (rejectedFiles) => {
    this.setState((state) => ({
      statementInProgress:
        state.statementInProgress.setFileError(rejectedFiles),
    }));
  };

  _createNewStatement = async ({ project, statement }) => {
    this.setState((state) => ({
      statementInProgress: state.statementInProgress.setLoading(),
    }));
    try {
      const response = await createNewStatementRequest({
        project,
        statement,
      });
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setLoaded({
          response,
        }),
      }));
      this.shouldBlockNavigation = false;
      this.props.finishStatementCreation({
        project: this.state.project.data,
      });
    } catch (error) {
      this.setState((state) => ({
        statementInProgress: state.statementInProgress.setError(error),
      }));
    }
  };

  render() {
    const { showCancelModal, statementInProgress, project } = this.state;
    return (
      <ConditionalRender dependencies={[project, statementInProgress]}>
        <Page>
          <div className={STATEMENT_CREATION_PAGE_BLOCK}>
            <div className={`${STATEMENT_CREATION_PAGE_BLOCK}__header`}>
              <Button.IconButton
                id={`${STATEMENT_CREATION_PAGE_ID_BLOCK}__back-button`}
                className={`${STATEMENT_CREATION_PAGE_BLOCK}__back-button`}
                type={BUTTON_TYPES.icon}
                Icon={BackButton}
                onClick={() => this._toggleCancelModal()}
              >
                <div className={`${STATEMENT_CREATION_PAGE_BLOCK}__back-title`}>
                  {project.name}
                </div>
              </Button.IconButton>
              <div>
                <h1 className={`${STATEMENT_CREATION_PAGE_BLOCK}__page-title`}>
                  <FormattedMessage
                    id={'statement-create.create-new-statement'}
                  />
                </h1>
              </div>
            </div>
            <div className={`${STATEMENT_CREATION_PAGE_BLOCK}__body`}>
              <div
                className={`${STATEMENT_CREATION_PAGE_BLOCK}__select-statement`}
              >
                <SelectStatementComponent
                  setFiles={this._setFiles}
                  statementInProgress={statementInProgress}
                  setFilesError={this._setFilesError}
                  project={project}
                />
              </div>
              <div
                className={`${STATEMENT_CREATION_PAGE_BLOCK}__statement-info`}
              >
                <StatementInfoComponent
                  statementInProgress={statementInProgress}
                  setStatementName={(name) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setStatementName(name),
                    }))
                  }
                  setLanguageType={(lang) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setLanguageType(lang),
                    }))
                  }
                  setDateFormat={(date) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setDateFormat(date),
                    }))
                  }
                  setNumberFormat={(number) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setNumberFormat(number),
                    }))
                  }
                  setStatementType={(statementType) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setStatementType(
                          statementType,
                        ),
                    }))
                  }
                  setQuarter={(val) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setQuarter(val),
                    }))
                  }
                  setFiscalYear={(year) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setFiscalYear(year),
                    }))
                  }
                  setPeriodEndingDate={(date) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setPeriodEndingDate(date),
                    }))
                  }
                  setAccountingBasis={(option) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setAccountingBasis(option),
                    }))
                  }
                  setRegistrant={(option) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setRegistrant(option),
                    }))
                  }
                  setWrapUpDate={(date) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setWrapUpDate(date),
                    }))
                  }
                  setLegalHold={(checked) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setLegalHold(checked ? 1 : 0),
                    }))
                  }
                  setAutoIdentifyIrs={(checked) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setAutoIdentifyIrs(
                          checked ? 1 : 0,
                        ),
                    }))
                  }
                  setComfortLetter={(checked) =>
                    this.setState((prevState) => ({
                      statementInProgress:
                        prevState.statementInProgress.setComfortLetter(
                          checked ? 1 : 0,
                        ),
                    }))
                  }
                />
              </div>
              <div className={`${STATEMENT_CREATION_PAGE_BLOCK}__carryforward`}>
                <StatementCarryforwardComponent
                  statementInProgress={statementInProgress}
                  selectedProject={project}
                  setCarryForwardStatementCreation={(val) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setCarryForward(val),
                    }))
                  }
                  setCarryForwardDetails={(val) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setCarryForwardDetails(val),
                    }))
                  }
                  setCarryForwardStatementIds={(val) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setCarryForwardStatementIds(
                          val,
                        ),
                    }))
                  }
                  changeCarryForwordProject={(project) =>
                    this.setState((state) => ({
                      statementInProgress:
                        state.statementInProgress.setCarryForwardProject(
                          project,
                        ),
                    }))
                  }
                />
              </div>
            </div>

            <div
              className={`${STATEMENT_CREATION_PAGE_BLOCK}__button-container`}
            >
              <Button
                id={`${STATEMENT_CREATION_PAGE_ID_BLOCK}-statement-cancel-button`}
                className={`${STATEMENT_CREATION_PAGE_BLOCK}__cancel-button`}
                type={BUTTON_TYPES.tertiary}
                onClick={this._toggleCancelModal}
              >
                <FormattedMessage id={'common.cancel'} />
              </Button>
              <Button
                id={`${STATEMENT_CREATION_PAGE_ID_BLOCK}-statement-create-button`}
                className={`${STATEMENT_CREATION_PAGE_BLOCK}__create-button`}
                type={BUTTON_TYPES.primary}
                onClick={this._onCreateStatement}
                disabled={!statementInProgress.isStatementValid()}
              >
                <FormattedMessage id={'common.create'} />
              </Button>
            </div>
          </div>

          {this.isAdditionalConsiderationModalEnabled &&
            this.state.showAdditionalConsiderationModal && (
              <AdditionalConsiderationModal
                onClose={this._toggleAdditionalConsiderationModal}
                onSubmit={() => {
                  this._toggleAdditionalConsiderationModal();
                  this._createNewStatement({
                    project: project.getProjectData(),
                    statement: statementInProgress,
                  });
                }}
              />
            )}

          {showCancelModal ? (
            <StatementCreationCancelModal
              toggleCancelModal={this._toggleCancelModal}
              onCancelAndLeavePage={this._onCancelAndLeavePage}
            />
          ) : null}
        </Page>
      </ConditionalRender>
    );
  }
}

StatementCreation.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** currently selected project id from the route parameters */
  projectId: PropTypes.string.isRequired,
  /** Func that fires action which sets the project on the homepage following statement creation */
  finishStatementCreation: PropTypes.func.isRequired,
  /**Action fired to load selected project details in redux store */
  selectedProjectLoaded: PropTypes.func.isRequired,
};

export default StatementCreation;
