import { withDefaultContextAsHomeGeo } from 'api/api-default-context';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';

export const postMaintenanceBannerRequest = (
  isActive,
  id,
  text,
  startDate,
  endDate,
) =>
  withDefaultContextAsHomeGeo().post('/tieout/maintenance-banner', {
    status: isActive,
    text: text,
    startDate: startDate,
    endDate: endDate,
    id,
  });

//We had an initial design concept where users can edit multiple banners in admin module page.
// Hence, this api was intended for that purpose.
export const getAllMaintenancePageBannerData = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/maintenance-banner/all')
    .then((response) => response.data.result);

export const getCurrentMaintenanceBanner = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/maintenance-banner')
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result[0] &&
        response.data.result[0].text
      )
        return response.data.result[0].text;
      else return EMPTY_STRING;
    });

export const getSummaryOfKeyChanges = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/summarykeyChange')
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result
      )
        return {
          text: response.data.result.text,
          status: response.data.result.status,
        };
      else return null;
    });

export const postSummaryKeyChanges = (text, status) =>
  withDefaultContextAsHomeGeo().post('/tieout/summarykeyChange', {
    text,
    status,
  });

export const postRoleStateRequest = (
  user,
  roleState,
  isRoleUpdate,
  enabledDate,
  disabledDate,
) => {
  const payload = {
    id: user.id,
    admin: user.admin,
    coe: user.coe,
    enabled: user.enabled,
    enabledDate: enabledDate,
    disabledDate: disabledDate,
  };
  if (roleState === 'admin') {
    payload.supportId = isRoleUpdate ? user.supportId : null;
    payload.containers = isRoleUpdate ? user.countries : [];
  }
  return withDefaultContextAsHomeGeo().post(
    `/tieout/user/${roleState}`,
    payload,
    { params: { active: isRoleUpdate } },
  );
};

export const getCountryManagerDetailsByUserId = (userId) => {
  return withDefaultContextAsHomeGeo()
    .get(`/tieout/country-manager/user/${userId}`)
    .then((response) => {
      return response;
    });
};
