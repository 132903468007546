import RolesUtil from 'permissions/utils/roles';
const StatementPermissions = {
  canCreate: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canUploadRevision: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject()
    );
  },
  canChangeContent: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject()
    );
  },
  canEdit: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canManageLegalHold: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canSoftDelete: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canSeeDisplayValueOfModifiedStatement: () => {
    return RolesUtil.doesUserHaveAdminSupportAccess();
  },
  canRestoreSoftDeleted: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canPermanentlyDelete: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHaveAdminSupportAccess()
    );
  },
  canApproveWrapUp: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject()
    );
  },
  canSubmmitForWrapUpApproval: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject()
    );
  },
};
export default StatementPermissions;
