import ApiModel from 'models/api-model';
import ProjectListEntry from 'models/data/project-list-entry-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { GEO_OPTIONS } from 'constants/feature/project-list-header-constants';

export class ProjectList extends ApiModel({
  data: {
    projects: [], // type Project
    omniaEngagementEntities: [],
    selectedGeo: null,
    appliedSearchTerm: '',
  },
  status: null,
}) {
  processResponse({ response }) {
    return {
      data: {
        projects: response.data.result.map((project) => {
          return new ProjectListEntry(project);
        }),
        omniaEngagementEntities: response.data.result.map(
          (project) => project.omniaEngagementEntity,
        ),
        selectedGeo: this.selectedGeo ? this.selectedGeo : GEO_OPTIONS[0],
        appliedSearchTerm:
          this.data.appliedSearchTerm !== '' ? this.data.appliedSearchTerm : '',
      },
      status: response.status,
    };
  }

  get selectedGeo() {
    return this.data.selectedGeo;
  }

  getProjects() {
    if (this.data && this.data.projects) {
      /*Proj that are perm deleted are still available for admin users
      This filters them out of the list*/
      return this.data.projects.filter((proj) =>
        isNullOrUndefined(proj.permanentDeleteDate),
      );
    }
    return [];
  }

  getActiveProjects() {
    if (this.data && this.data.projects) {
      // filter out permanently and soft deleted projects
      return this.data.projects.filter(
        (proj) =>
          isNullOrUndefined(proj.permanentDeleteDate) && proj.active !== 0,
      );
    }
    return [];
  }

  getOmniaEngagements() {
    if (this.data && this.data.projects) {
      return this.data.omniaEngagementEntity;
    }
    return [];
  }

  isAnyEngagementLinked() {
    if (this.hasProjects()) {
      return !this.data.omniaEngagementEntities.some((entry) => {
        return Object.values(entry).some((val) => val === null);
      });
    }
    return false;
  }

  hasProjects() {
    return this.data && this.data.projects && this.data.projects.length > 0;
  }

  getProjectFromList(id) {
    // We might expect project id to be the combination of geo id and project id.
    // Hence it might be a string instead of number.
    // eslint-disable-next-line eqeqeq
    return this.data.projects.find((project) => project.id == id);
  }

  setGeoToFilterProjectList(geoCode) {
    return this.merge({
      data: {
        ...this.data,
        selectedGeo: geoCode,
      },
    });
  }

  setSearchTermToFilterProjectList(searchTerm) {
    return this.merge({
      data: {
        ...this.data,
        appliedSearchTerm: searchTerm,
      },
    });
  }

  clearSearchTermToFilterProjectList() {
    return this.merge({
      data: {
        ...this.data,
        appliedSearchTerm: '',
      },
    });
  }

  mergeSelectedProjectWithProjectList({ selectedProjectId, projectDetails }) {
    const updatedProjectsList = this.data.projects.map((project) => {
      if (parseInt(project.id) === parseInt(selectedProjectId)) {
        return new ProjectListEntry({ ...project, ...projectDetails });
      }
      return new ProjectListEntry({ ...project });
    });

    return this.merge({
      data: {
        ...this.data,
        projects: updatedProjectsList,
      },
    });
  }
}
