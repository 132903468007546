import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as OpenIcon } from 'icons/open-note.svg';
import SummaryNotes from 'models/api/statement-summary-notes-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { FormattedMessage } from 'react-intl';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';

export const STATEMENT_SUMMARY_NOTES_BLOCK = 'statement-summary-notes';

const StatementSummaryNotesComponent = ({
  showNotesPanel,
  summaryNotes,
  shouldShowNotesSummary,
  selectMode,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleFormulaCancelModal,
}) => {
  if (isNullOrUndefined(summaryNotes)) {
    return null;
  }
  return (
    shouldShowNotesSummary && (
      <div className={STATEMENT_SUMMARY_NOTES_BLOCK}>
        <p className={`${STATEMENT_SUMMARY_NOTES_BLOCK}__heading`}>
          <FormattedMessage id="statement-summary.heading.notes" />
        </p>
        <Tooltip
          id={`${STATEMENT_SUMMARY_NOTES_BLOCK}__tooltip`}
          text="statement-summary.notes.label-tooltip"
          position="bottom"
        >
          <button
            className={`${STATEMENT_SUMMARY_NOTES_BLOCK}__button`}
            onClick={() => {
              if (selectMode === ELEMENT_SELECT_MODES.FORMULA.title) {
                openCancelModalAfterModalConfirm(true);
                toggleFormulaCancelModal(true);
              } else if (
                selectMode === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.title
              ) {
                toggleInternalReferenceCancelModal(true);
                openCancelModalAfterModalConfirm(true);
              } else {
                showNotesPanel();
              }
            }}
          >
            <OpenIcon className={`${STATEMENT_SUMMARY_NOTES_BLOCK}__icon`} />
            <div className={`${STATEMENT_SUMMARY_NOTES_BLOCK}__label`}>
              ({summaryNotes.notes})
            </div>
          </button>
        </Tooltip>
      </div>
    )
  );
};

StatementSummaryNotesComponent.propTypes = {
  /** Action fired when button is clicked to open notes panel */
  showNotesPanel: PropTypes.func.isRequired,
  /** The count of notes for the selected revision id */
  summaryNotes: PropTypes.instanceOf(SummaryNotes).isRequired,
  /** indicates if in range or batch selection mode */
  selectMode: PropTypes.string.isRequired,
  /** Action  toggling the IR modal */
  toggleInternalReferenceCancelModal: PropTypes.func.isRequired,
  /** Action to open cancel modal */
  openCancelModalAfterModalConfirm: PropTypes.func.isRequired,
  /** Action toggling the formula cancel modal */
  toggleFormulaCancelModal: PropTypes.func.isRequired,
};

export default StatementSummaryNotesComponent;
