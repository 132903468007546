import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/common/chip-component';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';
import shortid from 'shortid';
import {
  FILTER_STATUS,
  FILTER_TYPE,
  FILTER_PRIORITY,
  FILTER_GHOST,
  FILTER_USER,
  FILTER_HEADING,
  FILTER_CREATED_BY,
  FILTER_REPLIED_BY,
  FILTER_CREATED_OR_REPLIED,
  FILTER_MENTIONED,
} from 'constants/feature/notes-panel-constants';
import {
  IS_MENTIONED_FILTER,
  OBSERVER_TYPE_FILTER,
  UNSELECTED_FILTER,
} from 'constants/feature/notes/notes-panel-filter-constants';
import Tooltip from 'components/common/tool-tip-component';

export const NOTES_FILTER_CHIPS_ID_BLOCK = 'notes-filter-chips-id';
export const NOTES_FILTER_CHIPS_BLOCK = 'notes-filter-chips';

const MAX_CHARACTER_LIMIT = 30;

export const NotesFilterChips = ({
  basedId,
  removeNoteFilter,
  filterChipsFilterObject,
  usersListNotesCreatedBy,
  usersListNotesRepliedBy,
  userListCreatedAndReplied,
  selectedSegments,
  userFilterMentionedUsers,
}) => {
  const _getChipFromArray = ({ valuesArray }) =>
    valuesArray.map(({ text, intlId }) => {
      if (!isNullOrUndefined(intlId)) {
        return <FormattedMessage key={shortid.generate()} id={intlId} />;
      }
      if (text.length > MAX_CHARACTER_LIMIT) {
        return (
          <span className={`${NOTES_FILTER_CHIPS_BLOCK}__ellipsis-item`}>
            <Tooltip
              id={`${NOTES_FILTER_CHIPS_ID_BLOCK}-${text}`}
              position="top"
              text={text}
              isNotInternationalized={true}
            >
              <span>{text.slice(0, MAX_CHARACTER_LIMIT)}...</span>
            </Tooltip>
          </span>
        );
      }
      return <span>{text}</span>;
    });

  const getORedChipTextArray = ({ valuesArray, areValuesIntl }) => {
    if (!Array.isArray(valuesArray) || valuesArray.length === 0) {
      return null;
    }
    const chipTextArray = [];
    valuesArray.forEach((value, i) => {
      if (i > 0) {
        chipTextArray.push({
          intlId: 'notes-panel.filter-chip.logic.or',
        });
      }
      if (areValuesIntl) {
        chipTextArray.push({
          intlId: value,
        });
      } else {
        chipTextArray.push({ text: value });
      }
    });
    return chipTextArray;
  };

  const _getChipTextForMentions = ({ valuesArray, areValuesIntl }) => {
    if (!Array.isArray(valuesArray) || valuesArray.length === 0) {
      return null;
    }
    const chipTextArray = [];
    valuesArray.forEach((value, i) => {
      if (i > 0) {
        chipTextArray.unshift({
          intlId: 'notes-panel.filter-chip.logic.or',
        });
      }
      if (areValuesIntl) {
        chipTextArray.unshift({
          intlId: value,
        });
      } else {
        chipTextArray.unshift({ text: value });
      }
    });
    return chipTextArray;
  };

  const getUserNameChipText = (selectedUsers = {}) => {
    const selectedUser = [];
    let selectedUsersArray = [];

    for (const user in selectedUsers) {
      if (selectedUsers[user]) {
        selectedUsersArray.push(selectedUsers[user].split(' ').join(' '));
      }
    }

    if (filterChipsFilterObject.user === UNSELECTED_FILTER) {
      selectedUser.push(
        'notes-panel.filter-chip.is-mentioned-created-or-replied',
      );
      if (
        filterChipsFilterObject.user === UNSELECTED_FILTER &&
        selectedUsersArray.length > 0
      ) {
        return [
          {
            intlId: 'notes-panel.filter-chip.is-mentioned-created-or-replied',
          },
          ...getORedChipTextArray({
            valuesArray: selectedUsersArray,
            areValuesIntl: false,
          }),
        ];
      }
    } else if (filterChipsFilterObject.user === true) {
      selectedUser.push('notes-panel.filter-chip.created-by');
      if (
        filterChipsFilterObject.user === true &&
        selectedUsersArray.length > 0
      ) {
        return [
          {
            intlId: 'notes-panel.filter-chip.created-by',
          },
          ...getORedChipTextArray({
            valuesArray: selectedUsersArray,
            areValuesIntl: false,
          }),
        ];
      }
    } else if (filterChipsFilterObject.user === false) {
      selectedUser.push('notes-panel.filter-chip.replied-by');
      if (
        filterChipsFilterObject.user === false &&
        selectedUsersArray.length > 0
      ) {
        return [
          {
            intlId: 'notes-panel.filter-chip.replied-by',
          },
          ...getORedChipTextArray({
            valuesArray: selectedUsersArray,
            areValuesIntl: false,
          }),
        ];
      }
    } else if (filterChipsFilterObject.user === IS_MENTIONED_FILTER) {
      selectedUser.push('notes-panel.filter-chip.is-mentioned');

      if (
        filterChipsFilterObject.user === IS_MENTIONED_FILTER &&
        selectedUsersArray.length > 0
      ) {
        return [
          ..._getChipTextForMentions({
            valuesArray: selectedUsersArray,
            areValuesIntl: false,
          }),
          {
            intlId: 'notes-panel.filter-chip.is-mentioned.suffix',
          },
        ];
      }
    }

    return getORedChipTextArray({
      valuesArray: selectedUser,
      areValuesIntl: true,
    });
  };

  const getStatementSegmentsChipText = (selectedSegments) => {
    if (Object.keys(selectedSegments).length > 0) {
      return [
        {
          intlId: 'notes-panel.filter-chip.heading-suffix',
        },
        ...getORedChipTextArray({
          valuesArray: Object.values(selectedSegments),
          areValuesIntl: false,
        }),
      ];
    }

    return getORedChipTextArray({
      valuesArray: [],
      areValuesIntl: true,
    });
  };

  const userNameChipText = getUserNameChipText(usersListNotesCreatedBy);
  const userNameChipTextReplies = getUserNameChipText(usersListNotesRepliedBy);
  const selectedSegmentsText = getStatementSegmentsChipText(selectedSegments);
  const userNameCreatedAndRepliedtext = getUserNameChipText(
    userListCreatedAndReplied,
  );
  const userNameTaggedUsersText = getUserNameChipText(userFilterMentionedUsers);

  return (
    <div className={NOTES_FILTER_CHIPS_BLOCK}>
      {filterChipsFilterObject.heading &&
        Object.keys(selectedSegments).length > 0 && (
          <Chip
            id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-statement-segment`}
            onRemove={() => removeNoteFilter(FILTER_HEADING)}
          >
            {_getChipFromArray({ valuesArray: selectedSegmentsText })}
          </Chip>
        )}

      {filterChipsFilterObject.status && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-open-note`}
          onRemove={() => removeNoteFilter(FILTER_STATUS)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.open-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.status === false && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-closed-note`}
          onRemove={() => removeNoteFilter(FILTER_STATUS)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.closed-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.type === true && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-review-note`}
          onRemove={() => removeNoteFilter(FILTER_TYPE)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.review'} />
        </Chip>
      )}

      {filterChipsFilterObject.type === false && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-client-note`}
          onRemove={() => removeNoteFilter(FILTER_TYPE)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.client'} />
        </Chip>
      )}
      {filterChipsFilterObject.type === OBSERVER_TYPE_FILTER && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-observer-note`}
          onRemove={() => removeNoteFilter(FILTER_TYPE)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.observer'} />
        </Chip>
      )}

      {filterChipsFilterObject.priority && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-priority-note`}
          onRemove={() => removeNoteFilter(FILTER_PRIORITY)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.priority-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.priority === false && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-non-priority-note`}
          onRemove={() => removeNoteFilter(FILTER_PRIORITY)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.non-priority-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.ghost && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-ghost-note`}
          onRemove={() => removeNoteFilter(FILTER_GHOST)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.ghost-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.ghost === false && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-non-ghost-note`}
          onRemove={() => removeNoteFilter(FILTER_GHOST)}
        >
          <FormattedMessage id={'notes-panel.filter-chip.non-ghost-note'} />
        </Chip>
      )}

      {filterChipsFilterObject.user === true && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-note-taker-names`}
          onRemove={() => removeNoteFilter(FILTER_USER, FILTER_CREATED_BY)}
        >
          {_getChipFromArray({ valuesArray: userNameChipText })}
        </Chip>
      )}

      {filterChipsFilterObject.user === false && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-note-replier-names`}
          onRemove={() => removeNoteFilter(FILTER_USER, FILTER_REPLIED_BY)}
        >
          {_getChipFromArray({ valuesArray: userNameChipTextReplies })}
        </Chip>
      )}

      {filterChipsFilterObject.user === UNSELECTED_FILTER &&
        Object.keys(userListCreatedAndReplied).length > 0 && (
          <Chip
            id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-note-taker-names`}
            onRemove={() =>
              removeNoteFilter(FILTER_USER, FILTER_CREATED_OR_REPLIED)
            }
          >
            {_getChipFromArray({ valuesArray: userNameCreatedAndRepliedtext })}
          </Chip>
        )}

      {filterChipsFilterObject.user === IS_MENTIONED_FILTER && (
        <Chip
          id={`${basedId}-${NOTES_FILTER_CHIPS_ID_BLOCK}-mentioned-users`}
          onRemove={() => removeNoteFilter(FILTER_USER, FILTER_MENTIONED)}
        >
          {_getChipFromArray({ valuesArray: userNameTaggedUsersText })}
        </Chip>
      )}
    </div>
  );
};

NotesFilterChips.propTypes = {
  /** id from parent component used to give filter chip a unique id */
  basedId: PropTypes.string.isRequired,
  /** function to remove note filter */
  removeNoteFilter: PropTypes.func,
  /** Filter object to determine which filter data to render on the screen */
  filterChipsFilterObject: PropTypes.object.isRequired,
  /** object containing users who have created a note */
  usersListNotesCreatedBy: PropTypes.object,
  /** object containing users who have replied to a note */
  usersListNotesRepliedBy: PropTypes.object,
  /** object containing users who have created, replied or have been tagged to a note */
  userListCreatedAndReplied: PropTypes.object,
  /** object containing users who have been tagged in a note */
  userFilterMentionedUsers: PropTypes.object,
};

export default NotesFilterChips;
