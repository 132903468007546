import RolesUtil from 'permissions/utils/roles';

const WorkpaperRefPermissions = {
  canEditWorkpaperRef: (projectID) =>
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectID),
};

export default WorkpaperRefPermissions;
