import { handleActions } from 'redux-actions';
import {
  clearContentSearchAction,
  setContentSearchAction,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';

const DEFAULT = '';

export default handleActions(
  {
    [setContentSearchAction]: (search, { payload }) => payload,
    [clearContentSearchAction]: () => DEFAULT,
    [clearStatementPageStoreAction]: () => DEFAULT,
  },
  DEFAULT
);
