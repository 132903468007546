import { handleActions } from 'redux-actions';
import ApiError from 'models/utils/api-error-handler-model';

import {
  clearApiErrorDataAction,
  createApiErrorDataAction,
  createErrorStackTraceAction,
  createCatchedErrorJsonAction,
} from 'store/actions/api-error-handler-actions';

export default handleActions(
  {
    [clearApiErrorDataAction]: (apiError) => {
      return apiError.clearErrorData();
    },
    [createApiErrorDataAction]: (apiError, { payload }) => {
      return apiError.updateErrorData(payload);
    },
    [createErrorStackTraceAction]: (apiError, { payload }) => {
      return apiError.updateErrorStackTrace(payload);
    },
    [createCatchedErrorJsonAction]: (apiError, { payload }) => {
      return apiError.updateCatchedErrorJson(payload);
    },
  },
  new ApiError(),
);
