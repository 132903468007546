/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isNullOrUndefined } from 'utils/object-utils';
import moment from 'moment';
import { ReactComponent as LeftArrowIcon } from 'icons/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arrow.svg';
import { ReactComponent as Calendar } from 'icons/calendar.svg';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import FormItem from 'components/common/form-item-component';
import { ReactComponent as UpArrow } from 'icons/chevron-up.svg';
import useOutsideClick from 'components/hooks/useOutsideClick';
import {
  DATE_FORMATS,
  DateFormatRegex,
  button,
  closeButton,
  dateInput,
  arrowIcons,
  DatePicker,
  calendar,
  directionIcon,
  months,
  ARROW_ICON_SIZE,
  CALENDAR_ICON_SIZE,
  CLEAR_ICON_SIZE,
  clrButton,
  weekDays,
} from 'constants/util/date-constants';

const CustomDatePicker = ({
  id,
  format,
  width,
  className,
  disabled,
  errorText,
  isValid,
  label,
  required,
  tooltip,
  onDateChange,
  placeHolder,
  selectedDate,
  shoudlDisablePastDate,
  startDate,
  endDate,
  isFormItemRequired,
}) => {
  const [dateValue, setDateValue] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateFormat, setDateFormat] = useState(DATE_FORMATS.MONTH_DAY_YEAR);
  const [isValidDate, setIsValidDate] = useState(true);
  const [disablePastDate, setDisablePastDate] = useState(false);
  const isDateSelected = dateValue !== '';
  const [showFormItem, setShowFormItem] = useState(true);
  const [dateContainer] = useOutsideClick(() => setShowCalendar(false)); // used to handle outside click

  //On change event method based on the user selecting a date from the calendar with selected date and month as parameters...
  const selectDate = (day, month, year) => {
    let date = moment({ year, month, date: day });
    setDateValue(date.format(dateFormat));
    onDateChange(date);
    toggleCalendar();
  };

  //Toggle method is used to toggle the calendar for date selection...
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  /**this life cycle method is used to update the local variables based on the props passed from the parent component such as
   * Selected date, validating the selected date or entered date & past date disabling property from the parent component */
  useEffect(() => {
    if (!isNullOrUndefined(format)) {
      setDateFormat(format);
    }
    if (!isNullOrUndefined(selectedDate)) {
      let value = moment(selectedDate).format(dateFormat);
      setDateValue(value);
      setCalendarDate(
        new Date(
          new Date(value).getFullYear(),
          new Date(value).getMonth(),
          new Date(value).getDate(),
        ),
      );
    } else {
      setDateValue('');
    }
    setIsValidDate(isValid);
    if (!isNullOrUndefined(shoudlDisablePastDate)) {
      setDisablePastDate(shoudlDisablePastDate);
    }
    if (!isFormItemRequired) {
      setShowFormItem(isFormItemRequired);
    }
  }, [selectedDate, isValid, shoudlDisablePastDate, isFormItemRequired]);

  // resets the current calendar when the user opens the calendar and moves to the next month of the calendar based on selected date values.
  useEffect(() => {
    if (selectedDate && showCalendar) {
      let value = moment(selectedDate.format(dateFormat));
      setCalendarDate(
        new Date(
          new Date(value).getFullYear(),
          new Date(value).getMonth(),
          new Date(value).getDate(),
        ),
      );
    } else if (showCalendar) {
      setCalendarDate(new Date());
    }
  }, [showCalendar]);

  // this generic value is used to get value of current month
  const currentMonth = calendarDate.getMonth() + 1;

  // this generic value is used to get value of current year
  const currentYear = calendarDate.getFullYear();

  // this function is used to check if two dates represent the same day
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  /**  this generic method is used to get the calendar and CSS classnames based on certain conditions such as
   * past dates are enabled/disbaled based on disablePastDate value and
   * disabling the date specified with in the range based on the startDate and endDate values from parent components.
   */
  const refreshCalendar = () => {
    const currentYear = calendarDate.getFullYear();
    const currentMonth = calendarDate.getMonth();
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const startDay = firstDayOfMonth.getDay();

    const calendarDays = [];

    // Fill in the days of the previous month
    for (let i = 0; i < startDay; i++) {
      const prevMonthDays = new Date(currentYear, currentMonth, -i).getDate();
      // Check if the date is in the past
      const dateToCheck = new Date(
        currentYear,
        currentMonth - 1,
        prevMonthDays,
      );
      const isPastDate = disablePastDate ? dateToCheck < currentDate : false;
      // Check if the date is in within range
      const isDateOutOfRange = isDateOutOfRangeValue(
        dateToCheck,
        startDate,
        endDate,
      );
      const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      calendarDays.unshift(
        <span
          key={`prev_${i}`}
          className={classnames('day prev-month ', {
            pastDate: isPastDate,
            dateRange: isDateOutOfRange,
          })}
          onClick={() =>
            !(isPastDate || isDateOutOfRange) &&
            selectDate(
              prevMonthDays,
              currentMonth === 0
                ? prevYear === currentYear - 1
                  ? 11
                  : currentMonth
                : currentMonth - 1,
              prevYear,
            )
          }
        >
          {prevMonthDays}
        </span>,
      );
    }

    // Fill in the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      // Check if the date is in the past
      const dateToCheck = new Date(currentYear, currentMonth, i);
      const isPastDate = disablePastDate
        ? dateToCheck < currentDate && !isSameDay(dateToCheck, currentDate)
        : false;
      // Check if the date is in within range
      const isDateOutOfRange = isDateOutOfRangeValue(
        dateToCheck,
        startDate,
        endDate,
      );
      calendarDays.push(
        <span
          key={`day_${i}`}
          className={classnames('day ', {
            'current-day': i === getStyleForCurrentDate(),
            pastDate: isPastDate,
            dateRange: isDateOutOfRange,
          })}
          onClick={() =>
            !(isPastDate || isDateOutOfRange) &&
            selectDate(i, currentMonth, currentYear)
          }
        >
          {i}
        </span>,
      );
    }

    // Fill in the days of the next month
    const remainingDays = 6 - ((startDay + daysInMonth - 1) % 7);
    for (let i = 1; i <= remainingDays; i++) {
      const dateToCheck = new Date(currentYear, currentMonth + 1, i);
      // Check if the date is in within range
      const isDateOutOfRange = endDate ? dateToCheck > endDate : false;
      const nextYear = currentMonth + 1 === 12 ? currentYear + 1 : currentYear;
      calendarDays.push(
        <span
          key={`next_${i}`}
          className="day next-month"
          onClick={() =>
            !isDateOutOfRange &&
            selectDate(
              i,
              currentMonth + 1 === 12
                ? nextYear === currentYear + 1
                  ? 0
                  : currentMonth
                : currentMonth + 1,
              nextYear,
            )
          }
        >
          {i}
        </span>,
      );
    }

    return calendarDays;
  };

  const isDateOutOfRangeValue = (dateToCheck, startDate, endDate) => {
    return startDate && endDate
      ? dateToCheck < startDate && dateToCheck > endDate
      : false;
  };

  // this method is used to enable the CSS for currentDay with in the calendar.
  const getStyleForCurrentDate = () => {
    if (
      isDateSelected &&
      currentYear === new Date(dateValue).getFullYear() &&
      currentMonth === new Date(dateValue).getMonth() + 1
    ) {
      return new Date(dateValue).getDate();
    } else {
      return 0;
    }
  };

  // this method is used to view the previous month's calendar based on user click on the calendar.
  const prevMonth = () => {
    setCalendarDate((prevDate) => {
      return new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
    });
  };

  // this method is used to view the next/upcoming month's calendar based on user click on the calendar.
  const nextMonth = () => {
    setCalendarDate((prevDate) => {
      return new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
    });
  };

  // this onChange method allows user to enter a date and updates the validates the user entered date is valid or not.
  const onChange = (e) => {
    const date = e.target.value;
    setDateValue(date);

    if (!DateFormatRegex(dateFormat).test(date)) {
      setIsValidDate(false);
      onDateChange(date);
    } else {
      let correctDate = !isNaN(Date.parse(date));
      if (correctDate) {
        setIsValidDate(isValid);
        onDateChange(moment.utc(date));
      } else {
        setIsValidDate(false);
        onDateChange(date);
      }
    }
  };

  // this method is us to clear the selected date or user entered date.
  const clearDate = () => {
    setDateValue('');
    onDateChange(null);
    setIsValidDate(false);
    toggleCalendar();
  };

  const calendarDays = refreshCalendar();

  const dateComponentContent = () => {
    return (
      <div id="dateComponent" ref={dateContainer}>
        <div
          className={classnames(`${DatePicker}`, {
            [`${DatePicker}__errorMessage`]: !isValidDate,
            [`${DatePicker}__disabled`]: disabled,
          })}
          onClick={!disabled && toggleCalendar}
        >
          <button
            className={classnames(`${button}`, {
              [`${button}__errorMessage`]: !isValidDate,
              [`${button}__disabled`]: disabled,
            })}
            onClick={!disabled && toggleCalendar}
          >
            <Calendar
              className={classnames(`${button}__icon`)}
              width={CALENDAR_ICON_SIZE}
              height={CALENDAR_ICON_SIZE}
            />
          </button>
          <input
            type="text"
            id="date-input"
            className={classnames(`${dateInput}`, {
              [`${dateInput}__errorMessage`]: !isValidDate,
              [`${dateInput}__disabled`]: disabled,
            })}
            value={dateValue}
            placeholder={placeHolder}
            disabled={disabled}
            required={required}
            onChange={(e) => onChange(e)}
            onClick={!disabled && toggleCalendar}
          />
          {isDateSelected && (
            <button
              className={classnames(`${closeButton}`, {
                [`${closeButton}__errorMessage`]: !isValid,
                [`${closeButton}__disabled`]: disabled,
              })}
              onClick={() => clearDate()}
            >
              <CloseButton
                className={classnames(`${clrButton}__icon`, [
                  `${DatePicker}__closeButton`,
                ])}
                width={CLEAR_ICON_SIZE}
                height={CLEAR_ICON_SIZE}
              />
            </button>
          )}
        </div>

        {showCalendar && (
          <div id="selectableCalendar" className={'selectedCalendar'}>
            <div className={`${directionIcon}`}>
              <UpArrow
                className={classnames(`${directionIcon}__icon`)}
                width={CALENDAR_ICON_SIZE}
                height={CALENDAR_ICON_SIZE}
              />
            </div>
            <div
              className={classnames(`${calendar}-container`, {
                fitContentWidth: !showFormItem,
              })}
            >
              <div className={classnames(`${calendar}`)}>
                <div className={classnames(`${calendar}-header`)}>
                  <div
                    className={classnames(`${arrowIcons}`)}
                    onClick={prevMonth}
                  >
                    <LeftArrowIcon
                      className={classnames(`${arrowIcons}__leftArrow`)}
                      width={ARROW_ICON_SIZE}
                      height={ARROW_ICON_SIZE}
                    />
                  </div>
                  <span className={classnames(`${calendar}-month-year`)}>
                    {months[currentMonth]} {currentYear}
                  </span>
                  <div
                    className={classnames(`${arrowIcons}`)}
                    onClick={nextMonth}
                  >
                    <RightArrowIcon
                      className={classnames(`${arrowIcons}__rightArrow`)}
                      width={ARROW_ICON_SIZE}
                      height={ARROW_ICON_SIZE}
                    />
                  </div>
                </div>
                <div className={classnames(`${calendar}-weekdays`)}>
                  {weekDays.map((weekDay) => (
                    <span key={weekDay}>{weekDay}</span>
                  ))}
                </div>
                <div className={classnames(`${calendar}-days`)}>
                  {calendarDays.map((dayElement) => dayElement)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {showFormItem ? (
        <FormItem
          className={className}
          disabled={disabled}
          errorText={errorText}
          id={id}
          isValid={isValidDate}
          label={label}
          tooltip={tooltip}
          width={width}
        >
          {dateComponentContent()}
        </FormItem>
      ) : (
        <>{dateComponentContent()}</>
      )}
    </>
  );
};

CustomDatePicker.defaultProps = {
  format: DATE_FORMATS.MONTH_DAY_YEAR,
  width: '25%',
  disabled: false,
  errorText: false,
  isValid: true,
  required: false,
  placeHolder: DATE_FORMATS.MONTH_DAY_YEAR,
  selectedDate: '',
  isReadOnly: false,
  isFormItemRequired: true,
};

CustomDatePicker.PropsTypes = {
  /** Unique string id for date picker */
  id: PropTypes.string,
  /** String representing that format of date that need to selected */
  format: PropTypes.string,
  /** String percentage representing width of component passed to form item */
  width: PropTypes.string,
  /** String custom class */
  className: PropTypes.string,
  /** String error copy passed to form item */
  disabled: PropTypes.bool,
  /** String error copy passed to form item */
  errorText: PropTypes.string,
  /** Boolean representing if dateComponent output is valid */
  isValid: PropTypes.bool,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Booledn representing if dateComponent field as mandatory */
  required: PropTypes.bool,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.string,
  /** Function fired when date is changed. return moment object */
  onDateChange: PropTypes.func.isRequired,
  /** string representing the value that needs to be displayed with the date field */
  placeHolder: PropTypes.string,
  /** Moment object representing date selected */
  selectedDate: PropTypes.string,
  /** Boolean representing if past dates to be disable */
  shoudlDisablePastDate: PropTypes.bool,
  /** string in new Date(values) format representing to startDate which allows users to selection */
  startDate: PropTypes.string,
  /** string in new Date(values) format representing to endDate which allows users to selection  */
  endDate: PropTypes.string,
  /** Boolean representing whether formItem element is required or not by default it should true and false is used only for other re-usable components */
  isFormItemRequired: PropTypes.bool,
};

export default CustomDatePicker;
