import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DataGridColumn, {
  DataGridKebabColumn,
  DataGridDropdownColumn,
  DataGridCheckboxColumn,
  DataGridIconColumn,
} from 'models/utils/common/data-grid/data-grid-column-model';
import { DataGridSort } from 'models/utils/common/data-grid/data-grid-sort-model.jsx';
import { DataGridDropdown } from 'models/utils/common/data-grid/data-grid-dropdown-options';
import ConditionalRender from 'components/util/conditional-render-component';
import DataGridData, {
  DataGridDataApi,
} from 'models/utils/common/data-grid/data-grid-data-model';
import { DataGridRow } from './_data-grid-row.component';
import { DataGridColumnsHeader } from './_data-grid-columns-header.component';
import { DataGridFilter } from 'models/utils/common/data-grid/data-grid-filter-model';
import DataGridConstants from 'constants/common/data-grid-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import VirtualizedList from 'components/common/virtualized-list-component';

const DATA_GRID_BLOCK = 'data-grid';

const { DATA_GRID_ROW_HEIGHT, ROWS_AMOUNT } = DataGridConstants;

const StaticOrDynamicWrapper = ({ dataModel, children }) => {
  if (dataModel instanceof DataGridDataApi) {
    return (
      <ConditionalRender dependencies={[dataModel.apiModel]}>
        {children}
      </ConditionalRender>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

class DataGrid extends React.Component {
  _handleRowSelect = (rowDataId) => {
    const { selectedRows, onRowCheckboxSelect, canSelectMoreThenOneRow } =
      this.props;
    if (canSelectMoreThenOneRow) {
      onRowCheckboxSelect({
        ...selectedRows,
        [rowDataId]: !selectedRows[rowDataId],
      });
    } else {
      onRowCheckboxSelect({
        [rowDataId]: !selectedRows[rowDataId],
      });
    }
  };

  /**
   * When the checkbox in the header column is selected, all visible rows should either be checked or unchecked depending on
   * the headerChecked boolean argument value
   */
  _handleHeaderCheckboxSelect = (headerChecked) => {
    const {
      onRowCheckboxSelect,
      dataModel: { apiModel, rowItems },
    } = this.props;

    if (headerChecked && apiModel.isLoaded) {
      const checkedRows = {};
      rowItems.forEach((row) => (checkedRows[row.id] = true));
      onRowCheckboxSelect(checkedRows);
    } else {
      onRowCheckboxSelect({});
    }
  };

  _shouldShowSrollbar = () => {
    const { dataModel, scrollable, numberOfVisibleRows } = this.props;
    const _moreRowsThanVisible =
      dataModel.rowItems && numberOfVisibleRows < dataModel.rowItems.length;
    return _moreRowsThanVisible && scrollable;
  };

  _getNumVisibleRows = () => {
    const { dataModel, numberOfVisibleRows } = this.props;
    const _hasLoadedData =
      !isNullOrUndefined(dataModel.apiModel) && dataModel.apiModel.isLoaded;
    if (_hasLoadedData) {
      if (dataModel.rowItems.length === 0) {
        //We want at least one row if there is no data
        return 1;
      } else if (this._shouldShowSrollbar()) {
        //We want to set the num of rows manually and have the table be scrollable
        return numberOfVisibleRows;
      } else {
        //We want the table to be as high as the total row items
        return dataModel.rowItems.length;
      }
    }
    // no loaded data so return default
    return numberOfVisibleRows;
  };

  render() {
    const {
      columns,
      className,
      dataModel,
      onRowClick,
      rowClassnames,
      tableId,
      selectedRows,
      onDropdownColumnSelect,
      shouldDisableRow,
      NoResultsComponent,
      canSelectMoreThenOneRow,
      sortColumn,
      onSortClick,
      listScroll,
      useVirtualizedList,
      virtualizedListFooter,
      virtualizedListDisableScrollCallback,
      virtualizedListRef,
    } = this.props;
    const { rowItems } = dataModel;
    const statementsListSize = rowItems.length;
    return (
      <div className={classnames(`${DATA_GRID_BLOCK}`, className)}>
        <div
          className={classnames(
            this._shouldShowSrollbar()
              ? `${DATA_GRID_BLOCK}__scrollable-wrapper`
              : null,
          )}
        >
          <DataGridColumnsHeader
            key={tableId}
            tableId={tableId}
            columns={columns}
            sort={sortColumn}
            onSortClick={onSortClick}
            selectedRows={selectedRows}
            onHeaderCheckboxSelect={this._handleHeaderCheckboxSelect}
            canSelectMoreThenOneRow={canSelectMoreThenOneRow}
            filterInput={this.props.filterInput}
            showFilterPopup={this.props.showFilterPopup}
            isFilterActive={this.props.isFilterActive}
            onFilterInputChange={this.props.onFilterInputChange}
            onFilterInputClear={this.props.onFilterInputClear}
            onFilterInputApply={this.props.onFilterInputApply}
            onFilterClick={this.props.onFilterClick}
            onCloseFilterPopup={this.props.onCloseFilterPopup}
          />
          <div
            ref={virtualizedListRef}
            className={`${DATA_GRID_BLOCK}__body`}
            style={{
              maxHeight: `${
                useVirtualizedList
                  ? rowItems.length <= ROWS_AMOUNT
                    ? this._getNumVisibleRows() * DATA_GRID_ROW_HEIGHT
                    : (ROWS_AMOUNT - 1) * DATA_GRID_ROW_HEIGHT
                  : this._getNumVisibleRows() * DATA_GRID_ROW_HEIGHT
              }rem`,
              minHeight: `${
                useVirtualizedList
                  ? rowItems.length <= ROWS_AMOUNT
                    ? this._getNumVisibleRows() * DATA_GRID_ROW_HEIGHT
                    : (ROWS_AMOUNT - 1) * DATA_GRID_ROW_HEIGHT
                  : this._getNumVisibleRows() * DATA_GRID_ROW_HEIGHT
              }rem`,
            }}
          >
            <StaticOrDynamicWrapper dataModel={dataModel}>
              {statementsListSize > 0 ? (
                useVirtualizedList ? (
                  <VirtualizedList
                    id={`${DATA_GRID_BLOCK}-list`}
                    itemHeight={DATA_GRID_ROW_HEIGHT * 16} //amount of rems * 16px
                    itemList={rowItems}
                    onScrollEnd={listScroll}
                    rowsAmount={
                      statementsListSize <= ROWS_AMOUNT
                        ? statementsListSize
                        : ROWS_AMOUNT - 1
                    }
                    renderFooter={virtualizedListFooter}
                    disableScrollCallback={virtualizedListDisableScrollCallback}
                    renderItem={(element, index) => (
                      <DataGridRow
                        key={`${tableId}-${index}`}
                        rowKey={`${tableId}-${index}`}
                        classNames={rowClassnames}
                        columns={columns}
                        rowData={element}
                        shouldDisableRow={shouldDisableRow}
                        onRowClick={onRowClick}
                        onRowCheckboxSelect={this._handleRowSelect}
                        isRowSelected={selectedRows && selectedRows[element.id]}
                        onDropdownColumnSelect={onDropdownColumnSelect}
                        virtualizedListRef={virtualizedListRef}
                      />
                    )}
                  />
                ) : (
                  rowItems.map((rowData, rowIndex) => (
                    <DataGridRow
                      key={`${tableId}-${rowIndex}`}
                      rowKey={`${tableId}-${rowIndex}`}
                      classNames={rowClassnames}
                      columns={columns}
                      rowData={rowData}
                      shouldDisableRow={shouldDisableRow}
                      onRowClick={onRowClick}
                      onRowCheckboxSelect={this._handleRowSelect}
                      isRowSelected={selectedRows && selectedRows[rowData.id]}
                      onDropdownColumnSelect={onDropdownColumnSelect}
                    />
                  ))
                )
              ) : NoResultsComponent ? (
                <div
                  style={{ height: `${DATA_GRID_ROW_HEIGHT}rem` }}
                  className={`${DATA_GRID_BLOCK}__no-results`}
                >
                  {NoResultsComponent}
                </div>
              ) : null}
            </StaticOrDynamicWrapper>
          </div>
        </div>
      </div>
    );
  }
}

DataGrid.propTypes = {
  /** An array of column objects, if you want special columns like Kebab or Checkbox, specify them in this prop */
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DataGridColumn)).isRequired,
  /** Function, triggered when clicking on a row, passes the current clicked row object */
  onRowClick: PropTypes.func,
  /** Applies classnames to the rows, if a func, passed the row data object and expected to return a string representing the classnames that should be applied */
  rowClassnames: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
  ]),
  /** Custom class name applied to the top level component */
  className: PropTypes.string,
  /** object expressing the sort direction and key for the data grid */
  sortColumn: PropTypes.instanceOf(DataGridSort),
  /** returns the new object of selected rows */
  onRowCheckboxSelect: PropTypes.func,
  /** Unique key string for each table */
  tableId: PropTypes.string.isRequired,
  /** Object map (each row object of rowData array needs to have a unique 'id' prop) of the selected rows passed back to the grid */
  selectedRows: PropTypes.object,
  /* Number of visual data rows, controls scrolling */
  numberOfVisibleRows: PropTypes.number,
  /** Contains the rowItems array and api model for loading */
  dataModel: PropTypes.instanceOf(DataGridData).isRequired,
  /** Function, triggered when clicking on a column header, passes the key of the column */
  onSortClick: PropTypes.func,
  /** props (val, rowId,  colKey) where val is the menu option selected, rowId is the unique id of the row data for which the selection was made, and colKey is the key in the rowData object.
   * If multiple  dropdownColumns are defined in your columns array, this function will need to handle all of them */
  onDropdownColumnSelect: PropTypes.func,
  /** Custom component to display under the column headers when there the rowItems property of the dataModel is an empty [] */
  NoResultsComponent: PropTypes.element,
  /** indicates if the data grid should scroll based on numberOfVisibleRows prop, or resize to fit the contents */
  scrollable: PropTypes.bool,
  /** Bool representing if more then one check box can be selected at once */
  canSelectMoreThenOneRow: PropTypes.bool,
  /** Function to determine if a specific row should be disabled, func should look like (rowData) => someFunction(rowData)
   * And evaluate to a boolean
   */
  shouldDisableRow: PropTypes.func,
  /* function that returns next list portion*/
  listScroll: PropTypes.func,
  /* boolean to determine if virtualized list is going to be used */
  useVirtualizedList: PropTypes.bool,
  /* function that returns a footer for the virtualized list */
  virtualizedListFooter: PropTypes.func,
  /* bool to determine if scroll callback from virtualized list needs to be disabled */
  virtualizedListDisableScrollCallback: PropTypes.bool,
  /*Ref to be assigned to the data grid body if a virtualized list is being used */
  virtualizedListRef: PropTypes.object,
};

DataGrid.defaultProps = {
  numberOfVisibleRows: 10,
};

export default DataGrid;
export {
  DataGridData,
  DataGridDataApi,
  DataGridColumn,
  DataGridKebabColumn,
  DataGridDropdownColumn,
  DataGridCheckboxColumn,
  DataGridIconColumn,
  DataGridSort,
  DataGridDropdown,
  DataGridFilter,
  DATA_GRID_BLOCK,
  DATA_GRID_ROW_HEIGHT,
};
