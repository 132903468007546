import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Checkbox from 'components/common/checkbox-component';
import Loading from '../../../common/loading-component';

const AUDIT_CHECKLIST_PANEL_CONFIRM_MODAL_ID =
  'audit-checklist-panel-confirm-modal-id';

const AUDIT_CHECKLIST_PANEL_CONFIRM_MODAL =
  'audit-checklist-panel-confirm-modal';

const AuditCheckListConfirmationModal = ({ onClickSave, onClickCancel }) => {
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onClickDisable, setOnClickDisable] = useState(false);

  return (
    <Modal
      id={AUDIT_CHECKLIST_PANEL_CONFIRM_MODAL_ID}
      title={'audit-checklist-panel.confirm-modal.title'}
      onClose={onClickCancel}
      primaryModalButton={
        new ModalButton({
          text: 'audit-checklist-panel.confirm-modal.confirm-button.title',
          onClick: async () => {
            setLoading(true);
            try {
              setOnClickDisable(true);
              await onClickSave();
              setLoading(false);
              onClickCancel();
            } catch (error) {
              setLoading(false);
              onClickCancel();
            }
          },

          disabled: !toggleCheckBox
            ? !toggleCheckBox
            : !onClickDisable
            ? onClickDisable
            : onClickDisable,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClickCancel,
        })
      }
    >
      {loading ? <Loading /> : null}
      <FormattedMessage id={'audit-checklist.confirm-modal.body'} />

      <Checkbox
        checked={toggleCheckBox}
        disabled={onClickDisable}
        onChange={() => setToggleCheckBox(!toggleCheckBox)}
        label={'audit-checklist.confirm-modal.confirm.checkbox'}
        id={`${AUDIT_CHECKLIST_PANEL_CONFIRM_MODAL}-confirm-checkbox`}
        width={'100%'}
        isValid={true}
      />
    </Modal>
  );
};

AuditCheckListConfirmationModal.propTypes = {
  /** Action fired when user clicks confirm button */
  onClickSave: PropTypes.func.isRequired,
  /** Action fired when user cancel button */
  onClickCancel: PropTypes.func.isRequired,
};

export default AuditCheckListConfirmationModal;
