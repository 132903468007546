import { createAction } from 'redux-actions';
import { getMultipleElementsFromCache } from './element-cache-actions';
import {
  clearSelectedElementsContentPanel,
  fetchClientEntityList,
  selectMultipleElementsContentPanel,
} from './statement-content-actions';
import { updateCacheWithMostRecentElementChanges } from './element-changes-since-tracker-actions';
import {
  showBatchPanelAction,
  hideBatchPanelAction,
} from './panel-controller-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { createProjectEntityRequest } from 'api/create-project-entity-api';
import {
  updateElementsSearchByIds,
  removeIdFromBatchSelectedItemsAction,
  addIdToBatchSelectedItemsAction,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import { fetchSectionHTMLSegmentsStatementContent } from 'store/actions/statement-content-actions';
import { batchMaxAmountNotification } from 'constants/feature/batch-panel-constants';
import { clearBatchModeSelected } from './modes-actions';
import { getMaxElementForBatchUpdateLimit } from 'utils/statement-content-page-utils';

export const setBatchSelectedElementsLoading = createAction(
  'BATCH_ELEMENTS_LOADING',
);

export const appendBatchSelectedElementsLoaded = createAction(
  'BATCH_APPEND_ELEMENTS_LOADED',
);
export const setBatchSelectedElementsError = createAction(
  'BATCH_ELEMENTS_ERROR',
);

export const clearBatchSelectedElementsAction = createAction(
  'BATCH_CLEAR_SELECTED_ELEMENTS',
);

export const addElementToBatchSelectionAction = createAction(
  'BATCH_ADD_ELEMENT_TO',
);

export const removeElementFromBatchSelectionAction = createAction(
  'BATCH_REMOVE_ELEMENT',
);

export const batchElementUpdateSuccessAction = createAction(
  'BATCH_ELEMENTS_UPDATE_SUCCESS',
);

export const createProjectEntityError = createAction(
  'CREATE_PROJECT_ENTITY_ERROR',
);

export const createProjectEntityLoading = createAction(
  'CREATE_PROJECT_ENTITY_LOADING',
);

export const updateSelectedBatchElementsFromSocketAction = createAction(
  'UPDATE_SELECTED_BATCH_ELEMENTS_FROM_SOCKET_ACTION',
);

export const removeBatchElementsFromSocketPayloadAction = createAction(
  'REMOVE_BATCH_ELEMENTS_FROM_SOCKET_PAYLOAD_ACTION',
);

const _handleBatchAddWithElementAlreadySelected =
  () => (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          panels: { right },
        },
      },
      data: {
        statementContent: { elementCache },
        elementPanel: { elementDetails },
      },
    } = getState();
    if (right === RIGHT_PANELS.ELEMENT) {
      // with element panel open + batch click, switch to batch panel with that element selected
      let element =
        elementDetails &&
        // getElement method inside section-cache model mandatorily needs elementID to get element details.
        // if sectionId is null then it will search in all the sections present in the cache.
        elementDetails.id &&
        elementCache.getElement({
          elementId: elementDetails.id,
          sectionId: elementDetails.sectionId,
        });
      element &&
        dispatch(
          addElementToBatchSelectionAction({
            element,
          }),
        );
    }
  };

export const clickElementInContentWhileBatch =
  ({ elementId, sectionId }) =>
  (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          panels: { right },
          statementNavigatorPanel: { elementSearchResults },
        },
      },
      data: {
        statementContent: { elementCache },
        batchPanel: { batchElementList },
      },
    } = getState();
    const batchElementsAmount = batchElementList.selectedElementIds.length;
    if (batchElementsAmount + 1 <= getMaxElementForBatchUpdateLimit()) {
      dispatch(_handleBatchAddWithElementAlreadySelected());
    }

    if (right !== RIGHT_PANELS.BATCH) {
      dispatch(showBatchPanelAction());
    }
    if (batchElementList.hasElement(elementId)) {
      dispatch(removeElementFromBatchSelectionAction({ elementId }));
      //removes the id from the batch selected items from the filtered elements list
      dispatch(removeIdFromBatchSelectedItemsAction(elementId));
    } else {
      if (batchElementsAmount + 1 <= getMaxElementForBatchUpdateLimit()) {
        if (elementCache.getElement({ elementId })) {
          dispatch(
            addElementToBatchSelectionAction({
              element: elementCache.getElement({ elementId, sectionId }),
            }),
          );
          if (
            elementSearchResults.elements &&
            elementSearchResults.hasElement(
              typeof elementId === 'string' ? parseInt(elementId) : elementId,
            )
          ) {
            dispatch(addIdToBatchSelectedItemsAction(elementId));
          }
        } else {
          // Try and get this element from element search result if not in elementcache
          dispatch(
            addElementToBatchSelectionAction({
              element: elementSearchResults.getElement({ elementId }),
            }),
          );
          if (
            elementSearchResults.elements &&
            elementSearchResults.hasElement(
              typeof elementId === 'string' ? parseInt(elementId) : elementId,
            )
          ) {
            dispatch(addIdToBatchSelectedItemsAction(elementId));
          }
        }
      } else {
        batchMaxAmountNotification();
      }
    }
  };

export const clickElementSearchEntryWhileRange =
  ({ element }) =>
  async (dispatch, getState) => {
    const {
      data: {
        batchPanel: {
          batchElementList: { selectedElementIds },
        },
      },
      ui: {
        statementPage: {
          statementNavigatorPanel: { elementSearchResults },
          panels: { right },
        },
      },
    } = getState();

    if (!right !== RIGHT_PANELS.BATCH) {
      dispatch(showBatchPanelAction());
    }

    const firstElIndex = elementSearchResults.selectedIndex;
    const secondElIndex = elementSearchResults.elements.indexOf(element);
    let selectedElementRange = elementSearchResults.elements.slice(
      firstElIndex,
      secondElIndex + 1,
    );
    if (firstElIndex > secondElIndex) {
      selectedElementRange = elementSearchResults.elements.slice(
        secondElIndex,
        firstElIndex + 1,
      );
    }
    if (
      selectedElementIds.length + selectedElementRange.length <=
      getMaxElementForBatchUpdateLimit()
    ) {
      dispatch(
        selectMultipleElementsContentPanel({
          elementIds: selectedElementRange.map((el) => el.id),
        }),
      );

      dispatch(
        appendBatchSelectedElementsLoaded({
          selectedElements: selectedElementRange,
        }),
      );
    } else {
      batchMaxAmountNotification();
    }
  };

export const batchAddElementsToBatch =
  ({ elements }) =>
  async (dispatch, getState) => {
    const {
      data: {
        batchPanel: {
          batchElementList: { selectedElementIds },
        },
      },
      ui: {
        statementPage: {
          panels: { right },
        },
      },
    } = getState();
    if (selectedElementIds.length + 1 <= getMaxElementForBatchUpdateLimit()) {
      dispatch(_handleBatchAddWithElementAlreadySelected());
    }
    if (
      selectedElementIds.length + elements.length <=
      getMaxElementForBatchUpdateLimit()
    ) {
      // always select all the elements in the content panel
      dispatch(
        selectMultipleElementsContentPanel({
          elementIds: elements.map((el) => el.elementId),
        }),
      );
      if (!right !== RIGHT_PANELS.BATCH) {
        dispatch(showBatchPanelAction());
      }
      const selectedElements = await dispatch(
        getMultipleElementsFromCache({
          elements,
          loadingAction: setBatchSelectedElementsLoading,
        }),
      );
      dispatch(appendBatchSelectedElementsLoaded({ selectedElements }));
    } else {
      batchMaxAmountNotification();
    }
  };

export const addElementsToBatchFromElementsFilter =
  (elements) => async (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          panels: { right },
        },
      },
    } = getState();
    if (!right !== RIGHT_PANELS.BATCH) {
      dispatch(showBatchPanelAction());
    }
    dispatch(
      selectMultipleElementsContentPanel({
        elementIds: elements.map((el) => el.id),
      }),
    );
    dispatch(appendBatchSelectedElementsLoaded({ selectedElements: elements }));
  };
export const removeFilteredElementsFromBatch =
  (elements) => async (dispatch, getState) => {
    const {
      data: {
        batchPanel: { batchElementList },
      },
      ui: {
        statementPage: {
          statementNavigatorPanel: {
            elementSearchResults: { batchSelectedItemsIds },
          },
          panels: { right },
        },
      },
    } = getState();
    if (
      right === RIGHT_PANELS.BATCH &&
      Object.keys(batchSelectedItemsIds).length ===
        batchElementList.selectedElementIds.length
    ) {
      dispatch(hideBatchPanelAction());
    }
    dispatch(removeBatchElementsFromSocketPayloadAction(elements));
  };

export const cancelBatchUpdatePanel = () => (dispatch, getState) => {
  dispatch(clearBatchSelectedElementsAction());
  dispatch(hideBatchPanelAction());
  dispatch(clearSelectedElementsContentPanel());
  dispatch(clearBatchModeSelected());
};

export const handleBatchUpdateSuccess =
  ({ elementIds, revisionId, updateModel }) =>
  (dispatch, getState) => {
    const { project } = getState().data.selectedProject;
    const { selectedElementsMap } = getState().data.batchPanel.batchElementList;
    const socketModel = getState().sockets;
    const { socketHasBeenDisconnected } = socketModel.statementSocket;
    // get updated elements for cache
    // rely on these methods of updaing the ui if the client fails to connect to signalr
    if (socketHasBeenDisconnected) {
      dispatch(updateCacheWithMostRecentElementChanges());
      dispatch(updateElementsSearchByIds({ elementIds }));
    }

    //get updated entity list
    dispatch(fetchClientEntityList(project.id));

    // clean up batch panel redux state
    dispatch(clearBatchSelectedElementsAction());
    dispatch(hideBatchPanelAction());

    // initiate actions to highlight successfully batch updated elements
    dispatch(
      batchElementUpdateSuccessAction({
        elementIds,
      }),
    );

    // fire action to update statement summary if needed
    if (
      updateModel.shouldRefreshStatementSummary &&
      socketHasBeenDisconnected
    ) {
      dispatch(fetchStatementSummaryElements({ revisionId }));
    }

    /** We need the sectionIds of currently selected elements to reload statement content */
    const sectionIds = Object.values(selectedElementsMap).map(
      (element) => element.sectionId,
    );

    if (socketHasBeenDisconnected) {
      dispatch(
        fetchSectionHTMLSegmentsStatementContent({
          sectionIdList: Array.from(new Set(sectionIds)),
        }),
      );
    }

    /** Set one time click event listener to clear green selected elements after
     * successful batch update
     */
    document.addEventListener(
      'click',
      (e) => {
        !e.ctrlKey && dispatch(clearSelectedElementsContentPanel());
      },
      {
        once: true,
      },
    );
  };

export const createEntity = (entity) => async (dispatch, getState) => {
  const { project } = getState().data.selectedProject;
  dispatch(createProjectEntityLoading());
  try {
    await createProjectEntityRequest({
      projectId: project.id,
      revisionId: entity.revisionId,
      entity: entity.entitySearch,
    });
    dispatch(fetchClientEntityList(project.id));
  } catch (error) {
    dispatch(createProjectEntityError(project.id));
  }
};
