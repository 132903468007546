import { handleActions } from 'redux-actions';
import { setStatementNavigationRefresh } from 'store/actions/statement-navigator/navigator-panel-actions';

export default handleActions(
  {
    [setStatementNavigationRefresh]: (IsRefresh, { payload }) => {
      return payload;
    },
  },
  null,
);
