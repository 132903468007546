import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import User from 'models/data/user-model';
import StatementWrapUpStats from './statement-wrap-up-stats-api-model';
export default class StatementWrapUpApprovalStats extends ApiModel({
  data: {
    reviewedElements: 0,
    submitDate: null,
    submitUser: new User(),
    totalElements: 0,
    totalNotes: 0,
    verifiedElements: 0,
  },
}) {
  get totalElements() {
    if (this.data) {
      return this.data.totalElements;
    }
    return 0;
  }
  get verifiedElements() {
    if (this.data) {
      return this.data.verifiedElements;
    }
    return 0;
  }
  get reviewedElements() {
    if (this.data) {
      return this.data.reviewedElements;
    }
    return 0;
  }
  get totalNotes() {
    if (this.data) {
      return this.data.totalNotes;
    }
    return 0;
  }
  get verifiedPercentage() {
    return ((this.verifiedElements * 100) / this.totalElements).toFixed(2);
  }
  get reviewedPercentage() {
    return ((this.reviewedElements * 100) / this.totalElements).toFixed(2);
  }

  get submitDate() {
    if (this.data && !isNullOrUndefined(this.data.submitDate)) {
      return this.data.submitDate;
    }
    return null;
  }
  get submitUser() {
    if (this.data && !isNullOrUndefined(this.data.submitUser)) {
      return this.data.submitUser;
    }
    return new User();
  }

  asWrapUpStatsModel() {
    const response = {
      data: {
        totalElements: this.totalElements,
        verifiedElements: this.verifiedElements,
        reviewedElements: this.reviewedElements,
        totalNotes: this.totalNotes,
      },
    };
    return new StatementWrapUpStats().setLoaded({ response });
  }

  processResponse({ response }) {
    return {
      data: {
        ...response.data,
        submitUser: new User(response.data.submitUser),
      },
    };
  }
}
