import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormulaListEntry from 'models/data/formula/formula-list-entry-model';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import {
  getResultDisplay,
  ROUND_NUMBER_ICON_HEIGHT,
  ROUND_NUMBER_ICON_WIDTH,
} from 'constants/feature/formula-constants';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { ReactComponent as TrashCan } from 'icons/delete-icon.svg';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ReactComponent as RoundNumberIcon } from 'icons/round-number.svg';
import { isNullOrUndefined } from 'utils/object-utils';
import { ReactComponent as CopyFormulaIcon } from 'icons/copy.svg';
import { FormulaList } from 'models/api/formula-list-model';

const FORMULA_ENTRY_BLOCK = 'formula-entry';
const FORMULA_ENTRY_ID_BLOCK = 'formula-entry-id';

const FORMULA_ENTRY_FLAGGED_ICON_SIZE = '16px';

const TRASHCAN_ICON_SIZE = '28';
const COPYFORMULA_ICON_SIZE = '18';

const FORMULA_ENTRY_TOOLTIP = new TooltipOptions({
  text: 'common.delete',
  id: 'tooltip-unlink-button-tool-tip',
  position: 'left',
});

const COPY_FORMULA_TOOLTIP = new TooltipOptions({
  text: 'element-panel.annotation.content.formula.copy-formula',
  id: 'tooltip-copy-formula-tool-tip',
  position: 'left',
});

const FormulaEntry = ({
  formulaEntry,
  units,
  onHover,
  formulaDeleteAction,
  onClick,
  disabled,
  CopyFormulaAction,
  isSelectedForCopy,
  isFormulaCopied,
  isSystemFlagOverride,
  numberFormatId,
}) => {
  return (
    <div
      className={classNames(
        `${FORMULA_ENTRY_BLOCK}`,
        isSelectedForCopy &&
          `${FORMULA_ENTRY_BLOCK}__result-container--selected`,
      )}
      onMouseOver={onHover}
      onClick={() => !disabled && onClick(formulaEntry.id)}
      disabled={disabled}
    >
      <div className={`${FORMULA_ENTRY_BLOCK}__container`}>
        <div
          className={classNames(
            `${FORMULA_ENTRY_BLOCK}__result-container`,
            formulaEntry.isFlagged() &&
              !isSystemFlagOverride &&
              `${FORMULA_ENTRY_BLOCK}__result-container--flagged`,
          )}
        >
          {formulaEntry.isFormulaRounded() && (
            <div className={`${FORMULA_ENTRY_BLOCK}__prefix-icon`}>
              <RoundNumberIcon
                width={ROUND_NUMBER_ICON_WIDTH}
                height={ROUND_NUMBER_ICON_HEIGHT}
              />
            </div>
          )}
          {formulaEntry.isFlagged() && !isSystemFlagOverride ? (
            <FlaggedIcon
              className={`${FORMULA_ENTRY_BLOCK}__prefix-icon`}
              width={FORMULA_ENTRY_FLAGGED_ICON_SIZE}
              height={FORMULA_ENTRY_FLAGGED_ICON_SIZE}
            />
          ) : null}
          {getResultDisplay({
            result: !isNullOrUndefined(formulaEntry.roundingResult)
              ? formulaEntry.roundingResult
              : formulaEntry.computedResult,
            units,
            numberFormatId,
          })}
        </div>
        <div className={`${FORMULA_ENTRY_BLOCK}__expression-container`}>
          {formulaEntry.getExpression(numberFormatId)}
        </div>
      </div>
      <div className={`${FORMULA_ENTRY_BLOCK}__copy-delete-button-container`}>
        {/* Don't show option to copy formula for restricted user/read only mode (disabled flag) */}
        {/* Don't show option to copy formula when some other formula is already copied (isFormulaCopied flag) */}
        {!disabled && !isFormulaCopied && (
          <IconButton
            id={`${FORMULA_ENTRY_ID_BLOCK}-${formulaEntry.formulaId}-copy-formula-icon`}
            className={`${FORMULA_ENTRY_BLOCK}__copy-formula-icon`}
            tooltip={COPY_FORMULA_TOOLTIP}
            onClick={(e) => {
              e.stopPropagation();
              CopyFormulaAction(
                new FormulaList({
                  data: {
                    formulas: [formulaEntry], // type FormulaListEntry
                  },
                }),
              );
            }}
            Icon={CopyFormulaIcon}
            iconSize={COPYFORMULA_ICON_SIZE}
            disabled={disabled}
          />
        )}
        <IconButton
          id={`${FORMULA_ENTRY_ID_BLOCK}-${formulaEntry.formulaId}-delete-icon`}
          className={`${FORMULA_ENTRY_BLOCK}__delete-icon`}
          tooltip={FORMULA_ENTRY_TOOLTIP}
          onClick={(e) => {
            e.stopPropagation();
            formulaDeleteAction(
              formulaEntry.formulaId,
              formulaEntry.creationType,
              formulaEntry.type,
            );
          }}
          Icon={TrashCan}
          iconSize={TRASHCAN_ICON_SIZE}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

FormulaEntry.propTypes = {
  /** Specified formula's details */
  formulaEntry: PropTypes.instanceOf(FormulaListEntry).isRequired,
  /** The units of the formula used for display */
  units: PropTypes.instanceOf(MenuOption),
  /** Action for deleting a formula from the formula list */
  formulaDeleteAction: PropTypes.func.isRequired,
  /** Action fired to get details for edit */
  onClick: PropTypes.func.isRequired,
  /** To Check if disabled */
  disabled: PropTypes.bool.isRequired,
  /** Action fired once formula is clicked for copy */
  CopyFormulaAction: PropTypes.func.isRequired,
  /** Returns if formula selected for copy */
  isSelectedForCopy: PropTypes.bool.isRequired,
  /** Checks if in copy formula mode */
  isFormulaCopied: PropTypes.bool.isRequired,
  /**Flag to check if system flag is overriden */
  isSystemFlagOverride: PropTypes.bool.isRequired,
  /**numberFormatId is a number to get the format which amount field of selected statement to be displayed in element/formula panels */
  numberFormatId: PropTypes.number,
};

export default memo(FormulaEntry);
