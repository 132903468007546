import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Page from 'components/util/page-component';
import ProjectInfoComponent from 'components/feature/project-creation/project-info/project-creation-project-info-component';
import ManageUsersComponent from 'components/feature/project-creation/manage-users/project-creation-manage-users-component';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { history } from 'store/store';
import ProjectForm from 'models/form/project-form-model';
import CountryForm from 'models/form/country-form-model';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import ProjectCreationCancelModal from 'components/feature/project-creation/project-creation-cancel-modal';
import { createNewProjectRequest } from 'api/project-creation-edit-api';
import { getContainerListUserHasAccessTo } from 'api/project-list-api';

const PROJECT_CREATION_PAGE_BLOCK = 'project-creation';
const PROJECT_CREATION_PAGE_ID_BLOCK = 'project-creation-id';

class ProjectCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelModal: false,
      projectInProgress: new ProjectForm(),
      countryList: new CountryForm(),
      shouldDisableCreateButton: false,
    };
    this.unBlockNavigation = null;
    this.shouldBlockNavigation = true; // instance variable for indicating if navigation away from page should be blocked
  }

  getProjectContainerListData = async () => {
    this.setState((state) => ({
      countryList: state.countryList.setLoading(),
    }));
    try {
      const data = await getContainerListUserHasAccessTo();
      this.setState((state) => ({
        countryList: state.countryList.setLoaded(data),
      }));
    } catch (error) {
      this.setState((state) => ({
        countryList: state.countryList.setError(error),
      }));
    }
  };

  componentDidMount() {
    const { currentUser } = this.props;
    this.getProjectContainerListData();
    /** Handles logic for blocking navigation with modal prompt and redux clean up */
    this.unBlockNavigation = history.block((toLocation) => {
      if (
        this.shouldBlockNavigation &&
        toLocation &&
        toLocation.pathname !== ROUTE_CONSTANTS.SESSION_TIMEOUT
      ) {
        this.setState({ showCancelModal: true });
        return false;
      }
      return true;
    });
    this.setState((state) => ({
      projectInProgress: state.projectInProgress.initCurrentUser(currentUser),
    }));
    /** Prevent browser from navigating away from statenement content page
     * if the they type a random url and if formula is not saved */
    window.addEventListener('beforeunload', this._blockUrlNavigation);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.countryList !== this.state.countryList) {
      const { countriesOptions } = this.state.countryList;
      if (countriesOptions.length === 1) {
        this.setState((state) => ({
          projectInProgress:
            state.projectInProgress.setCountryOfIssuanceDropdownValue(
              countriesOptions[0],
            ),
        }));
      }
    }
  }

  componentWillUnmount() {
    this.unBlockNavigation();
    window.removeEventListener('beforeunload', this._blockUrlNavigation);
  }

  _blockUrlNavigation = (e) => {
    if (this.shouldBlockNavigation) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  _toggleCancelModal = () => {
    this.setState((state, props) => {
      return {
        showCancelModal: !state.showCancelModal,
      };
    });
  };

  _createNewProject = async () => {
    const { finishProjectCreation } = this.props;
    this.setState((state) => ({
      projectInProgress: state.projectInProgress.setLoading(),
    }));
    try {
      const response = await createNewProjectRequest(
        this.state.projectInProgress,
      );
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setLoaded({ response }),
      }));
      this.shouldBlockNavigation = false;

      finishProjectCreation(response);
    } catch (error) {
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setLoaded(error),
      }));
    }
  };

  _onCancelAndLeavePage = () => {
    const { push } = this.props;
    this.shouldBlockNavigation = false;
    push(ROUTE_CONSTANTS.HOME_PAGE);
  };

  _onSave = () => {
    this.setState({ shouldDisableCreateButton: true });
    this._createNewProject();
  };
  render() {
    const {
      showCancelModal,
      projectInProgress,
      shouldDisableCreateButton,
      countryList,
    } = this.state;
    return (
      <Page>
        <div className={PROJECT_CREATION_PAGE_BLOCK}>
          <div className={`${PROJECT_CREATION_PAGE_BLOCK}__header`}>
            <Button.IconButton
              id={`${PROJECT_CREATION_PAGE_ID_BLOCK}__back-button`}
              className={`${PROJECT_CREATION_PAGE_BLOCK}__back-button`}
              type={BUTTON_TYPES.icon}
              Icon={BackButton}
              onClick={() => this._toggleCancelModal()}
            >
              <div className={`${PROJECT_CREATION_PAGE_BLOCK}__back-title`}>
                <FormattedMessage id={'project-creation.my-projects'} />
              </div>
            </Button.IconButton>
            <div>
              <h1 className={`${PROJECT_CREATION_PAGE_BLOCK}__page-title`}>
                <FormattedMessage id={'project-creation.create-new-project'} />
              </h1>
            </div>
          </div>
          <div className={`${PROJECT_CREATION_PAGE_BLOCK}__body`}>
            <div className={`${PROJECT_CREATION_PAGE_BLOCK}__project-info`}>
              <ProjectInfoComponent
                currentUser={this.props.currentUser}
                projectInProgress={projectInProgress}
                countryList={countryList}
                setProjectName={(name) =>
                  this.setState((state) => ({
                    projectInProgress: state.projectInProgress.setName(name),
                  }))
                }
                setEngagementEntity={(name) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setEngagementEntity(name),
                  }))
                }
                setNonAuditEngagementEntityDataHasBeenModified={(value) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setNonAuditEngagementEntityDataHasBeenModified(
                        value,
                      ),
                  }))
                }
                setIsAuditClient={(checked) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.initNotAuditClient(checked),
                  }))
                }
                setClientName={(client) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setClientName(client),
                  }))
                }
                setEngagementName={(engagement) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setEngagementName(engagement),
                  }))
                }
                setEngagementFye={(year) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setEngagementFye(year),
                  }))
                }
                setNonAuditReason={(option) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setNonAuditReason(option),
                  }))
                }
                setProjectCountry={(option) =>
                  this.setState((state) => ({
                    projectInProgress:
                      state.projectInProgress.setCountryOfIssuanceDropdownValue(
                        option,
                      ),
                  }))
                }
              />
            </div>
            {projectInProgress.isClientNameValid() && (
              <div className={`${PROJECT_CREATION_PAGE_BLOCK}__manange-users`}>
                <ManageUsersComponent
                  projectInProgress={projectInProgress}
                  addUserToProject={(id) =>
                    this.setState((state) => ({
                      projectInProgress: state.projectInProgress.addUser(id),
                    }))
                  }
                  assignUserProjectRole={(role, userId) =>
                    this.setState((state) => ({
                      projectInProgress:
                        state.projectInProgress.assignRoleToUser(role, userId),
                    }))
                  }
                  removeUserFromProject={(user) =>
                    this.setState((state) => ({
                      projectInProgress:
                        state.projectInProgress.removeUser(user),
                    }))
                  }
                  toggleHasAdminGroup={() =>
                    this.setState((state) => ({
                      projectInProgress:
                        state.projectInProgress.toggleHasAdminGroup(),
                    }))
                  }
                />
              </div>
            )}
          </div>
          <div className={`${PROJECT_CREATION_PAGE_BLOCK}__button-container`}>
            <Button
              id={`${PROJECT_CREATION_PAGE_ID_BLOCK}-project-cancel-button`}
              className={`${PROJECT_CREATION_PAGE_BLOCK}__cancel-button`}
              type={BUTTON_TYPES.tertiary}
              onClick={this._toggleCancelModal}
            >
              <FormattedMessage id={'common.cancel'} />
            </Button>
            <Button
              id={`${PROJECT_CREATION_PAGE_ID_BLOCK}-project-create-button`}
              className={`${PROJECT_CREATION_PAGE_BLOCK}__create-button`}
              type={BUTTON_TYPES.primary}
              onClick={this._onSave}
              disabled={
                !projectInProgress.isProjectValid() ||
                (projectInProgress.clientNameEdited &&
                  !projectInProgress.isClientNameValid()) ||
                shouldDisableCreateButton
              }
            >
              <FormattedMessage id={'common.create'} />
            </Button>
          </div>
        </div>
        {showCancelModal ? (
          <ProjectCreationCancelModal
            toggleCancelModal={this._toggleCancelModal}
            onCancelAndLeavePage={this._onCancelAndLeavePage}
          />
        ) : null}
      </Page>
    );
  }
}

ProjectCreation.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
};

export default ProjectCreation;
