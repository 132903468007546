import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { connect } from 'react-redux';
import { CreateComfortLetterModal } from '../element-panel/comfort-letter-panel/create-comfort-letter-modal';
import { AttachComfortLetter } from '../element-panel/comfort-letter-panel/_comfort-letter-panel-attach-component';
import {
  createBulkComfortLetterRequest,
  getMatchingComfortLettersRequest,
} from 'api/comfort-letter-api';
import BatchElementUpdateForm from 'models/form/batch-element-update-form-model';
import { ComfortLetterEntry } from 'components/feature/element-panel/annotations/comfort-letter-entry/comfort-letter-entry-component';
import ComfortLetter from 'models/data/comfort-letter-model';
import Permissions from 'permissions/permissions';

export const BatchPanelComfortLetters = ({
  updates,
  setUpdates,
  revisionId,
  fetchComfortAnnotationListForRevisionWithoutLoading,
}) => {
  const [showCreateComfortModal, setShowCreateComfortModal] =
    React.useState(false);

  const _toggleCreateComfortLettterModal = useCallback(() => {
    setShowCreateComfortModal((prevModalState) => !prevModalState);
  }, []);

  const [currentComfortLetterID, setCurrentComfortLetterID] =
    React.useState(undefined);
  const updateExpandedComfortLetterID = (toggleStatus, id) => {
    !toggleStatus && setCurrentComfortLetterID(id);
  };

  const _canDetachOrAttachComfortLetter =
    Permissions.Statement.canChangeContent();

  const _onCreateBulkComfortLetter = useCallback(
    ({ comfortLetterForm }) =>
      createBulkComfortLetterRequest({ comfortLetterForm }).then((response) => {
        _toggleCreateComfortLettterModal();
        fetchComfortAnnotationListForRevisionWithoutLoading({ revisionId });
        setUpdates((u) =>
          u.attachComfortLetter(
            new ComfortLetter().convertApiResponsetoComfortLetterModelFormat(
              response.data.result,
            ),
          ),
        );
      }),
    [
      _toggleCreateComfortLettterModal,
      fetchComfortAnnotationListForRevisionWithoutLoading,
      revisionId,
      setUpdates,
    ],
  );

  const _searchComfortLetters = async ({ searchTerm }) => {
    return await getMatchingComfortLettersRequest({
      revisionId,
      searchTerm,
    });
  };

  return (
    <>
      <AttachComfortLetter
        onCreate={_toggleCreateComfortLettterModal}
        onSearchComfortLetter={_searchComfortLetters}
        selectedComfortLetterList={updates.comfortLetters}
        onAttach={({ comfortLetter }) =>
          setUpdates((u) => u.attachComfortLetter(comfortLetter))
        }
        disabled={!_canDetachOrAttachComfortLetter}
      />
      {updates.comfortLetters.map((comfortLetter) => (
        <ComfortLetterEntry
          key={comfortLetter.comfortLetterId}
          comfortLetter={comfortLetter}
          currentlyExpandedComfortLetterID={currentComfortLetterID}
          updateExpandedComfortLetterID={updateExpandedComfortLetterID}
        />
      ))}

      {showCreateComfortModal && (
        <CreateComfortLetterModal
          onCreate={_onCreateBulkComfortLetter}
          onClose={_toggleCreateComfortLettterModal}
          elementDetails={
            new ElementDetails({ data: { revisionId: revisionId } })
          }
        />
      )}
    </>
  );
};

BatchPanelComfortLetters.propTypes = {
  /** Current batch updates model */
  updates: PropTypes.instanceOf(BatchElementUpdateForm).isRequired,
  /** Function for updating current batch updates model */
  setUpdates: PropTypes.func.isRequired,
  /**Number to represent revision id */
  revisionId: PropTypes.number.isRequired,
  /**action method to fetch comfort letter list with element count. It is used to refresh comfort letter toolkit */
  fetchComfortAnnotationListForRevisionWithoutLoading:
    PropTypes.func.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export default connect(mapStateToProps)(BatchPanelComfortLetters);
