import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import ProjectCreationPage from 'pages/project-creation-page';
import { finishProjectCreation } from 'store/actions/project-creation-actions';

const mapStateToProps = ({
  data: {
    currentUser: { data },
  },
}) => ({
  currentUser: data,
});

const mapDispatchToProps = {
  finishProjectCreation,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCreationPage);
