import { handleActions } from 'redux-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import {
  initFormulaCreateAction,
  setFormulaOperator,
  addManualFormulaRow,
  updateRowOperator,
  setFormulaManualNumber,
  removeFormulaRowAction,
  addFormulaRowOperator,
  removeFormulaRowOperator,
  removeAllRowsAction,
  clearFormulaAction,
  setFormulaLoading,
  setFormulaLoaded,
  setFormulaResult,
  setFormulaError,
  setFormulaElementRowsLoaded,
  setSelectedRowAction,
  setRoundingResultAction,
  setNewFormulaAction,
  removeFormulaWithSocketPayloadAction,
} from 'store/actions/formula-actions';

import FormulaForm from 'models/form/formula-form-model';
import { FORMULA_ROW_TYPES } from 'constants/feature/formula-constants';

export default handleActions(
  {
    [initFormulaCreateAction]: (formula, { payload }) => {
      return formula.initFormulaCreate(payload);
    },
    [setFormulaOperator]: (formula, { payload }) => {
      return formula.setOperator(payload);
    },

    [updateRowOperator]: (formula, { payload }) => {
      return formula.setRowOperator(payload);
    },
    [addFormulaRowOperator]: (formula) => {
      return formula.addOperator(FORMULA_ROW_TYPES.OPERATOR);
    },

    [removeFormulaRowOperator]: (formula, { payload }) => {
      return formula.removeRowOperator(payload);
    },

    [setFormulaManualNumber]: (formula, { payload }) => {
      return formula.setManualNumber(payload);
    },
    [removeFormulaRowAction]: (formula, { payload }) => {
      return formula.removeRow(payload);
    },
    [addManualFormulaRow]: (formula, { payload }) => {
      return formula.addManualRow();
    },
    [removeAllRowsAction]: (formula) => {
      return formula.removeAllRows();
    },
    [setFormulaLoading]: (formula) => {
      return formula.setLoading();
    },
    [setFormulaLoaded]: (formula, { payload }) => {
      return formula.setLoaded(payload);
    },
    [setFormulaResult]: (formula, { payload }) => {
      return formula.setFormulaResult();
    },
    [setFormulaError]: (formula, { payload }) => {
      return formula.setError(payload);
    },
    [setFormulaElementRowsLoaded]: (formula, { payload }) => {
      return formula.setElementRowsLoaded(payload);
    },
    [clearFormulaAction]: () => {
      return new FormulaForm();
    },
    [clearStatementPageStoreAction]: () => {
      return new FormulaForm();
    },
    [setSelectedRowAction]: (formula, { payload }) => {
      return formula.setSelectedRow(payload);
    },
    [setRoundingResultAction]: (formula, { payload }) => {
      return formula.setRoundingResultAndScale(payload);
    },
    [setNewFormulaAction]: (formula, { payload }) => {
      return formula.setNewFormula(payload);
    },
    [removeFormulaWithSocketPayloadAction]: (formula, { payload }) => {
      return formula.removeFormulaWithSocketPayload(payload);
    },
  },
  new FormulaForm(),
);
