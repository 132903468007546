import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { toggleAuditChecklistPanel } from 'store/actions/panel-controller-actions';
import AuditChecklistPanelContent from 'components/feature/toolkit/toolkit-audit-panel/toolkit-audit-checklist-panel-content';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import AuditQuestionsChecklist from 'models/api/audit-questions-checklist-api-model';
import { fetchAuditChecklistForRevision } from 'store/actions/audit-checklist-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import StatementProgressBar from 'components/feature/statement-list/statement-list-progress-bar-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import AuditCheckListConfirmationModal from './toolkit-audit-checklist-panel-confirmation-modal';
import { postFinalChecklistConfirmation } from 'api/audit-checklist-api';
import Permissions from 'permissions/permissions';
import {
  AUDIT_CHECKLIST_INFORMATION_TOOLTIP,
  AUDIT_CHECKLIST_STATUS_INFORMATION_TOOLTIP,
  AUDIT_CHECKLIST_EDIT_INFORMATION_TOOLTIP,
} from 'constants/feature/panel-constants';

export const AUDIT_CHECKLIST_PANEL_BLOCK = 'audit-checklist-panel';
const AUDIT_INFO_ICON_SIZE = '18px';

export const AuditCheckListPanel = ({
  show,
  onCancelPanel,
  auditQuestionsList,
  fetchAuditChecklist,
  revisionId,
  statementId,
  projectId,
}) => {
  const [count, setCount] = useState(0);
  const [allowEdit, setAllowEdit] = useState(false);
  const [showAuditConfirmationModel, setShowAuditConfirmationModel] = useState(
    false,
  );

  const checkListStatus = auditQuestionsList.data.auditFinalChecklistStatus;
  const permissionToModifyChecklist = Permissions.AuditChecklistPermissions.canModifyChecklist(
    projectId,
  );

  const confirmationRequestBody = {
    auditChecklistStatement: {
      auditFinalChecklistStatus: true,
    },
  };

  useEffect(() => {
    if (
      show &&
      !isNullOrUndefined(revisionId) &&
      !isNullOrUndefined(statementId)
    ) {
      fetchAuditChecklist({ revisionId, statementId });
    }
  }, [fetchAuditChecklist, revisionId, show, statementId]);

  useEffect(() => {
    setCount(() => {
      const lengthOfChecklist =
        auditQuestionsList.data.questionsChecklist.length;
      let temp = 0;
      for (let i = 0; i < lengthOfChecklist; i++) {
        if (auditQuestionsList.data.questionsChecklist[i].auditAnswer) {
          temp++;
        }
      }
      return temp;
    });
  }, [auditQuestionsList.data.questionsChecklist]);

  const saveAuditChecklist = async () => {
    await postFinalChecklistConfirmation(
      confirmationRequestBody,
      revisionId,
      statementId,
      projectId,
    );

    await fetchAuditChecklist({ revisionId, statementId });
  };

  const handleEditButton = () => {
    setAllowEdit(true);
  };

  return (
    <>
      <Flyout
        className={AUDIT_CHECKLIST_PANEL_BLOCK}
        show={show}
        position={'left'}
      >
        <FlyoutHeader enableClose onClose={onCancelPanel}>
          <h3 className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__title`}>
            <FormattedMessage id={'audit-checklist-panel.title'} />
          </h3>

          <Tooltip {...AUDIT_CHECKLIST_INFORMATION_TOOLTIP}>
            <Information
              name={'information'}
              className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__info_icon`}
              width={AUDIT_INFO_ICON_SIZE}
              height={AUDIT_INFO_ICON_SIZE}
            />
          </Tooltip>

          <div className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__button-container`}>
            <div
              className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__button-container--progress`}
            >
              <p>
                {count}/{auditQuestionsList.data.questionsChecklist.length}{' '}
                <FormattedMessage id="audit-checklist-panel-status.complete" />
              </p>
              <StatementProgressBar
                percentVerified={Math.round(
                  (count / auditQuestionsList.data.questionsChecklist.length) *
                    100,
                )}
              />
            </div>
            {!checkListStatus || allowEdit ? (
              <Tooltip
                {...AUDIT_CHECKLIST_STATUS_INFORMATION_TOOLTIP}
                active={!permissionToModifyChecklist}
              >
                <span>
                  <Button
                    id={`${AUDIT_CHECKLIST_PANEL_BLOCK}__finish-button`}
                    className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__button-container--finish`}
                    type={BUTTON_TYPES.primary}
                    onClick={() => {
                      setShowAuditConfirmationModel(true);
                      setAllowEdit(false);
                    }}
                    disabled={
                      Math.round(
                        (count /
                          auditQuestionsList.data.questionsChecklist.length) *
                          100,
                      ) === 100 && permissionToModifyChecklist
                        ? false
                        : true
                    }
                  >
                    <FormattedMessage id="audit-checklist-panel-header-button.finish" />
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                {...AUDIT_CHECKLIST_EDIT_INFORMATION_TOOLTIP}
                active={!permissionToModifyChecklist}
              >
                <span>
                  <Button
                    id={`${AUDIT_CHECKLIST_PANEL_BLOCK}__edit-button`}
                    className={`${AUDIT_CHECKLIST_PANEL_BLOCK}__button-container--edit`}
                    type={BUTTON_TYPES.primary}
                    onClick={handleEditButton}
                    disabled={
                      Math.round(
                        (count /
                          auditQuestionsList.data.questionsChecklist.length) *
                          100,
                      ) === 100 && permissionToModifyChecklist
                        ? false
                        : true
                    }
                  >
                    <FormattedMessage id="audit-checklist-panel-header-button.edit" />
                  </Button>
                </span>
              </Tooltip>
            )}
          </div>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <AuditChecklistPanelContent
            auditQuestionpaperList={auditQuestionsList}
            buttonStatus={
              !checkListStatus
                ? checkListStatus
                : checkListStatus && !allowEdit
                ? checkListStatus
                : !allowEdit
            }
          />
        </FlyoutPanelContent>
      </Flyout>
      {showAuditConfirmationModel && (
        <AuditCheckListConfirmationModal
          onClickCancel={() => {
            setShowAuditConfirmationModel(false);
          }}
          onClickSave={saveAuditChecklist}
        />
      )}
    </>
  );
};

AuditCheckListPanel.propTypes = {
  /** Indicates if the Audit Checklist panel should be shown */
  show: PropTypes.bool.isRequired,
  /** function to handle close/cancel panel */
  onCancelPanel: PropTypes.func,
  /** Checklist of Audit Questions to be displayed */
  auditQuestionsList: PropTypes.instanceOf(AuditQuestionsChecklist).isRequired,
  /** Function to fetch the list of Audit questions */
  fetchAuditChecklist: PropTypes.func,
  /** revision ID of the statement */
  revisionId: PropTypes.number.isRequired,
  /** Statement ID of the statement */
  statementId: PropTypes.number.isRequired,
  /** Selected Project ID */
  projectId: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  data: {
    revision,
    auditChecklistData,
    selectedProject: { id },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  show: left === LEFT_PANELS.AUDIT_CHECKLIST,
  revisionId: revision.id,
  statementId: revision.statementId,
  projectId: id,
  auditQuestionsList: auditChecklistData,
});

const mapDispatchToProps = {
  onCancelPanel: toggleAuditChecklistPanel,
  fetchAuditChecklist: fetchAuditChecklistForRevision,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditCheckListPanel);
