import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ProjectDetails from 'components/feature/project-details/project-details-component';
import {
  initProjectSocketClient,
  setProjectSocketClient,
  closeProjectSocketClient,
} from 'store/actions/project-socket-actions';

const mapStateToProps = ({
  data: {
    projectList,
    selectedProject: { project },
  },
}) => ({
  projectList,
  project,
});

const mapDispatchToProps = {
  push,
  initProjectSocketClient,
  setProjectSocketClient,
  closeProjectSocketClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
