import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const TICKMARK_PANEL_DELETE_MODAL = 'tickmark-panel-delete-modal';

const TickmarkPanelDeleteModal = ({ onClose, onDelete }) => (
  <Modal
    id={TICKMARK_PANEL_DELETE_MODAL}
    title="tickmark-panel.entry.delete-confirmation.title"
    onClose={onClose}
    primaryModalButton={
      new ModalButton({ text: 'common.confirm', onClick: onDelete })
    }
    secondaryModalButton={
      new ModalButton({ text: 'common.cancel', onClick: onClose })
    }
  >
    <div>
      <FormattedMessage id="tickmark-panel.entry.delete-confirmation.message" />
    </div>
  </Modal>
);

TickmarkPanelDeleteModal.propTypes = {
  /** Action fired when user clicks close */
  onClose: PropTypes.func.isRequired,
  /** Action fired when user clicks delete */
  onDelete: PropTypes.func.isRequired,
};

export default TickmarkPanelDeleteModal;
