import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import { PeriodList } from 'models/api/period-list-model';

export const PERIOD_FILTERS_ID_BLOCK = 'element-filters-period-id';

const ElementFiltersPeriod = ({
  filters,
  setFilters,
  className,
  periodList,
}) => {
  return (
    <div className={className}>
      <ConditionalRender dependencies={[periodList]}>
        {periodList.periodFilterList.map((period) => (
          <Checkbox
            key={period}
            id={`${PERIOD_FILTERS_ID_BLOCK}-${period}`}
            isNotIntl
            label={period}
            checked={filters.isPeriodSelected(period)}
            onChange={() => setFilters(filters.selectPeriod(period))}
          />
        ))}
      </ConditionalRender>
    </div>
  );
};

ElementFiltersPeriod.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Period list api model fetched from backend on page load, used to populate the checkbox options */
  periodList: PropTypes.instanceOf(PeriodList).isRequired,
};

const mapStateToProps = ({
  data: {
    elementPanel: { periodList },
  },
}) => ({
  periodList,
});

export { ElementFiltersPeriod };
export default connect(mapStateToProps, null)(ElementFiltersPeriod);
