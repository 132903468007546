import ApiModel from 'models/api-model';
import {
  UNSELECTED_OPTION,
  isOptionUnselected,
} from 'constants/feature/batch-panel-constants';
export const NOT_SET = '<NOTSET>';
/** Pass -1 if update should be ignored */
const apiBoolFormat = (bool) => (bool === true ? 1 : bool === false ? 0 : -1);

export default class BatchElementUpdateForm extends ApiModel({
  verify: UNSELECTED_OPTION,
  review: UNSELECTED_OPTION,
  userFlag: UNSELECTED_OPTION,
  flagOverride: UNSELECTED_OPTION,
  comfortAssign: UNSELECTED_OPTION,
  included: -1,
  shouldDeleteFormulas: false,
  shouldDeleteComfortLetters: false,
  shouldDeleteIntRef: false,
  shouldDeleteTickmarks: false,
  shouldDeleteWpRefs: false,
  tickmarks: [],
  shouldAddTickmarks: false,
  comfortLetters: [],
  shouldAddComfortLetters: false,
  workpaperRefs: [],
  shouldAddWpRefs: false,
  entity: null,
  scaling: null,
  scalingId: -1,
  units: null,
  unitsId: -1,
  period: null,
}) {
  get shouldRefreshStatementSummary() {
    return !(
      isOptionUnselected(this.verify) && isOptionUnselected(this.review)
    );
  }
  /**
   * Override default ApiModel.setLoaded,
   * used for handling loading of the batch panel after save
   */
  setLoaded() {
    return this.merge({
      error: null,
      isLoading: false,
      isLoaded: true,
    });
  }
  toApiFormat({ elementIds }) {
    return {
      elementEntity: {
        elementIds,
        signFlag: -1,
        entity: this.entity,
        unitsId: this.unitsId,
        scalingId: this.scalingId,
        periodType: this.period ? this.period.split(' ')[0] : null,
        fiscalYear: this.period ? parseInt(this.period.split(' ')[1]) : -1,
        internalVerified: apiBoolFormat(this.verify),
        reviewed: apiBoolFormat(this.review),
        flagUser: apiBoolFormat(this.userFlag),
        comfortAssign: apiBoolFormat(this.comfortAssign),
        flagOverride: apiBoolFormat(this.flagOverride),
        included: this.included,
      },
      shouldDeleteFormulas: this.shouldDeleteFormulas,
      shouldDeleteIntRef: this.shouldDeleteIntRef,
      shouldDeleteTickmarks: this.shouldDeleteTickmarks,
      shouldDeleteComfortLetters: this.shouldDeleteComfortLetters,
      shouldDeleteWpRefs: this.shouldDeleteWpRefs,
      tickmarkIds: this.tickmarks.map((t) => t.tickmarkId),
      shouldAddTickmarks: this.shouldAddTickmarks,
      comfortLetterIds: this.comfortLetters.map(
        (cfltr) => cfltr.comfortLetterId,
      ),
      shouldAddComfortLetters: this.shouldAddComfortLetters,
      workpaperRefIds: this.workpaperRefs.map((wpr) => wpr.workpaperRefId),
      shouldAddWpRefs: this.shouldAddWpRefs,
    };
  }

  hasUpdatesSelected() {
    return (
      !isOptionUnselected(this.verify) ||
      !isOptionUnselected(this.review) ||
      !isOptionUnselected(this.userFlag) ||
      !isOptionUnselected(this.flagOverride) ||
      !isOptionUnselected(this.comfortAssign) ||
      this.shouldDeleteFormulas ||
      this.shouldDeleteComfortLetters ||
      this.shouldDeleteIntRef ||
      this.shouldDeleteTickmarks ||
      this.shouldDeleteWpRefs ||
      (this.shouldAddWpRefs && this.workpaperRefs.length > 0) ||
      (this.shouldAddTickmarks && this.tickmarks.length > 0) ||
      (this.shouldAddComfortLetters && this.comfortLetters.length > 0) ||
      this.scalingId !== -1 ||
      this.unitsId !== -1 ||
      this.period !== null ||
      this.entity !== null
    );
  }
  setVerify(val) {
    return this.merge({
      verify: val,
    });
  }
  setReview(val) {
    return this.merge({
      review: val,
    });
  }
  setUserFlag(val) {
    return this.merge({
      userFlag: val,
    });
  }

  setComfortAssign(val) {
    return this.merge({
      comfortAssign: val,
    });
  }
  setSystemFlagOverride(val) {
    return this.merge({
      flagOverride: val,
    });
  }
  setExclude() {
    return this.merge({
      included: 0,
    });
  }
  unSetExclude() {
    return this.merge({
      included: -1,
    });
  }
  toggleDeleteWorkpaper() {
    return this.merge({
      shouldDeleteWpRefs: !this.shouldDeleteWpRefs,
    });
  }

  setScaling(val) {
    return this.merge({
      scaling: val,
      scalingId: val === null ? -1 : val.id,
    });
  }

  setUnits(val) {
    return this.merge({
      units: val,
      unitsId: val === null ? -1 : val.id,
    });
  }

  setPeriod(val) {
    return this.merge({
      period: val,
    });
  }

  toggleDeleteInternalRef() {
    return this.merge({
      shouldDeleteIntRef: !this.shouldDeleteIntRef,
    });
  }

  toggleDeleteTickmarks() {
    return this.merge({
      shouldDeleteTickmarks: !this.shouldDeleteTickmarks,
    });
  }

  toggleDeleteFormulas() {
    return this.merge({
      shouldDeleteFormulas: !this.shouldDeleteFormulas,
    });
  }

  toggleDeleteComfortLetters() {
    return this.merge({
      shouldDeleteComfortLetters: !this.shouldDeleteComfortLetters,
    });
  }

  attachWpr(workpaper) {
    return this.merge({
      workpaperRefs: [workpaper, ...this.workpaperRefs],
      shouldAddWpRefs: true,
    });
  }

  setWorkpaperList(workpapers) {
    if (workpapers && workpapers.length > 0) {
      return this.merge({
        workpaperRefs: [...workpapers, ...this.workpaperRefs],
        shouldAddWpRefs: true,
      });
    }
    return this;
  }

  removeWpr(workpaper) {
    const workpaperRefs = this.workpaperRefs.filter(
      (wpr) => wpr.workpaperRefId !== workpaper.workpaperRefId,
    );
    return this.merge({
      workpaperRefs,
      shouldAddWpRefs: workpaperRefs.length > 0,
    });
  }

  updateEntity(val) {
    return this.merge({
      entity: val,
    });
  }

  hasTickmark(tickmark) {
    return this.tickmarks.some((t) => t.tickmarkId === tickmark.tickmarkId);
  }

  hasComfortLetter(comfortLetter) {
    return this.comfortLetters.some(
      (cfltr) =>
        cfltr.comfortLetterId === comfortLetter &&
        comfortLetter.comfortLetterId,
    );
  }

  attachTickmark(tickmark) {
    if (this.hasTickmark(tickmark)) return this;
    return this.merge({
      tickmarks: [tickmark, ...this.tickmarks],
      shouldAddTickmarks: true,
    });
  }

  setTickmarkList(tickmarks) {
    if (tickmarks && tickmarks.length > 0) {
      return this.merge({
        tickmarks: [...tickmarks, ...this.tickmarks],
        shouldAddTickmarks: true,
      });
    }
    return this;
  }

  attachComfortLetter(comfortLetter) {
    if (this.hasComfortLetter(comfortLetter)) return this;
    return this.merge({
      comfortLetters: [comfortLetter, ...this.comfortLetters],
      shouldAddComfortLetters: true,
    });
  }

  detachTickmark(tickmark) {
    const filteredTickmarks = this.tickmarks.filter(
      (t) => t.tickmarkId !== tickmark.tickmarkId,
    );
    return this.merge({
      tickmarks: filteredTickmarks,
      shouldAddTickmarks: filteredTickmarks.length > 0,
    });
  }
}
