import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const NoCarryForwardStatements = () => {
  return (
    <FormattedMessage id={'statement-create.carry-forward.no-statements'} />
  );
};

export default memo(NoCarryForwardStatements);
