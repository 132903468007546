import PropTypes from 'prop-types';
import React from 'react';
import SummaryElements from 'models/api/statement-summary-elements-model';
import { FormattedMessage } from 'react-intl';

export const STATEMENT_SUMMARY_ELEMENTS_TOTAL_BLOCK =
  'statement-summary-elements-total';

const StatementSummaryElementsTotal = ({ summaryElements }) => (
  <div className={STATEMENT_SUMMARY_ELEMENTS_TOTAL_BLOCK}>
    <p className={`${STATEMENT_SUMMARY_ELEMENTS_TOTAL_BLOCK}__total`}>
      <FormattedMessage id="statement-summary.heading.total" /> (
      {summaryElements.totalElements})
    </p>
  </div>
);

StatementSummaryElementsTotal.propTypes = {
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElements).isRequired,
};

export default StatementSummaryElementsTotal;
