import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Note from 'models/data/note-model';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { NOTE_TYPES } from 'constants/feature/notes-panel-constants';

const NOTES_DETAILS_BLOCK = 'notes-details';
const NotesDetails = ({ note, className }) => {
  return (
    <div className={classnames(NOTES_DETAILS_BLOCK, className)}>
      <span className={`${NOTES_DETAILS_BLOCK}__item`}>
        {note.formattedNoteNumber}
      </span>
      <span className={`${NOTES_DETAILS_BLOCK}__item`}>
        {note.noteType === NOTE_TYPES.CLIENT.short ? (
          <FormattedMessage id={NOTE_TYPES.CLIENT.intlId} />
        ) : note.noteType === NOTE_TYPES.OBSERVER.short ? (
          <FormattedMessage id={NOTE_TYPES.OBSERVER.intlId} />
        ) : (
          <FormattedMessage id={NOTE_TYPES.REVIEW.intlId} />
        )}
      </span>
      <span className={`${NOTES_DETAILS_BLOCK}__item`}>
        {moment(note.noteDate).format('l')}
      </span>
      <span className={`${NOTES_DETAILS_BLOCK}__item`}>
        {moment(note.noteDate).format('LT')}
      </span>
      <span className={`${NOTES_DETAILS_BLOCK}__item`}>
        <FormattedMessage
          id="notes-panel.additional-details.version-number"
          values={{ revision: note.revisionNumber }}
        />
      </span>
    </div>
  );
};

NotesDetails.propTypes = {
  /** This note's data model */
  note: PropTypes.instanceOf(Note).isRequired,
  /** accepts a custom class */
  className: PropTypes.string,
};

export default memo(NotesDetails);
