import { handleActions } from 'redux-actions';

import {
  setSourceStatementElementMapAction,
  setTargetStatementElementMapAction,
  clearSideBySideElementsMap,
  setTargetStatementBatchElementsMapAction,
  setSourceStatementBatchElementsMapAction,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { SideBySideViewElementMap } from 'models/data/side-by-side-view-element-map-model';

export default handleActions(
  {
    [setSourceStatementElementMapAction]: (
      sideBySideElementMap,
      { payload },
    ) => {
      return sideBySideElementMap.setSourceStatementElementMap(payload);
    },
    [setTargetStatementElementMapAction]: (
      sideBySideElementMap,
      { payload },
    ) => {
      return sideBySideElementMap.setTargetStatementElementMap(payload);
    },
    [setTargetStatementBatchElementsMapAction]: (
      sideBySideElementMap,
      { payload },
    ) => {
      return sideBySideElementMap.setStatementSideBySideBatchElementsMap(
        payload,
      );
    },
    [setSourceStatementBatchElementsMapAction]: (
      sideBySideElementMap,
      { payload },
    ) => {
      return sideBySideElementMap.setSourceStatementBatchElementsMap(payload);
    },
    [clearSideBySideElementsMap]: (sideBySideElementMap) => {
      return sideBySideElementMap.clearElementsMap();
    },
  },
  new SideBySideViewElementMap(),
);
