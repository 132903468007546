import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';
import { shouldBreakWord } from 'constants/common/multi-line-ellipsis-constants';
import ReactDOM from 'react-dom';

const TOOLTIP_BLOCK = 'tooltip';
/** must wrap actual html elements not react components */

const Tooltip = ({
  id,
  text,
  position,
  className,
  children,
  isNotInternationalized,
  active,
  useDefault,
  intl,
  event,
  tooltipOnHideFunction,
  delay,
  clickable,
  offset,
}) => {
  const [positionOnOverflow, setPositionOnOverflow] = useState(position);
  const reactTooltipRef = useRef(null);

  const _id = `${id}-tooltip`;
  if (isNullOrUndefined(id) || isNullOrUndefined(text)) {
    return children;
  }
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { 'data-for': _id, 'data-tip': 'data-tip' }),
  );

  const _displayText = () => {
    if (isNotInternationalized) {
      return text;
    } else if (typeof text === 'string') {
      return intl.formatMessage({ id: text });
    } else if (typeof text === 'object') {
      if (text.defaultMessage) {
        return intl.formatMessage(
          { id: text.id, defaultMessage: text.defaultMessage },
          text.values,
        );
      }
      return intl.formatMessage({ id: text.id }, text.values);
    }
    return null;
  };

  const defaultTooltipChild = React.Children.map(children, (child) =>
    React.cloneElement(child, { title: _displayText() }),
  );

  if (useDefault) {
    return <React.Fragment>{defaultTooltipChild}</React.Fragment>;
  } else if (active) {
    return (
      <>
        {ReactDOM.createPortal(
          <ReactTooltip
            className={classNames(
              TOOLTIP_BLOCK,
              className,
              shouldBreakWord(_displayText())
                ? `${TOOLTIP_BLOCK}--break-word`
                : null,
            )}
            id={_id}
            effect="solid"
            place={positionOnOverflow}
            multiline
            html={false} //if this is set to true, it makes the app vulnerable to XSS attacks
            ref={reactTooltipRef}
            event={event ? event : null}
            //if event is changed, isCapture is neccesary to allow onClick events of children
            isCapture={event ? true : null}
            afterHide={tooltipOnHideFunction ? tooltipOnHideFunction : null}
            delayHide={delay ? delay : 0}
            clickable={clickable}
            offset={offset}
            afterShow={() => {
              const { tooltipRef } = reactTooltipRef.current;

              if (!tooltipRef) return;

              const rect = tooltipRef.getBoundingClientRect();

              const overflownLeft = rect.left < 0;
              const overflownRight = rect.right > window.innerWidth;

              if (overflownLeft) {
                setPositionOnOverflow('right');
              } else if (overflownRight) {
                setPositionOnOverflow('left');
              }
            }}
          >
            {_displayText()}
          </ReactTooltip>,
          document.getElementById('tooltip-root'),
        )}
        {childrenWithProps}
      </>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

Tooltip.defaultProps = {
  active: true,
};

Tooltip.propTypes = {
  /** REQUIRED if passing a tooltip, no tooltip attributes applied to children if not present, Unique String id for tool tip*/
  id: PropTypes.string,
  /** REQUIRED if passing a tooltip, no tooltip attributes applied to children if not present, String copy for the tool tip*/
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.object,
    }),
    PropTypes.node,
  ]),
  /** Position of tooltip: right, left, top, bottom */
  position: PropTypes.string,
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if the tool tip accepts an internationalized id or a string */
  isNotInternationalized: PropTypes.bool,
  /** Boolean representing if a tool tip should render or just its child. useful for conditionally adding tooltips to components.*/
  active: PropTypes.bool,
  /** indicates if the tooltip should be browser based using the "title" attribute or a custom tooltip */
  useDefault: PropTypes.bool,
  /* indicates if the tooltip needs to work with different event other than hover */
  event: PropTypes.string,
  /* function executed when tooltip hides */
  tooltipOnHideFunction: PropTypes.func,
  /* delay in hiding the tooltip */
  delay: PropTypes.number,
  /* boolean value that allows the tooltip to be clickable */
  clickable: PropTypes.bool,
};

export default memo(injectIntl(Tooltip));
