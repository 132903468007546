import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ConditionalRender from 'components/util/conditional-render-component';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import TextInput from 'components/common/text-input-component';
import TextArea from 'components/common/text-area-component';
import WorkpaperForm from 'models/form/workpaper-form-model';
import {
  WPR_NUMBER_CHAR_LIMIT,
  WPR_NAME_CHAR_LIMIT,
} from 'constants/feature/workpaper-panel-constants';
import { editWorkpaperDetailsAction } from 'store/actions/element-panel-actions';

export const EDIT_WORKPAPER_BLOCK = 'edit-workpaper-modal';
const EDIT_WORKPAPER_ID_BLOCK = 'edit-workpaper-modal-id';

export const ToolkitEditWorkpaperModal = ({
  onCancel,
  onSave,
  workpaperToEdit,
  revisionId,
  editWorkpaperDetailsAction,
}) => {
  const [workpaperInProgress, setWorkpaperInProgress] = useState(
    new WorkpaperForm().fromWorkpaper(workpaperToEdit),
  );
  const [visitedReferenceNumber, setVisitedReferenceNumber] = useState(false);
  const [visitedReferenceName, setVisitedReferenceName] = useState(false);

  const _handleCreateClick = async () => {
    setWorkpaperInProgress((workpaper) => workpaper.setLoading());
    try {
      await editWorkpaperDetailsAction({
        revisionId,
        workpaper: workpaperInProgress.trimmedWorkpaperReference(),
      });
      onSave();
    } catch (error) {
      if (_isRequestErrorForDuplicate(error)) {
        setWorkpaperInProgress((workpaper) =>
          workpaper.setDuplicateErrorLoaded({
            duplicate: workpaperInProgress.referenceNumber,
          }),
        );
      } else {
        setWorkpaperInProgress((workpaper) => workpaper.setError(error));
      }
    }
  };

  const _isRequestErrorForDuplicate = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause.includes(
      'This work paper reference number already exists', // distinct error message from API
    );

  const _referenceNumberErrorText = () => {
    if (workpaperInProgress.isReferenceNumberDuplicate()) {
      return 'create-workpaper-modal.form.reference-number.error.duplicate';
    } else if (workpaperInProgress.doesRefNumberHaveIllegalChars()) {
      return 'create-workpaper-modal.form.reference-number.error.char-exclude';
    }
    return {
      id: 'create-workpaper-modal.form.reference-number.error.char-count',
      values: { limit: WPR_NUMBER_CHAR_LIMIT },
    };
  };

  return (
    <Modal
      id={EDIT_WORKPAPER_ID_BLOCK}
      title="toolkit.workpaper-panel.edit-modal.title"
      onClose={onCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          onClick: _handleCreateClick,
          disabled:
            !workpaperInProgress.isWorkpaperValid() ||
            (workpaperInProgress.data.referenceNumber ===
              workpaperToEdit.referenceNumber &&
              workpaperInProgress.data.referenceName ===
                workpaperToEdit.referenceName),
        })
      }
      secondaryModalButton={
        new ModalButton({ text: 'common.cancel', onClick: onCancel })
      }
    >
      <ConditionalRender dependencies={[workpaperInProgress]}>
        <p className={`${EDIT_WORKPAPER_BLOCK}__instructions`}>
          <FormattedMessage id="toolkit.workpaper-panel.edit-modal.instructions" />
        </p>
        <div className={`${EDIT_WORKPAPER_BLOCK}__reference-number`}>
          <TextInput
            id={`${EDIT_WORKPAPER_ID_BLOCK}-reference-number`}
            label={'toolkit.workpaper-panel.edit-modal.ref-number'}
            placeholder={
              'create-workpaper-modal.form.reference-number.placeholder'
            }
            onBlur={() => setVisitedReferenceNumber(true)}
            width={'100%'}
            onChange={(val) =>
              setWorkpaperInProgress((workpaper) =>
                workpaper.setReferenceNumber(val),
              )
            }
            isValid={
              !workpaperInProgress.isReferenceNumberDuplicate() &&
              (!visitedReferenceNumber ||
                workpaperInProgress.isReferenceNumberValid())
            }
            errorText={_referenceNumberErrorText()}
            type={'text'}
            value={workpaperInProgress.data.referenceNumber}
          />
        </div>
        <div className={`${EDIT_WORKPAPER_BLOCK}__reference-number`}>
          <TextArea
            errorText={{
              id: 'create-workpaper-modal.form.reference-name.error.char-count',
              values: { limit: WPR_NAME_CHAR_LIMIT },
            }}
            id={`${EDIT_WORKPAPER_ID_BLOCK}-reference-name`}
            isValid={
              !visitedReferenceName ||
              workpaperInProgress.isReferenceNameValid()
            }
            label="toolkit.workpaper-panel.edit-modal.ref-name"
            onChange={(val) =>
              setWorkpaperInProgress((workpaper) =>
                workpaper.setReferenceName(val),
              )
            }
            onBlur={() => setVisitedReferenceName(true)}
            placeholder="create-workpaper-modal.form.reference-name.placeholder"
            value={workpaperInProgress.data.referenceName}
            width={'100%'}
          />
        </div>
      </ConditionalRender>
    </Modal>
  );
};

ToolkitEditWorkpaperModal.propTypes = {
  /** Action fired when user clicks close */
  onCancel: PropTypes.func.isRequired,
  /** Action fired when user clicks delete */
  onSave: PropTypes.func.isRequired,
  /** Object of the selected workpaper to edit */
  workpaperToEdit: PropTypes.object.isRequired,
  /** Current revision id */
  revisionId: PropTypes.number,
  /**Action fired to make api call and save the edited content and update store with latest change */
  editWorkpaperDetailsAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

const mapDispatchToProps = { editWorkpaperDetailsAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolkitEditWorkpaperModal);
