import { createAction } from 'redux-actions';
import { userAuthentication } from 'api/current-user-api';
import { userLogOut } from 'api/api-authentication';

export const userAuthError = createAction('USER_AUTH_ERROR');
export const userAuth401Error = createAction('USER_AUTH_401_ERROR');
export const userAuthLoading = createAction('USER_AUTH_LOADING');
export const userAuthLoaded = createAction('USER_AUTH_LOADED');

export const authenticateUser = () => async (dispatch, getState) => {
  const { revision, selectedProject, projectUsersList } = getState().data || {};
  const revisionId = revision && revision.id;
  const projectId = selectedProject && selectedProject.id;
  const projectUsers = projectUsersList.users;
  let authResponse;
  try {
    dispatch(userAuthLoading());
    authResponse = await userAuthentication();
    if (
      authResponse &&
      authResponse.response &&
      authResponse.response.result &&
      authResponse.response.result.enabled
    )
      dispatch(userAuthLoaded(authResponse));
    else throw new Error('recieved null response from "/tieout/auth" api call');
  } catch (error) {
    const { response: { data } = {} } = error;
    if (
      authResponse &&
      authResponse.response &&
      authResponse.response.result &&
      !authResponse.response.result.enabled
    )
      dispatch(userAuth401Error({ status: 401 }));
    else if (data && data.status === 401) dispatch(userAuth401Error(data));
    else {
      console.log('user Authentication failed. Error details:');
      console.log(error);
      console.trace();
      dispatch(userAuthError(error));
      userLogOut(revisionId, projectUsers, projectId);
    }
  }
};
