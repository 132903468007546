import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import Search from 'components/common/search-component';
import TickmarkListModel from 'models/api/tickmark-list-api-model';
import { getTickmarksForRevisionRequest } from 'api/tickmark-api';
import { connect } from 'react-redux';
import TickmarkEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-component';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import { searchTickmarkByKeywords } from 'api/tickmark-api';
import ConditionalRender from 'components/util/conditional-render-component';

export const TERTIARY_TICKMARK_BLOCK = 'tertiary-tickmark-filters';
export const TERTIARY_TICKMARK_ID_BLOCK = 'tertiary-tickmark-filters-id';

const MIN_CHAR_LENGTH_TO_SEARCH = 1;

const ElementFiltersTickmarkTertiary = ({
  filters,
  setFilters,
  className,
  revisionId,
}) => {
  const [searchTerm, setSearch] = useState('');
  const [tickmarkList, setTickmarkList] = useState(new TickmarkListModel());
  const [currentTickmarkId, setCurrentTickmarkId] = useState(undefined);

  const updateExpandedTickmarkID = (toggleStatus, id) => {
    !toggleStatus && setCurrentTickmarkId(id);
  };

  const _fetchTickmarks = useCallback(async () => {
    setTickmarkList(tickmarkList.setLoading());
    try {
      const response = await getTickmarksForRevisionRequest({
        revisionId,
      });
      setTickmarkList(tickmarkList.setLoaded({ response }));
    } catch (error) {
      setTickmarkList(tickmarkList.setError(error));
    }
  }, [revisionId, tickmarkList]);

  useEffect(() => {
    _fetchTickmarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _searchTickmarks = (val) => {
    setSearch(val);
    if (isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH)) {
      debouncedSearch({ search: val });
    } else {
      _fetchTickmarks();
    }
  };

  const _fetchTickmarksByKeywords = async ({ search }) => {
    setTickmarkList(tickmarkList.setLoading());
    try {
      const response = await searchTickmarkByKeywords({ search, revisionId });
      setTickmarkList(tickmarkList.setLoaded({ response }));
    } catch (error) {
      setTickmarkList(tickmarkList.setError(error));
    }
  };

  const debouncedSearch = useCallback(
    debounceSearch(_fetchTickmarksByKeywords),
    [],
  );

  return (
    <div className={className}>
      <Search
        className={`${TERTIARY_TICKMARK_BLOCK}__search`}
        id={`${TERTIARY_TICKMARK_ID_BLOCK}-search`}
        onChange={_searchTickmarks}
        onClear={() => {
          setSearch('');
          _fetchTickmarks();
        }}
        placeholder="statement-nav-element-filters.tickmarks.search.placeholder"
        showSearchButton
        value={searchTerm}
        isValid
        disableAutoComplete
      />
      <ConditionalRender dependencies={[tickmarkList]}>
        {tickmarkList.tickmarks.map((tickmark) => (
          <div
            className={`${TERTIARY_TICKMARK_BLOCK}__checkbox-container`}
            key={tickmark.tickmarkId}
          >
            <Checkbox
              className={`${TERTIARY_TICKMARK_BLOCK}__tickmark-checkbox`}
              id={`${TERTIARY_TICKMARK_ID_BLOCK}-${tickmark.tickmarkId}`}
              isNotIntl={true}
              value={tickmark}
              checked={filters.isTickmarkSelected(tickmark.noteOrdinal)}
              onChange={(val) => setFilters(filters.selectTickmarks(val))}
              width={'15%'}
            />
            <TickmarkEntry
              tickmark={tickmark}
              currentlyExpandedTickmarkID={currentTickmarkId}
              updateExpandedTickmarkID={updateExpandedTickmarkID}
            />
          </div>
        ))}
      </ConditionalRender>
    </div>
  );
};

ElementFiltersTickmarkTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Revision id of current statement */
  revisionId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { ElementFiltersTickmarkTertiary };
export default connect(mapStateToProps)(ElementFiltersTickmarkTertiary);
