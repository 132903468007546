import { createAction } from 'redux-actions';
import {
  getWorkpapersForRevisionRequest,
  deleteWorkpaperRequest,
  detachElementWorkpaperRequest,
} from 'api/workpaper-api';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { updateCacheWithMostRecentElementChanges } from 'store/actions/element-changes-since-tracker-actions';
import { getElementsByWorkpaperReference } from 'api/element-api';
import {
  elementWorkpaperListLoading,
  getElementWorkpapersAndUpdateElementCacheWithParameters,
} from 'store/actions/element-panel-actions';

export const workpaperToolkitListLoading = createAction(
  'WORKPAPER_TOOLKIT_LIST_LOADING',
);
export const workpaperToolkitListLoaded = createAction(
  'WORKPAPER_TOOLKIT_LIST_LOADED',
);
export const workpaperToolkitListError = createAction(
  'WORKPAPER_TOOLKIT_LIST_ERROR',
);
export const deleteWorkpaperError = createAction('DELETE_WORKPAPER_ERROR');
export const fetchElementsFromWorkpaperAction = createAction(
  'FETCH_ELEMENTS_FROM_WORKPAPER',
);
export const clearWorkpaperElementsAction = createAction(
  'CLEAR_ELEMENTS_FROM_WORKPAPER',
);
export const updateWorkpaperElementsMapAction = createAction(
  'UPDATE_WORKPAPER_ELEMENTS_MAP_ACTION',
);
export const updateWorkpaperElementsMapFromArrayAction = createAction(
  'UPDATE_WORKPAPER_ELEMENTS_MAP_FROM_ARRAY_ACTION',
);

export const removeWorkpaperFromElementsMapAction = createAction(
  'REMOVE_WORKPAPER_FROM_ELEMENTS_MAP_ACTION',
);
export const unlinkElementFromElementsMapAction = createAction(
  'UNLINK_ELEMENT_FROM_ELEMENTS_MAP_ACTION',
);

export const removeElementsFromElementsMapWithSocketPayloadAction = createAction(
  'REMOVE_ELEMENTS_FROM_ELEMENTS_MAP_WITH_SOCKET_PAYLOAD_ACTION',
);
export const addWorkpaperToDeletedArrayAction = createAction(
  'ADD_TICKMARK_TO_DELETED_ARRAY_ACTION',
);
export const removeElementFromElementsMapAction = createAction(
  'REMOVE_ELEMENT_FROM_ELEMENTS_MAP_ACTION',
);
export const addWorkpaperToListAction = createAction(
  'ADD_WORKPAPER_TO_LIST_ACTION',
);
export const unlinkWorkpaperFromElementWithWebsocketPayload = createAction(
  'UNLINK_WORKPAPER_FROM_ELEMENT_WITH_WEBSOCKET_PAYLOAD',
);

export const updateWorkpaperWithSocketPayloadAction = createAction(
  'UPDATE_WORKPAPER_FROM_SOCKET_PAYLOAD_ACTION',
);

export const deleteWorkpaperWithSocketPayloadAction = createAction(
  'DELETE_WORKPAPER_FROM_SOCKET_PAYLOAD_ACTION',
);

export const fetchWorkpaperListForRevisionWithoutLoading = ({
  revisionId,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  try {
    const response = await getWorkpapersForRevisionRequest({ revisionId });
    dispatch(workpaperToolkitListLoaded({ response }));
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};

export const fetchWorkpaperListForRevision = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  dispatch(workpaperToolkitListLoading());
  await dispatch(fetchWorkpaperListForRevisionWithoutLoading({ revisionId }));
};

export const deleteWorkpaper = ({ workpaper }) => async (
  dispatch,
  getState,
) => {
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;

  dispatch(addWorkpaperToDeletedArrayAction(workpaper.workpaperRefId));
  await deleteWorkpaperRequest({ workpaper });
  const { id: revisionId } = getState().data.revision;
  if (socketHasBeenDisconnected) {
    dispatch(fetchWorkpaperListForRevisionWithoutLoading({ revisionId }));
    dispatch(updateCacheWithMostRecentElementChanges());
  }
};

export const fetchElementsFromWorkpaper = ({ revisionId, wpRefId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  try {
    const response = await getElementsByWorkpaperReference({
      revisionId,
      wpRefId,
    });
    dispatch(
      fetchElementsFromWorkpaperAction({
        response: response,
        wpRefId: wpRefId,
      }),
    );
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};

export const clearElementsFromWorkpaper = () => (dispatch) => {
  try {
    dispatch(clearWorkpaperElementsAction());
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};

export const removeElementFromElementsMap = ({ elementId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  const { id: revisionId } = getState().data.revision;

  try {
    await dispatch(fetchWorkpaperListForRevisionWithoutLoading({ revisionId }));
    dispatch(removeElementFromElementsMapAction(elementId));
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};

export const unlinkElementFromWorkpaperElementsList = ({
  revisionId,
  wpRefId,
  elementId,
  sectionId,
}) => async (dispatch, getState) => {
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;

  try {
    dispatch(elementWorkpaperListLoading());
    await detachElementWorkpaperRequest({
      elementId,
      revisionId,
      workpaperId: wpRefId,
    });
    if (socketHasBeenDisconnected) {
      await dispatch(
        getElementWorkpapersAndUpdateElementCacheWithParameters({
          elementId,
          sectionId,
        }),
      );
      dispatch(
        fetchElementsFromWorkpaper({
          revisionId,
          wpRefId,
        }),
      );
      dispatch(fetchWorkpaperListForRevisionWithoutLoading({ revisionId }));
    }
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};

export const removeWorkpaperFromElementsMapStore = ({ wpRefId }) => (
  dispatch,
) => {
  dispatch(removeWorkpaperFromElementsMapAction(wpRefId));
};

export const updateWorkpaperElementsMap = ({ response }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  const updatedElementsArray = response.data.result
    ? response.data.result.elementEntityList[0]
    : response;
  if (response && updatedElementsArray) {
    dispatch(updateWorkpaperElementsMapAction(updatedElementsArray));
  }
};
export const updateWorkpaperElementsMapFromArray = ({
  elementsArray,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  if (elementsArray) {
    dispatch(updateWorkpaperElementsMapFromArrayAction(elementsArray));
  }
};

export const unlinkElementFromElementsMap = ({ elementId, wpRefId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.WORKPAPER) return;
  const { id: revisionId } = getState().data.revision;
  try {
    await dispatch(fetchWorkpaperListForRevisionWithoutLoading({ revisionId }));
    dispatch(
      unlinkElementFromElementsMapAction({
        elementId: elementId,
        wpRefId: wpRefId,
      }),
    );
  } catch (error) {
    dispatch(workpaperToolkitListError(error));
  }
};
