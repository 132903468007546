import { handleActions } from 'redux-actions';
import {
  elementWorkpaperListLoading,
  elementWorkpaperListLoaded,
  elementWorkpaperListError,
  elementWorkpaperListFromWebSocketPayloadAction,
  removeWorkpaperFromElementPanelWithSocketPayload,
  elementWorkpaperListLoadedWithoutResponse,
  removeWPFromElementPanelAction,
  updateWPFromElementPanelAction,
} from 'store/actions/element-panel-actions';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';

export default handleActions(
  {
    [elementWorkpaperListLoading]: (workpaperList) => {
      return workpaperList.setLoading();
    },
    [elementWorkpaperListLoaded]: (workpaperList, { payload }) => {
      return workpaperList.setLoaded(payload);
    },
    [elementWorkpaperListLoadedWithoutResponse]: (workpaperList) => {
      return workpaperList.setLoadedWithoutResponse();
    },
    [elementWorkpaperListError]: (workpaperList, { payload }) => {
      return workpaperList.setError(payload);
    },
    [elementWorkpaperListFromWebSocketPayloadAction]: (
      workpaperList,
      { payload },
    ) => {
      return workpaperList.setWorkpaperListFromWebSocketPayload(payload);
    },
    [removeWorkpaperFromElementPanelWithSocketPayload]: (
      workpaperList,
      { payload },
    ) => {
      return workpaperList.removeWorkpaperFromElementPanelWithSocketPayload(
        payload,
      );
    },
    [removeWPFromElementPanelAction]: (workpaperList, { payload }) => {
      return workpaperList.removeWPFromElementPanel(payload);
    },
    [updateWPFromElementPanelAction]: (workpaperList, { payload }) => {
      return workpaperList.updateWPFromElementPanel(payload);
    },
  },
  new ElementWorkpaperList(),
);
