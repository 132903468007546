import { handleActions } from 'redux-actions';
import {
  initNotePanelFromSelectionAction,
  clearNoteSelectionAction,
} from 'store/actions/notes-panel-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import NoteSelection from 'models/data/note-content-selection-model';

export default handleActions(
  {
    [initNotePanelFromSelectionAction]: (selection, { payload }) => {
      return new NoteSelection(payload);
    },
    [clearNoteSelectionAction]: () => {
      return new NoteSelection();
    },
    [clearStatementPageStoreAction]: () => {
      return new NoteSelection();
    },
  },
  new NoteSelection(),
);
