import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';

const OMNIA_CONFIRM_MODAL_ID = 'omnia-confirm-modal-id';
export const OMNIA_CONFIRM_MODAL = 'omnia-confirm-modal';

const OmniaConfirmModal = ({ onClickYes, onClickNo }) => {
  return (
    <Modal
      id={OMNIA_CONFIRM_MODAL_ID}
      title={'omnia.confirm-modal.title'}
      onClose={onClickNo}
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: onClickNo,
        })
      }
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: onClickYes,
        })
      }
    >
      <div className={`${OMNIA_CONFIRM_MODAL}__guidance`}>
        <FormattedMessage id={'omnia.confirm-modal.body'} />
      </div>
      <FormattedMessage id={'omnia.confirm-modal.confirm-message'} />
    </Modal>
  );
};

OmniaConfirmModal.propTypes = {
  /** Action fired when user clicks the yes button */
  onClickYes: PropTypes.func.isRequired,
  /** Action fired when user clicks No || close button */
  onClickNo: PropTypes.func.isRequired,
};

export default OmniaConfirmModal;
