import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Checkbox from 'components/common/checkbox-component';
import TextInput from 'components/common/text-input-component';
import ProjectForm from 'models/form/project-form-model';
import Dropdown from 'components/common/dropdown-component';
import { isNullOrUndefined } from 'utils/object-utils';
import Chip from 'components/common/chip-component';
import Tooltip from 'components/common/tool-tip-component';

export const TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK =
  'tech-support-access-disclaimer-modal';
export const TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK =
  'tech-support-access-disclaimer-modal-id';
const MAX_STR_LENGTH = 20;

export const TechSupportAcknowledgeDisclaimerModal = ({
  onClose,
  onSubmit,
  onDisable,
  projectInProgress,
  setSupportId,
  setEnabledTechSupportStatementList,
  disableTechSupportEditByRole,
  onRemoveChip,
}) => {
  const [toggleAcknowledge, setToggleAcknowledge] = useState(false);
  useEffect(() => {
    if (
      isNullOrUndefined(projectInProgress.supportId) ||
      !projectInProgress.enabledTechSupportStatementList.length
    ) {
      setToggleAcknowledge(false);
    }
  }, [
    projectInProgress.supportId,
    projectInProgress.enabledTechSupportStatementList,
  ]);

  return (
    <Modal
      id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}`}
      title={
        'project-edit.manage-users.acknowledge-tech-support-access.disclaimer-modal-title'
      }
      primaryModalButton={
        new ModalButton({
          text: 'common.submit',
          onClick: onSubmit,
          disabled: !toggleAcknowledge,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'project-edit.manage-users.tech-support-access.disable-all-btn',
          onClick: onDisable,
          disabled: disableTechSupportEditByRole,
        })
      }
      onClose={onClose}
    >
      <div
        className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__tech-support-access`}
      >
        <div
          className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__support-ticket-id-input`}
        >
          <TextInput
            id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}-support-ticket-id`}
            className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__support-ticket-id`}
            isValid
            label="project-edit.manage-users.acknowledge-tech-support-access.support-ticket-id"
            onChange={setSupportId}
            placeholder="project-edit.manage-users.acknowledge-tech-support-access.support-ticket-id.placeholder"
            width={'100%'}
            type={'text'}
            value={projectInProgress.supportId}
            disabled={disableTechSupportEditByRole}
          />
        </div>
        <div
          className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__chip-container`}
        >
          {projectInProgress.enabledTechSupportStatementList.length > 0 &&
            projectInProgress.enabledTechSupportStatementList.map((key, i) => {
              return (
                <Chip
                  key={`statement-key-${key.id}`}
                  id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}-key`}
                  onRemove={() => onRemoveChip(key)}
                  disabled={disableTechSupportEditByRole}
                >
                  {key.title.length > MAX_STR_LENGTH ? (
                    <Tooltip
                      id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}_${i}__tooltip`}
                      text={key.title}
                    >
                      <span> {key.title.slice(0, MAX_STR_LENGTH) + '...'}</span>
                    </Tooltip>
                  ) : (
                    key.title
                  )}
                </Chip>
              );
            })}
        </div>
        <div
          className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__tech-support-statementlist`}
        >
          <Dropdown
            id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}-tech-support-statementlist`}
            placeholder={
              'project-edit.manage-users.acknowledge-tech-support-access.statements.placeholder'
            }
            label="project-edit.manage-users.acknowledge-tech-support-access.statements"
            width={'100%'}
            onSelectOption={setEnabledTechSupportStatementList}
            disabled={disableTechSupportEditByRole}
            isValid
            options={projectInProgress.statementsListForTechSupportOptions}
            value={null}
          />
        </div>
        <div className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-1`}>
          <FormattedMessage
            id={
              'project-edit.manage-users.acknowledge-tech-support-access.disclaimer-modal-message-first-paragraph'
            }
          />
        </div>
        <div className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-2`}>
          <FormattedMessage
            id={
              'project-edit.manage-users.acknowledge-tech-support-access.disclaimer-modal-message-first-paragraph1'
            }
          />
        </div>
        <div className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__checkbox`}>
          <Checkbox
            checked={toggleAcknowledge}
            onChange={() => {
              setToggleAcknowledge(!toggleAcknowledge);
            }}
            label={'project-edit.manage-users.acknowledge-tech-support-access'}
            id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}-disclaimer-checkbox`}
            width={'100%'}
            isValid
            disabled={
              isNullOrUndefined(projectInProgress.supportId) ||
              projectInProgress.enabledTechSupportStatementList.length === 0
            }
          />
        </div>
      </div>
    </Modal>
  );
};

TechSupportAcknowledgeDisclaimerModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /** function fired to submit acknowledgement */
  onSubmit: PropTypes.func.isRequired,
  /** function fired to desible All button */
  onDisable: PropTypes.func.isRequired,
  /** returns boolean value to disable supportid field to disable based on PPMD and EO role */
  disableTechSupportEditByRole: PropTypes.func.isRequired,
  /** function fired to remove respected statement the enabled statements list */
  onRemoveChip: PropTypes.func,
  /** Project object to edit and be submitted */
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Function fired to update tech suport access's supportTicketId on this project */
  setSupportId: PropTypes.func,
  /** Function fired to update tech suport access's Statementlist on this project */
  setEnabledTechSupportStatementList: PropTypes.func,
};
