import { handleActions } from 'redux-actions';
import SectionAssignments from 'models/api/section-assignments-api-model';
import {
  fetchSectionAssignmentsLoadedAction,
  fetchSectionAssignmentsLoadingAction,
  fetchSectionAssignmentsErrorAction,
  replaceNewWithSocketData,
  updateSectionAssignmentInBulkFromSocket,
} from 'store/actions/section-assignment-actions';

export default handleActions(
  {
    [fetchSectionAssignmentsLoadedAction]: (assignments, { payload }) => {
      return assignments.setLoaded(payload);
    },
    [fetchSectionAssignmentsLoadingAction]: (assignments, { payload }) => {
      return assignments.setLoading(payload);
    },

    [fetchSectionAssignmentsErrorAction]: (assignments, { payload }) => {
      return assignments.setError(payload);
    },
    [replaceNewWithSocketData]: (assignments, { payload }) => {
      return assignments.replaceNewWithSocketData(payload);
    },
    [updateSectionAssignmentInBulkFromSocket]: (assignments, { payload }) => {
      return assignments.updateSectionAssignmentInBulkFromSocket(payload);
    },
  },
  new SectionAssignments(),
);
