import { createAction } from 'redux-actions';
import { getSectionReviewersRequest } from 'api/sections-api';
import { fetchSectionHistoryDetailsWithoutLoading } from 'store/actions/section-review-history-actions';
import { fetchStatementSummarySections } from 'store/actions/statement-summary/sections-summary-actions';
import { sectionDetailsLoaded } from 'store/actions/statement-content-actions';
import { showSectionDetailsPanelAction } from 'store/actions/panel-controller-actions';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';
import { getUpdatedSectionElementsNotesSummaryCount } from '../../utils/heading-data-utils';

export const appendSectionToSectionReviewList = createAction(
  'SET_SECTION_LIST_STORE',
);

export const fetchSectionReviewsLoaded = createAction(
  'FETCH_SECTIONS_REVIEWS_LOADED',
);

export const fetchSectionReviewsLoading = createAction(
  'FETCH_SECTIONS_REVIEWS_LOADING',
);

export const fetchSectionReviewsError = createAction(
  'FETCH_SECTIONS_REVIEWS_ERROR',
);

export const removeSectionReviewFromStore = createAction(
  'REMOVE_SECTION_REVIEW_FROM_STORE',
);

export const fetchSectionReviews = () => async (dispatch, getStore) => {
  const { revision } = getStore().data;
  try {
    const response = await getSectionReviewersRequest(revision.id);
    dispatch(fetchSectionReviewsLoaded({ response }));
  } catch (error) {
    dispatch(fetchSectionReviewsError(error));
  }
};

export const fetchSectionReviewsWithLoading =
  () => async (dispatch, getStore) => {
    const { revision } = getStore().data;
    try {
      dispatch(fetchSectionReviewsLoading());
      const response = await getSectionReviewersRequest(revision.id);
      dispatch(fetchSectionReviewsLoaded({ response }));
    } catch (error) {
      dispatch(fetchSectionReviewsError(error));
    }
  };

export const handleSignOffSuccess =
  ({ data, sectionId }) =>
  async (dispatch, getState) => {
    const {
      data: { revision },
    } = getState();
    dispatch(appendSectionToSectionReviewList(data));
    await dispatch(fetchStatementSummarySections({ revisionId: revision.id }));
    dispatch(
      fetchSectionHistoryDetailsWithoutLoading({
        revisionId: revision.id,
        sectionId,
      }),
      dispatch(fetchSectionReviewsWithLoading()),
    );
  };

export const handleRemoveSignOffSuccess =
  (sectionId, appUserId) => async (dispatch, getState) => {
    const {
      data: { revision },
    } = getState();
    dispatch(removeSectionReviewFromStore({ sectionId, userId: appUserId }));
    await dispatch(fetchStatementSummarySections({ revisionId: revision.id }));
    dispatch(
      fetchSectionHistoryDetailsWithoutLoading({
        revisionId: revision.id,
        sectionId,
      }),
    );
  };

export const updateSelectedSectionPanel =
  ({ sectionId }) =>
  async (dispatch, getState) => {
    const { contentSectionMap } =
      getState().data.statementContent.sectionsCache;
    const section = contentSectionMap.get(sectionId);
    const {
      data: { revision },
    } = getState();
    dispatch(
      fetchSectionHistoryDetails({
        revisionId: revision.id,
        sectionId: sectionId,
      }),
    );
    let updatedSection = await getUpdatedSectionElementsNotesSummaryCount(
      revision,
      section,
    );

    dispatch(sectionDetailsLoaded(updatedSection));
    dispatch(showSectionDetailsPanelAction());
  };
