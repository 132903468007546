import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

import ElementDetails from './element-details-api-model';

const DEFAULT_PAGE_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_SELECTED_INDEX = 0;
export default class ElementsSearchResults extends ApiModel({
  data: {
    elements: [],
    first: DEFAULT_PAGE_OFFSET,
    size: DEFAULT_PAGE_SIZE,
    total: null,
  },
  selectedIndex: DEFAULT_SELECTED_INDEX,
  showElementSearchRefreshButton: false,
  batchSelectedItemsIds: {},
}) {
  get elements() {
    return this.data.elements;
  }

  get totalLoadedResults() {
    return this.data.elements.length;
  }

  get totalOverallResults() {
    if (!isNullOrUndefined(this.data.total)) {
      return this.data.total;
    }
    return 0;
  }

  get hasAllResults() {
    return this.totalOverallResults === this.totalLoadedResults;
  }

  processResponse({ response }) {
    const { elements = [], first } = response.data;
    const aggregatedElements = this.elements;
    const appendElements = first >= this.elements.length;
    if (appendElements) {
      elements.forEach((element) => {
        aggregatedElements.push(
          new ElementDetails().setLoaded({
            response: { data: { result: element } },
          }),
        );
      });
    }
    return {
      data: {
        ...response.data,
        elements: aggregatedElements,
      },
      selectedIndex: appendElements
        ? this.selectedIndex
        : DEFAULT_SELECTED_INDEX,
    };
  }

  hasElements() {
    return this.elements.length > 0;
  }

  updateElementList({ elements = [], total }) {
    const _elementMap = elements.reduce((elementMap, elementData) => {
      const payload = { response: { data: { result: elementData } } };
      elementMap[elementData.id] = new ElementDetails().setLoaded(payload);
      return elementMap;
    }, {});

    let _mergedElementList = [...this.elements, ...Object.values(_elementMap)];

    const _updatedElementList = _mergedElementList.map(
      (elementDetails) => _elementMap[elementDetails.id] || elementDetails,
    );

    return this.merge({
      selectedIndex: this.selectedIndex,
      data: {
        ...this.data,
        elements: [...new Set(_updatedElementList)],
        total: total ? total : this.data.total,
      },
    });
  }

  updateElementListFromSocketPayload({ elements = [], total }) {
    const _elementMap = elements.reduce((elementMap, elementData) => {
      const payload = { response: { data: { result: elementData } } };
      elementMap[elementData.id] = new ElementDetails().setLoaded(payload);
      return elementMap;
    }, {});

    const elementsList = [...this.data.elements];
    for (let i = 0; i < elementsList.length; i++) {
      const element = elementsList[i];
      if (element.id in _elementMap) {
        elementsList[i].data = {
          ...element.data,
          ..._elementMap[element.id].data,
        };
      }
    }

    return this.merge({
      selectedIndex: this.selectedIndex,
      data: {
        ...this.data,
        elements: [...new Set(elementsList)],
        total: total ? total : this.data.total,
      },
    });
  }

  removeFromElementList({ elementIds = [], total }) {
    const _elementMap = elementIds.reduce((elementMap, elementId) => {
      elementMap[elementId] = true;
      return elementMap;
    }, {});
    const _updatedElementList = this.elements.filter(
      ({ id }) => !_elementMap[id],
    );
    return this.merge({
      selectedIndex: DEFAULT_SELECTED_INDEX - 1,
      data: {
        ...this.data,
        elements: _updatedElementList,
        total,
      },
    });
  }

  emptyElementList() {
    return this.merge({
      data: { ...this.data, elements: [] },
      selectedIndex: DEFAULT_SELECTED_INDEX,
    });
  }

  setSelectedIndex(index) {
    return this.merge({ selectedIndex: index });
  }

  getElement({ elementId }) {
    return this.data.elements
      .find((element) => elementId === element.id)
      .filter((element) => element.included === 1);
  }
  hasElement(elementId) {
    return !isNullOrUndefined(
      this.data.elements.find((element) => elementId === element.id),
    );
  }

  setShowElementSearchRefreshButton(payload) {
    return this.merge({ showElementSearchRefreshButton: payload });
  }
  setBatchSelectedItemsIds(elements) {
    const elementsCopy = {};
    for (const elem of elements) {
      elementsCopy[elem.id] = true;
    }
    return this.merge({ batchSelectedItemsIds: elementsCopy });
  }
  removeIdFromBatchSelectedItems(elementId) {
    const elementsCopy = this.batchSelectedItemsIds;
    if (elementId in elementsCopy) {
      delete elementsCopy[elementId];
    }
    return this.merge({ batchSelectedItemsIds: elementsCopy });
  }
  addIdToBatchSelectedItemsIds(elementId) {
    const elementsCopy = this.batchSelectedItemsIds;
    elementsCopy[elementId] = true;
    return this.merge({ batchSelectedItemsIds: elementsCopy });
  }
}
