// imported/inspired by the 5.x code
import { SEGMENT_BLOCK } from 'components/feature/statement-content-panel/section-content-component';
import { ANNOTATION_BASE_BLOCK } from 'constants/feature/tieout-element-constants';
import {
  CONTENT_SEARCH_HIGHLIGHT_CLASS,
  REACT_SECTION_ID_PREFIX,
  STATEMENT_PSEUDO_ELEMENT_ID_PREFIX,
} from 'constants/feature/statement-content-constants';
import { NOTE_ANCHOR_BLOCK } from 'components/feature/statement-content-panel/note-anchor-component.jsx';

let elementSelectorBlacklist =
  "a[id^='CFTO_SECTION_'], a[name^='CFTO_ELEMENT_'], span[id='CFTO_WP_ICON'], span[id='CFTO_TICKMARK_ICON'], span[id='CFTO_FORMULA_RECALC_ICON'], span[id='CFTO_FORMULA_ICON'], span[class='icon-stm-internal-reference'], span[class='icon-stm-wp-reference'], span[class='icon-tickmark'], span[class='icon-stm-view-formula_recalc'], span[class='icon-stm-view-formula'], [class='note-indicator-statement'], [class='tieout-element'], [class='section-anchor'], [class='tieout-element tieout-element--selected tieout-element--selected--opened tieout-element--selected--opened'], [class='tieout-element tieout-element--selected tieout-element--selected--default tieout-element--selected--default']";

let ignoreAnnotation = {
  // items which needs to be ignored while adding an element, note, or section
  id: ['CFTO_TICKMARK_ICON', 'CFTO_FORMULA_RECALC_ICON', 'CFTO_FORMULA_ICON'],
  class: [
    `.${ANNOTATION_BASE_BLOCK}`,
    '.icon-stm-internal-reference',
    '.icon-stm-wp-reference',
    '.icon-tickmark',
    '.icon-stm-view-formula_recalc',
    '.icon-stm-view-formula',
    '.note-indicator-statement',
  ],
};

const ignoreTagsInSelector = ['EM', 'STRONG', 'B'];

let sectionIdSelector = `div[id^='${REACT_SECTION_ID_PREFIX}']`;

const isTextNote = (node) => node.nodeType === Node.TEXT_NODE;

const hasAncestorWithClassName = (node, className, maxDepth = 7) => {
  if (!node || !className) {
    return false;
  }
  let currentNode = node;
  let currentDepth = 0;
  while (currentNode && currentDepth < maxDepth) {
    if (String(currentNode.className).includes(className)) {
      return true;
    }
    currentNode = currentNode.parentNode;
    currentDepth++;
  }
  return false;
};

const matchesOrContainsNote = (range) => {
  const noteSelector = `*[class^='${NOTE_ANCHOR_BLOCK}']`;
  const contents = range.cloneContents();
  const { startContainer, endContainer } = range;
  // Check for note in content
  if (
    contents.querySelectorAll(noteSelector).length > 0 ||
    (contents.matches && contents.matches(noteSelector).length > 0) ||
    (contents.className && contents.className.includes(NOTE_ANCHOR_BLOCK))
  ) {
    return true;
  }
  // Check for note in ancestors
  if (
    (isTextNote(startContainer) &&
      hasAncestorWithClassName(
        startContainer,
        STATEMENT_PSEUDO_ELEMENT_ID_PREFIX,
      )) ||
    (isTextNote(endContainer) &&
      hasAncestorWithClassName(
        endContainer,
        STATEMENT_PSEUDO_ELEMENT_ID_PREFIX,
      ))
  ) {
    return true;
  }
  return false;
};

const matchesOrContainsSection = (range) => {
  const noteSelector = `*[class^='${NOTE_ANCHOR_BLOCK}']`;
  const contents = range.cloneContents();
  const { startContainer, endContainer } = range;
  // Check for section anchor in content
  if (
    contents.querySelectorAll(noteSelector).length > 0 ||
    (contents.matches && contents.matches(noteSelector).length > 0) ||
    (contents.className && contents.className.includes(NOTE_ANCHOR_BLOCK))
  ) {
    return true;
  }
  // Check for section anchor in ancestors
  if (
    (isTextNote(startContainer) &&
      hasAncestorWithClassName(startContainer, 'section-anchor')) ||
    (isTextNote(endContainer) &&
      hasAncestorWithClassName(endContainer, 'section-anchor'))
  ) {
    return true;
  }
  return false;
};

const matchSectionCount = (selection) =>
  selection.getRangeAt(0).cloneContents().querySelectorAll(sectionIdSelector)
    .length;

const matchTableCellCount = (selection) =>
  selection.getRangeAt(0).cloneContents().querySelectorAll('td').length;

const matchParagraphTagCount = (selection) => {
  return selection.getRangeAt(0).cloneContents().querySelectorAll('p').length;
};

const isInsideTableCells = (selection) => {
  let parent = selection.getRangeAt(0).commonAncestorContainer;

  while (parent.nodeType === Node.TEXT_NODE) {
    parent = parent.parentNode;
  }
  return parent.closest('td');
};

const NOTE_INDICATOR_ = 'NOTE_INDICATOR_';

const matchSectionOrElement = (selection) =>
  selection
    .getRangeAt(0)
    .cloneContents()
    .querySelectorAll(elementSelectorBlacklist).length > 0 ||
  !!selection.focusNode.parentNode.id.match(/CFTO_ELEMENT_|CFTO_SECTION_/) ||
  !!selection.focusNode.parentNode.parentNode.id.match(
    /CFTO_ELEMENT_|CFTO_SECTION_/,
  );

export const getStartCharacterOffsetWithin = (range, element) => {
  let offset = 0;

  let preRange = range.cloneRange(),
    preRangeText,
    preRangeEndContainer = range.startContainer,
    preRangeEndOffset = range.startOffset;

  let startContainer =
    range.startContainer.nodeType === Node.TEXT_NODE
      ? range.startContainer.parentNode
      : range.startContainer;

  if (-1 !== ignoreAnnotation.id.indexOf(startContainer.id)) {
    // resetting startOffset to zero when selected text is starting with annotation which needs to ignored
    preRangeEndOffset = 0;
  }

  preRange.selectNodeContents(element);
  preRange.setEnd(preRangeEndContainer, preRangeEndOffset);
  preRange = removeContentWithIgnorableSelectors({
    range: preRange,
    ignorable: ignoreAnnotation.class,
  });

  preRangeText = preRange.toString();
  offset = preRangeText.length;

  return offset;
};

export const getEndCharacterOffsetWithin = (range, element) => {
  let preRange = range.cloneRange();
  preRange.selectNodeContents(element);
  preRange.setEnd(range.endContainer, range.endOffset);
  preRange = removeContentWithIgnorableSelectors({
    range: preRange,
    ignorable: ignoreAnnotation.class,
  });
  return preRange.toString().length;
};

export const getContentLength = (range, originalLength) => {
  if (
    range.commonAncestorContainer &&
    range.commonAncestorContainer.parentElement
  ) {
    const parentElement = range.commonAncestorContainer.parentElement.nodeName;

    // This is a special case - if the parent node contains 'EM', 'STRONG',
    // or 'B' then we need to add 1 to the length so it won't cut the last
    // selected character
    if (ignoreTagsInSelector.includes(parentElement)) {
      return originalLength + 1;
    }
  }
  return originalLength;
};

/**
 * Filters ignorable content, like element annotations, out of the current range selection
 * useful so we do not include Tieout text content inserted into the document, like element annotations and icons
 * @param {Range} param.range current range that may include content that should be ignored
 */
const getRangeMinusIgnorableContent = ({ range }) => {
  let rangeClone = range.cloneRange();
  rangeClone = removeContentWithIgnorableSelectors({
    range: rangeClone,
    ignorable: ignoreAnnotation.class,
  });

  return rangeClone;
};

const removeContentWithIgnorableSelectors = function ({ range, ignorable }) {
  let clonedRange = range.cloneRange(),
    contentFragment = range.cloneContents(),
    ignoreElement = contentFragment.querySelectorAll(ignorable.join(','));

  if (0 < ignoreElement.length) {
    // if selection contains ignorable content('i.e., text based annotation')

    ignoreElement.forEach((x) => x.parentNode.removeChild(x));
    clonedRange.selectNodeContents(contentFragment); // setting new range content after ignorable element has removed

    return clonedRange;
  }

  return range;
};

const getElementCSSXPath = function (element, ignoreTag = true) {
  let array = [];
  let el = element;

  try {
    while (el && el.className !== SEGMENT_BLOCK) {
      let siblings = 0,
        sib = el.previousSibling;
      if (sib !== null) {
        while (sib !== null) {
          if (
            sib.tagName === el.tagName &&
            sib.id.indexOf(NOTE_INDICATOR_) === -1
          ) {
            siblings++;
          }
          sib = sib.previousSibling;
        }
      }

      array.push(el.tagName + (siblings ? ':eq(' + siblings + ')' : ':eq(0)'));
      el = el.parentNode;
    }
    array.reverse();
    // Remove trailing tag names in "ignoreTagsInSelector"
    if (ignoreTag)
      for (let i = array.length - 1; i > 0 && i === array.length - 1; i--) {
        const selector = array[i];
        const [tagName] = selector.split(':');
        if (ignoreTagsInSelector.includes(tagName)) {
          array.pop();
        }
      }
    return array.join('>');
  } catch (error) {
    return '';
  }
};

/**
 * Function for determining a safe parent for XPATH creation when adding content like notes/sections/elements to a section
 * The parent cannot be a text node nor can it be a tag that was INSERTED by the FE like the mark.`${CONTENT_SEARCH_HIGHLIGHT_CLASS}` that
 * are injected when doing content searching
 * @param {Range} param.range current window.getSelection().rangeAt(0)
 */
const getSafeParentForRange = ({ range }) => {
  return getSafeParentForNode(range.startContainer);
};

const isTextContentEmpty = (node) =>
  String(node.textContent).trim().length === 0;

const getSafeParentForNode = (node) => {
  let parent = node;
  while (
    parent.nodeType === Node.TEXT_NODE ||
    String(parent.className).includes(CONTENT_SEARCH_HIGHLIGHT_CLASS) ||
    isTextContentEmpty(parent)
  ) {
    if (isTextContentEmpty(parent) && parent.nextSibling) {
      parent = parent.nextSibling;
      while (parent.firstChild) {
        parent = parent.firstChild;
      }
    } else {
      parent = parent.parentNode;
    }
  }
  return parent;
};

export {
  matchSectionOrElement,
  getElementCSSXPath,
  getRangeMinusIgnorableContent,
  matchSectionCount,
  matchesOrContainsNote,
  matchesOrContainsSection,
  matchTableCellCount,
  getSafeParentForRange,
  getSafeParentForNode,
  isInsideTableCells,
  matchParagraphTagCount,
};
