import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Detach } from 'icons/unlink.svg';
import {
  ComfortLetterEntry,
  COMFORT_LETTER_ENTRY_BLOCK,
} from './comfort-letter-entry-component';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const DETACH_ICON_SIZE = '28px';

const COMFORT_LETTER_DETACH_TOOLTIP = new TooltipOptions({
  text: 'common.detach',
  id: 'comfort-letter-unlink-button-tool-tip',
  position: 'left',
});

const ComfortLetterEntryWithDetach = ({
  comfortLetter,
  onDetachComfortLetter,
  disabled,
  detachIcon,
  tooltip = COMFORT_LETTER_DETACH_TOOLTIP,
  currentlyExpandedComfortLetterID,
  updateExpandedComfortLetterID,
}) => {
  return (
    <ComfortLetterEntry
      comfortLetter={comfortLetter}
      currentlyExpandedComfortLetterID={currentlyExpandedComfortLetterID}
      updateExpandedComfortLetterID={updateExpandedComfortLetterID}
      rightComponent={
        <IconButton
          id={`detach-comfort-letter-${comfortLetter.comfortLetterId}`}
          className={`${COMFORT_LETTER_ENTRY_BLOCK}__detach`}
          tooltip={tooltip}
          onClick={() => onDetachComfortLetter({ comfortLetter })}
          Icon={detachIcon}
          iconSize={DETACH_ICON_SIZE}
          disabled={disabled}
        />
      }
    />
  );
};

ComfortLetterEntryWithDetach.defaultProps = {
  detachIcon: Detach,
};

ComfortLetterEntryWithDetach.propTypes = {
  /** Comformt Letter object from the comfort letter list endpoint */
  comfortLetter: PropTypes.object.isRequired,
  /** action fired to detach a comfort letter from this element */
  onDetachComfortLetter: PropTypes.func.isRequired,
  /** boolean to disable the button */
  disabled: PropTypes.bool.isRequired,
  /** svg to use as detach icon */
  detachIcon: PropTypes.object,
  /**tooltip to be shown on hover */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /* function to set the currently expanded comfort letter id after clicking Show More button */
  updateExpandedComfortLetterID: PropTypes.func,
  /** Comfort Letter id of the comfort letter expanded on the toolkit panel after clicking Show More button */
  currentlyExpandedComfortLetterID: PropTypes.number,
};

export default memo(ComfortLetterEntryWithDetach);
