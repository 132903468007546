import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onSectionClick } from 'store/actions/statement-content-actions';
import SectionAnchor from 'components/feature/statement-content-panel/section-anchor-component';
import { SectionReview } from 'components/feature/statement-content-panel/_section-Review-Tooltip';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  toggleFormulaCancelModal,
  openSectionPanelAfterCancelConfirmAction,
  storeSelectedSectionIdAction,
  toggleInternalReferenceCancelModal,
} from 'store/actions/panel-cancel-modal-actions';

export const SectionAnchorHOC = (props) => {
  const {
    sectionReview,
    leftSideView,
    sectionId,
    leftSectionReview,
    onSectionClick,
    toggleFormulaCancelModal,
    openSectionPanelAfterCancelConfirmAction,
    storeSelectedSectionIdAction,
    toggleInternalReferenceCancelModal,
    selectModeId,
  } = props;
  const pathName = useLocation().pathname;

  const { isSectionSelected, section, color } = useSelector((store) => {
    const selectedSection =
      pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE) && leftSideView
        ? store.data.leftStatementContent.leftSelectedSection[sectionId]
        : store.ui.statementPage.selectedSectionsMap[sectionId];

    const contentSectionMap =
      pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE) && leftSideView
        ? store.data.leftStatementContent.leftSectionsCache
            .leftContentSectionMap
        : store.data.statementContent.sectionsCache.contentSectionMap;
    return {
      isSectionSelected: !!selectedSection,
      color: selectedSection ? selectedSection.color : null,
      section: Object.values(contentSectionMap.map).find(
        (entry) => entry.id === sectionId,
      ),
    };
  });

  const handleElementClick = (sectionId, color) => {
    if (pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      return () => {};
    } else {
      if (selectModeId === ELEMENT_SELECT_MODES.FORMULA.id) {
        toggleFormulaCancelModal(true);
        storeSelectedSectionIdAction(sectionId);
        openSectionPanelAfterCancelConfirmAction(true);
      } else if (selectModeId === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id) {
        toggleInternalReferenceCancelModal(true);
        storeSelectedSectionIdAction(sectionId);
        openSectionPanelAfterCancelConfirmAction(true);
      } else {
        onSectionClick({ sectionId, color: color, sectionClick: true });
      }
    }
  };

  return (
    <SectionAnchor
      {...props}
      isCursorAlias={leftSideView}
      shouldSectionAnchorDisabled={
        pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
          ? !leftSideView
          : leftSideView
      }
      handleElementClick={handleElementClick}
      isSectionSelected={isSectionSelected}
      section={section}
      sectionReview={leftSideView ? leftSectionReview : sectionReview}
      color={color}
      sectionReviewTooltip={() => (
        <SectionReview
          {...props}
          sectionReview={leftSideView ? leftSectionReview : sectionReview}
        />
      )}
    ></SectionAnchor>
  );
};

const mapStateToProps = ({
  data: { sectionPanel, leftStatementContent },
  ui: {
    statementPage: {
      modes: { selectMode },
    },
  },
}) => ({
  sectionReview: sectionPanel.sectionReviewList,
  sectionAssignments: sectionPanel.sectionAssignmentsList,
  leftSectionReview: leftStatementContent.leftSectionReviewsList,
  selectModeId: selectMode.id,
});

const mapDispatchToProps = {
  onSectionClick,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  storeSelectedSectionIdAction,
  openSectionPanelAfterCancelConfirmAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionAnchorHOC);
