import ApiModel from 'models/api-model';
import mexp from 'math-expression-evaluator';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  formatNumberWithCommas,
  removeCommasFromNumber,
  removeDuplicateLeadingZerosFromNumber,
  addLeadingZeroToEmptyDecimal,
} from 'utils/number-formatter-util';
import FormulaRowOperator from 'models/data/formula/formula-row-operator-model';
import FormulaRowNumber from 'models/data/formula/formula-row-number-model';
import FormulaRowElement from 'models/data/formula/formula-row-element-model';
import {
  FORMULA_CREATION_TYPES,
  FORMULA_ROW_OPERATOR_OPTIONS_MAP,
  FORMULA_OPERATOR_OPTIONS_MAP,
  FORMULA_ROW_TYPES,
  MATH_EXPRESSION_EVAL_INFINITY_RESULT,
  FORMULA_ELEMENT_CARRY_FORWARD_STATUS_TYPES,
} from 'constants/feature/formula-constants';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { round } from 'utils/formula-utils';
export default class FormulaForm extends ApiModel({
  data: {
    elementId: null,
    revisionId: null,
    formulaId: null,
    result: 0,
    display: null,
    operator: null,
    rows: [],
    selectedRow: null,
    computedResult: 0,
    roundingResult: null,
    roundingScale: null,
  },
  calculationError: false,
  hasBeenModified: false,
  isNewFormula: false,
}) {
  get formulaId() {
    if (this.data) {
      return this.data.formulaId;
    }
    return null;
  }
  get elementId() {
    if (this.data) {
      return this.data.elementId;
    }
    return null;
  }
  get revisionId() {
    if (this.data) {
      return this.data.revisionId;
    }
    return null;
  }
  get display() {
    if (this.data) {
      return this.data.display;
    }
    return null;
  }
  get operator() {
    if (this.data) {
      return this.data.operator;
    }
    return null;
  }

  get result() {
    if (this.data) {
      return this.data.result;
    }
    return null;
  }
  get computedResult() {
    if (this.data) {
      return this.data.computedResult;
    }
    return null;
  }
  get roundingResult() {
    if (this.data) {
      return this.data.roundingResult;
    }
    return null;
  }
  get roundingScale() {
    if (this.data) {
      return this.data.roundingScale;
    }
    return null;
  }
  get rows() {
    if (this.data) {
      return this.data.rows;
    }
    return [];
  }
  get selectedRow() {
    if (this.data) {
      return this.data.selectedRow;
    }
    return null;
  }

  getRow(index) {
    if (this.hasRows() && index >= 0 && this.data.rows.length > index) {
      return this.data.rows[index];
    }
    return null;
  }

  initFormulaCreate(elementDetails) {
    return this.merge({
      isLoaded: true,
      isLoading: false,
      error: null,
      data: {
        ...this.data,
        revisionId: elementDetails.revisionId,
        elementId: elementDetails.id,
        display: formatNumberWithCommas(elementDetails.amount),
        operator: FORMULA_OPERATOR_OPTIONS_MAP.get('='),
        result: 0,
        computedResult: 0,
        roundingResult: null,
        roundingScale: null,
      },
    });
  }

  _initCalculationError(formulaRows) {
    return !formulaRows
      .filter(
        (row) =>
          !(
            row.type === FORMULA_ROW_TYPES.OPERATOR &&
            (row.value === '(' || row.value === ')')
          ),
      )
      .every((row, index) => {
        if (index % 2 === 0) {
          return (
            row.type === FORMULA_ROW_TYPES.NUMBER ||
            row.type === FORMULA_ROW_TYPES.ELEMENT
          );
        } else {
          return row.type === FORMULA_ROW_TYPES.OPERATOR;
        }
      });
  }

  _updateFormulaRowsAndCalculateResult({ newRows }) {
    let calculationError = this._initCalculationError(newRows);
    try {
      //We try parsing the new expression.
      const result = this._calculateResult(newRows);
      const roundedNumber = !isNullOrUndefined(this.roundingScale)
        ? round(result, this.roundingScale)
        : null;
      const isRoundingEqualToResult =
        !isNullOrUndefined(roundedNumber) && result === roundedNumber;
      return this.merge({
        hasBeenModified: true,
        calculationError,
        data: {
          ...this.data,
          result,
          roundingResult: isRoundingEqualToResult
            ? null
            : !isNullOrUndefined(roundedNumber)
            ? roundedNumber
            : null,
          computedResult: result,
          roundingScale: isRoundingEqualToResult
            ? null
            : !isNullOrUndefined(this.roundingScale)
            ? this.roundingScale
            : null,
          rows: newRows,
        },
      });
    } catch (error) {
      //If the result cannot be parsed, that means it isn't an actually valid expression.
      calculationError = true;
    }
    return this.merge({
      data: { ...this.data, rows: newRows },
      hasBeenModified: true,
      calculationError,
    });
  }

  processResponse({ response }) {
    const { result } = response.data;
    return {
      data: {
        ...result,
        result: result.computedResult,
        computedResult: result.computedResult,
        roundingResult: !isNullOrUndefined(result.roundingResult)
          ? result.roundingResult
          : result.computedResult,
        roundingScale: result.roundingScale,
        display: formatNumberWithCommas(result.result),
        formulaId: result.id,
        operator: new MenuOption({
          title: result.operator,
          value: result.operator,
          isIntl: false,
        }),
        rows: result.formulaElementDtoList.map((formulaElementDto) => {
          switch (formulaElementDto.type) {
            case FORMULA_ROW_TYPES.ELEMENT: {
              if (
                // eslint-disable-next-line eqeqeq
                formulaElementDto.carryForwardStatus ==
                FORMULA_ELEMENT_CARRY_FORWARD_STATUS_TYPES.MISSING
              ) {
                return new FormulaRowNumber({
                  value: formulaElementDto.includedElementAmount,
                });
              } else {
                return new FormulaRowElement({
                  elementData: {
                    id: formulaElementDto.includedElementId,
                    amount: formulaElementDto.includedElementAmount,
                    elementActive: formulaElementDto.elementActive,
                  },
                });
              }
            }
            case FORMULA_ROW_TYPES.NUMBER: {
              return new FormulaRowNumber({
                value: formulaElementDto.value,
              });
            }
            case FORMULA_ROW_TYPES.OPERATOR: {
              return new FormulaRowOperator({
                value: formulaElementDto.value,
              });
            }
            default: {
              console.error('INVALID FORMULA ROW TYPE');
              return null;
            }
          }
        }),
      },
    };
  }

  /**
   * The utility function that we use to calculate the total of a formula based on its rows.
   * @param {[FormulaRowModel]} formulaRows: The array of formula rows used to calculate the total.
   * @return {number} result: The evaluation of all the rows as a mathematical expression
   * @throws {Error} error: In the event that the total cannot be computed (due to improperly formatted numbers in the rows)
   *                        an error is thrown, which must be caught at a higher level.
   */
  _calculateResult(formulaRows) {
    let result = this.data.result;
    let resultString = '';
    formulaRows.forEach((row) => {
      resultString += row.value;
    });
    if (resultString) {
      //If not empty and there are values, try to parse it.
      result = mexp.eval(resultString);
    } else {
      result = 0;
    }
    return result
      .toString()
      .includes(`-${MATH_EXPRESSION_EVAL_INFINITY_RESULT}`)
      ? MATH_EXPRESSION_EVAL_INFINITY_RESULT
      : result;
  }

  addManualRow() {
    let newRows = [];
    if (this._shouldAddOperatorBeforeRow()) {
      newRows.push(new FormulaRowOperator());
    }
    newRows.push(new FormulaRowNumber());
    return this.merge({
      ...this._updateFormulaRowsAndCalculateResult({
        newRows: [...this.data.rows, ...newRows],
      }),
      isLoading: false,
      isLoaded: true,
    });
  }

  _shouldAddOperatorBeforeRow() {
    const _previousRowIsValue = this.hasRows() && !this.isLastRowOperator();
    const _previousOperatorIsClosedParenthesis =
      this.hasRows() && this.isLastRowClosedParenthesis();
    return _previousRowIsValue || _previousOperatorIsClosedParenthesis;
  }

  addOperator() {
    return this.merge({
      ...this._updateFormulaRowsAndCalculateResult({
        newRows: [...this.data.rows, new FormulaRowOperator()],
      }),
      isLoading: false,
      isLoaded: true,
    });
  }

  isLastRowOperator() {
    return this.hasRows() && this.getLastRow().isOperator();
  }

  isLastRowClosedParenthesis() {
    return (
      this.isLastRowOperator() &&
      this.getLastRow().operator === FORMULA_ROW_OPERATOR_OPTIONS_MAP.get(')')
    );
  }

  removeRow({ formulaRowIndex }) {
    let formulaRows = [...this.data.rows];
    let formulaRow = this.getRow(formulaRowIndex);
    let prevFormulaRow = this.getRow(formulaRowIndex - 1);
    const _shouldRemovePrevOperatorAsWell =
      !isNullOrUndefined(prevFormulaRow) &&
      prevFormulaRow.isOperator() &&
      !prevFormulaRow.isOperatorParenthesis();

    if (!isNullOrUndefined(formulaRow)) {
      if (_shouldRemovePrevOperatorAsWell) {
        // remove operator row (prev) and value row
        formulaRows.splice(formulaRowIndex - 1, 2);
      } else {
        // just remove value row
        formulaRows.splice(formulaRowIndex, 1);
      }
    }
    return this._updateFormulaRowsAndCalculateResult({
      newRows: formulaRows,
    });
  }

  removeRowOperator(index) {
    let formulaRows = [...this.data.rows];

    if (this.getRow(index).isOperator()) {
      formulaRows.splice(index, 1);
      return this._updateFormulaRowsAndCalculateResult({
        newRows: formulaRows,
      });
    }
    // should never happen, function should only be called for operator rows
    return this;
  }

  hasRows() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.rows) &&
      this.data.rows.length > 0
    );
  }

  setOperator(operator) {
    return this.merge({
      data: { ...this.data, operator },
      hasBeenModified: true,
    });
  }

  setManualNumber({ index, number }) {
    let formulaRows = this._updateRowAtIndexAndGetRows({ index, number });
    return this._updateFormulaRowsAndCalculateResult({ newRows: formulaRows });
  }

  _updateRowAtIndexAndGetRows({ index, number }) {
    let formulaRows = [...this.data.rows];
    let formulaRow = this.getRow(index);
    if (!isNullOrUndefined(formulaRow) && formulaRow.isManual()) {
      //We have to ensure that zeros are properly accounted for in our number.
      const formattedValue = addLeadingZeroToEmptyDecimal(
        removeDuplicateLeadingZerosFromNumber(removeCommasFromNumber(number)),
      );
      formulaRow = formulaRow.setValue(formattedValue);
      formulaRows[index] = formulaRow;
    }
    return formulaRows;
  }

  disableAddOperator() {
    const lastRow = this.getLastRow();
    if (this.hasRows() && lastRow.isOperator()) {
      const _lastOperatorIsOpenParenthesis =
        !isNullOrUndefined(lastRow.operator) &&
        lastRow.operator.value ===
          FORMULA_ROW_OPERATOR_OPTIONS_MAP.get('(').value;
      return _lastOperatorIsOpenParenthesis;
    } else if (this.hasRows()) {
      return !this.areManualInputsRealNumbers();
    }
    return false;
  }

  disableAddRow() {
    if (this.hasRows()) {
      return !this.areManualInputsRealNumbers();
    }
    return false;
  }

  getLastRow() {
    if (this.hasRows()) {
      return this.data.rows[this.data.rows.length - 1];
    }
    return null;
  }

  setRowOperator({ operator, index }) {
    let formulaRows = this._getRowsWithUpdatedOperator({ index, operator });
    return this._updateFormulaRowsAndCalculateResult({ newRows: formulaRows });
  }

  _getRowsWithUpdatedOperator({ operator, index }) {
    let formulaRows = [...this.data.rows];
    let formulaRow = this.getRow(index);
    if (!isNullOrUndefined(formulaRow)) {
      formulaRow = formulaRow.setOperator(operator);
      formulaRows[index] = formulaRow;
    }
    return formulaRows;
  }

  removeAllRows() {
    return this._updateFormulaRowsAndCalculateResult({
      newRows: [],
    });
  }

  areParenthesesBalanced() {
    let parenthesisBalanceStack = [];

    this.rows.forEach((row) => {
      if (row.isOperator()) {
        parenthesisBalanceStack.push(row.operator.value);
      }
    });

    /** This increases the index which is innitially set to 0
     * for anywhere within the parenthesisBalanceStack array where there is a '('
     * and decreaments whenever there is a ')'
     * Should result to zero ultimately if the array of operators is balanced
     * Since we are getting a number back we want to set a balanced formula of return
     * 0 to true hence '!' in the begining
     */
    return !parenthesisBalanceStack.reduce((index, currentChar) => {
      if (currentChar === '(') {
        return ++index;
      } else if (currentChar === ')') {
        return --index;
      }
      return index;
    }, 0);
  }

  // this method returns the index of the decimal place
  // if the value is an integer or not a number, we return 0
  _findDecimalIndex(value) {
    if (
      Number.isInteger(value) &&
      !isNaN(parseFloat(value)) &&
      isFinite(value)
    ) {
      return 0;
    }
    return value.toString().length - (value.toString().indexOf('.') + 1);
  }

  areManualInputsRealNumbers() {
    /** Checking if manually created formula row already have an error  */
    return !this.rows.some((row) => row.hasRowError());
  }

  shouldValueBeComparedToRoundedValue(elementDetails) {
    return (
      elementDetails &&
      elementDetails.data &&
      // id 1 is "Curreny" and id 2 is "Percentage"
      (elementDetails.data.unitsId === 1 ||
        elementDetails.data.unitsId === 2) &&
      this.data &&
      this.data.rows.some((data) => data.value === '/')
    );
  }

  isFormulaFlagged(elementDetails = {}) {
    const formattedResultValue = parseFloat(
      removeCommasFromNumber(
        !isNullOrUndefined(this.data.roundingResult)
          ? this.data.roundingResult
          : this.data.result,
      ),
    );
    const formattedDisplayValue = parseFloat(
      removeCommasFromNumber(this.data.display),
    );
    const shouldValueBeComparedToRoundedValue =
      this.shouldValueBeComparedToRoundedValue(elementDetails);

    const precision = Math.pow(
      10,
      this._findDecimalIndex(formattedDisplayValue),
    );

    const calculateRoundedResultValue = (formattedResultValue, precision) => {
      if (formattedResultValue < 0) {
        return (
          -1 *
          (Math.round(Math.abs(formattedResultValue) * precision) / precision)
        );
      }
      return Math.round(formattedResultValue * precision) / precision;
    };

    const roundedResultValue = calculateRoundedResultValue(
      formattedResultValue,
      precision,
    );

    switch (this.data.operator && this.data.operator.value) {
      case '=':
        if (shouldValueBeComparedToRoundedValue) {
          return roundedResultValue !== formattedDisplayValue;
        }
        return formattedResultValue !== formattedDisplayValue;
      case '>':
        if (shouldValueBeComparedToRoundedValue) {
          return !(formattedDisplayValue > roundedResultValue);
        }
        return !(formattedDisplayValue > formattedResultValue);
      case '<':
        if (shouldValueBeComparedToRoundedValue) {
          return !(formattedDisplayValue < roundedResultValue);
        }
        return !(formattedDisplayValue < formattedResultValue);
      case '>=':
        if (shouldValueBeComparedToRoundedValue) {
          return !(formattedDisplayValue >= roundedResultValue);
        }
        return !(formattedDisplayValue >= formattedResultValue);
      case '<=':
        if (shouldValueBeComparedToRoundedValue) {
          return !(formattedDisplayValue <= roundedResultValue);
        }
        return !(formattedDisplayValue <= formattedResultValue);
      default:
        return false;
    }
  }

  setElementRowsLoaded({ selectedElements }) {
    let formulaRows = [...this.data.rows];
    const lastRow = this.getLastRow();
    const _lastRowIsEmptyManual =
      !isNullOrUndefined(lastRow) && lastRow.isManual() && !lastRow.hasValue();
    selectedElements.forEach((elementData, index) => {
      const _isFirstElementOfNewSelection = index === 0;
      const _shouldAddOperator =
        !_isFirstElementOfNewSelection ||
        (_isFirstElementOfNewSelection && this._shouldAddOperatorBeforeRow());
      if (this.selectedRow !== null) {
        const rowNumber = this.selectedRow;
        //check if the selected row input is empty
        const __selectedRowIsEmptyManual =
          !isNullOrUndefined(formulaRows[rowNumber]) &&
          formulaRows[rowNumber].isManual() &&
          !formulaRows[rowNumber].hasValue();
        if (__selectedRowIsEmptyManual) {
          formulaRows[rowNumber] = new FormulaRowElement({
            elementData,
          });
        } else {
          // if input of manual row is not empty, it is going to create a new row as last row of the formula
          if (_shouldAddOperator) {
            formulaRows.push(new FormulaRowOperator());
          }
          formulaRows.push(new FormulaRowElement({ elementData }));
        }
      } else {
        if (_isFirstElementOfNewSelection && _lastRowIsEmptyManual) {
          // situation where we are on first selection and need to convert the existing last row instead
          // of add a new one

          formulaRows[formulaRows.length - 1] = new FormulaRowElement({
            elementData,
          });
        } else {
          if (_shouldAddOperator) {
            formulaRows.push(new FormulaRowOperator());
          }
          formulaRows.push(new FormulaRowElement({ elementData }));
        }
      }
    });
    return this.merge({
      ...this._updateFormulaRowsAndCalculateResult({
        newRows: formulaRows,
      }),
      isLoading: false,
      isLoaded: true,
    });
  }

  isResultValueValid() {
    let result = this.data.result;
    if (
      isNaN(result) ||
      (typeof result === 'number' && /e[+-]/.test(result.toString()))
    ) {
      result = MATH_EXPRESSION_EVAL_INFINITY_RESULT;
    }
    const isResultNegative = result.toString()[0] === '-';
    if (isResultNegative) {
      const resultWithoutMinus = this.data.result.toString().slice(1);
      result = mexp.eval(resultWithoutMinus);
    }
    return result !== MATH_EXPRESSION_EVAL_INFINITY_RESULT;
  }

  isFormulaValid() {
    return (
      !this.calculationError &&
      this.hasRows() &&
      this.areParenthesesBalanced() &&
      this.areManualInputsRealNumbers() &&
      this.isResultValueValid()
    );
  }

  toApiFormat() {
    return {
      id: !isNullOrUndefined(this.data.formulaId) ? this.data.formulaId : 0,
      revisionId: this.data.revisionId,
      elementId: this.data.elementId,
      operator: this.data.operator.value,
      creationType: FORMULA_CREATION_TYPES.MANUAL,
      roundingResult: this.data.roundingResult,
      roundingScale: this.data.roundingScale,
      result: this.data.result,
      formulaElementDtoList: this._rowsToApiFormat(),
    };
  }

  _rowsToApiFormat() {
    let apiRows = [];
    const { revisionId, elementId } = this.data;
    this.data.rows.forEach((row) => {
      if (row.isOperator()) {
        apiRows.push({
          revisionId,
          elementId,
          type: FORMULA_ROW_TYPES.OPERATOR,
          value: row.operator.value,
        });
      }
      if (row.isManual()) {
        apiRows.push({
          revisionId,
          elementId,
          type: FORMULA_ROW_TYPES.NUMBER,
          value: Number.parseFloat(row.value),
        });
      }
      if (row.isElement()) {
        apiRows.push({
          revisionId,
          elementId,
          type: FORMULA_ROW_TYPES.ELEMENT,
          includedElementId: row.elementId,
          includedElementAmount: row.value,
        });
      }
    });
    return apiRows;
  }

  isCreate() {
    const { formulaId } = this.data;
    /** formulaId is null when we creating a formula */
    return isNullOrUndefined(formulaId);
  }
  setSelectedRow(payload) {
    const selectedFormulaRow = payload;
    return this.mergeData({ selectedRow: selectedFormulaRow });
  }
  setComputedResult(result) {
    return this.merge({
      data: { ...this.data, computedResult: result },
      hasBeenModified: true,
    });
  }
  setRoundingResultAndScale(payload) {
    const { newValue, roundNumberInputValue } = payload;
    return this.merge({
      data: {
        ...this.data,
        roundingResult: newValue,
        roundingScale: roundNumberInputValue,
      },
      hasBeenModified: true,
    });
  }
  isFormulaRounded() {
    return (
      this.roundingResult !== null &&
      this.roundingResult !== this.computedResult
    );
  }
  setNewFormula(value) {
    return this.merge({
      isNewFormula: value,
    });
  }

  removeFormulaWithSocketPayload(payload) {
    let formulaRows = [...this.data.rows];
    let formulaDtoList = [...this.data.formulaElementDtoList];
    let elementsToRemove = new Set();
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      const elementId = element.id;
      elementsToRemove.add(elementId);
    }
    if (this.hasRows()) {
      for (let i = 0; i < formulaRows.length; i++) {
        const row = formulaRows[i];
        if (
          row.type === FORMULA_ROW_TYPES.ELEMENT &&
          row.elementData &&
          elementsToRemove.has(row.elementData.id)
        ) {
          formulaRows[i].elementData.elementActive = false;
        }
      }
    }
    for (let i = 0; i < formulaDtoList.length; i++) {
      const row = formulaDtoList[i];
      if (
        row &&
        row.type === FORMULA_ROW_TYPES.ELEMENT &&
        elementsToRemove.has(row.includedElementId)
      ) {
        formulaDtoList[i].elementActive = false;
      }
    }
    return this.mergeData({
      rows: formulaRows,
      formulaElementDtoList: formulaDtoList,
    });
  }

  setFormulaResult() {
    const formulaRows = [...this.data.rows];
    const result = this._updateFormulaRowsAndCalculateResult({
      newRows: formulaRows,
    });
    return this.mergeData({ ...result.data });
  }
}
