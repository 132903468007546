import { getStore } from 'store/store';
import { GEO_NAMES } from 'constants/feature/project-list-header-constants';
import { isArray } from 'lodash';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import {
  LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER,
  TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
} from 'constants/common/feature-common-utils';
import { getAllContainerDetails } from 'api/project-list-api';
import CountryForm from 'models/form/country-form-model';
import { isNullOrUndefined } from 'utils/object-utils';
/**
 * This map contains a list of all the pipe colors supported in the application in their proper ordering. As this is only
 * used for visual separation between cards, please use the {`getPipeColorForProject`} function to access the proper color value for
 * a project based on its index. If any further logic is needed with pipe colors, please add it to this utility file as well.
 */
const _pipeColorMap = {
  green: '#c4d600',
  purple: '#012169',
  blue: '#0097a9',
  teal: '#62b5e5',
  greenDark: '#009a44',
  tealLight: '#9dd4cf',
  blueDark: '#041e42',
  grey: '#63666a',
};

/**
 * This function is used to determine the hex code of the pipe color for a given project. We are simply using the index
 * of the project from the store to determine what pipe color to give it. E.g: the first project is green, the second is purple, etc...
 * Returns an empty string iff the project is not found in the store's project list, based on its id, or the store/project list have not
 * been initialized.
 *
 * @param {String} projectID - The id of the project we wish to give a color, used to find the project in the store to determine its color index.
 *
 * @return {String} projectPipeColor - The hex code of the project's pipe color, based on its index in the store's project list, or empty
 * String if it is not present in the project list.
 *
 */

export const getPipeColorForProject = (projectID) => {
  let projectPipeColor = '';
  const stateData = getStore().getState().data;
  if (stateData && stateData.projectList.hasProjects()) {
    const projectList = stateData.projectList.getProjects();
    const desiredProjectIndex = projectList.findIndex((project) => {
      return project.id === projectID;
    });
    const pipeColorValues = Object.values(_pipeColorMap);
    projectPipeColor =
      pipeColorValues[desiredProjectIndex % pipeColorValues.length];
  }
  return projectPipeColor;
};

const hasDuplicate = (array, obj) => {
  // checking if the existing array already has the obj present in it.
  return array.some((item) => {
    return item.id === obj.id && item.geoCode === obj.geoCode;
  });
};

export const collateProjectDetails = (apiResponses) => {
  // Creating an object which contains Geo wise project list data.
  const geoCodeMapData = {};

  apiResponses.forEach((response) => {
    response &&
      response.data &&
      Array.isArray(response.data.result) &&
      response.data.result.map((item) => {
        if (
          geoCodeMapData[item.geoCode] &&
          !hasDuplicate(geoCodeMapData[item.geoCode], item) // we are checking if for the next response item also if we have the same Geo data or not.
        ) {
          geoCodeMapData[item.geoCode] = [
            ...geoCodeMapData[item.geoCode],
            item,
          ];
        } else if (!geoCodeMapData[item.geoCode]) {
          geoCodeMapData[item.geoCode] = [item];
        }
      });
  });

  const mergedData = [];

  Object.keys(geoCodeMapData).map((keys) =>
    mergedData.push(...geoCodeMapData[keys]),
  );

  // creating collatedResponse object which will contain the data result for all the Geos.
  const collatedResponse = {
    ...apiResponses[0],
    data: {
      ...apiResponses[0].data,
      result: mergedData,
    },
  };

  collatedResponse &&
    collatedResponse.data &&
    Array.isArray(collatedResponse.data.result) &&
    collatedResponse.data.result.forEach((item) => {
      item.id = serializedProjectIdWithGeo(item.id, item.geoCode);
    });

  return collatedResponse;
};

export const serializedProjectIdWithGeo = (projectID, geoCode) => {
  return `${projectID}-${geoCode}`;
};

// This function will update project id of each of the projects in the list to geo-suffixed project id
export const updateProjectListWithGeoSuffixedProjectId = (
  projectList,
  nameOfProjectIdProp,
  nameOfGeoCodeProp,
) => {
  return isArray(projectList)
    ? projectList.map((_project) => {
        _project[nameOfProjectIdProp] = serializedProjectIdWithGeo(
          _project[nameOfProjectIdProp],
          _project[nameOfGeoCodeProp],
        );
        return _project;
      })
    : [];
};

export const getGeosWhereUserHasProject = () => {
  const store = getStore();
  const { data: { currentUser } = {} } = store.getState();
  let { projectToGeoMap } = currentUser && currentUser.data;
  // if project to geo map is not in store then try to get it from session
  if (!projectToGeoMap)
    projectToGeoMap = JSON.parse(sessionStorage.getItem('projectToGeoMap'));

  const geoAndRolesList = Array.from(projectToGeoMap.values());
  const geos = geoAndRolesList.map((item) => item.geoCode);
  return Array.from(new Set(geos));
};

export const isProjectExistInAMERegion = (project) => {
  const { data: { geoCode } = {} } = project || {};
  return geoCode === GEO_NAMES.AME;
};

export const doesUserExistInAMERegion = () => {
  const store = getStore();
  const { data: { currentUser } = {} } = store.getState();
  const { data: { geoCode } = {} } = currentUser || {};
  return geoCode === GEO_NAMES.AME;
};

/**This piece of code might be required in case we want to fetch containers data from multiple geos
 * in future.
 */
// export const collateCountryListDetails = (apiResponses) => {
//   // borrow structure from api response
//   let collatedResponse = apiResponses[0];
//   // We want to collate responses only when it is fetched from geos having different base_api_url
//   // Generally in dev env, we do not have different base_api_url
//   !isBaseAPIUrlSimilarForAllGeos() &&
//     apiResponses.forEach((response, index) => {
//       index > 0 &&
//         response &&
//         Array.isArray(response.data) &&
//         Array.isArray(collatedResponse.data) &&
//         collatedResponse.data.push(...response.data);
//     });
//   collatedResponse.data =
//     getUniqueCountryListFromMultiGeosData(collatedResponse);
//   return collatedResponse;
// };

// // filters the unique countries/containers list from multiple geos collated response based on the unique geoCode, containerCode and countryCode fields
// const getUniqueCountryListFromMultiGeosData = (collatedCountryList) => {
//   const uniqueCountryList = [];
//   const uniqueCountryKeys = new Set();

//   collatedCountryList.data.forEach((obj) => {
//     const key = `${obj.geoCode}-${obj.containerCode}-${obj.countryCode}`;
//     if (!uniqueCountryKeys.has(key)) {
//       uniqueCountryKeys.add(key);
//       uniqueCountryList.push(obj);
//     }
//   });
//   return uniqueCountryList;
// };

export const getCountryCodeMenuOption = (countryOptionList, countryCode) => {
  if (!isArray(countryOptionList) || !countryCode)
    // if we get falsy value user from BE, user will see empty dropdown
    return new MenuOption({
      id: 1,
      title: EMPTY_STRING,
      value: '',
      isIntl: false,
    });
  return countryOptionList.find((container) => container.id === countryCode);
};

export const getAllCountriesListFromLocalstorage = () =>
  JSON.parse(
    localStorage.getItem(
      `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}_AllCountriesList`,
    ),
  );

/**it checks localStorage key "tieout__AllCountriesList" with its value if it exists
 * it won't be triggers /tieout/containers/all API call to fetch all the countries list(static data) and
 * if localStorage key not present it will creates key with value of response of /tieout/containers/all API call.
 * Note: this localStorage key will be updated based on the expiry value
 */
export const fetchAndUpdateAllCountriesList =
  () => async (dispatch, getState) => {
    const countriesList = getAllCountriesListFromLocalstorage();
    if (
      // if allCountryList does not exist or it has expired for specific locale then only we want to make api call.
      !(
        countriesList &&
        isArray(countriesList.data) &&
        countriesList.data.length > 0 &&
        countriesList.expiry > new Date().getTime()
      )
    ) {
      try {
        const response = await getAllContainerDetails();
        // append time to live with allCountriesList
        const countriesListWithTTL = response &&
          response.data && {
            data: response.data,
            expiry:
              new Date().getTime() + TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
          };

        localStorage.setItem(
          `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}_AllCountriesList`,
          JSON.stringify(countriesListWithTTL),
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

//getting the countryName based on geoCode and countryCode from all countries list
export const getCountryNameByGeoCodeAndCountryCode = (geoCode, countryCode) => {
  const allCountryListData = getAllCountriesListFromLocalstorage();

  const countryData =
    allCountryListData &&
    isArray(allCountryListData.data) &&
    allCountryListData.data.find(
      (item) => item.geoCode === geoCode && item.countryCode === countryCode,
    );
  return countryData && countryData.countryName;
};

//getting the countryName based on countryCode from all countries list
export const getCountryNameByCountryCode = (countryCode) => {
  const allCountryListData = getAllCountriesListFromLocalstorage();

  const countryData =
    allCountryListData &&
    isArray(allCountryListData.data) &&
    allCountryListData.data.find((item) => item.countryCode === countryCode);
  return countryData && countryData.countryName;
};

//getting the countryName based on geoCode, containerCode and countryCode from all countries list
export const getCountryNameByGeoCodeContainerCodeAndCountryCode = (
  geoCode,
  countryCode,
  containerCode,
) => {
  const allCountryListData = getAllCountriesListFromLocalstorage();

  const countryData =
    allCountryListData &&
    isArray(allCountryListData.data) &&
    allCountryListData.data.find(
      (item) =>
        item.geoCode === geoCode &&
        item.countryCode === countryCode &&
        item.containerCode === containerCode,
    );
  return countryData && countryData.countryName;
};

export const getCountriesListLoadedInModel = () => {
  let localStorageCountriesList = getAllCountriesListFromLocalstorage();
  const countriesList = new CountryForm();
  if (
    !isNullOrUndefined(localStorageCountriesList) &&
    isArray(localStorageCountriesList.data) &&
    localStorageCountriesList.data.length > 1
  ) {
    return countriesList.setLoaded(localStorageCountriesList);
  } else {
    localStorage.removeItem(
      `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}_AllCountriesList`,
    );
    return countriesList;
  }
};

export const isUserHasAccessToProject = (projectId) => {
  const store = getStore();
  const {
    data: {
      currentUser: {
        data: { projectToGeoMap },
      },
    } = {},
  } = store.getState();
  let userHasAccess;
  if (!isNullOrUndefined(projectId)) {
    userHasAccess = projectToGeoMap.has(`${projectId}`);
  }
  return userHasAccess;
};
