import { handleActions } from 'redux-actions';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import {
  leftSectionTreeListLoading,
  leftSectionTreeListError,
  leftSectionTreeListLoaded,
  setCreateleftSectionAction,
  addNewleftSectionAction,
  removeNewleftSectionAction,
  clearleftSectionTreeListAction,
  setNewleftSectionIdAction,
} from 'store/actions/side-by-side-statement/left-statement-section-tree-list-actions';

export default handleActions(
  {
    [leftSectionTreeListLoading]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setLoading(payload);
    },
    [leftSectionTreeListLoaded]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setLoaded({ response: payload });
    },
    [leftSectionTreeListError]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setError(payload);
    },
    [setCreateleftSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setIsCreateSectionModalOpen(payload);
    },
    [addNewleftSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.addNewSectionByParentId(payload);
    },
    [removeNewleftSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.removeNewSection(payload);
    },
    [clearleftSectionTreeListAction]: () => {
      return new SectionTreeList();
    },
    [setNewleftSectionIdAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setNewSectionId(payload);
    },
  },
  new SectionTreeList(),
);
