export class ModalButton {
  text;
  onClick;
  disabled;
  tooltip;

  constructor({ text, onClick, disabled, tooltip }) {
    this.text = text;
    this.onClick = onClick;
    this.disabled = disabled;
    this.tooltip = tooltip;
  }
}
