import ApiModel from 'models/api-model';
import Engagement from 'models/data/engagement-model';

export class EngagementList extends ApiModel({
  data: {
    engagementEntities: [], // type Project
  },
  status: null,
}) {
  processResponse({ response }) {
    return {
      data: {
        engagementEntities: response.data.map(
          (entity) => new Engagement(entity),
        ),
      },
      status: response.status,
    };
  }

  getEngagementEntities() {
    if (this.data && this.data.engagementEntities) {
      return this.data.engagementEntities;
    }
    return [];
  }
}
