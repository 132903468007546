import { createAction } from 'redux-actions';

export const toggleFormulaCancelModalAction = createAction(
  'TOGGLE_FORMULA_CANCEL_MODAL_ACTION',
);

export const toggleInternalReferenceCancelModalAction = createAction(
  'TOGGLE_IR_CANCEL_MODAL_ACTION',
);

export const openCancelModalAfterModalConfirmAction = createAction(
  'OPEN_CANCEL_MODAL_AFTER_CONFIRM_ACTION',
);

export const storeSelectedNoteIdAction = createAction(
  'STORE_SELECTED_NOTE_ID_ACTION',
);

export const storeSelectedSectionIdAction = createAction(
  'STORE_SELECTED_SECTION_ID_ACTION',
);

export const openSectionPanelAfterCancelConfirmAction = createAction(
  'OPEN_SECTION_PANEL_AFTER_CANCEL_CONFIRM_ACTION',
);

export const storeSelectedNotePseudoIdAction = createAction(
  'STORE_SELECTED_NOTE_PSEUDO_ID_ACTION',
);

export const toggleFormulaCancelModal = (state) => (dispatch) => {
  dispatch(toggleFormulaCancelModalAction(state));
};

export const toggleInternalReferenceCancelModal = (state) => (dispatch) => {
  dispatch(toggleInternalReferenceCancelModalAction(state));
};

export const openCancelModalAfterModalConfirm = (state) => (dispatch) => {
  dispatch(openCancelModalAfterModalConfirmAction(state));
};

export const storeSelectedNoteId = (id) => (dispatch) => {
  dispatch(storeSelectedNoteIdAction(id));
};

export const storeSelectedNotePseudoId = (id) => (dispatch) => {
  dispatch(storeSelectedNotePseudoIdAction(id));
};
