import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
/**
 * Map of sectionIds that are currently within the viewport threshold
 * {
 *  [sectionId]: true,
 *  ...
 * }
 * Essentially an implementation of a Set, keys are removed from the map when they are no longer in view
 */
class SectionInViewMap extends BaseModel({}) {
  get sectionIds() {
    return Object.keys(this);
  }
  has(sectionId) {
    return !isNullOrUndefined(this[sectionId]) && this[sectionId]; // map to booleans, guards against saving a `false`
  }

  get(sectionId) {
    return this[sectionId];
  }

  /**
   * Reducer function for adding a section to the map
   * @param {int} param.sectionId
   */
  setSectionInView({ sectionId }) {
    return this.merge({
      [sectionId]: true,
    });
  }

  /**
   * Reducer function for removing a section from the map
   * @param {int} param.sectionId
   */
  clearSectionInView({ sectionId }) {
    const newSet = { ...this };
    delete newSet[sectionId];
    // calling this.merge would not actually remove the existing key so we need a new instance
    return new SectionInViewMap(newSet);
  }

  /**
   * Reducer function for clearing the whole map
   */
  clearMap() {
    return new SectionInViewMap();
  }
}

export default SectionInViewMap;
