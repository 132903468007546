import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import { ReactComponent as FilterIcon } from 'icons/filter-icon.svg';
import Tooltip from 'components/common/tool-tip-component';
import classNames from 'classnames';
import {
  DISPLAY_ANNOTATION_CHECKBOX_ARRAY,
  ANNOTATION_DISPLAY_FILTER_OPTIONS_ID,
  ANNOTATION_DISPLAY_FILTER_OPTIONS,
} from 'constants/feature/annotation-display-filter-constants';
import AnnotationDisplayOptions from 'models/data/annotation-display-filter-options-model';
import { setAnnotationDisplayOption } from 'store/actions/annotation-display-actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button, { BUTTON_TYPES } from './button-component';
import isEqual from 'lodash/isEqual';
import FeatureFlagComponent from './feature-flag-component';

const ANNOTATION_DISPLAY_FILTER = 'annotation-display-filter';
const FILTER_ICON_SIZE = '20px';

export const AnnotationDisplayFilter = ({
  annotationDisplayOptions,
  onDisplayOptionChange,
}) => {
  const [displayMenu, setDisplayMenu] = React.useState(false);
  const [displayOptions, setdisplayOptions] = React.useState(
    new AnnotationDisplayOptions(),
  );
  const containerRef = useRef();
  useEffect(() => {
    onDisplayOptionChange(new AnnotationDisplayOptions());
  }, [onDisplayOptionChange]);

  useEffect(() => {
    const onMousedownEvent = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDisplayMenu(false);
      }
    };
    document.addEventListener('mousedown', onMousedownEvent, false);
    return () => {
      document.removeEventListener('mousedown', onMousedownEvent, false);
    };
  }, [containerRef]);

  const setDisplayMenuOpen = (open) => {
    if (open) setdisplayOptions(annotationDisplayOptions);
    setDisplayMenu(open);
  };

  const handleChange = (option) => {
    setdisplayOptions(
      displayOptions.merge({ [option]: !displayOptions[option] }),
    );
  };
  const resetChanges = () => {
    setdisplayOptions(new AnnotationDisplayOptions());
  };

  const applyChanges = () => {
    onDisplayOptionChange(displayOptions);
    setDisplayMenuOpen(false);
  };

  return (
    <>
      <div className={ANNOTATION_DISPLAY_FILTER} ref={containerRef}>
        <div
          className={classNames(
            `${ANNOTATION_DISPLAY_FILTER}__fixed-container`,
          )}
        >
          <div className={`${ANNOTATION_DISPLAY_FILTER}__container`}>
            <Tooltip
              id={`${ANNOTATION_DISPLAY_FILTER}__button-tooltip`}
              position={'bottom'}
              text="annotation-display-filter-tooltip"
              key={`${ANNOTATION_DISPLAY_FILTER}__button-tooltip`}
            >
              <button
                className={`${ANNOTATION_DISPLAY_FILTER}__button`}
                onClick={() => setDisplayMenuOpen(!displayMenu)}
              >
                <FilterIcon
                  width={FILTER_ICON_SIZE}
                  height={FILTER_ICON_SIZE}
                />
              </button>
            </Tooltip>
          </div>

          {displayMenu && (
            <div className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__menu`}>
              <div className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__header`}>
                <FormattedMessage id={'annotation-display-filter.label'} />
              </div>
              <div
                className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__menu-options`}
              >
                <div
                  className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__options`}
                >
                  {DISPLAY_ANNOTATION_CHECKBOX_ARRAY.map((item) => (
                    <FeatureFlagComponent name={item.featureName}>
                      <Checkbox
                        id={`${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-${item.key}`}
                        name={item.name}
                        checked={displayOptions[item.name]}
                        value={item.name}
                        onChange={handleChange}
                        label={item.label}
                        key={`${item.name}-${item.key}`}
                      />
                    </FeatureFlagComponent>
                  ))}
                </div>
              </div>
              <div className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__footer`}>
                <div
                  className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__footer-left`}
                >
                  <Button
                    id={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-tertiary-button`}
                    className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-button`}
                    type={BUTTON_TYPES.tertiary}
                    onClick={resetChanges}
                  >
                    <FormattedMessage id="common.reset" />
                  </Button>
                </div>
                <div
                  className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}__footer-right`}
                >
                  <Button
                    id={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-secondary-button`}
                    className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-button`}
                    type={BUTTON_TYPES.secondary}
                    onClick={() => setDisplayMenuOpen(false)}
                  >
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    id={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-primary-button`}
                    className={`${ANNOTATION_DISPLAY_FILTER_OPTIONS}-button`}
                    type={BUTTON_TYPES.primary}
                    onClick={applyChanges}
                    disabled={isEqual(displayOptions, annotationDisplayOptions)}
                  >
                    <FormattedMessage id="common.apply" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data: { annotationDisplayOptions } }) => ({
  annotationDisplayOptions,
});

const mapDispatchToProps = {
  onDisplayOptionChange: setAnnotationDisplayOption,
};

AnnotationDisplayFilter.propTypes = {
  /** func to set annotation display options */
  onDisplayOptionChange: PropTypes.func.isRequired,
  /** Obj containing display options from store */
  annotationDisplayOptions: PropTypes.instanceOf(AnnotationDisplayOptions)
    .isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnotationDisplayFilter);
