import React, { PureComponent, memo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import classnames from 'classnames';

/**
 * A Header to display within a Flyout.
 *
 * To enable the back button pass in the enableBack prop and use the onBack function  from the component you are calling it from and integrate the functionality
 *
 * If you wish to put extra content within the header section on the next row add this component <Header/> and configure it how you like; the children will render whatever is passed to it
 */

const ICON_DIMENSION = '18px';
const CLOSE_ICON_DIMENSION = '28px';

const HEADER_BLOCK = 'panelHeader';

class FlyoutHeader extends PureComponent {
  render() {
    const {
      children,
      enableClose,
      enableBack,
      onClose,
      onBack,
      intl,
      className,
    } = this.props;

    return (
      <div className={classnames(HEADER_BLOCK, className)}>
        {enableBack && (
          <ArrowLeft
            className={`${HEADER_BLOCK}__back`}
            onClick={() => onBack()}
            title={intl.formatMessage({ id: 'common.back' })}
            height={ICON_DIMENSION}
            width={ICON_DIMENSION}
            role="button"
          />
        )}
        {children}
        {enableClose && (
          <CloseButton
            className={`${HEADER_BLOCK}__close`}
            onClick={() => onClose()}
            title={intl.formatMessage({ id: 'common.close' })}
            height={CLOSE_ICON_DIMENSION}
            width={CLOSE_ICON_DIMENSION}
            role="button"
          />
        )}
      </div>
    );
  }
}

FlyoutHeader.defaultProps = {
  enableClose: false,
};

FlyoutHeader.propTypes = {
  /** Enables the close button. */
  enableClose: PropTypes.bool,
  /** Function which handles closing the Flyout. */
  onClose: PropTypes.func,
  /** Boolean which indicates if we can navigate back to previous flyout component. */
  enableBack: PropTypes.bool,
  /** Function which handles going back to previous Flyout overlay. */
  onBack: PropTypes.func,
};

export default injectIntl(memo(FlyoutHeader));
