import ApiModel from 'models/api-model';
import {
  REVISION_STATUS_DISPLAYS,
  REVISION_STATUSES,
} from 'constants/feature/revision-status-constants';
import { isNullOrUndefined } from 'utils/object-utils';
export default class Revision extends ApiModel({
  data: {
    id: null,
    statementId: null,
    revisionNumber: null,
    linkToDocx: null,
    linkToOrgXhtml: null,
    linktoXhtml: null,
    status: null,
    processedByUserEntity: null,
    processedDate: null,
    deleteDate: null,
    lastModifiedDate: null,
    lastModifiedBy: null,
    docChangedTimestamp: null,
    linkToChangesStatement: null,
    changesStatementTimestamp: null,
    clientId: null,
    hasSanitizedImage: null,
    statementName: null,
  },
  status: null,
}) {
  get id() {
    return this.data.id;
  }
  get statementId() {
    return this.data.statementId;
  }
  get revisionNumber() {
    return this.data.revisionNumber;
  }
  get linkToDocx() {
    return this.data.linkToDocx;
  }
  get linkToOrgXhtml() {
    return this.data.linkToOrgXhtml;
  }
  get linktoXhtml() {
    return this.data.linktoXhtml;
  }
  get revisionStatus() {
    switch (this.data.revisionStatus) {
      case REVISION_STATUS_DISPLAYS.ACTIVE:
        return REVISION_STATUSES.ACTIVE;
      case REVISION_STATUS_DISPLAYS.DELETED:
        return REVISION_STATUSES.DELETED;
      case REVISION_STATUS_DISPLAYS.RESTORING:
        return REVISION_STATUSES.RESTORING;
      case REVISION_STATUS_DISPLAYS.FROM_RESTORE:
        return REVISION_STATUSES.FROM_RESTORE;
      case REVISION_STATUS_DISPLAYS.ERROR:
        return REVISION_STATUSES.ERROR;
      default:
        return null;
    }
  }
  get processedByUserEntity() {
    return this.data.processedByUserEntity;
  }
  get processedDate() {
    return this.data.processedDate;
  }
  get deleteDate() {
    return this.data.deleteDate;
  }
  get lastModifiedDate() {
    return this.data.lastModifiedDate;
  }
  get lastModifiedBy() {
    return this.data.lastModifiedBy;
  }
  get docChangedTimestamp() {
    return this.data.docChangedTimestamp;
  }
  get linkToChangesStatement() {
    return this.data.linkToChangesStatement;
  }
  get changesStatementTimestamp() {
    return this.data.changesStatementTimestamp;
  }
  get clientId() {
    return this.data.clientId;
  }
  get hasSanitizedImage() {
    return this.data.hasSanitizedImage;
  }
  get statementName() {
    return this.data.statementName;
  }

  get readOnly() {
    return this.revisionStatus === REVISION_STATUSES.DELETED;
  }

  processResponse({ response }) {
    return {
      data: response.data,
      status: response.status,
    };
  }

  isInitialized() {
    return !isNullOrUndefined(this.data) || !isNullOrUndefined(this.data.id);
  }

  getRevisionData() {
    if (this.data) {
      return this.data;
    }
  }
}
