import { handleActions } from 'redux-actions';
import AuditQuestionsChecklist from 'models/api/audit-questions-checklist-api-model';
import {
  auditChecklistLoading,
  auditChecklistLoaded,
  auditChecklistError,
  auditChecklistSaveAnswer,
} from 'store/actions/audit-checklist-actions';

export default handleActions(
  {
    [auditChecklistLoading]: (auditQuestionsChecklist) => {
      return auditQuestionsChecklist.setLoading();
    },
    [auditChecklistLoaded]: (auditQuestionsChecklist, { payload }) => {
      return auditQuestionsChecklist.setLoaded(payload);
    },
    [auditChecklistError]: (auditQuestionsChecklist, payload) => {
      return auditQuestionsChecklist.setError(payload);
    },
    [auditChecklistSaveAnswer]: (auditQuestionsChecklist, payload) => {
      return auditQuestionsChecklist.updateChecklistData(payload);
    },
  },
  new AuditQuestionsChecklist(),
);
