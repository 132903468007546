import { createAction } from 'redux-actions';

import { getAllRevisionElementsRequest } from 'api/element-api.js';
import { LEFT_PANELS } from 'constants/feature/panel-constants';

export const comparePanelLoading = createAction('COMPARE_STATS_LOADING');
export const comparePanelLoaded = createAction('COMPARE_STATS_LOADED');
export const comparePanelError = createAction('COMPARE_STATS_ERROR');

export const fetchComparePanelForRevision = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.COMPARE) return;
  dispatch(comparePanelLoading());
  try {
    const response = await getAllRevisionElementsRequest({ revisionId });
    dispatch(comparePanelLoaded({ response }));
  } catch (error) {
    dispatch(comparePanelError(error));
  }
};
