import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const CARRY_FORWARD_INFO_BLOCK = 'carry-forward-info';

const CarryForwardInfo = () => {
  return (
    <div className={`${CARRY_FORWARD_INFO_BLOCK}`}>
      <div className={`${CARRY_FORWARD_INFO_BLOCK}__header`}>
        <FormattedMessage id={'statement-create.carry-forward-info.headline'} />
      </div>
      <ol className={`${CARRY_FORWARD_INFO_BLOCK}__ordered-list`}>
        <li>
          <FormattedMessage
            id={'statement-create.carry-forward-info.step-one'}
          />
        </li>
        <li>
          <FormattedMessage
            id={'statement-create.carry-forward-info.step-two'}
          />
        </li>
      </ol>
      <div className={`${CARRY_FORWARD_INFO_BLOCK}__bullet-container`}>
        <div>
          <FormattedMessage
            id={'statement-create.carry-forward-info.headline-two'}
          />
        </div>
        <ul className={`${CARRY_FORWARD_INFO_BLOCK}__unordered-list`}>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-one'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-two'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-three'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-four'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-five'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-six'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-seven'}
            />
          </li>
          <li>
            <FormattedMessage
              id={'statement-create.carry-forward-info.bullet-eight'}
            />
          </li>
        </ul>
      </div>
      <div className={`${CARRY_FORWARD_INFO_BLOCK}__footer`}>
        {/* TODO bring this text back when the learn more about carryforward document download link is available */}
        {/* <FormattedMessage
          id={'statement-create.carry-forward-info.learn-more'}
        /> */}
      </div>
    </div>
  );
};

export default memo(CarryForwardInfo);
