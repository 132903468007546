import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { UNIT_OPTIONS } from 'constants/feature/element-attribute-constants';

export const UNIT_FILTERS_ID_BLOCK = 'element-filters-unit-id';

const ElementFiltersUnit = ({ filters, setFilters, className }) => {
  return (
    <div className={className}>
      {UNIT_OPTIONS.map((unit) => (
        <Checkbox
          key={unit.id}
          id={`${UNIT_FILTERS_ID_BLOCK}-${unit.id}`}
          label={unit.title}
          checked={filters.isUnitSelected(unit)}
          onChange={() => setFilters(filters.selectUnit(unit))}
        />
      ))}
    </div>
  );
};

ElementFiltersUnit.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersUnit;
