import { handleActions } from 'redux-actions';
import {
  setCopiedFormula,
  clearCopiedFormula,
} from 'store/actions/copy-formula-actions';
import { FormulaList } from 'models/api/formula-list-model';

export default handleActions(
  {
    [setCopiedFormula]: (formulaList, { payload }) => {
      return payload;
    },
    [clearCopiedFormula]: (formulaList) => {
      return new FormulaList();
    },
  },
  new FormulaList(),
);
