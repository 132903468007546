import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

export const COMFORT_LETTER_DESCRIPTION_CHAR_LIMIT = 4000;
export const COMFORT_LETTER_CUSTOM_LABEL_CHAR_LIMIT = 7;

export const COMFORT_ANNOTATION_SORT_BY = {
  ordinal: 'ordinal',
  createdDate: 'created_date',
};

export const createEditComfortletterTooltip = (id) =>
  new TooltipOptions({
    text: 'common.edit',
    id,
  });

export const disableEditComfortletterTooltip = (id) =>
  new TooltipOptions({
    text: 'permissions.tickmark.edit.statement',
    id,
  });

export const COLLAPSE_COMFORT_ANNOTATION_LIST_TOOLTIP = (id) =>
  new TooltipOptions({
    text: 'common.collapse',
    id: `comfort-annotation-collapse-button-tool-tip-${id}`,
  });

export const EXPAND_COMFORT_ANNOTATION_LIST_TOOLTIP = (id) =>
  new TooltipOptions({
    text: 'common.expand',
    id: `comfort-annotation-expand-button-tool-tip-${id}`,
  });

export const UNLINK_COMFORT_ICON_TOOLTIP = new TooltipOptions({
  text: 'common.unlink',
  id: 'comfort-annotation-element-icon-button-tool-tip',
});

export const DETACH_COMFORT_ICON_TOOLTIP = new TooltipOptions({
  text: 'common.detach',
  id: 'comfort-annotation-element-icon-button-tool-tip-detach',
});

export const deleteComfortAnnotationTooltip = (id) =>
  new TooltipOptions({
    text: 'common.delete',
    id,
  });
