export default class DocumentUploadStatusModel {
  id;
  status;
  statusDisplay;
  currentStep;
  totalSteps;
  constructor({ id, status, statusDisplay, currentStep, totalSteps }) {
    this.id = id;
    this.status = status;
    this.statusDisplay = statusDisplay;
    this.currentStep = currentStep;
    this.totalSteps = totalSteps;
  }
}
