import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Success } from 'icons/success.svg';
import { ReactComponent as Warning } from 'icons/warning.svg';
import {
  replaceLeftSelectedElementWithoutRemovingOld,
  mapElementsSuccessResult,
  replaceSelectedElementWithoutRemovingOld,
} from 'store/actions/statement-content-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';

export const ANNOTATION_MAP_ACTION_BLOCK = 'annotation-map-action';
const BANNER_ICON_SIZE = '10';

export const getExcessiveElementSelectionNotification = (elementLimit) => {
  return toast.error(
    <div>
      <Warning
        name={'warning'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__notify-header`}>
        <strong>
          <FormattedMessage
            values={{
              numberOfElements: elementLimit,
            }}
            id="element-panel.annotation.content.element-map-notify"
          />
        </strong>
      </div>
    </div>,
    { autoClose: 15000 },
  );
};

// this function is used to segregate the source and current(target) elements from array of objects based on elementUpdateStatus property to identify success and failed mapped elements
export const seggregateSourceTargetElements = (elementsMapped) => {
  let successSourceElements = [];
  let failedSourceElements = [];
  let successTargetElements = [];
  let failedTargetElements = [];
  for (let i = 0; i < elementsMapped.length; i++) {
    if (elementsMapped[i].elementUpdateStatus) {
      successSourceElements.push(elementsMapped[i].sourceElementId);
      successTargetElements.push(elementsMapped[i].targetElementId);
    } else {
      failedSourceElements.push(elementsMapped[i].sourceElementId);
      failedTargetElements.push(elementsMapped[i].targetElementId);
    }
  }
  return {
    successSourceElements,
    failedSourceElements,
    successTargetElements,
    failedTargetElements,
  };
};

//common function used to seggregate source and current(target) elements based on the response from element map request in side by side view page.
export const highlightMappedElementsByResponse =
  ({ result, sourceElements, targetElements }) =>
  async (dispatch, getState) => {
    dispatch(
      replaceLeftSelectedElementWithoutRemovingOld({
        elementIds: sourceElements,
        color:
          result === mapElementsSuccessResult
            ? ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_SUCCESS
            : ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_FAILURE,
      }),
    );
    dispatch(
      replaceSelectedElementWithoutRemovingOld({
        elementIds: targetElements,
        color:
          result === mapElementsSuccessResult
            ? ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_SUCCESS
            : ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_FAILURE,
      }),
    );
  };

export const handleElementMappingFailure =
  (mapElementsFailureResult, sourceElements, targetElements) =>
  async (dispatch, getState) => {
    //if element mapping request failed it triggers below code
    dispatch(
      highlightMappedElementsByResponse({
        result: mapElementsFailureResult,
        sourceElements,
        targetElements,
      }),
    );
    dispatch(clearSideBySideElementsMap());
    // failed mapped elements notification
    failureElementMapNotification({ targetElements });
  };

//successful notification for element(s) mapped
export const successElementMapNotification = ({ targetElements }) => {
  return toast.success(
    <div>
      <Success
        name={'success'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__success-header`}>
        <strong>
          <FormattedMessage
            id="element-panel.annotation.content.element-map-sucess"
            values={{
              br: (
                <br
                  key={`${ANNOTATION_MAP_ACTION_BLOCK}__success-header-brTag`}
                />
              ),
            }}
          />
        </strong>
      </div>
      <FormattedMessage
        values={{
          numberOfElements: targetElements.length,
          br: (
            <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__success-summary-brTag`} />
          ),
        }}
        id="element-panel.annotation.content.element-map-sucess-summary"
      />
    </div>,
    { autoClose: 15000 },
  );
};

//failure notification for element(s) mapped
export const failureElementMapNotification = ({ targetElements }) => {
  return toast.error(
    <div>
      <Warning
        name={'warning'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-header`}>
        <strong>
          <FormattedMessage
            id="element-panel.annotation.content.element-map-fail"
            values={{
              br: (
                <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-header-brTag`} />
              ),
            }}
          />
        </strong>
      </div>
      <FormattedMessage
        values={{
          numberOfElements: targetElements.length,
          br: <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-summary-brTag`} />,
        }}
        id="element-panel.annotation.content.element-map-fail-summary"
      />
    </div>,
    { autoClose: 15000 },
  );
};
