import TabModel from 'models/utils/common/tab-model';

export const TOOLKIT_EXPORT_MODULE_TABS = {
  REPORT_TYPES: new TabModel({
    id: 1,
    apiParam: 'exportReportTypes',
    intlTitle: 'toolkit-export-module.tabs.report-types',
  }),
  DOWNLOADS: new TabModel({
    id: 2,
    apiParam: 'downloadReportTab',
    intlTitle: 'toolkit-export-module.tabs.downloads',
  }),
};
