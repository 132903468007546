import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import Search from 'components/common/search-component';
import ComfortLetterList from 'models/api/comfort-letter-list-api-model';
import { getComfortAnnotationsForRevisionRequest } from 'api/comfort-letter-api';
import { connect } from 'react-redux';
import ComfortLetterEntry from 'components/feature/element-panel/annotations/comfort-letter-entry/comfort-letter-entry-component';
import ConditionalRender from 'components/util/conditional-render-component';

export const TERTIARY_COMFORT_ANNOTATION_BLOCK =
  'tertiary-comfort-annotation-filters';
export const TERTIARY_COMFORT_ANNOTATION_ID_BLOCK =
  'tertiary-comfort-annotation-filters-id';

const ElementFiltersComfortAnnotationTertiary = ({
  filters,
  setFilters,
  className,
  revisionId,
}) => {
  const [searchTerm, setSearch] = useState('');
  const [comfortLetters, setComfortLetters] = useState(new ComfortLetterList());

  const _fetchComfortLetters = async () => {
    setComfortLetters(comfortLetters.setLoading());
    try {
      const response = await getComfortAnnotationsForRevisionRequest({
        revisionId,
      });
      setComfortLetters(comfortLetters.setLoaded({ response }));
    } catch (error) {
      setComfortLetters(comfortLetters.setError(error));
    }
  };

  useEffect(() => {
    _fetchComfortLetters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Search
        className={`${TERTIARY_COMFORT_ANNOTATION_BLOCK}__search`}
        id={`${TERTIARY_COMFORT_ANNOTATION_ID_BLOCK}-search`}
        onChange={(val) => setSearch(val)}
        onClear={() => {
          setSearch('');
        }}
        placeholder="statement-nav-element-filters.comfort-annotation.search.placeholder"
        showSearchButton
        value={searchTerm}
        isValid
        disableAutoComplete
      />
      <ConditionalRender dependencies={[comfortLetters]}>
        {Array.isArray(comfortLetters.getFilteredComfortLetters(searchTerm)) &&
          comfortLetters
            .getFilteredComfortLetters(searchTerm)
            .map((comfortLetter) => (
              <div
                className={`${TERTIARY_COMFORT_ANNOTATION_BLOCK}__checkbox-container`}
              >
                <Checkbox
                  className={`${TERTIARY_COMFORT_ANNOTATION_BLOCK}__comfort-annotation-checkbox`}
                  key={comfortLetter.comfortLetterId}
                  id={`${TERTIARY_COMFORT_ANNOTATION_ID_BLOCK}-${comfortLetter.comfortLetterId}`}
                  isNotIntl={true}
                  value={comfortLetter}
                  checked={filters.isComfortLetterSelected(
                    comfortLetter.customLabel,
                  )}
                  onChange={(val) =>
                    setFilters(filters.selectComfortLetter(val))
                  }
                  width={'15%'}
                />
                <ComfortLetterEntry comfortLetter={comfortLetter} />
              </div>
            ))}
      </ConditionalRender>
    </div>
  );
};

ElementFiltersComfortAnnotationTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Revision id of current statement */
  revisionId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { ElementFiltersComfortAnnotationTertiary };
export default connect(mapStateToProps)(
  ElementFiltersComfortAnnotationTertiary,
);
