import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';

import { isNullOrUndefined } from 'utils/object-utils';

import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

const FORM_ITEM_BLOCK = 'form-item';
const ICON_SIZE = 18;

const FormItem = ({
  children,
  className,
  disabled,
  errorText,
  id,
  isValid,
  label,
  tooltip,
  width,
  intl,
  onClickFormItem,
  iconConfig,
}) => {
  const _itemIsValid = () => isNullOrUndefined(isValid) || isValid;
  const _getErrorMessage = () => {
    if (typeof errorText === 'string') {
      return intl.formatMessage({ id: errorText });
    } else if (typeof errorText === 'object') {
      return intl.formatMessage({ id: errorText.id }, errorText.values);
    }
    return null;
  };
  const { infoText, tooltipInfoId, iconComponent: IconComponent } =
    iconConfig || {};
  return (
    <div
      className={classNames(
        FORM_ITEM_BLOCK,
        className,
        !_itemIsValid() ? `${FORM_ITEM_BLOCK}--invalid` : null,
      )}
      style={{ width: width ? width : '100%' }}
      onClick={onClickFormItem}
    >
      {label ? (
        <div className={`${FORM_ITEM_BLOCK}__container`}>
          <label
            htmlFor={id}
            className={classNames(`${FORM_ITEM_BLOCK}__label`)}
            disabled={disabled}
          >
            {intl.formatMessage({ id: label })}
          </label>
          {iconConfig && (
            <Tooltip text={infoText} id={tooltipInfoId}>
              <IconComponent width={ICON_SIZE} height={ICON_SIZE} />
            </Tooltip>
          )}
        </div>
      ) : null}
      <Tooltip id={id} {...tooltip} active={tooltip ? true : false}>
        {children}
      </Tooltip>
      {!_itemIsValid() && errorText ? (
        <div className={classNames(`${FORM_ITEM_BLOCK}__error-text`)}>
          {_getErrorMessage()}
        </div>
      ) : null}
    </div>
  );
};

FormItem.propTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** String error copy intl id or object containing intl id and values object */
  errorText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.object,
    }),
  ]),
  /** Unique string id for form item*/
  id: PropTypes.string.isRequired,
  /** Boolean representing if form item output is valid */
  isValid: PropTypes.bool,
  /**Internationalized Sting id */
  label: PropTypes.string,
  /** SHOULD RARELY BE USED. Individual form-item components should have their own onSelect, onCheck etc. functions. Function fired onClick of form item */
  onClickFormItem: PropTypes.func,
  /** Object with tool tip options passed to tool tip*/
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** String percentage representing width of component*/
  width: PropTypes.string,
  /** config to show the icon beside the lable of form elements*/
  iconConfig: PropTypes.shape({
    infoText: PropTypes.string.isRequired,
    tooltipInfoId: PropTypes.string.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
      .isRequired,
  }),
};

export default memo(injectIntl(FormItem));
export { FORM_ITEM_BLOCK };
