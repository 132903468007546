import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormulaList } from 'models/api/formula-list-model';
import InternalReference from 'models/api/internal-reference-model';
import TabsGroup from 'components/common/tabs-group-component';
import TabModel from 'models/utils/common/tab-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { getDynamicElementAnnotationsTabs } from 'constants/feature/element-panel-constants';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import ElementComfortLetterList from 'models/api/element-comfort-letter-list-model';

const ANNOTATIONS_BLOCK = 'annotations-tabs';
const ANNOTATIONS_ID_BLOCK = 'annotations-tabs-id';

const ElementPanelAnnotationsTab = ({
  elementDetails,
  formulaList,
  selectedElementPanelAnnotationsTab,
  setCurrentElementAnnotationsTab,
  internalReference,
  tickmarkList,
  workpaperList,
  comfortLetterList,
}) => {
  return (
    <ConditionalRender dependencies={[elementDetails]}>
      <div className={`${ANNOTATIONS_BLOCK}`}>
        <TabsGroup
          tabsArray={getDynamicElementAnnotationsTabs({
            elementDetails,
            formulaList,
            internalReference,
            tickmarkList,
            workpaperList,
            comfortLetterList,
          })}
          id={`${ANNOTATIONS_ID_BLOCK}__tabs`}
          className={`${ANNOTATIONS_BLOCK}__tabs`}
          selectedTab={selectedElementPanelAnnotationsTab}
          onSelectTab={setCurrentElementAnnotationsTab}
          fullWidth
        />
      </div>
    </ConditionalRender>
  );
};

ElementPanelAnnotationsTab.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** The currently selected tab in the element panel */
  selectedElementPanelAnnotationsTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Action fired when a user changes the currently selected tab in the element annotations tab component*/
  setCurrentElementAnnotationsTab: PropTypes.func.isRequired,
  /** The list of formulas for the current element*/
  formulaList: PropTypes.instanceOf(FormulaList).isRequired,
  /** Internal reference that is currently linked to this element */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /**List of comfort letters attached to this element */
  comfortLetterList: PropTypes.instanceOf(ElementComfortLetterList).isRequired,
};

export default memo(ElementPanelAnnotationsTab);
