import { connect } from 'react-redux';
import {
  getElementTickmarksAndUpdateElementCache,
  attachTickmarkToElement,
  detachTickmarkFromElement,
} from 'store/actions/element-panel-actions';
import ElementTickmarkPanel from 'components/feature/element-panel/tickmark-panel/element-tickmark-panel-component';
import { searchTickmarksByRevisionAndElementRequest } from 'api/tickmark-api';
import {
  deallocateItems,
  selectElementContentPanel,
} from 'store/actions/statement-content-actions';

const mapStateToProps = (
  {
    data: {
      elementPanel: { tickmarkList, elementDetails },
      selectedProject: { id },
    },
    sockets: {
      statementSocket: { socketHasBeenDisconnected },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  tickmarkList,
  elementDetails,
  selectedProjectId: id,
  onSearchTickmark: searchTickmarksByRevisionAndElementRequest,
  socketHasBeenDisconnected,
});

const mapDispatchToProps = {
  onCreateTickmark: getElementTickmarksAndUpdateElementCache,
  onAttachTickmark: attachTickmarkToElement,
  onDetachTickmark: detachTickmarkFromElement,
  deallocateItems,
  selectElementContentPanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementTickmarkPanel);
