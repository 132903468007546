import FormulaRow from './formula-row-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { FORMULA_ROW_TYPES } from 'constants/feature/formula-constants';
import get from 'lodash/get';

export default class FormulaRowElement extends FormulaRow {
  elementData = null; //ElementContentModel: Used for element rows to render the appropriate data.
  constructor(props) {
    super(props);
    this.type = FORMULA_ROW_TYPES.ELEMENT;
    if (!isNullOrUndefined(props) && !isNullOrUndefined(props.elementData)) {
      this.elementData = props.elementData;
      this.value = props.elementData.amount;
    }
  }

  get elementId() {
    if (!isNullOrUndefined(this.elementData)) {
      return this.elementData.id;
    }
    return null;
  }

  get elementActive() {
    if (!isNullOrUndefined(this.elementData)) {
      return get(this.elementData, 'elementActive', true);
    }
    return false;
  }
}
