/**
 * A helper function used to get a list of emails that will notified whenever a user is tagged in a note
 * @param {string} noteBody : The text of the note body.
 * @param {Object} replies: The object that contains all the replies for the note.
 * @param {array} projectUsersList: The array of all user details.
 * @param {string} previousNoteBody: The text of the previous not body. This paramter is used only for not editting.
 */

export const getEmailsForTaggedUsers = ({
  noteBody,
  replies,
  projectUsersList,
  previousNoteBody,
}) => {
  const regex = /cfto_user_id="\s*(.*?)\s*"/g;
  let match;
  let usersObject = {};
  let usersObjectInPreviousNote = {};
  let emailsObject = {};

  const checkForMatchingSubstring = (text, usersMap) => {
    do {
      match = regex.exec(text);
      if (match) {
        usersMap[match[1]] = true;
      }
    } while (match);
  };

  if (noteBody !== '') {
    checkForMatchingSubstring(noteBody, usersObject);
  }

  if (previousNoteBody !== '') {
    checkForMatchingSubstring(previousNoteBody, usersObjectInPreviousNote);
  }

  const repliesKeys = Object.keys(replies);
  if (repliesKeys.length > 0) {
    const lastReplyInReplyObject = repliesKeys[repliesKeys.length - 1];
    const reply = replies[lastReplyInReplyObject];
    checkForMatchingSubstring(reply.reply, usersObject);
  }

  if (Object.keys(usersObjectInPreviousNote).length !== 0) {
    const currentUsersInNoteBody = Object.keys(usersObject);
    for (let i = 0; i < currentUsersInNoteBody.length; i++) {
      const userId = currentUsersInNoteBody[i];
      if (userId in usersObjectInPreviousNote) {
        delete usersObject[userId];
      }
    }
  }

  for (let i = 0; i < projectUsersList.length; i++) {
    const user = projectUsersList[i];
    if (user.id in usersObject) {
      emailsObject[user.email] = true;
    }
  }

  return Object.keys(emailsObject);
};
