import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalButton } from 'models/utils/common/modal-button-model';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { isNullOrUndefined } from 'utils/object-utils';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import { getContainerListOptionsMap } from 'constants/util/admin-module-utility';
import Dropdown from 'components/common/dropdown-component';
import TextInput from 'components/common/text-input-component';
import Chip from 'components/common/chip-component';
import { getCountryManagerDetailsByUserId } from 'api/admin-module-api';
import ConditionalRender from 'components/util/conditional-render-component';

export const SUPPORT_ID_INPUT_MODAL =
  'manage-country-manager-access-support-id';

export const SUPPORT_ID_INPUT_MODAL_BLOCK =
  'manage-country-manager-access-support-id-block';

const SupportIDInputModal = ({
  isEditMode,
  userId,
  toggleSupportIDModal,
  onSave,
  onCancel,
}) => {
  const containerList = useSelector(
    (store) => store.data.countryManagerContainerList,
  );
  const [updatedSupportID, setUpdatedSupportID] = useState('');
  const [previouslySelectedCountries, setPreviouslySelectedCountries] =
    useState([]);
  const [selectedContainerOptions, setSelectedContainerOptions] = useState([]);
  const [containerOptions, setContainerOptions] = useState(
    getContainerListOptionsMap(containerList.containersList),
  );
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = React.useState(null);

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  const _getCountryManagerAccessDetails = async () => {
    try {
      setDataLoading();
      const response = await getCountryManagerDetailsByUserId(userId);
      setDataLoaded();
      const { supportId, containers } = response.data;
      const selectedContainerOptions = [];
      containers.forEach((containerCode) => {
        if (containerOptions.has(containerCode)) {
          const option = containerOptions.get(containerCode);
          selectedContainerOptions.push(option);
          containerOptions.set(containerCode, { ...option, disabled: true });
        }
      });
      setPreviouslySelectedCountries(containers);
      setUpdatedSupportID(supportId);
      setSelectedContainerOptions(selectedContainerOptions);
      setContainerOptions(containerOptions);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      _getCountryManagerAccessDetails();
    } else {
      setDataLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectOption = (option) => {
    setSelectedContainerOptions((prevOption) => {
      return prevOption.concat(option);
    });
    setContainerOptions((containerOption) => {
      const selectedOption = containerOption.get(option.id);
      containerOption.set(option.id, { ...selectedOption, disabled: true });
      return containerOption;
    });
  };

  const onRemoveChip = (option) => {
    const filteredData = selectedContainerOptions.filter((value) => {
      return value.id !== option.id;
    });
    setSelectedContainerOptions(filteredData);
    setContainerOptions((containerOption) => {
      const selectedOption = containerOption.get(option.id);
      containerOption.set(option.id, { ...selectedOption, disabled: false });
      return containerOption;
    });
  };

  const onSaveSupportIdDetails = (
    updatedSupportID,
    selectedContainerOptions,
  ) => {
    const countryCodeList = selectedContainerOptions.map((item) => item.value);
    onSave(updatedSupportID, countryCodeList);
  };

  const isSaveBtnDisabled = () => {
    if (isEditMode) {
      //During Edit, disabled when previously selected country length is same as
      //currently selected and data should also match.
      const currentlySelected = selectedContainerOptions
        .map((item) => item.id)
        .sort();
      const previouslySelected = previouslySelectedCountries.sort();
      return currentlySelected.length
        ? previouslySelected.length === currentlySelected.length &&
            currentlySelected.toString() === previouslySelected.toString()
        : true;
    }
    //During creation, disabled until both field has data present in it.
    return !(updatedSupportID && selectedContainerOptions.length);
  };

  return (
    <Modal
      id={`${SUPPORT_ID_INPUT_MODAL}__modal`}
      title={'admin-module.manage-users.input.support-id.header'}
      onClose={() => toggleSupportIDModal(false)}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          onClick: () =>
            onSaveSupportIdDetails(updatedSupportID, selectedContainerOptions),

          disabled: isSaveBtnDisabled(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onCancel,
        })
      }
    >
      <div className="modal__text">
        <FormattedMessage id="admin-module.manage-users.input.support-id.guidance-message" />
      </div>
      <ConditionalRender
        dependencies={[{ error, isLoading, isLoaded }, containerList]}
      >
        <div className={`${SUPPORT_ID_INPUT_MODAL_BLOCK}__input-support`}>
          <TextInput
            id={`${SUPPORT_ID_INPUT_MODAL}-input-box`}
            label={
              'admin-module.manage-users.user-heading.support-id-modal.input.label'
            }
            placeholder="admin-module.manage-users.user-heading.support-id-modal.input.placeholder"
            onChange={setUpdatedSupportID}
            value={
              isNullOrUndefined(updatedSupportID)
                ? EMPTY_STRING
                : updatedSupportID
            }
            width={'100%'}
            type={'text'}
            isValid={true}
            disabled={isEditMode}
          />
          <>
            <Dropdown
              id={`${SUPPORT_ID_INPUT_MODAL_BLOCK}-country-dropdown-id`}
              placeholder={
                'admin-module.manage-users.user-heading.support-id-modal.dropdown.placeholder'
              }
              label="admin-module.manage-users.user-heading.support-id-modal.dropdown.label"
              width={'100%'}
              onSelectOption={onSelectOption}
              isValid={true}
              options={Array.from(containerOptions.values())}
              value={null}
            />
            <div className={`${SUPPORT_ID_INPUT_MODAL_BLOCK}__chip-container`}>
              {selectedContainerOptions.map((key) => {
                return (
                  <Chip
                    key={`country-key-${key.id}`}
                    id={`${SUPPORT_ID_INPUT_MODAL_BLOCK}-key`}
                    onRemove={() => onRemoveChip(key)}
                  >
                    {key.title}
                  </Chip>
                );
              })}
            </div>
          </>
        </div>
      </ConditionalRender>
    </Modal>
  );
};

SupportIDInputModal.propTypes = {
  /** function to toggle state in order to show/hide support ID modal */
  toggleSupportIDModal: PropTypes.func.isRequired,
  /**Boolean value representing that popup is open in edit mode or not */
  isEditMode: PropTypes.bool.isRequired,
  /**Function fired when input text from modal is saved. */
  onSave: PropTypes.func.isRequired,
  /**Function fired when cancel button on modal is clicked */
  onCancel: PropTypes.func.isRequired,
  /**UserId required to fetch the country Manger details */
  userId: PropTypes.number.isRequired,
};

export default SupportIDInputModal;
