import { handleActions } from 'redux-actions';
import ChangesSinceTracker from 'models/data/element-changes-since-tracker-model';
import { setElementChangesSinceAction } from 'store/actions/element-changes-since-tracker-actions';
import { revisionLoaded } from 'store/actions/revision-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setElementChangesSinceAction]: (tracker, { payload }) => {
      return tracker.setSince(payload);
    },
    /* External Action Listeners */
    [revisionLoaded]: (tracker, { payload }) => {
      const revisionId = payload.response.data.id;
      return tracker.initFromRevision({ revisionId });
    },
    [clearStatementPageStoreAction]: (tracker, { payload }) => {
      return new ChangesSinceTracker();
    },
  },
  new ChangesSinceTracker()
);
