import debounce from 'lodash.debounce';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';

const _MIN_CHAR_LENGTH_TO_SEARCH = 3;
const _DEBOUNCE_TIME = 250;

/**
 * Returns a debounced version of the passed function
 * @param {function} debouncedFunction
 */
export const debounceSearch = (debouncedFunction) => {
  return debounce(debouncedFunction, _DEBOUNCE_TIME);
};

/**
 * Returns if the search value is of the minimum length to allow a search to be triggered
 * @param {string} searchTerm
 */
export const isSearchCharLengthValid = (
  searchTerm,
  minSearchCharLength = _MIN_CHAR_LENGTH_TO_SEARCH,
) => {
  return searchTerm.length >= minSearchCharLength;
};

/**
 * Returns a string formatted for displaying in the search field of create/edit project like <Entity Name> - <Fiscal Year>
 * @param {EngagementEntity} entity object representing engagement entity
 */
export const getSearchFormattedEngagementEntity = (entity) => {
  return `${entity.clientName} - ${moment(entity.engagementFye).format(
    DATE_FORMATS.YEAR,
  )}`;
};
