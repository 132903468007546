import React from 'react';
import { FormattedMessage } from 'react-intl';

import Switch from 'react-switch';
const BLACKLINE_VIEW_CONTROL = 'blackline-control';

const BlacklineViewToggle = ({
  blacklineViewMode,
  toggleBlacklineViewMode,
}) => {
  return (
    <div className={BLACKLINE_VIEW_CONTROL}>
      <span>
        <FormattedMessage id="custom-menu-panel-content.checkbox-heading.label-blackline" />
      </span>
      <Switch
        onChange={toggleBlacklineViewMode}
        checked={blacklineViewMode}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={45}
      />
    </div>
  );
};

export default BlacklineViewToggle;
