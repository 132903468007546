import { connect } from 'react-redux';
import {
  hideElementPanel,
  updateUserFlag,
  updateVerify,
  updateReviewed,
  fetchSelectedElementDetails,
  detachElementWorkpaper,
  detachTickmarkFromElement,
  excludeElement,
} from 'store/actions/element-panel-actions';
import {
  clearFormula,
  fetchSelectedFormulaDetails,
  setNewFormulaAction,
} from 'store/actions/formula-actions';
import {
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  clearInternalReference,
} from 'store/actions/internal-reference-actions';
import ElementPanel from 'components/feature/element-panel/element-panel-component';
import { setElementSelectModeInternalReferenceAction } from 'store/actions/modes-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import {
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
} from 'store/actions/panel-cancel-modal-actions';
import { selectNoteFromContentPanel } from 'store/actions/notes-panel-actions';
import { toggleNotesPanel } from 'store/actions/panel-controller-actions';
import { deselectElementContentPanel } from 'store/actions/statement-content-actions';
import { onSectionClick } from 'store/actions/statement-content-actions';
import {
  stopCopyFormula,
  cancelCopyFormula,
} from 'store/actions/copy-formula-actions';
const mapStateToProps = ({
  data: {
    elementPanel: {
      elementDetails,
      formulaList,
      internalReference,
      workpaperList,
      tickmarkList,
    },
    copyFormula: { tableMatrix, copyFormulaSelectedElement },
  },
  ui: {
    statementPage: {
      panels: { right },
      cancelModal,
    },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  elementDetails,
  formulaList,
  internalReference,
  workpaperList,
  tickmarkList,
  shouldShowElementPanel: right === RIGHT_PANELS.ELEMENT,
  cancelModal,
  socketHasBeenDisconnected,
  isFormulaCopied: tableMatrix && tableMatrix.length > 0,
  numberOfElementsSelectedForCopy:
    tableMatrix &&
    copyFormulaSelectedElement &&
    copyFormulaSelectedElement.selectedElementIds &&
    copyFormulaSelectedElement.selectedElementIds.length,
});
const mapDispatchToProps = {
  hideElementPanel,
  updateUserFlag,
  updateVerify,
  updateReviewed,
  fetchSelectedElementDetails,
  clearFormula,
  fetchSelectedFormulaDetails,
  clearInternalReference,
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  detachElementWorkpaper,
  detachTickmarkFromElement,
  initInternalReferencePanel: setElementSelectModeInternalReferenceAction,
  excludeElementFromElementList: excludeElement,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleNotesPanel,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
  selectNoteFromContentPanel,
  deselectElementContentPanel,
  setNewFormulaAction,
  stopCopyFormula,
  cancelCopyFormula,
  onSectionClick,
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementPanel);
