import { handleActions } from 'redux-actions';

import {
  addToCurrentSectionIds,
  removeFromCurrentSectionIds,
} from 'store/actions/current-section-actions';

export default handleActions(
  {
    [addToCurrentSectionIds]: (currentSectionIds, { payload }) => {
      return [...currentSectionIds, payload];
    },
    [removeFromCurrentSectionIds]: (currentSectionIds, { payload }) => {
      return currentSectionIds.filter((sectionId) => sectionId !== payload);
    },
  },
  []
);
