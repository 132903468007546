import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import InternalReference from 'models/api/internal-reference-model';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import ToolTip from 'components/common/tool-tip-component';
import { ReactComponent as Unlink } from 'icons/unlink.svg';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import {
  getElementStatusIcon,
  getElementFlaggedIcon,
} from 'constants/feature/element-panel-constants';
import { COMFORT_ASSIGN_ELEMENT } from 'components/feature/statement-content-panel/tieout-element-component';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export const INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK =
  'internal-reference-element-entry';
const INTERNAL_REFERENCE_ELEMENT_ENTRY_ID_BLOCK =
  'internal-reference-element-entry-id';

const UNLINK_ICON_SIZE = '28px';

const INTERNAL_REF_ENTRY_TOOLTIP = new TooltipOptions({
  text: 'common.unlink',
  id: 'internal-reference-unlink-button-tool-tip',
  position: 'left',
});

const InternalReferenceElementEntry = ({
  elementDetails,
  internalReference,
  elementDetachAction,
  selectElementAction,
  selectedElement,
  disabled,
  highlighted,
  checkIfFeatureFlagEnabled,
}) => {
  const onElementClick = async () => {
    await scrollElementIntoView({
      elementId: elementDetails.id,
      sectionId: elementDetails.sectionId,
    });
    selectElementAction({ elementId: elementDetails.id });
  };

  return (
    <div
      className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK} ${
        highlighted
          ? `${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}--selected`
          : null
      }`}
      onClick={() => {
        onElementClick();
      }}
    >
      <div
        className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__text-container`}
      >
        <div
          className={classNames(
            `${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__element-value-wrapper`,
            !elementDetails.shouldFlagIR(selectedElement) &&
              `${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__flagged`,
          )}
        >
          <button
            id={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_ID_BLOCK}-element-value`}
            className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__element-value`}
          >
            {getElementFlaggedIcon({ elementDetails })}
            <span
              className={classNames(
                elementDetails &&
                  elementDetails.isComfortAssign() &&
                  checkIfFeatureFlagEnabled({
                    featureName: FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE,
                  }) &&
                  COMFORT_ASSIGN_ELEMENT,
              )}
            >
              {elementDetails.display}
            </span>
          </button>
          <div
            className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__annotations`}
          >
            <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
              {ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
                elementDetails,
              })}
            </FeatureFlagComponent>
            {ANNOTATION_DISPLAY_FUNCTIONS.tickmarkText({
              elementDetails,
            })}
            {/* {ANNOTATION_DISPLAY_FUNCTIONS.formula({
              elementDetails,
            })} */}
            {ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
              elementDetails,
            })}
            {ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
              elementDetails,
            })}
          </div>
          {getElementStatusIcon({ elementDetails })}
        </div>
        <ToolTip
          id={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_ID_BLOCK}__section-name-tooltip-${elementDetails.id}`}
          text={elementDetails.sectionname}
          isNotInternationalized
        >
          <span
            className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__section-name`}
          >
            {elementDetails.sectionname}
          </span>
        </ToolTip>
        <ToolTip
          id={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_ID_BLOCK}__element-label-tooltip-${elementDetails.id}`}
          className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__element-label-tooltip`}
          text={elementDetails.label}
          isNotInternationalized
          position={'left'}
        >
          <span
            className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__element-label`}
          >
            {elementDetails.label}
          </span>
        </ToolTip>
        <AmountAttributeDetails
          className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__amount-attributes`}
          elementDetails={elementDetails}
          flagMap={{
            scaling: selectedElement.scaling === elementDetails.scaling,
            units: selectedElement.units === elementDetails.units,
            period: selectedElement.period === elementDetails.period,
            entity: selectedElement.entity === elementDetails.entity,
          }}
        />
      </div>
      <div
        className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__button-container`}
      >
        <IconButton
          id={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_ID_BLOCK}-unlink-${elementDetails.id}`}
          tooltip={INTERNAL_REF_ENTRY_TOOLTIP}
          position={'left'}
          className={`${INTERNAL_REFERENCE_ELEMENT_ENTRY_BLOCK}__unlink`}
          onClick={(e) => {
            e.stopPropagation();
            elementDetachAction({
              internalReferenceId: internalReference.id,
              elementId: elementDetails.id,
            });
          }}
          Icon={Unlink}
          iconSize={UNLINK_ICON_SIZE}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

InternalReferenceElementEntry.propTypes = {
  /** elementDetails of the element attached to an internal reference */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /**The model the represents the internal reference. */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /** Action for detaching an element from the internal reference */
  elementDetachAction: PropTypes.func.isRequired,
  /** Action fired for highlighting an element from the content panel on click*/
  selectElementAction: PropTypes.func.isRequired,
  /** elementDetails of selected element */
  selectedElement: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Indicates whether the buttons are disabled or not */
  disabled: PropTypes.bool,
  /* bool that indicates if the row needs to be highlighted */
  highlighted: PropTypes.bool.isRequired,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

export default InternalReferenceElementEntry;
