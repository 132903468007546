import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Checkbox from 'components/common/checkbox-component';

const SUPPORT_ACCESS_DISCLAIMER_BLOCK = 'support-access-disclaimer-modal';
const SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK = 'support-access-disclaimer-modal-id';

export const AcknowledgeDisclaimerModal = ({ onClose, onSubmit }) => {
  const [toggleAcknowledge, setToggleAcknowledge] = useState(false);
  return (
    <Modal
      id={`${SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}`}
      title={
        'project-creation.manage-users.support-team-access.disclaimer-modal-title'
      }
      primaryModalButton={
        new ModalButton({
          text: 'common.submit',
          onClick: onSubmit,
          disabled: !toggleAcknowledge,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      onClose={onClose}
    >
      <div className={`${SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-1`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.support-team-access.disclaimer-modal-message-first-paragrath'
          }
        />
      </div>
      <div className={`${SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-2`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.support-team-access.disclaimer-modal-message-sec-paragrath'
          }
        />
      </div>
      <div className={`${SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-3`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.support-team-access.disclaimer-modal-message-third-paragrath'
          }
        />
      </div>
      <div className={`${SUPPORT_ACCESS_DISCLAIMER_BLOCK}__checkbox`}>
        <Checkbox
          checked={toggleAcknowledge}
          onChange={() => setToggleAcknowledge(!toggleAcknowledge)}
          label={
            'project-creation-edit.manage-users.acknowledge-support-team-access'
          }
          id={`${SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}-disclaimer-checkbox`}
          width={'100%'}
          isValid={true}
        />
      </div>
    </Modal>
  );
};

AcknowledgeDisclaimerModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /** function fired to submit acknowledgement */
  onSubmit: PropTypes.func.isRequired,
};
