import { handleActions } from 'redux-actions';

import {
  sectionDetailsLoaded,
  clearSectionDetails,
  sectionDetailsLoading,
} from 'store/actions/statement-content-actions';

const contentSectionDetails = { isLoading: false, isLoaded: true, error: null };

export default handleActions(
  {
    [sectionDetailsLoaded]: (contentSection, { payload }) => {
      return { ...payload, isLoading: false, isLoaded: true, error: null };
    },
    [clearSectionDetails]: (contentSection, { payload }) => {
      return contentSectionDetails;
    },
    [sectionDetailsLoading]: () => {
      return { isLoading: true, isLoaded: false, error: null };
    },
  },
  contentSectionDetails,
);
