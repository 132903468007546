import { withDefaultContextAsSelectedProject } from './api-default-context';
import { REVISION_STATUSES } from 'constants/feature/revision-status-constants';
import { getStatementIdUtil } from 'utils/statement-export-utils';
import { serializedProjectIdWithGeo } from 'utils/project-utils';

const getRevisionRequest = (
  revisionId,
  selectedProjectGeoCode,
  statementId,
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  return withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(
      `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revision/${revisionId}`,
    )
    .then((response) => {
      response.data.clientId = serializedProjectIdWithGeo(
        response.data.clientId,
        selectedProjectGeoCode,
      );
      return response;
    });
};

const getAllRevisionsForStatement = (statementId) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/statements/${statementId}/revisions`, {
      params: {
        status: `${REVISION_STATUSES.ACTIVE.status},${REVISION_STATUSES.DELETED.status}`,
      },
    })
    .then((response) => {
      return response;
    });
};

const getDeletedRevisionsForStatement = (statementId) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/statements/${statementId}/revisions`, {
      params: {
        status: REVISION_STATUSES.DELETED.status,
      },
    })
    .then((response) => {
      return response;
    });
};

const getActiveRevisionsForStatement = (statementId) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/statements/${statementId}/revisions`, {
      params: {
        status: REVISION_STATUSES.ACTIVE.status,
      },
    })
    .then((response) => {
      return response;
    });
};

export default {
  getRevisionRequest,
  getAllRevisionsForStatement,
  getDeletedRevisionsForStatement,
  getActiveRevisionsForStatement,
};
