import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import FormItem from 'components/common/form-item-component';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const TextEditor = ({
  className,
  disabled,
  errorText,
  id,
  isValid,
  label,
  maxLength,
  onChange,
  onBlur,
  placeholder,
  tooltip,
  width,
  initContents,
  intl,
}) => {
  const _placeholder =
    typeof placeholder === 'string'
      ? intl.formatMessage({ id: placeholder })
      : intl.formatMessage({ id: placeholder.id }, { ...placeholder.values });
  return (
    <FormItem
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      label={label}
      tooltip={tooltip}
      width={width}
    >
      <SunEditor
        onChange={onChange}
        onBlur={onBlur}
        width={width}
        disable={disabled}
        setContents={initContents}
        setOptions={{
          buttonList: [['bold', 'italic', 'underline', 'list']],
        }}
        placeholder={_placeholder}
        setDefaultStyle="font-size: 16px; height: 224px"
      />
    </FormItem>
  );
};

TextEditor.propTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** Unique string id for text area */
  id: PropTypes.string.isRequired,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Maximum text area length */
  maxLength: PropTypes.number,
  /** Function fired on user input return value*/
  onChange: PropTypes.func.isRequired,
  /** Function fired when focus is lost from text area*/
  onBlur: PropTypes.func,
  /** Internationalized sting id */
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.object,
    }),
  ]),
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** String percentage representing width of component passed to form item*/
  width: PropTypes.string,
  /** Initial rich text string contents of the editor */
  initContents: PropTypes.string,
};

export default memo(injectIntl(TextEditor));
