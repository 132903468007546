import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';

export const GEO_NAMES = {};
const ALL_OPTION = 'ALL';
const geoCodes = Object.keys(window.TIEOUT.ENV.GEOS);

//Created this function because in future if any other GEO is added in the list,
//we need not to update it here, It will get picked automatically from env file.
const createGeoCodeMap = () => {
  geoCodes.unshift(ALL_OPTION);
  geoCodes.forEach((code) => {
    if (!GEO_NAMES[code])
      GEO_NAMES[code] = code === ALL_OPTION ? EMPTY_STRING : code;
  });
};

createGeoCodeMap();

export const GEO_OPTION_MAP = new Map();
geoCodes.forEach((code) => {
  GEO_OPTION_MAP.set(
    code,
    new MenuOption({
      id: code,
      title: `project-list.header.dropdown.option.${code}`,
      value: code === ALL_OPTION ? EMPTY_STRING : code,
    }),
  );
});

export const GEO_OPTIONS = Array.from(GEO_OPTION_MAP.values());
