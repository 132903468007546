/**
 * Abstract class for different models that are used in React State or Redux, comes with convenient `merge`
 * function that accepts only the keys you wish to change (like setState) and returns a new instance of a class
 * with the changes
 *
 * Should not be instantied by itself, it is meant to be extended by React State or Redux models that do NOT fetch data from the API
 *
 * NOTE: If you need to set data from the api extend the adjacent ApiModel.
 */
export default (defaultProps = {}) => {
  return class BaseModel {
    constructor(props) {
      Object.assign(this, defaultProps, props);
    }

    merge(mergeProps) {
      return new this.constructor({
        ...this,
        ...mergeProps,
      });
    }

    getDropdownValue(menuOptionVariable) {
      if (menuOptionVariable) {
        return menuOptionVariable.value;
      }
    }
  };
};
