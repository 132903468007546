import React from 'react';
import TabModel from 'models/utils/common/tab-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ReactComponent as FlaggedVerifiedIcon } from 'icons/flagged-and-verified.svg';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import { ReactComponent as VerifiedIcon } from 'icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'icons/unverified.svg';
import { ReactComponent as ReviewedIcon } from 'icons/reviewed.svg';
import { ReactComponent as FlaggedReviewedIcon } from 'icons/flagged-and-reviewed.svg';
import { isNullOrUndefined } from 'utils/object-utils';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

const ELEMENT_PANEL_STATUS_ICON_SIZE = '20px';
const ELEMENT_PANEL_FLAGGED_ICON_SIZE = '16px';

export const getElementStatusIcon = ({
    elementDetails,
    iconSize = ELEMENT_PANEL_STATUS_ICON_SIZE,
}) => {
    if (isNullOrUndefined(elementDetails)) {
        return null;
    } else if (elementDetails.isReviewed()) {
        return <ReviewedIcon width = { iconSize }
        height = { iconSize }
        />;
    } else if (elementDetails.isVerified()) {
        return <VerifiedIcon width = { iconSize }
        height = { iconSize }
        />;
    } else {
        return <UnverifiedIcon width = { iconSize }
        height = { iconSize }
        />;
    }
};

export const getElementFlaggedIcon = ({
    elementDetails,
    iconSize = ELEMENT_PANEL_FLAGGED_ICON_SIZE,
}) => {
    if (isNullOrUndefined(elementDetails)) {
        return null;
    } else if (elementDetails.isFlaggedAndReviewed()) {
        return <FlaggedReviewedIcon width = { iconSize }
        height = { iconSize }
        />;
    } else if (elementDetails.isFlaggedAndVerified()) {
        return <FlaggedVerifiedIcon width = { iconSize }
        height = { iconSize }
        />;
    } else if (elementDetails.isFlagged()) {
        return <FlaggedIcon width = { iconSize }
        height = { iconSize }
        />;
    }
    return null;
};

export const ELEMENT_ANNOTATIONS_TABS = {
    FORMULA: new TabModel({
        id: 1,
        // feature for which we do not wish to enable feature flag option
        featureName: FEATURE_NAMES.OTHER_FEATURES,
        intlTitle: 'element-panel.annotation.formula',
        intlValues: { formulaCount: 0 },
        tooltip: new TooltipOptions({
            text: 'element-panel.annotation.formula.tooltip',
            id: 'element-panel-annotation-formula-tooltip-id',
            position: 'top',
        }),
    }),
    INTERNAL_REFERENCE: new TabModel({
        id: 2,
        // feature for which we do not wish to enable feature flag option
        featureName: FEATURE_NAMES.OTHER_FEATURES,
        intlTitle: 'element-panel.annotation.internal-reference',
        intlValues: { internalReferenceCount: 0 },
        tooltip: new TooltipOptions({
            text: 'element-panel.annotation.internal-reference.tooltip',
            id: 'element-panel-annotation-internal-reference-tooltip-id',
            position: 'top',
        }),
    }),
    TICKMARK: new TabModel({
        id: 3,
        // feature for which we do not wish to enable feature flag option
        featureName: FEATURE_NAMES.OTHER_FEATURES,
        intlTitle: 'element-panel.annotation.tickmark',
        intlValues: { tickmarkCount: 0 },
        tooltip: new TooltipOptions({
            text: 'element-panel.annotation.tickmark.tooltip',
            id: 'element-panel-annotation-tickmark-tooltip-id',
            position: 'top',
        }),
    }),
    WORK_PAPER_REFERENCE: new TabModel({
        id: 4,
        // feature for which we do not wish to enable feature flag option
        featureName: FEATURE_NAMES.OTHER_FEATURES,
        intlTitle: 'element-panel.annotation.workpaper-reference',
        intlValues: { workpaperReferenceCount: 0 },
        tooltip: new TooltipOptions({
            text: 'element-panel.annotation.workpaper-reference.tooltip',
            id: 'element-panel-annotation-workpaper-tooltip-id',
            position: 'top',
        }),
    }),
    COMFORT_LETTER: new TabModel({
        id: 5,
        featureName: FEATURE_NAMES.COMFORT_LETTER_FEATURE,
        intlTitle: 'element-panel.annotation.comfort-letter',
        intlValues: { comfortLetterCount: 0 },
        tooltip: new TooltipOptions({
            text: {
                id: 'element-panel.annotation.comfort-letter.tooltip',
                values: {
                    br: (...chunks) => ( <
                        span key = { `element-panel.annotation.comfort-letter.tooltip-brTag-for-${chunks}` } >
                        <
                        br / > { chunks } { ' ' } <
                        /span>
                    ),
                },
            },
            id: 'element-panel.annotation.comfort-letter.tooltip-id',
            position: 'top',
        }),
    }),
};

export const getDynamicElementAnnotationsTabs = ({
    elementDetails,
    formulaList,
    internalReference,
    tickmarkList,
    workpaperList,
    comfortLetterList,
}) => {
    return [
        new TabModel({
            ...ELEMENT_ANNOTATIONS_TABS.WORK_PAPER_REFERENCE,
            intlValues: {
                workpaperReferenceCount: workpaperList.getWorkpaperCount(),
            },
        }),
        new TabModel({
            ...ELEMENT_ANNOTATIONS_TABS.FORMULA,
            intlValues: { formulaCount: formulaList.getFormulaCount() },
            className: elementDetails.isFormulaFlagged(true) ?
                'annotations-tabs__tab--flagged' : null,
        }),
        new TabModel({
            ...ELEMENT_ANNOTATIONS_TABS.INTERNAL_REFERENCE,
            intlValues: {
                internalReferenceCount: internalReference.getOtherElementsCount({
                    elementId: elementDetails.id,
                }),
            },
            className: elementDetails.isInternalRefFlagged(true) ?
                'annotations-tabs__tab--flagged' : null,
        }),
        new TabModel({
            ...ELEMENT_ANNOTATIONS_TABS.TICKMARK,
            intlValues: { tickmarkCount: tickmarkList.getTickmarkCount() },
        }),
        new TabModel({
            ...ELEMENT_ANNOTATIONS_TABS.COMFORT_LETTER,
            intlValues: {
                comfortLetterCount: comfortLetterList.getComfortLetterCount(),
            },
        }),
    ];
};

export const getReviewTooltip = (elementDetails) => {
    if (!elementDetails.isVerified()) {
        return new TooltipOptions({
            text: 'element-panel.checkbox.disable-review-tooltip',
            id: 'element-panel.checkbox.disable-review-tooltip-id',
            position: 'left',
        });
    } else {
        return new TooltipOptions({
            text: 'element-panel.checkbox.review.tooltip',
            id: 'element-panel.checkbox.review-tooltip-id',
            position: 'bottom',
        });
    }
};

export const getOverrideSystemFlagTooltip = (elementDetails) => {
    if (!(
            elementDetails.isFormulaFlagged() ||
            elementDetails.isInternalRefFlagged() ||
            elementDetails.isSystemOverrideFlagged()
        )) {
        return new TooltipOptions({
            text: 'element-panel.checkbox.disable-override-system-tooltip',
            id: 'element-panel.checkbox.disable-override-system-tooltip-id',
            position: 'left',
        });
    }
};

export const hoverVerifyTooltip = new TooltipOptions({
    text: 'element-panel.checkbox.verify.tooltip',
    id: 'element-panel.checkbox.verify-tooltip-id',
    position: 'bottom',
});

export const disableComfortAssignTooltip = new TooltipOptions({
    text: 'element-panel.checkbox.disabled.comfortAssign.tooltip',
    id: 'element-panel.checkbox.disabled.comfortAssign-tooltip-id',
    position: 'bottom',
});
export const reviewInfoButtonTooltip = (event) =>
    new TooltipOptions({
        text: 'element-panel.review.information',
        id: 'element-panel.info.button-review-tooltip-id',
        position: 'bottom',
        event: event,
    });

export const HISTORIC_ACTIONS = {
    VERIFIED: 'Verified',
    UNVERIFIED: 'Unverified',
    FLAGGED: 'Flagged',
    UNFLAGGED: 'UnFlagged',
    USER_FLAGGED: 'User flag added',
    USER_UNFLAGGED: 'User flag removed',
    REVIEWED: 'Reviewed',
    UNREVIEWED: 'Unreviewed',
    FLAG_OVERRIDE: 'System flag overridden',
    REMOVE_FLAG_OVERRIDE: 'System flag override removed',
    FORMULA_OVERRIDE: 'Formula annotation modified',
    ANNOTATION_ATTACHED: 'Annotation(s) Attached',
    ATTRIBUTE_MODIFIED: 'Attribute(s) Modified',
};

export const FORMULA_TYPES = {
    FLOATED_FORMULA: 'f',
    CROSS_FOOTED_FORMULA: 'cf',
    RECALCULATED_FORMULA: 'rx',
};

export const FORMULA_OPTIONS_MAP = new Map();
FORMULA_OPTIONS_MAP.set(
    FORMULA_TYPES.FLOATED_FORMULA,
    new MenuOption({
        id: FORMULA_TYPES.FLOATED_FORMULA,
        title: 'element-panel.amount-attribute.formula.option.footed_formula',
        value: FORMULA_TYPES.FLOATED_FORMULA,
    }),
);
FORMULA_OPTIONS_MAP.set(
    FORMULA_TYPES.CROSS_FOOTED_FORMULA,
    new MenuOption({
        id: FORMULA_TYPES.CROSS_FOOTED_FORMULA,
        title: 'element-panel.amount-attribute.formula.option.cross_footed_formula',
        value: FORMULA_TYPES.CROSS_FOOTED_FORMULA,
    }),
);
FORMULA_OPTIONS_MAP.set(
    FORMULA_TYPES.RECALCULATED_FORMULA,
    new MenuOption({
        id: FORMULA_TYPES.RECALCULATED_FORMULA,
        title: 'element-panel.amount-attribute.formula.option.recalculated_formula',
        value: FORMULA_TYPES.RECALCULATED_FORMULA,
    }),
);

export const FORMULA_OPTIONS = Array.from(FORMULA_OPTIONS_MAP.values());