import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'components/common/dropdown-component';
import {
  FORMULA_OPERATOR_TYPE_OPTIONS,
  ROUND_NUMBER_ICON_HEIGHT,
  ROUND_NUMBER_ICON_WIDTH,
  getOriginalComputedValueTooltip,
  getResultDisplay,
} from 'constants/feature/formula-constants';
import FormulaPanelEditContainer from 'containers/feature/formula-panel/formula-panel-edit-formula-container';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import FormulaForm from 'models/form/formula-form-model';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import { ReactComponent as RoundNumberIcon } from 'icons/round-number.svg';
import classnames from 'classnames';
import { shouldDisplayValueBeFlagged } from 'utils/formula-utils';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import Tooltip from 'components/common/tool-tip-component';
import { isNullOrUndefined } from 'utils/object-utils';
import { getElementResultByNumberFormat } from 'utils/formula-utils';
import { removeCommasFromNumber } from 'utils/number-formatter-util';

export const FORMULA_PANEL_CONTENT_BLOCK = 'formula-panel-content';
export const FORMULA_PANEL_CONTENT_ID_BLOCK = 'formula-panel-content-id';
export const FLAGGED_FORMULA_ICON_WIDTH = '15px';
export const FLAGGED_FORMULA_ICON_HEIGHT = '19px';

const OPERATOR_DROP_DOWN_WIDTH = '48px';

class FormulaPanelContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementDisplayHovered: false,
      isInputEmpty: false,
    };
  }

  componentDidMount() {
    const { initFormulaCreate } = this.props;
    initFormulaCreate();
  }

  handleElementDisplayOnMouseEnter = () => {
    this.setState((state) => ({
      elementDisplayHovered: true,
    }));
  };

  handleElementDisplayOnMouseLeave = () => {
    this.setState((state) => ({
      elementDisplayHovered: false,
    }));
  };

  checkEmptyInput = (number) => {
    const { isInputEmpty } = this.state;
    if (number === '') {
      if (!isInputEmpty) {
        this.setState({ isInputEmpty: true });
      }
    } else {
      if (isInputEmpty) {
        this.setState({ isInputEmpty: false });
      }
    }
  };

  render() {
    const {
      elementDetails,
      formulaInProgress,
      setFormulaOperator,
      saveFormula,
      onCancel,
      numberFormatId,
    } = this.props;
    const { elementDisplayHovered, isInputEmpty } = this.state;
    const _isFormulaValid = formulaInProgress.isFormulaValid();
    return (
      <ConditionalRender dependencies={[elementDetails]}>
        <div className={`${FORMULA_PANEL_CONTENT_BLOCK}`}>
          <div
            className={classNames(
              `${FORMULA_PANEL_CONTENT_BLOCK}__selected-rounded-value-container`,
            )}
          >
            <div
              className={classNames(
                `${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-container`,
                elementDisplayHovered
                  ? `${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-container--hovered`
                  : null,
              )}
            >
              <div
                className={`${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-display-container`}
              >
                <span>
                  <FormattedMessage id="formula-panel.edit.selected-amount" />
                </span>
                <button
                  id={`${FORMULA_PANEL_CONTENT_ID_BLOCK}-element-value`}
                  className={`${FORMULA_PANEL_CONTENT_BLOCK}__element-value`}
                  onMouseEnter={this.handleElementDisplayOnMouseEnter}
                  onMouseLeave={this.handleElementDisplayOnMouseLeave}
                  onClick={() =>
                    scrollElementIntoView({
                      elementId: elementDetails.id,
                      sectionId: elementDetails.sectionId,
                    })
                  }
                >
                  <MultiLineEllipsis
                    toolTipID={`${FORMULA_PANEL_CONTENT_BLOCK}-element-value`}
                    tooltipClassName={`${FORMULA_PANEL_CONTENT_BLOCK}__element-value-tooltip`}
                    text={getElementResultByNumberFormat(
                      removeCommasFromNumber(formulaInProgress.display),
                      numberFormatId,
                    )}
                    isNotInternationalized={true}
                  />
                </button>
              </div>
              <Dropdown
                id={`${FORMULA_PANEL_CONTENT_BLOCK}-operator`}
                width={OPERATOR_DROP_DOWN_WIDTH}
                onSelectOption={setFormulaOperator}
                isValid={true}
                options={FORMULA_OPERATOR_TYPE_OPTIONS}
                value={formulaInProgress.operator}
                isNotIntl={true}
                hideDropDownArrow={true}
                hideCheckmarks={true}
                centerMenuDisplay={true}
              />
            </div>
            <div
              className={classNames(
                `${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-container`,
                `${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-container--margin-left`,
              )}
            >
              <div
                className={`${FORMULA_PANEL_CONTENT_BLOCK}__selected-value-display-container`}
              >
                <span>
                  <FormattedMessage id="formula-panel.edit.computed-amount" />
                </span>
                <div>
                  <div
                    className={classnames(
                      shouldDisplayValueBeFlagged(
                        elementDetails,
                        formulaInProgress,
                      )
                        ? `${FORMULA_PANEL_CONTENT_BLOCK}__flagged`
                        : `${FORMULA_PANEL_CONTENT_BLOCK}__computed-amount`,
                    )}
                  >
                    {formulaInProgress.isFormulaRounded(elementDetails) && (
                      <div
                        className={`${FORMULA_PANEL_CONTENT_BLOCK}__prefix-icon`}
                      >
                        <Tooltip
                          {...getOriginalComputedValueTooltip(
                            getResultDisplay({
                              result: formulaInProgress.result,
                              numberFormatId,
                            }),
                          )}
                        >
                          <RoundNumberIcon
                            className={`${FORMULA_PANEL_CONTENT_BLOCK}__rounded-icon`}
                            width={ROUND_NUMBER_ICON_WIDTH}
                            height={ROUND_NUMBER_ICON_HEIGHT}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {(!isNullOrUndefined(formulaInProgress.formulaId) ||
                      formulaInProgress.isNewFormula) &&
                      formulaInProgress.isFormulaFlagged(elementDetails) &&
                      !elementDetails.isSystemOverrideFlagged() && (
                        <div
                          className={`${FORMULA_PANEL_CONTENT_BLOCK}__prefix-icon`}
                        >
                          <FlaggedIcon
                            width={FLAGGED_FORMULA_ICON_WIDTH}
                            height={FLAGGED_FORMULA_ICON_HEIGHT}
                          />
                        </div>
                      )}
                    <div
                      className={`${FORMULA_PANEL_CONTENT_BLOCK}__display-value`}
                    >
                      {getResultDisplay({
                        result: !isNullOrUndefined(
                          formulaInProgress.roundingResult,
                        )
                          ? formulaInProgress.roundingResult
                          : formulaInProgress.computedResult,
                        numberFormatId,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FormulaPanelEditContainer checkEmptyInput={this.checkEmptyInput} />
          <div
            className={`${FORMULA_PANEL_CONTENT_BLOCK}__save-button-container`}
          >
            <Button
              className={`${FORMULA_PANEL_CONTENT_BLOCK}__cancel-button`}
              id={`${FORMULA_PANEL_CONTENT_ID_BLOCK}-cancel-button`}
              type={BUTTON_TYPES.secondary}
              onClick={onCancel}
            >
              <FormattedMessage id={'common.cancel'} />
            </Button>
            <Button
              className={`${FORMULA_PANEL_CONTENT_BLOCK}__save-button`}
              id={`${FORMULA_PANEL_CONTENT_ID_BLOCK}-save-button`}
              type={BUTTON_TYPES.primary}
              onClick={() => saveFormula()}
              disabled={
                isInputEmpty ||
                !_isFormulaValid ||
                (_isFormulaValid && !formulaInProgress.hasBeenModified)
              }
            >
              <FormattedMessage id={'common.save'} />
            </Button>
          </div>
        </div>
      </ConditionalRender>
    );
  }
}

FormulaPanelContent.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** The formula we are working on from the redux store */
  formulaInProgress: PropTypes.instanceOf(FormulaForm).isRequired,
  /** The action used to initialize a formula form model for creation */
  initFormulaCreate: PropTypes.func.isRequired,
  /** The action used to set the operator on the current formula */
  setFormulaOperator: PropTypes.func.isRequired,
  /** Action fired to save the formula */
  saveFormula: PropTypes.func.isRequired,
};

export default FormulaPanelContent;
