import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import { shouldShowMoreButton } from 'constants/feature/tickmark-constants';
import { isNullOrUndefined } from '../../../../../utils/object-utils';

export const COMFORT_LETTER_ENTRY_BLOCK = 'comfort-letter-entry';
export const COMFORT_LETTER_ENTRY_ID_BLOCK = 'comfort-letter-entry-id';
export const COMFORT_LETTER_TEXT_CHAR_LENGTH = 150;
export const COMFORT_LETTER_TEXT_LINES_LENGTH = 3;

export const ComfortLetterEntry = ({
  comfortLetter,
  className,
  rightComponent,
  bottomComponent,
  absoluteTopRightComponent,
  updateExpandedComfortLetterID,
  currentlyExpandedComfortLetterID,
}) => {
  const [toggleShowMore, setToggleShowMore] = React.useState(true);
  const [isShowLessBtnVisible, setShowLessBtnVisible] = React.useState(false);
  const [refValue, setRefValue] = React.useState(null);
  const [clamped, setClamped] = React.useState(false);

  const comfortLetterTextRef = React.useRef(null);

  const toggleShowMoreClicked = (toggleStatus) => {
    setToggleShowMore(toggleStatus);
    updateExpandedComfortLetterID &&
      updateExpandedComfortLetterID(
        toggleStatus,
        comfortLetter.comfortLetterId,
        comfortLetterTextRef,
      );
  };

  /**based on toggleShowMore value it will trigger updateExpandedComfortLetterID callback method send latest reference element to 
  Parent class to get clientHeight or offSetHeight value of selected element, so that the value can be used to update height 
  in virtualized list component div elements to extended scroll bar and to view complete description of selected Comfort Annotation*/
  useEffect(() => {
    if (toggleShowMore === false) {
      updateExpandedComfortLetterID &&
        updateExpandedComfortLetterID(
          toggleShowMore,
          comfortLetter.comfortLetterId,
          comfortLetterTextRef,
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleShowMore]);

  useEffect(() => {
    if (
      Number.isInteger(currentlyExpandedComfortLetterID) &&
      !toggleShowMore &&
      currentlyExpandedComfortLetterID !== comfortLetter.comfortLetterId
    ) {
      setToggleShowMore(true);
      setShowLessBtnVisible(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comfortLetter.comfortLetterId, currentlyExpandedComfortLetterID]);

  useEffect(() => {
    if (
      Number.isInteger(currentlyExpandedComfortLetterID) &&
      toggleShowMore &&
      currentlyExpandedComfortLetterID === comfortLetter.comfortLetterId
    ) {
      setToggleShowMore(false);
      setShowLessBtnVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comfortLetter.comfortLetterId, currentlyExpandedComfortLetterID]);

  React.useEffect(() => {
    setRefValue(comfortLetterTextRef);
  }, []);

  React.useEffect(() => {
    if (
      shouldShowMoreButton({
        textRef: refValue && refValue.current,
      })
    ) {
      setClamped(true);
    } else {
      if (!isShowLessBtnVisible) {
        setClamped(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refValue, comfortLetter]);

  const onShowMoreBtnClick = () => {
    toggleShowMoreClicked(false);
    setShowLessBtnVisible(true);
  };

  const onShowLessBtnClick = () => {
    toggleShowMoreClicked(true);
    setShowLessBtnVisible(false);
  };

  return (
    <div
      key={comfortLetter.comfortLetterId}
      className={classnames(`${COMFORT_LETTER_ENTRY_BLOCK}`, className)}
      id={`${COMFORT_LETTER_ENTRY_ID_BLOCK}-${comfortLetter.comfortLetterId}`}
    >
      <div className={`${COMFORT_LETTER_ENTRY_BLOCK}__top`}>
        <div className={`${COMFORT_LETTER_ENTRY_BLOCK}__info`}>
          <span className={`${COMFORT_LETTER_ENTRY_BLOCK}__custom-label`}>
            {`[${comfortLetter.customLabel}]`}
          </span>
          <span
            className={classnames(
              `${COMFORT_LETTER_ENTRY_BLOCK}__body`,
              !toggleShowMore && clamped
                ? `${COMFORT_LETTER_ENTRY_BLOCK}__body--show-less`
                : null,
            )}
            ref={comfortLetterTextRef}
            dangerouslySetInnerHTML={{
              __html: comfortLetter.descriptionRichText,
            }}
          />
          {clamped &&
            (toggleShowMore ? (
              <Button
                id={`${COMFORT_LETTER_ENTRY_ID_BLOCK}-show-more-button`}
                className={`${COMFORT_LETTER_ENTRY_BLOCK}__show-more-button`}
                type={BUTTON_TYPES.tertiary}
                onClick={onShowMoreBtnClick}
              >
                <FormattedMessage id={'common.show-more'} />
              </Button>
            ) : (
              <Button
                id={`${COMFORT_LETTER_ENTRY_ID_BLOCK}-show-less-button`}
                className={`${COMFORT_LETTER_ENTRY_BLOCK}__show-less-button`}
                type={BUTTON_TYPES.tertiary}
                onClick={onShowLessBtnClick}
              >
                <FormattedMessage id={'common.show-less'} />
              </Button>
            ))}
        </div>
        {!isNullOrUndefined(rightComponent) && (
          <div
            className={classnames(
              `${COMFORT_LETTER_ENTRY_BLOCK}__actions`,
              !isNullOrUndefined(className) ? `${className}__actions` : null,
            )}
          >
            {rightComponent}
          </div>
        )}
      </div>
      {!isNullOrUndefined(bottomComponent) && (
        <div
          className={classnames(
            `${COMFORT_LETTER_ENTRY_BLOCK}__bottom`,
            !isNullOrUndefined(className) ? `${className}__bottom` : null,
          )}
        >
          {bottomComponent}
        </div>
      )}
      {!isNullOrUndefined(absoluteTopRightComponent) && (
        <div
          className={classnames(
            `${COMFORT_LETTER_ENTRY_BLOCK}__top-right`,
            !isNullOrUndefined(className) ? `${className}__top-right` : null,
          )}
        >
          {absoluteTopRightComponent}
        </div>
      )}
    </div>
  );
};

ComfortLetterEntry.propTypes = {
  /** comfortLetter object from the comfortLetter list endpoint */
  comfortLetter: PropTypes.object.isRequired,
  /** Component placed absolutely in top right corner of entry */
  absoluteTopRightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Component used to children to the right of the main content, displayed as flex container with content*/
  rightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Component used to place any nodes below the normal entry component*/
  bottomComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Custom classname applied to entry */
  className: PropTypes.string,
  /* function to set the currently expanded comfort letter id after clicking Show More button */
  updateExpandedComfortLetterID: PropTypes.func,
  /** Comfort Letter id of the comfort letter expanded on the toolkit panel after clicking Show More button */
  currentlyExpandedComfortLetterID: PropTypes.number,
};

export default memo(ComfortLetterEntry);
