export default class StatementStatusModel {
  id;
  name;
  desc;
  lname;
  intlIdentifier;
  constructor({ id, name, desc, lname, intlIdentifier }) {
    this.id = id;
    this.name = name;
    this.desc = desc;
    this.lname = lname;
    this.intlIdentifier = intlIdentifier; //internationalization string identifier
  }
}
