import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { getUserNameByUserData } from 'utils/statement-content-page-utils';

export const SIGN_OFF_HISTORY_OPTIONS = {
  REVIEW: 'REVIEW',
  UNREVIEW: 'UNREVIEW',
  LABEL_UPDATED: 'LABEL_UPDATED',
  ENTITY_UPDATED: 'ENTITY_UPDATED',
  CREATE: 'CREATE',
  ASSIGN_TO: 'ASSIGN_TO',
  ASSIGN_BY: 'ASSIGN_BY',
  UNASSIGN_TO: 'UNASSIGN_TO',
  UNASSIGN_BY: 'UNASSIGN_BY',
  UNASSIGN_ALL: 'UNASSIGN_ALL',
};

export const reviewInfoButtonTooltip = (event) =>
  new TooltipOptions({
    text: 'section-details.status.review.information',
    id: 'section-details.info.button-verify-tooltip-id',
    position: 'bottom',
    event: event,
  });
export const hoverReviewTooltip = new TooltipOptions({
  text: 'section-details.status.review.checkbox.tooltip',
  id: 'section-details.checkbox.review-tooltip-id',
  position: 'left',
});
export const SYSTEM_USER_NAME = 'System';
export const SECTION_HIGHLIGHT_STATES = {
  DEFAULT: 'default',
  CREATED_NAV: 'created-nav',
  PANEL_SELECTED: 'panel-selected',
};

export const getUserMenuOptions = (users, assignedUsers) => {
  return users.map(
    (user) =>
      new MenuOption({
        id: user.id,
        title: getUserNameByUserData(user.id, users),
        value: user,
        isIntl: false,
        disabled: user.id in assignedUsers ? true : false,
      }),
  );
};
