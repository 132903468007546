import { isNullOrUndefined } from './object-utils';

export const getArrayWithNewSection = (
  tree,
  section,
  parentId,
  inline,
  isSectionAlreadyInTree,
) => {
  let isSectionAdded = false;
  if (tree.children) {
    tree.children.forEach((children, index) => {
      //case one: section is already in tree, wee need to add a child to the existing node with the same section id.
      if (isSectionAlreadyInTree) {
        // if current children has the same section id
        if (children.id === section.id && !children.isNew) {
          if (children.children) {
            if (inline) {
              tree.children.splice(index + 1, 0, section);
            } else {
              children.children.unshift(section);
            }
          } else {
            if (inline) {
              tree.children.splice(index + 1, 0, section);
            } else {
              children.children = [section];
            }
          }
          isSectionAdded = true;
          return isSectionAdded;
        } else {
          //if not, it calls himself with the new tree data
          if (!isSectionAdded) {
            isSectionAdded = getArrayWithNewSection(
              children,
              section,
              parentId,
              inline,
              isSectionAlreadyInTree,
            );
          }
        }
      } else {
        //case 2 the section id does not exist in the tree so we need to append the new section to a node
        if (children.id === parentId) {
          if (inline) {
            tree.children.splice(index + 1, 0, section);
          } else {
            const indentParent = tree.children[index];
            if (indentParent.children) {
              indentParent.children.push(section);
            } else {
              indentParent.children = [section];
            }
          }
          isSectionAdded = true;
          return isSectionAdded;
        } else {
          if (!isSectionAdded) {
            isSectionAdded = getArrayWithNewSection(
              children,
              section,
              parentId,
              inline,
              isSectionAlreadyInTree,
            );
          }
        }
      }
    });
  }
  return isSectionAdded;
};
//function to delete section added when the modal opened and when the modal closes
export const deleteNewSection = (tree, sectionId) => {
  let isSectionDeleted = false;
  if (tree.children) {
    tree.children.forEach((children, index) => {
      if (children.id === sectionId && children.isNew) {
        if (tree.children.length === 1) {
          tree.children = null;
        } else {
          tree.children.splice(index, 1);
        }
        isSectionDeleted = true;
        return isSectionDeleted;
      } else {
        if (!isSectionDeleted) {
          isSectionDeleted = deleteNewSection(children, sectionId);
        }
      }
    });
  }
  return isSectionDeleted;
};
//function to verify if a section is already in the tree
export const isSectionInTree = (tree, parentId) => {
  let isSection = false;
  if (tree.id === parentId) {
    isSection = true;
    return isSection;
  } else {
    if (tree.children) {
      tree.children.forEach((children) => {
        if (!isSection) {
          isSection = isSectionInTree(children, parentId);
        }
      });
    }
  }
  return isSection;
};

//function to get tree in section
export const getTreeBySectionId = (tree, sectionId) => {
  let selectedTree = null;
  if (tree.id === sectionId) {
    selectedTree = tree;
    return selectedTree;
  } else {
    if (tree.children) {
      tree.children.forEach((children) => {
        if (!selectedTree) {
          selectedTree = getTreeBySectionId(children, sectionId);
        }
      });
    }
  }
  return selectedTree;
};

export const isRootLevelSection = (tree, parentId) => {
  let parentSection = getTreeBySectionId({ children: tree }, parentId);

  return (
    isNullOrUndefined(parentSection) ||
    isNullOrUndefined(parentSection.parentId)
  );
};
