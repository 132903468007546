import { handleActions } from 'redux-actions';
import {
  fetchSectionAssignmentsListLoaded,
  fetchSectionAssignmentsListError,
  updateSectionAssignmentListFromSocket,
  updateSectionAssignmentListForBulkAssignFromSocket,
  updateSectionAssignmentListForBulkUnAssignFromSocket,
} from 'store/actions/section-assignment-actions';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';

export default handleActions(
  {
    [fetchSectionAssignmentsListLoaded]: (
      SectionAssignmentsList,
      { payload },
    ) => {
      return SectionAssignmentsList.setLoaded(payload);
    },

    [fetchSectionAssignmentsListError]: (
      SectionAssignmentsList,
      { payload },
    ) => {
      return SectionAssignmentsList.setError(payload);
    },
    [updateSectionAssignmentListFromSocket]: (
      SectionAssignmentsList,
      { payload },
    ) => {
      return SectionAssignmentsList.updateSectionAssignmentListFromSocket(
        payload,
      );
    },
    [updateSectionAssignmentListForBulkAssignFromSocket]: (
      SectionAssignmentsList,
      { payload },
    ) => {
      return SectionAssignmentsList.updateSectionAssignmentListForBulkAssignFromSocket(
        payload,
      );
    },
    [updateSectionAssignmentListForBulkUnAssignFromSocket]: (
      SectionAssignmentsList,
      { payload },
    ) => {
      return SectionAssignmentsList.updateSectionAssignmentListForBulkUnAssignFromSocket(
        payload,
      );
    },
  },
  new SectionAssignmentsList(),
);
