const envParam = window.TIEOUT.ENV.ENV_PARAM;

function addScript(src) {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.async = true;
  element.src = src;
  document.head.appendChild(element);
  window._walkmeConfig = { smartLoad: true };
}

var src = 'https://cdn.walkme.com/users/4a7690aa5e724a2e9323da9b9849482e/test/walkme_4a7690aa5e724a2e9323da9b9849482e_https.js';
switch (envParam) {
  case 'PROD':
  case 'BCP':
    src = 'https://cdn.walkme.com/users/4a7690aa5e724a2e9323da9b9849482e/walkme_4a7690aa5e724a2e9323da9b9849482e_https.js';
    break;
  default:
    src = 'https://cdn.walkme.com/users/4a7690aa5e724a2e9323da9b9849482e/test/walkme_4a7690aa5e724a2e9323da9b9849482e_https.js';
    break;
};

addScript(src);
