import FormulaRow from './formula-row-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  formatManualInputFormulaRowNumber,
  FLOATING_POINT_NUMBER_REGEX,
} from 'utils/number-formatter-util';
import { FORMULA_ROW_TYPES } from 'constants/feature/formula-constants';

export default class FormulaRowNumber extends FormulaRow {
  value = '0';
  constructor(props) {
    super(props);
    this.type = FORMULA_ROW_TYPES.NUMBER;
    if (!isNullOrUndefined(props) && !isNullOrUndefined(props.value)) {
      this.value = props.value;
    }
  }
  get display() {
    return this.hasValue() ? formatManualInputFormulaRowNumber(this.value) : 0;
  }

  hasValue() {
    return (
      !isNullOrUndefined(this.value) && this.value !== '' && this.value !== '0'
    );
  }
  setValue(value) {
    let rowError = false;
    if (value && !FLOATING_POINT_NUMBER_REGEX.test(value)) {
      rowError = true;
    }
    return this.merge({ value, rowError });
  }
}
