export const getNumVisibleRows = ({
  dataModel,
  options,
  visibleRowsWhileLoading,
  visibleRowsForScroll,
}) => {
  if (!dataModel.isLoaded) {
    return visibleRowsWhileLoading;
  } else if (options.length > 0) {
    return Math.min(visibleRowsForScroll, options.length);
  }
  return 1;
};
