import React, { Component } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import InternalReference from 'models/api/internal-reference-model';
import { FormattedMessage } from 'react-intl';
import InternalReferenceElementEntry from 'components/feature/element-panel/annotations/_internal-reference-element-entry-component';
import { getElementFlaggedIcon } from 'constants/feature/element-panel-constants';
import classNames from 'classnames';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import CopyAnnotationButton from './_copy-annotation-modal-controller';

const INTERNAL_REFERENCE_LISTING_BLOCK = 'internal-reference-panel-listing';
const INTERNAL_REFERENCE_LISTING_ID = 'internal-reference-panel-listing-id';

class InternalReferenceListing extends Component {
  constructor() {
    super();
    this.state = {
      selectedRow: null,
      showCopyAnnotationModal: false,
    };
  }
  setSelected = (selected) => {
    this.setState({
      selectedRow: selected,
    });
  };
  getInternalReferenceElementList = () => {
    const {
      elementDetails,
      internalReference,
      selectElementFromInternalReference,
      detachElementFromInternalReference,
      checkIfFeatureFlagEnabled,
    } = this.props;
    return internalReference
      .getInternalReferencesByTimeCreated({ elementId: elementDetails.id })
      .map((element, iter) => {
        return (
          <div onClick={() => this.setSelected(iter)} key={shortid.generate()}>
            <InternalReferenceElementEntry
              elementDetails={element}
              internalReference={internalReference}
              selectElementAction={selectElementFromInternalReference}
              elementDetachAction={detachElementFromInternalReference}
              selectedElement={elementDetails}
              highlighted={this.state.selectedRow === iter}
              checkIfFeatureFlagEnabled={checkIfFeatureFlagEnabled}
            />
          </div>
        );
      });
  };

  render() {
    const { elementDetails, internalReference } = this.props;
    const internalReferenceCount = internalReference.getOtherElementsCount({
      elementId: elementDetails.id,
    });
    return (
      <div className={`${INTERNAL_REFERENCE_LISTING_BLOCK}`}>
        {internalReference.hasElements() ? (
          <div
            className={`${INTERNAL_REFERENCE_LISTING_BLOCK}__title-container`}
          >
            <h4
              className={classNames(
                `${INTERNAL_REFERENCE_LISTING_BLOCK}__title`,
                elementDetails.isInternalRefFlagged() &&
                  `${INTERNAL_REFERENCE_LISTING_BLOCK}__title--flagged`,
              )}
            >
              {elementDetails.isInternalRefFlagged() &&
                getElementFlaggedIcon({ elementDetails })}{' '}
              <FormattedMessage
                id={'internal-reference-panel.listing.title'}
                values={{
                  internalReferenceNumber: internalReference.number,
                  internalReferenceCount: internalReferenceCount,
                }}
              />
            </h4>
            <div
              className={`${INTERNAL_REFERENCE_LISTING_BLOCK}__subtitle-container`}
            >
              {elementDetails.isInternalRefFlagged() && (
                <Banner
                  type={BANNER_TYPES.WARNING}
                  id={`${INTERNAL_REFERENCE_LISTING_ID}-flagged-formula-banner`}
                  className={`${INTERNAL_REFERENCE_LISTING_BLOCK}__warning-banner`}
                  bannerCopy={{
                    id: 'internal-reference-panel.listing.banner',
                  }}
                  isInternationalized={true}
                />
              )}
            </div>
            <CopyAnnotationButton
              disabled={false}
              internalReference={this.props.internalReference}
              componentClassNameConstant={`${INTERNAL_REFERENCE_LISTING_BLOCK}__copy-all-annotation-class`}
            />
          </div>
        ) : (
          <h4 className={`${INTERNAL_REFERENCE_LISTING_BLOCK}__empty-title`}>
            <FormattedMessage
              id={'internal-reference-panel.listing.title-empty'}
            />
          </h4>
        )}
        <div
          className={`${INTERNAL_REFERENCE_LISTING_BLOCK}__element-listing-container`}
        >
          {this.getInternalReferenceElementList()}
        </div>
      </div>
    );
  }
}

InternalReferenceListing.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /**The model the represents the internal reference which is used in the internal reference panel. */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};
export default InternalReferenceListing;
