import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import ProjectEditPage from 'pages/project-edit-page';
import { finishUpdatingExistingProject } from 'store/actions/project-edit-actions';
import { selectedProjectLoaded } from 'store/actions/selected-project-actions';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';

const mapStateToProps = (
  {
    data: {
      currentUser: { data },
    },
  },
  ownprops,
) => ({
  currentUser: data,
  projectId: ownprops.match.params.id,
});

const mapDispatchToProps = {
  finishUpdatingExistingProject,
  checkIfFeatureFlagEnabled,
  push,
  selectedProjectLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditPage);
