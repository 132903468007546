import React, { Component } from 'react';
import { focusFirstMenuOption } from 'utils/menu-accessibility-utils';
import {
  SPACEBAR_KEY_CODE,
  TAB_KEY_CODE,
  ARROW_KEY_DOWN_CODE,
} from 'constants/util/key-code-constants';
import { noop } from '@babel/types';

/**
 * HOC for enabling drawer menu actions open, focusing and keyboard accessiblity
 * @param {Component} WrappedComponent Component who will have the Drawer Props applied
 * @param {string} options.itemQuerySelector css selector that indicates individual menu items, used for the accessibility utils
 * @param {boolean}  options.textInputDrawer indicates if the drawer is linked to a textInput (e.g. search bar with drawer results), used to ensure the space key is not blocked from user input
 */
export const DrawerActionHandler = (WrappedComponent, options) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
      };

      this.menuPortal = document.getElementById('menu-root');
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }

    toggleDrawer = () => {
      this.setState((state) => ({
        isOpen: !state.isOpen,
      }));
    };

    onFocus = (e) => {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
      this.setState({
        isOpen: true,
      });
    };

    setWrapperRef = (node) => {
      this.node = node;
    };

    handleOutsideClick = (e) => {
      if (
        !(
          this.menuPortal.contains(e.target) ||
          this.node.contains(e.target) ||
          !this.state.isOpen
        )
      ) {
        this.toggleDrawer();
      }
    };

    onKeyDown = (e) => {
      const { disabled } = this.props;
      const { isOpen } = this.state;
      let itemQuerySelector, textInputDrawer;
      if (options) {
        itemQuerySelector = options.itemQuerySelector;
        textInputDrawer = options.textInputDrawer;
      }

      if (this.props.onKeyDown) {
        this.props.onKeyDown(e);
      }

      const _spaceKeyPartOfUserEntry =
        textInputDrawer && e.keyCode === SPACEBAR_KEY_CODE;

      if (_spaceKeyPartOfUserEntry) {
        noop();
      } else if (!disabled && e.keyCode === SPACEBAR_KEY_CODE) {
        e.preventDefault();
        this.toggleDrawer();
      } else if (e.keyCode === TAB_KEY_CODE && isOpen) {
        this.toggleDrawer();
      } else if (e.keyCode === ARROW_KEY_DOWN_CODE && isOpen) {
        //if the selector on a menu item (menu__option) changes this must also change.
        focusFirstMenuOption(
          e,
          itemQuerySelector ? itemQuerySelector : 'ul.menu > li.menu__option',
        );
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isOpen={this.state.isOpen}
          toggleDrawer={this.toggleDrawer}
          onKeyDown={this.onKeyDown} // override
          handleOutsideClick={this.handleOutsideClick}
          setWrapperRef={this.setWrapperRef}
          wrapperRef={this.node}
          onFocus={this.onFocus} // override
        />
      );
    }
  };
};
