import ApiModel from 'models/api-model';
export default class StatementWrapUpStats extends ApiModel({
  data: {
    totalElements: 0,
    verifiedElements: 0,
    reviewedElements: 0,
    totalNotes: 0,
  },
}) {
  get totalElements() {
    if (this.data) {
      return this.data.totalElements;
    }
    return 0;
  }
  get verifiedElements() {
    if (this.data) {
      return this.data.verifiedElements;
    }
    return 0;
  }
  get reviewedElements() {
    if (this.data) {
      return this.data.reviewedElements;
    }
    return 0;
  }
  get totalNotes() {
    if (this.data) {
      return this.data.totalNotes;
    }
    return 0;
  }
  get verifiedPercentage() {
    return Math.round((this.verifiedElements * 100) / this.totalElements);
  }
  get reviewedPercentage() {
    return ((this.reviewedElements * 100) / this.totalElements).toFixed(2);
  }

  processResponse({ response }) {
    return {
      data: response.data,
    };
  }
}
