import { handleActions } from 'redux-actions';
import {
  setSectionInViewStatementContent,
  clearSectionInViewStatementContent,
} from 'store/actions/statement-content-actions';
import SectionInViewMap from 'models/data/section-in-view-map-model';

export default handleActions(
  {
    [setSectionInViewStatementContent]: (map, { payload }) => {
      return map.setSectionInView(payload);
    },
    [clearSectionInViewStatementContent]: (map, { payload }) => {
      return map.clearSectionInView(payload);
    },
  },
  new SectionInViewMap()
);
