import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Detach } from 'icons/unlink.svg';

import IconButton from 'components/common/icon-button-component';
import WorkpaperEntry from './workpaper-entry-component';
import { WORKPAPER_DETACH_TOOLTIP } from 'constants/feature/workpaper-panel-constants';
export const WORKPAPER_ENTRY_BLOCK = 'workpaper-entry';
const DETACH_ICON_SIZE = '28px';

const WorkpaperEntryWithDetach = ({ workpaper, onDetach, disabled }) => {
  return (
    <WorkpaperEntry
      workpaper={workpaper}
      rightComponent={
        <IconButton
          id={`detach-workpaper-${workpaper.referenceNumber}`}
          className={`${WORKPAPER_ENTRY_BLOCK}__detach`}
          tooltip={WORKPAPER_DETACH_TOOLTIP}
          onClick={() => {
            onDetach({ workpaper });
          }}
          Icon={Detach}
          iconSize={DETACH_ICON_SIZE}
          disabled={disabled}
        />
      }
    />
  );
};

WorkpaperEntryWithDetach.propTypes = {
  /** Workpaper object from the workpaper list endpoint */
  workpaper: PropTypes.shape({
    workpaperRefId: PropTypes.number.isRequired,
    referenceName: PropTypes.string,
    referenceNumber: PropTypes.string.isRequired,
  }),
  /** action fired to detach a work paper from this element */
  onDetach: PropTypes.func.isRequired,
  /** Indicates whether the buttons are disabled or not */
  disabled: PropTypes.bool,
};

export default WorkpaperEntryWithDetach;
