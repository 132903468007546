import { createAction } from 'redux-actions';
import { getSectionHistoryRequest } from 'api/sections-api';

export const fetchSectionReviewsHistoryLoaded = createAction(
  'FETCH_SECTION_REVIEWS_HISTORY_LOADED',
);

export const fetchSectionReviewsHistoryLoading = createAction(
  'FETCH_SECTION_REVIEWS_HISTORY_LOADING',
);

export const fetchSectionReviewsHistoryError = createAction(
  'FETCH_SECTION_REVIEWS_HISTORY_ERROR',
);

export const appendSectionReviewHistory = createAction(
  'APPEND_SECTION_REVIEW_HISTORY',
);

export const fetchSectionHistoryDetails = ({ revisionId, sectionId }) => async (
  dispatch,
) => {
  dispatch(fetchSectionReviewsHistoryLoading());
  await dispatch(
    fetchSectionHistoryDetailsWithoutLoading({ revisionId, sectionId }),
  );
};

export const fetchSectionHistoryDetailsWithoutLoading = ({
  revisionId,
  sectionId,
}) => async (dispatch) => {
  try {
    const response = await getSectionHistoryRequest({ revisionId, sectionId });
    dispatch(fetchSectionReviewsHistoryLoaded({ response }));
  } catch (error) {
    dispatch(fetchSectionReviewsHistoryError(error));
  }
};
