import { createAction } from 'redux-actions';
import revisionApi from 'api/revision-api';
import { isNullOrUndefined } from 'utils/object-utils';
import { getGeoForProjectId } from './selected-project-actions';
export const revisionLoading = createAction('REVISION_LOADING');
export const revisionError = createAction('REVISION_ERROR');
export const revisionLoaded = createAction('REVISION_LOADED');
export const clearStatementPageRevisionStoreAction = createAction(
  'CLEAR_STATEMENT_PAGE_REVISION_STORE_ACTION',
);

export const fetchRevision = (revisionId) => async (dispatch, getState) => {
  dispatch(revisionLoading());
  if (!isNullOrUndefined(revisionId)) {
    try {
      const storeData = getState().data;
      const selectedProject = storeData.selectedProject;
      const projectId = selectedProject.id;
      const selectedProjectGeoCode = dispatch(getGeoForProjectId(projectId));
      const response = await revisionApi.getRevisionRequest(
        revisionId,
        selectedProjectGeoCode,
      );

      if (
        !isNullOrUndefined(projectId) &&
        response.data.clientId === projectId
      ) {
        dispatch(
          revisionLoaded({
            response,
          }),
        );
      } else {
        dispatch(
          revisionError(
            new Error('You currently cannot access this statement.'),
          ),
        );
      }
    } catch (error) {
      dispatch(revisionError(error));
    }
  } else {
    dispatch(
      revisionError(new Error('You currently cannot access this statement.')),
    );
  }
};
