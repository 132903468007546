import { connect } from 'react-redux';
import {} from 'store/actions/element-panel-actions';
import ElementFormulaList from 'components/feature/element-panel/annotations/_element-panel-formula-list-annotation-component';
import {
  replaceSelectedElementsContentPanel,
  replaceSelectedElementWithoutRemovingOld,
} from 'store/actions/statement-content-actions';
import { deleteFormulaFromList } from 'store/actions/element-panel-actions';
import {
  initcopyFormula,
  stopCopyFormula,
  getTableMatrix,
} from 'store/actions/copy-formula-actions';
import {
  getHistoryForElementPanel,
  getElementFormulasWithoutLoading,
} from 'store/actions/element-panel-actions';

const mapStateToProps = (
  {
    data: {
      elementPanel: { elementDetails, formulaList },
      selectedProject: { id },
      selectedStatement: { statement },
      copyFormula: { copiedFormula, copyFormulaSelectedElement },
      statementContent: {
        sectionsCache: { cache },
      },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  elementDetails,
  formulaList,
  copiedFormula,
  copyFormulaSelectedElement,
  numberFormatId: statement.numberFormatId,
  selectedProjectId: id,
  isCopiedFormula:
    copiedFormula &&
    copiedFormula.data &&
    copiedFormula.data.formulas &&
    copiedFormula.data.formulas.length > 0,
  sectionsCache: cache,
});
const mapDispatchToProps = {
  replaceSelectedElements: replaceSelectedElementsContentPanel,
  replaceSelectedElementWithoutRemovingOld,
  formulaDeleteAction: deleteFormulaFromList,
  initcopyFormula: initcopyFormula,
  stopCopyFormula: stopCopyFormula,
  getTableMatrix,
  getHistoryForElementPanel,
  getElementFormulasWithoutLoading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementFormulaList);
