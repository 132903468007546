export const focusNextMenuItem = (e) => {
  e.preventDefault();
  if (e.target.nextElementSibling) {
    e.target.nextElementSibling.focus();
  }
};

export const focusPrevMenuItem = (e) => {
  e.preventDefault();
  if (e.target.previousElementSibling) {
    e.target.previousElementSibling.focus();
  }
};

export const focusFirstMenuOption = (e, selector) => {
  e.preventDefault();
  document.querySelector(selector) && document.querySelector(selector).focus();
};
