import React from 'react';
import PropTypes from 'prop-types';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import ToolTip from 'components/common/tool-tip-component';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { FormattedMessage } from 'react-intl';
import {
  SECTION_ANCHOR_BLOCK_ID,
  SECTION_ANCHOR_BLOCK,
  getInitials,
} from 'components/feature/statement-content-panel/section-anchor-component';

export const SectionReview = (props) => {
  const { sectionReview, sectionId } = props;
  return (
    <>
      {Array.isArray(sectionReview.sectionReviews) &&
        sectionReview.sectionReviews
          .filter((review) => review.sectionId === sectionId)
          .map((review, index) => (
            <ToolTip
              key={`${review.sectionId}-user-${review.appUserId}-tooltip-id-index-${index}`}
              id={`${SECTION_ANCHOR_BLOCK_ID}__section-review-tooltip-${review.sectionId}-${review.appUserId}`}
              className={`${SECTION_ANCHOR_BLOCK}__section-review-tooltip`}
              text={
                <FormattedMessage
                  id={'section-anchor.tooltip-message'}
                  values={{
                    appUserName: review.appUserName,
                    year: moment(review.updatedDate).format(
                      DATE_FORMATS.MONTH_DAY_YEAR,
                    ),
                    time: moment(review.updatedDate).format(
                      DATE_FORMATS.HOURS_MINUTES,
                    ),
                  }}
                />
              }
              isNotInternationalized
            >
              <div className={`${SECTION_ANCHOR_BLOCK}__reviewer`}>
                {getInitials(review.appUserName)}
              </div>
            </ToolTip>
          ))}
    </>
  );
};

SectionReview.propTypes = {
  sectionReview: PropTypes.instanceOf(SectionReviewList).isRequired,
};
