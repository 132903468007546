import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import DataGridConstants from 'constants/common/data-grid-constants';
import DataGridFilter from 'components/common/data-grid/_data-grid-filter-component';

import {
  DATA_GRID_BLOCK,
  DataGridColumn,
  DataGridSort,
} from './data-grid-component';

const { SPECIAL_COLUMNS_LIST, SPECIAL_COLUMNS, DATA_GRID_ROW_HEIGHT } =
  DataGridConstants;

const COL_HEADER_ICON_SIZE = '20';

export const DataGridColumnsHeader = ({
  columns,
  sort,
  onSortClick,
  tableId,
  onHeaderCheckboxSelect,
  canSelectMoreThenOneRow,
  filterInput,
  showFilterPopup,
  isFilterActive,
  onFilterInputChange,
  onFilterInputClear,
  onFilterInputApply,
  onFilterClick,
  onCloseFilterPopup,
}) => {
  const [headerChecked, _setHeaderChecked] = useState(false);

  const _displayHeader = (colModel) => {
    // header checkbox controls whether all rows are selected, changing the rows values occurs in data-grid-component controlled by onHeaderCheckboxSelect
    if (colModel.key === SPECIAL_COLUMNS.checkbox && canSelectMoreThenOneRow) {
      return (
        <input
          type="checkbox"
          checked={headerChecked}
          className={'checkbox__input'}
          id={`${tableId}-checkbox-column-header`}
          onChange={(e) => {
            _setHeaderChecked(!headerChecked);
            onHeaderCheckboxSelect(!headerChecked);
          }}
        />
      );
    }
    if (colModel.header) {
      let content = (
        <>
          {sort ? sort.getIcon(colModel) : null}
          <FormattedMessage id={colModel.header} />
        </>
      );
      if (colModel.sortable) {
        return (
          <>
            <button
              className={classNames(`${DATA_GRID_BLOCK}__header-col-button`)}
              onClick={() => onSortClick(colModel)}
              disabled={SPECIAL_COLUMNS_LIST.includes(colModel.key)}
            >
              {content}
            </button>
            {colModel.filterable && (
              <DataGridFilter
                className={colModel.className}
                columnKey={colModel.key}
                filterInput={filterInput}
                showFilterPopup={showFilterPopup}
                isFilterActive={isFilterActive}
                onFilterInputChange={onFilterInputChange}
                onFilterInputClear={onFilterInputClear}
                onFilterInputApply={onFilterInputApply}
                onFilterClick={onFilterClick}
                onCloseFilterPopup={onCloseFilterPopup}
              />
            )}
          </>
        );
      }
      if (colModel.Icon) {
        return (
          <>
            <colModel.Icon
              className={`${DATA_GRID_BLOCK}__omnia-icon`}
              width={COL_HEADER_ICON_SIZE}
              height={COL_HEADER_ICON_SIZE}
            />
            {content}
          </>
        );
      }
      return content;
    }
    return null;
  };
  return (
    <div
      className={classNames(`${DATA_GRID_BLOCK}__cols-header-row`)}
      style={{
        height: `${DATA_GRID_ROW_HEIGHT}rem`,
      }}
    >
      {columns.map((colModel, headIndex) => (
        <div
          className={classNames(`${DATA_GRID_BLOCK}__header-col`)}
          key={`${tableId}-heading-${headIndex}`}
          style={{ width: colModel.width }}
        >
          {_displayHeader(colModel)}
        </div>
      ))}
    </div>
  );
};

DataGridColumnsHeader.propTypes = {
  /** An array of column objects */
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DataGridColumn)).isRequired,
  /** Sort object representing current column to sort by */
  sort: PropTypes.instanceOf(DataGridSort),
  /** Function to fire when a header is clicked which should alter the sort object */
  onSortClick: PropTypes.func,
  /** Unique key string for each table */
  tableId: PropTypes.string.isRequired,
  /** Bool representing if more then one check box can be selected at once */
  canSelectMoreThenOneRow: PropTypes.bool,
  /**It containes the value of input field */
  filterInput: PropTypes.object,
  /**It will show/hide the filter popup */
  showFilterPopup: PropTypes.bool,
  /**It will check whether filter is active or not to show orange icon */
  isFilterActive: PropTypes.bool,
  /**It is the change handler for filter input */
  onFilterInputChange: PropTypes.func,
  /**It will clear/reset the filter input */
  onFilterInputClear: PropTypes.func,
  /**It will execute when use click on apply button */
  onFilterInputApply: PropTypes.func,
  /**It will execute when user will click on filter icon */
  onFilterClick: PropTypes.func,
  /**It will close the filter popup */
  onCloseFilterPopup: PropTypes.func,
};
