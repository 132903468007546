import React, { Component } from 'react';
import { connect } from 'react-redux';
import ElementDetails from 'models/api/element-details-api-model';
import PropTypes from 'prop-types';
import ConditionalRender from 'components/util/conditional-render-component';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import AttachWorkpaper from 'components/feature/element-panel/workpaper-panel/_workpaper-panel-attach-component';
import AlreadyLinkedWorkpapers from 'components/feature/element-panel/workpaper-panel/_workpaper-panel-already-linked-component';
import CreateWorkpaperModal from 'components/feature/element-panel/workpaper-panel/create-work-paper-modal';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import { createOrUpdateElementWorkpaperRequest } from 'api/workpaper-api';
import { fetchWorkpaperListForRevisionWithoutLoading } from 'store/actions/workpaper-toolkit-list-actions';
import {
  deallocateItems,
  selectElementContentPanel,
} from 'store/actions/statement-content-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';

const WORKPAPER_PANEL_BLOCK = 'workpaper-panel';
const WORKPAPER_PANEL_ID_BLOCK = 'workpaper-panel-id';

class WorkpaperPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateWorkpaperModal: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      closeWorkpaperPanel,
      elementDetails,
      showWorkpaperPanel,
    } = this.props;

    if (!showWorkpaperPanel && !nextProps.showWorkpaperPanel) {
      // save some performance by only re-rendering during instances where the the content should be displayed
      return false;
    }
    const _clickedNewElementInContentPanel =
      elementDetails.id !== null &&
      nextProps.elementDetails.id !== elementDetails.id;
    if (_clickedNewElementInContentPanel) {
      // close the panel when a new element has been clicked before the component re-renders
      closeWorkpaperPanel();
    }

    return true;
  }

  _toggleCreateWorkPaperModal = () => {
    this.setState((state) => ({
      showCreateWorkpaperModal: !state.showCreateWorkpaperModal,
    }));
  };

  _handleCreateNewWorkpaper = async ({ workpaperModel }) => {
    const {
      fetchWorkpaperList,
      revisionId,
      socketHasBeenDisconnected,
      elementDetails,
    } = this.props;
    await createOrUpdateElementWorkpaperRequest({ workpaperModel });
    if (socketHasBeenDisconnected) {
      const { onCreateWorkaper } = this.props;
      await onCreateWorkaper({
        elementId: elementDetails.id,
        sectionId: elementDetails.sectionId,
      });
      fetchWorkpaperList({ revisionId });
    }

    this._toggleCreateWorkPaperModal();
  };

  render() {
    const {
      showWorkpaperPanel,
      closeWorkpaperPanel,
      elementDetails,
      workpaperList,
      detachElementWorkpaper,
      updateWorkpaper,
      deallocateItems,
      selectElementContentPanel,
    } = this.props;
    const { showCreateWorkpaperModal } = this.state;
    return (
      <>
        <Flyout
          className={`${WORKPAPER_PANEL_BLOCK}__flyout`}
          show={showWorkpaperPanel}
          isAnimated
        >
          {showWorkpaperPanel && (
            <ConditionalRender dependencies={[workpaperList]}>
              <FlyoutHeader enableBack onBack={closeWorkpaperPanel}>
                <h3 className={`${WORKPAPER_PANEL_BLOCK}__title`}>
                  <FormattedMessage id={'workpaper-panel.header.title'} />
                </h3>
                <div className={`${WORKPAPER_PANEL_BLOCK}__selected-value`}>
                  <FormattedMessage id="common.selected-value" />
                </div>
                <button
                  id={`${WORKPAPER_PANEL_ID_BLOCK}-element-value`}
                  className={`${WORKPAPER_PANEL_BLOCK}__element-value`}
                  onClick={() => {
                    scrollElementIntoView({
                      elementId: elementDetails.id,
                      sectionId: elementDetails.sectionId,
                    });
                    deallocateItems();
                    selectElementContentPanel({
                      elementIds: [elementDetails.id],
                      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                    });
                  }}
                >
                  {elementDetails.display}
                </button>
                <AmountAttributeDetails
                  className={`${WORKPAPER_PANEL_BLOCK}__amount-attributes`}
                  elementDetails={elementDetails}
                />
              </FlyoutHeader>
              <FlyoutPanelContent
                className={`${WORKPAPER_PANEL_BLOCK}__flyout-content`}
              >
                {workpaperList.hasWorkpapers() && (
                  <AlreadyLinkedWorkpapers
                    workpaperList={workpaperList}
                    onDetachWorkpaper={detachElementWorkpaper}
                  />
                )}
                <AttachWorkpaper
                  elementDetails={elementDetails}
                  onCreate={this._toggleCreateWorkPaperModal}
                  onSelect={updateWorkpaper}
                  alreadyAddedWorkpaperIds={workpaperList.workpapers.map(
                    (wpr) => wpr.workpaperRefId,
                  )}
                />
              </FlyoutPanelContent>
            </ConditionalRender>
          )}
        </Flyout>
        {showCreateWorkpaperModal && (
          <CreateWorkpaperModal
            onClose={this._toggleCreateWorkPaperModal}
            onCreate={this._handleCreateNewWorkpaper}
            elementDetails={elementDetails}
          />
        )}
      </>
    );
  }
}

WorkpaperPanel.propTypes = {
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /**A boolean indicating if we should show the workpaper panel or not. */
  showWorkpaperPanel: PropTypes.bool.isRequired,
  /**The action that is fired when we need to hide the workpaper panel. */
  closeWorkpaperPanel: PropTypes.func.isRequired,
  /** Action fired after successful workpaper creation */
  onCreateWorkaper: PropTypes.func.isRequired,
  /** action fired to detach a work paper from this element */
  detachElementWorkpaper: PropTypes.func.isRequired,
  /** Action for attaching workpaper from search results */
  updateWorkpaper: PropTypes.func.isRequired,
  /** revision Id of the statement */
  revisionId: PropTypes.number.isRequired,
  /* Action to be fired when an element from the list selected */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  data: { revision },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  revisionId: revision.id,
  socketHasBeenDisconnected,
});
const mapDispatchToProps = {
  fetchWorkpaperList: fetchWorkpaperListForRevisionWithoutLoading,
  deallocateItems,
  selectElementContentPanel,
};

export { WorkpaperPanel };
export default connect(mapStateToProps, mapDispatchToProps)(WorkpaperPanel);
