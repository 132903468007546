import { Component } from 'react';
import {
  requestGetStatementWorkflows,
  getStatementWorkflowsWithoutLoading,
} from 'store/actions/statement-workflows-actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  initProjectSocketClient,
  setProjectSocketClient,
  closeProjectSocketClient,
} from 'store/actions/project-socket-actions';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';

const WORKFLOWS_POLLING_INTERVAL = 15000; //15 sec

class StatementWorkflowController extends Component {
  constructor(props) {
    super(props);
    this.state = { intervalId: 0 };
  }
  componentDidMount() {
    const {
      socketHasBeenDisconnected,
      initProjectSocketClient,
      setProjectSocketClient,
      project,
    } = this.props;
    if (socketHasBeenDisconnected) {
      initProjectSocketClient(project.id);
      setProjectSocketClient();
    }
    this._setIntervalForWorkflowStatus();
  }

  _setIntervalForWorkflowStatus = () => {
    const intervalId = setInterval(() => {
      const {
        socketHasBeenDisconnected,
        getStatementWorkflowsWithoutLoading,
        workflowsMap,
      } = this.props;
      if (socketHasBeenDisconnected && workflowsMap.isInitialized()) {
        getStatementWorkflowsWithoutLoading();
      }
    }, WORKFLOWS_POLLING_INTERVAL);

    this.setState({ intervalId });
  };
  _clearIntervalForWorkflowStatus = () => {
    clearInterval(this.state.intervalId);
    this.setState({ intervalId: 0 });
  };
  componentDidUpdate(prevProps, prevState) {
    const { project, socketHasBeenDisconnected } = this.props;
    this._handleProjectSocket(prevProps.project, project);
    if (prevProps.socketHasBeenDisconnected !== socketHasBeenDisconnected) {
      if (socketHasBeenDisconnected)
        !this.state.intervalId && this._setIntervalForWorkflowStatus();
      else this._clearIntervalForWorkflowStatus();
    }
  }

  _handleProjectSocket = async (previousProject, currentProject) => {
    const {
      initProjectSocketClient,
      setProjectSocketClient,
      closeProjectSocketClient,
      requestGetStatementWorkflows,
    } = this.props;
    if (previousProject.id !== currentProject.id) {
      await requestGetStatementWorkflows();
      previousProject.id &&
        (await closeProjectSocketClient(previousProject.id));
      if (currentProject.id) {
        await initProjectSocketClient(currentProject.id);
        await setProjectSocketClient();
      }
    }
  };

  componentWillUnmount() {
    const { project, closeProjectSocketClient } = this.props;
    closeProjectSocketClient(project.id);
    this._clearIntervalForWorkflowStatus();
  }
  render() {
    return null;
  }
}

const mapStateToProps = ({
  data: {
    selectedProject: { project },
    workflowsMap,
  },
  sockets: {
    projectSocket: { socketHasBeenDisconnected },
  },
}) => ({
  project,
  workflowsMap,
  socketHasBeenDisconnected,
});

const mapDispatchToProps = {
  requestGetStatementWorkflows,
  getStatementWorkflowsWithoutLoading,
  initProjectSocketClient,
  setProjectSocketClient,
  closeProjectSocketClient,
};

StatementWorkflowController.propTypes = {
  /** Action to request statement workflows */
  requestGetStatementWorkflows: PropTypes.func.isRequired,
  /** Action to request statement workflows without loading */
  getStatementWorkflowsWithoutLoading: PropTypes.func.isRequired,
  /** Action to disconnect project level signalr client */
  closeProjectSocketClient: PropTypes.func.isRequired,
  /** Action to initialize project level signalr client */
  initProjectSocketClient: PropTypes.func.isRequired,
  /** Action to set project level signalr client */
  setProjectSocketClient: PropTypes.func.isRequired,
  /** workflowMap object contains statement details of statement uploading with status inProgress/failed */
  workflowsMap: PropTypes.instanceOf(WorkflowsMap),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementWorkflowController);
