import React, { useEffect, useState } from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { getSummaryOfKeyChanges } from '../../../api/admin-module-api';
import { EMPTY_STRING } from '../../../constants/common/feature-common-utils';
import { getText } from '../../../constants/util/admin-module-utility';

export const RECENT_UPDATES_ID_BLOCK = 'recent-update-id';
export const RECENT_UPDATES_BLOCK = 'recent-updates';

const RecentUpdatesModal = ({ onClose, onSave }) => {
  const [innerTextHtml, setInnerTextHtml] = useState(EMPTY_STRING);
  const [showRecentUpdates, setShowRecentUpdates] = useState(false);

  const fetchSummaryOfKeyChanges = async () => getSummaryOfKeyChanges();

  useEffect(() => {
    fetchSummaryOfKeyChanges().then((response) => {
      setInnerTextHtml(response.text);
      setShowRecentUpdates(response.status);
    });
  }, []);
  return (
    showRecentUpdates &&
    getText(innerTextHtml) && (
      <Modal
        id={RECENT_UPDATES_ID_BLOCK}
        onClose={onClose}
        title={'recent-updates-information-modal.title'}
        className={`${RECENT_UPDATES_BLOCK}__modal`}
        primaryModalButton={
          new ModalButton({
            text: 'recent-updates-information-modal.button',
            onClick: () => onSave(),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.close',
            onClick: () => onClose(),
          })
        }
      >
        <div className={`${RECENT_UPDATES_BLOCK}`}>
          <p
            className={`${RECENT_UPDATES_BLOCK}__content`}
            dangerouslySetInnerHTML={{
              __html: innerTextHtml,
            }}
          />
        </div>
      </Modal>
    )
  );
};

RecentUpdatesModal.propTypes = {
  /** Modal panel onClose function  */
  onClose: PropTypes.func.isRequired,
  /** Modal panel onSave function  */
  onSave: PropTypes.func.isRequired,
};

export default RecentUpdatesModal;
