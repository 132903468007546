import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import classnames from 'classnames';
import TextInput from 'components/common/text-input-component';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as arrow } from 'icons/arrow-simple-thick-down.svg';
import shortid from 'shortid';
import { getResultDisplay } from 'constants/feature/formula-constants';
import { round, countDecimals, countDigits } from 'utils/formula-utils';
export const FORMULA_PANEL_ROUND_NUMBER_MODAL_ID = 'round-number-modal-id';
export const FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK = 'round-number-modal';
const FormulaPanelRoundNumberModal = ({
  onSave,
  onCancel,
  currentNumber,
  inputValue,
  setInputValue,
  computedResult,
  roundingScale,
  removeRounding,
  numberFormatId,
}) => {
  const [newValue, setNewValue] = useState(currentNumber);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const ROUND_NUMBER_REGEX = /^-?[0-9\b]*$/;

  const decimalsResult = countDecimals(computedResult);

  const decimals = decimalsResult !== 0 ? decimalsResult : 0;

  const digits = -(countDigits(computedResult) - 1);

  useEffect(() => {
    if (inputValue !== '' && inputValue !== '-') {
      setNewValue(round(computedResult, inputValue));
    }
    if (
      inputValue === '' ||
      inputValue === '-' ||
      inputValue < digits ||
      inputValue > decimals ||
      inputValue === roundingScale ||
      (currentNumber === newValue && currentNumber === computedResult)
    ) {
      if (!saveButtonDisabled) setSaveButtonDisabled(true);
    } else {
      if (saveButtonDisabled) setSaveButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (
      inputValue === '' ||
      inputValue === '-' ||
      inputValue < digits ||
      inputValue > decimals ||
      inputValue === roundingScale ||
      (currentNumber === newValue && currentNumber === computedResult)
    ) {
      if (!saveButtonDisabled) setSaveButtonDisabled(true);
    } else {
      if (saveButtonDisabled) setSaveButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue]);
  const save = () => {
    onSave(newValue);
  };
  const onBlur = () => {
    if (
      (inputValue !== '-' || inputValue !== '') &&
      (inputValue < digits || inputValue > decimals)
    ) {
      setInputValue('');
      setNewValue('');
    }
  };

  return (
    <Modal
      id={FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}
      title={'formula-edit-panel.round-number-modal.title'}
      onClose={onCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.apply',
          onClick: save,
          disabled: saveButtonDisabled,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onCancel,
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'formula-edit-panel.round-number-modal.remove-rounding',
          onClick: removeRounding,
        })
      }
    >
      <div className={FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}>
        <div
          className={classnames(
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__section`,
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__current-number`,
          )}
        >
          <div
            className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__subtitle`}
          >
            <FormattedMessage
              id={'formula-edit-panel.round-number-modal.current-value'}
            />
          </div>
          <div
            className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__current-number-value`}
          >
            = {getResultDisplay({ result: computedResult, numberFormatId })}
          </div>
        </div>
        <div
          className={classnames(
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__section`,
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__change-digit`,
          )}
        >
          <div
            className={classnames(
              `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__subtitle`,
              `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__subtitle-with-text`,
            )}
          >
            <FormattedMessage
              id={'formula-edit-panel.round-number-modal.number-digits'}
            />
            <div className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__text`}>
              <FormattedMessage
                id="formula-edit-panel.round-number-modal.input-guidance-text"
                values={{
                  ol: (...chunks) => (
                    <ol
                      key={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-olTag-${shortid.generate()}`}
                      className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__guidance-text-list`}
                    >
                      {chunks}
                    </ol>
                  ),
                  li: (...chunks) => (
                    <li
                      key={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-liTag-${shortid.generate()}`}
                    >
                      {chunks}
                    </li>
                  ),
                  b: (...chunks) => (
                    <b
                      key={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-bTag-${shortid.generate()}`}
                    >
                      {chunks}
                    </b>
                  ),
                  linebreak: (
                    <br
                      key={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-brTag-${shortid.generate()}`}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div
            className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__change-digit-input`}
          >
            <div
              className={classnames(
                `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__buttons`,
              )}
            >
              <IconButton
                id={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-arrow-up-button`}
                className={classnames(
                  `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__arrow--rotate`,
                  `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__arrow`,
                )}
                Icon={arrow}
                onClick={() => {
                  if (inputValue !== '-') {
                    if (inputValue === '') {
                      setInputValue(0);
                    } else {
                      if (inputValue + 1 <= decimals) {
                        setInputValue((prevState) => prevState + 1);
                      }
                    }
                  }
                }}
              ></IconButton>
              <IconButton
                id={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-arrow-down-button`}
                className={classnames(
                  `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__arrow`,
                )}
                Icon={arrow}
                onClick={() => {
                  if (inputValue !== '-') {
                    if (inputValue === '') {
                      setInputValue(0);
                    } else {
                      if (inputValue - 1 >= digits) {
                        setInputValue((prevState) => prevState - 1);
                      }
                    }
                  }
                }}
              ></IconButton>
            </div>
            <TextInput
              id={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_ID}-input`}
              className={classnames(
                `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__input`,
                (inputValue === '' || inputValue === '-') &&
                  `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__input--error`,
              )}
              value={inputValue.toString()}
              onChange={(val) => {
                if (typeof val === 'string') {
                  if (val !== '' && val !== '-') {
                    setInputValue(parseInt(val));
                  } else {
                    setInputValue(val);
                  }
                } else {
                  setInputValue(val);
                }
              }}
              type={'text'}
              width={'75px'}
              pattern={ROUND_NUMBER_REGEX}
              onBlur={onBlur}
            />
          </div>
        </div>
        <div
          className={classnames(
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__section`,
            `${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__new-rounded`,
          )}
        >
          <div
            className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__subtitle`}
          >
            <FormattedMessage
              id={'formula-edit-panel.round-number-modal.new-rounded-value'}
            />
          </div>
          <div
            className={`${FORMULA_PANEL_ROUND_NUMBER_MODAL_BLOCK}__new-rounded-value`}
          >
            {inputValue !== '' && inputValue !== '-'
              ? `= ${getResultDisplay({ result: newValue, numberFormatId })}`
              : ''}
          </div>
        </div>
      </div>
    </Modal>
  );
};
FormulaPanelRoundNumberModal.propTypes = {
  /** Action fired when user clicks save button */
  onSave: PropTypes.func.isRequired,
  /** Action fired when user clicks cancel  button */
  onCancel: PropTypes.func.isRequired,
  /* current number to be rounded */
  currentNumber: PropTypes.number.isRequired,
  /* current computed number of the formula */
  computedResult: PropTypes.number.isRequired,
  /* input value for the amount of number to round the number */
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /* function to set the value of input value */
  setInputValue: PropTypes.func.isRequired,
  /* current scaling value on formula */
  roundingScale: PropTypes.number,
  /* function to remove the rounding from the formula and reset modal values*/
  removeRounding: PropTypes.func.isRequired,
};
export default FormulaPanelRoundNumberModal;
