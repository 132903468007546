import React, { useState } from 'react';
import Notification from 'components/common/notification-component';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ReactComponent as ErrorIcon } from 'icons/error.svg';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import PropTypes from 'prop-types';
import ApiError from 'models/utils/api-error-handler-model';
import { ReactComponent as RefreshIcon } from 'icons/refresh-blue.svg';

export const API_ERROR_NOTIFICATION = 'api-error-notification';
export const API_ERROR_NOTIFICATION_ID = 'api-error-notification-id';

export const ApiErrorNotification = ({ apiError }) => {
  const [messageIsCopied, setMessageIsCopied] = useState(false);

  const copyToClipBoard = async (copyText) => {
    await navigator.clipboard.writeText(copyText);
    setMessageIsCopied(true);
  };

  return (
    <Notification
      icon={<ErrorIcon />}
      title={{ id: 'api-error.notification.title' }}
    >
      <p className={`${API_ERROR_NOTIFICATION}__main-message`}>
        <FormattedMessage id={'api-error.notification.main-message'} />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__message-paragraph`}>
        <FormattedMessage id={'api-error.notification.message-1'} />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__message-paragraph`}>
        <FormattedMessage id={'api-error.notification.message-2'} />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__message-paragraph`}>
        <FormattedMessage id={'api-error.notification.message-3'} />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__message-paragraph`}>
        <FormattedMessage id={'api-error.notification.message-4'} />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__refresh-message`}>
        <FormattedMessage
          id={'api-error.notification.refresh-message'}
          values={{
            refreshIcon: (
              <button
                className={`${API_ERROR_NOTIFICATION}__refresh-button`}
                onClick={() => window.location.reload()}
              >
                <RefreshIcon />
              </button>
            ),
          }}
        />
      </p>
      <p className={`${API_ERROR_NOTIFICATION}__message-label`}>
        <FormattedMessage id={'api-error.notification.message-label'} />
      </p>
      <div className={`${API_ERROR_NOTIFICATION}__message-container`}>
        <p className={`${API_ERROR_NOTIFICATION}__error-message-text`}>
          {apiError.getReadOnlyErrorMessageToDisplay()}
        </p>
        <button
          className={classnames(
            `${API_ERROR_NOTIFICATION}__copy-message-button`,
            messageIsCopied
              ? `${API_ERROR_NOTIFICATION}__copy-message-button--copied`
              : `${API_ERROR_NOTIFICATION}__copy-message-button--copy-message`,
          )}
          // the copied message needs to be encrypted
          onClick={() =>
            copyToClipBoard(window.btoa(apiError.getDetailsToCopyToClipboard()))
          }
          disabled={messageIsCopied}
        >
          {messageIsCopied ? (
            <FormattedMessage id={'api-error.notification.button.copied'} />
          ) : (
            <FormattedMessage
              id={'api-error.notification.button.copy-details'}
            />
          )}
        </button>
      </div>
      <button
        className={`${API_ERROR_NOTIFICATION}__submit-ticket-button`}
        onClick={() => {
          // open support website
          window.open(ROUTE_CONSTANTS.SERVICE_NOW);
        }}
      >
        <FormattedMessage id={'api-error.notification.button.submit-ticket'} />
      </button>
    </Notification>
  );
};

ApiErrorNotification.propTypes = {
  /** contains details of api errors */
  apiError: PropTypes.instanceOf(ApiError).isRequired,
};

const mapStateToProps = ({ data: { apiError } }) => ({
  apiError,
});

export default connect(mapStateToProps)(ApiErrorNotification);
