import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

export const TERMS_OF_USE_ANCHOR = (
  <a
    href={ROUTE_CONSTANTS.TERMS_OF_USE}
    target={'_blank'}
    rel="noopener noreferrer"
  >
    <FormattedMessage id={'footer.terms-of-use'} />
  </a>
);

export const PRIVACY_ANCHOR = (
  <a href={ROUTE_CONSTANTS.PRIVACY} target={'_blank'} rel="noopener noreferrer">
    <FormattedMessage id={'footer.privacy'} />
  </a>
);

export const NOTICES_ANCHOR = (
  <a href={ROUTE_CONSTANTS.NOTICES}>
    <FormattedMessage id={'footer.notices'} />
  </a>
);

export const ABOUT_ANCHOR = (
  <a href={ROUTE_CONSTANTS.ABOUT} target={'_blank'} rel="noopener noreferrer">
    www.deloitte.com/about
  </a>
);

export const COOKIES_ANCHOR = (
  <a
    href={ROUTE_CONSTANTS.COOKIE_NOTICE}
    target={'_blank'}
    rel="noopener noreferrer"
  >
    <FormattedMessage id={'footer.cookies'} />
  </a>
);

export const COOKIES_SETTINGS = (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    onClick={() => document.getElementById('ot-sdk-btn').click()}
    target="_blank"
  >
    <FormattedMessage id="footer.cookies-settings" />
  </a>
);
