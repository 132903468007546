import { handleActions } from 'redux-actions';
import {
  notesListLoading,
  notesListLoaded,
  notesListError,
  updateNoteFilterAction,
  clearAllNoteFiltersAction,
  removeNoteFilterAction,
  applyQuickFilterAction,
  applyNoteFilterAction,
  deleteNoteWithWebsocketPayload,
  notesListLoadedWithoutReponse,
  addNoteWithWebsocketPayload,
  updateNoteWithWebsocketPayload,
  setPreviousNoteBody,
  setAssignedToMe,
  addAdditionalDetailsToNoteList,
} from 'store/actions/notes-panel-actions';
import NotesList from 'models/api/notes-list-api-model';

export default handleActions(
  {
    [notesListLoading]: (notesList, { payload }) => {
      return notesList.setLoading(payload);
    },
    [notesListLoaded]: (notesList, { payload }) => {
      return notesList.setLoaded(payload);
    },
    [notesListLoadedWithoutReponse]: (notesList) => {
      return notesList.setLoadedWithoutResponse();
    },
    [notesListError]: (notesList, { payload }) => {
      return notesList.setError(payload);
    },
    [updateNoteFilterAction]: (notesList, { payload }) => {
      return notesList.updateNoteFilters(payload);
    },
    [clearAllNoteFiltersAction]: (notesList) => {
      return notesList.clearAllFilters();
    },
    [removeNoteFilterAction]: (notesList, { payload }) => {
      return notesList.removeNoteFilter(payload);
    },
    [applyQuickFilterAction]: (notesList, { payload }) => {
      return notesList.applyQuickFilter(payload);
    },
    [applyNoteFilterAction]: (notesList, { payload }) => {
      return notesList.applyNoteFilter(payload);
    },
    [deleteNoteWithWebsocketPayload]: (notesList, { payload }) => {
      return notesList.deleteNoteWithWebsocket(payload);
    },
    [addNoteWithWebsocketPayload]: (notesList, { payload }) => {
      return notesList.addNoteWithWebsocketPayload(payload);
    },
    [updateNoteWithWebsocketPayload]: (notesList, { payload }) => {
      return notesList.updateNoteWithWebsocket(payload);
    },
    [setPreviousNoteBody]: (notesList, { payload }) => {
      return notesList.updatePreviousNote(payload);
    },
    [setAssignedToMe]: (notesList, { payload }) => {
      return notesList.setAssignedToMe(payload);
    },
    [addAdditionalDetailsToNoteList]: (notesList, { payload }) => {
      return notesList.addAdditionalDetailsToNotesList(payload);
    },
  },
  new NotesList(),
);
