import { handleActions } from 'redux-actions';
import { DEFAULT_SELECTED_PROJECT_ID } from 'constants/feature/selected-project-constants';
import { setSelectedProjectId } from 'store/actions/selected-project-actions';

export default handleActions(
  {
    [setSelectedProjectId]: (id, { payload: { projectId } }) => {
      return projectId;
    },
  },
  DEFAULT_SELECTED_PROJECT_ID
);
