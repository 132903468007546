function getHeaderPlugin() {
  const header = document.getElementsByTagName('header-plugin');
  if (header && header[0]) {
    return header[0];
  }
  return null;
}

export function hideHeaderPlugin() {
  const header = getHeaderPlugin();
  if (header) {
    header.style.display = 'none';
  }
}

export function showHeaderPlugin() {
  const header = getHeaderPlugin();
  if (header) {
    header.style.display = 'block';
  }
}
