import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Permissions from 'permissions/permissions';
import { ReactComponent as Pencil } from 'icons/edit-page.svg';
import Card from 'components/common/card-component';
import { SearchTableDropdownApi } from 'components/common/search-component';
import Checkbox from 'components/common/checkbox-component';
import DataGrid, {
  DataGridData,
  DataGridDataApi,
} from 'components/common/data-grid/data-grid-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import {
  USER_SEARCH_COLUMNS,
  getAddedUserColumns,
} from 'constants/feature/project-edit-constants';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import { UserList } from 'models/api/users-list-model';
import ProjectForm from 'models/form/project-form-model';
import { LearnMoreAboutRolesModal } from 'components/feature/project-creation/project-info/learn-more-about-roles-modal';
import { getUserListRequest } from 'api/project-creation-edit-api';
import { ViewDisclaimerModal } from 'components/feature/project-creation/manage-users/support-team-access-view-disclaimer-modal';
import { ViewTechSupportAccessDisclaimerModal } from 'components/feature/project-creation/manage-users/tech-support-access-view-disclaimer-modal';
import { AcknowledgeDisclaimerModal } from 'components/feature/project-creation/manage-users/support-team-access-acknowledge-disclaimer-modal';
import { TechSupportAcknowledgeDisclaimerModal } from 'components/feature/project-creation/manage-users/tech-support-access-acknowledge-disclaimer-modal';

export const EDIT_MANAGE_USERS_BLOCK = 'edit-manage-users';
export const EDIT_MANAGE_USERS_ID_BLOCK = 'edit-manage-users-id';

class EditManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      showLearnMoreModal: false,
      showViewDisclaimerModal: false,
      showTechSupportAccessViewDisclaimerModal: false,
      showAcknowledgeDisclaimerModal: false,
      showTechSupportAccessAcknowledgeDisclaimerModal: false,
      userListModel: new UserList(),
    };
    this.debouncedSearch = debounceSearch(this._fetchSearchUserList);
  }

  _searchUser = (val) => {
    this.setState({ search: val }, () => {
      if (isSearchCharLengthValid(this.state.search)) {
        this.debouncedSearch({ search: this.state.search });
      }
    });
  };

  _fetchSearchUserList = async ({ search }) => {
    const { projectInProgress } = this.props;
    this.setState((state) => ({
      userListModel: state.userListModel.setLoading(),
    }));
    try {
      const response = await getUserListRequest({
        clientId: projectInProgress.id,
        searchString: search,
        numberOfRows: 50,
        pageNumber: 1,
      });
      this.setState((state) => ({
        userListModel: state.userListModel.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        userListModel: state.userListModel.setError(error),
      }));
    }
  };

  // always display at least one extra row so you don't just have a floating table header
  _getNumAddedUserRows = () =>
    this.props.projectInProgress.userEntities.length || 1;

  _toggleLearnMoreModal = () => {
    this.setState((state) => ({
      showLearnMoreModal: !state.showLearnMoreModal,
    }));
  };

  _toggleViewDisclaimerModal = () => {
    this.setState((state) => ({
      showViewDisclaimerModal: !state.showViewDisclaimerModal,
    }));
  };

  _toggleTechSupportAccessViewDisclaimerModal = () => {
    this.setState((state) => ({
      showTechSupportAccessViewDisclaimerModal:
        !state.showTechSupportAccessViewDisclaimerModal,
    }));
  };

  _toggleAcknowledgementDisclaimerModal = () => {
    this.setState((state) => ({
      showAcknowledgeDisclaimerModal: !state.showAcknowledgeDisclaimerModal,
    }));
  };

  _toggleTechSupportAccessAcknowledgementDisclaimerModal = () => {
    this.setState((state) => ({
      showTechSupportAccessAcknowledgeDisclaimerModal:
        !state.showTechSupportAccessAcknowledgeDisclaimerModal,
    }));
  };

  _onSupportTeamAccessChange = () => {
    const { projectInProgress, toggleHasAdminGroup } = this.props;
    if (projectInProgress.hasAdminGroup) {
      toggleHasAdminGroup();
    } else {
      this._toggleAcknowledgementDisclaimerModal();
    }
  };

  _onTechSupportAccessChange = () => {
    const { projectInProgress, toggleTechSupportAccess } = this.props;
    this._toggleTechSupportAccessAcknowledgementDisclaimerModal();
    if (projectInProgress.techSupportAccess) {
      toggleTechSupportAccess();
    }
  };

  _isSupportTeamAccessCheckboxDisabled = () => {
    const { projectInProgress } = this.props;
    // We should only block the modification of the admin group of a project after it has been modified.
    // Once it is modified, allow the user to back out of their choice.
    if (!projectInProgress.hasAdminGroupBeenModified()) {
      if (projectInProgress.hasAdminGroup) {
        return !Permissions.Project.canDisableSupportTeamAccess(
          projectInProgress.id,
        );
      } else {
        return !Permissions.Project.canEnableSupportTeamAccess(
          projectInProgress.id,
        );
      }
    } else {
      // If it has been modified, allow the user to undo their selection for a better UX.
      return false;
    }
  };

  _isTechSupportAccessCheckboxDisabled = () => {
    const { projectInProgress } = this.props;
    // We should only block the modification of the admin group of a project after it has been modified.
    // Once it is modified, allow the user to back out of their choice.
    if (!projectInProgress.hasTechSupportAccessHasBeenModified()) {
      if (projectInProgress.techSupportAccess) {
        return !Permissions.Project.canDisableTechSupportAccess(
          projectInProgress.id,
        );
      }
      return !Permissions.Project.canEnableTechSupportAccess(
        projectInProgress.id,
      );
    }
    // If it has been modified, allow the user to undo their selection for a better UX.
    return false;
  };

  render() {
    const {
      projectInProgress,
      addUserToProject,
      removeUserFromProject,
      assignUserProjectRole,
      toggleHasAdminGroup,
      toggleTechSupportAccess,
      disableTechSupportAccess,
      setSupportId,
      setEnabledTechSupportStatementList,
      removeSelectedTechSupportStatement,
      resetEnabledTechSupportStatementListData,
      isTechSupportStatementAccessEnabled,
    } = this.props;
    const {
      search,
      showLearnMoreModal,
      userListModel,
      showViewDisclaimerModal,
      showAcknowledgeDisclaimerModal,
      showTechSupportAccessViewDisclaimerModal,
      showTechSupportAccessAcknowledgeDisclaimerModal,
    } = this.state;
    const _canManageUsers = !Permissions.Project.canManageUsers(
      projectInProgress.id,
    );
    return (
      <div className={EDIT_MANAGE_USERS_BLOCK}>
        <Card>
          <h3 className={`${EDIT_MANAGE_USERS_BLOCK}__title`}>
            <FormattedMessage id={'project-edit.manage-users.title'} />
          </h3>

          <div className={`${EDIT_MANAGE_USERS_BLOCK}__info`}>
            <FormattedMessage id={'project-creation.manage-users.info'} />
            <button
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-learn-more`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__learn-more-button`}
              onClick={() => this._toggleLearnMoreModal()}
            >
              <FormattedMessage
                id={'project-creation.manage-users.learn-more'}
              />
            </button>
          </div>

          <div className={`${EDIT_MANAGE_USERS_BLOCK}__search`}>
            <SearchTableDropdownApi
              columns={USER_SEARCH_COLUMNS}
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-user-search`}
              label={'project-edit.manage-users.search'}
              placeholder={'project-edit.manage-users.search.placeholder'}
              disabled={_canManageUsers}
              onChange={(val) => this._searchUser(val)}
              onClear={() => this._searchUser('')}
              value={search}
              onSelectResult={(user) => {
                addUserToProject(user);
                this.setState({
                  search: '',
                });
              }}
              isOptionSelected={(user) => projectInProgress.containsUser(user)}
              shouldDisableMenuOption={(user) =>
                projectInProgress.containsUser(user)
              }
              showSearchButton
              isValid
              resultsApiModel={
                new DataGridDataApi({
                  apiModel: userListModel,
                  rowItems: userListModel.getUsers(),
                })
              }
            />
          </div>
          {!projectInProgress.isPpmdUserAdded() && (
            <Banner
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-ppmd-banner`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__banner`}
              type={BANNER_TYPES.ERROR}
              bannerCopy={'project-edit.manage-users.ppmd-banner'}
              isInternationalized={true}
              width={'75%'}
            />
          )}
          {!projectInProgress.isEngagementOwnerUserAdded() && (
            <Banner
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-engagement-owner-banner`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__banner`}
              type={BANNER_TYPES.ERROR}
              bannerCopy={'project-edit.manage-users.engagement-owner-banner'}
              isInternationalized={true}
              width={'75%'}
            />
          )}
          <DataGrid
            columns={getAddedUserColumns({
              project: projectInProgress,
              onRemoveClick: removeUserFromProject,
            })}
            className={`${EDIT_MANAGE_USERS_BLOCK}__added-users`}
            tableId={`${EDIT_MANAGE_USERS_ID_BLOCK}-added-users`}
            dataModel={
              new DataGridData({
                rowItems: projectInProgress.userEntities,
              })
            }
            numberOfVisibleRows={this._getNumAddedUserRows()}
            onDropdownColumnSelect={(role, rowId) => {
              assignUserProjectRole({
                userId: rowId,
                role,
              });
            }}
          />
          <div className={`${EDIT_MANAGE_USERS_BLOCK}__checkbox`}>
            <Checkbox
              checked={projectInProgress.hasAdminGroup}
              value={'support-team-access'}
              disabled={this._isSupportTeamAccessCheckboxDisabled()}
              onChange={() => this._onSupportTeamAccessChange()}
              label={'project-edit.manage-users.support-team-access'}
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-legal-hold-checkbox`}
              width={'75%'}
              isValid={true}
            />
          </div>
          <div className={`${EDIT_MANAGE_USERS_BLOCK}__view-disclaimer`}>
            <button
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-disclaimer`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__disclaimer-button`}
              onClick={() => this._toggleViewDisclaimerModal()}
            >
              <FormattedMessage
                id={
                  'project-creation.manage-users.support-team-access.disclaimer'
                }
              />
            </button>
          </div>
          {projectInProgress.hasAdminGroup && (
            <Banner
              width={'75%'}
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-support-team-banner`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__banner`}
              type={BANNER_TYPES.INFORMATION}
              bannerCopy={
                'project-edit.manage-users.support-team-access.banner'
              }
              isInternationalized
            />
          )}
          {projectInProgress.hasAdminGroup &&
            projectInProgress.adminGroupAddDate && (
              <div
                className={`${EDIT_MANAGE_USERS_BLOCK}__admin-enable-history`}
              >
                <div>
                  <FormattedMessage
                    id={
                      'project-edit.manage-users.support-access.history.last-enabled'
                    }
                    values={{
                      adminAddDate: (
                        <span>{projectInProgress.adminGroupAddDate}</span>
                      ),
                    }}
                  />
                </div>
                <div>
                  <FormattedMessage
                    id={
                      'project-edit.manage-users.support-access.history.enabled-by'
                    }
                    values={{
                      adminAddName: (
                        <span>
                          {projectInProgress.adminGroupEnabledByUserName}
                        </span>
                      ),
                    }}
                  />
                </div>
              </div>
            )}
          {isTechSupportStatementAccessEnabled && (
            <div
              className={`${EDIT_MANAGE_USERS_BLOCK}__tech-support-access-checkbox`}
            >
              <Checkbox
                checked={projectInProgress.techSupportAccess}
                value={'tech-support-access'}
                disabled={this._isTechSupportAccessCheckboxDisabled()}
                onChange={() => this._onTechSupportAccessChange()}
                label={'project-creation.manage-users.tech-support-access'}
                id={`${EDIT_MANAGE_USERS_ID_BLOCK}-tech-support-access-checkbox`}
                width={'20%'}
                isValid
              />
              {projectInProgress.techSupportAccess && (
                <Pencil
                  disabled
                  onClick={() =>
                    !this._isTechSupportAccessCheckboxDisabled() &&
                    this._toggleTechSupportAccessAcknowledgementDisclaimerModal()
                  }
                  className={`${EDIT_MANAGE_USERS_BLOCK}__pencil-icon`}
                />
              )}
            </div>
          )}
          {isTechSupportStatementAccessEnabled && (
            <div
              className={`${EDIT_MANAGE_USERS_BLOCK}__tech-support-access-view-disclaimer`}
            >
              <button
                id={`${EDIT_MANAGE_USERS_ID_BLOCK}-disclaimer`}
                className={`${EDIT_MANAGE_USERS_BLOCK}__tech-support-access-disclaimer-button`}
                onClick={() =>
                  this._toggleTechSupportAccessViewDisclaimerModal()
                }
              >
                <FormattedMessage
                  id={
                    'project-creation.manage-users.tech-support-access.disclaimer'
                  }
                />
              </button>
            </div>
          )}
          {projectInProgress.techSupportAccess && (
            <Banner
              width={'75%'}
              id={`${EDIT_MANAGE_USERS_ID_BLOCK}-tech-support-access-banner`}
              className={`${EDIT_MANAGE_USERS_BLOCK}__banner`}
              type={BANNER_TYPES.INFORMATION}
              bannerCopy={
                'project-edit.manage-users.tech-support-access.banner'
              }
              isInternationalized
            />
          )}
        </Card>
        {showLearnMoreModal && (
          <LearnMoreAboutRolesModal
            BLOCK={EDIT_MANAGE_USERS_BLOCK}
            ID_BLOCK={EDIT_MANAGE_USERS_ID_BLOCK}
            onClose={this._toggleLearnMoreModal}
          />
        )}
        {showViewDisclaimerModal && (
          <ViewDisclaimerModal onClose={this._toggleViewDisclaimerModal} />
        )}
        {showTechSupportAccessViewDisclaimerModal && (
          <ViewTechSupportAccessDisclaimerModal
            onClose={this._toggleTechSupportAccessViewDisclaimerModal}
          />
        )}
        {showAcknowledgeDisclaimerModal && (
          <AcknowledgeDisclaimerModal
            onClose={() => this._toggleAcknowledgementDisclaimerModal()}
            onSubmit={() => {
              toggleHasAdminGroup();
              this._toggleAcknowledgementDisclaimerModal();
            }}
          />
        )}
        {showTechSupportAccessAcknowledgeDisclaimerModal && (
          <TechSupportAcknowledgeDisclaimerModal
            onClose={() => {
              resetEnabledTechSupportStatementListData();
              this._toggleTechSupportAccessAcknowledgementDisclaimerModal();
            }}
            onSubmit={() => {
              toggleTechSupportAccess();
              this._toggleTechSupportAccessAcknowledgementDisclaimerModal();
            }}
            onDisable={() => {
              disableTechSupportAccess();
              this._toggleTechSupportAccessAcknowledgementDisclaimerModal();
            }}
            disableTechSupportEditByRole={this._isTechSupportAccessCheckboxDisabled()}
            projectInProgress={projectInProgress}
            setSupportId={setSupportId}
            setEnabledTechSupportStatementList={
              setEnabledTechSupportStatementList
            }
            onRemoveChip={removeSelectedTechSupportStatement}
          />
        )}
      </div>
    );
  }
}

EditManageUsers.propTypes = {
  /** Project object to edit and be submitted */
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Function fired for adding a user to this project */
  addUserToProject: PropTypes.func.isRequired,
  /** Function fired for removing a user from this project */
  removeUserFromProject: PropTypes.func.isRequired,
  /** Function fired for assigning a role to a user on this project */
  assignUserProjectRole: PropTypes.func.isRequired,
  /** Function fired for adding suport team access on this project */
  toggleHasAdminGroup: PropTypes.func.isRequired,
  /** Function fired for adding tech suport team access on this project */
  toggleTechSupportAccess: PropTypes.func.isRequired,
  /** Function fired to set tech suport access related data is modified on this project */
  resetEnabledTechSupportStatementListData: PropTypes.func.isRequired,
  /** Function fired to reset tech suport team access on this project */
  disableTechSupportAccess: PropTypes.func.isRequired,
  /** Function fired to update tech suport access's supportTicketId on this project */
  setSupportId: PropTypes.func,
  /** Function fired to update tech suport access's Statementlist on this project */
  setTechSupportStatementList: PropTypes.func,
  /** Function fired to remove statement from tech suport Statementlist on this project */
  removeSelectedTechSupportStatement: PropTypes.func,
  /** based on this boolean value to check if feature flag is enabled for the feature with mentioned name */
  isTechSupportStatementAccessEnabled: PropTypes.bool.isRequired,
};

export default EditManageUsers;
