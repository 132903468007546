import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const createNewFormulaRequest = ({ formulaModel }) =>
  withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${
        formulaModel.revisionId
      }/elements/${formulaModel.elementId}/formulas`,
      formulaModel.toApiFormat(),
    )
    .then((response) => {
      return response;
    });

export const getFormulasForElementRequest = ({ revisionId, elementId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/formulas`,
    )
    .then((response) => {
      return response;
    });

export const deleteFormulaRequest = ({
  revisionId,
  elementId,
  formulaId,
  creationType,
  type,
}) =>
  withDefaultContextAsSelectedProject()
    .delete(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/formulas/${formulaId}/creationType/${creationType}/type/${type}`,
    )
    .then((response) => {
      return response;
    });

export const getFormulaDetailsRequest = ({
  revisionId,
  elementId,
  formulaId,
}) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/formulas/${formulaId}`,
    )
    .then((response) => {
      return response;
    });

export const editFormulaRequest = ({ formulaModel }) =>
  withDefaultContextAsSelectedProject()
    .put(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${
        formulaModel.revisionId
      }/elements/${formulaModel.elementId}/formulas/${formulaModel.formulaId}`,
      formulaModel.toApiFormat(),
    )
    .then((response) => {
      return response;
    });

export const updateFormulaTypeRequest = ({
  id,
  revisionId,
  elementId,
  type,
  formulaOverride,
}) =>
  withDefaultContextAsSelectedProject()
    .put(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/formulas/${id}/update/formulatype`,
      {
        id,
        revisionId,
        elementId,
        type,
        formulaOverride,
      },
    )
    .then((response) => {
      return response;
    });
