import ApiModel from 'models/api-model';

export class SectionIdList extends ApiModel({
  data: {
    sectionIds: [],
  },
  status: null,
}) {
  processResponse({ response }) {
    return {
      data: {
        sectionIds: response.data,
      },
      status: response.status,
    };
  }
  get sectionIds() {
    return this.data.sectionIds;
  }
}
