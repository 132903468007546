import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/common/chip-component';
import { FormattedMessage } from 'react-intl';
import ElementFilters from 'models/data/element-filters-model';
import shortid from 'shortid';
import { isFilterUnselected } from 'constants/feature/statement-navigator/statement-nav-element-filter-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import { connect } from 'react-redux';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import Tooltip from 'components/common/tool-tip-component';

export const ELEMENT_FILTER_CHIPS_ID_BLOCK = 'element-filter-chips-id';
const ELEMENT_FILTER_CHIPS_BLOCK = 'statement-nav-filter-chips';

const MAX_CHARACTER_LIMIT = 20;

/**
 * A function for filling out the inner text of a chip that handles multiple values
 * @param {Array} param.valuesArray array of values with either a `text` or `intlId` prop indicating what to display
 */
const _getChipFromArray = ({ valuesArray }) =>
  valuesArray.map(({ text, intlId }) => {
    if (!isNullOrUndefined(intlId)) {
      return (
        <Fragment key={shortid.generate()}>
          <FormattedMessage id={intlId} />{' '}
        </Fragment>
      );
    }
    if (text.length > MAX_CHARACTER_LIMIT) {
      return (
        <Fragment key={shortid.generate()}>
          <span className={`${ELEMENT_FILTER_CHIPS_BLOCK}__ellipsis-item`}>
            <Tooltip
              id={`${ELEMENT_FILTER_CHIPS_BLOCK}-${text}`}
              position="top"
              text={text}
              isNotInternationalized={true}
            >
              <span>{text.slice(0, MAX_CHARACTER_LIMIT)}...</span>
            </Tooltip>
          </span>{' '}
        </Fragment>
      );
    }
    return <span key={shortid.generate()}>{`${text} `}</span>;
  });

const ElementFilterChips = ({ filters, onRemoveChip, baseId }) => {
  return (
    <>
      {!isFilterUnselected(filters.verified) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-verified`}
          onRemove={() => onRemoveChip(filters.clearVerified())}
        >
          <FormattedMessage id={filters.verifiedChiptext} />
        </Chip>
      )}
      {!isFilterUnselected(filters.reviewed) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-reviewed`}
          onRemove={() => onRemoveChip(filters.clearReviewed())}
        >
          <FormattedMessage id={filters.reviewedChiptext} />
        </Chip>
      )}

      {!isFilterUnselected(filters.comfortAssign) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-comfort-assigned`}
          onRemove={() => onRemoveChip(filters.clearComfortAssign())}
        >
          <FormattedMessage id={filters.comfortAssignChiptext} />
        </Chip>
      )}
      {!isFilterUnselected(filters.parentFlagged) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-flagged`}
          onRemove={() => onRemoveChip(filters.clearFlagged())}
        >
          {_getChipFromArray({ valuesArray: filters.flagChipText })}
        </Chip>
      )}
      {filters.arePeriodsSelected() && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-periods`}
          onRemove={() => onRemoveChip(filters.clearPeriods())}
        >
          {_getChipFromArray({ valuesArray: filters.periodChipText })}
        </Chip>
      )}

      {filters.areScalingsSelected() && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-scaling`}
          onRemove={() => onRemoveChip(filters.clearScalings())}
        >
          {_getChipFromArray({ valuesArray: filters.scalingChipText })}
        </Chip>
      )}

      {filters.areUnitsSelected() && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-unit`}
          onRemove={() => onRemoveChip(filters.clearUnits())}
        >
          {_getChipFromArray({ valuesArray: filters.unitChipText })}
        </Chip>
      )}

      {filters.areEntitiesSelected() && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-entity`}
          onRemove={() => onRemoveChip(filters.clearEntities())}
        >
          {_getChipFromArray({ valuesArray: filters.entityChipText })}
        </Chip>
      )}

      {!isFilterUnselected(filters.wpReference) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-wpr`}
          onRemove={() => onRemoveChip(filters.clearWPR())}
        >
          {_getChipFromArray({ valuesArray: filters.wprChipText })}
        </Chip>
      )}

      {!isFilterUnselected(filters.comfortLetter) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-comfort-annotation`}
          onRemove={() => onRemoveChip(filters.clearComfortLetter())}
        >
          {_getChipFromArray({ valuesArray: filters.comfortLetterChipText })}
        </Chip>
      )}

      {!isFilterUnselected(filters.internalReference) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-ir`}
          onRemove={() => onRemoveChip(filters.clearIR())}
        >
          <FormattedMessage id={filters.irChipText} />
        </Chip>
      )}

      {!isFilterUnselected(filters.tickmarks) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-tickmarks`}
          onRemove={() => onRemoveChip(filters.clearTickmarks())}
        >
          {_getChipFromArray({ valuesArray: filters.tickmarksChipText })}
        </Chip>
      )}
      {!isFilterUnselected(filters.parentFormula) &&
        filters.parentFormula === false && (
          <Chip
            id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-formula`}
            onRemove={() => onRemoveChip(filters.clearFormula())}
          >
            {_getChipFromArray({ valuesArray: filters.formulaChipText })}
          </Chip>
        )}
      {(!isFilterUnselected(filters.recalculatedFormula) ||
        !isFilterUnselected(filters.crossFootedFormula) ||
        !isFilterUnselected(filters.footedFormula)) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-formula`}
          onRemove={() => onRemoveChip(filters.clearFormula())}
        >
          {_getChipFromArray({ valuesArray: filters.formulaChipText })}
        </Chip>
      )}
      {!isFilterUnselected(filters.roundedFormula) && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-rounded-formula`}
          onRemove={() => onRemoveChip(filters.clearRoundedFormula())}
        >
          {_getChipFromArray({ valuesArray: filters.roundedFormulaChipText })}
        </Chip>
      )}
      {filters.areSectionsSelected() && (
        <Chip
          id={`${baseId}-${ELEMENT_FILTER_CHIPS_ID_BLOCK}-section`}
          onRemove={() => onRemoveChip(filters.clearSectionIds())}
        >
          {_getChipFromArray({ valuesArray: filters.sectionSegmentChipText })}
        </Chip>
      )}
    </>
  );
};

ElementFilterChips.propTypes = {
  /** ID string unique to this component, will be used to build unique ids for each chip within */
  baseId: PropTypes.string.isRequired,
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired when remove chip is clicked, returns the updated filters model as only argument */
  onRemoveChip: PropTypes.func.isRequired,
  /** Map of content section id to section meta-data */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap).isRequired,
};

const mapStateToProps = ({
  data: {
    statementContent: {
      sectionsCache: { contentSectionMap },
    },
  },
}) => ({ contentSectionMap });

export { ElementFilterChips };
export default connect(mapStateToProps, null)(ElementFilterChips);
