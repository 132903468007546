import ApiModel from 'models/api-model';
import User from 'models/data/user-model';
import { isNullOrUndefined } from 'utils/object-utils';

export class AuthUser extends ApiModel({
  data: new User(),
  status: null,
}) {
  get id() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.id;
    }
    return null;
  }

  processResponse({ response }) {
    return {
      data: new User(response.result),
      status: response.status,
    };
  }

  isInitialized() {
    return !isNullOrUndefined(this.data) || !isNullOrUndefined(this.data.id);
  }

  getUser() {
    return this.data;
  }

  hasID() {
    return !isNullOrUndefined(this.id);
  }

  errorNotNull() {
    return !this.isLoading && this.error !== null;
  }

  isDisabled() {
    return this.isInitialized() && !this.getUser().enabled;
  }
  isUserAuthorized() {
    return this.status === 401
      ? false
      : this.isLoaded &&
          this.hasID() &&
          !this.errorNotNull() &&
          !this.isDisabled();
  }

  isAuthStatus401UnAuthorized() {
    return this.status === 401;
  }

  set401Error(error) {
    return this.merge({
      status: error.status,
      isLoading: false,
      isLoaded: true,
    });
  }
}
