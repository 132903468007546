import ApiModel from 'models/api-model';

export class RevisionList extends ApiModel({
  data: {
    revisions: [],
  },
  status: null,
}) {
  get revisions() {
    return this.data ? this.data.revisions : [];
  }

  get length() {
    return this.data && this.data.revisions ? this.data.revisions.length : 0;
  }
  processResponse({ response }) {
    return {
      data: {
        revisions: response.data,
      },
      status: response.status,
    };
  }
}
