import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

import Statement from 'models/data/statement-model';

const SOFT_DELETE_ALL_REVISIONS_ID_BLOCK =
  'id-soft-delete-active-revision-modal';

const SoftDeleteAllRevisionsModal = ({
  statementToDelete,
  onClose,
  onDelete,
}) => {
  return (
    <Modal
      id={SOFT_DELETE_ALL_REVISIONS_ID_BLOCK}
      title={
        'statement-list.soft-delete-all-revisions-modal.all-revisions-title'
      }
      onClose={onClose}
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
      primaryDeleteModalButton={
        new ModalButton({
          text: 'common.delete',
          onClick: () => onDelete(),
        })
      }
    >
      <div className="modal__text">
        <FormattedMessage
          id={'statement-list.soft-delete-all-revisions-statement-modal'}
        />
      </div>
    </Modal>
  );
};

SoftDeleteAllRevisionsModal.propTypes = {
  /** Statement model holding the revisions that can be deleted */
  statementToDelete: PropTypes.instanceOf(Statement).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when deletion is confirmed */
  onDelete: PropTypes.func.isRequired,
};

export default memo(SoftDeleteAllRevisionsModal);
