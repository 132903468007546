const envParam = window.TIEOUT.ENV.ENV_PARAM;
const oneTrustScriptEnvParam =
  window.TIEOUT.ENV.FEATURE.ENABLE_COOKIE_CONSENT_NOTIFICATION;
const DISABLE_SCRIPT_TYPE = 'text';
const ENABLE_SCRIPT_TYPE = 'text/javascript';

function addScript(src, dataDomainScript) {
  const element = document.createElement('script');
  element.type = oneTrustScriptEnvParam
    ? ENABLE_SCRIPT_TYPE
    : DISABLE_SCRIPT_TYPE;
  element.src = src;
  element.async = true;
  element.setAttribute('data-domain-script', dataDomainScript);
  element.setAttribute('charset', 'UTF-8');
  document.head.appendChild(element);
}

let src;
let dataDomainScript;
switch (envParam) {
  case 'PROD':
    src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    dataDomainScript = '988f64c0-ebb0-49af-8080-9a3de86edce0';
    break;
  case 'BCP':
    src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    dataDomainScript = '988f64c0-ebb0-49af-8080-9a3de86edce0';
    break;
  default:
    src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    dataDomainScript = '988f64c0-ebb0-49af-8080-9a3de86edce0-test';
    break;
}

addScript(src, dataDomainScript);
