import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormulaList } from 'models/api/formula-list-model';
import ElementPanelHeader from './_element-panel-header-component';
import ElementPanelBody from './_element-panel-body-component';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { ELEMENT_ANNOTATIONS_TABS } from 'constants/feature/element-panel-constants';

import FormulaPanelContainer from 'containers/feature/formula-panel/formula-panel-container';
import InternalReferencePanel from 'components/feature/element-panel/internal-reference-panel/internal-reference-panel-component';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import WorkpaperPanelContainer from 'containers/feature/workpaper-panel-container';
import InternalReference from 'models/api/internal-reference-model';
import TickmarkPanelContainer from 'containers/feature/element-panel/element-tickmark-panel-container';
import ElementTickmarkList from 'models/api/element-tickmark-list-model';
import CopyFormulaBanner from 'components/util/copy-formula-banner-component';

export const ELEMENT_PANEL_BLOCK = 'element-panel';
export const ELEMENT_PANEL_ID_BLOCK = 'element-panel-id';

class ElementPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedElementPanelAnnotationsTab:
        ELEMENT_ANNOTATIONS_TABS.WORK_PAPER_REFERENCE,
      showFormulaPanelFlyout: false,
      showInternalReferencePanel: false,
      showFormulaPanel: false,
      showWorkpaperPanel: false,
      showTickmarkPanel: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      elementDetails,
      shouldShowElementPanel,
      deselectElementContentPanel,
    } = this.props;
    const { showInternalReferencePanel } = this.state;
    if (prevProps.elementDetails.id !== elementDetails.id) {
      this.setState((state) => ({
        selectedElementPanelAnnotationsTab:
          ELEMENT_ANNOTATIONS_TABS.WORK_PAPER_REFERENCE,
      }));
    }
    if (!shouldShowElementPanel) {
      deselectElementContentPanel({ elementId: elementDetails.id });
      if (showInternalReferencePanel) {
        this.toggleInternalReferencePanel();
      }
    }
  }

  /**
   * Should show when `shouldShowElementPanel` is true and all sub panels are false
   */
  _shouldRenderElementPanelContent = () => {
    const { shouldShowElementPanel } = this.props;
    const { showFormulaPanel, showWorkpaperPanel } = this.state;

    return shouldShowElementPanel && !showFormulaPanel && !showWorkpaperPanel;
  };

  setCurrentElementAnnotationsTab = (selectedElementPanelAnnotationsTab) => {
    this.setState((state) => ({
      selectedElementPanelAnnotationsTab: selectedElementPanelAnnotationsTab,
    }));
  };

  toggleFormulaPanel = () => {
    this.setState((state) => ({
      showFormulaPanel: !state.showFormulaPanel,
    }));
  };
  createNewFormula = () => {
    const { setNewFormulaAction } = this.props;
    setNewFormulaAction(true);
    this.setState((state) => ({
      showFormulaPanel: !state.showFormulaPanel,
    }));
  };

  toggleInternalReferencePanel = () => {
    this.setState((state) => ({
      showInternalReferencePanel: !state.showInternalReferencePanel,
    }));
  };

  _closeFormulaPanel = (fetchElementsAfterModalClose = true) => {
    const {
      clearFormula,
      elementDetails,
      fetchSelectedElementDetails,
      socketHasBeenDisconnected,
    } = this.props;
    this.toggleFormulaPanel();
    if (fetchElementsAfterModalClose) {
      clearFormula({ elementId: elementDetails.id });
      if (socketHasBeenDisconnected) {
        fetchSelectedElementDetails({ elementId: elementDetails.id });
      }
    }
  };

  _closeInternalReferencePanel = () => {
    const {
      clearInternalReference,
      elementDetails,
      fetchSelectedElementDetails,
    } = this.props;
    this.toggleInternalReferencePanel();
    clearInternalReference();
    fetchSelectedElementDetails({ elementId: elementDetails.id });
  };

  _getFormulaDetailsForEdit = (formulaId) => {
    const { fetchSelectedFormulaDetails } = this.props;
    fetchSelectedFormulaDetails(formulaId);
    this.toggleFormulaPanel();
  };

  toggleWorkpaperPanel = () => {
    this.setState((state) => ({
      showWorkpaperPanel: !state.showWorkpaperPanel,
    }));
  };

  toggleTickmarkPanel = () => {
    this.setState((state) => ({
      showTickmarkPanel: !state.showTickmarkPanel,
    }));
  };

  render() {
    const {
      elementDetails,
      formulaList,
      internalReference,
      workpaperList,
      shouldShowElementPanel,
      hideElementPanel,
      updateUserFlag,
      updateVerify,
      updateReviewed,
      selectElementFromInternalReference,
      detachElementFromInternalReference,
      detachElementWorkpaper,
      initInternalReferencePanel,
      tickmarkList,
      detachTickmarkFromElement,
      excludeElementFromElementList,
      cancelModal,
      toggleInternalReferenceCancelModal,
      openCancelModalAfterModalConfirm,
      toggleNotesPanel,
      storeSelectedNoteId,
      storeSelectedNotePseudoId,
      selectNoteFromContentPanel,
      isFormulaCopied,
      stopCopyFormula,
      cancelCopyFormula,
      numberOfElementsSelectedForCopy,
      onSectionClick,
    } = this.props;
    const {
      selectedElementPanelAnnotationsTab,
      showInternalReferencePanel,
      showFormulaPanel,
      showWorkpaperPanel,
      showTickmarkPanel,
    } = this.state;
    return (
      <>
        <Flyout
          className={`${ELEMENT_PANEL_BLOCK}`}
          show={shouldShowElementPanel}
        >
          {isFormulaCopied && (
            <CopyFormulaBanner
              isFormulaCopied={isFormulaCopied}
              stopCopyFormula={stopCopyFormula}
              numberOfElementsSelectedForCopy={numberOfElementsSelectedForCopy}
              cancelCopyFormula={cancelCopyFormula}
            />
          )}
          {this._shouldRenderElementPanelContent() && (
            // The flyout panel can be shown and hidden, but the content should not exist in the DOM until it is shown for performance
            <>
              <FlyoutHeader enableClose onClose={hideElementPanel}>
                <ElementPanelHeader
                  elementDetails={elementDetails}
                  excludeElementFromElementList={excludeElementFromElementList}
                />
              </FlyoutHeader>
              <FlyoutPanelContent>
                <ElementPanelBody
                  elementDetails={elementDetails}
                  formulaList={formulaList}
                  internalReference={internalReference}
                  tickmarkList={tickmarkList}
                  workpaperList={workpaperList}
                  updateUserFlag={updateUserFlag}
                  updateVerify={updateVerify}
                  updateReviewed={updateReviewed}
                  selectedElementPanelAnnotationsTab={
                    selectedElementPanelAnnotationsTab
                  }
                  setCurrentElementAnnotationsTab={
                    this.setCurrentElementAnnotationsTab
                  }
                  primaryAnnotationActions={{
                    onCreateNewFormula: this.createNewFormula,
                    onAddInternalReference: this.toggleInternalReferencePanel,
                    onAddWorkpaper: this.toggleWorkpaperPanel,
                    onAttachTickmark: this.toggleTickmarkPanel,
                  }}
                  secondaryAnnotationActions={{
                    onCopyAllFormula: () => console.log('COPY ALL CLICKED'),
                  }}
                  onEditFormula={this._getFormulaDetailsForEdit}
                  onDetachWorkpaper={detachElementWorkpaper}
                  selectElementFromInternalReference={
                    selectElementFromInternalReference
                  }
                  detachElementFromInternalReference={
                    detachElementFromInternalReference
                  }
                  detachTickmarkFromElement={detachTickmarkFromElement}
                />
              </FlyoutPanelContent>
            </>
          )}
        </Flyout>
        {shouldShowElementPanel && (
          <>
            <FormulaPanelContainer
              showFormulaPanel={showFormulaPanel}
              closeFormulaPanelFlyout={this._closeFormulaPanel}
            />
            <WorkpaperPanelContainer
              showWorkpaperPanel={showWorkpaperPanel}
              closeWorkpaperPanel={this.toggleWorkpaperPanel}
            />
            <InternalReferencePanel
              showInternalReferencePanel={showInternalReferencePanel}
              closeInternalReferencePanel={this._closeInternalReferencePanel}
              initInternalReferencePanel={initInternalReferencePanel}
              onSectionClick={onSectionClick}
              cancelModal={cancelModal}
              toggleInternalReferenceCancelModal={
                toggleInternalReferenceCancelModal
              }
              openCancelModalAfterModalConfirm={
                openCancelModalAfterModalConfirm
              }
              toggleNotesPanel={toggleNotesPanel}
              storeSelectedNoteId={storeSelectedNoteId}
              storeSelectedNotePseudoId={storeSelectedNotePseudoId}
              selectNoteFromContentPanel={selectNoteFromContentPanel}
            />
            <TickmarkPanelContainer
              showTickmarkPanel={showTickmarkPanel}
              closeTickmarkPanel={this.toggleTickmarkPanel}
            />
          </>
        )}
      </>
    );
  }
}

ElementPanel.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Indicates if the element panel should be shown */
  shouldShowElementPanel: PropTypes.bool.isRequired,
  /** Action to hide element flyout panel */
  hideElementPanel: PropTypes.func.isRequired,
  /** Action to update user flag for element */
  updateUserFlag: PropTypes.func.isRequired,
  /** Action that set element as verified */
  updateVerify: PropTypes.func.isRequired,
  /** Action that set element as reviewed */
  updateReviewed: PropTypes.func.isRequired,
  /**Action used to clear the formula that was being worked on in the formula panel */
  clearFormula: PropTypes.func.isRequired,
  /** The list of formulas for the current element*/
  formulaList: PropTypes.instanceOf(FormulaList).isRequired,
  /** Action used to fetch the details of an element,
   * used to refresh element details data after returning to element panel from other children panels */
  fetchSelectedElementDetails: PropTypes.func.isRequired,
  /** Action used to fetch the details of a formula */
  fetchSelectedFormulaDetails: PropTypes.func,
  /** Action used to clear the internal referecne that is being worked on in the internal reference panel */
  clearInternalReference: PropTypes.func.isRequired,
  /** Internal reference that is currently linked to this element */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** action fired to detach a work paper from this element */
  detachElementWorkpaper: PropTypes.func.isRequired,
  /** Function fired when internal reference panel is shown, initializes redux information */
  initInternalReferencePanel: PropTypes.func.isRequired,
  /** List of tickmarks associated with this element */
  tickmarkList: PropTypes.instanceOf(ElementTickmarkList).isRequired,
  /** action fired to detach a tickmark from this element */
  detachTickmarkFromElement: PropTypes.func.isRequired,
  /** action fired to open the internal reference cancel modal */
  toggleInternalReferenceCancelModal: PropTypes.func,
  /** action fired to indicate the note panel will be opened after modal is confirmed */
  openCancelModalAfterModalConfirm: PropTypes.func,
  /** action fired to store the selected note in redux */
  storeSelectedNoteId: PropTypes.func,
  /** action fired to store the psuedo id of the selected note in redux */
  storeSelectedNotePseudoId: PropTypes.func,
  /** object with cancel modal details */
  cancelModal: PropTypes.object,
  /* action to set a formula as new */
  setNewFormulaAction: PropTypes.func.isRequired,
  /** The action used to clear copied formulas and Table matrix from the store*/
  stopCopyFormula: PropTypes.func.isRequired,
  /** to check if user has copied formula*/
  isFormulaCopied: PropTypes.bool.isRequired,
  /*To abort copy formula mode and clear related redux store data*/
  cancelCopyFormula: PropTypes.func.isRequired,
  /*Data from redux store to check number of elements selected for pasting copied formula*/
  numberOfElementsSelectedForCopy: PropTypes.number,
  /**The action to open the section. */
  onSectionClick: PropTypes.func,
};

export default ElementPanel;
