import { clearStatementList } from 'store/actions/statement-list/statement-list-actions';
import { clearProjectIdWorkflowExpanded } from 'store/actions/statement-workflows-actions';
import { initStatementListFilters } from 'store/actions/statement-list/statement-list-filters-actions';

/**
 * TODO Refactor to a single action that is listend for in all of these reducers
 */
export const cleanUpHomePageStore = () => (dispatch) => {
  dispatch(clearStatementList());
  dispatch(clearProjectIdWorkflowExpanded());
  dispatch(initStatementListFilters());
};
