import { handleActions } from 'redux-actions';
import { setElementFilters } from 'store/actions/statement-navigator/elements-search-panel-actions';
import ElementFilters from 'models/data/element-filters-model';
import { clearElementFilters } from 'store/actions/statement-navigator/elements-search-panel-actions';

export default handleActions(
  {
    [setElementFilters]: (_, { payload }) => {
      return payload;
    },
    [clearElementFilters]: (_, { payload }) => {
      return new ElementFilters();
    },
  },
  new ElementFilters(),
);
