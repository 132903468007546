import BaseModel from 'models/base-model';
export default class User extends BaseModel({
  admin: null,
  applicationManager: null,
  coe: null,
  company: null,
  disabledDate: null,
  email: null,
  enabled: null,
  enabledDate: null,
  firstName: null,
  fullName: null,
  function: null,
  id: null,
  isPpmd: null,
  jobType: null,
  lastName: null,
  preferredFullName: null,
  region: null,
  title: null,
  officeDescription: null,
  zoneId: null,
  geoCode: null,
  projectToGeoMap: null,
}) {}
