import { useRef, useEffect } from 'react';
/** Taken from the React hooks docs https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * Lets you store the previous value of a prop or local state variable in a functional component to mimic componentDidUpdate(prevState, prevProps)
 */
export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
