import { handleActions } from 'redux-actions';
import {
  clearStatementPageStoreAction,
  replaceLeftSelectedElementWithoutRemovingOld,
  clearLeftSelectedElementsContentPanel,
} from 'store/actions/statement-content-actions';

import SelectedElementMap from 'models/data/selected-element-map';

export default handleActions(
  {
    [replaceLeftSelectedElementWithoutRemovingOld]: (
      selectedElementMap,
      { payload },
    ) => {
      return selectedElementMap.replaceWithoutRemovingOld(payload);
    },
    [clearLeftSelectedElementsContentPanel]: (selectedElementMap) => {
      return selectedElementMap.clearMap();
    },
    [clearStatementPageStoreAction]: () => {
      return new SelectedElementMap();
    },
  },
  new SelectedElementMap(),
);
