import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import NotesTextArea from 'components/feature/notes-panel/_notes-textarea-component';

export const NOTES_PANEL_INPUT_AREA_BLOCK = 'notes-panel-input-area';
export const NOTES_PANEL_REPLY_INPUT_AREA_ID_BLOCK =
  'notes-panel-reply-input-area-id';

const NoteReplyInputArea = ({
  cancelAddNoteReply,
  createNoteReply,
  projectUsersList,
}) => {
  const [text, setText] = useState('');

  return (
    <div
      className={NOTES_PANEL_INPUT_AREA_BLOCK}
      id={NOTES_PANEL_REPLY_INPUT_AREA_ID_BLOCK}
    >
      <NotesTextArea
        id={`${NOTES_PANEL_REPLY_INPUT_AREA_ID_BLOCK}-content`}
        placeholder="notes-panel.notes-reply.placeholder"
        onChange={(text) => setText(text)}
        value={text}
        data={projectUsersList}
      />
      <div className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container`}>
        <Button
          id={`${NOTES_PANEL_INPUT_AREA_BLOCK}__cancel-button`}
          className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container--cancel`}
          type={BUTTON_TYPES.secondary}
          onClick={cancelAddNoteReply}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          id={`${NOTES_PANEL_INPUT_AREA_BLOCK}__add-button`}
          className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container--add`}
          type={BUTTON_TYPES.primary}
          onClick={() => {
            createNoteReply(text);
            setText('');
          }}
          disabled={text.length > 0 ? false : true}
        >
          <FormattedMessage id="common.add" />
        </Button>
      </div>
    </div>
  );
};

NoteReplyInputArea.propTypes = {
  /** Action fired for canceling adding a note */
  cancelAddNoteReply: PropTypes.func.isRequired,
  /** Action for creating a note reply */
  createNoteReply: PropTypes.func.isRequired,
  /** List of the elements to render in the suggestions list */
  projectUsersList: PropTypes.array.isRequired,
};

export default NoteReplyInputArea;
