import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import { ReactComponent as Kebab } from 'icons/kebab-bold.svg';
import { FormattedMessage } from 'react-intl';
import {
  OPTIONS1_CHECKBOX_ARRAY,
  OPTIONS2_CHECKBOX_ARRAY,
  METRICS_CHECKBOX_ARRAY,
} from 'constants/feature/statement-summary-constants';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';

export const SUMMARY_DISPLAY_OPTIONS_DROPDOWN =
  'summary-display-options-dropdown';
export const SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID =
  'summary-display-options-dropdown-id';
const KEBAB_SIZE = '25px';
const ELEMENT_TITLE_INDEX = 0;
const HEADING_TITLE_INDEX = 2;

const StatementSummaryDropDown = ({
  displayOptions,
  onDisplayOptionChange,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const onMousedownEvent = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDisplayMenu(false);
      }
    };
    document.addEventListener('mousedown', onMousedownEvent, false);
    return () => {
      document.removeEventListener('mousedown', onMousedownEvent, false);
    };
  }, [containerRef]);

  return (
    <div className={SUMMARY_DISPLAY_OPTIONS_DROPDOWN} ref={containerRef}>
      <Kebab
        className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__kebab`}
        id={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-kebab`}
        width={KEBAB_SIZE}
        height={KEBAB_SIZE}
        onClick={() => setDisplayMenu(!displayMenu)}
      />
      {displayMenu && (
        <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__menu`}>
          <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__header`}>
            <FormattedMessage id={'statement-summary.display-settings.label'} />
          </div>
          <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__menu-options`}>
            <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__options`}>
              <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__label`}>
                <FormattedMessage id={'statement-summary.options.label'} />
              </div>
              {OPTIONS1_CHECKBOX_ARRAY.map((item, iter) => {
                return (
                  <div
                    className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__checkbox-container`}
                    key={`${item.name}-${item.key}`}
                  >
                    {iter === ELEMENT_TITLE_INDEX ? (
                      <div
                        className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__category-label`}
                      >
                        <FormattedMessage
                          id={'statement-summary.element.category-label'}
                        />
                      </div>
                    ) : iter === HEADING_TITLE_INDEX ? (
                      <div
                        className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__category-label`}
                      >
                        <FormattedMessage
                          id={'statement-summary.heading.category-label'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <Checkbox
                      name={item.name}
                      checked={displayOptions[item.name]}
                      value={item.name}
                      onChange={onDisplayOptionChange}
                      label={item.label}
                      id={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-${item.key}`}
                    />
                  </div>
                );
              })}
            </div>
            {!displayOptions.verified &&
            !displayOptions.reviewed &&
            !displayOptions.signedOff ? null : (
              <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__metrics`}>
                <div className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__label`}>
                  <FormattedMessage id={'statement-summary.metrics.label'} />
                </div>
                {METRICS_CHECKBOX_ARRAY.map((item) => (
                  <Checkbox
                    id={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}__metrics-${item.key}`}
                    name={item.name}
                    checked={displayOptions[item.name]}
                    value={item.name}
                    onChange={onDisplayOptionChange}
                    label={item.label}
                    key={`${item.name}-${item.key}`}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN}__menu-options-2`}
          >
            {OPTIONS2_CHECKBOX_ARRAY.map((item) => (
              <Checkbox
                id={`${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-${item.key}`}
                name={item.name}
                checked={displayOptions[item.name]}
                value={item.name}
                onChange={onDisplayOptionChange}
                label={item.label}
                key={`${item.name}-${item.key}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

StatementSummaryDropDown.propTypes = {
  /** func to set display options */
  onDisplayOptionChange: PropTypes.func.isRequired,
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
};

export default StatementSummaryDropDown;
