import { createAction } from 'redux-actions';

export const setSourceStatementParams = createAction(
  'SET_SOURCE_STATEMENT_PARAMS',
);
export const clearSourceStatementParams = createAction(
  'CLEAR_SOURCE_STATEMENT_PARAMS',
);

export const setLeftStatementSelected = createAction(
  'SET_LEFT_STATEMENT_SELECTED',
);
