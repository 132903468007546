import { handleActions } from 'redux-actions';
import {
  selectElementContentPanel,
  deselectElementContentPanel,
  clearSelectedElementsContentPanel,
  selectMultipleElementsContentPanel,
  replaceSelectedElementsContentPanel,
  clearStatementPageStoreAction,
  deallocateSelectedElementsContentPanel,
  deselectElementsFromArrayContentPanel,
  replaceSelectedElementWithoutRemovingOld,
} from 'store/actions/statement-content-actions';
import {
  removeElementFromBatchSelectionAction,
  addElementToBatchSelectionAction,
  batchElementUpdateSuccessAction,
} from 'store/actions/batch-panel-actions';
import { selectCreatedElementAction } from 'store/actions/element-panel-actions';

import SelectedElementMap from 'models/data/selected-element-map';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';

export default handleActions(
  {
    [selectElementContentPanel]: (selectedElementMap, { payload }) => {
      const elementId = payload.elementIds ? payload.elementIds[0] : null;
      const color = payload.color ? payload.color : null;
      return selectedElementMap.appendSelectedElement({ elementId, color });
    },

    [deselectElementContentPanel]: (selectedElementMap, { payload }) => {
      return selectedElementMap.deselectElement(payload);
    },
    [deselectElementsFromArrayContentPanel]: (
      selectedElementMap,
      { payload },
    ) => {
      return selectedElementMap.deselectElementsFromArray(payload);
    },
    [clearSelectedElementsContentPanel]: (selectedElementMap) => {
      return selectedElementMap.clearMap();
    },
    [selectMultipleElementsContentPanel]: (selectedElementMap, { payload }) => {
      return selectedElementMap.appendSelectedElements(payload);
    },
    [deallocateSelectedElementsContentPanel]: (
      selectedElementMap,
      { payload },
    ) => {
      return selectedElementMap.deallocateElement(payload);
    },
    [replaceSelectedElementsContentPanel]: (
      selectedElementMap,
      { payload },
    ) => {
      return selectedElementMap.replaceWithElements(payload);
    },
    [replaceSelectedElementWithoutRemovingOld]: (
      selectedElementMap,
      { payload },
    ) => {
      return selectedElementMap.replaceWithoutRemovingOld(payload);
    },
    /* Non selected map specific action reducers */
    // highlights elements when added to batch selection
    [addElementToBatchSelectionAction]: (
      selectedElementMap,
      { payload: { element } },
    ) => {
      return selectedElementMap.appendSelectedElement({
        elementId: element.id,
      });
    },
    // removes highlight from elements removed from batch selection
    [removeElementFromBatchSelectionAction]: (
      selectedElementMap,
      { payload: { elementId } },
    ) => {
      return selectedElementMap.removeElement({ elementId });
    },
    [batchElementUpdateSuccessAction]: (
      selectedElementMap,
      { payload: { elementIds } },
    ) => {
      return selectedElementMap.replaceWithElements({
        elementIds,
        color: ELEMENT_HIGHLIGHT_STATES.POST_BATCH,
      });
    },
    [selectCreatedElementAction]: (
      selectedElementMap,
      { payload: { elementIds } },
    ) => {
      return selectedElementMap.replaceWithElements({
        elementIds,
        color: ELEMENT_HIGHLIGHT_STATES.POST_BATCH,
      });
    },
    [clearStatementPageStoreAction]: (selectedElementMap, { payload }) => {
      return new SelectedElementMap();
    },
  },
  new SelectedElementMap(),
);
