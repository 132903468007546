import { createAction } from 'redux-actions';

export const clearApiErrorDataAction = createAction(
  'CLEAR_API_ERROR_DATA_ACTION',
);

export const createApiErrorDataAction = createAction(
  'CREATE_API_ERROR_DATA_ACTION',
);

export const createErrorStackTraceAction = createAction(
  'CREATE_ERROR_STACK_TRACE_ACTION',
);

export const createCatchedErrorJsonAction = createAction(
  'CREATE_CATCHED_ERROR_JSON_ACTION',
);
