import { handleActions } from 'redux-actions';
import {
  clearElementSearchResults,
  emptyElementSearchResults,
  setElementSearchResults,
  setElementSearchResultsLoading,
  setElementSearchResultsLoaded,
  setElementSearchResultsError,
  setSelectedElementResult,
  setElementSearchUpdateResultsLoaded,
  setElementSearchRemoveElementsLoaded,
  updateElementFilterFromSocketPayloadAction,
  showElementSearchRefreshButtonAction,
  setBatchSelectedItemsIdsAction,
  removeIdFromBatchSelectedItemsAction,
  addIdToBatchSelectedItemsAction,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import ElementSearchResults from 'models/api/statements-element-search-results-api-model';

export default handleActions(
  {
    [setElementSearchResults]: (elementSearchResults, { payload }) => {
      return payload;
    },
    [setElementSearchResultsLoading]: (elementSearchResults) => {
      return elementSearchResults.setLoading();
    },
    [setElementSearchResultsLoaded]: (elementSearchResults, { payload }) => {
      return elementSearchResults.setLoaded(payload);
    },
    [setElementSearchResultsError]: (elementSearchResults, { payload }) => {
      return elementSearchResults.setError(payload);
    },
    [setSelectedElementResult]: (elementSearchResults, { payload }) => {
      return elementSearchResults.setSelectedIndex(payload);
    },
    [emptyElementSearchResults]: (elementSearchResults) => {
      return elementSearchResults.emptyElementList();
    },
    [setElementSearchUpdateResultsLoaded]: (
      elementSearchResults,
      { payload },
    ) => {
      return elementSearchResults.updateElementList(payload);
    },
    [updateElementFilterFromSocketPayloadAction]: (
      elementSearchResults,
      { payload },
    ) => {
      return elementSearchResults.updateElementListFromSocketPayload(payload);
    },
    [setElementSearchRemoveElementsLoaded]: (
      elementSearchResults,
      { payload },
    ) => {
      return elementSearchResults.removeFromElementList(payload);
    },
    [clearElementSearchResults]: (elementSearchResults) => {
      return new ElementSearchResults();
    },
    [showElementSearchRefreshButtonAction]: (
      elementSearchResults,
      { payload },
    ) => {
      return elementSearchResults.setShowElementSearchRefreshButton(payload);
    },
    [setBatchSelectedItemsIdsAction]: (elementSearchResults, { payload }) => {
      return elementSearchResults.setBatchSelectedItemsIds(payload);
    },
    [addIdToBatchSelectedItemsAction]: (elementSearchResults, { payload }) => {
      return elementSearchResults.addIdToBatchSelectedItemsIds(payload);
    },
    [removeIdFromBatchSelectedItemsAction]: (
      elementSearchResults,
      { payload },
    ) => {
      return elementSearchResults.removeIdFromBatchSelectedItems(payload);
    },
  },
  new ElementSearchResults(),
);
