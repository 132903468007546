import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import TickmarkAttachEntry from '../annotations/tickmark-entry/tickmark-entry-with-attach-component';
import TickmarkList from 'models/api/tickmark-list-api-model';
import Button from 'components/common/button-component';
import { ReactComponent as Plus } from 'icons/plus.svg';
import { MODAL_CLOSE_ICON_ID } from 'components/common/modal-component';
const TICKMARK_SEARCH_BLOCK = 'tickmark-search-results';

const TickmarkSearchResults = ({
  tickmarkList,
  onAddTickmark,
  search,
  disabled,
  wrapperRef,
  isOpen,
  onClickOutside,
  onCreate,
  showCreateButton = false,
}) => {
  const resultRef = useRef();

  const style = wrapperRef
    ? { top: wrapperRef.getBoundingClientRect().height }
    : { position: 'relative', height: '150px' };

  useEffect(() => {
    const onMousedownEvent = (event) => {
      if (
        resultRef.current &&
        !resultRef.current.contains(event.target) &&
        event.target.id !== MODAL_CLOSE_ICON_ID
      ) {
        //pass event to callback so that if someone has to restrict the invoke based on event target they can implement it inside callback.
        onClickOutside(event);
      }
    };
    document.addEventListener('mousedown', onMousedownEvent, false);
    return () => {
      document.removeEventListener('mousedown', onMousedownEvent, false);
    };
  }, [onClickOutside, resultRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={TICKMARK_SEARCH_BLOCK} style={style} ref={resultRef}>
      <ConditionalRender dependencies={[tickmarkList]}>
        {tickmarkList.hasTickmarks() ? (
          tickmarkList.tickmarks.map((tickmark) => (
            <TickmarkAttachEntry
              key={tickmark.ordinal}
              tickmark={tickmark}
              onAddTickmark={onAddTickmark}
              disabled={isFunction(disabled) ? disabled(tickmark) : disabled}
            />
          ))
        ) : (
          <div>
            <FormattedMessage
              id="element-tickmark-panel.attach-section.search.no-results"
              values={{ search }}
            />
          </div>
        )}
        {showCreateButton && (
          <Button.IconButton
            id={`${TICKMARK_SEARCH_BLOCK}__create-button`}
            className={`${TICKMARK_SEARCH_BLOCK}__create-button`}
            onClick={onCreate}
            Icon={Plus}
            disabled={tickmarkList.hasTickmarks()}
          >
            <FormattedMessage id="common.create" />
          </Button.IconButton>
        )}
      </ConditionalRender>
    </div>
  );
};

TickmarkSearchResults.propTypes = {
  /** List of tickmark search results */
  tickmarkList: PropTypes.instanceOf(TickmarkList).isRequired,
  /** Action fired when the add button is clicked */
  onAddTickmark: PropTypes.func.isRequired,
  /** Search  text */
  search: PropTypes.string.isRequired,
  /** boolean or func to disable the button */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  /** Ref to wrapper component */
  wrapperRef: PropTypes.object,
  /** If search results should be rendered */
  isOpen: PropTypes.bool.isRequired,
  /** Function fired when user clicks outside wrapper */
  onClickOutside: PropTypes.func.isRequired,
  /**If create button should be rendered just below search result */
  showCreateButton: PropTypes.bool,
};

export default memo(TickmarkSearchResults);
