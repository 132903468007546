import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import ComfortLetter from 'models/data/comfort-letter-model';
import ElementDetails from 'models/api/element-details-api-model';
import { cloneDeep } from 'lodash';
export default class ComfortLetterList extends ApiModel({
  data: {
    comfortLetters: [],
    elementsMap: {}, // To be used for comfort letter toolkit element list
  },
}) {
  get comfortLetters() {
    if (this.hasComfortLetters()) {
      return this.data.comfortLetters;
    }
    return [];
  }

  get elementsMap() {
    if (this.hasElementsMap()) {
      return this.data.elementsMap;
    }
    return [];
  }

  processResponse({ response }) {
    return {
      data: {
        comfortLetters: response.data.result.map((comfortLetter) =>
          new ComfortLetter().convertApiResponsetoComfortLetterModelFormat(
            comfortLetter,
          ),
        ),
        elementsMap: this.data.elementsMap,
      },
    };
  }

  hasComfortLetters() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.comfortLetters) &&
      this.data.comfortLetters.length > 0
    );
  }

  hasElementsMap() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.elementsMap) &&
      Object.keys(this.data.elementsMap).length > 0
    );
  }

  returnTopNComfortLetters(noOfComfortLetterToReturn) {
    return this.mergeData({
      comfortLetters: this.comfortLetters.slice(0, noOfComfortLetterToReturn),
    });
  }

  setElementsForComfortLetterId({ elementsMap, comfortLetterId }) {
    let newElementMap = cloneDeep(this.data.elementsMap);
    newElementMap[comfortLetterId] = elementsMap.map((element) => {
      return new ElementDetails().setLoaded({
        response: { data: { result: element } },
      });
    });
    return this.mergeData({
      comfortLetters: this.data.comfortLetters,
      elementsMap: newElementMap,
    });
  }

  getElementsByComfortLetterId(id) {
    if (this.data && Object.keys(this.data.elementsMap).length > 0) {
      return this.data.elementsMap[id];
    }
    return [];
  }

  getElementsCountByComfortLetterId(id) {
    if (this.hasComfortLetterAttachedElements(id)) {
      return this.data.elementsMap[id].length;
    }
    return 0;
  }

  hasComfortLetterAttachedElements(id) {
    return (
      this.data &&
      this.data.elementsMap &&
      this.data.elementsMap[id] &&
      this.data.elementsMap[id].length > 0
    );
  }

  removeElementFromComfortLetterElementsMapWithSocketPayload(payload) {
    let elementsToRemoveSet = new Set();
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      elementsToRemoveSet.add(element.id);
    }
    let clonedElementsMap = cloneDeep(this.data.elementsMap);
    const comfortLetterIdArray = Object.keys(clonedElementsMap);

    // this part updates the elements map
    for (let index = 0; index < comfortLetterIdArray.length; index++) {
      const comfortLetterId = comfortLetterIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[comfortLetterId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementsToRemoveSet.has(elementId)) {
          clonedElementsMap[comfortLetterId].splice(j, 1);
        }
      }
    }
    return this.mergeData({
      elementsMap: clonedElementsMap,
    });
  }

  unlinkElementFromElementsMapAndUpdateCount({
    elementId,
    comfortLetterId,
    shouldUpdateCount = true,
  }) {
    comfortLetterId = parseInt(comfortLetterId);
    elementId = parseInt(elementId);
    if (!Number.isInteger(comfortLetterId) || !Number.isInteger(elementId))
      return this;

    let clonedElementsMap = cloneDeep(this.data.elementsMap);
    let clonedComfortLetters = cloneDeep(this.comfortLetters);
    if (comfortLetterId in clonedElementsMap) {
      const elementIndex = clonedElementsMap[comfortLetterId].find(
        (element) => element.id === elementId,
      );
      clonedElementsMap[comfortLetterId].splice(
        clonedElementsMap[comfortLetterId].indexOf(elementIndex),
        1,
      );
    }
    const comfortLetterIndex =
      shouldUpdateCount &&
      clonedComfortLetters.findIndex(
        (cmfrtLetter) => cmfrtLetter.comfortLetterId === comfortLetterId,
      );
    if (
      shouldUpdateCount &&
      comfortLetterIndex !== -1 &&
      Number.isInteger(clonedComfortLetters[comfortLetterIndex].elementCount) &&
      clonedComfortLetters[comfortLetterIndex].elementCount > 0
    ) {
      clonedComfortLetters[comfortLetterIndex].elementCount--;
    }

    return this.mergeData({
      elementsMap: clonedElementsMap,
      comfortLetters: clonedComfortLetters,
    });
  }

  linkElementFromElementsMapAndUpdateCount({
    elementDetails,
    comfortLetterId,
    shouldUpdateCount = true,
  }) {
    comfortLetterId = parseInt(comfortLetterId);
    if (!Number.isInteger(comfortLetterId)) return this;

    let clonedElementsMap = cloneDeep(this.data.elementsMap);
    let clonedComfortLetters = cloneDeep(this.comfortLetters);

    const elementIndex =
      clonedElementsMap &&
      clonedElementsMap[comfortLetterId] &&
      clonedElementsMap[comfortLetterId].findIndex(
        (element) => element.id === elementDetails.id,
      );

    const elementToInsert = new ElementDetails().setLoaded({
      response: { data: { result: elementDetails.data } },
    });
    if (!(Number.isInteger(elementIndex) && elementIndex !== -1)) {
      if (comfortLetterId in clonedElementsMap) {
        clonedElementsMap[comfortLetterId].push(elementToInsert);
        clonedElementsMap[comfortLetterId].sort((a, b) => a.id - b.id);
      } else {
        clonedElementsMap[comfortLetterId] = [elementToInsert];
      }
    }
    const comfortLetterIndex =
      shouldUpdateCount &&
      clonedComfortLetters.findIndex(
        (cmfrtLetter) => cmfrtLetter.comfortLetterId === comfortLetterId,
      );
    if (
      shouldUpdateCount &&
      comfortLetterIndex !== -1 &&
      Number.isInteger(clonedComfortLetters[comfortLetterIndex].elementCount)
    ) {
      clonedComfortLetters[comfortLetterIndex].elementCount++;
    }

    return this.mergeData({
      elementsMap: clonedElementsMap,
      comfortLetters: clonedComfortLetters,
    });
  }

  getFilteredComfortLetters(term) {
    return this.data.comfortLetters.filter(
      (comfortLetter) =>
        comfortLetter.customLabel.toLowerCase().indexOf(term.toLowerCase()) !==
          -1 ||
        comfortLetter.description.toLowerCase().indexOf(term.toLowerCase()) !==
          -1,
    );
  }

  // This function is a single point where we can 'attach an element to a comfort letter' OR 'detach an element'
  // or 'modify an element attached to comfort letter'.
  // This function might look a little bulky but it saves us from some additional socket notification(similar to
  // Tickkmar_linked, Tickmark_unlinked etc) and an api calls to BE. Conditions for attach, detach and modify will
  //be understood as you go through below code along with code comments.
  /**
   * @param {Array} payload The array of elements which have been impacted with the bulk or any similar action
   * @returns {ThisType<ComfortLetterList>}
   */
  updateComfortLetterElementsMapFromArray(payload) {
    // Save the current instance in a variable. Any modification henceforth to the instance will reflect here.
    let comfortLetterListDetails = this;

    // Structure : {elementId1: elementDetails, elementId2:elementDetails}
    // This will allow us to fetch elementDetails corresponding to an element without finding the index position of that element.
    let payloadElementsMapObject = {};

    //list of comfort letters in current instance
    let comfortLetterList = cloneDeep(comfortLetterListDetails.comfortLetters);

    //Structure : {comfortLetterId1:comfortLetterDetails, comfortletterId2: comfortLetterDetails}
    // This will allow us to fetch comfort letter details corresponding to an id without finding the index position of comfort letter in a array
    let comfortLetterToLabelMap = {};

    for (let index = 0; index < comfortLetterList.length; index++) {
      comfortLetterToLabelMap[comfortLetterList[index].comfortLetterId] =
        comfortLetterList[index].customLabel;
    }

    for (let i = 0; i < payload.length; i++) {
      payloadElementsMapObject = {
        ...payloadElementsMapObject,
        [payload[i].id]: payload[i],
      };
    }

    let clonedElementsMap = cloneDeep(
      comfortLetterListDetails.data.elementsMap,
    );

    const comfortLetterIdArray = Object.keys(clonedElementsMap);

    // iterate over a list of comfort letters that is present in elements map (current instance).
    // Hence it will modify only those comfort letters for which elements map is not empty OR
    // you can interpret it as - for which comfort letter element list is expanded in toolkit
    for (let index = 0; index < comfortLetterIdArray.length; index++) {
      const comfortLetterId = comfortLetterIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[comfortLetterId]);

      // iterate over each element present in payload and check if that element requires -
      // modification(new annotation like tickmark, workpaper added) OR detachment (comfort letter is detached)
      // OR attachment (new comfort letter attached)
      for (let j = 0; j < Object.keys(payloadElementsMapObject).length; j++) {
        // elementId of jth element in payload
        const elementId = Object.keys(payloadElementsMapObject)[j];

        // load the element details of jth element in payload and wrap it with our model
        // we will use it incase we want to modify the element details or link the new element with our comfort letter.
        const element = new ElementDetails().setLoaded({
          response: {
            data: { result: { ...payloadElementsMapObject[elementId] } },
          },
        });

        // for a particular comfort letter id we have list of elements attached to it.
        // Here, we need to find the index of element in the list which matched with elementId in payload.
        const indexOfElementToUpdate =
          Array.isArray(elementsArray) &&
          elementsArray.findIndex(
            (element) => element.id === parseInt(elementId),
          );

        // if the element in payload is found in the array of elements currently attached to comfort letter,
        // then it might have come (in payload) for either modification or detachment.
        // More story about attach or detach decison below.
        if (
          Number.isInteger(indexOfElementToUpdate) &&
          indexOfElementToUpdate !== -1
        ) {
          // if the element in payload includes the comfort letter then it must have come for modification or else
          // for detachment
          if (
            element &&
            Array.isArray(element.comfortLetterLabelList) &&
            element.comfortLetterLabelList.includes(
              comfortLetterToLabelMap[comfortLetterId],
            )
          ) {
            const elementToUpdate =
              clonedElementsMap[comfortLetterId][indexOfElementToUpdate];
            clonedElementsMap[comfortLetterId][indexOfElementToUpdate] =
              new ElementDetails().setLoaded({
                response: {
                  data: {
                    result: {
                      ...elementToUpdate.data,
                      ...element.data,
                    },
                  },
                },
              });
          } else {
            comfortLetterListDetails =
              comfortLetterListDetails.unlinkElementFromElementsMapAndUpdateCount(
                {
                  elementId: element.id,
                  comfortLetterId,
                  shouldUpdateCount: false, // updation of count will be done using api call, since here we are only concerned about updating element list
                },
              );
            clonedElementsMap = comfortLetterListDetails.elementsMap;
          }
        }
        // if the element in payload does not already exists in the list then it might require attachment.
        // But before attaching check if that element (in payload) also contains the concerned comfort letter.
        else if (
          element &&
          Array.isArray(element.comfortLetterLabelList) &&
          element.comfortLetterLabelList.includes(
            comfortLetterToLabelMap[comfortLetterId],
          )
        ) {
          comfortLetterListDetails =
            comfortLetterListDetails.linkElementFromElementsMapAndUpdateCount({
              elementDetails: element,
              comfortLetterId,
              shouldUpdateCount: false, // updation of count will be done using api call, since here we are only concerned about updating element list
            });
          clonedElementsMap = comfortLetterListDetails.elementsMap;
        }
      }
    }
    return comfortLetterListDetails.mergeData({
      elementsMap: clonedElementsMap,
    });
  }
}
