import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAndUpdateLocaleMessage } from 'store/actions/locale-actions';

import enMessages from 'i18n/en.json';
import frMessages from 'i18n/fr.json';
import { getMessagesForLocale } from 'constants/common/feature-common-utils';

const mapStateToProps = ({ ui: { locale } }) => ({
  locale,
});

const mapDispatchToProps = {
  fetchAndUpdateLocaleMessage,
};

export const messages = {
  en: enMessages,
  fr: frMessages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ locale, children, fetchAndUpdateLocaleMessage }) => {
  useEffect(() => {
    fetchAndUpdateLocaleMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const messages = getMessagesForLocale(locale);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={messages && messages.data ? messages.data : enMessages}
    >
      {children}
    </IntlProvider>
  );
});
