import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class StatementSummaryNotes extends ApiModel({
  data: {
    notes: 0,
  },
}) {
  get notes() {
    if (isNullOrUndefined(this.data)) {
      return 0;
    }
    return this.data.notes;
  }

  processResponse({ response }) {
    return {
      data: { notes: response.data.result },
    };
  }

  updateSummaryFromWebsocket(payload) {
    return this.mergeData({
      notes: this.data.notes + payload,
    });
  }
}
