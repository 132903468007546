import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Plus } from 'icons/plus.svg';
import Button from 'components/common/button-component';
import SearchComponent from '../../../common/search-component';
import { ComfortLetterSearchResults } from './_element-comfort-panel-search-results-component';
import { useState } from 'react';
import { useRef } from 'react';
import ComfortLetterList from 'models/api/comfort-letter-list-api-model';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from '../../../../constants/util/search-utility';

export const ATTACH_COMFORT_LETTER_BLOCK = 'attach-comfort-letter';
export const ATTACH_COMFORT_LETTER_ID_BLOCK = 'attach-comfort-letter-id';

export const MIN_CHAR_LENGTH_TO_SEARCH = 1;
export const MAX_CHAR_LENGTH_TO_SEARCH = 10;

export const AttachComfortLetter = ({
  onCreate,
  onAttach,
  onSearchComfortLetter,
  disabled,
  selectedComfortLetterList,
  readOnly,
}) => {
  const [search, setSearch] = useState('');
  const searchComponentRef = useRef();
  const [comfortLetters, setComfortLetters] = useState(new ComfortLetterList());

  const _clearSearch = () => {
    setComfortLetters(new ComfortLetterList());
    setSearch('');
  };

  const _shouldOpenMenu = isSearchCharLengthValid(
    search,
    MIN_CHAR_LENGTH_TO_SEARCH,
  );

  const _onClickOutside = (e) => {
    if (
      !(
        (searchComponentRef &&
          searchComponentRef.current &&
          searchComponentRef.current.contains(e.target)) ||
        //cancel button
        e.target.id === `${ATTACH_COMFORT_LETTER_BLOCK}-secondary-button`
      )
    ) {
      _clearSearch();
    }
  };

  const _fetchSuggestedComfortLetters = async ({ search }) => {
    setComfortLetters(comfortLetters.setLoading());
    try {
      let response = await onSearchComfortLetter({
        searchTerm: search,
      });
      setComfortLetters(
        comfortLetters.setLoaded({ response }).returnTopNComfortLetters(10),
      );
    } catch (error) {
      setComfortLetters((comfortLetterPrev) =>
        comfortLetterPrev.setError(error),
      );
    }
  };

  const debouncedSearch = React.useCallback(
    debounceSearch(_fetchSuggestedComfortLetters),
  );

  const _searchComfortLetters = (val) => {
    setSearch(val);
    if (isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH)) {
      debouncedSearch({ search: val });
    }
  };

  const _handleAttachComfortLetter = async (comfortLetter) => {
    try {
      await onAttach({ comfortLetter });
    } catch (error) {
      setComfortLetters(comfortLetters.setError(error));
    }
    _clearSearch();
  };

  return (
    <div className={`${ATTACH_COMFORT_LETTER_BLOCK}`}>
      { !readOnly && <Button.IconButton
        id={`${ATTACH_COMFORT_LETTER_ID_BLOCK}__add-link`}
        className={`${ATTACH_COMFORT_LETTER_BLOCK}__create-button`}
        onClick={onCreate}
        Icon={Plus}
        disabled={disabled}
      >
        <FormattedMessage id="common.create" />
      </Button.IconButton>}

      <div
        ref={searchComponentRef}
        className={`${ATTACH_COMFORT_LETTER_BLOCK}-search-wrapper`}
      >
        <SearchComponent
          id={`${ATTACH_COMFORT_LETTER_ID_BLOCK}-search`}
          className={`${ATTACH_COMFORT_LETTER_BLOCK}__search`}
          placeholder="comfort-letter-panel.attach-existing.search.placeholder"
          onChange={_searchComfortLetters}
          value={search}
          isValid={true}
          disableAutoComplete
          label="comfort-letter-panel.attach-existing.search.label"
          onClear={_clearSearch}
          minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
          showSearchButton
          maxLength={MAX_CHAR_LENGTH_TO_SEARCH}
        />
        <ComfortLetterSearchResults
          comfortLetterList={comfortLetters}
          search={search}
          onAddComfortLetter={_handleAttachComfortLetter}
          selectedComfortLetterList={selectedComfortLetterList}
          disabled={disabled}
          isOpen={_shouldOpenMenu}
          onClickOutside={(e) => _onClickOutside(e)}
        />
      </div>
    </div>
  );
};

AttachComfortLetter.propTypes = {
  /**function fired to show create comfort letter modal */
  onCreate: PropTypes.func.isRequired,
  /**action method fired to attach existing comfort letter */
  onAttach: PropTypes.func.isRequired,
  /**function fired to fetch comfort letter list */
  onSearchComfortLetter: PropTypes.func.isRequired,
  /**boolean value to indicate if we want to disable attach comfort letter button */
  disabled: PropTypes.bool,
};

export default AttachComfortLetter;
