import { createAction } from 'redux-actions';

export const setCurrentStatementListTab = createAction(
  'SET_CURRENT_STATEMENT_LIST_TAB',
);

export const setStatementListSort = createAction('SET_STATEMENT_LIST_SORT');

export const initStatementListFilters = createAction(
  'INIT_STATEMENT_LIST_FILTERS',
);
