import BaseModel from 'models/base-model';

export default class WorkPaper extends BaseModel({
  revisionId: null,
  elementId: null,
  referenceNumber: '',
  referenceName: '',
  id: null,
  workpaperRefCreatedBy: null,
  elementWpRefCreatedBy: null,
  workpaperRefId: null,
}) {}
