import { handleActions } from 'redux-actions';
import SectionInViewMap from 'models/data/section-in-view-map-model';
import {
  setLeftSectionInViewStatementContent,
  clearLeftSectionInViewStatementContent,
} from 'store/actions/left-statement-section-content-actions';
export default handleActions(
  {
    [setLeftSectionInViewStatementContent]: (map, { payload }) => {
      return map.setSectionInView(payload);
    },
    [clearLeftSectionInViewStatementContent]: (map, { payload }) => {
      return map.clearSectionInView(payload);
    },
  },
  new SectionInViewMap(),
);
