import { handleActions } from 'redux-actions';
import CancelModal from 'models/utils/common/panels-cancel-modal-model';

import {
  toggleFormulaCancelModalAction,
  toggleInternalReferenceCancelModalAction,
  openCancelModalAfterModalConfirmAction,
  storeSelectedNoteIdAction,
  storeSelectedNotePseudoIdAction,
  openSectionPanelAfterCancelConfirmAction,
  storeSelectedSectionIdAction,
} from 'store/actions/panel-cancel-modal-actions';

export default handleActions(
  {
    [toggleFormulaCancelModalAction]: (cancel, { payload }) => {
      return cancel.toggleFormulaCancelModal(payload);
    },
    [toggleInternalReferenceCancelModalAction]: (cancel, { payload }) => {
      return cancel.toggleInternalReferenceCancelModal(payload);
    },
    [openCancelModalAfterModalConfirmAction]: (cancel, { payload }) => {
      return cancel.openNotePanelAfterCancelConfirm(payload);
    },
    [storeSelectedNoteIdAction]: (cancel, { payload }) => {
      return cancel.setSelectedNoteId(payload);
    },
    [storeSelectedNotePseudoIdAction]: (cancel, { payload }) => {
      return cancel.setSelectedNotePseudoId(payload);
    },
    [storeSelectedSectionIdAction]: (cancel, { payload }) => {
      return cancel.setSelectedSectionId(payload);
    },
    [openSectionPanelAfterCancelConfirmAction]: (cancel, { payload }) => {
      return cancel.openSectionPanelAfterCancelConfirm(payload);
    },
  },
  new CancelModal(),
);
