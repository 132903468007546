import { handleActions } from 'redux-actions';
import {
  setDownloadData,
  setDownloadListData,
  setDownloadDataLoaded,
  setDownloadDataLoading,
  setDownloadDataError,
  clearDownloadData,
  updateDownloadStatus,
  setReportIdLoading,
  setReportIdLoaded,
  setReportIdError,
  updateFlagUserDownloadedReportStatus,
} from 'store/actions/toolkit-export-panel-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import ToolkitDownloadListData from 'models/api/toolkit-export-download-list-model';

export default handleActions(
  {
    [setDownloadData]: (downloadReportData, { payload }) => {
      return downloadReportData.setDownloadData(payload);
    },
    [setDownloadListData]: (downloadReportData, { payload }) => {
      return downloadReportData.setDownloadListData(payload);
    },
    [updateDownloadStatus]: (downloadReportData, { payload }) => {
      return downloadReportData.updateDownloadStatus(payload);
    },
    [updateFlagUserDownloadedReportStatus]: (
      downloadReportData,
      { payload },
    ) => {
      return downloadReportData.updateFlagUserDownloadedReportStatus(payload);
    },
    [setDownloadDataLoaded]: (downloadReportData) => {
      return downloadReportData.setLoaded();
    },
    [setDownloadDataLoading]: (downloadReportData) => {
      return downloadReportData.setLoading();
    },
    [setDownloadDataError]: (downloadReportData, { payload }) => {
      return downloadReportData.setError(payload);
    },
    [setReportIdLoading]: (downloadReportData, { payload }) => {
      return downloadReportData.setReportIdLoading(payload);
    },
    [setReportIdLoaded]: (downloadReportData, { payload }) => {
      return downloadReportData.setReportIdLoaded(payload);
    },
    [setReportIdError]: (downloadReportData, { payload }) => {
      return downloadReportData.setReportIdError(payload);
    },
    [clearDownloadData]: (downloadReportData) => {
      return downloadReportData.clear();
    },
    [clearStatementPageStoreAction]: (downloadReportData) => {
      return downloadReportData.clear();
    },
  },
  new ToolkitDownloadListData(),
);
