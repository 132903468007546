import ApiModel from 'models/api-model';
import cloneDeep from 'lodash.clonedeep';
import uniqBy from 'lodash.uniqby';

export default class SectionAssignmentsList extends ApiModel({
  data: {
    sectionAssignments: [],
    assignedSectionsToUser: {},
  },
}) {
  get sectionAssignments() {
    if (this.data && this.data.sectionAssignments) {
      return this.data.sectionAssignments;
    }
    return null;
  }

  get assignedSectionsToUser() {
    if (this.data && this.data.assignedSectionsToUser) {
      return this.data.assignedSectionsToUser;
    }
    return {};
  }

  processResponse({ response, currentUserId }) {
    let assignedSectionsToUserArray = {};
    for (let i = 0; i < response.data.result.length; i++) {
      let assignment = response.data.result[i];
      if (assignment.userId === currentUserId) {
        assignedSectionsToUserArray[assignment.sectionId] = true;
      }
    }
    return {
      data: {
        sectionAssignments: response.data.result,
        assignedSectionsToUser: assignedSectionsToUserArray,
      },
    };
  }
  /**This is used for updating section assignment details only for a single section id */
  /**It will replace the old section assignment details for a particular section Id with new
   * section assignment details.
   */
  updateSectionAssignmentListFromSocket({
    sectionAssignmentListPayload,
    sectionId,
    currentUserId,
  }) {
    let newsectionAssignments = [];
    let userIDsInPayload = [];
    let assignedSectionsToUserCopy = cloneDeep(this.assignedSectionsToUser);

    // since the update is only for particular sectionId (in parameter) so extact all data which
    // do not belongs to that sectionId(in the parameter) and append it in newsectionAssignments
    // under construction;
    this.sectionAssignments.length > 0 &&
      this.sectionAssignments.forEach((sectionAssign) => {
        sectionAssign.sectionId !== sectionId &&
          newsectionAssignments.push(sectionAssign);
      });
    //in case of delete or an unassign-all event,remove existing assigned section ID for a user
    if (sectionAssignmentListPayload.length === 0) {
      delete assignedSectionsToUserCopy[sectionId];
    } else {
      for (let i = 0; i < sectionAssignmentListPayload.length; i++) {
        newsectionAssignments.push(sectionAssignmentListPayload[i]);
        userIDsInPayload.push(sectionAssignmentListPayload[i].userId);
      }

      //This code snippet handles assign/unassign scenario based on the userIDs and sectionId in the payload
      if (userIDsInPayload.includes(currentUserId)) {
        assignedSectionsToUserCopy[sectionId] = true;
      } else if (
        assignedSectionsToUserCopy[sectionId] &&
        !userIDsInPayload.includes(currentUserId)
      ) {
        delete assignedSectionsToUserCopy[sectionId];
      }
    }
    return this.merge({
      data: {
        sectionAssignments: newsectionAssignments,
        assignedSectionsToUser: assignedSectionsToUserCopy,
      },
    });
  }
  updateSectionAssignmentListForBulkAssignFromSocket({
    sectionAssignmentListPayload,
    currentUserId,
  }) {
    // add section assignments from payload and remove duplicate assignments if there are any
    const sectionAssignments = uniqBy(
      [...this.sectionAssignments, ...sectionAssignmentListPayload],
      (v) => [v.revisionId, v.sectionId, v.userId].join(),
    );

    const assignedSectionsToUser = sectionAssignments
      .filter((assignment) => assignment.userId === currentUserId)
      .reduce(
        (arr, assigment) => ({ ...arr, [assigment.sectionId]: true }),
        {},
      );

    return this.merge({
      data: {
        sectionAssignments,
        assignedSectionsToUser,
      },
    });
  }

  updateSectionAssignmentListForBulkUnAssignFromSocket({
    sectionAssignmentListPayload,
    currentUserId,
  }) {
    //unassign all - remove sectionAssignments whose section ids are passed in payload.
    const sectionAssignments = this.sectionAssignments.filter(
      (assignment) =>
        !sectionAssignmentListPayload.includes(assignment.sectionId),
    );

    const assignedSectionsToUser = sectionAssignments
      .filter((assignment) => assignment.userId === currentUserId)
      .reduce(
        (arr, assigment) => ({ ...arr, [assigment.sectionId]: true }),
        {},
      );

    return this.merge({
      data: {
        sectionAssignments,
        assignedSectionsToUser,
      },
    });
  }
}
