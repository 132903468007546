import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Information } from 'icons/info.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import classnames from 'classnames';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import shortid from 'shortid';

const BANNER_INFO_ICON_SIZE = '18px';
const COPY_FORMULA_BANNER_BLOCK = 'copy-formula-banner';
const COPY_FORMULA_BANNER_ID_BLOCK = 'copy-formula-banner-id';

const CopyFormulaBanner = (props) => (
  <div className={classnames(`${COPY_FORMULA_BANNER_BLOCK}`)}>
    <div>
      <Tooltip
        isNotInternationalized={true}
        {...new TooltipOptions({
          text: (
            <FormattedMessage
              id="selected-statement-copy-formula-banner-drag-info"
              values={{
                br: (
                  <br
                    key={`${COPY_FORMULA_BANNER_BLOCK}__drag-related-info-brTag-${shortid.generate()}`}
                  />
                ),
              }}
            />
          ),
          id: `${COPY_FORMULA_BANNER_ID_BLOCK}-tooltip-extra-info-drag-related`,
          position: 'bottom',
        })}
      >
        <Information
          name={'information'}
          className={`${COPY_FORMULA_BANNER_BLOCK}__info_icon`}
          width={BANNER_INFO_ICON_SIZE}
          height={BANNER_INFO_ICON_SIZE}
        />
      </Tooltip>
      <strong>
        <FormattedMessage
          values={{
            numberOfSelections: props.numberOfElementsSelectedForCopy,
            br: (
              <br
                key={`${COPY_FORMULA_BANNER_BLOCK}__center-panel-banner-icon-brTag`}
              />
            ),
          }}
          id="element-panel.annotation.content.formula.copy-formula-element-selected"
        />
      </strong>

      <FormattedMessage id="selected-statement.copy.formula-banner" />
    </div>
    <Button
      id={`${COPY_FORMULA_BANNER_ID_BLOCK}-formula-cancel-button`}
      className={`${COPY_FORMULA_BANNER_BLOCK}__formula-cancel-button`}
      type={BUTTON_TYPES.tertiary}
      disabled={false} // Will never be disabled. User has an option to abort operation anytime
      onClick={() => props.cancelCopyFormula()}
    >
      <FormattedMessage
        id={
          'element-panel.annotation.content.formula.copy-formula-abort-copy-formula'
        }
      />
    </Button>
    {props.numberOfElementsSelectedForCopy > 0 && (
      <Button
        id={`${COPY_FORMULA_BANNER_ID_BLOCK}-formula-paste-button`}
        className={`${COPY_FORMULA_BANNER_BLOCK}__formula-paste-button`}
        type={BUTTON_TYPES.primary}
        disabled={!props.numberOfElementsSelectedForCopy}
        onClick={() => props.stopCopyFormula()}
      >
        <FormattedMessage
          id={
            'element-panel.annotation.content.formula.copy-formula-stop-pasting'
          }
        />
      </Button>
    )}
  </div>
);

export default CopyFormulaBanner;
