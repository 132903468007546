import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';

export const TERTIARY_UNFLAGGED_ID_BLOCK = 'tertiary-unflagged-filters-id';

const ElementFiltersUnFlaggedTertiary = ({
  filters,
  setFilters,
  className,
}) => {
  return (
    <div className={className}>
      <Checkbox
        id={`${TERTIARY_UNFLAGGED_ID_BLOCK}-flag`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.flag.flagOverride"
        checked={filters.flagOverride}
        onChange={() =>
          setFilters(filters.setFlagOverride(!filters.flagOverride))
        }
      />
    </div>
  );
};

ElementFiltersUnFlaggedTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersUnFlaggedTertiary;
