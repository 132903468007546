import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import { EntityList } from 'models/api/entity-list-model';

export const ENTITY_FILTERS_ID_BLOCK = 'element-filters-entity-id';

const ElementFiltersEntity = ({
  filters,
  setFilters,
  className,
  entityList,
}) => {
  return (
    <div className={className}>
      <ConditionalRender dependencies={[entityList]}>
        {entityList.entities.map(({ entity }) => (
          <Checkbox
            key={entity}
            id={`${ENTITY_FILTERS_ID_BLOCK}-${entity}`}
            isNotIntl
            label={entity}
            checked={filters.isEntitySelected(entity)}
            onChange={() => setFilters(filters.selectEntity(entity))}
          />
        ))}
      </ConditionalRender>
    </div>
  );
};

ElementFiltersEntity.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Entity list api model fetched from backend on page load, used to populate the checkbox options */
  entityList: PropTypes.instanceOf(EntityList).isRequired,
};

const mapStateToProps = ({
  data: {
    elementPanel: { entityList },
  },
}) => ({
  entityList,
});

export { ElementFiltersEntity };
export default connect(mapStateToProps, null)(ElementFiltersEntity);
