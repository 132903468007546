import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const LEARN_MORE_BLOCK = 'learn-more-modal';
const LEARN_MORE_ID_BLOCK = 'learn-more-modal-id';

export const LearnMoreAboutRolesModal = ({ onClose }) => (
  <Modal
    id={`${LEARN_MORE_ID_BLOCK}`}
    title={'project-creation.manage-users.learn-more.modal.title'}
    primaryModalButton={
      new ModalButton({
        text: 'common.close',
        onClick: onClose,
      })
    }
    onClose={onClose}
  >
    <dl className={`${LEARN_MORE_BLOCK}__list`}>
      <dt className={`${LEARN_MORE_BLOCK}__title`}>
        <FormattedMessage id={'common.user-roles.preparer-reviewer'} />
      </dt>
      <dd className={`${LEARN_MORE_BLOCK}__description`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.learn-more.modal.preparer-description'
          }
        />
      </dd>
      <dt className={`${LEARN_MORE_BLOCK}__title`}>
        <FormattedMessage id={'common.user-roles.engagement-owner'} />
      </dt>
      <dd className={`${LEARN_MORE_BLOCK}__description`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.learn-more.modal.engagement-owner-description'
          }
        />
      </dd>
      <dt className={`${LEARN_MORE_BLOCK}__title`}>
        <FormattedMessage id={'common.user-roles.observer'} />
      </dt>
      <dd className={`${LEARN_MORE_BLOCK}__description`}>
        <FormattedMessage
          id={
            'project-creation.manage-users.learn-more.modal.observer-description'
          }
        />
      </dd>
      <dt className={`${LEARN_MORE_BLOCK}__title`}>
        <FormattedMessage id={'common.user-roles.ppmd'} />
      </dt>
      <dd className={`${LEARN_MORE_BLOCK}__description`}>
        <FormattedMessage
          id={'project-creation.manage-users.learn-more.modal.ppmd-description'}
        />
      </dd>
    </dl>
  </Modal>
);

LearnMoreAboutRolesModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
};
