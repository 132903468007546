import { handleActions } from 'redux-actions';
import {
  setTableMatrix,
  clearTableMatrix,
} from 'store/actions/copy-formula-actions';

export default handleActions(
  {
    [setTableMatrix]: (tableMatrix, { payload }) => {
      return payload;
    },
    [clearTableMatrix]: (tableMatrix) => {
      return [];
    },
  },
  [],
);
