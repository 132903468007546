import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TextInput from 'components/common/text-input-component';
import Dropdown from 'components/common/dropdown-component';
import ProjectForm from 'models/form/project-form-model';
import {
  NON_AUDIT_REASON_OPTIONS,
  getMatEngagementYears,
} from 'constants/feature/project-creation-constants';

export const NON_AUDIT_INPUT_BLOCK = 'non-audit-textarea';
const NON_AUDIT_INPUT_ID_BLOCK = 'non-audit-textarea-id';

const ProjectEditNonAuditInputArea = ({
  setClientName,
  setEngagementName,
  setEngagementFye,
  projectInProgress,
  setNonAuditReason,
}) => {
  return (
    <>
      <div className={`${NON_AUDIT_INPUT_BLOCK}__client-name`}>
        {projectInProgress.clientNameEdited ? (
          <TextInput
            id={`${NON_AUDIT_INPUT_ID_BLOCK}-client-name`}
            label={'project-creation.non-audit-form.client-name.label'}
            placeholder={
              'project-creation.non-audit-form.client-name.placeholder'
            }
            type={'text'}
            width={'50%'}
            onChange={setClientName}
            isValid={projectInProgress.isClientNameValid()}
            errorText={
              'project-creation-edit.client-name.text-input.error-text'
            }
            value={projectInProgress.clientName}
          />
        ) : (
          <div
            title={
              !projectInProgress.isClientNameValid() ? (
                <FormattedMessage id="client-name.not-allowed.tooltip" />
              ) : (
                ''
              )
            }
          >
            <TextInput
              id={`${NON_AUDIT_INPUT_ID_BLOCK}-client-name`}
              label={'project-creation.non-audit-form.client-name.label'}
              placeholder={
                'project-creation.non-audit-form.client-name.placeholder'
              }
              type={'text'}
              width={'50%'}
              onChange={setClientName}
              value={projectInProgress.clientName}
              isValid={true}
              disabled={!projectInProgress.isClientNameValid()}
            />
          </div>
        )}
      </div>
      <div className={`${NON_AUDIT_INPUT_BLOCK}__engagement-name`}>
        <TextInput
          id={`${NON_AUDIT_INPUT_ID_BLOCK}-engagement-name`}
          label={'project-creation.non-audit-form.engagement-name.label'}
          placeholder={
            'project-creation.non-audit-form.engagement-name.placeholder'
          }
          type={'text'}
          width={'50%'}
          onChange={setEngagementName}
          isValid={true}
          value={projectInProgress.engagementName}
        />
      </div>
      <div className={`${NON_AUDIT_INPUT_BLOCK}__dropdowns`}>
        <Dropdown
          id={`${NON_AUDIT_INPUT_BLOCK}__fiscal-year-dropdown`}
          label="project-creation.non-audit-form.fiscal-year.label"
          options={getMatEngagementYears()}
          value={projectInProgress.engagementFye}
          placeholder="common.placeholder.select"
          onSelectOption={setEngagementFye}
          width="15%"
        />
        <Dropdown
          id={`${NON_AUDIT_INPUT_BLOCK}__non-audit-reason-dropdown`}
          label="project-creation.non-audit-form.non-audit-reason.label"
          value={projectInProgress.nonAuditReason}
          options={NON_AUDIT_REASON_OPTIONS}
          placeholder="common.placeholder.select"
          onSelectOption={setNonAuditReason}
          width="20%"
        />
      </div>
    </>
  );
};

ProjectEditNonAuditInputArea.propTypes = {
  /** Function to set the client name */
  setClientName: PropTypes.func.isRequired,
  /** Function to set the engagement name */
  setEngagementName: PropTypes.func.isRequired,
  /** Function to set the fiscal year */
  setEngagementFye: PropTypes.func.isRequired,
  /** Object that contains working data for form before submission*/
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
};

export default ProjectEditNonAuditInputArea;
