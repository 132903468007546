import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  STATEMENT_NAV_TABS,
  STATEMENT_NAV_TABS_ARRAY,
} from 'constants/feature/statement-navigator-constants';

import TableOfContentsTreeView from './table-of-contents-treeview';
import { SectionTreeList } from 'models/api/section-tree-list-model';

import ConditionalRender from 'components/util/conditional-render-component';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import {
  onSectionClick,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import ContentTabContent from './statement-nav-content-search-results-component';
import ElementTabContent from './elements/statement-nav-elements-search-results-component';
import { selectElementFromElementSearch } from 'store/actions/statement-content-actions';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import ElementsSearchResults from 'models/api/statements-element-search-results-api-model';
import { searchElementsNextPage } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { setSelectedElementResult } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { setTOCExpandAll } from 'store/actions/TOC-actions';
import { toggleNavigationEditModeDispatch } from 'store/actions/navigation-edit-actions';
import { setNewSectionIdAction } from 'store/actions/section-tree-list-actions';
import { setHeadingAssignmentRefresh } from 'store/actions/section-assignment-heading-refresh-actions';
import { updateBulkSectionAssignmentsList } from 'store/actions/section-assignment-actions';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import SelectedStatement from 'models/api/selected-statement-model';
import ProjectApi from 'models/api/project-api-model';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import {
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  storeSelectedSectionIdAction,
  openSectionPanelAfterCancelConfirmAction,
} from 'store/actions/panel-cancel-modal-actions';

const StatementNavigatorBody = ({
  selectedTab,
  sections,
  onSectionClick,
  searchTerm,
  searchResultsContent,
  searchResultsElements,
  selectElementAction,
  contentSectionMap,
  onFilterClick,
  searchElementsNextPage,
  onSelectElementResult,
  onSelectContentResult,
  deallocateItems,
  searchElements,
  filters,
  isFilterApplied,
  setIsFilterApplied,
  setSectionAssigmentsFilterMode,
  setResultsFromAssignmentFilter,
  currentSectionIds,
  sectionIds,
  TOCExpandAll,
  navigationEditMode,
  selectedStatement,
  workflowsMap,
  sectionReview,
  left,
  sectionAssignmentsList,
  sectionAssignmentHeadingRefresh,
  revisionId,
  projectUsersList,
  selectedSection,
  selectedProject,
  socketHasBeenDisconnected,
  sectionIdList,
  selectModeId,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  storeSelectedSectionIdAction,
  openSectionPanelAfterCancelConfirmAction,
  toggleNavigationEditModeDispatch,
  setTOCExpandAll,
  setNewSectionIdAction,
  headingAssignmentRefreshAction,
  updateBulkSectionAssignmentsList,
}) => {
  const sectionClick = ({ sectionId }) => {
    deallocateItems();
    onSectionClick({
      sectionId: sectionId,
      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
      sectionClick: true,
    });
  };
  switch (selectedTab) {
    case STATEMENT_NAV_TABS.headings: {
      return (
        <ConditionalRender
          dependencies={[sections, sectionIdList, contentSectionMap]}
        >
          <TableOfContentsTreeView
            sectionTreeList={sections}
            onSectionClick={sectionClick}
            searchTerm={searchTerm}
            searchResultsContent={searchResultsContent}
            setSectionAssigmentsFilterMode={setSectionAssigmentsFilterMode}
            setResultsFromAssignmentFilter={setResultsFromAssignmentFilter}
            contentSectionMap={contentSectionMap}
            currentSectionIds={currentSectionIds}
            TOCExpandAll={TOCExpandAll}
            navigationEditMode={navigationEditMode}
            selectedStatement={selectedStatement}
            workflowsMap={workflowsMap}
            sectionReview={sectionReview}
            left={left}
            sectionAssignmentsList={sectionAssignmentsList}
            sectionAssignmentHeadingRefresh={sectionAssignmentHeadingRefresh}
            revisionId={revisionId}
            projectUsersList={projectUsersList}
            selectedSection={selectedSection}
            selectedProject={selectedProject}
            socketHasBeenDisconnected={socketHasBeenDisconnected}
            sectionIds={
              sectionIdList &&
              sectionIdList.data &&
              sectionIdList.data.sectionIds
            }
            selectModeId={selectModeId}
            toggleFormulaCancelModal={toggleFormulaCancelModal}
            toggleInternalReferenceCancelModal={
              toggleInternalReferenceCancelModal
            }
            storeSelectedSectionIdAction={storeSelectedSectionIdAction}
            openSectionPanelAfterCancelConfirmAction={
              openSectionPanelAfterCancelConfirmAction
            }
            toggleNavigationEditModeDispatch={toggleNavigationEditModeDispatch}
            setTOCExpandAll={setTOCExpandAll}
            setNewSectionIdAction={setNewSectionIdAction}
            headingAssignmentRefreshAction={headingAssignmentRefreshAction}
            updateBulkSectionAssignmentsList={updateBulkSectionAssignmentsList}
          />
        </ConditionalRender>
      );
    }
    case STATEMENT_NAV_TABS.elements: {
      return (
        <ElementTabContent
          searchTerm={searchTerm}
          searchResultsElement={searchResultsElements}
          selectElementAction={selectElementAction}
          contentSectionMap={contentSectionMap}
          onFilterClick={onFilterClick}
          searchElementsNextPage={searchElementsNextPage}
          setActiveEntry={onSelectElementResult}
          searchResultsContent={searchResultsContent}
          searchElements={searchElements}
          filters={filters}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
        />
      );
    }
    case STATEMENT_NAV_TABS.content: {
      return (
        <ContentTabContent
          searchTerm={searchTerm}
          searchResultsContent={searchResultsContent}
          setActiveEntry={onSelectContentResult}
        />
      );
    }
    default: {
      console.error('NO TAB SELECTED');
      return null;
    }
  }
};

StatementNavigatorBody.propTypes = {
  /** currently selected tab */
  selectedTab: PropTypes.oneOf(STATEMENT_NAV_TABS_ARRAY).isRequired,
  /** Treelist model of sections to display in headings tab */
  sections: PropTypes.instanceOf(SectionTreeList).isRequired,
  /** Function fired on click of section in Headings tab */
  onSectionClick: PropTypes.func.isRequired,
  /** Current search string */
  searchTerm: PropTypes.string.isRequired,
  /** Search results model of the content tab */
  searchResultsContent: PropTypes.instanceOf(ContentSearchResults).isRequired,
  /** Search results model of the elements tab */
  searchResultsElements: PropTypes.instanceOf(ElementsSearchResults).isRequired,
  /** Action fired for highlighting an element from the content panel on click*/
  selectElementAction: PropTypes.func.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** action fired when user clicks filter button in default message when no search or filters have been applied */
  onFilterClick: PropTypes.func.isRequired,
  /** Set an element search result as actively selected */
  onSelectElementResult: PropTypes.func.isRequired,
  /** Set a content search result as actively selected */
  onSelectContentResult: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /* function to execute the element filter search*/
  searchElements: PropTypes.func.isRequired,
  /** object containing the applied filters */
  filters: PropTypes.object.isRequired,
  /* value to know when a filter is applied */
  isFilterApplied: PropTypes.bool.isRequired,
  /* function to set value after filter is applied and batch selection is reset */
  setIsFilterApplied: PropTypes.func.isRequired,
  /* function to set mode when user filters on sections */
  setSectionAssigmentsFilterMode: PropTypes.func.isRequired,
  /* function to get the number of filtered sections */
  setResultsFromAssignmentFilter: PropTypes.func.isRequired,

  /** Tree list of section hirearchy */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /** Currently processing/failed statements */
  workflowsMap: PropTypes.instanceOf(WorkflowsMap),
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList),
  /** Indicates which left panel is open */
  left: PropTypes.string,
  /* action to set the new section value */
  setNewSectionIdAction: PropTypes.func,
  /* section assignment list model */
  sectionAssignmentsList: PropTypes.instanceOf(SectionAssignmentsList),
  /**Field to determine if the refresh button should be displayed or not */
  sectionAssignmentHeadingRefresh: PropTypes.bool,
  /**Redux Action to set the refresh button based on the heading update */
  headingAssignmentRefreshAction: PropTypes.func,
  /** the id of the selected revision */
  revisionId: PropTypes.number.isRequired,
  /**An object which contains projects users details and userID to username map */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList),
  /** Section from the section detail store */
  selectedSection: PropTypes.object,
  /** Action to update/fetch bulk assignments for a section */
  updateBulkSectionAssignmentsList: PropTypes.func,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement),
  /** Currently selected project id of the revision we are viewing */
  selectedProject: PropTypes.instanceOf(ProjectApi),
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
  /** the id of the selected section */
  selectModeId: PropTypes.number.isRequired,
  /**The action to open the formula cancel modal. */
  toggleFormulaCancelModal: PropTypes.func,
  /**The action to open the IR cancel modal. */
  toggleInternalReferenceCancelModal: PropTypes.func,
  /**The action to store the selected section Id. */
  storeSelectedSectionIdAction: PropTypes.func,
  /**The action to Confirm cancel*/
  openSectionPanelAfterCancelConfirmAction: PropTypes.func,
};

const mapStateToProps = ({
  data: {
    statementContent: {
      sectionsCache: { contentSectionMap },
      sectionIdList,
    },
    selectedStatement,
    workflowsMap,
    sectionPanel,
    sectionPanel: { sectionAssignmentsList, selectedSection },
    sectionAssignmentHeadingRefresh,
    revision,
    projectUsersList,
    selectedProject: { project },
  },
  ui: {
    statementPage: {
      currentSectionIds,
      statementNavigatorPanel: { TOCExpandAll },
      modes: { selectMode, navigationEditMode },
      panels: { left },
    },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  contentSectionMap,
  currentSectionIds,
  sectionIdList,
  TOCExpandAll,
  navigationEditMode,
  selectedStatement,
  workflowsMap,
  sectionReview: sectionPanel.sectionReviewList,
  left,
  sectionAssignmentsList,
  sectionAssignmentHeadingRefresh,
  revisionId: revision.id,
  projectUsersList,
  selectedSection,
  selectedProject: project,
  socketHasBeenDisconnected,
  selectModeId: selectMode.id,
});

const mapDispatchToProps = {
  onSectionClick,
  selectElementAction: selectElementFromElementSearch,
  searchElementsNextPage,
  onSelectElementResult: setSelectedElementResult,
  deallocateItems,
  setTOCExpandAll,
  toggleNavigationEditModeDispatch,
  setNewSectionIdAction,
  headingAssignmentRefreshAction: setHeadingAssignmentRefresh,
  updateBulkSectionAssignmentsList,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  storeSelectedSectionIdAction,
  openSectionPanelAfterCancelConfirmAction,
};

export { StatementNavigatorBody };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(StatementNavigatorBody));
