import { createAction } from 'redux-actions';
import sizeof from 'object-sizeof';

import { clearSectionContentWithIdsFromCache } from 'store/actions/statement-content-actions';
import { getRevisionsSectionsRequest } from 'api/sections-api';
import { CONTEXT_KEY } from 'api/api-client';

export const createSectionCacheTracker = createAction(
  'CACHE_CREATE_SECTION_TRACKER',
);

export const createLeftSectionCacheTracker = createAction(
  'CACHE_LEFT_CREATE_SECTION_TRACKER',
);

export const updateSectionViewedTimestamp = createAction(
  'CACHE_UPDATE_SECTION_VIEWED_TIMESTAMP',
);

export const addSectionToCache = createAction('ADD_SECTION_TO_CACHE');
export const addLeftSectionToCache = createAction('ADD_LEFT_SECTION_TO_CACHE');

export const updatePurgedCacheTracker = createAction(
  'UPDATE_PURGED_CACHE_TRACKER',
);

export const contentSectionMapLoading = createAction(
  'CONTENT_SECTION_MAP_LOADING',
);
export const contentSectionMapLoaded = createAction(
  'CONTENT_SECTION_MAP_LOADED',
);
export const contentSectionMapError = createAction('CONTENT_SECTION_MAP_ERROR');

export const leftContentSectionMapLoading = createAction(
  'LEFT_CONTENT_SECTION_MAP_LOADING',
);
export const leftContentSectionMapLoaded = createAction(
  'LEFT_CONTENT_SECTION_MAP_LOADED',
);
export const leftContentSectionMapError = createAction(
  'LEFT_CONTENT_SECTION_MAP_ERROR',
);

export const checkCacheToPurge = () => async (dispatch, getStore) => {
  const store = getStore();
  const { tracker } = store.data.statementContent.sectionsCache;
  const { sectionsInView } = store.ui.statementPage;

  if (tracker.cacheOverThreshold()) {
    const {
      newViewedSectionMetricsMap,
      newViewedSectionQueue,
      reducedTotalSize,
      removedSectionIds,
    } = tracker.getPurgedCache({ sectionsInView });
    dispatch(
      updatePurgedCacheTracker({
        viewedSectionMetricsMap: newViewedSectionMetricsMap,
        viewedSectionQueue: newViewedSectionQueue,
        totalCacheMemorySize: reducedTotalSize,
      }),
    );
    dispatch(clearSectionContentWithIdsFromCache({ removedSectionIds }));
  }
};

export const calculateSizeAndAddSectionToCache =
  ({ sectionId }) =>
  async (dispatch, getStore) => {
    const { cache } = getStore().data.statementContent.sectionsCache;
    const sectionMemorySize = sizeof(cache.get(sectionId));

    dispatch(
      addSectionToCache({
        sectionId,
        sectionMemorySize,
      }),
    );
  };

export const getContentSectionMapRequest =
  ({ revisionId }) =>
  async (dispatch, getStore) => {
    dispatch(contentSectionMapLoading());
    try {
      await getRevisionsSectionsRequest({
        revisionId,
        onlyBookmarkSection: false,
        asTreeList: false,
      }).then((response) => {
        dispatch(contentSectionMapLoaded({ response }));
      });
    } catch (error) {
      dispatch(contentSectionMapError(error));
    }
  };

export const getLeftContentSectionMapRequest =
  ({ statementId, revisionId, projectId }) =>
  async (dispatch, getStore) => {
    dispatch(leftContentSectionMapLoading());
    try {
      await getRevisionsSectionsRequest({
        statementId,
        revisionId,
        onlyBookmarkSection: false,
        asTreeList: false,
        contextKeyForApiCall: CONTEXT_KEY.PROJECT_CONTEXT,
        contextValue: projectId,
      }).then((response) => {
        dispatch(leftContentSectionMapLoaded({ response }));
      });
    } catch (error) {
      dispatch(leftContentSectionMapError(error));
    }
  };
