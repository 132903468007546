import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { userLogOut } from 'api/api-authentication';
import { connect } from 'react-redux';
import ProjectUsersList from 'models/api/project-users-list-api-model';

export const LOGOUT_PAGE_BLOCK = 'logout-page';

export const LogoutPage = (props) => {
  const { revisionId, projectUsersList, selectedProjectId } = props;
  useEffect(() => {
    // manually triggering the '/logout' api when user is opted to logout option to invalidate tieout token
    _userLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _userLogout = async () => {
    await userLogOut(
      revisionId,
      projectUsersList.data.userIdToUserNameMap,
      selectedProjectId,
    ).catch((e) => {
      console.error(e);
    });
  };

  return (
    <div className={LOGOUT_PAGE_BLOCK}>
      <FormattedMessage id="logout-page.message" />
    </div>
  );
};

const mapStateToProps = ({
  data: { projectUsersList, revision, selectedProject },
}) => ({
  projectUsersList,
  revisionId: revision.id,
  selectedProjectId: selectedProject && selectedProject.id,
});

LogoutPage.propTypes = {
  /** the instance of all the users in the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList),
  /** it has revisionId is User selected statement */
  revisionId: PropTypes.number,
  /** it has projectId is User selected project */
  selectedProjectId: PropTypes.number,
};

export default connect(mapStateToProps, null)(LogoutPage);
