import { createAction } from 'redux-actions';

export const resetSideBySideViewAnnotations = createAction(
  'RESET_SIDE_BY_SIDE_VIEW_ANNOTATIONS',
);
export const setAnnotationMapping = createAction('SET_ANNOTATION_MAPPING');

export const setAnnotationsForMapping = (annotations) => (dispatch) => {
  dispatch(setAnnotationMapping(annotations));
};
