import { withDefaultContextAsSelectedProject } from './api-default-context';

export const getStatementCreationPageDataRequest = () =>
  withDefaultContextAsSelectedProject().get('/tieout/statement/options');

// BE api is not yet ready. Refactor will be required, after BE is ready
export const getLanguageData = (localeCode) => Promise.reject();
// {
//   return withDefaultContextAsCurrentGeo()
//     .get(`/translations/languages/${localeCode}`)
//     .then((response) => {
//       return response.data;
//     });
// };
