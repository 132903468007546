import { handleActions } from 'redux-actions';
import { setTOCRefresh } from 'store/actions/TOC-actions';

export default handleActions(
  {
    [setTOCRefresh]: (tab, { payload }) => {
      return payload;
    },
  },
  null
);
