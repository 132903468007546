import RevisionStatusModel from 'models/utils/common/revision-status-model';

export const REVISION_STATUS_DISPLAYS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  RESTORING: 'RESTORING',
  FROM_RESTORE: 'FROM_RESTORE',
  ERROR: 'ERROR',
};

export const REVISION_STATUSES = {
  ACTIVE: new RevisionStatusModel({
    id: 0,
    status: 'ACTIVE',
    statusDisplay: 'Active',
    intlIdentifier: 'revision-status.active',
  }),
  DELETED: new RevisionStatusModel({
    id: 1,
    status: 'DELETED',
    statusDisplay: 'Deleted',
    intlIdentifier: 'revision-status.deleted',
  }),
  RESTORING: new RevisionStatusModel({
    id: 2,
    status: 'RESTORING',
    statusDisplay: 'Restoring',
    intlIdentifier: 'revision-status.restoring',
  }),
  FROM_RESTORE: new RevisionStatusModel({
    id: 3,
    status: 'FROM_RESTORE',
    statusDisplay: 'From Restore',
    intlIdentifier: 'revision-status.from-restore',
  }),
  ERROR: new RevisionStatusModel({
    id: 4,
    status: 'ERROR',
    statusDisplay: 'Error',
    intlIdentifier: 'revision-status.error',
  }),
};
