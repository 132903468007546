import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  WPR_NUMBER_CHAR_LIMIT,
  WPR_NAME_CHAR_LIMIT,
  WPR_NUMBER_REGEX,
} from 'constants/feature/workpaper-panel-constants';
export default class WorkpaperForm extends ApiModel({
  data: {
    revisionId: null,
    elementId: null,
    referenceNumber: '',
    referenceName: '',
    id: null,
    workpaperRefCreatedBy: null,
    elementWpRefCreatedBy: null,
    workpaperRefCreatedDate: null,
    workpaperRefId: null,
  },
  errorDuplicateRefNumber: null, // stores the reference number determined to be duplicate after the last failed request
}) {
  get id() {
    if (this.data) {
      return this.data.id;
    }
    return null;
  }

  get workpaperRefId() {
    if (this.data) {
      return this.data.workpaperRefId;
    }
    return null;
  }
  get revisionId() {
    if (this.data) {
      return this.data.revisionId;
    }
    return null;
  }
  get elementId() {
    if (this.data) {
      return this.data.elementId;
    }
    return null;
  }
  get referenceNumber() {
    if (this.data) {
      return this.data.referenceNumber;
    }
    return '';
  }
  get referenceName() {
    if (this.data) {
      return this.data.referenceName;
    }
    return '';
  }

  /**
   * Initializes a workpaper form from a normal workpaper model
   * @param {Workpaper} workpaper
   */
  fromWorkpaper(workpaper) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      error: null,
      data: { ...workpaper },
    });
  }

  toApiFormat() {
    return {
      ...this.data,
    };
  }

  toEditApiFormat() {
    return {
      revisionId: this.data.revisionId,
      workpaperRefId: this.data.workpaperRefId,
      referenceNumber: this.data.referenceNumber,
      referenceName: this.data.referenceName,
    };
  }

  initFromElement({ elementDetails }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      data: {
        ...this.data,
        revisionId: elementDetails.revisionId,
        elementId: elementDetails.id,
      },
    });
  }

  setDuplicateErrorLoaded({ duplicate }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      errorDuplicateRefNumber: duplicate,
    });
  }

  setReferenceNumber(referenceNumber) {
    return this.merge({
      data: {
        ...this.data,
        referenceNumber,
      },
    });
  }

  trimmedWorkpaperReference() {
    return this.merge({
      data: {
        ...this.data,
        referenceNumber: this.data.referenceNumber.trim(),
        referenceName: this.data.referenceName.trim(),
      },
    });
  }

  setReferenceName(referenceName) {
    return this.merge({
      data: {
        ...this.data,
        referenceName,
      },
    });
  }

  hasDuplicateReferenceError() {
    return !isNullOrUndefined(this.errorDuplicateRefNumber);
  }

  isReferenceNumberDuplicate() {
    return (
      this.hasDuplicateReferenceError() &&
      this.errorDuplicateRefNumber === this.data.referenceNumber
    );
  }

  doesRefNumberHaveIllegalChars() {
    return WPR_NUMBER_REGEX.test(this.data.referenceNumber);
  }

  isRefNumberTooLong() {
    return (
      !isNullOrUndefined(this.data.referenceNumber) &&
      this.data.referenceNumber.length > WPR_NUMBER_CHAR_LIMIT
    );
  }

  isReferenceNumberValid() {
    return (
      !this.isReferenceNumberDuplicate() &&
      !this.doesRefNumberHaveIllegalChars() &&
      !this.isRefNumberTooLong()
    );
  }

  isRefNameTooLong() {
    return (
      !isNullOrUndefined(this.data.referenceName) &&
      this.data.referenceName.length > WPR_NAME_CHAR_LIMIT
    );
  }

  isReferenceNameValid() {
    return !this.isRefNameTooLong();
  }

  isWorkpaperValid() {
    return (
      // Reference number is required but has no required validation message
      // so it should prevent the user from saving, but not trigger an error message
      !isNullOrUndefined(this.data.referenceNumber) &&
      this.data.referenceNumber.length > 0 &&
      this.isReferenceNumberValid() &&
      this.isReferenceNameValid() &&
      !this.isReferenceNumberDuplicate()
    );
  }
}
