import StatementSocketModel from 'models/sockets/statement-socket-model';
import { handleActions } from 'redux-actions';
import {
  initStatementSocketClient,
  initStatementSignalrSocketClient,
  setStatementSocketClient,
  clearSocketClientInStore,
  stopSocketConnection,
  setSocketDisconnectAction,
} from 'store/actions/statement-socket-actions';
import { initialStatementSocketClientUrl } from 'api/statement-socket-client-api';

export default handleActions(
  {
    [initStatementSocketClient]: (statementSocket, { payload }) => {
      return statementSocket.init({
        revisionId: payload.revisionId,
        projectId: payload.projectId,
        currentUserId: payload.currentUserId,
        geoCode: payload.geoCode,
      });
    },
    [initStatementSignalrSocketClient]: (statementSocket, { payload }) => {
      const { revisionId, geoCode } = payload;
      const url = initialStatementSocketClientUrl(revisionId);
      return statementSocket.init({ url, geoCode });
    },
    [setStatementSocketClient]: (statementSocket, { payload }) => {
      return statementSocket.setSocketClient(payload);
    },
    [setSocketDisconnectAction]: (statementSocket, { payload }) => {
      return statementSocket.setSocketDisconnect(payload);
    },
    [stopSocketConnection]: (statementSocket) => {
      return statementSocket.stopSocketConnection();
    },
    [clearSocketClientInStore]: (statementSocket, { payload }) => {
      return new StatementSocketModel();
    },
  },
  new StatementSocketModel(),
);
