import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getElementStatusIcon,
  getElementFlaggedIcon,
} from 'constants/feature/element-panel-constants';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributesDetails from 'components/common/element-amount-attributes-details-component';
import ElementDetails from 'models/api/element-details-api-model';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import {
  ANNOTATION_DISPLAY_FUNCTIONS,
  ELEMENT_HIGHLIGHT_STATES,
} from 'constants/feature/tieout-element-constants';
import classnames from 'classnames';
import {
  onElementClick,
  ELEMENT_BLOCK,
  COMFORT_ASSIGN_ELEMENT,
} from 'components/feature/statement-content-panel/tieout-element-component';
import SelectedElementMap from 'models/data/selected-element-map';
import {
  clickElementSearchEntryWhileRange,
  clickElementInContentWhileBatch,
} from 'store/actions/batch-panel-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { getElementHightlightState } from 'constants/feature/statement-content-constants';
import { deallocateItems } from 'store/actions/statement-content-actions';
import { REACT_SECTION_ID_PREFIX } from 'constants/feature/statement-content-constants';
import { setContentHighlightSelector } from 'store/actions/statement-content-actions';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { ReactComponent as Sidebar } from 'icons/sidebar.svg';
import Tooltip from 'components/common/tool-tip-component';
import shortid from 'shortid';
import { useCallback } from 'react';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';

export const ELEMENTS_SEARCH_ENTRY_BLOCK = 'element-search-entry';
export const ELEMENTS_SEARCH_ENTRY_ID_BLOCK = 'element-search-entry-id';
const SIDEBAR_ICON_SIZE = '20px';

const ElementsSearchEntry = ({
  element,
  selectElementAction,
  contentSectionMap,
  searchTerm,
  onElementClick,
  setActiveEntry,
  activeEntry,
  selectedElementsMap,
  selectMode,
  clickElementSearchEntryWhileRange,
  clickElementInContentWhileBatch,
  panel,
  elementPanelElementId,
  deallocateItems,
  searchResultsContent,
  setContentHighlightSelector,
  sectionsInView,
  leftPanel,
  checkIfFeatureFlagEnabled,
}) => {
  const elementBookmark = contentSectionMap.getClosestBookmarkSection({
    sectionId: element.sectionId,
  });

  const _getSelectedModifierClass = ({ selected }) =>
    selected
      ? `${ELEMENT_BLOCK}--selected--${getElementHightlightState(
          panel,
          elementPanelElementId,
          element.id,
        )}`
      : null;

  const isElementSelected = selectedElementsMap.has(element.id);
  //this function is going to verify if it needs to highlight items on content panel
  //related with the selected element from searched elements list
  const highlightContent = (id) => {
    //it is going to iterate through all the items from the search results
    for (let i = 0; i < searchResultsContent.data.occurrences.length; i++) {
      const selector = searchResultsContent.occurrences[i].selector;
      const sectionId = searchResultsContent.occurrences[i].sectionId;
      const sectionElement = document.querySelector(
        `#${REACT_SECTION_ID_PREFIX}${sectionId} ${selector}`,
      );
      //if the section is already loaded
      //it is going to check if the clicked element from the list exist in this section by its id
      if (sectionElement) {
        //first case, if the element is on a table, it is going to check if the searched word is on a table
        const sectionOnTable = sectionElement.closest('tr');
        //if it's inside a table, it is going to check if the searched word and the element are in the same tr element
        if (
          sectionOnTable &&
          sectionOnTable.querySelector(`#CFTO_ELEMENT_${id}`)
        ) {
          setContentHighlightSelector({
            sectionId,
            selector,
            isElementHighlight: true,
          });
          break;
        } else {
          // if the searched word is not inside a table it is going to apply the general case
          if (sectionElement.querySelector(`#CFTO_ELEMENT_${id}`)) {
            //if the element exist on this section of the content, it is going to highlight only the words searched.
            setContentHighlightSelector({
              sectionId,
              selector,
              isElementHighlight: true,
            });
            break;
          } else {
            //case searched word inside element
            const searchedWordInElement = sectionElement.closest('p');
            if (
              searchedWordInElement &&
              searchedWordInElement.querySelector(`#CFTO_ELEMENT_${id}`)
            ) {
              //if the element exist on this section of the content, it is going to highlight only the words searched.
              setContentHighlightSelector({
                sectionId,
                selector,
                isElementHighlight: true,
              });
              break;
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    if (
      isElementSelected &&
      element.sectionId in sectionsInView &&
      leftPanel === LEFT_PANELS.STATEMENT_NAV
    ) {
      highlightContent(element.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.id, isElementSelected, sectionsInView]);

  const _scrollElementIntoViewAndOpenElementPanel = useCallback(() => {
    scrollElementIntoView({
      elementId: element.id,
      sectionId: element.sectionId,
    });
    deallocateItems();

    // Clicking on button in statement nav panel should not result in linking of element when in IR mode or Formula mode.
    selectMode !== ELEMENT_SELECT_MODES.INTERNAL_REFERENCE &&
      selectMode !== ELEMENT_SELECT_MODES.FORMULA &&
      onElementClick({
        elementId: element.id,
        sectionId: element.sectionId,
        color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
        isClickedFromPanel: true,
      });
    selectElementAction({ elementId: element.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.id, element.sectionId, selectMode]);

  return (
    <div
      className={classnames(
        ELEMENTS_SEARCH_ENTRY_BLOCK,
        isElementSelected ? `${ELEMENTS_SEARCH_ENTRY_BLOCK}--selected` : null,
        _getSelectedModifierClass({
          selected: isElementSelected,
        }),
      )}
      id={`${ELEMENTS_SEARCH_ENTRY_ID_BLOCK}-${element.id}`}
      onClick={() => {
        scrollElementIntoView({
          elementId: element.id,
          sectionId: element.sectionId,
        });
        deallocateItems();
        highlightContent(element.id);
        selectElementAction({ elementId: element.id });
        switch (selectMode) {
          case ELEMENT_SELECT_MODES.RANGE: {
            clickElementSearchEntryWhileRange({ element });
            break;
          }
          case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER:
          case ELEMENT_SELECT_MODES.BATCH: {
            clickElementInContentWhileBatch({
              elementId: element.id,
              sectionId: element.sectionId,
            });
            break;
          }
          default: {
            setActiveEntry();
          }
        }
      }}
    >
      {elementBookmark && (
        <div
          className={classnames(
            `${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-details-section-name-wrapper`,
          )}
        >
          <span
            className={classnames(
              `${ELEMENTS_SEARCH_ENTRY_BLOCK}__section-name`,
              activeEntry
                ? `${ELEMENTS_SEARCH_ENTRY_BLOCK}__section-name--active`
                : null,
            )}
          >
            {elementBookmark.name}
          </span>
          <span
            className={classnames(
              `${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-details`,
            )}
          >
            <Tooltip
              text="statement-nav-element-search-entry.view-element-details-tooltip"
              id={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-element-details-tooltip-id`}
              position="top"
              key={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-element-details-${shortid.generate()}`}
            >
              <Sidebar
                id={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-element-details-id`}
                className={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__view-element-details-button`}
                onClick={_scrollElementIntoViewAndOpenElementPanel}
                width={SIDEBAR_ICON_SIZE}
                height={SIDEBAR_ICON_SIZE}
              />
            </Tooltip>
          </span>
        </div>
      )}
      <span
        className={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__element-label`}
        title={element.label}
        dangerouslySetInnerHTML={{
          __html: element.highlightSearchTermInLabel({
            term: searchTerm,
          }),
        }}
      />
      <div className={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__element-value-wrapper`}>
        <button
          id={`${ELEMENTS_SEARCH_ENTRY_ID_BLOCK}-element-value`}
          className={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__element-value`}
          onClick={(e) => {
            if (e) {
              e.stopPropagation();
            }
            deallocateItems();
            onElementClick({
              elementId: element.id,
              sectionId: element.sectionId,
              color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
              isClickedFromPanel: true,
            });
            highlightContent(element.id);
          }}
        >
          {getElementFlaggedIcon({ elementDetails: element })}
          <span
            className={classnames(
              element &&
                element.isComfortAssign() &&
                checkIfFeatureFlagEnabled({
                  featureName: FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE,
                }) &&
                COMFORT_ASSIGN_ELEMENT,
            )}
            dangerouslySetInnerHTML={{
              __html: element.highlightSearchTermInDisplayValue({
                term: searchTerm,
              }),
            }}
          />
        </button>
        <div
          className={classnames(
            `${ELEMENTS_SEARCH_ENTRY_BLOCK}__annotations`,
            activeEntry
              ? `${ELEMENTS_SEARCH_ENTRY_BLOCK}__annotations--active`
              : null,
          )}
        >
          <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
            {ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
              elementDetails: element,
            })}
          </FeatureFlagComponent>
          {ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
            elementDetails: element,
          })}
          {ANNOTATION_DISPLAY_FUNCTIONS.formula({
            elementDetails: element,
          })}
          {ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
            elementDetails: element,
          })}
          {ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
            elementDetails: element,
          })}
        </div>
        {getElementStatusIcon({ elementDetails: element })}
      </div>
      <AmountAttributesDetails
        className={`${ELEMENTS_SEARCH_ENTRY_BLOCK}__element-attributes`}
        elementDetails={element}
      />
    </div>
  );
};

ElementsSearchEntry.propTypes = {
  /** the api model containing search results */
  element: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Action fired for highlighting an element from the content panel on click*/
  selectElementAction: PropTypes.func.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** The term searched for in the statement nav search box */
  searchTerm: PropTypes.string,
  /** function fired to set the state of active result entry */
  setActiveEntry: PropTypes.func.isRequired,
  /** The currently selected (index) entry result. This is used to set the white-space and overflow of annotations and section name */
  activeEntry: PropTypes.bool.isRequired,
  /** Contains list of elements selected in batch */
  selectedElementsMap: PropTypes.instanceOf(SelectedElementMap).isRequired,
  /** current mode for selecting elements in the content panel */
  selectMode: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  /** Action fired while element is selected while mode is in range */
  clickElementSearchEntryWhileRange: PropTypes.func.isRequired,
  /** Action fired while element is selected while mode is in batch */
  clickElementInContentWhileBatch: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /** Search results model of the content tab */
  searchResultsContent: PropTypes.instanceOf(ContentSearchResults).isRequired,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: { elementPanel },
  ui: {
    statementPage: { selectedElementsMap, modes, panels, sectionsInView },
  },
}) => ({
  selectedElementsMap,
  selectMode: modes.selectMode,
  panel: panels.right,
  leftPanel: panels.left,
  elementPanelElementId: elementPanel.elementDetails.id,
  sectionsInView,
});

const mapDispatchToProps = {
  onElementClick,
  clickElementSearchEntryWhileRange,
  clickElementInContentWhileBatch,
  deallocateItems,
  setContentHighlightSelector,
  checkIfFeatureFlagEnabled,
};

export { ElementsSearchEntry };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementsSearchEntry);
