export const TIE_OUT_STATUS = 'TIE_OUT_STATUS';
export const ANNOTATIONS = 'ANNOTATIONS';
export const NOTES = 'NOTES';
export const BLACKLINE_VERSION = 'BLACKLINE_VERSION';

// Name and Type Should be a unique combination for Check boxes
// Name within a particular check box type should be unique.
export const TIE_OUT_CHECK_BOXES = {
  VERIFICATION_STATUS: {
    type: TIE_OUT_STATUS,
    name: 'VERIFICATION_STATUS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-tieout.heading.label.verification',
    disabled: false,
    tooltip: null,
  },
  REVIEW_STATUS_ELEMENTS: {
    type: TIE_OUT_STATUS,
    name: 'REVIEW_ELEMENTS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-tieout.heading.label.review-elements',
    disabled: false,
    tooltip: null,
  },
  REVIEW_STATUS_HEADINGS: {
    type: TIE_OUT_STATUS,
    name: 'REVIEW_HEADINGS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-tieout.heading.label.review-headings',
    disabled: false,
    tooltip: null,
  },

  COMFORT_ASSIGNED_ON_ELEMENTS: {
    type: TIE_OUT_STATUS,
    name: 'COMFORT_ASSIGNED_ON_ELEMENTS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-tieout.heading.label.comfort-assigned-on-elements',
    disabled: false,
    tooltip: null,
  },
};

export const ANNOTATION_CHECK_BOXES = {
  FORMULAS: {
    type: ANNOTATIONS,
    name: 'FORMULAS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-annotations.heading.label.formulas',
    disabled: false,
    tooltip: null,
  },
  TICKMARKS: {
    type: ANNOTATIONS,
    name: 'TICKMARKS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-annotations.heading.label.tickmarks',
    disabled: false,
    tooltip: null,
  },
  INTERNAL_REFERENCES: {
    type: ANNOTATIONS,
    name: 'INTERNAL_REFERENCES',
    checked: false,
    labelId: 'custom-menu-panel-content-checkbox-annotations.heading.label.irs',
    disabled: false,
    tooltip: null,
  },
  WORKPAPER_REFERENCES: {
    type: ANNOTATIONS,
    name: 'WORKPAPER_REFERENCES',
    checked: false,
    labelId: 'custom-menu-panel-content-checkbox-annotations.heading.label.wrs',
    disabled: false,
    tooltip: null,
  },
  SYSTEM_USER_FLAGS: {
    type: ANNOTATIONS,
    name: 'SYSTEM_USER_FLAGS',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-annotations.heading.label.flags',
    disabled: false,
    tooltip: null,
  },
  COMFORT_LETTER_ANNOTATION: {
    type: ANNOTATIONS,
    name: 'COMFORT_LETTER_ANNOTATION',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-annotations.heading.label.comfort-annotations',
    disabled: false,
    tooltip: null,
  },
  ATTRIBUTES: {
    type: ANNOTATIONS,
    name: 'ATTRIBUTES',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-annotations.heading.label.attributes',
    disabled: false,
    tooltip: null,
  },
};

export const NOTES_CHECK_BOXES = {
  CLIENT_NOTES: {
    type: NOTES,
    name: 'CLIENT_NOTES',
    checked: false,
    labelId: 'custom-menu-panel-content-checkbox-notes.heading.label.client',
    disabled: false,
    tooltip: null,
  },
  REVIEW_NOTES: {
    type: NOTES,
    name: 'REVIEW_NOTES',
    checked: false,
    labelId: 'custom-menu-panel-content-checkbox-notes.heading.label.review',
    disabled: false,
    tooltip: null,
  },
  OBSERVER_NOTES: {
    type: NOTES,
    name: 'OBSERVER_NOTES',
    checked: false,
    labelId: 'custom-menu-panel-content-checkbox-notes.heading.label.observer',
    disabled: false,
    tooltip: null,
  },
};

export const BLACKLINE_CHECK_BOXES = {
  BLACKLINE_VERSION: {
    type: BLACKLINE_VERSION,
    name: 'BLACKLINE_VERSION',
    checked: false,
    labelId:
      'custom-menu-panel-content-checkbox-blackline.heading.label.version',
    disabled: false,
    tooltip: null,
  },
};
