import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { toggleCustomMenuPanel } from 'store/actions/panel-controller-actions';
import CustomReportContent from './toolkit-export-custom-report-content-component';
import {
  exportCustomReport,
  REPORT_TYPES,
} from 'store/actions/statement-list/statement-list-actions';
import SelectedStatement from 'models/api/selected-statement-model';

export const CUSTOM_REPORT_PANEL_BLOCK = 'custom-report-panel';

export const CustomMenuPanel = ({
  show,
  onCancelPanel,
  revisionId,
  exportCustomReport,
  revisionNumber,
  selectedStatement,
  isExportCustomReportDisabled,
}) => {
  return (
    <>
      <Flyout
        className={CUSTOM_REPORT_PANEL_BLOCK}
        show={show}
        position={'left'}
      >
        <FlyoutHeader enableBack onBack={onCancelPanel}>
          <h3 className={`${CUSTOM_REPORT_PANEL_BLOCK}__title`}>
            <FormattedMessage
              id={'custom-menu-panel-content-sub-heading.label'}
            />
          </h3>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <CustomReportContent
            exportCustomReport={exportCustomReport}
            revisionId={revisionId}
            show={show}
            revisionNumber={revisionNumber}
            selectedStatement={selectedStatement}
            isExportCustomReportDisabled={isExportCustomReportDisabled}
          />
        </FlyoutPanelContent>
      </Flyout>
    </>
  );
};

const mapStateToProps = ({
  ui: {
    statementPage: {
      panels: { left },
    },
  },
  data: {
    revision: {
      data: { id, revisionNumber },
    },
    selectedStatement,
    toolkitExportPanel: { downloadProgressData },
  },
}) => ({
  show: left === LEFT_PANELS.CUSTOM,
  left,
  revisionId: id,
  revisionNumber,
  selectedStatement,
  isExportCustomReportDisabled:
    downloadProgressData &&
    downloadProgressData.hasReportTypeDownloadProcessing(
      REPORT_TYPES.BUILD_CUSTOMIZED_REPORT,
    ),
});

const mapDispatchToProps = {
  onCancelPanel: toggleCustomMenuPanel,
  exportCustomReport,
};

CustomMenuPanel.propTypes = {
  /** boolean value to represent open/close state of a panel */
  show: PropTypes.bool.isRequired,
  /* Action to toggle state of panel- open/closed  */
  onCancelPanel: PropTypes.func.isRequired,
  /* Current Revision Id : Property of a particular statement  */
  revisionId: PropTypes.number.isRequired,
  /* Current Revision Number : Property of a particular statement  */
  revisionNumber: PropTypes.number.isRequired,
  /** Action to export custom reports */
  exportCustomReport: PropTypes.func.isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /**boolean value to indicate if button to trigger export of customized report should be disabled */
  isExportCustomReportDisabled: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(CustomMenuPanel));
