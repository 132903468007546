import BaseModel from 'models/base-model';
import { SocketClient, SOCKET_CONNECTION_TYPE } from 'socket/socketio-client';
import { SignalrClient } from 'socket/signalr-client';
import { getStore } from 'store/store';
import { updateElementCacheFromSocketPayloadAction } from 'store/actions/element-cache-actions';
import {
  updateElementDetailsFromLeftPanel,
  updateElementPanelFromSocketPayload,
  removeElementFromFormulaTabFromSocketPayloadAction,
  addFormulaToFormulaListFromSocketPayloadAction,
  elementFormulaListLoadedWithoutResponse,
  elementWorkpaperListFromWebSocketPayloadAction,
  removeWorkpaperFromElementPanelWithSocketPayload,
  elementWorkpaperListLoadedWithoutResponse,
  removeWPFromElementPanelAction,
  updateWPFromElementPanelAction,
  updateTickmarkInElementPanelWithSocketPayloadAction,
  removeTickmarkFromElementPanelWithSocketPayloadAction,
  elementTickmarkListFromWebSocketPayloadAction,
  removeTickmarkWithSocketPayloadInBulk,
  elementTickmarkListLoadedWithoutResponse,
} from 'store/actions/element-panel-actions';
import {
  updateElementFilterFromSocketPayload,
  showElementSearchRefreshButtonAction,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import {
  updateSelectedBatchElementsFromSocketAction,
  removeBatchElementsFromSocketPayloadAction,
} from 'store/actions/batch-panel-actions';
import { LEFT_PANELS, RIGHT_PANELS } from 'constants/feature/panel-constants';
import {
  updateInternalReferenceElementsFromSocketAction,
  updateInternalReferenceFromSocketAction,
  setInternalReferenceLoadedWithoutPayload,
} from 'store/actions/internal-reference-actions';
import ElementDetails from 'models/api/element-details-api-model';
import {
  updateSuggestedIRElementsFromListFromSocketPayloadAction,
  fetchSuggestedListedWithoutLoading,
} from 'store/actions/internal-reference-suggestion-list-actions';
import { updateSectionSummaryFromSocketPayloadAction } from 'store/actions/statement-summary/sections-summary-actions';
import { updateElementSummaryFromSocketPayloadAction } from 'store/actions/statement-summary/elements-summary-actions';
import {
  fetchSectionHTMLSegmentsStatementContentWithoutLoading,
  fetchAllSectionRenderingDataWithoutLoading,
} from 'store/actions/statement-content-actions';
import {
  removeElementsFromElementsMapWithSocketPayloadAction,
  fetchWorkpaperListForRevisionWithoutLoading,
  addWorkpaperToListAction,
  unlinkWorkpaperFromElementWithWebsocketPayload,
  deleteWorkpaperWithSocketPayloadAction,
  updateWorkpaperWithSocketPayloadAction,
} from 'store/actions/workpaper-toolkit-list-actions';
import {
  removeElementFromTickmarkElementsMapWithSocketPayloadAction,
  fetchTickmarkListForRevisionWithoutLoading,
  deleteTickmarkWithSocketPayloadAction,
  updateTickmarkWithSocketPayloadAction,
  addTickmarkToListAction,
  unlinkTickmarkFromElementWithWebsocket,
} from 'store/actions/tickmark-list-panel-actions';
import {
  addUserAfterValidatingIfIdExists,
  removeUserAction,
  // removeUserFromRoomAction,
} from 'store/actions/active-users-actions';
import { removeFormulaWithSocketPayloadAction } from 'store/actions/formula-actions';
import { hideElementPanelAction } from 'store/actions/panel-controller-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { setElementSelectModeDefaultAction } from 'store/actions/modes-actions';
import { updateSummaryNotesFromWebsocket } from 'store/actions/statement-summary/notes-summary-actions';
import {
  deleteNoteWithWebsocketPayload,
  notesListLoadedWithoutReponse,
  addNoteWithWebsocketPayload,
  selectNoteFromNoteListAction,
  updateNoteWithWebsocketPayload,
} from 'store/actions/notes-panel-actions';
import Note from 'models/data/note-model';
import {
  removeNoteFromCacheWithSocketPayloadAction,
  updateNoteFromNoteCacheWithSocketPayloadAction,
} from 'store/actions/notes-cache-actions';
import { setStatementNavigationRefresh } from 'store/actions/statement-navigator/navigator-panel-actions';
import {
  appendSectionToSectionReviewList,
  removeSectionReviewFromStore,
} from 'store/actions/sections-actions';
import {
  appendSectionReviewHistory,
  fetchSectionHistoryDetails,
} from 'store/actions/section-review-history-actions';
import {
  updateSectionAssignmentListFromSocket,
  replaceNewWithSocketData,
  updateSectionAssignmentListForBulkAssignFromSocket,
  updateSectionAssignmentListForBulkUnAssignFromSocket,
  updateSectionAssignmentInBulkFromSocket,
} from 'store/actions/section-assignment-actions';
import { downloadReportAction } from 'store/actions/toolkit-export-panel-actions';

import { setHeadingAssignmentRefresh } from 'store/actions/section-assignment-heading-refresh-actions';
import {
  fetchComfortAnnotationListForRevisionWithoutLoading,
  removeElementFromComfortLetterElementsWithSocketPayloadAction,
} from 'store/actions/comfort-annotations-list-actions';

// WEBSOCKET EVENT TYPE CONSTANTS
import { NOTIFICATION_EVENT_TYPES } from 'constants/web-socket-constants';

import { blacklineTogleNotification } from 'utils/statement-content-page-utils';
import { refetchSectionsInView } from '../../store/actions/statement-content-actions';
import { BLACKLINE_VIEW_STATUS } from '../../constants/feature/statement-content-constants';
import {
  getWebSocketNotificationType,
  getWebSocketPayload,
} from 'constants/util/web-socket-utils';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';
import { getUserNameByUserData } from 'utils/statement-content-page-utils';

export default class StatementSocketModel extends BaseModel({
  client: null,
  socketHasBeenDisconnected: true,
}) {
  init(config = {}) {
    if (isSignalRForSocketConnectionEnabled) {
      const client = new SignalrClient(config);
      return this.merge({ ...this, client });
    } else {
      const client = new SocketClient({
        ...config,
        userId: config.currentUserId,
        socketConnectionType: SOCKET_CONNECTION_TYPE.REVISION,
      });
      return this.merge({ ...this, client });
    }
  }

  setSocketClient() {
    if (this.client) {
      this.client.on('newMessage', this.onEventHandler);
    }
    return this;
  }

  stopSocketConnection() {
    //if application is enabled with signalr socket connection
    if (
      isSignalRForSocketConnectionEnabled &&
      this.client &&
      this.client.connection
    ) {
      this.client.connection.stop();
    }
    //if application is enabled with socket connection
    if (!isSignalRForSocketConnectionEnabled && this.client) {
      this.client._closeSocketConnection();
      this.client.disconnect();
      return this.merge({ ...this, socketHasBeenDisconnected: true });
    }
    return this;
  }

  setSocketDisconnect(payload) {
    return this.merge({ ...this, socketHasBeenDisconnected: payload });
  }

  onEventHandler(event) {
    // This dispatch and getState is from the store and will allow us to update data throughout the app
    const { dispatch, getState } = getStore();
    const storeData = getState().data;
    const { revision } = storeData;
    const {
      data: {
        currentUser,
        sectionPanel: { selectedSection },
        projectUsersList,
      },
      ui: {
        statementPage: {
          panels: { right, left },
          statementNavigatorPanel: { elementSearchResults },
          modes: { blacklineViewMode },
        },
      },
    } = getState();
    const { elementDetails } = storeData.elementPanel;
    const { internalReference } = storeData.elementPanel;
    const { formula } = storeData.formulaPanel;
    const { internalReferenceSuggestionList } =
      storeData.internalReferencePanel;
    const { elementCache } = storeData.statementContent;

    const _elementUpdate = (webSocketPayload) => {
      // this action updates the elements in the content in real time
      dispatch(updateElementCacheFromSocketPayloadAction(webSocketPayload));

      // this action updates the element details in the TM/WP panel
      // this action only gets called if the user has the TM/WP open
      dispatch(
        updateElementDetailsFromLeftPanel({
          elementsArray: webSocketPayload,
        }),
      );

      if (right === RIGHT_PANELS.ELEMENT) {
        const _elementMap = webSocketPayload.reduce(
          (elementMap, elementData) => {
            const payload = { response: { data: { result: elementData } } };
            elementMap[elementData.id] = new ElementDetails().setLoaded(
              payload,
            );
            return elementMap;
          },
          {},
        );

        if (internalReferenceSuggestionList.isLoaded) {
          dispatch(
            updateSuggestedIRElementsFromListFromSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }

        dispatch(updateInternalReferenceElementsFromSocketAction(_elementMap));

        // this action updates the element panel in real time if the updated element
        // is viewed in the element panel by the user
        if (webSocketPayload.length > 0) {
          const element = webSocketPayload.find(
            (elem) => elem.id === elementDetails.id,
          );
          if (element) {
            dispatch(updateElementPanelFromSocketPayload(element));
          }
        }
      }

      // this action updates the element filter list when a user makes an update to an element
      // this action will only be called if the elements tab is selected and if there are
      // element filters applied
      dispatch(updateElementFilterFromSocketPayload(webSocketPayload));

      // this action updates the selected elements in the batch panel
      // this action will only be called if the batch panel is open
      if (right === RIGHT_PANELS.BATCH) {
        dispatch(updateSelectedBatchElementsFromSocketAction(webSocketPayload));
      }

      // this action will determine if the element search content component will render the
      // filter refresh button
      if (elementSearchResults.isLoaded) {
        dispatch(showElementSearchRefreshButtonAction(true));
      }
    };

    const webSocketPayload = getWebSocketPayload(event);
    let elementToFormulasObject = {};
    let irElement = null;

    switch (getWebSocketNotificationType(event)) {
      // case for single and batch element status updates
      case NOTIFICATION_EVENT_TYPES.ELEMENT_UPDATE:
        _elementUpdate(webSocketPayload);
        break;

      case NOTIFICATION_EVENT_TYPES.ELEMENT_ADD:
        dispatch(showElementSearchRefreshButtonAction(true));

        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(
            updateInternalReferenceElementsFromSocketAction(
              webSocketPayload.reduce((elementMap, elementData) => {
                const payload = { response: { data: { result: elementData } } };
                elementMap[elementData.id] = new ElementDetails().setLoaded(
                  payload,
                );
                return elementMap;
              }, {}),
            ),
          );
        }

        dispatch(
          fetchAllSectionRenderingDataWithoutLoading({
            sectionId: webSocketPayload[0].sectionId,
          }),
        );

        break;

      case NOTIFICATION_EVENT_TYPES.ELEMENT_EXCLUDE:
        // removes elements to selected elements in batch panel when open
        dispatch(showElementSearchRefreshButtonAction(true));
        if (right === RIGHT_PANELS.BATCH) {
          dispatch(
            removeBatchElementsFromSocketPayloadAction(webSocketPayload),
          );
        }

        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(
            updateInternalReferenceElementsFromSocketAction(
              webSocketPayload.reduce((elementMap, elementData) => {
                const payload = { response: { data: { result: elementData } } };
                elementMap[elementData.id] = new ElementDetails().setLoaded(
                  payload,
                );
                return elementMap;
              }, {}),
            ),
          );

          // removes element from formula edit panel if the formula panel contains the removed element
          if (formula.data.formulaElementDtoList) {
            dispatch(removeFormulaWithSocketPayloadAction(webSocketPayload));
          }

          // close element panel if the element is removed
          if (
            webSocketPayload.length > 0 &&
            elementDetails.id === webSocketPayload[0].id
          ) {
            dispatch(hideElementPanelAction());
            dispatch(setElementSelectModeDefaultAction());
          }
        }

        // Update the content panel html thru api and not signalr
        dispatch(
          fetchSectionHTMLSegmentsStatementContentWithoutLoading({
            sectionIdList: Array.from(
              new Set(webSocketPayload.map((element) => element.sectionId)),
            ),
            segmentType: blacklineViewMode ? 'C' : 'P',
          }),
        );

        // update WP element list if user has the Workpaper panel open
        if (left === LEFT_PANELS.WORKPAPER) {
          dispatch(
            fetchWorkpaperListForRevisionWithoutLoading({
              revisionId: revision.id,
            }),
          );
          dispatch(
            removeElementsFromElementsMapWithSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }

        // update TM element list if user has the Tickmark panel open
        if (left === LEFT_PANELS.TICKMARK) {
          dispatch(
            fetchTickmarkListForRevisionWithoutLoading({
              revisionId: revision.id,
            }),
          );
          dispatch(
            removeElementFromTickmarkElementsMapWithSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }

        // update Comfort Annotation elements list if user has the Comfort Annotation panel open
        if (left === LEFT_PANELS.COMFORT_ANNOTATION) {
          dispatch(
            fetchComfortAnnotationListForRevisionWithoutLoading({
              revisionId: revision.id,
            }),
          );
          dispatch(
            removeElementFromComfortLetterElementsWithSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }

        break;

      // update the summary count for verified, reviewed, and flagged elements
      case NOTIFICATION_EVENT_TYPES.ELEMENT_SUMMARY:
        dispatch(updateElementSummaryFromSocketPayloadAction(webSocketPayload));
        break;

      // update the summary count for sections
      case NOTIFICATION_EVENT_TYPES.HEADING_SUMMARY:
        dispatch(updateSectionSummaryFromSocketPayloadAction(webSocketPayload));
        break;

      case NOTIFICATION_EVENT_TYPES.FORMULA_DELETED:
        // remove formula from formula tab if the user has the affected element open
        if (right === RIGHT_PANELS.ELEMENT) {
          for (let i = 0; i < webSocketPayload.length; i++) {
            const element = webSocketPayload[i];
            const elementId = element.elementId;
            const formulasIds = element.formulaIds;
            elementToFormulasObject[elementId] = new Set(formulasIds);
          }

          if (elementToFormulasObject[elementDetails.id]) {
            dispatch(
              removeElementFromFormulaTabFromSocketPayloadAction(
                elementToFormulasObject[elementDetails.id],
              ),
            );
          }
        }

        // render refresh button on element search
        if (elementSearchResults.isLoaded) {
          dispatch(showElementSearchRefreshButtonAction(true));
        }

        // this action updates the selected elements in the batch panel
        // this action will only be called if the batch panel is open
        if (right === RIGHT_PANELS.BATCH) {
          dispatch(
            updateSelectedBatchElementsFromSocketAction(webSocketPayload),
          );
        }

        dispatch(elementFormulaListLoadedWithoutResponse());
        break;

      case NOTIFICATION_EVENT_TYPES.FORMULA_SAVED:
        if (
          webSocketPayload &&
          elementDetails.id === webSocketPayload.elementId
        ) {
          dispatch(
            addFormulaToFormulaListFromSocketPayloadAction(webSocketPayload),
          );
        }

        // render refresh button on element search
        if (elementSearchResults.isLoaded) {
          dispatch(showElementSearchRefreshButtonAction(true));
        }

        // this action updates the selected elements in the batch panel
        // this action will only be called if the batch panel is open
        if (right === RIGHT_PANELS.BATCH) {
          dispatch(
            updateSelectedBatchElementsFromSocketAction(webSocketPayload),
          );
        }
        dispatch(elementFormulaListLoadedWithoutResponse());
        break;

      case NOTIFICATION_EVENT_TYPES.INTERNAL_REFERENCE_UPDATE:
        for (let i = 0; i < webSocketPayload.length; i++) {
          irElement = webSocketPayload[i];
          if (!isNullOrUndefined(irElement.id)) {
            const isDifferentElement =
              !isNullOrUndefined(irElement.internalReferenceElements) &&
              !isNullOrUndefined(
                irElement.internalReferenceElements.find(
                  (element) => element.elementId === elementDetails.id,
                ),
              );
            // if its the same internal reference or the elementDetails is inside the internal reference elements list
            //we are going to update the IR information
            if (
              irElement.id === internalReference.data.id ||
              isDifferentElement
            ) {
              // if its the same internal reference id but the elements does not longer exist in the IR we are going to clear the IR info
              if (
                isNullOrUndefined(
                  irElement.internalReferenceElements.find(
                    (element) => element.elementId === elementDetails.id,
                  ),
                )
              ) {
                dispatch(updateInternalReferenceFromSocketAction(null));
              } else {
                //update the current internal reference object on the element details stored in redux
                dispatch(updateInternalReferenceFromSocketAction(irElement));
              }
              //we call this action to update the suggestion for the IR panel, only if the element is part of the IR.
              dispatch(
                fetchSuggestedListedWithoutLoading({
                  revisionId: revision.id,
                  elementId: elementDetails.id,
                }),
              );
              dispatch(setInternalReferenceLoadedWithoutPayload());
              break;
            }

            // this action will determine if the element search content component will render the
            // filter refresh button
            if (elementSearchResults.isLoaded) {
              dispatch(showElementSearchRefreshButtonAction(true));
            }
          }
        }

        break;

      case NOTIFICATION_EVENT_TYPES.WORKPAPER_LINKED:
        if (right === RIGHT_PANELS.ELEMENT) {
          const elementId = elementDetails.id;
          dispatch(
            elementWorkpaperListFromWebSocketPayloadAction({
              webSocketPayload: webSocketPayload.attachedWorkpapers,
              elementId,
            }),
          );
        }
        if (left === LEFT_PANELS.WORKPAPER) {
          dispatch(addWorkpaperToListAction(webSocketPayload));
        }
        //we call the element update function here because for WP element update event, we are not going to get anything for element update event
        _elementUpdate(webSocketPayload.elementEntities);
        break;

      case NOTIFICATION_EVENT_TYPES.USER_JOINED_REVISION_GROUP:
        dispatch(
          addUserAfterValidatingIfIdExists({ payload: webSocketPayload }),
        );
        break;
      case NOTIFICATION_EVENT_TYPES.USER_LEFT_REVISION_GROUP:
        dispatch(removeUserAction({ payload: webSocketPayload }));
        break;
      case NOTIFICATION_EVENT_TYPES.WORKPAPER_UNLINKED:
        if (left === LEFT_PANELS.WORKPAPER) {
          dispatch(
            unlinkWorkpaperFromElementWithWebsocketPayload(webSocketPayload),
          );
        }

        if (right === RIGHT_PANELS.ELEMENT) {
          const workpaperIdSet = new Set();
          for (let i = 0; i < webSocketPayload.length; i++) {
            workpaperIdSet.add(webSocketPayload[i].workpaperRefId);
          }

          dispatch(
            removeWorkpaperFromElementPanelWithSocketPayload(workpaperIdSet),
          );
          dispatch(elementWorkpaperListLoadedWithoutResponse());
        }

        // this action will determine if the element search content component will render the
        // filter refresh button

        if (elementSearchResults.isLoaded) {
          dispatch(showElementSearchRefreshButtonAction(true));
        }

        break;

      case NOTIFICATION_EVENT_TYPES.WORKPAPER_DELETED:
        if (left === LEFT_PANELS.WORKPAPER) {
          dispatch(deleteWorkpaperWithSocketPayloadAction(webSocketPayload));
        }
        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(removeWPFromElementPanelAction(webSocketPayload));
        }

        break;

      case NOTIFICATION_EVENT_TYPES.WORKPAPER_UPDATE:
        if (left === LEFT_PANELS.WORKPAPER) {
          dispatch(updateWorkpaperWithSocketPayloadAction(webSocketPayload));
        }
        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(updateWPFromElementPanelAction(webSocketPayload));
        }

        break;

      case NOTIFICATION_EVENT_TYPES.NOTE_DELETE:
        /*
          expected payload: 
            {
                "revisionId": 2,
                "sectionId": 13,
                "noteId": 150,
                "pseudoElementId": 1
            }
        */
        /*
          since there is no batch delete for notes, we do not need a specific payload or event type
          to update the Summary Notes. Therefore, we can always subtract 1 to the notes summary when 
          a note is deleted
        */
        dispatch(updateSummaryNotesFromWebsocket(-1));

        // this action removes the deleted note from the note cache
        dispatch(removeNoteFromCacheWithSocketPayloadAction(webSocketPayload));

        // this action deletes the affected note from the notes panel only if the notes panel is open
        if (right === RIGHT_PANELS.NOTES) {
          dispatch(deleteNoteWithWebsocketPayload(webSocketPayload));
          dispatch(notesListLoadedWithoutReponse());
        }

        // this action removes the deleted note from the content only if the user has the affected section in view
        if (webSocketPayload.sectionId in elementCache) {
          // take the first instance of the span tag and delete the previous sibling
          // which should be the note svg
          document
            .querySelector(
              `[id*="CFTO_PSEUDO_ELEMENT_${webSocketPayload.pseudoElementId}"]`,
            )
            .previousSibling.remove();

          // get list of all elements with CFTO_PSEUDO_ELEMENT class
          let pseudoElements = document.querySelectorAll(
            `[class*="CFTO_PSEUDO_ELEMENT_${webSocketPayload.pseudoElementId}"]`,
          );

          // remove the span tags and only render the innerHTML
          for (let i = 0; i < pseudoElements.length; i++) {
            const element = pseudoElements[i];
            element.outerHTML = element.innerHTML;
          }
        }
        break;

      case NOTIFICATION_EVENT_TYPES.NOTE_ADD:
        /*
          since there is no batch delete for notes, we do not need a specific payload or event type
          to update the Summary Notes. Therefore, we can always add 1 to the notes summary when 
          a note is deleted
        */
        dispatch(updateSummaryNotesFromWebsocket(1));

        // when we add a note we still need to make this fetch call to update the content panel
        // we will only make this call if the affected section is in view
        if (webSocketPayload.sectionId in elementCache) {
          dispatch(
            fetchAllSectionRenderingDataWithoutLoading({
              sectionId: webSocketPayload.sectionId,
            }),
          );
        }

        // this action adds the affected note from the notes panel only if the notes panel is open
        if (right === RIGHT_PANELS.NOTES) {
          // We need to modify the websocket payload to include additional details like parentId and isTechnical for particular section.
          // We expected to get these details from BE directly, but that they are facing some technical issues (inner join to fetch these
          // details causes ghost notes to dissappear from the list), which enforces us to fetch these details from sections cache.
          const { contentSectionMap } =
            getStore().getState().data.statementContent.sectionsCache;
          const sectionCacheData =
            contentSectionMap &&
            contentSectionMap.data &&
            contentSectionMap.data.map;
          let webSocketPayloadModified =
            sectionCacheData &&
            webSocketPayload.sectionId &&
            sectionCacheData[webSocketPayload.sectionId]
              ? {
                  ...webSocketPayload,
                  parentId:
                    sectionCacheData[webSocketPayload.sectionId].parentId,
                  isTechnical:
                    sectionCacheData[webSocketPayload.sectionId].isTechnical,
                }
              : webSocketPayload;
          dispatch(addNoteWithWebsocketPayload(webSocketPayloadModified));
          dispatch(notesListLoadedWithoutReponse());

          // only set the new note as selected if the user that created the note matches the id in the
          // current user reducer
          if (currentUser.id === webSocketPayloadModified.noteTaker) {
            webSocketPayloadModified.isNew = true;
            dispatch(
              selectNoteFromNoteListAction({
                note: new Note(webSocketPayloadModified),
              }),
            );
          }
        }

        break;

      case NOTIFICATION_EVENT_TYPES.NOTE_UPDATE:
        /*
          sample payload for note update. 
          payload = {
            "noteId":1313,
            "revisionId":1140,
            "sectionId":0,
            "pseudoElementId":1,
            "elementId":-1,
            "noteType":"I",
            "workflowStatus":"O",
            "closedBy":null,
            "closedByName":null,
            "body":"{\"note\":\"Nevada note\",\"replies\":{}}",
            "noteDate":"2021-09-21T19:11:48Z",
            "closedDate":null,
            "noteTaker":130,
            "noteTakerName":"Lakshmi Reddyreddy",
            "notePriorityType":"NORMAL",
            "revisionNumber":1
          }

          NOTE: for note Updates we receive a reduced payload and not the entire note entity
        */

        if (right === RIGHT_PANELS.NOTES) {
          dispatch(updateNoteWithWebsocketPayload(webSocketPayload));
        }

        if (webSocketPayload.sectionId in elementCache) {
          dispatch(
            updateNoteFromNoteCacheWithSocketPayloadAction(webSocketPayload),
          );
        }
        break;
      case NOTIFICATION_EVENT_TYPES.HEADING_UPDATE:
        if (currentUser.id !== webSocketPayload.updatedBy) {
          dispatch(setStatementNavigationRefresh(true));
        }
        break;
      case NOTIFICATION_EVENT_TYPES.HEADING_REVIEW:
        dispatch(
          appendSectionToSectionReviewList(webSocketPayload.sectionReview),
        );
        if (
          right === RIGHT_PANELS.SECTION &&
          webSocketPayload &&
          webSocketPayload.sectionReview &&
          webSocketPayload.sectionReview.sectionId === selectedSection.id
        ) {
          dispatch(appendSectionReviewHistory(webSocketPayload.sectionHistory));
        }
        break;
      case NOTIFICATION_EVENT_TYPES.HEADING_UNREVIEW:
        dispatch(
          removeSectionReviewFromStore({
            sectionId: webSocketPayload.sectionId,
            userId: webSocketPayload.userId,
          }),
        );
        if (
          right === RIGHT_PANELS.SECTION &&
          webSocketPayload.sectionId === selectedSection.id
        ) {
          dispatch(appendSectionReviewHistory(webSocketPayload));
        }
        break;
      default:
        break;

      case NOTIFICATION_EVENT_TYPES.TICKMARK_UPDATE:
        if (left === LEFT_PANELS.TICKMARK) {
          dispatch(updateTickmarkWithSocketPayloadAction(webSocketPayload));
        }
        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(
            updateTickmarkInElementPanelWithSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }

        break;

      case NOTIFICATION_EVENT_TYPES.TICKMARK_DELETE:
        if (left === LEFT_PANELS.TICKMARK) {
          dispatch(deleteTickmarkWithSocketPayloadAction(webSocketPayload));
        }
        if (right === RIGHT_PANELS.ELEMENT) {
          dispatch(
            removeTickmarkFromElementPanelWithSocketPayloadAction(
              webSocketPayload,
            ),
          );
        }
        break;
      case NOTIFICATION_EVENT_TYPES.ADD_SECTION_HEADING:
        {
          let sectionAssignmentListPayload = webSocketPayload.userIds.map(
            (userId) => {
              return {
                revisionId: webSocketPayload.revisionId,
                sectionId: webSocketPayload.sectionId,
                userId: userId,
                userName: getUserNameByUserData(userId, projectUsersList.users),
                date: webSocketPayload.createdDate,
              };
            },
          );

          dispatch(
            updateSectionAssignmentListFromSocket({
              sectionAssignmentListPayload,
              sectionId: webSocketPayload.sectionId,
              currentUserId: currentUser.id,
            }),
          );
          if (
            right === RIGHT_PANELS.SECTION &&
            selectedSection.id === webSocketPayload.sectionId
          ) {
            dispatch(replaceNewWithSocketData(sectionAssignmentListPayload));
            dispatch(
              fetchSectionHistoryDetails({
                revisionId: webSocketPayload.revisionId,
                sectionId: webSocketPayload.sectionId,
              }),
            );
          }
          if (left === LEFT_PANELS.STATEMENT_NAV) {
            dispatch(setHeadingAssignmentRefresh(true));
          }
        }
        break;

      case NOTIFICATION_EVENT_TYPES.HEADING_DELETE:
        {
          let sectionAssignmentListPayload = []; //Sending an empty payload in case of heading delete
          dispatch(
            updateSectionAssignmentListFromSocket({
              sectionAssignmentListPayload,
              sectionId: webSocketPayload.sectionId,
              currentUserId: currentUser.id,
            }),
          );
          if (left === LEFT_PANELS.STATEMENT_NAV) {
            dispatch(setHeadingAssignmentRefresh(true));
          }
        }
        break;
      case NOTIFICATION_EVENT_TYPES.TICKMARK_LINKED:
        if (right === RIGHT_PANELS.ELEMENT) {
          const elementId = elementDetails.id;
          dispatch(
            elementTickmarkListFromWebSocketPayloadAction({
              webSocketPayload: webSocketPayload.attachedTickmark,
              elementId,
            }),
          );
        }
        if (left === LEFT_PANELS.TICKMARK) {
          dispatch(addTickmarkToListAction(webSocketPayload));
        }
        //we call the element update function here because for tickmark element update event, we are not going to get anything for element update event
        _elementUpdate(webSocketPayload.elementEntities);
        if (elementSearchResults.isLoaded) {
          dispatch(showElementSearchRefreshButtonAction(true));
        }
        break;
      case NOTIFICATION_EVENT_TYPES.TICKMARK_UNLINKED:
        if (left === LEFT_PANELS.TICKMARK) {
          dispatch(unlinkTickmarkFromElementWithWebsocket(webSocketPayload));
        }

        if (right === RIGHT_PANELS.ELEMENT) {
          const elementId = elementDetails.id;
          const tickmarkIdSet = new Set();
          for (let i = 0; i < webSocketPayload.length; i++) {
            webSocketPayload[i].elementId === elementId &&
              tickmarkIdSet.add(webSocketPayload[i].tickmarkId);
          }

          tickmarkIdSet.size > 0 &&
            dispatch(removeTickmarkWithSocketPayloadInBulk(tickmarkIdSet));
          dispatch(elementTickmarkListLoadedWithoutResponse());
        }

        // this action will determine if the element search content component will render the
        // filter refresh button

        if (elementSearchResults.isLoaded) {
          dispatch(showElementSearchRefreshButtonAction(true));
        }

        break;

      case NOTIFICATION_EVENT_TYPES.TOOLKIT_EXPORT_REPORT_STATUS:
        // check if the update is for concerned revisionId and userID
        if (
          webSocketPayload.revisionId === revision.id &&
          webSocketPayload.requestedUser === currentUser.id
        ) {
          dispatch(downloadReportAction(webSocketPayload));
        }
        break;

      case NOTIFICATION_EVENT_TYPES.BULK_SECTION_ASSIGN:
        {
          let sectionAssignmentListPayload = [];
          webSocketPayload &&
            webSocketPayload.sectionIds &&
            webSocketPayload.sectionIds.forEach((sectionId) => {
              webSocketPayload.userIds.forEach((userId) => {
                sectionAssignmentListPayload.push({
                  revisionId: webSocketPayload.revisionId,
                  sectionId: sectionId,
                  userId: userId,
                  userName: getUserNameByUserData(
                    userId,
                    projectUsersList.users,
                  ),
                  date: webSocketPayload.createdDate,
                });
              });
            });

          dispatch(
            updateSectionAssignmentListForBulkAssignFromSocket({
              sectionAssignmentListPayload,
              currentUserId: currentUser.id,
            }),
          );
          if (
            right === RIGHT_PANELS.SECTION &&
            webSocketPayload &&
            webSocketPayload.sectionIds &&
            webSocketPayload.sectionIds.includes(selectedSection.id)
          ) {
            dispatch(
              updateSectionAssignmentInBulkFromSocket(
                sectionAssignmentListPayload,
              ),
            );
            dispatch(
              fetchSectionHistoryDetails({
                revisionId: webSocketPayload.revisionId,
                sectionId: selectedSection.id,
              }),
            );
          }
          if (left === LEFT_PANELS.STATEMENT_NAV) {
            dispatch(setHeadingAssignmentRefresh(true));
          }
        }
        break;

      case NOTIFICATION_EVENT_TYPES.BULK_SECTION_UNASSIGN:
        dispatch(
          updateSectionAssignmentListForBulkUnAssignFromSocket({
            sectionAssignmentListPayload: webSocketPayload.sectionIds,
            currentUserId: currentUser.id,
          }),
        );
        if (
          right === RIGHT_PANELS.SECTION &&
          webSocketPayload &&
          webSocketPayload.sectionIds &&
          webSocketPayload.sectionIds.includes(selectedSection.id)
        ) {
          dispatch(updateSectionAssignmentInBulkFromSocket([]));
          dispatch(
            fetchSectionHistoryDetails({
              revisionId: webSocketPayload.revisionId,
              sectionId: selectedSection.id,
            }),
          );
        }
        if (left === LEFT_PANELS.STATEMENT_NAV) {
          dispatch(setHeadingAssignmentRefresh(true));
        }

        break;

      case NOTIFICATION_EVENT_TYPES.BLACK_LINE_VIEW_GENERATED:
        dispatch(refetchSectionsInView());
        blacklineTogleNotification(BLACKLINE_VIEW_STATUS.COMPLETED);
        break;
      // TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
      // case NOTIFICATION_EVENT_TYPES.USER_DISCONNECTED:
      //   dispatch(removeUserFromRoomAction(webSocketPayload));
      //   break;
    }
  }
}
