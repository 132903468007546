import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Card from 'components/common/card-component';
import Dropdown from 'components/common/dropdown-component';
import { ReactComponent as InfoIcon } from 'icons/icon-information-tooltip.svg';
import TextInput from 'components/common/text-input-component';
import { SearchTableDropdownApi } from 'components/common/search-component';
import { EngagementList } from 'models/api/engagement-list-model';
import { DataGridDataApi } from 'components/common/data-grid/data-grid-component';
import { BACKSPACE_KEY_CODE } from 'constants/util/key-code-constants';
import { ENGAGEMENT_SEARCH_COLUMNS } from 'constants/feature/project-edit-constants';
import {
  debounceSearch,
  getSearchFormattedEngagementEntity,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import ProjectForm from 'models/form/project-form-model';
import { getEngagementEntitiesRequest } from 'api/project-creation-edit-api';
import Switch from 'react-switch';
import NonAuditInputArea from 'components/feature/project-edit/project-info/_project-edit-project-info-non-audit-text-input';
import ConditionalRender from 'components/util/conditional-render-component';

export const EDIT_PROJECT_INFO_BLOCK = 'edit-project-info';
const EDIT_PROJECT_INFO_ID_BLOCK = 'edit-project-info';
const SWITCH_HEIGHT = 20;
const SWITCH_WIDTH = 45;

class EditProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      engagementEntities: new EngagementList(),
    };
    // do not call this function directly, call _searchUser, which uses the debounced version of this function
    this.debouncedSearch = debounceSearch(this._fetchEngagementEntities);
  }

  componentDidMount() {
    // init the search value with the properly formatted entity string
    // not an anti-pattern because this is setting an initial value and
    // the project prop can not be changed anywhere else in the application
    const { projectInProgress, setSearch } = this.props;

    let searchText = '';

    if (projectInProgress.auditClient) {
      searchText = getSearchFormattedEngagementEntity(
        projectInProgress.engagementEntity,
      );
    }
    this.setState({
      search: searchText,
    });
    setSearch(searchText);
  }

  _searchEngagement = (val) => {
    this.setState({ search: val }, () => {
      if (isSearchCharLengthValid(val)) {
        this.debouncedSearch({ search: val });
      }
    });
  };

  _fetchEngagementEntities = async ({ search }) => {
    this.setState((state) => ({
      engagementEntities: state.engagementEntities.setLoading(),
    }));
    try {
      const response = await getEngagementEntitiesRequest(search);
      this.setState((state) => ({
        engagementEntities: state.engagementEntities.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        engagementEntities: state.engagementEntities.setError(error),
      }));
    }
  };

  _setEngagementEntity = (entity) => {
    const {
      setEngagementEntity,
      setNonAuditEngagementEntityDataHasBeenModified,
      setSearch,
    } = this.props;

    this.setState(
      {
        search: getSearchFormattedEngagementEntity(entity),
      },
      () => {
        setEngagementEntity(entity);
        setNonAuditEngagementEntityDataHasBeenModified(true);
        setSearch(getSearchFormattedEngagementEntity(entity));
      },
    );
  };

  _setEnagementData = () => {
    const { projectInProgress, setEngagementEntity, setSearch } = this.props;

    this._searchEngagement('');
    setSearch('');
    if (projectInProgress.nonAuditEngagementEntityDataHasBeenModified) {
      setEngagementEntity(null);
    }
  };

  render() {
    const {
      setProjectName,
      projectInProgress,
      setIsAuditClient,
      setClientName,
      setEngagementName,
      setEngagementFye,
      setNonAuditReason,
      countryList,
      setProjectCountry,
      setNonAuditEngagementEntityDataHasBeenModified,
    } = this.props;
    const { search, engagementEntities } = this.state;
    return (
      <div className={EDIT_PROJECT_INFO_BLOCK}>
        <Card>
          <div className={`${EDIT_PROJECT_INFO_BLOCK}__title`}>
            <FormattedMessage id={'project-edit.project-info.title'} />
          </div>
          <div className={`${EDIT_PROJECT_INFO_BLOCK}__text-input`}>
            <TextInput
              id={`${EDIT_PROJECT_INFO_ID_BLOCK}-project-name-text-input`}
              label={'project-edit.project-info.project-name'}
              width={'75%'}
              disabled={false}
              placeholder={'project-edit.project-info.project-name.placeholder'}
              onChange={(text) => {
                setProjectName(text);
              }}
              value={projectInProgress.name}
              maxLength={100}
              required={true}
              isValid={true}
              errorText={{ id: 'project-edit-input-error-text' }}
              type={'text'}
            />
          </div>
          <div className={`${EDIT_PROJECT_INFO_BLOCK}__display-horizontal`}>
            <div className={`${EDIT_PROJECT_INFO_BLOCK}__region`}>
              <div className={`${EDIT_PROJECT_INFO_BLOCK}__region-label`}>
                <FormattedMessage
                  id={'project-creation.project-info.project-region'}
                />
              </div>
              <div className={`${EDIT_PROJECT_INFO_BLOCK}__region-name`}>
                {projectInProgress && projectInProgress.geoCode}
              </div>
            </div>
            <ConditionalRender dependencies={[countryList]}>
              <div className={`${EDIT_PROJECT_INFO_BLOCK}__dropdown`}>
                <Dropdown
                  id={`${EDIT_PROJECT_INFO_ID_BLOCK}-project-country`}
                  label={'project-creation.project-info.project-country'}
                  placeholder={'common.placeholder.select'}
                  onSelectOption={(option) => {
                    setProjectCountry(option);
                  }}
                  options={countryList.getCountryMenuOptionByGeoCode(
                    projectInProgress.geoCode,
                  )}
                  value={projectInProgress.countryOfIssuanceDropdownValue}
                  iconConfig={{
                    infoText: 'project-creation.project-country.tooltip.info',
                    tooltipInfoId: `${EDIT_PROJECT_INFO_ID_BLOCK}-info-tooltip`,
                    iconComponent: InfoIcon,
                  }}
                  disabled
                />
              </div>
            </ConditionalRender>
          </div>
          <div className={`${EDIT_PROJECT_INFO_BLOCK}__switch`}>
            <div className={`${EDIT_PROJECT_INFO_BLOCK}__switch-label`}>
              <FormattedMessage
                id={'project-creation.project-info.non-audit-input-header'}
              />
            </div>
            <Switch
              onChange={(val) => {
                setIsAuditClient(val);
                this.setState({ search: '' });
                setNonAuditEngagementEntityDataHasBeenModified(false);
              }}
              checked={!projectInProgress.auditClient}
              uncheckedIcon={false}
              checkedIcon={false}
              height={SWITCH_HEIGHT}
              width={SWITCH_WIDTH}
              disabled={!projectInProgress.isClientNameValid()}
            />
          </div>
          {projectInProgress.auditClient ? (
            <div className={`${EDIT_PROJECT_INFO_BLOCK}__search`}>
              <SearchTableDropdownApi
                columns={ENGAGEMENT_SEARCH_COLUMNS}
                id={`${EDIT_PROJECT_INFO_ID_BLOCK}-client-name-search`}
                label={'project-edit.project-info.client-search'}
                width={'75%'}
                disabled={false}
                placeholder={
                  'project-edit.project-info.client-search.placeholder'
                }
                onChange={(val) => this._searchEngagement(val)}
                onClear={() => {
                  this._setEnagementData();
                }}
                onKeyDown={(e) =>
                  e.keyCode === BACKSPACE_KEY_CODE && e.target.value === ''
                    ? this._setEnagementData()
                    : null
                }
                value={search}
                onSelectResult={this._setEngagementEntity}
                required={true}
                isValid={true}
                errorText={{ id: 'project-edit-input-error-text' }}
                type={'text'}
                showSearchButton={true}
                resultsApiModel={
                  new DataGridDataApi({
                    apiModel: engagementEntities,
                    rowItems: engagementEntities.getEngagementEntities(),
                  })
                }
              />
            </div>
          ) : (
            <NonAuditInputArea
              setClientName={setClientName}
              setEngagementName={setEngagementName}
              setEngagementFye={setEngagementFye}
              projectInProgress={projectInProgress}
              setNonAuditReason={setNonAuditReason}
            />
          )}
        </Card>
      </div>
    );
  }
}

EditProjectInfo.propTypes = {
  /** Func that fires action to set project name */
  setProjectName: PropTypes.func.isRequired,
  /** Func that fires action to set engagement entity*/
  setEngagementEntity: PropTypes.func.isRequired,
  /** Object that contains working data for form before submission*/
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Func that set the isAuditClient toggle */
  setIsAuditClient: PropTypes.func.isRequired,
  /** Function to set the client name */
  setClientName: PropTypes.func.isRequired,
  /** Function to set the engagement name */
  setEngagementName: PropTypes.func.isRequired,
  /** Function to set the fiscal year */
  setEngagementFye: PropTypes.func.isRequired,
  /** Function to set the non audit reason */
  setNonAuditReason: PropTypes.func.isRequired,
  /** Function to check if non audit client has been modified*/
  setNonAuditEngagementEntityDataHasBeenModified: PropTypes.func.isRequired,
  /** This function is used to set search value to get in the parent component*/
  setSearch: PropTypes.func.isRequired,
};

export default EditProjectInfo;
