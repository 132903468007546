import { createAction } from 'redux-actions';
import { onStatementNavSectionSelect } from '../statement-content-actions';
import { scrollLeftSectionIntoView } from 'utils/scrolling-utils';
import { getSectionReviewersRequest } from 'api/sections-api';
import { CONTEXT_KEY } from 'api/api-client';

export const setSourceStatementElementMapAction = createAction(
  'SET_SOURCE_STATEMENT_ELEMENT_MAP_ACTION',
);

export const setTargetStatementElementMapAction = createAction(
  'SET_TARGET_STATEMENT_ELEMENT_MAP_ACTION',
);

export const clearSideBySideElementsMap = createAction(
  'CLEAR_SIDE_BY_SIDE_ELEMENTS_MAP',
);

export const replaceSelectedSectionsInLeftContentPanel = createAction(
  'REPLACE_SELECTED_SECTIONS_LEFT_STATEMENT_CONTENT_PANEL',
);
export const deallocateSelectedSectionsInLeftContentPanel = createAction(
  'DEALLOCATE_SELECTED_SECTIONS_LEFT_STATEMENT_CONTENT_PANEL',
);

export const fetchLeftSectionReviewsError = createAction(
  'LEFT_FETCH_SECTIONS_REVIEWS_ERROR',
);

export const fetchLeftSectionReviewsLoaded = createAction(
  'LEFT_FETCH_SECTIONS_REVIEWS_LOADED',
);

export const setTargetStatementBatchElementsMapAction = createAction(
  'SET_TARGET_STATEMENT_BATCH_ELEMENTS_MAP_ACTION',
);
export const setSourceStatementBatchElementsMapAction = createAction(
  'SET_SOURCE_STATEMENT_BATCH_ELEMENTS_MAP_ACTION',
);

export const onRightStatementNavSectionClick =
  ({ sectionId, color }) =>
  async (dispatch, getState) => {
    dispatch(onStatementNavSectionSelect({ sectionId, color }));
  };

export const onLeftStatementNavSectionClick =
  ({ sectionId, color }) =>
  async (dispatch, getState) => {
    dispatch(
      replaceSelectedSectionsInLeftContentPanel({
        sectionIds: [sectionId],
        color,
      }),
    );
    scrollLeftSectionIntoView(sectionId);
  };

export const fetchLeftSectionReviews =
  (statementId) => async (dispatch, getStore) => {
    const { leftRevision } = getStore().data;
    const { sourceStatementParams } = getStore().ui;
    try {
      const response = await getSectionReviewersRequest(
        leftRevision.id,
        CONTEXT_KEY.PROJECT_CONTEXT,
        sourceStatementParams.projectId,
        statementId,
      );
      dispatch(fetchLeftSectionReviewsLoaded({ response }));
    } catch (error) {
      dispatch(fetchLeftSectionReviewsError(error));
    }
  };
