import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TieoutBuildInfo } from 'models/api/tieout-build-info-model';
import { getBuildInfoRequest } from 'api/tieout-info-api';
import {
  // TERMS_OF_USE_ANCHOR,
  PRIVACY_ANCHOR,
  NOTICES_ANCHOR,
  ABOUT_ANCHOR,
  COOKIES_ANCHOR,
  COOKIES_SETTINGS,
} from 'constants/footer-constants';

const FOOTER_BLOCK = 'footer';
const FOOTER_BLOCK_ID = 'footer_id';

const Footer = () => {
  const [buildInfo, setBuildInfo] = useState(new TieoutBuildInfo());

  React.useEffect(() => {
    const fetchBuildInfo = async () => {
      setBuildInfo(buildInfo.setLoading());
      try {
        const response = await getBuildInfoRequest();
        setBuildInfo(buildInfo.setLoaded({ response }));
      } catch (error) {
        setBuildInfo(buildInfo.setError(error));
      }
    };

    fetchBuildInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={FOOTER_BLOCK}>
      <div className={`${FOOTER_BLOCK}__left`}>
        <div className={`${FOOTER_BLOCK}__terms`}>
          <FormattedMessage
            id={'footer.message.coppyright'}
            values={{
              currentYear: new Date().getFullYear(),
              //termsOfUse: TERMS_OF_USE_ANCHOR,
              privacy: PRIVACY_ANCHOR,
              notices: NOTICES_ANCHOR,
              cookies: COOKIES_ANCHOR,
              cookiesSettings: COOKIES_SETTINGS,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id={'footer.message.info'}
            values={{
              about: ABOUT_ANCHOR,
              br: <br key={`${FOOTER_BLOCK_ID}-brTag`} />,
            }}
          />
        </div>
      </div>
      <div className={`${FOOTER_BLOCK}__right`}>
        <div>
          <FormattedMessage
            id={'footer.version-number'}
            // values={{ version: buildInfo.projectVersion }}
          />
        </div>
        <div>
          <FormattedMessage
            id={'footer.build-number'}
            values={{
              build: buildInfo.vstsBuild,
              region: window.TIEOUT.ENV.CURRENT_GEO,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
