import React, { Component } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import KebabDropdown from 'components/common/dropdown-component';
import Radio from 'components/common/radio-component';
import { FormattedMessage } from 'react-intl';
import { RadioOption } from 'models/utils/common/radio-options-model';
import {
  EXPORT_PAGE_ORIENTATION_PORTRAIT,
  EXPORT_PAGE_SIZE_OBJECT,
} from 'constants/feature/statement-content-constants';
import Loading from 'components/common/loading-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

const EXPORT_WORKPAPER_ID_BLOCK = 'export-workpaper-modal-id';
export const EXPORT_WORKPAPER_MODAL_BLOCK = 'export-workpaper-modal';

export const TOOLKIT_DISABLED_DOWNLAOD_BUTTON_TOOLTIP = new TooltipOptions({
  id: `${EXPORT_WORKPAPER_ID_BLOCK}__workpaper-modal-disabled-downlaod-button-tooltip-id`,
  text: 'toolkit-report-disabed-downlaod-button-tooltip-downlaod.is.in.progress',
  position: 'top',
});
class ExportWorkpaperModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOrientation: EXPORT_PAGE_ORIENTATION_PORTRAIT,
      pageSize: Object.values(EXPORT_PAGE_SIZE_OBJECT)[0],
      loading: false,
    };
  }

  async onSubmit() {
    const { onClose, callback } = this.props;
    const { pageOrientation, pageSize } = this.state;

    try {
      this.setState({ loading: true });
      await callback({
        pageOrientation,
        pageSize: pageSize.value,
      });
      this.setState({ loading: false });
      onClose();
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      title,
      description,
      validation,
      onClose,
      isDownloadButtonDisabled,
    } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        id={EXPORT_WORKPAPER_ID_BLOCK}
        title={title}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'export-workpaper-label',
            onClick: this.onSubmit.bind(this),
            disabled: loading || isDownloadButtonDisabled,
            tooltip:
              loading || isDownloadButtonDisabled
                ? TOOLKIT_DISABLED_DOWNLAOD_BUTTON_TOOLTIP
                : null,
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.close',
            onClick: onClose,
          })
        }
      >
        {loading ? <Loading /> : null}
        <p className={`${EXPORT_WORKPAPER_MODAL_BLOCK}__instructions`}>
          <FormattedMessage id={description} />
        </p>
        <p className={`${EXPORT_WORKPAPER_MODAL_BLOCK}__instructions`}>
          <FormattedMessage id="export-workpaper-static-text-3" />
        </p>

        <div className={`${EXPORT_WORKPAPER_MODAL_BLOCK}__form-item`}>
          <div className={'form-item__label'}>
            <FormattedMessage id="export-workpaper-orientation-label" />
          </div>

          <Radio
            id={'export-page-orientation'}
            className={'navigation-insertion__radio'}
            options={[
              new RadioOption({
                id: 'Portrait',
                label: 'export-workpaper-pageorientation-portrait',
                value: 'Portrait',
              }),
              new RadioOption({
                id: 'Landscape',
                label: 'export-workpaper-pageorientation-landscape',
                value: 'Landscape',
              }),
            ]}
            width="auto"
            isValid
            selectedOption={this.state.pageOrientation}
            onChange={(val) => {
              this.setState({ pageOrientation: val });
            }}
          />
        </div>

        <div className={`${EXPORT_WORKPAPER_MODAL_BLOCK}__form-item`}>
          <div className={'form-item__label'}>
            <FormattedMessage id="export-workpaper-pagesize-label" />
          </div>
          <KebabDropdown
            className={'page-size__kebab'}
            menuClassName={'page-size__kebab-menu'}
            id={'export-kebab'}
            options={Object.values(EXPORT_PAGE_SIZE_OBJECT)}
            width={'100%'}
            onClickFormItem={(e) => {
              e.stopPropagation();
            }}
            value={this.state.pageSize}
            onSelectOption={(option) => {
              this.setState({ pageSize: option });
            }}
          />
        </div>
        {validation && (
          <span
            className={`${EXPORT_WORKPAPER_MODAL_BLOCK}__validation-message`}
          >
            <strong>
              <FormattedMessage id={validation} />
            </strong>
          </span>
        )}
      </Modal>
    );
  }
}

export default ExportWorkpaperModal;
