import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

//stores all the featureFlag data coming from backend,
//data is the map of geoCode to featureFlag. ex. {AME: {featureFlags}, EMA: {featureFlags}}
export class FeatureInfo extends ApiModel({
  data: {},
}) {
  processResponse({ response }) {
    return {
      data: response,
    };
  }

  getCarryForwardMetaDataEnabled(geo) {
    const featureConfig = this.data[geo];
    return isNullOrUndefined(featureConfig)
      ? false
      : featureConfig.carryForwardMetaDataEnabled;
  }

  getTechSupportStatementAccessEnabled(geo) {
    const featureConfig = this.data[geo];
    return isNullOrUndefined(featureConfig)
      ? false
      : featureConfig.techSupportStatementAccessEnabled;
  }
}
