export const NUMBER_WITH_COMMAS_REGEX = /\B(?=(\d{3})+(?!\d))/g;
export const COMMA_REGEX = /,/g;
export const DUPLICATE_LEADING_ZERO_REGEX = /^0+(?=\d)/g;
export const EMPTY_REGEX = /^$/g;
export const FLOATING_POINT_NUMBER_REGEX = /^[-+]?[0-9]*\.?[0-9]+$/;
export const NUMBER_PATTERN_REGEX = /\d+/g;

const _formatNumber = (number, regex, replacement) => {
  if (number) {
    number += '';
    const numberParts = number.split('.');
    let integerPart = numberParts[0];
    //We are only updating the integer part of the number (the part before the decimal)
    integerPart = integerPart.toString().replace(regex, replacement);
    const decimalPart =
      numberParts.length > 1
        ? '.' + numberParts.slice(1, numberParts.length).join('.')
        : '';

    return integerPart + decimalPart;
  } else if (number === 0) {
    return number;
  }
  return '';
};

export const formatNumberWithCommas = (number) => {
  return _formatNumber(number, NUMBER_WITH_COMMAS_REGEX, ',');
};

export const removeCommasFromNumber = (number) => {
  return _formatNumber(number, COMMA_REGEX, '');
};

export const removeDuplicateLeadingZerosFromNumber = (number) => {
  if (number + '' === '0') {
    return '0';
  }
  return _formatNumber(number, DUPLICATE_LEADING_ZERO_REGEX, '');
};

export const addLeadingZeroToEmptyDecimal = (number) => {
  return _formatNumber(number, EMPTY_REGEX, '0');
};

export const formatManualInputFormulaRowNumber = (number) => {
  return formatNumberWithCommas(
    addLeadingZeroToEmptyDecimal(
      removeDuplicateLeadingZerosFromNumber(removeCommasFromNumber(number)),
    ),
  );
};
