import { handleActions } from 'redux-actions';

import {
  addToLeftCurrentSectionIds,
  removeFromLeftCurrentSectionIds,
} from 'store/actions/current-section-actions';

export default handleActions(
  {
    [addToLeftCurrentSectionIds]: (currentSectionIds, { payload }) => {
      return [...currentSectionIds, payload];
    },
    [removeFromLeftCurrentSectionIds]: (currentSectionIds, { payload }) => {
      return currentSectionIds.filter((sectionId) => sectionId !== payload);
    },
  },
  [],
);
