import { getLanguageData } from 'api/statement-creation-page-data-api';
import {
  LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER,
  TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
  getMessagesForLocale,
} from 'constants/common/feature-common-utils';
import { createAction } from 'redux-actions';

export const setLocale = createAction('SET_LOCALE');

// export const setLocaleMessage = createAction('SET_LOCALE_MESSAGE');
// export const clearLocaleMessage = createAction('CLEAR_LOCALE_MESSAGE');

export const fetchAndUpdateLocaleMessage = () => async (dispatch, getState) => {
  const { ui: { locale } = {} } = getState();
  const localeMessages = getMessagesForLocale(locale);
  if (
    locale &&
    // if messages does not exist or it has expired for specific locale then only we want to make api call.
    !(
      localeMessages &&
      localeMessages.data &&
      localeMessages.expiry > new Date().getTime()
    )
  ) {
    try {
      const { response: { result: data } = {} } = await getLanguageData(locale);

      // append time to live with locale message
      const localeMessagesWithTTL = {
        ...data,
        expiry: new Date().getTime() + TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
      };

      localStorage.setItem(
        `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}${locale}`,
        JSON.stringify(localeMessagesWithTTL),
      );
    } catch (e) {
      console.error(e);
    }
  }
};
