import React from 'react';

const LOADING_BLOCK = 'loading';

const Loading = () => {
  return (
    <div className={LOADING_BLOCK}>
      <div className="lds-ellipsis">
        <div id="dot-1" />
        <div id="dot-2" />
        <div id="dot-3" />
        <div id="dot-4" />
      </div>
    </div>
  );
};

export default Loading;
