import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ProjectApi from 'models/api/project-api-model';
import Statement from 'models/data/statement-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import StatementWrapUpStats from '../_statement-wrap-up-stats-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import {
  rejectStatementWrapUpRequest,
  getStatementApprovalStatsRequest,
} from 'api/statement-list-api';
import StatementWrapUpApprovalStats from 'models/api/statement-wrap-up/statement-wrap-up-approval-stats-api-model';
import { getUserNameByUserDataObject } from 'utils/statement-content-page-utils';

const STATEMENT_WRAP_UP_APPROVAL_1_ID_BLOCK =
  'statement-wrap-up-approval-modal-1-id';

export const STATEMENT_WRAP_UP_APPROVAL_1_BLOCK =
  'statement-wrap-up-approval-modal-1';

const ApproveStatementWrapUpModal1 = ({
  statement,
  project,
  onClose,
  onDecline,
  onApprove,
}) => {
  const [approvalStats, setApprovalStats] = useState(
    new StatementWrapUpApprovalStats(),
  );
  useEffect(() => {
    const loadStatementDetails = async () => {
      setApprovalStats((s) => s.setLoading());
      try {
        const response = await getStatementApprovalStatsRequest({
          statementId: statement.id,
        });
        setApprovalStats((s) => s.setLoaded({ response }));
      } catch (error) {
        setApprovalStats((s) => s.setError(error));
      }
    };
    loadStatementDetails();
  }, [statement]);

  const handleDecline = async () => {
    setApprovalStats((s) => s.setLoading());
    try {
      await rejectStatementWrapUpRequest({ statementId: statement.id });
      onDecline();
    } catch (error) {
      setApprovalStats((s) => s.setError(error));
    }
  };
  return (
    <Modal
      id={STATEMENT_WRAP_UP_APPROVAL_1_ID_BLOCK}
      className={STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}
      title={'statement-list.wrap-up-approval.modal.1.label.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'statement-list.wrap-up-approval.modal.1.approve-begin',
          onClick: onApprove,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'statement-list.wrap-up-approval.modal.1.decline',
          onClick: handleDecline,
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'statement-list.wrap-up-approval.modal.1.cancel',
          onClick: onClose,
        })
      }
    >
      <ConditionalRender dependencies={[project, approvalStats]}>
        <Banner
          id={`${STATEMENT_WRAP_UP_APPROVAL_1_ID_BLOCK}-warning-banner`}
          type={BANNER_TYPES.WARNING}
          bannerCopy={{
            id: 'statement-list.wrap-up-approval.modal.1.warning',
            values: {
              submitterName: getUserNameByUserDataObject(
                approvalStats.submitUser,
              ),
              submitterEmail: approvalStats.submitUser.email,
              submissionDate: moment(approvalStats.submitDate).format(
                DATE_FORMATS.MONTH_DAY_YEAR,
              ),
            },
          }}
          isInternationalized={true}
          isInteractive={false}
        />
        <div className={`${STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}__row`}>
          <div className={`${STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}__col`}>
            <span className={`${STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}__label`}>
              {project.name}
            </span>
            <span className={`${STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}__value`}>
              {statement.statementName}
            </span>
          </div>
        </div>
        <StatementWrapUpStats
          stats={approvalStats.asWrapUpStatsModel()}
          className={`${STATEMENT_WRAP_UP_APPROVAL_1_BLOCK}__row`}
        />
      </ConditionalRender>
    </Modal>
  );
};

ApproveStatementWrapUpModal1.propTypes = {
  /** Statement model to be wrapped up */
  statement: PropTypes.instanceOf(Statement).isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when approval is confirmed */
  onApprove: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  data: {
    selectedProject: { project },
  },
}) => ({
  project,
});

export { ApproveStatementWrapUpModal1 };
export default connect(mapStateToProps, null)(ApproveStatementWrapUpModal1);
