import React, { Component } from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';

const EDIT_SECTION_ID_BLOCK = 'EDIT_SECTION_ID_BLOCK';

class DeleteConfirmationModal extends Component {
  render() {
    const { hasChildren, onClose, onDelete } = this.props;

    return (
      <Modal
        id={EDIT_SECTION_ID_BLOCK}
        title={
          hasChildren
            ? 'edit-section-modal.confirm.delete.has.child.title'
            : 'edit-section-modal.confirm.delete.has.no.child.title'
        }
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.yes',
            onClick: () => onDelete(),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.no',
            onClick: onClose,
          })
        }
      ></Modal>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  /** Flaf indicate the section has subsection or not */
  hasChildren: PropTypes.bool.isRequired,
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** Confirm deletion fucntion  */
  onDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
