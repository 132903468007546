import {
  UNSELECTED_FILTER,
  IS_MENTIONED_FILTER,
  OBSERVER_TYPE_FILTER,
} from 'constants/feature/notes/notes-panel-filter-constants';
import {
  NOTE_TYPES,
  NOTE_PRIORITY_TYPES,
} from 'constants/feature/notes-panel-constants';
import {
  FILTER_STATUS,
  FILTER_PRIORITY,
  FILTER_TYPE,
  FILTER_GHOST,
  FILTER_USER,
  FILTER_HEADING,
} from 'constants/feature/notes-panel-constants';
import { getStore } from 'store/store';

export const hasNotesFilters = (
  noteFilters,
  createdNotesUserObject,
  repliedNotesUserObject,
  userslistCreatedAndReplied,
  userFilterMentionedUsers,
) => {
  const isCreatedUserListChecked = (createdNotesUserObject) => {
    for (const user in createdNotesUserObject) {
      if (createdNotesUserObject[user]) return true;
    }
    return false;
  };

  const isRepliedUserListChecked = (repliedNotesUserObject) => {
    for (const user in repliedNotesUserObject) {
      if (repliedNotesUserObject[user]) return true;
    }
    return false;
  };

  const isMentionedListChecked = (userFilterMentionedUsers) => {
    for (const user in userFilterMentionedUsers) {
      if (userFilterMentionedUsers[user]) return true;
    }
    return false;
  };

  if (
    noteFilters.user === true &&
    !isCreatedUserListChecked(createdNotesUserObject)
  )
    return false;
  if (
    noteFilters.user === false &&
    !isRepliedUserListChecked(repliedNotesUserObject)
  )
    return false;
  if (
    noteFilters.user === IS_MENTIONED_FILTER &&
    !isMentionedListChecked(userFilterMentionedUsers)
  )
    return false;

  return (
    noteFilters.heading !== UNSELECTED_FILTER ||
    noteFilters.status !== UNSELECTED_FILTER ||
    noteFilters.type !== UNSELECTED_FILTER ||
    noteFilters.priority !== UNSELECTED_FILTER ||
    noteFilters.ghost !== UNSELECTED_FILTER ||
    (noteFilters.user === UNSELECTED_FILTER &&
      Object.keys(userslistCreatedAndReplied).length > 0) ||
    (noteFilters.user === true &&
      isCreatedUserListChecked(createdNotesUserObject)) ||
    (noteFilters.user === false &&
      isRepliedUserListChecked(repliedNotesUserObject)) ||
    (noteFilters.user === IS_MENTIONED_FILTER &&
      isMentionedListChecked(userFilterMentionedUsers))
  );
};

export const applyNoteFilters = (
  notes,
  filtersObject = {},
  usersListNotesCreatedBy = {},
  usersListNotesRepliedBy = {},
  selectedSegments = {},
  userslistCreatedAndReplied = {},
  userFilterMentionedUsers = {},
  assignedToMe = '',
) => {
  if (
    !hasNotesFilters(
      filtersObject,
      usersListNotesCreatedBy,
      usersListNotesRepliedBy,
      userslistCreatedAndReplied,
      userFilterMentionedUsers,
    ) &&
    !assignedToMe
  )
    return notes;

  const filteredNotesList = notes.filter((note) => {
    const {
      notePriorityType,
      noteType,
      sectionId,
      parentId,
      isTechnical,
    } = note;

    const isNotePriority = notePriorityType === NOTE_PRIORITY_TYPES.HIGH;
    const isNoteNonPriority = notePriorityType === NOTE_PRIORITY_TYPES.NORMAL;
    const isGhostNote = note.ghost === 1;
    const isNoteClosed = note.closedBy === null ? false : true;
    const isNoteTypeClient = noteType === NOTE_TYPES.CLIENT.short;
    const isNoteTypeReview = noteType === NOTE_TYPES.REVIEW.short;
    const isNoteTypeObserver = noteType === NOTE_TYPES.OBSERVER.short;
    const isNoteCreatedByUser = Boolean(
      usersListNotesCreatedBy[note.noteTaker],
    );

    const isNoteRepliedByUser = () => {
      const repliesArray = Object.values(note.body.replies);
      for (let index = 0; index < repliesArray.length; index++) {
        const replierId = repliesArray[index].userId;
        if (usersListNotesRepliedBy[replierId]) {
          return true;
        }
      }
      return false;
    };
    const isSectionIdSelected =
      (isTechnical && selectedSegments[parentId]) ||
      selectedSegments[sectionId];
    const isNoteMentionedOrCreatedOrRepliedByUser = () => {
      if (note.noteTaker in userslistCreatedAndReplied) {
        return true;
      } else {
        const repliesArray = Object.values(note.body.replies);
        for (let index = 0; index < repliesArray.length; index++) {
          const replierId = repliesArray[index].userId;
          if (replierId in userslistCreatedAndReplied) return true;
        }
      }
      return isNoteMentionedByUser(userslistCreatedAndReplied);
    };

    const isNoteMentionedByUser = (userIdToUserNameObject) => {
      for (const taggedUser in userIdToUserNameObject) {
        // we need to check if the taggedUsers property is in the note body first
        if (note.body.taggedUsers) {
          if (taggedUser in note.body.taggedUsers) {
            return true;
          }
        }

        const repliesArray = Object.values(note.body.replies);
        for (let index = 0; index < repliesArray.length; index++) {
          const reply = repliesArray[index];
          // we need to check if the taggedUsers properpty is in the reply body
          if (reply.taggedUsers) {
            if (taggedUser in reply.taggedUsers) {
              return true;
            }
          }
        }
      }

      return false;
    };

    const isNoteMentionedToCurrentUser = (currentUser) => {
      // we need to check if the taggedUsers property is in the note body first
      if (note.body.taggedUsers) {
        if (currentUser in note.body.taggedUsers) {
          return true;
        }
      }
      const repliesArray = Object.values(note.body.replies);
      for (let index = 0; index < repliesArray.length; index++) {
        const reply = repliesArray[index];
        // we need to check if the taggedUsers properpty is in the reply body
        if (reply.taggedUsers) {
          if (currentUser in reply.taggedUsers) {
            return true;
          }
        }
      }

      return false;
    };

    let filterKeys = Object.keys(filtersObject).filter(
      (key) => filtersObject[key] !== UNSELECTED_FILTER,
    );

    if (Object.keys(userslistCreatedAndReplied).length > 0) {
      filterKeys.push(FILTER_USER);
    }

    for (let index = 0; index < filterKeys.length; index++) {
      const key = filterKeys[index];

      if (key === FILTER_HEADING) {
        // filter by statement segments
        if (!isSectionIdSelected) return false;
      }

      if (key === FILTER_PRIORITY) {
        // high priority
        if (filtersObject[key] && !isNotePriority) {
          return false;
        }

        // non-priority
        if (filtersObject[key] === false && !isNoteNonPriority) {
          return false;
        }
      }

      if (key === FILTER_TYPE) {
        // note type of review
        if (filtersObject[key] === true && !isNoteTypeReview) {
          return false;
        }

        // note type of client
        if (filtersObject[key] === false && !isNoteTypeClient) {
          return false;
        }

        //note type of Observer
        if (
          filtersObject[key] === OBSERVER_TYPE_FILTER &&
          !isNoteTypeObserver
        ) {
          return false;
        }
      }

      if (key === FILTER_STATUS) {
        // open note
        if (filtersObject[key] && isNoteClosed) {
          return false;
        }

        // closed note
        if (filtersObject[key] === false && !isNoteClosed) {
          return false;
        }
      }

      if (key === FILTER_GHOST) {
        // ghost note
        if (filtersObject[key] && !isGhostNote) {
          return false;
        }

        // non-ghost note
        if (filtersObject[key] === false && isGhostNote) {
          return false;
        }
      }

      if (key === FILTER_USER) {
        // notes that are created by a user
        if (filtersObject[key] === true && !isNoteCreatedByUser) {
          return false;
        }

        // notes that are replied by a user
        if (filtersObject[key] === false && !isNoteRepliedByUser()) {
          return false;
        }

        // notes that are either created or replied by a user
        if (
          filtersObject[key] === UNSELECTED_FILTER &&
          !isNoteMentionedOrCreatedOrRepliedByUser()
        ) {
          return false;
        }

        // notes that are mentioned by a user in the note body or reply
        if (
          filtersObject[key] === IS_MENTIONED_FILTER &&
          !isNoteMentionedByUser(userFilterMentionedUsers)
        ) {
          return false;
        }
      }
    }
    if (assignedToMe && !isNoteMentionedToCurrentUser(assignedToMe)) {
      return false;
    }

    return true;
  });

  return filteredNotesList;
};

export const toApiNoteFormat = (note) => {
  const { projectUsersList } = getStore().getState().data;
  const users = projectUsersList.data.userIdToUserNameMap;
  let div = document.createElement('div');
  div.innerHTML = note;
  let tags = div.getElementsByTagName('span');
  for (let index = 0; index < tags.length; index++) {
    let replaceFromStr = tags[index].outerHTML;
    let id = tags[index].getAttribute('cfto_user_id');
    let replaceToStr;
    if (!users[id]) replaceToStr = tags[index].innerHTML;
    else replaceToStr = `<span cfto_user_id="${id}"></span>`;
    note = note.replace(replaceFromStr, replaceToStr);
  }
  return note;
};

export const toDisplayNoteFormat = (note) => {
  const { projectUsersList } = getStore().getState().data;
  const users = projectUsersList.data.userIdToUserNameMap;
  let div = document.createElement('div');
  div.innerHTML = note;
  let tags = div.getElementsByTagName('span');
  for (let index = 0; index < tags.length; index++) {
    let replaceFromStr = tags[index].outerHTML;
    let id = tags[index].getAttribute('cfto_user_id');
    let name = users[id];
    let replaceToStr = `<span cfto_user_id="${id}">@${name}</span>`;
    note = note.replace(replaceFromStr, replaceToStr);
  }
  return note;
};
