import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import AuditQuestion from 'models/data/audit-question-model';

export default class AuditQuestionsChecklist extends ApiModel({
  data: {
    questionsChecklist: [],
    auditFinalChecklistStatus: null,
  },
}) {
  get questionsChecklist() {
    if (this.hasQuestionsChecklist()) {
      return this.data.questionsChecklist;
    }
    return [];
  }

  processResponse({ response }) {
    return {
      data: {
        questionsChecklist: response.data.result.questionList.map(
          (auditQuestion) => new AuditQuestion(auditQuestion),
        ),
        auditFinalChecklistStatus:
          response.data.result.auditChecklistStatement
            .auditFinalChecklistStatus,
      },
    };
  }

  hasQuestionsChecklist() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.questionsChecklist) &&
      this.data.questionsChecklist.length > 0
    );
  }

  updateChecklistData(payload) {
    let questionsChecklistCopy = [...this.data.questionsChecklist];
    for (
      let currentQuestionIndex = 0;
      currentQuestionIndex < questionsChecklistCopy.length;
      currentQuestionIndex++
    ) {
      if (
        payload.payload.response.data.result.id ===
        questionsChecklistCopy[currentQuestionIndex].id
      ) {
        questionsChecklistCopy[currentQuestionIndex].auditAnswer =
          payload.payload.response.data.result.auditAnswer;
      }
    }
    return this.mergeData({
      questionsChecklist: questionsChecklistCopy.map(
        (auditQuestion) => new AuditQuestion(auditQuestion),
      ),
    });
  }
}
