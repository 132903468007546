import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import ShowLinkedAnnotations from './_copy-annotation-linked-elements-modal-component';
import { useState } from 'react';
import PropTypes from 'prop-types';
import InternalReference from '../../../../models/api/internal-reference-model';

const COPY_ANNOTATION_CONTROLLER_BLOCK = 'copy-annotation';
const COPY_ANNOTATION_CONTROLLER_ID = 'copy-annotation-id';

const CopyAnnotationButton = ({
  componentClassNameConstant = '',
  disabled,
  internalReference,
}) => {
  const [showCopyAnnotationModal, setShowCopyAnnotationModal] = useState(false);

  return (
    <div>
      <Button
        id={`${COPY_ANNOTATION_CONTROLLER_ID}-copy-all-button`}
        className={classNames(
          `${COPY_ANNOTATION_CONTROLLER_BLOCK}__copy-all-annotation-class`,
          componentClassNameConstant,
        )}
        type={BUTTON_TYPES.primary}
        disabled={disabled}
        onClick={() => setShowCopyAnnotationModal((prev) => !prev)}
      >
        <FormattedMessage
          id={'internal-reference-panel-listing-copy-annotation-ir.button'}
        />
      </Button>
      {showCopyAnnotationModal && (
        <ShowLinkedAnnotations
          onClickCancel={() => setShowCopyAnnotationModal((prev) => !prev)}
          internalReference={internalReference}
        />
      )}
    </div>
  );
};

CopyAnnotationButton.propTypes = {
  /**Custom class used for component specific styles */
  componentClassNameConstant: PropTypes.string,
  /**If copy annotation button has to be kept disabled */
  disabled: PropTypes.bool,
  /**The model the represents the internal reference which is used in the internal reference panel. */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
};

export default CopyAnnotationButton;
