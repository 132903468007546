import { handleActions } from 'redux-actions';

import {
  currentUserLoading,
  currentUserError,
  currentUserLoaded,
} from 'store/actions/current-user-actions';
import { setUserHomeGeo } from 'store/actions/user-profile-actions';
import { CurrentUser } from 'models/api/current-user-model';

export default handleActions(
  {
    [currentUserLoading]: (currentUser) => {
      return currentUser.setLoading();
    },
    [currentUserError]: (currentUser, { payload }) => {
      return currentUser.setError(payload);
    },
    [currentUserLoaded]: (currentUser, { payload }) => {
      return currentUser.setLoaded(payload);
    },
    [setUserHomeGeo]: (currentUser, { payload }) => {
      const { response: { result: { geoCode } = {} } = {} } = payload;
      return currentUser.setHomeGeo(geoCode);
    },
  },
  new CurrentUser(),
);
