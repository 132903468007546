import React from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CANCEL_MODAL_ID_BLOCK = 'statement-processing-cancel-modal-id';

const CancelConfirmationModal = (props) => {
  const { onClose, onDelete } = props;

  return (
    <Modal
      id={CANCEL_MODAL_ID_BLOCK}
      title={'statement-processing.cancel-confirm-modal.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: () => onDelete(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: onClose,
        })
      }
    >
      <FormattedMessage id="statement-processing.cancel-confirm-modal.body" />
    </Modal>
  );
};

CancelConfirmationModal.propTypes = {
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** Confirm deletion fucntion  */
  onDelete: PropTypes.func.isRequired,
};

export default CancelConfirmationModal;
