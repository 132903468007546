import { handleActions } from 'redux-actions';

import {
  userAuthLoading,
  userAuthError,
  userAuthLoaded,
  userAuth401Error,
} from 'store/actions/user-auth-actions';
import { AuthUser } from 'models/api/auth-user-model';

export default handleActions(
  {
    [userAuthLoading]: (authUser) => {
      return authUser.setLoading();
    },
    [userAuthError]: (authUser, { payload }) => {
      return authUser.setError(payload);
    },
    [userAuthLoaded]: (authUser, { payload }) => {
      return authUser.setLoaded(payload);
    },
    [userAuth401Error]: (authUser, { payload }) => {
      return authUser.set401Error(payload);
    },
  },
  new AuthUser(),
);
