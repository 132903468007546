import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import {
  SIGN_OFF_HISTORY_OPTIONS,
  SYSTEM_USER_NAME,
} from 'constants/feature/section-details-constants';
import PropTypes from 'prop-types';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';

export const SECTION_DETAILS_HISTORY_ELEMENT = 'section-history-element';

const SectionDetailsHistoryElement = ({ history }) => {
  const { userName, userId, revisionNumber, sectionAction, date } = history;
  const fullUserName = userId >= 0 ? getUserNameByUserId(userId) : userName;

  return (
    <div className={SECTION_DETAILS_HISTORY_ELEMENT}>
      <p className={`${SECTION_DETAILS_HISTORY_ELEMENT}__first-line`}>
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.REVIEW && (
          <FormattedMessage
            id={'section-panel.history.section-reviewed'}
            values={{
              userName: fullUserName,
            }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.UNREVIEW &&
          userName.trim() !== SYSTEM_USER_NAME && (
            <FormattedMessage
              id={'section-panel.history.section-unreviewed'}
              values={{
                userName: getUserNameByUserId(userId),
              }}
            />
          )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.UNREVIEW &&
          userName.trim() === SYSTEM_USER_NAME && (
            <FormattedMessage id={'section-panel.history.system-unreview'} />
          )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.LABEL_UPDATED && (
          <FormattedMessage
            id={'section-panel.history.heading-label-updated'}
            values={{
              userName: fullUserName,
            }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.ENTITY_UPDATED && (
          <FormattedMessage
            id={'section-panel.history.heading-entity-updated'}
            values={{
              userName: fullUserName,
            }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.CREATE && (
          <FormattedMessage
            id={'section-panel.history.create-element'}
            values={{
              userName: fullUserName,
            }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.ASSIGN_TO && (
          <FormattedMessage
            id={'section-panel.history.assign-to'}
            values={{ userName: fullUserName }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.UNASSIGN_TO && (
          <FormattedMessage
            id={'section-panel.history.unassign-to'}
            values={{ userName: fullUserName }}
          />
        )}
        {sectionAction === SIGN_OFF_HISTORY_OPTIONS.UNASSIGN_ALL && (
          <FormattedMessage id={'section-panel.history.unassign-all'} />
        )}
      </p>
      <p>
        <FormattedMessage
          id={'section-panel.history.element-history-date'}
          values={{
            revisionNumber: revisionNumber,
            year: moment(date).format(DATE_FORMATS.MONTH_DAY_YEAR),
            time: moment(date).format(DATE_FORMATS.HOURS_MINUTES),
          }}
        />
      </p>
    </div>
  );
};

SectionDetailsHistoryElement.propTypes = {
  /** history object from the section detail store */
  history: PropTypes.object.isRequired,
};

export default SectionDetailsHistoryElement;
