import React, { useEffect } from 'react';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'utils/object-utils';
import EngagagementList from 'components/feature/omnia/_omnia-engagement-list-component';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';
import { fetchOmniaEngagement } from 'store/actions/omnia-engagement-actions';
import OmniaEngagement from 'models/api/omnia-engagement-model';
import ConditionalRenderComponent from 'components/util/conditional-render-component';

export const OMNIA_PAGE_BLOCK = 'omnia-page';
const OMNIA_PAGE_ID_BLOCK = 'omnia-page-id';

const OMNIA_ICON_SIZE = '40';

const OmniaEngagementPage = ({
  push,
  fetchOmniaEngagement,
  omniaEngagement,
}) => {
  const engagementId = sessionStorage.getItem('omEngagementId');
  const geoCode = omniaEngagement.getGeoName();
  useEffect(() => {
    !isNullOrUndefined(geoCode) &&
      fetchOmniaEngagement({ engagementId, geoCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementId, fetchOmniaEngagement]);

  return (
    <ConditionalRenderComponent
      dependencies={[{ error: null, isLoading: false, isLoaded: true }]}
    >
      <div className={OMNIA_PAGE_BLOCK}>
        <Button.IconButton
          id={`${OMNIA_PAGE_ID_BLOCK}__back-button`}
          className={`${OMNIA_PAGE_BLOCK}__back-button`}
          type={BUTTON_TYPES.icon}
          Icon={BackButton}
          onClick={() => push(ROUTE_CONSTANTS.HOME_PAGE)}
        >
          <div className={`${OMNIA_PAGE_BLOCK}__back-title`}>
            <FormattedMessage id={'common.back'} />
          </div>
        </Button.IconButton>
        <div>
          <h1 className={`${OMNIA_PAGE_BLOCK}__page-title`}>
            <FormattedMessage id={'omnia-page.title'} />
          </h1>
        </div>
        {isNullOrUndefined(omniaEngagement.omniaEngagementEntity) ? (
          <>
            <div className={`${OMNIA_PAGE_BLOCK}__no-engagements`}>
              <div>
                <FormattedMessage id={'omnia-page.no-omnia-engagements'} />
              </div>
            </div>
            <div className={`${OMNIA_PAGE_BLOCK}__guidance-1`}>
              <FormattedMessage id={'omnia-page.guidance-1.no-engagements'} />
            </div>
            <div className={`${OMNIA_PAGE_BLOCK}__guidance-2`}>
              <FormattedMessage id={'omnia-page.guidance-2'} />
            </div>
            <div className={`${OMNIA_PAGE_BLOCK}__guidance-3`}>
              <FormattedMessage id={'omnia-page.guidance-3'} />
            </div>
          </>
        ) : (
          <>
            <div className={`${OMNIA_PAGE_BLOCK}__project-info`}>
              <div className={`${OMNIA_PAGE_BLOCK}__engagement`}>
                <OmniaIcon
                  className={`${OMNIA_PAGE_BLOCK}__omnia-icon`}
                  width={OMNIA_ICON_SIZE}
                  height={OMNIA_ICON_SIZE}
                />
                <div>
                  <div className={`${OMNIA_PAGE_BLOCK}__project-info-title`}>
                    <FormattedMessage
                      id={'omnia.confirm-link-modal.engagement-name'}
                    />
                  </div>
                  <div
                    className={`${OMNIA_PAGE_BLOCK}__project-info-engagement-name`}
                  >
                    {omniaEngagement.omniaEngagementEntity.engagementName}
                  </div>
                </div>
              </div>
              <div>
                <div className={`${OMNIA_PAGE_BLOCK}__project-info-title`}>
                  <FormattedMessage
                    id={'omnia.confirm-link-modal.client-name'}
                  />
                </div>
                <div
                  className={`${OMNIA_PAGE_BLOCK}__project-info-client-name`}
                >
                  {omniaEngagement.omniaEngagementEntity.clientName}
                </div>
              </div>
            </div>

            <div className={`${OMNIA_PAGE_BLOCK}__projects-available-title`}>
              <FormattedMessage id={'omnia-page.projects-available-title'} />
            </div>
            <div className={`${OMNIA_PAGE_BLOCK}__guidance-1`}>
              <FormattedMessage id={'omnia-page.guidance-1'} />
            </div>
          </>
        )}
        {!isNullOrUndefined(geoCode) && (
          <EngagagementList omniaEngagement={omniaEngagement} />
        )}
      </div>
    </ConditionalRenderComponent>
  );
};

OmniaEngagementPage.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** func to fetch omnia engagement */
  fetchOmniaEngagement: PropTypes.func.isRequired,
  /** The omnia engagement obj from store */
  omniaEngagement: PropTypes.instanceOf(OmniaEngagement).isRequired,
};

const mapStateToProps = ({ data: { omniaEngagement } }) => ({
  omniaEngagement,
});

const mapDispatchToProps = {
  push,
  fetchOmniaEngagement,
};

export { OmniaEngagementPage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OmniaEngagementPage);
