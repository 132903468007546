import ApiModel from 'models/api-model';
import User from 'models/data/user-model';
import { getUserNameByUserData } from 'utils/statement-content-page-utils';

export default class ProjectUsersList extends ApiModel({
  data: {
    users: [],
    usersListForMentionsTextArea: [],
    userIdToUserNameMap: {},
  },
}) {
  get users() {
    if (this.data) {
      return this.data.users;
    }
    return null;
  }

  get usersListForMentionsTextArea() {
    if (this.data) {
      return this.data.usersListForMentionsTextArea;
    }
    return null;
  }

  get userIdToUserNameMap() {
    if (this.data && this.data.userIdToUserNameMap) {
      return this.data.userIdToUserNameMap;
    }
    return null;
  }

  processResponse({ response }) {
    let usersMap = {};
    for (let i = 0; i < response.data.length; i++) {
      const user = response.data[i];
      usersMap[user.id] = getUserNameByUserData(user.id, response.data);
    }

    return {
      data: {
        users: response.data.map((user) => {
          return new User(user);
        }),
        usersListForMentionsTextArea: response.data.map((user) => {
          return {
            id: user.id.toString(),
            display: getUserNameByUserData(user.id, response.data),
          };
        }),
        userIdToUserNameMap: usersMap,
      },
      status: response.status,
    };
  }
}
