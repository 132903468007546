import React from 'react';
import PropTypes from 'prop-types';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
const RESTORE_REVISION_ID_BLOCK = 'restore-deleted-revision-modal-id';

const RestoreRevisionErrorModal = ({ toggleRestoreModal, onOkay }) => {
  return (
    <Modal
      id={`${RESTORE_REVISION_ID_BLOCK}`}
      title={'statement-list.restore-revisions-modal.title'}
      onClose={toggleRestoreModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.okay',
          onClick: onOkay ? onOkay : toggleRestoreModal,
        })
      }
    >
      <div className="modal__text">
        <FormattedMessage id="statement-list.restore-modal.message" />
      </div>
    </Modal>
  );
};

RestoreRevisionErrorModal.propTypes = {
  /** Method to provided to modal to show and hide modal */
  toggleRestoreModal: PropTypes.func.isRequired,
  /** Method that handles what happens when the okay button is clicked */
  onOkay: PropTypes.func.isRequired,
};

export default RestoreRevisionErrorModal;
