import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from 'icons/down-arrow.svg';
import { ReactComponent as Pencil } from 'icons/edit-pencil.svg';
import { ReactComponent as TaskReviewedIcon } from 'icons/task-reviewed.svg';
import { ReactComponent as TaskIcon } from 'icons/task.svg';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { smoothScrollIntoTopById } from 'utils/scrolling-utils';

export const TREE_VIEW_BLOCK = 'tree-view';
export const TREE_VIEW_ID_BLOCK = 'tree-view-id';
const EDIT_SECTION_MODAL = 'statement-content-panel--edit-section';

const TreeView = ({
  expanded,
  children,
  toggle,
  hidden,
  highlighted,
  id,
  parentId = null,
  label,
  onSelect,
  scrollBasedHighlighted,
  navigationEditMode,
  onOpenEditNavigation,
  sectionReview,
  panel,
  isNew,
  recentlyAdded,
  setNewSectionId,
  isSideBySideViewMode,
}) => {
  // this useEffect works as componentDidMount, it is going to scroll to the current treeView if its new and is being previewed
  useEffect(() => {
    if (isNew || recentlyAdded) {
      smoothScrollIntoTopById(`${TREE_VIEW_ID_BLOCK}-${id}`);

      return () => {
        //if there was a recently added section, when this component umount
        // we should reset the newSectionId to avoid unnecessary scrolling
        if (recentlyAdded) {
          setNewSectionId(null);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExpandCollapse = ({ expandable }) => (
    <div
      className={classNames(
        `${TREE_VIEW_BLOCK}__arrow`,
        !expanded && `${TREE_VIEW_BLOCK}__arrow--collapsed`,
      )}
      onClick={(e) => {
        toggle(id);
        e.stopPropagation();
      }}
    >
      {expandable && <ArrowDown />}
    </div>
  );

  const renderIcon = () => {
    return (
      ((panel !== LEFT_PANELS.COMPARE && panel === LEFT_PANELS.STATEMENT_NAV) ||
        isSideBySideViewMode) && (
        <div className={`${TREE_VIEW_BLOCK}__item--col2`}>
          {!isNew &&
          sectionReview &&
          sectionReview.hasSectionBeenReviewed(id) ? (
            <TaskReviewedIcon />
          ) : (
            <TaskIcon />
          )}
        </div>
      )
    );
  };

  return (
    <div
      className={`${TREE_VIEW_BLOCK}`}
      id={`${TREE_VIEW_ID_BLOCK}-${id}-${isNew ? 'new' : 'existing'}`}
    >
      <div
        className={classNames(
          `${TREE_VIEW_BLOCK}__item`,
          expanded && `${TREE_VIEW_BLOCK}__item--expanded`,
          highlighted && `${TREE_VIEW_BLOCK}__item--highlighted`,
          scrollBasedHighlighted &&
            `${TREE_VIEW_BLOCK}__item--scroll-highlighted`,
          isNew && `${TREE_VIEW_BLOCK}__item--new-item`,
        )}
        style={{
          display: hidden ? 'none' : null,
        }}
      >
        <div className={`${TREE_VIEW_BLOCK}__item--col1`}>
          <ExpandCollapse expandable={children} />
          {parentId !== null && navigationEditMode && (
            <Pencil
              onClick={onOpenEditNavigation}
              className={`${EDIT_SECTION_MODAL}__pencil-icon`}
            />
          )}
          <span onClick={() => onSelect(id)} title={label}>
            {label}
          </span>
        </div>
        {renderIcon()}
      </div>
      {children && (
        <div
          className={classNames(
            `${TREE_VIEW_BLOCK}__children`,
            !expanded && `${TREE_VIEW_BLOCK}__children--collapsed`,
          )}
        >
          {expanded && children}
        </div>
      )}
    </div>
  );
};

TreeView.propTypes = {
  /** Flag for whether this row is expanded */
  expanded: PropTypes.bool,
  /** Function for expanding/closing this row */
  toggle: PropTypes.func.isRequired,
  /** Flag for if this row should be hidden */
  hidden: PropTypes.bool.isRequired,
  /** Flag for if this row should be highlighted */
  highlighted: PropTypes.bool.isRequired,
  /** Unique id corresponding to the data that is represented by this row, returned as the argument in `toggle` prop */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Label of the row, displayed to the right of the caret */
  label: PropTypes.string.isRequired,
  /** Flag for if this row should be highlighted based on scroll position */
  scrollBasedHighlighted: PropTypes.bool.isRequired,
  /** Action fired on click of the label */
  onSelect: PropTypes.func.isRequired,
  /** Flag for heading editable function */
  navigationEditMode: PropTypes.bool,
  /** Heading editable function */
  onOpenEditNavigation: PropTypes.func.isRequired,
  /** Indicates which left panel is open */
  panel: PropTypes.string,
  /* bool value that indicates if a sections is new */
  isNew: PropTypes.bool.isRequired,
  /* bool value that indicates if a section has been recently added */
  recentlyAdded: PropTypes.bool.isRequired,
  /* action to set the new section value */
  setNewSectionId: PropTypes.func,
};

export default TreeView;
