import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'react-intl';
import StatementWrapUpStatsModel from 'models/api/statement-wrap-up/statement-wrap-up-stats-api-model';

export const WRAP_UP_STATS_BLOCK = 'statement-wrap-up-stats';

const StatementWrapUpStats = ({ stats, className, notAllElementsVerified }) => {
  return (
    <div className={classnames(WRAP_UP_STATS_BLOCK, className)}>
      <div
        className={classnames(
          `${WRAP_UP_STATS_BLOCK}__col`,
          `${WRAP_UP_STATS_BLOCK}__col--centered`,
        )}
      >
        <span className={`${WRAP_UP_STATS_BLOCK}__label`}>
          <FormattedMessage id="statement-list.submit-wrap-up-approval.modal.label.percent-verified" />
        </span>
        <span
          className={`${WRAP_UP_STATS_BLOCK}__value  ${
            notAllElementsVerified && `${WRAP_UP_STATS_BLOCK}__not-verified`
          }`}
        >
          <FormattedMessage
            id="statement-list.submit-wrap-up-approval.modal.value.percent-verified"
            values={{
              percentage: stats.verifiedPercentage,
            }}
          />
        </span>
      </div>
      <div className={`${WRAP_UP_STATS_BLOCK}__col`}>
        <span className={`${WRAP_UP_STATS_BLOCK}__label`}>
          <FormattedMessage id="statement-list.submit-wrap-up-approval.modal.label.total-verified" />
        </span>
        <div className={`${WRAP_UP_STATS_BLOCK}__value-container`}>
          <span
            className={`${WRAP_UP_STATS_BLOCK}__value  ${
              notAllElementsVerified && `${WRAP_UP_STATS_BLOCK}__not-verified`
            }`}
          >
            <FormattedMessage
              id="statement-list.submit-wrap-up-approval.modal.value.total-verified-first"
              values={{
                verified: stats.verifiedElements,
              }}
            />
          </span>
          <span className={`${WRAP_UP_STATS_BLOCK}__value`}>
            &thinsp;
            <FormattedMessage
              id="statement-list.submit-wrap-up-approval.modal.value.total-verified-second"
              values={{
                total: stats.totalElements,
              }}
            />
          </span>
        </div>
      </div>
      <div
        className={classnames(
          `${WRAP_UP_STATS_BLOCK}__col`,
          `${WRAP_UP_STATS_BLOCK}__col--centered`,
        )}
      >
        <span className={`${WRAP_UP_STATS_BLOCK}__label`}>
          <FormattedMessage id="statement-list.submit-wrap-up-approval.modal.label.percent-reviewed" />
        </span>
        <span className={`${WRAP_UP_STATS_BLOCK}__value`}>
          <FormattedMessage
            id="statement-list.submit-wrap-up-approval.modal.value.percent-reviewed"
            values={{
              percentage: stats.reviewedPercentage,
            }}
          />
        </span>
      </div>
      <div className={`${WRAP_UP_STATS_BLOCK}__col`}>
        <span className={`${WRAP_UP_STATS_BLOCK}__label`}>
          <FormattedMessage id="statement-list.submit-wrap-up-approval.modal.label.total-reviewed" />
        </span>
        <span className={`${WRAP_UP_STATS_BLOCK}__value`}>
          <FormattedMessage
            id="statement-list.submit-wrap-up-approval.modal.value.total-reviewed"
            values={{
              reviewed: stats.reviewedElements,
              total: stats.totalElements,
            }}
          />
        </span>
      </div>
      <div
        className={classnames(
          `${WRAP_UP_STATS_BLOCK}__col`,
          `${WRAP_UP_STATS_BLOCK}__col--centered`,
        )}
      >
        <span className={`${WRAP_UP_STATS_BLOCK}__label`}>
          <FormattedMessage id="statement-list.submit-wrap-up-approval.modal.label.notes" />
        </span>
        <span className={`${WRAP_UP_STATS_BLOCK}__value`}>
          {stats.totalNotes}
        </span>
      </div>
    </div>
  );
};

StatementWrapUpStats.propTypes = {
  /** Stats model containing data */
  stats: PropTypes.instanceOf(StatementWrapUpStatsModel).isRequired,
  /** optional classname applied to top level block */
  className: PropTypes.string,
  /* Boolean to know if al elements are verified */
  notAllElementsVerified: PropTypes.bool,
};

export default StatementWrapUpStats;
