import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import WorkpaperEntryWithDetach from 'components/feature/element-panel/annotations/workpaper-entry/workpaper-entry-with-detach-component';

export const ALREADY_LINKED_WORKPAPERS_BLOCK = 'already-linked-workpapers';

const AlreadyLinkedWorkpapers = ({ workpaperList, onDetachWorkpaper }) => {
  return (
    <div className={ALREADY_LINKED_WORKPAPERS_BLOCK}>
      <h4 className={`${ALREADY_LINKED_WORKPAPERS_BLOCK}__title`}>
        <FormattedMessage id="workpaper-panel.linked-section.title" />
      </h4>
      {workpaperList.workpapers.map((workpaper) => (
        <WorkpaperEntryWithDetach
          key={workpaper.referenceNumber}
          workpaper={workpaper}
          onDetach={onDetachWorkpaper}
        />
      ))}
    </div>
  );
};

AlreadyLinkedWorkpapers.propTypes = {
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** action fired to detach a work paper from this element */
  onDetachWorkpaper: PropTypes.func.isRequired,
};

export default memo(AlreadyLinkedWorkpapers);
