import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import Tooltip from 'components/common/tool-tip-component';

import { ReactComponent as PlusCircleIcon } from 'icons/plus-circle.svg';

const ICON_WITH_CHILDREN_SIZE = 20;

const BUTTON_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  icon: 'icon',
  delete: 'delete',
};

const BLOCK = 'button';

const Button = React.forwardRef(
  (
    {
      children,
      className,
      type,
      disabled,
      id,
      onClick,
      tooltip,
      isTooltipRequiredForDisabled = false,
    },
    ref,
  ) =>
    // when button is disabled, tooltip does not shows up.
    // To fix that we need to wrap button isnide some span or div with display set to inline-block
    // https://stackoverflow.com/questions/13311574/how-to-enable-bootstrap-tooltip-on-disabled-button
    isTooltipRequiredForDisabled ? (
      <Tooltip id={id} {...tooltip} active={tooltip ? true : false}>
        <span className={classNames('disabled-button-tootlip-wrapper')}>
          <button
            id={id}
            ref={ref}
            disabled={disabled}
            onClick={onClick}
            className={classNames(BLOCK, `${BLOCK}__${type}`, className)}
          >
            {children}
          </button>
        </span>
      </Tooltip>
    ) : (
      <Tooltip id={id} {...tooltip} active={tooltip ? true : false}>
        <button
          id={id}
          ref={ref}
          disabled={disabled}
          onClick={onClick}
          className={classNames(BLOCK, `${BLOCK}__${type}`, className)}
        >
          {children}
        </button>
      </Tooltip>
    ),
);

Button.IconButton = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      id,
      onClick,
      tooltip,
      Icon = PlusCircleIcon,
      iconSize = ICON_WITH_CHILDREN_SIZE,
    },
    ref,
  ) => (
    <Button
      ref={ref}
      disabled={disabled}
      type={BUTTON_TYPES.icon}
      id={id}
      onClick={onClick}
      tooltip={tooltip}
      className={className}
    >
      <Icon
        className={classNames(
          `${BLOCK}__${BUTTON_TYPES.icon}__icon`,
          disabled ? `${BLOCK}__${BUTTON_TYPES.icon}__icon--disabled` : null,
        )}
        width={iconSize}
        height={iconSize}
      />
      {children}
    </Button>
  ),
);

const buttonProps = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /**true value means a component wants to show tooltip when the button is disabled.
   * This extra prop types is required, since to show tooltip for disabled button we
   * need to wrap it inside span or div.
   * https://stackoverflow.com/questions/13311574/how-to-enable-bootstrap-tooltip-on-disabled-button
   */
  isTooltipRequiredForDisabled: PropTypes.bool,
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  ...buttonProps,
};

Button.IconButton.propTypes = {
  ...buttonProps,
  /**The Icon to be rendered in the IconButton,
   * it is fully customizable, but does require that its svg path propertyhave the class `.icon-path`
   * for proper-styling. By default uses the PlusCircleIcon if none is provided.  */
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default Button;
export { BUTTON_TYPES };
