import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';

import ConditionalRender from 'components/util/conditional-render-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import NoProjects from 'components/feature/project-details/_project-details-no-projects-component';
import ProjectDeletedOverlay from 'components/feature/project-details/_project-details-deleted-overlay-component';
import { ReactComponent as Shield } from 'icons/shield.svg';
import { ReactComponent as Rocket } from 'icons/rocket.svg';
import Tooltip from 'components/common/tool-tip-component';

import { ProjectList } from 'models/api/project-list-model';
import { UserList } from 'models/api/users-list-model';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { getPipeColorForProject } from 'utils/project-utils';
import {
  EDIT_PROJECT_TOOLTIP,
  SUPPORT_TEAM_ACCESS_TOOLTIP,
  COE_TEAM_ACCESS_TOOLTIP,
} from 'constants/feature/project-details-constants';
import { getCountryNameByGeoCodeAndCountryCode } from 'utils/project-utils';
import { ReactComponent as Edit } from 'icons/edit.svg';
import ProjectApi from 'models/api/project-api-model';

import Permissions from 'permissions/permissions';
import { getSelectedProjectUsersRequest } from 'api/project-list-api';
import ExpandedUsers from './_project-details-expanded-users-component';

export const PROJECT_DETAILS_BLOCK = 'project-details';
export const PROJECT_DETAILS_ID_BLOCK = 'id-project-details';
const EDIT_ICON_SIZE = '28px';
const SHIELD_ICON_SIZE = '24px';
const ROCKET_ICON_SIZE = '24px';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      users: new UserList(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { project } = this.props;
    const { isExpanded } = this.state;
    if (isExpanded) {
      if (prevProps.project !== project) {
        this.setState({
          isExpanded: false,
        });
      } else if (prevState.isExpanded === false) {
        this._fetchSelectedProjectUsers();
      }
    }
  }

  _fetchSelectedProjectUsers = async () => {
    const { project } = this.props;
    const projectUserEntities = project.userEntities;
    try {
      this.setState(({ users }) => ({
        users: users.setLoading(),
      }));
      const response = await getSelectedProjectUsersRequest(
        project.getUserIds(),
      );
      this.setState(({ users }) => ({
        users: users.setLoaded({
          response,
          projectUserEntities,
        }),
      }));
    } catch (e) {
      this.setState(({ users }) => ({
        users: users.setError(e),
      }));
    }
  };

  _toggleMoreProjectInfo = () => {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  };

  _canEdit = () => {
    const { project } = this.props;
    return Permissions.Project.canEdit(project.id);
  };

  render() {
    const { projectList, project, push } = this.props;
    const { data: { geoCode, countryCode } = {} } = project;
    const { users, isExpanded } = this.state;
    return (
      <ConditionalRender dependencies={[projectList]}>
        {!project.isInitialized() ? (
          <NoProjects />
        ) : (
          <ConditionalRender dependencies={[project]}>
            {!project.isInitialized() ? null : (
              <div className={PROJECT_DETAILS_BLOCK}>
                {project.isDeleted() ? <ProjectDeletedOverlay /> : null}
                <div className={`${PROJECT_DETAILS_BLOCK}__header`}>
                  {project.hasAdminGroup ? (
                    <Tooltip {...SUPPORT_TEAM_ACCESS_TOOLTIP}>
                      <Shield
                        height={SHIELD_ICON_SIZE}
                        width={SHIELD_ICON_SIZE}
                        className={`${PROJECT_DETAILS_BLOCK}__shield`}
                      />
                    </Tooltip>
                  ) : null}
                  {project.hasCoeGroup ? (
                    <Tooltip {...COE_TEAM_ACCESS_TOOLTIP}>
                      <Rocket
                        height={ROCKET_ICON_SIZE}
                        width={ROCKET_ICON_SIZE}
                        className={`${PROJECT_DETAILS_BLOCK}__rocket`}
                      />
                    </Tooltip>
                  ) : null}
                  <div
                    className={`${PROJECT_DETAILS_BLOCK}__project-name`}
                    style={
                      !(project.hasAdminGroup || project.hasCoeGroup)
                        ? {
                            borderLeft: `solid 3px ${getPipeColorForProject(
                              project.id,
                            )}`,
                          }
                        : null
                    }
                  >
                    {project.name}
                    {project.legalHold ? (
                      <span className={`${PROJECT_DETAILS_BLOCK}__hold`}>
                        <FormattedMessage id={'common.hold'} />
                      </span>
                    ) : null}
                  </div>
                  {this._canEdit() ? (
                    <Tooltip {...EDIT_PROJECT_TOOLTIP}>
                      <Edit
                        id={`${PROJECT_DETAILS_ID_BLOCK}-edit-project-button`}
                        className={`${PROJECT_DETAILS_BLOCK}__edit-button`}
                        role={'button'}
                        width={EDIT_ICON_SIZE}
                        height={EDIT_ICON_SIZE}
                        onClick={() => {
                          push(
                            parseRoute(ROUTE_CONSTANTS.PROJECT_EDIT_PAGE, {
                              params: { id: project.id },
                            }),
                          );
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Edit
                      id={`${PROJECT_DETAILS_ID_BLOCK}-edit-project-button`}
                      className={classnames(
                        `${PROJECT_DETAILS_BLOCK}__edit-button`,
                        `${PROJECT_DETAILS_BLOCK}__edit-button--disabled`,
                      )}
                      width={EDIT_ICON_SIZE}
                      height={EDIT_ICON_SIZE}
                    />
                  )}
                </div>
                <div className={`${PROJECT_DETAILS_BLOCK}__information`}>
                  <div className={`${PROJECT_DETAILS_BLOCK}__row`}>
                    <div className={`${PROJECT_DETAILS_BLOCK}__col`}>
                      <div className={`${PROJECT_DETAILS_BLOCK}__label`}>
                        <FormattedMessage
                          id={'project-details.body.client-name'}
                        />
                      </div>
                      <div className={`${PROJECT_DETAILS_BLOCK}__value`}>
                        {project.engagementEntity.clientName}
                      </div>
                    </div>
                    <div className={`${PROJECT_DETAILS_BLOCK}__col`}>
                      <div className={`${PROJECT_DETAILS_BLOCK}__label`}>
                        <FormattedMessage
                          id={'project-details.body.fiscal-year'}
                        />
                      </div>
                      <div className={`${PROJECT_DETAILS_BLOCK}__value`}>
                        {moment(project.engagementEntity.engagementFye)
                          .utc()
                          .format(DATE_FORMATS.YEAR)}
                      </div>
                    </div>
                    <div className={`${PROJECT_DETAILS_BLOCK}__col`}>
                      <div className={`${PROJECT_DETAILS_BLOCK}__label`}>
                        <FormattedMessage
                          id={'project-details.body.engagement-name'}
                        />
                      </div>
                      <div className={`${PROJECT_DETAILS_BLOCK}__value`}>
                        {project.engagementEntity.engagementName}
                      </div>
                    </div>
                    <div className={`${PROJECT_DETAILS_BLOCK}__col`}>
                      <div
                        className={classnames(
                          `${PROJECT_DETAILS_BLOCK}__label`,
                          `${PROJECT_DETAILS_BLOCK}__coi-label`,
                        )}
                      >
                        <FormattedMessage
                          id={'project-creation.project-info.project-country'}
                        />
                      </div>
                      <div>
                        {getCountryNameByGeoCodeAndCountryCode(
                          geoCode,
                          countryCode,
                        )}
                      </div>
                    </div>
                    <div className={`${PROJECT_DETAILS_BLOCK}__col`}>
                      <div className={`${PROJECT_DETAILS_BLOCK}__label`}>
                        <FormattedMessage
                          id={'project-details.body.support-team-access'}
                        />
                      </div>
                      <div className={`${PROJECT_DETAILS_BLOCK}__value`}>
                        {project.hasAdminGroup ? (
                          <FormattedMessage id={'common.ON'} />
                        ) : (
                          <FormattedMessage id={'common.OFF'} />
                        )}
                      </div>
                    </div>
                  </div>
                  {isExpanded ? (
                    <ExpandedUsers project={project} users={users} />
                  ) : null}
                  <Button
                    id={`${PROJECT_DETAILS_ID_BLOCK}-more-project-info-button`}
                    className={`${PROJECT_DETAILS_BLOCK}__info-button`}
                    type={BUTTON_TYPES.tertiary}
                    onClick={this._toggleMoreProjectInfo}
                  >
                    {!isExpanded ? (
                      <FormattedMessage
                        id={'project-details.body.more-info-button'}
                      />
                    ) : (
                      <FormattedMessage
                        id={'project-details.body.less-info-button'}
                      />
                    )}
                  </Button>
                </div>
              </div>
            )}
          </ConditionalRender>
        )}
      </ConditionalRender>
    );
  }
}

ProjectDetails.propTypes = {
  /** Object representing selected project*/
  project: PropTypes.instanceOf(ProjectApi),
  /** Object with data array of users for selected project */
  users: PropTypes.instanceOf(UserList),
  /** Object with data array of projects for current user*/
  projectList: PropTypes.instanceOf(ProjectList),
};

export default ProjectDetails;
