import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import {
  hideHeaderPlugin,
  showHeaderPlugin,
} from 'utils/disclosure-analytics-utils';

class PublicRoute extends Component {
  componentDidMount() {
    hideHeaderPlugin();
  }

  componentWillUnmount() {
    showHeaderPlugin();
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} component={Component} />;
  }
}

PublicRoute.propTypes = {
  /**React component to be rendered in the public route component */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]),
};
export default PublicRoute;
