import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { fetchComparePanelForRevision } from 'store/actions/compare-stats-panel-actions';
import { isNullOrUndefined } from 'utils/object-utils';

import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { hideComparePanelAction } from 'store/actions/panel-controller-actions';
import CompareDiffComponent from './compare-panel-diff-component';
import ConditionalRender from 'components/util/conditional-render-component';

export const COMPARE_PANEL_BLOCK = 'compare-panel';

const ComparePanel = ({
  show,
  onCancelPanel,
  revisionId,
  fetchComparePanelForRevision,
  allStats,
}) => {
  useEffect(() => {
    if (show && !isNullOrUndefined(revisionId)) {
      fetchComparePanelForRevision({ revisionId });
    }
  }, [show, revisionId, fetchComparePanelForRevision]);

  const {
    changedCount,
    addedCount,
    deletedCount,
    unChangedCount,
  } = allStats.getStats();

  const diffData = allStats.getDiff();

  return (
    <Flyout className={COMPARE_PANEL_BLOCK} show={show} position={'left'}>
      <FlyoutHeader enableClose onClose={onCancelPanel}>
        <h3 className={`${COMPARE_PANEL_BLOCK}__title`}>
          <FormattedMessage id={'compare-panel.title'} />
        </h3>
      </FlyoutHeader>
      <FlyoutPanelContent>
        <ConditionalRender dependencies={[allStats]}>
          <div className={`${COMPARE_PANEL_BLOCK}__stats`}>
            <div className={`${COMPARE_PANEL_BLOCK}__stats--unchanged`}>
              <FormattedMessage id="compare-panel-elements-unchanged-label" /> (
              {unChangedCount})
            </div>
            <div className={`${COMPARE_PANEL_BLOCK}__stats--added`}>
              <div className="circle" />
              <FormattedMessage id="compare-panel-elements-added-label" /> (
              {addedCount})
            </div>
            <div className={`${COMPARE_PANEL_BLOCK}__stats--changed`}>
              <div className="circle" />
              <FormattedMessage id="compare-panel-elements-changed-label" /> (
              {changedCount})
            </div>
            <div className={`${COMPARE_PANEL_BLOCK}__stats--deleted`}>
              <div className="circle" />
              <FormattedMessage id="compare-panel-elements-deleted-label" /> (
              {deletedCount})
            </div>
          </div>
          <CompareDiffComponent diffData={diffData} />
        </ConditionalRender>
      </FlyoutPanelContent>
    </Flyout>
  );
};

const mapStateToProps = ({
  data: {
    revision,
    comparePanel: { allStats, diffStats },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  revisionId: revision.id,
  show: left === LEFT_PANELS.COMPARE,
  allStats,
});

const mapDispatchToProps = {
  onCancelPanel: hideComparePanelAction,
  fetchComparePanelForRevision,
};

export { ComparePanel };
export default connect(mapStateToProps, mapDispatchToProps)(ComparePanel);
