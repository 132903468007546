import { handleActions } from 'redux-actions';
import {
  elementDetailsLoading,
  elementDetailsLoaded,
  elementDetailsError,
  updateElementVerify,
  updateElementReview,
  updateElementUserFlag,
  updateElementOverrideSystemFlag,
  updateElementComfortAssignAnnotation,
  updateElementUnits,
  updateElementScaling,
  updateElementEntity,
  updateElementPeriod,
  updateElementSectionName,
  updateElementPanelFromSocketPayload,
} from 'store/actions/element-panel-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import ElementDetails from 'models/api/element-details-api-model';

export default handleActions(
  {
    [elementDetailsLoading]: (elementDetails, { payload }) => {
      return elementDetails.setLoading(payload);
    },
    [elementDetailsLoaded]: (elementDetails, { payload }) => {
      return elementDetails.setLoaded(payload);
    },
    [elementDetailsError]: (elementDetails, { payload }) => {
      return elementDetails.setError(payload);
    },
    [updateElementVerify]: (elementDetails, { payload }) => {
      return elementDetails.setVerify(payload);
    },
    [updateElementReview]: (elementDetails, { payload }) => {
      return elementDetails.setReview(payload);
    },
    [updateElementUserFlag]: (elementDetails, { payload }) => {
      return elementDetails.setUserFlag(payload);
    },
    [updateElementOverrideSystemFlag]: (elementDetails, { payload }) => {
      return elementDetails.setOverrideSystemFlag(payload);
    },
    [updateElementComfortAssignAnnotation]: (elementDetails, { payload }) => {
      return elementDetails.setComfortAssign(payload);
    },
    [updateElementUnits]: (elementDetails, { payload }) => {
      return elementDetails.setUnit(payload);
    },
    [updateElementScaling]: (elementDetails, { payload }) => {
      return elementDetails.setScaling(payload);
    },
    [updateElementEntity]: (elementDetails, { payload }) => {
      return elementDetails.setEntity(payload);
    },
    [updateElementPeriod]: (elementDetails, { payload }) => {
      return elementDetails.setPeriod(payload);
    },
    [updateElementSectionName]: (elementDetails, { payload }) => {
      return elementDetails.setSectionName(payload);
    },
    [updateElementPanelFromSocketPayload]: (elementDetails, { payload }) => {
      return elementDetails.updateElementDetailsFromSocket(payload);
    },
    [clearStatementPageStoreAction]: (elementDetails, { payload }) => {
      return new ElementDetails();
    },
  },
  new ElementDetails(),
);
