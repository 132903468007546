import React, { memo, useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import {
  MAX_STATEMENT_FILE_SIZE,
  SUPPORTED_STATEMENT_FILE_TYPES,
} from 'constants/feature/statement-creation-constants';
import Card from 'components/common/card-component';
import ProgressBarStep from 'components/common/progress-bar-step-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as Document } from 'icons/document.svg';
import { ReactComponent as ESC } from 'icons/ESC-support.svg';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { isProjectExistInAMERegion } from 'utils/project-utils';
// import { getBetaStmtGuide } from 'api/statement-creation-edit-api';
// import { downloadFile } from 'utils/statement-export-utils';
import StatementForm from 'models/form/statement-form-model';

export const SELECT_STATEMENT_BLOCK = 'select-statement';
export const SELECT_STATEMENT_ID_BLOCK = 'id-select-statement';
const DOCUMENT_ICON_SIZE = '100px';
const CLEAR_ICON_SIZE = '20px';

/** For dropzone documentation visit https://react-dropzone.netlify.com/ */
const SelectStatement = ({
  setFiles,
  setFilesError,
  statementInProgress,
  project,
}) => {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        setSelectStatementButtonDisabled(true);
        setFiles(acceptedFiles);
      }
      if (rejectedFiles.length) {
        //We can only upload one file in this modal
        setFilesError(rejectedFiles);
      }
    },
    [setFiles, setFilesError],
  );
  const onFileDialogCancel = () => {
    if (!statementInProgress.hasUploadedFiles()) {
      setSelectStatementButtonDisabled(false);
    }
  };

  // const _getBetaStmtGuide = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await getBetaStmtGuide();
  //     downloadFile(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [selectStatementButtonDisabled, setSelectStatementButtonDisabled] =
    useState(false);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: SUPPORTED_STATEMENT_FILE_TYPES,
    multiple: false,
    onDrop,
    onFileDialogCancel,
    maxSize: MAX_STATEMENT_FILE_SIZE,
  });
  const _isFileError = statementInProgress.hasFileError();
  return (
    <div className={SELECT_STATEMENT_BLOCK}>
      <div className={`${SELECT_STATEMENT_BLOCK}__step`}>
        <ProgressBarStep
          id={`${SELECT_STATEMENT_ID_BLOCK}-step`}
          active={true}
          isValid={statementInProgress.isStepOneValid()}
          stepNum={'1'}
        />
      </div>
      <div
        id={`${SELECT_STATEMENT_ID_BLOCK}-card`}
        className={`${SELECT_STATEMENT_BLOCK}__card`}
      >
        <Card>
          <div className={`${SELECT_STATEMENT_BLOCK}__title`}>
            <FormattedMessage id={'statement-create.select-statement.title'} />
          </div>
          <div className={`${SELECT_STATEMENT_BLOCK}__banner`}>
            <Banner
              id={`${SELECT_STATEMENT_BLOCK}-info-banner`}
              type={BANNER_TYPES.INFORMATION}
              bannerCopy={'statement-create.select-statement.info-banner'}
              isInternationalized={true}
              isInteractive={false}
              width={'100%'}
            />
          </div>
          {_isFileError ? (
            <div className={`${SELECT_STATEMENT_BLOCK}__banner--error`}>
              <Banner
                id={`${SELECT_STATEMENT_BLOCK}-error-banner`}
                type={BANNER_TYPES.ERROR}
                bannerCopy={'statement-create.select-statement.error-banner'}
                isInternationalized={true}
                isInteractive={false}
                width={'100%'}
              />
            </div>
          ) : null}
          <div className={`${SELECT_STATEMENT_BLOCK}__upload-section`}>
            <div
              {...getRootProps({
                className: classnames(
                  'dropzone',
                  `${SELECT_STATEMENT_BLOCK}__upload`,
                  {
                    [`${SELECT_STATEMENT_BLOCK}__upload__full-width`]:
                      !isProjectExistInAMERegion(project),
                  },
                  isDragActive && `${SELECT_STATEMENT_BLOCK}__upload--active`,
                  _isFileError &&
                    !isDragActive &&
                    `${SELECT_STATEMENT_BLOCK}__upload--rejected`,
                ),
              })}
            >
              <input {...getInputProps()} />
              <div className={`${SELECT_STATEMENT_BLOCK}__drop-container`}>
                <Document
                  id={`${SELECT_STATEMENT_BLOCK}-document-icon`}
                  width={DOCUMENT_ICON_SIZE}
                  height={DOCUMENT_ICON_SIZE}
                />
                <div className={`${SELECT_STATEMENT_BLOCK}__subtitle`}>
                  <FormattedMessage
                    id={'statement-create.select-statement.drop-title'}
                  />
                </div>
                <div className={`${SELECT_STATEMENT_BLOCK}__text`}>
                  <FormattedMessage
                    id={'statement-create.select-statement.drop-copy'}
                  />
                </div>
                <div
                  className={`${SELECT_STATEMENT_BLOCK}__banner`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Banner
                    id={`${SELECT_STATEMENT_BLOCK}-warning-banner`}
                    type={BANNER_TYPES.WARNING}
                    bannerCopy={{
                      id: 'statement-create.select-statement.warning-banner',
                      values: {
                        evaluationGuide: (
                          <a
                            className={`${SELECT_STATEMENT_BLOCK}__evaluation-statement-guide-hyperlink`}
                            href={ROUTE_CONSTANTS.STATEMENT_EVAULATION_GUIDE}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage
                              id={
                                'statement-create.select-statement.warning-banner-guide'
                              }
                            />
                          </a>
                        ),
                      },
                    }}
                    isInternationalized={true}
                    isInteractive={false}
                    width={'100%'}
                  />
                  <Banner
                    id={`${SELECT_STATEMENT_BLOCK}-beta-statement-banner`}
                    className={`${SELECT_STATEMENT_BLOCK}__banner`}
                    type={BANNER_TYPES.INFORMATION}
                    bannerCopy={{
                      id: 'statement-create.statement-info.banner.beta-statement',
                      values: {
                        statementname: (
                          <a
                            className={`${SELECT_STATEMENT_BLOCK}__beta-statement-guide-hyperlink`}
                            href={ROUTE_CONSTANTS.BETA_STATEMENT_GUIDE}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage
                              id={
                                'statement-create.statement-info.banner.beta-statement-name'
                              }
                            />
                          </a>
                        ),
                      },
                    }}
                    isInternationalized={true}
                    width={'100%'}
                  />
                </div>
                {statementInProgress.hasUploadedFiles() ? (
                  <div
                    className={`${SELECT_STATEMENT_BLOCK}__accepted-files-list`}
                  >
                    {statementInProgress.files.map((file, i) => (
                      <div
                        className={`${SELECT_STATEMENT_BLOCK}__accepted-files-row`}
                        key={`${SELECT_STATEMENT_BLOCK}-file-row-${i}`}
                      >
                        <div
                          className={classnames(
                            `${SELECT_STATEMENT_BLOCK}__accepted-file-name`,
                            _isFileError &&
                              `${SELECT_STATEMENT_BLOCK}__accepted-file-name--error`,
                          )}
                        >
                          {file.name}
                        </div>
                        <Clear
                          id={`${SELECT_STATEMENT_BLOCK}-clear-button-${i}`}
                          className={`${SELECT_STATEMENT_BLOCK}__clear-icon`}
                          role={'button'}
                          width={CLEAR_ICON_SIZE}
                          height={CLEAR_ICON_SIZE}
                          onClick={(e) => {
                            e.stopPropagation(); //nessarry because all clicks inside the dropzone trigger upload
                            setFiles(null);
                            setSelectStatementButtonDisabled(false);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
                <Button
                  id={`${SELECT_STATEMENT_ID_BLOCK}-select-statement-button`}
                  className={`${SELECT_STATEMENT_BLOCK}__button`}
                  type={BUTTON_TYPES.primary}
                  disabled={selectStatementButtonDisabled || isDragActive}
                  onClick={(e) => {
                    e.stopPropagation(); //nessarry because all clicks inside the dropzone trigger upload
                    setSelectStatementButtonDisabled(true);
                    open();
                  }}
                >
                  <FormattedMessage
                    id={'statement-create.select-statement.title'}
                  />
                </Button>
              </div>
            </div>
            {isProjectExistInAMERegion(project) && (
              <div
                className={`${SELECT_STATEMENT_BLOCK}__esc-information-section`}
              >
                <ESC
                  id={`${SELECT_STATEMENT_BLOCK}-pdf-icon`}
                  width={DOCUMENT_ICON_SIZE}
                  height={DOCUMENT_ICON_SIZE}
                />
                <div className={`${SELECT_STATEMENT_BLOCK}__subtitle`}>
                  <FormattedMessage
                    id={'statement-create.select-esc-information-file-title'}
                  />
                </div>
                <div className={`${SELECT_STATEMENT_BLOCK}__text`}>
                  <FormattedMessage
                    id={'statement-create.select-esc-information-file-text'}
                  />
                </div>
                <Button
                  id={`${SELECT_STATEMENT_ID_BLOCK}-navigate-button`}
                  className={`${SELECT_STATEMENT_BLOCK}__button`}
                  type={BUTTON_TYPES.secondary}
                  onClick={() => {
                    window.open(ROUTE_CONSTANTS.ESC_SUPPORT);
                  }}
                >
                  <FormattedMessage
                    id={'statement-create.select-esc-information.button'}
                  />
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

SelectStatement.propTypes = {
  /** function used to set the uploaded file to the statementInProgress object. */
  setFiles: PropTypes.func.isRequired,
  /** function used to signal that a file was rejected by the react dropzone based on business criteria */
  setFilesError: PropTypes.func.isRequired,
  /** Object representing the working copy of the statement being created.*/
  statementInProgress: PropTypes.instanceOf(StatementForm).isRequired,
};

export default memo(SelectStatement);
