import ApiModel from 'models/api-model';
import Country from 'models/data/country-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { MenuOption } from '../utils/common/menu/menu-option-model';
import { isArray } from 'lodash';
export default class CountryForm extends ApiModel({
  data: {
    countriesList: [],
    countriesOptions: [],
  },
}) {
  get countriesList() {
    if (isArray(this.data.countriesList)) {
      return this.data.countriesList;
    }
    return [];
  }
  get countriesOptions() {
    if (!isNullOrUndefined(this.data.countriesOptions)) {
      return this.data.countriesOptions;
    }
    return [];
  }

  getCountryMenuOptionByGeoCode(geoCode) {
    const geoSpecificCountries = this.countriesList.filter(
      (value) => value.geoCode === geoCode,
    );
    return geoSpecificCountries.length
      ? geoSpecificCountries.map((item) => {
          return new MenuOption({
            id: item.countryCode,
            title: item.countryName,
            value: `${item.containerCode}-${item.countryCode}`,
            isIntl: false,
          });
        })
      : [];
  }

  processResponse(response) {
    const countries = response.data;
    return {
      data: {
        countriesList: countries.map((country) => {
          return new Country(country);
        }),
        countriesOptions: countries.map((item) => {
          return new MenuOption({
            id: item.countryCode,
            title: item.countryName,
            value: `${item.containerCode}-${item.countryCode}`,
            isIntl: false,
          });
        }),
      },
    };
  }

  getCountryDetailsByCountryCode(countryCode) {
    return this.countriesList.find(
      (country) => country.countryCode === countryCode,
    );
  }
}
