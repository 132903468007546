import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

const init_data = {
  sectionDifferenceMap: {},
  elementMap: {
    missingElements: [],
    addedElements: [],
    changedElements: [],
    unchangedElements: [],
  },
};
export default class CompareStats extends ApiModel({
  data: init_data,
}) {
  processResponse({ response }) {
    return {
      data: {
        sectionDifferenceMap: {
          ...init_data.sectionDifferenceMap,
          ...response.data.result.sectionDifferenceMap,
        },
        elementMap: {
          ...init_data.elementMap,
          ...response.data.result.elementMap,
        },
      },
    };
  }

  hasData() {
    return !isNullOrUndefined(this.data);
  }

  getStats() {
    if (!this.hasData()) return init_data.elementMap;

    const {
      changedElements,
      addedElements,
      missingElements,
      unchangedElements,
    } = this.data.elementMap;

    return {
      changedCount: changedElements.length,
      addedCount: addedElements.length,
      deletedCount: missingElements.length,
      unChangedCount: unchangedElements.length,
    };
  }

  getDiff() {
    if (!this.hasData()) return init_data.sectionDifferenceMap;

    return this.data.sectionDifferenceMap;
  }
}
