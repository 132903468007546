import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import { note_type_options_array } from 'constants/feature/notes-panel-constants';
import Radios from 'components/common/radio-component';
import Note from 'models/data/note-model';
import NotesTextArea from 'components/feature/notes-panel/_notes-textarea-component';
import { toDisplayNoteFormat } from 'utils/note-filter-util';

export const NOTES_PANEL_INPUT_EDIT_AREA_BLOCK = 'notes-panel-edit-input-area';
export const NOTES_PANEL_EDIT_INPUT_AREA_ID_BLOCK =
  'notes-panel-edit-input-area-id';

const NoteEditInputArea = ({
  cancelEditNote,
  onEditNote,
  note,
  projectUsersList,
  setPreviousNoteBody,
  selectedProjectId,
}) => {
  // React.useState instead of useState will help us test useState as in scope of React and not function locally
  const [text, setText] = React.useState(toDisplayNoteFormat(note.getNote()));
  const [noteType, setNoteType] = React.useState(note.noteType);

  return (
    <div
      className={NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}
      id={NOTES_PANEL_EDIT_INPUT_AREA_ID_BLOCK}
    >
      <div className={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__radio-buttons`}>
        <Radios
          id={`${NOTES_PANEL_EDIT_INPUT_AREA_ID_BLOCK}-note-type`}
          selectedOption={noteType}
          options={note_type_options_array(selectedProjectId)}
          isValid
          width={'auto'}
          onChange={(type) => setNoteType(type)}
        />
      </div>
      <NotesTextArea
        id={`${NOTES_PANEL_EDIT_INPUT_AREA_ID_BLOCK}-content`}
        placeholder="notes-panel.notes-reply.placeholder"
        onChange={(text) => setText(text)}
        value={text}
        data={projectUsersList}
      />
      <div className={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__button-container`}>
        <Button
          id={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__cancel-button`}
          className={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__button-container--cancel`}
          type={BUTTON_TYPES.secondary}
          onClick={cancelEditNote}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          id={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__save-button`}
          className={`${NOTES_PANEL_INPUT_EDIT_AREA_BLOCK}__button-container--add`}
          type={BUTTON_TYPES.primary}
          onClick={() => {
            setPreviousNoteBody(note.getNote());
            onEditNote(text, noteType);
            setText('');
          }}
          disabled={note.hasNoteBeenEdited(text, noteType)}
        >
          <FormattedMessage id="common.save" />
        </Button>
      </div>
    </div>
  );
};

NoteEditInputArea.propTypes = {
  /** Action fired for canceling adding a note */
  cancelEditNote: PropTypes.func.isRequired,
  /** Action for editing a note reply */
  onEditNote: PropTypes.func.isRequired,
  /** This note's data model */
  note: PropTypes.instanceOf(Note).isRequired,
  /** The list of users of the project will be passed down to NotesTextArea */
  projectUsersList: PropTypes.array.isRequired,
  /** Action to store the previous note in redux */
  setPreviousNoteBody: PropTypes.func.isRequired,
  /**Id of the selected project */
  selectedProjectId: PropTypes.string.isRequired,
};

export default NoteEditInputArea;
