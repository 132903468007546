import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
const FORMULA_CANCEL_MODAL_ID = 'formula-cancel-modal-id';
const FormulaCancelModal = ({ onClickYes, onClickNo }) => {
  return (
    <Modal
      id={FORMULA_CANCEL_MODAL_ID}
      title={'common.discard-changes.title'}
      onClose={onClickNo}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: onClickYes,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: onClickNo,
        })
      }
    >
      <FormattedMessage id={'formula-panel.cancel-modal.body'} />
    </Modal>
  );
};

FormulaCancelModal.propTypes = {
  /** Action fired when user clicks Yes button */
  onClickYes: PropTypes.func.isRequired,
  /** Action fired when user clicks No || close button */
  onClickNo: PropTypes.func.isRequired,
};

export default memo(FormulaCancelModal);
