import { isNullOrUndefined } from 'utils/object-utils';

export class RadioOption {
  id = null;
  label = null;
  value = null;
  selected = null;
  disabled = null;
  /* this property it is being used to set the tooltip on and off on required radio buttons. By default it is always going to be true */
  tooltipEnabled = null;
  isNotInternationalized = false;

  constructor({
    id,
    label,
    value,
    selected,
    disabled,
    tooltipEnabled,
    isNotInternationalized,
  }) {
    this.id = id;
    this.label = label;
    this.value = value;
    this.selected = selected;
    this.disabled = disabled;
    this.isNotInternationalized = isNullOrUndefined(isNotInternationalized)
      ? false
      : isNotInternationalized;
    this.tooltipEnabled = tooltipEnabled !== null ? tooltipEnabled : true;
  }
}
