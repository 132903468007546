import { handleActions } from 'redux-actions';

import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import {
  showTickmarkPanelAction,
  hideTickmarkPanelAction,
  showStatementNavPanelAction,
  hideStatementNavPanelAction,
  hideLeftPanelAction,
  hideComparePanelAction,
  showComparePanelAction,
  hideExportPanelAction,
  showExportPanelAction,
  showWorkPaperPanelAction,
  hideWorkPaperPanelAction,
  showCustomMenuPanelAction,
  hideCustomMenuPanelAction,
  showAuditChecklistPanelAction,
  hideAuditChecklistPanelAction,
  hideComfortAnnotationPanelAction,
  showComfortAnnotationPanelAction,
} from 'store/actions/panel-controller-actions';
import { LEFT_PANELS } from 'constants/feature/panel-constants';

const {
  CLOSED,
  STATEMENT_NAV,
  TICKMARK,
  COMPARE,
  EXPORT,
  WORKPAPER,
  CUSTOM,
  AUDIT_CHECKLIST,
  COMFORT_ANNOTATION,
} = LEFT_PANELS;

export default handleActions(
  {
    [hideStatementNavPanelAction]: () => {
      return CLOSED;
    },
    [showStatementNavPanelAction]: () => {
      return STATEMENT_NAV;
    },
    [hideTickmarkPanelAction]: () => {
      return CLOSED;
    },
    [showTickmarkPanelAction]: () => {
      return TICKMARK;
    },
    [hideComparePanelAction]: () => {
      return CLOSED;
    },
    [showComparePanelAction]: () => {
      return COMPARE;
    },
    [hideLeftPanelAction]: () => {
      return CLOSED;
    },
    [clearStatementPageStoreAction]: () => {
      return CLOSED;
    },
    [hideExportPanelAction]: () => {
      return CLOSED;
    },
    [showExportPanelAction]: (state, { payload = LEFT_PANELS.EXPORT }) => {
      return payload;
    },
    [showWorkPaperPanelAction]: () => {
      return WORKPAPER;
    },
    [hideWorkPaperPanelAction]: () => {
      return CLOSED;
    },
    [hideCustomMenuPanelAction]: () => {
      return EXPORT;
    },
    [showCustomMenuPanelAction]: () => {
      return CUSTOM;
    },
    [hideAuditChecklistPanelAction]: () => {
      return CLOSED;
    },
    [showAuditChecklistPanelAction]: () => {
      return AUDIT_CHECKLIST;
    },
    [hideComfortAnnotationPanelAction]: () => {
      return CLOSED;
    },
    [showComfortAnnotationPanelAction]: () => {
      return COMFORT_ANNOTATION;
    },
  },
  CLOSED,
);
