import { handleActions } from 'redux-actions';

import SideBySideViewAnnotations from 'models/api/side-by-side-view-annotations-modal';
import {
  resetSideBySideViewAnnotations,
  setAnnotationMapping,
} from 'store/actions/side-by-side-view-annotations-actions';

export default handleActions(
  {
    [setAnnotationMapping]: (sideBySideViewAnnotations, { payload }) => {
      return sideBySideViewAnnotations.setAnnotationsMapping(payload);
    },
    [resetSideBySideViewAnnotations]: () => {
      return new SideBySideViewAnnotations();
    },
  },
  new SideBySideViewAnnotations(),
);
