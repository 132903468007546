import { createAction } from 'redux-actions';
import revisionApi from 'api/revision-api';
import { isNullOrUndefined } from 'utils/object-utils';
import { CONTEXT_KEY } from 'api/api-client';
import { getGeoForProjectId } from './selected-project-actions';
export const leftRevisionLoading = createAction('LEFT_REVISION_LOADING');
export const leftRevisionError = createAction('LEFT_REVISION_ERROR');
export const leftRevisionLoaded = createAction('LEFT_REVISION_LOADED');

export const fetchLeftRevision =
  (revisionId, statementId, projectId) => async (dispatch, getState) => {
    dispatch(leftRevisionLoading());
    if (!isNullOrUndefined(revisionId)) {
      try {
        const selectedProjectGeoCode = dispatch(getGeoForProjectId(projectId));
        const response = await revisionApi.getRevisionRequest(
          revisionId,
          selectedProjectGeoCode,
          statementId,
          {
            contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
            contextValue: projectId,
          },
        );
        dispatch(
          leftRevisionLoaded({
            response,
          }),
        );
      } catch (error) {
        dispatch(leftRevisionError(error));
      }
    } else {
      dispatch(
        leftRevisionError(
          new Error('You currently cannot access this statement.'),
        ),
      );
    }
  };
