import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {
  cloneElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { isNullOrUndefined } from 'utils/object-utils';

export const VIRTUALIZED_LIST_BLOCK = 'virtualized-list';

// Debounce duration for callback when scrolled to end
export const CALLBACK_DEBOUNCE_DURATION = 500;
// Items out of view but within 1000px will be rendered
export const OUT_OF_VIEW_THRESHOLD = 1000;

const VirtualizedList = ({
  disableScrollCallback,
  extractKey,
  id,
  itemHeight,
  itemList,
  onScrollEnd,
  renderFooter,
  renderItem,
  rowsAmount,
  refElementHeight,
  calculatedScrollPosition,
  selectedItemClassName,
}) => {
  const listRef = useRef();
  const scrollAnimation = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    //This prop (calculatedScrollPosition) is required when any item is already selected into list.
    //We need to set the scroll position to calculate the 'startIndex' and 'endIndex', so
    //that list items with correct window is selected/sliced.
    if (!isNullOrUndefined(calculatedScrollPosition)) {
      setScrollPosition(calculatedScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedScrollPosition]);

  const itemsOutOfView = Math.ceil(OUT_OF_VIEW_THRESHOLD / itemHeight);

  const getListBounding = useCallback(() => {
    if (listRef.current) {
      return listRef.current.getBoundingClientRect();
    }
    return null;
  }, [listRef]);

  const getListHeight = useCallback(() => {
    const bounds = getListBounding();
    return bounds ? bounds.height : 0;
  }, [getListBounding]);

  let startIndex = useMemo(() => {
    const start =
      Math.floor(scrollPosition / (itemHeight + refElementHeight)) -
      itemsOutOfView;
    return Math.max(0, start);
  }, [scrollPosition, itemHeight, refElementHeight, itemsOutOfView]);

  const endIndex = useMemo(() => {
    const end =
      Math.ceil((scrollPosition + getListHeight()) / itemHeight) +
      itemsOutOfView;
    return Math.min(itemList.length, end);
  }, [
    itemsOutOfView,
    getListHeight,
    itemHeight,
    itemList.length,
    scrollPosition,
  ]);

  useEffect(() => {
    //This prop (selectedItemClassName) is required to scroll the list to the selected item.
    //It finds the selected element and scroll list to that element.
    const selectedItem = document.getElementsByClassName(selectedItemClassName);
    if (selectedItem && selectedItem[0]) {
      selectedItem[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [startIndex, endIndex, selectedItemClassName]);

  const getListItemsInView = useCallback(
    () =>
      itemList
        .slice(startIndex, endIndex)
        .map((item, index) =>
          Object.assign(item, { index: startIndex + index }),
        ),
    [endIndex, itemList, startIndex],
  );

  const onScrollEndDebounced = useMemo(
    () => debounce(onScrollEnd, CALLBACK_DEBOUNCE_DURATION),
    [onScrollEnd],
  );

  const onScroll = useCallback(
    (event) => {
      if (scrollAnimation.current) {
        cancelAnimationFrame(scrollAnimation.current);
      }
      const { scrollTop } = event.currentTarget;
      const scrollEndPosition =
        scrollTop + event.currentTarget.getBoundingClientRect().height;
      const scrollThreshold = itemHeight * (itemList.length - itemsOutOfView);
      scrollAnimation.current = requestAnimationFrame(() => {
        setScrollPosition(scrollTop);
        if (!disableScrollCallback && scrollEndPosition > scrollThreshold) {
          onScrollEndDebounced();
        }
      });
    },
    [
      disableScrollCallback,
      itemsOutOfView,
      itemHeight,
      itemList.length,
      onScrollEndDebounced,
    ],
  );

  const renderItemWithKey = useCallback(
    (item) => {
      const itemProps = { key: extractKey(item) };
      return cloneElement(renderItem(item, item.index, itemList), itemProps);
    },
    [extractKey, itemList, renderItem],
  );

  return (
    <div
      className={VIRTUALIZED_LIST_BLOCK}
      id={id}
      onScroll={onScroll}
      ref={listRef}
    >
      <div
        className={`${VIRTUALIZED_LIST_BLOCK}__inner`}
        style={{
          height: isNaN(rowsAmount)
            ? itemHeight * itemList.length + refElementHeight
            : itemHeight * rowsAmount,
        }}
      >
        <div
          className={`${VIRTUALIZED_LIST_BLOCK}__in-view`}
          style={{ top: `${startIndex * itemHeight}px` }}
        >
          {getListItemsInView().map(renderItemWithKey)}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

VirtualizedList.defaultProps = {
  disableScrollCallback: false,
  refElementHeight: 0,
  extractKey: (item) => item.id,
  renderFooter: () => null,
  onScrollEnd: () => {},
};

VirtualizedList.propTypes = {
  /** Boolean controls if we call onScrollEnd */
  disableScrollCallback: PropTypes.bool,
  /** Function to extract the item key from item object */
  extractKey: PropTypes.func,
  /** Id of div wrapper */
  id: PropTypes.string,
  /** Item height used to calculate positioning and what items to render */
  itemHeight: PropTypes.number.isRequired,
  /** represents the clientHeight or offSetHeight of the reference element when user clicks on show more button to view more description of element which has more than 3 lines */
  refElementHeight: PropTypes.number,
  /** Array of items to render */
  itemList: PropTypes.array.isRequired,
  /** Callback for when scrolling near the end of list */
  onScrollEnd: PropTypes.func,
  /** Function render footer */
  renderFooter: PropTypes.func,
  /** Function render each item */
  renderItem: PropTypes.func.isRequired,
  /* max amount of rows on the virtualized list*/
  rowsAmount: PropTypes.number,
};

export default VirtualizedList;
