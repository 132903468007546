import ApiModel from 'models/api-model';
import ComfortLetter from 'models/data/comfort-letter-model';

export default class ElementComfortLetterList extends ApiModel({
  data: {
    comfortLetters: [],
  },
}) {
  get comfortLetters() {
    if (this.data) {
      return this.data.comfortLetters;
    }
    return [];
  }
  processResponse({ response }) {
    return {
      data: {
        comfortLetters:
          response &&
          response.data &&
          response.data.result &&
          response.data.result.map((comfortLetter) =>
            new ComfortLetter().convertApiResponsetoComfortLetterModelFormat(
              comfortLetter,
            ),
          ),
      },
    };
  }

  getComfortLetterCount() {
    if (this.hasComfortLetter()) {
      return this.data.comfortLetters.length;
    }
    return 0;
  }

  get(index) {
    if (
      this.data &&
      this.data.comfortLetters &&
      index >= 0 &&
      index < this.data.comfortLetters.length
    ) {
      return this.data.comfortLetters[index];
    }
    return null;
  }

  hasComfortLetter() {
    return (
      this.data &&
      this.data.comfortLetters &&
      this.data.comfortLetters.length > 0
    );
  }

  getComfortLettersSortedAlphabetically() {
    if (this.data) {
      return this.data.comfortLetters.sort((b, a) =>
        b.comfortLetterCreatedDate.localeCompare(a.comfortLetterCreatedDate),
      );
    }
    return [];
  }
}
