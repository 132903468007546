import React from 'react';
import { ReactComponent as DownloadPendingIcon } from 'icons/semi-ellipse.svg';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { downloadFile } from '../../../utils/statement-export-utils';
import { REPORT_DOWNLOAD_PROCESSED } from '../../../models/api/toolkit-export-download-list-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { isNullOrUndefined } from '../../../utils/object-utils';
import Button from 'components/common/button-component';
import { BUTTON_TYPES } from '../../common/button-component';
import { ReactComponent as RemoveIcon } from 'icons/cross.svg';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { downloadReportFromGuidApiSelector } from 'utils/statement-content-page-utils';

export const DOWNLOAD_CARD_BLOCK = 'toolkit-downlaod-card';
const DOWNLOAD_CARD_BLOCK_ID = 'toolkit-downlaod-card-id';

const CANCEL_ICON_SIZE = '16px';
const DOWNLOADED_ICON_SIZE = '28px';

export const TOOLKIT_CANCEL_EXPORT_BUTTON_TOOLTIP = new TooltipOptions({
  id: `${DOWNLOAD_CARD_BLOCK}__cancel_button_tooltip`,
  text: 'toolkit.export.panel.download-card.cancel-export',
  position: 'bottom',
});

export const DownloadCard = ({
  downloadId,
  name,
  dateTime,
  downloadStatus,
  selectedId,
  setSelectedId,
  downloadResponse,
  storageGuid,
  revisionId,
  updateDownloadStatus,
  userDownloadedReportStatus,
  isLoading,
  isLoaded,
  error,
  setReportIdError,
  setReportIdLoaded,
  setReportIdLoading,
  cancelReportProcessing,
  updateFlagUserDownloadedReportStatus,
  reportType,
}) => {
  React.useEffect(() => {
    // revert back to old state (before error occured) on component unmount,
    // We do not want to show the same error everytime the card is rendered
    return () => {
      error &&
        !isNullOrUndefined(downloadId) &&
        updateDownloadStatus({
          id: downloadId,
          downloadStatus: downloadStatus,
          downloadResponse: downloadResponse,
          storageGuid: storageGuid,
          userDownloadedReportStatus: userDownloadedReportStatus,
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onClickDownload = async () => {
    setReportIdLoading({ id: downloadId });
    if (downloadResponse) {
      downloadFile(downloadResponse);
      setReportIdLoaded({ id: downloadId });

      // if report is already downloaded by user (lets say - in other system) then, we dont want to
      // trigger action for changing flag again
      if (!userDownloadedReportStatus) {
        try {
          // We don't want to wait for api call to finish.
          // Since report data is handy with FE, so it will be downloaded instantly and the count of
          // downloaded report should also change instantly
          updateFlagUserDownloadedReportStatus({
            id: downloadId,
            userDownloadedReportStatus: true,
          });
          // This will just inform BE that user has clicked on download button and was able to download the report
          await downloadReportFromGuidApiSelector({
            revisionId,
            fileName: name,
            storageGuid,
            userDownloadedReportStatus: true,
            reportType,
            id: downloadId,
          });
        } catch (e) {
          console.log(e);
          // Lets say api call to update flag has failed, then we want to revert back the change related to status
          updateFlagUserDownloadedReportStatus({
            id: downloadId,
            userDownloadedReportStatus: true,
          });
        }
      }
    } else {
      try {
        let blobResonse = await downloadReportFromGuidApiSelector({
          revisionId,
          fileName: name,
          storageGuid,
          userDownloadedReportStatus: true,
          reportType,
          id: downloadId,
        });
        downloadFile(blobResonse);
        updateDownloadStatus({
          id: downloadId,
          downloadStatus: REPORT_DOWNLOAD_PROCESSED,
          downloadResponse: blobResonse,
          storageGuid: storageGuid,
          userDownloadedReportStatus: true,
        });
        setReportIdLoaded({ id: downloadId });
      } catch (e) {
        setReportIdError({ id: downloadId, error: e });
      }
    }
  };
  const isReportProcessed = downloadStatus === REPORT_DOWNLOAD_PROCESSED;
  return (
    <ConditionalRender dependencies={[{ isLoaded, isLoading, error }]}>
      <div
        className={classNames(
          selectedId === downloadId && `${DOWNLOAD_CARD_BLOCK}--selected`,
          `${DOWNLOAD_CARD_BLOCK}`,
        )}
        key={`${DOWNLOAD_CARD_BLOCK_ID}-key-${downloadId}`}
        onClick={() => setSelectedId(downloadId)}
      >
        <div
          className={classNames(
            `${DOWNLOAD_CARD_BLOCK}-name`,
            userDownloadedReportStatus &&
              `${DOWNLOAD_CARD_BLOCK}-name-user-downloaded`,
          )}
        >
          <span>{name}</span>
        </div>
        <div className={`${DOWNLOAD_CARD_BLOCK}-download-status-icon`}>
          <div
            className={`${DOWNLOAD_CARD_BLOCK}-download-status-icon__left-items`}
          >
            <span>{dateTime}</span>
          </div>

          <div
            className={`${DOWNLOAD_CARD_BLOCK}-download-status-icon__right-items`}
          >
            {!isReportProcessed && (
              <>
                <Button.IconButton
                  id={`${DOWNLOAD_CARD_BLOCK_ID}__cancel-button`}
                  className={`${DOWNLOAD_CARD_BLOCK}__cancel-button`}
                  type={BUTTON_TYPES.icon}
                  Icon={RemoveIcon}
                  iconSize={CANCEL_ICON_SIZE}
                  onClick={() => cancelReportProcessing(downloadId, name)}
                  tooltip={TOOLKIT_CANCEL_EXPORT_BUTTON_TOOLTIP}
                />
                <DownloadPendingIcon
                  className={`${DOWNLOAD_CARD_BLOCK}-download-pending-icon`}
                />
              </>
            )}
            {isReportProcessed && (
              <DownloadIcon
                width={DOWNLOADED_ICON_SIZE}
                height={DOWNLOADED_ICON_SIZE}
                onClick={onClickDownload}
                className={`${DOWNLOAD_CARD_BLOCK}-download-ready-icon`}
              />
            )}
          </div>
        </div>
      </div>
    </ConditionalRender>
  );
};

DownloadCard.propTypes = {
  /**unique id for each card */
  downloadId: PropTypes.string.isRequired,
  /**name of downloading file */
  name: PropTypes.string.isRequired,
  /**date and time - when download was requested */
  dateTime: PropTypes.string.isRequired,
  /**If it downloaded or pending */
  downloadStatus: PropTypes.string.isRequired,
  /**If user downloaded the report. null or undefined should be interpreted as false */
  userDownloadedReportStatus: PropTypes.bool,
  /**it stores id to check if this card(amongst many other) is selected */
  selectedId: PropTypes.string,
  /**function to selectedId */
  setSelectedId: PropTypes.func.isRequired,
  /**This will consist of binary for downloading file */
  downloadResponse: PropTypes.object,
  /**A special id used to download report from blob storage */
  storageGuid: PropTypes.string,
  /**integer which represent revision number for statement */
  revisionId: PropTypes.number.isRequired,
  /**function to update report */
  updateDownloadStatus: PropTypes.func.isRequired,
  /**if card should show loading icon */
  isLoading: PropTypes.bool.isRequired,
  /**if data required to render component is loaded */
  isLoaded: PropTypes.bool.isRequired,
  /**if error occured for a particular report card */
  error: PropTypes.object,
  /**set error object for particular report*/
  setReportIdError: PropTypes.func.isRequired,
  /**set boolean to indicate report is loaded */
  setReportIdLoaded: PropTypes.func.isRequired,
  /**set boolean to represent report is loading */
  setReportIdLoading: PropTypes.func.isRequired,
  /**function fired to cancel report processing request */
  cancelReportProcessing: PropTypes.func.isRequired,
  /**Action fired to update flag to indicate if user was able to download report data */
  updateFlagUserDownloadedReportStatus: PropTypes.func.isRequired,
  /**whether it is element report, workpaper, custom etc */
  reportType: PropTypes.string.isRequired,
};
