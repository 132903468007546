import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';

export const TERTIARY_FLAGGED_ID_BLOCK = 'tertiary-flagged-filters-id';

const ElementFiltersFlaggedTertiary = ({ filters, setFilters, className }) => {
  return (
    <div className={className}>
      <Checkbox
        id={`${TERTIARY_FLAGGED_ID_BLOCK}-user`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.flag.user"
        checked={filters.userFlag === true}
        onChange={() => setFilters(filters.setUserFlag(!filters.userFlag))}
      />
      <Checkbox
        id={`${TERTIARY_FLAGGED_ID_BLOCK}-formula`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.flag.formula"
        checked={filters.formulaFlag === true}
        onChange={() =>
          setFilters(filters.setFormulaFlag(!filters.formulaFlag))
        }
      />
      <Checkbox
        id={`${TERTIARY_FLAGGED_ID_BLOCK}-ir`}
        label="statement-nav-panel.tab.elements.filters.modal.col-3.flag.ir"
        checked={filters.internalReferenceFlag === true}
        onChange={() =>
          setFilters(filters.setIRFlag(!filters.internalReferenceFlag))
        }
      />
    </div>
  );
};

ElementFiltersFlaggedTertiary.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersFlaggedTertiary;
