import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import HomePage from 'pages/home-page';

import { cleanUpHomePageStore } from 'store/actions/homepage-actions';
import { verifyOmniaTokenAndGetEngagement } from 'store/actions/omnia-engagement-actions';

import { push } from 'connected-react-router';

const mapStateToProps = ({ data: { omniaEngagement } }) => ({
  omniaEngagement,
});

const mapDispatchToProps = {
  cleanUpHomePageStore,
  push,
  verifyOmniaTokenAndGetEngagement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(HomePage));
