import { createAction } from 'redux-actions';
import get from 'lodash/get';
import set from 'lodash/set';

import { getCurrentUserRequest } from 'api/current-user-api';
import { collateCurrentUserDetail } from 'utils/current-user-utils';
import { getUserIDFromEmail, setDataLayer } from 'utils/adobe-analytics-utils';
import { postCurrentUserPreferencesRequest } from 'api/current-user-api';
import moment from 'moment-timezone';
import { fetchAndUpdateAllCountriesList } from 'utils/project-utils';
import { fetchFeatureInfoForAllGeos } from 'store/actions/feature-info-actions';

export const currentUserLoading = createAction('CURRENT_USER_LOADING');
export const currentUserError = createAction('CURRENT_USER_ERROR');
export const currentUserLoaded = createAction('CURRENT_USER_LOADED');

export const fetchCurrentUser =
  (callbackBeforeLoaded) => async (dispatch, getState) => {
    dispatch(currentUserLoading());
    try {
      const responses = await getCurrentUserRequest();
      const response = collateCurrentUserDetail(responses);

      if (!(response && response.result && response.result.zoneId)) {
        const zoneId = moment.tz.guess();
        const timeZoneResponse = await postCurrentUserPreferencesRequest(
          get(response, 'response.result.id', ''),
          zoneId,
        );
        set(response, 'response.result.zoneId', timeZoneResponse.data.zoneId);
      }
      callbackBeforeLoaded && callbackBeforeLoaded();
      await dispatch(currentUserLoaded(response));
      dispatch(fetchFeatureInfoForAllGeos());
      const userEmail = get(response, 'response.result.email', '');
      const userID = userEmail.includes('@deloitte')
        ? getUserIDFromEmail(userEmail)
        : '';
      setDataLayer({ pageInfo: { userID } });
      dispatch(fetchAndUpdateAllCountriesList());
      //setup WalkMe context
      window.WM_CTX = window.WM_CTX || {};
      window.WM_CTX.userId = get(response, 'response.result.id', '');
    } catch (error) {
      dispatch(currentUserError(error));
    }
  };

export const getHomeGeo = () => (dispatch, getState) => {
  const { currentUser = {} } = getState().data || {};
  return currentUser.data && currentUser.data.geoCode;
};
