import { handleActions } from 'redux-actions';
import {
  elementFormulaListLoading,
  elementFormulaListLoaded,
  elementFormulaListError,
  deleteFormulaError,
  removeElementFromFormulaTabFromSocketPayloadAction,
  addFormulaToFormulaListFromSocketPayloadAction,
  elementFormulaListLoadedWithoutResponse,
} from 'store/actions/element-panel-actions';
import { FormulaList } from 'models/api/formula-list-model';

export default handleActions(
  {
    [elementFormulaListLoading]: (formulaList) => {
      return formulaList.setLoading();
    },
    [elementFormulaListLoaded]: (formulaList, { payload }) => {
      return formulaList.setLoaded(payload);
    },
    [elementFormulaListLoadedWithoutResponse]: (formulaList) => {
      return formulaList.setLoadedWithoutResponse();
    },
    [elementFormulaListError]: (formulaList, { payload }) => {
      return formulaList.setError(payload);
    },
    [deleteFormulaError]: (formulaList, { payload }) => {
      return formulaList.setError(payload);
    },
    [removeElementFromFormulaTabFromSocketPayloadAction]: (
      formulaList,
      { payload },
    ) => {
      return formulaList.removeElementFromFormulaTabFromSocketPayload(payload);
    },
    [addFormulaToFormulaListFromSocketPayloadAction]: (
      formulaList,
      { payload },
    ) => {
      return formulaList.addFormulaToFormulaListFromSocketPayload(payload);
    },
  },
  new FormulaList(),
);
