import { handleActions } from 'redux-actions';

import {
  revisionLoading,
  revisionLoaded,
  revisionError,
  clearStatementPageRevisionStoreAction,
} from 'store/actions/revision-actions';

import Revision from 'models/api/revision-api-model';

export default handleActions(
  {
    [revisionLoading]: (revision) => {
      return revision.setLoading();
    },
    [revisionError]: (revision, { payload }) => {
      return revision.setError(payload);
    },
    [revisionLoaded]: (revision, { payload }) => {
      return revision.setLoaded(payload);
    },
    [clearStatementPageRevisionStoreAction]: (revision, { payload }) => {
      return new Revision();
    },
  },
  new Revision(),
);
