import { KebabMenuOption } from 'models/utils/common/menu/menu-option-model';
import Permissions from 'permissions/permissions';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { isProjectHasReservedClientName } from 'utils/project-list-utils';

export const PROJECTS_AMOUNT = 20;

export const getProjectKebabOptions = ({
  cardIndex,
  project,
  onSoftDeleteClick,
  onRestoreClick,
  onPermanentDeleteClick,
  push,
}) => {
  // to account for z-index issues with tooltips, the top project list card's
  // menu tooltips need to be positioned bottom so they do not run under the project
  // list card header, hence why we check index > 0
  const menuTooltipPosition = cardIndex > 0 ? 'top' : 'bottom';
  const projectHasReservedClientName = isProjectHasReservedClientName(project);
  //Handle when a project is deleted
  if (project.isDeleted()) {
    const _userCanPermanentlyDelete =
      Permissions.Project.canPermanentlyDelete(project.id) &&
      !project.legalHold;
    const _userCanRestore =
      Permissions.Project.canRestore(project.id) && !project.legalHold;
    let permDeleteTooltip, restoreToolTip;
    if (!_userCanPermanentlyDelete) {
      permDeleteTooltip = new TooltipOptions({
        text: 'permissions.delete.project',
        id: `${project.id}-delete-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    if (!_userCanRestore) {
      restoreToolTip = new TooltipOptions({
        text: 'permissions.delete.project',
        id: `${project.id}-delete-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    if (projectHasReservedClientName) {
      permDeleteTooltip = new TooltipOptions({
        text: 'client-name.not-allowed.tooltip',
        id: `${project.id}-reserved-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    return [
      new KebabMenuOption({
        id: `project-${project.id}-kebab-option-restore`,
        title: 'common.restore',
        onSelectOption: () => onRestoreClick(project),
        disabled: !_userCanRestore,
        tooltip: restoreToolTip,
      }),
      new KebabMenuOption({
        id: `project-${project.id}-kebab-option-permanent-delete`,
        title: 'common.delete',
        onSelectOption: () => onPermanentDeleteClick(project),
        disabled: !_userCanPermanentlyDelete || projectHasReservedClientName,
        tooltip: permDeleteTooltip,
      }),
    ];
  } else {
    //Otherwise handle when a project is not deleted.
    const _userCanEdit = Permissions.Project.canEdit(project.id);
    const _userCanSoftDelete =
      Permissions.Project.canSoftDelete(project.id) && !project.legalHold;
    let editTooltip, softDeleteTooltip;
    if (!_userCanEdit) {
      editTooltip = new TooltipOptions({
        text: 'permissions.edit.project',
        id: `${project.id}-edit-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    if (!_userCanSoftDelete) {
      softDeleteTooltip = new TooltipOptions({
        text: 'permissions.delete.project',
        id: `${project.id}-delete-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    if (projectHasReservedClientName) {
      softDeleteTooltip = new TooltipOptions({
        text: 'client-name.not-allowed.tooltip',
        id: `${project.id}-reserved-project-tooltip-id`,
        position: menuTooltipPosition,
      });
    }
    return [
      new KebabMenuOption({
        id: `project-${project.id}-kebab-option-edit`,
        title: 'common.edit',
        onSelectOption: () =>
          push(
            parseRoute(ROUTE_CONSTANTS.PROJECT_EDIT_PAGE, {
              params: { id: project.id },
            }),
          ),
        disabled: !_userCanEdit,
        tooltip: editTooltip,
      }),
      new KebabMenuOption({
        id: `project-${project.id}-kebab-option-soft-delete`,
        title: 'common.delete',
        onSelectOption: () => onSoftDeleteClick(),
        disabled: !_userCanSoftDelete || projectHasReservedClientName,
        tooltip: softDeleteTooltip,
      }),
    ];
  }
};

export const SUPPORT_TEAM_ACCESS_TOOLTIP = new TooltipOptions({
  text: 'project-list.support-team-access.tooltip',
  id: 'project-list-id-support-team-access',
  position: 'right',
});

export const COE_TEAM_ACCESS_TOOLTIP = new TooltipOptions({
  text: 'project-details.coe-team-access.tooltip',
  id: 'project-details-id-coe-team-access',
  position: 'right',
});
