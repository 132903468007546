import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Card from 'components/common/card-component';
import Dropdown from 'components/common/dropdown-component';
import TextInput from 'components/common/text-input-component';
import { ReactComponent as InfoIcon } from 'icons/icon-information-tooltip.svg';
import { SearchTableDropdownApi } from 'components/common/search-component';
import ProgressBarStep from 'components/common/progress-bar-step-component';
import ConditionalRender from 'components/util/conditional-render-component';
import { EngagementList } from 'models/api/engagement-list-model';
import { DataGridDataApi } from 'components/common/data-grid/data-grid-component';
import { BACKSPACE_KEY_CODE } from 'constants/util/key-code-constants';

import { ENGAGEMENT_SEARCH_COLUMNS } from 'constants/feature/project-creation-constants';
import {
  debounceSearch,
  getSearchFormattedEngagementEntity,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import ProjectForm from 'models/form/project-form-model';
import { getEngagementEntitiesRequest } from 'api/project-creation-edit-api';
import Switch from 'react-switch';
import NonAuditInputArea from 'components/feature/project-creation/project-info/_project-creation-project-info-non-audit-text-input';
import { doesUserExistInAMERegion } from 'utils/project-utils';

export const PROJECT_INFO_BLOCK = 'project-info';
const PROJECT_INFO_ID_BLOCK = 'id-project-info';
const SWITCH_HEIGHT = 20;
const SWITCH_WIDTH = 45;

class ProjectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      engagementEntities: new EngagementList(),
    };
    // do not call this function directly, call _searchUser, which uses the debounced version of this function
    this.debouncedSearch = debounceSearch(this._fetchEngagementEntities);
  }

  componentDidMount() {
    const { setIsAuditClient } = this.props;
    const isAMERegion = doesUserExistInAMERegion();
    if (!isAMERegion) {
      setIsAuditClient(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectInProgress } = this.props;
    if (
      prevProps.projectInProgress !== projectInProgress &&
      !projectInProgress.isAuditClient
    ) {
      // reset the search field when switch has been turned on
      this.setState({ search: '' });
    }
  }

  _fetchEngagementEntities = async ({ search }) => {
    this.setState((state) => ({
      engagementEntities: state.engagementEntities.setLoading(),
    }));
    try {
      const response = await getEngagementEntitiesRequest(search);
      this.setState((state) => ({
        engagementEntities: state.engagementEntities.setLoaded({ response }),
      }));
    } catch (err) {
      this.setState((state) => ({
        engagementEntities: state.engagementEntities.setError(err),
      }));
    }
  };

  _searchEngagement = (val) => {
    this.setState({ search: val }, () => {
      if (isSearchCharLengthValid(val)) {
        this.debouncedSearch({ search: val });
      }
    });
  };

  _setEngagementEntity = (entity) => {
    const {
      setEngagementEntity,
      setNonAuditEngagementEntityDataHasBeenModified,
    } = this.props;

    this.setState(
      {
        search: getSearchFormattedEngagementEntity(entity),
      },
      () => {
        setEngagementEntity(entity);
        setNonAuditEngagementEntityDataHasBeenModified(true);
      },
    );
  };

  _setEnagementData = () => {
    const { projectInProgress, setEngagementEntity } = this.props;

    if (!projectInProgress.nonAuditEngagementEntityDataHasBeenModified) {
      this._searchEngagement('');
    } else {
      this._searchEngagement('');
      setEngagementEntity(null);
    }
  };

  render() {
    const {
      setProjectName,
      projectInProgress,
      setIsAuditClient,
      setClientName,
      setEngagementName,
      setEngagementFye,
      setNonAuditReason,
      countryList,
      setProjectCountry,
      currentUser,
      setNonAuditEngagementEntityDataHasBeenModified,
    } = this.props;
    const { search, engagementEntities } = this.state;
    return (
      <div className={PROJECT_INFO_BLOCK}>
        <div className={`${PROJECT_INFO_BLOCK}__step`}>
          <ProgressBarStep
            id={`${PROJECT_INFO_ID_BLOCK}-step`}
            active={true}
            isValid={projectInProgress.isStepOneValid()}
            stepNum={'1'}
          />
        </div>
        <div
          id={`${PROJECT_INFO_ID_BLOCK}-card`}
          className={`${PROJECT_INFO_BLOCK}__card`}
        >
          <Card>
            <div className={`${PROJECT_INFO_BLOCK}__title`}>
              <FormattedMessage id={'project-creation.project-info.title'} />
            </div>
            <div className={`${PROJECT_INFO_BLOCK}__instructions`}>
              <FormattedMessage id={'project-creation.project-info.info'} />
            </div>
            <div className={`${PROJECT_INFO_BLOCK}__text-input`}>
              <TextInput
                id={`${PROJECT_INFO_ID_BLOCK}-project-name-text-input`}
                label={'project-creation.project-info.project-name'}
                width={'75%'}
                disabled={false}
                placeholder={
                  'project-creation.project-info.project-name.placeholder'
                }
                onChange={(text) => {
                  setProjectName(text);
                }}
                value={projectInProgress.name}
                maxLength={100}
                isValid={true}
                errorText={{ id: 'project-edit-input-error-text' }}
                type={'text'}
              />
            </div>
            <div className={`${PROJECT_INFO_BLOCK}__display-horizontal`}>
              <div className={`${PROJECT_INFO_BLOCK}__region`}>
                <div className={`${PROJECT_INFO_BLOCK}__region-label`}>
                  <FormattedMessage
                    id={'project-creation.project-info.project-region'}
                  />
                </div>
                <div className={`${PROJECT_INFO_BLOCK}__region-name`}>
                  {currentUser.geoCode}
                </div>
              </div>
              <ConditionalRender dependencies={[countryList]}>
                <div className={`${PROJECT_INFO_BLOCK}__dropdown`}>
                  <Dropdown
                    id={`${PROJECT_INFO_ID_BLOCK}-project-country`}
                    label={'project-creation.project-info.project-country'}
                    placeholder={'common.placeholder.select'}
                    onSelectOption={(option) => {
                      setProjectCountry(option);
                    }}
                    options={countryList.countriesOptions}
                    value={projectInProgress.countryOfIssuanceDropdownValue}
                    iconConfig={{
                      infoText: 'project-creation.project-country.tooltip.info',
                      tooltipInfoId: `${PROJECT_INFO_ID_BLOCK}-info-tooltip`,
                      iconComponent: InfoIcon,
                    }}
                  />
                </div>
              </ConditionalRender>
            </div>
            <div className={`${PROJECT_INFO_BLOCK}__switch`}>
              <div className={`${PROJECT_INFO_BLOCK}__switch-label`}>
                <FormattedMessage
                  id={'project-creation.project-info.non-audit-input-header'}
                />
              </div>
              <Switch
                onChange={(val) => {
                  setIsAuditClient(val);
                  setNonAuditEngagementEntityDataHasBeenModified(false);
                }}
                checked={!projectInProgress.auditClient}
                uncheckedIcon={false}
                checkedIcon={false}
                height={SWITCH_HEIGHT}
                width={SWITCH_WIDTH}
              />
            </div>
            {projectInProgress.auditClient ? (
              <div className={`${PROJECT_INFO_BLOCK}__search`}>
                <SearchTableDropdownApi
                  columns={ENGAGEMENT_SEARCH_COLUMNS}
                  id={`${PROJECT_INFO_ID_BLOCK}-client-name-search`}
                  label={'project-creation.project-info.client-search'}
                  width={'75%'}
                  placeholder={
                    'project-creation.project-info.client-search.placeholder'
                  }
                  onChange={(val) => this._searchEngagement(val)}
                  onClear={() => {
                    this._setEnagementData();
                  }}
                  value={search}
                  onSelectResult={this._setEngagementEntity}
                  onKeyDown={(e) =>
                    e.keyCode === BACKSPACE_KEY_CODE && e.target.value === ''
                      ? this._searchEngagement('')
                      : null
                  }
                  isValid
                  errorText={{ id: 'project-edit-input-error-text' }}
                  showSearchButton
                  resultsApiModel={
                    new DataGridDataApi({
                      apiModel: engagementEntities,
                      rowItems: engagementEntities.getEngagementEntities(),
                    })
                  }
                />
              </div>
            ) : (
              <NonAuditInputArea
                setClientName={setClientName}
                setEngagementName={setEngagementName}
                setEngagementFye={setEngagementFye}
                projectInProgress={projectInProgress}
                setNonAuditReason={setNonAuditReason}
              />
            )}
          </Card>
        </div>
      </div>
    );
  }
}

ProjectInfo.propTypes = {
  /** Func that fires action to set project name */
  setProjectName: PropTypes.func.isRequired,
  /** Func that set the isAuditClient toggle */
  setIsAuditClient: PropTypes.func.isRequired,
  /** Func that fires action to set engagement entity*/
  setEngagementEntity: PropTypes.func.isRequired,
  /** Object that contains working data for form before submission*/
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Function to set the client name */
  setClientName: PropTypes.func.isRequired,
  /** Function to set the engagement name */
  setEngagementName: PropTypes.func.isRequired,
  /** Function to set the fiscal year */
  setEngagementFye: PropTypes.func.isRequired,
  /** Function to set the non audit reason */
  setNonAuditReason: PropTypes.func.isRequired,
  /** Function to check if non audit client has been modified*/
  setNonAuditEngagementEntityDataHasBeenModified: PropTypes.func.isRequired,
};

export default ProjectInfo;
