import React, { memo } from 'react';
import { connect } from 'react-redux';
import {
  updateUserFlag,
  updateVerify,
  updateReviewed,
  detachTickmarkFromElement,
  detachComfortLetterAction,
} from 'store/actions/element-panel-actions';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormulaList } from 'models/api/formula-list-model';
import ConditionalRender from 'components/util/conditional-render-component';
import TabModel from 'models/utils/common/tab-model';
import ElementStatusContainer from 'containers/feature/element-panel/element-status-container';
import ElementAttributesContainer from 'containers/feature/element-panel/element-attributes-container';
import ElementHistory from 'components/feature/element-panel/element-history-component';
import Annotations from './annotations/_element-panel-annotations-component';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import InternalReference from 'models/api/internal-reference-model';
import Permissions from 'permissions/permissions';
import SelectedStatement from 'models/api/selected-statement-model';
import ElementComfortLetterList from 'models/api/element-comfort-letter-list-model';
import { getElementComfortLettersAndUpdateElementCache } from 'store/actions/element-panel-actions';

export const ELEMENT_PANEL_BODY_BLOCK = 'element-panel-body';
export const ELEMENT_PANEL_BODY_ID_BLOCK = 'element-panel-body-id';

const ElementPanelBody = ({
  selectedProjectId,
  elementDetails,
  formulaList,
  selectedElementPanelAnnotationsTab,
  setCurrentElementAnnotationsTab,
  primaryAnnotationActions,
  secondaryAnnotationActions,
  onEditFormula,
  internalReference,
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  detachTickmarkFromElement,
  workpaperList,
  onDetachWorkpaper,
  tickmarkList,
  comfortLetterList,
  selectedStatement,
  getElementComfortLettersAndUpdateElementCache,
  revisionId,
  detachComfortLetterAction,
}) => {
  const _permissionToEdit = Permissions.Element.canEditElement(
    selectedProjectId,
  );
  const readOnly = selectedStatement.isReadOnly();

  return (
    <ConditionalRender dependencies={[elementDetails, formulaList]}>
      <div className={ELEMENT_PANEL_BODY_BLOCK}>
        <ElementStatusContainer
          disabled={!_permissionToEdit || readOnly}
          setCurrentElementAnnotationsTab={setCurrentElementAnnotationsTab}
        />
        <ElementAttributesContainer
          disabled={!_permissionToEdit || readOnly}
          selectedElementPanelAnnotationsTab={
            selectedElementPanelAnnotationsTab
          }
        />
        <Annotations
          elementDetails={elementDetails}
          revisionId={revisionId}
          formulaList={formulaList}
          selectedElementPanelAnnotationsTab={
            selectedElementPanelAnnotationsTab
          }
          internalReference={internalReference}
          selectElementFromInternalReference={
            selectElementFromInternalReference
          }
          detachElementFromInternalReference={
            detachElementFromInternalReference
          }
          workpaperList={workpaperList}
          setCurrentElementAnnotationsTab={setCurrentElementAnnotationsTab}
          primaryAnnotationActions={primaryAnnotationActions}
          secondaryAnnotationActions={secondaryAnnotationActions}
          onEditFormula={onEditFormula}
          onDetachWorkpaper={onDetachWorkpaper}
          onDetachComfortLetter={detachComfortLetterAction}
          onDetachTickmark={detachTickmarkFromElement}
          tickmarkList={tickmarkList}
          comfortLetterList={comfortLetterList}
          readOnly={readOnly}
          getElementComfortLettersAndUpdateElementCache={
            getElementComfortLettersAndUpdateElementCache
          }
        />
        <ElementHistory />
      </div>
    </ConditionalRender>
  );
};

ElementPanelBody.propTypes = {
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Selected tab for the annotations table */
  selectedElementPanelAnnotationsTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Action to set the annotations tab */
  setCurrentElementAnnotationsTab: PropTypes.func.isRequired,
  /** Map of actions fired when a user clicks on the primary button of the annotations content */
  primaryAnnotationActions: PropTypes.object.isRequired,
  /** Map of actions fired when a user clicks on the secondary button of the annotations content */
  secondaryAnnotationActions: PropTypes.object,
  /** The list of formulas for the current element*/
  formulaList: PropTypes.instanceOf(FormulaList).isRequired,
  /** Action fired to get details for edit */
  onEditFormula: PropTypes.func.isRequired,
  /** Internal reference that is currently linked to this element */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** action fired to detach a work paper from this element */
  onDetachWorkpaper: PropTypes.func.isRequired,
  /** action fired to detach a tickmark from this element */
  detachTickmarkFromElement: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /**List of comfort letters attached to this element */
  comfortLetterList: PropTypes.instanceOf(ElementComfortLetterList).isRequired,
  /**To update element cache, tickmark toolkit, workpaper toolkit and element search results with latest comfort letter details  */
  getElementComfortLettersAndUpdateElementCache: PropTypes.func.isRequired,
  /**Number to represent revision id */
  revisionId: PropTypes.number.isRequired,
  /**ation method fired to detach comfort letter */
  detachComfortLetterAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    revision,
    selectedProject: { id },
    elementPanel: {
      elementDetails,
      formulaList,
      internalReference,
      workpaperList,
      tickmarkList,
      comfortLetterList,
    },
    selectedStatement,
  },
}) => ({
  revisionId: revision.id,
  elementDetails,
  formulaList,
  internalReference,
  workpaperList,
  tickmarkList,
  comfortLetterList,
  selectedProjectId: id,
  selectedStatement,
});

const mapDispatchToProps = {
  updateUserFlag,
  updateVerify,
  updateReviewed,
  detachTickmarkFromElement,
  getElementComfortLettersAndUpdateElementCache,
  detachComfortLetterAction,
};

export { ElementPanelBody };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ElementPanelBody));
