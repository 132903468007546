import { handleActions } from 'redux-actions';
import Zoom from 'models/data/zoom-model';

import {
  incrementZoom,
  decrementZoom,
  resetZoom,
} from 'store/actions/zoom-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [incrementZoom]: (zoom) => {
      return zoom.incrementZoom();
    },
    [decrementZoom]: (zoom) => {
      return zoom.decrementZoom();
    },
    [resetZoom]: () => {
      return new Zoom();
    },
    [clearStatementPageStoreAction]: () => {
      return new Zoom();
    },
  },
  new Zoom()
);
