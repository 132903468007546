import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { SECTION_HIGHLIGHT_STATES } from 'constants/feature/section-details-constants';

class SelectedSectionModel extends BaseModel({
  color: SECTION_HIGHLIGHT_STATES.DEFAULT,
  isSelected: false,
}) {}
/**
 * A set of selected section ids, keys are removed when they are no longer selected
 */
export default class SelectedSectionMap extends BaseModel({
  /* shape is
    {
      [sectionId]: true 
      ...
    }
  */
}) {
  /**
   * Reducer function for simultaneously clearing old selected ids and initializing
   * the set with new ones
   * @param {int[]} param.sectionIds array of section ids
   */
  replaceWithSections({
    color = SECTION_HIGHLIGHT_STATES.DEFAULT,
    sectionIds,
  }) {
    const sectionMap = {};
    sectionIds.forEach((id) => {
      sectionMap[id] = new SelectedSectionModel({ color, isSelected: true });
    });
    return new SelectedSectionMap({ ...sectionMap });
  }

  /**
   * Reducer function for appending sections to the selection set
   * @param {int[]} param.sectionIds array of section ids
   */
  appendSelectedSections({
    sectionIds,
    color = SECTION_HIGHLIGHT_STATES.DEFAULT,
  }) {
    const sectionMap = {};
    sectionIds.forEach((id) => {
      // add 1 to existing or init as 1
      sectionMap[id] = new SelectedSectionModel({ color, isSelected: true });
    });
    return this.merge({
      ...sectionMap,
    });
  }

  /**
   * Reducer function for appending a section to the selection set
   * @param {int} param.sectionId
   */
  appendSelectedSection({
    sectionId,
    color = SECTION_HIGHLIGHT_STATES.DEFAULT,
  }) {
    return this.merge({
      [sectionId]: new SelectedSectionModel({ color, isSelected: true }),
    });
  }

  /**
   * Reducer function for deselecting an section
   * @param {int} param.sectionId
   */
  deselectSection({ sectionId }) {
    if (this[sectionId]) {
      const newMap = { ...this };
      delete newMap[sectionId];
      return new SelectedSectionMap({ ...newMap });
    }
    return this;
  }

  /**
   * Reducer function for clearing the set
   */
  clearMap() {
    return new SelectedSectionMap();
  }

  has(id) {
    return !isNullOrUndefined(this[id]) && this[id] === true;
  }

  deallocateSection() {
    let keys = Object.keys(this);
    for (let i = 0; i < keys.length; i++) {
      if (this[keys[i]].color === SECTION_HIGHLIGHT_STATES.PANEL_SELECTED) {
        this[keys[i]].color = SECTION_HIGHLIGHT_STATES.DEFAULT;
      }
    }
    return this;
  }
}
