import { createAction } from 'redux-actions';

export const toggleNavigationEditMode = createAction(
  'SET_NAVIGATION_EDIT_MODE'
);

export const toggleNavigationEditModeDispatch = () => async (
  dispatch,
  getState
) => {
  dispatch(toggleNavigationEditMode());
};
