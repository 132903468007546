import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/common/dropdown-component';
import { getUserMenuOptions } from 'constants/feature/section-details-constants';
import Chip from 'components/common/chip-component';

export const BULK_SELECT_SECTIONS_BLOCK = 'bulk-select-sections';
export const BULK_SELECT_SECTIONS_ID = 'bulk-select-sections-id';

const StatementNavBulkSelectUsers = ({
  usersList,
  handleUserSelect,
  handleChipRemove,
  assignedUsers,
}) => {
  return (
    <>
      <div className={`${BULK_SELECT_SECTIONS_BLOCK}__chip-container`}>
        {Object.keys(assignedUsers).map((key) => {
          return (
            <Chip
              key={`assigned-user-key-${key}`}
              id={`${BULK_SELECT_SECTIONS_ID}-key`}
              onRemove={() => handleChipRemove(key)}
            >
              {assignedUsers[key].name}
            </Chip>
          );
        })}
      </div>
      <Dropdown
        id={`${BULK_SELECT_SECTIONS_ID}-assign-modal`}
        placeholder={'section-details.assign-modal.placeholder'}
        width={'100%'}
        onSelectOption={(user) => handleUserSelect(user)}
        isValid={true}
        options={getUserMenuOptions(usersList, assignedUsers)}
        value={null}
      />
    </>
  );
};

StatementNavBulkSelectUsers.propTypes = {
  /** an array of all the users*/
  usersList: PropTypes.array.isRequired,
  /**function to be called when user is selected from the dropdown */
  handleUserSelect: PropTypes.func.isRequired,
  /**function to be called when selected users is removed */
  handleChipRemove: PropTypes.func.isRequired,
  /**An object with userId and details pertaining to user mapped as key value pair */
  assignedUsers: PropTypes.object.isRequired,
};

export default StatementNavBulkSelectUsers;
