import { withDefaultContextAsProject } from 'api/api-default-context';
import { serializedProjectIdWithGeo } from 'utils/project-utils';

export const getSelectedProjectRequest = (id, contextKey) => {
  return withDefaultContextAsProject({ contextKey }, id)
    .get('/tieout/client', {
      params: {
        clientId: parseInt(id),
      },
    })
    .then((response) => {
      response.data.id = serializedProjectIdWithGeo(
        response.data.id,
        response.data.geoCode,
      );
      return response;
    });
};

export default {
  getSelectedProjectRequest,
};
