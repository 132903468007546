import React from 'react';
import Checkbox from 'components/common/checkbox-component';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ProjectForm from 'models/form/project-form-model';
import { useState } from 'react';
import { ViewCOEDisclaimerModal } from './coe-team-access-view-disclaimer';
import { AcknoledgeCOEAccessDisclaimerModal } from './coe-team-acknowledgement-modal';
import Card from 'components/common/card-component';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import Permissions from 'permissions/permissions';

const EDIT_MANAGE_COE_TEAM_ACCESS_ID_BLOCK = 'manage-coe-team-access-id';
const EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK = 'manage-coe-team-access';

export const EditManageCOETeamAccess = ({
  projectInProgress,
  toggleHasCoeGroup,
}) => {
  const [showViewDisclaimerModal, setShowViewDisclaimerModal] = useState(false);
  const [
    showAcknowledgeDisclaimerModal,
    setShowAcknowledgeDisclaimerModal,
  ] = useState(false);

  const _onCoeTeamAccessChange = () => {
    projectInProgress.hasCoeGroup
      ? toggleHasCoeGroup()
      : setShowAcknowledgeDisclaimerModal((prev) => !prev);
  };

  const _isCoeTeamAccessCheckboxDisabled = () => {
    // We have maintained separate access set for who can enable and who can disable.
    // Currently it might seem irrelevant, but in future we might require separate set of people
    // having enable privelege and separate set for disable privelege. In that scenario our
    // infra will be ready to cope with the requirement.
    return (
      !Permissions.Project.canDisableCoeTeamAccess(projectInProgress.id) &&
      !Permissions.Project.canEnableCoeTeamAccess(projectInProgress.id)
    );
  };

  return (
    <div className={EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}>
      <Card>
        <h3 className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__title`}>
          <FormattedMessage id={'project-edit.manage-coe.header'} />
        </h3>
        <div className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__info`}>
          <FormattedMessage id={'project-edit.manage-coe-team-access.info'} />
        </div>

        <div className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__checkbox`}>
          <Checkbox
            checked={projectInProgress.hasCoeGroup}
            value={'coe-team-access'}
            disabled={_isCoeTeamAccessCheckboxDisabled()} // IF it has privilege then do not disable
            onChange={() => _onCoeTeamAccessChange()}
            label={'project-edit.manage-users.coe-team-access'}
            id={`${EDIT_MANAGE_COE_TEAM_ACCESS_ID_BLOCK}-coe-team-access-checkbox`}
            width={'75%'}
            isValid={true}
          />
        </div>
        <div
          className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__view-disclaimer`}
        >
          <button
            id={`${EDIT_MANAGE_COE_TEAM_ACCESS_ID_BLOCK}-disclaimer`}
            className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__disclaimer-button`}
            onClick={() => setShowViewDisclaimerModal((prev) => !prev)}
          >
            <FormattedMessage
              id={
                'project-creation.manage-users.support-team-access.disclaimer'
              }
            />
          </button>
          {projectInProgress.hasCoeGroup && (
            <Banner
              width={'75%'}
              id={`${EDIT_MANAGE_COE_TEAM_ACCESS_ID_BLOCK}-coe-team-banner`}
              className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__banner`}
              type={BANNER_TYPES.INFORMATION}
              bannerCopy={
                'project-creation.manage-coe-access.coe-team-access.banner'
              }
              isInternationalized
            />
          )}
          {projectInProgress.hasCoeGroup && projectInProgress.coeGroupAddDate && (
            <div
              className={`${EDIT_MANAGE_COE_TEAM_ACCESS_BLOCK}__coe-enable-history`}
            >
              <div>
                <FormattedMessage
                  id={
                    'project-edit.manage-users.coe-access.history.last-enabled'
                  }
                  values={{
                    coeAddDate: (
                      <span>{projectInProgress.coeGroupAddDate}</span>
                    ),
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  id={'project-edit.manage-users.coe-access.history.enabled-by'}
                  values={{
                    coeAddName: (
                      <span>{projectInProgress.coeGroupEnabledByUserName}</span>
                    ),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Card>
      {showViewDisclaimerModal && (
        <ViewCOEDisclaimerModal
          onClose={() => setShowViewDisclaimerModal((prev) => !prev)}
        />
      )}
      {showAcknowledgeDisclaimerModal && (
        <AcknoledgeCOEAccessDisclaimerModal
          onClose={() => setShowAcknowledgeDisclaimerModal(() => false)}
          onSubmit={() => {
            toggleHasCoeGroup();
            setShowAcknowledgeDisclaimerModal((prev) => !prev);
          }}
        />
      )}
    </div>
  );
};

EditManageCOETeamAccess.propTypes = {
  /** Project object to edit and be submitted */
  projectInProgress: PropTypes.instanceOf(ProjectForm).isRequired,
  /** Function fired for adding coe team access on this project */
  toggleHasCoeGroup: PropTypes.func.isRequired,
};

export default EditManageCOETeamAccess;
