import { createAction } from 'redux-actions';
import {
  cancelReportIdProcessingRequest,
  fetchToolkitReportList,
} from 'api/statement-list-api';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import {
  toggleExportPanel,
  toggleSubPanelWithinExport,
} from './panel-controller-actions';
import {
  downloadReportFromGuidApiSelector,
  downloadReportNotification,
  getExportReportType,
  NOTIFICATION_TYPE,
} from 'utils/statement-content-page-utils';
import {
  REPORT_DOWNLOAD_PROCESSING,
  REPORT_DOWNLOAD_PROCESSED,
  REPORT_DOWNLOAD_FAILED,
} from '../../models/api/toolkit-export-download-list-model';
import { REPORT_TYPES } from 'store/actions/statement-list/statement-list-actions';

export const setDownloadData = createAction('SET_SINGLE_DOWNLOAD_DATA');
export const setDownloadListData = createAction('SET_DOWNLOAD_LIST_DATA');
export const updateDownloadStatus = createAction('UPDATE_DOWNLOAD_STATUS');

export const setDownloadDataLoading = createAction('DOWNLOAD_DATA_LOADING');
export const setDownloadDataLoaded = createAction('DOWNLOAD_DATA_LOADED');
export const setDownloadDataError = createAction('DOWNLOAD_DATA_ERROR');
export const clearDownloadData = createAction('CLEAR_DOWNLOAD_DATA');

// To set loading, loaded, error for single reports
export const setReportIdLoading = createAction('REPORT_ID_LOADING_ACTION');
export const setReportIdLoaded = createAction('REPORT_ID_LOADED_ACTION');
export const setReportIdError = createAction('REPORT_ID_ERROR_ACTION');
export const updateFlagUserDownloadedReportStatus = createAction(
  'UPDATE_FLAG_USER_DOWNLOADED_REPORT_STATUS',
);

export const showDownloadTabInsideExport = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left !== LEFT_PANELS.EXPORT && left !== LEFT_PANELS.DOWNLOAD) {
    dispatch(toggleExportPanel(LEFT_PANELS.DOWNLOAD));
  } else if (left === LEFT_PANELS.EXPORT) {
    dispatch(toggleSubPanelWithinExport());
  }
};

export const updateToolkitReportWithLoading =
  () => async (dispatch, getState) => {
    dispatch(setDownloadDataLoading());
    dispatch(updateReportWithLatestRecordWithoutLoading());
  };

export const updateReportWithLatestRecordWithoutLoading =
  () => async (dispatch, getState) => {
    const stateData = getState().data;
    const {
      revision: { id },
      toolkitExportPanel: { downloadProgressData },
    } = stateData;

    fetchToolkitReportList({ revisionId: id })
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          dispatch(setDownloadListData(response.data));
        }
        // if response is empty then set loaded with empty list
        else {
          dispatch(setDownloadDataLoaded());
        }
      })
      .catch((e) =>
        // We do not intend to block users activity for reports which is already available
        !(downloadProgressData && downloadProgressData.hasReports())
          ? dispatch(setDownloadDataError(e))
          : dispatch(setDownloadDataLoaded()),
      );
  };

export const cancelReportWithLoading =
  (reportId, name) => async (dispatch, getState) => {
    dispatch(setReportIdLoading({ id: reportId }));
    dispatch(cancelReportProcessingAction(reportId, name));
  };

export const cancelReportWithoutLoading =
  (reportId) => async (dispatch, getState) => {
    dispatch(cancelReportProcessingAction(reportId));
  };

export const cancelReportProcessingAction =
  (reportId, name) => async (dispatch, getState) => {
    const stateData = getState().data;
    const { revision } = stateData;
    try {
      await cancelReportIdProcessingRequest({
        revisionId: revision.id,
        reportId,
        reportName: name,
      });
      dispatch(
        updateDownloadStatus({
          id: reportId,
          downloadStatus: REPORT_DOWNLOAD_FAILED,
          downloadResponse: null,
          storageGuid: null,
        }),
      );
    } catch (e) {
      dispatch(setReportIdError({ id: reportId, error: e }));
    }
  };

// This action method is to be called when we make an api call to send processing request
export const reportProcessingRequestAction =
  (response) => async (dispatch, getState) => {
    if (response.data.status === REPORT_DOWNLOAD_PROCESSING) {
      const reportType =
        response.data.reportType === REPORT_TYPES.AUDIT_TRAIL_REPORT
          ? `${REPORT_TYPES.AUDIT_TRAIL_REPORT} Trail`
          : response.data.reportType;
      response.data.reportType &&
        downloadReportNotification(reportType, NOTIFICATION_TYPE.SUCCESS);
    } else if (response.data.status === REPORT_DOWNLOAD_PROCESSED) {
      downloadReportNotification(undefined, NOTIFICATION_TYPE.PROCESSED);
    }
    dispatch(setDownloadData(response.data));
  };

// This action method is designed to do upsert operation and I have tried my best to cover
// all scenarios to choose whether to update or insert
export const downloadReportAction =
  (response) => async (dispatch, getState) => {
    const stateData = getState().data;
    const {
      toolkitExportPanel: { downloadProgressData },
    } = stateData;

    let modifiedResponse = {
      reportType: getExportReportType(response.reportType),
      id: response.reportId,
      downloadStatus: response.status,
      downloadResponse: response.byteDocument,
      storageGuid: response.storageGuid,
      revisionId: response.revisionId,
      fileName: response.fileName,
      // This might be a bit confusing. For BE, the field downlaodStatus is what we call userDownloadedReportStatus
      // and the field status is what we call downloadStatus. We need to interpret it correctly at our end.
      userDownloadedReportStatus: response.downloadedStatus,
    };
    try {
      // If downloadResponse already exists or byteDocument (byte code for report) already exists then
      // we do not want to make unnecessary api call for getting blob.
      // And we must have storage guid, in case we are making api calls for getting blob
      if (
        response.storageGuid &&
        response.status === REPORT_DOWNLOAD_PROCESSED &&
        !(
          (downloadProgressData &&
            downloadProgressData.hasReportId(response.reportId) &&
            downloadProgressData.getReportById(response.reportId)
              .downloadResponse) ||
          response.byteDocument
        )
      ) {
        let blobResponse = await downloadReportFromGuidApiSelector(
          modifiedResponse,
        );
        // eslint-disable-next-line require-atomic-updates
        response.downloadResponse = blobResponse;
        modifiedResponse.downloadResponse = blobResponse;
      }
    } catch (e) {
      // we do not want to show error pop up message for programs attempt to download report internally.
      // They can attempt again manually.
      console.error('error occured while downloading report internally', e);
    } finally {
      // finally block is required, since irrespective of, if the report download in the try block
      // was successfull or not, we want to update the status of report. The updated status will enable the
      // download icon, using which user can fire api call for downloading report.

      // if report already exists then update else insert new record
      if (
        downloadProgressData &&
        downloadProgressData.hasReportId(response.reportId) &&
        // we want to update only processing report and not already processed one
        downloadProgressData.getReportById(response.reportId).downloadStatus ===
          REPORT_DOWNLOAD_PROCESSING
      ) {
        let reportType =
          downloadProgressData &&
          downloadProgressData.hasReportId(response.reportId) &&
          downloadProgressData.getReportById(response.reportId).reportType;

        // Here we expect status to change from the following :
        // Processing to Processed
        // OR, Processing to Processing
        // OR, Processing to Failed
        await dispatch(updateDownloadStatus(modifiedResponse));

        // send notification to user about succesfull processing/download of report.
        // We want to show notification for, updates for which the old staus is processing
        // Irrespective of any report, the success notification is same, hence first argument for report type is undefined
        downloadProgressData.getReportById(response.reportId).downloadStatus ===
          REPORT_DOWNLOAD_PROCESSED &&
          downloadReportNotification(undefined, NOTIFICATION_TYPE.PROCESSED);

        reportType &&
          downloadProgressData.getReportById(response.reportId)
            .downloadStatus === REPORT_DOWNLOAD_FAILED &&
          downloadReportNotification(reportType, NOTIFICATION_TYPE.FAILURE);
      } else if (
        !(
          downloadProgressData &&
          downloadProgressData.hasReportId(response.reportId)
        )
      ) {
        dispatch(setDownloadData(response));
      }
    }
  };
