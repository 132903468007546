import { handleActions } from 'redux-actions';

import CompareStatesModel from 'models/api/compare-api-model';
import {
  comparePanelLoading,
  comparePanelLoaded,
  comparePanelError,
} from 'store/actions/compare-stats-panel-actions';

export default handleActions(
  {
    [comparePanelLoading]: (compareStatesModel) => {
      return compareStatesModel.setLoading();
    },
    [comparePanelLoaded]: (compareStatesModel, { payload }) => {
      return compareStatesModel.setLoaded(payload);
    },
    [comparePanelError]: (compareStatesModel, { payload }) => {
      return compareStatesModel.setError(payload);
    },
  },
  new CompareStatesModel()
);
