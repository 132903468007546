import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { STATEMENT_LIST_TABS } from 'constants/feature/statement-list-constants';

const NoStatements = ({ isFilterActive }) => {
  const currentTab = useSelector((store) => {
    return store.data.statementList.statementListFilters.selectedTab;
  });

  const getStatusMessage = () => {
    if (isFilterActive) {
      return 'statement-list.no-statements.search';
    }
    if (currentTab) {
      switch (currentTab.id) {
        case STATEMENT_LIST_TABS.ACTIVE.id: {
          return 'statement-list.no-statements.inProgress';
        }
        case STATEMENT_LIST_TABS.ARCHIVED.id: {
          return 'statement-list.no-statements.archived';
        }
        case STATEMENT_LIST_TABS.DELETED.id: {
          return 'statement-list.no-statements.deleted';
        }
        default: {
          return 'statement-list.no-statements.inProgress';
        }
      }
    }
    return 'statement-list.no-statements.inProgress';
  };
  return <FormattedMessage id={getStatusMessage()} />;
};

export default memo(NoStatements);
