import BaseModel from 'models/base-model';

export default class MenuApiData extends BaseModel({
  /* Api model representing the response object, used for loading animations on the table */
  apiModel: null,
  /* Array of MenuOption options, should be a (sub)property of the apiModel */
  options: [],
}) {
  constructor({ apiModel, options }) {
    super();
    // TODO would like to add this instanceof check here
    // if (!(apiModel instanceof ApiModel)) {
    //   throw Error('apiModel prop for menu component needs to be of type ApiModel');
    // }
    if (!Array.isArray(options)) {
      throw Error('options prop for menu component needs to be of type Array');
    }
    this.apiModel = apiModel;
    this.options = options;
  }
}
