import { handleActions } from 'redux-actions';

import TickmarkListModel from 'models/api/tickmark-list-api-model';
import {
  tickmarkListLoading,
  tickmarkListLoaded,
  tickmarkListError,
  fetchElementsFromTickmarkAction,
  updateTickmarkElementsMapAction,
  clearTickmarkElementsAction,
  removeTickmarkFromElementsMapAction,
  updateTickmarkElementsMapFromArrayAction,
  removeElementFromTickmarkElementsMapWithSocketPayloadAction,
  addTickmarkToDeletedArrayAction,
  excludeElementFromElementsMapAction,
  deleteTickmarkWithSocketPayloadAction,
  updateTickmarkWithSocketPayloadAction,
  addTickmarkToListAction,
  unlinkTickmarkFromElementWithWebsocket,
} from 'store/actions/tickmark-list-panel-actions';

export default handleActions(
  {
    [tickmarkListLoading]: (tickmarkList) => {
      return tickmarkList.setLoading();
    },
    [tickmarkListLoaded]: (tickmarkList, { payload }) => {
      return tickmarkList.setLoaded(payload);
    },
    [tickmarkListError]: (tickmarkList, { payload }) => {
      return tickmarkList.setError(payload);
    },
    [fetchElementsFromTickmarkAction]: (tickmarkList, { payload }) => {
      return tickmarkList.setElementList(payload);
    },
    [updateTickmarkElementsMapAction]: (tickmarkList, { payload }) => {
      return tickmarkList.updateTickmarkElementsMap(payload);
    },
    [updateTickmarkElementsMapFromArrayAction]: (tickmarkList, { payload }) => {
      return tickmarkList.updateTickmarkElementsMapFromArray(payload);
    },
    [clearTickmarkElementsAction]: (tickmarkList) => {
      return tickmarkList.clearElementList();
    },
    [removeTickmarkFromElementsMapAction]: (tickmarkList, { payload }) => {
      return tickmarkList.removeTickmarkFromElementsMap(payload);
    },
    [removeElementFromTickmarkElementsMapWithSocketPayloadAction]: (
      tickmarkList,
      { payload },
    ) => {
      return tickmarkList.removeElementFromTickmarkElementsMapWithSocketPayload(
        payload,
      );
    },
    [excludeElementFromElementsMapAction]: (tickmarkList, { payload }) => {
      return tickmarkList.removeElementFromElementsMap(payload);
    },
    [addTickmarkToDeletedArrayAction]: (tickmarkList, { payload }) => {
      return tickmarkList.addTickmarkToDeletedArray(payload);
    },

    [deleteTickmarkWithSocketPayloadAction]: (tickmarkList, { payload }) => {
      return tickmarkList.deleteTickmarkWithSocketPayload(payload);
    },

    [updateTickmarkWithSocketPayloadAction]: (tickmarkList, { payload }) => {
      return tickmarkList.updateTickmarkWithSocketPayload(payload);
    },
    [addTickmarkToListAction]: (tickmarkList, { payload }) => {
      return tickmarkList.addTickmarkToList(payload);
    },
    [unlinkTickmarkFromElementWithWebsocket]: (tickmarkList, { payload }) => {
      return tickmarkList.unlinkTickmarkFromElementWithWebsocket(payload);
    },
  },
  new TickmarkListModel(),
);
