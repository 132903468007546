const SPECIAL_COLUMNS = {
  kebab: 'special-kebab-column',
  checkbox: 'special-checkbox-column',
  icon: 'special-icon-column',
};
const CONSTANTS = {
  SPECIAL_COLUMNS,
  SPECIAL_COLUMNS_LIST: Object.values(SPECIAL_COLUMNS),
  DATA_GRID_ROW_HEIGHT: 3, // in rems
  ROWS_AMOUNT: 20,
};
export default CONSTANTS;
