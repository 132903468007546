import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { getEmailsForTaggedUsers } from 'utils/note-email-util';
import { getStore } from 'store/store';

import { toApiNoteFormat } from 'utils/note-filter-util';
import DOMPurify from 'dompurify';
export default class NotesForm extends ApiModel({
  data: {
    noteType: 'I',
    body: { note: '', replies: {}, taggedUsers: {} },
    notePriorityType: 'NORMAL',
  },
}) {
  get noteType() {
    if (this.data) {
      return this.data.noteType;
    }
    return null;
  }
  get body() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.body;
    }
    return '';
  }

  get notePriorityType() {
    if (this.data) {
      return this.data.notePriorityType;
    }
    return null;
  }

  setBody({ text }) {
    return this.merge({
      data: {
        ...this.data,
        body: { ...this.data.body, note: text },
      },
    });
  }

  toSendEmailFormat() {
    const { projectUsersList, revision } = getStore().getState().data;
    let note = this.removeXMLTags(this.data.body.note);
    note = toApiNoteFormat(note);
    const emailList = getEmailsForTaggedUsers({
      noteBody: note,
      replies: {},
      projectUsersList: projectUsersList.users,
      previousNoteBody: '',
    });

    return {
      revisionId: revision.id,
      noteContent: note,
      sendEmailTo: emailList,
    };
  }

  removeXMLTags(note) {
    let cleanHTML = DOMPurify.sanitize(note, {
      ALLOWED_TAGS: ['span'],
      ALLOWED_ATTR: ['cfto_user_id'],
    });

    return cleanHTML;
  }

  toApiFormat() {
    let noteBody = { ...this.data.body };
    let note = this.removeXMLTags(noteBody.note);
    noteBody.note = toApiNoteFormat(note);

    const regex = /cfto_user_id="\s*(.*?)\s*"/g;
    let match;
    let usersObject = {};

    do {
      match = regex.exec(noteBody.note);
      if (match) {
        usersObject[match[1]] = true;
      }
    } while (match);

    noteBody.taggedUsers = usersObject;

    return {
      ...this.data,
      body: JSON.stringify(noteBody),
    };
  }

  setNoteType({ type }) {
    return this.merge({
      data: { ...this.data, noteType: type },
    });
  }

  isTextPresent() {
    return (
      !isNullOrUndefined(this.data.body) &&
      this.data.body.note &&
      this.data.body.note.length > 0
    );
  }
}
