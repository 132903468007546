//import { getAPI } from 'api/api-client';
import moment from 'moment-timezone';
import { getStatementIdUtil } from 'utils/statement-export-utils';
import {
    withDefaultContextAsSelectedProject,
    withDefaultContextAsProject,
} from './api-default-context';
import { serializedProjectIdWithGeo } from 'utils/project-utils';

export const getStatementListRequest = ({
    projectId,
    status,
    sort,
    order,
    first,
    size,
    isAuditClient,
    contextKey,
    selectedProject,
}) => {
    return withDefaultContextAsProject({ contextKey }, projectId)
        .get(`/tieout/clients/${parseInt(projectId)}/statements`, {
            params: {
                status,
                sort,
                order,
                first,
                size,
                isAuditClient,
            },
        })
        .then((response) => {
            response.data &&
                response.data.result &&
                Array.isArray(response.data.result.elements) &&
                response.data.result.elements.forEach((item) => {
                    item.clientId = serializedProjectIdWithGeo(
                        item.clientId,
                        selectedProject.data.geoCode,
                    );
                });
            return response;
        });
};

// TODO props may need to change when we add pagination and sorting integration
export const getStatementListForProjectRequest = ({
    projectId,
    sort,
    order,
    selectedProject,
}) => {
    return getStatementListRequest({
        status: 'active, pending_approval, archived, approval_initiated',
        projectId,
        sort,
        order,
        selectedProject,
    });
};

// TODO props may need to change when we add pagination and sorting integration
export const getArchivedStatementListForProjectRequest = ({
        projectId,
        sort,
        order,
        selectedProject,
    }) =>
    getStatementListRequest({
        status: 'archived',
        projectId,
        sort,
        order,
        selectedProject,
    });

// TODO props may need to change when we add pagination and sorting integration
export const getDeletedStatementListForProjectRequest = ({
        projectId,
        sort,
        order,
        selectedProject,
    }) =>
    getStatementListRequest({
        status: 'deleted',
        projectId,
        sort,
        order,
        selectedProject,
    });

export const sofDeleteCurrentRevisionRequest = ({ revisionId, statementId }) =>
    withDefaultContextAsSelectedProject()
    .post(`/tieout/revisions/${revisionId}/statement/${statementId}`, null, {
        params: { deleteAllRevisions: false },
    })
    .then((response) => {
        return response;
    });

export const softDeleteAllRevisionsRequest = (statementId) =>
    withDefaultContextAsSelectedProject()
    //this -1 in the route needs to get changed in a backend update.
    .post(`/tieout/revisions/-1/statement/${statementId}`, null, {
        params: { deleteAllRevisions: true },
    })
    .then((response) => {
        return response;
    });

export const permanentlyDeleteRevisionRequest = (revisionId, statementId) =>
    withDefaultContextAsSelectedProject()
    .post(`/tieout/statements/${statementId}/revisions/${revisionId}/delete`)
    .then((response) => {
        return response;
    });

export const permanentlyDeleteFailedRevisionRequest = (
        statementId,
        revisionId,
    ) =>
    withDefaultContextAsSelectedProject()
    .delete(
        `/tieout/statements/${statementId}/revisions/${revisionId}/deletefailed`,
    )
    .then((response) => {
        return response;
    });

export const permanentlyDeleteAllSoftDeletedRevisionsForStatementRequest = (
        statementId,
    ) =>
    withDefaultContextAsSelectedProject()
    .delete(`/tieout/statements/${statementId}`)
    .then((response) => {
        return response;
    });

export const getStatementWorkflowsRequest = (projectId) => {
    return withDefaultContextAsProject({}, projectId)
        .get(`/tieout/documentupload/${parseInt(projectId)}/workflows`)
        .then((response) => {
            return response;
        });
};

export const retryFailedDocumentUploadRequest = (
        statementId,
        revisionId,
        clientId,
    ) =>
    withDefaultContextAsSelectedProject()
    .post(
        `/tieout/documentupload/client/${parseInt(
        clientId,
      )}/statements/${statementId}/revisions/${revisionId}/retry`,
    )
    .then((response) => {
        return response;
    });

export const restoreDeletedRevisionRequest = (revisionId, statementId) =>
    withDefaultContextAsSelectedProject()
    .post(`/tieout/statements/${statementId}/revisions/${revisionId}/restore`)
    .then((response) => {
        return response;
    });

export const getStatementSubmitForWrapUpApprovalStatsRequest = ({
        statementId,
    }) =>
    withDefaultContextAsSelectedProject().get(
        `/tieout/statements/${statementId}/submissionsynopsis`,
    );

export const getStatementApprovalStatsRequest = ({ statementId }) =>
    withDefaultContextAsSelectedProject().get(
        `/tieout/statements/${statementId}/approvalsynopsis`,
    );

export const submitStatementForWrapUpApprovalRequest = ({ statementId }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${statementId}/submit`,
    );

export const rejectStatementWrapUpRequest = ({ statementId }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${statementId}/decline`,
    );

export const approveStatementWrapUpRequest = ({ statementId, signedDate }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${statementId}/approve`,
        null, {
            params: {
                signedDate,
            },
        },
    );

export const exportWorkpaperStatementRequest = ({
        revisionId,
        pageSize,
        pageOrientation,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export`, {
            pageSize,
            pageOrientation,
            reportType: 'Workpaper',
            reportFormat: 'PDF',
            timeZone: moment.tz.guess(),
        },
    );

export const exportElementReportRequest = ({ revisionId }) =>
    withDefaultContextAsSelectedProject().get(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/report`,
    );

export const exportAuditTrailRequest = ({
        revisionId,
        pageSize,
        pageOrientation,
        reportType,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export`, {
            pageSize,
            pageOrientation,
            reportType,
            timeZone: moment.tz.guess(),
        },
    );

export const exportComfortLetterReportRequest = ({
        revisionId,
        pageSize,
        pageOrientation,
        reportType,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export`, {
            pageSize,
            pageOrientation,
            reportType,
            timeZone: moment.tz.guess(),
        },
    );

export const exportClientNotesRequest = ({
        revisionId,
        pageSize,
        pageOrientation,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export`, {
            pageSize,
            pageOrientation,
            reportType: 'ClientNotes',
            timeZone: moment.tz.guess(),
        },
    );

export const exportCustomReportRequest = ({
    revisionId,
    pageSize,
    pageOrientation,
    exportCustomParam,
}) => {
    return withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export`, {
            ...exportCustomParam,
            pageSize,
            pageOrientation,
            reportType: 'CustomReport',
            timeZone: moment.tz.guess(),
        },
    );
};

/**Api's to download report directly into local.
 * This shall be used when blob storage service is not actively running
 */

export const exportWorkpaperStatementRequestFallback = ({
        revisionId,
        pageSize,
        pageOrientation,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/fallback`, {
            pageSize,
            pageOrientation,
            reportType: 'Workpaper',
            reportFormat: 'PDF',
            timeZone: moment.tz.guess(),
        }, {
            responseType: 'blob',
        },
    );

export const exportElementReportRequestFallback = ({ revisionId }) =>
    withDefaultContextAsSelectedProject().get(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/report/fallback`, {
            responseType: 'blob',
        },
    );

export const exportAuditTrailRequestFallback = ({
        revisionId,
        pageSize,
        pageOrientation,
        reportType,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/fallback`, {
            pageSize,
            pageOrientation,
            reportType,
            timeZone: moment.tz.guess(),
        }, {
            responseType: 'blob',
        },
    );

export const exportComfortLetterReportRequestFallback = ({
        revisionId,
        pageSize,
        pageOrientation,
        reportType,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/fallback`, {
            pageSize,
            pageOrientation,
            reportType,
            timeZone: moment.tz.guess(),
        }, {
            responseType: 'blob',
        },
    );

export const exportClientNotesRequestFallback = ({
        revisionId,
        pageSize,
        pageOrientation,
    }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/fallback`, {
            pageSize,
            pageOrientation,
            reportType: 'ClientNotes',
            timeZone: moment.tz.guess(),
        }, {
            responseType: 'blob',
        },
    );

export const exportCustomReportRequestFallback = ({
    revisionId,
    pageSize,
    pageOrientation,
    exportCustomParam,
}) => {
    return withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/fallback`, {
            ...exportCustomParam,
            pageSize,
            pageOrientation,
            reportType: 'CustomReport',
            timeZone: moment.tz.guess(),
        }, {
            responseType: 'blob',
        },
    );
};

/** END Of api call list for report download directly in local */

export const copyStatementRequest = ({ statementId, targetClientId }) =>
    withDefaultContextAsSelectedProject().post(
        `/tieout/copy/statement/${statementId}/to/client/${parseInt(
      targetClientId,
    )}`,
    );

export const downloadReportFromGuid = ({
    revisionId,
    fileName,
    storageGuid,
    userDownloadedReportStatus,
}) => {
    return withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/download`, {
            fileName,
            storageGuid,
            downloadedStatus: userDownloadedReportStatus,
        }, {
            responseType: 'blob',
        },
    );
};

export const downloadElementReportFromGuid = ({
    revisionId,
    fileName,
    storageGuid,
    userDownloadedReportStatus,
    reportId,
}) => {
    return withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/download`, {
            fileName,
            storageGuid,
            downloadedStatus: userDownloadedReportStatus,
            reportId,
        }, {
            responseType: 'blob',
        },
    );
};

export const fetchToolkitReportList = ({ revisionId }) => {
    return withDefaultContextAsSelectedProject().get(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/exportList`,
    );
};

export const cancelReportIdProcessingRequest = ({
    revisionId,
    reportId,
    reportName,
}) => {
    return withDefaultContextAsSelectedProject().post(
        `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/export/cancel`, {
            reportId,
            reportName,
        },
    );
};