import { cloneDeep } from 'lodash';
import ApiModel from 'models/api-model';
import User from 'models/data/user-model';
import { isNullOrUndefined } from 'utils/object-utils';
export class CurrentUser extends ApiModel({
  data: new User(),
  status: null,
}) {
  get id() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.id;
    }
    return null;
  }

  processResponse({ response }) {
    // Current user model is always going to keep track of users home geo only.
    // We set home geo after getting users OPM profile.
    // The below changes will protect overwriting users home geo, since current user api response
    // is expected to contain geo code of region in which api call was made.
    const updatedResponse = cloneDeep(response);
    updatedResponse &&
      updatedResponse.result &&
      (updatedResponse.result.geoCode = this.homeGeo);
    return {
      data: new User(updatedResponse.result),
      status: updatedResponse.status,
    };
  }

  isInitialized() {
    return !isNullOrUndefined(this.data) || !isNullOrUndefined(this.data.id);
  }

  getUser() {
    return this.data;
  }

  hasID() {
    return !isNullOrUndefined(this.id);
  }

  errorNotNull() {
    return !this.isLoading && this.error !== null;
  }

  isDisabled() {
    return this.isInitialized() && !this.getUser().enabled;
  }

  get homeGeo() {
    if (this.data) return this.data.geoCode;
    return undefined;
  }

  setHomeGeo(geo) {
    return this.merge({
      data: {
        ...this.data,
        geoCode: geo,
      },
    });
  }
}
