import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';

import Banner, { BANNER_TYPES } from 'components/common/banner-component';

import { ModalButton } from 'models/utils/common/modal-button-model';

const STATEMENT_EDIT_PAGE_ID_BLOCK = 'statement-edit-id';

const StatementEditCancelModal = ({
  toggleCancelModal,
  onCancelAndLeavePage,
}) => {
  return (
    <Modal
      id={`${STATEMENT_EDIT_PAGE_ID_BLOCK}-cancel-modal`}
      title={'statement-edit.statement-edit-cancel-modal.title'}
      onClose={toggleCancelModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: onCancelAndLeavePage,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: toggleCancelModal,
        })
      }
    >
      <Banner
        id={`${STATEMENT_EDIT_PAGE_ID_BLOCK}-cancel-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'statement-edit.statement-edit-cancel-modal.banner'}
        isInternationalized={true}
        isInteractive={false}
      />
    </Modal>
  );
};

StatementEditCancelModal.propTypes = {
  /** Method on the statement edit component that closes statement edit cancel modal */
  toggleCancelModal: PropTypes.func.isRequired,
  /** Method on the statement edit component that let user leave statement edit page */
  onCancelAndLeavePage: PropTypes.func.isRequired,
};

export default StatementEditCancelModal;
