import Project from 'permissions/structures/project';
import Statement from 'permissions/structures/statement';
import Element from 'permissions/structures/element';
import Tickmark from 'permissions/structures/tickmark';
import WorkpaperReference from 'permissions/structures/workpaper-reference';
import InternalReference from 'permissions/structures/internal-reference';
import Formula from 'permissions/structures/formula';
import Omnia from 'permissions/structures/omnia';
import Note from 'permissions/structures/note';
import Section from 'permissions/structures/section';
import AuditChecklistPermissions from 'permissions/structures/auditchecklist';
import SideBySideStatementViewPermissions from 'permissions/structures/side-by-side';
/* Permissions is an important global wrapper object used to govern permissions in the Tie Out Front-end.
To use it, one would simply access it in a js/jsx file like so:

import Permissions from 'permissions/permissions';

....

Permissions.Structure.permissionFunction(params...)

For a concrete example, to see if a user could delete a project structure, simply call it like so:

Permissions.Project.canDelete(id)*/

const Permissions = {
  Project,
  Statement,
  Element,
  WorkpaperReference,
  Tickmark,
  Formula,
  InternalReference,
  Omnia,
  Note,
  Section,
  AuditChecklistPermissions,
  SideBySideStatementViewPermissions,
};
export default Permissions;
