import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';

export class SectionTreeListEntry extends BaseModel({
  children: [],
  id: 0,
  is_technical: null,
  name: null,
  orderNum: null,
  parentSectionName: null,
  revisionId: null,
  type: null,
  isNew: false,
}) {
  constructor(props) {
    super(props);
    this.children = this._initChildren({ children: props.children });
  }

  _initChildren({ children }) {
    if (isNullOrUndefined(children)) {
      return null;
    }

    return children.map(
      (childSection) => new SectionTreeListEntry(childSection),
    );
  }
}
