import { getAPI } from 'api/api-client';
import {
  withDefaultContextAsCurrentGeo,
  withDefaultContextAsHomeGeo,
} from './api-default-context';

export const getCurrentUserRequest = () =>
  Promise.all(
    Object.keys(window.TIEOUT.ENV.GEOS).map((geoKey) =>
      getAPI(geoKey)
        .get('/tieout/currentuser', {
          params: {
            populateUserRoles: true,
          },
        })
        .then((response) => {
          if (response.data && response.data.result)
            response.data.result.geoCode = geoKey;
          return response;
        })
        .catch(() => ({ data: undefined })),
    ),
  ).then((responses) => {
    //following check will discard any response that is not having user data in it
    const responsesWithUserDetails = responses.filter(
      (res) => res && res.data && res.data.result && res.data.result.email,
    );
    return responsesWithUserDetails;
  });

export const postCurrentUserPreferencesRequest = (
  currentUserID,
  currentZoneId,
) =>
  withDefaultContextAsHomeGeo()
    .post('/tieout/user/preferences', {
      id: currentUserID,
      zoneId: currentZoneId,
    })
    .then((response) => {
      return response;
    });

export const getUserOPMProfile = () =>
  withDefaultContextAsCurrentGeo()
    .get('/tieout/getUserProfile')
    .then((response) => ({
      response: response.data,
    }))
    .catch((e) => {
      console.log('failed to get users OPM profile. Error details:');
      console.log(e);
      console.trace();
      return e;
    });

export const userAuthentication = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/auth')
    .then((response) => ({
      response: response.data,
    }));
