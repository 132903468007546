import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
const BATCH_PANEL_CONFIRM_MODAL_ID = 'batch-panel-confirm-modal-id';
const ExcludeElementsConfirmModal = ({
  onClickSave,
  onClickCancel,
  batchCount,
}) => {
  return (
    <Modal
      id={BATCH_PANEL_CONFIRM_MODAL_ID}
      title={'batch-panel.confirm-modal.title'}
      onClose={onClickCancel}
      primaryModalButton={
        new ModalButton({
          text: 'batch-panel.confirm-modal.save-button-title',
          onClick: onClickSave,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClickCancel,
        })
      }
    >
      <Banner
        id={`${BATCH_PANEL_CONFIRM_MODAL_ID}-confirm-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'batch-panel.confirm-modal.warning'}
        isInternationalized={true}
        isInteractive={false}
      />
      <FormattedMessage
        id={'batch-panel.confirm-modal.body'}
        values={{ count: batchCount }}
      />
    </Modal>
  );
};

ExcludeElementsConfirmModal.propTypes = {
  /** Action fired when user clicks exclude & save button */
  onClickSave: PropTypes.func.isRequired,
  /** Action fired when user clicks No || close button */
  onClickCancel: PropTypes.func.isRequired,
  /** elements count selected from content panel for batch update */
  batchCount: PropTypes.number.isRequired,
};

export default ExcludeElementsConfirmModal;
