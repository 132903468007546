/* eslint-disable no-extend-native */
/**
 * Polyfill for development that allows the ES6 Map object to be viewable in Redux dev tools
 * taken from https://github.com/zalmoxisus/redux-devtools-extension/issues/124#issuecomment-221972997
 */
if (process.env.NODE_ENV === 'development') {
  Map.prototype.toJSON = function () {
    let obj = {};
    this.forEach((value, key) => (obj[key] = value));
    return obj;
  };
}
