import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as PlusCircleIcon } from 'icons/plus-circle.svg';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

const DEFAULT_ICON_SIZE = 20;

const ICON_BUTTON_BLOCK = 'icon-button';
//This container div it's neccesary to avoid malfunction of the tooltip if the button is disabled
const ICON_BUTTON_CONTAINER = 'icon-button-container';

const IconButton = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      id,
      onClick,
      Icon = PlusCircleIcon,
      iconSize = DEFAULT_ICON_SIZE,
      tooltip,
      position,
      tooltipOnHideFunction,
    },
    ref,
  ) => (
    <Tooltip
      id={id}
      {...tooltip}
      active={tooltip ? true : false}
      position={position}
      event={tooltip ? tooltip.event : null}
      tooltipOnHideFunction={tooltipOnHideFunction}
    >
      <div className={ICON_BUTTON_CONTAINER}>
        <button
          ref={ref}
          disabled={disabled}
          id={id}
          onClick={onClick}
          className={classNames(ICON_BUTTON_BLOCK, className)}
        >
          <Icon
            className={`${ICON_BUTTON_BLOCK}__icon`}
            width={iconSize}
            height={iconSize}
          />
          {children}
        </button>
      </div>
    </Tooltip>
  ),
);

IconButton.propTypes = {
  /** custom string class */
  className: PropTypes.string,
  /** A boolean that determine if button should be disabled */
  disabled: PropTypes.bool,
  /** unigue string id */
  id: PropTypes.string.isRequired,
  /** Function for determing the behavior of button when clicked */
  onClick: PropTypes.func.isRequired,
  /** tooltip for button */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /**The Icon to be rendered in the IconButton,
   * it is fully customizable, but does require that its svg path propertyhave the class `.icon-path`
   * for proper-styling. By default uses the PlusCircleIcon if none is provided.  */
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  /** tooltip position */
  position: PropTypes.string,
  /* function executedn when tooltip hides */
  tooltipOnHideFunction: PropTypes.func,
};

export default IconButton;
