import React from 'react';
import moment from 'moment';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import {
  DataGridColumn,
  DataGridDropdownColumn,
  DataGridIconColumn,
} from 'components/common/data-grid/data-grid-component';
import MultilineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import {
  USER_ROLE_OPTIONS_MAP,
  JOB_TYPES,
} from 'constants/common/job-type-constants';
import { ReactComponent as XIcon } from 'icons/cross.svg';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isNullOrUndefined } from 'utils/object-utils';

export const LEGAL_HOLD_TOOLTIP = new TooltipOptions({
  text: 'project-creation.project-info.legal-hold-verbiage',
  id: 'project-creation-id-tooltip-legal-hold',
  position: 'right',
});

export const getMatDateFormat = (year) => {
  const currMonth = new Date().getMonth();
  const newDateFormat = new Date().setFullYear(year, currMonth + 1, 0);
  return newDateFormat;
};

export const getMatEngagementYears = () => {
  const currYear = new Date().getFullYear();
  let year,
    index = 0;
  let years = [];
  for (year = currYear - 5; year < currYear; year++) {
    years.push(
      new MenuOption({
        id: index,
        title: year,
        value: getMatDateFormat(year),
        isIntl: false,
      }),
    );
    index++;
  }
  for (year = currYear; year < currYear + 6; year++) {
    years.push(
      new MenuOption({
        id: index,
        title: year,
        value: getMatDateFormat(year),
        isIntl: false,
      }),
    );
    index++;
  }
  return years;
};

// Backend ENUM ids
export const NON_AUDIT_REASON = {
  DEMO: 1,
  PURSUIT: 2,
  TESTING: 3,
  SUPPORT: 4,
  ASSURANCE: 5,
  OTHER: 6,
};

export const NON_AUDIT_REASON_MAP = new Map();
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.DEMO,
  new MenuOption({
    id: NON_AUDIT_REASON.DEMO,
    title: 'project-creation.non-audit-reason.option.demo',
    value: NON_AUDIT_REASON.DEMO,
  }),
);
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.PURSUIT,
  new MenuOption({
    id: NON_AUDIT_REASON.PURSUIT,
    title: 'project-creation.non-audit-reason.option.pursuit',
    value: NON_AUDIT_REASON.PURSUIT,
  }),
);
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.TESTING,
  new MenuOption({
    id: NON_AUDIT_REASON.TESTING,
    title: 'project-creation.non-audit-reason.option.testing',
    value: NON_AUDIT_REASON.TESTING,
  }),
);
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.SUPPORT,
  new MenuOption({
    id: NON_AUDIT_REASON.SUPPORT,
    title: 'project-creation.non-audit-reason.option.support',
    value: NON_AUDIT_REASON.SUPPORT,
  }),
);
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.ASSURANCE,
  new MenuOption({
    id: NON_AUDIT_REASON.ASSURANCE,
    title: 'project-creation.non-audit-reason.option.assurance',
    value: NON_AUDIT_REASON.ASSURANCE,
  }),
);
NON_AUDIT_REASON_MAP.set(
  NON_AUDIT_REASON.OTHER,
  new MenuOption({
    id: NON_AUDIT_REASON.OTHER,
    title: 'project-creation.non-audit-reason.option.other',
    value: NON_AUDIT_REASON.OTHER,
  }),
);

export const NON_AUDIT_REASON_OPTIONS = Array.from(
  NON_AUDIT_REASON_MAP.values(),
);

export const USER_SEARCH_COLUMNS = [
  new DataGridColumn({
    key: 'preferredFullName',
    header: 'project-creation.manage-users.user-heading.name',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-fullName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.preferredFullName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'email',
    header: 'project-creation.manage-users.user-heading.email',
    width: '30%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-email-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.email}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'title',
    header: 'project-creation.manage-users.user-heading.title',
    width: '15%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-title-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.title}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'region',
    header: 'project-creation.manage-users.user-heading.region',
    width: '15%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-region-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.region}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'officeDescription',
    header: 'project-creation.manage-users.user-heading.office',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-office-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.officeDescription}
          isNotInternationalized
        />
      );
    },
  }),
];

export const ENGAGEMENT_SEARCH_COLUMNS = [
  new DataGridColumn({
    key: 'engagementId',
    header: 'project-creation.project-info.engagement-heading.engagement-id',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `engagementId-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.engagementId}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'clientName',
    header: 'project-creation.project-info.engagement-heading.client-name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `clientName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.clientName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'engagementName',
    header: 'project-creation.project-info.engagement-heading.engagement-name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `engagementName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.engagementName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'engagementFye',
    header: 'project-creation.project-info.engagement-heading.fiscal-year',
    width: '25%',
    formatter: (rowData) => {
      return moment(rowData.engagementFye).format(DATE_FORMATS.YEAR);
    },
  }),
];

export const getAddedUserColumns = ({ onRemoveClick }) => [
  new DataGridColumn({
    key: 'preferredFullName',
    header: 'project-creation.manage-users.user-heading.name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `manage-users-fullName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.preferredFullName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'email',
    header: 'project-creation.manage-users.user-heading.email',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `manage-users-email-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.email}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'title',
    header: 'project-creation.manage-users.user-heading.title',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `manage-users-title-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.title}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'region',
    header: 'project-creation.manage-users.user-heading.region',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `manage-users-region-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.region}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'officeDescription',
    header: 'project-creation.manage-users.user-heading.office',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `manage-users-office-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.officeDescription}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridDropdownColumn({
    key: 'jobType',
    header: 'project-creation.manage-users.user-heading.role',
    width: '20%',
    placeholder: 'project-creation.manage-users.role-dropdown.placeholder',
    isValid: (user) => {
      return user.jobType !== '' && !isNullOrUndefined(user.jobType);
    },
    options: (user) => {
      const commonRoleOptions = [
        USER_ROLE_OPTIONS_MAP[JOB_TYPES.PREPARER_REVIEWER],
        USER_ROLE_OPTIONS_MAP[JOB_TYPES.OBSERVER],
        USER_ROLE_OPTIONS_MAP[JOB_TYPES.ENGAGEMENT_OWNER],
      ];

      if (user.isPpmd) {
        return [...commonRoleOptions, USER_ROLE_OPTIONS_MAP[JOB_TYPES.PPMD]];
      }
      return commonRoleOptions;
    },
  }),
  new DataGridIconColumn({
    Icon: XIcon,
    iconHeight: '28px',
    iconWidth: '28px',
    onClick: onRemoveClick,
  }),
];
