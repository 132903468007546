import { handleActions } from 'redux-actions';
import {
  statementSummaryElementsLoading,
  statementSummaryElementsLoaded,
  statementSummaryElementsError,
  updateElementSummaryFromSocketPayloadAction,
} from 'store/actions/statement-summary/elements-summary-actions';
import StatementSummaryElements from 'models/api/statement-summary-elements-model';

export default handleActions(
  {
    [statementSummaryElementsLoading]: (elementSummary) => {
      return elementSummary.setLoading();
    },
    [statementSummaryElementsError]: (elementSummary, { payload }) => {
      return elementSummary.setError(payload);
    },
    [statementSummaryElementsLoaded]: (elementSummary, { payload }) => {
      return elementSummary.setLoaded(payload);
    },
    [updateElementSummaryFromSocketPayloadAction]: (
      elementSummary,
      { payload },
    ) => {
      return elementSummary.updateElementsSummaryFromSocketPayload(payload);
    },
  },
  new StatementSummaryElements(),
);
