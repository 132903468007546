export const DATE_FORMATS = {
  YEAR: 'YYYY',
  MONTH_DAY_YEAR: 'MM/DD/YYYY',
  MONTH_DAY_YEAR_TIME: 'MM/DD/YYYY h:mm a',
  HOURS_MINUTES: 'h:mm a',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  YEAR_MONTH_DAY_TIME: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
  DAY_MONTH_YEAR: 'DD-MM-YYYY',
  DAY_MONTH_YEAR_TIME: 'DD/MM/YYYY HH:mm',
};

export const button = 'calendarBtn';
export const clrButton = 'clearBtn';
export const closeButton = 'closeBtn';
export const dateInput = 'date-input';
export const arrowIcons = 'arrowIcons';
export const DatePicker = 'DatePicker';
export const calendar = 'calendar';
export const directionIcon = 'dateInputFangShape';
export const ARROW_ICON_SIZE = '1.5rem';
export const CALENDAR_ICON_SIZE = '15px';
export const CLEAR_ICON_SIZE = '20px';
export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const DateFormatRegex = (exp) => {
  switch (exp) {
    case DATE_FORMATS.MONTH_DAY_YEAR:
      return /^\d{2}\/\d{2}\/\d{4}$/;
    case DATE_FORMATS.YEAR_MONTH_DAY:
      return /^\d{4}-\d{2}-\d{2}$/;
    case DATE_FORMATS.DAY_MONTH_YEAR:
      return /^\d{2}\/\d{2}\/\d{4}$/;
    default:
      return /^\d{2}\/\d{2}\/\d{4}$/; //DATE_FORMATS.MONTH_DAY_YEAR
  }
};

export const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
