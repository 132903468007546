import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import RichTextEditor from 'components/common/rich-text-editor-component';
import Switch from 'react-switch';
import { ReactComponent as ConfirmButton } from 'icons/close-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { DateTimePicker } from 'components/common/date-time-picker-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import moment from 'moment';
import { isNullOrUndefined } from 'utils/object-utils';
import AdminModuleMaintenance from 'models/data/admin-module-maintenance-page-model';
import classNames from 'classnames';
import { getText } from 'constants/util/admin-module-utility';

export const ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK = 'maintenance-page';
const ADMIN_MODULE_MAINTENANCE_PAGE_ID = 'maintenance-page-id';
const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;

export const MaintenanceBannerAdmin = ({
  bannerData,
  maintenanceBannerSaveAction,
}) => {
  const MAINTENANCE_BANNER_LENGTH = 2000;

  const [startDate, setStartDate] = useState(bannerData.apiStartDate);
  const [endDate, setEndDate] = useState(bannerData.apEndDate);
  const [validStartDate, setValidStartDate] = useState(!!bannerData.apiStartDate);
  const [validEndDate, setValidEndDate] = useState(!!bannerData.apEndDate);
  const [startTime, setStartTime] = useState(bannerData.apiStartTime);
  const [endTime, setEndTime] = useState(bannerData.apiEndTime);
  const [bannerText, setBannerText] = useState(bannerData.apiBannerText);
  const [switchToggle, setSwitchToggle] = useState(bannerData.isActive);
  const [showEditButton, setShowEditButton] = useState(
    !isNullOrUndefined(bannerData.apiMaintenanceId),
  );

  const _saveData = async () => {
    await maintenanceBannerSaveAction(
      switchToggle,
      bannerData.apiMaintenanceId,
      bannerText,
      moment
        .utc(
          moment(
            startDate.format(DATE_FORMATS.DAY_MONTH_YEAR) + ' ' + startTime,
            DATE_FORMATS.DAY_MONTH_YEAR_TIME,
          ).valueOf(),
        )
        .format('YYYY-MM-DD HH:mm:ss'),
      moment
        .utc(
          moment(
            endDate.format(DATE_FORMATS.DAY_MONTH_YEAR) + ' ' + endTime,
            DATE_FORMATS.DAY_MONTH_YEAR_TIME,
          ).valueOf(),
        )
        .format('YYYY-MM-DD HH:mm:ss'),
    );
    setShowEditButton(true);
  };

  const isSaveDisabled = () => {
    if (
      !(
        validStartDate &&
        validEndDate &&
        startTime &&
        endTime &&
        bannerText &&
        getText(bannerText)
      ) ||
      isBannerTextTooLong() ||
      !isValidEndDateTime() ||
      !isDateTimeNotPredated(startDate, startTime) ||
      !isDateTimeNotPredated(endDate, endTime)
    )
      return true;
    else return false;
  };

  const _switchToggle = () => {
    setSwitchToggle(!switchToggle);
  };
  const isValidEndDateTime = () => {
    if (startDate && startTime && endDate && endTime) {
      let startDateTime = moment(
        startDate.format(DATE_FORMATS.DAY_MONTH_YEAR) + ' ' + startTime,
        DATE_FORMATS.DAY_MONTH_YEAR_TIME,
      ).unix();
      let endDateTime = moment(
        endDate.format(DATE_FORMATS.DAY_MONTH_YEAR) + ' ' + endTime,
        DATE_FORMATS.DAY_MONTH_YEAR_TIME,
      ).unix();
      return moment(endDateTime).isAfter(startDateTime);
    } else return true;
  };

  const isDateTimeNotPredated = (date, time) => {
    if (date && time) {
      let dateTime = moment(
        date.format(DATE_FORMATS.DAY_MONTH_YEAR) + ' ' + time,
        DATE_FORMATS.DAY_MONTH_YEAR_TIME,
      ).unix();
      return moment(dateTime).isAfter(moment().unix());
    } else return true;
  };

  const endTimeErrorTxt = () => {
    if (!isDateTimeNotPredated(endDate, endTime)) {
      return 'admin-module-page-maintenance-banner-start-date-error-text';
    } else if (!isValidEndDateTime()) {
      return 'admin-module-page-maintenance-banner-end-date-error-text';
    }
  };

  const isBannerTextTooLong = () => {
    return bannerText && getText(bannerText).length > MAINTENANCE_BANNER_LENGTH;
  };

  return (
    <div
      className={ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}
      id={`${ADMIN_MODULE_MAINTENANCE_PAGE_ID}-${
        isNullOrUndefined(bannerData.maintenanceId)
          ? ''
          : bannerData.maintenanceId
      }`}
    >
      <div className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}-switch`}>
        <span
          className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}-switch-banner`}
        >
          <FormattedMessage id="admin-module-page-button-toggle-maintenance-banner" />
        </span>

        <Switch
          id={'Banner'}
          onChange={_switchToggle}
          checked={switchToggle}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={45}
          disabled={showEditButton}
        />
      </div>
      <div
        className={classNames(
          !switchToggle || showEditButton
            ? `${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__maintenance-rich-text--disabled`
            : `${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__maintenance-rich-text`,
        )}
      >
        <RichTextEditor
          className={classNames(
            !switchToggle || showEditButton
              ? `${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__maintenance-text--disabled`
              : `${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__maintenance-text`,
          )}
          errorText={{
            id: 'admin.maintenance-banner.text.error.char-count',
            values: { limit: MAINTENANCE_BANNER_LENGTH },
          }}
          id={'maintenancebanner-text'}
          placeholder={{
            id: 'admin.maintenance-banner.text.placeholder',
            values: { limit: MAINTENANCE_BANNER_LENGTH },
          }}
          width={'100%'}
          onChange={(val) => {
            setBannerText(val);
          }}
          initContents={isNullOrUndefined(bannerText) ? '' : bannerText}
          disabled={!switchToggle || showEditButton}
          isValid={!isBannerTextTooLong()}
        />
      </div>
      <DateTimePicker
        id={`${ADMIN_MODULE_MAINTENANCE_PAGE_ID}__start-date`}
        label={'admin.banner.start-date'}
        errorText={{
          id: 'admin-module-page-maintenance-banner-start-date-error-text',
        }}
        onDateChange={(val) => {
          if(val === null) {
            //if user clicks on cross icon to clear date
            setStartDate(val);
            setValidStartDate(false);
          } else if(val instanceof moment) {
            setStartDate(val);
            setValidStartDate(true);
          } else if(DATE_REGEX.test(val)) {
            //if user manually types in complete date and date format is correct
            setStartDate(moment.utc(val));
            setValidStartDate(true);
          } else {
            //when user manually types in date field and date format is incorrect
            setValidStartDate(false);
          }
        }}
        selectedDate={startDate}
        className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__start-date`}
        openClockOnFocus={false}
        onTimeChange={(val) => {
          setStartTime(val);
        }}
        isValid={
          isDateTimeNotPredated(startDate, startTime) ||
          showEditButton ||
          !switchToggle
        }
        selectedTime={startTime}
        width={'30%'}
        disabled={!switchToggle || showEditButton}
      />

      <DateTimePicker
        id={`${ADMIN_MODULE_MAINTENANCE_PAGE_ID}__end-date`}
        label={'admin.banner.end-date'}
        onDateChange={(val) => {
          if(val === null) {
            setEndDate(val);
            setValidEndDate(false);
          } else if(val instanceof moment) {
            setEndDate(val);
            setValidEndDate(true);
          } else if(DATE_REGEX.test(val)) {
            setEndDate(moment.utc(val));
            setValidEndDate(true);
          } else {
            //when user manually types in date field and date is incorrect
            setValidEndDate(false);
          }
        }}
        selectedDate={endDate}
        className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}__end-date`}
        openClockOnFocus={false}
        onTimeChange={(val) => {
          setEndTime(val);
        }}
        isValid={
          (isValidEndDateTime() && isDateTimeNotPredated(endDate, endTime)) ||
          showEditButton ||
          !switchToggle
        }
        errorText={{
          id: endTimeErrorTxt(),
        }}
        selectedTime={endTime}
        width={'30%'}
        disabled={!switchToggle || showEditButton}
      />
      <div className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}-button-wrapper`}>
        {!showEditButton ? (
          <Button
            id={'save_button'}
            className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}-save-button`}
            type={BUTTON_TYPES.primary}
            icon={ConfirmButton}
            disabled={isSaveDisabled()}
            onClick={() => {
              _saveData();
            }}
          >
            <FormattedMessage id={'common.save'} />
          </Button>
        ) : (
          <Button
            id={'edit_button'}
            className={`${ADMIN_MODULE_MAINTENANCE_PAGE_BLOCK}-edit-button`}
            type={BUTTON_TYPES.primary}
            icon={ConfirmButton}
            disabled={false}
            onClick={() => {
              setShowEditButton(false);
            }}
          >
            <FormattedMessage id={'common.edit'} />
          </Button>
        )}
      </div>
    </div>
  );
};

MaintenanceBannerAdmin.propTypes = {
  /**Model consisting single maintenace banner data */
  bannerData: PropTypes.instanceOf(AdminModuleMaintenance).isRequired,
  /**function fired to save maintenance page data */
  maintenanceBannerSaveAction: PropTypes.func.isRequired,
};
