import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormItem from 'components/common/form-item-component';
import classNames from 'classnames';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { RadioOption } from 'models/utils/common/radio-options-model';
import Tooltip from 'components/common/tool-tip-component';

const radio_block = 'radio';

const Radio = ({
  className,
  disabled,
  errorText,
  id,
  options,
  selectedOption,
  isValid,
  label,
  name,
  onChange,
  tooltip,
  width,
  alt,
  radioWrapperClass,
}) => (
  <FormItem
    className={classNames(className)}
    disabled={disabled}
    errorText={errorText}
    id={id}
    isValid={isValid}
    width={width}
    label={label}
  >
    {options.map((option, i) => (
      <Tooltip
        key={`${id}-option-${i}`}
        id={id}
        {...tooltip}
        active={option.tooltipEnabled ? true : false}
      >
        <div
          className={classNames(
            { [radioWrapperClass]: radioWrapperClass },
            radio_block,
            selectedOption === option.value ? `${radio_block}--checked` : null,
            disabled ? `${radio_block}--disabled` : null,
            !isValid ? `${radio_block}--invalid` : null,
          )}
          onClick={(e) => {
            if (option.disabled) {
              return false;
            }
            onChange(option.value);
          }}
        >
          <input
            className={classNames(
              `${radio_block}__input`,
              alt ? `${radio_block}__input--alt` : null,
            )}
            disabled={disabled || option.disabled}
            id={option.id}
            name={name}
            onChange={(e) => {
              if (option.disabled) {
                return false;
              }
              onChange(option.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }} // prevents the onChange handler from being called twice since the click propagates to the wraping div which also has an onClick
            type="radio"
            value={option.value}
            checked={selectedOption === option.value}
          />
          <label
            htmlFor={id}
            disabled={disabled || option.disabled}
            className={classNames(`${radio_block}__label`)}
          >
            {option.isNotInternationalized ? (
              option.label
            ) : (
              <FormattedMessage id={option.label} />
            )}
          </label>
        </div>
      </Tooltip>
    ))}
  </FormItem>
);

Radio.propTypes = {
  /* Use alternative checked state */
  alt: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(RadioOption)).isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  isValid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.instanceOf(TooltipOptions),
  width: PropTypes.string,
};

export default memo(injectIntl(Radio));
