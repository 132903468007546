import RolesUtil from 'permissions/utils/roles';
const OmniaPermissions = {
  canEditOmniaEngagements: (projectID) =>
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID),
  canShareFile: () => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject() ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject() ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject()
    );
  },
};

export default OmniaPermissions;
