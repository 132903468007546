import React from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import Search from 'components/common/search-component';
import SearchResults from 'components/feature/element-panel/tickmark-panel/_element-tickmark-panel-search-results-component';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import TickmarkList from 'models/api/tickmark-list-api-model';
import Loading from 'components/common/loading-component';
import { FormattedMessage } from 'react-intl';
import shortid from 'shortid';
import TickmarkDetachEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-with-detach-component';
import { TICKMARK_REMOVE_TOOLTIP } from 'constants/feature/batch-panel-constants';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { isNullOrUndefined } from 'utils/object-utils';
import PropTypes from 'prop-types';
import Tickmark from '../../../models/data/tickmark-model';

export const CONFIRM_OVERRIDE_SYSTEM_FLAG = 'confirm-override-system-flag';
export const MIN_CHAR_LENGTH_TO_SEARCH = 1;
export const MAX_CHAR_LENGTH_TO_SEARCH = 3999;
export const CREATE_TICKMARK_DUPLICATE_ERROR =
  'ValidationException: This tickmark already exists, try attaching it from existing tickmarks';

export const ConfirmOverrideSystemFlag = ({
  onClickCancel,
  updateOverrideSystemFlag,
  disabled = false,
  _fetchTickmarks,
  _createNewTickmark,
  onTickmarkAttach,
}) => {
  const [search, setSearch] = React.useState('');
  const searchComponentRef = React.useRef(null);

  // It stores the list of matching (from serach) tickmark fetched from BE to display in dropdown for selection
  const [tickmarkList, setTickmarkListModel] = React.useState(
    new TickmarkList(),
  );
  const [loading, setLoading] = React.useState(false);
  // It stores tickmark list to be attached to an element
  const [attachTickmark, setAttachTickmark] = React.useState(
    [], // This will contain elements of type Tickmark
  );
  const [errorMsgId, seterrorMsgId] = React.useState('');

  const _isRequestErrorForDuplicate = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause.includes(
      CREATE_TICKMARK_DUPLICATE_ERROR, // error message from API
    );

  const _onClickOutside = (e) => {
    if (
      !(
        (searchComponentRef &&
          searchComponentRef.current &&
          searchComponentRef.current.contains(e.target)) ||
        //cancel button
        e.target.id === `${CONFIRM_OVERRIDE_SYSTEM_FLAG}-secondary-button`
      )
    ) {
      _clearSearch();
    }
  };

  const _clearSearch = () => {
    setTickmarkListModel(new TickmarkList());
    setSearch('');
    seterrorMsgId('');
  };

  // This function is used for creating new tickmark, when tickmark does not already exists
  const updateAttachTickmarkFromSearch = async () => {
    try {
      setLoading(true);

      // There was BE requirement to wrap tickmark within some HTML tags.
      // tickmark text without HTML tags was causing some orientation issues while displaying tickmark in report.
      let tickmarkTextToBeCreated = `<p>${search}</p>`;
      let response = await _createNewTickmark(tickmarkTextToBeCreated);
      setAttachTickmark((attachTickmarkList) => {
        attachTickmarkList.push(new Tickmark(response.data.result));
        return attachTickmarkList;
      });
      _clearSearch();
    } catch (error) {
      if (_isRequestErrorForDuplicate(error)) {
        seterrorMsgId('create-tickmark-modal.form.rich-text.error.duplicate');
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  // This function is used to attach already existing tickmark
  // When we are updating tickmark we need tickmarkId also. Hence we need to update it from object
  const updateAttachTickmarkFromObject = (tickmarkToAttach) => {
    setAttachTickmark((attachTickmarkList) => {
      // We want to refine search such that duplicate tickmarks are not there in dropdown
      attachTickmarkList.findIndex(
        (tickmark) => tickmarkToAttach.tickmarkId === tickmark.tickmarkId,
      ) === -1 && attachTickmarkList.push(new Tickmark(tickmarkToAttach));
      return attachTickmarkList;
    });
    _clearSearch();
  };

  const _fetchSuggestedTickmarks = async ({ search }) => {
    setTickmarkListModel(tickmarkList.setLoading());
    try {
      let response = await _fetchTickmarks({ search });
      setTickmarkListModel(tickmarkList.setLoaded({ response }));
    } catch (error) {
      setTickmarkListModel((e) => e.setError(error));
    }
  };

  const _handleAttachTickmark = async () => {
    setLoading(true);
    try {
      if (
        attachTickmark &&
        typeof onTickmarkAttach === 'function' &&
        attachTickmark.length > 0
      ) {
        for (
          let tickmarkIndex = 0;
          tickmarkIndex < attachTickmark.length;
          tickmarkIndex++
        )
          await onTickmarkAttach({ tickmark: attachTickmark[tickmarkIndex] });
      }
    } catch (error) {
      setTickmarkListModel(tickmarkList.setError(error));
    } finally {
      setLoading(false);
    }
    _clearSearch();
  };

  const debouncedSearch = React.useCallback(
    debounceSearch(_fetchSuggestedTickmarks),
  );

  const _searchTickmarks = async (val) => {
    if (val.length >= MAX_CHAR_LENGTH_TO_SEARCH) {
      seterrorMsgId(
        'confirm-override-flag-max-search-length-validation-message',
      );
      return;
    }
    seterrorMsgId('');
    setSearch(val);
    const _shouldClearResults =
      !isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH) &&
      tickmarkList.isLoaded &&
      tickmarkList.hasTickmarks();
    if (isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH)) {
      debouncedSearch({ search: val });
    } else if (_shouldClearResults) {
      setTickmarkListModel(new TickmarkList());
    }
  };

  const _shouldOpenMenu = isSearchCharLengthValid(
    search,
    MIN_CHAR_LENGTH_TO_SEARCH,
  );

  const _onDetachTickmark = ({ tickmark }) => {
    setAttachTickmark((attachTickmarkList) =>
      attachTickmarkList.filter(
        (attachedTickmark) =>
          attachedTickmark.tickmarkId !== tickmark.tickmarkId,
      ),
    );
  };

  return (
    <Modal
      id={CONFIRM_OVERRIDE_SYSTEM_FLAG}
      className={CONFIRM_OVERRIDE_SYSTEM_FLAG}
      title={'confirm-override-system-flag-modal-title'}
      onClose={onClickCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          disabled: attachTickmark.length === 0,
          onClick: async () => {
            setLoading(true);
            try {
              attachTickmark &&
                attachTickmark.length > 0 &&
                (await _handleAttachTickmark());

              updateOverrideSystemFlag({
                systemFlag: true,
              });
            } catch (err) {
              console.log(err);
            } finally {
              setLoading(false);
              onClickCancel();
            }
          },
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClickCancel,
        })
      }
    >
      <Banner
        width={'100%'}
        id={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}-information-guidance`}
        className={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}__guidance`}
        type={BANNER_TYPES.INFORMATION}
        bannerCopy={'confirm-override-system-flag-guidance-message-banner'}
        isInternationalized
      />
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <div
            ref={searchComponentRef}
            className={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}-search-wrapper`}
          >
            <Search
              id={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}-search`}
              className={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}__search`}
              placeholder="confirm-override-system-flag-modal-search.placeholder"
              onChange={_searchTickmarks}
              value={search}
              isValid={true}
              disableAutoComplete
              onClear={_clearSearch}
              minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
              showSearchButton
              maxLength={MAX_CHAR_LENGTH_TO_SEARCH}
            />
            <SearchResults
              tickmarkList={tickmarkList.returnTopNTickmarks(10)}
              search={search}
              onAddTickmark={updateAttachTickmarkFromObject}
              disabled={disabled}
              isOpen={_shouldOpenMenu}
              onClickOutside={(e) => _onClickOutside(e)}
              showCreateButton={true}
              onCreate={updateAttachTickmarkFromSearch}
            />
          </div>
          {errorMsgId && (
            <FormattedMessage
              id={errorMsgId}
              values={{
                message: (...chunks) => (
                  <span
                    className={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}__maxlength-error-message`}
                    key={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}-spanTag-${shortid.generate()}`}
                  >
                    {chunks}
                  </span>
                ),
                errorTxt: (...chunks) => (
                  <span className="error-text-validation-msg">{chunks}</span>
                ),
                maxLength: MAX_CHAR_LENGTH_TO_SEARCH - 1,
              }}
            />
          )}
          <div
            className={`${CONFIRM_OVERRIDE_SYSTEM_FLAG}-tickmark_attach_list`}
          >
            {attachTickmark.length > 0 &&
              attachTickmark.map(
                (selectedTickmark) =>
                  !isNullOrUndefined(selectedTickmark.tickmarkId) && (
                    <TickmarkDetachEntry
                      key={selectedTickmark.tickmarkId}
                      tickmark={selectedTickmark}
                      onDetach={_onDetachTickmark}
                      disabled={false}
                      detachIcon={CloseButton}
                      tooltip={TICKMARK_REMOVE_TOOLTIP}
                    />
                  ),
              )}
          </div>
        </div>
      )}
    </Modal>
  );
};

ConfirmOverrideSystemFlag.propTypes = {
  /**function to handle cancel event */
  onClickCancel: PropTypes.func.isRequired,
  /**function to make api call and handle enabling of system override flag */
  updateOverrideSystemFlag: PropTypes.func.isRequired,
  /**function to handle creation of new tickmark. API call is made here */
  _createNewTickmark: PropTypes.func.isRequired,
  /**It is used to enable/disbale permission to attach tickmark in confirm override system flag modal */
  disabled: PropTypes.bool,
  /**It is used to fetch matching (with text entered in search box) tickmark */
  _fetchTickmarks: PropTypes.func.isRequired,
  /**A function used handle request about attaching tickmark to an element */
  onTickmarkAttach: PropTypes.func.isRequired,
};

export default ConfirmOverrideSystemFlag;
