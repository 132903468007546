import BaseModel from 'models/base-model';
export default class ToolkitOption extends BaseModel({
  Icon: null,
  id: null,
  shouldDisplay: () => false,
}) {
  isSelected(selectedOption) {
    return this.id === selectedOption.id;
  }
}
