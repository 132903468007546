import ApiModel from 'models/api-model';

export default class ActiveUsers extends ApiModel({
  data: {
    users: [],
  },
}) {
  get users() {
    return this.data.users;
  }

  processResponse(response) {
    return {
      data: {
        users: response.data.result,
      },
    };
  }

  addUser({ payload }) {
    return this.merge({ data: { users: payload } });
  }

  removeUser({ payload }) {
    return this.merge({ data: { users: payload } });
  }

  hasUsers() {
    return this.data.users && this.data.users.length > 0;
  }
  getUsersLength() {
    if (this.hasUsers()) {
      return this.data.users.length;
    }
  }
}
