import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export class ContentSectionMap extends ApiModel({
  data: {
    map: {},
  },
}) {
  get map() {
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.map)) {
      return this.data.map;
    }
    return {};
  }
  processResponse({ response }) {
    const map = {};
    response.data.result.forEach(
      ({ parentId, name, id, is_technical, originalText }) => {
        map[id] = {
          parentId,
          name,
          id,
          isTechnical: is_technical,
          originalText,
        };
      },
    );
    return {
      data: {
        map,
      },
    };
  }

  has(id) {
    return (
      !isNullOrUndefined(id) &&
      !isNullOrUndefined(this.map) &&
      !isNullOrUndefined(this.map[id])
    );
  }

  get(id) {
    if (this.has(id)) {
      return this.map[id];
    }
    return null;
  }

  getClosestBookmarkSection({ sectionId }) {
    if (this.has(sectionId)) {
      const thisContent = this.get(sectionId);
      if (!thisContent.isTechnical) {
        return thisContent;
      }
      return this.getClosestBookmarkSection({
        sectionId: thisContent.parentId,
      });
    }
    return null;
  }

  hasSubsection({ sectionId }) {
    return (
      this.has(sectionId) &&
      Object.values(this.map).some((section) => section.parentId === sectionId)
    );
  }
}
