import { handleActions } from 'redux-actions';

import ContentViewModes from 'models/data/content-view-modes-model';
import {
  setContentFixedMode,
  setContentFullMode,
} from 'store/actions/content-view-modes-actions';

export default handleActions(
  {
    [setContentFixedMode]: () => {
      return new ContentViewModes().setContentFixedMode();
    },
    [setContentFullMode]: () => {
      return new ContentViewModes().setContentFullMode();
    },
  },
  new ContentViewModes(),
);
