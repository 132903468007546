import React from 'react';
import TimePicker from 'react-time-picker';
import FormItem from 'components/common/form-item-component';
import CustomDatePicker from 'constants/feature/dateComponent/customDatePicker';
import { DATE_FORMATS } from 'constants/util/date-constants';
import PropTypes from 'prop-types';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

const DISPLAY_DATE_FORMAT = DATE_FORMATS.MONTH_DAY_YEAR;
const DATE_TIME_PICKER_BLOCK = 'date-time-picker';
const DATE_TIME_PICKER_ID = 'date-time-pciker-id';

export const DateTimePicker = ({
  id = DATE_TIME_PICKER_ID,
  onDateChange,
  onTimeChange,
  selectedDate,
  selectedTime,
  className,
  disabled,
  errorText,
  isValid,
  label,
  tooltip,
  width,
  maxTimeDetail, //  How detailed time picking shall be. Can be "hour", "minute" or "second".
  onClockClose,
  onClockOpen,
  openClockOnFocus,
}) => {
  return (
    <FormItem
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      label={label}
      tooltip={tooltip}
      width={width}
    >
      <div
        className={
          isValid
            ? DATE_TIME_PICKER_BLOCK
            : `${DATE_TIME_PICKER_BLOCK}--invalid`
        }
        disabled={disabled}
      >
        <div className={`${DATE_TIME_PICKER_BLOCK}-wrap`}>
          <CustomDatePicker
            id={id}
            selectedDate={selectedDate}
            disabled={disabled}
            format={DISPLAY_DATE_FORMAT}
            placeholder={DISPLAY_DATE_FORMAT}
            width={width}
            isFormItemRequired={false}
            onDateChange={(date) => {
              onDateChange(date);
            }}
          />
          <TimePicker
            openClockOnFocus={openClockOnFocus}
            onChange={(val) => {
              onTimeChange(val);
            }}
            value={selectedTime}
            disabled={disabled}
            maxDetail={maxTimeDetail}
            onClockClose={onClockClose}
            onClockOpen={onClockOpen}
          />
        </div>
      </div>
    </FormItem>
  );
};

DateTimePicker.propTypes = {
  /** Moment object representing date selected */
  selectedDate: PropTypes.object,
  /** Moment object representing time selected */
  selectedTime: PropTypes.object,
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** String error copy passed to form item */
  errorText: PropTypes.string,
  /** Unique string id for date time picker*/
  id: PropTypes.string.isRequired,
  /** Boolean representing if date time picker output is valid */
  isValid: PropTypes.bool,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** String percentage representing width of component passed to form item */
  width: PropTypes.string,
  /** Function fired when date is changed. return moment object */
  onDateChange: PropTypes.func.isRequired,
  /** Function fired when time is changed. */
  onTimeChange: PropTypes.func.isRequired,
  /** How detailed time picking shall be. Can be "hour", "minute" or "second". */
  maxTimeDetail: PropTypes.string,
  /**Function fired when clock is closed */
  onClockClose: PropTypes.func,
  /**Function fired when clock is opened */
  onClockOpen: PropTypes.func,
  /**Should open clock when input box for time is clicked */
  openClockOnFocus: PropTypes.bool,
};
