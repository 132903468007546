import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import {
  toggleExportPanel,
  toggleSubPanelWithinExport,
} from 'store/actions/panel-controller-actions';
import ExportPanelContent from 'components/feature/toolkit/toolkit-export-panel-content-component';
import DataGridHeader from 'components/common/data-grid/data-grid-header.component';
import { TOOLKIT_EXPORT_MODULE_TABS } from 'constants/feature/toolkit/toolkit-export-panel-constant';
import ToolkitDownloadList from './toolkit-downloads-list-component';
import ConditionalRender from 'components/util/conditional-render-component';
import ToolkitDownloadListData from 'models/api/toolkit-export-download-list-model';
import { updateToolkitReportWithLoading } from 'store/actions/toolkit-export-panel-actions';
import TabModelIcon from 'models/utils/common/tab-model-icon';
import { getListOfProcessedAndNotDownloadedReports } from 'utils/statement-content-page-utils';

export const EXPORT_PANEL_BLOCK = 'export-panel';
export const EXPORT_PANEL_BLOCK_ID = 'export-panel-id';

export const getDownloadTabCountIcon = (countValue) => {
  if (countValue) {
    let tabId = TOOLKIT_EXPORT_MODULE_TABS.DOWNLOADS.id;
    let iconComponent = (
      <span className={`${EXPORT_PANEL_BLOCK}__download-counter`}>
        {countValue}
      </span>
    );
    let className = 'export-panel-download-report-counter';
    return new TabModelIcon().setNewIcon({ tabId, iconComponent, className })
      .data;
  } else return null;
};

export const ExportPanel = ({
  show,
  onCancelPanel,
  openedSubTab,
  toggleSubPanelWithinExport,
  downloadProgressData,
  updateToolkitReportWithLoading,
}) => {
  const switchToolkitExportModuleTabs = (selectedTab) => {
    switch (selectedTab) {
      case LEFT_PANELS.EXPORT:
        return <ExportPanelContent />;
      case LEFT_PANELS.DOWNLOAD:
        return (
          <ConditionalRender dependencies={[downloadProgressData]}>
            <ToolkitDownloadList downloadProgressData={downloadProgressData} />
          </ConditionalRender>
        );
      default:
        return <ExportPanelContent />;
    }
  };

  const reportCount = () => {
    let originalReportList = downloadProgressData &&
      downloadProgressData.data &&
      downloadProgressData.data.size && [...downloadProgressData.data.values()];
    if (!originalReportList) return 0;
    else {
      let filteredReportList =
        getListOfProcessedAndNotDownloadedReports(originalReportList);
      return filteredReportList.length ? filteredReportList.length : 0;
    }
  };

  const previouslySelectedTab = () => {
    return openedSubTab === LEFT_PANELS.DOWNLOAD
      ? TOOLKIT_EXPORT_MODULE_TABS.DOWNLOADS
      : TOOLKIT_EXPORT_MODULE_TABS.REPORT_TYPES;
  };

  React.useEffect(() => {
    show && updateToolkitReportWithLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <Flyout className={EXPORT_PANEL_BLOCK} show={show} position={'left'}>
        <FlyoutHeader enableClose onClose={onCancelPanel}>
          <h3 className={`${EXPORT_PANEL_BLOCK}__title`}>
            <FormattedMessage id={'export-panel.title'} />
          </h3>
        </FlyoutHeader>
        <DataGridHeader
          tableId={`${EXPORT_PANEL_BLOCK_ID}-headings`}
          tabsArray={Object.values(TOOLKIT_EXPORT_MODULE_TABS)}
          selectedTab={
            openedSubTab === LEFT_PANELS.EXPORT
              ? TOOLKIT_EXPORT_MODULE_TABS.REPORT_TYPES
              : TOOLKIT_EXPORT_MODULE_TABS.DOWNLOADS
          }
          onSelectTab={(selectedTab) =>
            selectedTab !== previouslySelectedTab() &&
            toggleSubPanelWithinExport()
          }
          className={`${EXPORT_PANEL_BLOCK}__navigate-export-tab`}
          rightIcon={getDownloadTabCountIcon(reportCount())}
        />
        <FlyoutPanelContent>
          {switchToolkitExportModuleTabs(openedSubTab)}
        </FlyoutPanelContent>
      </Flyout>
    </>
  );
};

ExportPanel.propTypes = {
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  // This will get list of download data to be rendered in download section of toolkit
  downloadProgressData: PropTypes.instanceOf(ToolkitDownloadListData)
    .isRequired,
  // action method fired to close toolkit-export flyout panel
  onCancelPanel: PropTypes.func.isRequired,
  // string which gives name of currently opened sub tab within toolkit export panel
  openedSubTab: PropTypes.string.isRequired,
  // action method fired to toggle subpanel within toolkit export panel
  toggleSubPanelWithinExport: PropTypes.func.isRequired,
  // action fired to fetch list of reports and update the redux store
  updateToolkitReportWithLoading: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    toolkitExportPanel: { downloadProgressData },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  show: left === LEFT_PANELS.EXPORT || left === LEFT_PANELS.DOWNLOAD,
  openedSubTab: left,
  downloadProgressData: downloadProgressData,
});

const mapDispatchToProps = {
  onCancelPanel: toggleExportPanel,
  toggleSubPanelWithinExport,
  updateToolkitReportWithLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPanel);
