import { handleActions } from 'redux-actions';
import {
  setCopyFormulaResult,
  clearCopyFormulaResult,
} from 'store/actions/copy-formula-actions';
import CopyFormulaResult from 'models/api/copy-formula-result-model';

export default handleActions(
  {
    [setCopyFormulaResult]: (CopyFormulaResult, { payload }) => {
      return CopyFormulaResult.addFormulaResult(payload);
    },
    [clearCopyFormulaResult]: () => {
      return new CopyFormulaResult();
    },
  },
  new CopyFormulaResult(),
);
