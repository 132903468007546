import { removeCommasFromNumber } from 'utils/number-formatter-util';

export const shouldDisplayValueBeFlagged = (
  elementDetails,
  formulaInProgress,
) => {
  // if this case is triggered, the formula won't be considered to be flagged but we stil
  // want to have the text in the orange color
  /* this case is not going to be applied if the formula original value
   and the formula computedResult are still the same number after applying the "/" operator */
  if (
    formulaInProgress.shouldValueBeComparedToRoundedValue(elementDetails) &&
    !formulaInProgress.isFormulaFlagged(elementDetails) &&
    !(
      formulaInProgress.computedResult ===
      parseFloat(removeCommasFromNumber(formulaInProgress.display))
    )
  ) {
    return true;
  }

  return (
    formulaInProgress.isFormulaFlagged(elementDetails) &&
    !elementDetails.isSystemOverrideFlagged()
  );
};
export const round = (value, exp) => {
  if (typeof exp === 'undefined' || +exp === 0) return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
};
export const countDecimals = (number) => {
  let numberToString = Math.abs(number).toString();
  let index = numberToString.indexOf('.');
  if (index !== -1) {
    return numberToString.length - index - 1;
  }
  return 0;
};

export const countDigits = (number) => {
  let numberToString = Math.abs(number).toString();
  let index = numberToString.indexOf('.');
  if (index !== -1) {
    return index;
  }
  return numberToString.length;
};

// generic function which is used to replace the custom special characters separators for thousands and decimal places for each amount element
export const numberFormatConverter = (
  number,
  thousandSep = ',',
  decimalSep = '.',
) => {
  const numStr = number.toString();
  const [intPart, decimalPart] = numStr.split('.');

  const formattedIntPart = intPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSep,
  );
  const formattedNumber = decimalPart
    ? `${formattedIntPart}${decimalSep}${decimalPart}`
    : formattedIntPart;

  return formattedNumber;
};

// gets the formatted amount value based on the numberformat selected during the statement upload for this current statement
export const getElementResultByNumberFormat = (value, numberFormatId) => {
  /** null or undefined check */
  if (!value) return value;
  /** this checks if value is not null or undefined and type as a string and contains numeric values to convert to a number
   * or else return number values as it is
   */
  const unformattedNumber =
    value && typeof value === 'string' && /[0-9]/.test(value)
      ? parseFloat(value)
      : value;

  /** convert the number to respective number format of current statment during statement upload time */
  switch (numberFormatId) {
    case 1:
      //format: "1,234.56 (Thousand separator is 'comma' and Decimal separator is 'dot')",
      return numberFormatConverter(unformattedNumber, ',', '.');
    case 2:
      //format: "1.234,56 (Thousand separator is 'dot' and Decimal separator is 'comma')",
      return numberFormatConverter(unformattedNumber, '.', ',');
    case 3:
      //format: "1’234.56 (Thousand separator is 'apostrophe' and Decimal separator is 'dot')",
      return numberFormatConverter(unformattedNumber, "'", '.');
    case 4:
      //format: "1 234.56 (Thousand separator is 'space' and Decimal separator is 'dot')",
      return numberFormatConverter(unformattedNumber, ' ', '.');
    case 5:
      //format: "1 234,56 (Thousand separator is 'space' and Decimal separator is 'comma')",
      return numberFormatConverter(unformattedNumber, ' ', ',');
    case 6:
      //format: "1’234,56 (Thousand separator is 'apostrophe' and Decimal separator is 'Comma')",
      return numberFormatConverter(unformattedNumber, "'", ',');
    default:
      //format: "1,234.56 (Thousand separator is 'comma' and Decimal separator is 'dot')",
      return numberFormatConverter(unformattedNumber, ',', '.');
  }
};
