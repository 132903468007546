import { getAPI } from './api-client';
import { withDefaultContextAsCurrentGeo } from 'api/api-default-context';
import { getGeosWhereUserHasProject } from 'utils/project-utils';

export const getBuildInfoRequest = () =>
  withDefaultContextAsCurrentGeo()
    .get('tieout/info')
    .then((response) => {
      return response;
    });

export const getFeatureInfoForAllGeos = async () => {
  const uniqueGeos = getGeosWhereUserHasProject();
  if (uniqueGeos.length) {
    return Promise.all(
      uniqueGeos.map((geoKey) =>
        getAPI(geoKey)
          .get('/tieout/info')
          .catch((e) => {
            console.log(
              'failed to fetch feature info from GEO:' + geoKey + ',error:' + e,
            );
            return null;
          }),
      ),
    ).then((responses) => {
      return responses;
    });
  }
  return Promise.resolve([]);
};
