import { handleActions } from 'redux-actions';
import {
  replaceSelectedSectionsInLeftContentPanel,
  deallocateSelectedSectionsInLeftContentPanel,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import SelectedSectionMap from 'models/data/selected-section-map-model';

export default handleActions(
  {
    [replaceSelectedSectionsInLeftContentPanel]: (
      selectedSectionMap,
      { payload },
    ) => {
      return selectedSectionMap.replaceWithSections(payload);
    },
    [deallocateSelectedSectionsInLeftContentPanel]: (selectedSectionMap) => {
      return selectedSectionMap.deallocateSection();
    },
    [clearStatementPageStoreAction]: () => {
      return new SelectedSectionMap();
    },
  },
  new SelectedSectionMap(),
);
