import BaseModel from 'models/base-model';

class AdobeDirectCallRule extends BaseModel({
  dataLayer: {},
  ruleName: null,
}) {
  constructor(ruleName, dataLayer) {
    super({ ruleName, dataLayer });
  }
}

export default AdobeDirectCallRule;
