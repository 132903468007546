import { handleActions } from 'redux-actions';
import {
  elementTickmarkListLoading,
  elementTickmarkListLoaded,
  elementTickmarkListError,
  removeTickmarkFromElementPanelWithSocketPayloadAction,
  updateTickmarkInElementPanelWithSocketPayloadAction,
  elementTickmarkListFromWebSocketPayloadAction,
  removeTickmarkWithSocketPayloadInBulk,
  elementTickmarkListLoadedWithoutResponse,
} from 'store/actions/element-panel-actions';
import ElementTickmarkList from 'models/api/element-tickmark-list-model';

export default handleActions(
  {
    [elementTickmarkListLoading]: (tickmarkList) => {
      return tickmarkList.setLoading();
    },
    [elementTickmarkListLoaded]: (tickmarkList, { payload }) => {
      return tickmarkList.setLoaded(payload);
    },
    [elementTickmarkListLoadedWithoutResponse]: (tickmarkList) => {
      return tickmarkList.setLoadedWithoutResponse();
    },
    [elementTickmarkListError]: (tickmarkList, { payload }) => {
      return tickmarkList.setError(payload);
    },
    [removeTickmarkFromElementPanelWithSocketPayloadAction]: (
      tickmarkList,
      { payload },
    ) => {
      return tickmarkList.removeTickmarkFromElementPanelWithSocketPayload(
        payload,
      );
    },
    [removeTickmarkWithSocketPayloadInBulk]: (tickmarkList, { payload }) => {
      return tickmarkList.removeTickmarkWithSocketPayloadInBulk(payload);
    },
    [updateTickmarkInElementPanelWithSocketPayloadAction]: (
      tickmarkList,
      { payload },
    ) => {
      return tickmarkList.updateTickmarkInElementPanelWithSocketPayload(
        payload,
      );
    },
    [elementTickmarkListFromWebSocketPayloadAction]: (
      tickmarkList,
      { payload },
    ) => {
      return tickmarkList.setTickmarkListFromWebSocketPayload(payload);
    },
  },
  new ElementTickmarkList(),
);
