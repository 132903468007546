import { handleActions } from 'redux-actions';

import {
  setActiveUsersLoading,
  setActiveUsersLoaded,
  setActiveUsersError,
  addUserAction,
  removeUserAction,
  clearActiveUsersAction,
} from 'store/actions/active-users-actions';
import ActiveUsers from 'models/api/active-users-api-model';

export default handleActions(
  {
    [setActiveUsersLoading]: (activeUsers) => {
      return activeUsers.setLoading();
    },
    [setActiveUsersLoaded]: (activeUsers, { payload }) => {
      return activeUsers.setLoaded(payload);
    },
    [setActiveUsersError]: (activeUsers, { payload }) => {
      return activeUsers.setError(payload);
    },
    [addUserAction]: (activeUsers, { payload }) => {
      return activeUsers.addUser(payload);
    },
    [removeUserAction]: (activeUsers, { payload }) => {
      return activeUsers.removeUser(payload);
    },
    [clearActiveUsersAction]: (activeUsers, { payload }) => {
      return new ActiveUsers();
    },
  },
  new ActiveUsers(),
);
