import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import ProjectApi from 'models/api/project-api-model';

export default class ProjectListEntry extends BaseModel({
  active: null,
  adminGroupAddDate: null,
  cik: null,
  deleteDate: null,
  engagementEntity: null,
  omniaEngagementEntity: null,
  hasAdminGroup: null,
  hasCoeGroup: null,
  id: null,
  legalHold: null,
  name: null,
  sic: null,
  geoCode: null,
  symbol: null,
  type: null,
  userEntities: [], // type [User]
  createdDate: null,
}) {
  getCreatedDate() {
    return moment(this.createdDate).format(DATE_FORMATS.MONTH_DAY_YEAR);
  }

  isInitialized() {
    return !isNullOrUndefined(this.id);
  }

  isDeleted() {
    return !this.active && this.deleteDate;
  }

  getUserIds() {
    let idsArray = [];
    if (this.userEntities.length) {
      this.userEntities.forEach((user) => {
        idsArray.push(user.id);
      });
    }
    return idsArray.join(',');
  }

  getProjectGeo() {
    return this.geoCode;
  }

  toProjectApiModel() {
    const apiModel = new ProjectApi();
    // returns data the same shape as it would be returned in the /client?clientId={clientId} response
    return apiModel.setLoaded({
      data: { ...this },
    });
  }
}
