import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import TickmarkListModel from 'models/api/tickmark-list-api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import DeleteTickmarkModal from './delete-tickmark-modal';
import EditTickmarkModal from './edit-tickmark-modal';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { toggleTickmarkPanel } from 'store/actions/panel-controller-actions';
import {
  fetchTickmarkListForRevision,
  fetchTickmarkListForRevisionWithoutLoading,
  deleteTickmark,
} from 'store/actions/tickmark-list-panel-actions';
import Loading from 'components/common/loading-component';
const TickmarkList = lazy(() =>
  import('./_tickmark-panel-tickmark-list-component'),
);
export const TICKMARK_PANEL_BLOCK = 'tickmark-panel';

const TickmarkPanel = ({
  tickmarkList,
  show,
  onCancelPanel,
  revisionId,
  fetchTickmarkList,
  fetchTickmarkListWithoutLoading,
  deleteTickmark,
  socketHasBeenDisconnected,
}) => {
  const [showEditTickmarkModal, setShowEditTickmarkModal] = useState(false);
  const [tickmarkToEdit, setTickmarkToEdit] = useState(null);
  const [tickmarkToDelete, setTickmarkToDelete] = useState(null);

  useEffect(() => {
    if (show && !isNullOrUndefined(revisionId)) {
      fetchTickmarkList({ revisionId });
    }
  }, [show, revisionId, fetchTickmarkList]);

  const _handleTickmarkEditClick = useCallback(({ tickmark }) => {
    setTickmarkToEdit(tickmark);
    setShowEditTickmarkModal(true);
  }, []);

  const _handleTickmarkDeleteClick = useCallback(({ tickmark }) => {
    setTickmarkToDelete(tickmark);
  }, []);

  return (
    <>
      <Flyout className={TICKMARK_PANEL_BLOCK} show={show} position={'left'}>
        <FlyoutHeader enableClose onClose={onCancelPanel}>
          <h3 className={`${TICKMARK_PANEL_BLOCK}__title`}>
            <FormattedMessage id={'tickmark-panel.title'} />
          </h3>
        </FlyoutHeader>
        <FlyoutPanelContent>
          <Suspense fallback={<Loading />}>
            <TickmarkList
              tickmarkList={tickmarkList}
              onDeleteTickmark={_handleTickmarkDeleteClick}
              onEditTickmark={_handleTickmarkEditClick}
            />
          </Suspense>
        </FlyoutPanelContent>
      </Flyout>
      {tickmarkToDelete && (
        <DeleteTickmarkModal
          onDelete={() => {
            deleteTickmark({ tickmark: tickmarkToDelete });
            setTickmarkToDelete(null);
          }}
          onClose={() => {
            setTickmarkToDelete(null);
          }}
        />
      )}
      {showEditTickmarkModal && (
        <EditTickmarkModal
          tickmarkToEdit={tickmarkToEdit}
          onCancel={() => setShowEditTickmarkModal(false)}
          onSave={() => {
            setShowEditTickmarkModal(false);
            socketHasBeenDisconnected &&
              fetchTickmarkListWithoutLoading({ revisionId });
          }}
        />
      )}
    </>
  );
};

TickmarkPanel.propTypes = {
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  /** Function to cancel and close batch panel */
  onCancelPanel: PropTypes.func.isRequired,
  /** Current revision id */
  revisionId: PropTypes.number,
  /** List of tickmarks for current revision id */
  tickmarkList: PropTypes.instanceOf(TickmarkListModel).isRequired,
  /** Action to fetch tickmark list for a revision */
  fetchTickmarkList: PropTypes.func.isRequired,
  /** Action to fetch tickmark list for a revision without loading */
  fetchTickmarkListWithoutLoading: PropTypes.func.isRequired,
  /** Action to delete tickmark */
  deleteTickmark: PropTypes.func.isRequired,
  /**To Check if socket connection is there */
  socketHasBeenDisconnected: PropTypes.bool,
};

const mapStateToProps = ({
  data: { revision, tickmarkPanel },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  revisionId: revision.id,
  show: left === LEFT_PANELS.TICKMARK,
  tickmarkList: tickmarkPanel,
  socketHasBeenDisconnected,
});

const mapDispatchToProps = {
  onCancelPanel: toggleTickmarkPanel,
  fetchTickmarkList: fetchTickmarkListForRevision,
  fetchTickmarkListWithoutLoading: fetchTickmarkListForRevisionWithoutLoading,
  deleteTickmark,
};

export { TickmarkPanel };
export default connect(mapStateToProps, mapDispatchToProps)(TickmarkPanel);
