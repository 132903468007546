import moment from 'moment';

/**
 * This is a utility function that converts the UTC-based timestamps that we receive from the back-end to
 * localized dates on the front-end based on the user's current timezone. Currently we are storing the UTC-based time in the database,
 * which is served up to us as a parseable timeStamp string.
 * @param {String} utcTime : The actual time we wish to convert, passed in as a String
 * @param {String} format : The format of how we want to receive the converted Date, passed in as a String used by moment.js for formatting, e.g: 'MM-DD-YYYY'
 */
export const convertUTCTimeStampToLocalTime = (utcTime, format) => {
  return moment.utc(utcTime).local().format(format);
};
