import BaseModel from 'models/base-model';
import {
  STATEMENT_LIST_TABS,
  STATEMENT_LIST_SORT_COLUMN_KEYS,
} from 'constants/feature/statement-list-constants';
import { DataGridSort } from 'components/common/data-grid/data-grid-component';

export default class StatementListFilters extends BaseModel({
  // For unit testing we need to set this to null for enzyme runs, it should never be null when actually rendered.
  selectedTab: STATEMENT_LIST_TABS ? STATEMENT_LIST_TABS.ACTIVE : null,
  sort: new DataGridSort({
    key: STATEMENT_LIST_SORT_COLUMN_KEYS
      ? STATEMENT_LIST_SORT_COLUMN_KEYS.lastModifiedDate
      : null,
  }),
}) {
  setSelectedTab(selectedTab) {
    return this.merge({
      selectedTab: selectedTab,
    });
  }

  /**
   *
   * @param {DataGridColumn || DataGridSort} sortColumn object with a key representing the api key to sort by with optional order property
   */
  setSort(sortColumn) {
    return this.merge({
      sort: this.sort.setSort(sortColumn),
    });
  }
}
