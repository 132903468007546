import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { connect } from 'react-redux';
import { hideSectionDetailsPanelAction } from 'store/actions/panel-controller-actions';
import { ReactComponent as TaskReviewedIcon } from 'icons/task-reviewed.svg';
import { ReactComponent as TaskIcon } from 'icons/task.svg';
import ExpandableRow from 'components/common/expandable-row-component';
import {
  reviewSectionRequest,
  deleteReviewSectionRequest,
} from 'api/sections-api';
import { CREATED, OK } from 'http-status-codes';
import {
  handleSignOffSuccess,
  handleRemoveSignOffSuccess,
} from 'store/actions/sections-actions';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import Permissions from 'permissions/permissions';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import {
  onSectionClick,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import SectionDetailsHistoryRow from 'components/feature/section-details-panel/_section-details-history-row-component';
import Checkbox from 'components/common/checkbox-component';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as infoIcon } from 'icons/info.svg';
import { ReactComponent as infoHoverIcon } from 'icons/icon-information-tooltip.svg';
import {
  reviewInfoButtonTooltip,
  hoverReviewTooltip,
} from 'constants/feature/section-details-constants';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import SelectedStatement from 'models/api/selected-statement-model';
import {
  fetchSectionAssignments,
  fetchSectionAssignmentsList,
} from 'store/actions/section-assignment-actions';
import ConditionalRender from 'components/util/conditional-render-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import AssignSectionModal from 'components/feature/section-details-panel/_section-details-section-assignment-modal-component';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';
import ProjectApi from 'models/api/project-api-model';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import { fetchSectionReviewsLoading } from 'store/actions/sections-actions';
import Tooltip from 'components/common/tool-tip-component';
import classnames from 'classnames';
import { ReactComponent as VerifiedIcon } from 'icons/verified.svg';
import { ReactComponent as ReviewedIcon } from 'icons/reviewed.svg';
import { ReactComponent as OpenIcon } from 'icons/open-note.svg';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import { fetchNotesListAndApplyFilter } from 'store/actions/notes-panel-actions';
import { showStatementNavPanelAction } from 'store/actions/panel-controller-actions';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import { searchElements } from 'store/actions/statement-navigator/elements-search-panel-actions';
import {
  verifiedElementFilter,
  reviewedElementFilter,
  flaggedElementFilter,
} from 'constants/feature/statement-summary-constants';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import { ReactComponent as Refresh } from 'icons/refresh-heading.svg';

export const SECTION_PANEL_BLOCK = 'section-panel';
export const SECTION_PANEL_ID_BLOCK = 'section-panel-id';

export const SIGN_ICON_SIZE = '20px';

const DISABLED_REVIEW_BUTTON_TOOLTIP = new TooltipOptions({
  text: 'section-panel.permissions.disabled-button.tooltip',
  id: 'section-panel.permissions.disabled-button.tooltip-id',
  position: 'left',
});
const TOOLTIP_EVENT = 'click';

const SectionDetailsPanel = ({
  shouldShowSectionPanel,
  hideSectionPanel,
  section,
  revisionId,
  sectionReview,
  selectedProjectId,
  currentUserId,
  handleSignOffSuccess,
  handleRemoveSignOffSuccess,
  onSectionClick,
  deallocateItems,
  selectedStatement,
  fetchSectionAssignments,
  sectionAssignments,
  fetchSectionHistoryDetails,
  fetchSectionAssignmentsList,
  projectUsersList,
  socketHasBeenDisconnected,
  fetchSectionReviewsLoading,
  fetchNotesListAndApplyFilter,
  showStatementNavPanelAction,
  setSelectedNavigatorTab,
  fetchElementSearchResults,
}) => {
  const permissionToReview =
    Permissions.Section.canReviewSection(selectedProjectId);
  const permissionToAssign =
    Permissions.Section.canAssignSection(selectedProjectId);

  const isStatementReadyOnly = !selectedStatement.isReadOnly();

  const [shouldExpandExpandableRow, setShouldExpandExpandableRow] =
    useState(true);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [showInformationMessage, setShowInformationMessage] = useState(false);
  const [showSectionAssignModal, setShowSectionAssignModal] = useState(false);

  useEffect(() => {
    if (section.id || section.id === 0) {
      fetchSectionAssignments({
        revisionId,
        sectionId: section.id,
        usersList: projectUsersList.users,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revisionId, section.id, projectUsersList.users]);

  useEffect(() => {
    setToggleStatus(
      sectionReview.userAlreadyReviewed(currentUserId, section.id),
    );
  }, [currentUserId, section.id, sectionReview]);

  const _onSectionAssignSaveAndDeleteAllUsers = async () => {
    setShowSectionAssignModal(false);
    if (socketHasBeenDisconnected) {
      await fetchSectionAssignments({
        revisionId,
        sectionId: section.id,
        usersList: projectUsersList.users,
      });
      await fetchSectionAssignmentsList({ revisionId });
      await fetchSectionHistoryDetails({
        revisionId,
        sectionId: section.id,
      });
    }
  };

  const handleSignOffClick = async () => {
    let isSectionAlreadyReviewed =
      sectionReview.hasSectionBeenReviewedByOtherUser(
        currentUserId,
        section.id,
      );
    await reviewSectionRequest(
      revisionId,
      section.id,
      isSectionAlreadyReviewed,
    ).then((response) => {
      if (
        socketHasBeenDisconnected &&
        response &&
        response.status === CREATED
      ) {
        handleSignOffSuccess({
          data: response.data,
          sectionId: section.id,
        });
      }
    });
  };

  const handleRemoveSignOffClick = async () => {
    let isSectionAlreadyReviewed =
      sectionReview.hasSectionBeenReviewedByOtherUser(
        currentUserId,
        section.id,
      );
    await deleteReviewSectionRequest(
      revisionId,
      section.id,
      isSectionAlreadyReviewed,
    ).then((response) => {
      if (socketHasBeenDisconnected && response && response.status === OK) {
        handleRemoveSignOffSuccess(section.id, currentUserId);
      }
    });
  };
  const resetState = () => {
    setShowInformationMessage(false);
  };
  //function to click on information button when modal is open to hide the tooltip
  const clickOnInfoButton = () => {
    if (showInformationMessage) {
      document
        .getElementById(`${SECTION_PANEL_ID_BLOCK}-review-info-${section.id}`)
        .click();
    }
  };
  useEffect(() => {
    clickOnInfoButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowSectionPanel]);

  useEffect(() => {
    //set tooltip specific size for info button on the right panel if the panel is open
    if (shouldShowSectionPanel && shouldExpandExpandableRow) {
      const infoTooltip = document.getElementById(
        `${reviewInfoButtonTooltip().id}-tooltip`,
      );
      if (infoTooltip) infoTooltip.style.width = '300px';
    }
    //if status row is collapsed, reset the value if the information button to false
    if (!shouldExpandExpandableRow) setShowInformationMessage(false);
  }, [shouldShowSectionPanel, shouldExpandExpandableRow]);

  const showElementListPanel = useCallback(
    (filters) => {
      showStatementNavPanelAction();
      setSelectedNavigatorTab(STATEMENT_NAV_TABS.elements);
      fetchElementSearchResults({
        filters: filters.selectSection(section),
        searchTerm: EMPTY_STRING,
      });
    },
    [
      fetchElementSearchResults,
      section,
      setSelectedNavigatorTab,
      showStatementNavPanelAction,
    ],
  );

  return (
    <ConditionalRender dependencies={[section, sectionReview]}>
      <Flyout className={SECTION_PANEL_BLOCK} show={shouldShowSectionPanel}>
        <>
          <FlyoutHeader
            enableClose
            onClose={hideSectionPanel}
            className={`${SECTION_PANEL_BLOCK}__header`}
          >
            {sectionReview.hasSectionBeenReviewed(section.id) ? (
              <TaskReviewedIcon
                id={`${SECTION_PANEL_ID_BLOCK}-sign-icon`}
                className={`${SECTION_PANEL_BLOCK}__sign-icon`}
                width={SIGN_ICON_SIZE}
                height={SIGN_ICON_SIZE}
              />
            ) : (
              <TaskIcon
                id={`${SECTION_PANEL_ID_BLOCK}-unsign-icon`}
                className={`${SECTION_PANEL_BLOCK}__sign-icon`}
                width={SIGN_ICON_SIZE}
                height={SIGN_ICON_SIZE}
              />
            )}
            <div
              className={`${SECTION_PANEL_BLOCK}__section-name`}
              title={section.name}
              onClick={() => {
                deallocateItems();
                onSectionClick({
                  sectionId: section.id,
                  color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                  sectionClick: true,
                });
              }}
            >
              {section.name}
            </div>
            <div className={`${SECTION_PANEL_BLOCK}__container`}>
              <Tooltip
                id={`${SECTION_PANEL_BLOCK}-total`}
                text="section-details.elements-total.label-tooltip"
                position="bottom"
              >
                <div className={`${SECTION_PANEL_BLOCK}__total`}>
                  <p className={`${SECTION_PANEL_BLOCK}__total--totalcount`}>
                    ({section.totalElements})
                  </p>
                </div>
              </Tooltip>
              <div className={`${SECTION_PANEL_BLOCK}__elementstatus`}>
                <Tooltip
                  id={`${SECTION_PANEL_BLOCK}-verified__tooltip`}
                  text="section-details.elements-verified.label-tooltip"
                  position="bottom"
                >
                  <button
                    className={classnames(
                      `${SECTION_PANEL_BLOCK}__elementstatus-verified`,
                      `${SECTION_PANEL_BLOCK}__elementstatus__button`,
                      `${SECTION_PANEL_BLOCK}__elementstatus__button--verified`,
                    )}
                    onClick={() => showElementListPanel(verifiedElementFilter)}
                  >
                    <VerifiedIcon
                      className={`${SECTION_PANEL_BLOCK}__elementstatus__icon`}
                    />
                    <div
                      className={`${SECTION_PANEL_BLOCK}__elementstatus__label`}
                    >
                      ({section.verifiedElements})
                    </div>
                  </button>
                </Tooltip>
                <Tooltip
                  id={`${SECTION_PANEL_BLOCK}-reviewed`}
                  text="section-details.elements-reviewed.label-tooltip"
                  position="bottom"
                >
                  <button
                    className={classnames(
                      `${SECTION_PANEL_BLOCK}__elementstatus-reviewed`,
                      `${SECTION_PANEL_BLOCK}__elementstatus__button`,
                      `${SECTION_PANEL_BLOCK}__elementstatus__button--reviewed`,
                    )}
                    onClick={() => showElementListPanel(reviewedElementFilter)}
                  >
                    <ReviewedIcon
                      className={`${SECTION_PANEL_BLOCK}__elementstatus__icon`}
                    />
                    <div
                      className={`${SECTION_PANEL_BLOCK}__elementstatus__label`}
                    >
                      ({section.reviewedElements})
                    </div>
                  </button>
                </Tooltip>
              </div>
              <Tooltip
                id={`${SECTION_PANEL_BLOCK}__tooltip`}
                text="section-details.notes.label-tooltip"
                position="bottom"
              >
                <div
                  className={`${SECTION_PANEL_BLOCK}--notesbox`}
                  onClick={() => {
                    let updatedFilters = {
                        heading: true,
                      },
                      selectedSegments = {};
                    selectedSegments[section.id] = section.name;
                    fetchNotesListAndApplyFilter({
                      revisionId,
                      updatedFilters,
                      selectedSegments,
                    });
                  }}
                >
                  <OpenIcon className={`${SECTION_PANEL_BLOCK}--notesicon`} />
                  <div className={`${SECTION_PANEL_BLOCK}--noteslabel`}>
                    ({section.totalNotes})
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                id={`${SECTION_PANEL_BLOCK}-flagged`}
                text="statement-summary.elements-flagged.label-tooltip"
                position="bottom"
              >
                <button
                  className={`${SECTION_PANEL_BLOCK}-flagged ${SECTION_PANEL_BLOCK}__flaggedbutton`}
                  onClick={() => showElementListPanel(flaggedElementFilter)}
                >
                  <FlaggedIcon
                    className={`${SECTION_PANEL_BLOCK}__flaggedicon`}
                  />
                  <div className={`${SECTION_PANEL_BLOCK}__flaggedlabel`}>
                    ({section.flaggedElements})
                  </div>
                </button>
              </Tooltip>
            </div>
            <Tooltip
              id={`${SECTION_PANEL_BLOCK}__refresh-heading-tooltip`}
              text="statement-summary.elements-refresh.button-tooltip"
              position="bottom"
            >
              <button
                onClick={() => {
                  onSectionClick({
                    sectionId: section.id,
                    color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                    sectionClick: true,
                  });
                }}
                className={`${SECTION_PANEL_BLOCK}__refresh-button`}
              >
                <Refresh />
              </button>
            </Tooltip>
          </FlyoutHeader>
          <FlyoutPanelContent>
            <ExpandableRow
              title="section-panel.status.title"
              showExpandableRow={shouldExpandExpandableRow}
              minimizeExpandableRow={() => setShouldExpandExpandableRow(false)}
              expandExpandableRow={() => setShouldExpandExpandableRow(true)}
              tooltipId={`${SECTION_PANEL_BLOCK}-status__tooltip`}
            >
              <div
                id={`${SECTION_PANEL_BLOCK}-review-button`}
                className={`${SECTION_PANEL_BLOCK}__status-button`}
              >
                <ConditionalRender dependencies={[sectionReview]}>
                  <Checkbox
                    id={`${SECTION_PANEL_BLOCK}-sign-off`}
                    label={'section-panel.status.review.button-tittle'}
                    width={'auto'}
                    onChange={() => {
                      if (permissionToReview) {
                        fetchSectionReviewsLoading();
                        if (toggleStatus) {
                          setShowInformationMessage(false);
                          clickOnInfoButton();
                          handleRemoveSignOffClick();
                        } else {
                          handleSignOffClick();
                        }
                      }
                    }}
                    disabled={
                      !isStatementReadyOnly
                        ? !isStatementReadyOnly
                        : !permissionToReview
                    }
                    tooltip={
                      permissionToReview
                        ? hoverReviewTooltip
                        : DISABLED_REVIEW_BUTTON_TOOLTIP
                    }
                    checked={toggleStatus}
                  />
                </ConditionalRender>
                <IconButton
                  id={`${SECTION_PANEL_ID_BLOCK}-review-info-${section.id}`}
                  className={`${SECTION_PANEL_BLOCK}__info-icon`}
                  Icon={showInformationMessage ? infoHoverIcon : infoIcon}
                  onClick={() => {
                    setShowInformationMessage(!showInformationMessage);
                  }}
                  tooltip={reviewInfoButtonTooltip(TOOLTIP_EVENT)}
                  position={'bottom'}
                  tooltipOnHideFunction={resetState}
                />
              </div>
              <ConditionalRender dependencies={[sectionAssignments]}>
                <div
                  className={`${SECTION_PANEL_BLOCK}__section-assign-container`}
                >
                  {isStatementReadyOnly && (
                    <Button
                      className={`${SECTION_PANEL_BLOCK}__assign-button`}
                      id={`${SECTION_PANEL_ID_BLOCK}-section-assign-button`}
                      type={BUTTON_TYPES.primary}
                      onClick={() => setShowSectionAssignModal(true)}
                      disabled={!permissionToAssign}
                    >
                      <FormattedMessage
                        id={
                          sectionAssignments.hasUsersAssignedToSection
                            ? 'section-details.edit-assignment'
                            : 'section-details.assign-button'
                        }
                      />
                    </Button>
                  )}
                </div>
              </ConditionalRender>
            </ExpandableRow>
            <SectionDetailsHistoryRow />
            {showSectionAssignModal && (
              <AssignSectionModal
                onClose={() => setShowSectionAssignModal(false)}
                sectionAssignments={sectionAssignments}
                usersList={projectUsersList.users}
                onSave={_onSectionAssignSaveAndDeleteAllUsers}
                revisionId={revisionId}
                sectionId={section.id}
              />
            )}
          </FlyoutPanelContent>
        </>
      </Flyout>
    </ConditionalRender>
  );
};

SectionDetailsPanel.propTypes = {
  /** Indicates if the element panel should be shown */
  shouldShowSectionPanel: PropTypes.bool.isRequired,
  /** Action to hide element flyout panel */
  hideSectionPanel: PropTypes.func.isRequired,
  /** Section from the section detail store */
  section: PropTypes.object.isRequired,
  /** id of current revision */
  revisionId: PropTypes.number.isRequired,
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList).isRequired,
  /** Id of currently selected project */
  selectedProjectId: PropTypes.string.isRequired,
  /** Gets the id of currently logged user */
  currentUserId: PropTypes.number.isRequired,
  /** Action fired after review success for post update of other comps */
  handleSignOffSuccess: PropTypes.func.isRequired,
  /** Action fired after remove review success for post update of other comps */
  handleRemoveSignOffSuccess: PropTypes.func.isRequired,
  /** Action fired to scroll the selected section into view */
  onSectionClick: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Action to fetch the list of all section assignments */
  fetchSectionAssignments: PropTypes.func.isRequired,
  /** Action to fetch the history details for a section */
  fetchSectionHistoryDetails: PropTypes.func.isRequired,
  /** Action to fetch the assignments for a single section */
  fetchSectionAssignmentsList: PropTypes.func.isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** The model of all the users on the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
  /**It Changes section review to loading state without any data alteration*/
  fetchSectionReviewsLoading: PropTypes.func.isRequired,
  /**Action that fetches notes list, opens notes panel, and applies filter */
  fetchNotesListAndApplyFilter: PropTypes.func.isRequired,
  /** Open statement navigator panel */
  showStatementNavPanelAction: PropTypes.func.isRequired,
  /** Action fired when button is clicked to open selected tab */
  setSelectedNavigatorTab: PropTypes.func.isRequired,
  /** Action fired to search for elements */
  fetchElementSearchResults: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  hideSectionPanel: hideSectionDetailsPanelAction,
  handleSignOffSuccess,
  handleRemoveSignOffSuccess,
  onSectionClick,
  deallocateItems,
  fetchSectionAssignments,
  fetchSectionHistoryDetails,
  fetchSectionAssignmentsList,
  fetchSectionReviewsLoading,
  fetchNotesListAndApplyFilter,
  showStatementNavPanelAction,
  setSelectedNavigatorTab: setSelectedTabAction,
  fetchElementSearchResults: searchElements,
};

const mapStateToProps = ({
  data: {
    sectionPanel,
    revision,
    selectedProject,
    currentUser,
    selectedStatement,
    selectedProject: { project },
    projectUsersList,
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
  ui: {
    statementPage: {
      panels: { right },
    },
  },
}) => ({
  shouldShowSectionPanel: right === RIGHT_PANELS.SECTION,
  section: sectionPanel.selectedSection,
  sectionReview: sectionPanel.sectionReviewList,
  sectionAssignments: sectionPanel.sectionAssignments,
  revisionId: revision.id,
  selectedProjectId: selectedProject.id,
  currentUserId: currentUser.id,
  selectedStatement,
  project,
  projectUsersList,
  socketHasBeenDisconnected,
});

export { SectionDetailsPanel };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionDetailsPanel);
