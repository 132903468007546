import { createAction } from 'redux-actions';
import {
  getComfortAnnotationsForRevisionRequest,
  getElementsByComfortAnnotation,
  deleteComfortLetterRequest,
} from 'api/comfort-letter-api';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { updateCacheWithMostRecentElementChanges } from 'store/actions/element-changes-since-tracker-actions';

export const comfortAnnotationListLoading = createAction(
  'COMFORT_ANNOTATION_LIST_LOADING',
);
export const comfortAnnotationListLoaded = createAction(
  'COMFORT_ANNOTATION_LIST_LOADED',
);
export const comfortAnnotationListError = createAction(
  'COMFORT_ANNOTATION_LIST_ERROR',
);
export const fetchElementsFromComfortAnnotationAction = createAction(
  'FETCH_ELEMENTS_FROM_COMFORT_ANNOTATION',
);

export const unlinkElementInComfortLetterElementsMapAndUpdateCountAction = createAction(
  'UNLINK_ELEMENT_IN_COMFORT_LETTER_ELEMENTS_MAP_AND_UPDATE_COUNTACTION',
);

export const removeElementFromComfortLetterElementsWithSocketPayloadAction = createAction(
  'REMOVE_ELEMENT_FROM_COMFORT_LETTER_ELEMENTS_WITH_SOCKET_PAYLOAD_ACTION',
);

export const linkElementInComfortLetterElementsMapAndUpdateCountAction = createAction(
  'LINK_ELEMENT_IN_COMFORT_LETTER_ELEMENTS_MAP_AND_UPDATE_COUNTACTION',
);

export const updateComfortLetterElementsMapFromArrayAction = createAction(
  'UPDATE_COMFORT_LETTER_ELEMENTS_MAP_FROM_ARRAY_ACTION',
);

export const fetchComfortAnnotationListForRevision = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  if (
    getState().ui.statementPage.panels.left !== LEFT_PANELS.COMFORT_ANNOTATION
  )
    return;
  dispatch(comfortAnnotationListLoading());
  await dispatch(
    fetchComfortAnnotationListForRevisionWithoutLoading({ revisionId }),
  );
};

export const fetchComfortAnnotationListForRevisionWithoutLoading = ({
  revisionId,
}) => async (dispatch, getState) => {
  if (
    getState().ui.statementPage.panels.left !== LEFT_PANELS.COMFORT_ANNOTATION
  )
    return;
  try {
    const response = await getComfortAnnotationsForRevisionRequest({
      revisionId,
    });

    dispatch(comfortAnnotationListLoaded({ response }));
  } catch (error) {
    dispatch(comfortAnnotationListError(error));
  }
};

export const fetchElementsFromComfortAnnotation = ({
  revisionId,
  comfortLetterId,
}) => async (dispatch, getState) => {
  if (
    getState().ui.statementPage.panels.left !== LEFT_PANELS.COMFORT_ANNOTATION
  )
    return;
  try {
    const response = await getElementsByComfortAnnotation({
      revisionId,
      comfortLetterId,
    });

    dispatch(
      fetchElementsFromComfortAnnotationAction({
        elementsMap: response.data,
        comfortLetterId,
      }),
    );
  } catch (error) {
    dispatch(comfortAnnotationListError(error));
  }
};

export const deleteComfortAnnotation = ({ comfortAnnotation }) => async (
  dispatch,
  getState,
) => {
  const { id: revisionId } = getState().data.revision;
  try {
    await deleteComfortLetterRequest({ comfortAnnotation, revisionId });
    dispatch(
      fetchComfortAnnotationListForRevisionWithoutLoading({ revisionId }),
    );
    dispatch(updateCacheWithMostRecentElementChanges());
  } catch (error) {
    dispatch(comfortAnnotationListError(error));
  }
};

export const updateComfortLettersElementsMapArray = ({
  elementsArray,
}) => async (dispatch, getState) => {
  if (
    getState().ui.statementPage.panels.left !== LEFT_PANELS.COMFORT_ANNOTATION
  )
    return;
  if (elementsArray) {
    dispatch(updateComfortLetterElementsMapFromArrayAction(elementsArray));
  }
};
