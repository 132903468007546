import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import StatementCreationPage from 'pages/statement-creation-page';
import { selectedProjectLoaded } from 'store/actions/selected-project-actions';
import { finishStatementCreation } from 'store/actions/statement-creation-actions';

const mapStateToProps = (state, { match: { params } }) => ({
  projectId: params.projectId,
});

const mapDispatchToProps = {
  finishStatementCreation,
  push,
  selectedProjectLoaded,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementCreationPage);
