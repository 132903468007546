import { createAction } from 'redux-actions';

import { getStatementRequest } from 'api/statement-api';
import Statement from 'models/data/statement-model';
import { fetchLeftRevision } from 'store/actions/left-revision-actions';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { CONTEXT_KEY } from 'api/api-client';

export const leftSelectedStatementLoading = createAction(
  'SELECTED_STATEMENT_LOADING',
);
export const leftSelectedStatementLoaded = createAction(
  'LEFT_SELECTED_STATEMENT_LOADED',
);
export const leftSelectedStatementError = createAction(
  'LEFT_SELECTED_STATEMENT_ERROR',
);
export const clearLeftSelectedStatement = createAction(
  'CLEAR_LEFT_SELECTED_STATEMENT',
);

export const fetchLeftSelectedStatement =
  ({ revisionId, statementId, projectId }) =>
  async (dispatch, getState) => {
    try {
      const { data: statementData } = await getStatementRequest(statementId, {
        contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
        contextValue: projectId,
      });
      //dispatch(leftSelectedStatementLoading());
      const statement = new Statement(statementData, statementId);
      await dispatch(fetchLeftRevision(revisionId, statementId, projectId));
      const { leftRevision } = getState().data;
      const isLatestRevision =
        leftRevision.revisionNumber === statement.latestRevision;
      dispatch(
        leftSelectedStatementLoaded({
          statement,
          readOnlyOverride: leftRevision.readOnly || !isLatestRevision,
        }),
      );
    } catch (error) {
      dispatch(leftSelectedStatementError(error));
    }
  };

export const checkIfFeatureFlagEnabled =
  ({ featureName }) =>
  (dispatch, getState) => {
    const selectedStatement =
      getState().data &&
      getState().data.leftselectedStatement &&
      getState().data.leftselectedStatement.data &&
      getState().data.leftselectedStatement.data.statement;
    switch (featureName) {
      case FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE:
      case FEATURE_NAMES.COMFORT_LETTER_FEATURE:
        return selectedStatement && selectedStatement.comfortLetter; // We are maintaining a common flag to enable/disable comfort letter and comfort assigned(comfortAssign)

      default:
        return true;
    }
  };
