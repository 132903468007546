import { createAction } from 'redux-actions';
import {
  closeSocketClientRequest,
  removeUserFromRoom,
} from 'api/statement-socket-client-api';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

export const setStatementSocketClient = createAction(
  'SET_STATEMENT_SOCKET_CLIENT',
);

export const initStatementSocketClient = createAction(
  'INIT_STATEMENT_SOCKET_CLIENT',
);

export const initStatementSignalrSocketClient = createAction(
  'INIT_STATEMENT_SIGNALR_SOCKET_CLIENT',
);

export const clearSocketClientInStore = createAction(
  'CLEAR_STATEMENT_SOCKET_CLIENT',
);

export const stopSocketConnection = createAction('STOP_SOCKET_CONNECTION');

export const setSocketDisconnectAction = createAction(
  'SET_SOCKET_DISCONNECT_ACTION',
);

export const initStatementSocketClientAction =
  ({ revisionId, projectId, currentUserId }) =>
  async (dispatch, getState) => {
    const pathname = window.location.pathname;
    const { data: { currentUser } = {} } = getState();
    const { data: { projectToGeoMap } = {} } = currentUser || {};
    const geoCode = projectId
      ? (projectToGeoMap.get(`${projectId}`) || {}).geoCode
      : null;
    /**enabling the api of signalr socket connection based on the isSignalRForSocketConnectionEnabled flag coming from env.js
     * and dispatch action according to signalr or socketio Socket connection
     */
    if (!pathname.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      if (isSignalRForSocketConnectionEnabled) {
        await dispatch(
          initStatementSignalrSocketClient({ revisionId, geoCode }),
        );
      } else {
        await dispatch(
          initStatementSocketClient({
            revisionId,
            projectId,
            currentUserId,
            geoCode,
          }),
        );
      }
    }
  };

export const closeSocketClient =
  ({ revisionId, projectUserIds, projectId }) =>
  async (dispatch, getState) => {
    try {
      await removeUserFromRoom({
        revisionId,
        projectUserIds: Object.keys(projectUserIds),
        projectId,
      });
      if (isSignalRForSocketConnectionEnabled) {
        const connectionId = localStorage.getItem('connectionId');
        dispatch(stopSocketConnection());
        await closeSocketClientRequest({
          revisionId,
          connectionId,
        });
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(stopSocketConnection());
    dispatch(clearSocketClientInStore());
  };
