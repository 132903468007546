import React from 'react';
import { API_ERROR_NOTIFICATION } from 'components/util/api-error-notificiation-component';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { clearApiErrorDataAction } from 'store/actions/api-error-handler-actions';
import PropTypes from 'prop-types';

const CloseToastButton = ({ clearApiErrorDataAction }) => {
  return (
    <button
      className={`${API_ERROR_NOTIFICATION}__close-notification-button`}
      onClick={() => {
        toast.dismiss();
        clearApiErrorDataAction();
      }}
    >
      <CloseButton />
    </button>
  );
};

CloseToastButton.propTypes = {
  /** Action that clears error data */
  clearApiErrorDataAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  clearApiErrorDataAction,
};

export default connect(null, mapDispatchToProps)(CloseToastButton);
