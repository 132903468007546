import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotesListModel from 'models/api/notes-list-api-model';
import NotesEntry from './notes-panel-notes-entry-component';
import ConditionalRender from 'components/util/conditional-render-component';

const NOTES_LIST_BLOCK = 'notes-list';

const NotesList = ({ notesList }) => {
  return (
    <ConditionalRender dependencies={[notesList]}>
      <div className={NOTES_LIST_BLOCK}>
        {notesList.notes.map((note) => (
          <NotesEntry key={note.noteId} note={note} />
        ))}
      </div>
    </ConditionalRender>
  );
};

NotesList.propTypes = {
  /** Action for setting the note's body */
  notesList: PropTypes.instanceOf(NotesListModel).isRequired,
};

const mapStateToProps = ({
  data: {
    notesPanel: { notesList },
  },
}) => ({
  notesList,
});

export { NotesList };
export default connect(mapStateToProps, null)(NotesList);
