import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const COE_TEAM_ACCESS_DISCLAIMER_BLOCK = 'coe-access-disclaimer-modal';
const COE_TEAM_ACCESS_DISCLAIMER_ID_BLOCK = 'coe-access-disclaimer-modal-id';

export const ViewCOEDisclaimerModal = ({ onClose }) => (
  <Modal
    id={`${COE_TEAM_ACCESS_DISCLAIMER_ID_BLOCK}`}
    title={
      'project-creation.manage-users.coe-team-access.disclaimer-modal-title'
    }
    primaryModalButton={
      new ModalButton({
        text: 'common.close',
        onClick: onClose,
      })
    }
    onClose={onClose}
  >
    <div className={`${COE_TEAM_ACCESS_DISCLAIMER_BLOCK}__para-1`}>
      <FormattedMessage
        id={
          'project-creation.manage-users.coe-team-access.disclaimer-modal-message-first-paragraph'
        }
      />
    </div>
    <div className={`${COE_TEAM_ACCESS_DISCLAIMER_BLOCK}__para-2`}>
      <FormattedMessage
        id={
          'project-creation.manage-users.coe-team-access.disclaimer-modal-message-sec-paragraph'
        }
      />
    </div>
  </Modal>
);

ViewCOEDisclaimerModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
};
