import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActiveUsers from 'models/api/active-users-api-model';
import { getInitials } from 'utils/text-format-utils';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import ProjectUsersList from 'models/api/project-users-list-api-model';

export const ACTIVE_USERS_LIST_BLOCK = 'active-users';
export const ACTIVE_USERS_LIST_ID_BLOCK = 'active-users-id';
const MAX_USERS_AMOUNT = 3;

export const ActiveUsersList = ({
  activeUsersList,
  shouldShowBlackLineToggle,
  projectUsersList,
}) => {
  const getTooltip = (text, id) => {
    return new TooltipOptions({
      text: text,
      id: `${ACTIVE_USERS_LIST_ID_BLOCK}-${id}`,
      position: 'bottom',
    });
  };

  const getAllUsersNamesTooltip = () => {
    let names = '';

    activeUsersList.users.forEach((element, index) => {
      if (index === 0) {
        names = projectUsersList.data.userIdToUserNameMap[element];
      } else {
        names = `${names}\n${projectUsersList.data.userIdToUserNameMap[element]}`;
      }
    });
    return new TooltipOptions({
      text: names,
      id: `${ACTIVE_USERS_LIST_ID_BLOCK}-all-users`,
      position: 'bottom',
    });
  };
  return (
    <ConditionalRender dependencies={[activeUsersList]}>
      <div
        className={classNames(
          `${ACTIVE_USERS_LIST_BLOCK}`,
          !shouldShowBlackLineToggle && `${ACTIVE_USERS_LIST_BLOCK}--aligned`,
        )}
      >
        {activeUsersList &&
          activeUsersList.users &&
          activeUsersList.users.map((element, index) => {
            if (index < MAX_USERS_AMOUNT) {
              return (
                <Tooltip
                  {...getTooltip(
                    projectUsersList.data.userIdToUserNameMap[element],
                    element,
                  )}
                  key={`${ACTIVE_USERS_LIST_ID_BLOCK}-key-${index}`}
                  isNotInternationalized={true}
                >
                  <div
                    className={classNames(
                      `${ACTIVE_USERS_LIST_BLOCK}__user`,
                      activeUsersList.users.length > 1
                        ? `${ACTIVE_USERS_LIST_BLOCK}__user--multiple`
                        : `${ACTIVE_USERS_LIST_BLOCK}__only`,
                    )}
                  >
                    {getInitials(
                      projectUsersList.data.userIdToUserNameMap[element],
                    )}
                  </div>
                </Tooltip>
              );
            }
            return null;
          })}
        {activeUsersList.users.length > MAX_USERS_AMOUNT && (
          <Tooltip
            {...getAllUsersNamesTooltip()}
            multiline={true}
            isNotInternationalized={true}
            className={`${ACTIVE_USERS_LIST_BLOCK}__users-list-tooltip`}
            //offset prop is being used to make the tooltip have the correct position
            offset={{ left: 60 }}
          >
            <div
              id={`${ACTIVE_USERS_LIST_ID_BLOCK}-users-count`}
              className={classNames(
                `${ACTIVE_USERS_LIST_BLOCK}__user`,
                `${ACTIVE_USERS_LIST_BLOCK}__user--multiple`,
              )}
            >
              +{activeUsersList.users.length - MAX_USERS_AMOUNT}
            </div>
          </Tooltip>
        )}
      </div>
    </ConditionalRender>
  );
};

const mapStateToProps = ({ data: { projectUsersList } }) => ({
  projectUsersList,
});

ActiveUsersList.propTypes = {
  /* object of type ActiveUsers with all the current users connected */
  activeUsersList: PropTypes.instanceOf(ActiveUsers).isRequired,
  /* bool value that indicates if blackLine is available */
  shouldShowBlackLineToggle: PropTypes.bool.isRequired,
  /** the instance of all the users in the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
};

export default connect(mapStateToProps, null)(ActiveUsersList);
