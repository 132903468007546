export class TooltipOptions {
  text;
  position;
  id;
  /** Indicates whether to use a custom tooltip component or the browser based `title` attribute */
  useDefault;
  /* this is going to receive a string with a new event to active the tooltip  with an event other than hover */
  event;

  constructor({ text, position, id, useDefault = false, event }) {
    this.text = text;
    this.position = position;
    this.id = id;
    this.useDefault = useDefault;
    this.event = event;
  }
}
