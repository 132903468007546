import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentCSSSelector from 'models/data/content-css-selector-model';

const StatementContentSearchHighlightSelectorStyle = ({
  contentHighlightSelector,
}) => {
  if (contentHighlightSelector.hasValidSelector()) {
    /* ensures content that matches the selector can be highlighted
     E.g. when a user searches in the content tab of statement navigator and wants
     to navigate to the search result */
    const dynamicStyle = React.createElement(
      'style',
      null,
      !contentHighlightSelector.isElementHighlight
        ? `${contentHighlightSelector.fullSelctor}  { background-color: #FFFF3B !important; }`
        : null,
      !contentHighlightSelector.isElementHighlight
        ? `${contentHighlightSelector.fullSelctor} > * { background-color: #FFFF3B !important; }`
        : null,
      !contentHighlightSelector.isElementHighlight
        ? `${contentHighlightSelector.fullSelctor}:hover { background-color: #f1f1f1 !important; }`
        : null,
      !contentHighlightSelector.isElementHighlight
        ? `${contentHighlightSelector.fullSelctor}:hover > * { background-color: #f1f1f1 !important; }`
        : null,
      `${contentHighlightSelector.fullSelctor} > .content-search-highlight { background-color: #ffcd00 !important; }`,
    );
    return dynamicStyle;
  }
  return null;
};

StatementContentSearchHighlightSelectorStyle.propTypes = {
  /** String query selector for normal html content that needs to be highlighted */
  contentHighlightSelector: PropTypes.instanceOf(ContentCSSSelector).isRequired,
};

const mapStateToProps = ({
  ui: {
    statementPage: { contentHighlightSelector },
  },
}) => ({ contentHighlightSelector });

const mapDispatchToProps = {};
export { StatementContentSearchHighlightSelectorStyle };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(StatementContentSearchHighlightSelectorStyle));
