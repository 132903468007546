import { createAction } from 'redux-actions';
import { LEFT_PANELS, RIGHT_PANELS } from 'constants/feature/panel-constants';

export const hideRightPanelAction = createAction('HIDE_RIGHT_PANEL');
export const hideLeftPanelAction = createAction('HIDE_LEFT_PANEL');

export const showElementPanelAction = createAction('SHOW_ELEMENT_PANEL');
export const hideElementPanelAction = createAction('HIDE_ELEMENT_PANEL');

export const showNotesPanelAction = createAction('SHOW_NOTES_PANEL');
export const hideNotesPanelAction = createAction('HIDE_NOTES_PANEL');

export const showBatchPanelAction = createAction('SHOW_BATCH_PANEL');
export const hideBatchPanelAction = createAction('HIDE_BATCH_PANEL');

export const showSectionDetailsPanelAction = createAction(
  'SHOW_SECTION_DETAILS_PANEL',
);
export const hideSectionDetailsPanelAction = createAction(
  'HIDE_SECTION_DETAILS_PANEL',
);

export const showStatementNavPanelAction = createAction(
  'SHOW_STATEMENT_NAV_PANEL',
);
export const hideStatementNavPanelAction = createAction(
  'HIDE_STATEMENT_NAV_PANEL',
);

export const showTickmarkPanelAction = createAction('SHOW_TICKMARK_PANEL');
export const hideTickmarkPanelAction = createAction('HIDE_TICKMARK_PANEL');

export const showComparePanelAction = createAction('SHOW_COMPARE_PANEL');
export const hideComparePanelAction = createAction('HIDE_COMPARE_PANEL');

export const showExportPanelAction = createAction('SHOW_EXPORT_PANEL');
export const showCustomMenuPanelAction = createAction('SHOW_CUSTOM_MENU_PANEL');
export const hideCustomMenuPanelAction = createAction('HIDE_CUSTOM_MENU_PANEL');

export const hideExportPanelAction = createAction('HIDE_EXPORT_PANEL');

export const showWorkPaperPanelAction = createAction('SHOW_WORK_PAPER_PANEL');
export const hideWorkPaperPanelAction = createAction('HIDE_WORK_PAPER_PANEL');

export const showAuditChecklistPanelAction = createAction(
  'SHOW_AUDIT_CHECKLIST_PANEL',
);
export const hideAuditChecklistPanelAction = createAction(
  'HIDE_AUDIT_CHECKLIST_PANEL',
);

export const showComfortAnnotationPanelAction = createAction(
  'SHOW_COMFORT_ANNOTATION_PANEL',
);
export const hideComfortAnnotationPanelAction = createAction(
  'HIDE_COMFORT_ANNOTATION_PANEL',
);

export const toggleWorkPaperPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.WORKPAPER) {
    dispatch(hideWorkPaperPanelAction());
  } else {
    dispatch(showWorkPaperPanelAction());
  }
};

export const toggleExportPanel = (subTab = LEFT_PANELS.EXPORT) => (
  dispatch,
  getState,
) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.EXPORT || left === LEFT_PANELS.DOWNLOAD) {
    dispatch(hideExportPanelAction());
  } else {
    dispatch(showExportPanelAction(subTab));
  }
};

// This has to be invoked when export panel is already open
export const toggleSubPanelWithinExport = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left !== LEFT_PANELS.EXPORT && left !== LEFT_PANELS.DOWNLOAD) {
    dispatch(toggleExportPanel());
  } else {
    left === LEFT_PANELS.EXPORT
      ? dispatch(showExportPanelAction(LEFT_PANELS.DOWNLOAD))
      : dispatch(showExportPanelAction(LEFT_PANELS.EXPORT));
  }
};

export const toggleNotesPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { right },
      },
    },
  } = getState();
  if (right === RIGHT_PANELS.NOTES) {
    dispatch(hideNotesPanelAction());
  } else {
    dispatch(showNotesPanelAction());
  }
};

export const toggleTickmarkPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.TICKMARK) {
    dispatch(hideTickmarkPanelAction());
  } else {
    dispatch(showTickmarkPanelAction());
  }
};

export const toggleComparePanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.COMPARE) {
    dispatch(hideComparePanelAction());
  } else {
    dispatch(showComparePanelAction());
  }
};

export const toggleStatementNavPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.STATEMENT_NAV) {
    dispatch(hideStatementNavPanelAction());
  } else {
    dispatch(showStatementNavPanelAction());
  }
};

export const selectStatementNavPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.STATEMENT_NAV) {
    dispatch(hideStatementNavPanelAction());
  } else {
    dispatch(showStatementNavPanelAction());
  }
};

export const toggleCustomMenuPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();

  if (left === LEFT_PANELS.CUSTOM) {
    dispatch(hideCustomMenuPanelAction());
  } else {
    dispatch(showCustomMenuPanelAction());
  }
};

export const toggleAuditChecklistPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.AUDIT_CHECKLIST) {
    dispatch(hideAuditChecklistPanelAction());
  } else {
    dispatch(showAuditChecklistPanelAction());
  }
};

export const toggleComfortAnnotationPanel = () => (dispatch, getState) => {
  const {
    ui: {
      statementPage: {
        panels: { left },
      },
    },
  } = getState();
  if (left === LEFT_PANELS.COMFORT_ANNOTATION) {
    dispatch(hideComfortAnnotationPanelAction());
  } else {
    dispatch(showComfortAnnotationPanelAction());
  }
};
