import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import ContentSearchResultEntry from 'models/data/content-search-result-entry-model';

const DEFAULT_SELECTED_INDEX = 0;

export default class ContentSearchResults extends ApiModel({
  data: {
    occurrences: [],
    total: null,
  },
  selectedIndex: DEFAULT_SELECTED_INDEX,
}) {
  get occurrences() {
    if (this.hasOccurrences()) {
      return this.data.occurrences;
    }
    return [];
  }

  get totalLoadedResults() {
    return this.data.occurrences.length;
  }

  get totalOverallResults() {
    if (!isNullOrUndefined(this.data.total)) {
      return this.data.total;
    }
    return 0;
  }

  get hasAllResults() {
    return this.totalOverallResults === this.totalLoadedResults;
  }

  processResponse({ response }) {
    const { occurrences, countResultsFound } = response.data;
    return {
      data: {
        occurrences: occurrences.map((o) => new ContentSearchResultEntry(o)),
        total: countResultsFound,
      },
      selectedIndex: DEFAULT_SELECTED_INDEX,
    };
  }

  hasOccurrences() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.occurrences) &&
      this.data.occurrences.length > 0
    );
  }

  setSelectedIndex(index) {
    return this.merge({ selectedIndex: index });
  }
}
