import { RadioOption } from 'models/utils/common/radio-options-model';

export const UNSELECTED_FILTER = undefined;
export const IS_MENTIONED_FILTER = 'is_mentioned';
export const OBSERVER_TYPE_FILTER = 'observer_type_filter';

const NOTES_STATUS_OPTION = {
  open: new RadioOption({
    id: 'notes-filter-option-status-open-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.status.open',
    value: true,
  }),
  closed: new RadioOption({
    id: 'notes-filter-option-status-closed-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.status.closed',
    value: false,
  }),
  all: new RadioOption({
    id: 'notes-filter-option-all-closed-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.status.all',
    value: UNSELECTED_FILTER,
  }),
};

export const NOTES_STATUS_OPTION_ARRAY = Object.values(NOTES_STATUS_OPTION);

const NOTES_TYPE_OPTION = {
  review: new RadioOption({
    id: 'notes-filter-option-type-review-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.type.review',
    value: true,
  }),
  client: new RadioOption({
    id: 'notes-filter-option-type-client-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.type.client',
    value: false,
  }),
  observer: new RadioOption({
    id: 'notes-filter-option-type-observer-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.type.observer',
    value: OBSERVER_TYPE_FILTER,
  }),
  all: new RadioOption({
    id: 'notes-filter-option-type-all-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.type.all',
    value: UNSELECTED_FILTER,
  }),
};

export const NOTES_TYPE_OPTION_ARRAY = Object.values(NOTES_TYPE_OPTION);

const NOTES_PRIORITY_OPTION = {
  priority: new RadioOption({
    id: 'notes-filter-option-priority-priority-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.priority.priority',
    value: true,
  }),
  non_priority: new RadioOption({
    id: 'notes-filter-option-priority-non-priority-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.priority.non-priority',
    value: false,
  }),
  all: new RadioOption({
    id: 'notes-filter-option-priority-all-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.priority.all',
    value: UNSELECTED_FILTER,
  }),
};

export const NOTES_PRIORITY_OPTION_ARRAY = Object.values(NOTES_PRIORITY_OPTION);

const NOTES_GHOST_OPTION = {
  yes: new RadioOption({
    id: 'notes-filter-option-ghost-yes-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.ghost.yes',
    value: true,
  }),
  no: new RadioOption({
    id: 'notes-filter-option-ghost-no-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.ghost.no',
    value: false,
  }),
  all: new RadioOption({
    id: 'notes-filter-option-ghost-all-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.ghost.all',
    value: UNSELECTED_FILTER,
  }),
};

export const NOTES_GHOST_OPTION_ARRAY = Object.values(NOTES_GHOST_OPTION);

const NOTES_USER_OPTION = {
  created_by: new RadioOption({
    id: 'notes-filter-option-user-created-by-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.user.created-by',
    value: true,
  }),
  reviewed_by: new RadioOption({
    id: 'notes-filter-option-user-reviewed-by-id',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.user.replied-by',
    value: false,
  }),
  is_mentioned: new RadioOption({
    id: 'notes-filter-option-user-is-mentioned',
    label:
      'notes-panel.advanced-filter-modal.col-1.filter-option.user.is-tagged',
    value: IS_MENTIONED_FILTER,
  }),
  both: new RadioOption({
    id: 'notes-filter-option-user-both-id',
    label: 'notes-panel.advanced-filter-modal.col-1.filter-option.user.all',
    value: UNSELECTED_FILTER,
  }),
};

export const NOTES_USER_OPTION_ARRAY = Object.values(NOTES_USER_OPTION);

export const FILTER_CATEGORIES = {
  HEADINGS: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.headings',
    name: 'notes-filters-headings',
    id: 'notes-filters-headings-id',
  },
  STATUS: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.status',
    name: 'notes-filters-status',
    id: 'notes-filters-status-id',
    options: NOTES_STATUS_OPTION_ARRAY,
  },
  TYPE: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.type',
    name: 'notes-filters-type',
    id: 'notes-filters-type-id',
    options: NOTES_TYPE_OPTION_ARRAY,
  },
  PRIORITY: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.priority',
    name: 'notes-filters-priority',
    id: 'notes-filters-priority-id',
    options: NOTES_PRIORITY_OPTION_ARRAY,
  },
  GHOST: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.ghost',
    name: 'notes-filters-ghost',
    id: 'notes-filters-ghost-id',
    options: NOTES_GHOST_OPTION_ARRAY,
  },
  USER: {
    heading: 'notes-panel.advanced-filter-modal.col-1.heading.user',
    name: 'notes-filters-user',
    id: 'notes-filters-user-id',
    options: NOTES_USER_OPTION_ARRAY,
  },
};
