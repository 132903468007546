import BaseModel from 'models/base-model';
import NotesBySectionMap from 'models/api/notes-by-section-map';
import { isNullOrUndefined } from 'utils/object-utils';
import cloneDeep from 'lodash.clonedeep';
import Note from 'models/data/note-model';

export default class NoteCache extends BaseModel({
  /* 
  { 
    [sectionId]: NotesBySectionMap,
    ...
  }
*/
}) {
  /**
   * Reducer function for setting error on specific sections when their request fail
   * @param {int} param.sectionId
   * @param {object} param.error
   */
  setSectionError({ sectionId, error }) {
    let errorSection;
    if (this.hasSection(sectionId)) {
      errorSection = this.getSection(sectionId).setError(error);
    } else {
      errorSection = new NotesBySectionMap().setError(error);
    }
    return this.merge({
      [sectionId]: errorSection,
    });
  }

  /**
   * Reducer function for setting loading on specific sections
   * @param {int} param.sectionId
   */
  setSectionLoading({ sectionId }) {
    let loadingSection;
    if (this.hasSection(sectionId)) {
      loadingSection = this.getSection(sectionId).setLoading();
    } else {
      loadingSection = new NotesBySectionMap().setLoading();
    }
    return this.merge({
      [sectionId]: loadingSection,
    });
  }

  /**
   * Reducer function for setting the note data by section id
   * @param {int} param.sectionId
   * @param {object} param.response
   */
  setSectionLoaded({ response, sectionId }) {
    let loadedSection;
    if (this.hasSection(sectionId)) {
      loadedSection = this.getSection(sectionId).setLoaded({
        response,
        sectionId,
      });
    } else {
      loadedSection = new NotesBySectionMap().setLoaded({
        response,
        sectionId,
      });
    }

    return this.merge({
      [sectionId]: loadedSection,
    });
  }

  /**
   * Reducer function for clearing section note data
   * @param {int[]} param.removedSectionIds
   */
  clearSections({ removedSectionIds }) {
    const newMap = { ...this };
    removedSectionIds.forEach((id) => {
      delete newMap[id];
    });

    // cannot use `this.merge` because we are REMOVING ids from the map
    // merge would not remove the ids we want to remove
    return new NoteCache(newMap);
  }

  hasSection(sectionId) {
    const sectionMap = this.getSection(sectionId);
    return !isNullOrUndefined(sectionMap);
  }

  getSection(sectionId) {
    return this[sectionId];
  }

  getPseudoNote({ sectionId, pseudoElementId }) {
    if (this.hasSection(sectionId)) {
      return this.getSection(sectionId).getPseudoNote(pseudoElementId);
    }
    return null;
  }

  removeNoteFromCacheFromSocketPayload(payload) {
    const { pseudoElementId, sectionId } = payload;
    const noteCacheCopy = cloneDeep(this);

    if (
      noteCacheCopy[sectionId] &&
      noteCacheCopy[sectionId].data.pseudoNotes[pseudoElementId]
    ) {
      delete noteCacheCopy[sectionId].data.pseudoNotes[pseudoElementId];
    }

    return this.merge({ [sectionId]: noteCacheCopy[sectionId] });
  }

  updateNoteFromCacheFromSocketpayload(payload) {
    const { pseudoElementId, sectionId } = payload;
    const noteCacheCopy = cloneDeep(this);

    if (
      noteCacheCopy[sectionId] &&
      noteCacheCopy[sectionId].data.pseudoNotes[pseudoElementId]
    ) {
      let note = noteCacheCopy[sectionId].data.pseudoNotes[pseudoElementId];
      note = {
        ...note,
        elementId: payload.elementId,
        noteType: payload.noteType,
        workflowStatus: payload.workflowStatus,
        closedBy: payload.closedBy,
        closedByName: payload.closedByName,
        body: payload.body,
        noteDate: payload.noteDate,
        closedDate: payload.closedDate,
        noteTaker: payload.noteTaker,
        noteTakerName: payload.noteTakerName,
        notePriorityType: payload.notePriorityType,
        revisionNumber: payload.revisionNumber,
      };
      noteCacheCopy[sectionId].data.pseudoNotes[pseudoElementId] = new Note(
        note,
      );
    }

    return this.merge({ [sectionId]: noteCacheCopy[sectionId] });
  }
}
