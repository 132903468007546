import { createAction } from 'redux-actions';
import { setSelectedProjectFromProjectList } from './selected-project-actions';
import { push } from 'connected-react-router';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import {
  linkOmniaEngagementRequest,
  unlinkOmniaEngagementRequest,
  verifyOmniaTokenRequest,
} from 'api/omnia-integration-api';
import { getEngagementsByIdRequest } from 'api/omnia-integration-api';
import { isNullOrUndefined } from 'utils/object-utils';
import { jwtDecode } from 'jwt-decode';

export const linkOmniaEngagementLoading = createAction(
  'LINK_OMNIA_ENGAGEMENT_LOADING',
);
export const linkOmniaEngagementLoaded = createAction(
  'LINK_OMNIA_ENGAGEMENT_LOADED',
);
export const linkOmniaEngagementError = createAction(
  'LINK_OMNIA_ENGAGEMENT_ERROR',
);

export const omniaEngagementLoading = createAction('OMNIA_ENGAGEMENT_LOADING');
export const omniaEngagementLoaded = createAction('OMNIA_ENGAGEMENT_LOADED');
export const omniaEngagementError = createAction('OMNIA_ENGAGEMENT_ERROR');

export const handleProjectClick =
  ({ projectId }) =>
  async (dispatch, getState) => {
    const storeData = getState().data;
    const projectList = storeData.projectList.getProjects();
    const selectedProject = projectList.find(
      (project) => project.id === projectId,
    );
    dispatch(
      setSelectedProjectFromProjectList({ projectListEntry: selectedProject }),
    );
    //Navigate to the home page
    dispatch(push(ROUTE_CONSTANTS.HOME_PAGE));
  };

export const handleOmniaEngagementLinking =
  ({ engagement, omEngagementId, geoCode }) =>
  async (dispatch, getState) => {
    dispatch(linkOmniaEngagementLoading());
    try {
      const { currentUser } = getState().data;
      await linkOmniaEngagementRequest({
        omniaTieoutEngagementEntity: {
          linkedBy: currentUser.getUser().id,
          linkedDate: Date.now(),
          omEngagementId: omEngagementId,
          toutProjectId: parseInt(engagement.toutProjectId),
        },
        geoCode,
      });
      dispatch(
        fetchOmniaEngagement({
          engagementId: omEngagementId,
          geoCode,
        }),
      );
    } catch (error) {
      dispatch(linkOmniaEngagementError(error));
    }
  };

export const handleOmniaEngagementUnlinking =
  ({ engagementToUnlink, geoCode }) =>
  async (dispatch, getState) => {
    dispatch(linkOmniaEngagementLoading());
    try {
      const { currentUser } = getState().data;
      await unlinkOmniaEngagementRequest({
        omniaTieoutEngagementEntity: {
          linkedBy: currentUser.getUser().id,
          linkedDate: Date.now(),
          omEngagementId: engagementToUnlink.omEngagementId,
          toutProjectId: parseInt(engagementToUnlink.toutProjectId),
        },
        geoCode,
      });

      const sessionOmEngagementId = sessionStorage.getItem('omEngagementId');
      let omniaEngagementId = !isNullOrUndefined(sessionOmEngagementId)
        ? sessionOmEngagementId
        : null;
      dispatch(
        fetchOmniaEngagement({
          engagementId: omniaEngagementId,
          geoCode,
        }),
      );
    } catch (error) {
      dispatch(linkOmniaEngagementError(error));
    }
  };

export const verifyOmniaTokenAndGetEngagement =
  ({ token }) =>
  async (dispatch, getState) => {
    dispatch(omniaEngagementLoading());
    try {
      const decodedOmniaToken = jwtDecode(token);
      const response = await verifyOmniaTokenRequest({
        token,
        geoCode: decodedOmniaToken.geoName,
      });
      dispatch(omniaEngagementLoaded({ response }));
    } catch (error) {
      dispatch(omniaEngagementError(error));
    }
  };

export const fetchOmniaEngagement =
  ({ engagementId, geoCode }) =>
  async (dispatch, getState) => {
    dispatch(omniaEngagementLoading());
    try {
      const response = await getEngagementsByIdRequest({
        engagementId,
        geoCode,
      });
      dispatch(omniaEngagementLoaded({ response }));
    } catch (error) {
      dispatch(omniaEngagementError(error));
    }
  };
