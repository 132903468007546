import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ConditionalRender from 'components/util/conditional-render-component';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import TextInput from 'components/common/text-input-component';
import TextArea from 'components/common/text-area-component';
import WorkpaperForm from 'models/form/workpaper-form-model';
import {
  WPR_NUMBER_CHAR_LIMIT,
  WPR_NAME_CHAR_LIMIT,
} from 'constants/feature/workpaper-panel-constants';
import ElementDetails from 'models/api/element-details-api-model';
import { checkIfStringContainsOnlySpaces } from 'constants/common/feature-common-utils';

const CREATE_WORKPAPER_BLOCK = 'create-workpaper-modal';
const CREATE_WORKPAPER_ID_BLOCK = 'create-workpaper-modal-id';

class CreateWorkpaperModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workpaperInProgress: new WorkpaperForm(),
      visitedReferenceName: false,
      visitedReferenceNumber: false,
    };
  }

  componentDidMount() {
    const { elementDetails } = this.props;
    this.setState((state) => ({
      workpaperInProgress: state.workpaperInProgress.initFromElement({
        elementDetails,
      }),
    }));
  }

  _handleCreateClick = async () => {
    const { onCreate } = this.props;
    const { workpaperInProgress } = this.state;
    try {
      this.setState((state) => ({
        workpaperInProgress: state.workpaperInProgress.setLoading(),
      }));
      await onCreate({
        workpaperModel: workpaperInProgress.trimmedWorkpaperReference(),
      });
    } catch (error) {
      if (this._isRequestErrorForDuplicate(error)) {
        this.setState((state) => ({
          workpaperInProgress: state.workpaperInProgress.setDuplicateErrorLoaded(
            {
              duplicate: workpaperInProgress.referenceNumber,
            },
          ),
        }));
      } else {
        this.setState((state) => ({
          workpaperInProgress: state.workpaperInProgress.setError(error),
        }));
      }
    }
  };

  _isRequestErrorForDuplicate = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause.includes(
      'This work paper reference number already exists', // distinct error message from API
    );

  _referenceNumberErrorText = () => {
    const { workpaperInProgress } = this.state;
    if (workpaperInProgress.isReferenceNumberDuplicate()) {
      return 'create-workpaper-modal.form.reference-number.error.duplicate';
    } else if (workpaperInProgress.doesRefNumberHaveIllegalChars()) {
      return 'create-workpaper-modal.form.reference-number.error.char-exclude';
    }
    return {
      id: 'create-workpaper-modal.form.reference-number.error.char-count',
      values: { limit: WPR_NUMBER_CHAR_LIMIT },
    };
  };

  render() {
    const { onClose } = this.props;
    const {
      workpaperInProgress,
      visitedReferenceName,
      visitedReferenceNumber,
    } = this.state;
    return (
      <Modal
        id={CREATE_WORKPAPER_ID_BLOCK}
        title={'create-workpaper-modal.title'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.create',
            onClick: this._handleCreateClick,
            disabled:
              !workpaperInProgress.isWorkpaperValid() ||
              checkIfStringContainsOnlySpaces(
                workpaperInProgress.referenceName,
              ) ||
              checkIfStringContainsOnlySpaces(
                workpaperInProgress.referenceNumber,
              ),
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
      >
        <ConditionalRender dependencies={[workpaperInProgress]}>
          <p className={`${CREATE_WORKPAPER_BLOCK}__instructions`}>
            <FormattedMessage id="create-workpaper-modal.instructions" />
          </p>

          <div className={`${CREATE_WORKPAPER_BLOCK}__reference-number`}>
            <TextInput
              id={`${CREATE_WORKPAPER_ID_BLOCK}-reference-number`}
              label={'create-workpaper-modal.form.reference-number.label'}
              placeholder={
                'create-workpaper-modal.form.reference-number.placeholder'
              }
              onBlur={() =>
                this.setState({
                  visitedReferenceNumber: true,
                })
              }
              width={'100%'}
              onChange={(val) => {
                this.setState((state) => ({
                  workpaperInProgress: state.workpaperInProgress.setReferenceNumber(
                    val,
                  ),
                }));
              }}
              isValid={
                !workpaperInProgress.isReferenceNumberDuplicate() &&
                (!visitedReferenceNumber ||
                  workpaperInProgress.isReferenceNumberValid() ||
                  checkIfStringContainsOnlySpaces(
                    workpaperInProgress.referenceNumber,
                  ))
              }
              errorText={this._referenceNumberErrorText()}
              type={'text'}
              value={workpaperInProgress.referenceNumber}
            />
          </div>
          <div className={`${CREATE_WORKPAPER_BLOCK}__reference-number`}>
            <TextArea
              errorText={{
                id:
                  'create-workpaper-modal.form.reference-name.error.char-count',
                values: { limit: WPR_NAME_CHAR_LIMIT },
              }}
              id={`${CREATE_WORKPAPER_ID_BLOCK}-reference-name`}
              isValid={
                !visitedReferenceName ||
                workpaperInProgress.isReferenceNameValid() ||
                checkIfStringContainsOnlySpaces(
                  workpaperInProgress.referenceName,
                )
              }
              label="create-workpaper-modal.form.reference-name.label"
              onChange={(val) => {
                this.setState((state) => ({
                  workpaperInProgress: state.workpaperInProgress.setReferenceName(
                    val,
                  ),
                }));
              }}
              onBlur={() =>
                this.setState({
                  visitedReferenceName: true,
                })
              }
              placeholder="create-workpaper-modal.form.reference-name.placeholder"
              value={workpaperInProgress.referenceName}
              width={'100%'}
            />
          </div>
        </ConditionalRender>
      </Modal>
    );
  }
}

CreateWorkpaperModal.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** func fired to close modal */
  onClose: PropTypes.func.isRequired,
  /** func fired on create click */
  onCreate: PropTypes.func.isRequired,
};

export default CreateWorkpaperModal;
