import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { connect } from 'react-redux';
import { clearApiErrorDataAction } from 'store/actions/api-error-handler-actions';
import { toast } from 'react-toastify';
import { clearAuthData } from 'api/api-authentication';

const TIMEOUT_PAGE_BLOCK = 'timeout-page';
const TIMEOUT_PAGE_ID_BLOCK = 'timeout-page-id';

const TimeoutPage = ({ push, clearApiErrorDataAction }) => {
  useEffect(() => {
    toast.dismiss();
    clearApiErrorDataAction();
  }, [clearApiErrorDataAction]);

  return (
    <div className={TIMEOUT_PAGE_BLOCK}>
      <div className={`${TIMEOUT_PAGE_BLOCK}__header`}>
        <FormattedMessage id={'session-timeout-page.header'} />
      </div>
      <FormattedMessage id={'session-timeout-page.message'} />
      <Button
        id={TIMEOUT_PAGE_ID_BLOCK}
        className={`${TIMEOUT_PAGE_BLOCK}__button`}
        onClick={() => {
          clearAuthData();
          push(ROUTE_CONSTANTS.HOME_PAGE);
        }}
        type={BUTTON_TYPES.primary}
      >
        <FormattedMessage id={'session-timeout-page.refresh'} />
      </Button>
    </div>
  );
};

TimeoutPage.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** Action that clears error data */
  clearApiErrorDataAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  push,
  clearApiErrorDataAction,
};

export { TimeoutPage, TIMEOUT_PAGE_BLOCK };
export default connect(null, mapDispatchToProps)(TimeoutPage);
