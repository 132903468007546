import BaseModel from 'models/base-model';

export default class Tickmark extends BaseModel({
  revisionId: null,
  elementId: null,
  tickmarkId: null,
  ordinal: null,
  noteOrdinal: '',
  richText: '',
  text: '',
  elementCount: 0,
}) {}
