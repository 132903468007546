import React, { memo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as Cross } from 'icons/cross.svg';
import Dropdown from 'components/common/dropdown-component';
import { FORMULA_ROW_OPERATOR_OPTIONS } from 'constants/feature/formula-constants';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';

const FORMULA_OPERATOR_BLOCK = 'formula-operator';
const FORMULA_OPERATOR_ID_BLOCK = 'formula-operator-id';

const ICON_SIZE = '18px';
const FormulaOperator = ({ disableRemove, onSelect, onRemove, operator }) => {
  const [operatorHovered, setOperatorHovered] = useState(false);
  return (
    <div
      className={`${FORMULA_OPERATOR_BLOCK}`}
      onMouseEnter={() => setOperatorHovered(true)}
      onMouseLeave={() => setOperatorHovered(false)}
      onMouseOver={() => {
        // if another operator is deleted the rest are shifted up
        // and the mouseEnter event doesn't fire, but we should still
        // trigger the hover state
        // mouseOver fires continuously, so we only want to set state if it isn't already set
        if (!operatorHovered) {
          setOperatorHovered(true);
        }
      }}
    >
      <Cross
        className={classnames(
          `${FORMULA_OPERATOR_BLOCK}__remove`,
          operatorHovered
            ? `${FORMULA_OPERATOR_BLOCK}__remove--show`
            : `${FORMULA_OPERATOR_BLOCK}__remove--hidden`
        )}
        height={ICON_SIZE}
        width={ICON_SIZE}
        disabled={disableRemove}
        onClick={!disableRemove ? onRemove : () => {}}
        role="button"
      />
      <Dropdown
        id={`${FORMULA_OPERATOR_ID_BLOCK}-operator`}
        className={`${FORMULA_OPERATOR_BLOCK}__operator`}
        onSelectOption={onSelect}
        isValid={true}
        options={FORMULA_ROW_OPERATOR_OPTIONS}
        value={operator}
        isNotIntl={true}
        hideDropDownArrow={true}
        hideCheckmarks={true}
        centerMenuDisplay={true}
      />
    </div>
  );
};

FormulaOperator.propTypes = {
  /** Indicates if remove X button should be disabled */
  disableRemove: PropTypes.bool,
  /** Function fired when a operator is selected */
  onSelect: PropTypes.func.isRequired,
  /** Function fired when a operator is removed using the X button */
  onRemove: PropTypes.func.isRequired,
  /** Value of selected operator */
  operator: PropTypes.instanceOf(MenuOption).isRequired,
};

export default memo(FormulaOperator);
