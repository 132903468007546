import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';

const SESSION_TIMEOUT_MODAL_ID = 'session-timeout-modal-id';
export const SESSION_TIMEOUT_MODAL = 'session-timeout-modal';

const SessionTimeoutModal = ({ onClickCancel, timer }) => {
  return (
    <Modal
      id={SESSION_TIMEOUT_MODAL_ID}
      title={'session-timeout.inactivity-notice'}
      onClose={onClickCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.continue',
          onClick: () => onClickCancel(),
        })
      }
    >
      <FormattedMessage id={'session-timeout.message'} values={{ timer }} />
    </Modal>
  );
};

SessionTimeoutModal.propTypes = {
  /** stop watch timer */
  timer: PropTypes.string.isRequired,
  /** Action fired when user clicks No || close button */
  onClickCancel: PropTypes.func.isRequired,
};

export default SessionTimeoutModal;
