import { handleActions } from 'redux-actions';

import SourceStatementParams from 'models/api/source-statement-model';
import {
  clearSourceStatementParams,
  setSourceStatementParams,
} from 'store/actions/source-statement-actions';

export default handleActions(
  {
    [setSourceStatementParams]: (sourceStatementParams, { payload }) => {
      return sourceStatementParams.merge(payload);
    },
    [clearSourceStatementParams]: (sourceStatementParams) => {
      return new SourceStatementParams();
    },
  },
  new SourceStatementParams(),
);
