import ApiModel from 'models/api-model';
import Tickmark from 'models/data/tickmark-model';
import clonedeep from 'lodash.clonedeep';

export default class ElementTickmarkList extends ApiModel({
  data: {
    tickmarks: [],
  },
}) {
  get tickmarks() {
    if (this.data) {
      return this.data.tickmarks;
    }
    return [];
  }
  processResponse({ response }) {
    return {
      data: {
        tickmarks: response.data.result.map(
          (tickmark) => new Tickmark(tickmark),
        ),
      },
    };
  }

  getTickmarkCount() {
    if (this.hasTickmarks()) {
      return this.data.tickmarks.length;
    }
    return 0;
  }

  getTickmarksSortedAlphabetically() {
    if (this.data) {
      return this.data.tickmarks.sort((a, b) =>
        a.noteOrdinal < b.noteOrdinal ? -1 : 1,
      );
    }
    return [];
  }

  get(index) {
    if (
      this.data &&
      this.data.tickmarks &&
      index >= 0 &&
      index < this.data.tickmarks.length
    ) {
      return this.data.tickmarks[index];
    }
    return null;
  }

  hasTickmarks() {
    return this.data && this.data.tickmarks && this.data.tickmarks.length > 0;
  }

  removeTickmarkFromElementPanelWithSocketPayload(payload) {
    let tickmarksCopy = [...this.data.tickmarks];
    tickmarksCopy = tickmarksCopy.filter(
      (tickmark) => tickmark.tickmarkId !== payload.tickmarkRefId,
    );
    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
    });
  }
  /** The payload will contain a set of tickmarkIds which has to be deleted
   * Example : [123,321]
   */
  removeTickmarkWithSocketPayloadInBulk(payload) {
    let tickmarksCopy = [...this.data.tickmarks];
    tickmarksCopy = tickmarksCopy.filter(
      (tickmark) => !payload.has(tickmark.tickmarkId),
    );
    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
    });
  }

  updateTickmarkInElementPanelWithSocketPayload(payload) {
    let tickmarksCopy = [...this.data.tickmarks];
    for (
      let currentTickmarkIndex = 0;
      currentTickmarkIndex < tickmarksCopy.length;
      currentTickmarkIndex++
    ) {
      if (
        payload.tickmarkRefId ===
          tickmarksCopy[currentTickmarkIndex].tickmarkId &&
        tickmarksCopy[currentTickmarkIndex].revisionId === payload.revisionId
      ) {
        tickmarksCopy[currentTickmarkIndex].text = payload.text;
        tickmarksCopy[currentTickmarkIndex].richText = payload.richText;
      }
    }
    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
    });
  }
  /** The elementId parameter is an elementId for the element for which element panel is open
   * If element panel is closed then this function will not be called.
   * NOTE :: This particular function is not for updating the particular tickmark unlike the
   * already existing funtion updateTickmarkInElementPanelWithSocketPayload, instead it is for
   * inserting the entire new tickmark object into an array.
   */
  setTickmarkListFromWebSocketPayload({ webSocketPayload, elementId }) {
    const tickmarksCopy = clonedeep(this.tickmarks);
    const tickmarksMap = tickmarksCopy.reduce((elementMap, elementData) => {
      elementMap[elementData.tickmarkId] = elementData;
      return elementMap;
    }, {});

    webSocketPayload.forEach((tickmark) => {
      /**If webSocketPayload has an update for element(opened in element panel) and
       * tickmarkId in webSocketPayload does not already exists in list then update the list
       * with this new tickmark data in webSocketPayload
       */
      if (
        tickmark.elementId === elementId &&
        !(tickmark.tickmarkId in tickmarksMap)
      ) {
        tickmarksCopy.push(tickmark);
      }
    });
    tickmarksCopy.sort((a, b) => a.ordinal - b.ordinal);
    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
    });
  }
}
