import { checkIfStringContainsOnlySpaces } from 'constants/common/feature-common-utils';
import {
  COMFORT_LETTER_CUSTOM_LABEL_CHAR_LIMIT,
  COMFORT_LETTER_DESCRIPTION_CHAR_LIMIT,
} from 'constants/feature/comfort-letter-constants';
import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import htmlToText from 'html-to-text';

export default class ComfortLetterForm extends ApiModel({
  data: {
    revisionId: null,
    elementId: null,
    customLabel: '',
    description: '',
    descriptionRichText: '',
    comfortLetterCreatedBy: null,
    comfortLetterCreatedDate: null,
    elementComfortLetterCreatedBy: null,
    elementComfortLetterCreatedDate: null,
    comfortLetterId: null,
  },
  errorDuplicateDescription: null, // stores the id determined to be duplicate after the last failed request
  errorDuplicateLabel: null,
}) {
  get id() {
    if (this.data) {
      return this.data.comfortLetterId;
    }
    return null;
  }

  get revisionId() {
    if (this.data) {
      return this.data.revisionId;
    }
    return null;
  }
  get elementId() {
    if (this.data) {
      return this.data.elementId;
    }
    return null;
  }
  get customLabel() {
    if (this.data) {
      return this.data.customLabel;
    }
    return '';
  }
  get description() {
    if (this.data) {
      return this.data.description;
    }
    return '';
  }

  get descriptionRichText() {
    if (this.data) {
      return this.data.descriptionRichText;
    }
    return '';
  }

  get comfortLetterId() {
    if (this.data) {
      return this.data.comfortLetterId;
    }
    return null;
  }

  toApiFormat() {
    return {
      elementId: this.elementId,
      revisionId: this.revisionId,
      label: this.customLabel,
      text: this.description,
      richText: this.descriptionRichText,
    };
  }

  toEditApiFormat() {
    return {
      revisionId: this.revisionId,
      comfortLetterId: this.data.comfortLetterId,
      label: this.customLabel,
      text: this.description,
      richText: this.descriptionRichText,
    };
  }

  initFromElement({ elementDetails }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      data: {
        ...this.data,
        revisionId: elementDetails.revisionId,
        elementId: elementDetails.id,
      },
    });
  }

  setDuplicateLabelLoaded({ duplicate }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      errorDuplicateLabel: duplicate,
    });
  }

  setDuplicateDescriptionLoaded({ duplicate }) {
    return this.merge({
      isLoading: false,
      isLoaded: true,
      errorDuplicateDescription: duplicate,
    });
  }

  setCustomLabel(customLabel) {
    return this.merge({
      data: {
        ...this.data,
        customLabel: customLabel.trim(),
      },
    });
  }

  setDescription(description) {
    const text = htmlToText.fromString(description, {
      wordwrap: false, // by default it adds new lines after 80 chars, need to disable to ensure no new characters are added
    });
    // we need to remove ascii codes from strings
    // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-

    //We need to remove new line characters
    //https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
    // text = text.replace(/(\r\n|\n|\r)/gm, '');
    return this.merge({
      data: {
        ...this.data,
        description: text.trim()
          // eslint-disable-next-line no-control-regex
          .replace(/[^\x00-\x7F]/g, '')
          .replace(/(\r\n|\n|\r)/gm, ''),

        descriptionRichText: description,
      },
    });
  }

  isCustomLabelTooLong() {
    return (
      !isNullOrUndefined(this.data.customLabel) &&
      this.data.customLabel.length > COMFORT_LETTER_CUSTOM_LABEL_CHAR_LIMIT
    );
  }

  isCustomLabelTooShort() {
    return (
      !isNullOrUndefined(this.data.customLabel) &&
      (this.data.customLabel.length === 0 ||
        checkIfStringContainsOnlySpaces(this.customLabel))
    );
  }

  isCustomLabelDuplicate() {
    return this.data && !isNullOrUndefined(this.errorDuplicateLabel);
  }

  isDescriptionDuplicate() {
    return this.data && !isNullOrUndefined(this.errorDuplicateDescription);
  }

  isCustomLabelValid() {
    return !this.isCustomLabelDuplicate() && !this.isCustomLabelTooLong();
  }

  isDescriptionTooLong() {
    return (
      !isNullOrUndefined(this.data && this.data.description) &&
      this.data.description.length > COMFORT_LETTER_DESCRIPTION_CHAR_LIMIT
    );
  }

  isDescriptionTooShort() {
    return (
      !isNullOrUndefined(this.data && this.data.description) &&
      this.data.description.length === 0
    );
  }

  isDescriptionValid() {
    return !this.isDescriptionTooLong() && !this.isDescriptionDuplicate();
  }

  isComfortLetterValid() {
    return this.isDescriptionValid() && this.isCustomLabelValid();
  }

  convertApiResponsetoComfortLetterFormModelFormat(apiResponse) {
    return this.merge({
      revisionId: apiResponse.revisionId,
      elementId: apiResponse.elementId,
      customLabel: apiResponse.label,
      description: apiResponse.text,
      descriptionRichText: apiResponse.richText,
      comfortLetterCreatedBy: apiResponse.comfortLetterCreatedBy,
      comfortLetterCreatedDate: apiResponse.comfortLetterCreatedDate,
      elementComfortLetterCreatedBy: apiResponse.elementcomfortLetterCreatedBy,
      elementComfortLetterCreatedDate:
        apiResponse.elementcomfortLetterCreatedDate,
      comfortLetterId: apiResponse.comfortLetterId,
    });
  }
}
