import React from 'react';
import moment from 'moment';
import Permissions from 'permissions/permissions';
import {
  DataGridColumn,
  DataGridDropdownColumn,
  DataGridIconColumn,
} from 'components/common/data-grid/data-grid-component';
import MultilineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { JOB_TYPES } from 'constants/common/job-type-constants';
import { ReactComponent as XIcon } from 'icons/cross.svg';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isNullOrUndefined } from 'utils/object-utils';

export const LEGAL_HOLD_TOOLTIP = new TooltipOptions({
  text: 'project-creation.project-info.legal-hold-verbiage',
  id: 'project-creation-id-tooltip-legal-hold',
  position: 'right',
});

export const USER_SEARCH_COLUMNS = [
  new DataGridColumn({
    key: 'preferredFullName',
    header: 'project-edit.manage-users.user-heading.name',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-fullName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.preferredFullName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'email',
    header: 'project-edit.manage-users.user-heading.email',
    width: '30%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-email-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.email}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'title',
    header: 'project-edit.manage-users.user-heading.title',
    width: '15%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-title-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.title}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'region',
    header: 'project-edit.manage-users.user-heading.region',
    width: '15%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-region-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.region}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'officeDescription',
    header: 'project-edit.manage-users.user-heading.office',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `user-search-office-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.officeDescription}
          isNotInternationalized
        />
      );
    },
  }),
];

export const ENGAGEMENT_SEARCH_COLUMNS = [
  new DataGridColumn({
    key: 'engagementId',
    header: 'project-edit.project-info.engagement-heading.engagement-id',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `engagementId-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.engagementId}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'clientName',
    header: 'project-edit.project-info.engagement-heading.client-name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `clientName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.clientName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'engagementName',
    header: 'project-edit.project-info.engagement-heading.engagement-name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `engagementName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.engagementName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'engagementFye',
    header: 'project-edit.project-info.engagement-heading.fiscal-year',
    width: '25%',
    formatter: (rowData) => {
      return moment(rowData.engagementFye).format(DATE_FORMATS.YEAR);
    },
  }),
];

export const getAddedUserColumns = ({ project, onRemoveClick }) => [
  new DataGridColumn({
    key: 'preferredFullName',
    header: 'project-edit.manage-users.user-heading.name',
    width: '25%',
    formatter: (rowData, { rowKey }) => {
      const colId = `added-users-fullName-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.preferredFullName}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'email',
    header: 'project-edit.manage-users.user-heading.email',
    width: '20%',
    formatter: (rowData, { rowKey }) => {
      const colId = `added-users-email-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.email}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'title',
    header: 'project-edit.manage-users.user-heading.title',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `added-users-title-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.title}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'region',
    header: 'project-edit.manage-users.user-heading.region',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `added-users-region-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.region}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridColumn({
    key: 'officeDescription',
    header: 'project-creation.manage-users.user-heading.office',
    width: '10%',
    formatter: (rowData, { rowKey }) => {
      const colId = `added-users-office-col-${rowKey}`;
      return (
        <MultilineEllipsis
          toolTipID={colId}
          text={rowData.officeDescription}
          isNotInternationalized
        />
      );
    },
  }),
  new DataGridDropdownColumn({
    key: 'jobType',
    header: 'project-edit.manage-users.user-heading.role',
    width: '20%',
    placeholder: 'project-edit.manage-users.role-dropdown.placeholder',
    isValid: (user) => {
      return user.jobType !== '' && !isNullOrUndefined(user.jobType);
    },
    disabled: !Permissions.Project.canManageUsers(project.id),
    options: (user) => {
      const commonRoleOptions = [
        new MenuOption({
          id: `${user.id}-engagement-owner-option`,
          title: 'common.user-roles.engagement-owner',
          value: JOB_TYPES.ENGAGEMENT_OWNER,
        }),
        new MenuOption({
          id: `${user.id}-observer-option`,
          title: 'common.user-roles.observer',
          value: JOB_TYPES.OBSERVER,
        }),
        new MenuOption({
          id: `${user.id}-perparer/reviewer-option`,
          title: 'common.user-roles.preparer-reviewer',
          value: JOB_TYPES.PREPARER_REVIEWER,
        }),
      ];

      /**
       * NOTE:
       * - `isPpmd` is the property populated by the backend when we request
       * users in the search component. So when we select a user from the search
       * it is populated with isPpmd, and we should be able to see the PPMD dropdown option
       * - `ppmd` is the field the backend sends for EXISTING users already added to the project
       * so we need to check for that field when we are displaying users for the first time when
       * we load the project Edit page
       */
      if (user.isPpmd || user.ppmd) {
        return [
          ...commonRoleOptions,
          new MenuOption({
            id: `${user.id}-ppmd-option`,
            title: 'common.user-roles.ppmd',
            value: JOB_TYPES.PPMD,
          }),
        ];
      }
      return commonRoleOptions;
    },
  }),
  new DataGridIconColumn({
    Icon: XIcon,
    iconHeight: '28px',
    iconWidth: '28px',
    onClick: onRemoveClick,
    disabled: !Permissions.Project.canManageUsers(project.id),
  }),
];
