import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as SettingIcon } from 'icons/settings-icon.svg';
import { ReactComponent as ArrowDown } from 'icons/arrow-simple-thick-down.svg';
import Option from 'pages/side-by-side-compare/dropdown/_side-by-side-options';
import useOutsideClick from 'components/hooks/useOutsideClick';

export const SIDE_BY_SIDE_DROPDOWN_BLOCK = 'side-by-side-dropdown';
export const SIDE_BY_SIDE_DROPDOWN_BLOCK_ID = 'side-by-side-dropdown-id';
const ICON_SIZE = 25;

const Dropdown = ({ options, intl, setAnnotationsForMapping }) => {
  const [show, setShow] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownRef] = useOutsideClick(() => show && onClick());

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  const selectedOptions = dropdownOptions
    .filter((item) => item.checked)
    .map((item) => intl.formatMessage({ id: item.name }));

  useEffect(() => {
    //trigger action
    if (selectedOptions.length !== 0) setAnnotationsForMapping(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const onToggle = (event) => {
    const { name, checked } = event.target;
    setDropdownOptions((options) => {
      return options.map((option) => {
        return option.name === name ? { ...option, checked } : option;
      });
    });
  };

  const onClick = () => {
    setShow(!show);
  };

  return (
    <div className={SIDE_BY_SIDE_DROPDOWN_BLOCK} ref={dropdownRef}>
      <div
        className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__dropdown-input`}
        onClick={onClick}
      >
        <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__setting-icon`}>
          <SettingIcon width={ICON_SIZE} height={ICON_SIZE} />
        </div>
        <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__selected-items`}>
          {selectedOptions.length ? (
            selectedOptions.join('; ')
          ) : (
            <FormattedMessage
              id={'side-by-side-view.compare.header.dropdown.placeholder'}
            />
          )}
        </div>
        <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__carret-icon`}>
          <ArrowDown
            name={'arrow-simple-thick-down'}
            width={ICON_SIZE}
            height={ICON_SIZE}
            style={show ? { transform: 'rotate(180deg)' } : null}
          />
        </div>
      </div>
      {show && (
        <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__dropdown-options`}>
          <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__sub-description`}>
            <FormattedMessage
              id={
                'side-by-side-view.compare.header.dropdown.options.description.header.title'
              }
            />
          </div>

          {dropdownOptions.map((option) => (
            <Option key={option.label} option={option} onToggle={onToggle} />
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  /** This is options config which describes the option's shape for dropdown */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** This is label which is visible in dropdown as option */
      label: PropTypes.string.isRequired,
      /** This is checked which describe which option is checked/toggled-on or not */
      checked: PropTypes.bool.isRequired,
      /** This is id which to distinguish options */
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**action to set Annotations of elements that needs to be mapped in side-by-side view page */
  setAnnotationsForMapping: PropTypes.func.isRequired,
};

export default injectIntl(Dropdown);
