import { push } from 'connected-react-router';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { fetchCurrentUser } from 'store/actions/current-user-actions';
import { updateSelectedProjectFromID } from 'store/actions/selected-project-actions';

export const finishUpdatingExistingProject = (project) => async (
  dispatch,
  getState,
) => {
  const { id: currentUserId } = getState().data.currentUser;
  const usersArray = project.data.userEntities;
  const doesUserArrayContainCurrentUser = usersArray.some(
    (user) => user.id === currentUserId,
  );
  /**
   * Update the current user as the user's project roles are stored there,
   * and once this project is edited, the current user's role may no longer be up to date in the stored current user.
   *  */
  dispatch(fetchCurrentUser());

  //Set the project just edited as the currently selected project
  // NOTE: do not call this method if the current user removes themselves from the project. this will cause a 403
  if (doesUserArrayContainCurrentUser) {
    dispatch(
      updateSelectedProjectFromID({
        projectId: project.data.id,
      }),
    );
  }

  //Navigate to the home page
  dispatch(push(ROUTE_CONSTANTS.HOME_PAGE));
};
