import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import Tickmark from 'models/data/tickmark-model';
import clonedeep from 'lodash.clonedeep';
export default class TickmarkList extends ApiModel({
  data: {
    tickmarks: [],
    elementsMap: {},
    deletedTickmarks: [],
  },
}) {
  get tickmarks() {
    if (this.hasTickmarks()) {
      return this.data.tickmarks;
    }
    return [];
  }
  get elementsMap() {
    if (this.hasElementsMap()) {
      return this.data.elementsMap;
    }
    return {};
  }

  hasElementsMap() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.elementsMap) &&
      Object.keys(this.data.elementsMap).length > 0
    );
  }

  processResponse({ response }) {
    return {
      data: {
        tickmarks: response.data.result.map(
          (tickmark) => new Tickmark(tickmark),
        ),
        elementsMap: this.data.elementsMap,
        deletedTickmarks: this.data.deletedTickmarks,
      },
    };
  }

  hasTickmarks() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.tickmarks) &&
      this.data.tickmarks.length > 0
    );
  }

  setElementList({ response, tickmarkId }) {
    let updatedElementsMap = clonedeep(this.data.elementsMap);

    updatedElementsMap = {
      ...updatedElementsMap,
      [tickmarkId]: response.data,
    };

    return this.mergeData({ elementsMap: updatedElementsMap });
  }

  clearElementList() {
    return this.mergeData({ elementsMap: {} });
  }

  updateTickmarkElementsMap(payload) {
    const updatedElement = payload;
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const tickmarkIdArray = Object.keys(clonedElementsMap);

    for (let index = 0; index < tickmarkIdArray.length; index++) {
      const tickmarkId = tickmarkIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[tickmarkId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId === updatedElement.id) {
          clonedElementsMap[tickmarkId][j] = updatedElement;
          break;
        }
      }
    }

    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  updateTickmarkElementsMapFromArray(payload) {
    let updatedElementsMapObject = {};
    for (let i = 0; i < payload.length; i++) {
      updatedElementsMapObject = {
        ...updatedElementsMapObject,
        [payload[i].id]: payload[i],
      };
    }
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const tickmarkIdArray = Object.keys(clonedElementsMap);
    for (let index = 0; index < tickmarkIdArray.length; index++) {
      const tickmarkId = tickmarkIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[tickmarkId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId in updatedElementsMapObject) {
          const element = updatedElementsMapObject[elementId];
          const elementToUpdate = clonedElementsMap[tickmarkId][j];
          clonedElementsMap[tickmarkId][j] = { ...elementToUpdate, ...element };
        }
      }
    }
    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  removeElementFromTickmarkElementsMapWithSocketPayload(payload) {
    let elementsToRemoveSet = new Set();
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      elementsToRemoveSet.add(element.id);
    }
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const tickmarkIdArray = Object.keys(clonedElementsMap);

    // this part updates the elements map
    for (let index = 0; index < tickmarkIdArray.length; index++) {
      const tickmarkId = tickmarkIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[tickmarkId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementsToRemoveSet.has(elementId)) {
          clonedElementsMap[tickmarkId].splice(j, 1);
        }
      }
    }

    return this.mergeData({
      elementsMap: clonedElementsMap,
    });
  }

  removeElementFromElementsMap(payload) {
    const elementIdToRemove = payload;
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const tickmarkIdArray = Object.keys(clonedElementsMap);

    for (let index = 0; index < tickmarkIdArray.length; index++) {
      const tickmarkId = tickmarkIdArray[index];
      const elementsArray = Object.values(clonedElementsMap[tickmarkId]);

      for (let j = 0; j < elementsArray.length; j++) {
        const elementId = elementsArray[j].id;
        if (elementId === elementIdToRemove) {
          clonedElementsMap[tickmarkId].splice(j, 1);
        }
      }
    }

    return this.mergeData({ elementsMap: clonedElementsMap });
  }

  removeTickmarkFromElementsMap(tickmarkId) {
    let updatedElementsMap = clonedeep(this.data.elementsMap);
    if (tickmarkId in updatedElementsMap) {
      delete updatedElementsMap[tickmarkId];
    }
    return this.mergeData({ elementsMap: updatedElementsMap });
  }
  addTickmarkToDeletedArray(tickmarkId) {
    const deletedTickmarksCopy = this.data.deletedTickmarks;
    deletedTickmarksCopy.push(tickmarkId);
    return this.mergeData({ deletedTickmarks: deletedTickmarksCopy });
  }

  deleteTickmarkWithSocketPayload(payload) {
    let tickmarksCopy = [...this.data.tickmarks];
    tickmarksCopy = tickmarksCopy.filter(
      (tickmark) => tickmark.tickmarkId !== payload.tickmarkRefId,
    );

    let updatedElementsMap = clonedeep(this.data.elementsMap);
    if (payload.tickmarkRefId in updatedElementsMap) {
      delete updatedElementsMap[payload.tickmarkRefId];
    }

    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
      elementsMap: updatedElementsMap,
    });
  }
  updateTickmarkWithSocketPayload(payload) {
    let tickmarksCopy = [...this.data.tickmarks];
    for (
      let currentTickmarkIndex = 0;
      currentTickmarkIndex < tickmarksCopy.length;
      currentTickmarkIndex++
    ) {
      if (
        payload.tickmarkRefId ===
          tickmarksCopy[currentTickmarkIndex].tickmarkId &&
        tickmarksCopy[currentTickmarkIndex].revisionId === payload.revisionId
      ) {
        tickmarksCopy[currentTickmarkIndex].text = payload.text;
        tickmarksCopy[currentTickmarkIndex].richText = payload.richText;
      }
    }
    return this.mergeData({
      tickmarks: tickmarksCopy.map((tickmark) => new Tickmark(tickmark)),
    });
  }
  returnTopNTickmarks(noOfTickmarkToReturn) {
    return this.mergeData({
      tickmarks: this.tickmarks.slice(0, noOfTickmarkToReturn),
    });
  }

  /**
   * It adds new data to elementsMap and tickmarks and increases the elementsCount in tickmarks
   * for the new tickmarkId inserted.
   * @param {Array} payload.attachedTickmark - it contains the list of attached tickmark with elementId
   * @param {Array} payload.elementEntities - it contains the list of elements modified as a result of attaching tickmark
   * @returns {ThisParameterType}
   */
  addTickmarkToList(payload) {
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    const tickmarksMap = this.tickmarks.reduce((elementMap, elementData) => {
      elementMap[elementData.tickmarkId] = elementData;
      return elementMap;
    }, {});
    const elements = payload.elementEntities.reduce(
      (elementMap, elementData) => {
        elementMap[elementData.id] = elementData;
        return elementMap;
      },
      {},
    );
    let modifiedTickmark = {};
    payload.attachedTickmark.forEach((tickmark) => {
      const tickmarkId = tickmark.tickmarkId;
      if (!(tickmarkId in tickmarksMap)) {
        tickmarksMap[tickmarkId] = tickmark;
      }
      const element = elements[tickmark.elementId];
      if (tickmarkId in clonedElementsMap) {
        // If there is already a tickmark and few elements already exists for that tickmark
        // then append this new element in the list.
        // Given : element is not null or undefined or any other falsy value.
        if (element) {
          modifiedTickmark[tickmarkId] = true;
          clonedElementsMap[tickmarkId].push(element);
          tickmarksMap[tickmarkId].elementCount++;
        }
      } else {
        //Else If there does not exist the particular tickmarkId
        // then create a new tickmark and store in the list.
        // Given : element is not null or undefined or any other falsy value.
        if (element) {
          clonedElementsMap[tickmarkId] = [element];
          tickmarksMap[tickmarkId].elementCount++;
        }
      }
    });
    const modifiedTickmarkKeys = Object.keys(modifiedTickmark);
    for (let i = 0; i < modifiedTickmarkKeys.length; i++) {
      //sort the element list by id to put the new element in the correct position
      clonedElementsMap[modifiedTickmarkKeys[i]] = clonedElementsMap[
        modifiedTickmarkKeys[i]
      ].sort((a, b) => a.id - b.id);
    }
    // sort the tickmarks by note ordinal
    const tickmarks = Object.values(tickmarksMap).sort((a, b) =>
      a.ordinal - b.ordinal,
    );
    return this.mergeData({
      tickmarks: tickmarks.map((tickmark) => new Tickmark(tickmark)),
      elementsMap: clonedElementsMap,
    });
  }
  /**
   * Removes data from elementsMap and modifies the count of tickmarks
   * @param {Array} payload - A particular object in an array consists revisionId, elementId, tickmarkId
   * @returns {ThisParameterType}
   */
  unlinkTickmarkFromElementWithWebsocket(payload) {
    let clonedElementsMap = clonedeep(this.data.elementsMap);
    let clonedTickmarks = clonedeep(this.data.tickmarks);

    let tickmarkToElementsMap = {};
    /** We are building a object which will contain all the new (in the payload) elementId mapped
     * to tickmarkId i.e. tickmarkId will be key which will contain collection of objects
     *  of the form 'elementId: true'.
     * Example : [123] : {{1:true}, {2:true}}.
     * Here 123 is tickmarkId and 1,2 are respective elementId (in the payload) mapped to
     * that tickmarkId.
     */
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      /** for the element in payload with particular tickmarkId, if tickmarkId
       * already exists in tickmarkToElementsMap then append the data for new element
       * else create a new key with that tickmarkId and store the elementId
       */
      if (element.tickmarkId in tickmarkToElementsMap) {
        tickmarkToElementsMap[element.tickmarkId] = {
          ...tickmarkToElementsMap[element.tickmarkId],
          [element.elementId]: true,
        };
      } else {
        tickmarkToElementsMap[element.tickmarkId] = {
          [element.elementId]: true,
        };
      }
    }
    /** Modify the elementCount for each tickmarkId (condition: tickmarkId belongs to the set of tickmarkToElementsMap)
     *  in tickmarks array and delete the elementIds from elementsMap
     */
    for (let i = 0; i < clonedTickmarks.length; i++) {
      let tickmark = clonedTickmarks[i];
      const tickmarkId = tickmark.tickmarkId;
      if (tickmarkId in tickmarkToElementsMap) {
        const elementsObject = tickmarkToElementsMap[tickmarkId];

        // subtract elementCount based on how many affected elements
        // are mapped to a specific tickmarkId
        const elementsToSubtract = Object.keys(elementsObject).length;
        tickmark.elementCount = tickmark.elementCount - elementsToSubtract;

        if (Object.keys(clonedElementsMap).length > 0) {
          let elements = clonedElementsMap[tickmarkId];
          const filteredElements = elements.filter(
            (element) => !(element.id in tickmarkToElementsMap[tickmarkId]),
          );
          clonedElementsMap[tickmarkId] = filteredElements;
        }
      }
    }

    return this.mergeData({
      tickmarks: clonedTickmarks,
      elementsMap: clonedElementsMap,
    });
  }
}
