import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const withURLRouter = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const params = useParams();

  return <WrappedComponent {...props} params={params} URLString={pathname} />;
};

export default withURLRouter;
