import { handleActions } from 'redux-actions';
import {
  leftSectionIdListStatementContentLoading,
  leftSectionIdListStatementContentLoaded,
  leftSectionIdListStatementContentError,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import { SectionIdList } from 'models/api/section-id-list-model';

export default handleActions(
  {
    [leftSectionIdListStatementContentLoading]: (sectionIdList) => {
      return sectionIdList.setLoading();
    },
    [leftSectionIdListStatementContentLoaded]: (sectionIdList, { payload }) => {
      return sectionIdList.setLoaded(payload);
    },
    [leftSectionIdListStatementContentError]: (sectionIdList, { payload }) => {
      return sectionIdList.setError(payload);
    },
    [clearStatementPageStoreAction]: (sectionIdList, { payload }) => {
      return new SectionIdList();
    },
  },
  new SectionIdList()
);
