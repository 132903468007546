import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Detach } from 'icons/unlink.svg';
import TickmarkEntry, {
  TICKMARK_ENTRY_BLOCK,
} from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-component';
import IconButton from 'components/common/icon-button-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const DETACH_ICON_SIZE = '28px';

const TICKMARK_DETACH_TOOLTIP = new TooltipOptions({
  text: 'common.detach',
  id: 'tickmark-unlink-button-tool-tip',
  position: 'left',
});

const TickmarkEntryWithDetach = ({
  tickmark,
  onDetach,
  disabled,
  detachIcon,
  tooltip = TICKMARK_DETACH_TOOLTIP,
  currentlyExpandedTickmarkID,
  updateExpandedTickmarkID,
}) => {
  return (
    <TickmarkEntry
      tickmark={tickmark}
      updateExpandedTickmarkID={updateExpandedTickmarkID}
      currentlyExpandedTickmarkID={currentlyExpandedTickmarkID}
      rightComponent={
        <IconButton
          id={`detach-tickmark-${tickmark.referenceNumber}`}
          className={`${TICKMARK_ENTRY_BLOCK}__detach`}
          tooltip={tooltip}
          onClick={() => onDetach({ tickmark })}
          Icon={detachIcon}
          iconSize={DETACH_ICON_SIZE}
          disabled={disabled}
        />
      }
    />
  );
};

TickmarkEntryWithDetach.defaultProps = {
  detachIcon: Detach,
};

TickmarkEntryWithDetach.propTypes = {
  /** Tickmark object from the tickmark list endpoint */
  // TODO update shape when shape is defined
  tickmark: PropTypes.object.isRequired,
  /** action fired to detach a work paper from this element */
  onDetach: PropTypes.func.isRequired,
  /** boolean to disable the button */
  disabled: PropTypes.bool.isRequired,
  /** svg to use as detach icon */
  detachIcon: PropTypes.object,
  /* function to set the currently expanded tickmark id after clicking Show More button */
  updateExpandedTickmarkID: PropTypes.func,
  /** Tickmark id of the tickmark expanded on the toolkit panel after clicking Show More button */
  currentlyExpandedTickmarkID: PropTypes.number,
};

export default memo(TickmarkEntryWithDetach);
