import ElementFilters from 'models/data/element-filters-model';
import { SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID } from 'components/feature/statement-summary/statement-summary-display-options-dropdown';

export const unverifiedElementFilter = new ElementFilters({ verified: false });

export const verifiedElementFilter = new ElementFilters({ verified: true });

export const reviewedElementFilter = new ElementFilters({ reviewed: true });

export const flaggedElementFilter = new ElementFilters({
  userFlag: true,
  formulaFlag: true,
  internalReferenceFlag: true,
  parentFlagged: true,
});

export const OPTIONS1_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-verified-checkbox`,
    name: 'verified',
    key: '1',
    label: 'statement-summary.elements-verified.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-reviewed-checkbox`,
    name: 'reviewed',
    key: '2',
    label: 'statement-summary.elements-reviewed.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-sign-off-checkbox`,
    name: 'signedOff',
    key: '3',
    label: 'statement-summary.section-sign-off.label',
  },
];

export const OPTIONS2_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-notes-checkbox`,
    name: 'notes',
    key: '4',
    label: 'statement-summary.notes.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-flags-checkbox`,
    name: 'flags',
    key: '5',
    label: 'statement-summary.elements-flagged.label',
  },
];

export const METRICS_CHECKBOX_ARRAY = [
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-percentage-checkbox`,
    name: 'percentage',
    key: '1',
    label: 'statement-summary.metrics.percentages.label',
  },
  {
    id: `${SUMMARY_DISPLAY_OPTIONS_DROPDOWN_ID}-count-checkbox`,
    name: 'count',
    key: '2',
    label: 'statement-summary.metrics.count.label',
  },
];
