import BaseModel from 'models/base-model';

const annotationsList = ['WP', 'TM', 'Status', 'PY', 'PQ'];
export default class SideBySideViewAnnotations extends BaseModel({
  isWorkpaperReferenceMapping: false,
  isStatusMapping: false,
  isTickmarkMapping: false,
  isPreviousYearMapping: false,
  isPreviousQuarterMapping: false,
}) {
  setAnnotationsMapping(val) {
    return this.merge({
      isWorkpaperReferenceMapping: val.includes(annotationsList[0]),
      isStatusMapping: val.includes(annotationsList[2]),
      isTickmarkMapping: val.includes(annotationsList[1]),
      isPreviousYearMapping: val.includes(annotationsList[3]),
      isPreviousQuarterMapping: val.includes(annotationsList[4]),
    });
  }
}
