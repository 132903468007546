import { handleActions } from 'redux-actions';
import ComfortLetterList from 'models/api/comfort-letter-list-api-model';
import {
  comfortAnnotationListLoading,
  comfortAnnotationListLoaded,
  comfortAnnotationListError,
  fetchElementsFromComfortAnnotationAction,
  unlinkElementInComfortLetterElementsMapAndUpdateCountAction,
  linkElementInComfortLetterElementsMapAndUpdateCountAction,
  removeElementFromComfortLetterElementsWithSocketPayloadAction,
  updateComfortLetterElementsMapFromArrayAction,
} from 'store/actions/comfort-annotations-list-actions';

export default handleActions(
  {
    [comfortAnnotationListLoading]: (comfortAnnotationList) => {
      return comfortAnnotationList.setLoading();
    },
    [comfortAnnotationListLoaded]: (comfortAnnotationList, { payload }) => {
      return comfortAnnotationList.setLoaded(payload);
    },
    [comfortAnnotationListError]: (comfortAnnotationList, { payload }) => {
      return comfortAnnotationList.setError(payload);
    },
    [unlinkElementInComfortLetterElementsMapAndUpdateCountAction]: (
      comfortAnnotationList,
      { payload },
    ) => {
      return comfortAnnotationList.unlinkElementFromElementsMapAndUpdateCount(
        payload,
      );
    },
    [linkElementInComfortLetterElementsMapAndUpdateCountAction]: (
      comfortAnnotationList,
      { payload },
    ) => {
      return comfortAnnotationList.linkElementFromElementsMapAndUpdateCount(
        payload,
      );
    },

    [removeElementFromComfortLetterElementsWithSocketPayloadAction]: (
      comfortAnnotationList,
      { payload },
    ) => {
      return comfortAnnotationList.removeElementFromComfortLetterElementsMapWithSocketPayload(
        payload,
      );
    },
    [fetchElementsFromComfortAnnotationAction]: (
      comfortAnnotationList,
      { payload },
    ) => {
      return comfortAnnotationList.setElementsForComfortLetterId(payload);
    },
    [updateComfortLetterElementsMapFromArrayAction]: (
      comfortAnnotationList,
      { payload },
    ) => {
      return comfortAnnotationList.updateComfortLetterElementsMapFromArray(
        payload,
      );
    },
  },
  new ComfortLetterList(),
);
