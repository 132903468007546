import { isNullOrUndefined } from 'utils/object-utils';
import queryString from 'query-string';
// Params (like /:id) are concatenated on the path prop of the Route component wherever ConnectedRouter is used
export const ROUTE_CONSTANTS = {
  HOME_PAGE: '/',
  TIEOUT_PAGE: '/tieout',
  LOGOUT_PAGE: '/tieout/logout',
  PROJECT_CREATION_PAGE: '/project/create',
  PROJECT_EDIT_PAGE: '/project/:id/edit',
  STATEMENT_CREATION: '/project/:projectId/statement/create',
  STATEMENT_EDIT_PAGE: '/project/:projectId/statement/:statementId/edit',
  STATEMENT_CONTENT_PAGE:
    '/project/:projectId/statement/:statementId/revision/:revisionId/:readOnly',
  STATEMENT_SPLIT_PAGE:
    '/project/:projectId/statement/:statementId/revision/:revisionId/:readOnly/split',
  OMNIA_ENGAGEMENT_PAGE: '/link',
  SESSION_TIMEOUT: '/timeout',
  TERMS_OF_USE: 'https://deloittenet.deloitte.com/system/pages/Use.aspx',
  BETA_STATEMENT_GUIDE: `${window.TIEOUT.ENV.DA_BASE_URL}/api/v1/file?key=Asset.TieOut.Help.StatementEvaluationUserGuide`,
  PRIVACY: '/resources/Privacy.pdf',
  ABOUT: 'https://www.deloitte.com/about',
  NOTICES: '/resources/ThirdParty.pdf',
  SUPPORT_REQUEST:
    'https://deloitteus.service-now.com/sp?id=kb_article&sys_id=2b09cdf9db8e3e0021697b1cbf96193d',
  SERVICE_NOW:
    'https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=7c519acb4fed8740f7a9cf5d0210c7a6',
  ESC_SUPPORT: 'https://auditonline.aaps.deloitte.com/USPROD/portfolio',
  ADMIN_MODULE_PAGE: '/admin',
  SIDE_BY_SIDE: '/side-by-side-view',
  COOKIE_NOTICE: '/resources/cookie-notice.html',
  STATEMENT_EVAULATION_GUIDE: `${window.TIEOUT.ENV.DA_BASE_URL}/api/v1/file?key=Asset.TieOut.Help.UserGuide`,
};

/**
 * Parses formatted url strings into useable URL's
 * e.g. if a route is `project/:projectId/statement/:statementId/edit` and the arguments passed to `parseRoute` function are
 * ```
 *  parseRoute('project/:projectId/statement/:statementId/edit', {
 *    params: { projectId: 1, statementId: 3},
 *    queryParams: { isPeriodToPeriod: true }
 * })
 * ```
 * will return `project/1/statement/3/edit?isPeriodToPeriod=true`
 * queryParams are parsed using the query-string package, reference the docs for further information on how this package works (for instance if arrays need to be handled a certain way)
 * https://www.npmjs.com/package/query-string
 * @param {string} route String representation of route with `:params` formatted with colons
 * @param {object} arg1.params key value map where the keys correspond to the `:params` in the route and the values that should replace those params in the url string
 * @param {object} arg1.queryParams key value map where the keys correspond to queryParam keys and the values are the values
 */
export const parseRoute = (route, { params, queryParams }) => {
  let _route = route;
  if (!isNullOrUndefined(params)) {
    const paramKeys = Object.keys(params);
    // replaces all route params with their the values of the keys by the same name in the params options prop
    paramKeys.forEach((paramKey) => {
      const paramIndex = _route.indexOf(`:${paramKey}`);
      const paramValue = params[paramKey];
      _route =
        _route.slice(0, paramIndex) +
        paramValue +
        _route.slice(paramIndex + paramKey.length + 1);
    });
  }

  if (!isNullOrUndefined(queryParams)) {
    // adds route query strings to the route
    // if arrays need to be supported as ids=[]24&ids=[]25 etc. then add { arrayFormat: 'brackets'} as the second argument to stringify below
    _route = `${_route}?${queryString.stringify(queryParams)}`;
  }

  return _route;
};
