import BaseModel from 'models/base-model';

/**
 * Values object for the SectionCacheTracker.viewedSectionMetricsMap
 * Indicate the section size and timestamp from when it was last viewed
 * @param {int} sectionMemorySize Sections Memory size in bytes
 * @param {Date} timestamp Timestamp of when this section has most recently been viewed
 */
class SectionCacheMetrics extends BaseModel({
  sectionMemorySize: 0,
  timestamp: null,
}) {
  constructor(props) {
    super(props);
    if (props) {
      const { sectionMemorySize, timestamp } = props;
      this.sectionMemorySize = sectionMemorySize ? sectionMemorySize : 0;
      this.timestamp = timestamp ? timestamp : Date.now();
    } else {
      this.sectionMemorySize = 0;
      this.timestamp = Date.now();
    }
  }
  setMemorySize(sectionMemorySize) {
    return this.merge({
      sectionMemorySize,
    });
  }

  setTimestamp() {
    return this.merge({
      timestamp: Date.now(),
    });
  }
}

export default SectionCacheMetrics;
