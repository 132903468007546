import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const addNavigationRequest = ({ revisionId, elementDetails }) =>
  withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/addnavigation`,
      elementDetails,
      {
        params: {
          since: Date.now().toString(),
        },
      },
    )
    .then((response) => {
      return response;
    });
