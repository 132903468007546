import FormulaRow from './formula-row-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  FORMULA_ROW_TYPES,
  FORMULA_ROW_OPERATOR_OPTIONS_MAP,
} from 'constants/feature/formula-constants';
export default class FormulaRowOperator extends FormulaRow {
  operator = FORMULA_ROW_OPERATOR_OPTIONS_MAP.get('+'); // MenuOption from FORMULA_ROW_OPERATOR_OPTIONS_MAP
  value = '+';
  constructor(props) {
    super(props);
    this.type = FORMULA_ROW_TYPES.OPERATOR;
    if (!isNullOrUndefined(props) && !isNullOrUndefined(props.value)) {
      this.operator = FORMULA_ROW_OPERATOR_OPTIONS_MAP.get(props.value);
      this.value = props.value;
    }
  }

  hasOperator() {
    return !isNullOrUndefined(this.operator);
  }

  isOperatorParenthesis() {
    return (
      !isNullOrUndefined(this.operator) &&
      (this.operator === FORMULA_ROW_OPERATOR_OPTIONS_MAP.get('(') ||
        this.operator === FORMULA_ROW_OPERATOR_OPTIONS_MAP.get(')'))
    );
  }

  setOperator(operator) {
    return this.merge({ operator, value: operator.value });
  }
}
