import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';

export const FeatureFlagComponent = ({
  name,
  children,
  fallback,
  checkIfFeatureFlagEnabled,
}) => {
  const show = checkIfFeatureFlagEnabled({ featureName: name });

  if (show) return <>{children}</>;
  else if (fallback) return <>{fallback}</>;
  else return null;
};

const mapDispatchToProps = {
  checkIfFeatureFlagEnabled,
};

FeatureFlagComponent.propTypes = {
  // name of the feature
  name: PropTypes.string.isRequired,
  // component to be rendered if feature with mentioned name is enabled
  children: PropTypes.node,
  // fallback component if feature is not enabled and we want to render different component for that case
  fallback: PropTypes.node,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(FeatureFlagComponent);
