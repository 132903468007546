import { connect } from 'react-redux';

import {
  initFormulaCreate,
  setFormulaOperator,
} from 'store/actions/formula-actions';

import FormulaPanelContent from 'components/feature/element-panel/formula-panel/_formula-panel-content-component';
const mapStateToProps = (
  {
    data: {
      elementPanel: { elementDetails },
      formulaPanel: { formula },
      selectedStatement: { statement },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  elementDetails,
  numberFormatId: statement && statement.numberFormatId,
  formulaInProgress: formula,
});
const mapDispatchToProps = {
  initFormulaCreate,
  setFormulaOperator,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormulaPanelContent);
