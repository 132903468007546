import { handleActions } from 'redux-actions';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import {
  sectionTreeListLoading,
  sectionTreeListError,
  sectionTreeListLoaded,
  setCreateSectionAction,
  addNewSectionAction,
  removeNewSectionAction,
  clearSectionTreeListAction,
  setNewSectionIdAction,
} from 'store/actions/section-tree-list-actions';

export default handleActions(
  {
    [sectionTreeListLoading]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setLoading(payload);
    },
    [sectionTreeListLoaded]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setLoaded({ response: payload });
    },
    [sectionTreeListError]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setError(payload);
    },
    [setCreateSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setIsCreateSectionModalOpen(payload);
    },
    [addNewSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.addNewSectionByParentId(payload);
    },
    [removeNewSectionAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.removeNewSection(payload);
    },
    [clearSectionTreeListAction]: () => {
      return new SectionTreeList();
    },
    [setNewSectionIdAction]: (sectionTreeList, { payload }) => {
      return sectionTreeList.setNewSectionId(payload);
    },
  },
  new SectionTreeList(),
);
