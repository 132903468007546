import { withDefaultContextAsSelectedProject } from './api-default-context';
import { COMFORT_ANNOTATION_SORT_BY } from 'constants/feature/comfort-letter-constants';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const createElementComfortLetterRequest = ({ comfortLetterForm }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${
      comfortLetterForm.revisionId
    }/elements/${comfortLetterForm.elementId}/comfortletter`,
    comfortLetterForm.toApiFormat(),
  );

export const updateElementComfortLetterRequest = async ({
  revisionId,
  comfortLetter,
}) => {
  await withDefaultContextAsSelectedProject().put(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/comfortletter/${
      comfortLetter.comfortLetterId
    }`,
    comfortLetter.toEditApiFormat(),
  );
};

export const getComfortLetterForElementRequest = ({ elementId, revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/cmftlrs`,
  );

export const createBulkComfortLetterRequest = ({ comfortLetterForm }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${
      comfortLetterForm.revisionId
    }/comfortletters`,
    comfortLetterForm.toApiFormat(),
  );

export const getMatchingComfortLettersForElementRequest = ({
  searchTerm,
  revisionId,
  elementId,
}) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/element/${elementId}/cmftltrs/suggestions`,
      {
        params: { searchText: encodeURIComponent(searchTerm) },
      },
    )
    .then((response) => {
      return response;
    });

export const getMatchingComfortLettersRequest = ({ searchTerm, revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/cmftltrs/suggestions`,
      {
        params: { searchText: encodeURIComponent(searchTerm) },
      },
    )
    .then((response) => {
      return response;
    });

export const attachComfortLetterToElementRequest = ({
  comfortLetterId,
  elementId,
  revisionId,
}) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/comfortletter`,
    {
      comfortLetterId,
      elementId,
      revisionId,
    },
  );

export const detachComfortLetterToElementRequest = ({
  comfortLetterId,
  elementId,
  revisionId,
}) =>
  withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/comfortletter/${comfortLetterId}`,
  );

export const getComfortAnnotationsForRevisionRequest = ({
  revisionId,
  sortBy = COMFORT_ANNOTATION_SORT_BY.ordinal,
  sortDirection = 'asc',
}) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/comfortletters`,
    {
      params: {
        sortBy,
        sortDirection,
      },
    },
  );

export const getElementsByComfortAnnotation = ({
  revisionId,
  comfortLetterId,
}) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/comfortletter/${comfortLetterId}/elements`,
  );
};

export const deleteComfortLetterRequest = ({
  comfortAnnotation,
  revisionId,
}) => {
  const { comfortLetterId } = comfortAnnotation;
  return withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/comfortletter/${comfortLetterId}`,
  );
};
