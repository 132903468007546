import React from 'react';
import classNames from 'classnames';
import Dropdown, { KebabDropdown } from 'components/common/dropdown-component';
import DATA_GRID_CONSTANTS from 'constants/common/data-grid-constants';
import { KebabMenuOption } from 'models/utils/common/menu/menu-option-model';
import { isNullOrUndefined } from 'utils/object-utils';
const ACCEPTABLE_WIDTH_UNITS = ['%', 'rem', 'px'];

const INTERACTIVE_COLUMN_WIDTH = '5%';

const { SPECIAL_COLUMNS } = DATA_GRID_CONSTANTS;
const stopRowClickPropagation = (e) => {
  // IMPORTANT prevents DataGrid onRowClick from firing for dropdown selections
  e.stopPropagation();
};

class DataGridColumn {
  /* column key to sort the table by */
  key;
  /* Column header, should be an intl id*/
  header;
  /* [optional] is column sortable? */
  sortable;
  /* [optional] is column filterable? */
  filterable;
  /* Css width string, preferably in % */
  width;
  /* [optional] Special class for this column*/
  className;
  /** [optional] Icon for the column header */
  Icon;
  /* [optional] Function that accepts the value of the row and returns jsx for what to display in a cell
      default to row data with key, arguments (rowData, options) options are data-grid level data that may be useful
      to a columns formatter function, they are passed from the data-grid -> data-grid-row -> formatter
      options can be, 
      - index,
      - onSelect,
      - rowKey,
      - isRowSelected,
      - onDropdownColumnSelect,
      */
  formatter;
  constructor({
    key,
    header,
    sortable,
    filterable,
    width,
    formatter,
    className,
    Icon,
  }) {
    if (
      !width ||
      !ACCEPTABLE_WIDTH_UNITS.some((unit) => width.includes(unit))
    ) {
      console.error(
        `DataGridColumn with key: ${key} width needs to be one of `,
        ...ACCEPTABLE_WIDTH_UNITS,
        `\n you specified ${width}`,
      );
    }

    this.key = key;
    this.header = header;
    this.sortable = sortable;
    this.filterable = filterable;
    this.width = width;
    this.className = className;
    this.Icon = Icon;
    this.formatter = formatter ? formatter : (rowData) => rowData[this.key];
  }
}

/**
 * A special column type if your row column needs a kebab menu
 * @param {[MenuOption]} props.options Array for simple options, or conditionally assign options based on row data by passing a function that returns an options array
 * @param {string} props.dropdownId unique string to build the dropdowns id attribute from
 */
export class DataGridKebabColumn extends DataGridColumn {
  constructor({
    options,
    dropdownId,
    className,
    usePortalMenu = true,
    containerRef,
  }) {
    super({
      key: SPECIAL_COLUMNS.kebab,
      header: null,
      width: INTERACTIVE_COLUMN_WIDTH,
      sortable: false,
      className: className,
      formatter: (rowData, { index }) => (
        <KebabDropdown
          id={`${dropdownId}-${index}`}
          options={this.giveOptionsRowData(options, rowData)}
          onClickFormItem={stopRowClickPropagation}
          menuClassName={`${className}`}
          usePortalMenu={usePortalMenu}
          containerRef={containerRef}
        />
      ),
    });
  }

  /**
   * Some properties of MenuOption like *onSelectOption*, *disabled*, and *shouldShowOption* require knowledge of
   * the current row's data. Due to the nature of the data-grid, it is difficult to instantiate different
   * MenuOption arrays for each row in a constants file. Therefore this function serves the purpose of instantiating
   * the menu with row specific data by "giving" row data to the *onSelectOption*, *disabled*, and *shouldShowOption* functions
   * if they are defined as functions
   *
   * one of your options in your array might look like
   * new KebabMenuOption({
   *    title: 'statement-list.kebab.legal-hold',
   *    onSelectOption: rowData => enableLegalHold(rowData),
   *    shouldShowOption: rowData => rowData.isOnLegalHold,
   *    disabled: rowData => rowData.canBePlacedOnLegalHold,
   *  }),
   *
   * this function is what allows *onSelectOption*, *disabled*, and *shouldShowOption* to have the rowData
   *
   * @param {[MenuOption]} options
   * @param {Object} rowData
   */
  giveOptionsRowData(options, rowData) {
    return options
      .filter((option) => {
        // filter out options that have shouldShowOption property set to false or a function
        // that evaluates to false so they do not interfere with menu height calculations
        if (typeof option.shouldShowOption === 'function') {
          return option.shouldShowOption(rowData);
        } else if (!isNullOrUndefined(option.shouldShowOption)) {
          return option.shouldShowOption;
        }
        return true;
      })
      .map(
        (option) =>
          new KebabMenuOption({
            ...option,
            onSelectOption: () => option.onSelectOption(rowData),
            disabled:
              typeof option.disabled === 'function'
                ? option.disabled(rowData)
                : option.disabled,
            tooltip:
              typeof option.tooltip === 'function'
                ? option.tooltip(rowData)
                : option.tooltip,
          }),
      );
  }
}

export class DataGridCheckboxColumn extends DataGridColumn {
  constructor({ checkboxId }) {
    super({
      key: SPECIAL_COLUMNS.checkbox,
      header: null,
      width: INTERACTIVE_COLUMN_WIDTH,
      sortable: false,
      className: 'checkbox-col',
      formatter: (rowData, { index, onSelect, isRowSelected }) => {
        if (!rowData.id) {
          throw new Error(
            `Row data for data grids must have an id property, no id present for row data: ${rowData}`,
          );
        }
        return (
          <input
            type="checkbox"
            checked={!!isRowSelected}
            className={'checkbox__input'}
            id={`${checkboxId}-${index}`}
            onClick={stopRowClickPropagation}
            onChange={(e) => onSelect(e.target.value)}
            value={rowData.id}
          />
        );
      },
    });
  }
}
/**
 * A special column type if your row column needs a selectable dropdown
 * @param {array|function} props.options Array for simple options, or conditionally assign options based on row data by passing a function that returns an options array
 * @param {string} props.key key for value that this column represents on rowData object
 * @param {string} props.header column display header
 * @param {string} props.width width of dropdown parent, in px, %, or rem
 * @param {bool|function} props.isValid if function, gives row data as argument to prop and should return a boolean
 */
export class DataGridDropdownColumn extends DataGridColumn {
  constructor({ options, key, header, width, isValid, placeholder, disabled }) {
    super({
      key: key,
      header: header,
      width: width,
      sortable: false,
      className: 'dropdown-col',
      formatter: (rowData, { rowKey, onDropdownColumnSelect }) => {
        const colId = `region-col-${rowKey}`;
        let _isValid = isValid;
        if (typeof isValid === 'function') {
          _isValid = isValid(rowData);
        }
        let _options = options;
        if (typeof options === 'function') {
          _options = options(rowData);
        }
        return (
          <Dropdown
            options={_options}
            id={colId}
            onSelectOption={(val) =>
              onDropdownColumnSelect(val, rowData.id, 'region')
            }
            value={rowData[this.key]}
            onClickFormItem={stopRowClickPropagation}
            placeholder={placeholder}
            width="100%"
            isValid={_isValid}
            usePortalMenu
            disabled={disabled}
          />
        );
      },
    });
  }
}

export class DataGridIconColumn extends DataGridColumn {
  constructor({ onClick, Icon, iconHeight, iconWidth, disabled }) {
    super({
      key: SPECIAL_COLUMNS.icon,
      header: null,
      width: INTERACTIVE_COLUMN_WIDTH,
      sortable: false,
      className: classNames('icon-col', disabled ? 'icon-col--disabled' : null),
      formatter: (rowData, { rowKey }) => (
        <Icon
          height={iconHeight}
          width={iconWidth}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              onClick(rowData);
            }
          }}
        />
      ),
    });
  }
}

export default DataGridColumn;
