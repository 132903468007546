import BaseModel from 'models/base-model';
import { STATEMENT_CONTENT_TABS } from 'constants/feature/statement-content-constants';

export default class StatementContentFilters extends BaseModel({
  selectedTab: STATEMENT_CONTENT_TABS.STATEMENT,
}) {
  setSelectedTab(selectedTab) {
    return this.merge({
      selectedTab: selectedTab,
    });
  }
}
