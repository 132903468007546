import { createAction } from 'redux-actions';
import {
  getSectionAssignments,
  getAllSectionAssignments,
} from 'api/sections-api';
import { fetchSectionHistoryDetails } from 'store/actions/section-review-history-actions';

export const fetchSectionAssignmentsLoadedAction = createAction(
  'FETCH_SECTION_ASSIGNMENTS_LOADED',
);

export const fetchSectionAssignmentsLoadingAction = createAction(
  'FETCH_SECTION_ASSIGNMENTS_LOADING',
);

export const fetchSectionAssignmentsErrorAction = createAction(
  'FETCH_SECTION_ASSIGNMENTS_ERROR',
);

export const fetchSectionAssignmentsListLoaded = createAction(
  'FETCH_SECTION_ASSIGNMENTS_LIST_LOADED',
);

export const fetchSectionAssignmentsListError = createAction(
  'FETCH_SECTION_ASSIGNMENTS_LIST_ERROR',
);

export const updateSectionAssignmentListFromSocket = createAction(
  'UPDATE_SECTION_ASSIGNMENT_LIST_FROM_SOCKET',
);

export const replaceNewWithSocketData = createAction(
  'REPLACE_SECTION_WITH_NEW_SOCKET_DATA',
);

export const updateSectionAssignmentListForBulkAssignFromSocket = createAction(
  'UPDATE_SECTION_ASSIGNMENT_LIST_FOR_BULK_ASSIGN_FROM_SOCKET',
);
export const updateSectionAssignmentListForBulkUnAssignFromSocket =
  createAction('UPDATE_SECTION_ASSIGNMENT_LIST_FOR_BULK_UNASSIGN_FROM_SOCKET');

export const updateSectionAssignmentInBulkFromSocket = createAction(
  'UPDATE_SECTION_ASSIGNMENT_IN_BULK_FROM_SOCKET',
);

export const fetchSectionAssignmentsList =
  ({ revisionId }) =>
  async (dispatch, getStore) => {
    const { currentUser } = getStore().data;

    try {
      const response = await getAllSectionAssignments({ revisionId });
      dispatch(
        fetchSectionAssignmentsListLoaded({
          response,
          currentUserId: currentUser.id,
        }),
      );
    } catch (error) {
      dispatch(fetchSectionAssignmentsListError(error));
    }
  };

export const fetchSectionAssignments =
  ({ revisionId, sectionId, usersList }) =>
  async (dispatch) => {
    dispatch(fetchSectionAssignmentsLoadingAction());
    await dispatch(
      fetchSectionAssignmentsWithoutLoading({
        revisionId,
        sectionId,
        usersList,
      }),
    );
  };

export const fetchSectionAssignmentsWithoutLoading =
  ({ revisionId, sectionId, usersList }) =>
  async (dispatch) => {
    try {
      const response = await getSectionAssignments({ revisionId, sectionId });
      dispatch(fetchSectionAssignmentsLoadedAction({ response, usersList }));
    } catch (error) {
      fetchSectionAssignmentsErrorAction(error);
    }
  };

export const updateBulkSectionAssignmentsList =
  ({ revisionId, sectionId, usersList }) =>
  async (dispatch) => {
    dispatch(fetchSectionAssignmentsLoadingAction());
    if (sectionId || sectionId === 0) {
      await dispatch(
        fetchSectionAssignmentsWithoutLoading({
          revisionId,
          sectionId,
          usersList,
        }),
      );
      await dispatch(
        fetchSectionHistoryDetails({
          revisionId,
          sectionId,
        }),
      );
    }

    await dispatch(
      fetchSectionAssignmentsList({
        revisionId,
      }),
    );
  };
