import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ConditionalRender from 'components/util/conditional-render-component';
import UserGrid from 'components/feature/project-details/_project-details-user-grid-component';
import ProjectApi from 'models/api/project-api-model';
import { UserList } from 'models/api/users-list-model';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';
import { ReactComponent as RightArrowIcon } from 'icons/arrow-right.svg';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { isProjectExistInAMERegion } from 'utils/project-utils';

export const USERS_BLOCK = 'project-details-users';
const USERS_ID_BLOCK = 'project-details-users-id';
const OMNIA_ICON_SIZE = '18';
const ARROW_ICON_SIZE = '17';
export const ExpandedUsers = ({ project, users, omniaDetails }) => {
  const { data: { geoCode } = {} } = project;
  return (
    <React.Fragment>
      <div className={`${USERS_BLOCK}__row`}>
        <div className={`${USERS_BLOCK}__col`}>
          <div className={`${USERS_BLOCK}__label`}>
            <FormattedMessage id={'project-details.body.engagement-id'} />
          </div>
          <div className={`${USERS_BLOCK}__value`}>
            {project.auditClient ? (
              project.engagementEntity.engagementId
            ) : (
              <FormattedMessage id={'common.NA'} />
            )}
          </div>
        </div>
        <div className={`${USERS_BLOCK}__col`}>
          <div className={`${USERS_BLOCK}__label`}>
            <FormattedMessage id={'common.user-roles.project-creation-date'} />
          </div>
          <div className={`${USERS_BLOCK}__value`}>
            {project.getCreatedDate()}
          </div>
        </div>
        {omniaDetails.engagementName && (
          <div className={`${USERS_BLOCK}__col`}>
            <div className={`${USERS_BLOCK}__engagement-name-title`}>
              <OmniaIcon
                className={`${USERS_BLOCK}__omnia-icon`}
                width={OMNIA_ICON_SIZE}
                height={OMNIA_ICON_SIZE}
              />
              <div className={`${USERS_BLOCK}__label`}>
                <FormattedMessage
                  id={'project-details.body.omnia-engagement-name'}
                />
              </div>
            </div>
            <div className={`${USERS_BLOCK}__omnia-engagement`}>
              <a href={`${project.omniaUrl}${omniaDetails.engagementId}`}>
                {omniaDetails.engagementName}
                <RightArrowIcon
                  className={`${USERS_BLOCK}__arrow-icon`}
                  width={ARROW_ICON_SIZE}
                  height={ARROW_ICON_SIZE}
                />
              </a>
            </div>
          </div>
        )}
        <div className={`${USERS_BLOCK}__col`}>
          <div className={`${USERS_BLOCK}__label`}>
            <FormattedMessage
              id={'project-creation.manage-users.user-heading.region'}
            />
          </div>
          <div>{geoCode}</div>
        </div>
        {isProjectExistInAMERegion(project) && (
          <div className={`${USERS_BLOCK}__col`}>
            <div className={`${USERS_BLOCK}__label`}>
              <FormattedMessage id={'project-details.body.coe-team-access'} />
            </div>
            <div className={`${USERS_BLOCK}__value`}>
              {project.hasCoeGroup ? (
                <FormattedMessage id={'common.ON'} />
              ) : (
                <FormattedMessage id={'common.OFF'} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className={`${USERS_BLOCK}__row`}>
        <div className={`${USERS_BLOCK}__user-container`}>
          <div className={`${USERS_BLOCK}__label`}>
            <FormattedMessage id={'project-details.body.users'} />
          </div>
          <ConditionalRender dependencies={[users]}>
            <div className={`${USERS_BLOCK}__row`}>
              <UserGrid
                id={`${USERS_ID_BLOCK}-user-col-ppmd`}
                users={users.getPpmdUsers()}
                numOfColumns={1}
                userType={'common.user-roles.ppmd'}
              />
              <UserGrid
                id={`${USERS_ID_BLOCK}-user-col-engagement-owner`}
                users={users.getEngagementOwnerUsers()}
                numOfColumns={1}
                userType={'common.user-roles.engagement-owner'}
              />
              <UserGrid
                id={`${USERS_ID_BLOCK}-user-col-observer`}
                users={users.getObserverUsers()}
                numOfColumns={1}
                userType={'common.user-roles.observer'}
              />
              <UserGrid
                id={`${USERS_ID_BLOCK}-user-col-preparer-reviewer`}
                users={users.getPreparerReviewerUsers()}
                numOfColumns={2}
                userType={'common.user-roles.preparer-reviewer'}
              />
            </div>
          </ConditionalRender>
        </div>
      </div>
    </React.Fragment>
  );
};

ExpandedUsers.propTypes = {
  /** Object representing selected project*/
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Object with data array of users for selected project */
  users: PropTypes.instanceOf(UserList).isRequired,
  /** Func that fires action that navigates user to omnia page */
  push: PropTypes.func.isRequired,
  // Fetches the selected project's omnia engagement data
  omniaDetails: PropTypes.object.isRequired,
};

const mapStateToProps = ({ data: { projectList } }, ownProps) => ({
  omniaDetails: projectList.getProjectFromList(ownProps.project.id)
    .omniaEngagementEntity,
});

const mapDispatchToProps = {
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandedUsers);
