import { handleActions } from 'redux-actions';
import {
  setMaintenacePageLoading,
  setMaintenacePageLoaded,
  setMaintenancePageError,
} from 'store/actions/admin-module-actions';
import AdminModuleMaintenanceList from 'models/data/admin-module-maintenance-page-list-model';

export default handleActions(
  {
    [setMaintenacePageLoading]: (maintenancePageList) => {
      return maintenancePageList.setLoading();
    },
    [setMaintenacePageLoaded]: (maintenancePageList, { payload }) => {
      return maintenancePageList.setLoaded(payload);
    },
    [setMaintenancePageError]: (maintenancePageList, { payload }) => {
      return maintenancePageList.setError(payload);
    },
  },
  new AdminModuleMaintenanceList(),
);
