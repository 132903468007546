import { handleActions } from 'redux-actions';
import {
  addElementToBatchSelectionAction,
  clearBatchSelectedElementsAction,
  removeElementFromBatchSelection,
} from 'store/actions/copy-formula-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import BatchUpdateElementList from 'models/data/batch-update-element-list-model';

export default handleActions(
  {
    [addElementToBatchSelectionAction]: (elements, { payload }) => {
      return elements.appendElement(payload);
    },
    [clearBatchSelectedElementsAction]: () => {
      return new BatchUpdateElementList();
    },
    [clearStatementPageStoreAction]: () => {
      return new BatchUpdateElementList();
    },
    [removeElementFromBatchSelection]: (elements, { payload }) => {
      return elements.removeElement(payload);
    },
  },
  new BatchUpdateElementList(),
);
