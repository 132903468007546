import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { addElementRequest } from 'api/element-api';
import {
  fetchAllSectionRenderingData,
  sectionHTMLSegmentsStatementContentLoading,
  sectionHTMLSegmentsStatementContentLoadedWithoutResponse,
} from 'store/actions/statement-content-actions';
import {
  initElementPanel,
  selectElementCreated,
} from 'store/actions/element-panel-actions';
import { initCreateNoteFromSelection } from 'store/actions/notes-panel-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import PropTypes from 'prop-types';
import {
  matchSectionOrElement,
  getStartCharacterOffsetWithin,
  getEndCharacterOffsetWithin,
  getElementCSSXPath,
  getRangeMinusIgnorableContent,
  matchSectionCount,
  matchesOrContainsNote,
  matchesOrContainsSection,
  matchTableCellCount,
  getSafeParentForRange,
  getSafeParentForNode,
  isInsideTableCells,
  matchParagraphTagCount,
} from 'utils/content-selection-utils';
import { toast } from 'react-toastify';
import Notification from 'components/common/notification-component';
import { injectIntl } from 'react-intl';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';
import { setTOCExpandAll } from 'store/actions/TOC-actions';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import Permissions from 'permissions/permissions';
import { getContentLength } from 'utils/content-selection-utils';
import { ReactComponent as CloseButton } from 'icons/close-button-red.svg';
import { ReactComponent as Checkmark } from 'icons/checkmark.svg';

const ContextMenu = ({
  visible,
  position,
  hideContextMenu,
  centerPanelRef,
  revision,
  sectionId,
  fetchAllSectionRenderingData,
  fetchStatementSummaryElements,
  selection,
  initCreateNoteFromSelection,
  initElementPanel,
  intl,
  toggleAddNavigationModal,
  setSelectedNavigatorTab,
  setTOCExpandAll,
  selectedProject,
  zoomRatio,
  selectElementCreated,
  socketHasBeenDisconnected,
  sectionHTMLSegmentsStatementContentLoading,
  sectionHTMLSegmentsStatementContentLoadedWithoutResponse,
}) => {
  const menuRef = useRef(null);
  const centerPanel = centerPanelRef.current;

  const range = selection.getRangeAt(0);

  const containsElement = matchSectionOrElement(selection);
  const containsSection = matchSectionCount(selection) > 0;
  const containsTableCell = isInsideTableCells(selection);
  const multipleSectionsSelected = matchSectionCount(selection) > 1;
  const selectedCellsCount = matchTableCellCount(selection);
  const containsNotes = matchesOrContainsNote(range);
  const containsSectionAnchor = matchesOrContainsSection(range);
  const selectedParagraphCount = matchParagraphTagCount(selection);
  const _userCanChangeContent = Permissions.Statement.canChangeContent();
  const canCreateNotes =
    Permissions.Note.canManageCoeNotesType(selectedProject) ||
    _userCanChangeContent;

  const _permissionToEdit = Permissions.Element.canEditElement(selectedProject);

  useEffect(() => {
    menuRef.current.style.left = _calculateX() / zoomRatio + 'px';

    menuRef.current.style.top = _calculateY() / zoomRatio + 'px';

    document.addEventListener('click', _handleClick);
    centerPanel.addEventListener('scroll', _handleScroll);

    // clean up
    return () => {
      document.removeEventListener('click', _handleClick);
      centerPanel.removeEventListener('scroll', _handleScroll);
    };
  });

  const _addElement = async () => {
    if (selectedCellsCount > 1) {
      toast.error(
        <Notification
          icon={<CloseButton />}
          message={{ id: 'notifications.add-element.error.message' }}
          title={{ id: 'notifications.add-element.error.title' }}
        />,
        { autoClose: 10000 },
      );
      hideContextMenu();
      return;
    }

    let text = selection.toString().trim();

    const parent = getSafeParentForRange({ range });

    hideContextMenu();

    try {
      const { data } = await addElementRequest({
        revisionId: revision.id,
        elementDetails: {
          selectedText: text,
          selector: getElementCSSXPath(parent),
          offset: getStartCharacterOffsetWithin(range, parent),
          length: text.length,
          tieoutSectionId: sectionId,
          hasElement: containsElement,
          elementId: -1, //id for new element, according to information provided by the backend team
          sectionId: sectionId,
        },
      });
      if (socketHasBeenDisconnected) {
        fetchStatementSummaryElements({ revisionId: revision.id });
        await fetchAllSectionRenderingData({ sectionId });
      } else {
        sectionHTMLSegmentsStatementContentLoading([sectionId]);
        setTimeout(() => {
          sectionHTMLSegmentsStatementContentLoadedWithoutResponse([sectionId]);
        }, 5000);
      }

      await initElementPanel({ elementId: data.id });
      selectElementCreated({ elementId: data.id });
      toast.success(
        <Notification
          elementDetails={data}
          icon={<Checkmark />}
          message={data.label}
          title={{ id: 'notifications.add-element.success.title' }}
          truncateMessage
        >
          <strong className="Toastify__toast-text--truncated">
            {data.display}
          </strong>
        </Notification>,
      );
    } catch (error) {
      console.log(`error adding element ${error.message}`);
    }
  };

  const _addNote = () => {
    const filteredRange = getRangeMinusIgnorableContent({ range });
    const text = filteredRange.toString();

    const { startContainer, endContainer } = range;
    const startNode = getSafeParentForNode(startContainer);
    const endNode = getSafeParentForNode(endContainer);

    const textLength = getContentLength(range, text.length);

    initCreateNoteFromSelection({
      data: {
        revisionId: revision.id,
        selectedText: text,
        selector: getElementCSSXPath(startNode),
        endSelector: getElementCSSXPath(endNode),
        offset: getStartCharacterOffsetWithin(range, startNode),
        endOffset: getEndCharacterOffsetWithin(range, endNode),
        length: textLength,
        hasElement: containsElement,
        sectionId: sectionId,
        revisionNumber: revision.revisionNumber,
      },
    });

    hideContextMenu();
  };

  const _addNavigation = () => {
    setSelectedNavigatorTab(STATEMENT_NAV_TABS.headings);
    setTOCExpandAll(true);
    toggleAddNavigationModal();
    hideContextMenu();
  };

  const _handleScroll = () => hideContextMenu();

  const _handleClick = (e) => {
    const wasOutside = !menuRef.current.contains(e.target);

    if (wasOutside && visible) {
      hideContextMenu();
    }
  };

  const _calculateY = () => {
    const calculatedY =
      position.y + centerPanel.scrollTop - centerPanel.offsetTop;

    // make sure the menu doesn't go beyond panel boundaries
    return calculatedY + menuRef.current.offsetHeight - centerPanel.scrollTop <
      centerPanel.offsetHeight
      ? calculatedY
      : calculatedY - menuRef.current.offsetHeight;
  };

  const _calculateX = () => {
    const calculatedX =
      position.x + centerPanel.scrollLeft - centerPanel.offsetLeft;

    // make sure the menu doesn't go beyond panel boundaries
    return calculatedX + menuRef.current.offsetWidth - centerPanel.scrollLeft <
      centerPanel.offsetWidth
      ? calculatedX
      : calculatedX - menuRef.current.offsetWidth;
  };

  return (
    visible && (
      <ul ref={menuRef} className="statement-context-menu">
        {_userCanChangeContent &&
          !containsElement &&
          !containsSectionAnchor &&
          !containsNotes && (
            <li>
              <button onClick={() => _addElement()}>
                {intl.formatMessage({
                  id: 'section-content.context-menu.add-element',
                })}
              </button>
            </li>
          )}
        {canCreateNotes &&
          !containsNotes &&
          !containsSectionAnchor &&
          !multipleSectionsSelected &&
          selectedCellsCount <= 1 &&
          selectedParagraphCount <= 1 && (
            <li>
              <button onClick={() => _addNote()}>
                {intl.formatMessage({
                  id: 'section-content.context-menu.add-note',
                })}
              </button>
            </li>
          )}
        {_permissionToEdit &&
          !containsNotes &&
          !containsElement &&
          !containsSection &&
          !containsSectionAnchor &&
          !containsTableCell &&
          selectedCellsCount === 0 && (
            <li>
              <button onClick={() => _addNavigation()}>
                {intl.formatMessage({
                  id: 'section-content.context-menu.add-navigation',
                })}
              </button>
            </li>
          )}
      </ul>
    )
  );
};

ContextMenu.propTypes = {
  /** indicates if the menu is visible or not */
  visible: PropTypes.bool.isRequired,
  /** the coordinates where the menu should be displayed on */
  position: PropTypes.PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  /** calling this function hides the menu */
  hideContextMenu: PropTypes.func.isRequired,
  /** reference to the parent node */
  centerPanelRef: PropTypes.object.isRequired,
  /** revision info for current document */
  revision: PropTypes.object.isRequired,
  /** current section id */
  sectionId: PropTypes.number.isRequired,
  /** redux action to retrieve section details  */
  fetchAllSectionRenderingData: PropTypes.func.isRequired,
  /** redux action to retrieve statement summary elements  */
  fetchStatementSummaryElements: PropTypes.func.isRequired,
  /** object containing the original window.selection() */
  selection: PropTypes.object.isRequired,
  /** redux action to initialize and open notes panel when creating a note */
  initCreateNoteFromSelection: PropTypes.func.isRequired,
  /** redux action to initialize and open element panel */
  initElementPanel: PropTypes.func.isRequired,
  toggleAddNavigationModal: PropTypes.func.isRequired,
  /** value from store indicating current zoom ratio  */
  zoomRatio: PropTypes.number.isRequired,
  /** boolean value that determines if the socket connection has failed  */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  data: { revision },
  ui: { statementPage },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  revision,
  zoomRatio: statementPage.zoom.zoomRatio,
  socketHasBeenDisconnected,
});

const mapDispatchToProps = {
  initElementPanel,
  initCreateNoteFromSelection,
  fetchAllSectionRenderingData,
  fetchStatementSummaryElements,
  setSelectedNavigatorTab: setSelectedTabAction,
  setTOCExpandAll,
  selectElementCreated,
  sectionHTMLSegmentsStatementContentLoading,
  sectionHTMLSegmentsStatementContentLoadedWithoutResponse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ContextMenu));
