import React from 'react';
import PropTypes from 'prop-types';
import {
  getElementStatusIcon,
  getElementFlaggedIcon,
} from 'constants/feature/element-panel-constants';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import AmountAttributesDetails from 'components/common/element-amount-attributes-details-component';
import ElementDetails from 'models/api/element-details-api-model';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import { ANNOTATION_DISPLAY_FUNCTIONS } from 'constants/feature/tieout-element-constants';
import classnames from 'classnames';
import { ReactComponent as Clear } from 'icons/cross.svg';
import { connect } from 'react-redux';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import IconButton from './icon-button-component';
import { isNullOrUndefined } from 'utils/object-utils';
import { COMFORT_ASSIGN_ELEMENT } from 'components/feature/statement-content-panel/tieout-element-component';
import FeatureFlagComponent from './feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';
import Tooltip from 'components/common/tool-tip-component';
import { LEFT_PANELS } from 'constants/feature/panel-constants';

export const ELEMENTS_DETAILS_BLOCK = 'element-details';
export const ELEMENTS_DETAILS_ID_BLOCK = 'element-details-id';
const CLEAR_ICON_SIZE = '12';

const CLEAR_ICON_TOOLTIP = new TooltipOptions({
  text: 'common.remove',
  id: 'element-details-icon-button-tool-tip',
  position: 'left',
});

const ElementDetailsComponent = ({
  element,
  contentSectionMap,
  iconSize,
  Icon,
  enableAction,
  onClickIcon,
  tooltip,
  disabled,
  checkIfFeatureFlagEnabled,
}) => {
  //if icon, tooltip and iconSize are being received as props it uses its value
  Icon = Icon ? Icon : Clear;
  iconSize = iconSize ? iconSize : CLEAR_ICON_SIZE;
  const elementBookmark = isNullOrUndefined(
    contentSectionMap.getClosestBookmarkSection({
      sectionId: element.sectionId,
    }),
  )
    ? element.sectionname
    : contentSectionMap.getClosestBookmarkSection({
        sectionId: element.sectionId,
      }).name;
  tooltip = tooltip ? tooltip : CLEAR_ICON_TOOLTIP;
  return (
    <div
      className={ELEMENTS_DETAILS_BLOCK}
      id={`${ELEMENTS_DETAILS_ID_BLOCK}-${element.id}`}
    >
      <div
        className={`${ELEMENTS_DETAILS_BLOCK}__element-details-wrapper`}
        onClick={() => {
          scrollElementIntoView({
            elementId: element.id,
            sectionId: element.sectionId,
          });
        }}
      >
        <span
          className={`${ELEMENTS_DETAILS_BLOCK}__section-name`}
          title={elementBookmark}
        >
          {elementBookmark}
        </span>

        <span
          className={`${ELEMENTS_DETAILS_BLOCK}__label`}
          title={element.label}
        >
          {element.label}
        </span>
        <div className={`${ELEMENTS_DETAILS_BLOCK}__element-value-wrapper`}>
          <button
            id={`${ELEMENTS_DETAILS_ID_BLOCK}-element-value`}
            className={classnames(`${ELEMENTS_DETAILS_BLOCK}__element-value`)}
          >
            {getElementFlaggedIcon({ elementDetails: element })}
            <span
              className={classnames(
                element &&
                  element.isComfortAssign() &&
                  checkIfFeatureFlagEnabled({
                    featureName: FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE,
                  }) &&
                  COMFORT_ASSIGN_ELEMENT,
              )}
            >
              {
                // IF amount is 0 or any other falsy value then it indicates that element display value is not a number.
                // For example display value not a number might look like, display value is a hyphen (-) or any word
                element.amount
                  ? typeof element.display === 'string' &&
                    element.display.match(/[()]/)
                    ? `(${element.display.split(/[()]/).join('')})`
                    : `${element.display}`
                  : element.display
              }
            </span>
          </button>
          <Tooltip
            id={`annotations-tooltip-${element.id}`}
            isNotInternationalized
            text={
              <div>
                <FeatureFlagComponent
                  name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}
                >
                  {ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
                    elementDetails: element,
                  })}
                </FeatureFlagComponent>
                {ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
                  elementDetails: element,
                })}
                {ANNOTATION_DISPLAY_FUNCTIONS.formula({
                  elementDetails: element,
                })}
                {ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
                  elementDetails: element,
                })}
                {ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
                  elementDetails: element,
                })}
              </div>
            }
          >
            <div className={`${ELEMENTS_DETAILS_BLOCK}__annotations`}>
              <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
                {ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
                  elementDetails: element,
                })}
              </FeatureFlagComponent>
              {ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
                elementDetails: element,
              })}
              {ANNOTATION_DISPLAY_FUNCTIONS.formula({
                elementDetails: element,
              })}
              {ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
                elementDetails: element,
              })}
              {ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
                elementDetails: element,
                currLeftPanel: LEFT_PANELS.WORKPAPER,
              })}
            </div>
          </Tooltip>
          {getElementStatusIcon({ elementDetails: element })}
        </div>
        <AmountAttributesDetails
          className={`${ELEMENTS_DETAILS_BLOCK}__element-attributes`}
          elementDetails={element}
        />
      </div>
      {enableAction && (
        <div>
          <IconButton
            id={`${ELEMENTS_DETAILS_ID_BLOCK}-icon-${element.id}`}
            tooltip={tooltip}
            className={!disabled ? `${ELEMENTS_DETAILS_BLOCK}__clear-icon` : ''}
            onClick={onClickIcon}
            Icon={Icon}
            iconSize={iconSize}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

ElementDetailsComponent.propTypes = {
  /** the api model containing search results */
  element: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** size of icon if any */
  iconSize: PropTypes.number,
  /** Icon if any is provided eg. delete icon, detach icon etc default = cross icon */
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  /** Bool for indicating if we want to use the Icon prop and action */
  enableAction: PropTypes.bool,
  /** action for what happens when icon is clicked */
  onClickIcon: PropTypes.func,
  /** Tooltip for icon */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** boolean to determine whether to disable icon */
  disabled: PropTypes.bool,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    statementContent: {
      sectionsCache: { contentSectionMap },
    },
  },
}) => ({ contentSectionMap });

const mapDispatchToProps = {
  checkIfFeatureFlagEnabled,
};

export { ElementDetailsComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementDetailsComponent);
