import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FormItem from 'components/common/form-item-component';
import classNames from 'classnames';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import Toggle from 'react-toggle';

const slide_toggle_block = 'slide-toggle';

const SlideToggle = ({
  children,
  className,
  disabled,
  errorText,
  id,
  checked,
  isValid,
  label,
  name,
  onChange,
  tooltip,
  width,
}) => (
  <FormItem
    className={className}
    disabled={disabled}
    errorText={errorText}
    id={id}
    isValid={isValid}
    label={label}
    tooltip={tooltip}
    width={width}
  >
    <Toggle
      checked={checked}
      className={classNames(slide_toggle_block)}
      disabled={disabled}
      icons={false}
      id={id}
      name={name}
      onChange={onChange}
    />
  </FormItem>
);

SlideToggle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.instanceOf(TooltipOptions),
  width: PropTypes.string,
};

export default memo(SlideToggle);
