import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as ArrowDown } from 'icons/arrow-simple-thick-down.svg';
import {
  MAXIMIZE_TOOLTIP,
  MINIMIZE_TOOLTIP,
} from 'constants/feature/statement-processing-constants';
import { FormattedMessage } from 'react-intl';

const EXPANDABLE_ROW_BLOCK = 'expandable-row';
const EXPANDABLE_ROW_ID_BLOCK = 'element-attribute-id';
const ARROW_DOWN_SIZE = '24px';

class ExpandableRow extends Component {
  _expandedExpandableRow = () => {
    const { children } = this.props;
    return (
      <div className={`${EXPANDABLE_ROW_BLOCK}__body`}>
        <div>{children}</div>
      </div>
    );
  };

  render() {
    const {
      id,
      tooltipId,
      title,
      expandExpandableRow,
      minimizeExpandableRow,
      showExpandableRow,
    } = this.props;
    
    const minimizeId = id ? `${EXPANDABLE_ROW_ID_BLOCK}-tooltip-minimize-${id}` : `${EXPANDABLE_ROW_ID_BLOCK}-tooltip-minimize`;
    const maximizeId = id ? `${EXPANDABLE_ROW_ID_BLOCK}-tooltip-maximize-${id}` : `${EXPANDABLE_ROW_ID_BLOCK}-tooltip-maximize`;
    return (
      <div className={`${EXPANDABLE_ROW_BLOCK}`}>
        <div className={`${EXPANDABLE_ROW_BLOCK}__header`}>
          <div className={`${EXPANDABLE_ROW_BLOCK}__title`}>
            <FormattedMessage
              id={title.id ? title.id : title}
              values={title.values ? title.values : {}}
            />
          </div>
          {showExpandableRow ? (
            <Tooltip {...MINIMIZE_TOOLTIP} id={tooltipId}>
              <ArrowDown
                id={minimizeId}
                role={'button'}
                className={`${EXPANDABLE_ROW_BLOCK}__toggle`}
                onClick={() => minimizeExpandableRow()}
                width={ARROW_DOWN_SIZE}
                height={ARROW_DOWN_SIZE}
                style={{ transform: 'rotate(180deg)' }}
              />
            </Tooltip>
          ) : (
            <Tooltip {...MAXIMIZE_TOOLTIP} id={tooltipId}>
              <ArrowDown
                id={maximizeId}
                role={'button'}
                className={`${EXPANDABLE_ROW_BLOCK}__toggle`}
                onClick={() => expandExpandableRow()}
                width={ARROW_DOWN_SIZE}
                height={ARROW_DOWN_SIZE}
              />
            </Tooltip>
          )}
        </div>
        {showExpandableRow ? this._expandedExpandableRow() : null}
      </div>
    );
  }
}

ExpandableRow.propTypes = {
  /** Title of expandable row */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.object,
    }),
  ]),

  /** Function for expanding the expandableRow Component */
  expandExpandableRow: PropTypes.func.isRequired,
  /** Function for hiding the expandableRow Component */
  minimizeExpandableRow: PropTypes.func.isRequired,
  /** Boolean set to false by default and toggles the expandableRow */
  showExpandableRow: PropTypes.bool.isRequired,
  /** Unique id for tooltip */
  tooltipId: PropTypes.string.isRequired,
};

export default ExpandableRow;
