import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import FormItem from 'components/common/form-item-component';
import Menu, {
  ApiMenu,
  TableApiMenu,
  ApiMenuWithCreate,
} from 'components/common/menu-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

import { DrawerActionHandler } from 'components/util/drawer-action-handler-component';

import { ReactComponent as ClearButton } from 'icons/close-button.svg';
import { ReactComponent as SearchButton } from 'icons/search.svg';
import MenuApiData from 'models/utils/common/menu/menu-api-data-model';
import {
  DataGridDataApi,
  DataGridColumn,
} from 'components/common/data-grid/data-grid-component';
import {
  ENTER_KEY_CODE,
  SPACEBAR_KEY_CODE,
} from 'constants/util/key-code-constants';
import { isSearchCharLengthValid } from 'constants/util/search-utility';
import { isNullOrUndefined } from 'utils/object-utils';

const SEARCH_BLOCK = 'search';
const SEARCH_CLOSE_ICON_SIZE = '28px';
const SEARCH_ICON_SIZE = '20px';

const DefaultSearch = ({
  className,
  disabled,
  errorText,
  id,
  intl,
  isOpen,
  isValid,
  label,
  maxLength,
  onBlur,
  onKeyDown,
  onChange,
  onSearch,
  onClear,
  onFocus,
  placeholder,
  setWrapperRef,
  showSearchButton,
  toggleDrawer,
  tooltip,
  value,
  width,
  children, // NOTE: do not use externally, only used internally by SearchDropdown and SearchDropdownApi components
  disableAutoComplete,
}) => {
  const searchInputRef = useRef();
  const _onSearch = () => onSearch(value);
  return (
    <FormItem
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      isValid={isValid}
      label={label}
      tooltip={tooltip}
      width={width}
    >
      <div
        ref={setWrapperRef}
        className={`${SEARCH_BLOCK}__container`}
        disabled={disabled}
      >
        <div
          onKeyDown={(e) => {
            if (!isNullOrUndefined(onSearch) && e.key === 'Enter') {
              _onSearch();
            }
            onKeyDown(e);
          }}
          className={`${SEARCH_BLOCK}__bar`}
        >
          {showSearchButton ? (
            <div
              className={classNames(`${SEARCH_BLOCK}__search-button`)}
              disabled={disabled}
            >
              <SearchButton
                name={'search-button'}
                role={'button'}
                className={`${SEARCH_BLOCK}__search-icon`}
                width={SEARCH_ICON_SIZE}
                height={SEARCH_ICON_SIZE}
                onClick={() => {
                  if (!isNullOrUndefined(onSearch)) {
                    _onSearch();
                  }
                  searchInputRef.current.focus();
                }}
              />
            </div>
          ) : null}
          <div
            className={classNames(
              SEARCH_BLOCK,
              !isValid ? `${SEARCH_BLOCK}--invalid` : null,
            )}
            disabled={disabled}
          >
            <input
              className={`${SEARCH_BLOCK}__input`}
              disabled={disabled}
              id={id}
              label={label}
              maxLength={maxLength}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onKeyDown={(e) =>
                e.keyCode === ENTER_KEY_CODE ? onChange(value) : null
              }
              onBlur={onBlur}
              placeholder={intl.formatMessage({ id: placeholder })}
              type={'search'}
              value={value}
              width={width}
              onFocus={onFocus}
              tabIndex={0}
              ref={searchInputRef}
              autoComplete={disableAutoComplete && 'off'}
            />
            {value ? (
              <ClearButton
                name={'close-button'}
                role={'button'}
                className={`${SEARCH_BLOCK}__close`}
                width={SEARCH_CLOSE_ICON_SIZE}
                height={SEARCH_CLOSE_ICON_SIZE}
                onClick={() => {
                  if (!disabled) {
                    onClear();
                  }
                }}
                tabIndex={0}
                onKeyDown={(e) =>
                  e.keyCode === SPACEBAR_KEY_CODE ? onClear() : null
                }
                disabled={disabled}
              />
            ) : null}
          </div>
        </div>
        {children}
      </div>
    </FormItem>
  );
};

const sharedPropTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** Unique string id for search */
  id: PropTypes.string.isRequired,
  /** HOC Boolean representing whether search menu is open */
  isOpen: PropTypes.bool.isRequired,
  /** Internationalized Sting id passed to form item */
  label: PropTypes.string,
  /** Maximum search query length */
  maxLength: PropTypes.number,
  /** Function fired on blur*/
  onBlur: PropTypes.func,
  /** Function fired on user input into search, used to track search state variable
   * onChange should be doing some kind of this.setState() or similar redux actions to control
   * the value prop. However, if an API request is necessary as well it should be debounced and passed as
   * the 2nd argument to that set state. Which means it would look something like below:
   * onChange={val => {
      this.setState({ search: val }, () =>
        this.debouncedSearch({ search: this.state.search }),
      );
    }}
  */
  onChange: PropTypes.func.isRequired,
  /** Optional function fired when either the search button is clicked or enter key is pressed while search has focus */
  onSearch: PropTypes.func,
  /** HOC function fired for key presses, needed to make dropdown accessible */
  onKeyDown: PropTypes.func.isRequired,
  /** HOC Function fired on focus */
  onFocus: PropTypes.func.isRequired,
  /** Internationalized Sting id passed to form item */
  placeholder: PropTypes.string,
  /** Boolean representing whether search button is shown */
  showSearchButton: PropTypes.bool,
  /** Function fired to toggle search menu */
  toggleDrawer: PropTypes.func.isRequired,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** String value of the search bar */
  value: PropTypes.string,
  /** String percentage representing width of component passed to form item*/
  width: PropTypes.string,
  /** indicates if menu should use a react portal or normal child element implementation */
  usePortalMenu: PropTypes.bool,
};

DefaultSearch.propTypes = {
  ...sharedPropTypes,
  /** (Should not be used externally! Use SearchApi component instead) Boolean flag passed by SearchApi component below to use the ApiMenu */
  useApiMenu: PropTypes.bool,
};

const DefaultSearchDropdown = ({
  className,
  menuClassName,
  disabled,
  errorText,
  id,
  intl,
  isOpen,
  isValid,
  label,
  maxLength,
  onBlur,
  onChange,
  onSearch,
  onClear,
  onFocus,
  onKeyDown,
  onSelectResult,
  placeholder,
  results,
  setWrapperRef,
  wrapperRef,
  showSearchButton,
  toggleDrawer,
  tooltip,
  value,
  width,
  usePortalMenu,
}) => {
  return (
    <DefaultSearch
      className={className}
      disabled={disabled}
      onSearch={onSearch}
      errorText={errorText}
      id={id}
      intl={intl}
      isOpen={isOpen}
      isValid={isValid}
      label={label}
      maxLength={maxLength}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onClear={onClear}
      onFocus={onFocus}
      placeholder={placeholder}
      setWrapperRef={setWrapperRef}
      showSearchButton={showSearchButton}
      toggleDrawer={toggleDrawer}
      tooltip={tooltip}
      value={value}
      width={width}
    >
      {isOpen ? (
        <Menu
          options={results}
          onSelectOption={onSelectResult}
          toggleDrawer={toggleDrawer}
          className={menuClassName}
          wrapperRef={wrapperRef}
          usePortal={usePortalMenu}
        />
      ) : null}
    </DefaultSearch>
  );
};

DefaultSearchDropdown.propTypes = {
  ...sharedPropTypes,
  /** class passed directly to the menu component */
  menuClassName: PropTypes.string,
  /** Func fired on menu item selection */
  onSelectResult: PropTypes.func.isRequired,
  /** Results of search query either array for simple front end searches, or an api model for backend, use SearchApi component below for api searches */
  results: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(MenuApiData),
  ]),
  /** HOC prop. Reference to this element, passed directly to the menu to help position it, since it is a portal */
  wrapperRef: PropTypes.instanceOf(Element),
};

const DefaultSearchDropdownApi = ({
  className,
  menuClassName,
  disabled,
  errorText,
  id,
  intl,
  isOpen,
  isValid,
  label,
  maxLength,
  onBlur,
  onChange,
  onSearch,
  onClear,
  onKeyDown,
  onFocus,
  onSelectResult,
  placeholder,
  resultsApiModel,
  setWrapperRef,
  wrapperRef,
  showSearchButton,
  toggleDrawer,
  tooltip,
  value,
  width,
  usePortalMenu,
  isCreate,
  onCreateClick,
  minSearchCharLength,
  numberOfVisibleRows,
  disableAutoComplete,
}) => {
  // only open if focused and user has typed at least the acceptable number of characters
  const _shouldOpenMenu =
    isOpen && isSearchCharLengthValid(value, minSearchCharLength);
  return (
    <DefaultSearch
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      intl={intl}
      isOpen={isOpen}
      isValid={isValid}
      label={label}
      maxLength={maxLength}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onSearch={onSearch}
      onClear={onClear}
      onFocus={(props) => {
        const _shouldSearchOnFocus = isSearchCharLengthValid(
          value,
          minSearchCharLength,
        );
        if (_shouldSearchOnFocus) {
          onChange(value);
        }
        onFocus(props);
      }}
      placeholder={placeholder}
      results={resultsApiModel} // different from Search
      setWrapperRef={setWrapperRef}
      showSearchButton={showSearchButton}
      toggleDrawer={toggleDrawer}
      tooltip={tooltip}
      value={value}
      width={width}
      disableAutoComplete={isCreate || disableAutoComplete}
    >
      {_shouldOpenMenu ? (
        isCreate ? (
          <ApiMenuWithCreate
            dataModel={resultsApiModel}
            onSelectOption={onSelectResult}
            toggleDrawer={toggleDrawer}
            className={menuClassName}
            wrapperRef={wrapperRef}
            usePortal={usePortalMenu}
            onCreateClick={onCreateClick}
            visibleRowsForScroll={numberOfVisibleRows}
            NoResultsComponent={() => (
              <div>
                <FormattedMessage
                  id="common.search.no-results"
                  values={{ searchValue: value }}
                />
              </div>
            )}
          />
        ) : (
          <ApiMenu
            dataModel={resultsApiModel}
            onSelectOption={onSelectResult}
            toggleDrawer={toggleDrawer}
            className={menuClassName}
            wrapperRef={wrapperRef}
            usePortal={usePortalMenu}
            NoResultsComponent={() => (
              <div>
                <FormattedMessage
                  id="common.search.no-results"
                  values={{ searchValue: value }}
                />
              </div>
            )}
          />
        )
      ) : null}
    </DefaultSearch>
  );
};
DefaultSearchDropdownApi.propTypes = {
  ...sharedPropTypes,
  /** class passed directly to the menu component */
  menuClassName: PropTypes.string,
  /** Func fired on menu item selection */
  onSelectResult: PropTypes.func.isRequired,
  /** MenuApiData model with an apiModal property to handle loading when fetching data */
  resultsApiModel: PropTypes.instanceOf(MenuApiData).isRequired,
  /** HOC prop. Reference to this element, passed directly to the menu to help position it, since it is a portal */
  wrapperRef: PropTypes.instanceOf(Element),
  /** Boolean passed in to enable the create button or disable it */
  isCreate: PropTypes.bool,
  /** REQUIRED if isCreate is set, Function that fires when the create button is clicked */
  onCreateClick: PropTypes.func,
  /**  Min char for search with create button is set to zero */
  minSearchCharLength: PropTypes.number.isRequired,
  /** Min number of rows to display for scroll */
  numberOfVisibleRows: PropTypes.number,
  /** Boolean for setting autocomplete on the input field */
  disableAutoComplete: PropTypes.bool,
};

const DefaultSearchTableDropdownApi = ({
  columns,
  numberOfVisibleRows,
  className,
  menuClassName,
  disabled,
  errorText,
  id,
  intl,
  isOpen,
  isValid,
  label,
  maxLength,
  onBlur,
  onChange,
  onSearch,
  onClear,
  onKeyDown,
  onFocus,
  onSelectResult,
  placeholder,
  resultsApiModel,
  setWrapperRef,
  wrapperRef,
  showSearchButton,
  isOptionSelected,
  toggleDrawer,
  tooltip,
  value,
  width,
  usePortalMenu,
  shouldDisableMenuOption,
}) => {
  // only open if focused and user has typed at least the acceptable number of characters
  const _shouldOpenMenu = isOpen && isSearchCharLengthValid(value);
  return (
    <DefaultSearch
      className={className}
      disabled={disabled}
      errorText={errorText}
      id={id}
      intl={intl}
      isOpen={isOpen}
      isValid={isValid}
      label={label}
      maxLength={maxLength}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onSearch={onSearch}
      onChange={onChange}
      onClear={onClear}
      onFocus={(props) => {
        const _shouldSearchOnFocus = isSearchCharLengthValid(value);
        if (_shouldSearchOnFocus) {
          onChange(value);
        }
        onFocus(props);
      }}
      placeholder={placeholder}
      results={resultsApiModel} // different from Search
      setWrapperRef={setWrapperRef}
      showSearchButton={showSearchButton}
      toggleDrawer={toggleDrawer}
      tooltip={tooltip}
      value={value}
      width={width}
    >
      {_shouldOpenMenu ? (
        <TableApiMenu
          dataModel={resultsApiModel}
          className={menuClassName}
          numberOfVisibleRows={numberOfVisibleRows}
          columns={columns}
          tableId={`${id}-results-table`}
          onSelectOption={onSelectResult}
          toggleDrawer={toggleDrawer}
          wrapperRef={wrapperRef}
          isOptionSelected={isOptionSelected}
          shouldDisableMenuOption={shouldDisableMenuOption}
          NoResultsComponent={
            <div>
              <FormattedMessage
                id="common.search.no-results"
                values={{ searchValue: value }}
              />
            </div>
          }
          usePortal={usePortalMenu}
        />
      ) : null}
    </DefaultSearch>
  );
};
DefaultSearchTableDropdownApi.propTypes = {
  ...sharedPropTypes,
  /** class passed directly to the menu component */
  menuClassName: PropTypes.string,
  /** An array of column objects, if you want special columns like Kebab or Checkbox, specify them in this prop */
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DataGridColumn)).isRequired,
  /* Number of visual data rows, controls scrolling */
  numberOfVisibleRows: PropTypes.number,
  /** Func fired on menu item selection */
  onSelectResult: PropTypes.func.isRequired,
  /** MenuApiData model with an apiModal property to handle loading when fetching data */
  resultsApiModel: PropTypes.instanceOf(DataGridDataApi).isRequired,
  /** HOC prop. Reference to this element, passed directly to the menu to help position it, since it is a portal */
  wrapperRef: PropTypes.instanceOf(Element),
  /** Function to determine if a specific option is already selected, func should look like (rowData) => someFunction(rowData)
   * And evaluate to a boolean
   */
  isOptionSelected: PropTypes.func,
  /** Function that determines if a particular row should be disabled based on if the user has already been selected */
  shouldDisableMenuOption: PropTypes.func,
};

const SearchTableDropdownApi = memo(
  DrawerActionHandler(injectIntl(DefaultSearchTableDropdownApi), {
    itemQuerySelector: '.menu .data-grid__body .data-grid__row',
    textInputDrawer: true,
  }),
);
const SearchDropdownApi = memo(
  DrawerActionHandler(injectIntl(DefaultSearchDropdownApi), {
    textInputDrawer: true,
  }),
);
const SearchDropdown = memo(
  DrawerActionHandler(injectIntl(DefaultSearchDropdown), {
    textInputDrawer: true,
  }),
);
export default memo(
  DrawerActionHandler(injectIntl(DefaultSearch), {
    textInputDrawer: true,
  }),
);
export { SearchDropdownApi, SearchDropdown, SearchTableDropdownApi };
