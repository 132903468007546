import BaseModel from 'models/base-model';

export default class StatementSummaryDisplayOptions extends BaseModel({
  verified: true,
  reviewed: true,
  signedOff: true,
  notes: true,
  flags: true,
  percentage: true,
  count: false,
}) {
  setDisplayOption(key) {
    return this.merge({
      [key]: !this[key],
    });
  }
}
