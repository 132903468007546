import BaseModel from 'models/base-model';

export default class CopyFormulaResult extends BaseModel({
  //Structure of Object
  elements: [
    // {
    //   elementId: null,
    //   copiedFormulaId: null,
    //   copyFormulaStatus: null,
    //   amount: null,
    // }
  ],
}) {
  addFormulaResult(response) {
    let newElement = {
      elementId: response.elementId,
      copiedFormulaId: response.copiedFormulaId,
      copyFormulaStatus: response.copyFormulaStatus,
      amount: response.amount,
    };
    return new CopyFormulaResult({
      elements: [...this.elements, newElement],
    });
  }
}
