import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const createRevisionSectionAssignment = ({
  revisionId,
  sectionIds,
  userIds,
}) => {
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/section/assignments/batch`,
    {
      revisionId,
      sectionIds,
      userIds,
    },
  );
};

export const unassignRevisionSectionAssignment = ({
  revisionId,
  sectionIds,
  userIds,
}) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/section/unassign/all`,
    {
      revisionId,
      sectionIds,
      userIds,
    },
  );
