import { CONTEXT_KEY } from 'api/api-client';
import { getRevisionsSectionsRequest } from 'api/sections-api';
import { createAction } from 'redux-actions';

export const leftSectionTreeListLoading = createAction(
  'LEFT_STATEMENT_SECTION_TREE_LIST_LOADING',
);
export const leftSectionTreeListError = createAction(
  'LEFT_STATEMENT_SECTION_TREE_LIST_ERROR',
);
export const leftSectionTreeListLoaded = createAction(
  'LEFT_STATEMENT_SECTION_TREE_LIST_LOADED',
);
export const setCreateleftSectionAction = createAction(
  'LEFT_STATEMENT_SET_CREATE_SECTION_ACTION',
);
export const addNewleftSectionAction = createAction(
  'ADD_NEW_LEFT_STATEMENT_SECTION_ACTION',
);
export const removeNewleftSectionAction = createAction(
  'LEFT_STATEMENT_REMOVE_NEW_SECTION_ACTION',
);
export const clearleftSectionTreeListAction = createAction(
  'LEFT_STATEMENT_CLEAR_SECTION_TREE_LIST_ACTION',
);
export const setNewleftSectionIdAction = createAction(
  'LEFT_STATEMENT_SET_NEW_SECTION_ID_ACTION',
);

export const fetchLeftSectionTreeAction =
  ({ statementId, revisionId, projectId }) =>
  async (dispatch, getStore) => {
    try {
      const response = await getRevisionsSectionsRequest({
        statementId,
        revisionId,
        onlyBookmarkSection: true,
        asTreeList: true,
        contextKeyForApiCall: CONTEXT_KEY.PROJECT_CONTEXT,
        // project id to make api call in desired project geo
        contextValue: projectId,
      });
      dispatch(leftSectionTreeListLoaded(response));
    } catch (error) {
      dispatch(leftSectionTreeListError(error));
    }
  };
