import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormattedMessage } from 'react-intl';

const AMOUNT_ATTRIBUTES_DETAILS_BLOCK = 'amount-attribute-details';
const AmountAttributesDetails = ({ elementDetails, className, flagMap }) => {
  if (elementDetails.isLoaded === false) {
    return null;
  }

  return (
    <div className={classnames(AMOUNT_ATTRIBUTES_DETAILS_BLOCK, className)}>
      <span
        className={classnames(
          `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item`,
          flagMap && !flagMap.scaling
            ? `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item-flagged`
            : null,
        )}
      >
        <FormattedMessage id={elementDetails.scaling.title} />
      </span>
      <span
        className={classnames(
          `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item`,
          flagMap && !flagMap.units
            ? `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item-flagged`
            : null,
        )}
      >
        <FormattedMessage id={elementDetails.units.title} />
      </span>
      <span
        className={classnames(
          `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item`,
          flagMap && !flagMap.period
            ? `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item-flagged`
            : null,
        )}
      >
        {elementDetails.period}
      </span>
      <span
        className={classnames(
          `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item`,
          `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__entity`,
          flagMap && !flagMap.entity
            ? `${AMOUNT_ATTRIBUTES_DETAILS_BLOCK}__item-flagged`
            : null,
        )}
        title={elementDetails.entity}
      >
        {elementDetails.entity}
      </span>
    </div>
  );
};

AmountAttributesDetails.propTypes = {
  /** Selected elements details object */
  elementDetails: PropTypes.oneOfType([
    PropTypes.instanceOf(ElementDetails),
    PropTypes.object,
  ]).isRequired,
};

export default AmountAttributesDetails;
