import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import { FormattedMessage } from 'react-intl';
import InternalReference from 'models/api/internal-reference-model';
import InternalReferenceElementEntry from 'components/feature/element-panel/annotations/_internal-reference-element-entry-component';
import Permissions from 'permissions/permissions';
import ConditionalRender from 'components/util/conditional-render-component';
import { fetchSuggestedListed } from 'store/actions/internal-reference-suggestion-list-actions';
import { getInternalReferenceWithoutLoading } from 'store/actions/internal-reference-actions';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as PlusCircle } from 'icons/plus-circle-blue.svg';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import CopyAnnotationButton from '../internal-reference-panel/_copy-annotation-modal-controller';
import { updateWorkpaper } from 'store/actions/workpaper-actions';
import { attachTickmarkToElement } from 'store/actions/element-panel-actions';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';

export const ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK =
  'element-internal-reference-list';
const ELEMENT_INTERNAL_REFERENCE_LIST_ID_BLOCK =
  'element-internal-reference-list-id';
export const DEFAULT_HOVERED_FORMULA_INDEX = null;

class ElementInternalReferenceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
    };
  }

  componentDidMount() {
    const {
      fetchSuggestedListed,
      getInternalReferenceWithoutLoading,
      elementDetails,
    } = this.props;
    fetchSuggestedListed({
      revisionId: elementDetails.revisionId,
      elementId: elementDetails.id,
    });
    getInternalReferenceWithoutLoading();
  }

  setSelected = (selected) => {
    this.setState({
      selectedRow: selected,
    });
  };
  getInternalReferenceElementList = () => {
    const {
      elementDetails,
      internalReference,
      selectElementFromInternalReference,
      detachElementFromInternalReference,
      selectedProjectId,
      readOnly,
      checkIfFeatureFlagEnabled,
    } = this.props;

    const _permissionToEdit = Permissions.InternalReference.canEditInternalRef(
      selectedProjectId,
    );

    return internalReference
      .getInternalReferencesSortedById({ elementId: elementDetails.id })
      .map((element, iter) => {
        return (
          <div onClick={() => this.setSelected(iter)} key={shortid.generate()}>
            <InternalReferenceElementEntry
              elementDetails={element}
              internalReference={internalReference}
              selectElementAction={selectElementFromInternalReference}
              elementDetachAction={detachElementFromInternalReference}
              selectedElement={elementDetails}
              disabled={!_permissionToEdit || readOnly}
              highlighted={this.state.selectedRow === iter}
              checkIfFeatureFlagEnabled={checkIfFeatureFlagEnabled}
            />
          </div>
        );
      });
  };

  render() {
    const {
      onAddLink,
      internalReference,
      selectedProjectId,
      readOnly,
      internalReferenceSuggestionList,
    } = this.props;

    const _permissionToEdit = Permissions.InternalReference.canEditInternalRef(
      selectedProjectId,
    );

    const PLUS_ICON_SIZE = '24px';

    const IR_ATTACH_TOOLTIP = new TooltipOptions({
      text: 'element-panel.annotation.content.internal-references.link-ir',
      id: 'ir-attach-button-tool-tip',
      position: 'top',
    });

    return (
      <div className={ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}>
        <div
          className={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__button-container`}
        >
          {!readOnly && (
            <>
              <ConditionalRender
                dependencies={[internalReferenceSuggestionList]}
              >
                <div
                  className={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__suggested-count`}
                  onClick={_permissionToEdit && onAddLink}
                  disabled={!_permissionToEdit}
                >
                  <FormattedMessage
                    id={'internal-reference-panel.suggested-references.title'}
                    values={{
                      suggestedReferenceCount:
                        internalReferenceSuggestionList.count,
                    }}
                  />
                </div>
              </ConditionalRender>
              <IconButton
                id={`${ELEMENT_INTERNAL_REFERENCE_LIST_ID_BLOCK}-internal-reference-add-button`}
                className={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__internal-reference-add-button`}
                tooltip={IR_ATTACH_TOOLTIP}
                Icon={PlusCircle}
                iconSize={PLUS_ICON_SIZE}
                onClick={onAddLink}
                disabled={!_permissionToEdit}
              />
            </>
          )}
        </div>
        {/* // Show copy annotation button only when there are few wps, tickmarks to
        be copied*/}
        {internalReference.hasElements() && (
          <CopyAnnotationButton
            disabled={false}
            internalReference={this.props.internalReference}
            componentClassNameConstant={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__copy-all-annotation-class`}
          />
        )}
        <div
          className={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__listing-container`}
        >
          {internalReference.hasElements() ? (
            this.getInternalReferenceElementList()
          ) : (
            <div
              className={`${ELEMENT_INTERNAL_REFERENCE_LIST_BLOCK}__empty-text-container`}
            >
              <FormattedMessage
                id={'element-panel.annotation.content.no-internal-references'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ElementInternalReferenceList.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Internal Reference linked to the current element */
  internalReference: PropTypes.instanceOf(InternalReference).isRequired,
  /**The action that is fired to highlight elements from the content panel after we click on them in the internal reference */
  selectElementFromInternalReference: PropTypes.func.isRequired,
  /**The action that is fired when we wish to detach an element from an internal reference */
  detachElementFromInternalReference: PropTypes.func.isRequired,
  /** Action fired when user clicks the add link button */
  onAddLink: PropTypes.func.isRequired,
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
  /** Boolean for read only mode */
  readOnly: PropTypes.bool.isRequired,
  /** function to fetch the suggested list of internal references */
  fetchSuggestedListed: PropTypes.func.isRequired,
  // action method to check if feature flag is enabled for the feature with mentioned name
  checkIfFeatureFlagEnabled: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
    internalReferencePanel: { internalReferenceSuggestionList },
  },
}) => ({
  selectedProjectId: id,
  internalReferenceSuggestionList,
});

const mapDispatchToProps = {
  fetchSuggestedListed,
  updateWorkpaper,
  attachTickmarkToElement,
  getInternalReferenceWithoutLoading,
  checkIfFeatureFlagEnabled,
};

export { ElementInternalReferenceList };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementInternalReferenceList);
