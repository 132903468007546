import { withDefaultContextAsSelectedProject } from 'api/api-default-context';
import { isSignalRForSocketConnectionEnabled } from 'api/api-authentication';

//funtion which is related to Signalr socket connection to initiate signalr socket connection
export const initialStatementSocketClientUrl = (revisionId) =>
  `/tieout/revision/${revisionId}/signalr`;

//funtion which is related to Signalr socket connection to close signalr socket connection
export const closeSocketClientRequest = ({ revisionId, connectionId }) =>
  withDefaultContextAsSelectedProject().delete(
    `/tieout/api/close/revision/${revisionId}/connection/${connectionId}`,
  );

//funtion which is related to Signalr socket connection to fetch all active users of the application
export const getActiverUsers = ({ revisionId, projectUserIds }) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/revision/${revisionId}/signalr/activerusers`,
    projectUserIds,
  );
};

export const removeUserFromRoom = ({
  revisionId,
  projectUserIds,
  projectId,
}) => {
  if (isSignalRForSocketConnectionEnabled) {
    return removeUserFromSignalrRoom({ revisionId, projectUserIds });
  } else {
    return removeUserFromSocketIORoom({
      revisionId,
      projectUserIds,
      projectId: parseInt(projectId),
    });
  }
};

// removing user from socketio group within socketio socket connection
const removeUserFromSocketIORoom = ({
  revisionId,
  projectUserIds,
  projectId,
}) => {
  return withDefaultContextAsSelectedProject()
    .delete(
      `/tieout/project/${parseInt(
        projectId,
      )}/revision/${revisionId}/socketio/group/user`,
      {
        data: projectUserIds,
      },
    )
    .then((response) => response);
};

// removing user from signalr group within signalr socket connection
const removeUserFromSignalrRoom = ({ revisionId, projectUserIds }) => {
  return withDefaultContextAsSelectedProject()
    .delete(`/tieout/revision/${revisionId}/signalr/group/user`, {
      data: projectUserIds,
    })
    .then((response) => response);
};

export const addUserToGroup = ({ revisionId, projectUserIds, projectId }) => {
  if (isSignalRForSocketConnectionEnabled) {
    return addUserToSignalrGroup({ revisionId, projectUserIds });
  } else {
    return addUserToSocketIOGroup({
      revisionId,
      projectUserIds,
      projectId: parseInt(projectId),
    });
  }
};

// adding user to socketio group within socketio socket connection
const addUserToSocketIOGroup = ({ revisionId, projectUserIds, projectId }) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/project/${parseInt(
      projectId,
    )}/revision/${revisionId}/socketio/group/user`,
    projectUserIds,
  );
};

// remove user from signalr group within signalr socket connection
const addUserToSignalrGroup = ({ revisionId, projectUserIds }) => {
  return withDefaultContextAsSelectedProject().post(
    `/tieout/revision/${revisionId}/signalr/group/user`,
    projectUserIds,
  );
};
