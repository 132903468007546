import { createAction } from 'redux-actions';
import { getSelectedProjectUsersRequest } from 'api/project-list-api';

export const setProjectUsersListLoaded = createAction(
  'SET_PROJECT_USERS_LIST_LOADED',
);

export const setProjectUsersListLoading = createAction(
  'SET_PROJECT_USERS_LIST_LOADING',
);

export const setProjectUsersListError = createAction(
  'SET_PROJECT_USERS_LIST_ERROR',
);

export const getAllUsers = () => async (dispatch, getState) => {
  dispatch(setProjectUsersListLoading());
  const { project } = getState().data.selectedProject;
  try {
    const response = await getSelectedProjectUsersRequest(project.getUserIds());
    await dispatch(setProjectUsersListLoaded({ response }));
  } catch (error) {
    dispatch(setProjectUsersListError(error));
  }
};
