import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { Redirect } from 'react-router';

const UNAUTHORIZED_BLOCK = 'unauthorized';
const SUPPORT_REQUEST_ANCHOR = (
  <a
    href={ROUTE_CONSTANTS.SUPPORT_REQUEST}
    target={'_blank'}
    rel="noopener noreferrer"
  >
    <FormattedMessage id={'unauthorized.support-request-link'} />
  </a>
);

const Unauthorized = ({ currentUser }) => {
  if (currentUser && currentUser.isDisabled() === false) {
    return <Redirect to="/" />;
  }

  return (
    <div className={`${UNAUTHORIZED_BLOCK}`}>
      <div className={`${UNAUTHORIZED_BLOCK}__header`} />
      <FormattedMessage
        id="unauthorized.title"
        values={{
          link: SUPPORT_REQUEST_ANCHOR,
        }}
      />
    </div>
  );
};

export default memo(Unauthorized);
