import { withDefaultContextAsProject } from 'api/api-default-context';

export const softDeleteProjectRequest = (clientID) => {
  return withDefaultContextAsProject({}, clientID)
    .post(`/tieout/clients/${parseInt(clientID)}/delete`)
    .then((response) => {
      return response;
    });
};

export const restoreSoftDeletedProjectRequest = (clientID) => {
  return withDefaultContextAsProject({}, clientID)
    .post(`/tieout/clients/restore/${parseInt(clientID)}`)
    .then((response) => {
      return response;
    });
};

export const permanentlyDeleteProjectRequest = (clientID) => {
  return withDefaultContextAsProject({}, clientID)
    .post(`/tieout/clients/${parseInt(clientID)}/permanent-delete`)
    .then((response) => {
      return response;
    });
};
