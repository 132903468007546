import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttachTickmark from 'components/feature/element-panel/tickmark-panel/_element-tickmark-panel-attach-component';
import BatchElementUpdateForm from 'models/form/batch-element-update-form-model';
import ElementDetails from 'models/api/element-details-api-model';
import CreateTickmarkModal from 'components/feature/element-panel/tickmark-panel/create-tickmark-modal';
import TickmarkDetachEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-with-detach-component';
import {
  createTickmarkRequest,
  searchTickmarkByKeywords,
} from 'api/tickmark-api';
import {
  fetchTickmarkListForRevisionWithoutLoading,
  fetchElementsFromTickmark,
} from 'store/actions/tickmark-list-panel-actions';
import { ReactComponent as RemoveIcon } from 'icons/cross.svg';
import { TICKMARK_REMOVE_TOOLTIP } from 'constants/feature/batch-panel-constants';

export const BATCH_TICKMARK_BLOCK = 'batch-tickmark-section';

const BatchPanelTickmarks = ({
  updates,
  setUpdates,
  revision,
  fetchTickmarkListForRevision,
  socketHasBeenDisconnected,
}) => {
  const [showCreateTickmarkModal, setShowCreateTickmarkModal] = useState(false);

  const _toggleCreateTickmarkModal = useCallback(() => {
    setShowCreateTickmarkModal(!showCreateTickmarkModal);
  }, [showCreateTickmarkModal]);

  const [currentTickmarkId, setCurrentTickmarkId] = useState(undefined);
  const updateExpandedTickmarkID = (toggleStatus, id) => {
    !toggleStatus && setCurrentTickmarkId(id);
  };

  const _onAttachTickmark = useCallback(
    ({ tickmark }) => {
      setUpdates((u) => u.attachTickmark(tickmark));
      if (socketHasBeenDisconnected) {
        fetchElementsFromTickmark({
          revisionId: revision.id,
          tickmarkId: tickmark.tickmarkId,
        });
      }
    },
    [revision.id, setUpdates, socketHasBeenDisconnected],
  );

  const _onCreateTickmark = useCallback(
    ({ tickmarkModel }) =>
      createTickmarkRequest({ tickmark: tickmarkModel }).then(
        async (response) => {
          await fetchTickmarkListForRevision({ revisionId: revision.id });
          _onAttachTickmark({ tickmark: response.data.result });
          _toggleCreateTickmarkModal();
        },
      ),
    [
      _onAttachTickmark,
      _toggleCreateTickmarkModal,
      fetchTickmarkListForRevision,
      revision.id,
    ],
  );

  const _onDetachTickmark = useCallback(
    ({ tickmark }) => {
      setUpdates((u) => u.detachTickmark(tickmark));
      if (socketHasBeenDisconnected) {
        fetchElementsFromTickmark({
          revisionId: revision.id,
          tickmarkId: tickmark.tickmarkId,
        });
      }
    },
    [revision.id, setUpdates, socketHasBeenDisconnected],
  );

  const _onSearchTickmark = useCallback(
    ({ search }) =>
      searchTickmarkByKeywords({ search, revisionId: revision.id }),
    [revision.id],
  );

  return (
    <div className={BATCH_TICKMARK_BLOCK}>
      <AttachTickmark
        disabled={(t) => updates.hasTickmark(t)}
        hideTitle
        onAttach={_onAttachTickmark}
        onCreate={_toggleCreateTickmarkModal}
        onSearch={_onSearchTickmark}
      />
      {updates.tickmarks.map((tickmark) => (
        <TickmarkDetachEntry
          key={tickmark.tickmarkId}
          tickmark={tickmark}
          onDetach={_onDetachTickmark}
          disabled={false}
          detachIcon={RemoveIcon}
          tooltip={TICKMARK_REMOVE_TOOLTIP}
          currentlyExpandedTickmarkID={currentTickmarkId}
          updateExpandedTickmarkID={updateExpandedTickmarkID}
        />
      ))}
      {showCreateTickmarkModal && (
        <CreateTickmarkModal
          onClose={_toggleCreateTickmarkModal}
          onCreate={_onCreateTickmark}
          elementDetails={
            new ElementDetails({ data: { revisionId: revision.id } })
          }
        />
      )}
    </div>
  );
};

BatchPanelTickmarks.propTypes = {
  /** Current batch updates model */
  updates: PropTypes.instanceOf(BatchElementUpdateForm).isRequired,
  /** Function for updating current batch updates model */
  setUpdates: PropTypes.func.isRequired,
  /** Action to update the tickmark panel */
  fetchTickmarkListForRevision: PropTypes.func.isRequired,
  /* action to fetch elements from tickmark element list */
  fetchElementsFromTickmark: PropTypes.func.isRequired,
  /**To check if we have socket connection or we lost it */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  fetchTickmarkListForRevision: fetchTickmarkListForRevisionWithoutLoading,
  fetchElementsFromTickmark,
};

export { BatchPanelTickmarks };
export default connect(null, mapDispatchToProps)(BatchPanelTickmarks);
