import React from 'react';
import { DownloadCard } from './toolkit-downloads-card-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ToolkitDownloadListData from 'models/api/toolkit-export-download-list-model';
import {
  updateDownloadStatus,
  setReportIdError,
  setReportIdLoaded,
  setReportIdLoading,
  cancelReportWithLoading,
  updateFlagUserDownloadedReportStatus,
} from 'store/actions/toolkit-export-panel-actions';
import { getDownloadDataExceptFailed } from 'utils/statement-content-page-utils';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';
import ToolTip from 'components/common/tool-tip-component';
import { updateToolkitReportWithLoading } from 'store/actions/toolkit-export-panel-actions';
import { FormattedMessage } from 'react-intl';

export const TOOLKIT_DOWNLOAD_LIST = 'toolkit-download-list';
const TOOLKIT_DOWNLOAD_LIST_ID = 'toolkit-download-list-id';

export const ToolkitDownloadList = ({
  downloadProgressData,
  revisionId,
  setReportIdError,
  setReportIdLoaded,
  setReportIdLoading,
  updateDownloadStatus,
  cancelReportProcessing,
  refreshReportList,
  socketHasBeenDisconnected,
  updateFlagUserDownloadedReportStatus,
}) => {
  const REFRESH_LIST_ICON_SIZE = '28px';
  const [selectedId, setSelectedId] = React.useState('');
  const reportData = getDownloadDataExceptFailed(
    downloadProgressData.sortByTime(),
  );
  return (
    <>
      <span className={`${TOOLKIT_DOWNLOAD_LIST}__report-cleared-msg`}>
        <FormattedMessage id="toolkit.export-download.panel.report-cleared-after-hours-msg" />
      </span>
      {reportData && (
        <div className={`${TOOLKIT_DOWNLOAD_LIST}`}>
          {socketHasBeenDisconnected && (
            <div className={`${TOOLKIT_DOWNLOAD_LIST}__refresh-list-button`}>
              <ToolTip
                id={`${TOOLKIT_DOWNLOAD_LIST_ID}-tooltip-refresh-button`}
                text={'toolkit.export.panel.report-list.refresh-tooltip'}
                position={'bottom'}
              >
                <RefreshIcon
                  className={`${TOOLKIT_DOWNLOAD_LIST}-refresh-list-icon`}
                  onClick={() => {
                    refreshReportList();
                  }}
                  width={REFRESH_LIST_ICON_SIZE}
                  height={REFRESH_LIST_ICON_SIZE}
                />
              </ToolTip>
            </div>
          )}
          {reportData.map((cardData) => (
            <DownloadCard
              key={`${TOOLKIT_DOWNLOAD_LIST_ID}-${cardData.downloadId}`}
              downloadId={cardData.downloadId}
              name={cardData.name}
              dateTime={cardData.dateTime}
              downloadStatus={cardData.downloadStatus}
              userDownloadedReportStatus={cardData.userDownloadedReportStatus}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              downloadResponse={cardData.downloadResponse}
              storageGuid={cardData.storageGuid}
              revisionId={revisionId}
              updateDownloadStatus={updateDownloadStatus}
              isLoading={cardData.isLoading}
              isLoaded={cardData.isLoaded}
              error={cardData.error}
              setReportIdError={setReportIdError}
              setReportIdLoaded={setReportIdLoaded}
              setReportIdLoading={setReportIdLoading}
              cancelReportProcessing={cancelReportProcessing}
              updateFlagUserDownloadedReportStatus={
                updateFlagUserDownloadedReportStatus
              }
              reportType={cardData.reportType}
            />
          ))}
        </div>
      )}
    </>
  );
};

ToolkitDownloadList.propTypes = {
  // This will get list of download data to be rendered in download section of toolkit
  downloadProgressData: PropTypes.instanceOf(ToolkitDownloadListData)
    .isRequired,
  /**integer which represent revision number for statement */
  revisionId: PropTypes.number.isRequired,
  /**set error object for particular report*/
  setReportIdError: PropTypes.func.isRequired,
  /**set boolean to indicate report is loaded */
  setReportIdLoaded: PropTypes.func.isRequired,
  /**set boolean to represent report is loading */
  setReportIdLoading: PropTypes.func.isRequired,
  /**function to update report */
  updateDownloadStatus: PropTypes.func.isRequired,
  /**function fired to cancel report processing request */
  cancelReportProcessing: PropTypes.func.isRequired,
  /**function fired to refresh report list */
  refreshReportList: PropTypes.func.isRequired,
  /**Action fired to update flag to indicate if user was able to download report data */
  updateFlagUserDownloadedReportStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    revision: { id },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  revisionId: id,
  socketHasBeenDisconnected,
});

const mapDispatchToProps = {
  updateDownloadStatus,
  setReportIdError,
  setReportIdLoaded,
  setReportIdLoading,
  cancelReportProcessing: cancelReportWithLoading,
  refreshReportList: updateToolkitReportWithLoading,
  updateFlagUserDownloadedReportStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolkitDownloadList);
