import { LOCATION_CHANGE } from 'react-router-redux';

import { DIRECT_CALL_RULES } from 'constants/feature/adobe-analytics-constants';
import DirectCallRule from 'models/data/adobe-direct-call-rule-model';
import {
  isDataLayerReady,
  isSatelliteReady,
  appendDCRQueue,
  getDCRQueue,
  setDCRQueue,
  trackDCR,
  getRoutePageName,
} from 'utils/adobe-analytics-utils';
import { createAction } from 'redux-actions';

export const SET_ADOBE_NEW_PROJECT_DATA_ACTION = createAction(
  'adobe-track-new-project-view-action',
);

const adobeAnalyticsMiddleware = (store) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    const { pathname } = action.payload.location;
    const event = new DirectCallRule(DIRECT_CALL_RULES.NEW_PAGE_VIEW, {
      pageInfo: { pageName: getRoutePageName(pathname) },
    });
    appendDCRQueue(event);
  } else if (action.type === `${SET_ADOBE_NEW_PROJECT_DATA_ACTION}`) {
    const event = new DirectCallRule(DIRECT_CALL_RULES.NEW_PROJECT_VIEW);
    appendDCRQueue(event);
  }

  const queue = getDCRQueue();
  if (isDataLayerReady() && isSatelliteReady() && queue.length > 0) {
    queue.forEach((event) => trackDCR(event, false));
    setDCRQueue([]);
  }

  return next(action);
};

export default adobeAnalyticsMiddleware;
