import { handleActions } from 'redux-actions';
import {
  statementListLoading,
  statementListError,
  statementListLoaded,
  softDeleteCurrentRevisionError,
  softDeleteAllRevisionsError,
  permanentlyDeleteCurrentRevisionError,
  permanentlyDeleteAllRevisionsError,
  navigateToStatementError,
  clearStatementList,
} from 'store/actions/statement-list/statement-list-actions';
import { StatementList } from 'models/api/statement-list-model';

export default handleActions(
  {
    [clearStatementList]: (statementList) => {
      return new StatementList();
    },
    [statementListLoading]: (statementList) => {
      return statementList.setLoading();
    },
    [statementListError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
    [statementListLoaded]: (statementList, { payload }) => {
      return statementList.setLoaded(payload);
    },
    [softDeleteCurrentRevisionError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
    [softDeleteAllRevisionsError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
    [permanentlyDeleteCurrentRevisionError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
    [permanentlyDeleteAllRevisionsError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
    [navigateToStatementError]: (statementList, { payload }) => {
      return statementList.setError(payload);
    },
  },
  new StatementList()
);
