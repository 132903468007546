import { handleActions } from 'redux-actions';
import StatementForm from 'models/form/statement-form-model';
import {
  setUploadedFilesAddRevision,
  setUploadedFilesAddRevisionError,
  clearAddRevision,
  addRevisionLoading,
  addRevisionLoaded,
  addRevisionError,
} from 'store/actions/statement-list/statement-list-actions';

export default handleActions(
  {
    [addRevisionLoading]: (statement, { payload }) => {
      return statement.setLoading(payload);
    },
    [addRevisionLoaded]: (statement, { payload }) => {
      return statement.setLoaded(payload);
    },
    [addRevisionError]: (statement, { payload }) => {
      return statement.setError(payload);
    },
    [setUploadedFilesAddRevision]: (statement, { payload }) => {
      return statement.setFile(payload);
    },
    [setUploadedFilesAddRevisionError]: (state, { payload }) => {
      return state.setFileError(payload);
    },
    [clearAddRevision]: () => {
      return new StatementForm();
    },
  },
  new StatementForm()
);
