import { createAction } from 'redux-actions';
import { getProjectListRequest } from 'api/project-list-api';
import {
  softDeleteProjectRequest,
  restoreSoftDeletedProjectRequest,
  permanentlyDeleteProjectRequest,
} from 'api/delete-project-api';

import { isNullOrUndefined } from 'utils/object-utils';
import {
  clearSelectedProject,
  updateSelectedProjectFromID,
  setSelectedProjectFromProjectList,
  setSelectedProjectId,
} from './selected-project-actions';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import { ROUTE_CONSTANTS } from '../../constants/util/route-constants';
import { GEO_NAMES } from 'constants/feature/project-list-header-constants';
import moment from 'moment';

export const clearProjectList = createAction('CLEAR_PROJECT_LIST');

export const projectListLoading = createAction('PROJECT_LIST_LOADING');
export const projectListError = createAction('PROJECT_LIST_ERROR');
export const projectListLoaded = createAction('PROJECT_LIST_LOADED');
export const softDeleteProjectError = createAction('SOFT_DELETE_RPOJECT_ERROR');
export const restoreDeletedProjectError = createAction(
  'RESTORE_DELETED_PROJECT_ERROR',
);
export const permanentlyDeleteProjectError = createAction(
  'RESTORE_DELETED_PROJECT_ERROR',
);

export const setGeoToFilterProjectList = createAction(
  'SET_GEO_TO_FILTER_PROJECT_LIST',
);

export const setAppliedSearchTermToFilterProjectList = createAction(
  'SET_APPLIED_SEARCH_TERM_TO_FILTER_PROJECT_LIST',
);

export const clearAppliedSearchTermToFilterProjectList = createAction(
  'CLEAR_APPLIED_SEARCH_TERM_TO_FILTER_PROJECT_LIST',
);
export const setMergeSelectedProjectWithProjectList = createAction(
  'MERGE_SELECTED_PROJECT_WITH_PROJECT_LIST',
);

export const fetchProjectList = () => async (dispatch, getState) => {
  dispatch(projectListLoading());
  try {
    const { currentUser } = getState().data;
    const response = await getProjectListRequest();
    if (
      currentUser &&
      currentUser.data &&
      (currentUser.data.admin || currentUser.data.countryManager)
    ) {
      response.data.result = sortProjectListBySupportEnabledAccessDate(
        response.data.result,
      );
    }
    await dispatch(
      projectListLoaded({
        response,
      }),
    );
    const currentState = getState().data;
    const projectList = currentState.projectList.getProjects();
    const selectedProject = currentState.selectedProject.project;
    let selectedProjectPresentInProjectList = false;
    if (
      projectList.length &&
      !isNullOrUndefined(selectedProject) &&
      selectedProject.id
    ) {
      /**
       * If there are projects in the project list and there is currently a selected project,
       * we need to make sure it is present in the project list. If not, we need to clean up the selected project.
       */
      selectedProjectPresentInProjectList = projectList.find((project) => {
        return project.id === selectedProject.id;
      });
    }

    if (!projectList.length) {
      // This clears the selected project when the list is empty.
      dispatch(clearSelectedProject());
    } else if (!selectedProjectPresentInProjectList) {
      //When project list is not empty, then filter the project list based on selected geo in project list header dropdown.
      const selectedGeo = currentState.projectList.selectedGeo;
      const filteredProjectList = projectList.filter((project) => {
        if (selectedGeo.value === GEO_NAMES.ALL) {
          return project;
        }
        return project.geoCode === selectedGeo.value;
      });
      /**
       * This initializes the default selected project, when the project list is not empty, but the currently selected
       * project is not found in the project list. This can happen if a new project is created without a link
       * to the current user, or if the current user is removed from an existing project they were linked to.
       * If the selected project is found in the list, no update is needed.
       */
      filteredProjectList.length
        ? dispatch(
            setSelectedProjectFromProjectList({
              projectListEntry: filteredProjectList[0],
            }),
          )
        : dispatch(clearSelectedProject());
    } else if (selectedProjectPresentInProjectList) {
      /**
       * This call is needed to re-load the statement's for a given page if a user navigates back to the homepage from
       * any other page. If the statements have been updated at all, the changes are not reflected right now on the homepage
       * as the statements are not refreshed at all.
       *
       * TODO: The real fix for this will be to migrate the statement fetch calls to the statement content panel itself to make the
       * proper back-end calls by type, which can be done once we migrate to a URL parameter approach to the statements tab and
       * we implement new endpoints on the back-end.
       *
       */
      dispatch(
        updateSelectedProjectFromID({
          projectId: selectedProject.id,
        }),
      );
    }
  } catch (error) {
    dispatch(projectListError(error));
  }
};

const sortProjectListBySupportEnabledAccessDate = (projectList) => {
  return projectList.sort((a, b) => {
    if (a.adminGroupAddDate === null) return 1; // Move objects with null date to end
    if (b.adminGroupAddDate === null) return -1; // Move objects with null date to end
    return moment(b.adminGroupAddDate) - moment(a.adminGroupAddDate); // Sort by date
  });
};

export const setProjectHeadersAction =
  (config, projectId) => (dispatch, getState) => {
    const pathName = window.location.pathname;
    let defaultConfig = config && config.defaults;

    const isCreateProjectPage =
      pathName && pathName.includes(ROUTE_CONSTANTS.PROJECT_CREATION_PAGE);

    const isAdminModulePage =
      pathName && pathName.includes(ROUTE_CONSTANTS.ADMIN_MODULE_PAGE);

    // get updated selected project data (it has to be used to set http header)
    const updatedSelectedProject =
      getState() &&
      getState().data &&
      getState().data.selectedProject &&
      getState().data.selectedProject.project;
    // after loading data to redux store update country code and member firm code to be sent with http headers.

    if (defaultConfig) {
      if (isAdminModulePage) {
        // adding client id to header when in admin module page
        defaultConfig.headers.common['x-client-id'] = 0;
      } else if (isCreateProjectPage) {
        // adding client id to header when in create project page
        defaultConfig.headers.common['x-client-id'] = -1;
      } else if (projectId) {
        defaultConfig.headers.common['x-client-id'] = projectId;
      } else if (updateSelectedProjectFromID) {
        // adding client id to header
        defaultConfig.headers.common['x-client-id'] = isNullOrUndefined(
          updatedSelectedProject.id,
        )
          ? EMPTY_STRING
          : parseInt(updatedSelectedProject.id);
      }
    }
  };

export const softDeleteProject =
  ({ project }) =>
  async (dispatch, getState) => {
    try {
      //First soft delete the project.
      await softDeleteProjectRequest(project.id);
      // ensure selected project ID is set so card is scrolled to on mount
      dispatch(setSelectedProjectId({ projectId: project.id }));
      //Refresh the project list after a successful deletion.
      await dispatch(fetchProjectList());
      //Finally, after successful deletion, update the currently selected project with the copy of it that was just fetched.
      const updatedProjectList = getState().data.projectList.getProjects();
      const updatedSelectedProject = updatedProjectList.find(
        (updatedProject) => {
          return updatedProject.id === project.id;
        },
      );
      await dispatch(
        updateSelectedProjectFromID({
          projectId: updatedSelectedProject.id,
        }),
      );
    } catch (error) {
      dispatch(softDeleteProjectError(error));
    }
  };

export const restoreDeletedProject =
  ({ project }) =>
  async (dispatch, getState) => {
    try {
      //Restore the deleted project.
      await restoreSoftDeletedProjectRequest(project.id);
      // ensure selected project ID is set so card is scrolled to on mount
      dispatch(setSelectedProjectId({ projectId: project.id }));
      //Refresh the project list after a successful restoration.
      await dispatch(fetchProjectList());
      //Finally, after successful restoration, update the currently selected project with the copy of it that was just fetched.
      const updatedProjectList = getState().data.projectList.getProjects();
      // If there are no projects, clear the selected project.
      if (!updatedProjectList.length) {
        dispatch(clearSelectedProject());
      } else {
        // Otherwise try to find the currently selected project in the list of projects. For soft-deleted projects on support-team access
        // that are visible to admins, restoring them removes support-team access, so they may no longer be in the list of projects.
        // Consequently we check if the currently selected project is in the list. If it is, it remains selected, otherwise we default
        // to selecting the first project in the list.
        const updatedSelectedProject = updatedProjectList.find(
          (updatedProject) => {
            return updatedProject.id === project.id;
          },
        );
        if (!isNullOrUndefined(updatedSelectedProject)) {
          await dispatch(
            updateSelectedProjectFromID({
              projectId: updatedSelectedProject.id,
            }),
          );
        } else {
          await dispatch(
            updateSelectedProjectFromID({
              projectId: updatedProjectList[0].id,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(restoreDeletedProjectError(error));
    }
  };

export const permanentlyDeleteProject =
  ({ project }) =>
  async (dispatch, getState) => {
    try {
      const selectedProject =
        project && project.id
          ? project
          : getState().data.selectedProject.project;
      //First permanently delete the project.
      await permanentlyDeleteProjectRequest(selectedProject.id);
      //Refresh the project list after a successful deletion.
      await dispatch(fetchProjectList());
    } catch (error) {
      dispatch(permanentlyDeleteProjectError(error));
    }
  };

export const setProjectFilterGeo = (geoCode) => (dispatch, getState) => {
  dispatch(setGeoToFilterProjectList(geoCode));
};

export const setAppliedSearchTerm = (searchTerm) => (dispatch, getState) => {
  dispatch(setAppliedSearchTermToFilterProjectList(searchTerm));
};

export const clearAppliedSearchTerm = () => (dispatch, getState) => {
  dispatch(clearAppliedSearchTermToFilterProjectList());
};

export const setFirstProjectAsSelectedFromProjectList =
  ({ projectList }) =>
  (dispatch, getState) => {
    if (projectList.length) {
      dispatch(
        setSelectedProjectFromProjectList({ projectListEntry: projectList[0] }),
      );
    } else {
      dispatch(clearSelectedProject());
    }
  };
