const envParam = window.TIEOUT.ENV.ENV_PARAM;

function addScript(src) {
  const element = document.createElement('script');
  element.src = src;
  element.async = true;
  document.head.appendChild(element);
}

let src;
switch (envParam) {
  case 'PROD':
  case 'BCP':
    src =
      'https://assets.adobedtm.com/58d4dfc81ba0/ca6ed953ccc0/launch-d0f8306f72be.min.js';
    break;
  default:
    src =
      'https://assets.adobedtm.com/58d4dfc81ba0/ca6ed953ccc0/launch-d0f8306f72be.min.js';
    break;
}

addScript(src);
