import { handleActions } from 'redux-actions';
import {
  featureInfoLoading,
  featureInfoLoaded,
  featureInfoError,
} from 'store/actions/feature-info-actions';
import { FeatureInfo } from 'models/api/feature-info-api-model';

export default handleActions(
  {
    [featureInfoLoading]: (featureInfo) => {
      return featureInfo.setLoading();
    },
    [featureInfoLoaded]: (featureInfo, { payload }) => {
      return featureInfo.setLoaded(payload);
    },
    [featureInfoError]: (featureInfo, { payload }) => {
      return featureInfo.setError(payload);
    },
  },
  new FeatureInfo(),
);
