import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import Note from 'models/data/note-model';
import { applyNoteFilters } from 'utils/note-filter-util';
import { UNSELECTED_FILTER } from 'constants/feature/notes/notes-panel-filter-constants';
import {
  FILTER_STATUS,
  FILTER_PRIORITY,
  FILTER_GHOST,
  FILTER_HEADING,
  FILTER_CREATED_BY,
  FILTER_REPLIED_BY,
  FILTER_CREATED_OR_REPLIED,
  FILTER_MENTIONED,
} from 'constants/feature/notes-panel-constants';
import cloneDeep from 'lodash.clonedeep';
export default class NotesList extends ApiModel({
  data: {
    notes: [], // type Note
    filteredNotes: [],
    noteFilters: {
      heading: UNSELECTED_FILTER,
      status: UNSELECTED_FILTER,
      type: UNSELECTED_FILTER,
      priority: UNSELECTED_FILTER,
      ghost: UNSELECTED_FILTER,
      user: UNSELECTED_FILTER,
    },
    usersListNotesCreatedBy: {},
    usersListNotesRepliedBy: {},
    statementSegmentFilters: {},
    userListCreatedAndReplied: {},
    usersListNotesMentions: {},
    previousNoteBody: '',
    assignedToMe: '',
  },
}) {
  get notes() {
    if (this.hasNotes()) {
      return this.data.filteredNotes;
    }
    return [];
  }

  processResponse({ response }) {
    const notesResponse = response.data.result.map((entry) => new Note(entry));

    return {
      data: {
        notes: notesResponse,
        filteredNotes: applyNoteFilters(
          notesResponse,
          this.data.noteFilters,
          this.data.usersListNotesCreatedBy,
          this.data.usersListNotesRepliedBy,
          this.data.statementSegmentFilters,
          this.data.userListCreatedAndReplied,
          this.data.usersListNotesMentions,
          this.data.assignedToMe,
        ),
        noteFilters: this.data.noteFilters,
        usersListNotesCreatedBy: this.data.usersListNotesCreatedBy,
        usersListNotesRepliedBy: this.data.usersListNotesRepliedBy,
        statementSegmentFilters: this.data.statementSegmentFilters,
        userListCreatedAndReplied: this.data.userListCreatedAndReplied,
        usersListNotesMentions: this.data.usersListNotesMentions,
        previousNoteBody: '',
        assignedToMe: this.data.assignedToMe,
      },
    };
  }

  setAssignedToMe(payload) {
    return this.mergeData({
      assignedToMe: payload,
      filteredNotes: applyNoteFilters(
        this.data.notes,
        this.data.noteFilters,
        this.data.usersListNotesCreatedBy,
        this.data.usersListNotesRepliedBy,
        this.data.statementSegmentFilters,
        this.data.userListCreatedAndReplied,
        this.data.usersListNotesMentions,
        payload,
      ),
    });
  }

  applyQuickFilter(payload) {
    const filterValue = payload.filterVal;
    const filterType = payload.filterType;
    let updatedFilters = {};

    if (filterType === FILTER_STATUS) {
      if (filterValue) {
        updatedFilters = {
          ...this.data.noteFilters,
          status: true,
          type: UNSELECTED_FILTER,
          priority: false,
          ghost: false,
          user: UNSELECTED_FILTER,
          heading: UNSELECTED_FILTER,
        };
      } else if (filterValue === false) {
        updatedFilters = {
          ...this.data.noteFilters,
          status: false,
          type: UNSELECTED_FILTER,
          priority: UNSELECTED_FILTER,
          ghost: false,
          user: UNSELECTED_FILTER,
          heading: UNSELECTED_FILTER,
        };
      }
    } else if (filterType === FILTER_PRIORITY) {
      updatedFilters = {
        ...this.data.noteFilters,
        status: true,
        type: UNSELECTED_FILTER,
        priority: filterValue,
        ghost: false,
        user: UNSELECTED_FILTER,
        heading: UNSELECTED_FILTER,
      };
    } else if (filterType === FILTER_GHOST) {
      updatedFilters = {
        ...this.data.noteFilters,
        status: UNSELECTED_FILTER,
        type: UNSELECTED_FILTER,
        priority: UNSELECTED_FILTER,
        ghost: filterValue,
        user: UNSELECTED_FILTER,
        heading: UNSELECTED_FILTER,
      };
    }

    const emptyObject = {};

    return this.mergeData({
      noteFilters: updatedFilters,
      usersListNotesCreatedBy: emptyObject,
      usersListNotesRepliedBy: emptyObject,
      statementSegmentFilters: emptyObject,
      userListCreatedAndReplied: emptyObject,
      usersListNotesMentions: emptyObject,
      filteredNotes: applyNoteFilters(
        this.data.notes,
        updatedFilters,
        emptyObject,
        emptyObject,
        emptyObject,
        emptyObject,
        emptyObject,
        this.data.assignedToMe,
      ),
    });
  }

  applyNoteFilter(payload) {
    const updatedNoteFilters = payload.updatedFilters;
    const updatedCreatedObject = payload.userFilterCreated;
    const updatedReplyObject = payload.userFilterReplied;
    const updatedStatementSegmentFilters = payload.selectedSegments;
    const updatedCreatedAndRepliedObject =
      payload.selectedUsersCreatedAndRepliedUsers;
    const usersListNotesMentions = payload.userFilterMentionedUsers;

    return this.mergeData({
      noteFilters: updatedNoteFilters,
      usersListNotesCreatedBy: cloneDeep(updatedCreatedObject),
      usersListNotesRepliedBy: cloneDeep(updatedReplyObject),
      statementSegmentFilters: updatedStatementSegmentFilters,
      userListCreatedAndReplied: updatedCreatedAndRepliedObject,
      usersListNotesMentions: usersListNotesMentions,
      filteredNotes: applyNoteFilters(
        this.data.notes,
        updatedNoteFilters,
        updatedCreatedObject,
        updatedReplyObject,
        updatedStatementSegmentFilters,
        updatedCreatedAndRepliedObject,
        usersListNotesMentions,
        this.data.assignedToMe,
      ),
    });
  }

  clearAllFilters() {
    const updatedFilters = {
      ...this.data.noteFilters,
      status: UNSELECTED_FILTER,
      type: UNSELECTED_FILTER,
      priority: UNSELECTED_FILTER,
      ghost: UNSELECTED_FILTER,
      user: UNSELECTED_FILTER,
      heading: UNSELECTED_FILTER,
    };

    const emptyObject = {};

    return this.mergeData({
      noteFilters: updatedFilters,
      usersListNotesCreatedBy: emptyObject,
      usersListNotesRepliedBy: emptyObject,
      statementSegmentFilters: emptyObject,
      userListCreatedAndReplied: emptyObject,
      usersListNotesMentions: emptyObject,
      filteredNotes: applyNoteFilters(
        this.data.notes,
        updatedFilters,
        emptyObject,
        emptyObject,
        emptyObject,
        emptyObject,
        emptyObject,
        this.data.assignedToMe,
      ),
    });
  }

  removeNoteFilter(payload) {
    const filterType = payload.filterType;
    const createdOrRepliedUsers = payload.createdOrRepliedUsers;

    const updatedFilters = {
      ...this.data.noteFilters,
      [filterType]: UNSELECTED_FILTER,
    };

    if (createdOrRepliedUsers === FILTER_CREATED_BY) {
      const emptyObject = {};

      return this.mergeData({
        noteFilters: updatedFilters,
        usersListNotesCreatedBy: emptyObject,
        filteredNotes: applyNoteFilters(
          this.data.notes,
          updatedFilters,
          emptyObject,
          this.data.usersListNotesRepliedBy,
          this.data.statementSegmentFilters,
          this.data.userListCreatedAndReplied,
          this.data.usersListNotesMentions,
          this.data.assignedToMe,
        ),
      });
    } else if (createdOrRepliedUsers === FILTER_REPLIED_BY) {
      const emptyObject = {};

      return this.mergeData({
        noteFilters: updatedFilters,
        usersListNotesRepliedBy: emptyObject,
        filteredNotes: applyNoteFilters(
          this.data.notes,
          updatedFilters,
          this.data.usersListNotesCreatedBy,
          emptyObject,
          this.data.statementSegmentFilters,
          this.data.userListCreatedAndReplied,
          this.data.usersListNotesMentions,
          this.data.assignedToMe,
        ),
      });
    } else if (createdOrRepliedUsers === FILTER_CREATED_OR_REPLIED) {
      const emptyObject = {};
      return this.mergeData({
        noteFilters: updatedFilters,
        userListCreatedAndReplied: emptyObject,
        filteredNotes: applyNoteFilters(
          this.data.notes,
          updatedFilters,
          this.data.usersListNotesCreatedBy,
          this.data.usersListNotesRepliedBy,
          this.data.statementSegmentFilters,
          emptyObject,
          this.data.usersListNotesMentions,
          this.data.assignedToMe,
        ),
      });
    } else if (createdOrRepliedUsers === FILTER_MENTIONED) {
      const emptyObject = {};
      return this.mergeData({
        noteFilters: updatedFilters,
        usersListNotesMentions: emptyObject,
        filteredNotes: applyNoteFilters(
          this.data.notes,
          updatedFilters,
          this.data.usersListNotesCreatedBy,
          this.data.usersListNotesRepliedBy,
          this.data.statementSegmentFilters,
          this.data.userListCreatedAndReplied,
          emptyObject,
          this.data.assignedToMe,
        ),
      });
    }

    if (filterType === FILTER_HEADING) {
      return this.mergeData({
        noteFilters: updatedFilters,
        statementSegmentFilters: {},
        filteredNotes: applyNoteFilters(
          this.data.notes,
          updatedFilters,
          this.data.usersListNotesCreatedBy,
          this.data.usersListNotesRepliedBy,
          {},
          this.data.userListCreatedAndReplied,
          this.data.usersListNotesMentions,
          this.data.assignedToMe,
        ),
      });
    }

    return this.mergeData({
      noteFilters: updatedFilters,
      filteredNotes: applyNoteFilters(
        this.data.notes,
        updatedFilters,
        this.data.usersListNotesCreatedBy,
        this.data.usersListNotesRepliedBy,
        this.data.statementSegmentFilters,
        this.data.userListCreatedAndReplied,
        this.data.usersListNotesMentions,
        this.data.assignedToMe,
      ),
    });
  }

  hasNotes() {
    return (
      !isNullOrUndefined(this.data) &&
      !isNullOrUndefined(this.data.notes) &&
      this.data.notes.length > 0
    );
  }

  deleteNoteWithWebsocket(payload) {
    let notesListCopy = cloneDeep(this.data.notes);
    let filteredListCopy = cloneDeep(this.data.filteredNotes);

    notesListCopy = notesListCopy.filter(
      (note) => note.noteId !== payload.noteId,
    );
    filteredListCopy = filteredListCopy.filter(
      (note) => note.noteId !== payload.noteId,
    );

    return this.mergeData({
      notes: notesListCopy,
      filteredNotes: filteredListCopy,
    });
  }

  addNoteWithWebsocketPayload(payload) {
    const newNoteEntry = new Note(payload);
    let notesListCopy = cloneDeep(this.data.notes);

    notesListCopy.push(newNoteEntry);

    // sort notes list by highest sectionId and then by most recently
    // added note
    notesListCopy.sort(
      (a, b) =>
        a.sectionId - b.sectionId ||
        new Date(b.noteTimestamp) - new Date(a.noteTimestamp),
    );

    const newFilteredList = applyNoteFilters(
      notesListCopy,
      this.data.noteFilters,
      this.data.usersListNotesCreatedBy,
      this.data.usersListNotesRepliedBy,
      this.data.statementSegmentFilters,
      this.data.userListCreatedAndReplied,
      this.data.usersListNotesMentions,
      this.data.assignedToMe,
    );

    return this.mergeData({
      notes: notesListCopy,
      filteredNotes: newFilteredList,
    });
  }

  updateNoteWithWebsocket(payload) {
    let notesListCopy = cloneDeep(this.data.notes);
    for (let i = 0; i < notesListCopy.length; i++) {
      let note = notesListCopy[i];
      if (note.noteId === payload.noteId) {
        note = {
          ...note,
          elementId: payload.elementId,
          noteType: payload.noteType,
          workflowStatus: payload.workflowStatus,
          closedBy: payload.closedBy,
          closedByName: payload.closedByName,
          body: payload.body,
          noteDate: payload.noteDate,
          closedDate: payload.closedDate,
          noteTaker: payload.noteTaker,
          noteTakerName: payload.noteTakerName,
          notePriorityType: payload.notePriorityType,
          revisionNumber: payload.revisionNumber,
        };

        notesListCopy[i] = new Note(note);
        break;
      }
    }

    const newFilteredList = applyNoteFilters(
      notesListCopy,
      this.data.noteFilters,
      this.data.usersListNotesCreatedBy,
      this.data.usersListNotesRepliedBy,
      this.data.statementSegmentFilters,
      this.data.userListCreatedAndReplied,
      this.data.usersListNotesMentions,
      this.data.assignedToMe,
    );

    return this.mergeData({
      notes: notesListCopy,
      filteredNotes: newFilteredList,
    });
  }

  updatePreviousNote(payload) {
    return this.mergeData({
      previousNoteBody: payload,
    });
  }

  // We need to modify the note list to include additional details like parentId and isTechnical for particular section.
  // We expected to get these details from BE directly, but they are facing some technical issues (inner join to fetch these
  // details causes ghost notes to dissappear from the list), which enforces us to fetch these details from sections cache.
  addAdditionalDetailsToNotesList(payload = {}) {
    const sectionCacheData = payload.sectionCacheData;
    let notesListCopy = cloneDeep(this.data.notes);
    const newNotes = notesListCopy.map((entry) => {
      sectionCacheData &&
        sectionCacheData[entry.sectionId] &&
        (entry = {
          ...entry,
          parentId: sectionCacheData[entry.sectionId].parentId,
          isTechnical: sectionCacheData[entry.sectionId].isTechnical,
        });
      return new Note(entry);
    });
    return this.mergeData({
      notes: newNotes,
      filteredNotes: applyNoteFilters(
        newNotes,
        this.data.noteFilters,
        this.data.usersListNotesCreatedBy,
        this.data.usersListNotesRepliedBy,
        this.data.statementSegmentFilters,
        this.data.userListCreatedAndReplied,
        this.data.usersListNotesMentions,
        this.data.assignedToMe,
      ),
    });
  }
}
