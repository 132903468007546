import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
const OMNIA_FILE_SHARING_CONFIRM_MODAL_ID = 'file-export-in-progess-modal-id';

const StatementExportInProgressModal = ({ onClickClose, title, message }) => {
  return (
    <Modal
      id={OMNIA_FILE_SHARING_CONFIRM_MODAL_ID}
      title={title}
      onClose={onClickClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.close',
          onClick: onClickClose,
        })
      }
    >
      <div>
        <FormattedMessage id={message} />
      </div>
    </Modal>
  );
};

StatementExportInProgressModal.propTypes = {
  /** Action fired when user clicks No || close button */
  onClickClose: PropTypes.func.isRequired,
  /** internationalized string title of the modal */
  title: PropTypes.string.isRequired,
  /** internationalized string - confirmation message*/
  message: PropTypes.string.isRequired,
};

export default StatementExportInProgressModal;
