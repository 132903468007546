import BaseModel from 'models/base-model';
import clonedeep from 'lodash.clonedeep';

const ERROR_STATUS_UNAVAILABLE = 'Error status unavailable';
const ERROR_MESSAGE_UNAVAILABLE = 'Error message unavailable';
const FAILED_URL_UNAVAILABLE = 'Failed url unavailable';
const STACK_TRACE_UNAVAILABLE = 'Stack trace unavailable';
const ERROR_NOT_HANDLED = 'Unhandled error';

export default class ApiError extends BaseModel({
  apiErrorArray: [],
}) {
  clearErrorData() {
    return this.merge({
      apiErrorArray: [],
    });
  }

  updateErrorData(payload) {
    const failedURLResponse = payload.config
      ? payload.config.url
      : FAILED_URL_UNAVAILABLE;

    let apiErrorArrayCopy = clonedeep(this.apiErrorArray);
    let newErrorObj = {};

    // depending on the error, the backend may return
    // a response a different format
    if (typeof payload.data === 'string') {
      newErrorObj.errorStatus = payload.status
        ? payload.status
        : ERROR_STATUS_UNAVAILABLE;
      newErrorObj.errorMessage =
        payload.data !== '' ? payload.data : ERROR_MESSAGE_UNAVAILABLE;
      newErrorObj.failedUrl = failedURLResponse;
      newErrorObj.stackTrace = STACK_TRACE_UNAVAILABLE;
      newErrorObj.handledErrorJsonString = ERROR_NOT_HANDLED;
    } else {
      const errorData = payload.data;
      newErrorObj.errorStatus = errorData.status
        ? errorData.status
        : ERROR_STATUS_UNAVAILABLE;
      newErrorObj.errorMessage = errorData.errorMsg
        ? errorData.errorMsg
        : ERROR_MESSAGE_UNAVAILABLE;
      newErrorObj.failedUrl = failedURLResponse;
      newErrorObj.stackTrace = STACK_TRACE_UNAVAILABLE;
      newErrorObj.handledErrorJsonString = ERROR_NOT_HANDLED;
    }

    apiErrorArrayCopy.push(newErrorObj);
    return this.merge({ apiErrorArray: apiErrorArrayCopy });
  }

  updateErrorStackTrace(payload) {
    const stack = payload;
    let apiErrorArrayCopy = clonedeep(this.apiErrorArray);

    if (apiErrorArrayCopy.length === 0) {
      let newErrorObj = {};
      newErrorObj.errorStatus = ERROR_STATUS_UNAVAILABLE;
      newErrorObj.errorMessage = ERROR_MESSAGE_UNAVAILABLE;
      newErrorObj.failedURL = FAILED_URL_UNAVAILABLE;
      newErrorObj.handledErrorJsonString = ERROR_NOT_HANDLED;
      newErrorObj.stackTrace = stack;
      apiErrorArrayCopy.push(newErrorObj);
    } else {
      apiErrorArrayCopy[apiErrorArrayCopy.length - 1].stackTrace = stack;
    }

    return this.merge({ apiErrorArray: apiErrorArrayCopy });
  }

  updateCatchedErrorJson(payload) {
    const handledErrorJsonString = payload;
    let apiErrorArrayCopy = clonedeep(this.apiErrorArray);

    if (apiErrorArrayCopy.length === 0) {
      let newErrorObj = {};
      newErrorObj.errorStatus = ERROR_STATUS_UNAVAILABLE;
      newErrorObj.errorMessage = ERROR_MESSAGE_UNAVAILABLE;
      newErrorObj.failedURL = FAILED_URL_UNAVAILABLE;
      newErrorObj.handledErrorJsonString = handledErrorJsonString;
      apiErrorArrayCopy.push(newErrorObj);
    } else {
      apiErrorArrayCopy[
        apiErrorArrayCopy.length - 1
      ].handledErrorJsonString = handledErrorJsonString;
    }

    return this.merge({ apiErrorArray: apiErrorArrayCopy });
  }
  getReadOnlyErrorMessageToDisplay() {
    if (this.apiErrorArray && this.apiErrorArray.length > 0) {
      return this.apiErrorArray[0].errorMessage;
    } else {
      return ERROR_MESSAGE_UNAVAILABLE;
    }
  }

  getDetailsToCopyToClipboard() {
    if (this.apiErrorArray) {
      let errorDetails = '';
      for (let index = 0; index < this.apiErrorArray.length; index++) {
        const error = this.apiErrorArray[index];
        const {
          errorStatus,
          errorMessage,
          failedUrl,
          stackTrace,
          handledErrorJsonString,
        } = error;
        const newErrorDetail = `Error Status: ${errorStatus}.\n Error Message: ${errorMessage} \n Failed URL: ${failedUrl}.\n\n Catched Error Json: ${handledErrorJsonString} \n\n $ Browser Generated Stack Trace: ${stackTrace}. `;
        errorDetails += newErrorDetail + '\n';
      }
      return errorDetails;
    } else {
      return 'Details not available';
    }
  }
}
