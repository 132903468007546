import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/button-component';
import { ReactComponent as Plus } from 'icons/plus.svg';
import { SearchDropdownApi } from 'components/common/search-component';
import {
  debounceSearch,
  isSearchCharLengthValid,
} from 'constants/util/search-utility';
import MenuApiData from 'models/utils/common/menu/menu-api-data-model';
import { WorkpaperReferenceList } from 'models/api/workpaper-reference-list-model';
import { getWorkpaperReferencesRequest } from 'api/workpaper-api';

const ATTACH_WORKPAPER_BLOCK = 'attach-work-paper';
const ATTACH_WORKPAPER_ID_BLOCK = 'attach-work-paper-id';

const MIN_CHAR_LENGTH_TO_SEARCH = 1;

class AttachWorkpaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      workpaperListModel: new WorkpaperReferenceList(),
    };
    this.debouncedSearch = debounceSearch(this._fetchWorkpaperReferences);
  }

  _searchWorkpapers = (val) => {
    this.setState({ search: val }, () => {
      if (isSearchCharLengthValid(val, MIN_CHAR_LENGTH_TO_SEARCH)) {
        this.debouncedSearch({ search: val });
      }
    });
  };

  _fetchWorkpaperReferences = async ({ search }) => {
    const { revisionId } = this.props;
    this.setState(({ workpaperListModel }) => ({
      workpaperListModel: workpaperListModel.setLoading(),
    }));

    try {
      const response = await getWorkpaperReferencesRequest({
        referenceNumber: search,
        revisionId,
      });
      this.setState(({ workpaperListModel }) => ({
        workpaperListModel: workpaperListModel.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState(({ workpaperListModel }) => ({
        workpaperListModel: workpaperListModel.setError(error),
      }));
    }
  };

  render() {
    const {
      onCreate,
      onSelect,
      alreadyAddedWorkpaperIds,
      hideTitle,
    } = this.props;
    const { search, workpaperListModel } = this.state;

    return (
      <div className={`${ATTACH_WORKPAPER_BLOCK}`}>
        {!hideTitle && (
          <h4 className={`${ATTACH_WORKPAPER_BLOCK}__title`}>
            <FormattedMessage id="workpaper-panel.attach-section.title" />
          </h4>
        )}
        <Button.IconButton
          id={`${ATTACH_WORKPAPER_ID_BLOCK}__add-link`}
          className={`${ATTACH_WORKPAPER_BLOCK}__create-button`}
          onClick={() => onCreate()}
          Icon={Plus}
        >
          <FormattedMessage id="common.create" />
        </Button.IconButton>

        <div className={`${ATTACH_WORKPAPER_BLOCK}__search-container`}>
          <SearchDropdownApi
            id={`${ATTACH_WORKPAPER_ID_BLOCK}-search`}
            className={`${ATTACH_WORKPAPER_BLOCK}__search`}
            placeholder={'workpaper-panel.search-workpaper.placeholder'}
            label={'attach-work-paper.workpaper-search.label'}
            onChange={(val) => this._searchWorkpapers(val)}
            value={search}
            isValid={true}
            resultsApiModel={
              new MenuApiData({
                apiModel: workpaperListModel,
                options: workpaperListModel.getWorkpaperDropdownValues({
                  alreadyAddedWorkpaperIds: alreadyAddedWorkpaperIds || [],
                }),
              })
            }
            disableAutoComplete
            onClear={() => this._searchWorkpapers('')}
            minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
            showSearchButton
            onSelectResult={async (workpaper) => {
              await onSelect({
                workpaper: workpaperListModel.getSelectedWorkpaper(
                  workpaper.id,
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

AttachWorkpaper.defaultProps = {
  hideTitle: false,
};

AttachWorkpaper.propTypes = {
  /** Currently selected revision */
  revisionId: PropTypes.number,
  /** Function fired when user clicks Create */
  onCreate: PropTypes.func.isRequired,
  /** Function fired for when you want to attach a workpaer from search */
  onSelect: PropTypes.func.isRequired,
  /** Optional List of workpaper ids that should be disabled in the list */
  alreadyAddedWorkpaperIds: PropTypes.array,
  /** boolean to hide title */
  hideTitle: PropTypes.bool,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { AttachWorkpaper };
export default connect(mapStateToProps, null)(AttachWorkpaper);
