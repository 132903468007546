import { handleActions } from 'redux-actions';

import SelectedStatement from 'models/api/selected-statement-model';
import {
  leftSelectedStatementLoading,
  leftSelectedStatementLoaded,
  leftSelectedStatementError,
  clearLeftSelectedStatement,
} from 'store/actions/left-selected-statement-actions';

export default handleActions(
  {
    [leftSelectedStatementLoading]: (selectedStatement) => {
      return selectedStatement.setLoading();
    },
    [leftSelectedStatementLoaded]: (selectedStatement, { payload }) => {
      return selectedStatement.setLoaded(payload);
    },
    [leftSelectedStatementError]: (selectedStatement, { payload }) => {
      return selectedStatement.setError(payload);
    },
    [clearLeftSelectedStatement]: (selectedStatement) => {
      return new SelectedStatement();
    },
  },
  new SelectedStatement(),
);
