import React from 'react';
import PropTypes from 'prop-types';
import Statement from 'models/data/statement-model';
import ApproveStatementWrapUpModal1 from './statement-wrap-up-approval-modal-1';
import ApproveStatementWrapUpModal2 from './statement-wrap-up-approval-modal-2';

const StatementWrapUpApprovalFlowWrapper = ({
  statement,
  onClose,
  onDecline,
  onApprove,
}) => {
  // React.useState instead of useState will help us test useState as in scope of React and not function locally
  const [showModal1, setShowModal1] = React.useState(true);
  const [showModal2, setShowModal2] = React.useState(false);

  return (
    <>
      {showModal1 && (
        <ApproveStatementWrapUpModal1
          statement={statement}
          onClose={() => {
            setShowModal1(false);
            onClose();
          }}
          onDecline={onDecline}
          onApprove={() => {
            setShowModal1(false);
            setShowModal2(true);
          }}
        />
      )}
      {showModal2 && (
        <ApproveStatementWrapUpModal2
          statement={statement}
          onApprove={onApprove}
          onClose={() => {
            setShowModal2(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

StatementWrapUpApprovalFlowWrapper.propTypes = {
  /** Statement model to be wrapped up */
  statement: PropTypes.instanceOf(Statement).isRequired,
  /** function fired for cancel and close of modal flow */
  onClose: PropTypes.func.isRequired,
};

export default StatementWrapUpApprovalFlowWrapper;
