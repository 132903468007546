import React, { useCallback, useEffect, useRef, useState } from 'react';
import RichTextEditor from 'components/common/rich-text-editor-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { isNullOrUndefined } from '../../utils/object-utils';
import { getText } from 'constants/util/admin-module-utility';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ConfirmButton } from 'icons/close-button.svg';
import {
  getSummaryOfKeyChanges,
  postSummaryKeyChanges,
} from 'api/admin-module-api';
import ConditionalRender from 'components/util/conditional-render-component';
import classNames from 'classnames';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';
import Switch from 'react-switch';

export const SUMMARY_OF_KEY_CHANGES_BLOCK = 'summary-of-key-changes';
const SUMMARY_OF_KEY_CHANGES_ID = 'summary-of-key-changes-id';

const MAX_SUMMARY_LENGTH = 10000; // ten thousand

export const SummaryOfKeyChanges = () => {
  const [bannerText, setBannerText] = useState(EMPTY_STRING);
  const [showEditButton, setShowEditButton] = useState(false);
  const [switchToggle, setSwitchToggle] = useState(false);

  /**This will check if component has mounted or not
   * This is an approach, I figured out, to implement componentDidUpdate/componentDidMount
   * (i.e. do something on first mount and different on subsequent update) in
   * function based component. This will be enabled when the user clicks on switch for the first time
   * And this will be cleared i.e. false when component unmounts
   */
  const isMounted = useRef(false);

  // states for conditional render. To capture specific errors
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = React.useState(null);

  const isBannerTextTooLong = useCallback(() => {
    return bannerText && getText(bannerText).length > MAX_SUMMARY_LENGTH;
  }, [bannerText]);

  /**isSaveDisabled function is passed as props of button. We want to memoize its reference,
   * so that referential equality is preserved until dependencies change
   */
  const isSaveDisabled = useCallback(() => {
    if (!(bannerText && getText(bannerText)) || isBannerTextTooLong()) {
      return true;
    } else return false;
  }, [bannerText, isBannerTextTooLong]);

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  useEffect(() => {
    const getBannerText = async () => await getSummaryOfKeyChanges();
    setDataLoading();
    setError(null);
    getBannerText()
      .then((response) => {
        setBannerText(response.text);
        setSwitchToggle(response.status);
        setDataLoaded();
        if (response && response.text && typeof response.text === 'string') {
          setShowEditButton(true);
        }
      })
      .catch((e) => {
        setError(e);
      });
    //Clean up function to be called on component unmount
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**_saveData function is passed as props of button. We want to memoize its reference,
   * so that referential equality is preserved until dependencies change
   */
  const _saveData = React.useCallback(async () => {
    try {
      setDataLoading();
      await postSummaryKeyChanges(bannerText, switchToggle);
      setDataLoaded();
      setShowEditButton(true);
    } catch (e) {
      setError(e);
    }
  }, [bannerText, switchToggle]);

  useEffect(() => {
    // This condition will prevent _saveData() api call to be triggered on mounting
    // We want to make api call on every state change of toggle button except on mount.
    isMounted.current && _saveData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchToggle]);

  return (
    <ConditionalRender dependencies={[{ error, isLoading, isLoaded }]}>
      <div className={SUMMARY_OF_KEY_CHANGES_BLOCK}>
        <div
          className={classNames(
            !showEditButton
              ? `${SUMMARY_OF_KEY_CHANGES_BLOCK}-switch-disabled`
              : `${SUMMARY_OF_KEY_CHANGES_BLOCK}-switch`,
          )}
        >
          <span className={`${SUMMARY_OF_KEY_CHANGES_BLOCK}-switch-banner`}>
            <FormattedMessage id="admin-module-page-button-toggle-summary-key-changes-banner" />
          </span>

          <Switch
            id={`${SUMMARY_OF_KEY_CHANGES_ID}-switch`}
            onChange={(val) => {
              isMounted.current = true;
              setSwitchToggle(val);
            }}
            checked={switchToggle}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={45}
            disabled={!showEditButton}
          />
        </div>
        <div
          className={classNames(
            showEditButton
              ? `${SUMMARY_OF_KEY_CHANGES_BLOCK}__rich-text--disabled`
              : `${SUMMARY_OF_KEY_CHANGES_BLOCK}__rich-text`,
          )}
        >
          <RichTextEditor
            className={classNames(
              showEditButton
                ? `${SUMMARY_OF_KEY_CHANGES_BLOCK}__text--disabled`
                : `${SUMMARY_OF_KEY_CHANGES_BLOCK}__text`,
            )}
            errorText={{
              id: 'admin.summary-of-key-changes.text.error.char-count',
              values: { limit: MAX_SUMMARY_LENGTH },
            }}
            id={`${SUMMARY_OF_KEY_CHANGES_ID}-text`}
            placeholder={{
              id: 'admin.maintenance-banner.text.placeholder',
              values: { limit: MAX_SUMMARY_LENGTH },
            }}
            width={'100%'}
            onChange={(val) => {
              setBannerText(val);
            }}
            initContents={isNullOrUndefined(bannerText) ? '' : bannerText}
            isValid={!isBannerTextTooLong()}
            disabled={showEditButton}
          />
        </div>
        <div className={`${SUMMARY_OF_KEY_CHANGES_BLOCK}-button-wrapper`}>
          {!showEditButton ? (
            <Button
              id={'save_button'}
              className={`${SUMMARY_OF_KEY_CHANGES_BLOCK}-save-button`}
              type={BUTTON_TYPES.primary}
              icon={ConfirmButton}
              disabled={isSaveDisabled()}
              onClick={() => {
                _saveData();
              }}
            >
              <FormattedMessage id={'common.save'} />
            </Button>
          ) : (
            <Button
              id={'edit_button'}
              className={`${SUMMARY_OF_KEY_CHANGES_BLOCK}-edit-button`}
              type={BUTTON_TYPES.primary}
              icon={ConfirmButton}
              disabled={false}
              onClick={() => {
                setShowEditButton(false);
              }}
            >
              <FormattedMessage id={'common.edit'} />
            </Button>
          )}
        </div>
      </div>
    </ConditionalRender>
  );
};

export default SummaryOfKeyChanges;
