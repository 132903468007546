import React from 'react';
import moment from 'moment';

import { DataGridColumn } from 'components/common/data-grid/data-grid-component';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { DataGridKebabColumn } from 'components/common/data-grid/data-grid-component';
import { ReactComponent as LinkIcon } from 'icons/link.svg';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { KebabMenuOption } from 'models/utils/common/menu/menu-option-model';
import Permissions from 'permissions/permissions';
import { getUserNameByUserDataObject } from 'utils/statement-content-page-utils';

export const NUM_VISIBLE_ROWS_REVISIONS_MODAL = 5;

export const VIEW_REVISION_LINK_TOOLTIP = new TooltipOptions({
  text: 'statement-list.revisions-modal-col.revision-link-tool-tip',
  id: 'statement-list-id-view-revision-link-button-tool-tip',
  position: 'top',
});

export const getRevisionHistoryListColumns = ({ onRevisionLinkIconClick }) => {
  return [
    new DataGridColumn({
      key: 'revisionNumber',
      header: 'statement-list.revisions-modal-col.revision',
      width: '22.5%',
    }),
    new DataGridColumn({
      key: 'processedDate',
      header: 'statement-list.revisions-modal-col.processed-date',
      width: '22.5%',
      formatter: (revision) =>
        moment(revision.processedDate).format(DATE_FORMATS.MONTH_DAY_YEAR),
    }),
    new DataGridColumn({
      key: 'processedByUserEntity',
      header: 'statement-list.revisions-modal-col.processed-by',
      width: '22.5%',
      formatter: (revision, { rowKey }) => {
        const colId = `last-modified-by-col-${rowKey}`;
        const displayText = revision.processedByUserEntity
          ? getUserNameByUserDataObject(revision.processedByUserEntity)
          : null;
        return (
          <MultiLineEllipsis
            toolTipID={colId}
            text={displayText}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: null,
      header: 'statement-list.revisions-modal-col.percent-verified',
      width: '22.5%',
      formatter: (revision, { rowKey }) => {
        return `${Math.round(
          (revision.percentageCompleted * 100 * 100) / 100,
        )}%`;
      },
    }),
    new DataGridColumn({
      key: null,
      header: null,
      width: '10%',
      formatter: (revision) => (
        <Tooltip {...VIEW_REVISION_LINK_TOOLTIP}>
          <LinkIcon
            role={'button'}
            className={'statement-list__revisions-list--link-icon'}
            onClick={() => onRevisionLinkIconClick(revision)}
          />
        </Tooltip>
      ),
    }),
  ];
};

export const getDeletedRevisionHistoryListColumns = ({
  project,
  selectedStatement,
  onView,
  onRestore,
  onPermanentlyDelete,
}) => {
  const _canRestoreSoftDeleted = Permissions.Statement.canRestoreSoftDeleted();
  const _canPermanentlyDelete =
    !selectedStatement.legalHold &&
    Permissions.Statement.canPermanentlyDelete();
  let permanentlyDeleteTooltip, restoreSoftDeletedTooltip;
  if (!_canRestoreSoftDeleted) {
    restoreSoftDeletedTooltip = new TooltipOptions({
      text: 'permissions.delete.statement',
      id: 'delete-statement-tooltip-id',
      position: 'left',
    });
  }
  if (!_canPermanentlyDelete) {
    permanentlyDeleteTooltip = new TooltipOptions({
      text: 'permissions.delete.statement',
      id: 'delete-statement-tooltip-id',
      position: 'left',
    });
  }
  return [
    new DataGridColumn({
      key: 'revisionNumber',
      header: 'statement-list.revisions-modal-col.revision',
      width: '22.5%',
    }),
    new DataGridColumn({
      key: 'deleteDate',
      header: 'statement-list.deleted-revisions-modal-col.processed-date',
      width: '22.5%',
      formatter: (revision) =>
        moment(revision.deletedDate).format(DATE_FORMATS.MONTH_DAY_YEAR),
    }),
    new DataGridColumn({
      key: 'deletedBy',
      header: 'statement-list.deleted-revisions-modal-col.processed-by',
      width: '22.5%',
      formatter: (revision, { rowKey }) => {
        const colId = `last-modified-by-col-${rowKey}`;
        const displayText = revision.lastModifiedByUserEntity
          ? getUserNameByUserDataObject(revision.processedByUserEntity)
          : null;
        return (
          <MultiLineEllipsis
            toolTipID={colId}
            text={displayText}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: null,
      header: 'statement-list.revisions-modal-col.percent-verified',
      width: '22.5%',
      formatter: (revision, { rowKey }) => {
        return `${Math.round(
          (revision.percentageCompleted * 100 * 100) / 100,
        )}%`;
      },
    }),
    new DataGridKebabColumn({
      options: [
        new KebabMenuOption({
          title: 'statement-list.kebab.view',
          onSelectOption: onView,
        }),
        new KebabMenuOption({
          title: 'statement-list.kebab.restore',
          disabled: !_canRestoreSoftDeleted,
          tooltip: restoreSoftDeletedTooltip,
          shouldShowOption: () => !selectedStatement.isInArchivalFlow(),
          onSelectOption: onRestore,
        }),
        new KebabMenuOption({
          title: 'statement-list.kebab.delete-permanently',
          disabled: !_canPermanentlyDelete,
          tooltip: permanentlyDeleteTooltip,
          onSelectOption: onPermanentlyDelete,
        }),
      ],
      dropdownId: 'delete-revision-kebab-id',
      className: 'statement-list__delete-revision-modal-kebab-menu',
      usePortalMenu: false,
    }),
  ];
};
