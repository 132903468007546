import { createAction } from 'redux-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import Permissions from 'permissions/permissions';

export const setElementSelectModeDefaultAction = createAction(
  'DEFAULT_ELEMENT_SELECT_MODE',
);
export const setElementSelectModeBatchAction = createAction(
  'BATCH_ELEMENT_SELECT_MODE',
);
export const setElementSelectModeSideBySideAction = createAction(
  'SIDE_BY_SIDE_ELEMENT_SELECT_MODE',
);

export const setElementSelectModeBatchWithBannerAction = createAction(
  'SET_ELEMENT_SELECT_MODE_BATCH_WITH_BANNER_ACTION',
);

export const setElementSelectModeRangeAction = createAction(
  'RANGE_ELEMENT_SELECT_MODE',
);
export const setElementSelectModeInternalReferenceAction = createAction(
  'IR_ELEMENT_SELECT_MODE',
);
export const setElementSelectModeFormulaAction = createAction(
  'FORMULA_ELEMENT_SELECT_MODE',
);

export const setContentHighlightModeDefault = createAction(
  'DEFAULT_CONTENT_HIGHLIGHT_MODE',
);
export const setContentHighlightModeNotes = createAction(
  'NOTES_CONTENT_HIGHLIGHT_MODE',
);
export const setContentHighlightModeAddElement = createAction(
  'ADD_ELEMENT_CONTENT_HIGHLIGHT_MODE',
);
export const setContentHighlightModeAddNavigation = createAction(
  'ADD_NAVIGATION_CONTENT_HIGHLIGHT_MODE',
);
export const clearSelectedElementRangeFromStore = createAction(
  'ADD_NAVIGATION_CONTENT_HIGHLIGHT_MODE',
);

export const setElementSelectModeCopyFormulaAction = createAction(
  'COPY_FORMULA_ELEMENT_SELECT_MODE',
);
export const setBatchModeSelected = createAction('SET_BATCH_MODE_SELCTED');

export const clearBatchModeSelected = createAction('CLEAR_BATCH_MODE_SELCTED');

export const setBatchModeSelectedIfPossible = () => (dispatch, getState) => {
  const {
    data: {
      selectedProject: { id: selectedProjectId },
      selectedStatement,
    },
  } = getState();
  const _userCanBatch = Permissions.Element.canEditElement(selectedProjectId);
  const readOnly = selectedStatement.isReadOnly();
  if (_userCanBatch && !readOnly) {
    dispatch(setBatchModeSelected());
  }
};

export const setElementSelectModeBatchIfPossible =
  () => (dispatch, getState) => {
    const {
      data: {
        selectedProject: { id: selectedProjectId },
      },
      ui: {
        statementPage: {
          modes: { selectMode },
        },
      },
    } = getState();
    const _userCanBatch = Permissions.Element.canEditElement(selectedProjectId);
    if (_userCanBatch && selectMode === ELEMENT_SELECT_MODES.DEFAULT) {
      // only want to go to batch mode if in default mode
      dispatch(setElementSelectModeBatchAction());
    }
  };

export const setElementSelectModeSideBySideIfPossible =
  ({ isLeftStatementView }) =>
  (dispatch, getState) => {
    const {
      data: {
        selectedProject: { id: selectedProjectId },
      },
      ui: {
        sourceStatementParams: { projectId },
        statementPage: {
          modes: { selectMode },
        },
      },
    } = getState();
    const sideBySideProjectId = isLeftStatementView
      ? projectId
      : selectedProjectId;
    const _userCanBatch =
      Permissions.Element.canEditElement(sideBySideProjectId);
    if (_userCanBatch && selectMode === ELEMENT_SELECT_MODES.DEFAULT) {
      // only want to go to batch mode if in default mode
      dispatch(setElementSelectModeSideBySideAction());
    }
  };

export const setElementSelectModeRangeIfPossible =
  () => (dispatch, getState) => {
    const {
      data: {
        selectedProject: { id: selectedProjectId },
      },
      ui: {
        statementPage: {
          modes: { selectMode },
        },
      },
    } = getState();
    const _userCanBatch = Permissions.Element.canEditElement(selectedProjectId);
    if (_userCanBatch && selectMode === ELEMENT_SELECT_MODES.DEFAULT) {
      // only want to go to range mode if in default mode
      dispatch(setElementSelectModeRangeAction());
    }
  };

export const clearModeIfPossible = () => (dispatch, getState) => {
  const { selectMode } = getState().ui.statementPage.modes;
  if (
    selectMode === ELEMENT_SELECT_MODES.BATCH ||
    selectMode === ELEMENT_SELECT_MODES.BATCH_WITH_BANNER ||
    selectMode === ELEMENT_SELECT_MODES.RANGE ||
    selectMode === ELEMENT_SELECT_MODES.SIDE_BY_SIDE
  ) {
    // only want to go to default mode if in batch mode
    dispatch(setElementSelectModeDefaultAction());
    dispatch(clearSelectedElementRangeFromStore());
  }
};

export const clearCopyFormulaModeIfPossible = () => (dispatch, getState) => {
  const { selectMode } = getState().ui.statementPage.modes;
  if (selectMode === ELEMENT_SELECT_MODES.COPY_FORMULA) {
    // only want to go to default mode if in batch mode
    dispatch(setElementSelectModeDefaultAction());
    dispatch(clearSelectedElementRangeFromStore());
  }
};
