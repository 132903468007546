import React from 'react';
import PropTypes from 'prop-types';

export const WORKPAPER_ENTRY_BLOCK = 'workpaper-entry';

const WorkpaperEntry = ({ workpaper, rightComponent }) => {
  return (
    <div key={workpaper.referenceNumber} className={`${WORKPAPER_ENTRY_BLOCK}`}>
      <div className={`${WORKPAPER_ENTRY_BLOCK}__info`}>
        <span className={`${WORKPAPER_ENTRY_BLOCK}__ref-num`}>
          {workpaper.referenceNumber}
        </span>
        <span className={`${WORKPAPER_ENTRY_BLOCK}__ref-name`}>
          {workpaper.referenceName}
        </span>
      </div>
      {rightComponent}
    </div>
  );
};

WorkpaperEntry.propTypes = {
  /** Workpaper object from the workpaper list endpoint */
  workpaper: PropTypes.shape({
    workpaperRefId: PropTypes.number.isRequired,
    referenceName: PropTypes.string,
    referenceNumber: PropTypes.string.isRequired,
  }),
  /** Component used to children to the right of the main content, displayed as flex container with content*/
  rightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default WorkpaperEntry;
