import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import ElementTickmarkList from 'models/api/element-tickmark-list-model';
import ConditionalRender from 'components/util/conditional-render-component';
import TickmarkDetachEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-with-detach-component';
import Permissions from 'permissions/permissions';

const TICKMARK_LIST_BLOCK = 'annotations-tickmark-list';
const TICKMARK_LIST_ID_BLOCK = 'tickmark-list-id';

class TickmarkList extends Component {
  constructor(props) {
    super(props);
    this.state = { currentTickmarkId: undefined };
  }

  updateExpandedTickmarkID = (toggleStatus, id) => {
    !toggleStatus && this.setState({ currentTickmarkId: id });
  };

  render() {
    const {
      onAttach,
      tickmarkList,
      onDetach,
      selectedProjectId,
      readOnly,
    } = this.props;
    const _permissionToAttach = Permissions.Tickmark.canEditTickmark(
      selectedProjectId,
    );

    return (
      <div className={TICKMARK_LIST_BLOCK}>
        <div className={`${TICKMARK_LIST_BLOCK}__button-container`}>
          {!readOnly && (
            <Button
              id={`${TICKMARK_LIST_ID_BLOCK}-tickmark-attach-button`}
              className={`${TICKMARK_LIST_BLOCK}__tickmark-attach-button`}
              type={BUTTON_TYPES.primary}
              onClick={onAttach}
              disabled={!_permissionToAttach}
            >
              <FormattedMessage id={'common.attach'} />
            </Button>
          )}
        </div>
        <div className={`${TICKMARK_LIST_BLOCK}__listing-container`}>
          <ConditionalRender dependencies={[tickmarkList]}>
            {tickmarkList.hasTickmarks() ? (
              tickmarkList
                .getTickmarksSortedAlphabetically()
                .map((tickmark) => (
                  <TickmarkDetachEntry
                    key={tickmark.tickmarkId}
                    tickmark={tickmark}
                    onDetach={onDetach}
                    disabled={!_permissionToAttach || readOnly}
                    currentlyExpandedTickmarkID={this.state.currentTickmarkId}
                    updateExpandedTickmarkID={this.updateExpandedTickmarkID}
                  />
                ))
            ) : (
              <div className={`${TICKMARK_LIST_BLOCK}__no-tickmarks`}>
                <FormattedMessage
                  id={'element-panel.annotation.content.no-tickmarks'}
                />
              </div>
            )}
          </ConditionalRender>
        </div>
      </div>
    );
  }
}

TickmarkList.propTypes = {
  /** Action fired when user clicks the attach button */
  onAttach: PropTypes.func.isRequired,
  /** List of workpapers associated with this element */
  tickmarkList: PropTypes.instanceOf(ElementTickmarkList).isRequired,
  /** action fired to detach a work paper from this element */
  onDetach: PropTypes.func.isRequired,
  /** Currently selected project id */
  selectedProjectId: PropTypes.string.isRequired,
  /** Boolean for read only mode */
  readOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
  },
}) => ({ selectedProjectId: id });

export { TickmarkList };
export default connect(mapStateToProps)(memo(TickmarkList));
