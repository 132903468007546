import { createAction } from 'redux-actions';
import { setElementSelectModeDefaultAction } from 'store/actions/modes-actions';
import {
  replaceSelectedElementsContentPanel,
  selectElementContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import {
  getInternalReferenceByRevisionAndElementId,
  createOrAddToInternalReferenceRequest,
  detachElementFromInternalReferenceRequest,
} from 'api/internal-reference-api';

import { updateCacheWithMostRecentElementChanges } from './element-changes-since-tracker-actions';
import { getElementDetailsWithoutLoading } from './element-panel-actions';
import { updateElementsSearchByIds } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';

export const setInternalReferenceLoading = createAction(
  'INTERNAL_REFERENCE_LOADING',
);
export const setInternalReferenceLoaded = createAction(
  'INTERNAL_REFERENCE_LOADED',
);
export const setInternalReferenceLoadedWithoutPayload = createAction(
  'INTERNAL_REFERENCE_LOADED_WITHOUT_PAYLOAD',
);
export const setInternalReferenceError = createAction(
  'INTERNAL_REFERENCE_ERROR',
);

export const clearInternalReferenceAction = createAction(
  'CLEAR_INTERNAL_REFERENCE',
);

export const updateInternalReferenceElementsFromSocketAction = createAction(
  'UPDATE_INTERNAL_REFERENCE_ELEMENTS_FROM_SOCKET_ACTION',
);

export const updateInternalReferenceFromSocketAction = createAction(
  'UPDATE_INTERNAL_REFERENCE_FROM_SOCKET_ACTION',
);

export const getElementInternalReference = () => async (dispatch, getState) => {
  dispatch(setInternalReferenceLoading());
  dispatch(getInternalReferenceWithoutLoading());
};

export const getInternalReferenceWithoutLoading = () => async (
  dispatch,
  getState,
) => {
  try {
    const { elementDetails } = getState().data.elementPanel;
    const response = await getInternalReferenceByRevisionAndElementId({
      revisionId: elementDetails.revisionId,
      elementId: elementDetails.id,
    });
    dispatch(setInternalReferenceLoaded({ response }));
  } catch (error) {
    dispatch(setInternalReferenceError(error));
  }
};

export const addElementToInternalReference = ({
  targetElementId,
  isFromSuggestion,
}) => async (dispatch, getState) => {
  const stateData = getState().data;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  const { elementDetails, internalReference } = stateData.elementPanel;
  const _clickedElementAlreadyApartOfIR =
    targetElementId.toString() === elementDetails.id.toString() ||
    internalReference.hasElement(targetElementId);
  if (_clickedElementAlreadyApartOfIR) {
    // do nothing if clicked owning element
    return;
  }

  dispatch(setInternalReferenceLoading());

  try {
    const response = await createOrAddToInternalReferenceRequest({
      revisionId: elementDetails.revisionId,
      sourceElementId: elementDetails.id,
      targetElementId,
      isFromSuggestion,
    });
    if (socketHasBeenDisconnected) {
      dispatch(getElementDetailsWithoutLoading(elementDetails.id));
      dispatch(setInternalReferenceLoaded({ response }));
      dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
      dispatch(updateCacheWithMostRecentElementChanges());
    } else {
      setTimeout(() => {
        dispatch(setInternalReferenceLoadedWithoutPayload());
      }, 5000);
    }
  } catch (error) {
    dispatch(setInternalReferenceError(error));
  }
};

export const detachElementFromInternalReference = ({
  revisionId,
  internalReferenceId,
  elementId,
}) => async (dispatch, getState) => {
  const { elementDetails } = getState().data.elementPanel;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;

  dispatch(setInternalReferenceLoading());

  try {
    await detachElementFromInternalReferenceRequest({
      revisionId: elementDetails.revisionId,
      internalReferenceId: internalReferenceId,
      elementId: elementId,
    });
    /**
     * We have to fetch the element again as its flaggging display may have changed
     * after a internal reference is successfully detached.
     */
    if (socketHasBeenDisconnected) {
      dispatch(getElementDetailsWithoutLoading(elementDetails.id));
      // Update the appropriate elements in the cache to remove their annotation.
      dispatch(updateCacheWithMostRecentElementChanges());
      // Finally update the internal reference based on the element in the element panel.
      const response = await getInternalReferenceByRevisionAndElementId({
        revisionId: elementDetails.revisionId,
        elementId: elementDetails.id,
      });
      dispatch(setInternalReferenceLoaded({ response }));
      dispatch(updateElementsSearchByIds({ elementIds: [elementDetails.id] }));
    } else {
      setTimeout(() => {
        dispatch(setInternalReferenceLoadedWithoutPayload());
      }, 5000);
    }

    // Then update the highlighted elements on detach.
    dispatch(_updateElementHighlighting({ elementId: elementId }));
  } catch (error) {
    dispatch(setInternalReferenceError(error));
  }
};
const _updateElementHighlighting = ({ elementId }) => (dispatch, getState) => {
  const { elementDetails } = getState().data.elementPanel;
  // If it is not the element panel element, check if the element was highlighted before removing the highlight.
  if (elementId !== elementDetails.id) {
    const { selectedElementsMap } = getState().ui.statementPage;
    if (selectedElementsMap.has(elementId)) {
      dispatch(
        replaceSelectedElementsContentPanel({
          elementIds: [elementDetails.id],
        }),
      );
    }
  } else {
    // If the element was in fact the element panel element, simply set it as the only highlighted element.
    dispatch(
      replaceSelectedElementsContentPanel({
        elementIds: [elementDetails.id],
      }),
    );
  }
};

export const clearInternalReference = () => (dispatch, getState) => {
  dispatch(clearInternalReferenceAction());
  const { elementDetails } = getState().data.elementPanel;
  dispatch(
    replaceSelectedElementsContentPanel({
      elementIds: [elementDetails.id],
    }),
  );
  dispatch(setElementSelectModeDefaultAction());
};

export const selectElementFromInternalReference = ({ elementId }) => (
  dispatch,
  getState,
) => {
  const { elementDetails } = getState().data.elementPanel;
  dispatch(deallocateItems());
  dispatch(
    replaceSelectedElementsContentPanel({
      elementIds: [elementId],
      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
    }),
  );
  dispatch(
    selectElementContentPanel({
      elementIds: [elementDetails.id],
      color: ELEMENT_HIGHLIGHT_STATES.DEFAULT,
    }),
  );
};
