import { connect } from 'react-redux';
import {
  updateVerify,
  updateReviewed,
  updateUserFlag,
  updateOverrideSystemFlag,
  updateComfortAssignAnnotation,
} from 'store/actions/element-panel-actions';
import ElementStatus from 'components/feature/element-panel/element-status-component';
import {
  getElementTickmarksAndUpdateElementCache,
  attachTickmarkToElement,
} from 'store/actions/element-panel-actions';
import { fetchTickmarkListForRevisionWithoutLoading } from '../../../store/actions/tickmark-list-panel-actions';

const mapStateToProps = ({
  data: {
    revision,
    elementPanel: { elementDetails },
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  elementDetails,
  leftPanel: left,
});
const mapDispatchToProps = {
  updateVerify,
  updateReviewed,
  updateUserFlag,
  updateOverrideSystemFlag,
  updateComfortAssignAnnotation,
  onCreateTickmark: getElementTickmarksAndUpdateElementCache,
  onAttachTickmark: attachTickmarkToElement,
  fetchTickmarkList: fetchTickmarkListForRevisionWithoutLoading,
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementStatus);
