import { ReactComponent as StatementNavIcon } from 'icons/statement-navigator.svg';
import { ReactComponent as NoteOpen } from 'icons/notes.svg';
import { ReactComponent as TickmarkIcon } from 'icons/tickmark.svg';
import { ReactComponent as CompareIcon } from 'icons/compare-documents.svg';
import { ReactComponent as ExportIcon } from 'icons/export.svg';
import { ReactComponent as WPIcon } from 'icons/WP.svg';
import { ReactComponent as Rocket } from 'icons/rocket-grey.svg';
import { ReactComponent as ComfortAnnotationIcon } from 'icons/comfort-annotation.svg';
import { ReactComponent as SideBySideIcon } from 'icons/compare.svg';


import ToolkitOption from 'models/utils/toolkit-option-model';
export const TOOLKIT_OPTIONS = {
  statementNav: new ToolkitOption({
    id: 'toolkit-statement-navigator',
    Icon: StatementNavIcon,
  }),
  notes: new ToolkitOption({
    id: 'toolkit-notes',
    Icon: NoteOpen,
  }),
  tickmarks: new ToolkitOption({
    id: 'toolkit-tickmarks',
    Icon: TickmarkIcon,
  }),
  compare: new ToolkitOption({
    id: 'toolkit-compare',
    Icon: CompareIcon,
  }),
  exportOption: new ToolkitOption({
    id: 'toolkit-export',
    Icon: ExportIcon,
  }),
  workPaper: new ToolkitOption({
    id: 'toolkit-workpaper',
    Icon: WPIcon,
  }),
  auditChecklist: new ToolkitOption({
    id: 'toolkit-audit-checklist',
    Icon: Rocket,
  }),
  comfortAnnotationPanel: new ToolkitOption({
    id: 'comfort-annotation-panel',
    Icon: ComfortAnnotationIcon,
  }),
  sideBySidePanel: new ToolkitOption({
    id: 'side-by-side-view',
    Icon: SideBySideIcon,
  }),
};
