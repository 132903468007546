import React from 'react';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import Tooltip from 'components/common/tool-tip-component';
import PropTypes from 'prop-types';
import SummaryElements from 'models/api/statement-summary-elements-model';
import { flaggedElementFilter } from 'constants/feature/statement-summary-constants';
import { FormattedMessage } from 'react-intl';

export const STATEMENT_SUMMARY_FLAGGED = 'statement-summary-flagged';

const StatementSummaryFlaggedComponent = ({
  shouldShowFlagsSummary,
  summaryElements,
  showElementListPanel,
}) => {
  return (
    shouldShowFlagsSummary && (
      <div className={STATEMENT_SUMMARY_FLAGGED}>
        <p className={`${STATEMENT_SUMMARY_FLAGGED}__heading`}>
          <FormattedMessage id="statement-summary.heading.flag" />
        </p>
        <Tooltip
          id={`${STATEMENT_SUMMARY_FLAGGED}-flagged`}
          text="statement-summary.elements-flagged.label-tooltip"
          position="bottom"
        >
          <button
            className={`${STATEMENT_SUMMARY_FLAGGED}-flagged ${STATEMENT_SUMMARY_FLAGGED}__button`}
            onClick={() => showElementListPanel(flaggedElementFilter)}
          >
            <FlaggedIcon className={`${STATEMENT_SUMMARY_FLAGGED}__icon`} />
            <div className={`${STATEMENT_SUMMARY_FLAGGED}__label`}>
              ({summaryElements.flagged})
            </div>
          </button>
        </Tooltip>
      </div>
    )
  );
};

StatementSummaryFlaggedComponent.propTypes = {
  /** Action fired when button is clicked to open element list panel */
  showElementListPanel: PropTypes.func.isRequired,
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElements).isRequired,
  /** Obj containing boolean for displaying flags option */
  shouldShowFlagsSummary: PropTypes.bool.isRequired,
};

export default StatementSummaryFlaggedComponent;
