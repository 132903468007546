import { handleActions } from 'redux-actions';
import {
  statementSummarySectionsLoading,
  statementSummarySectionsLoaded,
  statementSummarySectionsError,
  updateSectionSummaryFromSocketPayloadAction,
} from 'store/actions/statement-summary/sections-summary-actions';
import StatementSummarySections from 'models/api/statement-summary-sections-model';

export default handleActions(
  {
    [statementSummarySectionsLoading]: (sectionSummary) => {
      return sectionSummary.setLoading();
    },
    [statementSummarySectionsError]: (sectionSummary, { payload }) => {
      return sectionSummary.setError(payload);
    },
    [statementSummarySectionsLoaded]: (sectionSummary, { payload }) => {
      return sectionSummary.setLoaded(payload);
    },
    [updateSectionSummaryFromSocketPayloadAction]: (
      sectionSummary,
      { payload },
    ) => {
      return sectionSummary.updateSectionSummaryFromSocketPayload(payload);
    },
  },
  new StatementSummarySections(),
);
