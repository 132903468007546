import { handleActions } from 'redux-actions';

import WorkpaperToolkitListPanel from 'models/api/workpaper-toolkit-list-panel-api-model';
import {
  workpaperToolkitListLoading,
  workpaperToolkitListLoaded,
  workpaperToolkitListError,
  fetchElementsFromWorkpaperAction,
  clearWorkpaperElementsAction,
  updateWorkpaperElementsMapAction,
  removeWorkpaperFromElementsMapAction,
  unlinkElementFromElementsMapAction,
  updateWorkpaperElementsMapFromArrayAction,
  removeElementsFromElementsMapWithSocketPayloadAction,
  addWorkpaperToDeletedArrayAction,
  removeElementFromElementsMapAction,
  addWorkpaperToListAction,
  unlinkWorkpaperFromElementWithWebsocketPayload,
  updateWorkpaperWithSocketPayloadAction,
  deleteWorkpaperWithSocketPayloadAction,
} from 'store/actions/workpaper-toolkit-list-actions';

export default handleActions(
  {
    [workpaperToolkitListLoading]: (workpaperList) => {
      return workpaperList.setLoading();
    },
    [workpaperToolkitListLoaded]: (workpaperList, { payload }) => {
      return workpaperList.setLoaded(payload);
    },
    [workpaperToolkitListError]: (workpaperList, { payload }) => {
      return workpaperList.setError(payload);
    },
    [fetchElementsFromWorkpaperAction]: (workpaperList, { payload }) => {
      return workpaperList.setElementList(payload);
    },
    [clearWorkpaperElementsAction]: (workpaperList) => {
      return workpaperList.clearElementList();
    },
    [updateWorkpaperElementsMapAction]: (workpaperList, { payload }) => {
      return workpaperList.updateWorkpaperElementsMap(payload);
    },
    [updateWorkpaperElementsMapFromArrayAction]: (
      workpaperList,
      { payload },
    ) => {
      return workpaperList.updateWorkpaperElementsMapFromArray(payload);
    },
    [removeElementsFromElementsMapWithSocketPayloadAction]: (
      workpaperList,
      { payload },
    ) => {
      return workpaperList.removeElementsFromElementsMapWithSocketPayload(
        payload,
      );
    },
    [removeWorkpaperFromElementsMapAction]: (workpaperList, { payload }) => {
      return workpaperList.removeWorkpaperFromElementsMap(payload);
    },
    [unlinkElementFromElementsMapAction]: (workpaperList, { payload }) => {
      return workpaperList.unlinkElementFromElementsMap(payload);
    },
    [addWorkpaperToDeletedArrayAction]: (workpaperList, { payload }) => {
      return workpaperList.addWorkpaperToDeletedArray(payload);
    },
    [removeElementFromElementsMapAction]: (workpaperList, { payload }) => {
      return workpaperList.removeElementFromElementsMap(payload);
    },
    [addWorkpaperToListAction]: (workpaperList, { payload }) => {
      return workpaperList.addWorkpaperToList(payload);
    },
    [unlinkWorkpaperFromElementWithWebsocketPayload]: (
      workpaperList,
      { payload },
    ) => {
      return workpaperList.unlinkWorkpaperFromElementWithWebsocket(payload);
    },
    [updateWorkpaperWithSocketPayloadAction]: (workpaperList, { payload }) => {
      return workpaperList.updateWorkpaperWithSocketPayload(payload);
    },
    [deleteWorkpaperWithSocketPayloadAction]: (workpaperList, { payload }) => {
      return workpaperList.deleteWorkpaperWithSocketPayload(payload);
    },
  },
  new WorkpaperToolkitListPanel(),
);
