import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TabsGroup from 'components/common/tabs-group-component';
import TabModel from 'models/utils/common/tab-model';

import { DATA_GRID_BLOCK } from './data-grid-component';
import { DataGridFilter } from './data-grid-component';
import { isNullOrUndefined } from 'utils/object-utils';

import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import classNames from 'classnames';

const FILTER_ICON_SIZE = '28px';

const DataGridHeader = ({
  tableId,
  filters,
  title,
  rightHeaderButton,
  tabsArray,
  selectedTab,
  onSelectTab,
  className,
  rightIcon,
}) => {
  const HEADER_BLOCK = `${DATA_GRID_BLOCK}__header`;
  const _shouldDisplayFilters = () => filters && filters.length > 0;
  const _shouldDisplayTabs = () =>
    !isNullOrUndefined(tabsArray) && tabsArray.length > 0;
  return (
    <div className={classNames(HEADER_BLOCK, className)}>
      {title && (
        <h3 className={`${HEADER_BLOCK}__title`}>
          <FormattedMessage id={title} />
        </h3>
      )}
      {_shouldDisplayTabs() && (
        <TabsGroup
          tabsArray={tabsArray}
          id={tableId}
          className={`${HEADER_BLOCK}__tabs`}
          selectedTab={selectedTab}
          onSelectTab={onSelectTab}
          rightIcon={rightIcon}
        />
      )}
      {_shouldDisplayFilters() && (
        <FilterIcon
          role="button"
          width={FILTER_ICON_SIZE}
          length={FILTER_ICON_SIZE}
          className={`${HEADER_BLOCK}__filter`}
        />
      )}
      {rightHeaderButton ? (
        <div className={`${HEADER_BLOCK}__right-button-container`}>
          {rightHeaderButton}
        </div>
      ) : null}
    </div>
  );
};

DataGridHeader.propTypes = {
  /** Unique key string for each table, should be same value as tableId passed to sibling DataGrid */
  tableId: PropTypes.string.isRequired,
  /** Array of TabModels if tabs are desired */
  tabsArray: PropTypes.arrayOf(PropTypes.instanceOf(TabModel)),
  /** Array of filter model definitions, passing an array causes the filter icon to be displayed */
  filters: PropTypes.arrayOf(PropTypes.instanceOf(DataGridFilter)),
  /** Table header */
  title: PropTypes.string,
  /** An optional right header button for the data grid header */
  rightHeaderButton: PropTypes.instanceOf(Object),
  /** Model representing the currently selected tab */
  selectedTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Function fired on tab selection */
  onSelectTab: PropTypes.func.isRequired,
  /**Will be used apply external classname over this component */
  className: PropTypes.string,
  /**Any icon to be displayed at right side of tab button*/
  rightIcon: PropTypes.object,
};

export default memo(DataGridHeader);
