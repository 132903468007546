import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'components/common/checkbox-component';

export const CHECKBOX_LIST_BLOCK = 'checkbox-list';
const CheckboxList = ({
  itemsList,
  setCheckbox,
  masterCheckboxChecked,
  setMasterCheckbox,
  disabled,
  className,
  id,
}) => {
  const itemsListLength = itemsList.length;
  const checkedAmount = itemsList.filter((element) => {
    return element.checked === 1;
  }).length;

  /* setMasterCheckbox it is only going to return a value if the user select all the checkboxes manually
  so this is going to check if the amount of checked checkboxes is equal to the amount of items on the list
  if its true, return a true value to set the master checkbox value , same for false. 
  This is made like this to handle the case were the user select all the checkboxes manually and the case in which
  all the checkboxes are selected and the user deselect one or more checkboxes manually*/
  useEffect(() => {
    if (checkedAmount === itemsListLength && itemsListLength > 0) {
      setMasterCheckbox(true);
    } else if (
      masterCheckboxChecked &&
      itemsListLength > 0 &&
      checkedAmount === 0
    ) {
      setMasterCheckbox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedAmount]);

  return (
    <div className={classNames(CHECKBOX_LIST_BLOCK, className)}>
      <div className={classNames(`${CHECKBOX_LIST_BLOCK}__master-checkbox`)}>
        <Checkbox
          id={`${CHECKBOX_LIST_BLOCK}-master-${id}`}
          checked={masterCheckboxChecked}
          label={'common.master-checkbox'}
          width={'auto'}
          onChange={() => {
            setMasterCheckbox();
          }}
          masterChecked={checkedAmount > 0 && checkedAmount < itemsListLength}
          disabled={disabled}
        />
      </div>
      {itemsList.map((item, index) => (
        <div
          className={classNames(`${CHECKBOX_LIST_BLOCK}__checkbox-container`)}
          key={`${id}-${index}`}
        >
          <Checkbox
            id={`${CHECKBOX_LIST_BLOCK}-${id}-${index}`}
            checked={item.checked ? true : false}
            label={item.label}
            isNotIntl={true}
            onChange={() => {
              setCheckbox(index);
            }}
            disabled={item.disabled}
            tooltip={item.disabled ? item.tooltip : null}
            needsMultilineLabel={true}
          />
        </div>
      ))}
    </div>
  );
};

CheckboxList.propTypes = {
  /* Array of objects to be shown on the list*/
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
  ).isRequired,
  /** function to set the current checked status */
  setCheckbox: PropTypes.func.isRequired,
  /* Id of the current checkbox list */
  id: PropTypes.string.isRequired,
  /* bool with checked value of master checkbox */
  masterCheckboxChecked: PropTypes.bool.isRequired,
  /** function to set the current checked status of master checkbox */
  setMasterCheckbox: PropTypes.func.isRequired,
  /* bool to disable or enable checkbox */
  disabled: PropTypes.bool,
  /* className to be added to the component */
  className: PropTypes.string,
};

export default CheckboxList;
