import { handleActions } from 'redux-actions';
import {
  omniaEngagementLoading,
  omniaEngagementLoaded,
  omniaEngagementError,
} from 'store/actions/omnia-engagement-actions';
import OmniaEngagement from 'models/api/omnia-engagement-model';

export default handleActions(
  {
    [omniaEngagementLoading]: (omniaEngagement, { payload }) => {
      return omniaEngagement.setLoading(payload);
    },
    [omniaEngagementLoaded]: (omniaEngagement, { payload }) => {
      return omniaEngagement.setLoaded(payload);
    },
    [omniaEngagementError]: (omniaEngagement, { payload }) => {
      return omniaEngagement.setError(payload);
    },
  },
  new OmniaEngagement()
);
