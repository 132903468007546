import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Button, { BUTTON_TYPES } from './button-component';

import { ModalButton } from 'models/utils/common/modal-button-model';

import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { ESC_KEY_CODE } from 'constants/util/key-code-constants';

const MODAL_BLOCK = 'modal';
const MODAL_CLOSE_ICON_SIZE = '28px';
export const MODAL_CLOSE_ICON_ID = 'modal-close-icon-id';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = createRef();
    this.primaryButtonRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClose, false);
    document.addEventListener('keydown', this._handleClose, false);
    this._preventBodyScroll();
    this.primaryButtonRef.current.focus();
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClose, false);
    document.removeEventListener('keydown', this._handleClose, false);
    this._restoreBodyScroll();
  }

  _preventBodyScroll = () => {
    const body = document.querySelector('body');
    body.classList.add('modal-open');
  };

  _restoreBodyScroll = () => {
    const body = document.querySelector('body');
    body.classList.remove('modal-open');
  };

  _handleClose = (event) => {
    const _userClickedOutside =
      event.type === 'mousedown' &&
      !this.modalRef.current.contains(event.target);
    if (
      // Close the modal if you click outside of it, OR if the ESC key is pressed.
      event.keyCode === ESC_KEY_CODE ||
      _userClickedOutside
    ) {
      this.props.onClose();
    }
  };

  _hasButtons() {
    const {
      primaryModalButton,
      secondaryModalButton,
      tertiaryModalButton,
      primaryDeleteModalButton,
    } = this.props;

    return (
      primaryModalButton ||
      secondaryModalButton ||
      tertiaryModalButton ||
      primaryDeleteModalButton
    );
  }

  render() {
    const {
      id,
      children,
      stepNumber,
      totalSteps,
      title,
      onClose,
      primaryModalButton,
      secondaryModalButton,
      tertiaryModalButton,
      className,
      primaryDeleteModalButton,
    } = this.props;

    return ReactDOM.createPortal(
      <div className={`${MODAL_BLOCK}-backdrop`} id={id}>
        <div ref={this.modalRef} className={classnames(MODAL_BLOCK, className)}>
          <div
            className={classnames(
              title
                ? `${MODAL_BLOCK}__header`
                : `${MODAL_BLOCK}__header-without-title`,
            )}
          >
            <div className={`${MODAL_BLOCK}__title-container`}>
              {stepNumber && totalSteps ? (
                <div className={`${MODAL_BLOCK}__step-title`}>
                  <FormattedMessage
                    id="modal.stepHeader"
                    values={{
                      stepNumber: stepNumber,
                      totalSteps: totalSteps,
                    }}
                  />
                </div>
              ) : null}
              {/* Sometimes we want dynamic value for title as well, hence we want to omit the static value */}
              {title && (
                <h5 className={`${MODAL_BLOCK}__title`}>
                  <FormattedMessage id={title} />
                </h5>
              )}
            </div>
            <CloseButton
              id={MODAL_CLOSE_ICON_ID}
              name={'close-button'}
              role={'button'}
              className={`${MODAL_BLOCK}__close`}
              width={MODAL_CLOSE_ICON_SIZE}
              height={MODAL_CLOSE_ICON_SIZE}
              onClick={onClose}
            />
          </div>
          <div className={`${MODAL_BLOCK}__body`}>{children}</div>
          {this._hasButtons() ? (
            <div className={`${MODAL_BLOCK}__footer`}>
              {tertiaryModalButton && (
                <Button
                  id={`${id}-tertiary-button`}
                  type={BUTTON_TYPES.tertiary}
                  onClick={tertiaryModalButton.onClick}
                  disabled={tertiaryModalButton.disabled}
                  isTooltipRequiredForDisabled
                >
                  <FormattedMessage id={tertiaryModalButton.text} />
                </Button>
              )}
              {secondaryModalButton && (
                <Button
                  id={`${id}-secondary-button`}
                  type={BUTTON_TYPES.secondary}
                  onClick={secondaryModalButton.onClick}
                  disabled={secondaryModalButton.disabled}
                  isTooltipRequiredForDisabled
                >
                  <FormattedMessage id={secondaryModalButton.text} />
                </Button>
              )}
              {primaryDeleteModalButton && (
                <Button
                  id={`${id}-delete-button`}
                  ref={this.primaryButtonRef}
                  type={BUTTON_TYPES.delete}
                  onClick={primaryDeleteModalButton.onClick}
                  disabled={primaryDeleteModalButton.disabled}
                  isTooltipRequiredForDisabled
                >
                  <FormattedMessage id={primaryDeleteModalButton.text} />
                </Button>
              )}
              {primaryModalButton && (
                <Button
                  ref={this.primaryButtonRef}
                  id={`${id}-primary-button`}
                  type={BUTTON_TYPES.primary}
                  onClick={primaryModalButton.onClick}
                  disabled={primaryModalButton.disabled}
                  tooltip={primaryModalButton.tooltip}
                  isTooltipRequiredForDisabled
                >
                  <FormattedMessage id={primaryModalButton.text} />
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>,
      document.getElementById('modal-root'),
    );
  }
}

Modal.propTypes = {
  /** Node of children components to be included inside the main body area of the modal */
  children: PropTypes.node,
  /** String indicating the current step number of the modal if it runs in a series */
  stepNumber: PropTypes.string,
  /** String indicating the total number of steps for the modal if it runs in a series */
  totalSteps: PropTypes.string,
  /** internationalized string title of the modal */
  title: PropTypes.string,
  /** Unique string id for modal component */
  id: PropTypes.string.isRequired,
  /** function fired onClick of the close button */
  onClose: PropTypes.func.isRequired,
  /** Object with Modal Button primary button parameters passed into the modal */
  primaryModalButton: PropTypes.instanceOf(ModalButton),
  /** Object with Modal Button secondary button parameters passed into the modal */
  secondaryModalButton: PropTypes.instanceOf(ModalButton),
  /** Object with Modal Button tertiary button parameters passed into the modal */
  tertiaryModalButton: PropTypes.instanceOf(ModalButton),
  /** custom className for modal */
  className: PropTypes.string,
  /** Object with Modal Button tertiary button parameters passed into the modal */
  primaryDeleteModalButton: PropTypes.instanceOf(ModalButton),
};

export default Modal;
