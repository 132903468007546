import { handleActions } from 'redux-actions';
import { toggleBlacklineViewMode } from 'store/actions/blackline-view-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [toggleBlacklineViewMode]: (blacklineViewMode) => {
      return !blacklineViewMode;
    },
    [clearStatementPageStoreAction]: () => {
      return false;
    },
  },
  false,
);
