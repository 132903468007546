import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';

class SelectedElementModel extends BaseModel({
  count: 1, // number of times element has been selected
  color: ELEMENT_HIGHLIGHT_STATES.DEFAULT,
}) {
  increment() {
    return this.merge({ count: this.count + 1 });
  }
  decrement() {
    return this.merge({ count: this.count - 1 });
  }
}
/**
 * A set of selected element ids, keys are removed when they are no longer selected
 */
export default class SelectedElementMap extends BaseModel({
  /* shape is
    {
      [elementId]: SelectedElementModel
      ...
    }
  */
}) {
  /**
   * Reducer function for simultaneously clearing old selected ids and initializing
   * the set with new ones
   * @param {int[]} param.elementIds array of element ids
   */
  replaceWithElements({
    elementIds,
    color = ELEMENT_HIGHLIGHT_STATES.DEFAULT,
  }) {
    const elementMap = {};
    elementIds.forEach((id) => {
      elementMap[id] = new SelectedElementModel({ color });
    });
    return new SelectedElementMap({ ...elementMap });
  }
  // replaces element without modifying count
  replaceWithoutRemovingOld({
    elementIds,
    color = ELEMENT_HIGHLIGHT_STATES.DEFAULT,
  }) {
    const elementMap = {};
    elementIds.forEach((id) => {
      if (this.has(id)) {
        this[id].color = color;
      } else {
        elementMap[id] = new SelectedElementModel({ color });
      }
    });
    return new SelectedElementMap({ ...this, ...elementMap });
  }

  /**
   * Reducer function for appending elements to the selection set
   * @param {int[]} param.elementIds array of element ids
   */
  appendSelectedElements({
    elementIds,
    color = ELEMENT_HIGHLIGHT_STATES.DEFAULT,
  }) {
    const elementMap = {};
    elementIds.forEach((id) => {
      // add 1 to existing or init as 1
      if (this.has(id)) {
        elementMap[id] = this.get(id).increment();
      } else {
        elementMap[id] = new SelectedElementModel({ color });
      }
    });
    return this.merge({
      ...elementMap,
    });
  }

  /**
   * Reducer function for appending an element to the selection set
   * @param {int} param.elementId
   */
  appendSelectedElement({ elementId, color }) {
    if (!color) color = ELEMENT_HIGHLIGHT_STATES.DEFAULT;
    if (this.has(elementId)) {
      return this.merge({
        [elementId]: this.get(elementId).increment(),
        [elementId]: new SelectedElementModel({ color }),
      });
    }
    return this.merge({
      [elementId]: new SelectedElementModel({ color }),
    });
  }

  /**
   * Reducer function for deselecting an element
   * @param {int} param.elementId
   */
  deselectElement({ elementId }) {
    if (this.has(elementId)) {
      const element = this.get(elementId);
      //In this case there is only one instance of the element in the map. We remove it completely here.
      if (element.count === 1) {
        const newMap = { ...this };
        delete newMap[elementId];
        return new SelectedElementMap({ ...newMap });
      } else {
        // In this case we are simply decrementing the count for the selected element.
        const newMap = { ...this };
        newMap[elementId] = element.decrement();
        return new SelectedElementMap({ ...newMap });
      }
    }
    return this;
  }
  deselectElementsFromArray(elements) {
    elements.forEach((elem) => {
      if (this.has(elem.id)) {
        const element = this.get(elem.id);
        //In this case there is only one instance of the element in the map. We remove it completely here.
        if (element.count === 1) {
          const newMap = { ...this };
          delete newMap[elem.id];
          return new SelectedElementMap({ ...newMap });
        } else {
          // In this case we are simply decrementing the count for the selected element.
          const newMap = { ...this };
          newMap[elem.id] = element.decrement();
          return new SelectedElementMap({ ...newMap });
        }
      }
      return this;
    });
  }
  removeElement({ elementId }) {
    if (this.has(elementId)) {
      const newMap = { ...this };
      delete newMap[elementId];
      return new SelectedElementMap({ ...newMap });
    }
    return this;
  }

  /**
   * Reducer function for clearing the set
   */
  clearMap() {
    return new SelectedElementMap();
  }

  getElementCount(id) {
    if (this.has(id)) {
      return this[id].count;
    }
    return 0;
  }

  getElementHighlightColor(id) {
    if (this.has(id)) {
      return this[id].color;
    }
    return null;
  }

  get(id) {
    return this[id];
  }

  has(id) {
    return !isNullOrUndefined(this[id]) && this[id].count > 0;
  }
  deallocateElement(payload) {
    let keys = Object.keys(this);
    for (let i = 0; i < keys.length; i++) {
      if (this[keys[i]].color === ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED) {
        this[keys[i]].color = ELEMENT_HIGHLIGHT_STATES.DEFAULT;
      }
    }
    return this;
  }
}
