import React, { memo, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ElementDetails from 'models/api/element-details-api-model';
import FormulaForm from 'models/form/formula-form-model';
import {
  ROUND_NUMBER_ICON_HEIGHT,
  ROUND_NUMBER_ICON_WIDTH,
  getOriginalComputedValueTooltip,
  getResultDisplay,
} from 'constants/feature/formula-constants';
import Tooltip from 'components/common/tool-tip-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';
import { ReactComponent as RoundNumberIcon } from 'icons/round-number.svg';
import { isNullOrUndefined } from 'utils/object-utils';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ConditionalRender from 'components/util/conditional-render-component';
import { ReactComponent as FlaggedIcon } from 'icons/flagged.svg';
import RowOperator from './formula-row/_formula-operator-component';
import ManualRow from './formula-row/_formula-row-manual-input-component';
import ElementRow from './formula-row/_formula-row-element-component';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import FormulaPanelRoundNumberModal from './_formula-panel-round-number-modal-component';
import { shouldDisplayValueBeFlagged } from 'utils/formula-utils';

export const FORMULA_PANEL_EDIT_BLOCK = 'formula-panel-edit';
export const FORMULA_PANEL_EDIT_ID_BLOCK = 'formula-panel-edit-id';
export const FLAGGED_FORMULA_ICON_WIDTH = '15px';
export const FLAGGED_FORMULA_ICON_HEIGHT = '19px';
const FORMULA_BUTTON_ICON_SIZE = '17.5';

const FormulaPanelEdit = ({
  elementDetails,
  formulaInProgress,
  updateRowOperator,
  addOperator,
  addManualRow,
  setManualNumber,
  removeFormulaRow,
  removeRowOperator,
  removeAllRows,
  checkEmptyInput,
  selectElement,
  setSelectedRow,
  deallocateItems,
  setRoundingResult,
  numberFormatId,
}) => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const previousSelected = useRef('');
  const [showRoundNumberModal, setShowRoundNumberModal] = useState(false);
  const [toggleShowMore, setToggleShowMore] = useState(true);
  const [roundNumberInputValue, setRoundNumberInputValue] = useState(0);

  //this sets the intial value for the input box
  useEffect(() => {
    const isFirstRounding = isNullOrUndefined(formulaInProgress.roundingScale);

    setRoundNumberInputValue(
      isFirstRounding ? '' : formulaInProgress.roundingScale,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaInProgress]);

  /* Manages current and previous clicked formula row */
  useEffect(() => {
    if (selectedRowId) {
      if (previousSelected.current) {
        selectElement({
          elementIds: [previousSelected.current],
          color: ELEMENT_HIGHLIGHT_STATES.HOVERED_FROM_FORMULA,
        });
      }
      deallocateItems();
      selectElement({
        elementIds: [selectedRowId],
        color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
      });

      previousSelected.current = selectedRowId;
    }
  }, [deallocateItems, previousSelected, selectElement, selectedRowId]);

  const getFormulaRows = () => {
    return formulaInProgress.rows.map((row, index) => {
      const rowAbove = formulaInProgress.getRow(index - 1);
      const rowBelow = formulaInProgress.getRow(index + 1);
      const _rowAboveIsValue =
        !isNullOrUndefined(rowAbove) && !rowAbove.isOperator();
      const _rowBelowIsValue =
        !isNullOrUndefined(rowBelow) && !rowBelow.isOperator();
      const _disableRemoveOperator = _rowAboveIsValue && _rowBelowIsValue;
      if (row.isOperator()) {
        return (
          <RowOperator
            disableRemove={_disableRemoveOperator}
            key={`${FORMULA_PANEL_EDIT_ID_BLOCK}-operator-${row.key}`}
            onSelect={(operator) => updateRowOperator({ operator, index })}
            onRemove={() => removeRowOperator(index)}
            operator={row.operator}
          />
        );
      } else {
        return (
          <div key={`${FORMULA_PANEL_EDIT_ID_BLOCK}-number-${row.key}`}>
            <div className={`${FORMULA_PANEL_EDIT_BLOCK}__formula-row`}>
              {row.isManual() && (
                <ManualRow
                  formulaRow={row}
                  formulaRowIndex={index}
                  setManualNumber={setManualNumber}
                  checkEmptyInput={checkEmptyInput}
                  setSelectedRowNumber={setSelectedRow}
                />
              )}
              {row.isElement() && (
                <ElementRow
                  formulaRow={row}
                  sectionId={elementDetails.sectionId}
                  setSelectedRow={setSelectedRowId}
                  checkEmptyInput={checkEmptyInput}
                  numberFormatId={numberFormatId}
                />
              )}
              <Button.IconButton
                id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-clear-row-button`}
                className={`${FORMULA_PANEL_EDIT_BLOCK}__clear-row-button`}
                type={BUTTON_TYPES.icon}
                onClick={() => {
                  removeFormulaRow({
                    formulaRow: row,
                    formulaRowIndex: index,
                  });
                  //if user clicks on any button on the edit formula panel is going to reset the selected row
                  //to avoid keeping a row value that is not being selected at the moment
                  setSelectedRow(null);
                }}
                Icon={CrossIcon}
              />
            </div>
            {row.isElement() && !row.elementActive && (
              <div
                className={`${FORMULA_PANEL_EDIT_BLOCK}__formula-row--error`}
              >
                <FormattedMessage id="formula-panel.formula-row.inactive-error" />
              </div>
            )}
          </div>
        );
      }
    });
  };

  const getValidationMessage = () => {
    if (!formulaInProgress.areManualInputsRealNumbers())
      return (
        <Banner
          type={BANNER_TYPES.ERROR}
          id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-real-numbers-error-banner`}
          className={`${FORMULA_PANEL_EDIT_BLOCK}__error-banner`}
          bannerCopy={'formula-panel.content.real-numbers-validation.banner'}
          isInternationalized={true}
        />
      );

    if (!formulaInProgress.areParenthesesBalanced()) {
      return (
        <Banner
          type={BANNER_TYPES.ERROR}
          id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-parentheses-error-banner`}
          className={`${FORMULA_PANEL_EDIT_BLOCK}__error-banner`}
          bannerCopy={'formula-panel.content.parentheses-validation.banner'}
          isInternationalized={true}
        />
      );
    } else if (
      (!isNullOrUndefined(formulaInProgress.formulaId) ||
        formulaInProgress.isNewFormula) &&
      formulaInProgress.isFormulaFlagged()
    ) {
      return (
        <Banner
          type={BANNER_TYPES.WARNING}
          id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-flagged-formula-banner`}
          className={`${FORMULA_PANEL_EDIT_BLOCK}__warning-banner`}
          bannerCopy={{
            id: 'formula-panel.content.flagged-formula.banner',
            values: {
              flaggedFormula: (
                <div>
                  <p
                    className={`${FORMULA_PANEL_EDIT_BLOCK}__warning-banner-title`}
                  >
                    <FormattedMessage id="formula-panel.content.flagged-formula.banner.title" />
                  </p>
                  {!toggleShowMore && (
                    <p
                      className={classnames(
                        `${FORMULA_PANEL_EDIT_BLOCK}__warning-banner-text`,
                      )}
                    >
                      <FormattedMessage id="formula-panel.content.flagged-formula.banner.text" />
                    </p>
                  )}
                  <button
                    className={`${FORMULA_PANEL_EDIT_BLOCK}__show-more-button`}
                    onClick={() =>
                      setToggleShowMore((prevToggle) => !prevToggle)
                    }
                  >
                    <FormattedMessage
                      id={
                        toggleShowMore ? 'common.show-more' : 'common.show-less'
                      }
                    />
                  </button>
                </div>
              ),
            },
          }}
          isInternationalized={true}
        />
      );
    }
  };
  const getRoundedNumberIcon = () => {
    return (
      <div className={`${FORMULA_PANEL_EDIT_BLOCK}__prefix-icon`}>
        <Tooltip
          {...getOriginalComputedValueTooltip(
            getResultDisplay({
              result: formulaInProgress.result,
              numberFormatId,
            }),
          )}
        >
          <RoundNumberIcon
            className={`${FORMULA_PANEL_EDIT_BLOCK}__rounded-icon`}
            width={ROUND_NUMBER_ICON_WIDTH}
            height={ROUND_NUMBER_ICON_HEIGHT}
          />
        </Tooltip>
      </div>
    );
  };
  return (
    <div className={`${FORMULA_PANEL_EDIT_BLOCK}`}>
      {getValidationMessage() && (
        <div className={`${FORMULA_PANEL_EDIT_BLOCK}__banner-container`}>
          {getValidationMessage()}
        </div>
      )}
      <div className={`${FORMULA_PANEL_EDIT_BLOCK}__input-row-container`}>
        <ConditionalRender dependencies={[formulaInProgress]}>
          {getFormulaRows()}
        </ConditionalRender>
      </div>
      <div className={`${FORMULA_PANEL_EDIT_BLOCK}__bottom`}>
        <div className={`${FORMULA_PANEL_EDIT_BLOCK}__result-container`}>
          <div
            className={classnames(
              shouldDisplayValueBeFlagged(elementDetails, formulaInProgress)
                ? `${FORMULA_PANEL_EDIT_BLOCK}__flagged`
                : `${FORMULA_PANEL_EDIT_BLOCK}__computed-amount`,
            )}
          >
            {(!isNullOrUndefined(formulaInProgress.formulaId) ||
              formulaInProgress.isNewFormula) &&
              formulaInProgress.isFormulaFlagged(elementDetails) &&
              !elementDetails.isSystemOverrideFlagged() && (
                <div className={`${FORMULA_PANEL_EDIT_BLOCK}__flagged-icon`}>
                  <span>=</span>
                  {formulaInProgress.isFormulaRounded(elementDetails) &&
                    getRoundedNumberIcon()}
                  <FlaggedIcon
                    width={FLAGGED_FORMULA_ICON_WIDTH}
                    height={FLAGGED_FORMULA_ICON_HEIGHT}
                  />
                </div>
              )}
            <div className={`${FORMULA_PANEL_EDIT_BLOCK}__display-value`}>
              {(!formulaInProgress.isFormulaFlagged(elementDetails) ||
                elementDetails.isSystemOverrideFlagged()) && <span>=</span>}
              {formulaInProgress.isFormulaRounded(elementDetails) &&
                (!formulaInProgress.isFormulaFlagged(elementDetails) ||
                  elementDetails.isSystemOverrideFlagged()) &&
                getRoundedNumberIcon()}
              {getResultDisplay({
                result: !isNullOrUndefined(formulaInProgress.roundingResult)
                  ? formulaInProgress.roundingResult
                  : formulaInProgress.computedResult,
                numberFormatId,
              })}
            </div>
          </div>
          {formulaInProgress.hasRows() && (
            <button
              id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-round-number`}
              className={`${FORMULA_PANEL_EDIT_BLOCK}__round-number`}
              onClick={() => setShowRoundNumberModal(true)}
            >
              <FormattedMessage
                id={
                  formulaInProgress.isFormulaRounded()
                    ? 'formula-edit-panel.edit-rounded-number'
                    : 'formula-edit-panel.round-number-modal.title'
                }
              />
            </button>
          )}
        </div>
        <div className={`${FORMULA_PANEL_EDIT_BLOCK}__button-container`}>
          <div className={`${FORMULA_PANEL_EDIT_BLOCK}__button-row`}>
            <div className={`${FORMULA_PANEL_EDIT_BLOCK}__buttons`}>
              {/* TODO: on nex sprint we will work on this button functionality, until then it will be hide
               <Button.IconButton
                id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-change-operator-button`}
                className={`${FORMULA_PANEL_EDIT_BLOCK}__change-operator-button`}
                type={BUTTON_TYPES.icon}
                disabled={!formulaInProgress.hasRows()}
                onClick={() => {}}
                Icon={PlusMinusIcon}
              /> */}
              <Button.IconButton
                id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-clear-all-button`}
                className={`${FORMULA_PANEL_EDIT_BLOCK}__clear-all-button`}
                type={BUTTON_TYPES.icon}
                disabled={!formulaInProgress.hasRows()}
                onClick={() => {
                  removeAllRows();
                  //if user clicks on any button on the edit formula panel is going to reset the selected row
                  //to avoid keeping a row value that is not being selected at the moment
                  setSelectedRow(null);
                }}
                Icon={RefreshIcon}
                iconSize={FORMULA_BUTTON_ICON_SIZE}
              />
            </div>
          </div>
          <div className={`${FORMULA_PANEL_EDIT_BLOCK}__button-row`}>
            <div className={`${FORMULA_PANEL_EDIT_BLOCK}__buttons`}>
              <Button.IconButton
                id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-add-operator-button`}
                className={`${FORMULA_PANEL_EDIT_BLOCK}__add-operator-button`}
                type={BUTTON_TYPES.icon}
                Icon={PlusIcon}
                onClick={() => {
                  addOperator();
                  //if user clicks on any button on the edit formula panel is going to reset the selected row
                  //to avoid keeping a row value that is not being selected at the moment
                  setSelectedRow(null);
                }}
                disabled={formulaInProgress.disableAddOperator()}
                iconSize={FORMULA_BUTTON_ICON_SIZE}
              >
                <FormattedMessage id="formula-panel.edit.add-operator" />
              </Button.IconButton>
              <Button.IconButton
                id={`${FORMULA_PANEL_EDIT_ID_BLOCK}-add-row-button`}
                className={`${FORMULA_PANEL_EDIT_BLOCK}__add-row-button`}
                type={BUTTON_TYPES.icon}
                Icon={PlusIcon}
                onClick={() => {
                  addManualRow();
                  //if user clicks on any button on the edit formula panel is going to reset the selected row
                  //to avoid keeping a row value that is not being selected at the moment
                  setSelectedRow(null);
                }}
                disabled={formulaInProgress.disableAddRow()}
                iconSize={FORMULA_BUTTON_ICON_SIZE}
              >
                <FormattedMessage id="formula-panel.edit.add-row" />
              </Button.IconButton>
            </div>
          </div>
        </div>
      </div>
      {showRoundNumberModal && (
        <FormulaPanelRoundNumberModal
          onSave={(newValue) => {
            setRoundingResult({ newValue, roundNumberInputValue });
            setShowRoundNumberModal(false);
          }}
          onCancel={() => {
            if (isNullOrUndefined(formulaInProgress.roundingScale)) {
              setRoundNumberInputValue('');
            } else {
              setRoundNumberInputValue(formulaInProgress.roundingScale);
            }
            setShowRoundNumberModal(false);
          }}
          numberFormatId={numberFormatId}
          currentNumber={
            !isNullOrUndefined(formulaInProgress.roundingResult)
              ? formulaInProgress.roundingResult
              : formulaInProgress.computedResult
          }
          inputValue={roundNumberInputValue}
          setInputValue={setRoundNumberInputValue}
          computedResult={formulaInProgress.result}
          roundingScale={formulaInProgress.roundingScale}
          removeRounding={() => {
            setRoundNumberInputValue('');
            setRoundingResult({ newValue: null, roundNumberInputValue: null });
            setShowRoundNumberModal(false);
          }}
        />
      )}
    </div>
  );
};
FormulaPanelEdit.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** The formula we are currently editing */
  formulaInProgress: PropTypes.instanceOf(FormulaForm).isRequired,
  /** The action to be fired when an operator is updated in a row of the formula */
  updateRowOperator: PropTypes.func.isRequired,
  /** The action to be fired when we add an operator to the formula */
  addOperator: PropTypes.func.isRequired,
  /** The action to be fired when we add a row to the formula */
  addManualRow: PropTypes.func.isRequired,
  /** The action to be fired when one of the formula row needs to persisted and validated. */
  setManualNumber: PropTypes.func.isRequired,
  /** Action fired when an operator is to be removed */
  removeRowOperator: PropTypes.func.isRequired,
  /** Action fired when a formula row is to be removed */
  removeFormulaRow: PropTypes.func.isRequired,
  /** Action fired when we want to remove all the rows. */
  removeAllRows: PropTypes.func.isRequired,
  /** Event handler to check for empty input field */
  checkEmptyInput: PropTypes.func.isRequired,
  /* Action to be fired when one formula row is selected */
  selectElement: PropTypes.func.isRequired,
  /* action to set the value of the actual selected row */
  setSelectedRow: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /* action to set the computed value of the current formula */
  setRoundingResult: PropTypes.func.isRequired,
};
export default memo(FormulaPanelEdit);
