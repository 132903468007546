import { handleActions } from 'redux-actions';

import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import {
  showElementPanelAction,
  hideElementPanelAction,
  hideRightPanelAction,
  showNotesPanelAction,
  hideNotesPanelAction,
  showBatchPanelAction,
  hideBatchPanelAction,
  showSectionDetailsPanelAction,
  hideSectionDetailsPanelAction,
} from 'store/actions/panel-controller-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';

const { CLOSED, ELEMENT, BATCH, NOTES, SECTION } = RIGHT_PANELS;

export default handleActions(
  {
    [hideRightPanelAction]: () => {
      return CLOSED;
    },
    [showElementPanelAction]: () => {
      return ELEMENT;
    },
    [hideElementPanelAction]: () => {
      return CLOSED;
    },
    [showNotesPanelAction]: () => {
      return NOTES;
    },
    [hideNotesPanelAction]: () => {
      return CLOSED;
    },
    [showBatchPanelAction]: () => {
      return BATCH;
    },
    [hideBatchPanelAction]: () => {
      return CLOSED;
    },
    [showSectionDetailsPanelAction]: () => {
      return SECTION;
    },
    [hideSectionDetailsPanelAction]: () => {
      return CLOSED;
    },
    [clearStatementPageStoreAction]: () => {
      return CLOSED;
    },
  },
  CLOSED,
);
