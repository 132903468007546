import React from 'react';
import htmlToText from 'html-to-text';
import moment from 'moment';
import { postRoleStateRequest } from '../../api/admin-module-api';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { FORBIDDEN } from 'http-status-codes';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Warning } from 'icons/warning.svg';

export const getText = (richText) => {
  const text = htmlToText.fromString(richText, {
    wordwrap: false, // by default it adds new lines after 80 chars, need to disable to ensure no new characters are added
  });
  // we need to remove ascii codes from strings
  // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
  // eslint-disable-next-line no-control-regex
  const withoutNonAscii = text.replace(/[^\x00-\x7F]/g, '');
  const withoutNewlines = withoutNonAscii.replace(/\n/g, '');
  return withoutNewlines;
};
export const SWITCH_ENABLER = 'ENABLER';
export const SWITCH_ADMIN = 'ADMIN';
export const SWITCH_COE = 'COE';
export const BANNER_ICON_SIZE = '18px';
export const ADMIN_MODULE_TOAST_MESSAGE_BLOCK = 'admin-module-toast-message';

export const updateColumnDataAndPost = async (
  userData,
  updatedFieldValue,
  currentField,
) => {
  if (currentField === SWITCH_ENABLER) {
    userData.enabled = updatedFieldValue;
    userData.enabledDate = userData.enabled ? moment() : null;
    userData.disabledDate = userData.enabled ? null : moment();
    userData.enabled
      ? await saveEnableApplicationAccessRequest(
          userData,
          'enabler',
          updatedFieldValue,
          moment(),
          null,
        )
      : await saveEnableApplicationAccessRequest(
          userData,
          'enabler',
          updatedFieldValue,
          null,
          moment(),
        );
  }
  if (currentField === SWITCH_ADMIN) {
    userData.admin = updatedFieldValue;
    await saveEnableApplicationAccessRequest(
      userData,
      'admin',
      updatedFieldValue,
      null,
      null,
    );
  }
  if (currentField === SWITCH_COE) {
    userData.coe = updatedFieldValue;
    await saveRoleState(userData, 'coe', updatedFieldValue, null, null);
  }
};

const saveEnableApplicationAccessRequest = async (
  user,
  roleState,
  isRoleUpdate,
  enabledDate,
  disabledDate,
) => {
  try {
    await saveRoleState(
      user,
      roleState,
      isRoleUpdate,
      enabledDate,
      disabledDate,
    );
  } catch (e) {
    if (e.response && e.response.status === FORBIDDEN) {
      toast.warn(
        <>
          <Warning
            name={'warning'}
            className={`${ADMIN_MODULE_TOAST_MESSAGE_BLOCK}__info_icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <FormattedMessage id="notifications.admin-module-disable-access-country-manager.message" />
        </>,
        { autoClose: 5000, pauseOnHover: true },
      );
    }
    // this will be catched in the calling method where we want to revert back the toggle status to previous state for the user details
    throw new Error('api call failed while updating user details');
  }
};

const saveRoleState = async (
  user,
  roleState,
  isRoleUpdate,
  enabledDate,
  disabledDate,
) =>
  await postRoleStateRequest(
    user,
    roleState,
    isRoleUpdate,
    enabledDate,
    disabledDate,
  );

export const getContainerListOptionsMap = (containerList) => {
  const containerListMap = new Map();
  containerList.forEach((item) => {
    const option = new MenuOption({
      id: item.containerCode,
      title: item.containerName,
      value: item.containerCode,
      isIntl: false,
      disabled: false,
    });
    containerListMap.set(item.containerCode, option);
  });
  return containerListMap;
};
