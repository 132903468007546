import { MenuOption } from 'models/utils/common/menu/menu-option-model';

export const JOB_TYPES = {
  PPMD: 'PPMD',
  ENGAGEMENT_OWNER: 'Engagement Owner',
  OBSERVER: 'Observer',
  PREPARER_REVIEWER: 'Preparer/Reviewer',
  ADMIN: 'ADMIN',
  COE: 'COE',
};

export const USER_ROLE_OPTIONS_MAP = {
  [JOB_TYPES.PREPARER_REVIEWER]: new MenuOption({
    id: 'manage-users-preparer-reviewer-option',
    title: 'common.user-roles.preparer-reviewer',
    value: JOB_TYPES.PREPARER_REVIEWER,
  }),
  [JOB_TYPES.ENGAGEMENT_OWNER]: new MenuOption({
    id: 'manage-users-engagement-owner-option',
    title: 'common.user-roles.engagement-owner',
    value: JOB_TYPES.ENGAGEMENT_OWNER,
  }),
  [JOB_TYPES.OBSERVER]: new MenuOption({
    id: 'manage-users-observer-option',
    title: 'common.user-roles.observer',
    value: JOB_TYPES.OBSERVER,
  }),
  [JOB_TYPES.PPMD]: new MenuOption({
    id: 'manage-users-ppmd-option',
    title: 'common.user-roles.ppmd',
    value: JOB_TYPES.PPMD,
  }),
};
