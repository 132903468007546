import { handleActions } from 'redux-actions';
import { leftElementDatapartStoreAction } from 'store/actions/statement-content-actions';
import ElementDataParts from 'models/api/element-dataparts-model';

export default handleActions(
  {
    [leftElementDatapartStoreAction]: (dataPartsModel, { payload }) => {
      const { response } = payload;
      return dataPartsModel.updateDatapartElements({ response });
    },
  },
  new ElementDataParts(),
);
