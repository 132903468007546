const isNullOrUndefined = (val) => val === null || val === undefined;
const isObjectEmpty = (obj) =>
  obj.constructor === Object && Object.entries(obj).length === 0;
const extractPropFromFunctionOrValue = (val) => {
  if (typeof val === 'function') {
    return val();
  } else {
    return val;
  }
};
export { isNullOrUndefined, isObjectEmpty, extractPropFromFunctionOrValue };
