import { handleActions } from 'redux-actions';
import {
  fetchLeftSectionReviewsLoaded,
  fetchLeftSectionReviewsError,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { SectionReviewList } from 'models/api/section-review-list-api-model';

export default handleActions(
  {
    [fetchLeftSectionReviewsLoaded]: (SectionReviewList, { payload }) => {
      return SectionReviewList.setLoaded(payload);
    },

    [fetchLeftSectionReviewsError]: (SectionReviewList, { payload }) => {
      return SectionReviewList.setError(payload);
    },
  },
  new SectionReviewList(),
);
