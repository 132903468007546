import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import FlyoutHeader from 'components/common/omnia/flyout-header-component';
import FlyoutFooter from 'components/common/omnia/flyout-footer-component';
import FlyoutPanelContent from 'components/common/omnia/flyout-panel-content-component';
import ContentViewModes from 'models/data/content-view-modes-model';

/**
 * @group Layouts
 * A Flyout component which appears to the far right (by default) or far left of     the screen.  It can be configured with
 * a Header and Footer (separate components) and can contain any content.
 *
 *  The header and footer can be used as many times anywhere within the component to display header or footer such as titles and buttons or any component that utilizes and header body and footer
 *
 * It may also contain just the panel content(body) by pulling in the body panel from the omnia folder
 *
 * Refer to flyout-example1.jsx for an example of how to use the flyout Component
 *
 * It may be used without a header, body or footer and rendered with just children but no styling will be applied: This may be useful for customizing it and styling it differently from the default
 */

const FLYOUT_BLOCK = 'flyoutPanel';
const SCROLLBAR_HEIGHT = 12;

class Flyout extends PureComponent {
  render() {
    const {
      children,
      className,
      position,
      show,
      contentViewMode,
      flyoutRef,
    } = this.props;
    const transitionProps = {
      in: show,
      appear: true, // allows a panel to be able to animate in on the first mount
      timeout: 300,
      classNames: FLYOUT_BLOCK,
    };

    const bottomOffset = contentViewMode.contentFullMode ? SCROLLBAR_HEIGHT : 0;

    return (
      <CSSTransition {...transitionProps}>
        <div
          className={classNames(FLYOUT_BLOCK, className, position)}
          style={{ bottom: bottomOffset }}
          ref={flyoutRef}
        >
          {children}
        </div>
      </CSSTransition>
    );
  }
}

Flyout.defaultProps = {
  className: 'panelContent',
  position: 'right',
};

Flyout.propTypes = {
  /** A css class to include on the Flyout's wrapping div. */
  className: PropTypes.string,

  /** Indicates what position the flyout component is left or right. */
  position: PropTypes.string,

  /** Indicates whether the flyout is shown. */
  show: PropTypes.bool,

  /** Indicates whether the flyout is animated. */
  isAnimated: PropTypes.bool,

  /** View mode for statement content panel */
  contentViewMode: PropTypes.instanceOf(ContentViewModes).isRequired,

  /* flyout ref */
  flyoutRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

const mapStateToProps = ({
  ui: {
    statementPage: {
      modes: { contentViewMode },
    },
  },
}) => ({
  contentViewMode,
});

export default connect(mapStateToProps, null)(Flyout);

export { Flyout, FlyoutPanelContent, FlyoutHeader, FlyoutFooter };
