import BaseModel from 'models/base-model';
import ElementsBySectionMap from 'models/api/elements-by-section-map';
import { isNullOrUndefined } from 'utils/object-utils';
import ElementDetails from 'models/api/element-details-api-model';

export default class ElementCache extends BaseModel({
  /* 
  { 
    [sectionId]: ElementsBySectionMap,
    ...
  }
*/
}) {
  /**
   * Reducer function for setting error on specific sections when their request fail
   * @param {int} param.sectionId
   * @param {object} param.error
   */
  setSectionError({ sectionId, error }) {
    let errorSection;
    if (this.hasSection(sectionId)) {
      errorSection = this.getSection(sectionId).setError(error);
    } else {
      errorSection = new ElementsBySectionMap().setError(error);
    }
    return this.merge({
      [sectionId]: errorSection,
    });
  }

  /**
   * Reducer function for setting loading on specific sections
   * @param {int} param.sectionId
   */
  setSectionLoading({ sectionId }) {
    let loadingSection;
    if (this.hasSection(sectionId)) {
      loadingSection = this.getSection(sectionId).setLoading();
    } else {
      loadingSection = new ElementsBySectionMap().setLoading();
    }
    return this.merge({
      [sectionId]: loadingSection,
    });
  }

  /**
   * Reducer function for setting the element data by section id
   * @param {int} param.sectionId
   * @param {object} param.response
   */
  setSectionLoaded({ response, sectionId }) {
    let loadedSection;
    if (this.hasSection(sectionId)) {
      loadedSection = this.getSection(sectionId).setLoaded({
        response,
        sectionId,
      });
    } else {
      loadedSection = new ElementsBySectionMap().setLoaded({
        response,
        sectionId,
      });
    }

    return this.merge({
      [sectionId]: loadedSection,
    });
  }

  /** Reducer function called whenever a user has fetched new element details to be displayed
   * in the Element Panel, directly called in a reducer on the cache based on the elementDetailsLoaded Action
   */
  setElementFromElementDetailsLoaded({ response, sectionId, elementId }) {
    if (this.hasSection(sectionId)) {
      return this.merge({
        [sectionId]: this.getSection(sectionId).setElementLoaded({
          response,
          elementId,
        }),
      });
    }
    return this;
  }

  /**
   * Reducer function for clearing section element data
   * @param {int[]} param.removedSectionIds
   */
  clearSections({ removedSectionIds }) {
    const newMap = { ...this };
    removedSectionIds.forEach((id) => {
      delete newMap[id];
    });

    // cannot use `this.merge` because we are REMOVING ids from the map
    // merge would not remove the ids we want to remove
    return new ElementCache(newMap);
  }

  hasSection(sectionId) {
    const sectionMap = this.getSection(sectionId);
    return !isNullOrUndefined(sectionMap);
  }

  hasElement({ sectionId, elementId }) {
    if (this.hasSection(sectionId)) {
      return this.getSection(sectionId).has(elementId);
    }
    return false;
  }

  getSection(sectionId) {
    return this[sectionId];
  }

  getCurrentSectionIds() {
    return Object.keys(this);
  }

  /**
   * Fetches elements from the cache if they exist
   *
   * NOTE: Always try to fetch elements by sectionId and elementId
   * HOWEVER, since sometimes we will not have the sectionId available when trying to get an element from the cache
   * (e.g. batch select) we can iterate through the existing section ids and see if the element exists.
   * This is still somewhat performant because we only keep elements in the cache whose sections are in the sections
   * cache
   */
  getElement({ sectionId, elementId }) {
    if (isNullOrUndefined(sectionId)) {
      const sectionIdsInCache = this.getCurrentSectionIds();
      let foundElement;
      sectionIdsInCache.some((cachedSectionId) => {
        let section = this.getSection(cachedSectionId);
        foundElement = section.has(elementId) ? section.get(elementId) : null;
        return !isNullOrUndefined(foundElement);
      });
      return foundElement; // should still return null if it is never found
    } else if (this.hasSection(sectionId)) {
      return this.getSection(sectionId).get(elementId);
    }
    return null;
  }

  /** Updates an individual element for the given section
   * NOTE: element should already be converted to type ElementDetails
   *
   * @param {ElementDetails} param.element the updated content model for the given sectionId
   * @param {Number} param.sectionid the section id of the element to update
   */
  updateElement({ element, sectionId }) {
    if (this.hasSection(sectionId)) {
      return this.merge({
        [sectionId]: this.getSection(sectionId).updateElement({
          element,
        }),
      });
    }
    return this;
  }

  /**
   * This function is used to update multiple elements in the cache. This is useful for causing scalable and responsive
   * content panel updates for things like annotations based on user interaction in places like side panels, etc..
   *
   * @param {ElementDetails[]} elements
   */
  updateMultipleElements({ elements }) {
    let elementBySectionMapUpdates = {};
    /**
     * We construct an in-memory map of sections to elements so we can efficiently update them in the map model.
     * This map maps sectionIds to arrays of ElementDetail models.
     */
    let sectionToElementMap = {};
    if (!isNullOrUndefined(elements) && elements.length > 0) {
      elements.forEach((element) => {
        if (this.hasSection(element.sectionId)) {
          // only initialize sections that are in the cache (thus currently in view)
          let elementToUpdate = new ElementDetails();
          //Initialize the array of elements if it doesn't exist.
          if (isNullOrUndefined(sectionToElementMap[element.sectionId])) {
            sectionToElementMap[element.sectionId] = [
              elementToUpdate.setLoadedFromElementData({
                data: element,
              }),
            ];
          } else {
            sectionToElementMap[element.sectionId].push(
              elementToUpdate.setLoadedFromElementData({
                data: element,
              }),
            );
          }
        }
      });
      /**
       * After we have built up the map of sections to arrays of elements, we can iterate through the different sections
       * and do a single merge to update them on the map model object.
       */
      Object.keys(sectionToElementMap).forEach((sectionId) => {
        const elementsToUpdate = sectionToElementMap[sectionId];
        elementBySectionMapUpdates[sectionId] = this.getSection(
          sectionId,
        ).updateMultipleElements({ elementsToUpdate });
      });
      // Finally update the model with the updated element by section maps.
      return this.merge({
        ...elementBySectionMapUpdates,
      });
    }
    return this;
  }
}
