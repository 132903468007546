import { isArray } from 'lodash';
import set from 'lodash/set';

export const collateCurrentUserDetail = (apiResponses) => {
  // User having Admin access in any of the region should be treated as admin.
  const hasAdminAccess =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res &&
        res.data &&
        res.data.result &&
        (res.data.result.countryManager === true ||
          res.data.result.admin === true),
    );

  // User having COE access in any of the region should be treated as COE.
  const hasCoeAccess =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res && res.data && res.data.result && res.data.result.coe === true,
    );

  // User having application manager access in any of the region should be treated as application manager.
  const hasApplicationManagerAccess =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res &&
        res.data &&
        res.data.result &&
        res.data.result.applicationManager === true,
    );

  const isEnabled =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res && res.data && res.data.result && res.data.result.enabled === true,
    );

  const isActive =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res && res.data && res.data.result && res.data.result.active === true,
    );

  const isPpmd =
    isArray(apiResponses) &&
    apiResponses.some(
      (res) =>
        res && res.data && res.data.result && res.data.result.isPpmd === true,
    );

  const collatedResponse = apiResponses[0];
  const projectToGeoMap = new Map();

  if (Array.isArray(apiResponses)) {
    apiResponses.forEach(({ data }) => {
      if (data) {
        const { result: { userRoles, geoCode } = {} } = data;
        const projectIdWithRoleEntries = Object.entries(userRoles);

        projectIdWithRoleEntries.forEach((entry) => {
          const [projectId, role] = entry;
          if (!projectToGeoMap.has(`${projectId}-${geoCode}`)) {
            projectToGeoMap.set(`${projectId}-${geoCode}`, { geoCode, role });
          }
        });
      }
    });
    set(collatedResponse, 'data.result.projectToGeoMap', projectToGeoMap);
    // set project to geo map in session
    sessionStorage.setItem('projectToGeoMap', JSON.stringify(projectToGeoMap));
    // set admin access prop
    set(collatedResponse, 'data.result.admin', hasAdminAccess);
    // set coe access prop
    set(collatedResponse, 'data.result.coe', hasCoeAccess);
    //set applicationManager access prop
    set(
      collatedResponse,
      'data.result.applicationManager',
      hasApplicationManagerAccess,
    );
    set(collatedResponse, 'data.result.active', isActive);
    set(collatedResponse, 'data.result.enabled', isEnabled);
    set(collatedResponse, 'data.result.isPpmd', isPpmd);

    delete collatedResponse.data.result.userRoles;
  }
  return { response: { result: collatedResponse.data.result } };
};
