import { handleActions } from 'redux-actions';
import { toggleNavigationEditMode } from 'store/actions/navigation-edit-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions({
    [toggleNavigationEditMode]: (navigationEditMode) => {
      return !navigationEditMode;
    },
    [clearStatementPageStoreAction]: () => {
      return false;
    },
  },
  false
);
