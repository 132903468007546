import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import {
  fetchTheListOfNotes,
  clearNoteSelectionAction,
  handleNoteCreationSuccess,
  clearAllNoteFilters,
} from 'store/actions/notes-panel-actions';
import { hideNotesPanelAction } from 'store/actions/panel-controller-actions';
import NotesInputArea from 'components/feature/notes-panel/notes-panel-input-area-component';
import NotesList from 'components/feature/notes-panel/_notes-panel-notes-list-component';
import NotesForm from 'models/form/notes-form-model';
import {
  createNotesRequest,
  sendEmailToTaggedUsersRequest,
} from 'api/notes-api';
import Revision from 'models/api/revision-api-model';
import NoteSelection from 'models/data/note-content-selection-model';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import SelectedStatement from 'models/api/selected-statement-model';
import NotesPanelHeader from 'components/feature/notes-panel/_notes-panel-header-component';
import RoleUtil from 'permissions/utils/roles';

export const NOTES_PANEL_BLOCK = 'notes-panel';

const NotesPanel = ({
  shouldShowNotesPanel,
  selectedProjectId,
  hideNotesPanel,
  addNoteSelection,
  fetchTheListOfNotes,
  revision,
  clearNoteSelection,
  handleNoteCreationSuccess,
  selectedStatement,
  clearAllNoteFilters,
}) => {
  const DEFAULT_NOTE_TYPE =
    RoleUtil.doesUserHaveCoeRoleForProject(selectedProjectId) ||
    RoleUtil.doesUserHaveObserverRoleForProject(selectedProjectId)
      ? 'O'
      : 'I';
  const [noteInProgress, setNoteInProgress] = useState(
    new NotesForm().setNoteType({ type: DEFAULT_NOTE_TYPE }),
  );
  const [addNoteMode, setAddNoteMode] = useState(false);

  useEffect(() => {
    return () => {
      clearAllNoteFilters();
    };
  }, [clearAllNoteFilters]);

  useEffect(() => {
    if (!addNoteMode && addNoteSelection.hasSelection()) {
      setAddNoteMode(true);
    }
    // set the notes form to empty when a new text selection occurs
    setNoteInProgress(new NotesForm().setNoteType({ type: DEFAULT_NOTE_TYPE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteSelection, addNoteMode]);

  useEffect(() => {
    if (shouldShowNotesPanel) {
      fetchTheListOfNotes({
        revisionId: revision.id,
      });
    }
  }, [shouldShowNotesPanel, fetchTheListOfNotes, revision]);

  const _onCreateNewNote = async () => {
    setNoteInProgress(noteInProgress.setLoading());
    try {
      const response = await createNotesRequest({
        revisionId: revision.id,
        notesForm: noteInProgress,
        selection: addNoteSelection,
      });

      await handleNoteCreationSuccess({ noteId: response.data.result.noteId });
      if (
        noteInProgress.toSendEmailFormat().sendEmailTo &&
        noteInProgress.toSendEmailFormat().sendEmailTo.length > 0
      ) {
        await sendEmailToTaggedUsersRequest({
          revisionId: revision.id,
          emailRequestBody: noteInProgress.toSendEmailFormat(),
        });
      }

      setAddNoteMode(false);
      setNoteInProgress(
        new NotesForm().setNoteType({ type: DEFAULT_NOTE_TYPE }),
      );
    } catch (error) {
      setNoteInProgress(noteInProgress.setError(error));
    }
  };

  const _cancelAddNote = () => {
    setAddNoteMode(false);
    setNoteInProgress(new NotesForm().setNoteType({ type: DEFAULT_NOTE_TYPE }));
    clearNoteSelection();
  };

  const _closeTheNotesPanel = () => {
    hideNotesPanel();
    clearNoteSelection();
    setNoteInProgress(new NotesForm().setNoteType({ type: DEFAULT_NOTE_TYPE }));
    setAddNoteMode(false);
  };

  const readOnly = selectedStatement.isReadOnly();

  return (
    <Flyout show={shouldShowNotesPanel}>
      <FlyoutHeader enableClose onClose={_closeTheNotesPanel}>
        <NotesPanelHeader />
      </FlyoutHeader>
      <FlyoutPanelContent>
        <div>
          {addNoteMode && !readOnly && (
            <NotesInputArea
              cancelAddNote={_cancelAddNote}
              noteInProgress={noteInProgress}
              setNoteInProgress={setNoteInProgress}
              onCreateNote={_onCreateNewNote}
            />
          )}
          <NotesList />
        </div>
      </FlyoutPanelContent>
    </Flyout>
  );
};

NotesPanel.propTypes = {
  /** boolean for showing the notes panel */
  shouldShowNotesPanel: PropTypes.bool.isRequired,
  /** Currently open revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** Action fired to hide the notes panel */
  hideNotesPanel: PropTypes.func.isRequired,
  /** The note selection object created when the user highlights content and clicks add note */
  addNoteSelection: PropTypes.instanceOf(NoteSelection).isRequired,
  /** Action for fetching the current list of notes for selected text on statement */
  fetchTheListOfNotes: PropTypes.func.isRequired,
  /** Action that dispatches all redux actions required after successfully creating a note */
  handleNoteCreationSuccess: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** remove all filters */
  clearAllNoteFilters: PropTypes.func.isRequired,
  /**Id of the selected project */
  selectedProjectId: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  data: {
    notesPanel: { addNoteSelection },
    revision,
    selectedStatement,
    selectedProject: { id },
  },
  ui: {
    statementPage: {
      panels: { right },
    },
  },
}) => ({
  shouldShowNotesPanel: right === RIGHT_PANELS.NOTES,
  addNoteSelection,
  revision,
  selectedStatement,
  selectedProjectId: id,
});

const mapDispatchToProps = {
  hideNotesPanel: hideNotesPanelAction,
  fetchTheListOfNotes,
  clearNoteSelection: clearNoteSelectionAction,
  handleNoteCreationSuccess,
  clearAllNoteFilters,
};

export { NotesPanel };
export default connect(mapStateToProps, mapDispatchToProps)(NotesPanel);
