import { handleActions } from 'redux-actions';
import {
  selectedNoteLoadingAction,
  selectedNoteLoadedAction,
  selectedNoteErrorAction,
  selectNoteFromNoteListAction,
  changeIsNewPropertyFromSelectedNote,
} from 'store/actions/notes-panel-actions';
import NoteApi from 'models/api/note-api-model';
export default handleActions(
  {
    [selectedNoteLoadingAction]: (note, { payload }) => {
      return note.setLoading(payload);
    },
    [selectedNoteLoadedAction]: (note, { payload }) => {
      return note.setLoaded(payload);
    },
    [selectedNoteErrorAction]: (note, { payload }) => {
      return note.setError(payload);
    },
    [selectNoteFromNoteListAction]: (note, { payload }) => {
      return note.setFromNoteListEntry(payload);
    },
    [changeIsNewPropertyFromSelectedNote]: (note, { payload }) => {
      return note.changeIsNewPropertyFromSelectedNote(payload);
    },
  },
  new NoteApi(),
);
