import BaseModel from 'models/base-model';

export default class ToolkitDownloadData extends BaseModel({
  downloadId: null,
  name: null,
  dateTime: null,
  downloadStatus: false,
  userDownloadedReportStatus: true,
  downloadResponse: null,
  storageGuid: null,
  reportType: null,
  isLoading: true,
  isLoaded: false,
  error: null,
}) {}
