import React from 'react';
import { RadioOption } from 'models/utils/common/radio-options-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { ReactComponent as OpenIcon } from 'icons/open-non-priority.svg';
import { ReactComponent as ClosedIcon } from 'icons/closed-non-priority.svg';
import { ReactComponent as ClosedPriorityIcon } from 'icons/closed-priority.svg';
import { ReactComponent as OpenPriorityIcon } from 'icons/open-priority.svg';
import Permissions from 'permissions/permissions';

/** This replaces any found Button tag around an element with an anchor tag for the BE */
export const formatTieoutSelectorForApi = ({ selector }) => {
  return selector.replace('BUTTON', 'A');
};

export const NOTE_TYPES = {
  CLIENT: {
    short: 'C',
    intlId: 'notes-panel.note-type.client',
  },
  REVIEW: {
    short: 'I',
    intlId: 'notes-panel.note-type.review',
  },
  OBSERVER: {
    short: 'O',
    intlId: 'notes-panel.note-type.observer',
  },
};

export const NOTE_STATUS_TYPES = {
  OPEN: 'O',
  CLOSED: 'C',
};

export const NOTE_PRIORITY_TYPES = {
  NORMAL: 'NORMAL',
  HIGH: 'HIGH',
};

export const note_type_options = (selectedProjectId) => ({
  client: new RadioOption({
    id: 'notes-panel-radio-option-client',
    label: 'notes-panel.note-type.client',
    value: NOTE_TYPES.CLIENT.short,
    disabled: !Permissions.Note.canManageNotes(selectedProjectId),
  }),
  review: new RadioOption({
    id: 'notes-panel-radio-option-review',
    label: 'notes-panel.note-type.review',
    value: NOTE_TYPES.REVIEW.short,
    disabled: !Permissions.Note.canManageNotes(selectedProjectId),
  }),
  observer: new RadioOption({
    id: 'notes-panel-radio-option-observer',
    label: 'notes-panel.note-type.observer',
    value: NOTE_TYPES.OBSERVER.short,
    disabled: !Permissions.Note.canManageCoeNotesType(selectedProjectId),
  }),
});

export const note_type_options_array = (selectedProjectId) =>
  Object.values(note_type_options(selectedProjectId));

export const OPEN_NOTE_TOOLTIP = (noteId) =>
  new TooltipOptions({
    text: 'notes-panel.note-status.open.tooltip',
    id: `notes-panel-entry-open-note-tooltip-${noteId}`,
    useDefault: false,
  });
export const CLOSED_NOTE_TOOLTIP = (noteId) =>
  new TooltipOptions({
    text: 'notes-panel.note-status.closed.tooltip',
    id: `notes-panel-entry-closed-note-tooltip-${noteId}`,
    useDefault: false,
  });
export const PRIORITY_HIGH_NOTE_TOOLTIP = (noteId) =>
  new TooltipOptions({
    text: 'notes-panel.note-priority.normal.tooltip',
    id: `notes-panel-entry-high-priority-note-tooltip-${noteId}`,
    useDefault: false,
  });
export const PRIORITY_NORMAL_NOTE_TOOLTIP = (noteId) =>
  new TooltipOptions({
    text: 'notes-panel.note-priority.high.tooltip',
    id: `notes-panel-entry-normal-priority-note-tooltip-${noteId}`,
    useDefault: false,
  });

export const DELETE_NOTE_TOOLTIP = new TooltipOptions({
  text: 'notes-panel.delete-note.tooltip',
  id: 'notes-panel-entry-delete-note-tooltip-id',
  useDefault: false,
});

export const GHOST_NOTE_TOOLTIP = new TooltipOptions({
  text: 'notes-panel.ghost-note.tooltip',
  id: 'notes-panel-entry-ghost-note-tooltip-id',
  useDefault: false,
});

export const REPLY_NOTE_TOOLTIP = new TooltipOptions({
  text: 'notes-panel.reply-note.tooltip',
  id: 'notes-panel-entry-reply-note-tooltip-id',
  useDefault: false,
});

export const EDIT_NOTE_TOOLTIP = new TooltipOptions({
  text: 'notes-panel.edit-note.tooltip',
  id: 'notes-panel-entry-edit-note-tooltip-id',
  useDefault: false,
});

export const DELETE_NOTE_REPLY_TOOLTIP = new TooltipOptions({
  text: 'notes-panel.delete-note-reply.tooltip',
  id: 'notes-panel-entry-delete-note-reply-tooltip-id',
  useDefault: false,
  position: 'left',
});

export const getNoteStatusIcon = ({ note, iconProps, notesCursor }) => {
  if (isNullOrUndefined(note)) {
    return null;
  }
  if (note.isHighPriority()) {
    if (note.isClosed()) {
      return <ClosedPriorityIcon {...iconProps} className={notesCursor} />;
    }
    return <OpenPriorityIcon {...iconProps} className={notesCursor} />;
  } else if (note.isClosed()) {
    return <ClosedIcon {...iconProps} className={notesCursor} />;
  }
  return <OpenIcon {...iconProps} className={notesCursor} />;
};

export const FILTER_STATUS = 'status';
export const FILTER_TYPE = 'type';
export const FILTER_PRIORITY = 'priority';
export const FILTER_GHOST = 'ghost';
export const FILTER_USER = 'user';
export const FILTER_CREATED_BY = 'created-by';
export const FILTER_REPLIED_BY = 'replied-by';
export const FILTER_HEADING = 'heading';
export const FILTER_CREATED_OR_REPLIED = 'created-or-replied';
export const FILTER_MENTIONED = 'mentioned';
