import ApiModel from 'models/api-model';
import User from 'models/data/user-model';
import { JOB_TYPES } from 'constants/common/job-type-constants';

export class UserList extends ApiModel({
  data: {
    users: [], // type User
  },
}) {
  get users() {
    if (this.data) {
      return this.data.users;
    }
    return null;
  }

  /** We need to combine the response object and the project user entities because jobType comes from the tieout db and the user object comes from the opm database which is not project specific.*/
  processResponse({ response, projectUserEntities }) {
    return {
      data: {
        users: response.data.map((user) => {
          if (projectUserEntities && projectUserEntities.length) {
            const userEntityMatch = projectUserEntities.find(
              (userEntitiy) => userEntitiy.id === user.id,
            );
            user.jobType = userEntityMatch.jobType;
            return new User(user);
          }
          return new User(user);
        }),
      },
      status: response.status,
    };
  }

  getPpmdUsers() {
    if (this.data) {
      return this.data.users.filter((user) => user.jobType === JOB_TYPES.PPMD);
    }
    return [];
  }

  getEngagementOwnerUsers() {
    if (this.data) {
      return this.data.users.filter(
        (user) => user.jobType === JOB_TYPES.ENGAGEMENT_OWNER,
      );
    }
    return [];
  }

  getObserverUsers() {
    if (this.data) {
      return this.data.users.filter(
        (user) => user.jobType === JOB_TYPES.OBSERVER,
      );
    }
    return [];
  }

  getPreparerReviewerUsers() {
    if (this.data) {
      return this.data.users.filter(
        (user) => user.jobType === JOB_TYPES.PREPARER_REVIEWER,
      );
    }
    return [];
  }

  getUsers() {
    if (this.data) {
      return this.data.users;
    }
    return [];
  }
}
