import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ChevronIcon } from 'icons/down-arrow.svg';
import { FILTER_CATEGORIES } from 'constants/feature/notes/notes-panel-filter-constants';
import Radio from 'components/common/radio-component';
import {
  UNSELECTED_FILTER,
  IS_MENTIONED_FILTER,
} from 'constants/feature/notes/notes-panel-filter-constants';
import NotesPanelUserFilter from 'components/feature/notes-panel/_notes-panel-user-filter-list-component';
import NotesFilterChips from 'components/feature/notes-panel/_notes-panel-filter-chips-component';
import NotesStatementSegmentFilter from 'components/feature/notes-panel/_notes-panel-statement-segment-filter-component';
import { hasNotesFilters } from 'utils/note-filter-util';
import {
  FILTER_STATUS,
  FILTER_TYPE,
  FILTER_PRIORITY,
  FILTER_GHOST,
  FILTER_USER,
  FILTER_HEADING,
  FILTER_CREATED_BY,
  FILTER_REPLIED_BY,
  FILTER_CREATED_OR_REPLIED,
  FILTER_MENTIONED,
} from 'constants/feature/notes-panel-constants';
import ProjectUsersList from 'models/api/project-users-list-api-model';

const { STATUS, TYPE, PRIORITY, GHOST, USER, HEADINGS } = FILTER_CATEGORIES;

export const NOTES_ADVANCED_FILTER_MODAL_BLOCK = 'notes-advanced-filter-modal';
const NOTES_ADVANCED_FILTER_MODAL_ID_BLOCK = 'notes-advanced-filter-modal-id';

export const FilterCategory = ({
  filter,
  onClick,
  selectedFilter,
  children,
}) => (
  <button
    id={`${filter.id}-category`}
    onClick={onClick}
    className={classnames(
      `${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__filter-category`,
      selectedFilter === filter &&
        `${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__filter-category--selected`,
    )}
  >
    {children ? children : <FormattedMessage id={filter.heading} />}
    <ChevronIcon className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__chevron`} />
  </button>
);

export const NotesPanelAdvancedFilterModal = ({
  notesList,
  toggleModal,
  clearAllNoteFilters,
  applyNoteFilter,
  projectUsersList,
}) => {
  const [selectedPrimaryFilter, setSelectedPrimaryFitler] = useState(null);
  const [noteFiltersStateObject, setNoteFiltersStateObject] = useState(
    notesList.noteFilters,
  );
  const [userFilterCreated, setUserFilterCreated] = useState(
    notesList.usersListNotesCreatedBy,
  );
  const [userFilterReplied, setUserFilterReplied] = useState(
    notesList.usersListNotesRepliedBy,
  );
  const [selectedSegments, setSelectedSegments] = useState(
    notesList.statementSegmentFilters,
  );
  const [selectedAllUsers, setSelectedAllUsers] = useState(
    notesList.userListCreatedAndReplied,
  );
  const [userFilterMentionedUsers, setUserFilterMentionedUsers] = useState(
    notesList.usersListNotesMentions,
  );

  useEffect(() => {
    setUserFilterCreated(notesList.usersListNotesCreatedBy);
  }, [notesList.usersListNotesCreatedBy]);

  useEffect(() => {
    setUserFilterReplied(notesList.usersListNotesRepliedBy);
  }, [notesList.usersListNotesRepliedBy]);

  useEffect(() => {
    setSelectedAllUsers(notesList.userListCreatedAndReplied);
  }, [notesList.userListCreatedAndReplied]);

  useEffect(() => {
    setSelectedSegments(notesList.statementSegmentFilters);
  }, [notesList.statementSegmentFilters]);

  useEffect(() => {
    setNoteFiltersStateObject(notesList.noteFilters);
  }, [notesList.noteFilters]);

  useEffect(() => {
    setUserFilterMentionedUsers(notesList.usersListNotesMentions);
  }, [notesList.usersListNotesMentions]);

  const updateUserFilter = (checkboxValue, userId, showNamesByNoteTaker) => {
    if (showNamesByNoteTaker === true) {
      if (userId in userFilterCreated) {
        let updatedSelectedObject = { ...userFilterCreated };
        delete updatedSelectedObject[userId];
        setUserFilterCreated(updatedSelectedObject);
      } else {
        setUserFilterCreated((prevState) => ({
          ...prevState,
          [userId]: checkboxValue,
        }));
      }
    } else if (showNamesByNoteTaker === false) {
      if (userId in userFilterReplied) {
        let updatedSelectedObject = { ...userFilterReplied };
        delete updatedSelectedObject[userId];
        setUserFilterReplied(updatedSelectedObject);
      } else {
        setUserFilterReplied((prevState) => ({
          ...prevState,
          [userId]: checkboxValue,
        }));
      }
    } else if (showNamesByNoteTaker === UNSELECTED_FILTER) {
      if (userId in selectedAllUsers) {
        let updatedSelectedAllUsers = { ...selectedAllUsers };
        delete updatedSelectedAllUsers[userId];
        setSelectedAllUsers(updatedSelectedAllUsers);
      } else {
        setSelectedAllUsers((prevState) => ({
          ...prevState,
          [userId]: checkboxValue,
        }));
      }
    } else if (showNamesByNoteTaker === IS_MENTIONED_FILTER) {
      if (userId in userFilterMentionedUsers) {
        let updatedSelectedObject = { ...userFilterMentionedUsers };
        delete updatedSelectedObject[userId];
        setUserFilterMentionedUsers(updatedSelectedObject);
      } else {
        setUserFilterMentionedUsers((prevState) => ({
          ...prevState,
          [userId]: checkboxValue,
        }));
      }
    }
  };

  const updatedNoteFilters = (value, filter) => {
    setNoteFiltersStateObject((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const removeFilterChip = (filter, createdOrRepliedUsers = '') => {
    if (createdOrRepliedUsers === FILTER_CREATED_BY) {
      setUserFilterCreated({});
    } else if (createdOrRepliedUsers === FILTER_REPLIED_BY) {
      setUserFilterReplied({});
    } else if (createdOrRepliedUsers === FILTER_CREATED_OR_REPLIED) {
      setSelectedAllUsers({});
    } else if (createdOrRepliedUsers === FILTER_MENTIONED) {
      setUserFilterMentionedUsers({});
    }

    if (filter === FILTER_HEADING) {
      setSelectedSegments({});
    }

    setNoteFiltersStateObject((prevState) => ({
      ...prevState,
      [filter]: UNSELECTED_FILTER,
    }));
  };

  const cancelFilter = () => {
    setNoteFiltersStateObject(notesList.noteFilters);
  };

  const updateSelectedSegmentsObject = (section) => {
    const sectionId = section.id;
    const sectionName = section.name;
    let updatedSegments = { ...selectedSegments };
    if (sectionId in updatedSegments) {
      delete updatedSegments[sectionId];
    } else {
      updatedSegments[sectionId] = sectionName;
    }

    if (Object.keys(updatedSegments).length > 0) {
      setNoteFiltersStateObject((prevState) => ({
        ...prevState,
        heading: true,
      }));
    } else {
      setNoteFiltersStateObject((prevState) => ({
        ...prevState,
        heading: UNSELECTED_FILTER,
      }));
    }

    setSelectedSegments(updatedSegments);
  };

  const getSecondaryFilterCategories = () => {
    switch (selectedPrimaryFilter) {
      case HEADINGS: {
        return (
          <NotesStatementSegmentFilter
            className={NOTES_ADVANCED_FILTER_MODAL_BLOCK}
            selectedSegments={selectedSegments}
            updateSelectedSegmentsObject={updateSelectedSegmentsObject}
          />
        );
      }

      case STATUS: {
        return (
          <Radio
            {...STATUS}
            className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
            isValid
            selectedOption={noteFiltersStateObject.status}
            width="auto"
            onChange={(val) => updatedNoteFilters(val, FILTER_STATUS)}
          />
        );
      }

      case TYPE: {
        return (
          <Radio
            {...TYPE}
            className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
            isValid
            selectedOption={noteFiltersStateObject.type}
            width="auto"
            onChange={(val) => updatedNoteFilters(val, FILTER_TYPE)}
          />
        );
      }

      case PRIORITY: {
        return (
          <Radio
            {...PRIORITY}
            className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
            isValid
            selectedOption={noteFiltersStateObject.priority}
            width="auto"
            onChange={(val) => updatedNoteFilters(val, FILTER_PRIORITY)}
          />
        );
      }

      case GHOST: {
        return (
          <Radio
            {...GHOST}
            className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
            isValid
            selectedOption={noteFiltersStateObject.ghost}
            width="auto"
            onChange={(val) => updatedNoteFilters(val, FILTER_GHOST)}
          />
        );
      }

      case USER: {
        return (
          <Radio
            {...USER}
            className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
            isValid
            selectedOption={noteFiltersStateObject.user}
            width="auto"
            onChange={(val) => updatedNoteFilters(val, FILTER_USER)}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  const getTertiaryFilterCategories = () => {
    switch (selectedPrimaryFilter) {
      case USER: {
        if (noteFiltersStateObject.user === true) {
          return (
            <NotesPanelUserFilter
              className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
              showCreatedByUsers={true}
              updateUserFilter={updateUserFilter}
              userFilterCreated={userFilterCreated}
              notes={notesList.notes}
              userIdToUserNameMap={projectUsersList.userIdToUserNameMap}
            />
          );
        } else if (noteFiltersStateObject.user === false) {
          return (
            <NotesPanelUserFilter
              className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
              showCreatedByUsers={false}
              updateUserFilter={updateUserFilter}
              userFilterReplied={userFilterReplied}
              notes={notesList.notes}
              userIdToUserNameMap={projectUsersList.userIdToUserNameMap}
            />
          );
        } else if (noteFiltersStateObject.user === UNSELECTED_FILTER) {
          return (
            <NotesPanelUserFilter
              className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
              showCreatedByUsers={UNSELECTED_FILTER}
              updateUserFilter={updateUserFilter}
              selectedAllUsers={selectedAllUsers}
              notes={notesList.notes}
              userIdToUserNameMap={projectUsersList.userIdToUserNameMap}
            />
          );
        } else if (noteFiltersStateObject.user === IS_MENTIONED_FILTER) {
          return (
            <NotesPanelUserFilter
              className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__radio`}
              showCreatedByUsers={IS_MENTIONED_FILTER}
              updateUserFilter={updateUserFilter}
              userFilterMentionedUsers={userFilterMentionedUsers}
              notes={notesList.notes}
              userIdToUserNameMap={projectUsersList.userIdToUserNameMap}
            />
          );
        }
        break;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      id={NOTES_ADVANCED_FILTER_MODAL_ID_BLOCK}
      className={NOTES_ADVANCED_FILTER_MODAL_BLOCK}
      title="notes-panel.advanced-filter-modal.title"
      onClose={() => {
        toggleModal();
        cancelFilter();
      }}
      primaryModalButton={
        new ModalButton({
          text: 'common.apply',
          onClick: () => {
            applyNoteFilter(
              noteFiltersStateObject,
              userFilterCreated,
              userFilterReplied,
              selectedSegments,
              selectedAllUsers,
              userFilterMentionedUsers,
            );
            toggleModal();
          },
          disabled: !hasNotesFilters(
            noteFiltersStateObject,
            userFilterCreated,
            userFilterReplied,
            selectedAllUsers,
            userFilterMentionedUsers,
          ),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: () => {
            cancelFilter();
            toggleModal();
          },
        })
      }
      tertiaryModalButton={
        new ModalButton({
          text: 'common.clear-all',
          onClick: () => {
            clearAllNoteFilters();
          },
        })
      }
    >
      <p className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__default-text`}>
        <FormattedMessage id="notes-panel.advanced-filter-modal.default-text" />
      </p>
      <div className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__chips-container`}>
        <NotesFilterChips
          removeNoteFilter={removeFilterChip}
          basedId={NOTES_ADVANCED_FILTER_MODAL_ID_BLOCK}
          filterChipsFilterObject={noteFiltersStateObject}
          usersListNotesCreatedBy={userFilterCreated}
          usersListNotesRepliedBy={userFilterReplied}
          userListCreatedAndReplied={selectedAllUsers}
          selectedSegments={selectedSegments}
          userFilterMentionedUsers={userFilterMentionedUsers}
        />
      </div>
      <div
        className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__filters-container`}
      >
        <div className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__col-1`}>
          <p className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__section-title`}>
            <FormattedMessage id="notes-panel.advanced-filter-modal.section-title.statement-segment" />
          </p>
          <FilterCategory
            filter={HEADINGS}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(HEADINGS)}
          />
          <p className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__section-title`}>
            <FormattedMessage id="notes-panel.advanced-filter-modal.section-title.note-details" />
          </p>
          <FilterCategory
            filter={STATUS}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(STATUS)}
          />
          <FilterCategory
            filter={TYPE}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(TYPE)}
          />
          <FilterCategory
            filter={PRIORITY}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(PRIORITY)}
          />
          <FilterCategory
            filter={GHOST}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(GHOST)}
          />
          <FilterCategory
            filter={USER}
            selectedFilter={selectedPrimaryFilter}
            onClick={() => setSelectedPrimaryFitler(USER)}
          />
        </div>
        <div className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__col-2`}>
          {getSecondaryFilterCategories()}
        </div>
        <div className={`${NOTES_ADVANCED_FILTER_MODAL_BLOCK}__col-3`}>
          {getTertiaryFilterCategories()}
        </div>
      </div>
    </Modal>
  );
};

NotesPanelAdvancedFilterModal.propTypes = {
  /** Function fired to close modal */
  toggleModal: PropTypes.func.isRequired,
  /** filter object containing all the filter data */
  notesList: PropTypes.object.isRequired,
  /** func to clear all the filters */
  clearAllNoteFilters: PropTypes.func.isRequired,
  /** func to apply note filters */
  applyNoteFilter: PropTypes.func.isRequired,
  /** the instance of all the users in the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
};

const mapStateToProps = ({
  data: {
    notesPanel: {
      notesList: { data },
    },
    projectUsersList,
  },
}) => ({
  notesList: data,
  projectUsersList,
});

export default connect(mapStateToProps)(NotesPanelAdvancedFilterModal);
