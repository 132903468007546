import FormulaRowElement from 'models/data/formula/formula-row-element-model';
import FormulaRowNumber from 'models/data/formula/formula-row-number-model';
import { FORMULA_ROW_TYPES } from 'constants/feature/formula-constants';
import {
  COPY_SUCCESS,
  COPY_FAILED,
} from '../store/actions/copy-formula-actions';

//Calculate positions and gew new formula rows for the target element.
export const _getNewRowsForTargetElement = (
  srcElement,
  targetElement,
  copiedRows,
  tableMatrix,
) => {
  try {
    let firstNonUndefinedIndex = tableMatrix.findIndex(
      (el) => el !== undefined,
    );
    let isAtleastOneElementFound = false; // if no elements or numbers found, we should see the error.
    //loop through copied rows and change only elements with new positions and retain operators and numbers
    const newRows = copiedRows.map((row) => {
      // find the difference in row wise and column wise postions between source and target elements.
      let rowIndexDiff =
        tableMatrix[targetElement].row - tableMatrix[srcElement].row;
      let columnIndexDiff =
        tableMatrix[targetElement].column - tableMatrix[srcElement].column;
      switch (row.type) {
        case FORMULA_ROW_TYPES.ELEMENT: {
          let targetRowElement;
          let targetRowElementPos;
          try {
            let targetRowElementRowColumn = [
              tableMatrix[row.elementData.id].row + rowIndexDiff,
              tableMatrix[row.elementData.id].column + columnIndexDiff,
            ];
            targetRowElementPos =
              tableMatrix
                // Since the array can consist of lot of undefined values behind,
                // so I choose to start from first non-undefined index.
                // Pardon me for calling it 'non-undefined'.
                // I choose to call it that way since 'undefined' is a keyword here.
                .slice(firstNonUndefinedIndex)
                .findIndex(
                  (ele) =>
                    ele &&
                    ele.row === targetRowElementRowColumn[0] &&
                    ele.column === targetRowElementRowColumn[1],
                ) + firstNonUndefinedIndex;

            targetRowElement = tableMatrix[targetRowElementPos];
          } catch (e) {
            //incase if there is any exception while trying to get the targetRowElement, ignore it since we are validating targetRowElement below.
          }
          if (targetRowElement) {
            isAtleastOneElementFound = true;
            return new FormulaRowElement({
              elementData: {
                id: targetRowElementPos,
              },
            });
          } else {
            //if element not found , add 0 to the formula.
            return new FormulaRowNumber({
              value: 0,
            });
          }
        }
        case FORMULA_ROW_TYPES.NUMBER: {
          isAtleastOneElementFound = true;
          return row;
        }
        default:
          return row;
      }
    });
    return isAtleastOneElementFound ? newRows : null;
  } catch (e) {
    return null;
    //TODO: can set error in copyFormula state in the store.
  }
};

//get table from the selected element.
export const getCurrentTable = (elementId) => {
  // If ElementId is null, return null and don't initiate copy formula
  let tag =
    elementId &&
    document.getElementById(`CFTO_ELEMENT_${elementId}`) &&
    document.getElementById(`CFTO_ELEMENT_${elementId}`).parentNode;
  while (tag && tag.tagName !== 'TABLE') {
    tag = tag.parentNode;
  }
  return tag;
};

export const removeUnwantedColumn = (dataTable, maxColumnValue) => {
  let emptyColumns = [];
  let firstNonUndefinedIndex = dataTable.findIndex((ele) => ele !== undefined);
  for (let i = 0; i < maxColumnValue; i++) {
    if (dataTable.findIndex((ele) => ele && ele.column === i) === -1)
      emptyColumns.push(i);
  }
  dataTable.slice(firstNonUndefinedIndex).forEach((elementData) => {
    elementData &&
      (dataTable[elementData.elementId] = {
        elementId: elementData.elementId,
        value: elementData.value,
        row: elementData.row,
        column:
          elementData.column -
          emptyColumns.reduce(
            (prevValue, currentValue) =>
              currentValue < elementData.column ? prevValue + 1 : prevValue,
            0,
          ),
      });
  });
  return dataTable;
};

export const triggerPopUpStatusForCopyFormula = (
  copyFormulaResult,
  elementIds,
) => {
  let elementWiseStats = [];
  let successMessageDto = [];
  let failureMessageDto = [];

  // I intend to create the below structure in elementWiseStats
  /**ElementID |	Formula Pasted |	Formula Did not pasted
   * -------------------------------------------------
        1	          1               2
        2	          3	              2
        3	          2	              1
        4	          3	              3
        5	          1	              3
 */
  elementIds.forEach((id, index) => {
    elementWiseStats.push({
      elementId: id,
      numberOfSuccessfulPaste: copyFormulaResult.filter(
        (ele) => ele.elementId === id && ele.copyFormulaStatus === COPY_SUCCESS,
      ).length,
      numberOfFailedPaste: copyFormulaResult.filter(
        (ele) => ele.elementId === id && ele.copyFormulaStatus === COPY_FAILED,
      ).length,
    });
  });

  // I intend to create the below structure in successMessageDto.
  // This is derived from elementWiseStats
  /**NumberOfElements |	 NumberOfFormulaSuccesfullyPasted
   * -------------------------------------------------
        2                       1
        2                       3
        1                       2
 */
  /** It can be interpreted as :
   * There is 2 elements for which 1 formula is succesfully pasted
   * There is 1 element for which 3 formula is successfully pasted
   * There is 1 element for which 2 formula is successfully pasted
   */
  /**The similar kind of structure is there in  failureMessageDto*/
  elementWiseStats.forEach((stats, index) => {
    /**numberOfSameSuccessElements - Number of elements for which same amount of formula is successfully pasted */
    let numberOfSameSuccessElements = elementWiseStats.filter(
      (ele) => ele.numberOfSuccessfulPaste === stats.numberOfSuccessfulPaste,
    ).length;
    /**numberOfSameFailedElements - Number of elements for which same amount of formula has been failed to paste */
    let numberOfSameFailedElements = elementWiseStats.filter(
      (ele) => ele.numberOfFailedPaste === stats.numberOfFailedPaste,
    ).length;
    if (
      successMessageDto.findIndex(
        (ele) =>
          stats.numberOfSuccessfulPaste === ele.numberOfSuccessfulPaste &&
          numberOfSameSuccessElements === ele.numberOfElements,
      ) === -1 &&
      stats.numberOfSuccessfulPaste > 0
    ) {
      successMessageDto.push({
        numberOfSuccessfulPaste: stats.numberOfSuccessfulPaste,
        numberOfElements: numberOfSameSuccessElements,
      });
    }
    if (
      failureMessageDto.findIndex(
        (ele) =>
          stats.numberOfFailedPaste === ele.numberOfFailedPaste &&
          numberOfSameFailedElements === ele.numberOfElements,
      ) === -1 &&
      stats.numberOfFailedPaste > 0
    ) {
      failureMessageDto.push({
        numberOfFailedPaste: stats.numberOfFailedPaste,
        numberOfElements: numberOfSameFailedElements,
      });
    }
  });
  return { successMessageDto, failureMessageDto };
};
