import React, { useState, useEffect, memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TabModel from 'models/utils/common/tab-model';

import Tooltip from 'components/common/tool-tip-component';
import classNames from 'classnames';
import FeatureFlagComponent from './feature-flag-component';
import shortid from 'shortid';

const TABS_GROUP_BLOCK = 'tabs-group';

const TabsGroup = ({
  tabsArray,
  className,
  id,
  selectedTab,
  onSelectTab,
  fullWidth,
  rightIcon,
}) => {
  //this variable contains size of screen resolution
  const [, setScreenSize] = useState(window.innerWidth * window.innerHeight);

  /*this method is used to keep track of the screen resolution, and renders the tabs re-renders the tabs whenever the screen
  resolution changes and adds and removes the eventlistener as the mount and unmount of the component*/
  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenSize(window.innerWidth * window.innerHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setScreenSize(window.innerWidth * window.innerHeight);
      });
    };
  }, []);

  return (
    <div className={classnames(`${TABS_GROUP_BLOCK}`, className)}>
      {tabsArray.map((tabModel, index) => (
        <FeatureFlagComponent
          name={tabModel.featureName}
          key={`${TABS_GROUP_BLOCK}-key-tabs-array-${shortid.generate()}`}
        >
          <Tooltip
            id={`tab-${id}-${index}-tooltip`}
            {...tabModel.tooltip}
            active={tabModel.tooltip ? true : false}
            key={`tab-${id}-${index}-key`}
          >
            <button
              className={classnames(
                `${TABS_GROUP_BLOCK}__tab`,
                rightIcon && `${TABS_GROUP_BLOCK}__tab__right-icon`,
                selectedTab.id === tabModel.id
                  ? `${TABS_GROUP_BLOCK}__tab--active`
                  : null,
                fullWidth ? `${TABS_GROUP_BLOCK}__tab--full-width` : null,
                tabModel.className,
              )}
              onClick={() => {
                onSelectTab(tabModel);
              }}
            >
              <FormattedMessage
                id={tabModel.intlTitle}
                values={tabModel.intlValues}
              />
              {rightIcon &&
                rightIcon.get(tabModel.id) &&
                rightIcon.get(tabModel.id).iconComponent && (
                  <div
                    className={classNames(
                      `${TABS_GROUP_BLOCK}__right-icon-container`,
                      rightIcon.get(tabModel.id).className,
                    )}
                  >
                    {React.isValidElement(
                      rightIcon.get(tabModel.id).iconComponent,
                    ) && rightIcon.get(tabModel.id).iconComponent}
                  </div>
                )}
            </button>
          </Tooltip>
        </FeatureFlagComponent>
      ))}
    </div>
  );
};

TabsGroup.propTypes = {
  /** array of tab model objects containing internationalized ids and onClick handlers */
  tabsArray: PropTypes.arrayOf(PropTypes.instanceOf(TabModel)).isRequired,
  /** Custom classname applied to block level element */
  className: PropTypes.string,
  /** Unique id for this group of tabsArrayModels */
  id: PropTypes.string.isRequired,
  /** Model representing the currently selected tab */
  selectedTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Function fired on tab selection */
  onSelectTab: PropTypes.func.isRequired,
  /** Boolean indicating if tab buttons spread out to take up the full width of the container or not.*/
  fullWidth: PropTypes.bool,
  /**Any icon to be displayed at right side of tab button*/
  rightIcon: PropTypes.object,
};

export default memo(TabsGroup);
