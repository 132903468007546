import { connect } from 'react-redux';
import {
  updateScaling,
  updateOrCreateEntity,
  updateUnits,
  updatePeriod,
} from 'store/actions/element-panel-actions';
import ElementAttributes from 'components/feature/element-panel/element-attributes-component';
const mapStateToProps = ({
  data: {
    elementPanel: { elementDetails, periodList, entityList },
  },
}) => ({
  elementDetails,
  periodList,
  entityList,
});
const mapDispatchToProps = {
  updateScaling,
  updateOrCreateEntity,
  updateUnits,
  updatePeriod,
};
export default connect(mapStateToProps, mapDispatchToProps)(ElementAttributes);
