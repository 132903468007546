import React, { Component } from 'react';
import Authorized from 'containers/authorized-container';
import { authenticateAcrossAllGeos } from 'api/api-authentication';
import { getAPIClient } from 'api/api-client';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    if (!window.AuthenticationContext) {
      Object.keys(window.TIEOUT.ENV.GEOS).forEach((geoKey) =>
        getAPIClient(geoKey),
      );
    }
    authenticateAcrossAllGeos().then(() =>
      this.setState({
        authenticated: true,
      }),
    );
  }

  authenticationRender = (props) => {
    const { component: Component } = this.props;
    const { authenticated } = this.state;

    if (authenticated) {
      return <Authorized component={Component} {...props} />;
    } else {
      return null;
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={this.authenticationRender} />;
  }
}

ProtectedRoute.propTypes = {
  /**React component to be rendered in the protected route component */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
export default ProtectedRoute;
