import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import StatementContentPanel from 'components/feature/statement-content-panel/statement-content-panel-component';
import {
  initStatementContent,
  initLeftStatementContent,
  batchSelectElements,
  batchSelectElementsInSideBySideMode,
} from 'store/actions/statement-content-actions';
import withURLRouter from 'withURLRouter';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';

const StatementContentPanelComponentHOC = (props) => {
  const {
    leftSelectedStatement,
    selectedStatement,
    leftSectionIdList,
    sectionIdList,
    sectionsCache,
    leftSectionsCache,
    leftSideBySideView,
    zoom,
    leftZoom,
    rightZoom,
    URLString,
    clearSideBySideElementsMap,
    batchSelectElementsInSideBySideMode,
    batchSelectElements,
  } = props;

  return (
    <StatementContentPanel
      {...props}
      selectedStatement={
        leftSideBySideView ? leftSelectedStatement : selectedStatement
      }
      dragSelectElements={
        URLString.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
          ? batchSelectElementsInSideBySideMode
          : batchSelectElements
      }
      isSideBySideView={URLString.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)}
      zoom={
        URLString.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
          ? leftSideBySideView
            ? leftZoom
            : rightZoom
          : zoom
      }
      sectionIdList={leftSideBySideView ? leftSectionIdList : sectionIdList}
      sectionsCache={leftSideBySideView ? leftSectionsCache : sectionsCache}
      clearSideBySideElementsMap={clearSideBySideElementsMap}
    />
  );
};

const mapStateToProps = (
  {
    data: {
      statementContent: {
        sectionsCache: { cache },
        sectionIdList,
      },
      leftStatementContent,
      leftStatementContent: {
        leftSectionIdList,
        leftSectionsCache: { leftCache },
      },
      selectedProject: { id },
      selectedStatement,
      leftSelectedStatement,
    },
    ui: {
      sideBySideView: { sideBySideElementMap },
      statementPage: {
        modes: { selectMode },
        zoom,
        leftZoom,
        rightZoom,
        contentHighlightSelector,
      },
    },
  },
  ownProps,
) => ({
  sectionsCache: cache,
  leftSectionsCache: leftCache,
  leftStatementContent,
  sectionIdList,
  leftSectionIdList,
  urlParams: ownProps.urlParams,
  selectMode,
  zoom,
  leftZoom,
  rightZoom,
  contentHighlightSelector,
  selectedProject: id,
  selectedStatement,
  leftSelectedStatement,
  numberOfSourceElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
  numberOfTargetElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
});

const mapDispatchToProps = {
  push,
  initStatementContent,
  initLeftStatementContent,
  batchSelectElementsInSideBySideMode,
  batchSelectElements,
  clearSideBySideElementsMap,
};

export default withURLRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StatementContentPanelComponentHOC),
);
