import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import OmniaEngagementEntry from 'models/data/omnia-engagement-entry-model';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';

const OMNIA_LINKING_CONFIRM_MODAL_ID = 'omnia-linking-confirm-modal-id';
export const OMNIA_LINKING_CONFIRM_MODAL = 'omnia-linking-confirm-modal';

const OMNIA_ICON_SIZE = '20';

const OmniaUnLinkingConfirmModal = ({
  onClickUnlink,
  onClickCancel,
  engagementToUnlink,
}) => {
  return (
    <Modal
      id={OMNIA_LINKING_CONFIRM_MODAL_ID}
      title={'omnia.confirm-unlink-modal.title'}
      onClose={onClickCancel}
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClickCancel,
        })
      }
      primaryModalButton={
        new ModalButton({
          text: 'omnia.engagement-list.button.unlink',
          onClick: () => onClickUnlink(engagementToUnlink),
        })
      }
    >
      <Banner
        id={`${OMNIA_LINKING_CONFIRM_MODAL_ID}-omnia-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'omnia.confirm-unlink-modal.warning-message'}
        isInternationalized={true}
        isInteractive={false}
      />

      <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__guidance`}>
        <FormattedMessage id={'omnia.confirm-unlink-modal.body'} />
      </div>
      <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__row-1`}>
        <div>
          <div>
            <OmniaIcon
              className={`${OMNIA_LINKING_CONFIRM_MODAL}__omnia-icon`}
              width={OMNIA_ICON_SIZE}
              height={OMNIA_ICON_SIZE}
            />
            <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__label`}>
              <FormattedMessage
                id={'omnia.confirm-link-modal.omnia-engagement-name'}
              />
            </div>
          </div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__value`}>
            {engagementToUnlink.omEngagementName}
          </div>
        </div>
        <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__client-name`}>
          <div>
            <OmniaIcon
              className={`${OMNIA_LINKING_CONFIRM_MODAL}__omnia-icon`}
              width={OMNIA_ICON_SIZE}
              height={OMNIA_ICON_SIZE}
            />
            <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__label`}>
              <FormattedMessage
                id={'omnia.confirm-link-modal.omnia-client-name'}
              />
            </div>
          </div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__value`}>
            {engagementToUnlink.omClientName}
          </div>
        </div>
      </div>
      <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__row-2`}>
        <div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__label`}>
            <FormattedMessage id={'omnia.confirm-link-modal.project-name'} />
          </div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__value`}>
            {engagementToUnlink.toutProjectName}
          </div>
        </div>
        <div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__label`}>
            <FormattedMessage
              id={'omnia.confirm-link-modal.tieout-engagement-name'}
            />
          </div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__value`}>
            {engagementToUnlink.toutEngagementName}
          </div>
        </div>
        <div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__label`}>
            <FormattedMessage
              id={'omnia.confirm-link-modal.tieout-client-name'}
            />
          </div>
          <div className={`${OMNIA_LINKING_CONFIRM_MODAL}__value`}>
            {engagementToUnlink.toutClientName}
          </div>
        </div>
      </div>
    </Modal>
  );
};

OmniaUnLinkingConfirmModal.propTypes = {
  /** Action fired when user clicks the yes button */
  onClickUnlink: PropTypes.func.isRequired,
  /** Action fired when user clicks No || close button */
  onClickCancel: PropTypes.func.isRequired,
  /** Omnia engagement object currently selected to link */
  engagementToUnlink: PropTypes.instanceOf(OmniaEngagementEntry).isRequired,
};

export default OmniaUnLinkingConfirmModal;
