import ApiModel from 'models/api-model';
import Statement from 'models/data/statement-model';
export class StatementList extends ApiModel({
  data: {
    statements: [], // type Statement
  },
}) {
  processResponse({ response }) {
    return {
      data: {
        statements: response.data.result.elements.map(
          (statement) => new Statement(statement),
        ),
      },
    };
  }

  getStatements() {
    if (this.hasStatements()) {
      return this.data.statements;
    }
    return [];
  }

  hasStatements() {
    return this.data && this.data.statements && this.data.statements.length > 0;
  }

  getSelectedCarryForwardStatements(statementIds) {
    return this.data.statements.filter((statement) => {
      return statementIds.some((id) => parseInt(id) === statement.id);
    });
  }

  hasStatementsInApprovalInitiated(statements) {
    return statements.some((statement) =>
      new Statement(statement).hasApprovalInitiated(),
    );
  }
}
