import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Page from 'components/util/page-component';
import ConditionalRender from 'components/util/conditional-render-component';
import EditProjectInfoComponent from 'components/feature/project-edit/project-info/project-edit-project-info-component';
import EditManageUsersComponent from 'components/feature/project-edit/manage-users/project-edit-manage-users-component';
import EditManageLegalHold from 'components/feature/project-edit/legal-hold/project-edit-manage-legal-hold-component';
import ProjectEditSectionMenu from 'components/feature/project-edit/section-menu/project-edit-section-menu-component';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import ProjectForm from 'models/form/project-form-model';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { history } from 'store/store';
import ProjectEditCancelModal from 'components/feature/project-edit/project-edit-cancel-modal';
import { getSelectedProjectRequest } from 'api/selected-project-api';
import { editProjectRequest } from 'api/project-creation-edit-api';
import CountryForm from 'models/form/country-form-model';
import { getMostVisible } from 'utils/scrolling-utils';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  getCountriesListLoadedInModel,
  getCountryCodeMenuOption,
  isProjectExistInAMERegion,
} from '../utils/project-utils';
import EditManageCOETeamAccess from 'components/feature/project-edit/manage-coe/project-edit-manage-coe-access';
import Permissions from 'permissions/permissions';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

const PROJECT_EDIT_PAGE_BLOCK = 'project-edit';
const PROJECT_EDIT_PAGE_ID_BLOCK = 'project-edit-id';

class ProjectEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelModal: false,
      projectInProgress: new ProjectForm(),
      activeSection: `${PROJECT_EDIT_PAGE_ID_BLOCK}-project-info-id`,
      countryList: new CountryForm(),
      sectionsElement: [],
      search: '',
    };
    this.unBlockNavigation = null;
    this.shouldBlockNavigation = true; // instance variable for indicating if navigation away from page should be blocked
    this.projectInfoRef = React.createRef();
    this.manageUsersRef = React.createRef();
    this.legalHoldRef = React.createRef();
    this.coeUserRef = React.createRef();
  }

  async componentDidMount() {
    const { projectId } = this.props;
    this.setState({
      countryList: getCountriesListLoadedInModel(),
    });
    this._fetchSelectedProjectForEdit(projectId);
    /** Handles logic for blocking navigation with modal prompt and redux clean up */
    this.unBlockNavigation = history.block((toLocation) => {
      if (
        this.shouldBlockNavigation &&
        toLocation &&
        toLocation.pathname !== ROUTE_CONSTANTS.SESSION_TIMEOUT
      ) {
        this.setState({ showCancelModal: true });
        return false;
      }
      return true;
    });
    /** Prevent browser from navigating away from statenement content page
     * if the they type a random url and if formula is not saved */
    window.addEventListener('beforeunload', this._blockUrlNavigation);
    //scroll listener to set the current section as active on the section menu
    window.addEventListener('scroll', this._handleScroll);
  }
  componentWillUnmount() {
    this.unBlockNavigation();
    window.removeEventListener('beforeunload', this._blockUrlNavigation);
    window.removeEventListener('scroll', this._handleScroll);
  }
  checkRefs() {
    if (isProjectExistInAMERegion(this.state.projectInProgress)) {
      return (
        !isNullOrUndefined(this.legalHoldRef.current) &&
        !isNullOrUndefined(this.manageUsersRef.current) &&
        !isNullOrUndefined(this.projectInfoRef.current) &&
        !isNullOrUndefined(this.coeUserRef.current)
      );
    }
    return (
      !isNullOrUndefined(this.legalHoldRef.current) &&
      !isNullOrUndefined(this.manageUsersRef.current) &&
      !isNullOrUndefined(this.projectInfoRef.current)
    );
  }
  componentDidUpdate(prevProps, prevState) {
    const { projectInProgress, sectionsElement, countryList } = this.state;
    if (
      projectInProgress.isLoaded &&
      sectionsElement.length === 0 &&
      this.checkRefs()
    ) {
      if (
        !this.state.projectInProgress.countryOfIssuanceDropdownValue &&
        countryList &&
        projectInProgress.geoCode &&
        countryList.getCountryMenuOptionByGeoCode(projectInProgress.geoCode)
          .length
      ) {
        this.setState((prevState) => ({
          projectInProgress:
            prevState.projectInProgress.setCountryOfIssuanceDropdownValue(
              getCountryCodeMenuOption(
                countryList.getCountryMenuOptionByGeoCode(
                  prevState.projectInProgress.geoCode,
                ),
                prevState.projectInProgress.countryCode,
              ),
            ),
        }));
      }

      const sectionsInfo = [
        {
          text: 'project-edit.project-info.title',
          element: this.projectInfoRef.current,
        },
        {
          text: 'project-edit.manage-users.title',
          element: this.manageUsersRef.current,
        },
        {
          text: 'project-edit.manage-legal-hold.title',
          element: this.legalHoldRef.current,
        },
      ];

      if (isProjectExistInAMERegion(projectInProgress)) {
        sectionsInfo.splice(2, 0, {
          text: 'project-edit.manage-coe.title',
          element: this.coeUserRef.current,
        });
      }

      this.setState({ sectionsElement: sectionsInfo });
    }
  }

  _handleScroll = () => {
    let sectionContainer = [
      this.projectInfoRef.current,
      this.manageUsersRef.current,
      this.legalHoldRef.current,
    ];
    if (isProjectExistInAMERegion(this.state.projectInProgress)) {
      sectionContainer.push(this.coeUserRef.current);
    }

    if (
      !sectionContainer.includes(null) &&
      !sectionContainer.includes(undefined)
    ) {
      this._onSectionClick(getMostVisible(sectionContainer));
    }
  };

  _blockUrlNavigation = (e) => {
    if (this.shouldBlockNavigation) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  _fetchSelectedProjectForEdit = async (projectId) => {
    const { selectedProjectLoaded } = this.props;
    this.setState((state) => ({
      projectInProgress: state.projectInProgress.setLoading(),
    }));
    try {
      const response = await getSelectedProjectRequest(projectId);
      selectedProjectLoaded({ response });
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setLoaded({ response }),
      }));
    } catch (error) {
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setError(error),
      }));
    }
  };

  _updateExistingProject = async () => {
    const { projectInProgress } = this.state;
    const { finishUpdatingExistingProject } = this.props;
    this.setState((state) => ({
      projectInProgress: state.projectInProgress.setLoading(),
    }));
    try {
      const response = await editProjectRequest(projectInProgress);
      this.shouldBlockNavigation = false;
      finishUpdatingExistingProject(response);
    } catch (error) {
      this.setState((state) => ({
        projectInProgress: state.projectInProgress.setError(error),
      }));
    }
  };

  _toggleCancelModal = () => {
    this.setState((state, props) => {
      return {
        showCancelModal: !state.showCancelModal,
      };
    });
  };

  _onCancelAndLeavePage = () => {
    const { push } = this.props;
    this.shouldBlockNavigation = false;
    push(ROUTE_CONSTANTS.HOME_PAGE);
  };

  _onSave = () => {
    this._updateExistingProject();
  };

  _onSectionClick = (id) => {
    this.setState({
      activeSection: id,
    });
  };

  render() {
    const {
      showCancelModal,
      projectInProgress,
      activeSection,
      sectionsElement,
      countryList,
    } = this.state;
    const { projectId, checkIfFeatureFlagEnabled } = this.props;
    return (
      <Page>
        <ConditionalRender dependencies={[projectInProgress]}>
          <React.Fragment>
            <div className={PROJECT_EDIT_PAGE_BLOCK}>
              <div className={`${PROJECT_EDIT_PAGE_BLOCK}__header`}>
                <Button.IconButton
                  id={`${PROJECT_EDIT_PAGE_ID_BLOCK}__back-button`}
                  className={`${PROJECT_EDIT_PAGE_BLOCK}__back-button`}
                  type={BUTTON_TYPES.icon}
                  Icon={BackButton}
                  onClick={() => this._toggleCancelModal()}
                >
                  <div className={`${PROJECT_EDIT_PAGE_BLOCK}__back-title`}>
                    <FormattedMessage id={'project-edit.my-projects'} />
                  </div>
                </Button.IconButton>
                <div>
                  <h1 className={`${PROJECT_EDIT_PAGE_BLOCK}__page-title`}>
                    <FormattedMessage id={'project-edit.create-new-project'} />
                    {projectInProgress.name}
                  </h1>
                </div>
              </div>
              <div className={`${PROJECT_EDIT_PAGE_BLOCK}__button-container`}>
                <Button
                  id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-project-cancel-button`}
                  className={`${PROJECT_EDIT_PAGE_BLOCK}__cancel-button`}
                  type={BUTTON_TYPES.tertiary}
                  onClick={this._toggleCancelModal}
                >
                  <FormattedMessage id={'common.cancel'} />
                </Button>
                <Button
                  id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-project-create-button`}
                  className={`${PROJECT_EDIT_PAGE_BLOCK}__create-button`}
                  type={BUTTON_TYPES.primary}
                  onClick={this._onSave}
                  disabled={
                    !Permissions.Project.canEdit(projectId) ||
                    !projectInProgress.isProjectValid() ||
                    (projectInProgress.auditClient &&
                      this.state.search === '') ||
                    (projectInProgress.clientNameEdited &&
                      !projectInProgress.isClientNameValid())
                  }
                >
                  <FormattedMessage id={'common.save'} />
                </Button>
              </div>
              <div className={`${PROJECT_EDIT_PAGE_BLOCK}__body`}>
                <div className={`${PROJECT_EDIT_PAGE_BLOCK}__section-menu`}>
                  <div
                    className={`${PROJECT_EDIT_PAGE_BLOCK}__section-menu__menu`}
                  >
                    <ProjectEditSectionMenu
                      sectionList={sectionsElement}
                      activeSectionId={activeSection}
                      onSectionClick={this._onSectionClick}
                    />
                  </div>
                </div>
                <div className={`${PROJECT_EDIT_PAGE_BLOCK}__project-sections`}>
                  <div
                    className={`${PROJECT_EDIT_PAGE_BLOCK}__project-info`}
                    id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-project-info-id`}
                    ref={this.projectInfoRef}
                  >
                    <EditProjectInfoComponent
                      currentUser={this.props.currentUser}
                      projectInProgress={projectInProgress}
                      countryList={countryList}
                      setProjectName={(name) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setName(name),
                        }))
                      }
                      setEngagementEntity={(value) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setEngagementEntity(value),
                        }))
                      }
                      setSearch={(value) => {
                        this.setState({
                          search: value,
                        });
                      }}
                      setNonAuditEngagementEntityDataHasBeenModified={(value) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setNonAuditEngagementEntityDataHasBeenModified(
                              value,
                            ),
                        }))
                      }
                      setIsAuditClient={(checked) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.initNotAuditClient(checked),
                        }))
                      }
                      setClientName={(client) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setClientName(client),
                        }))
                      }
                      setEngagementName={(engagement) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setEngagementName(
                              engagement,
                            ),
                        }))
                      }
                      setEngagementFye={(year) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setEngagementFye(year),
                        }))
                      }
                      setNonAuditReason={(option) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setNonAuditReason(option),
                        }))
                      }
                      setProjectCountry={(option) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setCountryOfIssuanceDropdownValue(
                              option,
                            ),
                        }))
                      }
                    />
                  </div>
                  <div
                    className={`${PROJECT_EDIT_PAGE_BLOCK}__manage-users`}
                    id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-manage-users-id`}
                    ref={this.manageUsersRef}
                  >
                    <EditManageUsersComponent
                      projectInProgress={projectInProgress}
                      isTechSupportStatementAccessEnabled={checkIfFeatureFlagEnabled(
                        {
                          featureName:
                            FEATURE_NAMES.TECH_SUPPORT_STATEMENT_ACCESS_ENABLED,
                        },
                      )}
                      addUserToProject={(id) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.addUser(id),
                        }))
                      }
                      assignUserProjectRole={(role, userId) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.assignRoleToUser(
                              role,
                              userId,
                            ),
                        }))
                      }
                      removeUserFromProject={(user) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.removeUser(user),
                        }))
                      }
                      toggleHasAdminGroup={() =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.toggleHasAdminGroup(),
                        }))
                      }
                      toggleTechSupportAccess={() =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.toggleTechSupportAccess(),
                        }))
                      }
                      disableTechSupportAccess={() =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.disableTechSupportAccess(),
                        }))
                      }
                      resetEnabledTechSupportStatementListData={() =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.resetEnabledTechSupportStatementListData(),
                        }))
                      }
                      setSupportId={(val) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setSupportId(val),
                        }))
                      }
                      setEnabledTechSupportStatementList={(val) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setEnabledTechSupportStatementList(
                              val,
                            ),
                        }))
                      }
                      removeSelectedTechSupportStatement={(val) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.removeSelectedTechSupportStatement(
                              val,
                            ),
                        }))
                      }
                    />
                  </div>
                  {isProjectExistInAMERegion(projectInProgress) && (
                    <div
                      ref={this.coeUserRef}
                      className={`${PROJECT_EDIT_PAGE_BLOCK}__manage-coe-users`}
                      id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-manage-coe-users-id`}
                    >
                      <EditManageCOETeamAccess
                        projectInProgress={projectInProgress}
                        toggleHasCoeGroup={() =>
                          this.setState((state) => ({
                            projectInProgress:
                              state.projectInProgress.toggleHasCoeGroup(),
                          }))
                        }
                      ></EditManageCOETeamAccess>
                    </div>
                  )}
                  <div
                    className={`${PROJECT_EDIT_PAGE_BLOCK}__legal-hold`}
                    id={`${PROJECT_EDIT_PAGE_ID_BLOCK}-legal-hold-id`}
                    ref={this.legalHoldRef}
                  >
                    <EditManageLegalHold
                      projectId={projectId}
                      activeStatements={projectInProgress.activeStatements}
                      wrappedUpStatements={
                        projectInProgress.wrappedUpStatements
                      }
                      deletedStatements={projectInProgress.deletedStatements}
                      setActiveStatements={(activeStatements) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setActiveStatements(
                              activeStatements,
                            ),
                        }))
                      }
                      setWrappedUpStatements={(wrappedUpStatements) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setWrappedUpStatements(
                              wrappedUpStatements,
                            ),
                        }))
                      }
                      setDeletedStatements={(deletedStatements) =>
                        this.setState((state) => ({
                          projectInProgress:
                            state.projectInProgress.setDeletedStatements(
                              deletedStatements,
                            ),
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {showCancelModal ? (
              <ProjectEditCancelModal
                toggleCancelModal={this._toggleCancelModal}
                onCancelAndLeavePage={this._onCancelAndLeavePage}
              />
            ) : null}
          </React.Fragment>
        </ConditionalRender>
      </Page>
    );
  }
}

ProjectEdit.propTypes = {
  /** Func that fires action that navigates user to home page */
  push: PropTypes.func.isRequired,
  /** String id of the project that is going to be edited */
  projectId: PropTypes.string.isRequired,
  /** List of country in a geo */
  countryList: PropTypes.instanceOf(CountryForm),
};

export default ProjectEdit;
