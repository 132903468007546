import ApiModel from 'models/api-model';
import Workflow from 'models/data/workflow-model';
import { DOCUMENT_UPLOAD_STATUSES } from 'constants/feature/statement-processing-constants';
import { isNullOrUndefined } from 'utils/object-utils';

export class WorkflowsMap extends ApiModel({
  data: {
    revisionWorkflowMap: {
      // map of workflows by revisionId, shape of
      /*
      {
        [revisionId]: Workflow (workflow model)
      }
      */
    },
    statementIdsMap: {
      // map of statementIds and revision ids, shape of
      /*
      {
        [statementId]: {
          [revisionId]: true,
          .. other revision ids with same statementId
        }
      }
      */
    },
  },
  status: null,
}) {
  processResponse({ response }) {
    const { statementIdsMap, revisionWorkflowMap } = this._workflowListToMap(
      response.data,
    );
    return {
      data: {
        revisionWorkflowMap,
        statementIdsMap,
      },
      status: response.status,
    };
  }

  _workflowListToMap = (workflowList) => {
    const workflowMap = new Map();
    const statementIdsMap = {};
    workflowList.forEach((workflow) => {
      workflowMap.set(workflow.revisionId, new Workflow(workflow));
      statementIdsMap[workflow.statementId] = {
        ...statementIdsMap[workflow.statementId],
        [workflow.revisionId]: true,
      };
    });
    return {
      revisionWorkflowMap: workflowMap,
      statementIdsMap,
    };
  };

  isInitialized() {
    return (
      this.data &&
      this.data.revisionWorkflowMap &&
      this.data.revisionWorkflowMap.size > 0
    );
  }

  getInitializedSize() {
    return (
      this.data &&
      this.data.revisionWorkflowMap &&
      this.data.revisionWorkflowMap.size
    );
  }

  getWorkflowsMapData() {
    if (this.data) {
      return this.data.revisionWorkflowMap;
    }
    return new Map();
  }

  getWorkflowsListData() {
    if (this.isInitialized()) {
      return Array.from(this.data.revisionWorkflowMap.values());
    }
    return [];
  }

  getStatementMapData() {
    if (this.data) {
      return this.data.statementIdsMap;
    }
    return {};
  }

  getProcessingWorkflowsTotal() {
    let processingWorkflowsTotal = 0;
    if (this.isInitialized()) {
      this.data.revisionWorkflowMap.forEach((workflow) => {
        if (
          workflow.status !== DOCUMENT_UPLOAD_STATUSES.FAILED.status &&
          workflow.status !== DOCUMENT_UPLOAD_STATUSES.COMPLETED.status
        ) {
          processingWorkflowsTotal++;
        }
      });
    }
    return processingWorkflowsTotal;
  }

  getFailedWorkflowsTotal() {
    let failedWorkflowsTotal = 0;
    if (this.isInitialized()) {
      this.data.revisionWorkflowMap.forEach((workflow) => {
        if (workflow.status === DOCUMENT_UPLOAD_STATUSES.FAILED.status) {
          failedWorkflowsTotal++;
        }
      });
    }
    return failedWorkflowsTotal;
  }

  areWorkFlowsEqual(workflowsToCompare) {
    if (this.isInitialized() !== workflowsToCompare.isInitialized()) {
      return false;
    }
    if (this.isInitialized()) {
      if (
        this.getWorkflowsMapData().size !==
        workflowsToCompare.getWorkflowsMapData().size
      ) {
        return false;
      }
      for (let [key, value] of this.getWorkflowsMapData()) {
        const otherWorkflow = workflowsToCompare.getWorkflowsMapData().get(key);
        const _aWorkflowIsNullOrUndefined =
          isNullOrUndefined(otherWorkflow) &&
          !workflowsToCompare.getWorkflowsMapData().has(key);
        if (_aWorkflowIsNullOrUndefined || !otherWorkflow.isEqual(value)) {
          return false;
        }
      }
    }
    return true;
  }

  statementHasProcessingRevision({ statementId }) {
    return Object.prototype.hasOwnProperty.call(
      this.getStatementMapData(),
      statementId,
    );
  }

  getWorkflowsByStatementId(statementId) {
    const workflowMap = this.getWorkflowsMapData();
    if (this.statementHasProcessingRevision({ statementId })) {
      return Object.entries(this.data.statementIdsMap[statementId]).reduce(
        (workflowList, workflowEntry) => {
          const [workflowId, isWorkflowProcessing] = workflowEntry;
          if (isWorkflowProcessing) {
            return workflowList.concat(workflowMap.get(Number(workflowId)));
          }
          return workflowList;
        },
        [],
      );
    }
    return [];
  }
}
