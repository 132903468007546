import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';

import { updateAuditChecklistAnswer } from 'store/actions/audit-checklist-actions';
import Loading from '../../../common/loading-component';
import Permissions from 'permissions/permissions';
import AuditChecklistCard from 'components/feature/toolkit/toolkit-audit-panel/audit-checklist-card';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';

export const AUDIT_CHECKLIST_ELEMENT = 'audit-checklist-element';

export const AuditChecklistQuestionElement = ({
  questionId,
  question,
  auditAnswer,
  revisionId,
  statementId,
  projectId,
  updateAnswer,
  toggleButton,
}) => {
  const [loading, setLoading] = useState(false);

  const BUTTON_ANSWER_ID_YES = 1;
  const BUTTON_ANSWER_ID_NO = 2;
  const BUTTON_ANSWER_ID_MAYBE = 3;

  const permissionToModifyAnswer = Permissions.AuditChecklistPermissions.canModifyChecklist(
    projectId,
  );

  const checkIfButtonIsDisabled = permissionToModifyAnswer
    ? toggleButton
    : true;

  const handleAnswerClick = async (answerID) => {
    setLoading(true);
    await updateAnswer({
      answerID,
      questionId,
      revisionId,
      statementId,
      projectId,
    });
    setLoading((loading) => !loading);
  };

  return (
    <>
      <AuditChecklistCard>
        <div className={`${AUDIT_CHECKLIST_ELEMENT}__content-container`}>
          <div className={`${AUDIT_CHECKLIST_ELEMENT}__id`}>
            <FormattedMessage id="audit-checklist-panel-element-heading.question" />{' '}
            {questionId}
          </div>
          <p className={`${AUDIT_CHECKLIST_ELEMENT}__question-content`}>
            {question}
          </p>
          {loading ? (
            <Loading />
          ) : (
            <div className={`${AUDIT_CHECKLIST_ELEMENT}__button-container`}>
              <Tooltip
                isNotInternationalized={true}
                {...new TooltipOptions({
                  text: (
                    <FormattedMessage
                      id={'audit-checklist-panel-non-coe.information'}
                    />
                  ),
                  id: `${AUDIT_CHECKLIST_ELEMENT}-tooltip-extra-info`,
                  position: 'top',
                })}
                active={!permissionToModifyAnswer}
              >
                <span>
                  <Button
                    id={`${AUDIT_CHECKLIST_ELEMENT}__no-button`}
                    className={
                      checkIfButtonIsDisabled && auditAnswer === 'NO'
                        ? `${AUDIT_CHECKLIST_ELEMENT}__button-container--answer`
                        : null
                    }
                    type={
                      auditAnswer === 'NO'
                        ? BUTTON_TYPES.primary
                        : BUTTON_TYPES.secondary
                    }
                    onClick={() => handleAnswerClick(BUTTON_ANSWER_ID_NO)}
                    disabled={permissionToModifyAnswer ? toggleButton : true}
                  >
                    <FormattedMessage id="audit-checklist-panel-element-answer.no" />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                isNotInternationalized={true}
                {...new TooltipOptions({
                  text: (
                    <FormattedMessage
                      id={'audit-checklist-panel-non-coe.information'}
                    />
                  ),
                  id: `${AUDIT_CHECKLIST_ELEMENT}-tooltip-extra-info`,
                  position: 'top',
                })}
                active={!permissionToModifyAnswer}
              >
                <span>
                  <Button
                    id={`${AUDIT_CHECKLIST_ELEMENT}__yes-button`}
                    className={
                      checkIfButtonIsDisabled && auditAnswer === 'YES'
                        ? `${AUDIT_CHECKLIST_ELEMENT}__button-container--answer`
                        : null
                    }
                    type={
                      auditAnswer === 'YES'
                        ? BUTTON_TYPES.primary
                        : BUTTON_TYPES.secondary
                    }
                    onClick={() => handleAnswerClick(BUTTON_ANSWER_ID_YES)}
                    disabled={permissionToModifyAnswer ? toggleButton : true}
                  >
                    <FormattedMessage id="audit-checklist-panel-element-answer.yes" />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                isNotInternationalized={true}
                {...new TooltipOptions({
                  text: (
                    <FormattedMessage
                      id={'audit-checklist-panel-non-coe.information'}
                    />
                  ),
                  id: `${AUDIT_CHECKLIST_ELEMENT}-tooltip-extra-info`,
                  position: 'top',
                })}
                active={!permissionToModifyAnswer}
              >
                <span>
                  <Button
                    id={`${AUDIT_CHECKLIST_ELEMENT}__na-button`}
                    className={
                      checkIfButtonIsDisabled && auditAnswer === 'NA'
                        ? `${AUDIT_CHECKLIST_ELEMENT}__button-container--answer`
                        : null
                    }
                    type={
                      auditAnswer === 'NA'
                        ? BUTTON_TYPES.primary
                        : BUTTON_TYPES.secondary
                    }
                    onClick={() => handleAnswerClick(BUTTON_ANSWER_ID_MAYBE)}
                    disabled={permissionToModifyAnswer ? toggleButton : true}
                  >
                    <FormattedMessage id="audit-checklist-panel-element-answer.na" />
                  </Button>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </AuditChecklistCard>
    </>
  );
};

AuditChecklistQuestionElement.propTypes = {
  /** question paper to display on the toolkit */
  question: PropTypes.string.isRequired,
  /** question ID to display on the toolkit */
  questionId: PropTypes.number.isRequired,
  /** Answer to display on the toolkit */
  auditAnswer: PropTypes.string,
  /** revision ID of the statement */
  revisionId: PropTypes.number.isRequired,
  /** Statement ID of the statement */
  statementId: PropTypes.number.isRequired,
  /** Selected Project ID */
  projectId: PropTypes.string.isRequired,
  /** Function to update the Answer */
  updateAnswer: PropTypes.func.isRequired,
  /**To disable buttons after the confirmation on modal */
  toggleButton: PropTypes.bool,
};

const mapStateToProps = ({
  data: {
    revision,
    selectedProject: { id },
  },
}) => ({
  revisionId: revision.id,
  statementId: revision.statementId,
  projectId: id,
});

const mapDispatchToProps = {
  updateAnswer: updateAuditChecklistAnswer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditChecklistQuestionElement);
