import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getSectionIdListRequest = (
  revisionId,
  statementId,
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(
      `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revisions/${revisionId}/sectionidlist`,
    )
    .then((response) => {
      return response;
    });

export const getSectionSegmentsRequest = ({
  statementId,
  revisionId,
  sectionIdList,
  segmentType,
  contextKey,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .post(
      `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revisions/${revisionId}/sectionsegments`,
      {
        sectionIdList,
        // shouldBeJson: true // TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
      },
      {
        params: {
          segmentType,
        },
      },
    )
    .then((response) => {
      return response;
    });

export const getRevisionPeriodListRequest = (revisionId) => {
  return withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/periods`,
    )
    .then((response) => {
      return response;
    });
};

export const getClientEntityListRequest = (clientId) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/client/${parseInt(clientId)}/entities`)
    .then((response) => {
      return response;
    });
};

export const searchStatementContentRequest = ({ revisionId, searchTerm }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/search`,
    {
      params: {
        textToSearch: searchTerm,
      },
    },
  );
};

export const getBlacklineViewStatusRequest = ({ revisionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/blacklineView`,
    {
      params: {
        revisionId: revisionId,
      },
    },
  );
};

export const getConfigForBatchUpdateLimitRequest = () =>
  withDefaultContextAsSelectedProject()
    .get('/tieout/batch/config')
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log('failed to fetch batch update limit data', e);
      return { data: {} };
    });
