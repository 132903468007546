import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const COMFORT_ANNOTATION_PANEL_DELETE_MODAL =
  'comfort-annotation-panel-delete-modal';

export const DeleteComfortAnnotationModal = ({ onClose, onDelete }) => (
  <Modal
    id={COMFORT_ANNOTATION_PANEL_DELETE_MODAL}
    title="comfort-annotation-panel.entry.delete-confirmation.title"
    onClose={onClose}
    primaryModalButton={
      new ModalButton({ text: 'common.confirm', onClick: onDelete })
    }
    secondaryModalButton={
      new ModalButton({ text: 'common.cancel', onClick: onClose })
    }
  >
    <FormattedMessage id="comfort-annotation.entry.delete-confirmation.message" />
  </Modal>
);

DeleteComfortAnnotationModal.propTypes = {
  /** Action fired when user clicks close */
  onClose: PropTypes.func.isRequired,
  /** Action fired when user clicks delete */
  onDelete: PropTypes.func.isRequired,
};

export default DeleteComfortAnnotationModal;
