import React from 'react';
import { ReactComponent as SortArrow } from 'icons/arrow-thick-down.svg';

const SORT_ICON_HEIGHT = '13px';
const SORT_ICON_WIDTH = '10px';

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export class DataGridSort {
  constructor({ key = null, order = null }) {
    /* Currently selected column key to sort the table by */
    this.key = key;
    /* order to sort, one of ASC, or DESC from below DIRECTION */
    this.order = order || SORT_ORDER.DESC;
  }

  /**
   * Returns a new sort model object based on key passed
   * if optional order prop is not sent, defaults to descending order for new keys, or flips order for existing key
   * @param {DataGridColumn|DataGridSort} colModel currently selected column model to sort by
   */
  setSort({ key, order = null }) {
    return new DataGridSort({
      key,
      order:
        order ||
        (key === this.key && this.isDescending()
          ? SORT_ORDER.ASC
          : SORT_ORDER.DESC),
    });
  }

  isAscending() {
    return this.order === SORT_ORDER.ASC;
  }

  isDescending() {
    return this.order === SORT_ORDER.DESC;
  }

  /**
   * Determines if passed colModel is current sorted column and returns the proper icon
   * @param {DataGridColumn} colModel currently evaluated column model
   */
  getIcon({ key }) {
    if (key === this.key) {
      return (
        <SortArrow
          className="sort-arrow"
          style={this.isDescending() ? { transform: 'rotate(180deg)' } : null}
          width={SORT_ICON_WIDTH}
          height={SORT_ICON_HEIGHT}
        />
      );
    }
    return null;
  }
}
