import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';
import { getOmniaExportFileStatus } from 'api/omnia-integration-api';

const OMNIA_FILE_SHARING_MODAL_ID = 'omnia-file-sharing-confirm-modal-id';
export const OMNIA_FILE_SHARING_MODAL = 'omnia-file-sharing-confirm-modal';

const OMNIA_ICON_SIZE = '20';

class OmniaFileSharingConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileShareInProgress: false,
    };
  }

  componentDidMount() {
    this.getOmniaExportFileStatus();
  }

  getOmniaExportFileStatus = async () => {
    const { statementId } = this.props;
    const response = await getOmniaExportFileStatus({ statementId });
    try {
      if (
        response.data.result.processingStatus === 1 ||
        response.data.result.processingStatus === 2
      ) {
        this.setState((state) => ({
          fileShareInProgress: true,
        }));
      }
    } catch (error) {}
  };

  render() {
    const {
      onClickLink,
      onClickCancel,
      engagementName,
      clientName,
    } = this.props;
    const { fileShareInProgress } = this.state;
    return (
      <Modal
        id={OMNIA_FILE_SHARING_MODAL_ID}
        title={'omnia.confirm-file-share-modal.title'}
        onClose={onClickCancel}
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClickCancel,
          })
        }
        primaryModalButton={
          new ModalButton({
            text: 'omnia.confirm-file-share-modal.button.title',
            onClick: onClickLink,
            disabled: fileShareInProgress,
          })
        }
      >
        {fileShareInProgress ? (
          <>
            {' '}
            <div>
              <p className={`${OMNIA_FILE_SHARING_MODAL}__guidance-1`}>
                <FormattedMessage
                  id={
                    'statement-export.kebab.permissions-share-to-omnia-when-sharing-in-progress'
                  }
                />
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <div>
                  <p className={`${OMNIA_FILE_SHARING_MODAL}__guidance-1`}>
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.guidance-1'}
                    />
                  </p>
                  <span className={`${OMNIA_FILE_SHARING_MODAL}__guidance-2`}>
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.guidance-2'}
                    />
                  </span>
                  <p className={`${OMNIA_FILE_SHARING_MODAL}__guidance-1`}>
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.guidance-3'}
                    />
                  </p>
                  <p className={`${OMNIA_FILE_SHARING_MODAL}__guidance-3`}>
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.guidance-8'}
                    />
                  </p>
                </div>
              </div>
              <div
                className={`${OMNIA_FILE_SHARING_MODAL}__project-info-wrapper`}
              >
                <div
                  className={`${OMNIA_FILE_SHARING_MODAL}__project-info-link-details`}
                >
                  <FormattedMessage
                    id={'omnia.confirm-share-modal.guidance-9'}
                  />
                </div>
                <div className={`${OMNIA_FILE_SHARING_MODAL}__project-info`}>
                  <div className={`${OMNIA_FILE_SHARING_MODAL}__engagement`}>
                    <OmniaIcon
                      className={`${OMNIA_FILE_SHARING_MODAL}__omnia-icon`}
                      width={OMNIA_ICON_SIZE}
                      height={OMNIA_ICON_SIZE}
                    />
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.engagement-name'}
                      className={`${OMNIA_FILE_SHARING_MODAL}__project-info-title`}
                    />

                    <div>
                      <div
                        className={`${OMNIA_FILE_SHARING_MODAL}__project-info-engagement-name`}
                      >
                        {engagementName}
                      </div>
                    </div>
                  </div>
                  <div>
                    <OmniaIcon
                      className={`${OMNIA_FILE_SHARING_MODAL}__omnia-icon`}
                      width={OMNIA_ICON_SIZE}
                      height={OMNIA_ICON_SIZE}
                    />
                    <FormattedMessage
                      id={'omnia.confirm-share-modal.client-name'}
                      className={`${OMNIA_FILE_SHARING_MODAL}__project-info-title`}
                    />
                    <div
                      className={`${OMNIA_FILE_SHARING_MODAL}__project-info-client-name`}
                    >
                      {clientName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

OmniaFileSharingConfirmModal.propTypes = {
  /** Action fired when user clicks the share file button */
  onClickLink: PropTypes.func.isRequired,
  /** Action fired when user clicks No || close button */
  onClickCancel: PropTypes.func.isRequired,
  /** Omnia engagement Name   */
  engagementName: PropTypes.string.isRequired,
  /** Omnia engagement Client Name   */
  clientName: PropTypes.string.isRequired,
  /** Selected Statement id*/
  statementId: PropTypes.number.isRequired,
};

export default OmniaFileSharingConfirmModal;
