import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const INFORMATION_ICON_SIZE = '18px';

export const INTERNAL_REFERENCE_PANEL_HEADER_BLOCK =
  'internal-reference-panel-header';
export const INTERNAL_REFERENCE_PANEL_HEADER_ID_BLOCK =
  'internal-reference-panel-header-id';

const InternalReferencePanelHeader = () => {
  const INTERNAL_REFERENCE_HEADER_INFORMATION_TOOLTIP = new TooltipOptions({
    text: {
      id: 'internal-reference-panel.header.information-tooltip',
      values: {
        br: <br key={`${INTERNAL_REFERENCE_PANEL_HEADER_ID_BLOCK}-brTag`} />,
        b: (...chunks) => (
          <b key={`${INTERNAL_REFERENCE_PANEL_HEADER_ID_BLOCK}-bTag`}>
            {chunks}
          </b>
        ),
      },
    },
    id: 'internal-reference-panel-id-tooltip-information',
    position: 'left',
  });

  return (
    <div className={`${INTERNAL_REFERENCE_PANEL_HEADER_BLOCK}`}>
      <h3 className={`${INTERNAL_REFERENCE_PANEL_HEADER_BLOCK}__title`}>
        <FormattedMessage id={'internal-reference-panel.header.title'} />
      </h3>
      <div className={`${INTERNAL_REFERENCE_PANEL_HEADER_BLOCK}__information`}>
        <Tooltip
          {...INTERNAL_REFERENCE_HEADER_INFORMATION_TOOLTIP}
          className={`${INTERNAL_REFERENCE_PANEL_HEADER_BLOCK}__tooltip`}
        >
          <Information
            className={`${INTERNAL_REFERENCE_PANEL_HEADER_BLOCK}__information-icon`}
            width={INFORMATION_ICON_SIZE}
            height={INFORMATION_ICON_SIZE}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(InternalReferencePanelHeader);
