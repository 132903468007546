import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ElementSearchEntry from 'components/feature/toolkit/statement-nav/elements/statement-nav-elements-search-entry-component';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import Loading from 'components/common/loading-component';
import VirtualizedList from 'components/common/virtualized-list-component';
import ElementsSearchResults from 'models/api/statements-element-search-results-api-model';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import Permissions from 'permissions/permissions';
import { connect } from 'react-redux';
import {
  setElementSelectModeBatchIfPossible,
  setElementSelectModeRangeIfPossible,
  clearModeIfPossible,
} from 'store/actions/modes-actions';
import { isRangeSelectMode } from 'utils/modes-utils';
import classnames from 'classnames';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import SelectedStatement from 'models/api/selected-statement-model';
import ContentSearchResults from 'models/api/statement-content-search-results-api-model';
import {
  deselectSelectedElement,
  showElementSearchRefreshButtonAction,
  setBatchSelectedItemsIdsAction,
} from 'store/actions/statement-navigator/elements-search-panel-actions';
import { ReactComponent as Refresh } from 'icons/refresh-element-filter.svg';
import Tooltip from 'components/common/tool-tip-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import shortid from 'shortid';
import Checkbox from 'components/common/checkbox-component';
import {
  addElementsToBatchFromElementsFilter,
  removeFilteredElementsFromBatch,
  setBatchSelectedElementsError,
} from 'store/actions/batch-panel-actions';
import { searchStatementElementsRequest } from 'api/element-api';
import ElementFilters from 'models/data/element-filters-model';
import ElementDetails from 'models/api/element-details-api-model';
import { deselectElementsFromArrayContentPanel } from 'store/actions/statement-content-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { batchMaxAmountNotification } from 'constants/feature/batch-panel-constants';
import { usePrevious } from 'utils/hooks-util';
import { getMaxElementForBatchUpdateLimit } from 'utils/statement-content-page-utils';

export const ELEMENTS_SEARCH_BLOCK = 'statement-elements-search';
const ELEMENTS_SEARCH_BLOCK_ID = 'statement-elements-search-id';
const REVISION_ID_TIMESTAMP_MAP = 'REVISION_ID_TIMESTAMP_MAP';

export const ELEMENTS_SEARCH_RESULT_HEIGHT = 104;

const StatementElementsSearchResults = ({
  searchTerm,
  searchResultsElement,
  selectElementAction,
  contentSectionMap,
  onFilterClick,
  searchElementsNextPage,
  setElementSelectModeBatchIfPossible,
  setElementSelectModeRangeIfPossible,
  clearRangeModeIfPossible,
  selectMode,
  setActiveEntry,
  selectedStatement,
  searchResultsContent,
  deselectSelectedElement,
  searchElements,
  filters,
  showElementSearchRefreshButtonAction,
  revisionId,
  addElementsToBatchFromElementsFilter,
  removeFilteredElementsFromBatch,
  deselectElementsFromArrayContentPanel,
  batchPanelIsOpen,
  setBatchSelectedItemsIdsAction,
  elementsStillSelected,
  isFilterApplied,
  setIsFilterApplied,
  setBatchSelectedElementsError,
  batchSelectedAmount,
  selectedProjectId,
}) => {
  const [showRefreshButtonTooltip, setShowRefreshButtonTooltip] =
    useState(true);
  const isStatementReadOnly = selectedStatement.isReadOnly();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedElements, setSelectedElements] = useState(0);
  const batchElements = useRef(null);
  const prevBatchIsOpen = usePrevious(batchPanelIsOpen);
  const _permissionToEdit =
    Permissions.Element.canEditElement(selectedProjectId);
  const setDataForBatchUpdate = () => {
    if (
      searchResultsElement.totalOverallResults + batchSelectedAmount >
      getMaxElementForBatchUpdateLimit()
    ) {
      batchMaxAmountNotification();
    }
    const elementsArray = batchElements.current.data.elements.map((element) => {
      return new ElementDetails().setLoaded({
        response: { data: { result: element } },
      });
    });

    addElementsToBatchFromElementsFilter(batchElements ? elementsArray : []);
    setSelectedElements(elementsStillSelected);
    setBatchSelectedItemsIdsAction(elementsArray);
  };
  const removeDataForBatchUpdate = () => {
    removeFilteredElementsFromBatch(
      batchElements.current ? batchElements.current.data.elements : [],
    );
    deselectElementsFromArrayContentPanel(
      batchElements.current ? batchElements.current.data.elements : [],
    );
    setBatchSelectedItemsIdsAction([]);
    batchElements.current = null;
  };

  //this useEffect handles the adding and removing elements to batch update panel depending on selectAll value
  useEffect(() => {
    if (selectAll) {
      let filtersCopy = { ...filters };
      const maxAllowedElements =
        getMaxElementForBatchUpdateLimit() - batchSelectedAmount;
      const totalElementsSearched = searchResultsElement.totalOverallResults;
      filtersCopy._size =
        maxAllowedElements > totalElementsSearched
          ? totalElementsSearched
          : maxAllowedElements;
      filtersCopy._first = 0;
      filtersCopy = new ElementFilters(filtersCopy);
      const getData = async () => {
        try {
          const { searchTerm } = filters.getPageParams();
          batchElements.current = await searchStatementElementsRequest({
            revisionId,
            filters: filtersCopy,
            searchTerm,
          });
          setDataForBatchUpdate();
        } catch (error) {
          setBatchSelectedElementsError(error);
        }
      };
      if (!batchElements.current) {
        getData();
      } else {
        setDataForBatchUpdate();
      }
    } else {
      if (batchElements.current) {
        removeDataForBatchUpdate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  // if select all checkbox is unchecked and there are changes in the amount of elements selected for batch
  // we need to clean the batchElements ref because the info stored needs to be updated because we can now get more or less elements,
  useEffect(() => {
    if (!selectAll && batchElements.current) {
      batchElements.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchSelectedAmount]);

  // if batch panel is closed this is going to clear all the selected elements for batch update for the filtered list
  useEffect(() => {
    if (!batchPanelIsOpen) {
      if (selectAll) {
        setSelectAll(false);
      } else if (!selectAll && prevBatchIsOpen) {
        removeDataForBatchUpdate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchPanelIsOpen]);

  // if filters change, we are going to clear all the selected elements for batch update for the filtered list and
  // unselect the checkbox
  useEffect(() => {
    if (isFilterApplied) {
      if (selectAll) {
        setSelectAll(false);
      }
      removeDataForBatchUpdate();
      batchElements.current = null;
      setIsFilterApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterApplied]);

  // if the batch selected elements changes we are going to update the amount that are selected from filtered list.
  useEffect(() => {
    setSelectedElements(elementsStillSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsStillSelected]);

  useEffect(() => {
    if (localStorage.getItem(REVISION_ID_TIMESTAMP_MAP) !== null) {
      const revisionIdToTimestampMap = JSON.parse(
        localStorage.getItem(REVISION_ID_TIMESTAMP_MAP),
      );
      if (revisionId in revisionIdToTimestampMap) {
        const currentTime = new Date();
        const startingTime = new Date(
          revisionIdToTimestampMap[revisionId].startingTime,
        );
        const endingTime = new Date(
          revisionIdToTimestampMap[revisionId].endingTime,
        );
        if (currentTime >= startingTime && currentTime <= endingTime) {
          setShowRefreshButtonTooltip(false);
        }
      }
    }
  }, [revisionId]);

  useEffect(() => {
    return () => {
      deselectSelectedElement();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isStatementReadOnly) {
      window.addEventListener('keydown', _ctrlPressListener);
    }
    window.addEventListener('keyup', _ctrlReleaseListener);
    window.addEventListener('keydown', _shiftPressListener);
    window.addEventListener('keyup', _shiftReleaseListener);

    // clean up
    return () => {
      if (!isStatementReadOnly) {
        window.removeEventListener('keydown', _ctrlPressListener);
      }
      window.removeEventListener('keyup', _ctrlReleaseListener);
      window.removeEventListener('keydown', _shiftPressListener);
      window.removeEventListener('keyup', _shiftReleaseListener);
    };
  });

  const REFRESH_ELEMENT_TOOLTIP = new TooltipOptions({
    text: {
      id: 'statement-nav-panel.tab.elements.refresh-element-list-button',
      values: {
        linebreak: (
          <br key={`${ELEMENTS_SEARCH_BLOCK}-brTag-${shortid.generate()}}`} />
        ),
        button: (...chunks) => (
          <button
            className={`${ELEMENTS_SEARCH_BLOCK}__dismiss-button`}
            id={`${ELEMENTS_SEARCH_BLOCK_ID}-dismiss-button`}
            onClick={(e) => {
              e.stopPropagation();
              _setTimeStampInLocalStorage(revisionId);
              setShowRefreshButtonTooltip(false);
            }}
            key={`${ELEMENTS_SEARCH_BLOCK}-buttonTag-${shortid.generate()}}`}
          >
            {chunks}
          </button>
        ),
      },
    },
    id: `${ELEMENTS_SEARCH_BLOCK}-tooltip-information`,
    position: 'left',
  });

  const refreshButton = () => {
    return (
      <button
        onClick={() => {
          searchElements({
            filters: filters,
            scrollIntoView: false,
          });
          showElementSearchRefreshButtonAction(false);
        }}
        className={`${ELEMENTS_SEARCH_BLOCK}__refresh-button`}
      >
        <Refresh />
      </button>
    );
  };

  const refreshButtonWrapper = () => {
    if (searchResultsElement.showElementSearchRefreshButton) {
      if (showRefreshButtonTooltip) {
        return (
          <Tooltip
            {...REFRESH_ELEMENT_TOOLTIP}
            className={`${ELEMENTS_SEARCH_BLOCK}__refresh-button-tooltip`}
            delay={1000}
            clickable={true}
          >
            {refreshButton()}
          </Tooltip>
        );
      }
      return refreshButton();
    }
  };

  const _ctrlPressListener = (event) => {
    if (event.key === 'Control') {
      setElementSelectModeBatchIfPossible();
    }
  };

  const _shiftPressListener = (event) => {
    if (event.key === 'Shift') {
      setElementSelectModeRangeIfPossible();
    }
  };

  const _ctrlReleaseListener = (event) => {
    if (event.key === 'Control') {
      clearRangeModeIfPossible();
    }
  };

  const _shiftReleaseListener = (event) => {
    if (event.key === 'Shift') {
      clearRangeModeIfPossible();
    }
  };

  const _setTimeStampInLocalStorage = (revisionId) => {
    const startingTime = new Date();
    const endingTime = new Date(
      new Date(startingTime).getTime() + 60 * 60 * 24 * 1000,
    );
    if (localStorage.getItem(REVISION_ID_TIMESTAMP_MAP) === null) {
      const timestampMap = {
        [revisionId]: {
          startingTime: startingTime,
          endingTime: endingTime,
        },
      };
      localStorage.setItem(
        REVISION_ID_TIMESTAMP_MAP,
        JSON.stringify(timestampMap),
      );
    } else {
      let timestampMap = localStorage.getItem(REVISION_ID_TIMESTAMP_MAP);
      timestampMap = JSON.parse(timestampMap);
      if (!(revisionId in timestampMap)) {
        timestampMap[revisionId] = {
          startingTime: startingTime,
          endingTime: endingTime,
        };
      } else {
        const endingTime = new Date(timestampMap[revisionId].endingTime);
        const currentTime = new Date();
        if (currentTime > endingTime) {
          const newEndingTime = new Date(
            new Date(currentTime).getTime() + 60 * 60 * 24 * 1000,
          );
          timestampMap[revisionId] = {
            startingTime: currentTime,
            endingTime: newEndingTime,
          };
        }
      }
      localStorage.setItem(
        REVISION_ID_TIMESTAMP_MAP,
        JSON.stringify(timestampMap),
      );
    }
  };

  const _isRangeSelectMode = isRangeSelectMode(selectMode);

  const _isModeForAddCursor = (mode) => {
    const { RANGE, BATCH } = ELEMENT_SELECT_MODES;
    return (
      !isNullOrUndefined(mode) && (mode.id === RANGE.id || mode.id === BATCH.id)
    );
  };

  if (!searchResultsElement.isInitiated()) {
    return (
      <div className={`${ELEMENTS_SEARCH_BLOCK}__default-message`}>
        <h4 className={`${ELEMENTS_SEARCH_BLOCK}__default-message-header`}>
          <FormattedMessage id="statement-nav-panel.tab.elements.default-message-header" />
        </h4>
        <div className={`${ELEMENTS_SEARCH_BLOCK}__default-message-info`}>
          <FormattedMessage
            id="statement-nav-panel.tab.elements.default-message-search"
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />
        </div>
        <div className={`${ELEMENTS_SEARCH_BLOCK}__default-message-info`}>
          <FormattedMessage
            id="statement-nav-panel.tab.elements.default-message-filter"
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              button: (...chunks) => (
                <Button
                  type={BUTTON_TYPES.tertiary}
                  className={`${ELEMENTS_SEARCH_BLOCK}__default-message-filter-button`}
                  id={`${ELEMENTS_SEARCH_BLOCK_ID}-advanced-filters-default-message`}
                  onClick={onFilterClick}
                >
                  {chunks}
                </Button>
              ),
            }}
          />
        </div>
        <div className={`${ELEMENTS_SEARCH_BLOCK}__default-message-info`}>
          <FormattedMessage
            id="statement-nav-panel.tab.elements.one-click-filters"
            values={{
              b: (...chunks) => (
                <strong
                  key={`${ELEMENTS_SEARCH_BLOCK}__default-message-info_key-strong-${shortid.generate()}`}
                >
                  {chunks}
                </strong>
              ),
              em: (...chunks) => (
                <em
                  key={`${ELEMENTS_SEARCH_BLOCK}__default-message-info_key-em-${shortid.generate()}`}
                >
                  {chunks}
                </em>
              ),
              p: (...chunks) => (
                <p
                  key={`${ELEMENTS_SEARCH_BLOCK}__default-message-info_key-p-${shortid.generate()}`}
                >
                  {chunks}
                </p>
              ),
            }}
          />
        </div>
      </div>
    );
  }

  const _noResults =
    searchResultsElement.isLoaded &&
    searchResultsElement.totalLoadedResults === 0;

  if (_noResults) {
    return (
      <div className={`${ELEMENTS_SEARCH_BLOCK}__no-results`}>
        <FormattedMessage id="statement-nav-panel.tab.elements.no-results" />
        {refreshButtonWrapper()}
      </div>
    );
  }
  const disableScrollCallback =
    searchResultsElement.isLoading || searchResultsElement.hasAllResults;
  const renderFooter = searchResultsElement.hasAllResults
    ? () => null
    : () => <Loading />;
  return (
    <div
      className={classnames(
        ELEMENTS_SEARCH_BLOCK,
        _isModeForAddCursor(selectMode)
          ? `${ELEMENTS_SEARCH_BLOCK}--add-element-cursor`
          : null,
        _isRangeSelectMode ? `${ELEMENTS_SEARCH_BLOCK}--range-select` : null,
      )}
    >
      <div
        className={classnames(`${ELEMENTS_SEARCH_BLOCK}__select-all-section`)}
      >
        <Checkbox
          className={classnames(
            `${ELEMENTS_SEARCH_BLOCK}__select-all-checkbox`,
          )}
          id={`${ELEMENTS_SEARCH_BLOCK_ID}-select-all-checkbox`}
          label={'statement-nav-panel.tab.elements.select-all-checkbox'}
          checked={selectedElements ? selectAll : false}
          disabled={!_permissionToEdit || isStatementReadOnly}
          onChange={() => setSelectAll((prevState) => !prevState)}
          masterChecked={
            batchElements.current &&
            (batchElements.current.data.elements.length <
              searchResultsElement.totalOverallResults ||
              batchElements.current.data.elements.length > selectedElements)
          }
        />
        <span
          className={classnames(`${ELEMENTS_SEARCH_BLOCK}__selected-count`)}
        >
          <FormattedMessage
            id="statement-nav-panel.tab.elements.select-elements-count"
            values={{ count: selectedElements }}
          />
        </span>
      </div>
      <VirtualizedList
        disableScrollCallback={disableScrollCallback}
        id={`${ELEMENTS_SEARCH_BLOCK}-list`}
        itemHeight={ELEMENTS_SEARCH_RESULT_HEIGHT}
        itemList={searchResultsElement.elements}
        onScrollEnd={searchElementsNextPage}
        renderFooter={renderFooter}
        renderItem={(element, index) => (
          <ElementSearchEntry
            element={element}
            selectElementAction={selectElementAction}
            contentSectionMap={contentSectionMap}
            searchTerm={searchTerm}
            activeEntry={searchResultsElement.selectedIndex === index}
            setActiveEntry={() => setActiveEntry(index)}
            searchResultsContent={searchResultsContent}
          />
        )}
      />
      {refreshButtonWrapper()}
    </div>
  );
};

StatementElementsSearchResults.propTypes = {
  /** Current search string */
  searchTerm: PropTypes.string.isRequired,
  /** Search results model of the elements tab */
  searchResultsElement: PropTypes.instanceOf(ElementsSearchResults).isRequired,
  /** Action fired for highlighting an element from the content panel on click*/
  selectElementAction: PropTypes.func.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** action fired when user clicks filter button in default message when no search or filters have been applied */
  onFilterClick: PropTypes.func.isRequired,
  /** Action fired to get the next page of search results */
  searchElementsNextPage: PropTypes.func.isRequired,
  /** current mode for selecting elements in the content panel */
  selectMode: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  /** Set an element search result as actively selected */
  setActiveEntry: PropTypes.func.isRequired,
  /** function fired to try setting element batch select mode to batch */
  setElementSelectModeBatchIfPossible: PropTypes.func.isRequired,
  /** function fired to try setting element range select mode to batch */
  setElementSelectModeRangeIfPossible: PropTypes.func.isRequired,
  /** function fired to return element selct mode to default setting */
  clearRangeModeIfPossible: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Search results model of the content tab */
  searchResultsContent: PropTypes.instanceOf(ContentSearchResults).isRequired,
  /* function to deselect element if tab changes */
  deselectSelectedElement: PropTypes.func.isRequired,
  /* function to execute the element filter search*/
  searchElements: PropTypes.func.isRequired,
  /** object containing the applied filters */
  filters: PropTypes.object.isRequired,
  /** function that will be called to remove the refresh button from the element filter list component */
  showElementSearchRefreshButtonAction: PropTypes.func.isRequired,
  /** the id of the selected revision */
  revisionId: PropTypes.number.isRequired,
  /* action to add elements to the batch update panel */
  addElementsToBatchFromElementsFilter: PropTypes.func.isRequired,
  /* action to remove elements from the batch update panel */
  removeFilteredElementsFromBatch: PropTypes.func.isRequired,
  /* action to deselect elements from the selected elements object */
  deselectElementsFromArrayContentPanel: PropTypes.func.isRequired,
  /* indicates if right panel is open as batch */
  batchPanelIsOpen: PropTypes.bool.isRequired,
  /* action to set the ids of the elements filtered and selected for batch update */
  setBatchSelectedItemsIdsAction: PropTypes.func.isRequired,
  /* count of numbers selected */
  elementsStillSelected: PropTypes.number.isRequired,
  /* value to know when a filter is applied */
  isFilterApplied: PropTypes.bool.isRequired,
  /* function to set value after filter is applied and batch selection is reset */
  setIsFilterApplied: PropTypes.func.isRequired,
  /* action to be fired when we encounter an error with searchStatementElementsRequest  */
  setBatchSelectedElementsError: PropTypes.func.isRequired,
  /* it indicates the amount of elements currently selected for batch update */
  batchSelectedAmount: PropTypes.number.isRequired,
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  data: {
    selectedStatement,
    revision,
    batchPanel: {
      batchElementList: { selectedElementIds },
    },
    selectedProject: { id },
  },
  ui: {
    statementPage: {
      modes: { selectMode },
      statementNavigatorPanel: {
        elementSearchResults: { batchSelectedItemsIds },
      },
      panels: { right },
    },
  },
}) => ({
  selectMode,
  selectedStatement,
  revisionId: revision.id,
  batchPanelIsOpen: right === RIGHT_PANELS.BATCH,
  elementsStillSelected: Object.keys(batchSelectedItemsIds).length,
  batchSelectedAmount: selectedElementIds.length,
  selectedProjectId: id,
});

const mapDispatchToProps = {
  setElementSelectModeBatchIfPossible,
  setElementSelectModeRangeIfPossible,
  clearRangeModeIfPossible: clearModeIfPossible,
  deselectSelectedElement,
  showElementSearchRefreshButtonAction,
  addElementsToBatchFromElementsFilter,
  removeFilteredElementsFromBatch,
  deselectElementsFromArrayContentPanel,
  setBatchSelectedItemsIdsAction,
  setBatchSelectedElementsError,
};

export { StatementElementsSearchResults };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementElementsSearchResults);
