import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';

const TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK =
  'tech-support-access-disclaimer-modal';
const TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK =
  'tech-support-access-disclaimer-modal-id';

export const ViewTechSupportAccessDisclaimerModal = ({ onClose }) => (
  <Modal
    id={`${TECH_SUPPORT_ACCESS_DISCLAIMER_ID_BLOCK}`}
    title={
      'project-edit.manage-users.tech-support-access.disclaimer-modal-title'
    }
    primaryModalButton={
      new ModalButton({
        text: 'common.close',
        onClick: onClose,
      })
    }
    onClose={onClose}
  >
    <div className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-1`}>
      <FormattedMessage
        id={
          'project-edit.manage-users.acknowledge-tech-support-access.disclaimer-modal-message-first-paragraph'
        }
      />
    </div>
    <div className={`${TECH_SUPPORT_ACCESS_DISCLAIMER_BLOCK}__para-2`}>
      <FormattedMessage
        id={
          'project-edit.manage-users.acknowledge-tech-support-access.view-disclaimer-modal-message-first-paragraph1'
        }
      />
    </div>
  </Modal>
);

ViewTechSupportAccessDisclaimerModal.propTypes = {
  /** function fired to close the modal */
  onClose: PropTypes.func.isRequired,
};
