import { push } from 'connected-react-router';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { updateSelectedProjectFromID } from 'store/actions/selected-project-actions';
import {
  setProjectIdWorkflowExpanded,
  requestGetStatementWorkflows,
} from 'store/actions/statement-workflows-actions';

export const finishStatementCreation = ({ project }) => async (dispatch) => {
  //Set the project we just created a statement for as the currently selected project
  dispatch(
    updateSelectedProjectFromID({
      projectId: project.id,
    }),
  );
  //Navigate to the home page
  dispatch(push(ROUTE_CONSTANTS.HOME_PAGE));
  // ensure workflows popover is expanded when navigate back to home page
  dispatch(setProjectIdWorkflowExpanded(project.id));
  // request statement workflows immediately so pop up is displayed
  dispatch(requestGetStatementWorkflows());
};
