import { getStore } from 'store/store';
import { JOB_TYPES } from '../../constants/common/job-type-constants';
import { isNullOrUndefined } from 'util';
const RoleUtil = {
  /**A helper function to get the current user from the store. */
  _getUser: () => {
    const stateData = getStore().getState().data;
    let user = null;
    if (stateData.currentUser.isInitialized()) {
      user = stateData.currentUser.data;
    }
    return user;
  },

  /** A helper function to get a project from the project list in the store based on its ID.
   * If not provided, it will attempt to fetch the currently selected project in the store as a fallback.
   * If neither of these lists are populated, `null` is returned.
   *
   * @param {string} projectID - The optional projectID used to fetch a project from the project list in the store.
   *
   * @return {Project} - The appropriate project based on the optional projectID, or `null` if none is found.
   *
   * */
  _getProject: (projectID) => {
    const stateData = getStore().getState().data;
    let desiredProject = null;
    // Use the selected project by default from the store if it exists.
    if (
      (!projectID || !stateData.projectList.hasProjects()) &&
      stateData.selectedProject &&
      stateData.selectedProject.project.isInitialized()
    ) {
      desiredProject = stateData.selectedProject.project;
    } else if (projectID && stateData.projectList.hasProjects()) {
      // Otherwise use the provided projectID to find it.
      const projectList = stateData.projectList.getProjects();
      desiredProject = projectList.find((project) => {
        return project.id === projectID;
      });
    }
    return desiredProject;
  },

  /** This function is used to determine if a user has admin access on a project in the store based on an optional projectID.
   * If no projectID is provided, the admin access is checked against the currently selected project in the store,
   * if one is provided, it attempts to determine admin access from the project list in the store with that id.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveAdminSupportAccess - true iff the user is an admin and the retrieved project has admin access.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHaveAdminSupportAccess: (projectID) => {
    const user = RoleUtil._getUser();
    if (user && user.admin) {
      const desiredProject = RoleUtil._getProject(projectID);
      if (desiredProject) {
        return desiredProject.hasAdminGroup;
      }
    }
    return false;
  },

  /** This function is used to determine if a user has the role of COE for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveCoeRoleForProject - true iff the user is an COE on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHaveCoeRoleForProject: (projectID) => {
    const user = RoleUtil._getUser();
    if (user && user.coe) {
      const desiredProject = RoleUtil._getProject(projectID);
      if (desiredProject) {
        return desiredProject.hasCoeGroup;
      }
    }
    return false;
  },

  /** This private function is used to determine if a user has a specific role on a project in the store based on an optional projectID.
   * If no projectID is provided, the role is checked against the currently selected project in the store,
   * if one is provided, it attempts to compare it to the project in the store with that id, provided it exists.
   *
   * @param {String} role - The JOB_TYPE we wish to check on the appropriate project (e.g. Engagement Owner, etc...)
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveProjectRole - true iff the user has the specified `role` in the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  _doesUserHaveProjectRole: ({ role, projectID }) => {
    const user = RoleUtil._getUser();
    if (!isNullOrUndefined(user)) {
      const projectToGeoMap = user.projectToGeoMap;
      if (!isNullOrUndefined(projectToGeoMap)) {
        let userRole;
        //If a projectID was supplied, try to find it in the user roles map.
        if (!isNullOrUndefined(projectID)) {
          userRole =
            projectToGeoMap.get(`${projectID}`) &&
            projectToGeoMap.get(`${projectID}`).role;
        } else {
          //Otherwise fetch the currently selected project if there is no projectID provided.
          const selectedProject = RoleUtil._getProject();
          if (!isNullOrUndefined(selectedProject)) {
            userRole =
              projectToGeoMap.get(`${selectedProject.id}`) &&
              projectToGeoMap.get(`${selectedProject.id}`).role;
          }
        }
        return userRole === role;
      }
    }
    return false;
  },

  /** This function is used to determine if a user has the role of PPMD for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHavePPMDRoleForProject - true iff the user is a PPMD on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHavePPMDRoleForProject: (projectID) => {
    return RoleUtil._doesUserHaveProjectRole({
      role: JOB_TYPES.PPMD,
      projectID,
    });
  },

  /** This function is used to determine if a user has the role of Engagement Owner for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveEngagementOwnerRoleForProject - true iff the user is an Engagement Owner on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHaveEngagementOwnerRoleForProject: (projectID) => {
    return RoleUtil._doesUserHaveProjectRole({
      role: JOB_TYPES.ENGAGEMENT_OWNER,
      projectID,
    });
  },

  /** This function is used to determine if a user has the role of Observer for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveObserverRoleForProject - true iff the user is an Observer on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHaveObserverRoleForProject: (projectID) => {
    return RoleUtil._doesUserHaveProjectRole({
      role: JOB_TYPES.OBSERVER,
      projectID,
    });
  },

  /** This function is used to determine if a user has the role of Preparer Reviewer for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHavePreparerReviewerRoleForProject - true iff the user is a Preparer Reviewer on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHavePreparerReviewerRoleForProject: (projectID) => {
    return RoleUtil._doesUserHaveProjectRole({
      role: JOB_TYPES.PREPARER_REVIEWER,
      projectID,
    });
  },

  /** This function is used to determine if a user has the role of Admin for a given project.
   *
   * @param {String} projectID - The optional ID used to fetch a project from the project list in the store.
   *
   * @return {boolean} doesUserHaveAdminRoleForProject - true iff the user is an Admin on the project.
   * False otherwise, including if the user or project cannot be found.
   *
   * */
  doesUserHaveAdminRoleForProject: (projectID) => {
    return RoleUtil._doesUserHaveProjectRole({
      role: JOB_TYPES.ADMIN,
      projectID,
    });
  },
};
export default RoleUtil;
