import { connect } from 'react-redux';

import { fetchCurrentUser } from 'store/actions/current-user-actions';
import { fetchUserOPMProfile } from 'store/actions/user-profile-actions';
import { authenticateUser } from 'store/actions/user-auth-actions';
import Authorized from 'components/app/authorized-component';

const mapStateToProps = ({ data: { currentUser, authUser } }) => ({
  currentUser,
  authUser,
});

const mapDispatchToProps = {
  fetchUserOPMProfile,
  authenticateUser,
  fetchCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorized);
