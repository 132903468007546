import ApiModel from '../api-model';
import AdminModuleMaintenance from './admin-module-maintenance-page-model';
import moment from 'moment';
import { isNullOrUndefined } from 'utils/object-utils';

export default class AdminModuleMaintenanceList extends ApiModel({
  data: { maintenanceBannerDataList: [] },
}) {
  processResponse(response) {
    let maintenanceBannerList = [];
    if (response.length === 0) {
      let emptyResponse = {
        apiMaintenanceId: null,
        apiStartTime: null,
        apiEndTime: null,
        apiStartDate: null,
        apEndDate: null,
        apiBannerText: '',
        isActive: false, // by default it is false
      };
      maintenanceBannerList.push(
        new AdminModuleMaintenance({
          ...emptyResponse,
          isLoading: false,
          isLoaded: true,
        }),
      );
    } else {
      for (let index = 0; index < response.length; index++) {
        let localStartDateTime = moment(
          moment.utc(response[index].startDate).toDate(),
        )
          .local()
          .format('YYYY-MM-DD HH:mm:ss');
        let localEndDateTime = moment(
          moment.utc(response[index].endDate).toDate(),
        )
          .local()
          .format('YYYY-MM-DD HH:mm:ss');
        response[index] = {
          apiMaintenanceId: response[index].id,
          apiStartTime: moment(localStartDateTime).format('HH:mm'),
          apiEndTime: moment(localEndDateTime).format('HH:mm'),
          apiStartDate: moment(localStartDateTime),
          apEndDate: moment(localEndDateTime),
          apiBannerText: response[index].text,
          isActive: isNullOrUndefined(response[index].status)
            ? false
            : response[index].status,
        };
        maintenanceBannerList.push(
          new AdminModuleMaintenance({
            ...response[index],
            isLoading: false,
            isLoaded: true,
          }),
        );
      }
    }
    return { data: { maintenanceBannerDataList: maintenanceBannerList } };
  }

  isEmpty() {
    return (
      this.data &&
      this.data.maintenanceBannerDataList &&
      this.data.maintenanceBannerDataList.length === 0
    );
  }
}
