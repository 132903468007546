import BaseModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  FORMULA_CREATION_TYPES,
  FORMULA_TYPES_MAP,
  FORMULA_ROW_TYPES,
} from 'constants/feature/formula-constants';
import FormulaRowElement from './formula-row-element-model';
import FormulaRowNumber from './formula-row-number-model';
import FormulaRowOperator from './formula-row-operator-model';
import { getElementResultByNumberFormat } from 'utils/formula-utils';

export default class FormulaListEntry extends BaseModel({
  formulaId: null,
  revisionId: null,
  elementId: null,
  flagged: false,
  type: null,
  operator: null,
  creationType: null,
  result: 0, // The unscaled amount of the owning element of the formula, which is stored in the element.amount column in the DB
  computedResult: null, // The sum of the amounts of the elements that compose a formula
  rows: [],
  roundingResult: null,
  roundingScale: 0,

  // array of statement elements used on hover of the formula list entry to display relevant elements in the content panel
  includedElementIds: [],
}) {
  isFlagged() {
    return this.flagged;
  }
  isFormulaRounded() {
    return (
      this.roundingResult !== null &&
      this.roundingResult !== this.computedResult
    );
  }

  hasRows() {
    return this.rows.length > 0;
  }

  getExpression(numberFormatId) {
    let expression = '';
    if (this.hasRows()) {
      this.rows.forEach((row) => {
        expression =
          expression +
          getElementResultByNumberFormat(row.value, numberFormatId) +
          ' ';
      });
    }
    return expression;
  }

  convertFormulaDTOToFormulaListEntryModel(formulaDTO) {
    const { rows, includedElementIds } = this.convertDTOToRows(
      formulaDTO.formulaElementDtoList,
    );
    return this.merge({
      ...formulaDTO,
      isLoaded: true,
      rows,
      includedElementIds,
      formulaId: formulaDTO.id,
      flagged: formulaDTO.flagged === 1 ? true : false,
      creationType: formulaDTO.creationType
        ? formulaDTO.creationType
        : FORMULA_CREATION_TYPES.UNKNOWN,
      type: formulaDTO.type ? FORMULA_TYPES_MAP.get(formulaDTO.type) : null,
      value: !isNullOrUndefined(formulaDTO.includedElementAmount)
        ? formulaDTO.includedElementAmount
        : formulaDTO.value,
    });
  }

  convertDTOToRows(formulaElementDtoList) {
    let includedElementIds = [];
    const rows = formulaElementDtoList.map((formulaElementDto) => {
      switch (formulaElementDto.type) {
        case FORMULA_ROW_TYPES.ELEMENT: {
          includedElementIds.push(formulaElementDto.includedElementId);
          return new FormulaRowElement({
            elementData: {
              // TODO revisit initialization for formula edit
              id: formulaElementDto.includedElementId,
              amount: formulaElementDto.includedElementAmount,
            },
          });
        }
        case FORMULA_ROW_TYPES.NUMBER: {
          return new FormulaRowNumber({
            value: formulaElementDto.value,
          });
        }
        case FORMULA_ROW_TYPES.OPERATOR: {
          return new FormulaRowOperator({
            value: formulaElementDto.value,
          });
        }
        default: {
          console.error('INVALID FORMULA ROW TYPE');
          return null;
        }
      }
    });
    return {
      rows,
      includedElementIds,
    };
  }
}
