export const isProjectHasReservedClientName = (project) => {
  const { engagementEntity } = project;
  const RESERVED_CLIENT_NAME = 'Tie out support';

  if (engagementEntity && engagementEntity.clientName) {
    return (
      engagementEntity.clientName
        .toLowerCase()
        .indexOf(RESERVED_CLIENT_NAME.toLowerCase()) >= 0
    );
  }
  return false;
};
