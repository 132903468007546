import cloneDeep from 'lodash.clonedeep';

export default class TabModelIcon {
  /**shape is:
   * {"tabId-1":{iconComponent:'abc',classname:'test'},"tabId-2":{iconComponent:'abc1',classname:'test1'}}
   * shape is maintained using Map Object
   */
  data;
  constructor({ data } = {}) {
    this.data = data;
  }

  setNewIcon({ tabId, iconComponent, classname }) {
    if (this && this.data && this.data.size > 0) {
      let tabIconData = cloneDeep(this.data);
      tabIconData.set(tabId, { iconComponent, classname });
      return new this.constructor({ data: tabIconData });
    } else {
      return new this.constructor({
        data: new Map().set(tabId, { iconComponent, classname }),
      });
    }
  }
}
