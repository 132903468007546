import { createAction } from 'redux-actions';
import { getStatementCreationPageDataRequest } from 'api/statement-creation-page-data-api';

export const statementCreationPageDataLoading = createAction(
  'STATEMENT_CREATION_PAGE_DATA_LOADING',
);
export const statementCreationPageDataError = createAction(
  'STATEMENT_CREATION_PAGE_DATA_ERROR',
);
export const statementCreationPageDataLoaded = createAction(
  'STATEMENT_CREATION_PAGE_DATA_LOADED',
);
export const clearStatementCreationPageDataselected = createAction(
  'CLEAR_STATEMENT_CREATION_PAGE_DATA_SELECTED',
);

export const fetchStatementCreationPageData =
  () => async (dispatch, getState) => {
    try {
      dispatch(statementCreationPageDataLoading());
      const { data } = await getStatementCreationPageDataRequest();
      console.debug('statement create options data :', data);
      dispatch(statementCreationPageDataLoaded(data));
    } catch (error) {
      dispatch(statementCreationPageDataError(error));
    }
  };
