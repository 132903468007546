import { handleActions } from 'redux-actions';

import SelectedStatement from 'models/api/selected-statement-model';
import {
  selectedStatementLoading,
  selectedStatementLoaded,
  selectedStatementError,
  clearSelectedStatement,
} from 'store/actions/selected-statement-actions';

export default handleActions(
  {
    [selectedStatementLoading]: (selectedStatement) => {
      return selectedStatement.setLoading();
    },
    [selectedStatementLoaded]: (selectedStatement, { payload }) => {
      return selectedStatement.setLoaded(payload);
    },
    [selectedStatementError]: (selectedStatement, { payload }) => {
      return selectedStatement.setError(payload);
    },
    [clearSelectedStatement]: (selectedStatement) => {
      return new SelectedStatement();
    },
  },
  new SelectedStatement()
);
