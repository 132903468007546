import { getStatementSummarySectionsRequest } from 'api/statement-summary-api';
import { createAction } from 'redux-actions';

export const statementSummarySectionsLoading = createAction(
  'STATEMENT_SUMMARY_SECTIONS_LOADING',
);
export const statementSummarySectionsLoaded = createAction(
  'STATEMENT_SUMMARY_SECTIONS_LOADED',
);
export const statementSummarySectionsError = createAction(
  'STATEMENT_SUMMARY_SECTIONS_ERROR',
);

export const updateSectionSummaryFromSocketPayloadAction = createAction(
  'UPDATE_SECTION_SUMMARY_FROM_SOCKET_PAYLOAD_ACTION',
);

export const fetchStatementSummarySections = ({ revisionId }) => (dispatch) => {
  dispatch(statementSummarySectionsLoading());
  getStatementSummarySectionsRequest({ revisionId })
    .then((response) => {
      dispatch(statementSummarySectionsLoaded({ response }));
    })
    .catch((error) => {
      dispatch(statementSummarySectionsError({ error }));
    });
};
