import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
} from 'components/common/omnia/flyout-component';
import FormulaPanelHeader from './_formula-panel-header-component';
import FormulaPanelContentContainer from 'containers/feature/formula-panel/formula-panel-content-container';
import FormulaCancelModal from './formula-cancel-modal-component';
import FormulaForm from 'models/form/formula-form-model';
import { history } from 'store/store';
import { isNullOrUndefined } from 'utils/object-utils';
export const FORMULA_PANEL_BLOCK = 'formula-panel';
export const FORMULA_PANEL_ID_BLOCK = 'formula-panel-id';

class FormulaPanel extends Component {
  constructor(props) {
    super(props);
    this.unBlockNavigation = null;
    this.shouldBlockNavigation = false;
    this.toLocation = null;
  }

  componentDidUpdate() {
    const { formulaInProgress } = this.props;
    this.shouldBlockNavigation = formulaInProgress.hasBeenModified;
  }

  componentDidMount() {
    const { toggleFormulaCancelModal } = this.props;
    this.unBlockNavigation = history.block((location) => {
      if (this.shouldBlockNavigation) {
        toggleFormulaCancelModal(true);
        this.toLocation = location;
        return false;
      }
      return true;
    });

    /** Prevent browser from navigating away from statenement content page
     * if the they type a random url and if formula is not saved */
    window.addEventListener('beforeunload', this._blockUrlNavigation);
  }

  componentWillUnmount() {
    this.unBlockNavigation();
    window.removeEventListener('beforeunload', this._blockUrlNavigation);
  }

  _blockUrlNavigation = (e) => {
    if (this.shouldBlockNavigation) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  _toggleCancelModal = () => {
    const {
      toggleFormulaCancelModal,
      cancelModal,
      openCancelModalAfterModalConfirm,
      storeSelectedNoteId,
      storeSelectedNotePseudoId,
    } = this.props;
    const { openFormulaCancelModal } = cancelModal;
    toggleFormulaCancelModal(!openFormulaCancelModal);
    if (openFormulaCancelModal) {
      openCancelModalAfterModalConfirm(false);
      storeSelectedNoteId(null);
      storeSelectedNotePseudoId(null);
    }
  };

  /** First action fired when cancel or back button is clicked, determines if
   * we should show cancel modal or just close the formula panel
   */
  _determineActionOnCancel = () => {
    this._toggleCancelModal();
    this.toLocation = null;
  };

  /** Action fired from the cancel modal when we have confirmed that we'd like to
   * discard our changes and close the formula panel
   */
  _confirmCancelFromCancelModal = () => {
    const {
      closeFormulaPanelFlyout,
      toggleNotesPanel,
      selectNoteFromContentPanel,
      cancelModal,
      onSectionClick,
      cancelCopyFormula,
    } = this.props;
    const {
      openFormulaCancelModal,
      selectedNoteId,
      selectedPseudoNoteId,
      openNotePanelAfterConfirm,
      openSectionPanelAfterconfirm,
      selectedSectionId,
    } = cancelModal;
    let fetchElementsAfterModalClose = true;
    this._toggleCancelModal();
    cancelCopyFormula();
    if (this.toLocation) {
      this.shouldBlockNavigation = false;
      history.push(this.toLocation.pathname);
      fetchElementsAfterModalClose = false;
    }
    if (openFormulaCancelModal && openNotePanelAfterConfirm) {
      closeFormulaPanelFlyout(fetchElementsAfterModalClose);
      if (selectedNoteId === null || selectedPseudoNoteId === null) {
        toggleNotesPanel();
      } else {
        selectNoteFromContentPanel({
          elementId: selectedNoteId,
          pseudoElementId: selectedPseudoNoteId,
        });
      }
    }
    if (
      openSectionPanelAfterconfirm &&
      openFormulaCancelModal &&
      !isNullOrUndefined(selectedSectionId)
    ) {
      closeFormulaPanelFlyout(fetchElementsAfterModalClose);
      onSectionClick({ sectionId: selectedSectionId });
    } else {
      closeFormulaPanelFlyout(fetchElementsAfterModalClose);
    }
  };

  handleSaveFormula = async () => {
    const {
      closeFormulaPanelFlyout,
      saveFormula,
      updateFormula,
      formulaInProgress,
    } = this.props;
    if (formulaInProgress.isCreate()) {
      await saveFormula();
    } else {
      await updateFormula();
    }
    closeFormulaPanelFlyout();
  };

  render() {
    const { showFormulaPanel, cancelModal } = this.props;
    const { openFormulaCancelModal } = cancelModal;

    return (
      <>
        <Flyout
          className={`${FORMULA_PANEL_BLOCK}__flyout`}
          show={showFormulaPanel}
          isAnimated
        >
          {showFormulaPanel && (
            <>
              <FlyoutHeader enableBack onBack={this._determineActionOnCancel}>
                <FormulaPanelHeader />
              </FlyoutHeader>
              <FlyoutPanelContent>
                <FormulaPanelContentContainer
                  onCancel={this._determineActionOnCancel}
                  saveFormula={this.handleSaveFormula}
                />
              </FlyoutPanelContent>
            </>
          )}
        </Flyout>
        {openFormulaCancelModal && (
          <FormulaCancelModal
            onClickYes={this._confirmCancelFromCancelModal}
            onClickNo={this._toggleCancelModal}
          />
        )}
      </>
    );
  }
}

FormulaPanel.propTypes = {
  /**A boolean indicating if we should show the formula panel or not. */
  showFormulaPanel: PropTypes.bool.isRequired,
  /**The action that is fired when we need to hide the formula panel. */
  closeFormulaPanelFlyout: PropTypes.func.isRequired,
  /** Current formula form in progress */
  formulaInProgress: PropTypes.instanceOf(FormulaForm).isRequired,
  /**The action that is fired when user presses save button on formula-panel */
  saveFormula: PropTypes.func.isRequired,
  /** Action for updating formula */
  updateFormula: PropTypes.func.isRequired,
  /** Action to open the note panel */
  toggleNotesPanel: PropTypes.func,
  /** object value containing the cancel modal model */
  cancelModal: PropTypes.object,
  /** action fired to open the internal reference cancel modal */
  toggleInternalReferenceCancelModal: PropTypes.func,
  /** action fired to indicate the note panel will be opened after modal is confirmed */
  openCancelModalAfterModalConfirm: PropTypes.func,
  /** action fired to store the selected note in redux */
  storeSelectedNoteId: PropTypes.func,
  /** action fired to store the psuedo id of the selected note in redux */
  storeSelectedNotePseudoId: PropTypes.func,
  /**The action to open the section. */
  onSectionClick: PropTypes.func,
  /**The action to open the formula cancel modal. */
  toggleFormulaCancelModal: PropTypes.func,
};

export default FormulaPanel;
