import { handleActions } from 'redux-actions';
import {
  setProjectIdWorkflowExpanded,
  clearProjectIdWorkflowExpanded,
} from 'store/actions/statement-workflows-actions';

const DEFAULT_EXPANDED_PROJECT = null;

export default handleActions(
  {
    [clearProjectIdWorkflowExpanded]: () => {
      return DEFAULT_EXPANDED_PROJECT;
    },
    [setProjectIdWorkflowExpanded]: (projectId, { payload }) => {
      return payload;
    },
  },
  DEFAULT_EXPANDED_PROJECT
);
