import {
  withDefaultContextAsSelectedProject,
  withDefaultContextAsSelectedGeo,
} from 'api/api-default-context';

export const verifyOmniaTokenRequest = ({ token, geoCode }) =>
  withDefaultContextAsSelectedGeo({}, geoCode)
    .get(`/tieout/omnia/decode?token=${token}`)
    .then((response) => {
      return response;
    });

export const getEngagementsByIdRequest = ({ engagementId, geoCode }) =>
  withDefaultContextAsSelectedGeo({}, geoCode)
    .get(`/tieout/omnia/engagement?eId=${engagementId}`)
    .then((response) => {
      let projectGeo;
      // modify response to add geo code suffix for project id
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.omniaTieoutEngagementEntityList
      )
        projectGeo =
          response.data.result.omniaEngagementEntity &&
          response.data.result.omniaEngagementEntity.geoName;
      response.data.result.omniaTieoutEngagementEntityList =
        response.data.result.omniaTieoutEngagementEntityList.map((entry) => {
          entry.toutProjectId = `${entry.toutProjectId}-${projectGeo}`;
          return entry;
        });
      return response;
    });

export const linkOmniaEngagementRequest = ({
  omniaTieoutEngagementEntity,
  geoCode,
}) =>
  withDefaultContextAsSelectedGeo({}, geoCode)
    .post('/tieout/omnia/link', omniaTieoutEngagementEntity)
    .then((response) => {
      return response;
    });

export const unlinkOmniaEngagementRequest = ({
  omniaTieoutEngagementEntity,
  geoCode,
}) =>
  withDefaultContextAsSelectedGeo({}, geoCode)
    .post('/tieout/omnia/unlink', omniaTieoutEngagementEntity)
    .then((response) => {
      return response;
    });

export const exportFileToOmnia = ({ omniaReportEntity }) =>
  withDefaultContextAsSelectedProject()
    .post('/tieout/omnia/export', omniaReportEntity)
    .then((response) => {
      return response;
    });

export const exportFileToOmniaFallback = ({ omniaReportEntity }) =>
  withDefaultContextAsSelectedProject()
    .post('/tieout/omnia/fallback/export', omniaReportEntity)
    .then((response) => {
      return response;
    });

export const getOmniaExportFileStatus = ({ statementId }) =>
  withDefaultContextAsSelectedProject().get('/tieout/omnia/status', {
    params: { statementId: statementId },
  });
