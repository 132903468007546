import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getStatementSummaryNotesRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/summary/notes`,
  );

export const getStatementSummaryElementsRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/summary/elements`,
  );

export const getStatementSummarySectionsRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/summary/sectionreviews`,
  );
