import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SlideToggle from 'components/common/slide-toggle-component';
import { SIDE_BY_SIDE_DROPDOWN_BLOCK } from 'pages/side-by-side-compare/dropdown/side-by-side-dropdown';

const Option = ({ option, onToggle }) => {
  return (
    <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__option`}>
      <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__option-title`}>
        <FormattedMessage id={option.label} />
      </div>
      <div className={`${SIDE_BY_SIDE_DROPDOWN_BLOCK}__option-toggle`}>
        <SlideToggle
          name={option.name}
          onChange={onToggle}
          checked={option.checked}
          id={option.id}
        />
      </div>
    </div>
  );
};

Option.propTypes = {
  /** This is option which is visible in dropdown */
  option: PropTypes.shape({
    /** This is lable which is visible in dropdown as option */
    label: PropTypes.string.isRequired,
    /** This is name which is visible in dropdown input after selection */
    name: PropTypes.string.isRequired,
    /** This is checked which describe which option is checked/toggled-on or not */
    checked: PropTypes.bool.isRequired,
    /** This is id which to distinguish options */
    id: PropTypes.string.isRequired,
  }).isRequired,
  /** This is onToggle function which is used to toggle the dropdown input */
  onToggle: PropTypes.func.isRequired,
};

export default Option;
