import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import StatementContentPage from 'pages/statement-content-page';
import {
  initStatementContent,
  clearStatementPageStore,
} from 'store/actions/statement-content-actions';
import {
  setElementSelectModeBatchIfPossible,
  clearModeIfPossible,
  clearBatchModeSelected,
  setElementSelectModeBatchWithBannerAction,
} from 'store/actions/modes-actions';
import {
  clearSelectedStatement,
  fetchSelectedStatement,
  selectedStatementLoading,
} from 'store/actions/selected-statement-actions';
import { clearStatementNavElementTab } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { setContentFixedMode } from 'store/actions/content-view-modes-actions';
import { clearElementsFromWorkpaper } from 'store/actions/workpaper-toolkit-list-actions';
import { closeSocketClient } from 'store/actions/statement-socket-actions';
import { clearElementsFromTickmark } from 'store/actions/tickmark-list-panel-actions';
import { clearSectionTreeListAction } from 'store/actions/section-tree-list-actions';
import { cancelCopyFormula } from 'store/actions/copy-formula-actions';
import { showBatchPanelAction } from 'store/actions/panel-controller-actions';
import { cancelBatchUpdatePanel } from 'store/actions/batch-panel-actions';
import { ELEMENT_SELECT_MODES } from '../../../constants/feature/modes-constants';

const mapStateToProps = (
  {
    data: {
      revision,
      selectedStatement,
      workflowsMap,
      projectUsersList,
      selectedProject,
    },
    ui: {
      statementPage: {
        modes: { blacklineViewMode, contentViewMode, selectMode },
      },
    },
    // sockets: {
    //   statementSocket: { socketHasBeenDisconnected },
    // },
  },
  { match: { params } },
) => ({
  revision,
  urlParams: params,
  selectedStatement,
  blacklineViewMode,
  contentViewMode,
  workflowsMap,
  projectUsersList,
  selectedProjectId: selectedProject && selectedProject.id,
  // socketHasBeenDisconnected,
  isBatchModeSelected:
    selectMode &&
    (selectMode.id === ELEMENT_SELECT_MODES.BATCH_WITH_BANNER.id ||
      selectMode.id === ELEMENT_SELECT_MODES.FORMULA.id ||
      selectMode.id === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.id),
});

const mapDispatchToProps = {
  push,
  initStatementContent,
  clearStatementPageStore,
  setElementSelectModeBatchIfPossible,
  clearBatchModeIfPossible: clearModeIfPossible,
  clearSelectedStatement,
  fetchSelectedStatement,
  clearStatementNavElementTab,
  setContentFixedMode,
  clearElementsFromWorkpaper,
  closeSocketClient,
  clearElementsFromTickmark,
  clearSectionTreeListAction,
  cancelCopyFormula,
  showBatchPanelAction,
  cancelBatchUpdatePanel,
  clearBatchModeSelected,
  setElementSelectModeBatchWithBannerAction,
  selectedStatementLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementContentPage);
