import BaseModel from 'models/base-model';
export default class Engagement extends BaseModel({
  active: null,
  cik: null,
  clientId: null,
  clientName: null,
  clientPrimaryInd: null,
  clientPrimarySector: null,
  clientType: null,
  engagementFye: null,
  engagementId: null,
  engagementName: null,
  secFilerStatus: null,
  tickerSymbol: null,
}) {}
