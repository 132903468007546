import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import OmniaEngagementEntry from 'models/data/omnia-engagement-entry-model';

export default class OmniaEngagement extends ApiModel({
  data: {
    omniaEngagementEntity: null,
    omniaTieoutEngagementEntityList: [],
  },
}) {
  get omniaEngagementEntity() {
    if (this.data) {
      return this.data.omniaEngagementEntity;
    }
    return null;
  }

  get omniaTieoutEngagementEntityList() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.omniaTieoutEngagementEntityList;
    }
    return [];
  }

  getEngagementId() {
    if (this.data && this.data.omniaEngagementEntity) {
      return this.data.omniaEngagementEntity.engagementId;
    }
    return null;
  }

  getGeoName() {
    if (this.data && this.data.omniaEngagementEntity) {
      return this.data.omniaEngagementEntity.geoName;
    }
    return null;
  }

  hasOmniaEngagementBeenLinked() {
    if (
      this.data.omniaTieoutEngagementEntityList &&
      this.data.omniaEngagementEntity
    ) {
      return this.data.omniaTieoutEngagementEntityList.some((entry) => {
        return (
          entry.linkedStatus === 1 &&
          entry.omEngagementId ===
            this.data.omniaEngagementEntity.engagementId &&
          entry.environment === this.data.omniaEngagementEntity.environment
        );
      });
    }
    return false;
  }

  areThereTieoutEngagementEntities() {
    return (
      this.data.omniaTieoutEngagementEntityList &&
      this.data.omniaTieoutEngagementEntityList.length > 0
    );
  }

  processResponse({ response }) {
    return {
      data: {
        omniaEngagementEntity: response.data.result.omniaEngagementEntity,
        omniaTieoutEngagementEntityList:
          response.data.result.omniaTieoutEngagementEntityList.map(
            (entry) => new OmniaEngagementEntry(entry),
          ),
      },
    };
  }
}
