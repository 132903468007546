import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export default class ElementHistory extends ApiModel({
  data: {
    entries: [],
  },
}) {
  get entries() {
    if (isNullOrUndefined(this.data)) {
      return [];
    }
    return this.data.entries;
  }

  processResponse(response) {
    return { data: { entries: response.data.result } };
  }
}
