import { createAction } from 'redux-actions';

import {
  getTickmarksForRevisionRequest,
  deleteTickmarkRequest,
} from 'api/tickmark-api';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { updateCacheWithMostRecentElementChanges } from 'store/actions/element-changes-since-tracker-actions';
import { getElementTickmarksWithoutLoading } from 'store/actions/element-panel-actions';
import { getElementsByTickmark } from 'api/tickmark-api';
import { isNullOrUndefined } from 'utils/object-utils';
export const tickmarkListLoading = createAction('TICKMARK_LIST_LOADING');
export const tickmarkListLoaded = createAction('TICKMARK_LIST_LOADED');
export const tickmarkListError = createAction('TICKMARK_LIST_ERROR');
export const fetchElementsFromTickmarkAction = createAction(
  'FETCH_ELEMENTS_FROM_TICKMARK',
);
export const updateTickmarkElementsMapAction = createAction(
  'UPDATE_TICKMARK_ELEMENTS_MAP_ACTION',
);

export const updateTickmarkElementsMapFromArrayAction = createAction(
  'UPDATE_TICKMARK_ELEMENTS_MAP_FROM_ARRAY_ACTION',
);
export const clearTickmarkElementsAction = createAction(
  'CLEAR_TICKMARK_ELEMENTS_ACTION',
);
export const removeTickmarkFromElementsMapAction = createAction(
  'REMOVE_TICKMARK_FROM_ELEMENTS_MAP_ACTION',
);
export const removeElementFromTickmarkElementsMapWithSocketPayloadAction = createAction(
  'REMOVE_ELEMENT_FROM_TICKMARK_ELEMENTS_MAP_WITH_SOCKET_PAYLOAD_ACTION',
);
export const addTickmarkToDeletedArrayAction = createAction(
  'ADD_TICKMARK_TO_DELETED_ARRAY_ACTION',
);
export const excludeElementFromElementsMapAction = createAction(
  'EXCLUDE_TICKMARK_FROM_ELEMENTS_MAP_ACTION',
);

export const updateTickmarkWithSocketPayloadAction = createAction(
  'UPDATE_TICKMARK_WITH_SOCKET_PAYLOAD_ACTION',
);

export const addTickmarkToListAction = createAction(
  'ADD_TICKMARK_TO_LIST_ACTION',
);

export const unlinkTickmarkFromElementWithWebsocket = createAction(
  'UNLINK_TICKMARK_FROM_TICKMARK_LIST_WITH_SOCKET',
);

export const deleteTickmarkWithSocketPayloadAction = createAction(
  'DELETE_TICKMARK_WITH_SOCKET_PAYLOAD_ACTION',
);
export const fetchTickmarkListForRevisionWithoutLoading = ({
  revisionId,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  try {
    const response = await getTickmarksForRevisionRequest({ revisionId });
    dispatch(tickmarkListLoaded({ response }));
  } catch (error) {
    dispatch(tickmarkListError(error));
  }
};

export const fetchTickmarkListForRevision = ({ revisionId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  dispatch(tickmarkListLoading());
  await dispatch(fetchTickmarkListForRevisionWithoutLoading({ revisionId }));
};

export const deleteTickmark = ({ tickmark }) => async (dispatch, getState) => {
  dispatch(addTickmarkToDeletedArrayAction(tickmark.tickmarkId));
  await deleteTickmarkRequest({ tickmark });
  const { id: revisionId } = getState().data.revision;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  if (socketHasBeenDisconnected) {
    dispatch(fetchTickmarkListForRevisionWithoutLoading({ revisionId }));
    const { id: elementId } = getState().data.elementPanel.elementDetails;
    if (!isNullOrUndefined(elementId)) {
      dispatch(getElementTickmarksWithoutLoading({ elementId }));
    }
    // We do not want to update summary (1st param -false). Since deleting of Tickmark should not result
    // in change of summary.
    dispatch(updateCacheWithMostRecentElementChanges(false, true));
  }
};

export const fetchElementsFromTickmark = ({ revisionId, tickmarkId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  try {
    const response = await getElementsByTickmark({ revisionId, tickmarkId });
    dispatch(fetchElementsFromTickmarkAction({ response, tickmarkId }));
  } catch (error) {
    dispatch(tickmarkListError(error));
  }
};
export const updateTickmarkElementsMap = ({ response }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  const updatedElementsArray = response.data.result
    ? response.data.result.elementEntityList[0]
    : response;
  if (response && updatedElementsArray) {
    dispatch(updateTickmarkElementsMapAction(updatedElementsArray));
  }
};
export const updateTickmarkElementsMapFromArray = ({ elementsArray }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  if (elementsArray) {
    dispatch(updateTickmarkElementsMapFromArrayAction(elementsArray));
  }
};

export const clearElementsFromTickmark = () => (dispatch) => {
  dispatch(clearTickmarkElementsAction());
};

export const removeTickmarkFromElementsMapStore = ({ tickmarkId }) => (
  dispatch,
) => {
  dispatch(removeTickmarkFromElementsMapAction(tickmarkId));
};

export const excludeElementFromTickmarkElementsMap = ({ elementId }) => async (
  dispatch,
  getState,
) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.TICKMARK) return;
  const { id: revisionId } = getState().data.revision;

  try {
    await dispatch(fetchTickmarkListForRevisionWithoutLoading({ revisionId }));
    dispatch(excludeElementFromElementsMapAction(elementId));
  } catch (error) {
    dispatch(tickmarkListError(error));
  }
};
