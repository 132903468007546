import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import { FormattedMessage } from 'react-intl';
import { ModalButton } from 'models/utils/common/modal-button-model';
const OMNIA_FILE_SHARING_CONFIRM_MODAL_ID =
  'statement-wrap-up-approval-modal-2-id';
const OmniaFileSharingConfirmModal2 = ({ onClickClose }) => {
  return (
    <Modal
      id={OMNIA_FILE_SHARING_CONFIRM_MODAL_ID}
      title={'omnia.confirm-share-modal2.title'}
      onClose={onClickClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.close',
          onClick: onClickClose,
        })
      }
    >
      <div>
        <FormattedMessage id={'omnia.confirm-share-modal.status-display'} />
      </div>
    </Modal>
  );
};

OmniaFileSharingConfirmModal2.propTypes = {
  /** Action fired when user clicks No || close button */
  onClickClose: PropTypes.func.isRequired,
};

export default OmniaFileSharingConfirmModal2;
