import ApiModel from 'models/api-model';

export class SectionReviewHistory extends ApiModel({
  data: {
    sectionReviewHistory: {},
  },
}) {
  get sectionReviewHistory() {
    if (this.data && this.data.sectionReviewHistory) {
      return this.data.sectionReviewHistory;
    }
    return null;
  }

  appendSectionReviewHistory(response) {
    if (
      this.sectionReviewHistory &&
      this.sectionReviewHistory.result &&
      this.sectionReviewHistory.result.length > 0
    ) {
      let updatedSectionHistory = [...this.data.sectionReviewHistory.result];
      updatedSectionHistory.unshift(response);
      return this.mergeData({
        sectionReviewHistory: {
          result: updatedSectionHistory,
        },
      });
    } else {
      let newHistory = [];
      newHistory.push(response);
      return this.mergeData({
        sectionReviewHistory: {
          result: newHistory,
        },
      });
    }
  }

  processResponse({ response }) {
    return {
      data: {
        sectionReviewHistory: response.data,
      },
    };
  }
}
