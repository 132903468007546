import { handleActions } from 'redux-actions';
import {
  setContentHighlightSelector,
  clearContentHighlightSelector,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import ContentCSSSelector from 'models/data/content-css-selector-model';
export default handleActions(
  {
    [setContentHighlightSelector]: (selector, { payload }) => {
      return new ContentCSSSelector(payload);
    },
    [clearContentHighlightSelector]: () => {
      return new ContentCSSSelector();
    },
    [clearStatementPageStoreAction]: () => {
      return new ContentCSSSelector();
    },
  },
  new ContentCSSSelector()
);
