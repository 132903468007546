import ApiModel from 'models/api-model';

export default class SectionAssignments extends ApiModel({
  data: {},
}) {
  get hasUsersAssignedToSection() {
    if (this.data && this.data.assignedUsersMap) {
      return Object.keys(this.data.assignedUsersMap).length > 0;
    }
    return false;
  }

  get assignedUsersMap() {
    if (this.data && this.data.assignedUsersMap) {
      return this.data.assignedUsersMap;
    }
    return {};
  }

  processResponse({ response, usersList }) {
    let assignedUsersMap = {};
    let assignedUserIdSet = new Set();

    for (let i = 0; response.data.result.length > i; i++) {
      assignedUserIdSet.add(response.data.result[i].userId);
    }

    for (let i = 0; usersList.length > i; i++) {
      const user = usersList[i];

      if (assignedUserIdSet.has(user.id)) {
        assignedUsersMap[user.id] = {
          name: user.fullName,
        };
      }
    }

    return {
      data: {
        assignedUsersMap,
      },
    };
  }

  replaceNewWithSocketData(payload) {
    let assignedUsersMapCopy = {};
    for (let i = 0; i < payload.length; i++) {
      assignedUsersMapCopy[payload[i].userId] = { name: payload[i].userName };
    }
    return this.mergeData({
      assignedUsersMap: assignedUsersMapCopy,
    });
  }

  updateSectionAssignmentInBulkFromSocket(payload) {
    if (payload.length > 0) {
      //assign
      let assignedUsersMapCopy = {};
      for (let i = 0; i < payload.length; i++) {
        assignedUsersMapCopy[payload[i].userId] = {
          name: payload[i].userName,
        };
      }

      return this.mergeData({
        assignedUsersMap: { ...this.assignedUsersMap, ...assignedUsersMapCopy },
      });
    }
    //unassign all
    return this.mergeData({
      assignedUsersMap: {},
    });
  }
}
