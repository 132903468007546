import React from 'react';
import PropTypes from 'prop-types';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import ProjectListEntry from 'models/data/project-list-entry-model';

export const SOFT_DELETE_PROJECT_ID_BLOCK = 'project-card-id';

const SoftDeleteModal = ({
  toggleSoftDeleteModal,
  softDeleteAction,
  project,
  updateFilteredProjectList,
}) => {
  return (
    <Modal
      id={`${SOFT_DELETE_PROJECT_ID_BLOCK}__soft-delete-modal`}
      title={'project-list.delete-modal.title'}
      onClose={toggleSoftDeleteModal}
      primaryModalButton={
        new ModalButton({
          text: 'project-list.soft-delete-modal.delete-button',
          onClick: async () => {
            toggleSoftDeleteModal();
            await softDeleteAction({ project });
            updateFilteredProjectList();
          },
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: toggleSoftDeleteModal,
        })
      }
    >
      <div className="modal__text">
        <FormattedMessage id="project-list.soft-delete-modal.message" />
      </div>
      <Banner
        id={`${SOFT_DELETE_PROJECT_ID_BLOCK}-soft-delete-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'project-list.soft-delete-modal.bannerCopy'}
        isInternationalized={true}
        isInteractive={false}
      />
    </Modal>
  );
};

SoftDeleteModal.propTypes = {
  /** Method on the project list card component to show soft delete modal */
  toggleSoftDeleteModal: PropTypes.func.isRequired,
  /** Function that governs the behavior when the soft delete option is clicked on the project card component */
  softDeleteAction: PropTypes.func.isRequired,
  /** Project to be soft deleted */
  project: PropTypes.instanceOf(ProjectListEntry).isRequired,
  /** function fired to update project list with latest project data */
  updateFilteredProjectList: PropTypes.func.isRequired,
};

export default SoftDeleteModal;
