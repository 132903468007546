import { withDefaultContextAsSelectedProject } from 'api/api-default-context';

export const initialProjectSocketClientUrl = (projectId) =>
  `/tieout/signalr/project/${parseInt(projectId)}`;

export const closeSocketClientRequest = ({ projectId, connectionId }) => {
  return withDefaultContextAsSelectedProject().delete(
    `/tieout/signalr/project/${parseInt(
      projectId,
    )}/connection/${connectionId}/close`,
  );
};
