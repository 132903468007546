import React from 'react';
import { UNIT_TYPES } from 'constants/feature/element-attribute-constants';
import { FormattedMessage } from 'react-intl';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { TooltipOptions } from '../../models/utils/common/tooltip-options-model';
import { getElementResultByNumberFormat } from 'utils/formula-utils';

export const FORMULA_MANUAL_NUMBER_REGEX = /^-?[0-9.,\b]*$/;

export const MATH_EXPRESSION_EVAL_INFINITY_RESULT = 'Infinity';

export const SUPPORTED_NUMBER_LENGTH = 15;
export const ROUND_NUMBER_ICON_WIDTH = '8px';
export const ROUND_NUMBER_ICON_HEIGHT = '6px';

export const FORMULA_ELEMENT_CARRY_FORWARD_STATUS_TYPES = {
  NEW: 0,
  UNCHANGED: 1,
  CONTEXT_CHANGED: 2,
  VALUE_CHANGED: 3,
  MISSING: 4,
};

export const getOriginalComputedValueTooltip = (value) => {
  return new TooltipOptions({
    text: {
      id: 'formula-panel.rounded-number-original-value.tooltip',
      values: { lineBreak: <br />, value: value },
    },
    id: 'formula-panel-original-computed-amount-tool-tip',
    position: 'top',
  });
};

export const FORMULA_OPERATOR_OPTIONS_MAP = new Map();
FORMULA_OPERATOR_OPTIONS_MAP.set(
  '=',
  new MenuOption({
    id: 'formula-operator-equal',
    title: '=',
    value: '=',
    isIntl: false,
  }),
);
FORMULA_OPERATOR_OPTIONS_MAP.set(
  '<',
  new MenuOption({
    id: 'formula-operator-less-than',
    title: '<',
    value: '<',
    isIntl: false,
  }),
);
FORMULA_OPERATOR_OPTIONS_MAP.set(
  '>',
  new MenuOption({
    id: 'formula-operator-greater-than',
    title: '>',
    value: '>',
    isIntl: false,
  }),
);
FORMULA_OPERATOR_OPTIONS_MAP.set(
  '<=',
  new MenuOption({
    id: 'formula-operator-less-than-equal',
    title: '<=',
    value: '<=',
    isIntl: false,
  }),
);
FORMULA_OPERATOR_OPTIONS_MAP.set(
  '>=',
  new MenuOption({
    id: 'formula-operator-greater-than-equal',
    title: '>=',
    value: '>=',
    isIntl: false,
  }),
);

export const FORMULA_OPERATOR_TYPE_OPTIONS = Array.from(
  FORMULA_OPERATOR_OPTIONS_MAP.values(),
);

export const FORMULA_CREATION_TYPES = {
  MANUAL: 'MANUAL',
  SYSTEM: 'SYSTEM', // TODO perhaps rename once we integrate with SYSTEM formulas
  AUTOMATION: 'AUTOMATION',
  UNKNOWN: 'UNKNOWN',
};

export const FORMULA_TYPES = {
  F: 'f',
  CF: 'cf',
  RX: 'rx', //TODO: Refactor this once we more clearly defined what these types mean.
};

export const FORMULA_TYPES_MAP = new Map();
FORMULA_TYPES_MAP.set('f', FORMULA_TYPES.F);
FORMULA_TYPES_MAP.set('cf', FORMULA_TYPES.CF);
FORMULA_TYPES_MAP.set('rx', FORMULA_TYPES.RX);

export const FORMULA_ROW_TYPES = {
  NUMBER: 'NUMBER',
  ELEMENT: 'ELEMENT',
  OPERATOR: 'OPERATOR',
};

export const FORMULA_ROW_TYPES_MAP = new Map();
FORMULA_ROW_TYPES_MAP.set('NUMBER', FORMULA_ROW_TYPES.NUMBER);
FORMULA_ROW_TYPES_MAP.set('ELEMENT', FORMULA_ROW_TYPES.ELEMENT);
FORMULA_ROW_TYPES_MAP.set('OPERATOR', FORMULA_ROW_TYPES.OPERATOR);

export const FORMULA_ROW_OPERATOR_OPTIONS_MAP = new Map();
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  '+',
  new MenuOption({
    id: 'formula-row-operator-addition',
    title: '+',
    value: '+',
    isIntl: false,
  }),
);
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  '-',
  new MenuOption({
    id: 'formula-row-operator-subtraction',
    title: '-',
    value: '-',
    isIntl: false,
  }),
);
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  '/',
  new MenuOption({
    id: 'formula-row-operator-division',
    title: '/',
    value: '/',
    isIntl: false,
  }),
);
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  '*',
  new MenuOption({
    id: 'formula-row-operator-multiplication',
    title: '*',
    value: '*',
    isIntl: false,
  }),
);
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  ')',
  new MenuOption({
    id: 'formula-row-operator-close-parenthesis',
    title: ')',
    value: ')',
    isIntl: false,
  }),
);
FORMULA_ROW_OPERATOR_OPTIONS_MAP.set(
  '(',
  new MenuOption({
    id: 'formula-row-operator-open-parenthesis',
    title: '(',
    value: '(',
    isIntl: false,
  }),
);

export const FORMULA_ROW_OPERATOR_OPTIONS = Array.from(
  FORMULA_ROW_OPERATOR_OPTIONS_MAP.values(),
);

export const getResultDisplay = ({ result, units, numberFormatId }) => {
  let numberIsInfinity = false;

  if (typeof result === 'number' && /e[+-]/.test(result.toString())) {
    numberIsInfinity = true;
  } else {
    numberIsInfinity = false;
  }
  const resultString = result.toString(); // result don't always come as str
  let unformattedResult = result;
  const isResultNegative = resultString[0] === '-';

  if (!numberIsInfinity && resultString.indexOf('.') !== -1) {
    /** Checking for negative number scenarios  */
    const splitResultString = resultString.split('.');
    const decimalResultCount =
      splitResultString[0].length + splitResultString[1].length;
    if (decimalResultCount < SUPPORTED_NUMBER_LENGTH) {
      unformattedResult = result;
    } else if (isResultNegative) {
      // We want to return negative numbers with char count still 15
      unformattedResult = resultString.substr(0, SUPPORTED_NUMBER_LENGTH + 2);
      // We don't want the decimal displayed if it's the last char
      if (unformattedResult.slice(-1) === '.') {
        unformattedResult = resultString.substr(0, SUPPORTED_NUMBER_LENGTH + 1);
      }
    } else {
      unformattedResult = resultString.substr(0, SUPPORTED_NUMBER_LENGTH + 1);
      // We don't want the decimal displayed if it's the last char
      if (unformattedResult.slice(-1) === '.') {
        unformattedResult = resultString.substr(0, SUPPORTED_NUMBER_LENGTH);
      }
    }
  } else if (
    numberIsInfinity ||
    result === MATH_EXPRESSION_EVAL_INFINITY_RESULT ||
    (isResultNegative && resultString.length > SUPPORTED_NUMBER_LENGTH + 1) ||
    isNaN(result) ||
    (!isResultNegative && resultString.length > SUPPORTED_NUMBER_LENGTH)
  ) {
    return <FormattedMessage id="formula-panel.edit.unsupported-value" />;
  }
  const formattedResult = getElementResultByNumberFormat(
    unformattedResult,
    numberFormatId,
  );

  const formulaResult = formattedResult || 0;

  switch (units && units.value) {
    case UNIT_TYPES.CURRENCY:
      return (
        <FormattedMessage
          id="formula-panel.edit.currency"
          values={{ formulaResult }}
        />
      );
    case UNIT_TYPES.PERCENTAGE:
      return (
        <FormattedMessage
          id="formula-panel.edit.percentage"
          values={{ formulaResult }}
        />
      );
    case UNIT_TYPES.SHARES:
      return (
        <FormattedMessage
          id="formula-panel.edit.shares"
          values={{ formulaResult }}
        />
      );
    default:
      return `${formulaResult}`;
  }
};
