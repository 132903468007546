import RolesUtil from 'permissions/utils/roles';
const SectionReviewPermissions = {
  canReviewSection: (projectID) =>
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectID) ||
    RolesUtil.doesUserHaveObserverRoleForProject(projectID),

  canAssignSection: (projectID) =>
    RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
    RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
    RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectID),
};

export default SectionReviewPermissions;
