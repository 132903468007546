import React from 'react';
import PropTypes from 'prop-types';
import { ModalButton } from 'models/utils/common/modal-button-model';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal-component';
import ProjectListEntry from 'models/data/project-list-entry-model';

export const RESTORE_PROJECT_ID_BLOCK = 'project-card-id';

const RestoreProjectModal = ({
  toggleRestoreModal,
  restoreAction,
  project,
  updateFilteredProjectList,
}) => {
  return (
    <Modal
      id={`${RESTORE_PROJECT_ID_BLOCK}__restore-modal`}
      title={'project-list.restore-modal.title'}
      onClose={toggleRestoreModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: async () => {
            toggleRestoreModal();
            await restoreAction({ project });
            updateFilteredProjectList();
          },
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: toggleRestoreModal,
        })
      }
    >
      <div className="modal__text">
        <FormattedMessage id="project-list.restore-modal.message" />
      </div>
    </Modal>
  );
};

RestoreProjectModal.propTypes = {
  /** Method on the project list card component to show restore delete modal */
  toggleRestoreModal: PropTypes.func.isRequired,
  /** Function that governs the behavior when the restore delete option is clicked on the project card component */
  restoreAction: PropTypes.func.isRequired,
  /** Project to be soft deleted */
  project: PropTypes.instanceOf(ProjectListEntry).isRequired,
  /** function fired to update project list with latest project data */
  updateFilteredProjectList: PropTypes.func.isRequired,
};

export default RestoreProjectModal;
