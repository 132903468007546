import { handleActions } from 'redux-actions';
import {
  setStatementContentTab,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import StatementContentFilters from 'models/data/statement-content-filters-model';

export default handleActions(
  {
    [setStatementContentTab]: (state, { payload }) => {
      return state.setSelectedTab(payload);
    },
    [clearStatementPageStoreAction]: (state, { payload }) => {
      return new StatementContentFilters();
    },
  },
  new StatementContentFilters()
);
