import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as TaskReviewedIcon } from 'icons/task-reviewed.svg';
import { ReactComponent as TaskIcon } from 'icons/task.svg';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import ToolTip from 'components/common/tool-tip-component';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { FormattedMessage } from 'react-intl';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';
import { isNullOrUndefined } from 'utils/object-utils';

export const SECTION_ANCHOR_BLOCK = 'section-anchor';
export const SECTION_ANCHOR_BLOCK_ID = 'section-anchor-id';
export const REVIEW_ICON_SIZE = '16px';

export const getInitials = (string) => {
  if (isNullOrUndefined(string)) return;
  const initials = string.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  return initials[0] + initials[1];
};

const SectionAnchor = ({
  id,
  name,
  children,
  sectionId,
  sectionReview,
  shouldRenderIcon,
  sectionAssignments,
  handleElementClick,
  sectionReviewTooltip,
  isCursorAlias,
  shouldSectionAnchorDisabled,
  isSectionSelected,
  section,
  color,
}) => {
  const renderIcon = () => {
    if (!shouldRenderIcon) {
      return null;
    }
    const hasBeenReviewed = sectionReview.hasSectionBeenReviewed(sectionId);
    if (hasBeenReviewed) {
      return (
        <TaskReviewedIcon
          name={'anchor'}
          className={`${SECTION_ANCHOR_BLOCK}__icon`}
          width={REVIEW_ICON_SIZE}
          height={REVIEW_ICON_SIZE}
        />
      );
    }
    return (
      <TaskIcon
        name={'anchor'}
        className={`${SECTION_ANCHOR_BLOCK}__icon`}
        width={REVIEW_ICON_SIZE}
        height={REVIEW_ICON_SIZE}
      />
    );
  };

  const shouldAssignmentInitialsBeRendered = (userId, sectionId) => {
    if (sectionId in sectionReview.sectionReviewsMap) {
      return !(userId in sectionReview.sectionReviewsMap[sectionId]);
    }
    return true;
  };
  return section && !section.isTechnical ? (
    <button
      className={classnames(
        SECTION_ANCHOR_BLOCK,
        isSectionSelected ? `${SECTION_ANCHOR_BLOCK}--selected` : null,
        color ? `${SECTION_ANCHOR_BLOCK}--${color}` : null,
        isCursorAlias && `${SECTION_ANCHOR_BLOCK}--cursor-alias`,
        shouldSectionAnchorDisabled && `${SECTION_ANCHOR_BLOCK}--disabled`,
      )}
      id={id}
      name={name}
      onClick={() => handleElementClick(sectionId, color)}
    >
      {/* TODO: Add logic in the future so that technical sections 
 do not have this icon showing. We likely only want it to be shown for 
 non-technical sections. BE work is needed to make this check more robust 
 and scalable however. */}
      {renderIcon()}
      {/* If this class name is changed "__section-reviewers", then we must change it in 
      section-content-component.scss */}
      <div className={`${SECTION_ANCHOR_BLOCK}__section-reviewers`}>
        {sectionReviewTooltip()}
      </div>
      <div className={`${SECTION_ANCHOR_BLOCK}__section-assignments`}>
        {sectionAssignments.sectionAssignments
          .filter(
            (assignments) =>
              assignments.sectionId === sectionId &&
              shouldAssignmentInitialsBeRendered(
                assignments.userId,
                assignments.sectionId,
              ),
          )
          .map((assignment, index) => (
            <ToolTip
              key={`${assignment.sectionId}-user-${assignment.userId}-assignment-tooltip-id`}
              id={`${SECTION_ANCHOR_BLOCK_ID}__section-review-tooltip-${assignment.sectionId}-${assignment.userId}`}
              className={`${SECTION_ANCHOR_BLOCK}__section-review-tooltip`}
              text={
                <FormattedMessage
                  id={'section-details.assigned-user.tooltip'}
                  values={{
                    userName: getUserNameByUserId(assignment.userId),
                    date: moment(assignment.date).format(
                      DATE_FORMATS.MONTH_DAY_YEAR,
                    ),
                    time: moment(assignment.date).format(
                      DATE_FORMATS.HOURS_MINUTES,
                    ),
                  }}
                />
              }
              isNotInternationalized
            >
              <div
                className={classnames(
                  `${SECTION_ANCHOR_BLOCK}__assigned-reviewer`,
                  assignment.sectionId in sectionReview.sectionReviewsMap &&
                    index === 0 &&
                    `${SECTION_ANCHOR_BLOCK}__assigned-reviewer--with-comma-prefix`,
                )}
              >
                {getInitials(getUserNameByUserId(assignment.userId))}
              </div>
            </ToolTip>
          ))}
      </div>
      {children}
    </button>
  ) : null;
};

SectionAnchor.defaultProps = {
  shouldRenderIcon: true,
};

SectionAnchor.propTypes = {
  /** unique html id for this section anchor (should be CFTO_SECTION_XXXXXXX) */
  id: PropTypes.string.isRequired,
  /** Unique number id for this section */
  sectionId: PropTypes.number.isRequired,
  /** name attribute for section anchor, NOTE: probably can be filtered/deleted if we decide we don't need it */
  name: PropTypes.string,
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList).isRequired,
  /** boolean to render section anchor icon */
  shouldRenderIcon: PropTypes.bool,
  /** A reference to the assignments of the selected section */
  sectionAssignments: PropTypes.instanceOf(SectionAssignmentsList).isRequired,
  /** this function is used mediator to have handle API call triggering in side by side view */
  sectionReviewTooltip: PropTypes.func,
  /** this function is used mediator to have handle API call triggering in side by side view */
  handleElementClick: PropTypes.func.isRequired,
};

export default SectionAnchor;
