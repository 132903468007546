import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';
import RichTextEditor from 'components/common/rich-text-editor-component';
import ConditionalRender from 'components/util/conditional-render-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import TextInput from 'components/common/text-input-component';
import ElementDetails from '../../../../models/api/element-details-api-model';
import { checkIfStringContainsOnlySpaces } from 'constants/common/feature-common-utils';
import { updateElementComfortLetterRequest } from 'api/comfort-letter-api';
import ComfortLetterForm from 'models/form/comfort-letter-form-model';
export const CREATE_COMFORT_LETTER_BLOCK = 'create-comfort-letter-modal';
export const CREATE_COMFORT_LETTER_ID_BLOCK = 'create-comfort-letter-modal-id';

export const EditComfortLetterModal = ({
  comfortLetterToEdit,
  onCancel,
  elementDetails,
  onSave,
}) => {
  const [comfortLetterInProgress, setComfortLetterInProgress] = React.useState(
    new ComfortLetterForm({ data: { ...comfortLetterToEdit } }),
  );

  const _handleSave = async () => {
    setComfortLetterInProgress((comfortLetterPrev) =>
      comfortLetterPrev.setLoading(),
    );
    try {
      await updateElementComfortLetterRequest({
        revisionId: elementDetails.revisionId,
        comfortLetter: comfortLetterInProgress,
      });
      onSave();
    } catch (error) {
      if (_isErrorForDuplicateDescription(error)) {
        setComfortLetterInProgress((comfortLetterPrev) =>
          comfortLetterPrev.setDuplicateDescriptionLoaded({
            duplicate: error.response.data.rootCause,
          }),
        );
      } else if (_isErrorForDuplicateLabel(error)) {
        setComfortLetterInProgress((comfortLetterPrev) =>
          comfortLetterPrev.setDuplicateLabelLoaded({
            duplicate: error.response.data.rootCause,
          }),
        );
      } else {
        setComfortLetterInProgress((comfortLetterPrev) =>
          comfortLetterPrev.setError(error),
        );
      }
    }
  };

  const _isErrorForDuplicateDescription = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause &&
    error.response.data.rootCause.includes(
      'ValidationException: A comfort letter annotation with this description already exists.', // distinct error message from API
    );

  const _isErrorForDuplicateLabel = (error) =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.rootCause &&
    error.response.data.rootCause.includes(
      'A comfort letter annotation with this label already exists.', // distinct error message from API
    );

  const _customLabelErrorText = () => {
    if (comfortLetterInProgress.isCustomLabelDuplicate())
      return 'create-comfort-letter.custom-label.error.duplicate';
    else if (
      comfortLetterInProgress.isCustomLabelTooLong() &&
      !checkIfStringContainsOnlySpaces(comfortLetterInProgress.customLabel)
    )
      return 'create-comfort-letter.custom-label.error.text-too-long';
  };

  const _descriptionErrorText = () => {
    if (comfortLetterInProgress.isDescriptionDuplicate())
      return 'create-comfort-letter.description.error.duplicate';
    else if (
      comfortLetterInProgress.isDescriptionTooLong() &&
      !checkIfStringContainsOnlySpaces(comfortLetterInProgress.description)
    )
      return 'create-comfort-letter.description.error.text-too-long';
  };

  const _isModified = () => {
    return (
      comfortLetterInProgress &&
      comfortLetterToEdit &&
      (comfortLetterInProgress.descriptionRichText !==
        comfortLetterToEdit.descriptionRichText ||
        comfortLetterInProgress.customLabel !== comfortLetterToEdit.customLabel)
    );
  };

  React.useEffect(() => {
    setComfortLetterInProgress(
      comfortLetterInProgress.initFromElement({
        elementDetails,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementDetails.revisionId]);

  React.useEffect(() => {
    _isModified() &&
      comfortLetterInProgress.isDescriptionDuplicate() &&
      setComfortLetterInProgress((comfortLetterPrev) =>
        comfortLetterPrev.setDuplicateDescriptionLoaded({
          duplicate: undefined,
        }),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comfortLetterInProgress.descriptionRichText]);

  const handleComfortLetterDescription = (val) => {
    setComfortLetterInProgress((comfortLetterPrev) =>
      comfortLetterPrev.setDescription(val),
    );
  };

  return (
    <Modal
      id={CREATE_COMFORT_LETTER_ID_BLOCK}
      title={'edit-comfort-letter-modal.title'}
      onClose={onCancel}
      primaryModalButton={
        new ModalButton({
          text: 'common.save',
          onClick: _handleSave,
          disabled:
            comfortLetterInProgress.isDescriptionTooShort() ||
            comfortLetterInProgress.isCustomLabelTooShort() ||
            !comfortLetterInProgress.isComfortLetterValid() ||
            !_isModified(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onCancel,
        })
      }
    >
      <ConditionalRender dependencies={[comfortLetterInProgress]}>
        <RichTextEditor
          className={`${CREATE_COMFORT_LETTER_BLOCK}__text`}
          id={`${CREATE_COMFORT_LETTER_ID_BLOCK}-text`}
          onChange={handleComfortLetterDescription}
          errorText={_descriptionErrorText()}
          placeholder={'create.comfort.letter.modal.palceholder.description'}
          width={'100%'}
          isValid={comfortLetterInProgress.isDescriptionValid()}
          label={'create.comfort-letter.modal.label.description'}
          initContents={comfortLetterInProgress.descriptionRichText}
        />
        <div className={`${CREATE_COMFORT_LETTER_BLOCK}__custom-label`}>
          <TextInput
            id={`${CREATE_COMFORT_LETTER_ID_BLOCK}-custom-label`}
            isValid={
              comfortLetterInProgress.isCustomLabelValid() ||
              // string containing only white spaces is an exception scenario. We dont want to show any error message for that
              // instead create button will be disabled.
              checkIfStringContainsOnlySpaces(
                comfortLetterInProgress.customLabel,
              )
            }
            label="create.comfort-letter.modal.custom-label"
            onChange={(val) =>
              setComfortLetterInProgress((comfortLetterPrev) =>
                comfortLetterPrev
                  .setCustomLabel(val)
                  .setDuplicateLabelLoaded({ duplicate: undefined }),
              )
            }
            placeholder="create-comfort-letter.form.label.placeholder"
            width={'100%'}
            type={'text'}
            errorText={_customLabelErrorText()}
            value={comfortLetterInProgress.customLabel}
          />
        </div>
      </ConditionalRender>
    </Modal>
  );
};

EditComfortLetterModal.propTypes = {
  /** Object of the selected comfort letter to edit */
  comfortLetterToEdit: PropTypes.instanceOf(ComfortLetterForm).isRequired,
  /**function to close the edit comfort letter modal */
  onCancel: PropTypes.func.isRequired,
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** func fired on save click */
  onSave: PropTypes.func.isRequired,
};
