import { push } from 'connected-react-router';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { updateSelectedProjectFromID } from 'store/actions/selected-project-actions';

export const finishStatementEdit = ({ project }) => async (dispatch) => {
  //Set the project we just edited a statement for as the currently selected project
  dispatch(
    updateSelectedProjectFromID({
      projectId: project.id,
    })
  );
  // Navigate to the home page
  dispatch(push(ROUTE_CONSTANTS.HOME_PAGE));
};
