import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import ElementWorkpaperList from 'models/api/element-workpaper-list-model';
import ConditionalRender from 'components/util/conditional-render-component';
import WorkpaperEntryWithDetach from 'components/feature/element-panel/annotations/workpaper-entry/workpaper-entry-with-detach-component';
import Permissions from 'permissions/permissions';

const WORKPAPER_LIST_BLOCK = 'annotations-workpaper-list';
const WORKPAPER_LIST_ID_BLOCK = 'workpaper-list-id';

class WorkpaperList extends Component {
  render() {
    const { onAddLink, workpaperList, onDetach, selectedProjectId, readOnly } =
      this.props;

    const _permissionToEdit =
      Permissions.WorkpaperReference.canEditWorkpaperRef(selectedProjectId);

    return (
      <div className={WORKPAPER_LIST_BLOCK}>
        <div className={`${WORKPAPER_LIST_BLOCK}__button-container`}>
          {!readOnly && (
            <Button
              id={`${WORKPAPER_LIST_ID_BLOCK}-workpaper-new-button`}
              className={`${WORKPAPER_LIST_BLOCK}__workpaper-new-button`}
              type={BUTTON_TYPES.primary}
              onClick={onAddLink}
              disabled={!_permissionToEdit}
            >
              <FormattedMessage
                id={'element-panel.annotation.content.workpaper.primary-button'}
              />
            </Button>
          )}
        </div>
        <div className={`${WORKPAPER_LIST_BLOCK}__listing-container`}>
          <ConditionalRender dependencies={[workpaperList]}>
            {workpaperList.hasWorkpapers() ? (
              workpaperList.workpapers.map((workpaper) => (
                <WorkpaperEntryWithDetach
                  key={workpaper.referenceNumber}
                  workpaper={workpaper}
                  onDetach={onDetach}
                  disabled={!_permissionToEdit || readOnly}
                />
              ))
            ) : (
              <div className={`${WORKPAPER_LIST_BLOCK}__no-workpapers`}>
                <FormattedMessage
                  id={'element-panel.annotation.content.no-workpapers'}
                />
              </div>
            )}
          </ConditionalRender>
        </div>
      </div>
    );
  }
}

WorkpaperList.propTypes = {
  /** Action fired when user clicks the add link button */
  onAddLink: PropTypes.func.isRequired,
  /** List of workpapers associated with this element */
  workpaperList: PropTypes.instanceOf(ElementWorkpaperList).isRequired,
  /** action fired to detach a work paper from this element */
  onDetach: PropTypes.func.isRequired,
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
  /** Boolean for read only mode */
  readOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
  },
}) => ({
  selectedProjectId: id,
});

const mapDispatchToProps = () => ({});

export { WorkpaperList };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(WorkpaperList));
