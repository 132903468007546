import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'utils/object-utils';
import { shouldShowMoreButton } from 'constants/feature/tickmark-constants';

export const TICKMARK_ENTRY_BLOCK = 'tickmark-entry';
export const TICKMARK_ENTRY_ID_BLOCK = 'tickmark-entry-id';
export const TICKMARK_TEXT_CHAR_LENGTH = 150;
export const TICKMARK_TEXT_LINES_LENGTH = 3;

const TickmarkEntry = ({
  tickmark,
  rightComponent,
  bottomComponent,
  absoluteTopRightComponent,
  className,
  currentlyExpandedTickmarkID,
  updateExpandedTickmarkID,
}) => {
  const [toggleShowMore, setToggleShowMore] = React.useState(true);
  const [isShowLessBtnVisible, setShowLessBtnVisible] = React.useState(false);
  const [refValue, setRefValue] = React.useState(null);
  const [clamped, setClamped] = React.useState(false);

  const tickmarkTextRef = React.useRef(null);

  const toggleShowMoreClicked = (toggleStatus) => {
    setToggleShowMore(toggleStatus);
    updateExpandedTickmarkID &&
      updateExpandedTickmarkID(
        toggleStatus,
        tickmark.tickmarkId,
        tickmarkTextRef,
      );
  };

  /**based on toggleShowMore value it will trigger updateExpandedTickmarkID callback method send latest reference element to 
  Parent class to get clientHeight or offSetHeight value of selected element, so that the value can be used to update height 
  in virtualized list component div elements to extended scroll bar and to view complete description of selected tickmarsk*/
  useEffect(() => {
    if (toggleShowMore === false) {
      updateExpandedTickmarkID &&
        updateExpandedTickmarkID(
          toggleShowMore,
          tickmark.tickmarkId,
          tickmarkTextRef,
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleShowMore]);

  useEffect(() => {
    if (
      Number.isInteger(currentlyExpandedTickmarkID) &&
      !toggleShowMore &&
      currentlyExpandedTickmarkID !== tickmark.tickmarkId
    ) {
      setToggleShowMore(true);
      setShowLessBtnVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickmark.tickmarkId, currentlyExpandedTickmarkID]);

  useEffect(() => {
    if (
      Number.isInteger(currentlyExpandedTickmarkID) &&
      toggleShowMore &&
      currentlyExpandedTickmarkID === tickmark.tickmarkId
    ) {
      setToggleShowMore(false);
      setShowLessBtnVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickmark.tickmarkId, currentlyExpandedTickmarkID]);

  useEffect(() => {
    setRefValue(tickmarkTextRef);
  }, []);

  useEffect(() => {
    if (
      shouldShowMoreButton({
        textRef: refValue && refValue.current,
      })
    ) {
      setClamped(true);
    } else {
      if (!isShowLessBtnVisible) {
        setClamped(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refValue, tickmark]);

  const onShowMoreBtnClick = () => {
    toggleShowMoreClicked(false);
    setShowLessBtnVisible(true);
  };

  const onShowLessBtnClick = () => {
    toggleShowMoreClicked(true);
    setShowLessBtnVisible(false);
  };

  return (
    <div
      key={tickmark.tickmarkId}
      className={classnames(`${TICKMARK_ENTRY_BLOCK}`, className)}
      id={`${TICKMARK_ENTRY_ID_BLOCK}-${tickmark.tickmarkId}`}
    >
      <div className={`${TICKMARK_ENTRY_BLOCK}__top`}>
        <div className={`${TICKMARK_ENTRY_BLOCK}__info`}>
          <span className={`${TICKMARK_ENTRY_BLOCK}__ordinal`}>
            {`{${tickmark.noteOrdinal}}`}
          </span>
          <span
            className={classnames(
              `${TICKMARK_ENTRY_BLOCK}__body`,
              !toggleShowMore && clamped
                ? `${TICKMARK_ENTRY_BLOCK}__body--show-less`
                : null,
            )}
            ref={tickmarkTextRef}
            dangerouslySetInnerHTML={{ __html: tickmark.richText }}
          />
          {clamped &&
            (toggleShowMore ? (
              <Button
                id={`${TICKMARK_ENTRY_ID_BLOCK}-show-more-button`}
                className={`${TICKMARK_ENTRY_BLOCK}__show-more-button`}
                type={BUTTON_TYPES.tertiary}
                onClick={onShowMoreBtnClick}
              >
                <FormattedMessage id={'common.show-more'} />
              </Button>
            ) : (
              <Button
                id={`${TICKMARK_ENTRY_ID_BLOCK}-show-less-button`}
                className={`${TICKMARK_ENTRY_BLOCK}__show-less-button`}
                type={BUTTON_TYPES.tertiary}
                onClick={onShowLessBtnClick}
              >
                <FormattedMessage id={'common.show-less'} />
              </Button>
            ))}
        </div>
        {!isNullOrUndefined(rightComponent) && (
          <div
            className={classnames(
              `${TICKMARK_ENTRY_BLOCK}__actions`,
              !isNullOrUndefined(className) ? `${className}__actions` : null,
            )}
          >
            {rightComponent}
          </div>
        )}
      </div>
      {!isNullOrUndefined(bottomComponent) && (
        <div
          className={classnames(
            `${TICKMARK_ENTRY_BLOCK}__bottom`,
            !isNullOrUndefined(className) ? `${className}__bottom` : null,
          )}
        >
          {bottomComponent}
        </div>
      )}
      {!isNullOrUndefined(absoluteTopRightComponent) && (
        <div
          className={classnames(
            `${TICKMARK_ENTRY_BLOCK}__top-right`,
            !isNullOrUndefined(className) ? `${className}__top-right` : null,
          )}
        >
          {absoluteTopRightComponent}
        </div>
      )}
    </div>
  );
};

TickmarkEntry.propTypes = {
  /** Tickmark object from the tickmark list endpoint */
  tickmark: PropTypes.object.isRequired,
  /** Component placed absolutely in top right corner of entry */
  absoluteTopRightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Component used to children to the right of the main content, displayed as flex container with content*/
  rightComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Component used to place any nodes below the normal entry component*/
  bottomComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Custom classname applied to entry */
  className: PropTypes.string,
  /* function to set the currently expanded tickmark id after clicking Show More button */
  updateExpandedTickmarkID: PropTypes.func,
  /** Tickmark id of the tickmark expanded on the toolkit panel after clicking Show More button */
  currentlyExpandedTickmarkID: PropTypes.number,
};

export default memo(TickmarkEntry);
