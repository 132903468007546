import { createAction } from 'redux-actions';
import { refetchSectionsInView, refetchBlacklineView } from './statement-content-actions';
import { showComparePanelAction } from './panel-controller-actions';

export const toggleBlacklineViewMode = createAction('SET_BLACKLINE_VIEW_MOD');

export const toggleBlacklineViewModeAndRefetchSectionsInView = () => async (
  dispatch,
  getState,
) => {
    await dispatch(toggleBlacklineViewMode());

    if (getState().ui.statementPage.modes.blacklineViewMode) {
      dispatch(showComparePanelAction());
      dispatch(refetchBlacklineView());
    }

    dispatch(refetchSectionsInView());
  };
