import { createAction } from 'redux-actions';

export const addToCurrentSectionIds = createAction('ADD_TO_CURRENT_SECTIONS');
export const removeFromCurrentSectionIds = createAction(
  'REMOVE_FROM_CURRENT_SECTIONS',
);

export const addToLeftCurrentSectionIds = createAction(
  'ADD_TO_LEFT_CURRENT_SECTIONS',
);
export const removeFromLeftCurrentSectionIds = createAction(
  'REMOVE_FROM_LEFT_CURRENT_SECTIONS',
);
