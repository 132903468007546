import ApiModel from 'models/api-model';
import { MenuOption } from 'models/utils/common/menu/menu-option-model';
import { isNullOrUndefined } from 'utils/object-utils';

export class PeriodList extends ApiModel({
  data: {
    periods: [], //This stores a raw copy of the periods array response we get from the back-end.
    periodsFlattened: [], // flattened list of valid period properties
    periodsDropdownMap: {}, //This stores a filtered map of MenuOptions for selecting periods on the front-end.
    periodFilterList: [], //distinct periods for the statement to display in filter
  },
  status: null,
}) {
  get periodsFlattened() {
    if (
      !isNullOrUndefined(this.data.periodsFlattened) &&
      Array.isArray(this.data.periodsFlattened)
    ) {
      return this.data.periodsFlattened;
    }
    return [];
  }

  get periodFilterList() {
    if (
      !isNullOrUndefined(this.data.periodFilterList) &&
      Array.isArray(this.data.periodFilterList)
    ) {
      return this.data.periodFilterList;
    }
    return [];
  }

  processResponse({ response }) {
    let periodMenuOptions = {};
    let periodsFlattened = [];
    /**
     * We have to build up an array of periods that can be selected for elements on the statement.
     * The response is formatted like:
     *
     * [{years: "FY 2015", period1: "Q1 2015", period2: "Q2 2015" ...}, {years: "FY 2014", period1: "Q1 2014", ...}]
     * where we only care about the values inside this nested map, so we extract these for the dropdown. Some of the values are
     * null inside this nested map too, so they are skipped.
     */
    if (
      !isNullOrUndefined(response.data) &&
      !isNullOrUndefined(response.data.result) &&
      !isNullOrUndefined(response.data.result.periodEntityList)
    ) {
      let index = 1;
      response.data.result.periodEntityList.forEach((year) => {
        Object.values(year).forEach((period) => {
          if (!isNullOrUndefined(period)) {
            periodsFlattened.push(period);
            periodMenuOptions[period] = new MenuOption({
              id: index,
              title: period,
              value: period,
              isIntl: false,
            });
            index++;
          }
        });
      });
    }
    return {
      data: {
        periods: response.data.result.periodEntityList,
        periodsFlattened,
        periodsDropdownMap: periodMenuOptions,
        periodFilterList: response.data.result.periodFilterList,
      },
      status: response.status,
    };
  }

  hasPeriodDropdownMap() {
    return !isNullOrUndefined(this.data.periodsDropdownMap);
  }

  getPeriodDropdownMap() {
    if (this.hasPeriodDropdownMap()) {
      return this.data.periodsDropdownMap;
    }
    return null;
  }

  hasPeriodDropdownValue(period) {
    return (
      this.hasPeriodDropdownMap() &&
      !isNullOrUndefined(this.getPeriodDropdownValue(period))
    );
  }

  getPeriodDropdownValue(period) {
    if (this.hasPeriodDropdownMap()) {
      return this.data.periodsDropdownMap[period];
    }
    return null;
  }

  getPeriodDropdownValues() {
    return Object.values(this.data.periodsDropdownMap);
  }
}
