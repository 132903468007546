import { handleActions } from 'redux-actions';
import { setDisplayOptionAction } from 'store/actions/statement-summary/statement-summary-actions';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';

export default handleActions(
  {
    [setDisplayOptionAction]: (statementSumDisplayOption, { payload }) => {
      return statementSumDisplayOption.setDisplayOption(payload);
    },
  },
  new StatementSummaryDisplayOptions(),
);
