import { connect } from 'react-redux';

import {
  getInternalReferenceWithoutLoading,
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  addElementToInternalReference,
} from 'store/actions/internal-reference-actions';
import {
  selectElementContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';

import InternalReferenceContent from 'components/feature/element-panel/internal-reference-panel/_internal-reference-panel-content-component';
import { updateWorkpaper } from '../../../store/actions/workpaper-actions';
import { attachTickmarkToElement } from '../../../store/actions/element-panel-actions';
import { getElementInternalReference } from 'store/actions/internal-reference-actions';
import { checkIfFeatureFlagEnabled } from 'store/actions/selected-statement-actions';

const mapStateToProps = (
  {
    data: {
      elementPanel: { elementDetails },
      internalReferencePanel: { internalReference },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  elementDetails,
  internalReference,
});
const mapDispatchToProps = {
  getInternalReferenceWithoutLoading,
  selectElementFromInternalReference,
  detachElementFromInternalReference,
  addSuggestionToInternalReference: addElementToInternalReference,
  selectElementContentPanel,
  deallocateItems,
  updateWorkpaper,
  attachTickmarkToElement,
  getElementInternalReference,
  checkIfFeatureFlagEnabled,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternalReferenceContent);
