import { handleActions } from 'redux-actions';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';

export default handleActions(
  {
    [setSelectedTabAction]: (tab, { payload }) => {
      return payload;
    },
  },
  STATEMENT_NAV_TABS.headings
);
