import BaseModel from 'models/base-model';

export default class ContentViewModesModel extends BaseModel({
  contentFullMode: false,
  // We currently do not need to persist but leaving this here incase we change our mind
  // ...JSON.parse(localStorage.getItem('viewModes')),
}) {
  merge(mergeProps) {
    const nextModel = new this.constructor({ ...this, ...mergeProps });
    // localStorage.setItem('viewModes', JSON.stringify(nextModel));
    return nextModel;
  }

  setContentFixedMode() {
    return this.merge({ contentFullMode: false });
  }

  setContentFullMode() {
    return this.merge({ contentFullMode: true });
  }
}
