import { handleActions } from 'redux-actions';
import {
  clearSectionContentWithIdsFromCache,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';

import {
  notesCacheSectionLoading,
  notesCacheSectionLoaded,
  notesCacheSectionError,
  removeNoteFromCacheWithSocketPayloadAction,
  updateNoteFromNoteCacheWithSocketPayloadAction,
} from 'store/actions/notes-cache-actions';
import NoteCache from 'models/data/note-cache-model';

export default handleActions(
  {
    [notesCacheSectionLoading]: (noteCache, { payload }) => {
      return noteCache.setSectionLoading(payload);
    },
    [notesCacheSectionLoaded]: (noteCache, { payload }) => {
      return noteCache.setSectionLoaded(payload);
    },
    [notesCacheSectionError]: (noteCache, { payload }) => {
      return noteCache.setSectionError(payload);
    },
    /** External listeners */
    [clearStatementPageStoreAction]: (noteCache, { payload }) => {
      return new NoteCache();
    },
    [clearSectionContentWithIdsFromCache]: (noteCache, { payload }) => {
      return noteCache.clearSections(payload);
    },
    [removeNoteFromCacheWithSocketPayloadAction]: (noteCache, { payload }) => {
      return noteCache.removeNoteFromCacheFromSocketPayload(payload);
    },
    [updateNoteFromNoteCacheWithSocketPayloadAction]: (
      noteCache,
      { payload },
    ) => {
      return noteCache.updateNoteFromCacheFromSocketpayload(payload);
    },
  },
  new NoteCache(),
);
