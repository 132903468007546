import React from 'react';
import { connect } from 'react-redux';
import ZoomControl from 'components/common/zoom-control-component';
import {
  incrementZoom,
  decrementZoom,
  leftIncrementZoom,
  leftDecrementZoom,
  rightIncrementZoom,
  rightDecrementZoom,
} from 'store/actions/zoom-actions';
import {
  setContentFixedMode,
  setContentFullMode,
} from 'store/actions/content-view-modes-actions';

export const ZoomControlHOC = (props) => {
  const {
    zoom,
    leftZoom,
    leftDecrementZoom,
    leftIncrementZoom,
    incrementZoom,
    decrementZoom,
    isLeftSideView,
    isSideBySidePage,
    rightIncrementZoom,
    rightDecrementZoom,
    rightZoom,
  } = props;

  return (
    <ZoomControl
      {...props}
      zoomModel={
        isSideBySidePage ? (isLeftSideView ? leftZoom : rightZoom) : zoom
      }
      onPlus={
        isSideBySidePage
          ? isLeftSideView
            ? leftIncrementZoom
            : rightIncrementZoom
          : incrementZoom
      }
      onMinus={
        isSideBySidePage
          ? isLeftSideView
            ? leftDecrementZoom
            : rightDecrementZoom
          : decrementZoom
      }
    />
  );
};

const mapStateToProps = ({
  data: {
    copyFormula: { copiedFormula },
  },
  ui: {
    statementPage: {
      zoom,
      leftZoom,
      rightZoom,
      modes: { contentViewMode },
    },
  },
}) => ({
  contentViewMode,
  zoom,
  leftZoom,
  rightZoom,
  isCopiedFormula:
    copiedFormula &&
    copiedFormula.data &&
    copiedFormula.data.formulas &&
    copiedFormula.data.formulas.length > 0,
});

const mapDispatchToProps = {
  setContentFixedMode,
  setContentFullMode,
  leftIncrementZoom,
  leftDecrementZoom,
  incrementZoom,
  decrementZoom,
  rightIncrementZoom,
  rightDecrementZoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoomControlHOC);
