import { getStatementSummaryNotesRequest } from 'api/statement-summary-api';
import { createAction } from 'redux-actions';

export const statementSummaryNotesLoading = createAction(
  'STATEMENT_SUMMARY_NOTES_LOADING',
);
export const statementSummaryNotesLoaded = createAction(
  'STATEMENT_SUMMARY_NOTES_LOADED',
);
export const statementSummaryNotesError = createAction(
  'STATEMENT_SUMMARY_NOTES_ERROR',
);
export const updateSummaryNotesFromWebsocket = createAction(
  'UPDATE_SUMMARY_NOTES_FROM_WEBSOCKET',
);

export const fetchStatementSummaryNotes = ({ revisionId }) => (dispatch) => {
  dispatch(statementSummaryNotesLoading());
  getStatementSummaryNotesRequest({ revisionId })
    .then((response) => {
      dispatch(statementSummaryNotesLoaded({ response }));
    })
    .catch((error) => {
      dispatch(statementSummaryNotesError({ error }));
    });
};
