import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ElementTickmarkList from 'models/api/element-tickmark-list-model';
import TickmarkDetachEntry from 'components/feature/element-panel/annotations/tickmark-entry/tickmark-entry-with-detach-component';
import ConditionalRender from 'components/util/conditional-render-component';

export const ALREADY_LINKED_TICKMARKS_BLOCK = 'already-attached-tickmarks';

const AlreadyAttachedTickmarks = ({
  tickmarkList,
  onDetachTickmark,
  disabled,
}) => {
  const [currentTickmarkId, setCurrentTickmarkId] = useState(undefined);
  const updateExpandedTickmarkID = (toggleStatus, id) => {
    !toggleStatus && setCurrentTickmarkId(id);
  };
  return (
    <div className={ALREADY_LINKED_TICKMARKS_BLOCK}>
      <ConditionalRender dependencies={[tickmarkList]}>
        <h4 className={`${ALREADY_LINKED_TICKMARKS_BLOCK}__title`}>
          <FormattedMessage id="element-tickmark-panel.already-attached-section.title" />
        </h4>
        {tickmarkList.hasTickmarks() ? (
          tickmarkList.tickmarks.map((tickmark) => (
            <TickmarkDetachEntry
              key={tickmark.tickmarkId}
              tickmark={tickmark}
              onDetach={onDetachTickmark}
              disabled={disabled}
              currentlyExpandedTickmarkID={currentTickmarkId}
              updateExpandedTickmarkID={updateExpandedTickmarkID}
            />
          ))
        ) : (
          <div className={`${ALREADY_LINKED_TICKMARKS_BLOCK}__no-results`}>
            <FormattedMessage id="element-tickmark-panel.already-attached-section.no-results" />
          </div>
        )}
      </ConditionalRender>
    </div>
  );
};

AlreadyAttachedTickmarks.propTypes = {
  /** List of tickmarks associated with this element */
  tickmarkList: PropTypes.instanceOf(ElementTickmarkList).isRequired,
  /** action fired to detach a tickmark from this element */
  onDetachTickmark: PropTypes.func.isRequired,
};

export default memo(AlreadyAttachedTickmarks);
