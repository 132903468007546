import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const createOrUpdateElementWorkpaperRequest = ({ workpaperModel }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${
      workpaperModel.revisionId
    }/elements/${workpaperModel.elementId}/wprefs`,
    workpaperModel.toApiFormat(),
  );

export const createWorkpaperRequest = ({ workpaperModel }) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${
      workpaperModel.revisionId
    }/wprefs`,
    workpaperModel.toApiFormat(),
  );

export const getWorkpapersForElementRequest = ({ elementId, revisionId }) =>
  withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/wprefs`,
  );

export const detachElementWorkpaperRequest = ({
  elementId,
  revisionId,
  workpaperId,
}) =>
  withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/elements/${elementId}/wprefs/${workpaperId}`,
  );

export const getWorkpaperReferencesRequest = ({
  referenceNumber,
  revisionId,
}) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/wprefs`,
      {
        params: { refNumber: encodeURIComponent(referenceNumber) },
      },
    )
    .then((response) => {
      return response;
    });

export const getWorkpapersForRevisionRequest = ({ revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/wpreferences`,
    )
    .then((response) => {
      return response;
    });

export const updateWorkpaperRequest = ({ revisionId, workpaper }) =>
  withDefaultContextAsSelectedProject().put(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/wprefs/${
      workpaper.workpaperRefId
    }`,
    workpaper.toEditApiFormat(),
  );

export const deleteWorkpaperRequest = ({ workpaper }) => {
  const { revisionId, workpaperRefId } = workpaper;
  return withDefaultContextAsSelectedProject().delete(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/wprefs/${workpaperRefId}`,
  );
};
