import { handleActions } from 'redux-actions';
import {
  projectListLoading,
  projectListError,
  projectListLoaded,
  softDeleteProjectError,
  restoreDeletedProjectError,
  permanentlyDeleteProjectError,
  clearProjectList,
  setGeoToFilterProjectList,
  setAppliedSearchTermToFilterProjectList,
  clearAppliedSearchTermToFilterProjectList,
  setMergeSelectedProjectWithProjectList,
} from 'store/actions/project-list-actions';
import { ProjectList } from 'models/api/project-list-model';

export default handleActions(
  {
    [clearProjectList]: () => {
      return new ProjectList();
    },
    [projectListLoading]: (projectList) => {
      return projectList.setLoading();
    },
    [projectListError]: (projectList, { payload }) => {
      return projectList.setError(payload);
    },
    [projectListLoaded]: (projectList, { payload }) => {
      return projectList.setLoaded(payload);
    },
    [softDeleteProjectError]: (projectList, { payload }) => {
      return projectList.setError(payload);
    },
    [restoreDeletedProjectError]: (projectList, { payload }) => {
      return projectList.setError(payload);
    },
    [permanentlyDeleteProjectError]: (projectList, { payload }) => {
      return projectList.setError(payload);
    },
    [setGeoToFilterProjectList]: (projectList, { payload }) => {
      return projectList.setGeoToFilterProjectList(payload);
    },
    [setAppliedSearchTermToFilterProjectList]: (projectList, { payload }) => {
      return projectList.setSearchTermToFilterProjectList(payload);
    },
    [clearAppliedSearchTermToFilterProjectList]: (projectList) => {
      return projectList.clearSearchTermToFilterProjectList();
    },
    [setMergeSelectedProjectWithProjectList]: (projectList, { payload }) => {
      return projectList.mergeSelectedProjectWithProjectList(payload);
    },
  },
  new ProjectList(),
);
