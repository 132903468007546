import { RadioOption } from 'models/utils/common/radio-options-model';

export const UNSELECTED_FILTER = undefined;
export const API_BOOLS = {
  TRUE: 1,
  FALSE: 0,
};
export const isFilterUnselected = (filter) => filter === UNSELECTED_FILTER;

const STATUS_VERIFICATION_OPTIONS = {
  verified: new RadioOption({
    id: 'verification-verified-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.verification.verified',
    value: true,
  }),
  unverified: new RadioOption({
    id: 'verification-unverified-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.verification.unverified',
    value: false,
  }),
  all: new RadioOption({
    id: 'verification-all-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.verification.all',
    value: UNSELECTED_FILTER,
  }),
};
export const STATUS_VERIFICATION_OPTIONS_ARRAY = Object.values(
  STATUS_VERIFICATION_OPTIONS,
);

const STATUS_REVIEW_OPTIONS = {
  review: new RadioOption({
    id: 'review-review-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.review.review',
    value: true,
  }),
  unreview: new RadioOption({
    id: 'review-unreview-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.review.unreview',
    value: false,
  }),
  all: new RadioOption({
    id: 'review-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.review.all',
    value: UNSELECTED_FILTER,
  }),
};
export const STATUS_REVIEW_OPTIONS_ARRAY = Object.values(STATUS_REVIEW_OPTIONS);

const STATUS_COMFORT_ASSIGNED_OPTIONS = {
  comfortassigned: new RadioOption({
    id: 'comfort-assigned-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotation.assigned',
    value: true,
  }),
  comfortnotassigned: new RadioOption({
    id: 'comfort-not-assigned-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotation.notassigned',
    value: false,
  }),
  all: new RadioOption({
    id: 'comfort-annotation-all-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotation.all',
    value: UNSELECTED_FILTER,
  }),
};
export const STATUS_COMFORT_ASSIGNED_OPTIONS_ARRAY = Object.values(
  STATUS_COMFORT_ASSIGNED_OPTIONS,
);

const STATUS_FLAG_OPTIONS = {
  all: new RadioOption({
    id: 'flag-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.flag.all',
    value: UNSELECTED_FILTER,
  }),
  unflag: new RadioOption({
    id: 'flag-unflag-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.flag.unflag',
    value: false,
  }),
  flag: new RadioOption({
    id: 'flag-flag-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.flag.flag',
    value: true,
  }),
};
export const STATUS_FLAG_OPTIONS_ARRAY = Object.values(STATUS_FLAG_OPTIONS);

const ANNOTATION_FORMULA_OPTIONS = {
  with: new RadioOption({
    id: 'formula-with-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.formula.with',
    value: true,
  }),
  without: new RadioOption({
    id: 'formula-without-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.formula.without',
    value: false,
  }),
  all: new RadioOption({
    id: 'formula-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.formula.all',
    value: UNSELECTED_FILTER,
  }),
};

export const ANNOTATION_FORMULA_OPTIONS_ARRAY = Object.values(
  ANNOTATION_FORMULA_OPTIONS,
);

const ANNOTATION_TICKMARK_OPTIONS = {
  with: new RadioOption({
    id: 'tickmark-with-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.with',
    value: true,
  }),
  without: new RadioOption({
    id: 'tickmark-without-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.without',
    value: false,
  }),
  all: new RadioOption({
    id: 'tickmark-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.all',
    value: UNSELECTED_FILTER,
  }),
};

const COMFORT_ANNOTATIONS_OPTIONS = {
  with: new RadioOption({
    id: 'comfort-annotations-with-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotations.with',
    value: true,
  }),
  without: new RadioOption({
    id: 'comfort-annotations-without-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotations.without',
    value: false,
  }),
  all: new RadioOption({
    id: 'comfort-annotations-all-filter-option-id',
    label:
      'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotations.all',
    value: UNSELECTED_FILTER,
  }),
};

export const ANNOTATION_TICKMARK_OPTIONS_ARRAY = Object.values(
  ANNOTATION_TICKMARK_OPTIONS,
);

export const ANNOTATION_COMFORT_ANNOTATIONS_OPTIONS_ARRAY = Object.values(
  COMFORT_ANNOTATIONS_OPTIONS,
);
const ANNOTATION_IR_OPTIONS = {
  with: new RadioOption({
    id: 'ir-with-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.ir.with',
    value: true,
  }),
  without: new RadioOption({
    id: 'ir-without-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.ir.without',
    value: false,
  }),
  all: new RadioOption({
    id: 'ir-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.ir.all',
    value: UNSELECTED_FILTER,
  }),
};

export const ANNOTATION_IR_OPTIONS_ARRAY = Object.values(ANNOTATION_IR_OPTIONS);

const ANNOTATION_WPR_OPTIONS = {
  with: new RadioOption({
    id: 'wpr-with-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.wpr.with',
    value: true,
  }),
  without: new RadioOption({
    id: 'wpr-without-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.wpr.without',
    value: false,
  }),
  all: new RadioOption({
    id: 'wpr-all-filter-option-id',
    label: 'statement-nav-panel.tab.elements.filters.modal.col-2.wpr.all',
    value: UNSELECTED_FILTER,
  }),
};

export const ANNOTATION_WPR_OPTIONS_ARRAY = Object.values(
  ANNOTATION_WPR_OPTIONS,
);

export const PRIMARY_FILTER_CATEGORIES = {
  VERIFY: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.verification',
    name: 'element-filters-verification-name',
    id: 'element-filters-verification-id',
    options: STATUS_VERIFICATION_OPTIONS_ARRAY,
  },
  REVIEW: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.review',
    name: 'element-filters-review-name',
    id: 'element-filters-review-id',
    options: STATUS_REVIEW_OPTIONS_ARRAY,
  },
  COMFORT_ASSIGNED: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.comfort-assigned',
    name: 'element-filters-comfort-assigned-name',
    id: 'element-filters-comfort-assigned-id',
    options: STATUS_COMFORT_ASSIGNED_OPTIONS_ARRAY,
  },
  FLAG: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.flag',
    name: 'element-filters-parent-flag-name',
    id: 'element-filters-parent-flag-id',
    options: STATUS_FLAG_OPTIONS_ARRAY,
  },
  PERIOD: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.period',
    id: 'element-filters-period-id',
  },
  SCALING: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.scaling',
    id: 'element-filters-scaling-id',
  },
  UNIT: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.unit',
    id: 'element-filters-unit-id',
  },
  ENTITY: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.entity',
    id: 'element-filters-entity-id',
  },
  WPR: {
    heading: 'statement-nav-panel.tab.elements.filters.modal.col-1.heading.wpr',
    id: 'element-filters-wpr-id',
    name: 'element-filters-wpr-name',
    options: ANNOTATION_WPR_OPTIONS_ARRAY,
  },
  IR: {
    heading: 'statement-nav-panel.tab.elements.filters.modal.col-1.heading.ir',
    id: 'element-filters-ir-id',
    name: 'element-filters-ir-name',
    options: ANNOTATION_IR_OPTIONS_ARRAY,
  },
  TICKMARKS: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.tickmarks',
    id: 'element-filters-tickmarks-id',
    name: 'element-filters-tickmarks-name',
    options: ANNOTATION_TICKMARK_OPTIONS_ARRAY,
  },
  COMFORT_ANNOTATIONS: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.comfort-annotations',
    id: 'element-filters-comfort-annotations-id',
    name: 'element-filters-comfort-annotations-name',
    options: ANNOTATION_COMFORT_ANNOTATIONS_OPTIONS_ARRAY,
  },
  FORMULA: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.formulas',
    id: 'element-filters-formulas-id',
    name: 'element-filters-parent-formulas-name',
    options: ANNOTATION_FORMULA_OPTIONS_ARRAY,
  },
  STATEMENT_SEGMENTS: {
    heading:
      'statement-nav-panel.tab.elements.filters.modal.col-1.heading.segment-headings',
    id: 'element-filters-segment-headings-id',
  },
};
