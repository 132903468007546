import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
export default class InternalReferenceSuggestionList extends ApiModel({
  data: {
    suggestions: [],
  },
  status: null,
}) {
  get suggestions() {
    if (!isNullOrUndefined(this.data) && this.data.suggestions.length > 0) {
      return this.data.suggestions;
    }
    return [];
  }
  get count() {
    return this.suggestions.length;
  }
  processResponse(response) {
    if (response && response.data) {
      return {
        data: { suggestions: response.data },
        status: response.status,
      };
    }
  }

  updateSuggestedIRElementsFromListFromSocketPayload(payload) {
    const _elementMap = payload.reduce((elementMap, elementData) => {
      elementMap[elementData.id] = elementData;
      return elementMap;
    }, {});
    const suggestions = [...this.data.suggestions];
    for (let i = 0; i < suggestions.length; i++) {
      const element = suggestions[i];
      if (element.id in _elementMap) {
        suggestions[i] = { ...element, ..._elementMap[element.id] };
      }
    }
    return this.merge({ data: { suggestions } });
  }
  updateSuggestedIRListFromSocketPayload(payload) {
    return this.merge({ data: { suggestions: payload } });
  }

  removeSuggestedIRFromSocketPayload(payload) {
    const _elementMap = payload.reduce((elementMap, elementData) => {
      elementMap[elementData.elementId] = elementData;
      return elementMap;
    }, {});
    const suggestions = [...this.data.suggestions];
    for (let i = 0; i < suggestions.length; i++) {
      const element = suggestions[i];
      if (element.id in _elementMap) {
        suggestions.splice(i, 1);
      }
    }
    return this.merge({ data: { suggestions } });
  }
}
