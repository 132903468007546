import ApiModel from 'models/api-model';
import FormulaListEntry from 'models/data/formula/formula-list-entry-model';

export class FormulaList extends ApiModel({
  data: {
    formulas: [], // type FormulaListEntry
  },
  status: null,
}) {
  processResponse({ response }) {
    /**
     * We are returning the formulas from the back-end in an order that is by decreasing formula id.
     * This will ensure that the most recently-created formula is always at the top of the list, as their
     * IDs increase with time.
     */
    return {
      data: {
        formulas: response.data.result
          .map((formula) => {
            let formulaListEntry = new FormulaListEntry();
            return formulaListEntry.convertFormulaDTOToFormulaListEntryModel(
              formula,
            );
          })
          .sort((f1, f2) => (f1.id < f2.id ? 1 : -1)),
      },
      status: response.status,
    };
  }

  getFormulaCount() {
    if (this.hasFormulas()) {
      return this.data.formulas.length;
    }
    return 0;
  }

  getFormulas() {
    if (this.data && this.data.formulas) {
      return this.data.formulas;
    }
    return [];
  }

  get(index) {
    if (
      this.data &&
      this.data.formulas &&
      index >= 0 &&
      index < this.data.formulas.length
    ) {
      return this.data.formulas[index];
    }
    return null;
  }

  hasFormulas() {
    return this.data && this.data.formulas && this.data.formulas.length > 0;
  }

  removeElementFromFormulaTabFromSocketPayload(payload) {
    return this.mergeData({
      formulas: this.data.formulas.filter(
        (formula) => !payload.has(formula.id),
      ),
    });
  }

  addFormulaToFormulaListFromSocketPayload(payload) {
    let formulaListEntry = new FormulaListEntry().convertFormulaDTOToFormulaListEntryModel(
      payload,
    );
    let formulasArray = [...this.data.formulas];
    let formulaIsInArray = false;

    for (let i = 0; i < formulasArray.length; i++) {
      let formula = formulasArray[i];
      if (formula.formulaId === formulaListEntry.formulaId) {
        formulasArray[i] = formulaListEntry;
        formulaIsInArray = true;
        break;
      }
    }

    if (!formulaIsInArray) {
      formulasArray.unshift(formulaListEntry);
    }

    return this.mergeData({
      formulas: formulasArray,
    });
  }
}
