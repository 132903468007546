import React from 'react';
import PropTypes from 'prop-types';
import FormulaRowElementModel from 'models/data/formula/formula-row-element-model';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import shortid from 'shortid';
import classnames from 'classnames';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import { getElementResultByNumberFormat } from 'utils/formula-utils';
export const FORMULA_ROW_ELEMENT_BLOCK = 'formula-row-element-input';
export const FORMULA_ROW_ELEMENT_ID_BLOCK = 'formula-row-element-input-id';

const FormulaRowElement = ({
  formulaRow,
  sectionId,
  setSelectedRow,
  checkEmptyInput,
  numberFormatId,
}) => {
  const formattedFormulaValue = getElementResultByNumberFormat(
    formulaRow.value,
    numberFormatId,
  );
  const externalSectionId = formulaRow.elementData.sectionId;
  // needs to set isinputempty on elements row after adding a new element row from content panel
  // to  prevent the value from being minstakenly true
  checkEmptyInput(formulaRow.value);
  return (
    <div className={`${FORMULA_ROW_ELEMENT_BLOCK}`}>
      <div
        className={classnames(
          `${FORMULA_ROW_ELEMENT_BLOCK}__value`,
          !formulaRow.elementActive && `${FORMULA_ROW_ELEMENT_BLOCK}__error`,
        )}
      >
        <button
          id={`${FORMULA_ROW_ELEMENT_ID_BLOCK}-element-value`}
          className={classnames(`${FORMULA_ROW_ELEMENT_BLOCK}__element-value`)}
          onClick={() => {
            setSelectedRow(formulaRow.elementData.id);
            scrollElementIntoView({
              elementId: formulaRow.elementId,
              sectionId: externalSectionId ? externalSectionId : sectionId,
            });
          }}
        >
          <MultiLineEllipsis
            toolTipID={`${FORMULA_ROW_ELEMENT_ID_BLOCK}-${shortid.generate()}`}
            text={formattedFormulaValue}
            maxLines={1}
            isNotInternationalized={true}
          >
            {formattedFormulaValue}
          </MultiLineEllipsis>
        </button>
      </div>
    </div>
  );
};

FormulaRowElement.propTypes = {
  formulaRow: PropTypes.instanceOf(FormulaRowElementModel).isRequired,
  /* section id value of current formula row */
  sectionId: PropTypes.number,
  /* Function to set the current selected row */
  setSelectedRow: PropTypes.func,
  /** Event handler to check for empty input field */
  checkEmptyInput: PropTypes.func.isRequired,
};

export default FormulaRowElement;
