import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import Search from 'components/common/search-component';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { getRevisionsSectionsRequest } from 'api/sections-api';

export const SEGMENT_FILTERS_ID_BLOCK = 'element-filters-segment-id';

const ElementFiltersStatementSegments = ({
  filters,
  setFilters,
  className,
  revisionId,
}) => {
  const [searchTerm, setSearch] = useState('');
  const [sections, setSections] = useState(
    new SectionTreeList({ isLoading: true }),
  );

  // React.useEffect instead of useEffect makes testing it easier with enzyme 
  // https://blog.carbonfive.com/shallow-testing-hooks-with-enzyme/
  React.useEffect(() => {
    const fetchSectionList = async () => {
      try {
        const response = await getRevisionsSectionsRequest({
          revisionId: revisionId,
        });
        setSections(sections.setLoaded({ response }));
      } catch (error) {
        setSections(sections.setError(error));
      }
    };
    if (!sections.hasBeenFetched) {
      fetchSectionList();
    }
  });

  return (
    <div className={`${className}__statement-segment`}>
      <Search
        className={`${className}__statement-segment-search`}
        id={`${className}-search`}
        onChange={(val) => setSearch(val)}
        onClear={() => {
          setSearch('');
        }}
        placeholder="statement-nav-element-filters.statement-segment.search.placeholder"
        showSearchButton
        value={searchTerm}
        isValid
        disableAutoComplete
      />
      <ConditionalRender dependencies={[sections]}>
        {sections.filteredSectionsHierarchy(searchTerm).map((section) => (
          <Checkbox
            key={section.id}
            id={`${SEGMENT_FILTERS_ID_BLOCK}-${section.id}`}
            isNotIntl
            label={section.name}
            value={section}
            checked={filters.isSectionSelected(section.id)}
            onChange={(val) => setFilters(filters.selectSection(val))}
          />
        ))}
      </ConditionalRender>
    </div>
  );
};

ElementFiltersStatementSegments.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
  /** Period list api model fetched from backend on page load, used to populate the checkbox options */
  // id of current revision
  revisionId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ data: { revision } }) => ({
  revisionId: revision.id,
});

export { ElementFiltersStatementSegments };
export default connect(mapStateToProps, null)(ElementFiltersStatementSegments);
