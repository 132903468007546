import ApiModel from 'models/api-model';
import {
  // getParsedReactSegmentJSON, // TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
  getParsedReactSegmentFromHTML,
} from 'utils/section-rendering-utils';

export default class SectionContentModel extends ApiModel({
  data: {
    processedReactSegment: null,
    orderNum: null,
    revisionId: null,
    sectionId: null,
    segmentType: null,
  },
  prevSectionId: null, // TODO use linked list ids for ordering/inserting sections when adding to navigation
  nextSectionId: null, // TODO use linked list ids for ordering/inserting sections when adding to navigation
  leftSideView: false,
}) {
  get sectionId() {
    if (this.data) {
      return this.data.sectionId;
    }
    return null;
  }
  get processedReactSegment() {
    if (this.data && this.data.processedReactSegment) {
      return this.data.processedReactSegment;
    }
    return null;
  }

  /**
   * Reducer function for setting prev and next section ids
   * @param {int} param.prevSectionId
   * @param {int} param.nextSectionId
   * @param {boolean} param.leftSideView
   */
  setLinkedSectionIds({ prevSectionId, nextSectionId, leftSideView }) {
    return this.merge({
      nextSectionId,
      prevSectionId,
      leftSideView,
    });
  }

  /**
   * Reducer function for setting prev section id
   * @param {int} prevSectionId
   */
  setPrevSectionId(prevSectionId) {
    return this.merge({
      prevSectionId,
    });
  }

  /**
   * Reducer function for setting next section id
   * @param {int} nextSectionId
   */
  setNextSectionId(nextSectionId) {
    return this.merge({
      nextSectionId,
    });
  }

  processResponse(response) {
    const {
      orderNum,
      revisionId,
      sectionId,
      segmentType,
      html,
      searchTerm,
    } = response;
    let processedReactSegment;
    if (response.html) {
      processedReactSegment = getParsedReactSegmentFromHTML({
        sectionId,
        html,
        searchTerm,
        leftSideView: this.leftSideView,
      });
    }
    // TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
    // else if (response.segmentJson) {
    //   processedReactSegment = getParsedReactSegmentJSON({
    //     segmentJsonTree: props.segmentJson,
    //   });
    // }
    else {
      throw Error(
        'html or segmentJson property required for SectionContentModel',
      );
    }
    return {
      data: {
        processedReactSegment,
        orderNum,
        revisionId,
        sectionId,
        segmentType,
      },
      prevSectionId: this.prevSectionId,
      nextSectionId: this.nextSectionId,
      leftSideView: this.leftSideView,
    };
  }

  /**
   * Reducer function
   * removes processedReactStatement from memory and returns isLoaded to false to make section
   * appear like it has never been fetched before. Used when clearing the sectionsCache
   */
  clearContent() {
    return this.merge({
      isLoaded: false,
      data: {
        ...this.data,
        processedReactSegment: null,
      },
    });
  }
}
