import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  STATEMENT_ELEMENT_ID_PREFIX,
  getElementHightlightState,
} from 'constants/feature/statement-content-constants';

import { useSelector } from 'react-redux';
import { initElementPanel } from 'store/actions/element-panel-actions';
import {
  addElementFormulaRow,
  setSelectedRowAction,
} from 'store/actions/formula-actions';
import { addElementToInternalReference } from 'store/actions/internal-reference-actions';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  ANNOTATION_DISPLAY_FUNCTIONS,
  hoverCopyFormulaDisabledTooltip,
} from 'constants/feature/tieout-element-constants';
import { clickElementInContentWhileBatch } from 'store/actions/batch-panel-actions';
import { isNullOrUndefined } from 'utils/object-utils';
import { clearContentHighlightSelector } from 'store/actions/statement-content-actions';
import { addElementForCopyFormula } from 'store/actions/copy-formula-actions';
import Tooltip from 'components/common/tool-tip-component';
import { deselectElementContentPanel } from 'store/actions/statement-content-actions';
import { removeElementFromBatchSelection } from 'store/actions/copy-formula-actions';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from '../../../constants/feature-flag-constants';

export const ELEMENT_BLOCK = 'tieout-element';
export const COMFORT_ASSIGN_ELEMENT = 'comfort-assign-element';
export const SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT =
  'selected-element-for-side-by-side-view';

const ANNOTATION_ICON_SIZE = '12px';

/* LOCAL THUNKS */
/**
 * Handles what happens when an element is clicked in the content panel
 * different modes will dictate what happens when an element is clicked
 * @param {int} param.elementId
 * @param {int} param.sectionId
 */
export const onElementClick =
  ({ elementId, sectionId, color, isClickedFromPanel, isDisabled }) =>
  async (dispatch, getStore) => {
    const { modes } = getStore().ui.statementPage;
    const { copyFormulaSelectedElement } = getStore().data.copyFormula;
    const { selectMode } = modes;
    switch (selectMode) {
      case ELEMENT_SELECT_MODES.BATCH_WITH_BANNER:
      case ELEMENT_SELECT_MODES.BATCH: {
        dispatch(clickElementInContentWhileBatch({ elementId, sectionId }));
        break;
      }
      case ELEMENT_SELECT_MODES.RANGE: {
        break;
      }
      case ELEMENT_SELECT_MODES.INTERNAL_REFERENCE: {
        dispatch(
          addElementToInternalReference({
            targetElementId: elementId,
            isFromSuggestion: false,
          }),
        );
        break;
      }
      case ELEMENT_SELECT_MODES.FORMULA: {
        await dispatch(
          addElementFormulaRow({
            elementId,
            sectionId,
          }),
        );
        //if an element for a formula is already selected, set the selected row as null
        dispatch(setSelectedRowAction(null));
        break;
      }
      case ELEMENT_SELECT_MODES.COPY_FORMULA: {
        if (!isDisabled) {
          if (
            copyFormulaSelectedElement.selectedElementIds.some(
              // eslint-disable-next-line eqeqeq
              (eleId) => eleId == elementId,
            )
          ) {
            dispatch(removeElementFromBatchSelection({ elementId }));
            dispatch(deselectElementContentPanel({ elementId }));
          } else {
            dispatch(
              addElementForCopyFormula({
                elementId,
              }),
            );
          }
        }
        break;
      }

      default: {
        if (!isClickedFromPanel) {
          await dispatch(clearContentHighlightSelector());
        }
        await dispatch(initElementPanel({ elementId, color }));
      }
    }
  };

/* COMPONENT */
export const TieoutElement = ({
  id,
  sectionId,
  name,
  children,
  isSideBySideViewDisabled,
  elementDataparts,
  elementData,
  handleElementClick,
  isCursorAlias,
  isElementSelectedForSideBySideMap,
  getCountForSelectedElementForSideBySideMapping,
  selectedElementsMap,
  currLeftPanel,
}) => {
  let elementId = id.substring(STATEMENT_ELEMENT_ID_PREFIX.length);
  let hideAnnotations = { flag: false, annotations: false };

  if (elementId && elementId.indexOf('_') >= 0) {
    let elementIdWithDatapart = elementId;
    elementId = elementId.substr(0, elementId.indexOf('_'));
    if (elementDataparts.elements && elementId in elementDataparts.elements) {
      let datapartId = elementIdWithDatapart.substr(
        elementIdWithDatapart.indexOf('_') + 1,
        elementIdWithDatapart.length,
      );

      if (!isNullOrUndefined(datapartId)) {
        if (parseInt(datapartId) !== 0) {
          hideAnnotations.flag = true;
        }

        if (datapartId !== elementDataparts.elements[elementId]) {
          hideAnnotations.annotations = true;
        }
      }
    }
  }

  const isElementSelected = () => {
    return selectedElementsMap.has(elementId);
  };

  const isHighlighted = useSelector(
    // intentional double equal
    // eslint-disable-next-line eqeqeq
    (store) => store.ui.statementPage.highlightedElementId == elementId,
  );
  const annotationDisplayFilter = useSelector(
    (store) => store.data.annotationDisplayOptions,
  );

  const elementHighlightState = () => {
    return selectedElementsMap.getElementHighlightColor(elementId);
  };

  const rightPanelState = useSelector(
    (store) => store.ui.statementPage.panels.right,
  );
  const elementPanelElementId = useSelector(
    (store) => store.data.elementPanel.elementDetails.id,
  );
  const tableMatrix = useSelector(
    (store) => store.data.copyFormula.tableMatrix,
  );
  const isPartOfTableMatrix = () => {
    if (tableMatrix.length > 0) {
      return tableMatrix[elementId] ? true : false;
    } else {
      // Since Formula is not copied - tableMatrix size is zero
      return true;
    }
  };

  return (
    <SelectedableElement
      selectableKey={id}
      id={id}
      name={name}
      isHighlighted={isHighlighted}
      onClick={(event) => {
        if (event) {
          event.stopPropagation();
        }
        handleElementClick({
          elementId,
          sectionId,
          isDisabled: !isPartOfTableMatrix(),
        });
      }}
      currLeftPanel={currLeftPanel}
      isCursorAlias={isCursorAlias}
      elementData={elementData(elementId, sectionId)}
      selected={isElementSelected()}
      elementHighlightState={elementHighlightState()}
      elementId={elementId}
      sectionId={sectionId}
      panel={rightPanelState}
      elementPanelElementId={elementPanelElementId}
      hideAnnotations={hideAnnotations}
      isDisabled={!isPartOfTableMatrix()}
      isSideBySideViewDisabled={isSideBySideViewDisabled(elementId)}
      tooltip={!isPartOfTableMatrix() ? hoverCopyFormulaDisabledTooltip : false}
      annotationDisplayFilter={annotationDisplayFilter}
      isElementSelectedForSideBySideMap={isElementSelectedForSideBySideMap}
      getCountForSelectedElementForSideBySideMapping={
        getCountForSelectedElementForSideBySideMapping
      }
    >
      {children}
    </SelectedableElement>
  );
};

const _getSelectedModifierClass = ({
  selected,
  panel,
  elementPanelElementId,
  elementId,
  elementHighlightState,
}) =>
  selected
    ? `${ELEMENT_BLOCK}--selected--${getElementHightlightState(
        panel,
        elementPanelElementId,
        elementId,
      )} ${ELEMENT_BLOCK}--selected--${elementHighlightState}`
    : null;

const SelectedableElement = ({
  id,
  name,
  selected,
  elementHighlightState,
  onClick,
  elementData,
  elementId,
  sectionId,
  children,
  panel,
  elementPanelElementId,
  isHighlighted,
  hideAnnotations,
  href,
  isDisabled,
  isSideBySideViewDisabled,
  tooltip,
  annotationDisplayFilter,
  isElementSelectedForSideBySideMap,
  getCountForSelectedElementForSideBySideMapping,
  isCursorAlias,
  currLeftPanel,
}) => {
  const _isElementSelectedForSideBySideMap =
    isElementSelectedForSideBySideMap(elementId);
  const _countForSelectedElementForSideBySideMapping =
    getCountForSelectedElementForSideBySideMapping(elementId);
  const renderSelectedableElement = () => (
    <a
      className={classnames(
        ELEMENT_BLOCK,
        selected ? `${ELEMENT_BLOCK}--selected` : null,
        _getSelectedModifierClass({
          selected,
          panel,
          elementPanelElementId,
          elementId,
          elementHighlightState,
        }),
        isHighlighted && `${ELEMENT_BLOCK}--highlighted`,
        isDisabled && `${ELEMENT_BLOCK}--disabled`,
        isSideBySideViewDisabled && `${ELEMENT_BLOCK}--cursor-disabled`,
        isCursorAlias && `${ELEMENT_BLOCK}--cursor-alias`,
        _isElementSelectedForSideBySideMap &&
          SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT,
      )}
      id={id}
      disabled={isDisabled}
      name={name}
      onClick={onClick}
      data-element-id={elementId}
      data-section-id={sectionId}
      href={href}
    >
      {!hideAnnotations.flag && annotationDisplayFilter.flag
        ? ANNOTATION_DISPLAY_FUNCTIONS.flagged({
            elementDetails: elementData,
            iconSize: ANNOTATION_ICON_SIZE,
          })
        : null}
      <FeatureFlagComponent
        name={FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE}
        fallback={
          <span
            className={classnames(
              ELEMENT_BLOCK,
              isSideBySideViewDisabled && `${ELEMENT_BLOCK}--cursor-disabled`,
              isCursorAlias && `${ELEMENT_BLOCK}--cursor-alias`,
            )}
          >
            {children}
          </span>
        }
      >
        <span
          className={classnames(
            ELEMENT_BLOCK,
            elementData &&
              elementData.isComfortAssign() &&
              COMFORT_ASSIGN_ELEMENT,
            isSideBySideViewDisabled && `${ELEMENT_BLOCK}--cursor-disabled`,
            isCursorAlias && `${ELEMENT_BLOCK}--cursor-alias`,
          )}
        >
          {children}
        </span>
      </FeatureFlagComponent>
      <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
        {!hideAnnotations.annotations && annotationDisplayFilter.comfortLetter
          ? ANNOTATION_DISPLAY_FUNCTIONS.comfortLetter({
              elementDetails: elementData,
            })
          : null}
      </FeatureFlagComponent>
      {!hideAnnotations.annotations && annotationDisplayFilter.tickmarks
        ? ANNOTATION_DISPLAY_FUNCTIONS.tickmarks({
            elementDetails: elementData,
          })
        : null}
      {!hideAnnotations.annotations && annotationDisplayFilter.formula
        ? ANNOTATION_DISPLAY_FUNCTIONS.formula({
            elementDetails: elementData,
          })
        : null}
      {!hideAnnotations.annotations && annotationDisplayFilter.internalRef
        ? ANNOTATION_DISPLAY_FUNCTIONS.internalRef({
            className: ELEMENT_BLOCK,
            elementDetails: elementData,
          })
        : null}
      {!hideAnnotations.annotations && annotationDisplayFilter.workpaper
        ? ANNOTATION_DISPLAY_FUNCTIONS.workpaper({
            elementDetails: elementData,
          })
        : null}
      {!hideAnnotations.annotations && annotationDisplayFilter.status
        ? ANNOTATION_DISPLAY_FUNCTIONS.status({
            elementDetails: elementData,
            iconSize: ANNOTATION_ICON_SIZE,
          })
        : null}
      {!isNullOrUndefined(_countForSelectedElementForSideBySideMapping) && (
        <span className={`${SIDE_BY_SIDE_VIEW_SELECTED_ELEMENT}__count`}>
          {_countForSelectedElementForSideBySideMapping}
        </span>
      )}
    </a>
  );

  return tooltip ? (
    <Tooltip {...tooltip} active={tooltip ? true : false}>
      {renderSelectedableElement()}
    </Tooltip>
  ) : (
    renderSelectedableElement()
  );
};

TieoutElement.propTypes = {
  /** unique id for this element (should be CFTO_ELEMENT_XXXXXXX) */
  id: PropTypes.string.isRequired,
  /** name attribute for element, NOTE: probably can be filtered/deleted if we decide we don't need it */
  name: PropTypes.string,
  /** Link attribute */
  href: PropTypes.string,
  /** this function is used mediator to have handle API call triggering in side by side view */
  handleElementClick: PropTypes.func,
  /** object of statmentContent.elementDataParts or leftStatmentContent.leftElementDataParts will be used based on the left or right statements in SideBySide View */
  elementDataparts: PropTypes.object,
  /** object of statmentContent.elementCache or leftStatmentContent.leftElementCache will be used based on the left or right statements in SideBySide View */
  elementData: PropTypes.func,
  /** boolean value used to handle the pointer for left statement in SideBySide View */
  isCursorAlias: PropTypes.bool,
  /**A function which tells us, if an element is part of side by side view mapping */
  isElementSelectedForSideBySideMap: PropTypes.func,
  /**It gives us info about the sequence number of mapped element. For example, an element is nth mapping in the sequence */
  getCountForSelectedElementForSideBySideMapping: PropTypes.func,
  /** A function to help us decide if a particular element in source or target statement (when in side by side view mode) should be disabled or enabled*/
  isSideBySideViewDisabled: PropTypes.func,
};

export default memo(TieoutElement);
