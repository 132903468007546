import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Permissions from 'permissions/permissions';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import DataGrid from 'components/common/data-grid/data-grid-component';
import DataGridHeader from 'components/common/data-grid/data-grid-header.component';
import NoStatementComponent from 'components/feature/statement-list/statement-list-no-statements-component';
import {
  getStatementListColumns,
  getDeletedStatementListColumns,
  STATEMENT_LIST_TABS,
  DELETE_STATEMENT_OPTIONS,
  getArchivedStatementListColumns,
  STATEMENT_LIST_SORT_COLUMN_KEYS,
} from 'constants/feature/statement-list-constants';
import { DataGridDataApi } from 'models/utils/common/data-grid/data-grid-data-model';
import { StatementList } from 'models/api/statement-list-model';
import TabModel from 'models/utils/common/tab-model';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import AddRevisonModal from 'components/feature/statement-list/statement-list-add-revision-modal';
import RevisionHistoryModal from 'components/feature/statement-list/revision-history-modal';
import DeletedRevisionHistoryStateWrapper from 'components/feature/statement-list/deleted-revision-history-state-wrapper';
import StatementForm from 'models/form/statement-form-model';
import ProjectApi from 'models/api/project-api-model';

import SoftDeleteAllRevisionsModal from 'components/feature/statement-list/soft-delete-all-revisions-modal';
import SoftDeleteLatestRevisionModal from 'components/feature/statement-list/soft-delete-latest-revision-modal';
import PermanentlyDeleteRevisionsModal from 'components/feature/statement-list/permanently-delete-revisions-modal';
import { restoreDeletedRevisionRequest } from 'api/statement-list-api';
import RestoreRevisionErrorModal from 'components/feature/statement-list/restore-deleted-revision-error-modal';
import { BAD_REQUEST } from 'http-status-codes';
import { DataGridSort } from 'models/utils/common/data-grid/data-grid-sort-model';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { CurrentUser } from 'models/api/current-user-model';
import SubmitStatementWrapUpApprovalModal from './submit-statement-for-wrap-up-approval-modal';
import StatementWrapUpApprovalFlowWrapper from './wrap-up-approval/statement-wrap-up-approval-flow-wrapper';
import { isNullOrUndefined } from 'utils/object-utils';
import StatementExportInProgressModal from 'components/common/statement-export-in-progress-modal';
import CopyStatementModal from 'components/feature/statement-list/copy-statement-modal';
import Loading from 'components/common/loading-component';
import DataGridConstants from 'constants/common/data-grid-constants';
import { TooltipOptions } from '../../../models/utils/common/tooltip-options-model';
import { STATEMENT_TYPE_ID_MAP } from 'constants/feature/statement-creation-constants';
import { isProjectExistInAMERegion } from 'utils/project-utils';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';

export const STATEMENT_LIST_BLOCK = 'statement-list';

const { ROWS_AMOUNT } = DataGridConstants;
const STATEMENT_LIST_ID_BLOCK = 'statement-list-id';

const WRAP_UP_POLLING_INTERVAL = 15000; // 15 sec

class StatementListComponent extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      showSoftDeleteAllRevisionsModal: false,
      showSoftDeleteLatestRevisionModal: false,
      showRevisionHistoryModal: false,
      showDeletedRevisionHistoryModal: false,
      showPermanentlyDeleteRevisionModal: false,
      showAddRevisionModal: false,
      showRestoreRevisionErrorModal: false,
      showSubmitForWrapUpApprovalModal: false,
      showApproveWrapUpModal: false,
      selectedStatement: null,
      revisionDeleteSelection: DELETE_STATEMENT_OPTIONS.CURRENT,
      showExportInitiatedModal: false,
      showCopyStatementModal: false,
      index: ROWS_AMOUNT,
      showESCRequestButton: false,
      filterInput: {},
      showFilterPopup: false,
      isFilterActive: false,

      // statementList in props is the full list of statements while in state it is the subset.
      // Subset in the sense, that it is either the filtered list of optimized list for render.
      statementList: [],
    };
    this.statementWrapUpPoll = null;
  }

  static getDerivedStateFromProps(props, state) {
    const { statementList } = props;
    const statements = statementList.getStatements();
    const useVirtualizedList = statements.length > ROWS_AMOUNT;
    if (!state.isFilterActive) {
      return {
        statementList: useVirtualizedList
          ? statements.slice(0, state.index)
          : statements,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevSate) {
    const { statementList, project } = this.props;
    if (prevProps.project.id !== project.id) {
      this.setState({
        index: ROWS_AMOUNT,
        isFilterActive: false,
        filterInput: {},
      });
      // clear poll for other project
      clearTimeout(this.statementWrapUpPoll);
      this.statementWrapUpPoll = null;
    }
    if (prevProps.statementList.isLoading && statementList.isLoaded) {
      // if we load new statements we need to re-initiate polling for statements in approval initiated state
      this._pollForStatementsWithApprovalInitiated();
    }
    if (
      prevProps.statementList.data &&
      prevProps.statementList.data.statements !== statementList.data.statements
    ) {
      const showESCRequestButton =
        statementList.data.statements.some((statement) => {
          return (
            statement.statementTypeId ===
              STATEMENT_TYPE_ID_MAP.PRIVATE_ANNUAL ||
            statement.statementTypeId ===
              STATEMENT_TYPE_ID_MAP.PRIVATE_QUARTERLY
          );
        }) && isProjectExistInAMERegion(project);
      this.setState({ showESCRequestButton });

      if (this.state.isFilterActive) {
        const { statementList } = this.props;
        const statements = statementList.getStatements();
        const { filterInput } = this.state;
        const filterColumn = STATEMENT_LIST_SORT_COLUMN_KEYS.statementName;
        const searchTerm = filterInput[filterColumn].toLowerCase();
        const filteredList = statements.filter(
          (row) => row[filterColumn].toLowerCase().indexOf(searchTerm) !== -1,
        );
        this.setState({ statementList: filteredList });
      }
    }
  }

  _toggleExportInitiatedModal = () => {
    this.setState((state) => ({
      showExportInitiatedModal: !state.showExportInitiatedModal,
    }));
  };

  _toggleSoftDeleteAllRevisionsModal = () => {
    this.setState((state, props) => {
      return {
        showSoftDeleteAllRevisionsModal: !state.showSoftDeleteAllRevisionsModal,
      };
    });
  };

  _toggleSoftDeleteLatestRevisionModal = () => {
    this.setState((state, props) => {
      return {
        showSoftDeleteLatestRevisionModal:
          !state.showSoftDeleteLatestRevisionModal,
      };
    });
  };

  _togglePermanentlyDeleteRevisionModal = () => {
    this.setState((state) => {
      return {
        showPermanentlyDeleteRevisionModal:
          !state.showPermanentlyDeleteRevisionModal,
      };
    });
  };

  _toggleRevisonHistoryModal = () => {
    this.setState((state) => {
      return {
        showRevisionHistoryModal: !state.showRevisionHistoryModal,
      };
    });
  };

  toggleAddRevisionModal = () => {
    this.setState((state, props) => {
      return {
        showAddRevisionModal: !state.showAddRevisionModal,
      };
    });
  };

  toggleSubmitForWrapUpApprovalModal = () => {
    this.setState((state) => ({
      showSubmitForWrapUpApprovalModal: !state.showSubmitForWrapUpApprovalModal,
    }));
  };

  toggleWrapUpApprovalModal = () => {
    this.setState((state) => ({
      showApproveWrapUpModal: !state.showApproveWrapUpModal,
    }));
  };

  onSubmitForWrapUpApprovalSuccess = () => {
    const { refreshStatementList } = this.props;
    refreshStatementList();
    this._pollForStatementsWithApprovalInitiated();
    this.toggleSubmitForWrapUpApprovalModal();
  };

  _pollForStatementsWithApprovalInitiated = () => {
    const { refreshStatementList, statementList } = this.props;
    if (
      isNullOrUndefined(this.statementWrapUpPoll) &&
      statementList.hasStatementsInApprovalInitiated(this.state.statementList)
    ) {
      this.statementWrapUpPoll = setTimeout(() => {
        this.statementWrapUpPoll = null;
        refreshStatementList({ withLoading: false });
        this._pollForStatementsWithApprovalInitiated();
      }, WRAP_UP_POLLING_INTERVAL);
    }
  };

  onWrapUpDecisionSuccess = () => {
    const { refreshStatementList } = this.props;
    refreshStatementList();
    this.toggleWrapUpApprovalModal();
  };

  setStatementToUploadRevisionOn = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this.toggleAddRevisionModal();
  };

  _clearSelectedStatement = () => {
    this.setState({
      selectedStatement: null,
    });
  };

  setStatementToSubmitForWrapUpApproval = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this.toggleSubmitForWrapUpApprovalModal();
  };

  setStatementForWrapUpApproval = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this.toggleWrapUpApprovalModal();
  };

  setStatementToDelete = (statement) => {
    const { selectedTab } = this.props;
    this.setState({
      selectedStatement: statement,
    });
    if (selectedTab === STATEMENT_LIST_TABS.ACTIVE) {
      this._toggleSoftDeleteStatementModal();
    } else if (selectedTab === STATEMENT_LIST_TABS.DELETED) {
      this._togglePermanentlyDeleteRevisionModal();
    }
  };

  setSoftDeleteAllRevisions = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this._toggleSoftDeleteAllRevisionsModal();
  };

  setSoftDeleteLatestRevision = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this._toggleSoftDeleteLatestRevisionModal();
  };

  getStatementRevisionsForModal = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this._toggleRevisonHistoryModal();
  };

  _setRadioOptionForDeleteModal = (revisionDeleteSelection) => {
    this.setState({
      revisionDeleteSelection: revisionDeleteSelection,
    });
  };

  /**
   * For the Delete Revision Modal
   * Used to permanently delete top soft deleted revision for a statement
   */
  _permanentlyDeleteTopRevision = async () => {
    const { selectedStatement } = this.state;
    const { permanentlyDeleteRevision, selectTabAndGetStatements } = this.props;
    // TODO error handling for failed request
    await permanentlyDeleteRevision(
      selectedStatement.revisionId,
      selectedStatement.id,
    );
    selectTabAndGetStatements({ tab: STATEMENT_LIST_TABS.DELETED });
    this._clearSelectedStatement();
    this._togglePermanentlyDeleteRevisionModal();
  };

  /**
   * For the Delete Revision Modal
   * Used to delete all soft-deleted revisions for a statement
   */
  _permanentlyDeleteAllRevisions = async (revisionDeleteSelection) => {
    const { selectedStatement } = this.state;
    const { permanentlyDeleteAllSoftDeletedRevisionsForStatement } = this.props;
    await permanentlyDeleteAllSoftDeletedRevisionsForStatement(
      selectedStatement.id,
    );

    this._clearSelectedStatement();
    this._togglePermanentlyDeleteRevisionModal();
  };

  _softDeleteLatestRevision = () => {
    const { selectedStatement } = this.state;
    const { softDeleteCurrentRevision } = this.props;
    softDeleteCurrentRevision({
      revisionId: selectedStatement.revisionId,
      statementId: selectedStatement.id,
    });
    this._clearSelectedStatement();
    this._toggleSoftDeleteLatestRevisionModal();
  };

  _softDeleteAllRevisions = () => {
    const { selectedStatement } = this.state;
    const { softDeleteAllRevisions } = this.props;
    softDeleteAllRevisions(selectedStatement.id);
    this._clearSelectedStatement();
    this._toggleSoftDeleteAllRevisionsModal();
  };

  _restoreDeletedRevision = async (revisionId, statementId) => {
    const { selectTabAndGetStatements } = this.props;
    try {
      await restoreDeletedRevisionRequest(revisionId, statementId);
      selectTabAndGetStatements({ tab: STATEMENT_LIST_TABS.DELETED });
      /** Close restore revision modal if it is already open
       * this is for restoring a revision from the deleted revision history modal
       */
      this.setState({ showRestoreRevisionErrorModal: false });
    } catch (error) {
      const statusCode = error.response.status;
      if (statusCode === BAD_REQUEST) {
        this.setState((state) => ({
          showRestoreRevisionErrorModal: !state.showRestoreRevisionErrorModal,
        }));
      }
    }
  };

  /**
   * Wrapper for upload revision therefore passes props blindly
   */
  _uploadRevision = (props) => {
    const { uploadRevision } = this.props;
    uploadRevision(props);
    this._clearSelectedStatement();
  };

  /**
   * This function is used for us to navigate to the edit statement page from the statement row kebab options
   * in the statement list.
   */
  navigateToEditStatement = (statement) => {
    const { push } = this.props;
    push(
      parseRoute(ROUTE_CONSTANTS.STATEMENT_EDIT_PAGE, {
        params: {
          projectId: statement.clientId,
          statementId: statement.id,
        },
      }),
    );
  };

  copyStatement = (statement) => {
    this.setState({
      selectedStatement: statement,
    });
    this.toggleShowCopyStatementModal();
  };

  onClickCopyStatement = (statement, project) => {
    const { copyStatement, refreshStatementList } = this.props;
    copyStatement({
      statementId: statement.id,
      targetClientId: project.id,
    });
    if (statement.clientId === project.id) {
      refreshStatementList();
    }
    this.toggleShowCopyStatementModal();
  };

  toggleShowCopyStatementModal = () => {
    this.setState((state) => ({
      showCopyStatementModal: !state.showCopyStatementModal,
    }));
  };

  _getStatementListColumns = (isVirtualizedList) => {
    const { selectedTab, project, workflowsMap, currentUser } = this.props;
    const { DELETED, ARCHIVED } = STATEMENT_LIST_TABS;
    switch (selectedTab) {
      case DELETED: {
        return getDeletedStatementListColumns({
          project,
          deleteAction: this.setStatementToDelete,
          restoreAction: this._restoreDeletedRevision,
          onRevisonClick: this.getStatementRevisionsForModal,
          containerRef: isVirtualizedList ? this.listRef : null,
        });
      }
      case ARCHIVED: {
        return getArchivedStatementListColumns({
          project,
          copyStatement: this.copyStatement,
          workflowsMap,
          containerRef: isVirtualizedList ? this.listRef : null,
        });
      }
      default: {
        return getStatementListColumns({
          softDeleteAllRevisionsAction: this.setSoftDeleteAllRevisions,
          softDeleteLatestRevisionAction: this.setSoftDeleteLatestRevision,
          onRevisonClick: this.getStatementRevisionsForModal,
          project,
          addRevisionAction: this.setStatementToUploadRevisionOn,
          navigateToEditStatement: this.navigateToEditStatement,
          workflowsMap,
          onSubmitWrapUpClick: this.setStatementToSubmitForWrapUpApproval,
          onApproveWrapUpClick: this.setStatementForWrapUpApproval,
          currentUser,
          copyStatement: this.copyStatement,
          containerRef: isVirtualizedList ? this.listRef : null,
        });
      }
    }
  };
  listScroll = () => {
    const { index } = this.state;
    const { statementList } = this.props;
    const statementsAmount = statementList.getStatements().length;

    if (index !== statementsAmount) {
      if (index + ROWS_AMOUNT <= statementsAmount) {
        this.setState({
          index: index + ROWS_AMOUNT,
          statementList: statementList
            .getStatements()
            .slice(0, index + ROWS_AMOUNT),
        });
      } else {
        this.setState({
          index: statementsAmount,
        });
      }
    }
  };

  onFilterInputChange = (name, value) => {
    this.setState((prevState) => {
      const filterInput = Object.assign({}, prevState.filterInput);
      filterInput[name] = value;
      return { ...prevState, filterInput };
    });
  };

  onFilterInputClear = (name) => {
    const { statementList } = this.props;
    this.setState((prevState) => {
      const filterInput = Object.assign({}, prevState.filterInput);
      filterInput[name] = EMPTY_STRING;
      return {
        ...prevState,
        filterInput,
        isFilterActive: false,
        statementList: statementList.getStatements() || [],
      };
    });
  };

  onFilterInputApply = (e) => {
    const { statementList } = this.props;
    const statements = statementList.getStatements() || [];
    const { filterInput } = this.state;
    const filterColumn = STATEMENT_LIST_SORT_COLUMN_KEYS.statementName;
    const searchTerm = filterInput[filterColumn].toLowerCase();
    const filteredList = statements.filter(
      (row) => row[filterColumn].toLowerCase().indexOf(searchTerm) !== -1,
    );

    this.setState({
      statementList: filteredList,
      showFilterPopup: false,
      isFilterActive: true,
    });
  };

  onFilterClick = (e) => {
    this.setState({ showFilterPopup: !this.state.showFilterPopup });
  };

  onCloseFilterPopup = (e) => {
    const { statementList } = this.props;
    const filterColumn = STATEMENT_LIST_SORT_COLUMN_KEYS.statementName;
    const hasSearchValue = !!this.state.filterInput[filterColumn];
    if (hasSearchValue) {
      this.setState({
        showFilterPopup: false,
      });
    } else {
      this.setState({
        showFilterPopup: false,
        filterInput: {},
        isFilterActive: false,
        statementList: statementList.getStatements() || [],
      });
    }
  };

  render() {
    const {
      project,
      navigateToStatement,
      push,
      selectedTab,
      selectTabAndGetStatements,
      setUploadedFilesAddRevision,
      setUploadedFilesAddRevisionError,
      revisionInProgress,
      sortModel,
      permanentlyDeleteRevision,
      requestSortedStatementList,
    } = this.props;
    const {
      showSoftDeleteAllRevisionsModal,
      showSoftDeleteLatestRevisionModal,
      showRevisionHistoryModal,
      showRestoreRevisionErrorModal,
      selectedStatement,
      showAddRevisionModal,
      showPermanentlyDeleteRevisionModal,
      showSubmitForWrapUpApprovalModal,
      showApproveWrapUpModal,
      showExportInitiatedModal,
      showCopyStatementModal,
      index,
      showESCRequestButton,
      statementList,
      filterInput,
      showFilterPopup,
      isFilterActive,
    } = this.state;

    const disableScrollCallback =
      this.props.statementList &&
      index === this.props.statementList.getStatements().length;
    const renderFooter = disableScrollCallback ? () => null : () => <Loading />;

    // statementList in props is the full list of statements while in state it is the subset.
    // Subset in the sense, that it is either the filtered list of optimized list for render.
    const useVirtualizedList =
      this.props.statementList &&
      this.props.statementList.getStatements().length > ROWS_AMOUNT;
    /**
     * If there is no selected project, do not render the statement list component,
     * as it will have no statements to render. This scenario will occur when the project list is empty as there
     * is no project in the project list to be selected.
     */
    if (!project.isInitialized()) {
      return null;
    }
    return (
      <div className={`${STATEMENT_LIST_BLOCK}`}>
        <DataGridHeader
          tableId={`${STATEMENT_LIST_ID_BLOCK}-list`}
          tabsArray={Object.values(STATEMENT_LIST_TABS)}
          selectedTab={selectedTab}
          onSelectTab={(tab) => selectTabAndGetStatements({ tab })}
          rightHeaderButton={
            <div
              className={classNames(
                `${STATEMENT_LIST_BLOCK}__button-container`,
              )}
            >
              {showESCRequestButton && (
                <Button
                  className={classNames(`${STATEMENT_LIST_BLOCK}__esc-button`)}
                  id={`${STATEMENT_LIST_ID_BLOCK}-navigate-button`}
                  type={BUTTON_TYPES.secondary}
                  onClick={() => {
                    window.open(ROUTE_CONSTANTS.ESC_SUPPORT);
                  }}
                  tooltip={
                    new TooltipOptions({
                      text: 'statement-list.esc-button-tooltip',
                      id: `${STATEMENT_LIST_ID_BLOCK}-esc-support-tooltip`,
                      position: 'top',
                    })
                  }
                >
                  <FormattedMessage
                    id={'statement-create.select-esc-information.button'}
                  />
                </Button>
              )}
              <Button.IconButton
                id={`${STATEMENT_LIST_ID_BLOCK}-list-header-icon-button`}
                type={BUTTON_TYPES.icon}
                Icon={PlusIcon}
                onClick={() =>
                  push(
                    parseRoute(ROUTE_CONSTANTS.STATEMENT_CREATION, {
                      params: { projectId: project.id },
                    }),
                  )
                }
                className={`${STATEMENT_LIST_BLOCK}__create-statement-button`}
                disabled={!Permissions.Statement.canCreate(project.id)}
              >
                <FormattedMessage id={'statement-list.create-statement'} />
              </Button.IconButton>
            </div>
          }
          isIconButtonDisabled={!Permissions.Statement.canCreate(project.id)}
          onIconButtonClick={() =>
            push(
              parseRoute(ROUTE_CONSTANTS.STATEMENT_CREATION, {
                params: { projectId: project.id },
              }),
            )
          }
          iconButtonIcon={PlusIcon}
          iconButtonMessage={'statement-list.create-statement'}
        />
        <DataGrid
          listScroll={useVirtualizedList ? this.listScroll : null}
          useVirtualizedList={useVirtualizedList}
          virtualizedListFooter={useVirtualizedList ? renderFooter : null}
          virtualizedListDisableScrollCallback={
            useVirtualizedList && disableScrollCallback
          }
          virtualizedListRef={this.listRef}
          className={classNames(`${STATEMENT_LIST_BLOCK}__list`)}
          columns={this._getStatementListColumns(useVirtualizedList)}
          sortColumn={sortModel}
          filterInput={filterInput}
          showFilterPopup={showFilterPopup}
          isFilterActive={isFilterActive}
          onFilterInputChange={this.onFilterInputChange}
          onFilterInputClear={this.onFilterInputClear}
          onFilterInputApply={this.onFilterInputApply}
          onFilterClick={this.onFilterClick}
          onCloseFilterPopup={this.onCloseFilterPopup}
          onSortClick={(sort) => requestSortedStatementList({ sort })}
          tableId={`${STATEMENT_LIST_ID_BLOCK}-list`}
          dataModel={
            new DataGridDataApi({
              apiModel: this.props.statementList,
              rowItems: statementList,
            })
          }
          onRowClick={(rowData) => {
            navigateToStatement(rowData);
          }}
          rowClassnames={(statement) => {
            if (selectedTab === STATEMENT_LIST_TABS.ACTIVE) {
              if (statement.isPendingApproval()) {
                return `${STATEMENT_LIST_BLOCK}__list-item--pending-approval`;
              } else if (!statement.hasBeenOpenedByUser) {
                return `${STATEMENT_LIST_BLOCK}__list-item--not-viewed-yet`;
              }
            } else if (selectedTab === STATEMENT_LIST_TABS.DELETED) {
              if (statement.isPendingApproval()) {
                return `${STATEMENT_LIST_BLOCK}__list-item--pending-approval`;
              }
            }
          }}
          NoResultsComponent={
            <NoStatementComponent isFilterActive={this.state.isFilterActive} />
          }
        />
        {showRevisionHistoryModal &&
          selectedTab === STATEMENT_LIST_TABS.ACTIVE && (
            <RevisionHistoryModal
              project={project}
              toggleStatementRevisonsModal={this._toggleRevisonHistoryModal}
              selectedStatementId={selectedStatement.id}
            />
          )}
        {showRevisionHistoryModal &&
          selectedTab === STATEMENT_LIST_TABS.DELETED && (
            <DeletedRevisionHistoryStateWrapper
              project={project}
              selectedStatement={selectedStatement}
              closeWholeFlowFromParent={this._toggleRevisonHistoryModal}
              permanentlyDeleteRevision={permanentlyDeleteRevision}
              refreshStatementList={() =>
                selectTabAndGetStatements({ tab: STATEMENT_LIST_TABS.DELETED })
              }
            />
          )}
        {showPermanentlyDeleteRevisionModal && (
          <PermanentlyDeleteRevisionsModal
            statementToDelete={selectedStatement}
            onClose={this._togglePermanentlyDeleteRevisionModal}
            onDeleteAll={this._permanentlyDeleteAllRevisions}
            onDeleteTop={this._permanentlyDeleteTopRevision}
          />
        )}
        {showSoftDeleteAllRevisionsModal && (
          <SoftDeleteAllRevisionsModal
            statementToDelete={selectedStatement}
            onClose={this._toggleSoftDeleteAllRevisionsModal}
            onDelete={this._softDeleteAllRevisions}
          />
        )}
        {showSoftDeleteLatestRevisionModal && (
          <SoftDeleteLatestRevisionModal
            statementToDelete={selectedStatement}
            onClose={this._toggleSoftDeleteLatestRevisionModal}
            onDelete={this._softDeleteLatestRevision}
          />
        )}
        {showRestoreRevisionErrorModal && (
          <RestoreRevisionErrorModal
            toggleRestoreModal={() =>
              this.setState({
                showRestoreRevisionErrorModal: false,
              })
            }
          />
        )}
        {showAddRevisionModal && (
          <AddRevisonModal
            toggleAddRevisionModal={this.toggleAddRevisionModal}
            setUploadedFilesAddRevision={setUploadedFilesAddRevision}
            setUploadedFilesAddRevisionError={setUploadedFilesAddRevisionError}
            revisionInProgress={revisionInProgress}
            statementToUploadRevisionOn={selectedStatement}
            uploadRevision={this._uploadRevision}
          />
        )}
        {showSubmitForWrapUpApprovalModal && (
          <SubmitStatementWrapUpApprovalModal
            statement={selectedStatement}
            onClose={this.toggleSubmitForWrapUpApprovalModal}
            onSubmit={this.onSubmitForWrapUpApprovalSuccess}
          />
        )}

        {showApproveWrapUpModal && (
          <StatementWrapUpApprovalFlowWrapper
            statement={selectedStatement}
            onClose={this.toggleWrapUpApprovalModal}
            onDecline={this.onWrapUpDecisionSuccess}
            onApprove={this.onWrapUpDecisionSuccess}
          />
        )}
        {showExportInitiatedModal && (
          <StatementExportInProgressModal
            title={'statement-export-report.empty-title'}
            message={'statement-export-report.guidance-text'}
            onClickClose={this._toggleExportInitiatedModal}
          />
        )}
        {showCopyStatementModal && (
          <CopyStatementModal
            statement={selectedStatement}
            onClose={this.toggleShowCopyStatementModal}
            onClickCopy={this.onClickCopyStatement}
          />
        )}
      </div>
    );
  }
}

StatementListComponent.propTypes = {
  /** Api model of all statements for the selected project */
  statementList: PropTypes.instanceOf(StatementList).isRequired,
  /** Function that calls action to delete most recent revision only*/
  softDeleteCurrentRevision: PropTypes.func.isRequired,
  /** function that calls action to delete all revisions under an active statement */
  softDeleteAllRevisions: PropTypes.func.isRequired,
  /** function that calls action to permenatly delete most recent soft deleted revision under a deleted statement */
  permanentlyDeleteRevision: PropTypes.func.isRequired,
  /** function that fired to navigate us to the selected statements content page */
  navigateToStatement: PropTypes.func.isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** function for changing route */
  push: PropTypes.func.isRequired,
  /** Model representing the currently selected tab */
  selectedTab: PropTypes.instanceOf(TabModel).isRequired,
  /** Model representing the currently sort key and order */
  sortModel: PropTypes.instanceOf(DataGridSort).isRequired,
  /** Action that fires on tab selection setting selected tab and getting statements for that selection */
  selectTabAndGetStatements: PropTypes.func.isRequired,
  /** Action that fires on adding a revision to a statement */
  uploadRevision: PropTypes.func.isRequired,
  /** Action that sets the uploaded files to the revision in progress */
  setUploadedFilesAddRevision: PropTypes.func.isRequired,
  /** Action that signals that an error occured based on business criteria while processing the uploaded files to the revision in progress */
  setUploadedFilesAddRevisionError: PropTypes.func.isRequired,
  /** Object representing the working copy of the statement being created.*/
  revisionInProgress: PropTypes.instanceOf(StatementForm).isRequired,
  /** Currently processing/failed statements */
  workflowsMap: PropTypes.instanceOf(WorkflowsMap).isRequired,
  /** model representing current user object */
  currentUser: PropTypes.instanceOf(CurrentUser).isRequired,
  /** fired to refresh the statement list */
  refreshStatementList: PropTypes.func.isRequired,
  /** function for copying statement to selected client project */
  copyStatement: PropTypes.func.isRequired,
};

export default StatementListComponent;
