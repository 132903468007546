import { handleActions } from 'redux-actions';
import {
  periodListStatementContentLoading,
  periodListStatementContentLoaded,
  periodListStatementContentError,
  clearStatementPageStoreAction,
} from 'store/actions/statement-content-actions';
import { PeriodList } from 'models/api/period-list-model';

export default handleActions(
  {
    [periodListStatementContentLoading]: (periodList) => {
      return periodList.setLoading();
    },
    [periodListStatementContentLoaded]: (periodList, { payload }) => {
      return periodList.setLoaded(payload);
    },
    [periodListStatementContentError]: (periodList, { payload }) => {
      return periodList.setError(payload);
    },
    [clearStatementPageStoreAction]: (periodList, { payload }) => {
      return new PeriodList();
    },
  },
  new PeriodList()
);
