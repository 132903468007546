import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import adobeAnalyticsMiddleware from './middlewares/adobe-analytics-middleware';

import createRootReducer from 'store/reducers/root-reducer';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = null;

/* Note: It is important that this function be called BEFORE accessing the exported store variable. 
It is already called from the app-component.jsx file, please be wary of using the store variable directly*/
export default function configureStore(initialState) {
  store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        adobeAnalyticsMiddleware,
      ),
    ),
  );
  return store;
}

/* This function is used to get a reference to the store in a javascript file, as a side effect, 
it initializes the store if it does not already exist.*/
export function getStore() {
  if (!store) {
    configureStore({});
  }
  return store;
}
