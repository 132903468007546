import { handleActions } from 'redux-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';
import {
  leftContentSectionMapLoading,
  leftContentSectionMapLoaded,
  leftContentSectionMapError,
} from 'store/actions/section-cache-actions';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
export default handleActions(
  {
    [leftContentSectionMapLoading]: (contentSectionMap) => {
      return contentSectionMap.setLoading();
    },
    [leftContentSectionMapLoaded]: (contentSectionMap, { payload }) => {
      return contentSectionMap.setLoaded(payload);
    },
    [leftContentSectionMapError]: (contentSectionMap, { payload }) => {
      return contentSectionMap.setError(payload);
    },

    [clearStatementPageStoreAction]: (contentSectionMap, { payload }) => {
      return new ContentSectionMap();
    },
  },
  new ContentSectionMap(),
);
