import { createAction } from 'redux-actions';

import {
  searchStatementElementsRequest,
  getUpdatedElementsByIdsRequest,
} from 'api/element-api';
import {
  selectElementFromElementSearch,
  deselectElementContentPanel,
  clearContentHighlightSelector,
} from 'store/actions/statement-content-actions';

import { scrollElementIntoView } from 'utils/scrolling-utils';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';

export const setElementFilters = createAction('SET_ELEMENT_FILTERS');

export const setElementSearchResultsLoading = createAction(
  'SET_ELEMENT_SEARCH_RESULTS_LOADING',
);

export const setElementSearchResultsLoaded = createAction(
  'SET_ELEMENT_SEARCH_RESULTS_LOADED',
);

export const setElementSearchUpdateResultsLoaded = createAction(
  'SET_ELEMENT_SEARCH_UPDATE_RESULTS_LOADED',
);

export const setElementSearchRemoveElementsLoaded = createAction(
  'SET_ELEMENT_SEARCH_REMOVE_ELEMENTS_LOADED',
);

export const setElementSearchResultsError = createAction(
  'SET_ELEMENT_SEARCH_RESULTS_ERROR',
);

export const emptyElementSearchResults = createAction(
  'EMPTY_ELEMENT_SEARCH_RESULTS',
);

export const clearElementSearchResults = createAction(
  'CLEAR_ELEMENT_SEARCH_RESULTS',
);

export const clearElementFilters = createAction('CLEAR_ELEMENT_FILTERS');

export const setElementSearchResults = createAction(
  'SET_ELEMENT_SEARCH_RESULTS',
);

export const setSelectedElementResult = createAction(
  'SET_SELECTED_ELEMENT_RESULT',
);

export const updateElementFilterFromSocketPayloadAction = createAction(
  'UPDATE_ELEMENT_FILTER_FROM_SOCKET_PAYLOAD_ACTION',
);

export const showElementSearchRefreshButtonAction = createAction(
  'SHOW_ELEMENT_SEARCH_REFRESH_BUTTON_ACTION',
);
export const setBatchSelectedItemsIdsAction = createAction(
  'SET_BATCH_SELECTED_ITEMS_IDS_ACTION',
);
export const removeIdFromBatchSelectedItemsAction = createAction(
  'REMOVE_ID_FROM_BATCH_SELECTED_ITEMS_IDS_ACTION',
);
export const addIdToBatchSelectedItemsAction = createAction(
  'ADD_ID_TO_BATCH_SELECTED_ITEMS_IDS_ACTION',
);

export const searchElements = ({
  filters,
  searchTerm,
  scrollIntoView = true,
  sectionClick,
}) => async (dispatch, getState) => {
  const {
    elementFilters,
  } = getState().ui.statementPage.statementNavigatorPanel;
  const { id: revisionId } = getState().data.revision;
  const _elementFilters = filters || elementFilters;
  dispatch(emptyElementSearchResults());
  dispatch(setElementSearchResultsLoading());
  dispatch(setElementFilters(_elementFilters.setPageParams({ searchTerm })));
  try {
    const response = await searchStatementElementsRequest({
      revisionId,
      filters: _elementFilters.resetPagination(),
      searchTerm,
    });
    dispatch(setElementSearchResultsLoaded({ response }));
    const { elements } = response.data;
    if (elements && elements.length > 0) {
      const [firstElement] = elements;
      dispatch(
        selectElementFromElementSearch({
          elementId: firstElement.id,
          firstSearch: sectionClick,
        }),
      );
      if (scrollIntoView) {
        scrollElementIntoView({
          elementId: firstElement.id,
          sectionId: firstElement.sectionId,
        });
      }
    }
  } catch (error) {
    dispatch(setElementSearchResultsError(error));
  }
};

export const updateElementFilterFromSocketPayload = (elements) => (
  dispatch,
  getState,
) => {
  const {
    selectedTab,
    elementSearchResults,
  } = getState().ui.statementPage.statementNavigatorPanel;

  if (
    selectedTab === STATEMENT_NAV_TABS.elements &&
    elementSearchResults.hasElements()
  ) {
    dispatch(updateElementFilterFromSocketPayloadAction({ elements }));
  }
};

export const updateElementsSearchByIds = ({ elementIds }) => async (
  dispatch,
  getState,
) => {
  const { id: revisionId } = getState().data.revision;
  const {
    elementFilters,
  } = getState().ui.statementPage.statementNavigatorPanel;

  const {
    selectedTab,
    elementSearchResults,
  } = getState().ui.statementPage.statementNavigatorPanel;
  if (
    selectedTab === STATEMENT_NAV_TABS.elements &&
    elementSearchResults.hasElements()
  ) {
    try {
      const response = await getUpdatedElementsByIdsRequest({
        revisionId,
        elementIds,
        filters: elementFilters,
      });
      if (response.data.elements.length > 0) {
        dispatch(setElementSearchUpdateResultsLoaded(response.data));
      } else {
        dispatch(
          setElementSearchRemoveElementsLoaded({
            elementIds,
            total: response.data.total,
          }),
        );
      }
    } catch (error) {
      dispatch(setElementSearchResultsError(error));
    }
  }
};

export const searchElementsNextPage = () => async (dispatch, getState) => {
  const {
    elementFilters,
  } = getState().ui.statementPage.statementNavigatorPanel;
  const _elementFilters = elementFilters.setNextPage();
  dispatch(setElementSearchResultsLoading());
  dispatch(setElementFilters(_elementFilters));
  const { id: revisionId } = getState().data.revision;
  try {
    const { searchTerm } = _elementFilters.getPageParams();
    const response = await searchStatementElementsRequest({
      revisionId,
      filters: _elementFilters,
      searchTerm,
    });
    dispatch(setElementSearchResultsLoaded({ response }));
  } catch (error) {
    dispatch(setElementSearchResultsError(error));
  }
};
export const deselectSelectedElement = () => (dispatch, getState) => {
  const searchedElements = getState().ui.statementPage.statementNavigatorPanel
    .elementSearchResults;
  if (
    searchedElements &&
    searchedElements.hasElements() &&
    searchedElements.selectedIndex > -1
  ) {
    dispatch(
      deselectElementContentPanel({
        elementId: searchedElements.elements[searchedElements.selectedIndex].id,
      }),
    );
  }
  dispatch(clearContentHighlightSelector());
};

export const clearStatementNavElementTab = () => async (dispatch, getState) => {
  dispatch(clearElementSearchResults());
  dispatch(clearElementFilters());
};
