import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export default class TabModel {
  id;
  intlTitle;
  intlValues;
  tooltip;
  apiParam;
  featureName;
  /**
   *
   * @param {String} param.id: The unique identifier of the tab-model
   * @param {String} param.intlTitle: The key to retrieve the intl-formatted message for this tab-model from the respective i18n file for the current language
   * @param {Object} param.intlValues: The optional map of values to be supplied to the intl-formatted message specified by the `intlTitle`
   *                             for a tab, E.g: {someIntlMessageDisplayKey: someIntlDisplayValue}, which would populate for:
   *                             intlTitle: "This is an intl string with {someIntlMessageDisplayKey} meaning" to render as:
   *                             'This is an intl string with someIntlDisplayValue meaning.
   * @param {TooltipOptions} param.tooltip: An optional tooltip parameter which will render a tooltip on hover over the respective tab.
   * @param {String} param.className className to apply to this tab
   * @param {String} param.apiParam text corresponding to param in an api request that would trigger when changing to this tab
   * @param {TabModelIcon} param.rightIcon contains icon to be placed towards right side of tab model along with tabId for mapping with appropriated tabId -
   * /**shape is:
   * {"tabId-1":{iconComponent:'abc',classname:'test'},"tabId-2":{iconComponent:'abc1',classname:'test1'}}
   * shape is maintained using Map Object.
   * @param {String} param.featureName is the name of the feature. Based on name we can hide or unhide the feature
   * by wrapping it inside FeatureFlagComponent
   */
  constructor({
    id,
    intlTitle,
    intlValues,
    tooltip,
    className,
    apiParam,
    rightIcon,
    featureName = FEATURE_NAMES.OTHER_FEATURES,
  }) {
    this.id = id;
    this.intlTitle = intlTitle;
    this.intlValues = intlValues;
    this.tooltip = tooltip;
    this.className = className;
    this.apiParam = apiParam;
    this.rightIcon = rightIcon;
    this.featureName = featureName;
  }
}
