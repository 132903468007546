import BaseModel from 'models/base-model';

export default class ComfortLetter extends BaseModel({
  revisionId: null,
  elementId: null,
  customLabel: '',
  description: '',
  descriptionRichText: '',
  comfortLetterCreatedBy: null,
  comfortLetterCreatedDate: null,
  elementComfortLetterCreatedBy: null,
  elementComfortLetterCreatedDate: null,
  comfortLetterId: null,
  elementCount: 0,
}) {
  convertApiResponsetoComfortLetterModelFormat(apiResponse) {
    return this.merge({
      revisionId: apiResponse.revisionId,
      elementId: apiResponse.elementId,
      customLabel: apiResponse.label,
      description: apiResponse.text,
      descriptionRichText: apiResponse.richText,
      comfortLetterCreatedBy: apiResponse.comfortLetterCreatedBy,
      comfortLetterCreatedDate: apiResponse.comfortLetterCreatedDate,
      elementComfortLetterCreatedBy: apiResponse.elementcomfortLetterCreatedBy,
      elementComfortLetterCreatedDate:
        apiResponse.elementcomfortLetterCreatedDate,
      comfortLetterId: apiResponse.comfortLetterId
        ? apiResponse.comfortLetterId
        : apiResponse.id,
      elementCount: apiResponse.elementCount,
    });
  }
}
