import { handleActions } from 'redux-actions';
import {
  setCurrentStatementListTab,
  setStatementListSort,
  initStatementListFilters,
} from 'store/actions/statement-list/statement-list-filters-actions';
import StatementListFilters from 'models/data/statement-list-filters-model';

export default handleActions(
  {
    [setCurrentStatementListTab]: (statementListFilters, { payload }) => {
      return statementListFilters.setSelectedTab(payload);
    },
    [setStatementListSort]: (statementListFilters, { payload }) => {
      return statementListFilters.setSort(payload);
    },
    [initStatementListFilters]: (statementListFilters) => {
      return new StatementListFilters();
    },
  },
  new StatementListFilters(),
);
