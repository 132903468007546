import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as VerifiedIcon } from 'icons/verified.svg';
import { ReactComponent as ReviewedIcon } from 'icons/reviewed.svg';
import { ReactComponent as UnverifiedIcon } from 'icons/unverified.svg';

import SummaryElements from 'models/api/statement-summary-elements-model';
import {
  verifiedElementFilter,
  reviewedElementFilter,
  unverifiedElementFilter,
} from 'constants/feature/statement-summary-constants';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import classnames from 'classnames';

export const STATEMENT_SUMMARY_ELEMENTS_BLOCK = 'statement-summary-elements';

const StatementSummaryElementsComponent = ({
  showElementListPanel,
  summaryElements,
  displayOptions,
}) =>
  (displayOptions.verified || displayOptions.reviewed) && (
    <div className={STATEMENT_SUMMARY_ELEMENTS_BLOCK}>
      {
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-unverified__tooltip`}
          text="statement-summary.elements-unverified.label-tooltip"
          position="bottom"
        >
          <button
            className={classnames(
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-unverified`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--unverified`,
            )}
            onClick={() => showElementListPanel(unverifiedElementFilter)}
          >
            <UnverifiedIcon
              className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__icon`}
            />
            <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
              {displayOptions.percentage &&
                `${summaryElements.unverifiedPercentage}%`}
              {displayOptions.count && ` (${summaryElements.unverified})`}
            </div>
          </button>
        </Tooltip>
      }
      {displayOptions.verified && (
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-verified__tooltip`}
          text="statement-summary.elements-verified.label-tooltip"
          position="bottom"
        >
          <button
            className={classnames(
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-verified`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--verified`,
            )}
            onClick={() => showElementListPanel(verifiedElementFilter)}
          >
            <VerifiedIcon
              className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__icon`}
            />
            <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
              {displayOptions.percentage &&
                `${summaryElements.verifiedPercentage}%`}
              {displayOptions.count && ` (${summaryElements.verified})`}
            </div>
          </button>
        </Tooltip>
      )}
      {displayOptions.reviewed && (
        <Tooltip
          id={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-reviewed`}
          text="statement-summary.elements-reviewed.label-tooltip"
          position="bottom"
        >
          <button
            className={classnames(
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}-reviewed`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button`,
              `${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__button--reviewed`,
            )}
            onClick={() => showElementListPanel(reviewedElementFilter)}
          >
            <ReviewedIcon
              className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__icon`}
            />
            <div className={`${STATEMENT_SUMMARY_ELEMENTS_BLOCK}__label`}>
              {displayOptions.percentage &&
                `${summaryElements.reviewedPercentage}%`}
              {displayOptions.count && ` (${summaryElements.reviewed})`}
            </div>
          </button>
        </Tooltip>
      )}
    </div>
  );

StatementSummaryElementsComponent.propTypes = {
  /** Action fired when button is clicked to open element list panel */
  showElementListPanel: PropTypes.func.isRequired,
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElements).isRequired,
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
};

export default StatementSummaryElementsComponent;
