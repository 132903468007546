import { connect } from 'react-redux';
import {
  detachElementWorkpaper,
  getElementWorkpapersAndUpdateElementCacheWithParameters,
} from 'store/actions/element-panel-actions';
import { updateWorkpaper } from 'store/actions/workpaper-actions';
import WorkpaperPanel from 'components/feature/element-panel/workpaper-panel/workpaper-panel-component';

const mapStateToProps = (
  {
    data: {
      elementPanel: { workpaperList, elementDetails },
    },
  },
  ownProps,
) => ({
  ...ownProps,
  workpaperList,
  elementDetails,
});

const mapDispatchToProps = {
  detachElementWorkpaper,
  onCreateWorkaper: getElementWorkpapersAndUpdateElementCacheWithParameters,
  updateWorkpaper,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkpaperPanel);
