import { handleActions } from 'redux-actions';
import { CONTENT_HIGHLIGHT_MODES } from 'constants/feature/modes-constants';
import {
  setContentHighlightModeDefault,
  setContentHighlightModeNotes,
  setContentHighlightModeAddElement,
  setContentHighlightModeAddNavigation,
} from 'store/actions/modes-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [setContentHighlightModeDefault]: () => {
      return CONTENT_HIGHLIGHT_MODES.DEFAULT;
    },
    [setContentHighlightModeNotes]: () => {
      return CONTENT_HIGHLIGHT_MODES.NOTES;
    },
    [setContentHighlightModeAddElement]: () => {
      return CONTENT_HIGHLIGHT_MODES.ADD_ELEMENT;
    },
    [setContentHighlightModeAddNavigation]: () => {
      return CONTENT_HIGHLIGHT_MODES.ADD_NAVIGATION;
    },
    [clearStatementPageStoreAction]: () => {
      return CONTENT_HIGHLIGHT_MODES.DEFAULT;
    },
  },
  CONTENT_HIGHLIGHT_MODES.DEFAULT
);
