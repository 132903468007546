import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ExpandableRow from 'components/common/expandable-row-component';
import ConditionalRender from 'components/util/conditional-render-component';
import { HISTORIC_ACTIONS } from 'constants/feature/element-panel-constants';
import ElementDetails from 'models/api/element-details-api-model';
import ElementHistory from 'models/api/element-history-api-model';
import { getHistoryForElementPanel } from 'store/actions/element-panel-actions';
import { getSystemUserOrUserNameByUserId } from 'utils/statement-content-page-utils';

export const ELEMENT_HISTORY_BLOCK = 'element-history';

export const ElementHistorySection = ({
  elementDetails,
  elementHistory,
  getHistoryForElementPanel,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    getHistoryForElementPanel();
  }, [getHistoryForElementPanel]);

  const renderEntry = useCallback((entry) => {
    if (!HISTORIC_ACTIONS[entry.elementAction]) {
      return null;
    }
    return (
      <div className={`${ELEMENT_HISTORY_BLOCK}__entry`} key={entry.id}>
        <p className={`${ELEMENT_HISTORY_BLOCK}__change-description`}>
          <FormattedMessage
            id="element-panel.history.change-description"
            values={{
              actionType: HISTORIC_ACTIONS[entry.elementAction],
              fullName: getSystemUserOrUserNameByUserId(entry),
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />
        </p>
        <p className={`${ELEMENT_HISTORY_BLOCK}__change-metadata`}>
          <FormattedMessage
            id="element-panel.history.change-metadata"
            values={{
              revisionId: entry.revisionNumber,
              changeDate: moment(entry.changedDate).format(
                'MM/DD/YYYY hh:mm A',
              ),
            }}
          />
        </p>
      </div>
    );
  }, []);

  return (
    <ConditionalRender dependencies={[elementDetails, elementHistory]}>
      <ExpandableRow
        title="element-panel.history.title"
        expandExpandableRow={() => setExpanded(true)}
        minimizeExpandableRow={() => setExpanded(false)}
        showExpandableRow={expanded}
        tooltipId={`${ELEMENT_HISTORY_BLOCK}__tooltip`}
      >
        {elementHistory.entries.length > 0 ? (
          elementHistory.entries.map(renderEntry)
        ) : (
          <FormattedMessage id="element-panel.history.no-history-description" />
        )}
      </ExpandableRow>
    </ConditionalRender>
  );
};

ElementHistorySection.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  /** Specified element's history */
  elementHistory: PropTypes.instanceOf(ElementHistory).isRequired,
  /** action fired to get history for selected element */
  getHistoryForElementPanel: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    elementPanel: { elementDetails, elementHistory },
  },
}) => ({
  elementDetails,
  elementHistory,
});

const mapDispatchToProps = {
  getHistoryForElementPanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementHistorySection);
