import { createAction } from 'redux-actions';
import {
  getChecklistDataRequest,
  postAuditAnswerRequest,
} from 'api/audit-checklist-api';
import { LEFT_PANELS } from 'constants/feature/panel-constants';

export const auditChecklistLoading = createAction('AUDIT_CHECKLIST_LOADING');
export const auditChecklistLoaded = createAction('AUDIT_CHECKLIST_LOADED');
export const auditChecklistError = createAction('AUDIT_CHECKLIST_ERROR');
export const auditChecklistSaveAnswer = createAction(
  'AUDIT_CHECKLIST_SAVE_ANSWER',
);

export const fetchAuditChecklistforRevisionWithoutLoading = ({
  revisionId,
  statementId,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.AUDIT_CHECKLIST)
    return;
  try {
    const projectId = getState().data.selectedProject.project.id;
    const response = await getChecklistDataRequest({
      revisionId,
      statementId,
      projectId,
    });
    dispatch(auditChecklistLoaded({ response }));
  } catch (error) {
    dispatch(auditChecklistError(error));
  }
};

export const fetchAuditChecklistForRevision = ({
  revisionId,
  statementId,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.AUDIT_CHECKLIST)
    return;
  dispatch(auditChecklistLoading());
  await dispatch(
    fetchAuditChecklistforRevisionWithoutLoading({ revisionId, statementId }),
  );
};

export const updateAuditChecklistAnswer = ({
  answerID,
  questionId,
  revisionId,
  statementId,
  projectId,
}) => async (dispatch, getState) => {
  if (getState().ui.statementPage.panels.left !== LEFT_PANELS.AUDIT_CHECKLIST)
    return;
  const answer = {
    id: questionId,
    auditAnswer: answerID,
  };

  const response = await postAuditAnswerRequest(
    answer,
    revisionId,
    statementId,
    projectId,
  );

  dispatch(
    auditChecklistSaveAnswer({
      response,
    }),
  );
};
