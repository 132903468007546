import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
const INFORMATION_ICON_SIZE = '18px';

export const FORMULA_PANEL_HEADER_BLOCK = 'formula-panel-header';
export const FORMULA_PANEL_HEADER_ID_BLOCK = 'formula-panel-header-id';

const FormulaPanelHeader = () => {
  const FORMULA_HEADER_INFORMATION_TOOLTIP = new TooltipOptions({
    text: {
      id: 'formula-panel.header.tooltip-text',
      values: {
        br: <br key={`${FORMULA_PANEL_HEADER_ID_BLOCK}-brTag`} />,
        b: (...chunks) => (
          <b key={`${FORMULA_PANEL_HEADER_ID_BLOCK}-bTag`}>{chunks}</b>
        ),
      },
    },
    id: 'formula-panel-header-id-tooltip-information',
    position: 'left',
  });
  return (
    <div className={`${FORMULA_PANEL_HEADER_BLOCK}`}>
      <h3 className={`${FORMULA_PANEL_HEADER_BLOCK}__title`}>
        <FormattedMessage id={'formula-panel.header.title'} />
      </h3>
      <div className={`${FORMULA_PANEL_HEADER_BLOCK}__information`}>
        <Tooltip
          {...FORMULA_HEADER_INFORMATION_TOOLTIP}
          className={`${FORMULA_PANEL_HEADER_BLOCK}__tooltip`}
        >
          <Information
            name={'information-legal-hold'}
            className={`${FORMULA_PANEL_HEADER_BLOCK}__information-icon`}
            width={INFORMATION_ICON_SIZE}
            height={INFORMATION_ICON_SIZE}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(FormulaPanelHeader);
