import { STATEMENT_ELEMENT_ID_PREFIX } from 'constants/feature/statement-content-constants';
import BaseModel from 'models/base-model';

export default class ElementDataParts extends BaseModel({
  elements: {},
}) {
  updateDatapartElements({ response }) {
    let newElementDatapart = {};
    response.data.forEach(async (section) => {
      let parser = new DOMParser();
      let doc = parser.parseFromString(section.html, 'text/html');
      let elementIdSelector = "a[name^='CFTO_ELEMENT_']";
      let elements = doc.querySelectorAll(elementIdSelector);

      if (elements.length > 0) {
        elements.forEach((e) => {
          let elementId = e.id.substring(STATEMENT_ELEMENT_ID_PREFIX.length);
          if (elementId && elementId.indexOf('_') >= 0) {
            let actualElementId = elementId.substr(0, elementId.indexOf('_'));
            let datapartId = elementId.substr(
              elementId.indexOf('_') + 1,
              elementId.length,
            );
            if (actualElementId in newElementDatapart) {
              if (
                parseInt(newElementDatapart[actualElementId]) <
                parseInt(datapartId)
              ) {
                newElementDatapart[actualElementId] = datapartId;
              }
            } else {
              newElementDatapart[actualElementId] = datapartId;
            }
          }
        });
      }
    });
    return this.merge({
      elements: { ...this.elements, ...newElementDatapart },
    });
  }
}
