import InternalReferenceSuggestionList from 'models/api/internal-reference-suggestion-list-model';
import { handleActions } from 'redux-actions';
import {
  internalReferenceSuggestedListLoading,
  internalReferenceSuggestedListLoaded,
  internalReferenceSuggestedListError,
  updateSuggestedIRElementsFromListFromSocketPayloadAction,
  removeSuggestedIRFromSocketPayloadAction,
  updateSuggestedIRListFromSocketPayloadAction,
} from 'store/actions/internal-reference-suggestion-list-actions';

export default handleActions(
  {
    [internalReferenceSuggestedListLoading]: (internalReference) => {
      return internalReference.setLoading();
    },
    [internalReferenceSuggestedListLoaded]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.setLoaded(payload);
    },
    [internalReferenceSuggestedListError]: (internalReference, { payload }) => {
      return internalReference.setError(payload);
    },
    [updateSuggestedIRElementsFromListFromSocketPayloadAction]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.updateSuggestedIRElementsFromListFromSocketPayload(
        payload,
      );
    },
    [updateSuggestedIRListFromSocketPayloadAction]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.updateSuggestedIRListFromSocketPayload(payload);
    },
    [removeSuggestedIRFromSocketPayloadAction]: (
      internalReference,
      { payload },
    ) => {
      return internalReference.removeSuggestedIRFromSocketPayload(payload);
    },
  },
  new InternalReferenceSuggestionList(),
);
