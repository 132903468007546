import RolesUtil from 'permissions/utils/roles';
const ProjectPermissions = {
  canEdit: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canSoftDelete: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canRestore: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canPermanentlyDelete: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canEnableLegalHold: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canDisableLegalHold: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canEnableSupportTeamAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID)
    );
  },
  canEnableTechSupportAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID)
    );
  },
  canDisableSupportTeamAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
  canDisableTechSupportAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID)
    );
  },
  canEnableCoeTeamAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID)
    );
  },
  canDisableCoeTeamAccess: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID)
    );
  },
  canManageUsers: (projectID) => {
    return (
      RolesUtil.doesUserHavePPMDRoleForProject(projectID) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectID) ||
      RolesUtil.doesUserHaveAdminSupportAccess(projectID)
    );
  },
};
export default ProjectPermissions;
