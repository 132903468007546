import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import FormItem from 'components/common/form-item-component';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isNullOrUndefined } from '../../utils/object-utils';

const TEXT_INPUT_BLOCK = 'text-input';

const TextInput = React.forwardRef(
  (
    {
      className,
      disabled,
      errorText,
      id,
      isValid,
      label,
      maxLength,
      onChange,
      onBlur,
      placeholder,
      tooltip,
      type,
      value,
      width,
      onFocus,
      onPaste,
      pattern,
    },
    ref,
  ) => {
    const intl = useIntl();
    const handleOnChangeWithPattern = (event) => {
      const value = event.target.value;
      if (value === '' || pattern.test(value)) {
        onChange(value);
      } else {
        event.preventDefault();
      }
    };

    return (
      <FormItem
        className={className}
        disabled={disabled}
        errorText={errorText}
        id={id}
        isValid={isValid}
        label={label}
        tooltip={tooltip}
        width={width}
      >
        <input
          className={
            isValid
              ? classNames(TEXT_INPUT_BLOCK, className)
              : classNames(`${TEXT_INPUT_BLOCK}__invalid`, className)
          }
          disabled={disabled}
          id={id}
          label={label}
          maxLength={maxLength}
          name={id}
          onChange={
            !isNullOrUndefined(pattern)
              ? (e) => handleOnChangeWithPattern(e)
              : (e) => onChange(e.target.value)
          }
          onBlur={onBlur}
          onFocus={onFocus}
          onPaste={onPaste}
          placeholder={
            placeholder ? intl.formatMessage({ id: placeholder }) : ''
          }
          rows="10"
          type={type}
          value={value}
          width={width}
          ref={ref}
        />
      </FormItem>
    );
  },
);

TextInput.propTypes = {
  /** String custom class */
  className: PropTypes.string,
  /** Boolean representing if component should be disabled state */
  disabled: PropTypes.bool,
  /** Unique string id for text area */
  id: PropTypes.string.isRequired,
  /** Internationalized sting id passed to form item */
  label: PropTypes.string,
  /** Maximum text input length */
  maxLength: PropTypes.number,
  /** Function fired on user input, returns value */
  onChange: PropTypes.func.isRequired,
  /** Function fired on blur */
  onBlur: PropTypes.func,
  /** Internationalized sting id */
  placeholder: PropTypes.string,
  /** Object with tool tip options passed to form item */
  tooltip: PropTypes.instanceOf(TooltipOptions),
  /** String value of type of input field */
  type: PropTypes.string.isRequired,
  /** Current value of the text input*/
  value: PropTypes.string,
  /** String percentage representing width of component passed to form item */
  width: PropTypes.string,
  /** Function fired on focus */
  onFocus: PropTypes.func,
  /** Function fired on paste of text into the input box */
  onPaste: PropTypes.func,
  /** The optional regex pattern for this text input box to block input of invalid characters or strings*/
  pattern: PropTypes.instanceOf(RegExp),
};

export default TextInput;
