import { handleActions } from 'redux-actions';

import StatementCreationPageData from 'models/api/statement-creation-page-data-api-model';
import {
  statementCreationPageDataLoading,
  statementCreationPageDataError,
  statementCreationPageDataLoaded,
  clearStatementCreationPageDataselected,
} from 'store/actions/statement-creation-page-data-actions';

export default handleActions(
  {
    [statementCreationPageDataLoading]: (statementCreationPageData) => {
      return statementCreationPageData.setLoading();
    },
    [statementCreationPageDataError]: (
      statementCreationPageData,
      { payload },
    ) => {
      return statementCreationPageData.setError(payload);
    },
    [statementCreationPageDataLoaded]: (
      statementCreationPageData,
      { payload },
    ) => {
      return statementCreationPageData.setLoaded(payload);
    },
    [clearStatementCreationPageDataselected]: () => {
      return new StatementCreationPageData();
    },
  },
  new StatementCreationPageData(),
);
