import BaseModel from 'models/base-model';

export default class CancelModal extends BaseModel({
  openFormulaCancelModal: false,
  openInternalReferenceCancelModal: false,
  openNotePanelAfterConfirm: false,
  selectedNoteId: null,
  selectedPseudoNoteId: null,
  openSectionPanelAfterconfirm: false,
  selectedSectionId: null,
}) {
  toggleFormulaCancelModal(payload) {
    return this.merge({
      openFormulaCancelModal: payload,
    });
  }

  toggleInternalReferenceCancelModal(payload) {
    return this.merge({
      openInternalReferenceCancelModal: payload,
    });
  }

  openNotePanelAfterCancelConfirm(payload) {
    return this.merge({
      openNotePanelAfterConfirm: payload,
    });
  }

  setSelectedNoteId(payload) {
    return this.merge({
      selectedNoteId: payload,
    });
  }

  setSelectedNotePseudoId(payload) {
    return this.merge({
      selectedPseudoNoteId: payload,
    });
  }

  openSectionPanelAfterCancelConfirm(payload) {
    return this.merge({
      openSectionPanelAfterconfirm: payload,
    });
  }

  setSelectedSectionId(payload) {
    return this.merge({
      selectedSectionId: payload,
    });
  }
}
