import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Plus } from 'icons/plus-zoom.svg';
import { ReactComponent as Minus } from 'icons/minus-zoom.svg';
import { ReactComponent as FullWidthIcon } from 'icons/fullscreen-increase.svg';
import { ReactComponent as FixedWidthIcon } from 'icons/fullscreen-decrease.svg';
import SideBySideViewZoom from 'models/data/side-by-side-view-zoom-model';
import Tooltip from 'components/common/tool-tip-component';
import Zoom from 'models/data/zoom-model';
import classNames from 'classnames';

const ZOOM_CONTROL_BLOCK = 'zoom-control';
const ZOOM_CONTROL_ID_BLOCK = 'zoom-control-id';
const ZOOM_ICON_SIZE = '20px';

export const ZoomControl = ({
  onPlus,
  onMinus,
  zoomModel,
  contentViewMode,
  setContentFixedMode,
  setContentFullMode,
  isCopiedFormula,
  shouldDisplayExpandIcon,
}) => {
  const { contentFullMode } = contentViewMode;
  const ContentViewModeIcon = contentFullMode ? FixedWidthIcon : FullWidthIcon;
  return (
    <div className={ZOOM_CONTROL_BLOCK}>
      <div className={classNames(`${ZOOM_CONTROL_BLOCK}__fixed-container`)}>
        <div
          id={ZOOM_CONTROL_ID_BLOCK}
          className={`${ZOOM_CONTROL_BLOCK}__container ${ZOOM_CONTROL_BLOCK}__container--plus_minus_container`}
        >
          <button
            className={`${ZOOM_CONTROL_BLOCK}__minus`}
            onClick={() => onMinus()}
            disabled={zoomModel.disableDecrement()}
          >
            <Minus width={ZOOM_ICON_SIZE} height={ZOOM_ICON_SIZE} />
          </button>
          <button
            className={`${ZOOM_CONTROL_BLOCK}__plus`}
            onClick={() => onPlus()}
            disabled={zoomModel.disableIncrement()}
          >
            <Plus width={ZOOM_ICON_SIZE} height={ZOOM_ICON_SIZE} />
          </button>
        </div>
        {shouldDisplayExpandIcon && (
          <div className={`${ZOOM_CONTROL_BLOCK}__container`}>
            <Tooltip
              id={`${ZOOM_CONTROL_BLOCK}__content-view-mode`}
              position={contentFullMode ? 'left' : 'bottom'}
              text={
                contentFullMode
                  ? 'global-header.content-view-mode.restore'
                  : 'common.expand'
              }
              key={
                contentFullMode
                  ? `${ZOOM_CONTROL_BLOCK}__content-view-mode--restore`
                  : `${ZOOM_CONTROL_BLOCK}__content-view-mode--expand`
              }
            >
              <ContentViewModeIcon
                className={`${ZOOM_CONTROL_BLOCK}__content-view-mode-button`}
                role="button"
                onClick={
                  contentFullMode ? setContentFixedMode : setContentFullMode
                }
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

ZoomControl.propTypes = {
  /** Function fired when plus button pressed */
  onPlus: PropTypes.func.isRequired,
  /** Function fired when minus button pressed */
  onMinus: PropTypes.func.isRequired,
  /** zoom model */
  zoomModel: PropTypes.oneOfType([
    PropTypes.instanceOf(Zoom),
    PropTypes.instanceOf(SideBySideViewZoom),
  ]).isRequired,
  /** content view mode model */
  contentViewMode: PropTypes.object.isRequired,
  /** Function fired to set the content to fixed mode */
  setContentFixedMode: PropTypes.func.isRequired,
  /** Function fired to set the content to full mode */
  setContentFullMode: PropTypes.func.isRequired,
  /** based on this boolean value expand full view of statement will be viewed in fullmode */
  shouldDisplayExpandIcon: PropTypes.bool,
};

export default ZoomControl;
