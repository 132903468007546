import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { isProjectHasReservedClientName } from 'utils/project-list-utils';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';

export default class ProjectApi extends ApiModel({
  data: {
    active: null,
    adminGroupAddDate: null,
    cik: null,
    deleteDate: null,
    engagementEntity: null,
    hasAdminGroup: false,
    hasCoeGroup: false,
    id: null,
    isNewClient: true,
    legalHold: false,
    name: '',
    symbol: 'a',
    sic: null,
    type: null,
    userEntities: [],
    createdDate: null,
    omniaUrl: null,
    omniaEngagementEntity: null,
    linkStatus: null,
    auditClient: null,
    isAuditClient: false,
    geoCode: null,
    containerCode: null,
    countryCode: null,
  },
  status: null,
}) {
  get engagementEntity() {
    return this.data.engagementEntity;
  }
  get omniaEngagementEntity() {
    return this.data.omniaEngagementEntity;
  }

  get name() {
    return this.data.name;
  }
  get legalHold() {
    return this.data.legalHold;
  }
  get hasAdminGroup() {
    return this.data.hasAdminGroup;
  }
  get hasCoeGroup() {
    return this.data.hasCoeGroup;
  }
  get userEntities() {
    if (this.data && this.data.userEntities) {
      return this.data.userEntities;
    }
    return [];
  }

  get containerCode() {
    return this.data.containerCode;
  }

  get countryCode() {
    return this.data.countryCode;
  }

  getUserIds() {
    return this.userEntities.map((user) => user.id).join(',');
  }
  get id() {
    return this.data.id;
  }
  get omniaUrl() {
    return this.data.omniaUrl;
  }
  get auditClient() {
    return this.data.auditClient;
  }
  get geoCode() {
    return this.data.geoCode;
  }

  isInitialized() {
    return !isNullOrUndefined(this.data.id);
  }

  getProjectData() {
    if (this.data) {
      return this.data;
    }
  }

  processResponse(response) {
    // Processed from existing model
    const _fromApiModel = response instanceof ProjectApi;
    if (_fromApiModel) {
      return response;
    }
    return {
      data: {
        ...response.data,
        id: `${parseInt(response.data.id)}-${response.data.geoCode}`,
        legalHold: response.data.legalHold === 1 ? true : false,
        isAuditClient: isProjectHasReservedClientName(response.data)
          ? true
          : false,
      },
      status: response.status ? response.status : 'not from api response',
    };
  }

  isDeleted() {
    return !this.data.active && this.data.deleteDate;
  }

  getCreatedDate() {
    return moment
      .utc(this.data.createdDate)
      .format(DATE_FORMATS.MONTH_DAY_YEAR);
  }

  containsUser(compareUser) {
    return this.userEntities.find((user) => user.id === compareUser.id);
  }

  updateLegalHoldForSelectedProject({ projectDetails }) {
    return this.merge({
      data: {
        ...this.data,
        ...projectDetails,
      },
    });
  }
}
