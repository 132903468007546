import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/common/dropdown-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { FormattedMessage } from 'react-intl';
import { ProjectList } from 'models/api/project-list-model';
import { GEO_OPTIONS } from 'constants/feature/project-list-header-constants';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { ReactComponent as OmniaLinkIcon } from 'icons/omnia-link.svg';
import Search from 'components/common/search-component';
import { ReactComponent as AdminModuleIcon } from 'icons/settings-icon.svg';

export const ID_BLOCK = 'id-project-list-header';
export const BLOCK = 'project-list-header';

const OMNIA_LINK_ICON_SIZE = '25';

const ProjectListHeader = ({
  projectList,
  push,
  searchTerm,
  setSearchTerm,
  clearSearchTerm,
  _executeSearch,
  currentUser,
  setGeo,
  projects,
  appliedSearchTerm,
}) => {
  return (
    <div className={`${BLOCK}`}>
      <div className={`${BLOCK}__display-horizontal`}>
        <h5 className={`${BLOCK}__title`}>
          <FormattedMessage id={'project-list.header.title'} />
        </h5>
        <Dropdown
          width={'35%'}
          id={`${BLOCK}-geo-dropdown`}
          className={`${BLOCK}__geo-dropdown`}
          options={GEO_OPTIONS}
          value={projectList.data.selectedGeo}
          onSelectOption={setGeo}
        />
        {(currentUser.admin || currentUser.applicationManager) && (
          <Button.IconButton
            id={`${BLOCK}__settings-button`}
            className={`${BLOCK}__settings-button`}
            type={BUTTON_TYPES.icon}
            Icon={AdminModuleIcon}
            onClick={() => push(ROUTE_CONSTANTS.ADMIN_MODULE_PAGE)}
          ></Button.IconButton>
        )}
      </div>
      <Button
        type={BUTTON_TYPES.primary}
        onClick={() => {
          push(ROUTE_CONSTANTS.PROJECT_CREATION_PAGE);
        }}
        id={`${ID_BLOCK}-create-project`}
        className={`${BLOCK}__create`}
      >
        <FormattedMessage id={'project-list.header.create-project'} />
      </Button>
      <div
        onClick={() => {
          push(ROUTE_CONSTANTS.OMNIA_ENGAGEMENT_PAGE);
        }}
        className={`${BLOCK}__omnia-link`}
      >
        <OmniaLinkIcon
          id={`${ID_BLOCK}-omnia-engagment`}
          className={`${BLOCK}__link-icon`}
          width={OMNIA_LINK_ICON_SIZE}
          height={OMNIA_LINK_ICON_SIZE}
        />
        <div className={`${BLOCK}__omnia-link-text`}>
          <FormattedMessage id={'project-list.header.link-omnia-engagement'} />
        </div>
      </div>
      {(!!projects.length || appliedSearchTerm) && (
        <Search
          className={`${BLOCK}__search`}
          id={`${ID_BLOCK}-search`}
          value={searchTerm}
          onChange={setSearchTerm}
          onClear={clearSearchTerm}
          onSearch={_executeSearch}
          placeholder="common.search"
          showSearchButton
          isValid
          disableAutoComplete
        />
      )}
      {!projects.length && !appliedSearchTerm && (
        <span className={`${BLOCK}__no-projects`}>
          <FormattedMessage id="project-list.header.no-projects" />
        </span>
      )}
    </div>
  );
};

ProjectListHeader.propTypes = {
  /** list of projects */
  projectList: PropTypes.instanceOf(ProjectList).isRequired,
  /** function for changing route */
  push: PropTypes.func.isRequired,
  /** the current search term */
  searchTerm: PropTypes.string.isRequired,
  /** function set the search term when the user types in the search box */
  setSearchTerm: PropTypes.func.isRequired,
  /** function to clear the search term */
  clearSearchTerm: PropTypes.func.isRequired,
  /** function that is fired when the search is initiated */
  _executeSearch: PropTypes.func.isRequired,
  /**Current User Data */
  currentUser: PropTypes.object.isRequired,
  /**A handler function which will set the geo in state when selected Region dropdown */
  setGeo: PropTypes.func.isRequired,
};

export default memo(ProjectListHeader);
