import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modal-component';

import Banner, { BANNER_TYPES } from 'components/common/banner-component';

import { ModalButton } from 'models/utils/common/modal-button-model';

const STATEMENT_CREATION_PAGE_ID_BLOCK = 'statement-creation-id';

const StatementCreationCancelModal = ({
  toggleCancelModal,
  onCancelAndLeavePage,
}) => {
  return (
    <Modal
      id={`${STATEMENT_CREATION_PAGE_ID_BLOCK}-cancel-modal`}
      title={'common.cancel-modal.title'}
      onClose={toggleCancelModal}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: onCancelAndLeavePage,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.no',
          onClick: toggleCancelModal,
        })
      }
    >
      <Banner
        id={`${STATEMENT_CREATION_PAGE_ID_BLOCK}-cancel-modal-warning-banner`}
        type={BANNER_TYPES.WARNING}
        bannerCopy={'statement-create.cancel-modal.warning-banner'}
        isInternationalized={true}
        isInteractive={false}
      />
    </Modal>
  );
};

StatementCreationCancelModal.propTypes = {
  /** Method on the statement creation component that closes statement creation cancel modal */
  toggleCancelModal: PropTypes.func.isRequired,
  /** Method on the statement creation component that let user leave statement creation page */
  onCancelAndLeavePage: PropTypes.func.isRequired,
};

export default StatementCreationCancelModal;
