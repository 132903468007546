import BaseModel from 'models/base-model';
import { DOCUMENT_UPLOAD_STATUSES } from 'constants/feature/statement-processing-constants';
import { isNullOrUndefined } from 'utils/object-utils';
export default class Workflow extends BaseModel({
  endTime: null,
  initiatorId: null,
  previousStatus: null,
  retryable: null,
  revisionId: null,
  startTime: null,
  statementName: null,
  statementId: null,
  status: null,
  errorInfo: null,
}) {
  getWorkFlowStatus() {
    return this._getWorkFlowStatus(this.status);
  }
  getPreviousWorkflowStatus() {
    return this._getWorkFlowStatus(this.previousStatus);
  }
  _getWorkFlowStatus = (stringStatus) => {
    let status;
    switch (stringStatus) {
      case 'UPLOADING':
        status = DOCUMENT_UPLOAD_STATUSES.UPLOADING;
        break;
      case 'SCANNING':
        status = DOCUMENT_UPLOAD_STATUSES.SCANNING;
        break;
      case 'CONVERTING':
        status = DOCUMENT_UPLOAD_STATUSES.CONVERTING;
        break;
      case 'PENDING':
        status = DOCUMENT_UPLOAD_STATUSES.PENDING;
        break;
      case 'PARSING':
        status = DOCUMENT_UPLOAD_STATUSES.PARSING;
        break;
      case 'QUEUED':
        status = DOCUMENT_UPLOAD_STATUSES.QUEUED;
        break;
      case 'PROCESSING':
        status = DOCUMENT_UPLOAD_STATUSES.PROCESSING;
        break;
      case 'COMPLETED':
        status = DOCUMENT_UPLOAD_STATUSES.COMPLETED;
        break;
      case 'FAILED':
        status = DOCUMENT_UPLOAD_STATUSES.FAILED;
        break;
      default:
        //previousStatus can be null.
        break;
    }
    return status;
  };

  isEqual(anotherWorkflow) {
    return (
      !isNullOrUndefined(anotherWorkflow) &&
      this.startTime === anotherWorkflow.startTime &&
      this.initiatorId === anotherWorkflow.initiatorId &&
      this.endTime === anotherWorkflow.endTime &&
      this.getPreviousWorkflowStatus() ===
        anotherWorkflow.getPreviousWorkflowStatus() &&
      this.getWorkFlowStatus() === anotherWorkflow.getWorkFlowStatus() &&
      this.retryable === anotherWorkflow.retryable
    );
  }
}
