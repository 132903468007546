import BaseModel from 'models/base-model';

export default class OmniaEngagementEntry extends BaseModel({
  jobTypeId: null,
  linkedBy: null,
  linkedDate: null,
  linkedStatus: null,
  linkedUserName: null,
  omClientId: null,
  omClientName: null,
  omEngagementId: null,
  omEngagementName: null,
  omUserId: null,
  toutClientId: null,
  toutClientName: null,
  toutEngagementId: null,
  toutEngagementName: null,
  toutProjectId: null,
  toutProjectName: null,
}) {}
