import React, { useState } from 'react';
import { connect } from 'react-redux';

import TreeView from 'components/common/treeview';
import {
  onStatementNavSectionSelect,
  setHighlightedElementIdContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import { scrollElementIntoViewAlertOnMissing } from 'utils/scrolling-utils';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

const CompareDiffComponent = ({
  diffData,
  onSectionClick,
  setHighlightedElementIdContentPanel,
  deallocateItems,
}) => {
  return (
    <div className="diff-list">
      {Object.keys(diffData).map((sectionName) => {
        const sectionData = diffData[sectionName];

        const addedSections = sectionData['0'];
        const changedSections = sectionData['3'];
        const deletedSections = sectionData['4'];

        return (
          <div className="diff-info">
            <div className="section-name">{sectionName}</div>
            <div className="section-content">
              <ul>
                {addedSections && (
                  <CollapsibleRow
                    rowTitle="Added"
                    sectionInfo={addedSections}
                    onSectionClick={onSectionClick}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                  />
                )}
                {changedSections && (
                  <CollapsibleRow
                    rowTitle="Changed"
                    sectionInfo={changedSections}
                    onSectionClick={onSectionClick}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                  />
                )}
                {deletedSections && (
                  <CollapsibleRow
                    rowTitle="Deleted"
                    sectionInfo={deletedSections}
                    onSectionClick={onSectionClick}
                    clickable={sectionName.toLowerCase() !== 'heading removed'}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                  />
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CollapsibleRow = ({
  rowTitle,
  sectionInfo,
  clickable = true,
  setHighlightedElementIdContentPanel,
  left,
  deallocateItems,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <li>
      <TreeView
        label={`${rowTitle} (${sectionInfo.length})`}
        expanded={expanded}
        onSelect={() => setExpanded(!expanded)}
        toggle={() => setExpanded(!expanded)}
        panel={left}
      >
        <ul className="row-items">
          {sectionInfo.map((item, iter) => {
            return clickable ? (
              <li
                className={`clickable-element-title ${
                  selectedRow === iter
                    ? 'clickable-element-title--selected'
                    : null
                }`}
                onClick={() => {
                  scrollElementIntoViewAlertOnMissing({
                    elementId: item.id,
                    sectionId: item.sectionId,
                    missingElementCallback: () =>
                      toast.warning(
                        <FormattedMessage id="compare-panel-element-deleted-notification" />,
                        item.id,
                        item.sectionId,
                      ),
                  });
                  deallocateItems();
                  setHighlightedElementIdContentPanel(item.id);
                  setSelectedRow(iter);
                }}
              >
                {item.display}
              </li>
            ) : (
              <li className="element-title">{item.display}</li>
            );
          })}
        </ul>
      </TreeView>
    </li>
  );
};

const mapStateToProps = ({
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  left,
});

const mapDispatchToProps = {
  onSectionClick: onStatementNavSectionSelect,
  setHighlightedElementIdContentPanel,
  deallocateItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareDiffComponent);
