import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import BatchUpdateElementList from 'models/data/batch-update-element-list-model';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Caret } from 'icons/caret.svg';
import IconButton from 'components/common/icon-button-component';
import classnames from 'classnames';
import {
  EXPAND_ELEMENTS_TOOLTIP,
  COLLAPSE_ELEMENTS_TOOLTIP,
} from 'constants/feature/batch-panel-constants';
import { connect } from 'react-redux';
import { removeElementFromBatchSelectionAction } from 'store/actions/batch-panel-actions';
import { removeIdFromBatchSelectedItemsAction } from 'store/actions/statement-navigator/elements-search-panel-actions';
import ElementDetailsComponent from 'components/common/element-details-component';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import {
  selectElementContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import { MAX_NUMBER_OF_ELEMENTS_TO_RENDER } from 'components/feature/batch-panel/batch-panel-component';
const SELECTED_ELEMENTS_BLOCK_ID = 'batch-selected-elements-id';
const SELECTED_ELEMENTS_BLOCK = 'batch-selected-elements';
const CARET_ICON_SIZE = '30';

const SelectedElements = ({
  selectedElements,
  removeElementFromBatch,
  show,
  selectElementContentPanel,
  deallocateItems,
  showMore,
  elementsIndex,
  removeIdFromBatchSelectedItemsAction,
}) => {
  const [elementsExpanded, setElementsExpanded] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (!show) setElementsExpanded(false);
  }, [show, elementsExpanded]);

  const onElementClick = (id) => {
    if (
      selectedRow !== null &&
      selectedElements &&
      selectedElements
        .getSelectedElements()
        .find((element) => element.id === selectedRow)
    ) {
      selectElementContentPanel({
        elementIds: [selectedRow],
        color: ELEMENT_HIGHLIGHT_STATES.DEFAULT,
      });
    }
    deallocateItems();
    selectElementContentPanel({
      elementIds: [id],
      color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
    });
    setSelectedRow(id);
  };

  const getElements = useMemo(
    () => selectedElements.getSelectedElements().splice(0, elementsIndex),
    [elementsIndex, selectedElements],
  );

  return (
    <>
      <div className={SELECTED_ELEMENTS_BLOCK}>
        <IconButton
          id={`${SELECTED_ELEMENTS_BLOCK_ID}-caret`}
          className={classnames(
            `${SELECTED_ELEMENTS_BLOCK}__caret-icon`,
            elementsExpanded
              ? `${SELECTED_ELEMENTS_BLOCK}__caret-icon--expand`
              : null,
          )}
          Icon={Caret}
          iconSize={CARET_ICON_SIZE}
          onClick={() => setElementsExpanded(!elementsExpanded)}
          tooltip={
            elementsExpanded
              ? COLLAPSE_ELEMENTS_TOOLTIP
              : EXPAND_ELEMENTS_TOOLTIP
          }
        />
        <FormattedMessage
          id="batch-panel.elements-count"
          values={{ count: selectedElements.count }}
        />
      </div>
      {elementsExpanded && (
        <div className={`${SELECTED_ELEMENTS_BLOCK}__element-list`}>
          {getElements.map((element) => (
            <div
              className={`${SELECTED_ELEMENTS_BLOCK}__element-entry`}
              key={element.id}
              onClick={() => onElementClick(element.id)}
            >
              <ElementDetailsComponent
                element={element}
                enableAction
                onClickIcon={(e) => {
                  e.stopPropagation();
                  removeElementFromBatch({ elementId: element.id });
                  removeIdFromBatchSelectedItemsAction(element.id);
                }}
              />
            </div>
          ))}
        </div>
      )}
      {selectedElements &&
        elementsExpanded &&
        selectedElements.count > MAX_NUMBER_OF_ELEMENTS_TO_RENDER && (
          <div
            className={`${SELECTED_ELEMENTS_BLOCK}__pagination-button-container`}
          >
            <button
              className={`${SELECTED_ELEMENTS_BLOCK}__pagination-button ${SELECTED_ELEMENTS_BLOCK}__pagination-button--next`}
              disabled={elementsIndex === selectedElements.count}
              onClick={() => showMore()}
            >
              <FormattedMessage id="common.show-more" />
            </button>
          </div>
        )}
    </>
  );
};

SelectedElements.propTypes = {
  /** List of elements selected from content panel for batch update */
  selectedElements: PropTypes.instanceOf(BatchUpdateElementList).isRequired,
  /** Action fired to remove element from batch element list */
  removeElementFromBatch: PropTypes.func.isRequired,
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  /* Action to highlight an element on content panel */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
  /* function to show another set of elements */
  showMore: PropTypes.func.isRequired,
  /* current index of elements */
  elementsIndex: PropTypes.number.isRequired,
  /* action to remove the id of an element if its inside the filtered elements selected for batch update */
  removeIdFromBatchSelectedItemsAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  removeElementFromBatch: removeElementFromBatchSelectionAction,
  selectElementContentPanel,
  deallocateItems,
  removeIdFromBatchSelectedItemsAction,
};

export { SelectedElements, SELECTED_ELEMENTS_BLOCK };
export default connect(null, mapDispatchToProps)(SelectedElements);
