import React from 'react';
import { SearchTableDropdownApi } from 'components/common/search-component';
import PropTypes from 'prop-types';
import DataGrid, {
  DataGridData,
  DataGridDataApi,
} from 'components/common/data-grid/data-grid-component';
import {
  getAddedUserColumnsForAdminModule,
  getSearchUserColumnsForAdminModule,
} from 'constants/feature/admin-module-constants';
import { UserList } from 'models/api/users-list-model';
import { EMPTY_STRING } from 'constants/common/feature-common-utils';

const ADMIN_MODULE_MANAGEUSERS_BLOCK = 'manage-users';
const ADMIN_MODULE_MANAGEUSERS_ID = 'manage-users-id';

export const ManageUsersAdmin = ({
  userEntityArray,
  currentUser,
  setSearch,
  _searchUser,
  setUserListModel,
  setUserEntityArray,
  search,
  userListModel,
}) => {
  const _updateUserEntityAgainstEmail = (email, entityData) => {
    const entityIndex = userEntityArray.findIndex(
      (entity) => entity.email === email,
    );
    userEntityArray[entityIndex] = entityData;
    setUserEntityArray([...userEntityArray]);
  };
  return (
    <>
      <SearchTableDropdownApi
        columns={getSearchUserColumnsForAdminModule(currentUser)}
        id={`${ADMIN_MODULE_MANAGEUSERS_ID}-user-search`}
        placeholder={'admin-module.manage-users.search.placeholder'}
        disabled={!currentUser.admin}
        onChange={(val) => _searchUser(val)}
        onClear={() => {
          _searchUser(EMPTY_STRING);
          setUserListModel(new UserList());
        }}
        value={search}
        onSelectResult={(user) => {
          setUserEntityArray((userEntityArray) => [...userEntityArray, user]);
          setSearch('');
          setUserListModel(new UserList());
        }}
        isOptionSelected={(user) => {
          return userEntityArray.some(
            (userEntity) => userEntity.email === user.email,
          );
        }}
        shouldDisableMenuOption={(user) => {
          return userEntityArray.some(
            (userEntity) => userEntity.email === user.email,
          );
        }}
        showSearchButton
        isValid
        resultsApiModel={
          new DataGridDataApi({
            apiModel: userListModel,
            rowItems: userListModel.getUsers(),
          })
        }
      />
      <DataGrid
        columns={getAddedUserColumnsForAdminModule(
          currentUser,
          _updateUserEntityAgainstEmail,
        )}
        className={`${ADMIN_MODULE_MANAGEUSERS_BLOCK}__added-users`}
        tableId={`${ADMIN_MODULE_MANAGEUSERS_BLOCK}-added-users`}
        dataModel={
          new DataGridData({
            rowItems: userEntityArray,
          })
        }
        numberOfVisibleRows={userEntityArray.length || 1}
      />
    </>
  );
};

ManageUsersAdmin.propTypes = {
  /**Array containing user details */
  userEntityArray: PropTypes.array.isRequired,
  /**To get current user related data */
  currentUser: PropTypes.instanceOf(Object).isRequired,
  /**Function to set search state */
  setSearch: PropTypes.func.isRequired,
  /**Function fired to make api call and fetch searched user */
  _searchUser: PropTypes.func.isRequired,
  /**Function to set users list */
  setUserListModel: PropTypes.func.isRequired,
  /**Function to set users entity array*/
  setUserEntityArray: PropTypes.func.isRequired,
  /**string holding searched user*/
  search: PropTypes.string.isRequired,
  /**Model consisting of users list */
  userListModel: PropTypes.instanceOf(UserList).isRequired,
};

export default ManageUsersAdmin;
