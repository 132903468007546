import React from 'react';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RETRY_ID_BLOCK = 'statement-processing-retry-modal-id';

const RetryConfirmationModal = (props) => {
  const { onClose, onRetry } = props;

  return (
    <Modal
      id={RETRY_ID_BLOCK}
      title={'statement-processing.retry-confirm-modal.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'common.yes',
          onClick: () => onRetry(),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'common.cancel',
          onClick: onClose,
        })
      }
    >
      <FormattedMessage id="statement-processing.retry-confirm-modal.body" />
    </Modal>
  );
};

RetryConfirmationModal.propTypes = {
  /** Modal panel onClose fucntion  */
  onClose: PropTypes.func.isRequired,
  /** Confirm retry fucntion  */
  onRetry: PropTypes.func.isRequired,
};

export default RetryConfirmationModal;
