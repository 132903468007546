import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import { addNavigationRequest } from 'api/navigation-api';
import TextArea from 'components/common/text-area-component';
import KebabDropdown from 'components/common/dropdown-component';
import Radio from 'components/common/radio-component';
import { FormattedMessage } from 'react-intl';
import { RadioOption } from 'models/utils/common/radio-options-model';
import {
  getStartCharacterOffsetWithin,
  getElementCSSXPath,
  getSafeParentForRange,
} from 'utils/content-selection-utils';
import { setTOCRefresh, setTOCExpandAll } from 'store/actions/TOC-actions';
import { OK } from 'http-status-codes';
import {
  fetchAllSectionRenderingData,
  fetchSectionIdListStatementContent,
} from 'store/actions/statement-content-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { getContentSectionMapRequest } from 'store/actions/section-cache-actions';
import { fetchTheListOfNotes } from 'store/actions/notes-panel-actions';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import { fetchStatementSummarySections } from 'store/actions/statement-summary/sections-summary-actions';
import { updateSelectedSectionPanel } from 'store/actions/sections-actions';
import { DEFAULT_ENTITY_STRING } from 'models/api/entity-list-model';
import { replaceSelectedSectionsContentPanel } from 'store/actions/statement-content-actions';
import { SECTION_HIGHLIGHT_STATES } from 'constants/feature/section-details-constants';
import {
  setCreateSectionAction,
  addNewSectionAction,
  removeNewSectionAction,
  setNewSectionIdAction,
} from 'store/actions/section-tree-list-actions';
import { SectionTreeListEntry } from 'models/data/section-tree-list-entry-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isRootLevelSection } from 'utils/section-tree-utils';
import ConditionalRenderComponent from 'components/util/conditional-render-component';

const ADD_NAVIGATOR_ID_BLOCK = 'add-navigator-modal-id';
const ADD_NAVIGATION_MODAL_BLOCK = 'add-navigator-modal';

class AddNavigationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionRange: null,
      selectionText: '',
      inlineInsertion: this._enableInlineButton(), // cannot create inline if section has subsection
      entity: props.entityList.entitiesDropdownMap[DEFAULT_ENTITY_STRING],
    };
  }

  getSectionParentId(sectionId) {
    const { contentSectionMap } = this.props;
    const closesBookmark = contentSectionMap.getClosestBookmarkSection({
      sectionId,
    });
    return closesBookmark.id;
  }
  getNewSection() {
    const { selection, sectionId, revision } = this.props;
    const { selectionText } = this.state;
    const section = new SectionTreeListEntry({
      chlidren: [],
      id: sectionId,
      is_technical: false,
      name: selectionText !== '' ? selectionText : selection.toString().trim(),
      orderNum: 0,
      parentSectionName: null,
      revisionId: revision.id,
      type: null,
      isNew: true,
    });
    return section;
  }

  componentDidMount() {
    const {
      selection,
      sectionId,
      setCreateSectionAction,
      addNewSectionAction,
      contentSectionMap,
    } = this.props;
    const { inlineInsertion } = this.state;

    this.setState({
      selectionRange: selection.getRangeAt(0),
      selectionText: selection.toString().trim(),
      sectionId: sectionId,
    });
    setCreateSectionAction(true);
    const parentId = this.getSectionParentId(sectionId);
    const isExistingSection = contentSectionMap.get(sectionId);

    addNewSectionAction({
      section: this.getNewSection(),
      parentId,
      inline: inlineInsertion,
      isExistingSection: isExistingSection,
    });
  }

  componentWillUnmount() {
    const { setCreateSectionAction, sectionId, removeNewSectionAction } =
      this.props;
    setCreateSectionAction(false);
    removeNewSectionAction(sectionId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      sectionId,
      addNewSectionAction,
      removeNewSectionAction,
      setTOCExpandAll,
    } = this.props;
    const { inlineInsertion } = this.state;
    if (prevState.inlineInsertion !== inlineInsertion) {
      removeNewSectionAction(sectionId);
      const parentId = this.getSectionParentId(sectionId);
      addNewSectionAction({
        section: this.getNewSection(),
        parentId,
        inline: inlineInsertion,
      });
      setTOCExpandAll(true);
    }
  }

  highlightCreatedNavigation = (id) => {
    const { replaceSelectedSectionsContentPanel } = this.props;
    replaceSelectedSectionsContentPanel({
      color: SECTION_HIGHLIGHT_STATES.CREATED_NAV,
      sectionIds: [id],
    });
    /** Set one time click event listener to clear green selected elements after
     * successful creation of section navigation
     */
    document.addEventListener(
      'click',
      (e) => {
        if (!e.ctrlKey) {
          replaceSelectedSectionsContentPanel({
            color: SECTION_HIGHLIGHT_STATES.DEFAULT,
            sectionIds: [id],
          });
        }
      },
      {
        once: true,
      },
    );
  };

  _addNavigation = async () => {
    const {
      revision,
      sectionId,
      setTOCRefresh,
      onClose,
      fetchAllSectionRenderingData,
      fetchStatementSummaryElements,
      getContentSectionMapRequest,
      fetchSectionIdListStatementContent,
      fetchTheListOfNotes,
      panels,
      fetchStatementSummarySections,
      updateSelectedSectionPanel,
      socketHasBeenDisconnected,
      setNewSectionIdAction,
    } = this.props;

    const { selectionRange, inlineInsertion, selectionText, entity } =
      this.state;

    const parent = getSafeParentForRange({ range: selectionRange });
    onClose();
    try {
      const {
        status,
        data: { addedSection },
      } = await addNavigationRequest({
        revisionId: revision.id,
        elementDetails: {
          selectedText: selectionText,
          selector: getElementCSSXPath(parent, false),
          offset: getStartCharacterOffsetWithin(selectionRange, parent),
          length: selectionText.length,
          tieoutSectionId: sectionId,
          hasElement: false,
          elementId: -1, //id for new element, according to information provided by the backend team
          inline: inlineInsertion,
          sectionNameEnumEntity: {
            abbreviatedName: selectionText,
            entity: entity.value,
            name: selectionText,
            shortName: selectionText,
            type: 'O',
          },
        },
      });
      if (status === OK) {
        fetchSectionIdListStatementContent(revision.id);
        await getContentSectionMapRequest({ revisionId: revision.id });
        updateSelectedSectionPanel({ sectionId: addedSection.id });
        setTOCRefresh(true);
        // sending scrollToSection with true value it is going to make the app scroll to the newly created
        // section when its data to render the content is already loaded.
        await fetchAllSectionRenderingData({
          sectionId: addedSection.id,
          scrollToSection: true,
        });
        if (socketHasBeenDisconnected) {
          fetchStatementSummaryElements({ revisionId: revision.id });
          fetchStatementSummarySections({ revisionId: revision.id });
        }
        this.highlightCreatedNavigation(addedSection.id);
        setNewSectionIdAction(addedSection.id);
        if (panels.right === RIGHT_PANELS.NOTES) {
          fetchTheListOfNotes({ revisionId: revision.id });
        }
      }
    } catch (error) {
      console.log(`error adding navigation ${error.message}`);
    }
  };

  _isTechnicalSection = () => {
    const { contentSectionMap, sectionId } = this.props;
    return contentSectionMap.get(sectionId).isTechnical;
  };

  _isRootLevelSection = () => {
    const { sectionId, sectionTreeList } = this.props;
    const parentId = this.getSectionParentId(sectionId);
    const isRootLevel = isRootLevelSection(
      sectionTreeList.sectionsHierarchy,
      parentId,
    );
    return isRootLevel;
  };

  _enableInlineButton = () => {
    const { contentSectionMap, sectionId } = this.props;
    const manualHeading = contentSectionMap.get(sectionId).originalText;
    const isTechnicalSection = contentSectionMap.get(sectionId).isTechnical;
    // enable inline button when it is under manual section or it is a technical section but not a root level section.
    /***Background on concept */
    /*------------------------- */
    // Technical section usually does not participates in hierarchy, so it has to be inline, but we have a limitation where,
    // if technical section is a root level section then it should be created in the hierarchy of nearest parent or else
    // some functionality related to carryforward will not work.
    return (manualHeading || isTechnicalSection) && !this._isRootLevelSection();
  };

  _enableIndentButton = () => {
    // enable indent button when:
    // 1. it is technical section and at the same time a root level section
    // 2. it is not a technical section.
    return (
      (this._isTechnicalSection() && this._isRootLevelSection()) ||
      !this._isTechnicalSection()
    );
  };

  _toolTipText = () => {
    if (!this._enableInlineButton()) {
      return 'add-navigation-modal.options.inline-disabled';
    } else if (!this._enableIndentButton()) {
      return 'add-navigation-modal.options.indented-disabled';
    }
  };

  render() {
    const _toolTipTextRadio = this._toolTipText();
    const { onClose, entityList } = this.props;
    let uniqueId = 0;
    return (
      <Modal
        id={ADD_NAVIGATOR_ID_BLOCK}
        title="add-navigator-modal.title"
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'common.save',
            onClick: this._addNavigation,
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
      >
        <ConditionalRenderComponent dependencies={[entityList]}>
          <div className={`${ADD_NAVIGATION_MODAL_BLOCK}__form-item`}>
            <TextArea
              id={`${ADD_NAVIGATION_MODAL_BLOCK}__form-item-id`}
              label="add-navigation-modal.form.heading.label"
              isValid={true}
              placeholder="add-navigation-modal.form.label.placeholder"
              value={this.state.selectionText}
              width="100%"
              rows={3}
              onChange={(e) => {
                this.setState((state) => ({
                  selectionText: e,
                }));
              }}
            />
          </div>
          <div className={`${ADD_NAVIGATION_MODAL_BLOCK}__form-item`}>
            <div className={'form-item__label'}>
              <FormattedMessage id="add-navigation-modal.form.entity.label" />
            </div>
            <KebabDropdown
              className={'entity-type__kebab'}
              menuClassName={'entity__kebab-menu'}
              id={'project-kebab'}
              options={Object.values(entityList.entitiesDropdownMap)}
              width={'100%'}
              onClickFormItem={(e) => {
                e.stopPropagation();
              }}
              value={this.state.entity}
              onSelectOption={(option) => {
                this.setState({ entity: option });
              }}
            />
          </div>
          <div className={`${ADD_NAVIGATION_MODAL_BLOCK}__form-item`}>
            <p className={`${ADD_NAVIGATION_MODAL_BLOCK}__instructions`}>
              <FormattedMessage id="add-navigator-modal.static-text" />
            </p>

            <Radio
              id={'navigation-insertion-type'}
              className={'navigation-insertion__radio'}
              tooltip={
                _toolTipTextRadio &&
                new TooltipOptions({
                  text: {
                    id: _toolTipTextRadio,
                    values: {
                      br: (
                        <br
                          key={`${ADD_NAVIGATOR_ID_BLOCK}-brTag-${++uniqueId}`}
                        />
                      ),
                    },
                  },
                  id: 'add-navigation-modal.options.tooltip-id',
                  position: 'right',
                  className: `${ADD_NAVIGATOR_ID_BLOCK}__tooltip`,
                })
              }
              options={[
                new RadioOption({
                  id: 'add-navigation-modal.options.inline-id',
                  label: 'add-navigation-modal.options.inline',
                  value: true,
                  disabled: !this._enableInlineButton(),
                  tooltipEnabled: !this._enableInlineButton(),
                }),
                new RadioOption({
                  id: 'add-navigation-modal.options.indented-id',
                  label: 'add-navigation-modal.options.indented',
                  value: false,
                  disabled: !this._enableIndentButton(),
                  tooltipEnabled: !this._enableIndentButton(),
                }),
              ]}
              width="auto"
              isValid
              selectedOption={this.state.inlineInsertion}
              onChange={(val) => {
                this.setState({
                  inlineInsertion: val,
                });
              }}
            />
          </div>
        </ConditionalRenderComponent>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  data: {
    elementPanel: { entityList },
    sectionTreeList,
    statementContent: {
      sectionsCache: { contentSectionMap },
      sectionIdList: {
        data: { sectionIds },
      },
    },
    revision,
  },
  ui: {
    statementPage: { panels, sectionsInView },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  sectionTreeList,
  entityList,
  revision,
  contentSectionMap,
  panels,
  socketHasBeenDisconnected,
  sectionsInView,
  sectionIds,
});

const mapDispatchToProps = {
  setTOCRefresh,
  fetchStatementSummaryElements,
  fetchAllSectionRenderingData,
  getContentSectionMapRequest,
  fetchSectionIdListStatementContent,
  fetchTheListOfNotes,
  fetchStatementSummarySections,
  updateSelectedSectionPanel,
  replaceSelectedSectionsContentPanel,
  setCreateSectionAction,
  addNewSectionAction,
  removeNewSectionAction,
  setTOCExpandAll,
  setNewSectionIdAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNavigationModal);
