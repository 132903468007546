import BaseModel from 'models/base-model';

export default class Container extends BaseModel({
  geoCode: '',
  description: null,
  memberFirmCode: '',
  countryCode: '',
  countryName: '',
  containerId: null,
  containerCode: '',
  containerName: '',
}) {}
