import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as StepValid } from 'icons/stepValid.svg';

const PROGRESS_BAR_BLOCK = 'progress-bar-step';

const ProgressBarStep = ({ active, isValid, stepNum }) => {
  return (
    <div className={PROGRESS_BAR_BLOCK}>
      <div className={`${PROGRESS_BAR_BLOCK}__node-container`}>
        <div
          className={classNames(
            `${PROGRESS_BAR_BLOCK}__node-border`,
            !active && !isValid
              ? `${PROGRESS_BAR_BLOCK}__node-border--inactive`
              : null,
            isValid ? `${PROGRESS_BAR_BLOCK}__node-border--valid` : null
          )}
        >
          <div
            className={
              isValid || !active
                ? `${PROGRESS_BAR_BLOCK}__node-inner--hide`
                : `${PROGRESS_BAR_BLOCK}__node-inner`
            }
          />
          {isValid ? <StepValid /> : null}
        </div>
        <div
          className={classNames(
            `${PROGRESS_BAR_BLOCK}__text`,
            isValid ? `${PROGRESS_BAR_BLOCK}__text--valid` : null
          )}
        >{`Step ${stepNum}`}</div>
      </div>
      <div className={`${PROGRESS_BAR_BLOCK}__pipe`} />
    </div>
  );
};

ProgressBarStep.propTypes = {
  /** Unique string id for component */
  id: PropTypes.string.isRequired,
  /** Bool representing if form section is currently focused */
  active: PropTypes.bool.isRequired,
  /** Bool representing if form section feilds are all valid */
  isValid: PropTypes.bool.isRequired,
  /** String representing step number */
  stepNum: PropTypes.string.isRequired,
};

export default memo(ProgressBarStep);
