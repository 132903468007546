import React from 'react';
import { RadioOption } from 'models/utils/common/radio-options-model';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { toast } from 'react-toastify';
import Notification from 'components/common/notification-component';
import { LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER } from 'constants/common/feature-common-utils';
import { getMaxElementForBatchUpdateLimit } from 'utils/statement-content-page-utils';

export const UNSELECTED_OPTION = undefined;
export const isOptionUnselected = (val) => val === UNSELECTED_OPTION;
export const MAX_ELEMENTS_FOR_BATCH = 500;

export const SESSION_STORAGE_KEY_FOR_BATCH_UPDATE_CONFIG = `${LOCAL_STORAGE_TIEOUT_APP_IDENTIFIER}__batch-update-config`;

const STATUS_VERIFICATION_OPTIONS = {
  verify: new RadioOption({
    id: 'verification-verify-batch-option-id',
    label: 'batch-panel.status.verification.radio.verifiy',
    value: true,
    tooltipEnabled: true,
  }),
  unverify: new RadioOption({
    id: 'verification-unverify-batch-option-id',
    label: 'batch-panel.status.verification.radio.unverifiy',
    value: false,
    tooltipEnabled: false,
  }),
  none: new RadioOption({
    id: 'verification-none-batch-option-id',
    label: 'batch-panel.status.verification.radio.none',
    value: UNSELECTED_OPTION,
    tooltipEnabled: false,
  }),
};

export const STATUS_VERIFICATION_OPTIONS_ARRAY = Object.values(
  STATUS_VERIFICATION_OPTIONS,
);

const STATUS_REVIEW_OPTIONS = {
  review: new RadioOption({
    id: 'review-review-batch-option-id',
    label: 'batch-panel.status.review.radio.review',
    value: true,
    tooltipEnabled: true,
  }),
  unreview: new RadioOption({
    id: 'review-unreview-batch-option-id',
    label: 'batch-panel.status.review.radio.unreview',
    value: false,
    tooltipEnabled: false,
  }),
  none: new RadioOption({
    id: 'review-none-batch-option-id',
    label: 'batch-panel.status.review.radio.none',
    value: UNSELECTED_OPTION,
    tooltipEnabled: false,
  }),
};

export const STATUS_REVIEW_OPTIONS_ARRAY = Object.values(STATUS_REVIEW_OPTIONS);

const STATUS_USER_FLAG_OPTIONS = {
  add: new RadioOption({
    id: 'user-flag-add-batch-option-id',
    label: 'batch-panel.status.flag.radio.flag',
    value: true,
  }),
  remove: new RadioOption({
    id: 'user-flag-remove-batch-option-id',
    label: 'batch-panel.status.flag.radio.unflag',
    value: false,
  }),
  none: new RadioOption({
    id: 'user-flag-none-batch-option-id',
    label: 'batch-panel.status.flag.radio.none',
    value: UNSELECTED_OPTION,
  }),
};

export const STATUS_USER_FLAG_OPTIONS_ARRAY = Object.values(
  STATUS_USER_FLAG_OPTIONS,
);

const STATUS_SYSTEM_OVERRIDE_FLAG_OPTIONS = {
  add: new RadioOption({
    id: 'system-override-flag-add-batch-option-id',
    label: 'common.yes',
    value: true,
  }),
  remove: new RadioOption({
    id: 'system-override-remove-batch-option-id',
    label: 'common.no',
    value: false,
  }),
  none: new RadioOption({
    id: 'system-override-none-batch-option-id',
    label: 'batch-panel.status.flag.radio.none',
    value: UNSELECTED_OPTION,
  }),
};

const COMFORT_ASSIGN_OPTIONS = {
  add: new RadioOption({
    id: 'comfortAssign-add-option-id',
    label: 'common.yes',
    value: true,
  }),
  remove: new RadioOption({
    id: 'comfortAssign-remove-option-id',
    label: 'common.no',
    value: false,
  }),
  none: new RadioOption({
    id: 'comfortAssign-none-option-id',
    label: 'comfortAssign.radio.none',
    value: UNSELECTED_OPTION,
  }),
};

export const STATUS_SYSTEM_OVERRIDE_FLAG_OPTIONS_ARRAY = Object.values(
  STATUS_SYSTEM_OVERRIDE_FLAG_OPTIONS,
);

export const COMFORTASSIGN_OPTIONS_ARRAY = Object.values(
  COMFORT_ASSIGN_OPTIONS,
);

export const WORKPAPER_ENTRY_REMOVE_TOOLTIP = new TooltipOptions({
  text: 'common.remove',
  id: 'batch-panel-workpaper-remove-button-tool-tip',
  position: 'left',
});

export const EXPAND_ELEMENTS_TOOLTIP = new TooltipOptions({
  text: 'common.expand',
  id: 'batch-panel-expand-button-tool-tip',
});

export const COLLAPSE_ELEMENTS_TOOLTIP = new TooltipOptions({
  text: 'common.collapse',
  id: 'batch-panel-collapse-button-tool-tip',
});

export const TICKMARK_REMOVE_TOOLTIP = new TooltipOptions({
  text: 'common.remove',
  id: 'tickmark-remove-button-tool-tip',
  position: 'left',
});

export const hoverVerifyTooltip = new TooltipOptions({
  text: 'batch-panel.status.verification.radio.tooltip',
  id: 'batch-panel.checkbox.verify-tooltip-id',
  position: 'left',
});
export const hoverReviewTooltip = new TooltipOptions({
  text: 'batch-panel.status.review.radio.tooltip',
  id: 'batch-panel.checkbox.review-tooltip-id',
  position: 'left',
});
export const reviewInfoButtonTooltip = (event) =>
  new TooltipOptions({
    text: 'batch-panel.status.review.information',
    id: 'batch-panel.info.button-verify-tooltip-id',
    position: 'bottom',
    event: event,
  });

export const batchMaxAmountNotification = () => {
  toast.warning(
    <Notification
      icon={<Warning />}
      title={{ id: 'common.batch-warning-title' }}
      message={{
        id: 'common.batch-warning-message',
        values: { limit: getMaxElementForBatchUpdateLimit() },
      }}
    />,
    { autoClose: 3000 },
  );
};
