import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
const FOOTER_BLOCK = 'panelFooter';

/**
 * Footer to be used within a Flyout.
 */
class FlyoutFooter extends PureComponent {
  render() {
    const { children, className } = this.props;

    return (
      <div className={classnames(FOOTER_BLOCK, className)}>{children}</div>
    );
  }
}

FlyoutFooter.propTypes = {
  /** optional className applied to the footer */
  className: PropTypes.string,
};

export default FlyoutFooter;
