export const doCoordinatesCollide = ({
  aTop,
  aLeft,
  aWidth,
  aHeight,
  bTop,
  bLeft,
  bWidth,
  bHeight,
}) => {
  // the inverse of all non - collisions indicates a collision
  return !(
    // 'a' bottom doesn't touch 'b' top
    (
      aTop + aHeight < bTop ||
      // 'a' top doesn't touch 'b' bottom
      aTop > bTop + bHeight ||
      // 'a' right doesn't touch 'b' left
      aLeft + aWidth < bLeft ||
      // 'a' left doesn't touch 'b' right
      aLeft > bLeft + bWidth
    )
  );
};
