import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import StatementEditPage from 'pages/statement-edit-page';
import { finishStatementEdit } from 'store/actions/statement-edit-actions';
import { updateSelectedProjectFromIDIfDoesNotExists } from 'store/actions/selected-project-actions';

const mapStateToProps = (state, ownprops) => ({
  projectId: ownprops.match.params.projectId,
  statementId: ownprops.match.params.statementId,
});

const mapDispatchToProps = {
  finishStatementEdit,
  push,
  updateSelectedProjectFromIDIfDoesNotExists,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementEditPage);
