import { createAction } from 'redux-actions';
import { getFeatureInfoForAllGeos } from 'api/tieout-info-api';

export const featureInfoLoading = createAction('FEATURE_INFO_LOADING');
export const featureInfoError = createAction('FEATURE_INFO_ERROR');
export const featureInfoLoaded = createAction('FEATURE_INFO_LOADED');

export const fetchFeatureInfoForAllGeos = () => async (dispatch) => {
  dispatch(featureInfoLoading());
  try {
    const response = await getFeatureInfoForAllGeos();
    const geoToFeatureFlagMap = {};
    response.forEach((buildInfoDetails) => {
      const { data: { springProfiles = '', featureConfig } = {} } =
        buildInfoDetails;
      //we are getting springProfiles value as 'geo-env' i.e 'ame-qa'.
      // I am extracting geo information to create geoToFeatureFlagMap.
      const [geoCodeValue] = springProfiles.split('-');
      const geoCodeInUpperCase = geoCodeValue.toUpperCase();
      geoToFeatureFlagMap[geoCodeInUpperCase] = featureConfig;
    });
    await dispatch(
      featureInfoLoaded({
        response: geoToFeatureFlagMap,
      }),
    );
  } catch (error) {
    dispatch(featureInfoError(error));
  }
};
