import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import NotesSummary from 'components/feature/notes-panel/_notes-panel-note-summary-component';
import NotesFilterChips from 'components/feature/notes-panel/_notes-panel-filter-chips-component';
import Button from 'components/common/button-component';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import NotesPanelAdvancedFilterModal from './_notes-panel-advanced-filter-modal-component';
import {
  updateNoteFilterList,
  clearAllNoteFilters,
  applyNoteFilter,
  removeNoteFilter,
  setAssignedToMe,
} from 'store/actions/notes-panel-actions';
import { hasNotesFilters } from 'utils/note-filter-util';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import classNames from 'classnames';
import User from 'models/data/user-model';

export const NOTES_PANEL_HEADER_BLOCK = 'notes-panel-header';
const NOTES_PANEL_HEADER_ID_BLOCK = 'notes-panel-header-id';

export const NotesPanelHeader = ({
  notesList,
  noteId,
  updateNoteFilterList,
  clearAllNoteFilters,
  applyNoteFilter,
  removeNoteFilter,
  currentUser,
  setAssignedToMe,
}) => {
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);

  const toggleAdvancedFilterModal = () =>
    setShowAdvancedFilterModal(!showAdvancedFilterModal);

  const noteIndex = (notesList, noteId) => {
    let index = notesList.filteredNotes.findIndex(
      (note) => note.noteId === noteId,
    );
    // when a note is delete return -1
    if (index === -1) return -1;
    return index + 1;
  };

  const isNoteSelected =
    noteId === null || noteIndex(notesList, noteId) === -1 ? false : true;
  const numberOfNotes = notesList.filteredNotes.length;

  const areNoteFiltersApplied = useMemo(
    () =>
      hasNotesFilters(
        notesList.noteFilters,
        notesList.usersListNotesCreatedBy,
        notesList.usersListNotesRepliedBy,
        notesList.userListCreatedAndReplied,
        notesList.usersListNotesMentions,
      ),
    [
      notesList.noteFilters,
      notesList.usersListNotesCreatedBy,
      notesList.usersListNotesRepliedBy,
      notesList.userListCreatedAndReplied,
      notesList.usersListNotesMentions,
    ],
  );

  return (
    <>
      <div className={NOTES_PANEL_HEADER_BLOCK}>
        <div className={`${NOTES_PANEL_HEADER_BLOCK}__header`}>
          <FormattedMessage id="notes-panel.title" />
        </div>
        <NotesSummary />
        <div className={`${NOTES_PANEL_HEADER_BLOCK}__middle-container`}>
          <div className={`${NOTES_PANEL_HEADER_BLOCK}__note-summary-count`}>
            {isNoteSelected ? (
              <FormattedMessage
                id="notes-panel.note-count.note-selected"
                values={{
                  index: noteIndex(notesList, noteId),
                  count: numberOfNotes,
                }}
              />
            ) : numberOfNotes === 1 ? (
              <FormattedMessage
                id="notes-panel.note-count.default-singular"
                values={{ count: numberOfNotes }}
              />
            ) : (
              <FormattedMessage
                id="notes-panel.note-count.default-plural"
                values={{ count: numberOfNotes }}
              />
            )}
          </div>
          <Button.IconButton
            className={`${NOTES_PANEL_HEADER_BLOCK}__advanced-filter-button`}
            id={`${NOTES_PANEL_HEADER_ID_BLOCK}__advanced-filter-button`}
            onClick={() => toggleAdvancedFilterModal()}
            Icon={PlusIcon}
          >
            <FormattedMessage id="common.filter" />
          </Button.IconButton>
        </div>
        <div className={`${NOTES_PANEL_HEADER_BLOCK}__filter-chip-container`}>
          <div
            className={classNames(
              `${NOTES_PANEL_HEADER_BLOCK}__assigned-to-me`,
              notesList.assignedToMe &&
                `${NOTES_PANEL_HEADER_BLOCK}__assigned-to-me--active`,
            )}
          >
            <button
              onClick={() => {
                setAssignedToMe(String(currentUser.id));
              }}
              className={`${NOTES_PANEL_HEADER_BLOCK}__assigned-to-me-button`}
              disabled={notesList.assignedToMe}
            >
              <FormattedMessage
                id={'table-of-contents.assigned-to-me.button'}
              />
            </button>
            {notesList.assignedToMe && (
              <IconButton
                id={`${NOTES_PANEL_HEADER_BLOCK}__close-assignments-filter`}
                onClick={() => {
                  setAssignedToMe('');
                }}
                className={`${NOTES_PANEL_HEADER_BLOCK}__assigned-to-me-close-button`}
                Icon={CloseButton}
              />
            )}
          </div>
          {areNoteFiltersApplied && (
            <NotesFilterChips
              basedId={NOTES_PANEL_HEADER_ID_BLOCK}
              filterChipsFilterObject={notesList.noteFilters}
              usersListNotesCreatedBy={notesList.usersListNotesCreatedBy}
              usersListNotesRepliedBy={notesList.usersListNotesRepliedBy}
              userListCreatedAndReplied={notesList.userListCreatedAndReplied}
              removeNoteFilter={removeNoteFilter}
              selectedSegments={notesList.statementSegmentFilters}
              userFilterMentionedUsers={notesList.usersListNotesMentions}
            />
          )}
        </div>
        {areNoteFiltersApplied && notesList.filteredNotes.length === 0 && (
          <div
            className={`${NOTES_PANEL_HEADER_BLOCK}__no-filter-results-container`}
          >
            <FormattedMessage id={'notes-panel.filter.no-filter-results'} />
          </div>
        )}
        {notesList.assignedToMe && notesList.filteredNotes.length === 0 && (
          <div
            className={`${NOTES_PANEL_HEADER_BLOCK}__no-filter-results-container`}
          >
            <FormattedMessage
              id={'notes-panel.filter.not-assigned-to-me-results'}
            />
          </div>
        )}
      </div>
      {showAdvancedFilterModal && (
        <NotesPanelAdvancedFilterModal
          toggleModal={toggleAdvancedFilterModal}
          updateAdvancedFilter={updateNoteFilterList}
          clearAllNoteFilters={clearAllNoteFilters}
          applyNoteFilter={applyNoteFilter}
        />
      )}
    </>
  );
};

NotesPanelHeader.propTypes = {
  /** All the notes */
  notesList: PropTypes.object.isRequired,
  /** ID of the selected note */
  noteId: PropTypes.number,
  /** func to update the filter object when options are selected in the filter modal */
  updateNoteFilterList: PropTypes.func.isRequired,
  /** func to clear all the filters */
  clearAllNoteFilters: PropTypes.func.isRequired,
  /** func to apply note filters */
  applyNoteFilter: PropTypes.func.isRequired,
  /** func to apply Assigned To me filters */
  setAssignedToMe: PropTypes.func.isRequired,
  /** Object representing the current user in TieOut */
  currentUser: PropTypes.instanceOf(User).isRequired,
};

const mapStateToProps = ({
  data: {
    notesPanel: {
      notesList: { data },
      selectedNote: {
        data: { noteId },
      },
    },
    currentUser,
  },
}) => ({
  notesList: data,
  noteId: noteId,
  currentUser: currentUser.data,
});

const mapDispatchToProps = {
  updateNoteFilterList,
  clearAllNoteFilters,
  applyNoteFilter,
  removeNoteFilter,
  setAssignedToMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesPanelHeader);
