import { handleActions } from 'redux-actions';
import SideBySideViewZoom from 'models/data/side-by-side-view-zoom-model';

import {
  leftIncrementZoom,
  leftDecrementZoom,
  leftResetZoom,
} from 'store/actions/zoom-actions';
import { clearStatementPageStoreAction } from 'store/actions/statement-content-actions';

export default handleActions(
  {
    [leftIncrementZoom]: (zoom) => {
      return zoom.incrementZoom();
    },
    [leftDecrementZoom]: (zoom) => {
      return zoom.decrementZoom();
    },
    [leftResetZoom]: () => {
      return new SideBySideViewZoom();
    },
    [clearStatementPageStoreAction]: () => {
      return new SideBySideViewZoom();
    },
  },
  new SideBySideViewZoom(),
);
