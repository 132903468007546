import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Page from 'components/util/page-component';
import GlobalHeaderContainer from 'containers/global-header-container';
import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';
import ElementPanelContainer from 'containers/feature/element-panel/element-panel-container';
import SectionDetailsPanel from 'components/feature/section-details-panel/section-details-panel-component';
import ConditionalRender from 'components/util/conditional-render-component';
import Toolkit from 'components/feature/toolkit/toolkit-component';
import StatementNavigatorPanel from 'components/feature/toolkit/statement-nav/statement-navigator-panel-component';
import StatementContentSearchHighlightSelectorStyle from 'components/feature/statement-content-panel/statement-content-search-highlight-style-component';
import BatchPanel from 'components/feature/batch-panel/batch-panel-component';
import Revision from 'models/api/revision-api-model';
import NotesPanel from 'components/feature/notes-panel/notes-panel-component';
import TickmarkPanel from 'components/feature/tickmark-panel/tickmark-panel-component';
import ComparePanel from 'components/feature/statement-summary/compare-panel/compare-panel-component';
import SelectedStatement from 'models/api/selected-statement-model';
import { ReactComponent as Lock } from 'icons/lock.svg';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import classnames from 'classnames';
import ExportPanel from 'components/feature/toolkit/toolkit-export-panel-component';
import AuditCheckListPanel from 'components/feature/toolkit/toolkit-audit-panel/toolkit-audit-checklist-panel';
import ComfortAnnotationPanel from 'components/feature/toolkit/toolkit-comfort-annotation/toolkit-comfort-annotation-panel';
import WorkPaperPanel from 'components/feature/toolkit/workpaper-toolkit-panel/toolkit-workpaper-list-panel-component';
import { WorkflowsMap } from 'models/api/statement-workflows-map-model';
import { getReadOnlyBannerId } from 'utils/statement-content-page-utils';
import CustomMenuPanel from 'components/feature/toolkit/statement-nav/customized-report/toolkit-export-customized-report-component';
import BatchSelectControl from 'components/common/batch-select-control-component';
import shortid from 'shortid';
import AnnotationDisplayFilter from 'components/common/annotation-display-filter-component';
import BatchBanner from './batch-banner';
import ZoomControlHOC from 'higher-order-components/zoom-control-hoc-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
// import { addUserToRevision } from 'store/actions/active-users-actions';

export const STATEMENT_CONTENT_BLOCK = 'statement-content-page';
const STATEMENT_CONTENT_ID_BLOCK = 'statement-content-page-id';
export const CENTER_PANEL_ID = `${STATEMENT_CONTENT_ID_BLOCK}__center-panel`;
const TABLE_CONTROLS_ID = `${STATEMENT_CONTENT_ID_BLOCK}__table-controls`;
const SCROLL_TIMEOUT = 150;
const BANNER_CLOSE_ICON_SIZE = '28px';

class StatementContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // indicates to child components that the user is currently scrolling the content panel
      // i.e. more sections are being revealed
      isContentPanelScrolling: false,
      showBlackLineViewReadOnlyBanner: true,
    };
    this.centerPanelRef = createRef();
    this._scrollingTimeout = null;
    this._scrollingInitialized = false;
    this._ctrlPressed = false;
  }

  componentDidMount() {
    const { initStatementContent, urlParams, selectedStatementLoading } =
      this.props;
    const prepareStatementContent = async () => {
      selectedStatementLoading();
      initStatementContent(urlParams);
    };
    prepareStatementContent();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      revision,
      selectedStatement,
      urlParams,
      fetchSelectedStatement,
      blacklineViewMode,
      // selectedProjectId,
      // socketHasBeenDisconnected,
    } = this.props;
    // let isIntervalAlreadySet = null;

    if (
      revision.isLoaded &&
      selectedStatement.isLoaded &&
      (prevProps.revision.isLoaded !== revision.isLoaded ||
        prevProps.selectedStatement.isLoaded !== selectedStatement.isLoaded)
    ) {
      // Need to ensure revision and selected statement is loaded (thus center panel is rendered) before attaching event listener
      this.centerPanelRef.current.addEventListener(
        'scroll',
        this._scrollDetection,
      );
      if (!selectedStatement.isReadOnly()) {
        this._initBatchCtrlListeners();
      }
    }
    if (prevProps.urlParams.statementId !== urlParams.statementId) {
      fetchSelectedStatement(urlParams);
    }

    if (prevProps.blacklineViewMode !== blacklineViewMode) {
      this.setState({ showBlackLineViewReadOnlyBanner: true });
    }

    // TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
    // if (prevProps.socketHasBeenDisconnected !== socketHasBeenDisconnected) {
    //   if (socketHasBeenDisconnected && !isIntervalAlreadySet) {
    //     // We need to refresh the users in the room every 1hour
    //     isIntervalAlreadySet = setInterval(() => {
    //       addUserToRevision(revision.id, selectedProjectId);
    //     }, 3600000);
    //   } else {
    //     clearInterval(isIntervalAlreadySet);
    //   }
    // }
  }

  componentWillUnmount() {
    const {
      clearStatementPageStore,
      clearSelectedStatement,
      clearStatementNavElementTab,
      setContentFixedMode,
      clearElementsFromWorkpaper,
      closeSocketClient,
      clearElementsFromTickmark,
      clearSectionTreeListAction,
      projectUsersList,
      revision,
      cancelCopyFormula,
      clearBatchModeSelected,
      selectedProjectId,
    } = this.props;
    if (!window.location.pathname.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      if (this.centerPanelRef && this.centerPanelRef.current) {
        this.centerPanelRef.current.removeEventListener(
          'scroll',
          this._scrollDetection,
        );
      }
      this._cleanUpBatchCtrlListeners();
      window.clearTimeout(this._scrollingTimeout);
      this._scrollingTimeout = null;
      clearStatementPageStore();
      clearSelectedStatement();
      clearStatementNavElementTab();
      setContentFixedMode();
      clearElementsFromWorkpaper();
      clearElementsFromTickmark();
      clearSectionTreeListAction();
      cancelCopyFormula();
      clearBatchModeSelected();
    }
    closeSocketClient({
      revisionId: revision.id,
      projectUserIds: projectUsersList.data.userIdToUserNameMap,
      projectId: selectedProjectId,
    });
  }

  _scrollDetection = (event) => {
    const { isContentPanelScrolling } = this.state;
    if (!isContentPanelScrolling) {
      this.setState({
        isContentPanelScrolling: true,
      });
    }
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = setTimeout(() => {
      this.setState({
        isContentPanelScrolling: false,
      });
    }, SCROLL_TIMEOUT);
  };

  _initBatchCtrlListeners = () => {
    window.addEventListener('keydown', this._ctrlPressListener);
    window.addEventListener('keyup', this._ctrlReleaseListener);
  };

  _cleanUpBatchCtrlListeners = () => {
    window.removeEventListener('keydown', this._ctrlPressListener);
    window.removeEventListener('keyup', this._ctrlReleaseListener);
  };

  _ctrlPressListener = (event) => {
    const {
      setElementSelectModeBatchIfPossible,
      clearBatchModeIfPossible,
      isBatchModeSelected,
    } = this.props;
    if (event.key === 'Control' && !isBatchModeSelected) {
      setElementSelectModeBatchIfPossible();
      this._ctrlPressed = true;
    } else {
      if (this._ctrlPressed && !isBatchModeSelected) {
        clearBatchModeIfPossible();
        this._ctrlPressed = false;
      }
    }
  };

  _ctrlReleaseListener = (event) => {
    const { clearBatchModeIfPossible, isBatchModeSelected } = this.props;
    if (event.key === 'Control' && !isBatchModeSelected) {
      clearBatchModeIfPossible();
      this._ctrlPressed = false;
    }
  };

  render() {
    const {
      urlParams,
      revision,
      selectedStatement,
      blacklineViewMode,
      contentViewMode,
      workflowsMap,
      isBatchModeSelected,
      setElementSelectModeBatchWithBannerAction,
      showBatchPanelAction,
    } = this.props;
    const { isContentPanelScrolling, showBlackLineViewReadOnlyBanner } =
      this.state;

    return (
      <Page className={STATEMENT_CONTENT_BLOCK}>
        <StatementContentSearchHighlightSelectorStyle />
        <ConditionalRender dependencies={[revision, selectedStatement]}>
          <GlobalHeaderContainer urlParams={urlParams} />
          <div className={`${STATEMENT_CONTENT_BLOCK}__container`}>
            <div
              className={classnames(
                `${STATEMENT_CONTENT_BLOCK}__left-panel`,
                contentViewMode.contentFullMode &&
                  `${STATEMENT_CONTENT_BLOCK}__left-panel--full-mode`,
              )}
              id={`${STATEMENT_CONTENT_ID_BLOCK}__left-panel`}
            >
              <Toolkit />
              <StatementNavigatorPanel />
              <TickmarkPanel />
              <ExportPanel />
              <WorkPaperPanel />
              <CustomMenuPanel />
              <AuditCheckListPanel />
              <ComfortAnnotationPanel />
              {blacklineViewMode && <ComparePanel />}
            </div>
            <div
              ref={this.centerPanelRef}
              className={classnames(
                `${STATEMENT_CONTENT_BLOCK}__center-panel`,
                contentViewMode.contentFullMode &&
                  `${STATEMENT_CONTENT_BLOCK}__center-panel--full-mode`,
              )}
              id={CENTER_PANEL_ID}
            >
              <div id={TABLE_CONTROLS_ID} />
              <BatchSelectControl
                setBatchModeSelectedIfPossible={
                  setElementSelectModeBatchWithBannerAction
                }
                showBatchPanelAction={showBatchPanelAction}
              />
              <AnnotationDisplayFilter />
              <ZoomControlHOC
                isSideBySidePage={false}
                isLeftSideView={false}
                shouldDisplayExpandIcon
              />
              <BatchBanner />
              {selectedStatement.isReadOnly() && (
                <div
                  className={classnames(
                    `${STATEMENT_CONTENT_BLOCK}__center-panel-banner`,
                    isBatchModeSelected
                      ? `${STATEMENT_CONTENT_BLOCK}__readonly-top-fix`
                      : null,
                  )}
                >
                  <div>
                    <Lock
                      className={`${STATEMENT_CONTENT_BLOCK}__center-panel-banner-icon`}
                    />
                    <FormattedMessage
                      id={getReadOnlyBannerId(
                        selectedStatement,
                        urlParams,
                        workflowsMap,
                      )}
                      values={{
                        b: (...chunks) => <strong>{chunks}</strong>,
                        br: (
                          <br
                            key={`${STATEMENT_CONTENT_BLOCK}__read-only-info-brTag-${shortid.generate()}`}
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
              {blacklineViewMode && showBlackLineViewReadOnlyBanner && (
                <div
                  className={classnames(
                    `${STATEMENT_CONTENT_BLOCK}__center-panel-banner`,
                    isBatchModeSelected && selectedStatement.isReadOnly()
                      ? `${STATEMENT_CONTENT_BLOCK}__blackline-top-fix`
                      : isBatchModeSelected && !selectedStatement.isReadOnly()
                      ? `${STATEMENT_CONTENT_BLOCK}__readonly-top-fix`
                      : !isBatchModeSelected && selectedStatement.isReadOnly()
                      ? `${STATEMENT_CONTENT_BLOCK}__top-fix`
                      : null,
                  )}
                >
                  <div>
                    <Lock
                      className={`${STATEMENT_CONTENT_BLOCK}__center-panel-banner-icon`}
                    />
                    <FormattedMessage
                      id="selected-statement.blackline-banner"
                      values={{
                        br: (
                          <br
                            key={`${STATEMENT_CONTENT_BLOCK}__blackline-banner-brTag-${shortid.generate()}`}
                          />
                        ),
                      }}
                    />
                  </div>

                  <CloseButton
                    name={`{${STATEMENT_CONTENT_BLOCK}__button`}
                    role={'button'}
                    className={`{${STATEMENT_CONTENT_BLOCK}__close`}
                    width={BANNER_CLOSE_ICON_SIZE}
                    height={BANNER_CLOSE_ICON_SIZE}
                    onClick={() => {
                      this.setState({
                        showBlackLineViewReadOnlyBanner: false,
                      });
                    }}
                  />
                </div>
              )}
              <StatementContentPanelComponentHOC
                urlParams={urlParams}
                centerPanelRef={this.centerPanelRef}
                isContentPanelScrolling={isContentPanelScrolling}
                leftSideBySideView={false}
              />
            </div>
            <div
              className={classnames(
                `${STATEMENT_CONTENT_BLOCK}__right-panel`,
                contentViewMode.contentFullMode &&
                  `${STATEMENT_CONTENT_BLOCK}__right-panel--full-mode`,
              )}
              id={`${STATEMENT_CONTENT_ID_BLOCK}__right-panel`}
            >
              <ElementPanelContainer />
              <BatchPanel />
              <NotesPanel />
              <SectionDetailsPanel />
            </div>
          </div>
        </ConditionalRender>
      </Page>
    );
  }
}
StatementContent.propTypes = {
  /** url params object based on route */
  urlParams: PropTypes.object.isRequired,
  /** Action to initialize statement page data */
  initStatementContent: PropTypes.func.isRequired,
  /** Action to clear statement page data */
  clearStatementPageStore: PropTypes.func.isRequired,
  /** function fired to try setting element select mode to batch with banner */
  setElementSelectModeBatchWithBannerAction: PropTypes.func.isRequired,
  /** function fired to return element selct mode to default setting */
  clearBatchModeIfPossible: PropTypes.func.isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** function fired to fetch statement details */
  fetchSelectedStatement: PropTypes.func.isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Action to clear elements from workpapers elements list */
  clearElementsFromWorkpaper: PropTypes.func.isRequired,
  /** Action to disconnect signalr client */
  closeSocketClient: PropTypes.func.isRequired,
  /** Action to clear elements from tickmark elements list */
  clearElementsFromTickmark: PropTypes.func.isRequired,
  /**For tracing revisions processing/failed*/
  workflowsMap: PropTypes.instanceOf(WorkflowsMap).isRequired,
  /** Action to clear elements from section tree list */
  clearSectionTreeListAction: PropTypes.func.isRequired,
  /**Action to clear copy formula selection, data, if any*/
  cancelCopyFormula: PropTypes.func.isRequired,
  /** Flag to check if batch mode is selected */
  isBatchModeSelected: PropTypes.bool.isRequired,
  /**action to set loading icon while statement content loads */
  selectedStatementLoading: PropTypes.func.isRequired,
  // project id for the currently selected project
  selectedProjectId: PropTypes.string,
};

export default StatementContent;
