import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getInternalReferenceById = ({ internalReferenceId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/internalreference/${internalReferenceId}`,
    )
    .then((response) => {
      return response;
    });

export const getInternalReferenceByRevisionAndElementId = ({
  revisionId,
  elementId,
}) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/element/${elementId}/internalreference`,
    )
    .then((response) => {
      return response;
    });

export const createOrAddToInternalReferenceRequest = ({
  revisionId,
  sourceElementId,
  targetElementId,
  isFromSuggestion,
}) =>
  withDefaultContextAsSelectedProject()
    .post(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/source/${sourceElementId}/target/${targetElementId}/internalreference/isfromsuggestion/${isFromSuggestion}`,
    )
    .then((response) => {
      return response;
    });

export const detachElementFromInternalReferenceRequest = ({
  revisionId,
  internalReferenceId,
  elementId,
}) =>
  withDefaultContextAsSelectedProject()
    .delete(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/element/${elementId}/reference/${internalReferenceId}`,
    )
    .then((response) => {
      return response;
    });

export const getInternalReferenceSuggestionsByRevisionAndElementId = ({
  revisionId,
  elementId,
}) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/elements/${elementId}/internalreference/suggestions`,
    )
    .then((response) => {
      return response;
    });

export const removeSuggestedReferenceRequest = ({
  revisionId,
  sourceElementId,
  suggestedElementId,
}) =>
  withDefaultContextAsSelectedProject().post(
    `/tieout/statements/${getStatementIdUtil()}/revision/${revisionId}/reference/${sourceElementId}/suggestions/${suggestedElementId}`,
  );
