import { handleActions } from 'redux-actions';
import { setHeadingAssignmentRefresh } from 'store/actions/section-assignment-heading-refresh-actions';

export default handleActions(
  {
    [setHeadingAssignmentRefresh]: (refresh, { payload }) => {
      return payload;
    },
  },
  false,
);
