import BaseModel from 'models/base-model';
export default class SideBySideViewZoom extends BaseModel({
  currentZoom: 100, // default
  maxZoom: 500,
  minZoom: 10,
  stepValue: 10,
}) {
  get zoomRatio() {
    return this.currentZoom / 100;
  }
  incrementZoom() {
    let newZoomValue = this.currentZoom + this.stepValue;
    if (newZoomValue > this.maxZoom) {
      newZoomValue = this.maxZoom;
    }

    return this.merge({ currentZoom: newZoomValue });
  }

  decrementZoom() {
    let newZoomValue = this.currentZoom - this.stepValue;
    if (newZoomValue < this.minZoom) {
      newZoomValue = this.minZoom;
    }

    return this.merge({ currentZoom: newZoomValue });
  }

  disableIncrement() {
    return this.currentZoom >= this.maxZoom;
  }

  disableDecrement() {
    return this.currentZoom <= this.minZoom;
  }
}
