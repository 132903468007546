import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Tooltip from 'components/common/tool-tip-component';
import { FormattedMessage } from 'react-intl';

import {
  getProjectKebabOptions,
  SUPPORT_TEAM_ACCESS_TOOLTIP,
  COE_TEAM_ACCESS_TOOLTIP,
} from 'constants/feature/project-list-constants';
import Permissions from 'permissions/permissions';
import ProjectListEntry from 'models/data/project-list-entry-model';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { KebabDropdown } from 'components/common/dropdown-component';
import { DATE_FORMATS } from 'constants/util/date-constants';
import { ReactComponent as TrashCan } from 'icons/trash-can.svg';
import { ReactComponent as Shield } from 'icons/shield.svg';
import { ReactComponent as Rocket } from 'icons/rocket.svg';
import { getPipeColorForProject } from 'utils/project-utils';
import SoftDeleteModal from 'components/feature/project-list/soft-delete-project-modal';
import RestoreProjectModal from 'components/feature/project-list/restore-project-modal';
import PermanentDeleteModal from 'components/feature/project-list/permanent-delete-project-modal';
import { PROJECT_LIST_SCROLL_CONTAINER_ID } from './project-list-component';
import { ReactComponent as OmniaIcon } from 'icons/omnia-icon.svg';

const KEBAB_WIDTH = '24px';
export const PROJECT_CARD_BLOCK = 'project-card';
let PROJECT_CARD_ID_BLOCK = 'project-card-id';
const TRASH_CAN_ICON_SIZE = '20px';
const SHIELD_ICON_SIZE = '16px';
const ROCKET_ICON_SIZE = '16px';
const OMNIA_ICON_SIZE = '22px';

class ProjectListCard extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      showSoftDeleteModal: false,
      showPermanentDeleteModal: false,
      showRestoreModal: false,
    };
  }
  componentDidMount() {
    this._scrollToSelectedProject();
  }

  _scrollToSelectedProject = () => {
    const { project, selectedProjectID } = this.props;
    if (project.id === selectedProjectID) {
      const positionCardInViewHeight =
        this.ref.current.offsetTop - this.ref.current.offsetHeight;
      document.getElementById(PROJECT_LIST_SCROLL_CONTAINER_ID).scrollTo({
        top: positionCardInViewHeight,
        behavior: 'smooth',
      });
    }
  };

  _toggleSoftDeleteModal = () => {
    this.setState((state, props) => {
      return {
        showSoftDeleteModal: !state.showSoftDeleteModal,
      };
    });
  };

  _toggleRestoreModal = () => {
    this.setState((state, props) => {
      return {
        showRestoreModal: !state.showRestoreModal,
      };
    });
  };

  _togglePermanentDeleteModal = () => {
    this.setState((state, props) => {
      return {
        showPermanentDeleteModal: !state.showPermanentDeleteModal,
      };
    });
  };

  render() {
    const {
      project,
      onClick,
      softDeleteAction,
      permanentDeleteAction,
      restoreAction,
      selectedProjectID,
      push,
      index,
      updateFilteredProjectList,
    } = this.props;
    const { showSoftDeleteModal, showPermanentDeleteModal, showRestoreModal } =
      this.state;
    const {
      id,
      name,
      engagementEntity: { clientName, engagementFye, engagementName },
      omniaEngagementEntity,
      deleteDate,
      legalHold,
      hasAdminGroup,
      hasCoeGroup,
      geoCode,
    } = project;

    const _showProjectCardKebab =
      !project.isDeleted() ||
      Permissions.Project.canRestore(project.id) ||
      Permissions.Project.canPermanentlyDelete(project.id);
    return (
      <React.Fragment>
        <div
          className={classNames(
            `${PROJECT_CARD_BLOCK}`,
            selectedProjectID === id ? `${PROJECT_CARD_BLOCK}--selected` : null,
          )}
          onClick={onClick}
          ref={this.ref}
        >
          {_showProjectCardKebab ? (
            <KebabDropdown
              className={`${PROJECT_CARD_BLOCK}__kebab`}
              menuClassName={`${PROJECT_CARD_BLOCK}__kebab-menu`}
              id={`project-${id}-kebab`}
              options={getProjectKebabOptions({
                cardIndex: index,
                project,
                onSoftDeleteClick: this._toggleSoftDeleteModal,
                onRestoreClick: this._toggleRestoreModal,
                onPermanentDeleteClick: this._togglePermanentDeleteModal,
                push,
              })}
              width={KEBAB_WIDTH}
              onClickFormItem={(e) => {
                e.stopPropagation();
              }}
            />
          ) : null}
          <div
            className={classNames(
              `${PROJECT_CARD_BLOCK}__list-item`,
              `${PROJECT_CARD_BLOCK}__title`,
              project.isDeleted()
                ? `${PROJECT_CARD_BLOCK}__title--deleted`
                : null,
              hasAdminGroup
                ? `${PROJECT_CARD_BLOCK}__title--admin-group`
                : null,
            )}
            style={
              !project.isDeleted() && !hasAdminGroup
                ? {
                    borderLeft: `solid 3px ${getPipeColorForProject(
                      project.id,
                    )}`,
                  }
                : null
            }
          >
            {omniaEngagementEntity.engagementName !== null ? (
              <>
                <OmniaIcon
                  className={`${PROJECT_CARD_BLOCK}__omnia-icon`}
                  width={OMNIA_ICON_SIZE}
                  height={OMNIA_ICON_SIZE}
                />
              </>
            ) : null}
            {hasAdminGroup ? (
              <Tooltip {...SUPPORT_TEAM_ACCESS_TOOLTIP}>
                <Shield
                  height={SHIELD_ICON_SIZE}
                  width={SHIELD_ICON_SIZE}
                  className={`${PROJECT_CARD_BLOCK}__shield`}
                />
              </Tooltip>
            ) : null}
            {hasCoeGroup ? (
              <Tooltip {...COE_TEAM_ACCESS_TOOLTIP}>
                <Rocket
                  height={ROCKET_ICON_SIZE}
                  width={ROCKET_ICON_SIZE}
                  className={`${PROJECT_CARD_BLOCK}__rocket`}
                />
              </Tooltip>
            ) : null}
            {project.isDeleted() ? (
              <TrashCan
                id={`${PROJECT_CARD_BLOCK}-${id}-delete-icon`}
                className={`${PROJECT_CARD_BLOCK}__delete-icon`}
                width={TRASH_CAN_ICON_SIZE}
                height={TRASH_CAN_ICON_SIZE}
              />
            ) : null}
            <MultiLineEllipsis
              toolTipID={`${PROJECT_CARD_ID_BLOCK}-${id}-title`}
              tooltipPosition={index === 0 ? 'bottom' : 'top'}
              text={name}
              maxLines={1}
              isNotInternationalized={true}
            />
            {legalHold ? (
              <div className={`${PROJECT_CARD_BLOCK}__hold`}>
                <FormattedMessage id={'common.hold'} />
              </div>
            ) : null}
          </div>
          {project.isDeleted() ? (
            <div
              className={classNames(
                `${PROJECT_CARD_BLOCK}__list-item`,
                `${PROJECT_CARD_BLOCK}__deleted-date`,
              )}
            >
              <FormattedMessage
                id="project-card.deleted-label"
                values={{
                  deletedDate: moment
                    .utc(deleteDate)
                    .format(DATE_FORMATS.MONTH_DAY_YEAR),
                }}
              />
            </div>
          ) : null}
          <div
            className={classNames(
              `${PROJECT_CARD_BLOCK}__list-item`,
              `${PROJECT_CARD_BLOCK}__client`,
            )}
          >
            {clientName ? (
              <MultiLineEllipsis
                toolTipID={`${PROJECT_CARD_ID_BLOCK}-${id}-client`}
                text={clientName}
                maxLines={2}
                isNotInternationalized={true}
              />
            ) : null}
          </div>
          <div></div>
          <div
            className={classNames(
              `${PROJECT_CARD_BLOCK}__list-item`,
              `${PROJECT_CARD_BLOCK}__engagement`,
            )}
          >
            {engagementName ? (
              <MultiLineEllipsis
                toolTipID={`${PROJECT_CARD_ID_BLOCK}-${id}-engagement`}
                text={engagementName}
                maxLines={2}
                isNotInternationalized={true}
                tooltipPosition={'right'}
              />
            ) : null}
          </div>
          <div className={`${PROJECT_CARD_BLOCK}__display-horizontal`}>
            <div
              className={classNames(
                `${PROJECT_CARD_BLOCK}__list-item`,
                `${PROJECT_CARD_BLOCK}__geo-code`,
              )}
            >
              <FormattedMessage
                id="project-card.geo-label"
                values={{
                  region: geoCode,
                }}
              />
            </div>
            <div
              className={classNames(
                `${PROJECT_CARD_BLOCK}__list-item`,
                `${PROJECT_CARD_BLOCK}__period-ending`,
              )}
            >
              {engagementFye
                ? moment(engagementFye).utc().format(DATE_FORMATS.YEAR)
                : ''}
            </div>
          </div>
        </div>
        {showSoftDeleteModal ? (
          <SoftDeleteModal
            toggleSoftDeleteModal={this._toggleSoftDeleteModal}
            softDeleteAction={softDeleteAction}
            project={project}
            updateFilteredProjectList={updateFilteredProjectList}
          />
        ) : null}
        {showRestoreModal ? (
          <RestoreProjectModal
            toggleRestoreModal={this._toggleRestoreModal}
            restoreAction={restoreAction}
            project={project}
            updateFilteredProjectList={updateFilteredProjectList}
          />
        ) : null}
        {showPermanentDeleteModal ? (
          <PermanentDeleteModal
            togglePermanentDeleteModal={this._togglePermanentDeleteModal}
            permanentDeleteAction={permanentDeleteAction}
            updateFilteredProjectList={updateFilteredProjectList}
            project={project}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ProjectListCard.propTypes = {
  /** project the project that is to be displayed through this card*/
  project: PropTypes.instanceOf(ProjectListEntry),
  /** number that represents the currently selected project ID from the store.*/
  selectedProjectID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** function that governs the behaviour when a project card is clicked upon.*/
  onClick: PropTypes.func,
  /** function that governs the behaviour when the soft delete option is clicked on the project card.*/
  softDeleteAction: PropTypes.func,
  /** function that governs the behaviour when the restore option is clicked on the project card.*/
  restoreAction: PropTypes.func,
  /** function that governs the behaviour when the permanent delete option is clicked on the project card.*/
  permanentDeleteAction: PropTypes.func,
  /** index of card in the list */
  index: PropTypes.number.isRequired,
  /** function fired to update project list with latest project data */
  updateFilteredProjectList: PropTypes.func.isRequired,
};

export default ProjectListCard;
