import io from 'socket.io-client';
import { checkIfExistingTokenIsValidAndFetchNewIfExpired } from 'api/api-authentication';
import { getStore } from 'store/store';
import { setSocketDisconnectAction } from 'store/actions/statement-socket-actions';
import { setProjectSocketDisconnectAction } from 'store/actions/project-socket-actions';
//import { addUserToRevision } from 'store/actions/active-users-actions';

const globalClientConfig = {};

export const rooms = {
  revision(projectId, revisionId) {
    return `projects/${parseInt(projectId)}/revisions/${revisionId}`;
  },
  project(projectId) {
    return `projects/${parseInt(projectId)}`;
  },
};

export const SOCKET_CONNECTION_TYPE = {
  REVISION: 'REVISION',
  PROJECT: 'PROJECT',
};

export default globalClientConfig;

const UNIT_TIME = 1000; // 1 second
const RECONNECT_DELAY_TIME = UNIT_TIME * 5; // 5 seconds

// attempts are going to be --- 5th second, 10th second, 20th second, 40th second, 80th second, 160th seond
const RECONNECT_DELAY_TIME_MAX = RECONNECT_DELAY_TIME * 40; // 200 seconds

/*
  Example from socket.io docs:

  const socket = new Socket({
    autoConnect: true,
    url: 'wss://socketio-chat-h9jt.herokuapp.com',
  });
*/

export class SocketClient {
  accessToken = null;
  autoConnect = false;
  callbackQueue = [];
  isConnected = false;
  rooms = new Map();
  projectId = null;
  revisionId = null;
  userId = null;
  socketConnectionType = null;
  geoCode = null;

  constructor(config) {
    this.connectWithRetry = this.connectWithRetry.bind(this);
    //localStorage.debug = '*';
    Object.assign(this, globalClientConfig, config);
    if (this.autoConnect) {
      this.connectWithRetry();
    }
  }

  async connect() {
    if (!this.accessToken && !this.geoCode) {
      return false;
    }
    const { dispatch } = getStore();
    this.socket = io(
      `${window.TIEOUT.ENV.GEOS[this.geoCode].BASE_HUB_URL}/tieout`,
      {
        // how long to initially wait before attempting a new reconnection. Affected by +/- randomizationFactor
        reconnectionDelay: RECONNECT_DELAY_TIME,
        // number of reconnection attempts before giving up
        reconnectionAttempts: 6,
        // maximum amount of time to wait between reconnections. Each attempt increases the reconnection delay by 2x along with a randomization factor
        reconnectionDelayMax: RECONNECT_DELAY_TIME_MAX,
        // The randomization factor is used when reconnecting (so that the clients do not reconnect at the exact same time after a server crash, for example).
        // Example- 2nd attempt after =  RECONNECT_DELAY_TIME * 2^1 * (something between 0.8 and 1.2)
        // I have kept it low so that time taken between reconnection attempts does not becomes very unpredicatable.
        randomizationFactor: 0.2,
        transports: ['websocket', 'polling'], // use WebSocket first, if available
        rememberUpgrade: true,
        auth: async (callback) =>
          callback({
            token: `Bearer ${await checkIfExistingTokenIsValidAndFetchNewIfExpired(
              this.geoCode,
            )}`,
          }),
      },
    );
    this.socket.on('connect', () => {
      this.isConnected = true;
      console.info('socket connected');
      this._setSocketConnectionState(this.socket.id);
      this.executeCallbacks();
    });
    this.socket.on('connect_error', (e) => {
      this.isConnected = false;
      console.info('error while connecting to socket', e);
      // revert to classic upgrade
      this.socket.io.opts.transports = ['polling', 'websocket'];
      this.socketConnectionType === SOCKET_CONNECTION_TYPE.PROJECT
        ? dispatch(setProjectSocketDisconnectAction(true))
        : dispatch(setSocketDisconnectAction(true));
    });
    this.socket.on('disconnect', () => {
      this.isConnected = false;
      console.info('socket disconnected');

      this._deleteSocketRoom();

      this.socketConnectionType === SOCKET_CONNECTION_TYPE.PROJECT
        ? // this is somewhat hacky solution.
          // we cannot dispatch action from within a reducer, but we can wrap inside setTimeout and dispatch it.
          // Generally it is not recommended to dispatch from reducer. But in this scenario, we are recieving
          // disconnect event from socket server while store is still updating. (To repro this --> go to home page, switch project)
          setTimeout(() => dispatch(setProjectSocketDisconnectAction(true)), 0)
        : setTimeout(() => dispatch(setSocketDisconnectAction(true)), 0);

      this.executeCallbacks();
    });

    this.socket.io.on('reconnect_attempt', (attempt) => {
      this.isConnected = false;
      console.info('socket reconnecting ,  reconnect_attempt:', attempt);

      this.socketConnectionType === SOCKET_CONNECTION_TYPE.PROJECT
        ? dispatch(setProjectSocketDisconnectAction(true))
        : dispatch(setSocketDisconnectAction(true));
    });

    this.socket.io.on('reconnect_failed', () => {
      this.isConnected = false;
      console.info('reconnect_failed');

      this.socketConnectionType === SOCKET_CONNECTION_TYPE.PROJECT
        ? dispatch(setProjectSocketDisconnectAction(true))
        : dispatch(setSocketDisconnectAction(true));
    });

    return true;
  }

  async connectWithRetry() {
    if (this.isConnected) {
      return;
    }
    if (!this.accessToken && this.geoCode) {
      this.accessToken = await checkIfExistingTokenIsValidAndFetchNewIfExpired(
        this.geoCode,
      );
      this.autoConnect = true;
    }
    if (!(await this.connect())) {
      setTimeout(this.connectWithRetry, 30000);
    }
  }

  async disconnect() {
    if (!this.socket) {
      return false;
    }
    this.socket.close();
    this.rooms = new Map();
    return true;
  }

  queueCallback(callback) {
    if (this.isConnected) {
      callback();
    } else {
      this.callbackQueue.push(callback);
    }
  }

  executeCallbacks() {
    if (this.isConnected) {
      while (this.callbackQueue.length) {
        this.callbackQueue.shift()();
      }
    }
  }

  on(event, callback) {
    this.queueCallback(() => this.socket.on(event, callback));
  }

  join(room) {
    this.queueCallback(() => {
      if (this.rooms.get(room)) {
        return;
      }
      this.rooms.set(room, true);
      this.socket.emit('join', {
        accessToken: this.accessToken,
        userId: this.userId,
        room,
      });
      console.debug('socketIO- room joined:', room);
    });
  }

  leave(room) {
    this.queueCallback(() => {
      if (!this.rooms.get(room)) {
        return;
      }
      this.rooms.delete(room);
      this.socket.emit('leave', {
        accessToken: this.accessToken,
        room,
      });
      console.debug('socketIO- room left:', room);
    });
  }

  _setSocketConnectionState = (connectionId) => {
    const { dispatch } = getStore();
    const { currentUser } = getStore().getState().data;
    // before joining a room we need userId info so that we can record info about which user in the room
    this.userId = currentUser.id;
    switch (this.socketConnectionType) {
      case SOCKET_CONNECTION_TYPE.PROJECT:
        localStorage.setItem('projectConnectionId', connectionId);

        // Wait for disconnect event (Event recieved from Socket server) to complete before setting the connect state.
        // Otherwise else we might end up reading the wrong 'connected' status.
        // Setting sequence using async await was not feasible since we do not always connect after disconnect.
        setTimeout(
          () => dispatch(setProjectSocketDisconnectAction(false)),
          3000,
        );
        this.join(rooms.project(this.projectId));
        break;
      case SOCKET_CONNECTION_TYPE.REVISION:
        localStorage.setItem('connectionId', connectionId);
        // Wait for disconnect event (event recieved from socket server) to complete before setting the connect state.
        // Otherwise else we might end up reading the wrong 'connected' status.
        // Setting sequence using async await was not feasible since we do not always connect after disconnect.
        setTimeout(() => dispatch(setSocketDisconnectAction(false)), 3000);
        this.join(rooms.revision(this.projectId, this.revisionId));
        // TODO : Code to be uncommented when we are developing feature related to instant update of active users on statement.
        // dispatch(addUserToRevision(this.revisionId, this.projectId));
        break;
      default:
    }
  };

  _closeSocketConnection = () => {
    switch (this.socketConnectionType) {
      case SOCKET_CONNECTION_TYPE.PROJECT:
        this.leave(rooms.project(this.projectId));
        break;
      case SOCKET_CONNECTION_TYPE.REVISION:
        this.leave(rooms.revision(this.projectId, this.revisionId));
        break;
      default:
    }
  };

  _deleteSocketRoom = () => {
    let projectSpecificRoom = rooms.project(this.projectId);
    let revisionSpecificRoom = rooms.revision(this.projectId, this.revisionId);
    switch (this.socketConnectionType) {
      case SOCKET_CONNECTION_TYPE.PROJECT:
        if (!this.rooms.get(projectSpecificRoom)) {
          return;
        }
        this.rooms.delete(projectSpecificRoom);
        break;
      case SOCKET_CONNECTION_TYPE.REVISION:
        if (!this.rooms.get(revisionSpecificRoom)) {
          return;
        }
        this.rooms.delete(revisionSpecificRoom);
        break;
      default:
    }
  };

  _handleProjectDisconnect = () => {
    const { dispatch } = getStore();
    switch (this.socket) {
      case SOCKET_CONNECTION_TYPE.PROJECT:
        dispatch(setProjectSocketDisconnectAction(true));
        break;
      case SOCKET_CONNECTION_TYPE.REVISION:
        dispatch(setSocketDisconnectAction(true));
        break;
      default:
    }
  };
}
