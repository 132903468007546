import React, { useState, useEffect } from 'react';
import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import PropTypes from 'prop-types';
import StatementNavBulkSelectUsers from './_statement-nav-bulk-select-users';
import StatementNavBulkSelectSections from './_statement-nav-bulk-select-sections';
import Checkbox from 'components/common/checkbox-component';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import SectionAssignmentsList from 'models/api/section-assignments-list-api-model';
import {
  createRevisionSectionAssignment,
  unassignRevisionSectionAssignment,
} from 'api/bulk-assign-unassign-api';
import Loading from 'components/common/loading-component';
import shortid from 'shortid';
import { getUserNameByUserId } from 'utils/statement-content-page-utils';

export const BULK_ASSIGN_SECTIONS_MODAL_BLOCK = 'bulk-assign-sections';
export const BULK_ASSIGN_SECTIONS_MODAL_ID = 'bulk-assign-sections-id';

const BulkAssignSectionsModal = ({
  onClose,
  usersList,
  revisionId,
  sectionTreeList,
  sectionAssignments,
  currentSection,
  updateBulkSectionAssignmentsList,
  socketHasBeenDisconnected,
}) => {
  const [collapsibleNodesState, setCollapsibleNodesState] = useState(null);

  const [sectionsChecked, setSectionschecked] = React.useState(new Map());
  const [checkAll, setCheckAll] = useState(false);
  const [checkedCount, setCheckedCount] = React.useState(0);

  const [assignedUsers, setAssignedUsers] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [disableUnassignButton, setDisableUnassignButton] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const max_count = useRef(0);
  const BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE = true;

  React.useEffect(() => {
    setCollapsibleNodesState(
      sectionTreeList.createCollapseStateMapFromNodeIds({
        initNodeValue: BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE,
      }),
    );
    setSectionschecked(
      sectionTreeList.createContentSearchFilterState({ initNodeValue: false }),
    );
    max_count.current = [
      ...sectionTreeList.createContentSearchFilterState({
        initNodeValue: false,
      }),
    ].length;
  }, [BULK_ASSIGNMENT_DEFAULT_EXPANDED_VALUE, sectionTreeList]);

  useEffect(() => {
    setSectionschecked(
      sectionTreeList.createContentSearchFilterState({
        initNodeValue: checkAll,
      }),
    );
    setCheckedCount(checkAll === true ? max_count.current : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAll]);

  const toggleNode = (id) => {
    setCollapsibleNodesState(
      new Map([...collapsibleNodesState, [id, !collapsibleNodesState.get(id)]]),
    );
  };

  const setChecked = (id, val) => {
    setSectionschecked((prevSectionsChecked) =>
      new Map(prevSectionsChecked).set(id, val),
    );
    val === true
      ? setCheckedCount((prev) => prev + 1)
      : setCheckedCount((prev) => prev - 1);
  };

  const setAllChecked = (val = true) => {
    setCheckAll(val);
  };

  const renderTree = (nodes) =>
    Array.isArray(nodes) &&
    nodes.map((node, i) => {
      return (
        <StatementNavBulkSelectSections
          key={`${node.id}`}
          id={node.id}
          label={node.name}
          expanded={collapsibleNodesState.get(node.id)}
          toggle={(id) => toggleNode(id)}
          checked={sectionsChecked.get(node.id) === true}
          setChecked={setChecked}
          sectionAssignments={sectionAssignments}
          usersList={usersList}
        >
          {renderTree(node.children)}
        </StatementNavBulkSelectSections>
      );
    });

  const handleUserSelect = (user) => {
    setAssignedUsers({
      ...assignedUsers,
      [user.id]: {
        name: getUserNameByUserId(user.id),
      },
    });
  };

  const handleChipRemove = (key) => {
    let assignedUsersMap = { ...assignedUsers };
    if (key in assignedUsers) {
      delete assignedUsersMap[key];
    }
    setAssignedUsers(assignedUsersMap);
  };

  const handleSave = async () => {
    setDisableSaveButton(true);
    setLoading(true);
    let sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    const userIds = Object.keys(assignedUsers);
    await createRevisionSectionAssignment({ revisionId, sectionIds, userIds });
    if (socketHasBeenDisconnected) {
      await updateBulkSectionAssignmentsList({
        revisionId,
        sectionId: currentSection.id,
        usersList: usersList,
      });
    }

    onClose();
  };

  const handleUnassign = async () => {
    setDisableUnassignButton(true);
    setLoading(true);
    let sectionIds = [];
    sectionsChecked.forEach((value, key) => {
      value && sectionIds.push(key);
    });

    const userIds = Object.keys(assignedUsers);
    await unassignRevisionSectionAssignment({
      revisionId,
      sectionIds,
      userIds,
    });
    if (socketHasBeenDisconnected) {
      await updateBulkSectionAssignmentsList({
        revisionId,
        sectionId: currentSection.id,
        usersList: usersList,
      });
    }
    onClose();
  };

  return (
    <>
      <Modal
        id={BULK_ASSIGN_SECTIONS_MODAL_ID}
        title={'statement-nav-bulk-assign-modal-title.bulk.heading.assign'}
        onClose={onClose}
        primaryModalButton={
          new ModalButton({
            text: 'section-details.assign-button',
            onClick: handleSave,
            disabled:
              ![...sectionsChecked.values()].some((ele) => ele === true) ||
              !Object.keys(assignedUsers).length > 0 ||
              disableSaveButton,
          })
        }
        secondaryModalButton={
          new ModalButton({
            text: 'common.cancel',
            onClick: onClose,
          })
        }
        tertiaryModalButton={
          new ModalButton({
            text: 'statement-nav-bulk-unassign.button',
            onClick: handleUnassign,
            disabled:
              ![...sectionsChecked.values()].some((ele) => ele === true) ||
              disableUnassignButton,
          })
        }
      >
        <Banner
          width={'100%'}
          id={`${BULK_ASSIGN_SECTIONS_MODAL_ID}-warning-guidance`}
          className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__guidance`}
          type={BANNER_TYPES.WARNING}
          bannerCopy={{
            id:
              'statement-nav-bulk-assign-modal-warning-guidance.select.multiple.heading.sections',
            values: {
              b: (...chunks) => <strong>{chunks}</strong>,
              lineBreak: (
                <br
                  key={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}-brTag-${shortid.generate()}}`}
                />
              ),
            },
          }}
          isInternationalized
        />
        {loading ? (
          <Loading />
        ) : (
          <div>
            <StatementNavBulkSelectUsers
              usersList={usersList}
              handleUserSelect={handleUserSelect}
              handleChipRemove={handleChipRemove}
              assignedUsers={assignedUsers}
            />
            <div
              className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__select-all-and-count`}
            >
              <Checkbox
                masterChecked={
                  checkedCount > 0 && checkedCount < max_count.current
                }
                checked={checkAll}
                className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__item--col1__checkbox`}
                disabled={false}
                id={`${BULK_ASSIGN_SECTIONS_MODAL_ID}-select-all-checkbox`}
                name={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}-select-all-checkbox`}
                onChange={() => {
                  setAllChecked((prev) => !prev);
                }}
                isNotIntl={false}
                label={'common.master-checkbox'}
              />
              <p className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__count`}>
                <FormattedMessage
                  id={'custom-menu-panel-content-total-selected.description'}
                  values={{ count: checkedCount }}
                />
              </p>
            </div>
            <div
              className={`${BULK_ASSIGN_SECTIONS_MODAL_BLOCK}__tree-view-container`}
            >
              {collapsibleNodesState &&
                renderTree(sectionTreeList.sectionsHierarchy)}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

BulkAssignSectionsModal.propTypes = {
  /**function fired to close the modal */
  onClose: PropTypes.func.isRequired,
  /**Array which contains objects containing user details */
  usersList: PropTypes.array.isRequired,
  /**An object which contains list of sections */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList).isRequired,
  /** the id of the selected revision */
  revisionId: PropTypes.number.isRequired,
  /** A reference to the assignments of the selected section */
  sectionAssignments: PropTypes.instanceOf(SectionAssignmentsList).isRequired,
  /** Section from the section detail store */
  currentSection: PropTypes.object.isRequired,
  /** Action to update/fetch bulk assignments for a section */
  updateBulkSectionAssignmentsList: PropTypes.func.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
};

export default BulkAssignSectionsModal;
