import React from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

export const NOTES_TEXT_AREA_BLOCK = 'notes-text-area';

const NotesTextArea = ({ intl, onChange, value, data, placeholder }) => {
  const renderSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
  ) => {
    return (
      <div
        className={classnames(focused && `${NOTES_TEXT_AREA_BLOCK}__focused`)}
      >
        {highlightedDisplay}
      </div>
    );
  };

  return (
    <MentionsInput
      className={`${NOTES_TEXT_AREA_BLOCK}`}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={intl.formatMessage({
        id: placeholder,
      })}
      allowSpaceInQuery={true}
    >
      <Mention
        trigger="@"
        markup={'<span cfto_user_id="__id__">@__display__</span>'}
        data={data}
        appendSpaceOnAdd={true}
        displayTransform={(id, display) => `@<${display}>`}
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
};

NotesTextArea.propTypes = {
  /** event handler for handling changes in the text box */
  onChange: PropTypes.func.isRequired,
  /** Current value of the text area*/
  value: PropTypes.string.isRequired,
  /** a list of elements to render on the suggestions tab*/
  data: PropTypes.array.isRequired,
  /** the default text to be render in the text box*/
  placeholder: PropTypes.string.isRequired,
};

export default injectIntl(NotesTextArea);
