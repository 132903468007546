import React, { memo } from 'react';
import PropTypes from 'prop-types';

const STATEMENT_PROGRESS_BAR_BLOCK = 'statement-progress-bar';

const StatementProgressBar = ({ percentVerified }) => {
  return (
    <div className={STATEMENT_PROGRESS_BAR_BLOCK}>
      <div
        className={`${STATEMENT_PROGRESS_BAR_BLOCK}__inner`}
        style={{ width: percentVerified ? `${percentVerified}%` : '0%' }}
      />
    </div>
  );
};

StatementProgressBar.propTypes = {
  /** String percentage of verified elements */
  percentVerified: PropTypes.number.isRequired,
};

export default memo(StatementProgressBar);
