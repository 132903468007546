import model from './base-model';
/**
 * Abstract class for different models that are used in React State or Redux AND that represent data that needs to be fetched from
 * the API
 * Comes with error, isLoaded, isLoading props which are used in tandem with the <ConditionalRender> component to render loaders while
 * data is loading.
 * Each api call should have 3 actions whose reducers call the instance methods setLoading, setError, and setLoaded durring the process
 * of executing the api call
 *
 * Each setLoaded function also calls a user defined processResponse function that must be defined for each ApiModel that extends this model
 * This function should set the data: {} property with all the actual api fields
 *
 * Should not be instantied by itself, it is meant to be extended different models that fetch API data.
 */
export default (defaultProps = {}) => {
  return class ApiModel extends model({
    error: null,
    isLoaded: false,
    isLoading: false,
    data: null,
    ...defaultProps,
  }) {
    setLoading() {
      return this.merge({
        isLoading: true,
        isLoaded: false,
      });
    }

    setError(error) {
      return this.merge({ error });
    }

    setLoaded(response) {
      const modelControlValues = this.processResponse(response);
      return this.merge({
        isLoaded: true,
        isLoading: false,
        error: null,
        ...modelControlValues,
      });
    }
    setLoadedWithoutResponse() {
      return this.merge({
        isLoaded: true,
        isLoading: false,
        error: null,
        data: this.data,
      });
    }

    processResponse(response) {
      throw Error(`
      A processResponse function needs to be defined on your api model which returns the appropriate shape of any instance control properties
      For instance if you have an api model called ProjectList that gets a list of projects, the return value of this function might look like
      {
        data: {
          projects: [] // array of projects
        },
        status: 0 // indication of status of the client
      }
      you need to define a processResponse method on your ProjectList model that formats your projects array
      this may look like
      processResponse(response) {
        return {
          projects: response.data.map(project => new Project(project))
          status: response.status,
        }
      }
    `);
    }

    /**
     * Expresses the state of of this model after the api call has been made.
     * We might not have data yet, but we know that data has at least been fetched because loading is true
     */
    isInitiated() {
      return this.isLoading || this.isLoaded;
    }

    mergeData(updates = {}) {
      const data = { ...this.data, ...updates };
      return this.merge({ data });
    }
  };
};
