import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import DocumentUploadStatusModel from 'models/utils/common/document-upload-status-model';

export const RETRY_TOOLTIP = new TooltipOptions({
  text: 'statement-processing.tooltip.retry',
  id: 'statement-processing-id-tooltip-retry',
  position: 'left',
});

export const DELETE_PERMANENTLY_TOOLTIP = new TooltipOptions({
  text: 'statement-processing.tooltip.delete-permanently',
  id: 'statement-processing-id-tooltip-delete-permanently',
  position: 'left',
});

export const MAXIMIZE_TOOLTIP = new TooltipOptions({
  text: 'common.maximize',
  id: 'statement-processing-id-tooltip-expand',
  position: 'left',
});

export const MINIMIZE_TOOLTIP = new TooltipOptions({
  text: 'common.minimize',
  id: 'statement-processing-id-tooltip-expand',
  position: 'left',
});

export const numOfDocProcessingSteps = 8;

export const DOCUMENT_UPLOAD_STATUSES = {
  UPLOADING: new DocumentUploadStatusModel({
    id: 1,
    status: 'UPLOADING',
    statusDisplay: 'Processing',
    currentStep: 1,
    totalSteps: numOfDocProcessingSteps,
  }),
  SCANNING: new DocumentUploadStatusModel({
    id: 2,
    status: 'SCANNING',
    statusDisplay: 'Processing',
    currentStep: 2,
    totalSteps: numOfDocProcessingSteps,
  }),
  CONVERTING: new DocumentUploadStatusModel({
    id: 3,
    status: 'CONVERTING',
    statusDisplay: 'Processing',
    currentStep: 3,
    totalSteps: numOfDocProcessingSteps,
  }),
  PENDING: new DocumentUploadStatusModel({
    id: 4,
    status: 'PENDING',
    statusDisplay: 'Processing',
    currentStep: 4,
    totalSteps: numOfDocProcessingSteps,
  }),
  PARSING: new DocumentUploadStatusModel({
    id: 5,
    status: 'PARSING',
    statusDisplay: 'Processing',
    currentStep: 5,
    totalSteps: numOfDocProcessingSteps,
  }),
  QUEUED: new DocumentUploadStatusModel({
    id: 6,
    status: 'QUEUED',
    statusDisplay: 'Processing',
    currentStep: 6,
    totalSteps: numOfDocProcessingSteps,
  }),
  PROCESSING: new DocumentUploadStatusModel({
    id: 7,
    status: 'PROCESSING',
    statusDisplay: 'Processing',
    currentStep: 7,
    totalSteps: numOfDocProcessingSteps,
  }),
  COMPLETED: new DocumentUploadStatusModel({
    id: 8,
    status: 'COMPLETED',
    statusDisplay: 'Processing',
    currentStep: 8,
    totalSteps: numOfDocProcessingSteps,
  }),
  FAILED: new DocumentUploadStatusModel({
    id: 9,
    status: 'FAILED',
    statusDisplay: 'failed',
    currentStep: 0,
    totalSteps: 0,
  }),
};
