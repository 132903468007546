import axios from 'axios';
import { createAuthContext } from 'api/api-authentication';
import { globalApiErrorHandler } from 'api/api-error-handler';
import {
  globalApiRequestValidator,
  getAxiosInstance,
} from 'api/api-request-validator';
import { getStore } from 'store/store';
import {
  getGeoForProjectId,
  getGeoForSelectedProjectId,
} from 'store/actions/selected-project-actions';
import { getHomeGeo } from 'store/actions/current-user-actions';
import { setProjectHeadersAction } from 'store/actions/project-list-actions';

export const CURRENT_SELECTED_PROJECT_ID_SESSION_KEY =
  'current-selected-project-id';

let apiClient = {};

export const undeterminedGeoKey = 'undetermined-geoKey';

export const CONTEXT_KEY = {
  PROJECT_CONTEXT: 'project',
  SELECTED_PROJECT_CONTEXT: 'selected-project',
  HOME_GEO_CONTEXT: 'home-geo',
  SELECTED_GEO_CONTEXT: 'selected-geo',
  // STATEMENT_CONTEXT: 'statement',
  // REVISION_CONTEXT: 'revision',
  // CURRENT_GEO_CONTEXT: 'current-geo',
};

// instantiate api client for all three geos
Object.keys(window.TIEOUT.ENV.GEOS).forEach(
  (geoKey) =>
    (apiClient[geoKey] = {
      api: null,
      flags: {
        authenticated: false,
      },
    }),
);

export const getSelectedProjectApiClient = () => {
  const projectGeo = getGeo({
    contextKey: CONTEXT_KEY.SELECTED_PROJECT_CONTEXT,
  });
  return getAPI(projectGeo);
};

export const getProjectApiClient = (projectID) => {
  const projectGeo = getGeo({
    contextKey: CONTEXT_KEY.PROJECT_CONTEXT,
    contextValue: projectID,
  });
  return getAPI(projectGeo, parseInt(projectID));
};

export const getHomeGeoApiClient = () => {
  const homeGeo = getGeo({
    contextKey: CONTEXT_KEY.HOME_GEO_CONTEXT,
  });
  return getAPI(homeGeo);
};

export const getSelectedGeoApiClient = (geoCode) => {
  return getAPI(geoCode);
};

export const getCurrentGeoApiClient = () => {
  const currentGeo = window.TIEOUT.ENV.CURRENT_GEO;
  return getAPI(currentGeo);
};

/**
 * The axiosInstance setup by this private function is used throughout the application to manage API calls to the back-end, using environment configured parameters.
 * Going forward we will be using this as an api client wrapper for all of our axios requests, saving us from having to set headers,
 * manage api urls, etc... throughout the API files. This setup will also likely change
 * to a collection of axios api constants referenced by region once we need to handle globalization in TieOut as well.
 *
 * TODO: Setup array of api variables for handling globalization.
 *
 * Note: This function should not be called externally, instead, retrieve the api through the getAPI function below.
 */
const _createAPI = (geoKey) => {
  /** axios instace for invalid geoKey */
  if (geoKey === undeterminedGeoKey) {
    console.warn('geoKey could not be determined for the project id');
    apiClient[`${undeterminedGeoKey}`] = getAxiosInstance();
    globalApiRequestValidator(undeterminedGeoKey);
    return;
  }
  /** axios instace for valid geoKey */
  apiClient[geoKey].api = axios.create({
    baseURL: window.TIEOUT.ENV.GEOS[geoKey].BASE_API_URL,
    /* Add all custom API settings we will need here. */
  });
  createAuthContext(geoKey);
  globalApiErrorHandler(geoKey);
  globalApiRequestValidator(geoKey);
};

export const getAPIClient = (geoKey) => {
  if (!(apiClient[geoKey] && apiClient[geoKey].api)) {
    _createAPI(geoKey);
  }
  return apiClient[geoKey];
};

/**
 * A public getter function to fetch the reference to the api elsewhere in the application. If no api reference has been intialized
 * yet, this function calls the `_createAPI()` function to create one.
 */
export const getAPI = (geoKey, projectId) => {
  if (!(apiClient[geoKey] && apiClient[geoKey].api)) {
    _createAPI(geoKey);
  }
  // set header (related to client id, container code, member firm code) before each api call
  getStore().dispatch(
    setProjectHeadersAction(apiClient[geoKey].api, projectId),
  );
  return apiClient[geoKey].api;
};

export const getGeo = ({ contextKey, contextValue }) => {
  const { dispatch } = getStore();
  switch (contextKey) {
    case CONTEXT_KEY.SELECTED_PROJECT_CONTEXT: {
      const projectGeo = dispatch(getGeoForSelectedProjectId());
      return projectGeo;
    }

    case CONTEXT_KEY.PROJECT_CONTEXT: {
      const projectGeo = dispatch(getGeoForProjectId(contextValue));
      return projectGeo;
    }

    case CONTEXT_KEY.HOME_GEO_CONTEXT: {
      const homeGeo = dispatch(getHomeGeo());
      return homeGeo;
    }

    // case CONTEXT_KEY.STATEMENT_CONTEXT:
    //   return 'GEO_1';

    // case CONTEXT_KEY.REVISION_CONTEXT:
    //   return window.TIEOUT.ENV.CURRENT_GEO;

    // case CONTEXT_KEY.CURRENT_GEO_CONTEXT:
    //   return window.TIEOUT.ENV.CURRENT_GEO;

    default:
      return window.TIEOUT.ENV.CURRENT_GEO;
  }
};
