import { getAPIClient } from 'api/api-client';
import axios from 'axios';

export const urlForUndeterminedGeoKey =
  'http://tieout.aaps.deloitte.com/fail-this-request-for-undetermined-geo';

export const isInvalidUrl = (url) =>
  url
    .split('/')
    .some((urlParam) => ['null', 'NaN', 'undefined'].includes(urlParam));

export const globalApiRequestValidator = (geoKey) => {
  const apiClient = getAPIClient(geoKey);
  apiClient.api.interceptors.request.use((requestConfig) => {
    console.debug('going to make api call for geo:', geoKey);
    if (isInvalidUrl(requestConfig.url)) {
      console.warn(
        'null or NaN data detected in url path. Hence aborting the request:',
        requestConfig.url,
      );
      console.trace();
      return Promise.reject(requestConfig);
    } else if (requestConfig.baseURL === urlForUndeterminedGeoKey) {
      return Promise.reject(requestConfig).catch((err) => {
        console.warn(
          'invalid axios instance as geoKey could not be determined from project id',
          err,
        );
        console.trace();
      });
    }
    return requestConfig;
  });
};

// creating temporary geokey instance when geokey is not determined from project id
export const getAxiosInstance = () => {
  let axiosInstance = {};
  axiosInstance['api'] = axios.create({
    baseURL: urlForUndeterminedGeoKey,
  });
  return axiosInstance;
};
