import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  UNIT_OPTIONS_MAP,
  SCALING_OPTIONS_MAP,
} from 'constants/feature/element-attribute-constants';

export default class BatchUpdateElementList extends ApiModel({
  selectedElementIds: [],
  selectedElementsMap: {
    // [element.id]: element
  },
}) {
  get count() {
    return (
      (Array.isArray(this.selectedElementIds) &&
        this.selectedElementIds.length) ||
      0
    );
  }

  appendMultipleElementsLoaded({ selectedElements }) {
    const selectedElementsMap = { ...this.selectedElementsMap };
    selectedElements.forEach(
      (element) => (selectedElementsMap[element.id] = element),
    );
    return this.merge({
      error: null,
      isLoading: false,
      isLoaded: true,
      selectedElementsMap,
      selectedElementIds: this._getNewElementIdsFromElementsMap(
        selectedElementsMap,
      ),
    });
  }

  hasElement(id) {
    return !isNullOrUndefined(this.selectedElementsMap[id]);
  }

  getElement(id) {
    return this.selectedElementsMap[id];
  }

  _getNewElementIdsFromElementsMap = (newElementsMap) =>
    Object.values(newElementsMap).map((element) => element.id);

  appendElement({ element }) {
    const selectedElementsMap = {
      ...this.selectedElementsMap,
      [element.id]: element,
    };

    return this.merge({
      selectedElementIds: this._getNewElementIdsFromElementsMap(
        selectedElementsMap,
      ),
      selectedElementsMap,
    });
  }

  removeElement({ elementId }) {
    const selectedElementsMap = { ...this.selectedElementsMap };
    delete selectedElementsMap[elementId];
    return this.merge({
      selectedElementsMap,
      selectedElementIds: this._getNewElementIdsFromElementsMap(
        selectedElementsMap,
      ),
    });
  }

  getSelectedElements() {
    return Object.values(this.selectedElementsMap).filter((element) =>
      element.data
        ? element.data.included === 1
        : element.included
        ? element.included === 1
        : null,
    );
  }

  updateSelectedElementsMapFromSocket(payload) {
    const selectedElementsMap = { ...this.selectedElementsMap };
    payload.forEach((element) => {
      const elementId = element.id;
      if (elementId in selectedElementsMap) {
        selectedElementsMap[elementId].data = {
          ...element, // may need to update this line if a reduced payload is sent down
          internalVerified: element.internalVerified === 1,
          reviewed: element.reviewed === 1,
          flagUser: element.flagUser === 1,
          units: UNIT_OPTIONS_MAP.get(element.unitsId),
          scaling: SCALING_OPTIONS_MAP.get(element.scalingId),
          period: `${element.periodType} ${element.fiscalYear}`,
          flagInternalRef: element.flagInternalRef === 1,
        };
      }
    });
    return this.merge({
      selectedElementsMap,
    });
  }

  removeBatchElementsFromSocketPayload(payload) {
    const selectedElementsMap = { ...this.selectedElementsMap };
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      const elementId = element.id;
      if (elementId in selectedElementsMap) {
        delete selectedElementsMap[elementId];
      }
    }
    return this.merge({
      selectedElementsMap: selectedElementsMap,
      selectedElementIds: this._getNewElementIdsFromElementsMap(
        selectedElementsMap,
      ),
    });
  }
}
