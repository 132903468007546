import { withDefaultContextAsSelectedProject } from './api-default-context';

export const getStatementRequest = (
  statementId,
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue }).get(
    `/tieout/statements/${statementId}`,
  );
