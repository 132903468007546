import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'components/common/tool-tip-component';
import SummarySections from 'models/api/statement-summary-sections-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { ReactComponent as TaskReviewedIcon } from 'icons/task-reviewed.svg';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import { FormattedMessage } from 'react-intl';

export const STATEMENT_SUMMARY_SECTIONS_BLOCK = 'statement-summary-sections';

const StatementSummarySectionsComponent = ({
  summarySections,
  displayOptions,
}) => {
  if (isNullOrUndefined(summarySections)) {
    return null;
  }

  return (
    displayOptions.signedOff && (
      <div className={STATEMENT_SUMMARY_SECTIONS_BLOCK}>
        <p className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__heading`}>
          <FormattedMessage id="statement-summary.heading.review" />
        </p>
        <Tooltip
          id={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__tooltip`}
          text="statement-summary.sections.label-tooltip"
          position="bottom"
        >
          <button className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__button`}>
            <TaskReviewedIcon
              className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__icon`}
            />
            <div className={`${STATEMENT_SUMMARY_SECTIONS_BLOCK}__sign-off`}>
              {displayOptions.percentage &&
                `${summarySections.reviewedPercentage()}%`}
              {displayOptions.count &&
                ` (${summarySections.reviewed}/${summarySections.total})`}
            </div>
          </button>
        </Tooltip>
      </div>
    )
  );
};

StatementSummarySectionsComponent.propTypes = {
  /** The count of notes for the selected revision id */
  summarySections: PropTypes.instanceOf(SummarySections),
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
};

export default StatementSummarySectionsComponent;
