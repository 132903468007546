import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import ToolTip from 'components/common/tool-tip-component';
import Radios from 'components/common/radio-component';
import NotesForm from 'models/form/notes-form-model';
import { connect } from 'react-redux';
import { note_type_options_array } from 'constants/feature/notes-panel-constants';
import { FormattedMessage } from 'react-intl';
import NoteSelection from 'models/data/note-content-selection-model';
import { smoothScrollIntoCenterById } from 'utils/scrolling-utils';
import NotesTextArea from 'components/feature/notes-panel/_notes-textarea-component';
import ProjectUsersList from 'models/api/project-users-list-api-model';
import { getUserNameByUserDataObject } from 'utils/statement-content-page-utils';
export const NOTES_PANEL_INPUT_AREA_BLOCK = 'notes-panel-input-area';
export const NOTES_PANEL_INPUT_AREA_ID_BLOCK = 'notes-panel-input-area-id';

const NotesInputArea = ({
  currentUserFullName,
  cancelAddNote,
  onCreateNote,
  noteInProgress,
  setNoteInProgress,
  addNoteSelection,
  projectUsersList,
  selectedProjectId,
}) => {
  const [disableAddNoteButton, setDisableAddNoteButton] = useState(false);
  useEffect(() => {
    smoothScrollIntoCenterById(NOTES_PANEL_INPUT_AREA_ID_BLOCK);
  }, [addNoteSelection]);
  return (
    <div
      className={NOTES_PANEL_INPUT_AREA_BLOCK}
      id={NOTES_PANEL_INPUT_AREA_ID_BLOCK}
    >
      <div className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__current-user`}>
        {currentUserFullName}
      </div>
      <ToolTip
        id={`${NOTES_PANEL_INPUT_AREA_BLOCK}__selectedText-tooltip`}
        text={addNoteSelection.selectedText}
        isNotInternationalized
        position={'top'}
      >
        <span className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__selected-text`}>
          {addNoteSelection.selectedText}
        </span>
      </ToolTip>
      <div className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__radio-buttons`}>
        <Radios
          id={`${NOTES_PANEL_INPUT_AREA_ID_BLOCK}-note-type`}
          selectedOption={noteInProgress.noteType}
          options={note_type_options_array(selectedProjectId)}
          isValid
          width={'auto'}
          onChange={(type) =>
            setNoteInProgress(noteInProgress.setNoteType({ type }))
          }
        />
      </div>
      <NotesTextArea
        onChange={(text) => setNoteInProgress(noteInProgress.setBody({ text }))}
        value={noteInProgress.body.note}
        data={projectUsersList.usersListForMentionsTextArea}
        id={`${NOTES_PANEL_INPUT_AREA_ID_BLOCK}-content`}
        placeholder={'notes-panel.notes-input-area.placeholder'}
      />
      <div className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container`}>
        <Button
          id={`${NOTES_PANEL_INPUT_AREA_BLOCK}__cancel-button`}
          className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container--cancel`}
          type={BUTTON_TYPES.secondary}
          onClick={cancelAddNote}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          id={`${NOTES_PANEL_INPUT_AREA_BLOCK}__add-button`}
          className={`${NOTES_PANEL_INPUT_AREA_BLOCK}__button-container--add`}
          type={BUTTON_TYPES.primary}
          onClick={() => {
            onCreateNote();
            setDisableAddNoteButton(true);
          }}
          disabled={!noteInProgress.isTextPresent() || disableAddNoteButton}
        >
          <FormattedMessage id="common.add" />
        </Button>
      </div>
    </div>
  );
};

NotesInputArea.propTypes = {
  /** The name of the current loged in user */
  currentUserFullName: PropTypes.string.isRequired,
  /** Action fired for canceling adding a note */
  cancelAddNote: PropTypes.func.isRequired,
  /** Action for creating a note */
  onCreateNote: PropTypes.func.isRequired,
  /** refering to the notes form object */
  noteInProgress: PropTypes.instanceOf(NotesForm).isRequired,
  /** Action to update the note form model */
  setNoteInProgress: PropTypes.func.isRequired,
  /** The note selection object created when the user highlights content and clicks add note */
  addNoteSelection: PropTypes.instanceOf(NoteSelection).isRequired,
  /** The model of all the users on the project */
  projectUsersList: PropTypes.instanceOf(ProjectUsersList).isRequired,
  /**Id of the selected project */
  selectedProjectId: PropTypes.string.isRequired,
};
const mapStateToProps = ({
  data: {
    currentUser,
    notesPanel: { addNoteSelection },
    projectUsersList,
    selectedProject: { id },
  },
}) => {
  const user = currentUser.getUser();
  return {
    currentUserFullName: getUserNameByUserDataObject(user),
    addNoteSelection,
    projectUsersList,
    selectedProjectId: id,
  };
};

export { NotesInputArea };
export default connect(mapStateToProps, null)(NotesInputArea);
