import BaseModel from 'models/base-model';

export default class ContentSearchResultEntry extends BaseModel({
  id: null,
  sectionId: null,
  siblingIndex: null,
  context: '',
  selector: '',
}) {
  constructor(props) {
    super(props);
    const { sectionId, selector, siblingIndex } = props;
    this.id = `${sectionId}-${selector}-${siblingIndex}`;
  }
}
