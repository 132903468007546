import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ProjectApi from 'models/api/project-api-model';
import Statement from 'models/data/statement-model';
import { connect } from 'react-redux';
import ConditionalRender from 'components/util/conditional-render-component';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'components/common/checkbox-component';
import { approveStatementWrapUpRequest } from 'api/statement-list-api';
import Loading from 'components/common/loading-component';
import { isNullOrUndefined } from 'utils/object-utils';
import { CurrentUser } from 'models/api/current-user-model';
import { toast } from 'react-toastify';
import Notification from 'components/common/notification-component';
import { ReactComponent as CloseButton } from 'icons/close-button-red.svg';

const STATEMENT_WRAP_UP_APPROVAL_2_ID_BLOCK =
  'statement-wrap-up-approval-modal-2-id';

export const STATEMENT_WRAP_UP_APPROVAL_2_BLOCK =
  'statement-wrap-up-approval-modal-2';

const ApproveStatementWrapUpModal2 = ({
  statement,
  project,
  onClose,
  onApprove,
  currentUser,
}) => {
  const [signedDate, setSignedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleApprove = async () => {
    setLoading(true);
    try {
      await approveStatementWrapUpRequest({
        statementId: statement.id,
        signedDate,
      });
      onApprove();
    } catch (error) {
      toast.error(
        <Notification
          icon={<CloseButton />}
          message={{
            id: 'statement-list.wrap-up-approval.modal.2.toast-error-message',
          }}
          title={{
            id: 'statement-list.wrap-up-approval.modal.2.toast-error-title',
          }}
        />,
        { autoClose: 3000 },
      );
      onClose();
    }
  };
  const _currentUser = currentUser.getUser();
  return (
    <Modal
      id={STATEMENT_WRAP_UP_APPROVAL_2_ID_BLOCK}
      className={STATEMENT_WRAP_UP_APPROVAL_2_BLOCK}
      title={'statement-list.wrap-up-approval.modal.2.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'statement-list.wrap-up-approval.modal.2.approve',
          onClick: handleApprove,
          disabled: isNullOrUndefined(signedDate),
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'statement-list.wrap-up-approval.modal.2.cancel',
          onClick: onClose,
        })
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <ConditionalRender dependencies={[project]}>
          <Banner
            id={`${STATEMENT_WRAP_UP_APPROVAL_2_ID_BLOCK}-warning-banner`}
            type={BANNER_TYPES.WARNING}
            bannerCopy={{
              id: 'statement-list.wrap-up-approval.modal.2.warning',
              values: {
                b: (...chunks) => <strong>{chunks}</strong>,
                ul: (...chunks) => (
                  <ul
                    className={`${STATEMENT_WRAP_UP_APPROVAL_2_BLOCK}__warning-list`}
                  >
                    {chunks}
                  </ul>
                ),
                li: (...chunks) => <li>{chunks}</li>,
              },
            }}
            isInternationalized={true}
            isInteractive={false}
          />
          <p className={`${STATEMENT_WRAP_UP_APPROVAL_2_BLOCK}__instructions`}>
            <FormattedMessage id="statement-list.wrap-up-approval.modal.2.instructions" />
          </p>
          <div className={`${STATEMENT_WRAP_UP_APPROVAL_2_BLOCK}__row`}>
            <Checkbox
              id={`${STATEMENT_WRAP_UP_APPROVAL_2_ID_BLOCK}-sign`}
              checked={signedDate}
              label="statement-list.wrap-up-approval.modal.2.sign"
              width={'auto'}
              onChange={() =>
                setSignedDate((curDate) =>
                  isNullOrUndefined(curDate) ? Date.now() : null,
                )
              }
            />
            <span
              className={`${STATEMENT_WRAP_UP_APPROVAL_2_BLOCK}__sign-details`}
            >
              {`${_currentUser.preferredFullName}`} ({_currentUser.email})
            </span>
          </div>
        </ConditionalRender>
      )}
    </Modal>
  );
};

ApproveStatementWrapUpModal2.propTypes = {
  /** Statement model to be wrapped up */
  statement: PropTypes.instanceOf(Statement).isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** Currently signed in user object */
  currentUser: PropTypes.instanceOf(CurrentUser).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when approval is confirmed */
  onApprove: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  data: {
    currentUser,
    selectedProject: { project },
  },
}) => ({
  project,
  currentUser,
});

export { ApproveStatementWrapUpModal2 };
export default connect(mapStateToProps, null)(ApproveStatementWrapUpModal2);
