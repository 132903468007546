import React, { useState } from 'react';
import { ReactComponent as Pencil } from 'icons/edit-page.svg';
import Switch from 'react-switch';
import { updateColumnDataAndPost } from '../constants/util/admin-module-utility';
import Tooltip from 'components/common/tool-tip-component';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMATS } from 'constants/util/date-constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SWITCH_ENABLER,
  SWITCH_ADMIN,
  SWITCH_COE,
} from '../constants/util/admin-module-utility';
import SupportIDInputModal from 'pages/admin-module/admin-module-manage-users/_manage-users-support-id-modal';
import ConditionalRenderComponent from './util/conditional-render-component';

const SWITCH_HEIGHT = 20;
const SWITCH_WIDTH = 45;
const ADMIN_MODULE_API_SWITCH = 'admin-module-api-switch';

const AdminModuleAPISwitch = ({
  switchKey,
  switchData,
  currentField,
  _updateUserEntityAgainstEmail,
}) => {
  const [showSupportIDModal, setShowSupportIDModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const _toggleSupportIDModal = (editMode) => {
    setEditMode(editMode);
    setShowSupportIDModal((prevModalState) => !prevModalState);
  };

  const checkedInitialState =
    currentField === SWITCH_ENABLER
      ? switchData.enabled
      : currentField === SWITCH_ADMIN
      ? switchData.admin
      : currentField === SWITCH_COE
      ? switchData.coe
      : false;
  const [checked, setChecked] = useState(checkedInitialState);

  const handleSwitchChange = async (event) => {
    //event is a boolean value that corresponds to the toggle switch state as On-Off(true-false)
    try {
      if (currentField === SWITCH_ADMIN) {
        if (event) _toggleSupportIDModal(false);
        else {
          setChecked(event);
          _updateSupportIDAndCountries(null);
          await updateColumnDataAndPost(switchData, event, currentField);
        }
      } else {
        setChecked(event);
        await updateColumnDataAndPost(switchData, event, currentField);
      }
    } catch (e) {
      setChecked(!event);
      console.error(e);
    }
  };

  const _onSaveSupportID = async (supportId, countries) => {
    try {
      _toggleSupportIDModal(false);
      setChecked(true);
      _updateSupportIDAndCountries(supportId, countries);
      await updateColumnDataAndPost(switchData, true, currentField);
    } catch (e) {
      setChecked(false);
      console.error(e);
    }
  };

  const _onCancelSupportIDModal = () => {
    _toggleSupportIDModal(false);
    !isEditMode && setChecked(false);
  };

  const _updateSupportIDAndCountries = (supportId, countries) => {
    switchData.supportId = supportId;
    if (countries && countries.length) {
      switchData.countries = countries;
    }
    _updateUserEntityAgainstEmail(switchData.email, switchData);
  };

  return (
    // wrapping this inside error boundary, so that whole page does not go blank. Since we do not have any api dependency to show loading or loaded icon,
    // hence, hard coded those values
    <ConditionalRenderComponent
      dependencies={[{ error: null, isLoading: false, isLoaded: true }]}
    >
      <div
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onMouseOver={(e) => e.stopPropagation()}
      >
        <div className={`${ADMIN_MODULE_API_SWITCH}__switch-container`}>
          <Tooltip
            id={`${ADMIN_MODULE_API_SWITCH}__disabled-tooltip-${switchData.id}`}
            className={`${ADMIN_MODULE_API_SWITCH}__disabled-tooltip`}
            text={
              switchData.enabled && switchData.enabledDate ? (
                <FormattedMessage
                  id="admin-module.manage-users.enabledDate"
                  values={{
                    enabledDate:
                      switchData.enabledDate &&
                      moment(switchData.enabledDate).format(
                        DATE_FORMATS.MONTH_DAY_YEAR,
                      ),
                  }}
                />
              ) : !switchData.enabled && switchData.disabledDate ? (
                <FormattedMessage
                  id="admin-module.manage-users.disabledDate"
                  values={{
                    disabledDate:
                      switchData.disabledDate &&
                      moment(switchData.disabledDate).format(
                        DATE_FORMATS.MONTH_DAY_YEAR,
                      ),
                  }}
                />
              ) : (
                ''
              )
            }
            isNotInternationalized
            active={currentField === SWITCH_ENABLER}
            position={'left'}
          >
            <div>
              <Switch
                id={switchKey}
                onChange={handleSwitchChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                height={SWITCH_HEIGHT}
                width={SWITCH_WIDTH}
              />
            </div>
          </Tooltip>
          {currentField === SWITCH_ADMIN && checked && (
            <div>
              <Pencil
                onClick={() => _toggleSupportIDModal(true)}
                className={`${ADMIN_MODULE_API_SWITCH}__pencil-icon`}
              />
            </div>
          )}
        </div>
        {showSupportIDModal && (
          <SupportIDInputModal
            isEditMode={isEditMode}
            userId={switchData.id}
            toggleSupportIDModal={_toggleSupportIDModal}
            onSave={_onSaveSupportID}
            onCancel={_onCancelSupportIDModal}
          />
        )}
      </div>
    </ConditionalRenderComponent>
  );
};
AdminModuleAPISwitch.propTypes = {
  /** Current user data for a row */
  switchData: PropTypes.object.isRequired,
  /** Name of the current switch button  */
  currentField: PropTypes.string.isRequired,
  /**Unique Key for the current switch */
  switchKey: PropTypes.string.isRequired,
};

export default AdminModuleAPISwitch;
