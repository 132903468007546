import BaseModel from 'models/base-model';
import { isNullOrUndefined } from 'utils/object-utils';
import { REACT_SECTION_ID_PREFIX } from 'constants/feature/statement-content-constants';

export default class ContentCSSSelector extends BaseModel({
  sectionId: null,
  selector: null,
  isElementHighlight: null,
}) {
  get fullSelctor() {
    if (this.hasValidSelector()) {
      return `#${REACT_SECTION_ID_PREFIX}${this.sectionId} ${this.selector}`;
    }

    return null;
  }

  hasValidSelector() {
    return (
      !isNullOrUndefined(this.sectionId) && !isNullOrUndefined(this.selector)
    );
  }
  isElementHighlight() {
    return !isNullOrUndefined(this.isElementHighlight);
  }
}
