import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import ElementFilters from 'models/data/element-filters-model';
import { SCALING_OPTIONS } from 'constants/feature/element-attribute-constants';

export const SCALING_FILTERS_ID_BLOCK = 'element-filters-scaling-id';

const ElementFiltersScaling = ({ filters, setFilters, className }) => {
  return (
    <div className={className}>
      {SCALING_OPTIONS.map((scaling) => (
        <Checkbox
          key={scaling.id}
          id={`${SCALING_FILTERS_ID_BLOCK}-${scaling.id}`}
          label={scaling.title}
          checked={filters.isScalingSelected(scaling)}
          onChange={() => {
            setFilters(filters.selectScaling(scaling));
          }}
        />
      ))}
    </div>
  );
};

ElementFiltersScaling.propTypes = {
  /** ElementFilters model representing all filters currently applied */
  filters: PropTypes.instanceOf(ElementFilters).isRequired,
  /** Function fired to update the filters model */
  setFilters: PropTypes.func.isRequired,
  /** Optional classname property applied to parent div */
  className: PropTypes.string,
};

export default ElementFiltersScaling;
