import { handleActions } from 'redux-actions';

import { setLocale } from 'store/actions/locale-actions';

export default handleActions(
  {
    [setLocale]: (state, { payload }) => {
      return payload;
    },
  },
  'en',
);
