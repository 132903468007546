import BaseModel from 'models/base-model';
/**
 * Model used for tracking the last time a request to the documentChangesSince endpoint
 * This endpoint is responsible for returning changes to element meta data and content
 * "since" the provided timestamp
 */
export default class ChangesSinceTracker extends BaseModel({
  revisionId: null,
  since: Date.now(),
}) {
  /** Reducer function called when the statement content page initially loads
   * Inits our `since` value and the current revisionId
   */
  initFromRevision({ revisionId }) {
    return this.merge({
      revisionId,
      since: Date.now(),
    });
  }
  /** Reducer function to set `revisionId` */
  setRevisionId(revisionId) {
    return this.merge({
      revisionId,
    });
  }

  /** Reducer function to set `since` */
  setSince(since) {
    return this.merge({
      since,
    });
  }
}
