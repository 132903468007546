import { handleActions } from 'redux-actions';
import {
  countryManagercontainerListLoading,
  countryManagercontainerListLoaded,
  countryManagercontainerListError,
  clearAdminPageDataAction,
} from 'store/actions/admin-module-actions';
import ContainerForm from 'models/form/container-form-model';

export default handleActions(
  {
    [countryManagercontainerListLoading]: (containerList) => {
      return containerList.setLoading();
    },
    [countryManagercontainerListLoaded]: (containerList, { payload }) => {
      return containerList.setLoaded(payload);
    },
    [countryManagercontainerListError]: (containerList, { payload }) => {
      return containerList.setError(payload);
    },
    [clearAdminPageDataAction]: () => {
      return new ContainerForm();
    },
  },
  new ContainerForm(),
);
